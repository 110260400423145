import React, { useState } from 'react';
import MultiSelectSection from '../../../common/MultiSelectSection';
import {Icon} from "@iconify/react";

const SingleInputSection = (props) => {
    const [state, setState] = useState({
        inputComponent: props.inputComponent,
        selectedOption: props.selectedOption,
        selectedFilters: props.selectedFilters
    })

    const addDetails = (inputs) => {
        let selectedFilters = state.selectedFilters ? state.selectedFilters : []
        let text = state[inputs.field]

        if(text !== "" && !selectedFilters.filter(e => e.label === state.type && e.value === state[inputs.field]).length  ) {
            let dataRow = {}
            dataRow.label = state.selectedOption
            dataRow.value = state[inputs.field]
            dataRow.userView = state.selectedOption+": "+state[inputs.field]
            selectedFilters.push(dataRow)
        }

        setState(prevState => ({ ...prevState, selectedFilters, [inputs.field]: "" }))
        
    }

    const removeDetails = (row) => {
        let selectedFilters = state.selectedFilters ? state.selectedFilters : []

        selectedFilters = selectedFilters.filter(e => e.label !== row.label && e.value !== row.value)

        setState(prevState => ({ ...prevState, selectedFilters }))
        
    }

    const applyFilter = () => {
        if(state.selectedFilters && state.selectedFilters.length) {
            return props.selectedInput(state.selectedOption, state.selectedFilters)
        } else {
            return props.selectedInput(false);
        }
    }

    const closeFilter = () => {
        return props.selectedInput(false);
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
        event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {			
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
    
    return (
        <div className='mt-2 p-3 bg-white' onClick={(event) => handleChildClick(event, 'parent')}>
            <p className="text-black mb-2">{state.selectedOption}</p>
            <div className="flex flex-wrap mb-3">
                {state.inputComponent && Object.values(state.inputComponent).map((inpCom, gIndex)  => {
                    return(
                        <React.Fragment key={"inp_"+gIndex}>
                        {inpCom.component === "select" ?
                            <div onClick={(event) => {
                                if(!state['show_'+inpCom.field]) {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "show_"+inpCom.field)
                                }
                            }}>
                                <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mt-2 px-2 mb-2 truncate ${state[inpCom.field] ? 'text-black' : 'placeholder '}`}>
                                    {state[inpCom.field] ? state[inpCom.field] : 'Select'}
                                    <Icon icon="icon-park-solid:down-one" className={`${state[inpCom.field] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                </p>
                                {state['show_'+inpCom.field] ?
                                    <MultiSelectSection
                                        fields={["value", "label"]}
                                        options={inpCom.options ? inpCom.options : []}
                                        selectedValues={state[inpCom.field] ? state[inpCom.field] : ''}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
                                                setState(prevState => ({ ...prevState, [inpCom.field]: value }))
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                        removeTopOptions={true}
                                        hideSearch={false}
                                        topClass={'auto'}
                                        widthClass={'minWidth220'}
                                    />
                                : null}
                            </div>
                        : inpCom.component === "multiselect" ?
                            <div className='w-40 mt-1 boderDottedBottom'>
                                <p className={`flex mt-2 mb-0 ${state[inpCom.field] && state[inpCom.field].length ? 'text-black' : 'text-lightGray'}`}>{state[inpCom.field] && state[inpCom.field].length ? state[inpCom.field].length+' Selected' : 'Select'}</p>
                                {state.isDropdownOpen ?
                                    <div className={`relative`}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'muliselectDropDown', "isTagValue")
                                        }}
                                    >
                                        {/* <label className='my-1 flex'>Value</label> */}
                                        <p className={`border-b border-lightGray px-2 py-1 flex justify-between mb-0 truncate ${state[inpCom.field] && state[inpCom.field].length ? 'text-black' : 'text-lightGray'}`}>
                                            {state[inpCom.field] && state[inpCom.field].length ? state[inpCom.field].length+' Selected' : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state[inpCom.field] && state[inpCom.field].length ? "text-black" : "text-lightGray"}`} width="16" height="16" />
                                        </p>
                                        {state.isTagValue ?
                                            <MultiSelectSection 
                                                fields={["value", "label"]}
                                                className={'top80 left-0'}
                                                options={inpCom.options ? inpCom.options : []}
                                                selectedValues={state[inpCom.field] ? state[inpCom.field] : []}
                                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, [inpCom.field]: value }))}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                        : null}
                                    </div>
                                : null}
                            </div>
                        : inpCom.component === "multipleAddTextBox" ?  
                            <div className="flex w-full">
                                <input 
                                    type="text" 
                                    className={`bg-transparent border border-lightGray rounded-md px-2 py-1 text-black w-1/2`}
                                    placeholder={inpCom.label ? inpCom.label : 'Enter details'}
                                    value={state[inpCom.field] ? state[inpCom.field] : ""}
                                    onChange={e => setState(prevState => ({ ...prevState, [inpCom.field]: e.target.value }))}
                                />
                            
                                <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 cursor-pointer self-center' onClick={() => addDetails(inpCom)} />
                            </div>
                        : null}
                        </React.Fragment>
                    )
                })}
            </div>
            
            {state.selectedFilters && state.selectedFilters.length ? 
                <div className='flex flex-wrap'>
                    {state.selectedFilters.map(row => {
                        return(
                            <span className="flex bg-info px-2 py-1 rounded-full mr-2 mt-2 self-center text-black px-2">{row.userView}
                                <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => removeDetails(row) } />
                            </span>
                        )
                    })}
                </div>
            : null}
            
            <div className='flex justify-end mt-2 mb-3'>
                <button className={`cursor-pointer btn bg-info px-2 py-1 rounded-md text-white mr-3`} onClick={ () => applyFilter()}>Apply</button>
                <button className={`cursor-pointer btn bg-lightGray px-2 py-1 rounded-md text-white`} onClick={ () => closeFilter()}>Cancel</button>
            </div>
        </div>
    )
}

export default SingleInputSection;
