import React, { useEffect, useState } from 'react';
import { noSpace } from '../../../utils/utility';


const Textbox = ({ label, type, callback, className, placeholder, editValue }) => {
    const [state, setState] = useState('')

    useEffect(() => {
        setState(editValue)
    }, [editValue])

    return (
        <div className={`w-full items-center ${className}`}>
            <p className="text-NeutralGray-800 text-base flex w-full">{label}</p>
            <input 
                className="placeholder:italic placeholder:text-NeutralGray-600 text-base block border border-NeutralGray-400 rounded-md py-2.5 pl-2 shadow-sm w-full" 
                placeholder={`${placeholder ? placeholder : "Enter Details"}`}
                value={state ? state : ''}
                onChange={e => {
                    const value = noSpace(e.target.value);
                    setState(value);
                    callback(value);
                }}
                type={`${type ? type : 'text'}`}
                name="job_name"
            />
        </div>
    );
};

export default Textbox;
