/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * kiosk
 * @exports
 * @file ClusterDetails.js
 * @author Prakash // on 27/11/2023
 * @copyright © 2023 kiosk. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listQueueDetails } from '../../../actions/Collider/ClusterAction'
import { getQueueCostBreakup } from '../../../actions/cost/CostAction'
import ApexMixedBarLineChart from '../../common/charts/ApexMixedBarLineChart';
import ApexDonutChart from '../../common/charts/ApexDonutChart';
import ApexStackedBarChart from '../../common/charts/ApexStackedBarChart';
import ApexBarChart from '../../common/charts/ApexBarChart';
import ApexLineChartSpendBoard from '../../common/charts/ApexLineChartSpendBoard';
import DetailsSidePanel from './DetailsSidePanel';
import { currentUTCtime, momentDateGivenFormat, removeUnderScore } from '../../../utils/utility';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import {Icon} from "@iconify/react";
import { PERIOD_OPTIONS_NEW } from '../../../utils/constants';
import PageLoading from '../../common/PageLoading';
import { LoadingCircle } from '../../common/LoadingCiricle';
import MultiSelectSection from '../../common/MultiSelectSection';
import SelectOption from '../../common/Form/SelectOption';

const QueueDetails = (props) => {
    const [state, setState] = useState({
        showLoading: true,

        currentPage: 1,
        perPage: 5,

        queueDetails: [],
        selectedQueueDetails: [],
        clusterheaders: [],
        callSearch: true,
        graphOrder: ['cpus', 'cpu_hours', 'nodes', 'node_hours', 'jobs'],
        graphOrderColors: [['#854097'],['#693EBC'],['#775BA2'],['#9A77D1']],
        colors: ['#7052BC', '#AA2217', '#57AF3E', '#2D60A6', '#FC6D22', '#CF9124' ]
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    const expandSection = useCallback((queueDetails, type, index) => {
        queueDetails.forEach((clus, iIndex) => {
            setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+iIndex]: (iIndex === index ? (type === 'expand' ? true : false) : false) }))
        })
    }, [])
  
    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            let params = {}
            params.provider = props.selectedProvider.toLowerCase()
            params.spendboard = true
            params.start_time = props.startDate
            params.end_time = props.endDate
            if(props.selectedAccount && props.selectedAccount.length) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length) {
                params.region = props.selectedRegion
            }
            if(props.selectedClusters && props.selectedClusters.length) {
                params.cluster_name = props.selectedClusters
            }
            if(props.currencyUnit) {
                params.currency_conversion = props.currencyUnit
            }

            if(state.token === 'next') {
                params.next_token = state.listResponse && state.listResponse.next_token
            } else if(state.token === 'previous') {
                params.previous_token = state.listResponse && state.listResponse.previous_token
            } else if(state.token === 'current') {
                params.current_token = state.listResponse && state.listResponse.current_token
            }

            params.size = state.perPage

            dispatch(listQueueDetails(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length ? response.results : []
                        
                        setState(prevState => ({ ...prevState, listResponse: response, queueDetails: results, detailsFormat: response && response.queue_details ? response.queue_details : [], showLoading: false, callBudgetSpendTrendSection: true, callSpendTrendSection: true, totalRecords: response.total }));

                        expandSection(results)

                        props.callBack()
                    }
            })
        }
    }, [state.callSearch, dispatch, state.perPage, props, expandSection, state.listResponse, state.token])

    useEffect(() => {
        if(state.callBudgetSpendTrendSection) {
            setState(prevState => ({ ...prevState, callBudgetSpendTrendSection: false }))

            state.queueDetails.forEach((item, index) => {
                if(item.trend) {
                    let budgetSpendTrendData = item.trend ? item.trend : []
                    let graphData = {}
                    graphData.labels = item.dates ? item.dates : []
                    graphData['Budget'] = budgetSpendTrendData.budget
                    graphData['Spend'] = budgetSpendTrendData.spend

                    //horizontal spend and budget bar
                    let data = [budgetSpendTrendData && budgetSpendTrendData.budget_total ? budgetSpendTrendData.budget_total : 0, budgetSpendTrendData && budgetSpendTrendData.spend_total ? budgetSpendTrendData.spend_total : 0]
                    let labels = ['Budget', 'Spend']
                    let dataUnit = ''
                
                    let horiBarData = {}
                    horiBarData.data = data
                    horiBarData.labels = labels        
                    horiBarData.unit = dataUnit
                    // horiBarData.tooltipValues = tooltip        
                    
                    setState(prevState => ({ ...prevState, ["budgetSpendData_"+index]: horiBarData, ["budgetSpendBarLineGraphData_"+index]: graphData }))
                }

                setState(prevState => ({ ...prevState, ['selected_Period_'+index]: 'daily' }))
            })
            
            
        }
    }, [state.callBudgetSpendTrendSection, state.queueDetails])

    useEffect(() => {
        if(state.callSpendTrendSection) {
            
            setState(prevState => ({ ...prevState, callSpendTrendSection: false }))

            state.queueDetails.forEach((item, index) => {
                if(item.breakdown) {
                    let spendStackedTrend = item.breakdown
                    let graphData = {}
                    graphData.labels = item.dates ? item.dates : []
            
                    Object.entries(spendStackedTrend).length && spendStackedTrend.spend && Object.entries(spendStackedTrend.spend).forEach(([key, value]) => {
                        graphData[key] = value
                    })

                    let spendDonutResponse = item.breakdown && item.breakdown.spend_total ? item.breakdown.spend_total : {}
    
                    let donut = []
                    let donut_total_count = 0				
                    spendDonutResponse && Object.entries(spendDonutResponse).forEach(([key, value]) => {
                        let donutRow = {}
                        donutRow.item_count = value
                        donutRow.item_name = key
                        donut.push(donutRow)
                        donut_total_count += value 
                    })
            
                    let spendDonutData = {
                        'items': donut,
                        'label': 'Total',
                        'total_count': donut_total_count
                    }
                    // let trendBreakDownColor = ['yellow', 'orange', 'blue', 'plum']
                    let trendBreakDownColor = ['critical', 'GreenBlue', 'Violet', 'running', 'darkGreen', 'darkRed']
                    
                    setState(prevState => ({ ...prevState, ["spendDonutData_"+index]: spendDonutData, ['spendStackedTrendData_'+index]: graphData, ["trendBreakDownTotal_"+index]: spendDonutResponse,  trendBreakDownColor }))
                }
            })
        }
    }, [state.callSpendTrendSection, state.queueDetails])

    const onChangePeriod = (period, selectedPeriodIndex, item) => {

        setState(prevState => ({ ...prevState, 
            ["budgetSpendData_"+selectedPeriodIndex]: {}, 
            ["budgetSpendBarLineGraphData_"+selectedPeriodIndex]: {}, 
            ["spendDonutData_"+selectedPeriodIndex]: {}, 
            ['spendStackedTrendData_'+selectedPeriodIndex]: {}, 
            ["trendBreakDownTotal_"+selectedPeriodIndex]: [],
        }))

        let params = {}
        params.provider = props.selectedProvider.toLowerCase()
        params.spendboard = true
        params.start_time = props.startDate
        params.end_time = props.endDate
        if(props.selectedAccount && props.selectedAccount.length) {
            params.account_id = props.selectedAccount
        }
        if(props.selectedRegion && props.selectedRegion.length) {
            params.region = props.selectedRegion
        }
        if(period) {
            params.duration_aggregate_by = period
        }
        if(item.queue_name) {
            params.queue_name = item.queue_name
        }

        dispatch(listQueueDetails(params))
            .then((response) => {
                if(response) {
                    let results = response && response.results && response.results.length ? response.results : []
                    setState(prevState => ({
                        ...prevState,
                        selectedQueueDetails: results,
                        callSelectedBudgetSpendTrendSection: true,
                        selectedPeriodIndex
                    }));
                }
        })
    }

    useEffect(() => {
        if(state.callSelectedBudgetSpendTrendSection) {
            setState(prevState => ({ ...prevState, callSelectedBudgetSpendTrendSection: false }))
            state.selectedQueueDetails.forEach(item => {
                if(item.trend) {
                    let budgetSpendTrendData = item.trend ? item.trend : []
                    //horizontal spend and budget bar
                    let data = [budgetSpendTrendData && budgetSpendTrendData.budget_total ? budgetSpendTrendData.budget_total : 0, budgetSpendTrendData && budgetSpendTrendData.spend_total ? budgetSpendTrendData.spend_total : 0]
                    let labels = ['Budget', 'Spend']
                    let dataUnit = ''
                
                    let horiBarData = {}
                    horiBarData.data = data
                    horiBarData.labels = labels        
                    horiBarData.unit = dataUnit
                    // horiBarData.tooltipValues = tooltip
                    
                    // setState(prevState => ({ ...prevState, ["budgetSpendData_"+index]: horiBarData }))
                
                    
                    let graphData = {}
                    graphData.labels = item.dates ? item.dates : []
                    graphData['Budget'] = budgetSpendTrendData.budget
                    graphData['Spend'] = budgetSpendTrendData.spend
                    
                    setState(prevState => ({ ...prevState, ["budgetSpendData_"+state.selectedPeriodIndex]: horiBarData, ["budgetSpendBarLineGraphData_"+state.selectedPeriodIndex]: graphData }))
                }
                if(item.breakdown) {
                    let spendStackedTrend = item.breakdown
                    let graphData = {}
                    graphData.labels = item.dates ? item.dates : []
            
                    Object.entries(spendStackedTrend).length && spendStackedTrend.spend && Object.entries(spendStackedTrend.spend).forEach(([key, value]) => {
                        graphData[key] = value
                    })

                    let spendDonutResponse = item.breakdown && item.breakdown.spend_total ? item.breakdown.spend_total : {}
    
                    let donut = []
                    let donut_total_count = 0				
                    spendDonutResponse && Object.entries(spendDonutResponse).forEach(([key, value]) => {
                        let donutRow = {}
                        donutRow.item_count = value
                        donutRow.item_name = key
                        donut.push(donutRow)
                        donut_total_count += value 
                    })
            
                    let spendDonutData = {
                        'items': donut,
                        'label': 'Total',
                        'total_count': donut_total_count
                    }                    
                    
                    setState(prevState => ({ ...prevState, ["spendDonutData_"+state.selectedPeriodIndex]: spendDonutData, ['spendStackedTrendData_'+state.selectedPeriodIndex]: graphData, ["trendBreakDownTotal_"+state.selectedPeriodIndex]: spendDonutResponse }))
                }
            })
        }

    }, [state.callSelectedBudgetSpendTrendSection, state.selectedQueueDetails, state.selectedPeriodIndex])

    const getExpandedItemDetails = (period, item, index) => {

        let params = {}
        params.provider = props.selectedProvider.toLowerCase()
        params.start_time = props.startDate
        params.end_time = props.endDate
        // params.spendboard = true
        params.duration_aggregate_by = state['selected_Period_'+index]
        if(item.account_id) {
            params.account_id = item.account_id
        }
        if(item.region) {
            params.region = item.region
        }
        if(item.cluster_name) {
            params.cluster_name = item.cluster_name
        }
        if(item.queue_name) {
            params.queue_name = item.queue_name
        }
        if(props.currencyUnit) {
            params.currency_conversion = props.currencyUnit
        }
            
        if(props.selectedUser && props.selectedUser.length) {
            params.user_name = props.selectedUser
        }

        dispatch(getQueueCostBreakup(params))
            .then((response) => {
                if(response) {
                    let results = response && response.results && response.results.length ? response.results : []
                    setState(prevState => ({
                        ...prevState,
                        ['selectedItemDetails_'+index]: results,
                        selectedItemIndex: index,                        
                        callSelectedBreakDownDetail: true
                    }));
                }
        })

    }

    useEffect(() => {
        if(state.callSelectedBreakDownDetail) {
            setState(prevState => ({ ...prevState, callSelectedBreakDownDetail: false, ['showLoading_'+state.selectedItemIndex]: false })) 
            if(state['selectedItemDetails_'+state.selectedItemIndex] && state['selectedItemDetails_'+state.selectedItemIndex].length) {
                var trendTable = []
                state['selectedItemDetails_'+state.selectedItemIndex].forEach((item, index) => {

                    let budgetSpendTrendData = item.data ? item.data : []
                    let jobsData = {}
                    jobsData.labels = item.dates ? item.dates : []
                    jobsData.total = budgetSpendTrendData.hasOwnProperty('jobs') ? budgetSpendTrendData['jobs'] : []
                    jobsData.success = budgetSpendTrendData.hasOwnProperty('success') ? budgetSpendTrendData['success'] : []
                    jobsData.failed = budgetSpendTrendData.hasOwnProperty('failed') ? budgetSpendTrendData['failed'] : []
                    
                    let nodesData = {}
                    nodesData.labels = item.dates ? item.dates : []
                    nodesData.nodes = budgetSpendTrendData.hasOwnProperty('nodes') ? budgetSpendTrendData['nodes'] : []

                    let vcpusData = {}
                    vcpusData.labels = item.dates ? item.dates : []
                    vcpusData.vxpus = budgetSpendTrendData.hasOwnProperty('cpus') ? budgetSpendTrendData['cpus'] : []

                    let cpuHours = {}
                    cpuHours.labels = item.dates ? item.dates : []
                    cpuHours.cpu_hours = budgetSpendTrendData.hasOwnProperty('cpu_hours') ? budgetSpendTrendData['cpu_hours'] : []

                    let nodeHours = {}
                    nodeHours.labels = item.dates ? item.dates : []
                    nodeHours.node_hours = budgetSpendTrendData.hasOwnProperty('node_hours') ? budgetSpendTrendData['node_hours'] : []

                    setState(prevState => ({ 
                        ...prevState, 
                        ["nodes_"+state.selectedItemIndex]: nodesData,
                        ["cpus_"+state.selectedItemIndex]: vcpusData,
                        ["cpu_hours_"+state.selectedItemIndex]: cpuHours,
                        ["node_hours_"+state.selectedItemIndex]: nodeHours,
                        ["jobs_"+state.selectedItemIndex]: jobsData,                        
                    }))

                    let labels = item.dates ? item.dates : []
                    if(item.breakdown && item.breakdown.spend && Object.entries(item.breakdown.spend).length) {
                        Object.entries(item.breakdown.spend).forEach(([key, value]) => {
                            var result = {
                                "Title": key,
                                'total': item.breakdown.spend_total && item.breakdown.spend_total[key] ? item.breakdown.spend_total[key] : 0
                            }
                            
                            value.forEach((vItem, vIndex) => {
                                result[labels[vIndex]] = vItem
                            })
                            trendTable.push(result);
                        })
                    } else if(item.data && Array.isArray(item.data)) {
        
                        let data = item.data
                        // let totalCount = item.data.reduce(function(a, b){
                        // 	return a + b;
                        // }, 0);
                        var result = {
                            "Title": "Cost",
                            'total': item.total
                        }
                        
                        data.forEach((item,index) => {
                            if(momentDateGivenFormat(labels[index], "YYYY-MM-DD") <= momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                                result[labels[index]] = item
                            }
                        })
                        
                        if(item.forecast && item.forecast.dates) {
                            item.forecast && item.forecast.dates.forEach((lab, index) => {					
                                result[lab] = item.forecast.data[index]
                            })
                        }
        
                        trendTable.push(result);
                    }

                    setState(prevState => ({ ...prevState, ['selected_Period_'+index]: 'daily' }))
                })
                
                if(trendTable.length) {
                    let queueHeaders = ['Title', 'total']
                    const columns = Object.entries(trendTable[0]).map(([tKey, tValue]) =>{
                        let sticky = false
                        let header = ''
                        let width = '100%'
                        let textColor = ''
                        if(tKey === 'Title') {
                            sticky = true
                            header = tKey
                            width = 300
                        } else if(tKey === 'total') {
                            sticky = true
                            header = 'Total'
                            width = 75
                        } else {
                            if(momentDateGivenFormat(tKey, "YYYY-MM-DD") > momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                                textColor = "text-info"
                            }
                            // if(state.granualrity === 'monthly') {
                            //     header = moment(tKey).format('MMM YY')
                            //     width = 75
                            // } else  if(state.granualrity === 'daily') {
                            //     header = moment(tKey).format('MMM DD')
                            //     width = 75
                            // } else  {
                            header = moment(tKey).format('MMM DD')
                            width = 75
                            queueHeaders.push(tKey)
                            // }
                        }
                        return {
                            Header: header,
                            accessor: tKey,
                            width: width,
                            sticky: sticky ? "left" : "",
                            textColor: textColor,
                            className: 'text-truncate text-right'
                        }
                    })
                    
                    setState(prevState => ({ ...prevState, ['trendTable_'+state.selectedItemIndex]: trendTable, ['filteredArray_'+state.selectedItemIndex]: trendTable, ['columns_'+state.selectedItemIndex]: columns, queueHeaders }))
                } else {
                    const columns = []	
                    setState(prevState => ({ ...prevState, ['trendTable_'+state.selectedItemIndex]: trendTable, ['filteredArray_'+state.selectedItemIndex]: trendTable, ['columns_'+state.selectedItemIndex]: columns }))
                }
            }
        }
    }, [state.callSelectedBreakDownDetail, state])


    // useEffect(() => {
    //     if(state.clusterDetails && state.clusterDetails.length) {
    //         let dataResult = state.clusterDetails
    //         let headers = []
    //         Object.entries(dataResult[0]).forEach(([key, value]) => {
    //             if(typeof value === 'string') {
    //                 let headerRow = {}
    //                 headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
    //                 headerRow.key = key
    //                 headers.push(headerRow)
    //             }
    //         })
    
    //         setState(prevState => ({ ...prevState, clusterheaders: headers }))
    //     }

    //     //status graph of how many running days 
    //     state.clusterDetails && state.clusterDetails.forEach(row => {
    //         let donut = [{
    //             item_count: row.number_of_days ? row.number_of_days : 0,
    //             item_name:'Days'
    //         }]
    //         let donut_total_count = row.number_of_days ? row.number_of_days : 0
    //         donut = _.orderBy(donut, ['item_count'], ['desc'])
    
    //         let statusDonutData = {
    //             'items': donut,
    //             'label':'Days',
    //             'total_count': donut_total_count
    //         }
    
    //         setState(prevState => ({ ...prevState, ["statusDonutData_"+row.cluster_name]: statusDonutData }))
    //     })
    // }, [state.clusterDetails])

    useEffect(() => {
        if(state.refreshClusters) {
            setState(prevState => ({ ...prevState, refreshClusters: false }))
        }
    }, [state.refreshClusters])

    const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const getBgColor = (index) => {
        if(index > 5) {
            index = index - 6
        }
        let color = 'bg-slateBlue-600'
        if(index === 1) { color = 'bg-red-600' } else 
        if(index === 2) { color = 'bg-appleGreen-600' } else
        if(index === 3) { color = 'bg-blue-600' } else
        if(index === 4) { color = 'bg-PrimaryLightOrange-600' } else
        if(index === 5) { color = 'bg-LemonCurry-600' }
        return color
    }

    const getTextColor = (index) => {
        if(index > 5) {
            index = index - 6
        }
        let color = 'text-slateBlue-600'
        if(index === 1) { color = 'text-red-600' } else 
        if(index === 2) { color = 'text-appleGreen-600' } else
        if(index === 3) { color = 'text-blue-600' } else
        if(index === 4) { color = 'text-PrimaryLightOrange-600' } else
        if(index === 5) { color = 'text-LemonCurry-600' }
        return color
    }


    return (
        <div className='bg-transparent' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${(state.showLoading || state.downloading) ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            {state.showSidePanel ? 
                <DetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showSidePanel: false, selectedDetails: {}, selectedType: '' }))}                    
                    selectedProvider={props.selectedProvider}
                    selectedAccount={props.selectedAccount}
                    selectedRegion={props.selectedRegion}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    selectedDetails={state.selectedDetails}
                    selectedType={state.selectedType}
                    detailsFormat={state.detailsFormat ? state.detailsFormat : []}
                />
            : null}
            
            <div className='overflow-auto'>                
                <div className='flex justify-between'>
                    <p className='mb-0 self-center text-base font-medium text-black lg:w-1/2 w-full'>
                        <small>Showing {state.queueDetails && state.queueDetails.length && parseInt(state.currentPage * state.perPage) > state.totalRecords ? state.totalRecords : parseInt(state.currentPage * state.perPage)} out of total {state.totalRecords} project(s)</small>
                    </p>
                    {state.totalRecords > state.perPage ?
                        <div className='flex justify-end text-black lg:w-1/2 w-full'>
                            <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>                                
                            <div className="flex text-sm">
                                <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.listResponse && state.listResponse.previous_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.listResponse && state.listResponse.previous_token) {
                                        setState(prevState => ({ ...prevState, showLoading: true, currentPage: state.currentPage-1, token: 'previous', callSearch: true }))
                                    }
                                }}/></span> 
                                <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.listResponse && state.listResponse.next_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.listResponse && state.listResponse.next_token) {
                                        setState(prevState => ({ ...prevState, showLoading: true, currentPage: state.currentPage+1, token: 'next', callSearch: true }))
                                    }
                                }}/></span>
                            </div>
                        </div>
                    : null}
                </div>
                {state.queueDetails && state.queueDetails.length ?
                    state.queueDetails.map((item, index) => {
                        return(
                            <div className={`bg-white rounded-2xl mb-2`} key={"row_"+index}>
                                <div className='flex flex-wrap'>
                                    <div className="text-base w-full bg-PrimaryTintsPurple-600 rounded-l-2xl px-3 py-4 lg:w-1/4 md:w-1/2 flex flex-col justify-between">
                                        <div className="flex">
                                            <div className='py-2'>
                                                <div className="flex">
                                                    <div className="py-1 w-full">
                                                        <p className="b-block mb-0 text-lightGray">Queue</p>
                                                        <p className="mb-0 text-black">{ item.queue_name ? item.queue_name : ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                        <div className="flex">
                                            <div className="py-1 w-full">
                                                <p className="b-block mb-0 text-lightGray">Cluster</p>
                                                <p className="mb-0 text-black">
                                                {item.cluster_name ? item.cluster_name : ''}
                                                {item.cluster_version ?
                                                    <span className="mb-0 mr-2 text-lg text-black">
                                                        (v {item.cluster_version})
                                                    </span>
                                                : null}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="py-1 w-full">
                                                <p className="b-block mb-0 text-lightGray">Resources</p>
                                                <p className="mb-0 text-black">
                                                {item.account_id ? <span>{' ' + item.account_id}</span> : null}
                                                {item.region ? <span>{' ' + item.region}</span> : null}
                                                {item.resource_type ? <span>{' ' + item.resource_type}</span> : null}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {state['showLoading_'+index] ?
                                        <div className="w-full lg:w-3/4 flex justify-center py-4">
                                            <LoadingCircle />
                                        </div>
                                    :
                                        <div className="w-full lg:w-3/4 flex flex-wrap p-3">
                                            <div className='flex w-full justify-between pb-8'>
                                                <SelectOption
                                                    label={""}
                                                    fields={["value", "label"]}
                                                    options={PERIOD_OPTIONS_NEW}
                                                    selectedValues={state['selected_Period_'+index] ? state['selected_Period_'+index] : ''}
                                                    callbackMultiSelect={(value) => {
                                                        setState((prevState) => ({
                                                            ...prevState,
                                                            ['selected_Period_' + index]: value,
                                                            ['showLoading_'+index]: state['showExpandedSectoin_'+index]
                                                        }));
                                                        onChangePeriod(value, index, item);
                                                        if(state['showExpandedSectoin_'+index]) {
                                                            getExpandedItemDetails(value, item, index);
                                                        }
                                                    }}
                                                    singleSelection={true}
                                                    dropdownWidth={'min-w-32'}
                                                    classDetails={{border: "border border-primaryPurple-600", padding: "py-2.5 pr-4 pl-3", bg: 'bg-lavender-200', rounded: 'rounded-full' }}
                                                />
                                                
                                                <div className='flex'>
                                                    <p className='mb-0 text-white bg-DeepPurpleTints-600 pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: item, selectedType: 'Queue', showSidePanel: true }))}>View Details</p>
                                                    <span className='flex justify-center'>
                                                        {!state['showExpandedSectoin_'+index] ?
                                                            <Icon icon="basil:expand-outline" width={24} height={24} className={`text-white bg-DeepPurpleTints-600 h-8 w-8 rounded-full cursor-pointer self-center ml-3`}
                                                                onClick={() => {
                                                                    setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+index]: true, ['showLoading_'+index]: true }))
                                                                    getExpandedItemDetails(state['selected_Period_' + index], item, index)
                                                                }} 
                                                            />
                                                        :
                                                            <Icon icon="la:compress" width={24} height={24} className={`text-white bg-DeepPurpleTints-600 h-8 w-8 rounded-full cursor-pointer self-center ml-3`}
                                                                onClick={() => {
                                                                    setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+index]: false }))
                                                                }}
                                                            />
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="lg:w-3/12 md:w-1/4 w-full border-r border-DeepPurpleTints-600 pr-5">
                                                <p className='font-semibold text-sm pl-2'>Budget vs Spend</p>
                                                <div className='flex'>
                                                    {state["budgetSpendData_"+index] && Object.entries(state["budgetSpendData_"+index]).length ?
                                                        <div className='-mt-2 -mb-2 mr-4 self-center'>
                                                            <ApexBarChart
                                                                graphData={state["budgetSpendData_"+index]}
                                                                sparkline={false}
                                                                yaxis={true}
                                                                yaxisLabel={true}
                                                                xaxis={true}
                                                                xaxisFormat={'string'}
                                                                xaxisLabel={true}
                                                                axisLabelColor={'#495057'}
                                                                paddingLeft={0}
                                                                legend={false}
                                                                legendPostion={'bottom'}
                                                                legendHorizontalAlign={'center'}
                                                                legendUseSeriesColor={true}
                                                                stacked={false}
                                                                height={200}
                                                                horizontal={true}
                                                                barHeight={'40%'}
                                                                barEndShape={'rounded'}
                                                                columnWidth={'25%'}
                                                                gradient={false}
                                                                gradientColor={['#57AF3E', '#3576BE']}
                                                                hideTooltipValue={true}
                                                                backgroundBarShape={'rounded'}
                                                                backgroundBarColors={['#333947']}
                                                                showBackgroundBarColors={false}
                                                                className={'transparentTooltip'}
                                                                dataLabels= {true}
                                                                dataLabelsTextAnchor = {'end'}
                                                                dataLabelPosition={'top'}
                                                                colors={['#57AF3E', '#3576BE']}
                                                                dataLabelsOffsetX={0}
                                                                chartDistributed={true}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                            <div className="lg:w-3/12 md:w-1/4 w-full border-r border-DeepPurpleTints-600 px-5">
                                                <p className='font-semibold text-sm pl-2'>Trend</p>
                                                <div className='flex'>
                                                    {state['budgetSpendBarLineGraphData_'+index] && Object.entries(state['budgetSpendBarLineGraphData_'+index]).length ?
                                                        <div className='mb-n3'>
                                                            <ApexMixedBarLineChart 
                                                                graphData={state['budgetSpendBarLineGraphData_'+index]}
                                                                sparkline={false}
                                                                yaxis={false}
                                                                yaxisLabel={false}
                                                                xaxis={true}
                                                                xaxisFormat={'datetime'}
                                                                xaxisLabel={true}
                                                                axisLabelColor={'#495057'}
                                                                paddingLeft={10}
                                                                legend={true}
                                                                legendPostion={'bottom'}
                                                                legendHorizontalAlign={'center'}
                                                                legendUseSeriesColor={true}
                                                                stacked={false}
                                                                height={200}
                                                                horizontal={false}
                                                                barHeight={'40%'}
                                                                barEndShape={'flat'}
                                                                columnWidth={'25%'}
                                                                gradient={false}
                                                                gradientColor={['#039BE5', '#5F5BA2']}
                                                                hideTooltipValue={true}
                                                                backgroundBarShape={'flat'}
                                                                backgroundBarColors={['#333947']}
                                                                showBackgroundBarColors={false}
                                                                className={'transparentTooltip'}
                                                                colors={['#57AF3E', '#3576BE']}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                            <div className="lg:w-6/12 sm:w-2/4 w-full pl-5">
                                                <ul className="w-1/4 text-xs self-center mb-0 mt-3 text-black flex">
                                                    {state["trendBreakDownTotal_" + index] && Object.entries(state["trendBreakDownTotal_" + index]).map(([key, value], tIndex) => {
                                                        return(
                                                            <React.Fragment>
                                                            <li key={'trend_' + tIndex} id={'legend_'+index+'_'+tIndex} className='flex mb-2'><span className={`h-3 w-3 self-center ${getBgColor(tIndex)}`}></span> <span className={`px-2 ${getTextColor(tIndex)} d-inline-block capitalize`}>{key}</span></li>
                                                            {/* <UncontrolledTooltip target={'legend_'+index+'_'+tIndex}>
                                                                <span className='bg-white border border-extraLightGray p-2 rounded-md'>
                                                                    {key+' : '+value}
                                                                </span>
                                                            </UncontrolledTooltip> */}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </ul>
                                                <div className='flex'>
                                                    <div className='self-center mr-4 mt-3'>
                                                        {state['spendDonutData_'+index] && Object.entries(state['spendDonutData_'+index]).length ? 
                                                            <ApexDonutChart
                                                                labelColor={"#000000"}
                                                                valueColor={"#000000"}
                                                                // labels={['Jobs', 'Workstation', 'Disk', 'Storage']}
                                                                graphData={state['spendDonutData_'+index]}
                                                                legend={false}
                                                                showTotal={true}
                                                                showTotalLable={true}
                                                                showGraphHeading={false}
                                                                height={150}
                                                                width={150}
                                                                size={'70%'}
                                                                gradient={false}
                                                                gradientColor={['#A88CCC', '#D88ACF']}
                                                                // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                                // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                                colors={state.colors}
                                                                className={'transparentTooltip'}
                                                            />
                                                        : null}
                                                    </div>                                        
                                                    {state['spendStackedTrendData_'+index] && Object.entries(state['spendStackedTrendData_'+index]).length ?
                                                        <React.Fragment>
                                                        <div className='-mt-3 -mb-3 mr-2 w-3/4'>
                                                            <ApexStackedBarChart 
                                                                graphData={state['spendStackedTrendData_'+index]}
                                                                sparkline={false}
                                                                yaxis={false}
                                                                yaxisLabel={true}
                                                                xaxis={true}
                                                                xaxisFormat={'datetime'}
                                                                xaxisLabel={true}
                                                                axisLabelColor={'#666666'}
                                                                paddingLeft={-25}
                                                                legend={false}
                                                                stacked={true}
                                                                height={200}
                                                                horizontal={false}
                                                                // barHeight={'40%'}
                                                                barEndShape={'flat'}
                                                                columnWidth={'25%'}
                                                                gradient={true}
                                                                stroke={'hide'}
                                                                // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                                // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                                colors={state.colors}
                                                                hideTooltipValue={true}
                                                                backgroundBarShape={'flat'}
                                                                backgroundBarColors={['#E6E6E6']}
                                                                showBackgroundBarColors={false}
                                                                className={'transparentTooltip'}
                                                            />
                                                        </div>
                                                        </React.Fragment>
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {state['showExpandedSectoin_'+index] ?
                                    state['showLoading_'+index] ?
                                        <div className="w-full lg:w-3/4 flex justify-center mt-4">
                                            <LoadingCircle />
                                        </div>
                                    :
                                        <div className='py-6 border-t border-DeepPurpleTints-600'>
                                            <div className='px-6'>
                                                {state['selectedItemDetails_'+index] && state['selectedItemDetails_'+index].length && state['selectedItemDetails_'+index][0].data && Object.entries(state['selectedItemDetails_'+index][0].data).length ?
                                                    <div className="w-full pr-0">
                                                        <div className='flex flex-wrap justify-start'>
                                                            {state.graphOrder && state.graphOrder.map((order, oIndex) => {
                                                                return(
                                                                    <div key={'order_'+oIndex} className="w-full lg:w-1/2 lg:px-1 pr-0 mb-3">
                                                                        {state[order+'_'+index] && Object.entries(state[order+'_'+index]).length ?
                                                                            <div className='bg-PrimaryTintsPurple-600 border border-NeutralGray-500 p-3 rounded-2xl'>
                                                                                <p className='mb-2 text-black capitalize text-base pr-5'>{removeUnderScore(order)}</p>
                                                                                <div className='mb-1'>
                                                                                    <ApexLineChartSpendBoard
                                                                                        graphData={state[order+'_'+index]}
                                                                                        sparkline={false}
                                                                                        yaxis={true}
                                                                                        yaxisLabel={true}
                                                                                        yaxisBorder={true}
                                                                                        // yaxisTitleLabel={state['selectedChartType_'+index]}
                                                                                        gridYaxisBorder={true}
                                                                                        gridColor={'#434B5E'}
                                                                                        axisLabelColor={'#495057'}
                                                                                        xaxis={true}
                                                                                        xaxisFormat={'datetime'}
                                                                                        xaxisLabel={true}
                                                                                        paddingLeft={10}
                                                                                        legend={false}
                                                                                        stacked={false}
                                                                                        height={300}
                                                                                        customHpcDashboardTooltip={true}
                                                                                        className={'transparentTooltip'}
                                                                                        // colors={['#43b02a', '#009FDF']}
                                                                                        gradient={true}
                                                                                        gradientColor={['#F4F1FA', '#A790D8']}
                                                                                        colors={['#7551C2']}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                            {state['trendTable_'+index] ?
                                                <div className="w-full border-t border-DeepPurpleTints-600 pt-6 mt-6">
                                                    <div className='px-6'>
                                                        <div className="flex justify-between pb-4">
                                                            <p className="text-black font-bold self-center">Showing {state['filteredArray_'+index] && state['filteredArray_'+index].length} of total {state['trendTable_'+index] && state['trendTable_'+index].length} Cost and usage breakdown</p>
                                                            {state['trendTable_'+index] && state['trendTable_'+index].length ?
                                                                <div className="flex">
                                                                    <CSVLink
                                                                        data={state['filteredArray_'+index] ? state['filteredArray_'+index] : []} 
                                                                        headers={state.clusterHeaders ? state.clusterHeaders : []}
                                                                        filename={'spenboard-clusters-'+new Date().getTime()+'.csv'}
                                                                        className={"mt-2"}
                                                                        target="_blank"
                                                                    >
                                                                        <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-8 w-8 px-1 rounded-full self-center ml-3 cursor-pointer' width={24} height={24} />
                                                                    </CSVLink>
                                                                </div>
                                                            : null}
                                                        </div>
                                                        {state['columns_'+index] && state['columns_'+index].length ? 
                                                            <div className="overflow-x-scroll">
                                                                <table className="w-full text-left">
                                                                    <thead className="bg-gray-50">
                                                                        <tr className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600'>
                                                                            {state['columns_'+index].map((col, colIndex) => {
                                                                                return(
                                                                                    <th scope="col" key={'col_'+colIndex} className={`tracking-wider px-3 py-3 text-left ${col.sticky ? 'sticky z-10 left-0 bg-NeutralGray-600 text-white' : 'bg-NeutralGray-400 text-black'} ${colIndex === 1 ? 'sticky z-10 left-[300px] bg-NeutralGray-600 text-white' : ''} text-left text-base font-semisolid uppercase`} style={{minWidth: col.width+'px'}}>{col.Header}</th>
                                                                                )
                                                                            })}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="bg-white text-black">
                                                                        {state['filteredArray_'+index] && state['filteredArray_'+index].length ?
                                                                            state['filteredArray_'+index].map((col, colIndex) => {
                                                                                return(
                                                                                    <tr className={`${colIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'}`} key={'colval_'+colIndex}>
                                                                                        {state['columns_'+index].map((obj, objIndex) => {
                                                                                            return(
                                                                                                <td key={objIndex+'_'+colIndex} className={`p-4 text-black text-left text-sm ${objIndex < 1 ? 'sticky z-10 left-0 bg-NeutralGray-100' : ''} ${objIndex === 1 ? 'sticky z-10 left-[300px] bg-NeutralGray-100 ' : ''} whitespace-nowrap`}>{col[obj.accessor]}</td>
                                                                                            )
                                                                                        })}
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        : null}
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                : null}
                            </div>
                        )
                    })
                : 
                    <div className='flex justify-center m-4 text-black'>
                        There are no data on this criteria. Please try adjusting your filter.
                    </div>
                }
            </div>
        </div>
    )
}
export default QueueDetails