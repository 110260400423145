import React, { useEffect } from 'react';

const ErrorToast = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="error-toast">
      <div className="error-toast-content">
        <p className="m-0"><i className="fa fa-times-circle mr-2" aria-hidden="true"></i> {message}</p>
      </div>
    </div>
  );
};

export default ErrorToast;