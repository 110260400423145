/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 04/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 * 
 *************************************************/
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setCommonPropsDetails } from '../../../actions/commonActionNew';
import { listDiskDetails, getDiskDetails, getDiskMetrics, getQueueMetrics, getClusterHeadNodeMetrics, getClusterMetrics } from '../../../actions/Collider/ObservabilityAction';
import { getClusterDetails } from '../../../actions/Collider/ClusterAction';
import { listWorkstationResults, getWorkStationMetrics } from '../../../actions/CatalogAction'
import { subHours, subDays, getDayFromSelectedDuration, momentConvertionLocalToUtcTime, getCommonInformationMessage } from '../../../utils/utility';

import { HOURLY_CUSTOM_DURATION_OPTIONS } from '../../../utils/constants'

import MetricsTab from '../../common/Mertics/MetricsTab';
import Search from '../../common/SearchWithHiddenInput'
import DetailsTab from '../../common/DetailsTab';
import AlertsTab from './AlertsTab';
import TroubleshootTab from './TroubleshootTab';
import FilterSection from '../../common/FiltersSection';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import { LoadingCircle } from '../../common/LoadingCiricle';
import SelectOption from '../../common/Form/SelectOption';

const LandingPage = ()  => {
    const clickOutside = useRef()
    // State variables
    const [state, setState] = useState({
        showLoading: true,
        selectedGraphDuration: '+3h',
        selectedDuration: '+3h',
        pageType: 'Details',
        selectedMetricsType: 'Core Metrics',
        showSearchBtn: true,
        disableSearchBtn: true,
        categoryList: [],
        assetCategory: [],
        selectedService: [],
        isCategoryOpen: false,
        tagsArray: [0],
        nextErrorListToken: '',
        boxSection: ['Cluster', 'Disk', 'Workstation'],
        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster']
    });
        
    // Refs
    const dateRef = useRef();
    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
    useEffect(() => {
        if(state.showMetricLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState, showMetricLoading: false })) }, 5000);
        }
    }, [state.showMetricLoading])
    useEffect(() => {
        if(state.showDetailsLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState, showDetailsLoading: false })) }, 5000);
        }
    }, [state.showDetailsLoading])


    const onExpandCollapse = useCallback((type) => {
        setState(prevState => ({
            ...prevState,
            selectedLeftOption: type,
            showDetailsLoading: true,
            metricDetails: [],
            totalMenuItems: [],
            selectedAsset: {},
            selectedCluster: {},
            filteredLeftMenuItems: [],
            showLeftMenuLoading: true,
            [prevState.selectedLeftOption + '_section']: false,
            [type + '_section']: type,
            ['show_' + type + '_loading']: true,
            parentExpanded: true
        }));
        setTimeout(() => setState(prevState => ({ ...prevState, ['show_' + type + '_loading']: false })), 3000)

    }, [])

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            onExpandCollapse(state.selectedLeftOption ? state.selectedLeftOption : 'Cluster')
        }
    }, [state.callSearch, state.selectedLeftOption, onExpandCollapse])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            callSearch: true
        }))
    }
    
    // Call onSearch when changesInFilter is updated
    useEffect(() => {
        if (state.functionCallType === 'onLoad' && state.changesInFilter) {
            setState(prevState => ({
                ...prevState,
                changesInFilter: false,
                showLoading: true,
                resetEnabled: false,
                nextErrorListToken: '',
                selectedAsset: {}
            }));        
        }
    }, [state.changesInFilter, state.functionCallType, state]);
    
    const onReset = () => {
        setState(prevState => ({
            ...prevState,
            selectedProvider: '',
            selectedAccount: [],
            selectedDuration: '+7d',
            changesInFilter: state.resetEnabled,
            datePickerStartDate: '',
            datePickerEndDate: ''
        }))
    };

    useEffect(() => {
        if(state.parentExpanded) {
            setState(prevState => ({ ...prevState, parentExpanded: false }))
            if(state.selectedLeftOption === 'Disk') {            
                let params = {}
                params.provider = state.selectedProvider.toLowerCase();            
                if(state.selectedAccount && state.selectedAccount.length) {
                    params.account_id = state.selectedAccount
                }
                if(state.selectedRegion && state.selectedRegion.length) {
                    params.region = state.selectedRegion
                }            
                if(state.selectedClusters && state.selectedClusters.length && !state.selectedClusters.includes("All")) {
                    params.cluster_name = state.selectedClusters
                }
                if(state.selectedProjectTags && state.selectedProjectTags.length) {
                    params.project_tags = state.selectedProjectTags
                }
                dispatch(listDiskDetails(params))
                    .then((response) => {
                        if(response) {
                            let selectedAsset = {}
                            let results = response.results ? response.results : []
                            
                            if (results && results.length) {
                                selectedAsset = results[0]
                            }
                    
                            setState(prevState => ({
                                ...prevState,
                                totalDiskItems: results,
                                filteredDiskItems: results,
                                selectedAsset,
                                showLoading: false,
                                showMetricLoading: true,
                                ['show_' + state.selectedLeftOption + '_loading']: false,
                                callAssetDetails: true
                            }))
                        }
                    })
            } else if(state.selectedLeftOption === 'Cluster') {
                let type = state.selectedLeftOption;
                let params = {};
                params.provider = state.selectedProvider ? state.selectedProvider.toLowerCase() : ''            
                if(state.selectedAccount && state.selectedAccount.length) {
                    params.account_id = state.selectedAccount
                }
                if(state.selectedRegion && state.selectedRegion.length) {
                    params.region = state.selectedRegion
                }
                if(state.selectedClusters && state.selectedClusters.length && !state.selectedClusters.includes("All")) {
                    params.cluster_name = state.selectedClusters
                }
                if(state.selectedProjectTags && state.selectedProjectTags.length) {
                    params.project_tags = state.selectedProjectTags
                }
                dispatch(getClusterDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results ? response.results : []
    
                            let detailsFormat = response.cluster_details ? response.cluster_details : []
            
                            let selectedCluster = {}    
                            let selectedAsset = {}
                            if(results && results.length) {
                                selectedCluster = results[0]
                                selectedAsset = results[0]
                                selectedAsset.selected_cluster_type = "all"
                                // if(results[0].head_node) {
                                //     selectedAsset = results[0].head_node
                                // }
                            }
                            setState(prevState => ({
                                ...prevState,
                                detailsFormat,
                                clusterDetails: results,
                                selectedCluster,
                                [type + '_cluster_' + selectedCluster.cluster_name]: true,
                                // [selectedCluster.cluster_name + '_head_node']: true,
                                selectedAsset,
                                showMetricLoading: true,
                                showDetailsLoading: false,
                                ['show_' + state.selectedLeftOption + '_loading']: false
                            }))
                        }
                })
            } else if(state.selectedLeftOption === 'Workstation') {            
                let params = {"return_all": true, "metrics": false, 'is_asset_present': true}
                if(state.selectedProvider) {
                    params.provider = state.selectedProvider.toLowerCase()
                }
                if(state.selectedAccount && state.selectedAccount.length) {
                    params.account_id = state.selectedAccount
                }
                if(state.selectedRegion && state.selectedRegion.length) {
                    params.region = state.selectedRegion
                }
                if(state.selectedProjectTags && state.selectedProjectTags.length) {
                    params.project_tags = state.selectedProjectTags
                }
                
                dispatch(listWorkstationResults(params))
                    .then((response) => {
                        if(response) {
                            // let totalResults = state.totalWorkstations &&  state.totalWorkstations.length ? state.totalWorkstations : []
    
                            // let selectedAsset = {}
                            // if(response.results && response.results.length) {
                            //     totalResults = totalResults.concat(response.results)
                            //     selectedAsset = totalResults[0]
                            // }
                            let totalResults = []
                            let selectedAsset = {}
                            if(response.results && response.results.length) {
                                totalResults = response.results
                                selectedAsset = totalResults[0]
                            }
    
                            let detailsFormat = response.workstation_details ? response.workstation_details : []
    
                            setState(prevState => ({
                                ...prevState,
                                totalWorkstations: totalResults,
                                selectedAsset,
                                showLoading: false,
                                detailsFormat,
                                callAssetDetails: true,
                                ['show_' + state.selectedLeftOption + '_loading']: false
                            }));
                        }
                    })
            }
        }
    }, [state, dispatch]);

    useEffect(() => {        
        let type = state.selectedLeftOption;
        let data = []
        if(type === 'Disk') {
            data = state.totalDiskItems
        } else if(type === 'Cluster') {
            data = state.clusterDetails
        } else if(type === 'Workstation') {
            data = state.totalWorkstations
        }
        setState(prevState => ({
            ...prevState,
            totalMenuItems: data,
            filteredLeftMenuItems: data,
            showLeftMenuLoading: false,            
        }));
    }, [state.totalDiskItems, state.clusterDetails, state.totalWorkstations, state.selectedLeftOption])

    useEffect(() => {
        if(state.callAssetDetails) {
            // let type = state.selectedLeftOption;
            let pageType = state.pageType
            setState(prevState => ({
                ...prevState,
                pageType: '',
                showDetailsLoading: true,
                // metricDetails: {},
                callAssetDetails: false,
            }));

            if(pageType) {
                setTimeout(() => { setState(prevState => ({ ...prevState, pageType, showDetailsLoading: false })) }, 2000);
            }
        }

    }, [state.callAssetDetails, state.selectedLeftOption, state.pageType])

    

    const getDiskDetailsFunction = useCallback(() => {
        let params = {};
        params.provider = state.selectedAsset && state.selectedAsset.provider;
        params.service_name = state.selectedAsset && state.selectedAsset.service_name;
        params.asset_id = state.selectedAsset && state.selectedAsset.asset_id ? state.selectedAsset.asset_id : '';
        
        params.provider = state.selectedProvider.toLowerCase();            
        if(state.selectedAccount && state.selectedAccount.length) {
            params.account_id = state.selectedAccount
        }
        if(state.selectedRegion && state.selectedRegion.length) {
            params.region = state.selectedRegion
        }
        if(state.selectedProjectTags && state.selectedProjectTags.length) {
            params.project_tags = state.selectedProjectTags
        }

        if (state.selectedAsset && state.selectedAsset.asset_id) {            
            dispatch(getDiskDetails(params))
                .then((response) => {
					if(response) {
                        let detailsFormat = response.disk_details ? response.disk_details : []
                        let results = response.results ? response.results : []
                        setState(prevState => ({
                            ...prevState,
                            detailsFormat,
                            diskDetails: results,
                            showDetailsLoading: false,
                        }));
                    }
            });
        }   
    }, [state.selectedAsset, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.selectedProjectTags])    

    const getDiskMetricsFunction = useCallback(() => {
        let duration = state.selectedGraphDuration

        let period = duration.substring(duration.length, duration.length - 1);
        let durationBtw = getDayFromSelectedDuration(duration);
        let startTime = '';
        if (period === 'd') {
            startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        } else {
            startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        }
        let endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');

        let params = {};
        params.start_time = startTime;
        params.end_time = endTime;
        params.asset_id = state.selectedAsset && state.selectedAsset.asset_id;        
        if(state.selectedAsset && state.selectedAsset.account_id) {
            params.account_id = state.selectedAsset.account_id
        }
        if(state.selectedAsset && state.selectedAsset.region) {
            params.region = state.selectedAsset.region
        }
        if(state.selectedProjectTags && state.selectedProjectTags.length) {
            params.project_tags = state.selectedProjectTags
        }
    
        if (state.selectedAsset && state.selectedAsset.asset_id) {
            dispatch(getDiskMetrics(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({
                            ...prevState,
                            metricDetails: response,
                            showMetricLoading: false
                        }));
                    }
                })
        } else {
            setState(prevState => ({
                ...prevState,
                metricDetails: [],
                showMetricLoading: false
            }))
        }
    }, [dispatch, state.selectedAsset, state.selectedGraphDuration, state.selectedProjectTags])
    
    
    const getQueueMetricsFunction = useCallback(() => {
        let duration = state.selectedGraphDuration

        let period = duration.substring(duration.length, duration.length - 1);
        let durationBtw = getDayFromSelectedDuration(duration);
        let startTime = '';
        if (period === 'd') {
            startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        } else {
            startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        }
        let endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');

        let params = {};
        params.start_time = startTime;
        params.end_time = endTime;
        params.asset_id = state.selectedAsset && state.selectedAsset.asset_id;
        if(state.selectedQueue) {
            params.queue_name = state.selectedQueue;
        }
        
        if(state.selectedAsset && state.selectedAsset.account_id) {
            params.account_id = state.selectedAsset.account_id
        }
        if(state.selectedAsset && state.selectedAsset.region) {
            params.region = state.selectedAsset.region
        }
        if(state.selectedProjectTags && state.selectedProjectTags.length) {
            params.project_tags = state.selectedProjectTags
        }
    
        if (state.selectedAsset && state.selectedAsset.asset_id) {            
            dispatch(getQueueMetrics(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({
                            ...prevState,
                            metricDetails: response,
                            showMetricLoading: false
                        }));
                    }
                });
        }
    }, [dispatch, state.selectedAsset, state.selectedQueue, state.selectedGraphDuration, state.selectedProjectTags])

    const getClusterMetricsFunction = useCallback(() => {
        let duration = state.selectedGraphDuration

        let period = duration.substring(duration.length, duration.length - 1);
        let durationBtw = getDayFromSelectedDuration(duration);
        let startTime = '';
        if (period === 'd') {
            startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        } else {
            startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        }
        let endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');

        let params = {};
        params.start_time = startTime;
        params.end_time = endTime;
        params.asset_id = state.selectedAsset && state.selectedAsset.asset_id
        if(state.selectedAsset && state.selectedAsset.account_id) {
            params.account_id = state.selectedAsset.account_id
        }
        if(state.selectedAsset && state.selectedAsset.region) {
            params.region = state.selectedAsset.region
        }
        if(state.selectedProjectTags && state.selectedProjectTags.length) {
            params.project_tags = state.selectedProjectTags
        }
    
        if (state.selectedAsset && state.selectedAsset.asset_id) {
            dispatch(getClusterMetrics(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({
                            ...prevState,
                            metricDetails: response,
                            showMetricLoading: false
                        }));
                    }
                })
        }
    }, [dispatch, state.selectedAsset, state.selectedGraphDuration, state.selectedProjectTags])
    
    const getClusterHeadNodeMetricsFunction = useCallback(() => {
        let duration = state.selectedGraphDuration

        let period = duration.substring(duration.length, duration.length - 1);
        let durationBtw = getDayFromSelectedDuration(duration);
        let startTime = '';
        if (period === 'd') {
            startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        } else {
            startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        }
        let endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');

        let params = {};
        params.start_time = startTime;
        params.end_time = endTime;
        params.asset_id = state.selectedAsset && state.selectedAsset.asset_id;
        if (state.selectedProcess) {
            params.process_name = state.selectedProcess;
        }
        
        if(state.selectedAsset && state.selectedAsset.account_id) {
            params.account_id = state.selectedAsset.account_id
        }
        if(state.selectedAsset && state.selectedAsset.region) {
            params.region = state.selectedAsset.region
        }
        if(state.selectedProjectTags && state.selectedProjectTags.length) {
            params.project_tags = state.selectedProjectTags
        }

        if (state.selectedAsset && state.selectedAsset.asset_id) {
            dispatch(getClusterHeadNodeMetrics(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({
                            ...prevState,
                            metricDetails: response,
                            showMetricLoading: false
                        }));
                    }
                })
        }
    }, [dispatch, state.selectedAsset, state.selectedProcess, state.selectedGraphDuration, state.selectedProjectTags])

    
    const getWorkStationMetricsFunction = useCallback(() => {
        let duration = state.selectedGraphDuration

        let startTime = '';
        let endTime = ''
        if(duration === 'custom') {
            startTime = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss');
            endTime = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss');
        } else {
            let period = duration.substring(duration.length, duration.length - 1);
            let durationBtw = getDayFromSelectedDuration(duration);
            
            if (period === 'd') {
                startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            } else {
                startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            }
            endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');
        }


        let params = {};
        params.start_time = startTime;
        params.end_time = endTime;
        params.asset_id = state.selectedAsset && state.selectedAsset.asset_id       
        
        if(state.selectedAsset && state.selectedAsset.account_id) {
            params.account_id = state.selectedAsset.account_id
        }
        if(state.selectedAsset && state.selectedAsset.region) {
            params.region = state.selectedAsset.region
        }
        if(state.selectedProjectTags && state.selectedProjectTags.length) {
            params.project_tags = state.selectedProjectTags
        }

        if(params.asset_id) {
            dispatch(getWorkStationMetrics(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({ ...prevState, metricDetails: response, showMetricLoading: true }))
                    }
                })
        } else {
            setState(prevState => ({ ...prevState, metricDetails: [], showMetricLoading: true }))
        }
    }, [dispatch, state.selectedAsset, state.selectedGraphDuration, state.datePickerStartDate, state.datePickerEndDate, state.selectedProjectTags])

    useEffect(() => {
        if(state.selectedAsset) {
            if(state.selectedLeftOption === 'Disk') {
                getDiskDetailsFunction()
                getDiskMetricsFunction()
            } else if(state.selectedLeftOption === 'Cluster') {
                if(state.selectedAsset && state.selectedAsset.selected_cluster_type === "all") {
                    getClusterMetricsFunction()
                } else {
                    if(state.selectedAsset.queue_name) {
                        getQueueMetricsFunction()
                    } else {
                        getClusterHeadNodeMetricsFunction()
                    }
                }
            } else if(state.selectedLeftOption === 'Workstation') {
                getWorkStationMetricsFunction()
            }
        }
    }, [state.selectedAsset, getDiskDetailsFunction, getDiskMetricsFunction, getQueueMetricsFunction, getClusterHeadNodeMetricsFunction, getWorkStationMetricsFunction, getClusterMetricsFunction, state.selectedLeftOption])

    const onClickCluster = (item) => {
        let selectedAsset = item
        selectedAsset.selected_cluster_type = "all"
        let type = state.selectedLeftOption;
        let headNode = false;
        let queueSection = false;
        let selectedProcess = '';
        let selectedQueue = '';
        // if (item.head_node) {
        //     selectedAsset = item.head_node;
        //     headNode = true;
        // } else if (item.queues && item.queues.length) {
        //     queueSection = true;
        //     selectedAsset = item.queues[0];
        //     selectedQueue = item.queues[0].queue_name;
        // }

        let expandedCluster = state.selectedCluster && state.selectedCluster.cluster_name;
        
        setState(prevState => ({
            ...prevState,
            [type + '_cluster_' + expandedCluster]: false,
            [type + '_cluster_' + item.cluster_name]: !prevState[type + '_cluster_' + item.cluster_name],
            [item.cluster_name + '_head_node']: headNode,
            [item.cluster_name + '_queue_section']: queueSection,
            selectedCluster: item,
            selectedAsset,
            selectedProcess,
            selectedQueue,
            callAssetDetails: true,
            // setPageType: true
        }));
    }

    // useEffect(() => {
    //     if(state.setPageType) {
    //         let pageType = state.pageType
    //         setState(prevState => ({ ...prevState, pageType: '', setPageType: false  }))
    //         setTimeout(() => {
    //             setState(prevState => ({ ...prevState, pageType }))
    //         }, 2000);        
    //     }
    // }, [state.setPageType, state.pageType])
    
    const onClickClusterProcess = (type, item) => {
        if(type === 'process') {
            setState(prevState => ({ ...prevState, selectedProcess: item, selectedQueue: '', showDetailsLoading: false, showMetricLoading: true, callAssetDetails: true }))
        } else {
            setState(prevState => ({ ...prevState, selectedAsset: item, selectedQueue: item.queue_name, selectedProcess: '', showDetailsLoading: false, showMetricLoading: true, metricDetails: [], callAssetDetails: true }))
        }
    }

    const onExpandCollapseClusterChild = (section, item) => {
        setState(prevState => ({ 
            ...prevState,
            [item.cluster_name + '_head_node']: {},
            [item.cluster_name + '_queue_section']: {},
            selectedAsset: {},
            selectedProcess: "",
            selectedQueue: "",
            selectedSection: section,
            selectedItemDetails: item,
            expandChild: true,
        }))
    };
    
    useEffect(() => {
        if(state.expandChild) {
            let section = state.selectedSection
            let item = state.selectedItemDetails
            
            let selectedAsset = {};
            let headNode = false;
            let queueSection = false;
            let selectedProcess = '';
            let selectedQueue = '';
            if (section === 'headNode' && item.head_node) {
                selectedAsset = item.head_node;
                selectedAsset.selected_cluster_type = ""
                headNode = true;
            } else if (item.queues && item.queues.length) {
                selectedAsset = item.queues[0];
                selectedAsset.selected_cluster_type = ""
                queueSection = true;
                selectedQueue = item.queues[0].queue_name;
            }
            setState(prevState => ({
                ...prevState,
                [item.cluster_name + '_head_node']: headNode,
                [item.cluster_name + '_queue_section']: queueSection,
                selectedAsset,
                selectedProcess,
                selectedQueue,
                callAssetDetails: true,
                expandChild: false
            }));
        }
    }, [state.expandChild, state.selectedSection, state.selectedItemDetails])    
    
    // const handleSelectNew = (date) => {
    //     let datePickerStartDate = momentDateGivenFormat(date[0], 'YYYY-MM-DD HH:mm:00');
    //     let datePickerEndDate = momentDateGivenFormat(date[1], 'YYYY-MM-DD HH:mm:00');
    //     setState(prevState => ({
    //         ...prevState,
    //         datePickerStartDate,
    //         datePickerEndDate,
    //         showDateRangePicker: false,
    //         selectedGraphDuration: "custom",
    //         metricDetails: [], 
    //         showMetricLoading: true,
    //     }));
    // };

    const handleChildClick = (event, type, dropdownType, section) => {	
        
		if (dateRef.current && !dateRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}

        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}    

    return (
        <div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="lg:w-2/6 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Observability</p>
                        <p className="text-white text-base">Unified view of Metrics, Alerts, and Events in your Cloud HPC</p>
                    </div>
                    <div className="lg:w-4/6 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='flex flex-wrap m-2 item-stretch'>
                <div className={`lg:w-1/5 md:w-1/3 w-full flex flex-col flex-grow border border-DeepPurpleTints-600 bg-DeepPurpleTints-600 rounded-2xl pt-6 px-3`}>
                    <div className='w-full overflow-y-auto h-[calc(100vh-200px)]'>
                        {state.boxSection && state.boxSection.map(row => {
                            return(
                                <div className={`bg-NeutralGray-200 px-3 py-5 rounded-md w-full overflow-auto mb-2`} key={row}>
                                    <div className='flex justify-between text-black' onClick={() => {
                                        if(state[row+'_section'] !== row) {
                                            onExpandCollapse(row)
                                        } else {
                                            setState(prevState => ({ ...prevState, [row + '_section']: '' }))
                                        }
                                    }}>
                                        <p className='text-base font-bold'>{row}</p>
                                        <Icon icon={`icon-park-outline:${state[row+'_section'] ? 'up' : 'down'}`} className={`text-black`} width={24} height={24} />
                                    </div>
                                    {state[row+'_section'] ?
                                        <div className=''>
                                        {!state['show_'+row+'_loading'] && !state.showLeftMenuLoading ?
                                            <React.Fragment>
                                            <div className='flex self-center mb-[22px] pt-4'>
                                                <Search
                                                    data={state.totalMenuItems ? state.totalMenuItems : []}
                                                    topClassName={'border border-NeutralGray-600 rounded-full bg-white w-full'}
                                                    className={'rounded-full'}
                                                    filteredData={(filteredLeftMenuItems, searchText) => {
                                                        setState(prevState => ({ ...prevState, filteredLeftMenuItems, searchText, emptySearchText: false }))
                                                    }}
                                                    resetSearchBox={state.emptySearchText}
                                                />
                                            </div>
                                            {state.filteredLeftMenuItems && state.filteredLeftMenuItems.length ?
                                                state.filteredLeftMenuItems.map((item, i) => {
                                                    return (
                                                        <div key={"item_"+i} className=''>
                                                        {state.selectedLeftOption === 'Workstation' ?
                                                            <div key={"workstation_"+i}>
                                                                <div className={`bg-white px-3 py-3 cursor-pointer font-medium text-base ${state.selectedAsset && state.selectedAsset.workstation_id === item.workstation_id ? ' text-primaryPurple-600' : 'text-black'}`} id={state.selectedLeftOption+'_asset_name_'+item.asset_id} onClick={() => setState(prevState => ({ ...prevState, selectedAsset: item, showMetricLoading: true, metricDetails: [], callAssetDetails: true, showDetailsLoading: true, selectedCluster: {} }))}>
                                                                    <div className='flex'>                                                                 
                                                                        <p className={`text-base pl pr-1 pt-2 pb-1 truncate ${state.selectedAsset && state.selectedAsset.asset_id === item.asset_id ? 'text-primaryPurple-600' : 'text-black'}`}>
                                                                            {item.id_name_map ?
                                                                                <span>
                                                                                    {(item.id_name_map.value ? item.id_name_map.value : item.image_id)}
                                                                                    <span className={`ml-1 ${state.selectedAsset && state.selectedAsset.asset_id === item.asset_id ? 'text-primaryPurple-600' : 'text-NeutralGray-600'} mb-0`}>
                                                                                        {item.id_name_map.key ? ' : '+item.id_name_map.key : ''}
                                                                                    </span>
                                                                                </span>
                                                                            : null}
                                                                        </p>
                                                                    </div>
                                                                    <div className='flex flex-wrap mb-1'>
                                                                        <p className='mr-1 mb-0 text-NeutralGray-600'>{item.service_name ? item.service_name : ''}</p>
                                                                        {item.alerts ?
                                                                            <span className='rounded-full text-white bg-yellowOrange-600 border border-yellowOrange-600 text-base py-1 px-2 self-center'>Alerts {item.alerts}</span>
                                                                        : null}
                                                                        {item.anomalies ?
                                                                            <span className='rounded-full text-white bg-yellowOrange-600 border border-yellowOrange-600 text-base py-1 px-2 self-center'>Anomalies {item.anomalies}</span>
                                                                        : null}
                                                                    </div>
                                                                    
                                                                    {/* <UncontrolledTooltip placement='right' target={state.selectedLeftOption+'_asset_name_'+item.asset_id}>
                                                                        <div className='bg-white rounded-md p-3'>
                                                                            <p className='mb-1 text-lightGray wordBreakAll'><span className='text-black mr-1'>Asset: </span>
                                                                                {item.id_name_map && item.id_name_map !== '' ?
                                                                                    <span className={`ml-1`}>{(item.id_name_map.value ? item.id_name_map.value : '')}</span>
                                                                                :
                                                                                    <span className={`ml-1`}>{item.asset_name}</span>
                                                                                }
                                                                            </p>
                                                                            {item.id_name_map && item.id_name_map !== '' ?
                                                                                item.id_name_map.key ? 
                                                                                    <p className={`mb-1 wordBreakAll`}>{item.id_name_map.key}</p>
                                                                                : null
                                                                            :
                                                                                item.resource_type ?
                                                                                    item.resource_type === 'ApiGateway' ?
                                                                                        item.rest_api_name && item.rest_api_name !== '' ?
                                                                                            <p className={`mb-1 wordBreakAll`}>{item.rest_api_name})</p>
                                                                                        : null
                                                                                    : item.resource_type === 'EC2' ?
                                                                                        item.tags && item.tags.length && item.tags.filter(e => e.key === 'Name').length ?
                                                                                            <p className={`mb-1 wordBreakAll`}>{item.tags.filter(e => e.key === 'Name')[0].value}</p>
                                                                                        : null
                                                                                    : null
                                                                                : null
                                                                            }
                                                                            <p className='mb-1 text-lightGray'>
                                                                                <span className='text-black mr-1'>Resources: </span>
                                                                                {item.provider ? item.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                {item.account_id ? (' : ')+item.account_id : <span>&nbsp;</span>}
                                                                                {item.region ?  (' : ')+getRegionName(item.region, state.regions) : <span>&nbsp;</span>}
                                                                            </p>
                                                                            <p className='mb-1 text-lightGray'>
                                                                                <span className='text-black mr-1'>Services: </span>
                                                                                {item.resource_type ?  ('  ')+item.resource_type : <span>&nbsp;</span>}
                                                                            </p>
                                                                            <div className='flex flex-wrap'>
                                                                                {item.alerts ?
                                                                                    <span className='badge risk-badge-critical f10 mx-1 self-center py-1'>Alerts {item.alerts}</span>
                                                                                : null}
                                                                                {item.anomalies ?
                                                                                    <span className='badge risk-badge-critical f10 mx-1 self-center py-1'>Anomalies {item.anomalies}</span>
                                                                                : null}
                                                                                {item.errors ?
                                                                                    <span className='badge risk-badge-critical f10 mx-1 self-center py-1'>Errors {item.errors}</span>
                                                                                : null}
                                                                                {item.events ?
                                                                                    <span className='badge risk-badge-critical f10 mx-1 self-center py-1'>Events {item.events}</span>
                                                                                : null}
                                                                            </div>
                                                                            
                                                                        </div>    
                                                                    </UncontrolledTooltip> */}
                                                                </div>
                                                            </div>
                                                        : state.selectedLeftOption === 'Disk' ?
                                                            <div key={"disk_"+i}>
                                                                <div className={`bg-white px-3 py-3 cursor-pointer font-medium text-base ${state.selectedAsset && state.selectedAsset.asset_id === item.asset_id ? ' text-primaryPurple-600' : 'text-black'}`} id={state.selectedLeftOption+'_asset_name_'+item.asset_id} onClick={() => setState(prevState => ({ ...prevState, selectedAsset: item, showMetricLoading: true, metricDetails: [], callAssetDetails: true, showDetailsLoading: true, selectedCluster: {} }))}>
                                                                    <div className='flex'>
                                                                        <p className={`text-base pl pr-1 pt-2 pb-1 truncate ${state.selectedAsset && state.selectedAsset.asset_id === item.asset_id ? 'text-primaryPurple-600' : 'text-black'}`}>
                                                                            {item.id_name_map ?
                                                                                <span>
                                                                                    {(item.id_name_map.value ? item.id_name_map.value : '')}
                                                                                    <span className={`ml-1 ${state.selectedAsset && state.selectedAsset.asset_id === item.asset_id ? 'text-primaryPurple-600' : 'text-NeutralGray-600'} mb-0`}>
                                                                                        {item.id_name_map.key ? ' : '+item.id_name_map.key : ''}
                                                                                    </span>
                                                                                </span>
                                                                            : null}
                                                                        </p>
                                                                    </div>
                                                                    <div className='flex flex-wrap mb-1'>
                                                                        <p className='mr-1 mb-0 text-NeutralGray-600'>{item.storage_type ? item.storage_type : ''}</p>
                                                                        {item.alerts ?
                                                                            <span className='rounded-full text-white bg-yellowOrange-600 border border-yellowOrange-600 text-base py-1 px-2 self-center'>Alerts {item.alerts}</span>
                                                                        : null}
                                                                        {item.anomalies ?
                                                                            <span className='rounded-full text-white bg-yellowOrange-600 border border-yellowOrange-600 text-base py-1 px-2 self-center'>Anomalies {item.anomalies}</span>
                                                                        : null}
                                                                        <p className='mr-1 mb-0 text-lightGray'>{item.storage_type ? item.storage_type : ''}</p>
                                                                    </div>
                                                                    
                                                                    {/* <UncontrolledTooltip placement='right' target={state.selectedLeftOption+'_asset_name_'+item.asset_id}>
                                                                        <div className='bg-white rounded-md p-3'>
                                                                            <p className='mb-1 text-lightGray wordBreakAll'><span className='text-black mr-1'>Asset: </span>
                                                                                {item.id_name_map && item.id_name_map !== '' ?
                                                                                    <span className={`ml-1`}>{(item.id_name_map.value ? item.id_name_map.value : '')}</span>
                                                                                :
                                                                                    <span className={`ml-1`}>{item.asset_name}</span>
                                                                                }
                                                                            </p>
                                                                            {item.id_name_map && item.id_name_map !== '' ?
                                                                                item.id_name_map.key ? 
                                                                                    <p className={`mb-1 wordBreakAll`}>{item.id_name_map.key}</p>
                                                                                : null
                                                                            :
                                                                                item.resource_type ?
                                                                                    item.resource_type === 'ApiGateway' ?
                                                                                        item.rest_api_name && item.rest_api_name !== '' ?
                                                                                            <p className={`mb-1 wordBreakAll`}>{item.rest_api_name})</p>
                                                                                        : null
                                                                                    : item.resource_type === 'EC2' ?
                                                                                        item.tags && item.tags.length && item.tags.filter(e => e.key === 'Name').length ?
                                                                                            <p className={`mb-1 wordBreakAll`}>{item.tags.filter(e => e.key === 'Name')[0].value}</p>
                                                                                        : null
                                                                                    : null
                                                                                : null
                                                                            }
                                                                            <p className='mb-1 text-lightGray'>
                                                                                <span className='text-black mr-1'>Resources: </span>
                                                                                {item.provider ? item.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                {item.account_id ? (' : ')+item.account_id : <span>&nbsp;</span>}
                                                                                {item.region ?  (' : ')+getRegionName(item.region, state.regions) : <span>&nbsp;</span>}
                                                                            </p>
                                                                            <p className='mb-1 text-lightGray'>
                                                                                <span className='text-black mr-1'>Services: </span>
                                                                                {item.resource_type ?  ('  ')+item.resource_type : <span>&nbsp;</span>}
                                                                            </p>
                                                                            <div className='flex flex-wrap'>
                                                                                {item.alerts ?
                                                                                    <span className='badge risk-badge-critical f10 mx-1 self-center py-1'>Alerts {item.alerts}</span>
                                                                                : null}
                                                                                {item.anomalies ?
                                                                                    <span className='badge risk-badge-critical f10 mx-1 self-center py-1'>Anomalies {item.anomalies}</span>
                                                                                : null}
                                                                                {item.errors ?
                                                                                    <span className='badge risk-badge-critical f10 mx-1 self-center py-1'>Errors {item.errors}</span>
                                                                                : null}
                                                                                {item.events ?
                                                                                    <span className='badge risk-badge-critical f10 mx-1 self-center py-1'>Events {item.events}</span>
                                                                                : null}
                                                                            </div>
                                                                            
                                                                        </div>    
                                                                    </UncontrolledTooltip> */}
                                                                </div>
                                                            </div>
                                                        : state.selectedLeftOption === 'Cluster' ?
                                                            <div key={"cluster_"+i}>
                                                                <div className={`bg-white px-3 py-3 cursor-pointer font-medium text-base`}>
                                                                    <div className='flex justify-between self-center mb-2' onClick={() => {
                                                                            setState(prevState => ({ ...prevState, showDetailsLoading: true, showMetricLoading: true,  metricDetails: [] }))
                                                                            onClickCluster(item)
                                                                        }}>
                                                                        <div className='cursor-pointer' id={state.selectedLeftOption+'_asset_name_'+item.cluster_name}>
                                                                            <div className='flex flex-wrap'>
                                                                                {item.asset_name ? 
                                                                                    <p className={`truncate mb-0 wordBreakAll ${state.selectedCluster && state.selectedCluster.cluster_name === item.cluster_name ? 'text-primaryPurple-600' : 'text-black'}`}>{item.asset_name}</p>
                                                                                : null}
                                                                            </div>
                                                                        </div>
                                                                        {item.head_node && state[state.selectedLeftOption+'_cluster_'+item.cluster_name] ? 
                                                                            <Icon icon="icon-park-outline:up" className={`text-black self-center`} width={24} height={24} />
                                                                        : 
                                                                            <Icon icon="icon-park-outline:down" className={`text-black self-center`} width={24} height={24} />
                                                                        }
                                                                    </div>
                                                                    {/* <UncontrolledTooltip placement='right' target={state.selectedLeftOption+'_asset_name_'+item.cluster_name}>
                                                                        <div className='bg-white rounded-md p-3'>
                                                                            <p className='mb-1 text-lightGray wordBreakAll'><span className='text-black mr-1'>Cluster: </span>
                                                                                {item.cluster_name}
                                                                            </p>
                                                                            {item.asset_name ? 
                                                                                <p className='mb-1 text-lightGray wordBreakAll'><span className='text-black mr-1'>Asset: </span>
                                                                                    {item.asset_name}
                                                                                </p>
                                                                            : null}
                                                                            <p className='mb-1 text-lightGray'>
                                                                                <span className='text-black mr-1'>Resources: </span>
                                                                                {item.provider ? item.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                {item.account_id ? (' : ')+item.account_id : <span>&nbsp;</span>}
                                                                                {item.region ?  (' : ')+getRegionName(item.region, state.regions) : <span>&nbsp;</span>}
                                                                            </p>
                                                                            <p className='mb-1 text-lightGray'>
                                                                                <span className='text-black mr-1'>Services: </span>
                                                                                {item.resource_type ?  ('  ')+item.resource_type : <span>&nbsp;</span>}
                                                                            </p>
                                                                            <div className='flex flex-wrap'>
                                                                                {item.alerts ?
                                                                                    <span className='badge risk-badge-critical f10 mx-1 self-center py-1'>Alerts {item.alerts}</span>
                                                                                : null}
                                                                                {item.anomalies ?
                                                                                    <span className='badge risk-badge-critical f10 mx-1 self-center py-1'>Anomalies {item.anomalies}</span>
                                                                                : null}
                                                                            </div>
                                                                            
                                                                        </div>    
                                                                    </UncontrolledTooltip> */}
                                                                    {state[state.selectedLeftOption+'_cluster_'+item.cluster_name] && item.head_node ? 
                                                                        <div className={`pb-2 pl-3`}>
                                                                            <div className={``} onClick={() => {
                                                                                setState(prevState => ({ ...prevState, showDetailsLoading: true, showMetricLoading: true,  metricDetails: [], }))
                                                                                onExpandCollapseClusterChild('headNode', item)
                                                                            }}>
                                                                                <div className={`flex pb-2 cursor-pointer ${item.processes && item.processes.length ? 'text-black' : 'text-primaryPurple-600'}`}>
                                                                                    {item.processes && item.processes.length ?
                                                                                        state[item.cluster_name+'_head_node'] ? 
                                                                                            <Icon icon="mdi-light:minus" className={`text-black self-center cursor-pointer`} width={24} height={24} />
                                                                                        : 
                                                                                            <Icon icon="iconoir:plus" width={24} height={24}  className='text-black self-center cursor-pointer' />
                                                                                    : null}
                                                                                    <p className={`${item.processes && item.processes.length && state[item.cluster_name+'_head_node'] ? 'text-primaryPurple-600' : 'text-black'} self-center`} >Head Node</p>
                                                                                </div>
                                                                            </div>
                                                                            {state[item.cluster_name+'_head_node'] && item.processes ?
                                                                                <div className='mb-2 pl-2 border border-NeutralGray-400 rounded'>
                                                                                    {item.processes.map(prc => {
                                                                                        return(
                                                                                        <div key={"clu_"+prc} className='flex pl-3 mr-1' onClick={() => onClickClusterProcess('process', prc)}>
                                                                                            <p className={`truncate m-0 pr-1 py-2 ${state.selectedProcess === prc ? 'text-primaryPurple-600' : 'text-black'}`}>
                                                                                                {prc}
                                                                                                <span className='ml-1 text-NeutralGray-600'>(process)</span>
                                                                                            </p>
                                                                                        </div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : null}
                                                                        
                                                                            {item.queues && item.queues.length ?
                                                                                <div className={`flex pb-2 mt-3 ${state.selectedQueue ? 'text-primaryPurple-600' : 'text-black'}`} onClick={() => setState(prevState => ({ ...prevState, showDetailsLoading: false }), onExpandCollapseClusterChild('queueSection', item))}>
                                                                                    {state[item.cluster_name+'_queue_section'] ? 
                                                                                        <Icon icon="mdi-light:minus" className={`text-black self-center cursor-pointer`} width={24} height={24} />
                                                                                    : 
                                                                                        <Icon icon="iconoir:plus" width={24} height={24}  className='text-black self-center cursor-pointer' />
                                                                                    }
                                                                                    <p className={`mb-0 ${item.processes && item.processes.length && state[item.cluster_name+'_queue_section'] ? 'text-primaryPurple-600' : 'text-black'} self-center`}>Queues</p>
                                                                                </div>
                                                                            : null}
                                                                            {state[item.cluster_name+'_queue_section'] && item.queues && item.queues.length ?
                                                                                <div className='mb-2 pl-2 border border-NeutralGray-400 rounded'>
                                                                                    {item.queues.map(que => {
                                                                                        return(
                                                                                        <div className='flex' key={"que_"+que.name} onClick={() => onClickClusterProcess('queue', que)}>
                                                                                            <div className='flex truncate m-0 pr-1 py-2'>
                                                                                                <p className={`truncate m-0 text-md pl pr-1 pt-2 pb-1 ${state.selectedQueue === que.queue_name ? 'text-primaryPurple-600' : 'text-black'}`}>
                                                                                                    {que.queue_name}
                                                                                                </p>
                                                                                            </div>
                                                                                            
                                                                                            {que.asset_name ? 
                                                                                                <div className={`truncate self-center m-0 pr-1 pt-2`}>
                                                                                                    <p className={`truncate text-NeutralGray-600`}>{que.asset_name}</p>
                                                                                                </div>
                                                                                            : null}
                                                                                        </div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : null}
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                            </div>
                                                        : null}
                                                        </div>
                                                    )
                                                })
                                            : 
                                                <div className='flex justify-center m-4'>
                                                    {getCommonInformationMessage('observability')}
                                                </div>
                                            }
                                            </React.Fragment>
                                        :
                                            <div className='flex justify-center m-4'>
                                                <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                    <path d="M4 12a8 8 0 0112-6.9" />
                                                </svg>
                                            </div>
                                        }
                                        </div>
                                    : null}
                                </div>
                            )
                        })}
                    </div> 
                </div>                                 
                <div className={`lg:w-2/5 md:w-2/3 w-full lg:px-2 md:px-2 px-0 lg:mt-0 md:mt-0 mt-2 flex flex-col flex-grow`}>
                    <div className='overflow-y-auto h-[calc(100vh-180px)] rounded-2xl pt-7 px-4 bg-white border border-DeepPurpleTints-600'>
                        {state.selectedAsset && state.selectedAsset.asset_name ?
                            <div className='flex justify-between mb-3'>
                                <p className='pb-1 text-sm'>
                                    Showing data in <span className='font-base'>(local time)</span>
                                    <div className='flex justify-between text-NeutralGray-600'>
                                        {state.selectedAsset && state.selectedAsset.id_name_map ?
                                            <span className='text-primaryPurple-600'>
                                                <span className=''>{state.selectedProcess ? state.selectedProcess : (state.selectedQueue ? state.selectedQueue : "")}</span>
                                                {(state.selectedAsset.id_name_map.value ? state.selectedAsset.id_name_map.value : state.selectedAsset.image_id)}
                                                <span className={`mr-1 text-primaryPurple-600 mb-0`}>
                                                    {state.selectedAsset.id_name_map.key ? ' : '+state.selectedAsset.id_name_map.key : ''}
                                                </span>
                                            </span>
                                        : state.selectedAsset && state.selectedAsset.asset_name ?
                                            <span className={`text-primaryPurple-600 mb-0`}>
                                                <span className='mr-1'>{state.selectedProcess ? state.selectedProcess : (state.selectedQueue ? state.selectedQueue : "")}</span>
                                                {state.selectedAsset.asset_name}
                                            </span>
                                        : null}
                                    </div>
                                </p>
                                <div className="flex">
                                    <SelectOption
                                        valueLabel={"Duration"}
                                        fields={["value", "option"]}
                                        options={HOURLY_CUSTOM_DURATION_OPTIONS}
                                        selectedValues={state.selectedGraphDuration ? state.selectedGraphDuration : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, metricDetails: [], showMetricLoading: true, selectedGraphDuration: value }))
                                        }}
                                        singleSelection={true}
                                        classDetails={{border: "border border-primaryPurple-600", padding: "py-2.5 px-3", bg: 'bg-lavender-100', rounded: 'rounded-full', optionPosition: 'right-0' }}
                                    />
                                </div>
                            </div>
                        :
                            <div className='flex justify-center m-4'>               
                                <p className='m-3 self-center text-black'>Please select an asset to get the Metric details</p>
                            </div>
                        }
                        {state.metricDetails && Object.entries(state.metricDetails).length && !state.showMetricLoading ?
                            <MetricsTab
                                metricDetails={state.metricDetails}
                                selectedDetails={state.selectedAsset}
                                height={115}
                                axisLabelColor={'#495057'}
                                gradient={true}
                                gradientColor={['#E9E3F5', '#7052BC']}
                            />
                        : state.selectedAsset && state.showMetricLoading ?
                            <div className='flex justify-center m-4'>
                                <LoadingCircle />
                            </div>
                        : null}
                    </div>
                </div>
                <div className={`lg:w-2/5 w-full lg:mt-0 mt-2 flex flex-col flex-grow`}>
                    <ul className="flex flex-wrap text-sm text-center">
                        <li className="me-2">
                            <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.pageType === 'Details' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, pageType: 'Details' }))}>Details</span>
                        </li>
                        <li className="me-2">
                            <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.pageType === 'Alerts' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, pageType: 'Alerts' }))}>Alerts</span>
                        </li>
                    </ul>    
                    <div className='overflow-y-auto h-[calc(100vh-230px)] border border-NeutralGray-500 bg-white rounded-y-2xl rounded-r-2xl p-6'>
                        {state.pageType === 'Details' ?
                            state.showDetailsLoading ? 
                                <div className='flex justify-center'>
                                    <LoadingCircle />
                                </div>
                            : (state.selectedLeftOption === 'Disk' && state.diskDetails && Object.entries(state.diskDetails).length) || (state.selectedLeftOption === 'Workstation' && state.selectedAsset && Object.entries(state.selectedAsset).length) || (state.selectedLeftOption === 'Cluster' && state.selectedAsset && Object.entries(state.selectedAsset) && state.selectedCluster && Object.entries(state.selectedCluster).length) ?
                                <DetailsTab
                                    selectedDetails= {state.selectedLeftOption === 'Disk' ? state.diskDetails : state.selectedAsset}
                                    detailsFormat={state.detailsFormat}
                                />
                            :
                                <div className='flex justify-center m-4 text-NeutralGray-600'>
                                    {getCommonInformationMessage('observability')}
                                </div>                                                        
                        : state.pageType === 'Alerts' ?
                            <AlertsTab 
                                selectedLeftOption={state.selectedLeftOption}
                                selectedData= {state.selectedLeftOption === 'Disk' ? state.diskDetails : state.selectedAsset}
                                selectedClusterDetails={state.selectedCluster}
                                menuDisabled={() => setState({ menuDisabled: false })}
                            />
                        : state.pageType === 'Events' ?
                            <React.Fragment></React.Fragment>
                        : state.pageType === 'Troubleshooting' ?
                            <TroubleshootTab 
                                selectedLeftOption={state.selectedLeftOption}
                                selectedData={state.selectedAsset}
                                selectedCluster={state.selectedCluster}
                            />
                        : 
                            <div className='flex justify-center'>
                                <LoadingCircle />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LandingPage