/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file DetailTab.js
 * @author Prakash
 *************************************************/
import React from 'react';
import { convertToLowerCase, momentConvertionUtcToLocalTime, removeUnderScore, isValidDateTime } from '../../utils/utility'


const DetailsTab = ({ detailsFormat, selectedDetails }) => {
    const getStatusColor = (status) => {        
        let color = 'border border-darkPurple-600 text-darkPurple-600'
        if(status.toLowerCase() === 'cancelled') { color = 'border border-independenceGray-600 text-independenceGray-600' } else 
        if(status.toLowerCase() === 'completed') { color = 'border border-slateBlue-600 text-slateBlue-600' } else 
        if(status.toLowerCase() === 'failed') { color = 'border border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'running') { color = 'border border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'border border-blue-600 text-blue-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'border border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'timeout') { color = 'border border-yellowOrange-600 text-yellowOrange-600' }

        return color;
    }

    return (
        <div className="p-3">
            <React.Fragment>
            {detailsFormat && detailsFormat.length ? 
                detailsFormat.map((row, index) => {
                    return(
                        <div key={'key_'+index} className={`border-b border-NeutralGray-600 pb-[40px] text-lg ${index ? 'pt-[40px]' : ''}`}>
                            <p className={`text-primaryPurple-600 text-[22px] font-bold capitalize`}>{row.label}</p>
                            <small className='text-NeutralGray-700'>{row.description}</small>
                            {row.fields && row.fields.length && row.fields.map(fld => {
                                return(
                                    fld === 'licenses' ? 
                                        selectedDetails[fld] && Array.isArray(selectedDetails[fld]) ?
                                            selectedDetails[fld].map(item => {
                                                return(
                                                    Object.entries(item).map(([key, value], cIndex) => {
                                                        return(
                                                            <div key={'vol_'+cIndex} className="flex pt-8">
                                                                <span className="min-w-[222px] text-NeutralGray-600 self-center">{removeUnderScore(key)}:</span>
                                                                <span className="text-black">{value}</span>
                                                            </div>
                                                        )
                                                    })
                                                )
                                            })
                                        : null
                                    : fld === "queue_configuration" ?
                                        selectedDetails[fld] && Array.isArray(selectedDetails[fld]) ? 
                                            selectedDetails[fld].map((item, iIndex) => {
                                                return(
                                                <div key={'iIndex_'+iIndex} className={`mb-2 ${!iIndex ? 'mt-2' : ''}`}>
                                                    <div className="flex pt-8">
                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Subnet IDs:</span>
                                                        <div className="flex flex-wrap gap-2">
                                                            {item && item.subnet_ids ?
                                                                item.subnet_ids.map((ids, idsIndex) => {
                                                                    return(
                                                                        <span key={'idsIndex_'+idsIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{ids}</span>
                                                                    )
                                                                })
                                                            : null}
                                                        </div>
                                                    </div>
                                                    <div className="flex pt-8">
                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Security Group IDs:</span>
                                                        <div className="flex flex-wrap gap-2">
                                                            {item && item.security_group_ids ?
                                                                item.security_group_ids.map((ids, grpIndes) => {
                                                                    return(
                                                                        <span key={'grpIndes_'+grpIndes} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{ids}</span>
                                                                    )
                                                                })
                                                            : null}
                                                        </div>
                                                    </div>
                                                    <div className="flex pt-8">
                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Policies:</span>
                                                        <div className="flex flex-wrap gap-2">
                                                            {item && item.policies ?
                                                                item.policies.map((pol, polIndex) => {
                                                                    return(
                                                                        <span key={'polIndex_'+polIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{pol}</span>
                                                                    )
                                                                })
                                                            : null}
                                                        </div>
                                                    </div>
                                                    <div className="flex pt-8">
                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Script Location:</span>
                                                        <span className={`self-center text-black break-all`}>{item && item.script_location ? item.script_location : ""}</span>
                                                    </div>

                                                    <p className={`mb-1 mt-2 text-slateBlue-600 pb-1 border-b border-DeepPurpleTints-600`}>Volume Details</p>
                                                    <div className="flex pt-8">
                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Type:</span>
                                                        <span className={`self-center text-black`}>{item && item.volume_type ? item.volume_type : ""}</span>
                                                    </div>
                                                    <div className="flex pt-8">
                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Size:</span>
                                                        <span className={`self-center text-black`}>{item && item.volume_size && item.volume_size}</span>
                                                    </div>
                                                    <div className="flex pt-8">
                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Encryption:</span>
                                                        <span className={`self-center text-black`}>{item && item.volume_encryption ? "True" : "False"}</span>
                                                    </div>

                                                    <p className={`mb-1 mt-2 text-slateBlue-600 border-b border-DeepPurpleTints-600`}>S3 Access</p>
                                                    <div className="flex pt-8 text-base">
                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Bucket:</span>
                                                        <span className={`self-center text-black break-all`}>{item && item.bucket_name ? item.bucket_name : ""}</span>
                                                    </div>
                                                    <div className="flex pt-8">
                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Write Access:</span>
                                                        <span className={`self-center text-black break-all`}>{item && item.enable_s3_write_access ? "True" : "False"}</span>
                                                    </div>

                                                    <p className={`mb-1 mt-2 text-slateBlue-600 border-b border-DeepPurpleTints-600`}>Compute Resources</p>
                                                    {item && item.compute_resources ?
                                                        item.compute_resources.map((comp, compIndex) => {
                                                            return(
                                                                <div key={'comp_'+compIndex} className='bg-paleLavender-600 p-2 rounded mb-2'>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Name:</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                                                    </div>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Instance Type:</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.instance_type && comp.instance_type.length ? comp.instance_type[0] : ""}</span>
                                                                    </div>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Min Instance Count:</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                                                    </div>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Max Instance Count:</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                                                    </div>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Efa Enabled:</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.ena_enabled ? "True" : "False"}</span>
                                                                    </div>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Placement Group Enabled:</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                                                    </div>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Disable Simultaneous Multithreading:</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                                )
                                            })
                                        :
                                            <div className={`rounded p-2 mb-2 mt-2`}>
                                                <div className="flex pt-8">
                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Subnet IDs:</span>
                                                    <div className="flex flex-wrap">
                                                        {selectedDetails[fld] && selectedDetails[fld].subnet_ids ?
                                                            selectedDetails[fld].subnet_ids.map((ids, idsIndex) => {
                                                                return(
                                                                    <span key={'idsIndex_'+idsIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{ids}</span>
                                                                )
                                                            })
                                                        : null}
                                                    </div>
                                                </div>
                                                <div className="flex pt-8">
                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Security Group IDs:</span>
                                                    <div className="flex flex-wrap">
                                                        {selectedDetails[fld] && selectedDetails[fld].security_group_ids ?
                                                            selectedDetails[fld].security_group_ids.map((ids, gIndex) => {
                                                                return(
                                                                    <span key={'gIndex_'+gIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{ids}</span>
                                                                )
                                                            })
                                                        : null}
                                                    </div>
                                                </div>
                                                <div className="flex pt-8">
                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Policies:</span>
                                                    <div className="flex flex-wrap">
                                                        {selectedDetails[fld] && selectedDetails[fld].policies ?
                                                            selectedDetails[fld].policies.map((pol, polIndex) => {
                                                                return(
                                                                    <span key={'pol_'+polIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{pol}</span>
                                                                )
                                                            })
                                                        : null}
                                                    </div>
                                                </div>
                                                <div className="flex pt-8">
                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Script Location:</span>
                                                    <span className={`self-center text-black break-all`}>{selectedDetails[fld] && selectedDetails[fld].script_location ? selectedDetails[fld].script_location : ""}</span>
                                                </div>

                                                <p className={`mb-1 mt-2 text-purple border-b border-DeepPurpleTints-600`}>Volume Details</p>
                                                <div className="flex pt-8">
                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Type:</span>
                                                    <span className={`self-center text-black`}>{selectedDetails[fld] && selectedDetails[fld].volume_type ? selectedDetails[fld].volume_type : ""}</span>
                                                </div>
                                                <div className="flex pt-8">
                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Size:</span>
                                                    <span className={`self-center text-black`}>{selectedDetails[fld] && selectedDetails[fld].volume_size && selectedDetails[fld].volume_size}</span>
                                                </div>
                                                <div className="flex pt-8">
                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Encryption:</span>
                                                    <span className={`self-center text-black`}>{selectedDetails[fld] && selectedDetails[fld].volume_encryption ? "True" : "False"}</span>
                                                </div>

                                                <p className={`mb-1 mt-2 text-purple border-b border-DeepPurpleTints-600`}>S3 Access</p>
                                                <div className="flex pt-8 text-base">
                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Bucket:</span>
                                                    <span className={`self-center text-black`}>{selectedDetails[fld] && selectedDetails[fld].bucket_name ? selectedDetails[fld].bucket_name : ""}</span>
                                                </div>
                                                <div className="flex pt-8">
                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Write Access:</span>
                                                    <span className={`self-center text-black`}>{selectedDetails[fld] && selectedDetails[fld].enable_s3_write_access ? "True" : "False"}</span>
                                                </div>

                                                <p className={`mb-1 mt-2 text-purple border-b border-DeepPurpleTints-600`}>Compute Resources</p>
                                                {selectedDetails[fld] && selectedDetails[fld].compute_resources ?
                                                    selectedDetails[fld].compute_resources.map((comp, cIndex) => {
                                                        return(
                                                            <div key={'comp_'+cIndex} className={`bg-paleLavender-600 p-2 rounded mb-2 ${!cIndex ? "mt-2" : ""}`}>
                                                                <div className="flex pt-8">
                                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Name:</span>
                                                                    <span className={`self-center text-black`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                                                </div>
                                                                <div className="flex pt-8">
                                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Instance Type:</span>
                                                                    <span className={`self-center text-black`}>{comp && comp.instance_type && comp.instance_type.length ? comp.instance_type[0] : ""}</span>
                                                                </div>
                                                                <div className="flex pt-8">
                                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Min Instance Count:</span>
                                                                    <span className={`self-center text-black`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                                                </div>
                                                                <div className="flex pt-8">
                                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Max Instance Count:</span>
                                                                    <span className={`self-center text-black`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                                                </div>
                                                                <div className="flex pt-8">
                                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Efa Enabled:</span>
                                                                    <span className={`self-center text-black`}>{comp && comp.ena_enabled ? "True" : "False"}</span>
                                                                </div>
                                                                <div className="flex pt-8">
                                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Placement Group Enabled:</span>
                                                                    <span className={`self-center text-black`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                                                </div>
                                                                <div className="flex pt-8">
                                                                    <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">Disable Simultaneous Multithreading:</span>
                                                                    <span className={`self-center text-black`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                : null}
                                            </div>
                                    : fld === "storage_configuration" ?
                                        selectedDetails[fld] && selectedDetails[fld].map((storage, sIndex) => {
                                            return(
                                                <React.Fragment key={'store_'+sIndex}>
                                                {Object.entries(storage).length ?
                                                    Object.entries(storage).map(([key, value], stoIndex) => {
                                                        return(
                                                            <div key={'stoIndex_'+stoIndex} className="flex pt-8">
                                                                <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">{removeUnderScore(key)}:</span>
                                                                {typeof value === 'boolean' ?
                                                                    <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                : isValidDateTime(value) ?
                                                                    <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                : convertToLowerCase(key) === "status" ?
                                                                    <span className={`rlged-full px-2 mb-2 ${getStatusColor(value)} mr-2 py-1 break-all`}>{value}</span>
                                                                : convertToLowerCase(key) === "tags" ?
                                                                    <div className="flex flex-wrap gap-2">
                                                                        {value.map((item, tagIndex) => {
                                                                            return(
                                                                                <span key={'item_'+tagIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item.key +' : '+item.value}</span>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                : Array.isArray(value) ?
                                                                    <div className="flex flex-wrap gap-2 text-base">
                                                                        {value.map((item, vIndex) => {
                                                                            return(
                                                                                <span key={'vIndex_'+vIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item}</span>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                : (typeof value === 'string' || typeof value === 'number') ?
                                                                    <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                : null}
                                                            </div>
                                                        )
                                                    })
                                                : null}
                                                </React.Fragment>
                                            )
                                        })
                                    : fld === "head_node" || fld === "cluster_configuration" || fld === "database_configuration" ?
                                        selectedDetails[fld] && Object.entries(selectedDetails[fld]).length ?
                                            Object.entries(selectedDetails[fld]).map(([key, value], hIndex) => {
                                                return(
                                                    <div key={'hIndex_'+hIndex} className="flex pt-8">
                                                        <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">{removeUnderScore(key)}:</span>
                                                        {typeof value === 'boolean' ?
                                                            <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                        : isValidDateTime(value) ?
                                                            <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                        : convertToLowerCase(key) === "status" ?
                                                            <span className={`rlged-full px-2 mb-2 ${getStatusColor(value)} mr-2 py-1 break-all`}>{value}</span>
                                                        : convertToLowerCase(key) === "tags" ?
                                                            <div className="flex flex-wrap gap-2">
                                                                {value.map((item, ttIndex) => {
                                                                    return(
                                                                        <span key={'tagy_'+ttIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item.key +' : '+item.value}</span>
                                                                    )
                                                                })}
                                                            </div>
                                                        : Array.isArray(value) ?
                                                            <div className='w-full mr-2'>
                                                                <div className="flex flex-wrap">
                                                                    {value.map((item, itIndex) => {
                                                                        return(
                                                                            typeof item === 'string' ?
                                                                                <span key={"tak_key"+item.key} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item.key +' : '+item.value}</span>
                                                                            : typeof item === 'object' ?
                                                                                <div className='p-3 bg-paleLavender-600 rounded-md w-full mr-2 mt-2 text-base'>
                                                                                    {Object.entries(item).map(([iKey, iValue], iiIndex) => {
                                                                                        return(
                                                                                            typeof iValue === 'boolean'?
                                                                                                <p key={itIndex+'_key_'+iiIndex} className='mr-2 mb-1 mt-3'>{iKey} <span className='text-black ml-3'>{iValue ? 'True' : 'False'}</span></p>
                                                                                            :
                                                                                                <p key={itIndex+'_key_'+iiIndex} className='mr-2 mb-1'>{iKey}: <span className='text-black ml-3'>{iValue}</span></p>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : null
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        : Array.isArray(value) ?
                                                            <div className="flex flex-wrap gap-2">
                                                                {value.map((item, vvIndex) => {
                                                                    return(
                                                                        <span key={'value_'+vvIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item}</span>
                                                                    )
                                                                })}
                                                            </div>
                                                        : (typeof value === 'string' || typeof value === 'number') ?
                                                            <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                        : null}
                                                    </div>
                                                )
                                            })
                                        : null
                                    :
                                        <div className="flex pt-8" key={fld}>
                                            <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">{removeUnderScore(fld)}:</span>
                                            {selectedDetails && selectedDetails[fld] ?
                                                <React.Fragment>
                                                {typeof selectedDetails[fld] === 'boolean' ?
                                                    <span className={`text-black`}>{selectedDetails[fld] ? 'True' : 'False'}</span>
                                                : isValidDateTime(selectedDetails[fld]) ?
                                                    <span className="text-black">{momentConvertionUtcToLocalTime(selectedDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                : convertToLowerCase(fld) === "status" && selectedDetails[fld] ?
                                                    <span className={`self-center ${getStatusColor(selectedDetails[fld])} rounded-full px-2`}>{selectedDetails[fld]}</span>
                                                : convertToLowerCase(fld) === "tags" ?
                                                    <div className="flex flex-wrap">
                                                        {selectedDetails[fld].map(item => {
                                                            return(
                                                                <span className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all' key={item.key}>{item.key +' : '+item.value}</span>
                                                            )
                                                        })}
                                                    </div>
                                                : Array.isArray(selectedDetails[fld]) ?
                                                    <div className="flex flex-wrap gap-2 text-base">
                                                        {selectedDetails[fld].map((item, vIndex) => {
                                                            return(
                                                                typeof item === 'string' ?
                                                                    <span key={'vIndex_'+vIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item}</span>
                                                                : 
                                                                    Object.entries(item).map(([key, value], aoIndex) => {
                                                                        return(
                                                                            <div key={'arr_obj_'+aoIndex} className="flex pt-8">
                                                                                <span className="min-w-[222px] mr-3 text-NeutralGray-600 self-center">{removeUnderScore(key)}:</span>
                                                                                <span className="text-black">{value}</span>
                                                                            </div>
                                                                        )
                                                                    })
                                                            )
                                                        })}
                                                    </div>
                                                : (typeof selectedDetails[fld] === 'string' || typeof selectedDetails[fld] === 'number') ?
                                                    <span className="text-black">{fld === 'provider' ? selectedDetails[fld].toUpperCase() : selectedDetails[fld]}</span>
                                                : null}
                                                </React.Fragment>
                                            : null}
                                        </div>
                                )
                            })}
                        </div>
                    )
                })
            : null}
            {selectedDetails && selectedDetails.job_script ?
                <div className='pt-[40px]'>
                    <p className={`mb-1 text-primaryPurple-600 text-xl font-bold pb-1 capitalize`}>Job Script</p>
                    <div className='text-black rounded-md p-3 mb-5'>
                        <pre className='text-black text-wrap' style={{lineHeight: 2}}>{selectedDetails.job_script}</pre>
                    </div>
                </div>
            : null}
            </React.Fragment>
        </div>
    )
}

export default DetailsTab