import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { deprecateImage, listImages, scanImage } from '../../../actions/ImagebuilderAction'
// import WorkstationSidePanel from './WorkstationSidePanel'
import { CSVLink } from 'react-csv';
import {Icon} from "@iconify/react";
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
// import Search from '../../common/SearchWithHiddenInput';
import { capitalizeTheFirstLetterOfEachWord, momentDateGivenFormat } from '../../../utils/utility';
import CreateImage from './CreateImage';
import DetailsSidePanel from './DetailsSidePanel';
import SubscriptionSidePanel from './SubscriptionSidePanel';
import ShareSidePanel from './ShareSidePanel';
import { Store as CommonNotification } from 'react-notifications-component';
import ScheduleImage from './ScheduleImage';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import UpdateImage from './UpdateImage';
import AlertConfirmation from '../../common/AlertConfirmation';

let searchInput = null
const ImageSection = (props) => {
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        perPage: 100,        
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed, callListImages: true }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
        if(state.callListImages) {
            setState(prevState => ({ ...prevState, callListImages: false }))
            let params = {}
            if(props.selectedProvider) {
                params.provider = props.selectedProvider.toLowerCase()
            }
            if(props.selectedAccount && props.selectedAccount.length) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length) {
                params.region = props.selectedRegion
            }
            if(props.selectedImageType) {
                params.image_type = props.selectedImageType
            }
            if(props.selectedImage) {
                params[props.selectedProvider === 'docker' ? 'image_name' : 'os'] = props.selectedImage
            }

            if(state.searchText) {
                params.search_input = state.searchText
            }
    
            params.size = state.perPage

            let dynamiColumns = []
            let dataRow = {}
		
            dataRow.Header = 'Image'
            dataRow.accessor = 'image_name'
            dataRow.Cell = cellInfo => (
                <div className='cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original, showDetailsSection: true }))}>
                    <p className='flex w-full text-info'>
                        {cellInfo.row.original.image_name} 
                        {cellInfo.row.original.notifications ?
                            <Icon icon="tdesign:notification-error" className='ml-2 text-warning self-end' width={14} height={14} />
                        : null}
                    </p>
                    {/* {cellInfo.row.original.ami_name ?
                        <p className='w-full truncate'>{cellInfo.row.original.ami_name}</p>
                    : null} */}
                    <p className='w-full'>{cellInfo.row.original.description}</p>
                </div>
            )
            dataRow.width = 200
            dynamiColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Version'
            dataRow.accessor = 'image_version'
            dataRow.Cell = cellInfo => (
                <p className='flex w-full text-white'>
                    {cellInfo.row.original.image_version ? <span>v{cellInfo.row.original.image_version}</span> : null}
                </p>
            )
            dataRow.width = 100
            dynamiColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Type'
            dataRow.accessor = 'image_type'
            dataRow.width = 100
            dynamiColumns.push(dataRow)

            if(state.selectedProvider === 'aws' || state.selectedProvider === 'gcp') {
                dataRow = {}
                dataRow.Header = 'OS'
                dataRow.Cell = cellInfo => (
                    <p className='flex w-full text-white'>
                        {cellInfo.row.original.os} 
                        {cellInfo.row.original.os_version ? <span className='ml-2 text-lightGray'>(v{cellInfo.row.original.os_version})</span> : null}
                    </p>
                )
                dynamiColumns.push(dataRow)
            }

            dataRow = {}
            dataRow.Header = 'Last Download'
            dataRow.accessor = 'last_downloaded_at'
            dataRow.Cell = cellInfo => (
                <span>
                    {cellInfo.row.original.last_downloaded_at ? momentDateGivenFormat(cellInfo.row.original.last_downloaded_at, 'DD MMM YYYY HH:mm') : '-'}
                </span>
            )
            dynamiColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = '# of downloads'
            dataRow.accessor = 'total_downloads'
            dynamiColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Vulnerabilities'
            dataRow.accessor = 'vulnerabilities'
            dataRow.width = 180
            dynamiColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Status'
            dataRow.accessor = 'status'
            dataRow.Cell = cellInfo => (
                <span className={`border border-${cellInfo.row.original.status && cellInfo.row.original.status.toLowerCase() === 'deprecated' ? 'danger' : 'green'} text-${cellInfo.row.original.status && cellInfo.row.original.status.toLowerCase() === 'deprecated' ? 'danger' : 'green'} rounded-full px-2 py-1`}>
                    {cellInfo.row.original.status ? capitalizeTheFirstLetterOfEachWord(cellInfo.row.original.status) : ''}
                </span>
            )
            dynamiColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Action'
            dataRow.Cell = cellInfo => (
                <div className="flex self-center">
                    {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                        <React.Fragment>
                            {(state.selectedProvider === 'aws' || state.selectedProvider === 'gcp') ?
                                <React.Fragment>
                                <p className='cursor-pointer text-green' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original, pageMode: 'Create', showCreateImage: true }))} title="build">
                                    <Icon icon="octicon:play-24" className='text-info cursor-pointer' width={20} height={20} />
                                </p>
                                <span className='mx-2 text-lightGray'>/</span>
                                </React.Fragment>
                            : null}

                            {!state.scanLoading ?
                                <p className='cursor-pointer text-green' onClick={() => {
                                    setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original }))
                                    confirmActionDetails()
                                }} title="scan">
                                    <Icon icon="material-symbols-light:scan-outline" className='text-white cursor-pointer' width={20} height={20} />
                                </p>
                            :
                                <svg className="animate-spin h-5 w-5 text-white" fill="currentColor" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                    <path d="M4 12a8 8 0 0112-6.9" />
                                </svg>
                            }
                            <span className='mx-2 text-lightGray'>/</span>
                        </React.Fragment>
                    : null}
                    {/* {state.actionsAllowed && state.actionsAllowed.includes('edit') ? */}
                        {(state.selectedProvider === 'aws' || state.selectedProvider === 'gcp') ?
                            <React.Fragment>
                            <p className='cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original, pageMode: 'Edit', showUpdateImage: true }))} title="edit">
                                <Icon icon="tabler:edit" className={`${cellInfo.row.original.shared ? 'text-info' : 'text-white'} cursor-pointer`} width={20} height={20} />
                            </p>
                            <span className='mx-2 text-lightGray'>/</span>
                            </React.Fragment>
                        : null}

                    {/* : null} */}
                    
                    {state.selectedProvider === 'aws' || state.selectedProvider === 'gcp' ?
                        <React.Fragment>
                            <span title="share"> 
                            <Icon icon="solar:share-bold" className='text-text cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original, showShareSection: true }))} width={20} height={20} />
                            </span>
                            <span className='mx-2 text-lightGray'>/</span>
                        </React.Fragment>
                    : null}
                        
                    <p className='cursor-pointer text-info' title={`${cellInfo.row.original.shared ? 'un subscribe' : 'subscribe'}`} onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original, showSubscription: true }))}>
                    <Icon icon="fluent-mdl2:subscribe" className={`${cellInfo.row.original.subscribed ? 'text-info' : 'text-white'} cursor-pointer`} width={20} height={20} />
                    </p>
                    <span className='mx-2 text-lightGray'>/</span>
                    <p className='cursor-pointer text-info' title='schedule' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original, showScheduleImage: true }))}>
                    <Icon icon="mingcute:schedule-line" className={`${cellInfo.row.original.scheduled ? 'text-info' : 'text-white'} cursor-pointer`} width={20} height={20} />
                    </p>
                    <span className='mx-2 text-lightGray'>/</span>
                    <p className='cursor-pointer' onClick={() => {
                        setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original, deprecateModal: true }))
                    }} title="deprecate">
                        <Icon icon="oui:index-close" className={`${cellInfo.row.original.deprecate ? 'text-danger' : 'text-white'} cursor-pointer`} width={20} height={20} />
                    </p>
                </div>
            )
            dataRow.width  = 400
            dynamiColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = ' '
            dataRow.width  = 1
            dynamiColumns.push(dataRow)

            setState(prevState => ({ ...prevState, dynamiColumns }))
    
            dispatch(listImages(params))
                .then((response) => {
                    if(response) {
                        let totalResults = response && response.results ? response.results : []
    
                        setState(prevState => ({ ...prevState, detailsFormat: response.image_details ? response.image_details : [], imagesList: totalResults, filterListImages: totalResults, totalRecords: response.total ? response.total : 0, showLoading: false }));
                    }
                })
        }
    }, [dispatch, props.selectedProvider, props.selectedAccount, props.selectedImage, props.selectedImageType, props.selectedRegion, state.searchText, state.perPage, state.callListImages, state.actionsAllowed, state.scanLoading, state.selectedProvider])

    const confirmActionDetails = (field, index) => {
        let obj = {
            field: field,
            index: index,
            message: 'Are you sure you want to scan the image',
            heading: 'Confirm Scan'
        }
        setState(prevState => ({ ...prevState, confirmDetails: obj, confirmAction: true }))
    }

    useEffect(() => {
        if(state.callScanFunction) {
            setState(prevState => ({ ...prevState, callScanFunction: false }))

            let params = {}
            // if(props.selectedProvider) {
            //     params.provider = props.selectedProvider.toLowerCase()
            // }
            // if(props.selectedAccount && props.selectedAccount.length) {
            //     params.account_id = props.selectedAccount
            // }
            // if(props.selectedRegion && props.selectedRegion.length) {
            //     params.region = props.selectedRegion
            // }
            if(state.selectedDetails.image_id) {
                params.image_id = state.selectedDetails.image_id
            }
            if(state.selectedDetails.image_name) {
                params.image_name = state.selectedDetails.image_name
            }
            if(state.selectedDetails.image_version) {
                params.image_version = state.selectedDetails.image_version
            }
            if(state.selectedDetails.plan_key) {
                params.plan_key = state.selectedDetails.plan_key
            }
    
            dispatch(scanImage(params))
                .then((response) => {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : 'Error in scanning image'
                    if(response && !response.error) {
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'scanning successfully'
                        }
                    }

                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                        }
                    });
                    
                    setState(prevState => ({ ...prevState, showScanLoading: false }))
                })
        }

    }, [state.callScanFunction, dispatch, state.selectedDetails])


    

	const hanelDeprecageDateTime = (date) => {
		let deprecate_after = date ? momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss') : null
        setState(prevState => ({ ...prevState, deprecate_after }))
		// if(new Date(queryEndTime).getTime() > new Date(queryStartTime).getTime() ) {
		// 	setState(prevState => ({ ...prevState, queryEndTime}))
		// }
	}

    const deprecateFunction = () => {
        let hasError = false
        let params = {}
        params.provider = state.selectedDetails.provider
        params.account_id = state.selectedDetails.account_id
        params.region = state.selectedDetails.region
        params.image_id = state.selectedDetails.image_id
        params.deprecate  = state.selectedDetails && state.selectedDetails.deprecate ? false : true

        let deprecate_after = state.deprecate_after
        if(deprecate_after) {
            params.deprecate_after = deprecate_after
            if(deprecate_after < momentDateGivenFormat(moment().add(1, 'hour'), 'YYYY-MM-DD HH:mm:ss')) {
                hasError = true
            }
        }

        setState(prevState => ({ ...prevState, hasError, showDeprecateLoading: hasError ? false : true }))

        if(!hasError) {
            dispatch(deprecateImage(params))
                .then((response) => {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : 'Error in deprecating image'
                    if(response && !response.error) {
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'deprecation successfully'
                            deprecate_after = null
                        }
                    }
                    setState(prevState => ({ ...prevState, deprecate_after }))
    
                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                        }
                    });
    
                    setTimeout(() => { setState(prevState => ({ ...prevState, showDeprecateLoading: false, deprecateModal: false, callListImages: true })) }, 2000);
                })
        }


    }

    return (
        state.showLoading ?
            <div className='flex justify-center m-4'>
                <svg className="animate-spin h-5 w-5 ml-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                    <path d="M4 12a8 8 0 0112-6.9" />
                </svg>
            </div>
        :
            <React.Fragment>
                {state.showCreateImage ? 
                    <CreateImage
                        selectedDetails={state.selectedDetails}
                        closeSidePanel={(type) => setState(prevState => ({...prevState, selectedDetails: {}, showCreateImage: false, callListImages: type === 'refresh' ? true : false }))}
                        pageMode={state.pageMode}
                    />
                : null}
                {state.showUpdateImage ? 
                    <UpdateImage
                        selectedDetails={state.selectedDetails}
                        closeSidePanel={(type) => setState(prevState => ({...prevState, selectedDetails: {}, showUpdateImage: false, callListImages: type === 'refresh' ? true : false }))}
                        pageMode={state.pageMode}
                    />
                : null}
                {state.showScheduleImage ? 
                    <ScheduleImage
                        selectedProvider={state.selectedProvider}
                        selectedDetails={state.selectedDetails}
                        closeSidePanel={(type) => setState(prevState => ({...prevState, selectedDetails: {}, showScheduleImage: false, callListImages: type === 'refresh' ? true : false }))}
                        pageMode={state.pageMode}
                    />
                : null}
                {state.showDetailsSection ? 
                    <DetailsSidePanel
                        detailsFormat={state.detailsFormat}
                        selectedDetails={state.selectedDetails}
                        fromPage={'Image'}
                        closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {}, showDetailsSection: false }))}
                    />
                : null}

                {state.showSubscription ? 
                    <SubscriptionSidePanel
                        selectedDetails={state.selectedDetails}
                        closeSidePanel={(type) => setState(prevState => ({...prevState, selectedDetails: {}, showSubscription: false, callListImages: type === 'refresh' ? true : false }))}
                    />
                : null}

                {state.showShareSection ? 
                    <ShareSidePanel
                        selectedDetails={state.selectedDetails}
                        closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {}, showShareSection: false }))}
                    />
                : null}
                

                {state.confirmAction && state.confirmDetails ?
                    <AlertConfirmation
                        confirmDetails={state.confirmDetails}
                        confirmation={(action) => {
                            if(action === 'confirmed') {
                                setState(prevState => ({ ...prevState, callScanFunction: true, showScanLoading: true, confirmAction: false }))
                            } else {
                                setState(prevState => ({ ...prevState, confirmAction: false, selectedDetails: {} }))
                            }
                        }}
                    />
                : null}
                
                <div className={`${state.showScanLoading ? '' : 'hidden'}`}>
                    <div className='loading-wave'>
                        <div className='overlayEqualizerLoader'>
                            <div className="loading-bar"></div>
                            <div className="loading-bar"></div>
                            <div className="loading-bar"></div>
                            <div className="loading-bar"></div>
                        </div>
                    </div>
                </div>
                <div className='p-2 overflow-auto'>
                    <div className='flex flex-wrap justify-between my-3'>
                        <p className='mb-0 self-center md:w-1/2 w-full'>
                            <small>Showing {state.filterListImages && state.filterListImages.length} of total {state.totalRecords} image(s)</small>
                            {state.selectedProvider ?
                                <React.Fragment>
                                <small className='ml-1'>obtained from the</small>
                                <small className='ml-1 text-info'>{state.selectedProvider.toUpperCase()}</small>
                                <small className='ml-1'>provider</small>
                                </React.Fragment>
                            : null}
                        </p>
                        <div className='flex flex-wrap self-center'>
                            <div className='flex md:w-1/2 w-full md:ml-3 ml-0 md:mt-0 mt-2'>
                                {state.listUsers && state.listUsers.length ?
                                    <CSVLink
                                        data={state.filterListImages ? state.filterListImages : []} 
                                        headers={state.tableHeaders ? state.tableHeaders : []}
                                        filename={'images-lists.csv'}
                                        className={'self-center'}
                                        target='_blank'
                                    >
                                        <Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
                                    </CSVLink>
                                : null}
                                <div className='flex border border-lightGray rounded-md'>
                                    <Icon icon={`bitcoin-icons:search-outline`} className={`text-white w-5 self-center`} width={25} height={25} />
                                    <div className={`w-11/12`}>
                                        <input
                                            value={state.searchText}
                                            onChange={e => {
                                                setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                                clearTimeout(searchInput)
                                                if(e.target.value.length > 2) {
                                                    searchInput = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, startRecord: 0, currentPage: 1, totalPages: 1, listImages: [], filterListImages: [], showLoading: true, callListImages: true }))}, 1000); 
                                                } else if(!e.target.value.length) {
                                                    setState(prevState => ({ ...prevState, startRecord: 0, currentPage: 1, totalPages: 1, listImages: [], filterListImages: [], showLoading: true, callListImages: true }))
                                                }
                                            }}
                                            type='text'
                                            className={`bg-transparent text-white text-md pl-1 py-1`}
                                            placeholder='Search'
                                        />
                                    </div>
                                </div>
                                    {/* <Search
                                        data={state.imagesList ? state.imagesList : []}
                                        topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                        searchClassName={'px-2'}
                                        searchIconColor={'text-white '}
                                        searchPlaceHolder={'Search....'}
                                        className={'bg-transparent text-white'}
                                        widthHeight={25}
                                        filteredData={(filterListImages) => setState(prevState => ({ ...prevState, filterListImages }))}
                                    /> */}
                                {/* <button className={`flex bg-green px-2 py-1 rounded-md ml-3 md:mt-0 mt-2 text-white self-center`} onClick={() => {
                                    setState(prevState => ({ ...prevState, showScheduleImage: true, pageMode: 'Create' }))
                                }}>Schedule</button> */}
                            </div>
                        </div>
                    </div>
                    {state.filterListImages && state.filterListImages.length && state.dynamiColumns ?
                        <ResizeableDarkThemeTable
                            columns={state.dynamiColumns}
                            data={state.filterListImages}
                            perPage={20}
                            tableHead={'bg-lightGray text-white'}
                            tableBody={'text-black'}
                            tableBodyColor={'bg-GhostWhite'}
                            tableType={'table-light-hover'}
                            perPageClass={`bg-lightGray text-white rounded-md ml-2`}
                            textColor={'text-white'}
                            selectedColor={'bg-transparent'}
                            dashboard={true}
                            showPaginationTop={false}
                            paginationColor={'text-white'}
                            onClickRow={tableRow => {}}
                        />
                    : 
                        <div className='flex justify-center m-4'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                    }
                </div>
                <div id="popup-modal" tabIndex="-1" className={`flex ${state.deprecateModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow-lg dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, deprecateModal: false, showDeprecateLoading: false, selectedDetails: {} }))}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <p className='flex justify-center'>{state.selectedDetails && state.selectedDetails.image_name ? state.selectedDetails.image_name : ''}</p>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to {state.selectedDetails && state.selectedDetails.deprecate ? 'disbale image deprecation' : 'deprecate image'} ?</h3>

                                {/* <input
                                    type='text'
                                    placeholder={'depricate'}
                                    className={``}
                                    maxlength='5'
                                    value={state.deprecate_after ? state.deprecate_after : ''}
                                    onChange={e => setState(prevState => ({ ...prevState, deprecate_after: timeInput(e.target.value) }))}
                                /> */}
                                {state.selectedDetails && !state.selectedDetails.deprecate ?
                                    <React.Fragment>
                                    <p>Deprecate After</p>
                                    <Datetime
                                        // key={state.deprecate_after}  // Use key to force re-render
                                        value={state.deprecate_after ? momentDateGivenFormat(state.deprecate_after, 'DD MMM YYYY hh:mm A') : null}
                                        onChange={hanelDeprecageDateTime}
                                        dateFormat={'DD MMM YYYY'}
                                        timeFormat={'HH:mm A'}
                                        inputProps={{ readOnly: true, class: "bg-transparent px-2 py-1 border border-mediumDarkGray rounded-md text-black" }}
                                        className="w-100 mt-2"
                                        isValidDate={(current) => {
                                            const oneHourFromNow = moment().add(1, 'hour');
                                            return current.isAfter(oneHourFromNow); // Allow only dates and times after 1 hour from now
                                        }}
                                    />
                                    {state.hasError && (state.deprecate_after < momentDateGivenFormat(moment().add(1, 'hour'), 'YYYY-MM-DD HH:mm:ss')) ?
                                        <p className='text-danger'>Deprecate After should be greater than {momentDateGivenFormat(moment().add(1, 'hour'), "HH:mm")}</p>
                                    : null}
                                    </React.Fragment>
                                : null}
                                <div className='flex justify-center mt-4'>
                                    <button data-modal-hide="popup-modal" type="button" className="flex py-2 px-2 rounded-md text-white bg-HalloweenOrange mr-2" 
                                        onClick={() => {
                                            setState(prevState => ({ ...prevState, showDeprecateLoading: true }))
                                            if(!state.showDeprecateLoading) {
                                                deprecateFunction()
                                            }
                                        }}
                                    >
                                        {state.showDeprecateLoading ?
                                            <svg className="animate-spin h-5 w-5 mr-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                <path d="M4 12a8 8 0 0112-6.9" />
                                            </svg>
                                        : null}
                                        Yes, I'm sure
                                    </button>
                                    <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md bg-lightGray text-white" onClick={() => {
                                        setState(prevState => ({ ...prevState, deprecateModal: false, showDeprecateLoading: false, deprecate_after: null }))
                                    }}>
                                    No, cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
    );
};
export default ImageSection