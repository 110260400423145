import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { URL_PATH } from '../config/urlPath';
// import { currentLocaltime } from '../utils/utility'

import HpcDashboard from '../components/hpc/Dashboard/LandingPage';
import HpcJobStatus from '../components/hpc/JobStatus/LandingPage';
import Files from '../components/hpc/files';
import HpcSpendboard from '../components/hpc/Spendboard/LandingPage';

import LifecycleNotifications from '../components/common/LifecycleNotifications';
import LoadingPage from '../components/loadingPage';
import PermissionDenied from '../components/PermissionDenied';
import HpcStatus from '../components/hpc/Status/LandingPage';
import HpcLicenses from '../components/hpc/Licenses/LandingPage';
import HpcCatalogLandingPage from '../components/hpc/Catalog/LandingPage'
import HpcWorkStationRequestLandingPage from '../components/hpc/Catalog/Request/LandingPage'
import HpcClusterDetails from '../components/hpc/cluster/LandingPage'
import HpcClusterTemplateDetails from '../components/hpc/cluster/LandingPage'
import HpcCreateCluster from '../components/hpc/cluster/Create/CreateCluster'
import HpcQueueDetails from '../components/hpc/Queues/QueueDetails'
import HpcObservability from '../components/hpc/Observability/LandingPage'
import ManageQos from '../components/hpc/Qos/LandingPage'
import CreateUpdateQos from '../components/hpc/Qos/CreateUpdateQos'
import CreateUpdateGroup from '../components/hpc/Qos/CreateUpdateGroup'
import HpcAlerts from '../components/hpc/Alerts/LandingPage'
import HpcReports from '../components/hpc/Reports/LandingPage'
import HpcBudget from '../components/hpc/Budget/LandingPage'
import HpcCreateBudget from '../components/hpc/Budget/CreateBudget/CreateNewBudget'

import AdminUsers from '../components/Admin/Users/ManageUsers'
import AdminAccounts from '../components/Admin/Accounts/ManageAccounts'
import AdminDepartmentMapping from '../components/Admin/DepartmentMapping/LandingPage'
import AdminDepartmentMappingCreate from '../components/Admin/DepartmentMapping/CreateMapping'
import AdminSettings from '../components/Admin/Settings/LandingPage'

import GovernanceDashboard from '../components/governance/Dashboard'
import GovernanceManageRules from '../components/governance/ManagePolicies'
import GovernanceCreateNewRule from '../components/governance/PolicyDetails'
import GovernanceEditNewRule from '../components/governance/PolicyDetails'

import ResourcesInventory from '../components/resources/inventory/LandingPage'
import ResourcesLifecycle from '../components/resources/lifecycle/LandingPage'
import ResourcesQueries from '../components/resources/Queries/QueryEvents'
import ResourcesComparison from '../components/resources/comparison/AssetsComparison'
import ResourcesSearch from '../components/resources/searchModule/LandingPage'

import CostBudgetDashboard from '../components/cost/Dashboard/LandingPage'
import CostBudgetList from '../components/cost/Budget/ManageBudget'
import CostBudgetCreate from '../components/cost/Budget/CreateBudget/CreateNewBudget'
import CostExplorer from '../components/cost/Explorer/LandingPage'
import CostQueries from '../components/cost/Queries/QueryEvents' //still in class component

// import CostRecommendation from '../components/cost/Recommendations/Recommendations'
// import CostAnomalies from '../components/cost/Anomalies/LandingPage'
// import CostComparison from '../components/cost/Comparison/LandingPage'

import ImageBuilderDashboard from '../components/ImageBuilder/Image/LandingPage'
import ImageBuilderAudit from '../components/ImageBuilder/Audit/LandingPage'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={URL_PATH.LOADING_PAGE} element={<LoadingPage />} />
      <Route path={URL_PATH.LIFECYCLE_NOTIFICATIONS} element={<LifecycleNotifications />} />
      <Route path={URL_PATH.PERMISSION_DENINED} element={<PermissionDenied />} />
      
      {/* <Route path='/' element={<HpcJobStatus />} /> */}
      <Route path={URL_PATH.JOB_STATUS} element={<HpcJobStatus />} />
      <Route path={URL_PATH.HPC_DASHBOARD} element={<HpcDashboard />} />
      <Route path={URL_PATH.FILES} element={<Files />} /> 
      <Route path={URL_PATH.HPC_SPENDBOARD} element={<HpcSpendboard />} />
      <Route path={URL_PATH.HPC_STATUS} element={<HpcStatus />} />
      <Route path={URL_PATH.HPC_LICENSES} element={<HpcLicenses />} />
      <Route path={URL_PATH.CATALOG_LANDINGPAGE} element={<HpcCatalogLandingPage />} />
      <Route path={URL_PATH.WORKSTATION_REQUEST_LANDINGPAGE} element={<HpcWorkStationRequestLandingPage />} />
      <Route path={URL_PATH.CLUSTER_DETAILS} element={<HpcClusterDetails />} />
      <Route path={URL_PATH.LIST_CLUSTER_TEMPLATES} element={<HpcClusterTemplateDetails />} />
      <Route path={URL_PATH.CREATE_CLUSTER} element={<HpcCreateCluster />} />
      <Route path={URL_PATH.QUEUE_DETAILS} element={<HpcQueueDetails />} />
      <Route path={URL_PATH.OBSERVABILITY} element={<HpcObservability />} />
      <Route path={URL_PATH.MANAGE_QOS} element={<ManageQos />} />
      <Route path={URL_PATH.CREATE_QOS} element={<CreateUpdateQos />} />
      <Route path={URL_PATH.EDIT_QOS} element={<CreateUpdateQos />} />
      <Route path={URL_PATH.CREATE_GROUP} element={<CreateUpdateGroup />} />
      <Route path={URL_PATH.HPC_ALERTS} element={<HpcAlerts />} />
      <Route path={URL_PATH.HPC_REPORTS} element={<HpcReports />} /> 
      <Route path={URL_PATH.HPC_BUDGET} element={<HpcBudget />} />
      <Route path={URL_PATH.HPC_CREATE_BUDGET} element={<HpcCreateBudget />} />

      <Route path={URL_PATH.ADMIN_USERS} element={<AdminUsers />} />
      <Route path={URL_PATH.ADMIN_ACCOUNTS} element={<AdminAccounts />} />
      <Route path={URL_PATH.ADMIN_DEPARTMENT_MAPPING} element={<AdminDepartmentMapping />} />
      <Route path={URL_PATH.ADMIN_DEPARTMENT_MAPPING_CREATE} element={<AdminDepartmentMappingCreate />} />
      <Route path={URL_PATH.ADMIN_SETTINGS} element={<AdminSettings />} />

      <Route path={URL_PATH.GOVERNANCE_DASHBOARD} element={<GovernanceDashboard />} />
      <Route path={URL_PATH.GOVERNANCE_MANAGE_RULES} element={<GovernanceManageRules />} />
      <Route path={URL_PATH.GOVERNANCE_CREATE_NEW_RULE} element={<GovernanceCreateNewRule />} />
      <Route path={URL_PATH.GOVERNANCE_EDIT_NEW_RULE} element={<GovernanceEditNewRule />} />

      <Route path={URL_PATH.RESOURCES_INVENTORY} element={<ResourcesInventory />} />
      <Route path={URL_PATH.RESOURCES_LIFECYCLE} element={<ResourcesLifecycle />} />
      <Route path={URL_PATH.RESOURCES_QUERIES} element={<ResourcesQueries />} />
      <Route path={URL_PATH.RESOURCES_COMPARISON} element={<ResourcesComparison />} />
      <Route path={URL_PATH.RESOURCES_SEARCH} element={<ResourcesSearch />} />

      <Route path={URL_PATH.COST_BUDGET_DASHBOARD} element={<CostBudgetDashboard />} />
      <Route path={URL_PATH.COST_BUDGET_LIST} element={<CostBudgetList />} />
      <Route path={URL_PATH.COST_BUDGET_CREATE} element={<CostBudgetCreate />} />
      <Route path={URL_PATH.COST_EXPLORER} element={<CostExplorer />} />
      <Route path={URL_PATH.COST_QUERIES} element={<CostQueries />} />
      {/* <Route path={URL_PATH.COST_RECOMMENDATION} element={<CostRecommendation />} />
      <Route path={URL_PATH.COST_ANOMALIES} element={<CostAnomalies />} />
      <Route path={URL_PATH.COST_COMPARISON} element={<CostComparison />} /> */}

    <Route path={URL_PATH.IMAGE_BUILDER_DASHBOARD} element={<ImageBuilderDashboard />} />
    <Route path={URL_PATH.IMAGE_BUILDER_AUDIT} element={<ImageBuilderAudit />} />
    </Routes>
  );
};
