/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ManagePolicies.js
 * @author Prakash // on 09/12/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate, useLocation } from 'react-router-dom';
import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues, setCommonPropsDetails } from '../../actions/commonActionNew'
import { getGovMetaData, listGovernancePolicies, getPolicyDetails, listAssets, cleareNewEditRuleDetails, listGovernanceType, deleteGovernancePolicy } from '../../actions/governance/GovernanceAction'
import _ from 'lodash'
import { CSVLink } from "react-csv"
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'
import {Icon} from "@iconify/react";
import Search from '../common/SearchWithHiddenInput';
import { URL_PATH } from '../../config/urlPath'

import { capitalizeAllLetter, getAccountNameFromId, getRegionName, capitalizeTheFirstLetterOfEachWord, getCommonInformationMessage, } from '../../utils/utility'
import FilterSidePanel from './FilterSidePanel';
import { Store as CommonNotification } from 'react-notifications-component';
import PageLoading from '../common/PageLoading';
import SelectOption from '../common/Form/SelectOption';
import Button from '../common/Form/Button';
import AlertConfirmation from '../common/AlertConfirmation';
// import ViewSidePanel from './ViewSidePanel'

const ManagePolicies = () => {
	const advanceSearchRef = useRef();

	const [state, setState] = useState({
		queryLoading: true,
		showLoading: true,
		selectedGovernanceType: "All",
		filteredArray: [],
		viewOptions: [
			{label: "Policy", value: "policy_wise"},
			{label: "Policy Rule", value: "rule_wise"}
		],
		policyTypes: [
			{label: "Configured", value: "Configured"},
			{label: "Not Configured", value: "Not Configured"},
			{label: "All Rules", value: "all_rules"}
		],
		selectedViewOption: "policy_wise",
		selectedView: "policy_wise",
		selectedPolicyType: "Configured",
	})

	const navigate = useNavigate();
	const dispatch = useDispatch(); // Create a dispatch function
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
	const location = useLocation()

    const currentPath = location.pathname;
	const tagKeys = useSelector(state => state?.filters?.tagKeys);	
    const providers = useSelector(state => state?.filters?.providers || false)
    const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
					let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				}
				// else {
				// 	if(row.action) {
				// 		actionsAllowed = row.action
				// 	}
				// }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

	useEffect(() => {
        if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "",  callSearch: true }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name : "", callSearch: true }));
                    }
                })
        }
    }, [dispatch, providers]);// Call getFilterData whenever the providers state is updated

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

	useEffect(() => {
		dispatch((listGovernanceType()))
			.then((response)=> {
				if(response) {
					setState(prevState => ({ ...prevState, governanceTypes: response && response.length ? response : [] }))
				}
			})
	}, [dispatch])

	useEffect(() => {
		if(state.queryLoading) {
			setTimeout(() => { setState(prevState => ({ ...prevState, queryLoading: false })) }, 10000);
		}
	}, [state.queryLoading])

	useEffect(() => {
		let params = {}
		dispatch(getGovMetaData(params))
			.then((response) => {
				if(response) {
					if(!response.error) {
						setState(prevState => ({ ...prevState, metaData: response }))
					} else {
						setState(prevState => ({ ...prevState, metaData: [] }))
					}
				}
			})
    }, [dispatch]); // Empty dependency array ensures this runs only once on mount	

	useEffect(() => {
		if(state.callSearch) {
			setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {
				"enabled": true,
			}	
	
			if(state.selectedGovernanceType && state.selectedGovernanceType !== "All") {
				params.governance_type = state.selectedGovernanceType
			}
	
			if(state.selectedViewOption) {
				params.view = state.selectedViewOption
				if(state.selectedPolicyType === "all_rules") {
					params.all_rules = true
				}
			}
	
			
			let appliesTo = {}
			if(state.selectedAccount && state.selectedAccount !== "") {
				appliesTo.account_id = state.selectedAccount
			}
			if(state.selectedRegion && state.selectedRegion.length) {
				appliesTo.region = state.selectedRegion
			}
			if(state.selectedTags && state.selectedTags.length) {
				appliesTo.tags = state.selectedTags
			}
			if(state.selectedUsers && state.selectedUsers.length) {
				appliesTo.users = state.selectedUsers
			}
			if(state.selectedRoles && state.selectedRoles.length) {
				appliesTo.roles = state.selectedRoles
			}
	
			if(state.selectedGovernanceType !== "opa" && appliesTo && Object.entries(appliesTo).length) {
				params.applies_to = appliesTo
			}
	
			if(state.selectedViewOption === "rule_wise") {
				if(state.selectedFilters && state.selectedFilters.length) {
					let groupedData = _.chain(state.selectedFilters)
									.groupBy('label')
									.map((value, key) => ({ groupLabel: key, groupValue: value })).value()
	
					groupedData.forEach(row => {
						let data = []
						row.groupValue.forEach(item => {
							data.push(item.value)
						})
						params[row.groupLabel] = data
					})
		
				}
			}
	
			params.not_configured = false
			if(state.selectedPolicyType === "Not Configured") {
				params.not_configured = true
			}
			
			
			let showFilteredList = true
			if(Object.entries(params).length === 2) {
				showFilteredList = false
			}
	
			let dynamicRulePoliceColumns = []
			if(state.selectedViewOption === "rule_wise") {
				let dataRow = {}
		
				dataRow.Header = 'Type'
				dataRow.accessor = 'governance_type'
				dataRow.Cell = cellInfo => (
					<span>{cellInfo.row.original.governance_type ? (state.governanceTypes && state.governanceTypes.filter(e => e.value === cellInfo.row.original.governance_type).length ? state.governanceTypes.filter(e => e.value === cellInfo.row.original.governance_type)[0].label : cellInfo.row.original.governance_type) : "" }</span>
				)
				dataRow.width = 200
				dynamicRulePoliceColumns.push(dataRow)
	
				dataRow = {}
				dataRow.Header = 'Service'
				dataRow.accessor = 'resource_type'	
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 100	: 250
				dynamicRulePoliceColumns.push(dataRow)
	
				dataRow = {}
				dataRow.Header = 'Category'
				dataRow.accessor = 'severity'
				dataRow.Cell = cellInfo => (
					Array.isArray(cellInfo.row.original.rule_category) ?
						cellInfo.row.original.rule_category.length ?
							<div className='flex'>
								{cellInfo.row.original.rule_category.map((row, rIndex) => {
									return(
										<span className={`${!rIndex ? '' : 'mx-1'}`}>{row}{(rIndex+1) === cellInfo.row.original.rule_category.length ? '  Policy Rules' : ','}</span>
									)
								})}
							</div>
						: null
					: cellInfo.row.original.rule_category ?
						<span>{cellInfo.row.original.rule_category} Policy Rules</span>
					: null
				)
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 200 : 350
				dynamicRulePoliceColumns.push(dataRow)
	
				if(state.selectedPolicyType !== "all_rules") {	
					dataRow = {}
					dataRow.Header = 'Resources'
					// dataRow.accessor = 'severity'
					dataRow.Cell = cellInfo => (
						<div className="flex">
							<span className='mr-2'>{cellInfo.row.original.appResources}</span>
							{cellInfo.row.original.showMore ?
								<span className={`badge status-badge badge-outline-info`} onClick={() => setState(prevState => ({ ...prevState, masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original, showFilterDetailsPanel: true }))}>more</span>
							: null}
						</div>
					)
					dataRow.width = 300
					dynamicRulePoliceColumns.push(dataRow)
				}
	
				dataRow = {}	
				dataRow.Header = 'Tags'
				dataRow.Cell = cellInfo => (
					<div className="flex">
						{cellInfo.row.original.ruleTags && cellInfo.row.original.ruleTags.length ? 
							<span className={`badge status-badge risk-badge-outline-medium risk-medium`}>{cellInfo.row.original.ruleTags[0]}</span>
						: null}
					</div>
				)
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 150 : 250
				dynamicRulePoliceColumns.push(dataRow)
	
				dataRow = {}	
				dataRow.Header = 'Status'
				dataRow.Cell = cellInfo => (
					<span className={`badge status-badge badge-outline-${cellInfo.row.original.ruleEnabled ? "success" : "danger"}`}>{cellInfo.row.original.ruleEnabled ? "Enabled" : "Disabled"}</span>
				)
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 100 : 150
				dynamicRulePoliceColumns.push(dataRow)
	
				if(state.selectedPolicyType !== "all_rules") {	
					dataRow = {}
					dataRow.Header = 'Actions'
					// dataRow.accessor = 'severity'
					dataRow.Cell = cellInfo => (
						<div className="flex justify-between self-center">
							<p className="m-0 p-0 mx-1 cursor-pointer" 
								onClick={() => {
									// dispatch(setGovernancePropsDetails('editNewRuleDetails', false))
									setState(prevState => ({ ...prevState, masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original, showFilterDetailsPanel: true }))
								}}
							>
								<Icon icon="streamline:open-book" width='16' height='16' className="mr-1 self-center cursor-pointer"  />
							</p>
						</div>
					)
					dataRow.width = 100
					dynamicRulePoliceColumns.push(dataRow)
				}
	
				dataRow = {}	
				dataRow.Header = 'Description'
				dataRow.accessor = "ruleDescription"
				dataRow.width = 400
				dynamicRulePoliceColumns.push(dataRow)
			}
			
			dispatch(listGovernancePolicies(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, governanceRules: response, filteredArray: [], showLoading: false, showFilteredList, queryLoading: false, selectedView: state.selectedViewOption, dynamicRulePoliceColumns, callStructurePolicyDetails: true }))
					} else {
						setState(prevState => ({ ...prevState, showLoading: false, governanceRules: [], filteredArray: [], showFilteredList, queryLoading: false, selectedView: state.selectedViewOption, dynamicRulePoliceColumns }))
					}
				})
		}
	}, [state.callSearch, dispatch, state.governanceTypes, state.selectedAccount, state.selectedFilters, state.selectedGovernanceType, state.selectedPolicyType, state.selectedRegion, state.selectedRoles, state.selectedTags, state.selectedUsers, state.selectedViewOption])

	useEffect(() => {
		if(state.callStructurePolicyDetails) {
			setState(prevState => ({ ...prevState, callStructurePolicyDetails: false }))
			let data = state.governanceRules
			let policies = []
			data.length && data.forEach(row => {
				let policy_metadata = row.policy_metadata ? row.policy_metadata : {}
				let policyData = row.policy ? row.policy : {}
				let dataRow = {...policyData, ...policy_metadata}
	
				let appliesTo = row.policy_metadata && row.policy_metadata.applies_to ? row.policy_metadata.applies_to : []
				if(appliesTo.length) {
					let appAccounts = []	
					let appRegions = []
					let appTags = []
					let appUsers = []
					let appRoles = []
					let appResources = row.policy_metadata && row.policy_metadata.provider ? capitalizeAllLetter(row.policy_metadata.provider) : ""
					appliesTo.forEach((item, index) => {
						if(item.account_id){
							appAccounts.push(item.account_id)
						}
	
						if(item.region && Array.isArray(item.region)) {
							item.region.forEach(reg => {
								appRegions.push(reg)
							})
						} else if(item.region) {
							appRegions.push(item.region)
						}
						item.tags && item.tags.length && item.tags.forEach(tag => {
							appTags.push(tag)
						})
						item.users && item.users.length && item.users.forEach(usr => {
							appUsers.push(usr)
						})
						item.roles && item.roles.length && item.roles.forEach(rol => {
							appRoles.push(rol)
						})
					})
					
					if(appAccounts.length) {
						appResources += (row.policy_metadata && row.policy_metadata.provider ? " : " : "")+getAccountNameFromId(appAccounts[0], state.accounts)
					} 
	
					if(appRegions.length) {
						appResources += " : "+getRegionName(appRegions[0], state.regions)
					}
	
					// if(appRegions.length) {
					// 	appResources += " : "+appRegions[0]
					// }
	
					// row.appAccounts = appAccounts
					// row.appRegions = appRegions
					// row.appTags = appTags
					// row.appUsers = appUsers
					// row.appRoles = appRoles
	
					dataRow.appResources = appResources
					dataRow.showMore = (appAccounts.length > 1 || appRegions.length > 1) ? true : false
				}
				dataRow.ruleDescription = policyData.description
				dataRow.ruleTags = policyData.tags
				dataRow.ruleEnabled = policyData.enabled
	
				dataRow.ruleData = row
				policies.push(dataRow)
			})
	
			setState(prevState => ({ ...prevState, governancePolicies: policies, filteredArray: policies, callFormHeaders: true }))
		}
	}, [state.callStructurePolicyDetails, state.accounts, state.governanceRules, state.regions])

	useEffect(() => {
		if(state.callFormHeaders) {
			setState(prevState => ({ ...prevState, callFormHeaders: false }))
			let dataResult = state.filteredArray && state.filteredArray.length ? state.filteredArray : []
			if(dataResult.length) {
				let headers = []
				Object.entries(dataResult[0]).forEach(([key, value]) => {
					if(typeof value === "string") {
						let headerRow = {}
						headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
						headerRow.key = key
						headers.push(headerRow)
					}
				})
				setState(prevState => ({ ...prevState, headers }))
			}
		}
	}, [state.callFormHeaders, state.filteredArray])

	useEffect(() => {
		if(state.calltoGetPolicyInformation) {			
			let details = state.metaData[state.selectedGovernanceType] ? state.metaData[state.selectedGovernanceType] : {}

			let data = []

			if(Object.entries(details).length) {
				data = Object.keys(details)
			}

			setState(prevState => ({ ...prevState, calltoGetPolicyInformation: false, policyTypeOptions: data, mainDropdown: data }))
		}
	}, [state.calltoGetPolicyInformation, state.metaData, state.selectedGovernanceType])

	useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ "key": state.tag_key }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, tagValues: response }))
					}
				})
		}
	}, [state.tag_key, dispatch])

	// const addNewTag = () => {
	// 	let selectedTags = state["selectedTags"] ? state["selectedTags"] : []
	// 	if(state["tag_key"] && state["tag_key"] !== "" && state["tag_value"] && state["tag_value"] !== "" ) {
	// 		let dataRow = {
	// 			key: state["tag_key"],
	// 			value: state["tag_value"],
	// 		}
	// 		selectedTags.push(dataRow)
	// 	}


	// 	setState(prevState => ({ ...prevState, selectedTags: selectedTags, tag_key: "",  tag_value: "", tagValues: [] }))
	// }

	// const removeTagSection = (tag) => {
	// 	// filteredResult = state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
	// 	let filteredResult = state.selectedTags.filter(e => e !== tag)
	// 	setState(prevState => ({ ...prevState, selectedTags: filteredResult }))
	// }
	const onReset = () => {
		if(state.selectedFilters && state.selectedFilters.length) {
			let groupedData = _.chain(state.selectedFilters)
							.groupBy('label')
							.map((value, key) => ({ groupLabel: key, groupValue: value })).value()
			
			groupedData.forEach(row => {
				row.groupValue.forEach(item => {
					setState(prevState => ({ ...prevState, ["options_"+state.selectedOption+"".value]: false }))
				})
			})
		}

		setState(prevState => ({ 
			...prevState,
			selectedGovernanceType: "All",
			selectedAccount: "",
			selectedRegion: "",
			selectedViewOption: "policy_wise",
			selectedView: "policy_wise",
			selectedPolicyType: "Configured",
			selectedTags: [],
			selectedUsers: [],
			selectedRoles: [],
			selectedFilters: [],
			callSearch: true
		}))
	}

    // const comparisonFilter = () => {
	// 	if(!state.searchText || state.searchText === '') {
    //         setState(prevState => ({ ...prevState, showMainDropdownOption: state.showMainDropdownOption ? false : true, selectedDefaultOption: '' }))
	// 	}
	// }
    
    useEffect(() => {
		setState(prevState => ({ ...prevState, showMainDropdownOption: false }))

		let filterData = _.orderBy(state.policyTypeOptions, ['Asc'])
		if(state.searchText !== '') {
			filterData = filterData.filter(
				filter => 
				filter.toLocaleLowerCase().includes(state.searchText) ||
				filter.includes(state.searchText)
			)
		}
		setState(prevState => ({ 
			...prevState, 
			selectedDefaultOption: '',
			mainDropdown: filterData, 
			showMainDropdownOption: state.searchText === '' ? false : true 
		}))
	}, [state.searchText, state.policyTypeOptions])

	useEffect(() => {
		if(state.calltoGetSelectedOptionValues) {
			let details = state.metaData[state.selectedGovernanceType]
			let selectedOptionValues = details && details[state.selectedOption] ? details[state.selectedOption] : []
	
			setState(prevState => ({ ...prevState, childDropdownOptions: selectedOptionValues, filteredChildDropdownOptions: selectedOptionValues, calltoGetSelectedOptionValues: false }))
		}

	}, [state.calltoGetSelectedOptionValues, state.metaData, state.selectedOption, state.selectedGovernanceType])


	// const updateMultiSelectedOptions = (row, options) => {
	// 	let data = state.selectedFilters ? state.selectedFilters : []
	// 	let selectedMultiOptions = state.selectedMultiOptions ? state.selectedMultiOptions : []

	// 	if(state["options_"+state.selectedOption+"_"+row]) {				
	// 		let dataRow = {}
	// 		dataRow.label = state.selectedOption
	// 		dataRow.value = row

	// 		data.push(dataRow)
	// 	} else {
	// 		data = data.filter(e => e.value !== row)
	// 	}

	// 	setState(prevState => ({ ...prevState, selectedFilters: data, selectedMultiOptions }))
	// }

	useEffect(() => {
		if(state.showAdvancedSearch) {

			if(state.selectedGovernanceType === "infra") {
				if(!tagKeys) {
					let params = {}
					// params.account_id = "265469181489"
					// params.region = "us-east-2"
					dispatch(getAllTagsKeys(params))
				}
			} else {
	
				let params = {}
				if(state.selectedAccount && state.selectedAccount !== "") {
					params.account_id = state.selectedAccount
				}
				// params.service_name = "iam-users"
				dispatch(listAssets(params))
					.then((response) => {
						if (response) {
							setState(prevState => ({ ...prevState, applies_to_users: response.results ? response.results : [] }))
						} else {
							setState(prevState => ({ ...prevState, applies_to_users: [] }))
						}
					})
	
				let roleParams = {}
				if(state.selectedAccount && state.selectedAccount !== "") {
					roleParams.account_id = state.selectedAccount
				}
				roleParams.service_name = "iam-roles"
	
				dispatch(listAssets(roleParams))
					.then((response) => {
						if (response) {
							setState(prevState => ({ ...prevState, applies_to_roles: response.results ? response.results : [] }))
						} else {
							setState(prevState => ({ ...prevState, applies_to_roles: [] }))
						}
					})
			}
		}
	}, [state.showAdvancedSearch, dispatch, state.selectedAccount, state.selectedGovernanceType, tagKeys])
	
	const onClickCreateRule = ruleObj => {
		dispatch(cleareNewEditRuleDetails());
		navigate(URL_PATH.GOVERNANCE_CREATE_NEW_RULE, {state: { ruleDetails: {} } })
	}

	// const onSideViewRule = (ruleId) => {
	// 	setState(prevState => ({ ...prevState, queryLoading: true }))
	// 	let params = {}
	// 	params.policy_name = ruleId
	// 	dispatch(getPolicyDetails(params))
	// 		.then((response) => {
	// 			if(response) {
	// 				let ruleDetails = response
	// 				let policyBasicDetails = ruleDetails.policy_metadata ? ruleDetails.policy_metadata : {}	
	// 				setState(prevState => ({ ...prevState, queryLoading: false, ruleDetails, policyBasicDetails, showListDetailsPanel: true }))
	// 			} else {
	// 				setState(prevState => ({ ...prevState, editGovernanceRule: false, queryLoading: false }))
	// 			}
	// 		})
	// }

	const onClickEditRule = (ruleId) => {
		// dispatch(setGovernancePropsDetails('appliesToError', false))
		let params = {}
		params.policy_name = ruleId
		dispatch(getPolicyDetails(params))
			.then((response) => {
				if(response) {
					navigate(URL_PATH.GOVERNANCE_EDIT_NEW_RULE, { state: { ruleDetails: response } });
				} else {
					setState(prevState => ({ ...prevState, editGovernanceRule: false }))
				}
			})
	}

	const onClickDeleteRule = (ruleId) => {
		let params = {}
		params.policy_name = ruleId
		dispatch(deleteGovernancePolicy(params))
			.then((response) => {
				if(response) {
					let messageType = 'danger'
					let message = response.message ? response.message : 'Error in deleting policy'
					if(response && response.status) {
						messageType = 'success'
						message = response.message ? response.message : 'Policy deleted successfully'

						setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, deletePolicyDetails: '', deleteModal: false })) }, 2000)
					}

					setTimeout(() => { setState(prevState => ({ ...prevState, showDeleteLoading: false })) }, 2000)
	
					// setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
	
					CommonNotification.addNotification({
						//title: 'Wonderful!',
						message: message,
						type: messageType,
						insert: 'top',
						container: 'top-center',
						// animationIn: ['animate__animated', 'animate__fadeIn'],
						// animationOut: ['animate__animated', 'animate__fadeOut'],
						dismiss: {
						duration: 5000,
						onScreen: false,
						pauseOnHover: true,
						showIcon: true,
						}
					});
				}
			})
	}

	// const removeFilterData = (row) => {
	// 	let selectedFilters = state.selectedFilters.filter(e => e.label === row.label && e.value === row.value)

	// 	setState(prevState => ({ ...prevState, selectedFilters }))
	// }

	const handleChildClick = (event, type, dropdownType, section) => {
		if (advanceSearchRef && advanceSearchRef.current && !advanceSearchRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showMainDropdownOption: false, selectedOption: "" }))
		}

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
	
	return (
		<div  className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
			<div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
			{/* {state.showListDetailsPanel ? (
				<ViewSidePanel
					visitFrom={"List"}
					selectedProvider={state.selectedProvider}
					// resource_type={state.resource_type}
					ruleDetails={state.ruleDetails}
					governanceType={state.policyBasicDetails && state.policyBasicDetails.governance_type}
					closeSidePanel={() => setState(prevState => ({ ...prevState, showListDetailsPanel: false }))}
				/>
			) : null} */}
			{state.showFilterDetailsPanel ? (            
				<FilterSidePanel
					masterData={state.masterData}
					closeSidePanel={(type) => setState(prevState => ({ ...prevState, queryLoading: type === 'refresh' ? true : false, callSearch: type === 'refresh' ? true : false,  showFilterDetailsPanel: false }))}
				/>
			) : null}
			<div className="pt-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl py-7 px-6">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Governance Rules</p>
                        <p className="text-white text-base">Filter user governance rules on your Cloud</p>
                    </div>
					<div className={`lg:w-3/4 w-full flex justify-end self-end`}>
						<div className='mr-3'>
							<SelectOption
								label={"Governance Type"}
								labelClass={{color:'text-white'}}
								fields={["value", "label"]}
								options={state.governanceTypes}
								selectedValues={state.selectedGovernanceType ? state.selectedGovernanceType : ''}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, selectedGovernanceType: value, calltoGetPolicyInformation: true }))
								}}
								singleSelection={true}
								dropdownWidth={'min-w-40'}
							/>
						</div>
						{state.selectedGovernanceType !== "opa" && state.selectedGovernanceType !== "opa_kubernetes"  ?
							<React.Fragment>
							<div className='mr-3'>
								<SelectOption
									label={"Provider"}
									labelClass={{color:'text-white'}}
									fields={["provider_name", "provider_name"]}
									options={state.providers}
									selectedValues={state.selectedProvider ? state.selectedProvider : ''}
									callbackMultiSelect={(value) => {
										setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: '', selectedRegion: '' }))
									}}
									singleSelection={true}
									dropdownWidth={'min-w-40'}
								/>
							</div>
							<div className='mr-3'>
								<SelectOption
									label={"Account"}
									labelClass={{color:'text-white'}}
									fields={["account_id", "account_name"]}
									options={state.accounts}
									selectedValues={state.selectedAccount ? state.selectedAccount : ''}
									callbackMultiSelect={(value) => {
										setState(prevState => ({ ...prevState, selectedAccount: value, selectedRegion: '' }))
									}}
									singleSelection={true}
									dropdownWidth={'min-w-40'}
								/>
							</div>
							</React.Fragment>
						: null}						
						<div className='mr-3'>
							<SelectOption
								label={"Region"}
								labelClass={{color:'text-white'}}
								fields={["region", "name"]}
								options={state.regions}
								selectedValues={state.selectedRegion ? state.selectedRegion : ''}
								callbackMultiSelect={(value) => {
										setState(prevState => ({ ...prevState, selectedRegion: value }))
								}}
								singleSelection={true}
								dropdownWidth={'min-w-40'}
							/>
						</div>
						<div className='mr-3'>
							<SelectOption
								label={"View"}
								labelClass={{color:'text-white'}}
								fields={["value", "label"]}
								options={state.viewOptions}
								selectedValues={state.selectedViewOption ? state.selectedViewOption : ''}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, selectedViewOption: value, selectedPolicyType: value === "policy_wise" && state.selectedPolicyType === "all_rules" ? "Configured" : state.selectedPolicyType }))
								}}
								singleSelection={true}
								dropdownWidth={'min-w-40'}
							/>
						</div>
						<div className='mr-3'>
							<SelectOption
								label={"Policy Type"}
								labelClass={{color:'text-white'}}
								fields={["label", "value"]}
								options={state.policyTypes && (state.selectedPolicyType !== "rule_wise" ? state.policyTypes.filter(e => e.value !== "all_rules") : state.policyTypes)}
								selectedValues={state.selectedPolicyType ? state.selectedPolicyType : ''}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState, selectedPolicyType: value }))
								}}
								singleSelection={true}
								dropdownWidth={'min-w-40'}
							/>
						</div>
						<div className='self-end'>
							{/* {props.filterAdvancedSearch ?
								<p className='pl-[33px] pr-[34px] py-[3px] text-base border border-paleLavender-600  rounded-full text-white flex justify-center cursor-pointer mb-4' onClick={() => props.onClickAdvancedSearch()}>Advanced</p>
							: null} */}
							<div className="flex space-x-0 w-fit" role="group">
								<button type="button" className="text-base bg-pumpkin-600 rounded-md text-white font-bold py-2.5 pr-4 pl-3 inline-flex items-center mr-2"
									onClick={()=> setState(prevState => ({ ...prevState, queryLoading: true, callSearch: true }))}
								>
									<Icon icon="weui:search-filled" className="text-white" width={24} height={24} />
									<span className='pl-1 text-white'>Search</span>
								</button>
								<button type="button" className="text-xl bg-pumpkin-600 rounded-md text-white font-bold px-2.5 pr-3 pl-4 inline-flex items-center"
									onClick={() => onReset()}
								>
									<Icon icon="fa6-solid:arrows-rotate" className="text-white" width={24} height={24} />
								</button>
							</div>
						</div>						
					</div>
				</div>
				<div className=''>
					<div className='min-h-screen overflow-auto'>
						<div className='pt-8'>
							<div className="flex justify-between lg:items-center lg:flex-row flex-col mt-2">
								<div>
									<p className="text-[28px] font-semibold flex flex-row gap-3">Policies List</p>
									<p className='text-sm pt-2.5'>Showing {state.filteredArray && state.filteredArray.length} of total {state.governancePolicies && state.governancePolicies.length} {state.selectedView === "policy_wise" ? "Policies" : "Rule(s)"}</p>
								</div>
								<div className='flex justify-end self-end'>
									<div className="flex">
										{state.governancePolicies && state.governancePolicies.length ?
											<Search
												data={state.governancePolicies ? state.governancePolicies : []}
												className={'rounded-full'}
												topClassName={'border border-NeutralGray-600 rounded-full bg-white'}
												filteredData={filteredArray => {
													setState(prevState => ({ ...prevState, filteredArray }))
												}}
											/>
										: null}
										
										<button type="button" className="bg-DeepPurpleTints-600 text-white text-base font-semibold ml-3 py-2.5 px-[19px] rounded-full self-center text-nowrap" onClick={() => onClickCreateRule(state.selectedTableRow)}>Create Rule</button>
										{state.filteredArray && state.filteredArray.length ?
											<CSVLink 
												data={state.filteredArray ? state.filteredArray : []} 
												headers={state.headers ? state.headers : []}
												filename={"resource-alerts.csv"}
												className={"ml-3 self-center"}
												target="_blank"
											>
												<Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center' />
											</CSVLink>
										: null}
									</div>
								</div>
							</div>
							
							<div className="w-full pt-6">
								<div className="relative overflow-x-auto mt-3 rounded-2xl shadow border border-NeutralGray-600">
								{state.governancePolicies && state.governancePolicies.length && !state.queryLoading ?
									state.selectedView === "policy_wise" ?
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: "Type",
													Cell: cellInfo => (
														<span>{cellInfo.row.original.governance_type ? (state.governanceTypes && state.governanceTypes.filter(e => e.value === cellInfo.row.original.governance_type).length ? state.governanceTypes.filter(e => e.value === cellInfo.row.original.governance_type)[0].label : cellInfo.row.original.governance_type) : "" }</span>
													),
													accessor: "governance_type",
													width: 200
												},
												{
													Header: "Policy Name",
													accessor: "policy_name",
													width: 200
												},
												{
													Header: "Category",
													Cell: cellInfo => (
														cellInfo.row.original.rule_category && cellInfo.row.original.rule_category.length ?
															<div className='flex'>
																{cellInfo.row.original.rule_category.map((row, rIndex) => {
																	return(
																		<span key={'tt_'+rIndex} className={`${!rIndex ? '' : 'mx-1'}`}>{row}{(rIndex+1) === cellInfo.row.original.rule_category.length ? '  Policy Rules' : ','}</span>
																	)
																})}
															</div>
														: null
													),
													accessor: "rule_category",
													width: 300
												},
												{
													Header: "Resources",
													Cell: cellInfo => (
														<div className="flex">
															<span className='mr-2'>{cellInfo.row.original.appResources}</span>
															{cellInfo.row.original.showMore ?
																<span className={`badge status-badge badge-outline-info`}>more</span>
															: null}
														</div>
													),
													width: 250
												},
												{
													Header: "Actions",
													Cell: cellInfo => (
														<div className="flex text-center self-center">
															{state.selectedView === "rule_wise" ?
																<span className="mr-1 self-center cursor-pointer text-primaryPurple-600 text-sm" onClick={() => setState(prevState => ({ ...prevState, masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original, showFilterDetailsPanel: true }))}>View</span>
															:
																<React.Fragment>
																{state.actionsAllowed && state.actionsAllowed.includes('update') ?
																	<span className="mr-1 self-center cursor-pointer text-primaryPurple-600 text-sm" onClick={() => onClickEditRule(cellInfo.row.original.policy_name)} >Edit</span>
																: null}
																{state.actionsAllowed && state.actionsAllowed.includes('delete') ?
																	<span className="mr-1 self-center cursor-pointer text-ferrariRed-600 text-sm ml-2"
																	onClick={() => {
																		let obj = {
																			message: 'Are you sure you want to delete the policy' +(cellInfo.row.original.policy_name ? ' "'+cellInfo.row.original.policy_name+'"' : ''),
																			buttonLabel: "Yes, I'm sure",
																		}
																		setState(prevState => ({ ...prevState, confirmDetails: obj, deleteModal: true, deletePolicyDetails: cellInfo.row.original.policy_name }))
																	}}>Delete</span>
																: null}
																</React.Fragment>
															}
														</div>
													),
													width: 150
												},											
												{
													Header: "Description",
													accessor: "description",
													width: 350
												},
											]}
											
											data={state.filteredArray ? state.filteredArray : state.governancePolicies}
											perPage={20}
											dashboard={state.filteredArray && state.filteredArray.length ? true : false}
											onClickRow={tableRow => {}}
											showPaginationTop={true}
											sortByColumn={"Date"}
											riskTooltip={[]}
										/>
									:
										<ResizeableDarkThemeTable
											columns={state.dynamicRulePoliceColumns}
											data={state.filteredArray ? state.filteredArray : state.governancePolicies}
											perPage={20}
											dashboard={(state.filteredArray ? state.filteredArray.length : (state.governancePolicies && state.governancePolicies.length)) ? true : false}
											sortByColumn={"Date"}
											riskTooltip={[0]}
											tableType={'table-dark-hover'}
											onClickRow={tableRow => {}}
											selectedColor={'bg-transparent'}
											// tableSize={"table-sm"}
											// tableHead={"bg-dark"}
											tooltipFor={[]}
										/>
								: (!state.queryLoading && !state.governancePolicies) ||
									(state.governancePolicies && !state.governancePolicies.length && !state.showLoading) ? 
									<div className="flex justify-center m-4 text-black">
										<p>{getCommonInformationMessage('policies')}.</p>
									</div>
								: null}
								{state.queryLoading ? (
									<div className="flex justify-center m-4">
										<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
											<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
											<path d="M4 12a8 8 0 0112-6.9" />
										</svg>
									</div>
								) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
				{state.confirmDetails && state.deleteModal ?
					<AlertConfirmation 
						confirmDetails={state.confirmDetails}
						confirmation={(deleteModal) => {
							if(deleteModal) {
								onClickDeleteRule(state.deletePolicyDetails ? state.deletePolicyDetails : '')
							}
							setState(prevState => ({ ...prevState, confirmDetails: false, deleteModal: false, showDeleteLoading: false, deletePolicyDetails: {} }))
						}}
					/>
				: null}
			</div>
		</div>
	)
}

export default ManagePolicies;
