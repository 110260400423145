/*************************************************
 * Collider
 * @exports
 * @file CostAction.js
 * @author parakash // on 3-02-2023
 * @copyright © 20214Collider. All rights reserved.
 *************************************************/

import ApiCalls from '../../components/ApiCalls'

import {
	GET_COST_RECOMMENDATIONS,
	GET_POWER_TUNING_ASSETS,
	START_POWER_TUNING,
	GET_POWER_TUNING_RESULTS,

	GET_ANOMALY_TOTAL_COUNT,
	GET_ANOMALY_DAILY_TREND,
	LIST_ANOMALY_RESULTS,
	GET_TOP_ANOMALIES,
	COST_GET_COST_TREND,
	GET_COST_FILTERS,
	GET_COST_FILTER_VALUES,
	GET_COST_TOTAL_COUNT,
	LIST_COST_TEMPLATE,
	SAVE_TEMPLATE,
	COST_COMPARISON,
	GET_BUDGET_DETAILS,
	GET_BUDGET_FILTERS,
	INSERT_BUDGET_DETAILS,
	UPDATE_BUDGET_DETAILS,

	GET_COST_ALL_TAGS_KEYS,
	GET_COST_ALL_TAGS_VALUES,
	LIST_BUDGET_DETAILS,

	GET_CLUSTER_COST_BREAKUP,
	GET_QUEUE_COST_BREAKUP,
	GET_USER_COST_BREAKUP,
	GET_USER_YEARLY_COST_BREAKUP,
	GET_PROJECT_COST_BREAKUP,
	GET_PROJECT_YEARLY_COST_BREAKUP,
	GET_COST_ALLOCATION_TAGS
} from '../../config'

import { ACTION_TYPES } from '../types'
//import Cookies from 'js-cookie'


/**
 * Action to set cost props details
 *   for cost page
 * @author Prakash // on 13/06/2023
*/
export const setCostPropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		let costPropsDetails = JSON.parse(JSON.stringify(getState().cost.costPropsDetails))
		costPropsDetails[key] = value
		dispatch({ type: ACTION_TYPES.SET_COST_PROPS_DETAILS, payload: costPropsDetails });
	}
}

/**
 * Action to get-cost-recommendations
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostRecommendations = (params) => {
	return async () => {
		try {

			const url = GET_COST_RECOMMENDATIONS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-power-tuning-assets
 * @param {Object} body
 * @param {Function} callback
 */
export const getPowerTuningAssets = (params) => {
	return async () => {
		try {

			const url = GET_POWER_TUNING_ASSETS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to start-power-tuning
 * @param {Object} body
 * @param {Function} callback
 */
export const startPowerTuning = (params) => {
	return async () => {
		try {

			const url = START_POWER_TUNING;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-power-tuning-results
 * @param {Object} body
 * @param {Function} callback
 */
export const getPowerTuningResults = (params) => {
	return async () => {
		try {

			const url = GET_POWER_TUNING_RESULTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-anomaly-total-count
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostAnomalyTotalCount = (params) => {
	return async () => {
		try {

			const url = GET_ANOMALY_TOTAL_COUNT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-anomaly-daily-trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostAnomalyDailyTrend = (params) => {
	return async () => {
		try {

			const url = GET_ANOMALY_DAILY_TREND;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-anomaly-results
 * @param {Object} body
 * @param {Function} callback
 */
export const listCostAnomalyResults = (params) => {
	return async () => {
		try {

			const url = LIST_ANOMALY_RESULTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-top-anomalies
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostTopAnomalies = (params) => {
	return async () => {
		try {

			const url = GET_TOP_ANOMALIES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-cost-trend
 * @param {Object} body
 * @param {Function} callback
 */
export const costGetCostTrend = (params) => {
	return async () => {
		try {

			const url = COST_GET_COST_TREND;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-cost-filters
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostFilters = (params) => {
	return async () => {
		try {

			const url = GET_COST_FILTERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-cost-filter-values
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostFilterValues = (params) => {
	return async () => {
		try {

			const url = GET_COST_FILTER_VALUES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-cost-total-count
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostTotalCount = (params) => {
	return async () => {
		try {

			const url = GET_COST_TOTAL_COUNT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-templates
 * @param {Object} body
 * @param {Function} callback
 */
export const listCostTemplates = (params) => {
	return async () => {
		try {

			const url = LIST_COST_TEMPLATE;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-templates
 * @param {Object} body
 * @param {Function} callback
 */
export const saveTemplate = (params) => {
	return async () => {
		try {

			const url = SAVE_TEMPLATE;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to cost-comparison
 * @param {Object} body
 * @param {Function} callback
 */
export const costComparison = (params) => {
	return async () => {
		try {

			const url = COST_COMPARISON;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-budget-details
 * @param {Object} body
 * @param {Function} callback
 */
export const getBudgetDetails = (params) => {
	return async () => {
		try {

			const url = GET_BUDGET_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-budget-filters
 * @param {Object} body
 * @param {Function} callback
 */
export const getBudgetFilters = (params) => {
	return async () => {
		try {

			const url = GET_BUDGET_FILTERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to insert-budget-details
 * @param {Object} body
 * @param {Function} callback
*/
export const insertBudgetDetails = (params) => {
	return async () => {
		try {

			const url = INSERT_BUDGET_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to update-budget-details
 * @param {Object} body
 * @param {Function} callback
*/
export const updateBudgetDetails = (params) => {
	return async () => {
		try {

			const url = UPDATE_BUDGET_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-all-tags-keys
 * @param {Object} body
 * @param {Function} callback
*/
export const getCostAllTagsKeys = (params) => {	
	return async dispatch => {
		try {

			const url = GET_COST_ALL_TAGS_KEYS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			  dispatch({ type: ACTION_TYPES.SET_COST_TAG_KEYS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			dispatch({ type: ACTION_TYPES.SET_COST_TAG_KEYS, payload: [] });

			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-all-tags-values
 * @param {Object} body
 * @param {Function} callback
*/
export const getCostAllTagsValues = (params) => {
	return async () => {
		try {

			const url = GET_COST_ALL_TAGS_VALUES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-budget-details
 * @param {Object} body
 * @param {Function} callback
*/
export const listBudgetDetails = (params) => {	
	return async () => {
		try {

			const url = LIST_BUDGET_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
	
}


/**
 * Action to get-cost-total_count
 * @param {Object} body
 * @param {Function} callback
*/
export const getCostTotalCountNew = (params) => {	
	return async () => {
		try {

			const url = GET_COST_TOTAL_COUNT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-cluster-cost-breakup
 * @param {Object} body
 * @param {Function} callback
 */
export const getClusterCostBreakup = (params) => {
	return async () => {		
		try {
			const url = GET_CLUSTER_COST_BREAKUP;
			const response = await ApiCalls.post(url, params)

			return response && response.data
		} catch (error) {			
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-queue-cost-breakup
 * @param {Object} body
 * @param {Function} callback
 */
export const getQueueCostBreakup = (params) => {
	return async () => {		
		try {
			const url = GET_QUEUE_COST_BREAKUP;
			const response = await ApiCalls.post(url, params)

			return response && response.data
		} catch (error) {			
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-user-cost-breakup
 * @param {Object} body
 * @param {Function} callback
 */
export const getUserCostBreakup = (params) => {
	return async () => {		
		try {
			const url = GET_USER_COST_BREAKUP;
			const response = await ApiCalls.post(url, params)

			return response && response.data
		} catch (error) {			
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-user-yearly-cost-breakup
 * @param {Object} body
 * @param {Function} callback
 */
export const getUserYearlyCostBreakup = (params) => {
	return async () => {		
		try {
			const url = GET_USER_YEARLY_COST_BREAKUP;
			const response = await ApiCalls.post(url, params)

			return response && response.data
		} catch (error) {			
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-project-cost-breakup
 * @param {Object} body
 * @param {Function} callback
 */
export const getProjectCostBreakup = (params) => {
	return async () => {		
		try {
			const url = GET_PROJECT_COST_BREAKUP;
			const response = await ApiCalls.post(url, params)

			return response && response.data
		} catch (error) {			
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-project-yearly-cost-breakup
 * @param {Object} body
 * @param {Function} callback
 */
export const getProjectYearlyCostBreakup = (params) => {
	return async () => {		
		try {
			const url = GET_PROJECT_YEARLY_COST_BREAKUP;
			const response = await ApiCalls.post(url, params)

			return response && response.data
		} catch (error) {			
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-cost-allocation-tags
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostAllocationTags = (params) => {
	return async () => {		
		try {
			const url = GET_COST_ALLOCATION_TAGS;
			const response = await ApiCalls.post(url, params)

			return response && response.data
		} catch (error) {			
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}