import React, { useState } from 'react';

const TimeInput = ({ label, callback, className }) => {
    const [time, setTime] = useState('');

    const handleChange = (e) => {
        setTime(e.target.value);
        callback(e.target.value)
    };

    const validateTime = () => {
        // Validate that the time is in the correct format (HH:mm)
        const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return timePattern.test(time);
    };

    return (
        <div className={`w-full items-center ${className}`}>
            <p className="text-NeutralGray-800 text-base flex w-full">{label}</p>
            <input
                type="time"
                id="timeInput"
                value={time}
                onChange={handleChange}
                className="placeholder:italic placeholder:text-NeutralGray-600 text-base block border border-NeutralGray-400 rounded-md py-2.5 pl-2 shadow-sm w-full" 
                placeholder="HH:mm"
            />
        </div>
    );
};

export default TimeInput;
