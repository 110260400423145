/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file DetailTab.js
 * @author Prakash
 *************************************************/
import React from 'react';
const JobScript = ({ selectedJobDetails }) => {
    return (
        <div className="p-6">
            {selectedJobDetails && selectedJobDetails.job_script ?
                <div className='mb-5'>
                    <p className={`text-primaryPurple-600 text-xl font-bold pb-8 capitalize`}>Job Script</p>
                    <pre className='text-black text-wrap' style={{lineHeight: 2}}>{selectedJobDetails.job_script}</pre>
                </div>
            : null}
        </div>
    )
}

export default JobScript