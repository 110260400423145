/*************************************************
 * Collider
 * @exports
 * @file observabilityReducer.js
 * @author Prakash // on 07/06/2023
 * @copyright © 2022 Collider. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../actions/types'

const INITIAL_STATE = {
    observabilityQueryResult: {},
    observabilityQueryList: [],
}

/**
 * To store assets list in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const observabilityReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case ACTION_TYPES.SET_OBSERVABILITY_QUERY_RESULT:
			return {
				...state,
				observabilityQueryResult: { ...action.payload },
			}            
        case ACTION_TYPES.SET_OBSERVABILITY_QUERY_LIST:
			return {
				...state,
				observabilityQueryList: [ ...action.payload ],
			}
		default:
			return state
	}
}
