/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file Summary.js
 * @author Prakash
 *************************************************/
import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { momentConvertionUtcToLocalTime, numberFormater } from '../../../utils/utility';
import ApexDonutChart from '../../common/charts/ApexDonutChart';

const Summary = ({ selectedBatchJobDetails, summaryDetails }) => {
    const [state, setState] = useState({})

    const getBgStatusColor = (status) => {       
        let color = 'bg-darkPurple-50 border-2 border-darkPurple-600 text-darkPurple-600'
        if(status.toLowerCase() === 'cancelled') { color = 'bg-independenceGray-50 border-2 border-independenceGray-600 text-independenceGray-600' } else 
        if(status.toLowerCase() === 'completed') { color = 'bg-slateBlue-50 border-2 border-slateBlue-600 text-slateBlue text-slateBlue-600' } else 
        if(status.toLowerCase() === 'failed') { color = 'bg-red-50 border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'running') { color = 'bg-appleGreen-50 border-2 border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'bg-blue-50 border-2 border-blue-600 text-blue-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'bg-PrimaryLightOrange-50 border-2 border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'pending') { color = 'bg-yellowOrange-50 border-2 border-yellowOrange-600 text-yellowOrange-600' } else 
        if(status.toLowerCase() === 'pending') { color = 'bg-LavenderIndigo-50 border-2 border-LavenderIndigo-600 text-LavenderIndigo-600' }
        

        return color;
    }

    useEffect(() => {
        let donut = []
        let donut_total_count = 0
        let totalSuccessRate = 0
        let totalFailureRate = 0
        if(summaryDetails && summaryDetails.rate) {
            totalSuccessRate = summaryDetails.rate.success_rate ? summaryDetails.rate.success_rate : 0
            totalFailureRate = summaryDetails.rate.failure_rate ? summaryDetails.rate.failure_rate : 0
        }
        let donutRow = {}
        donutRow.item_count = totalSuccessRate
        donutRow.item_name = "Success"
        donut.push(donutRow)
        donut_total_count += totalSuccessRate 

        donutRow = {}
        donutRow.item_count = totalFailureRate
        donutRow.item_name = "Failed"
        donut.push(donutRow)
        donut_total_count += totalFailureRate 
        
        donut = _.orderBy(donut, ['item_count'], ['desc'])

        let successFailureDonutData = {
            'items': donut,
            'label': 'Total',
            'total_count': donut_total_count
        }

        setState(prevState => ({ ...prevState, summaryData: successFailureDonutData, totalSuccessRate, totalFailureRate, summary: summaryDetails && summaryDetails.summary ? summaryDetails.summary : {} }))

    }, [summaryDetails])

    return (
        <div className="p-6">
            <div className='flex flex-wrap'>
                <div className='flex w-1/3'>
                    <p className={`text-black min-w-36 font-bold text-base self-center`}>Status</p>
                    <p className={`rounded-full ${getBgStatusColor(state.summary && state.summary.status ? state.summary.status : "")} px-4 py-2.5`}>{state.summary && state.summary.status ? state.summary.status : ""}</p>
                </div>
                <div className='flex w-1/3'>
                    <p className={`text-black min-w-36 font-bold text-base self-center`}>Total Runtime</p>
                    <p className={`text-sm self-center`}>{state.summary && state.summary.execution_time}</p>
                </div>
                <div className='flex w-1/3'>
                    <p className={`text-black min-w-36 font-bold text-base self-center`}>Cost</p>
                    <p className={`text-sm self-center`}>{state.summary && state.summary.cost}</p>
                </div>
            </div>
            <div className='flex flex-wrap pt-12'>
                <div className='flex w-1/3'>
                    <p className={`text-black min-w-36 font-bold text-base self-center`}>Created At</p>
                    {state.summary && state.summary.created_at ?
                        <p className={`text-sm self-center`}>{momentConvertionUtcToLocalTime(state.summary && state.summary.created_at, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></p>
                    : null}
                </div>
                <div className='flex w-1/3'>
                    <p className={`text-black min-w-36 font-bold text-base self-center`}>Started At</p>
                    {state.summary && state.summary.start_time ?
                        <p className={`text-sm self-center`}>{momentConvertionUtcToLocalTime(state.summary && state.summary.start_time, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></p>
                    : null}
                </div>
                <div className='flex w-1/3'>
                    <p className={`text-black min-w-36 font-bold text-base self-center`}>Stopped At</p>
                    {state.summary && state.summary.end_time ?
                        <p className={`text-sm self-center`}>{momentConvertionUtcToLocalTime(state.summary && state.summary.end_time, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></p>
                    : null}
                </div>
            </div>

            <div className='flex flex-wrap pt-12'>
                <div className='w-1/4'>
                    <ul className="w-1/4 text-base self-center mb-4 text-black flex">
                        <li className='flex mr-2'>
                            <span className={`h-4 w-4 self-center rounded-full bg-green-600`}></span> 
                            <span className={`text-nowrap self-center px-2 text-green-600 d-inline-block capitalize`}>Success : {state.totalSuccessRate && state.totalSuccessRate ? state.totalSuccessRate : 0}%</span>
                        </li>
                        <li className='flex'>
                            <span className={`h-4 w-4 self-center rounded-full bg-red-600`}></span> 
                            <span className={`text-nowrap self-center px-2 text-red-600 d-inline-block capitalize`}>Failed : {state.totalFailureRate && state.totalFailureRate ? state.totalFailureRate : 0}%</span>
                        </li>
                    </ul>
                    {state.summaryData ?
                        <ApexDonutChart
                            graphData={state.summaryData}
                            showTotal={true}
                            showTotalLable={true}
                            hideTooltip={true}
                            totalLableType={'integer'}
                            height={300}
                            width={300}
                            labelSize={"16px"}
                            valueSize={"16px"}
                            size={'70%'}
                            formatedColors={{'failed': '#AA2217', 'success': '#357835'}}
                            colors={['#357835', '#AA2217']}
                            className={'transparentTooltip'}
                        />
                    : null}
                </div>
                <div className="lg:w-3/4 lg:mt-0 mt-3 lg:pl-4 w-full flex flex-wrap justify-start xl:ml-0 self-center ">
                    {summaryDetails && summaryDetails.count ? 
                        Object.entries(summaryDetails.count).map(([key, value], index) => {
                            return(
                                <div class="w-1/5 mb-6 min-h-20" key={'key__'+index}>
                                    <div className={`flex flex-wrap self-center w-3/4 text-xl rounded-lg h-full border-l-8 ${getBgStatusColor(key)} cursor-pointer`}>
                                        <span class={`flex items-center justify-center w-full self-end capitalize`}>{key.toLowerCase()}</span>
                                        <span class={`flex items-center justify-center w-full self-start`}>{numberFormater(value)}</span>
                                    </div>
                                </div>
                            )
                        })
                    : null}
                </div>
            </div>
        </div>
    )
}

export default Summary