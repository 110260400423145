/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LogsTab.js
 * @author Prakash // on 06/08/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime } from '../../../utils/utility'
import { downloadReport, listReports } from '../../../actions/ImagebuilderAction'

const ReportsTab = ({selectedDetails, fromPage}) => {
    
    const dispatch = useDispatch(); // Create a dispatch function

    const [state, setState] = useState({
        showReportLoading: true,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        callReportsList: true
    })

    useEffect(() => {
        if(state.callReportsList) {
            let params = {
                provider: selectedDetails.provider,
                account_id: selectedDetails.account_id,
                region: selectedDetails.region,
            };
    
            if (selectedDetails.image_id) {
                params.image_id = selectedDetails.image_id;
            }

            if((fromPage === 'Scan' || fromPage === 'Build') && selectedDetails.build_result_key) {
                params.build_result_key = selectedDetails.build_result_key;
            }
    
            dispatch(listReports(params))
                .then((response) => {
                    if(response) {
                        let totalResults = response.results && response.results.length ? response.results : []
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage

                        let totalPages = 1                
                        if(totalResults && totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }
    
                        setState(prevState => ({ ...prevState, reportsList: totalResults, filteredReportsList: totalResults, totalPages, startRecord, currentPage, showReportLoading: false, callReportsList: false, nextLogToken: response.next_token, }));
                    }
                })
        }
    }, [state.callReportsList, dispatch, selectedDetails, state.perPage, state.reportsList, state.nextLogToken, state.startRecord, state.currentPage, state.totalPages, fromPage])

    useEffect(() => {
        let dataResult = state.reportsList && state.reportsList.length ? state.reportsList : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, headers }))
		}
    }, [state.reportsList])

    const downloadReportFunction = (item) => {

        dispatch(downloadReport(item))
            .then((response) => {
                if(response) {
                    if(response.status) {
                        if(response && response.url) {
                            let fileUrl = response.url
                            window.open(fileUrl, '_blank')
                            setState(prevState => ({ ...prevState, downloadUrl: response.url, showDownloadLoading: false }));
                        } else {
                            setState(prevState => ({ ...prevState, showDownloadLoading: false }));
                        }
                    } else {
                        setState(prevState => ({ ...prevState, showDownloadLoading: false }));
                    }
                    
                }
            })
    }
    
    return (
        <div className='m-2'>
            <div className='w-full p-3'>
                <div className='flex justify-between'>
                    <p className='flex mr-2 w-full'>
                        <span className='text-lightGray text-xs m-0 self-center pt-1'>Showing {state.filteredReportsList && state.filteredReportsList.length} of total {state.reportsList && state.reportsList.length} report(s)</span>
                    </p>
                </div>

                <div className='flex overflow-auto h-screen'>
                    <div className={`w-3/4`}>
                        <div className={`py-3`} >
                            <div className="relative overflow-x-auto mt-2">
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead className="text-md text-black bg-lightGray">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">Scan Date</th>
                                            <th scope="col" className="px-6 py-3">Vulnerabilities</th>
                                            <th scope="col" className="px-6 py-3">Scan Report</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-black bg-white'>
                                        {state.filteredReportsList && state.filteredReportsList.length ?
                                            state.filteredReportsList.slice(state.startRecord, state.startRecord + state.perPage).map((row, index)=> {
                                                return(
                                                    <tr key={'sft_'+index} className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700`}>
                                                        <td className="px-6 py-3 text-sm" key={'sftin_'+index}>{row.created_at ? momentConvertionUtcToLocalTime(row.created_at, 'DD MMM YYYY HH:mm') : ''}</td>
                                                        <td className="px-6 py-3 text-sm" key={'sftin_'+index}>
                                                            {row.vulnerabilities ? row.vulnerabilities : ''}
                                                            {/* <span className='text-black ml-2'>(H: {row.high && row.high}</span>
                                                            <span className='text-black ml-2'>M: {row.medium && row.medium}</span>
                                                            <span className='text-black ml-2'>L: {row.low && row.low})</span> */}
                                                        </td>
                                                        <td className="px-6 py-3 text-sm" key={'sftin_'+index}>

                                                            <p className='flex text-info cursor-pointer' onClick={() => {
                                                                if(!state.showDownloadLoading) {
                                                                    setState(prevState => ({ ...prevState, showDownloadLoading: true }))
                                                                    downloadReportFunction(row)
                                                                }
                                                            }}>
                                                                {state.showDownloadLoading ?
                                                                    <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="black" viewBox="0 0 24 24">
                                                                        <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="2" fill="none" />
                                                                        <path d="M4 12a8 8 0 0112-6.9" />
                                                                    </svg>
                                                                : null}
                                                                Download
                                                            </p>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        : 
                                            <tr key={'sft'} className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700`}>
                                                <td className="px-6 py-3 text-sm" colSpan={3}>
                                                    <div className='flex justify-center m-4'>
                                                        {/* <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="white" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="10" stroke="white" strokeWidth="2" fill="none" />
                                                            <path d="M4 12a8 8 0 0112-6.9" />
                                                        </svg> */}
                                                        <p className='text-black'>No reports found.</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* {state.showReportLoading ? 
                                <div className='flex justify-center m-4'>
                                    <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="white" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="white" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                </div>
                            : 
                                state.filteredReportsList && state.filteredReportsList.length ?
                                    state.filteredReportsList.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                        return(
                                            <div className={`flex mr-2 px-2 mb-2 pb-1 ${(state.startRecord + state.perPage !== state.startRecord) && state.filteredReportsList.length !== i+1 ? 'border-b border-mediumDarkGray' : ''} cursor-pointer`} key={'key_'+i}>
                                                <div className='flex'>
                                                    <p className='mb-0'>
                                                        <span className='mb-0 mr-2 text-black text-xs font-bold'>{item.created_at ? momentConvertionUtcToLocalTime(item.created_at, 'DD MMM YYYY HH:mm') : ''} </span>
                                                        <span className='mb-0 mr-2 text-xs text-black font-bold'>vulnerabilities {item.failed}</span>
                                                    </p>
                                                    <div className={`text-xs text-black rounded-full bg-critical px-2 py-1 self-center`}>C</div>
                                                    <p className="m-0 text-sm text-black mr-1"><span className="mx-1">:</span> {item.critical ? item.critical : 0}</p>
                                                    <div className={`text-xs text-black rounded-full bg-high px-2 py-1 self-center`}>H</div>
                                                    <p className="m-0 text-sm text-black mr-1"><span className="mx-1">:</span> {item.high ? item.high : 0}</p>
                                                    <div className={`text-xs text-black rounded-full bg-medium px-2 py-1 self-center`}>M</div>
                                                    <p className="m-0 text-sm text-black mr-1"><span className="mx-1">:</span> {item.medium ? item.medium : 0}</p>
                                                    <div className={`text-xs text-black rounded-full bg-low px-2 py-1 self-center`}>L</div>
                                                    <p className="m-0 text-sm text-black mr-1"><span className="mx-1">:</span> {item.low ? item.low : 0}</p>
                                                </div>

                                                <p className='mb-0'> <span className='text-info' onClick={() => {
                                                    setState(prevState => ({ ...prevState, showDownloadLoading: true }))
                                                    downloadReportFunction(item)
                                                }}>Click here</span> to download scanned reports</p>
                                            </div>
                                        )
                                    })
                                :
                                    <div className='flex justify-center m-4 text-black'>
                                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                    </div>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportsTab