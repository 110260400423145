/*************************************************
 * Collider
 * @exports
 * @file ApexLineChartSpendBoard.js
 * @author Prakash // on 26/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, momentDateGivenFormat, removeUnderScore } from '../../../utils/utility'
import Chart from 'react-apexcharts'


class ApexLineChartSpendBoard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tooltipType: this.props.tooltipType
        }
    }
    
    componentDidMount = () => {
        let graphData = this.props.graphData;
        if(graphData){

            
            let colors = this.props.colors ? this.props.colors : ['#ffadad', '#ffd6a5', '#3F73AD', '#24A597']
            let series = []
            let graphLables = graphData.labels
            
            let highestValue = 0
            let categories = []
            
            Object.entries(graphData).forEach(([key, value]) => {
                let dataArray =[]
                if(key !== 'labels') {
                    if(this.props.xaxisFormat === "categoryString") {
                        dataArray = value
                        categories = graphLables
                    } else {
                        graphLables && graphLables.forEach((item, i) => {
                            categories.push(momentDateGivenFormat(graphLables[i], 'MMM Y'))
                            let date = momentConvertionUtcToLocalTime(graphLables[i], 'DD MMM YYYY HH:mm')
                            if(this.props.tooltipType && this.props.tooltipType === "dontConvertDate") {
                                if(this.props.xaxisFormat === "category") {
                                    date = momentDateGivenFormat(graphLables[i], 'MMM Y')
                                } else if(this.props.xaxisFormat ==="date") {
                                    date = momentDateGivenFormat(graphLables[i], 'DD MMM Y')
                                } else if(this.props.xaxisFormat ==="datetime") {
                                    date = momentDateGivenFormat(graphLables[i], 'DD MMM YYYY HH:mm')
                                }
                            } else {
                                if(this.props.xaxisFormat ==="date") {
                                    date = momentConvertionUtcToLocalTime(graphLables[i], 'DD MMM Y')
                                } else if(this.props.xaxisFormat ==="datetime") {
                                    date = momentConvertionUtcToLocalTime(graphLables[i], 'DD MMM YYYY HH:mm')
                                } else {
                                    date = momentConvertionUtcToLocalTime(graphLables[i], 'DD MMM YYYY HH:mm')
                                }
                            }
                            
                            let dataItems = {}
                            dataItems.x = date
                            dataItems.y = value[i]
                            dataArray.push(dataItems)
                            
                            if(highestValue < value[i]) {
                                highestValue = value[i]
                            }
                        })                        
                    }
                    let type = "area"
                    let seriesRow = {}
                    seriesRow.name = capitalizeFirstLetter(key)
                    seriesRow.tooltipLabel = graphData.tooltipLabel ? graphData.tooltipLabel : ''
                    seriesRow.type = type
                    seriesRow.data = dataArray
                    series.push(seriesRow)
                }
            })
            let fillData = {}
            if(this.props.gradient) {
                // fillData = {
                //     // colors:[this.props.gradientColor ? this.props.gradientColor[0] : "#26B68F"],
                //     type: 'gradient',
                //     // gradient: {
                //     //     type: 'vertical', // The gradient in the horizontal direction
                //     //     gradientToColors: [this.props.gradientColor ? this.props.gradientColor[1] : '#bee9de'], // The color at the end of the gradient
                //     //     opacityFrom: 1, // transparency
                //     //     opacityTo: .5,
                //     //     stops:[0, 120]
                //     // }
                //     shadeIntensity: .5,
                //     // inverseColors: true,
                //     // opacityFrom: 0.3,
                //     // opacityTo: 0.6,
                //     gradientToColors: [
                //         [{
                //             offset: 0,
                //             color: this.props.gradientColor[0],
                //             opacity: 1
                //         },
                //         {
                //             offset: 50,
                //             color: 'red',
                //             opacity: .6
                //         },
                //         {
                //             offset: 100,
                //             color: 'green',
                //             opacity: 1
                //         }]
                //     ],
                //     // stops: [0, 50, 100]

                //     // shadeIntensity: 0.5,
                //     // inverseColors: true,
                //     // opacityFrom: 1,
                //     // opacityTo: 1,
                //     // stops: [0, 50, 100],
                //     // colorStops: []
                // }
                fillData = {
                    type: "gradient",
                    gradient: {
                      gradientToColors: [this.props.gradientColor ? this.props.gradientColor[0] : '#bee9de'],
                      shadeIntensity: 1,
                      opacityFrom: 0.7,
                      opacityTo: 0.2,
                      stops: [0, 85, 100]
                    }
                }
            }

            let tooltip = {}
            if(this.props.customTooltip) {
                tooltip = {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let returnData = ''
                        returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'
                        returnData += w.globals.initialSeries[seriesIndex].data[dataPointIndex].x
                        returnData += '</div>'
                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[seriesIndex] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[seriesIndex].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[seriesIndex].data[dataPointIndex].y+'</span></div></div></div>'
                        returnData += '</div>'
                        return returnData
                    },
                    style: {
                        fontSize: '9px',
                    },
                }
            } else if(this.props.customHpcDashboardTooltip) {
                tooltip = {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let returnData = ''
                        returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'
                        returnData += momentConvertionUtcToLocalTime(w.globals.initialSeries[seriesIndex].data[dataPointIndex].x, 'DD MMM Y')
                        returnData += '</div>'
                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active flex"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[seriesIndex].tooltipLabel+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[seriesIndex].data[dataPointIndex].y+'</span></div></div></div>'
                        returnData += '</div>'
                        return returnData
                    },
                    style: {
                        fontSize: '9px',
                    },
                }
            }

            let options = {
                // tooltip: tooltip,
                chart: {
                    redrawOnParentResize: true,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: this.props.zoom,
                    },
                    sparkline: {
                        enabled: this.props.sparkline
                    },
                    animations: {
                        enabled: this.props.animation
                    },
                    type: 'line',
                    height: 150,
                    stacked: this.props.stacked,
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: colors,
                    width: [this.props.stroke ? this.props.stroke : 1],
                    dashArray: 0,      
                },
                dataLabels:{
                    enabled: false,
                },
                colors: colors,
                grid: {
                    show: true,
                    xaxis: {
                        axisBorder: {
                            show: this.props.gridXaxisBorder ? this.props.gridXaxisBorder : false,
                            color: this.props.gridColor ? this.props.gridColor : '',
                        }
                    },
                    yaxis: {
                        axisBorder: {
                            show: this.props.gridYaxisBorder ? this.props.gridYaxisBorder : false,
                            color: this.props.gridColor ? this.props.gridColor : '',
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: this.props.paddingLeft
                    },
                },
                xaxis: {
                    title: {
                        text: this.props.xaxisTitle ? removeUnderScore(this.props.xaxisTitle) : '',
                        style: {
                            color: this.props.axisLabelColor
                        }
                    },
                    show: this.props.xaxis,
                    tooltip: {
                        enabled: false
                    },
                    axisTicks: {
                        show: false
                    },
                    type: this.props.xaxisFormat && (this.props.xaxisFormat === "date" || this.props.xaxisFormat === "datetime") ? "datetime" : (this.props.xaxisFormat ? this.props.xaxisFormat : "datetime"),
                    categories: (this.props.xaxisFormat === "category" || this.props.xaxisFormat === "categoryString") ? categories : [],
                    axisBorder: {
                        show: true,
                        color: '#434B5E',
                    },
                    labels: {
                        show: this.props.xaxisLabel,
                        style: {
                            colors: this.props.axisLabelColor,
                            fontSize: this.props.axisLabelFontSize ?  this.props.axisLabelFontSize : '13px',
                            fontWeight: 'bolder',
                        },
                    },
                },
                yaxis: [{
                    title: {
                        text: this.props.yaxisTitle ? removeUnderScore(this.props.yaxisTitle) : '',
                        style: {
                            color: this.props.axisLabelColor
                        }                        
                    },
                    // tickAmount: parseInt(highestValue) <= 1 ? 1 : (this.props.yAxisTickAmount ? this.props.yAxisTickAmount : ""),
                    labels: {
                        show: this.props.yaxis,                        
                        style: {
                            colors: this.props.axisLabelColor,
                            fontSize: this.props.axisLabelFontSize ?  this.props.axisLabelFontSize : '13px',
                            fontWeight: 'bolder',
                        },
                        formatter: function(val) {
                            if(val > 0 && val < 1) {
                                return val.toFixed(1);
                            } else {
                                return parseInt(val)
                            }
                        },
                    }                
                }],
                fill: fillData,
                legend: {
                    show: series.length < 30 && this.props.legend ? true : false,
                    position: "bottom",
                    formatter: function (seriesName, opts) {
                        return `<table>
                        <tr>
                            <th>${seriesName}</th>
                        </tr>
                        </table>`;
                    },
                    offsetY: 7,
                    markers: {
                        radius: 12,
                    },
                    labels: {
                        colors: this.props.colors,
                        useSeriesColors: true
                    },
                },
                tooltip: tooltip
            }

            this.setState({ series, options });
        }
    }

    render() {
        return(
            <div className={`${this.props.className}`}>
                {this.state.series ?
                    <Chart options={this.state.options} series={this.state.series} type="area" height={this.props.height} />
                : null}
            </div>
        )
    }
}
export default ApexLineChartSpendBoard