/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file Runs.js
 * @author Prakash
 *************************************************/
import React from 'react';
import { getCommonInformationMessage, momentConvertionUtcToLocalTime } from '../../../utils/utility';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';

const Runs = ({ selectedBatchJobDetails, summaryDetails, onJobSelection }) => {
    const getBgStatusColor = (status) => {       
        let color = 'bg-darkPurple-50 border-2 border-darkPurple-600 text-darkPurple-600'
        if(status.toLowerCase() === 'cancelled') { color = 'bg-independenceGray-50 border-2 border-independenceGray-600 text-independenceGray-600' } else 
        if(status.toLowerCase() === 'completed') { color = 'bg-slateBlue-50 border-2 border-slateBlue-600 text-slateBlue text-slateBlue-600' } else 
        if(status.toLowerCase() === 'failed') { color = 'bg-red-50 border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'running') { color = 'bg-appleGreen-50 border-2 border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'bg-blue-50 border-2 border-blue-600 text-blue-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'bg-PrimaryLightOrange-50 border-2 border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'pending') { color = 'bg-yellowOrange-50 border-2 border-yellowOrange-600 text-yellowOrange-600' } else 
        if(status.toLowerCase() === 'pending') { color = 'bg-LavenderIndigo-50 border-2 border-LavenderIndigo-600 text-LavenderIndigo-600' }
        

        return color;
    }

    return (
        <div className="p-6">
            <div className="relative overflow-x-auto mt-3 rounded-t-lg shadow">
                {summaryDetails && summaryDetails.runs && summaryDetails.runs.length ?
                    <ResizeableDarkThemeTable
                        columns={[
                            {
                                Header: 'Name',
                                accessor: 'job_name',
                                Cell: cellInfo => (
                                    <div className='flex flex-wrap'>
                                        {cellInfo.row.original.job_id === "229" ?
                                            <div className='w-full mb-2'>
                                                <span className={`bg-slateBlue-50 border-2 border-slateBlue-600 text-slateBlue-600 w-fit h-fit rounded-md text-sm font-medium px-2 flex justify-center items-center`}>Batch</span>
                                            </div>
                                        : null}
                                        <p className='w-full text-slateBlue-600 cursor-pointer' onClick={() => {
                                            onJobSelection(cellInfo.row.original)
                                        }}> {cellInfo.row.original.job_id ? cellInfo.row.original.job_id : ''} </p>
                                        
                                        <p className='w-full text-black cursor-pointer' onClick={() => {
                                            onJobSelection(cellInfo.row.original)
                                        }}> {cellInfo.row.original.job_name ? cellInfo.row.original.job_name : ''} </p>
                                    </div>
                                ),
                                width: 250,
                            },
                            {
                                Header: 'Status',
                                accessor: 'Status',
                                Cell: cellInfo => (
                                    <div className='flex'>
                                        {cellInfo.row.original.status ?
                                            <span className={`${getBgStatusColor(cellInfo.row.original.status)} w-fit h-fit rounded-full text-sm font-medium px-2 flex justify-center items-center`}>{cellInfo.row.original.status}</span>
                                        : null}
                                    </div>
                                ),
                            },
                            {
                                Header: 'Started At',
                                accessor: 'start_time',
                                Cell: cellInfo => (<span>{cellInfo.row.original.start_time ? momentConvertionUtcToLocalTime(cellInfo.row.original.start_time, 'DD MMM YYYY HH:mm') : ''}</span>),
                                // width: 180,
                            },
                            {
                                Header: 'Total Run Time',
                                accessor: 'execution_time',
                                // width: 200,
                                // Cell: cellInfo => (<span>{cellInfo.row.original.elapsed_time_in_secs ? cellInfo.row.original.elapsed_time_in_secs : ''}</span>)
                            }
                        ]}
                        data={summaryDetails.runs}
                        perPage={10}
                        selectedColor={'bg-backGroundGray'}
                        dashboard={summaryDetails.runs && summaryDetails.runs.length ? true : false}
                        sortByColumn={''}
                        onClickRow={tableRow => {}}
                        onChangeAction={(actions, item) => {}}
                    />
                : 
                    <div className='flex justify-center m-4'>
                        {getCommonInformationMessage('run job')}
                    </div>
                }
            </div>
        </div>
    )
}

export default Runs