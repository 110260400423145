// App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppWrapper from './common/AppWrapper';
import { AppRoutes } from '../routes'; // Import your separate route configuration
import { AppConfig } from "../AppConfig";
// import '../assets/fontawesome5/css/all.min.css'
// import '../assets/css/bootstrap.css'
// import '../assets/css/bootstrap.min.css'
// import '../assets/css/style.scss'
// import '../assets/css/dev_style.scss'
// import '../assets/css/collider.scss'
// import '../assets/css/dev.css'

// MSAL imports
// import { MsalProvider } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from "@azure/msal-react";
import { loginRequest, msalConfig } from '../authConfig'

import { PublicClientApplication } from "@azure/msal-browser";
// import { momentDateFormat } from '../utils/utility';

const msalInstance = new PublicClientApplication(msalConfig);

const basePath = AppConfig.appBasePath;

const WrappedView = () => {
    const { instance } = useMsal()

    const handleRedirect = () => {
        localStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem('ACCESS_TOKEN_EXPIERS_ON');
        instance.loginRedirect({...loginRequest})
            .catch((error) => console.log(error))
    };

    const account = msalInstance.getAllAccounts()[0];
    if(!account) {
        handleRedirect()
    }
    // const fetchData = useCallback(async()=> {
    //     let tokenAcquired = false;
    //     let lastLogin = localStorage.getItem('ACCESS_TOKEN')
    //     if(lastLogin) {
    //         tokenAcquired = true
    //     }

    //     let expiresOn = localStorage.getItem('ACCESS_TOKEN_EXPIERS_ON')
    //     // let convertedDate = expiresOn ? momentDateFormat(expiresOn, 'YYYY-MM-DD HH:mm:ss') : undefined
    //     // if(tokenAcquired || !expiresOn || (expiresOn && convertedDate <= localStorage())) {
    //     //     tokenAcquired = false
    //     // }
    //     const account = msalInstance.getAllAccounts()[0];
    //     if (!tokenAcquired && account) {
    //         // Ensure you have a valid account object before calling acquireTokenSilent
    //         if (!account) {
    //             throw new Error('No active account available1.');
    //         }

    //         const accessTokenRequest = {
    //             scopes: ['User.Read'],
    //             account: account,
    //             authority: 'https://login.microsoftonline.com/cloudinvisibl.onmicrosoft.com',
    //             redirectUri: '/',
    //             // auth_uri: "https://login.microsoftonline.com/cloudinvisibl.onmicrosoft.com/oauth2/v2.0/authorize",
    //             // token_uri: "https://login.microsoftonline.com/cloudinvisibl.onmicrosoft.com/oauth2/v2.0/token",
    //             // client_id: "c416e83a-79dc-4676-b2ce-11769a102c6f",
    //             // client_secret: "TwJ8Q~6FtTX2Bcyptzo~ABV6uIC_NFEKMr4kvbZi",
    //             // expiresInSeconds: 3600,
    //         };
    //         await instance.initialize()
    //         const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
    //         const accessToken = accessTokenResponse?.idToken;

    //         const expiresOn = accessTokenResponse?.expiresOn;
    //         localStorage.setItem('ACCESS_TOKEN', accessToken ?? '');
    //         localStorage.setItem('ACCESS_TOKEN_EXPIERS_ON', expiresOn ?? '');
    //         tokenAcquired = true;

    //         // Set the tokenAcquired flag to true after acquiring the token
    //     }
    // }, [instance])

    // useEffect(() => {
    //     fetchData()
    // }, [fetchData]);

    // const handleLogout = () => instance.logout()
    
    return (
        <React.Fragment>
            <AuthenticatedTemplate>
                <Router basename={basePath}>
                    {/* <button className='btn btn-primary w-100' onClick={handleLogout}>Logout</button> */}
                    {/* <button className='btn btn-primary w-100' onClick={handleToke}>Get Token</button> */}
                    <AppWrapper>
                        <AppRoutes />
                    </AppWrapper>
                </Router>
            </AuthenticatedTemplate>
            {/* <UnauthenticatedTemplate>
                <div className="flex position-fixed overflow-auto h-100 w-100">
                    <div className="flex-grow-1 bg-dark text-white p-5 flex align-items-center">
                        <div className="self-center w-100">
                            <div className="d-table m-auto w-25">
                                <h5 className="my-3 text-center">Welcome to the Kiosk</h5>
                                <div className="p-4 bg-transparent">
                                    <button
                                        className='btn btn-primary w-100'
                                        onClick={handleRedirect}
                                        // disabled={state.showLoading}
                                    >
                                        Login
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </UnauthenticatedTemplate> */}
        </React.Fragment>
    )
}

function App({ msalInstance }) {  
    return (
        <MsalProvider instance={msalInstance}>
            <WrappedView />
        </MsalProvider>
    );
}
export default App