/*************************************************
 * Tvastar
 * @exports
 * @file PolicyDetails.js
 * @author Prakash // on 07/01/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate, useLocation, Link } from 'react-router-dom';
import _ from 'lodash'

import { Store as CommonNotification } from 'react-notifications-component'

import { URL_PATH } from '../../config/urlPath'
import {Icon} from "@iconify/react";

import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../actions/commonActionNew'
import { setNewInitialRuleDetails, setNewEditRuleDetails, setGovernancePropsDetails, getMasterGovernanceRules, createGovernancePolicy, editGovernancePolicy, getGovernanceServices, cleareNewEditRuleDetails, listGovernanceType } from '../../actions/governance/GovernanceAction'

import DynamicServices from './Section/DynamicServices'
import RdsServiceEnginesTemplate from './Section/RdsServiceEnginesTemplate'
import RdsServiceLogs from './Section/RdsServiceLogs'
import RdsServiceCapacity  from './Section/RdsServiceCapacity'
import RdsServiceBlockStore from './Section/RdsServiceBlockStore'
import Ec2ServiceBlockStore from './Section/Ec2ServiceBlockStore'
import ElasticServiceBlockStore from './Section/ElasticServiceBlockStore';
import DedicatedMasterServiceCapacity from './Section/DedicatedMasterServiceCapacity'
import DataNodeServiceCapacity from './Section/DataNodeServiceCapacity';
import SnsServiceLogs from './Section/SnsServiceLogs';

import MultiSelectSection from '../common/MultiSelectSection';
import { capitalizeAllLetter } from '../../utils/utility';
import AppliesToSection from './AppliesToSection';

const PolicyDetails = () => {
	const [state, setState] = useState({
		listLoading: false,
		serviceArray: [0],

		finishBtn: 'Save',

		showRuleInfoSection: true,

		showAppliedSection: false,

		// governanceTypes: [
		// 	{ label: 'HPC Slurm', value: 'hpc-slurm' },
		// 	{ label: 'Access Managment Policy', value: 'access' },
		// 	{ label: 'Global Policy', value: 'global' },
		// 	{ label: 'Infrastructure Policy', value: 'infra' },
		// 	{ label: 'OPA Policy', value: 'opa' },
		// 	{label: "Kubernetes(Gatekeeper) Policy", value: "opa_kubernetes"},
		// ],

		showActionSection: false,
		actionArray: [0],

		showNotificationSection: false,
		notificationArray: [0],
		notificationType: ['Slack', 'Email'],

		actionMethods: [
			{ value: 'immediately', label: 'Immediately after the violation' },
			{ value: 'run_at', label: 'At' },
			{ value: 'run_after', label: 'After' },
		],
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const providers = useSelector(state => state?.filters?.providers || false);
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);
	const service_master_details = useSelector(state => state?.governance?.governancePropsDetails?.service_master_details)
	const editNewRuleDetails = useSelector(state => state?.governance?.editNewRuleDetails || false)
	const navigate = useNavigate();
	const location = useLocation();
	const { ruleDetails } = location.state || {};

	// console.log("editNewRuleDetails", editNewRuleDetails)

	useEffect(() => {
		dispatch((listGovernanceType()))
			.then((response)=> {
				if(response) {
					setState(prevState => ({ ...prevState, governanceTypes: response && response.length ? response : [] }))
				}
			})
	}, [dispatch])

	const handleInputChange = useCallback((label, value) => {
		if (label === 'governance_type' && state.governance_type) {
			dispatch(setNewEditRuleDetails('policies', []));
			dispatch(setNewEditRuleDetails('applies_to', []));
			dispatch(setNewEditRuleDetails(label, value));
		} else {
			dispatch(setNewEditRuleDetails(label, value))
		}

	}, [dispatch, state.governance_type])

	// list all providers
    useEffect(() => {
        if (providers) {
			let selectedProvider = providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""
            setState(prevState => ({
                ...prevState,
                providers: providers.filter(e => e.provider_name === 'AWS'),
				selectedProvider,
                callAccountList: true,
                callRegionList: true
            }));			
        } else {
            let params = {}
            dispatch(listAllProviders(params))
        }
    }, [dispatch, providers]);// Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
			handleInputChange('provider', state.selectedProvider.toLowerCase())
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch, handleInputChange]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

	useEffect(() => {
		if(state.queryLoading) {
			setTimeout(() => { setState(prevState => ({ ...prevState, queryLoading: false })) }, 10000);
		}
	}, [state.queryLoading])

	const onClickServicesBlock = useCallback((field, value, index) => {
		if (value === 'global' || value === 'opa' || value === 'opa_kubernetes' || value === 'hpc-slurm') {
			setState(prevState => ({ ...prevState, ['show_resource_type_' + value]: true, ['resource_type_' + value]: value }))
		} else {
			state.services && state.services.forEach(row => {
				if (row === value) {
					setState(prevState => ({ ...prevState, ['show_resource_type_' + value]: true }))
				} else {
					setState(prevState => ({ ...prevState, ['show_resource_type_' + row]: false }))
				}
			})
		}
	}, [setState, state.services])
	
	const masterData = useCallback((field, value, index) => {
		let params = {}
		if (editNewRuleDetails.governance_type !== 'global' && editNewRuleDetails.governance_type !== 'opa' && editNewRuleDetails.governance_type !== 'opa_kubernetes') {
			params.resource_type = value
			params.provider = editNewRuleDetails.provider ? editNewRuleDetails.provider : ''
		}
		params.governance_type = editNewRuleDetails.governance_type
	
		if (params.provider && params.resource_type) {
			dispatch(getMasterGovernanceRules(params))
				.then((response) => {
					if (response) {
						// response.forEach((datt, datIndex) => {
						// 	let filter = datt.policies && datt.policies.filter(e => e.template)
						// })
						setState(prevState => ({ ...prevState, ['masterData_' + value]: response, ['showServiceLoading_'+ index]: false }))
						onClickServicesBlock(field, value, index)
					} else {
						setState(prevState => ({ ...prevState, masterData: [], ['showServiceLoading_'+ index]: false })) 
						onClickServicesBlock(field, value, index)
					}
				})
		} else {
			setState(prevState => ({ ...prevState, ['showServiceLoading_'+ index]: false }))
		}
	}, [dispatch, editNewRuleDetails.provider, editNewRuleDetails.governance_type, onClickServicesBlock, setState])
	
	useEffect(() => {
		if (ruleDetails && Object.entries(ruleDetails).length && !_.isEqual(state.ruleDetails, ruleDetails)) {
			let policyBasicDetails = ruleDetails.policy_metadata || {};
			let policyInformation = ruleDetails.policies || [];
					
			let policies = []
			let serviceArray = []
			
			policyInformation.forEach((item, index) => {
				serviceArray.push(index)
				item.categories.forEach(cat => {
					cat.policies.forEach(catPol => {
						catPol.resource_type = item.resource_type
						policies.push(catPol)
					})
				})
			})
	
			let applies_to = policyBasicDetails.applies_to ? policyBasicDetails.applies_to : []
			
			let groupedPolicies = { policies: policies }
			let mergeObjects = { ...groupedPolicies, ...policyBasicDetails }
			mergeObjects.applies_to = applies_to
			
			dispatch(setNewInitialRuleDetails(mergeObjects));
	
			setState(prevState => ({ ...prevState, ruleDetails, policyBasicDetails, policyInformation, selectedProvider: policyBasicDetails.provider || "AWS", governance_type: policyBasicDetails.governance_type || "", callSetPolicyBasicDetails: true, callGetGovernanceServices: true, structureResponse: true, serviceArray }));
	
			policyInformation.forEach((item, index) => {
				
				setState(prevState => ({ ...prevState, 
					['resource_type_' + index]: item.resource_type,
					selectedProvider: policyBasicDetails && policyBasicDetails.provider ? policyBasicDetails.provider : 'aws',
				}))
	
				masterData('resource_type_' + index, item.resource_type, index)
			})
	
			if (policyBasicDetails.governance_type === 'global' || policyBasicDetails.governance_type === 'opa' || policyBasicDetails.governance_type === 'opa_kubernetes' || policyBasicDetails.governance_type === 'hpc-slurm') {
				setState(prevState => ({ ...prevState, callGlobalMastartData: true }))
			} else if(policyBasicDetails.governance_type) {
				setState(prevState => ({ ...prevState, callGetGovernanceServices: true }))
			}
			
		}
	}, [dispatch, masterData, ruleDetails, setState, state])	
	

	useEffect(() => {
		if(state.structureResponse) {
			setState(prevState => ({ ...prevState, structureResponse: false }))
			let policyBasicDetails = ruleDetails.policy_metadata ? ruleDetails.policy_metadata : {}
			let policyInformation = ruleDetails.policies && ruleDetails.policies.length ? ruleDetails.policies : []
			let policies = []
			let serviceArray = []
			policyInformation.forEach((item, index) => {
				serviceArray.push(index)
				item.categories.forEach(cat => {
					cat.policies.forEach(catPol => {
						catPol.resource_type = item.resource_type
						policies.push(catPol)
					})
				})
			})
			let applies_to = policyBasicDetails.applies_to ? policyBasicDetails.applies_to : []

			let groupedPolicies = { policies: policies }
			let mergeObjects = { ...groupedPolicies, ...policyBasicDetails }
			mergeObjects.applies_to = applies_to
	
			dispatch(setNewInitialRuleDetails(mergeObjects))
		}
	}, [state.structureResponse, ruleDetails, dispatch])

	// useEffect(() => {
	// 	if(ruleDetails && Object.entries(ruleDetails).length && !_.isEqual(state.ruleDetailsState, ruleDetails)) {
	// 		let policyBasicDetails = ruleDetails.policy_metadata ? ruleDetails.policy_metadata : {}
	// 		let policyInformation = ruleDetails.policies && ruleDetails.policies.length ? ruleDetails.policies : []
	// 		setState(prevState => ({ ...prevState, ruleDetails, policyBasicDetails, policyInformation, selectedProvider: policyBasicDetails.provider ? policyBasicDetails.provider : "", governance_type: policyBasicDetails.governance_type ? policyBasicDetails.governance_type : "", callSetPolicyBasicDetails: true, callGetGovernanceServices: true, ruleDetailsState: ruleDetails,
	// 			// callStructureEditData: true
	// 		}))

	// 		let policies = []
	// 		let serviceArray = []
	// 		policyInformation.forEach((item, index) => {
	// 			serviceArray.push(index)
	// 			item.categories.forEach(cat => {
	// 				cat.policies.forEach(catPol => {
	// 					catPol.resource_type = item.resource_type
	// 					policies.push(catPol)
	// 				})
	// 			})
	// 			setState(prevState => ({ ...prevState, 
	// 				['resource_type_' + index]: item.resource_type,
	// 				serviceArray,
	// 				selectedProvider: policyBasicDetails && policyBasicDetails.provider ? policyBasicDetails.provider : 'aws',
	// 			}))

	// 			masterData('resource_type_' + index, state['resource_type_' + index], index)
	// 		})

	// 		let applies_to = policyBasicDetails.applies_to ? policyBasicDetails.applies_to : []
	// 		let appliesToArray = [0]
	// 		if (applies_to.length) {
	// 			appliesToArray = []
	// 			applies_to.forEach((row, index) => {
	// 				appliesToArray.push(index)
	// 				setState(prevState => ({ ...prevState, ['selected_account_' + index]: row.account_id, ['selected_region_' + index]: row.region ? row.region : [], ['selected_tags_' + index]: row.tags ? row.tags : [], ['cluster_id_' + index]: row.cluster_id, ['cluster_name_' + index]: row.cluster_name }))
	// 			})
	// 		}

	// 		let groupedPolicies = { policies: policies }
	// 		let mergeObjects = { ...groupedPolicies, ...policyBasicDetails }
	// 		mergeObjects.applies_to = applies_to

	// 		if (policyBasicDetails.governance_type === 'global' || policyBasicDetails.governance_type === 'opa' || policyBasicDetails.governance_type === 'opa_kubernetes' || policyBasicDetails.governance_type === 'hpc-slurm') {
	// 			setState(prevState => ({ ...prevState, callGlobalMastartData: true }))
	// 		} else if(policyBasicDetails.governance_type) {
	// 			setState(prevState => ({ ...prevState, callGetGovernanceServices: true }))
	// 		}
			
	// 		dispatch(setNewInitialRuleDetails(mergeObjects))
	// 	}
	// }, [ruleDetails, state.ruleDetailsState, dispatch, masterData, state])	

	useEffect(() => {
		if(state.callStructureEditData) {
			setState(prevState => ({ ...prevState, callStructureEditData: false }))
			let policyBasicDetails = ruleDetails.policy_metadata ? ruleDetails.policy_metadata : {}
			let policyInformation = ruleDetails.policies && ruleDetails.policies.length ? ruleDetails.policies : []
			let policies = []
			let serviceArray = []
			policyInformation.forEach((item, index) => {
				serviceArray.push(index)
				item.categories.forEach(cat => {
					cat.policies.forEach(catPol => {
						catPol.resource_type = item.resource_type
						policies.push(catPol)
					})
				})
				setState(prevState => ({ ...prevState, 
					['resource_type_' + index]: item.resource_type,
					serviceArray,
					selectedProvider: policyBasicDetails && policyBasicDetails.provider ? policyBasicDetails.provider : 'aws',
				}))

				// masterData('resource_type_' + index, state['resource_type_' + index], index)
			})

			let groupedPolicies = { policies: policies }
			let mergeObjects = { ...groupedPolicies, ...policyBasicDetails }
			dispatch(setNewInitialRuleDetails(mergeObjects))
		}
	}, [state.callStructureEditData, dispatch, masterData, ruleDetails])

	useEffect(() => {
		if(state.callSetPolicyBasicDetails) {
			setState(prevState => ({ ...prevState, callSetPolicyBasicDetails: false }))
			let policyBasicDetails = state.policyBasicDetails
			let notifications = policyBasicDetails.notifications ? policyBasicDetails.notifications : []
			let notificationArray = [0]
			if (notifications.length) {
				notificationArray = []
				notifications.forEach((row, index) => {
					notificationArray.push(index)
					setState(prevState => ({ ...prevState, ['selected_notfication_type_' + index]: row.notfication_type, ['shared_details_' + index]: row.notification_id ? row.notification_id : [] }))
				})
			}

			setState(prevState => ({ ...prevState, notificationArray, notifications }))
		}
	}, [state.callSetPolicyBasicDetails, state.policyBasicDetails])

	useEffect(() => {
		if(state.callGetGovernanceServices) {
			setState(prevState => ({ ...prevState, callGetGovernanceServices: false }))
			let params = {}
			params.governance_type = state.governance_type

			if (params.governance_type) {
				dispatch(getGovernanceServices(params))
					.then((response) => {
						if (response) {
							setState(prevState => ({ ...prevState, services: response }))
						} else {
							setState(prevState => ({ ...prevState, services: [] }))
						}
					})
			}
		}
	}, [state.callGetGovernanceServices, state.governance_type, dispatch])

	const onClickConfigureSection = () => {
		if (state.governance_type) {
			setState(prevState => ({ ...prevState, showPolicySection: !state.showPolicySection }))
		}
	}

	useEffect(() => {
		if(state.callGlobalMastartData) {
			setState(prevState => ({ ...prevState, callGlobalMastartData: false }))
			let field = state.governance_type
			let value = state.governance_type
			let index = 0
			let params = {}
			
			if (state.governance_type !== 'opa' && state.governance_type !== 'opa_kubernetes') {
				params.provider = state.selectedProvider ? state.selectedProvider.toLowerCase() : ''
			}
			params.governance_type = state.governance_type

			// let response = KubernetesData
			// setState(prevState => ({ ...prevState, ['masterData_' + value]: response, serviceArray: [value] }))
			// onClickServicesBlock(field, value, index)
			// if (response && response.length) {
			// 	let obj = service_master_details ? service_master_details : {}
			// 	obj[value] = response

			// 	dispatch(setGovernancePropsDetails('service_master_details', obj))
			// }

			dispatch(getMasterGovernanceRules(params))
				.then((response) => {
					if (response) {
						setState(prevState => ({ ...prevState, ['masterData_' + value]: response, serviceArray: [value] }))
						onClickServicesBlock(field, value, index)
						if (response && response.length) {
							let obj = service_master_details ? service_master_details : {}
							obj[value] = response
		
							dispatch(setGovernancePropsDetails('service_master_details', obj))
						}
					} else {
						setState(prevState => ({ ...prevState, masterData: [] }))
						onClickServicesBlock(field, value, index)
					}
				})
		}

	}, [state.callGlobalMastartData, dispatch, onClickServicesBlock, service_master_details, state.governance_type, state.selectedProvider])

	const closeOtherResource = (field, value, index) => {
		state.services &&
			state.services.forEach(row => {
				if (row !== value) {
					setState(prevState => ({ ...prevState, ['show_resource_type_' + row]: false }))
				}
			})
	}

	const addSection = array => {
		let rowList = state[array]
		if (state[array]) {
			let value = state[array][state[array].length - 1]
			value = value + 1
			rowList.push(value)
		}

		setState(prevState => ({ ...prevState, [array]: rowList }))
	}

	const removeSection = (array, serIndex, ser, resourse_type) => {
        let rowList = state[array];
        rowList.splice(serIndex, 1);
        setState(prevState => ({ ...prevState, [array]: rowList, ['resource_type_'+ser]: "" }))

		let data = editNewRuleDetails
		let policies = data.policies.filter(e => e.resource_type !== resourse_type)
		dispatch(setNewEditRuleDetails('policies', policies));
    }

	// const addNewSharedDetails = item => {
	// 	let sharedDetails = state['shared_details_' + item] ? state['shared_details_' + item] : []
	// 	if (state['shared_to_' + item] && state['shared_to_' + item] !== '') {
	// 		sharedDetails.push(state['shared_to_' + item])
	// 	}
		
	// 	let notifications = []
	// 	state.notificationArray.forEach(item => {
	// 		if (state['selected_notfication_type_' + item] && sharedDetails.length ) {
	// 			let notficationRow = {}
	// 			notficationRow.notfication_type = state['selected_notfication_type_' + item]
	// 			notficationRow.notification_id = sharedDetails
	// 			notifications.push(notficationRow)
	// 		}
	// 	})
	// 	handleInputChange('notifications', notifications)

	// 	setState(prevState => ({ ...prevState, ['shared_details_' + item]: sharedDetails, ['shared_to_' + item]: '' }))
	// }

	// const removeSharedDetails = (item, sharedTo) => {
	// 	let filteredResult = state['shared_details_' + item].filter(e => e !== sharedTo)
	// 	let notifications = []
	// 	state.notificationArray.forEach(item => {
	// 		if (state['selected_notfication_type_' + item] && state['shared_details_' + item] && state['shared_details_' + item].length ) {
	// 			let notficationRow = {}
	// 			notficationRow.notfication_type = state['selected_notfication_type_' + item]
	// 			notficationRow.notification_id = state['shared_details_' + item]
	// 			notifications.push(notficationRow)
	// 		}
	// 	})
	// 	handleInputChange('notifications', notifications)

	// 	setState(prevState => ({ ...prevState, ['shared_details_' + item]: filteredResult }))
	// }

	// const showPolicy = (array, showField, index) => {
	// 	state[array].forEach(item => {
	// 		if (index === item) {
	// 			setState(prevState => ({ ...prevState, [showField + '_' + item]: !state[showField + '_' + item] }))
	// 		} else {
	// 			setState(prevState => ({ ...prevState, [showField + '_' + item]: false }))
	// 		}
	// 	})
	// }

	// const addPolicies = (array, showField, index) => {
	// 	let rowList = state[array]
	// 	if (state[array]) {
	// 		let value = state[array][state[array].length - 1]
	// 		value = value + 1
	// 		rowList.push(value)
	// 	}
	// 	rowList.forEach(item => {
	// 		if (index === item) {
	// 			setState(prevState => ({ ...prevState, [showField + '_' + item]: true }))
	// 		} else {
	// 			setState(prevState => ({ ...prevState, [showField + '_' + item]: false }))
	// 		}
	// 	})

	// 	setState(prevState => ({ ...prevState, [array]: rowList }))
	// }

	const setTimeoutRedirect = useCallback((ruleId) => {
		setTimeout(() => { navigate(URL_PATH.GOVERNANCE_MANAGE_RULES, {state: { showRules: true, queryLoading: false } }) }, 2000)
	}, [navigate])

	const createPolicy = useCallback(() => {
		let params = editNewRuleDetails

		if (editNewRuleDetails.governance_type === 'opa_kubernetes') {
			const { provider, ...rest } = editNewRuleDetails; 
			delete rest.provider; 
			params = rest
		}
		
		dispatch(createGovernancePolicy(params))
			.then((response) => {
				if (response) {
					let messageType = 'danger'
					let message = response.message ? response.message : 'Error in saving User governance policy'
					if(response.status) {
						messageType = 'success'
						message = response.message ? response.message : 'User governance policy saved successfully'
					}
					CommonNotification.addNotification({
						//title: "Wonderful!",
						message: message,
						type: messageType,
						insert: 'top',
						container: 'top-center',
						// animationIn: ["animate__animated", "animate__fadeIn"],
						// animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						},
					})
					if(response.status) {						
						setState(prevState => ({ ...prevState, showLoading: false, finishBtn: 'Saved' }))
						dispatch(cleareNewEditRuleDetails());
						setTimeoutRedirect()
					} else {
						setState(prevState => ({ ...prevState, queryLoading: false, showLoading: false, finishBtn: 'Save' }))
					}
				} else {
					setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))
				}
			})
	}, [dispatch, editNewRuleDetails, setTimeoutRedirect])

	const updatePolicy = useCallback(() => {
		let params = editNewRuleDetails
		dispatch(editGovernancePolicy(params))
			.then((response) => {
				if (response) {
					let messageType = 'success'
					let message = 'User governance policy updated successfully'
					CommonNotification.addNotification({
						//title: "Wonderful!",
						message: message,
						type: messageType,
						insert: 'top',
						container: 'top-center',
						// animationIn: ["animate__animated", "animate__fadeIn"],
						// animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						},
					})
					setState(prevState => ({ ...prevState, queryLoading: false, finishBtn: 'Saved' }))
					setTimeoutRedirect()
				} else {
					setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save' }))
				}
			})
	}, [dispatch, editNewRuleDetails, setTimeoutRedirect])

	const validatePolicyInput = (category, boolean, polIndex) => {
		setState(prevState => ({ ...prevState, [category]: boolean }))
		if(boolean) {
			setState(prevState => ({ ...prevState, hasPolicyError: true }))
		}
		// if(!boolean) {
		// 	validateInput(boolean)
		// }
	}

	const validateInput = useCallback((hasError) => {
		if (!hasError) {
			let hasPolicyError = false
			let policies = editNewRuleDetails && editNewRuleDetails.policies ? editNewRuleDetails.policies : []
			policies.forEach(pol => {
				let totalVariables = pol.variables && pol.variables.length
				let inputMissing = 0
				if (pol.variables && pol.variables.length) {
					pol.variables.forEach(varb => {
						if (!hasPolicyError) {
							if (varb.mandatory && Object.keys(varb).includes('value') && !varb.value) {
								inputMissing++
								hasPolicyError = true
							} else if (varb.mandatory && Object.keys(varb).includes('value_list') && (!varb.value_list || !varb.value_list.length)) {
								inputMissing++
								hasPolicyError = true
							}
						}
					})
				}
				if(totalVariables+1 === inputMissing) {
					// policies.filter()
				}
			})
			
			if(!hasPolicyError) {
				setState(prevState => ({ ...prevState, queryLoading: true }))
				if (state.ruleDetails && Object.entries(state.ruleDetails).length) {
					updatePolicy()
				} else {
					createPolicy()
				}
			}
		}
	}, [createPolicy, editNewRuleDetails, state.ruleDetails, updatePolicy])

	const onFinish = () => {
		let hasError = false
		let data = editNewRuleDetails
		if (!data || !data.policy_name || !data.governance_type || !data.provider) {
			hasError = true
		}

		if (data.applies_to && data.applies_to.length) {
			let applyToDataExist = false
			if (data.applies_to) {
				data.applies_to.forEach(item => {
					if(editNewRuleDetails.governance_type !== 'opa_kubernetes') {
						// && editNewRuleDetails.governance_type !== 'hpc-slurm'
						if(editNewRuleDetails.governance_type === 'hpc-slurm' && item.account_id && item.cluster_name && item.cluster_name.length) {
							applyToDataExist = true	
						} else if (editNewRuleDetails.governance_type !== 'hpc-slurm' && item.account_id) {
							applyToDataExist = true	
						}
					} else if(item.cluster_name && item.cluster_id) {
						applyToDataExist = true
					}
				})
			}
			
			if (editNewRuleDetails && !applyToDataExist) {
				dispatch(setGovernancePropsDetails('appliesToError', true))
				hasError = true
			} else {
				dispatch(setGovernancePropsDetails('appliesToError', false))
			}
		} else {
			dispatch(setGovernancePropsDetails('appliesToError', true))
			hasError = true
		}
		
		let policiesError = false
		if(editNewRuleDetails && (!editNewRuleDetails.policies || !editNewRuleDetails.policies.length)) {
			policiesError = true
			hasError = true
		}

		let notifications = []
		if (state.notificationArray) {
			// let notficationDataExist = false
			state.notificationArray.forEach(item => {
				if (state['selected_notfication_type_' + item] && state['shared_details_' + item] && state['shared_details_' + item].length ) {
					// notficationDataExist = true

					let notficationRow = {}
					notficationRow.notfication_type = state['selected_notfication_type_' + item]
					notficationRow.notification_id = state['shared_details_' + item]
					notifications.push(notficationRow)
				}
			})

			// if (!notficationDataExist) {
			// 	hasError = true
			// }
		}		

		dispatch(setGovernancePropsDetails('onClickFinish', true))

		setState(prevState => ({ ...prevState, hasError, policiesError, onClickFinish: true, saveLoading: hasError ? false : true }))
		if(!hasError) {
			validateInput(hasError)
		}

	}

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
			<div className={`${state.showLoading ? '' : 'hidden'}`}>
				<div className='loading-wave'>
					<div className='overlayEqualizerLoader'>
						<div className="loading-bar"></div>
						<div className="loading-bar"></div>
						<div className="loading-bar"></div>
						<div className="loading-bar"></div>
					</div>
				</div>
			</div>
			{/* {state.showDetailsPanel ? (
				<ViewSidePanel
					masterData={state.masterData}
					closeSidePanel={() => setState(prevState => ({ ...prevState, showDetailsPanel: false }))}
				/>
			) : null} */}
			<div className="bg-white w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-8 col-span-12 self-center xl:mt-4">
                        <p className="text-black mx-4 text-xl md:mt-0 mt-4 font-semibold">Policies</p>
						<p className="mx-4 text-black">Governance policies on your Cloud</p>
					</div>
					{/* {state.ruleDetails && Object.entries(state.ruleDetails).length ? (
						<p
							className='text-info self-center cursor-pointer'
							onClick={() => setState(prevState => ({ ...prevState, showDetailsPanel: true }))}
						>
							Policy Details
						</p>
					) : null} */}
				</div>
			</div>

			<div className='m-2'>
				<div className='rounded-md bg-white p-3'>
					<div className='flex justify-between'>
						<p className='mb-2 text-black text-lg cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, showRuleInfoSection: !state.showRuleInfoSection }))}
						>Policy Information</p>
						<Icon icon={`fa:angle-${state.showRuleInfoSection ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, showRuleInfoSection: !state.showRuleInfoSection }))} />
					</div>
					{state.showRuleInfoSection ?
						<React.Fragment>
						<div className={`flex flex-wrap`}>
							<div className='lg:w-2/4 w-full md:pl-3 pl-0'>
								<div className='w-full'>
									<div className={`flex flex-wrap`}>
										<div className='md:w-1/3 w-full'>
											<div className='flex justify-between'>
												<p className='self-center m-0'>Policy Type </p>
												{state.hasError &&
												(editNewRuleDetails && !editNewRuleDetails.governance_type) ? (
													<small className='text-issues'>required</small>
												) : null}
											</div>
											<div onClick={(event) => {
													if(!state.policyType) {
														event.preventDefault();
														handleChildClick(event, 'child', 'singleDropDown', "policyType")
													}
												}}
											>
												<p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate cursor-pointer ${state.governance_type ? 'text-black' : 'text-lightGray'}`}>
													{state.governance_type ? (state.governanceTypes && state.governanceTypes.filter(e => e.value === state.governance_type)[0].label) : 'Select'}
													<Icon icon="icon-park-solid:down-one" className={`${state.governance_type ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
												</p>
												{state.policyType && !state.ruleDetails ?
													<MultiSelectSection
														fields={["value", "label"]}
														options={state.governanceTypes}
														selectedValues={state.governance_type ? state.governance_type : ''}
														callbackMultiSelect={(value) => {
															if(!value || typeof(value) === 'string') {
																let callGlobalMastartData = false
																let callGetGovernanceServices = false	
																if (value === 'global' || value === 'opa' || value === 'opa_kubernetes' || value === 'hpc-slurm') {
																	// getGloableMasterData(value)
																	callGlobalMastartData = true
																} else if(value) {
																	callGetGovernanceServices = true
																}
																if(state.governance_type) {
																	setState(prevState => ({ ...prevState, ['masterData_' + state.governance_type]: [] }))
																}
																if(state.serviceArray) {
																	state.serviceArray.forEach(ser => {
																		setState(prevState => ({ ...prevState, ['resource_type_' + ser] : ''}))
																	})
																}
																setState(prevState => ({ ...prevState, governance_type: value, callGlobalMastartData, callGetGovernanceServices, services: [] }))
																handleInputChange('governance_type', value)
																handleInputChange('applies_to', [])
																dispatch(setGovernancePropsDetails('service_master_details', {}))	
															} else {
																value.preventDefault()
																handleChildClick(value, "search", 'singleDropDown', "")
															}
														}}
														singleSelection={true}
														hideSearch={false}
														topClass={'auto'}
														widthClass={'minWidth220'}
														removeTopOptions={true}
													/>
												: null}
											</div>
										</div>
										<div className='md:w-2/3 w-full md:pl-3 pl-0 md:mt-0 mt-3'>
											<div className='flex justify-between'>
												<p className='self-center m-0'>Name of the policy</p>
												{state.hasError &&
												(editNewRuleDetails && !editNewRuleDetails.policy_name) ? (
													<small className='text-issues'>required</small>
												) : null}
											</div>
											{!state.ruleDetails ?
												<input
													type='text'
													placeholder='Enter Policy Name'
													className='border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full'
													maxLength='128'
													value={editNewRuleDetails && editNewRuleDetails.policy_name ? editNewRuleDetails.policy_name : '' }
													onChange={e => handleInputChange('policy_name', e.target.value)}
												/>
											:
												<p className='mb-0 text-black px-2 py-2 border-lightGray rounded-5'>{editNewRuleDetails && editNewRuleDetails.policy_name ? editNewRuleDetails.policy_name : '' }</p>
											}
										</div>
									</div>
								</div>
								{editNewRuleDetails && editNewRuleDetails.governance_type !== 'opa' && editNewRuleDetails.governance_type !== 'opa_kubernetes' ? (
									<div className={`lg:w-1/4 md:w-1/3 w-full mt-3`}>
										<div className='flex justify-between'>
											<p className='self-center m-0'>Provider </p>
											{state.hasError &&
											((state.editNewRuleDetails && !state.editNewRuleDetails.provider) ||
												(state.editNewRuleDetails &&
													editNewRuleDetails && editNewRuleDetails.provider === '')) ? (
												<small className='text-issues'>required</small>
											) : null}
										</div>
										<div onClick={(event) => {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
											}}
										>
											<p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate cursor-pointer ${editNewRuleDetails &&editNewRuleDetails.provider ? 'text-black' : 'text-lightGray'}`}>
												{editNewRuleDetails && editNewRuleDetails.provider ? capitalizeAllLetter(editNewRuleDetails.provider) : 'Select'}
												<Icon icon="icon-park-solid:down-one" className={`${editNewRuleDetails && editNewRuleDetails.provider  ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
											</p>
											{state.isProviderOpen && !state.ruleDetails ?
												<MultiSelectSection
													fields={["provider_name", "provider_name"]}
													options={state.providers}
													selectedValues={state.selectedProvider ? state.selectedProvider : ''}
													callbackMultiSelect={(value) => {
														setState(prevState => ({ ...prevState, selectedProvider: value }))
														if (editNewRuleDetails && editNewRuleDetails.provider !== value) {
															handleInputChange('provider', value.toLowerCase())
														}
													}}
													singleSelection={true}
													hideSearch={false}
													topClass={'auto'}
													widthClass={'minWidth220'}
													removeTopOptions={true}
												/>
											: null}
										</div>
									</div>
								) : null}
							</div>
							<div className='lg:w-2/4 w-full md:pl-3 pl-0 md:mt-0 mt-3'>
								<p className='self-center m-0'>Description</p>
								<textarea 
                                    id="description" 
                                    name="description" 
                                    rows="4" 
                                    readOnly={state.pageType === 'View' ? true : false}
                                    className={`w-full p-2 mb-4 bg-transparent border border-lightGray rounded-md text-black`}
                                    onChange={e => {
                                        if(state.pageType !== 'View') {
                                            setState(prevState => ({ ...prevState, description: e.target.value }))
                                            handleInputChange("description", e.target.value)
                                        }
                                    }}
                                    value={state.description ? state.description : ""}
                                    placeholder='Enter Policy Description'
									maxLength='256'
                                />
							</div>
						</div>
						</React.Fragment>
					: null}
				</div>
				{state.governance_type ?
					<React.Fragment>
					{editNewRuleDetails && editNewRuleDetails.governance_type !== 'opa' ?
						<AppliesToSection />
					: null}

					<div className='rounded-md bg-white p-3 mt-3 mb-5'>
						{state.listLoading ? 
							<div className='flex justify-center m-4'>
								<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
									<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
									<path d="M4 12a8 8 0 0112-6.9" />
								</svg>
							</div>
						:
							<React.Fragment>
							<div className={`flex pb-2 justify-between`}>
								<div className='flex mr-2 w-1/2'>
									<p className='mb-0 text-purple3 cursor-pointer flex text-lg font-semibold' onClick={() => onClickConfigureSection()} > 
										Step {editNewRuleDetails && editNewRuleDetails.governance_type === 'opa' ? 1 : 2} : Configure Policy Rule
									</p>
								</div>
								<div className='flex justify-end'>
									{state.policiesError ?
										<p className='mr-4 mb-0 self-center text-danger'>Please choose any one of the policy</p>
									: null}
									<Icon icon={`fa:angle-${state.showPolicySection ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer self-center' onClick={() => onClickConfigureSection()} />
								</div>
							</div>

							{state.showPolicySection ?
								<div className='px-3'>
									{state.serviceArray && state.serviceArray.map((ser, serIndex) => {
										return(
											<React.Fragment key={'serva_'+serIndex}>
											<div key={'service_'+ser} className={`rounded-md bg-BlockWhite p-3 mb-2`}>
												{state.governance_type !== 'global' && state.governance_type !== 'opa' && state.governance_type !== 'opa_kubernetes' && state.governance_type !== 'hpc-slurm' ?
													<div className='flex justify-between'>
														<div className='flex w-full mb-2'>
															<React.Fragment>
															<p className='self-end mb-0 mr-3'>Select a service</p>
															<div className='min-w-40'
																onClick={(event) => {
																	if(!state["show_applies_resource_type_"+ser]) {
																		event.preventDefault();
																		handleChildClick(event, 'child', 'singleDropDown', "show_applies_resource_type_"+ser)
																	}
																}}
															>
																<p className={`flex bg-transparent border-b  border-lightGray px-2 py-1 justify-between mb-0 truncate cursor-pointer ${state['resource_type_' + ser] ? 'text-info' : 'text-lightGray'}`}>
																	{state['resource_type_' + ser] ? state['resource_type_' + ser] : 'Select value'}
																	<Icon icon="icon-park-solid:down-one" className={`${state['resource_type_' + ser] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
																</p>
																{state['show_applies_resource_type_'+ser] ?
																	<MultiSelectSection
																		// fields={["account_id", "account_name"]}
																		options={state.services ? state.services : []}
																		selectedValues={state['resource_type_' + ser] ? state['resource_type_' + ser] : ''}
																		callbackMultiSelect={(value) => {
																			if(!value || typeof(value) === 'string') {
																				setState(prevState => ({ ...prevState, ['resource_type_' + ser]: value, ['showServiceLoading_'+ ser]: true }))
																				masterData('resource_type_' + ser, value, ser)
																			} else {
																				value.preventDefault()
																				handleChildClick(value, "search", 'singleDropDown', "")
																			}
																		}}
																		singleSelection={true}
																		widthClass={'minWidth220'}
																		removeTopOptions={true}
																	/>
																: null}
															</div>
															</React.Fragment>
														</div>
														
														<Icon icon={`fa:angle-${state['show_resource_type_' + state['resource_type_' + ser]] ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer' 
															onClick={() => {
																setState(prevState => ({ ...prevState, ['show_resource_type_' + state['resource_type_' + ser]]: !state['show_resource_type_' + state['resource_type_' + ser]] }))
																closeOtherResource( 'resource_type_' + ser, state['resource_type_' + ser], ser)
															}}
														/>
													</div>
												:
													<p className='mb-0 text-lg text-lightGray'>{state.governanceTypes.filter(e => e.value === state.governance_type).length ? state.governanceTypes.filter(e => e.value === state.governance_type)[0].label : state.governance_type}</p>
												}

												{state['showServiceLoading_'+serIndex] ?
													<div className='flex justify-center m-4 bg-white rounded-md p-2'>
														<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
															<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
															<path d="M4 12a8 8 0 0112-6.9" />
														</svg>
													</div>
												: (state['show_resource_type_' + state['resource_type_' + ser]] || state['show_resource_type_global'] || state['show_resource_type_opa_kubernetes'] || state['show_resource_type_hpc-slurm'] || state['show_resource_type_opa']) ?
													state['masterData_' + state['resource_type_' + ser]] && state['masterData_' + state['resource_type_' + ser]].length ?
														state['masterData_' + state['resource_type_' + ser]].map(
															(row, rowIndex) => {
																return(
																	<React.Fragment key={'rs_'+rowIndex}>
																	{row.policies && row.policies.length ? 
																		<div key={'pol_'+rowIndex} className={`bg-GhostWhite rounded-md p-2 overflow-unset`}>
																			<div className='flex justify-between text-black border-b border-mediumDarkGray'>
																				<div className='flex'>
																					<p className='mb-0 text-lg text-warning cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, ['show_' + row.rule_category +'_'+ ser +'_'+ rowIndex + '_policies']: !state['show_' + row.rule_category +'_'+ ser +'_'+ rowIndex + '_policies'] }))}>
																						{row.rule_category} Policy Rules
																					</p>
																					<span className='self-center ml-3 text-danger'> {state[row.rule_category] ? 'Please fill all the required fields' : ''} </span>
																				</div>
																				<div className='flex'>
																					<span className='text-black mr-3'> {row.policies.length} policy rule(s) </span>

																					<Icon icon={`fa:angle-${state['show_' + row.rule_category +'_'+ ser +'_'+ rowIndex + '_policies'] ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, ['show_' + row.rule_category +'_'+ ser +'_'+ rowIndex + '_policies']: !state['show_' + row.rule_category +'_'+ ser +'_'+ rowIndex + '_policies'] })) } />
																				</div>
																			</div>
																			{state['show_' + row.rule_category +'_'+ ser +'_'+ rowIndex + '_policies'] ?
																				row.policies.map((pol, polIndex) => {
																					return(
																					<React.Fragment key={'pol_'+polIndex}>
																					{!pol.template ?
																						<DynamicServices
																							masterData={pol}
																							resource_type={state['resource_type_' + ser]}
																							rule_category={row.rule_category}
																							onClickFinish={state.onClickFinish}
																							validateInput={(boo, noInputDetected) => {
																								setState(prevState => ({ ...prevState, onClickFinish: false, [row.rule_category + '_validation_' + polIndex]: noInputDetected ? (boo ? 'failed' : 'success') : 'failed' }))
																								validatePolicyInput(row.rule_category, boo, polIndex)
																							}}
																							actionMethods={state.actionMethods}
																							governance_type={state.governance_type}
																						/>
																						: (pol.rule_id === "AWS_GOV_RDS_0001" || pol.rule_id === "AWS_GOV_AURORA_RDS_0001" || pol.rule_id === "AWS_GOV_EC_0001") ?
																							<RdsServiceEnginesTemplate 
																								masterData = {pol}
																								onClickFinish={state.onClickFinish}
																								validateInput={boo => {
																									setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																									validatePolicyInput(pol.rule_category, boo, polIndex)
																								}}
																								selectedModeType={state.selectedModeType}
																								actionMethods={state.actionMethods}
																							/>
																							: (pol.rule_id === "AWS_GOV_RDS_0002" || pol.rule_id === "AWS_GOV_AURORA_RDS_0002") ?
																								<RdsServiceLogs 
																									masterData = {pol}
																									onClickFinish={state.onClickFinish}
																									validateInput={boo => {
																										setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																										validatePolicyInput(pol.rule_category, boo, polIndex)
																									}}
																									selectedModeType={state.selectedModeType}
																									actionMethods={state.actionMethods}
																								/>
																							: (pol.rule_id === "AWS_GOV_RDS_0005" || pol.rule_id === "AWS_GOV_AURORA_RDS_0005" || pol.rule_id === "AWS_GOV_EC2_0008" || pol.rule_id === "AWS_GOV_EC_0004" || pol.rule_id === "HPC_GOV_0029"|| pol.rule_id === "HPC_GOV_0032"|| pol.rule_id === "HPC_GOV_0035") ?
																								<RdsServiceCapacity 
																									masterData = {pol}
																									onClickFinish={state.onClickFinish}
																									validateInput={boo => {
																										setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																										validatePolicyInput(pol.rule_category, boo, polIndex)
																									}}
																									selectedModeType={state.selectedModeType}
																									actionMethods={state.actionMethods}
																								/>
																							: pol.rule_id === "AWS_GOV_RDS_0007" ?
																								<RdsServiceBlockStore 
																									masterData = {pol}
																									onClickFinish={state.onClickFinish}
																									validateInput={boo => {
																										setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																										validatePolicyInput(pol.rule_category, boo, polIndex)
																									}}
																									selectedModeType={state.selectedModeType}
																									actionMethods={state.actionMethods}
																								/>
																							: pol.rule_id === "AWS_GOV_ES_0006" ?
																								<ElasticServiceBlockStore 
																									masterData = {pol}
																									onClickFinish={state.onClickFinish}
																									validateInput={boo => {
																										setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																										validatePolicyInput(pol.rule_category, boo, polIndex)
																									}}
																									selectedModeType={state.selectedModeType}
																									actionMethods={state.actionMethods}
																								/>
																							: pol.rule_id === "AWS_GOV_EC2_0012" ?
																								<Ec2ServiceBlockStore 
																									masterData = {pol}
																									onClickFinish={state.onClickFinish}
																									validateInput={boo => {
																										setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																										validatePolicyInput(pol.rule_category, boo, polIndex)
																									}}
																									selectedModeType={state.selectedModeType}
																									actionMethods={state.actionMethods}
																								/>
																							: (pol.rule_id === "AWS_GOV_ES_0003" || pol.rule_id === "AWS_GOV_OS_0003") ?
																								<DedicatedMasterServiceCapacity
																									masterData = {pol}
																									onClickFinish={state.onClickFinish}
																									validateInput={boo => {
																										setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																										validatePolicyInput(pol.rule_category, boo, polIndex)
																									}}
																									selectedModeType={state.selectedModeType}
																									actionMethods={state.actionMethods}
																								/>
																							: (pol.rule_id === "AWS_GOV_ES_0004" || pol.rule_id === "AWS_GOV_OS_0004") ?
																								<DataNodeServiceCapacity 
																									masterData = {pol}
																									onClickFinish={state.onClickFinish}
																									validateInput={boo => {
																										setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																										validatePolicyInput(pol.rule_category, boo, polIndex)
																									}}
																									selectedModeType={state.selectedModeType}
																									actionMethods={state.actionMethods}
																								/>
																							: pol.rule_id === "AWS_GOV_SNS_0002" ?
																								<SnsServiceLogs 
																									masterData = {pol}
																									onClickFinish={state.onClickFinish}
																									validateInput={boo => {
																										setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																										validatePolicyInput(pol.rule_category, boo, polIndex)
																									}}
																									selectedModeType={state.selectedModeType}
																									actionMethods={state.actionMethods}
																								/>
																							: null}
																					</React.Fragment>
																					)
																				})
																			: null}
																		</div>
																	: null}
																	</React.Fragment>
																)
															}
														)
													: null
												: null}
											</div>
											{state.governance_type !== 'global' && state.governance_type !== 'opa' && state.governance_type !== 'opa_kubernetes' && state.governance_type !== 'hpc-slurm' ? 
												<React.Fragment>
												{state.serviceArray.length === serIndex+1 ?
													<span className='text-info mt-3 mb-5 cursor-pointer' onClick={() => addSection('serviceArray')} >
														+ Add New Service
													</span>
												: null}
												{state.serviceArray && state.serviceArray.length > 1 ?
													<span className='text-danger mt-3 mb-5 ml-3 cursor-pointer' onClick={() => removeSection('serviceArray', serIndex, ser, state['resource_type_' + ser])} >
														Remove
													</span>
												: null}
												</React.Fragment>
											: null}
											</React.Fragment>
										)
									})}
								</div>
							: null}
							</React.Fragment>
						}
					</div>
					
					<div className='fixed bottom-4 right-4 justify-end bg-transparent'>
                        <div className={`flex justify-end bg-white py-2`}>
                            <div className='self-center'>
								{state.hasError ?
									<span className='mr-3 text-danger self-center'>Please fill the maditory fields</span>
								: null}
                                {state.saveLoading ?
                                    <button className={`btn bg-info text-lg px-2 py-1 rounded-md mr-2`}>
                                        <svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                            <path d="M4 12a8 8 0 0112-6.9" />
                                        </svg>
                                        {state.pageType === 'edit' ? 'Updating' : 'Saving'}
                                    </button>
                                :
                                    <div className='flex'>
                                        <button className={`btn bg-info text-lg px-2 py-1 rounded-md mr-2 text-white`} 
											onClick={ () => {
												setState(prevState => ({ ...prevState, saveLoading: true }))
												onFinish()
											}}
										>Submit</button>
										
										<Link className='btn bg-lightGray px-2 py-1 text-lg rounded-md text-white text-decoration-none ml-1' to={URL_PATH.GOVERNANCE_MANAGE_RULES} >
											Cancel
										</Link>
                                        {/* <button className={`btn bg-lightGray px-2 py-1 text-lg rounded-md text-black`} onClick={ () => closeSidePanel()}>Cancel</button> */}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

					{/* <div className='fixed-buttons justify-end bg-transparent border-0 pb-0'>
						<div className={`flex justify-end bg-muted py-2 pb-0 mb-0 w-100`}>
							{state.hasError ?
								<span className='mr-3 text-danger self-center'>Please fill the maditory fields</span>
							: null}
							<div className='self-center'>
								<button className='btn btn-primary' onClick={() => onFinish()}>
									{state.ruleDetails ? 'Update' : 'Save'}{' '}
								</button>
								<Link className='float-right btn btn-light text-muted text-decoration-none ml-1' to={URL_PATH.GOVERNANCE_MANAGE_RULES} >
									Cancel
								</Link>
							</div>
						</div>
					</div> */}
					</React.Fragment>
				: null}
			</div>
		</div>
	)
}

export default PolicyDetails
