/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * @exports
 * @file ClusterDetails.js
 * @author Prakash
 *************************************************/
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listClusterDetails } from '../../../actions/Collider/ClusterAction'
import ApexLineChartSpendBoard from '../../common/charts/ApexLineChartSpendBoard';
import DetailsSidePanel from './DetailsSidePanel';
import { getCommonInformationMessage, getPaginationShowingDetails, momentConvertionUtcToLocalTime, momentDateGivenFormat, removeUnderScore } from '../../../utils/utility';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import {Icon} from "@iconify/react";
import { PERIOD_OPTIONS_NEW } from '../../../utils/constants';
import PageLoading from '../../common/PageLoading';
import { LoadingCircle } from '../../common/LoadingCiricle';
import SelectOption from '../../common/Form/SelectOption';

const ClusterDetails = (props) => {
    const [isNavigating, setIsNavigating] = useState(false); // To prevent double-clicks
    const [state, setState] = useState({
        showLoading: true,

        currentPage: 1,
        perPage: 5,

        clusterDetails: [],
        selectedClusterDetails: [],
        clusterheaders: [],
        callSearch: true,
        graphOrderColors: [['#854097'],['#693EBC'],['#775BA2'],['#9A77D1']]
        // order: ['jobs_executed', 'cumulative_CPU_cores', 'compute_cost', 'concurrent_CPU_cores']
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    const expandSection = useCallback((clusterDetails, type, index) => {
        clusterDetails.forEach((clus, iIndex) => {
            setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+iIndex]: (iIndex === index ? (type === 'expand' ? true : false) : false) }))
        })
    }, [])
  
    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, showLoading: true }))
            let params = {}
            params.provider = props.selectedProvider.toLowerCase()
            params.reports = true
            params.start_time = props.startDate
            params.end_time = props.endDate
            if(props.selectedAccount && props.selectedAccount.length) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length) {
                params.region = props.selectedRegion
            }
            if(props.selectedClusters && props.selectedClusters.length) {
                params.cluster_name = props.selectedClusters
            }

            if(state.token === 'next') {
                params.next_token = state.listResponse && state.listResponse.next_token
            } else if(state.token === 'previous') {
                params.previous_token = state.listResponse && state.listResponse.previous_token
            } else if(state.token === 'current') {
                params.current_token = state.listResponse && state.listResponse.current_token
            }

            params.size = state.perPage

            dispatch(listClusterDetails(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results ? response.results : []
                        
                        setState(prevState => ({ ...prevState, listResponse: response, clusterDetails: results, detailsFormat: response && response.cluster_details ? response.cluster_details : [], showLoading: false,  callTrenSection: true, calltoGetTrendTableDetails: true, totalRecords: response.total }));

                        expandSection(results)

                        props.callBack()
                    }
                })
        }
    }, [state.callSearch, dispatch, state.perPage, props, state.token, state.listResponse, expandSection])

    useEffect(() => {
        if(state.callConcurrentCpuSection) {
            setState(prevState => ({ ...prevState, callConcurrentCpuSection: false }))

            state.clusterDetails.forEach((item, index) => {
                let graphDetails = item.concurrent_CPU_cores ? item.concurrent_CPU_cores : []
                let graphData = {}
                graphData.labels = graphDetails.dates ? graphDetails.dates : []
                graphData.concurrent_CPU_cores = graphDetails.data ? graphDetails.data : []
                    
                setState(prevState => ({ ...prevState, ["concurrent_CPU_cores_"+index]: graphData, ['selected_Period_'+index]: 'daily' }))
            })
        }
    }, [state.callConcurrentCpuSection, state.clusterDetails])

    useEffect(() => {
        if(state.callTrenSection) {
            
            setState(prevState => ({ ...prevState, callTrenSection: false }))

            state.clusterDetails.forEach((item, index) => {
                if(item.trend) {
                    let trendDetails = item.trend
                    Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([trKey, trValue]) => { 
                        let graphData = {}
                        if(trValue.dates) {
                            graphData.labels = trValue.dates ? trValue.dates : []    
                        } else {
                            graphData.labels = item.dates ? item.dates : []
                        }
                        graphData[trKey] = trValue.data
                        graphData.tooltipLabel = item.title[trKey]
                        setState(prevState => ({ ...prevState, [trKey+'_'+index]: graphData }))
                    })
                }
            })
        }
    }, [state.callTrenSection, state.clusterDetails])

    const onChangePeriod = (period, selectedPeriodIndex, item) => {
        setState(prevState => ({ 
            ...prevState, 
            // ["concurrent_CPU_cores_"+selectedPeriodIndex]: {},
            ["reports_"+selectedPeriodIndex]: [],
            ['filteredReports_'+selectedPeriodIndex]: []
        }))
    
        state.clusterDetails.forEach((item, index) => {
            if(item.trend && index === selectedPeriodIndex) {
                let trendDetails = item.trend
                Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([key, value]) => {
                    setState(prevState => ({ ...prevState, [key+'_'+index]: {} }))
                })
            }
        })

        let params = {}
        params.provider = props.selectedProvider.toLowerCase()
        params.start_time = props.startDate
        params.end_time = props.endDate
        params.reports = true
        if(props.selectedAccount && props.selectedAccount.length) {
            params.account_id = props.selectedAccount
        }
        if(props.selectedRegion && props.selectedRegion.length) {
            params.region = props.selectedRegion
        }
        if(period) {
            params.duration_aggregate_by = period
        }
        if(item.cluster_name) {
            params.cluster_name = item.cluster_name
        }

        dispatch(listClusterDetails(params))
            .then((response) => {
                if(response) {
                    let results = response && response.results && response.results.length ? response.results : []
                    setState(prevState => ({
                        ...prevState,
                        selectedClusterDetails: results,
                        // callSelectedConcurrentCpuSection: true,
                        callSelectedTrenSection: true,
                        calltoGetSelectedTrendTableDetails: true,
                        selectedPeriodIndex,
                        ['showLoading_'+selectedPeriodIndex]: false
                    }));
                }
        })
    }

    useEffect(() => {
        if(state.callSelectedConcurrentCpuSection) {
            setState(prevState => ({ ...prevState, callSelectedConcurrentCpuSection: false }))

            state.selectedClusterDetails.forEach((item, index) => {
                let graphDetails = item.concurrent_CPU_cores ? item.concurrent_CPU_cores : []
                let graphData = {}
                graphData.labels = graphDetails.dates ? graphDetails.dates : []
                graphData.concurrent_CPU_cores = graphDetails.data ? graphDetails.data : []
                    
                setState(prevState => ({ ...prevState, ["concurrent_CPU_cores_"+state.selectedPeriodIndex]: graphData }))
            })
        }
    }, [state.callSelectedConcurrentCpuSection, state.selectedClusterDetails, state.selectedPeriodIndex])

    useEffect(() => {
        if(state.callSelectedTrenSection) {            
            setState(prevState => ({ ...prevState, callTrenSection: false }))

            state.selectedClusterDetails.forEach(item => {
                if(item.trend) {
                    let trendDetails = item.trend
                    Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([trKey, trValue]) => { 
                        let graphData = {}
                        if(trValue.dates) {
                            graphData.labels = trValue.dates ? trValue.dates : []    
                        } else {
                            graphData.labels = item.dates ? item.dates : []
                        }
                        graphData[trKey] = trValue.data
                        graphData.tooltipLabel = item.title[trKey]
                        setState(prevState => ({ ...prevState, [trKey+'_'+state.selectedPeriodIndex]: graphData }))
                    })
                }
                // if(item.trend) {
                //     let trendDetails = item.trend
                //     trendDetails.forEach(tre => {
                //         Object.entries(tre).length && Object.entries(tre).forEach(([key, value]) => {
                //             let graphData = {}
                //             if(value.dates) {
                //                 graphData.labels = tre.dates ? tre.dates : []    
                //             } else {
                //                 graphData.labels = item.dates ? item.dates : []
                //             }
                //             graphData[key] = value
                //             setState(prevState => ({ ...prevState, [key+'_'+state.selectedPeriodIndex]: graphData }))
                //         })
                //     })
                    
                // }
            })
        }
    }, [state.callSelectedTrenSection, state.selectedClusterDetails, state.selectedPeriodIndex])

    useEffect(() => {
        if(state.calltoGetTrendTableDetails) {
            setState(prevState => ({ ...prevState, calltoGetTrendTableDetails: false, ['showLoading_'+state.selectedItemIndex]: false })) 
            
            if(state.clusterDetails && state.clusterDetails.length) {
                state.clusterDetails.forEach((item, index) => {
                    if(item.reports && item.reports.length) {
                        let columns = []
                        let dataRow = {}
                        dataRow.Header = 'Date'
                        dataRow.accessor = 'date'
                        dataRow.Cell = cellInfo => (
                            <React.Fragment>
                                <span>{cellInfo.row.original.date ? momentConvertionUtcToLocalTime(cellInfo.row.original.date, (item.monthly_breakup ? "MMM YY" : "DD MMM YY")) : ""}</span>
                            </React.Fragment>
                        )
                        dataRow.width = 180
                        columns.push(dataRow)
                        item.order && item.order.forEach(ord => {
                            dataRow = {}
                            dataRow.Header = removeUnderScore(ord)
                            dataRow.accessor = ord
                            dataRow.width = 220
                            columns.push(dataRow)
                        })

                        setState(prevState => ({ ...prevState, ["reports_"+index]: item.reports, ['filteredReports_'+index]: item.reports, ['report_columns_'+index]: columns }))
                    } else {
                        setState(prevState => ({ ...prevState, ["reports_"+index]: [], ['filteredReports_'+index]: [], ['report_columns_'+index]: [] }))
                    }
                })
                
            }
        }
    }, [state.calltoGetTrendTableDetails, state])

    useEffect(() => {
        if(state.calltoGetSelectedTrendTableDetails) {
            setState(prevState => ({ ...prevState, calltoGetSelectedTrendTableDetails: false }))
            
            if(state.selectedClusterDetails && state.selectedClusterDetails.length) {
                state.selectedClusterDetails.forEach((item, index) => {
                    let reports= []

                    let labels = item.dates ? item.dates : []
                    labels.forEach((lab, labIndex) =>{ 
                        let reportRow = {}
                        reportRow.timestamp = lab
                        Object.entries(item.trend).forEach(([key, value]) => {
                            reportRow[key] = value[labIndex] ? value[labIndex] : 0
                        })
                        reports.push(reportRow)
                    })

                    if(reports.length) {
                        const reportColumns = Object.entries(reports[0]).map(([tKey, tValue]) =>{
                            let header = ''
                            let width = '100%'
                            let textColor = ''
                            let className = 'text-truncate'
                            let accessor = ''
                            let Cell = ''
                            if(tKey === 'timestamp') {
                                header = 'Date'
                                width = 180
                                accessor = tKey
                                Cell = cellInfo => (<span>{cellInfo.row.original.timestamp ? momentDateGivenFormat(cellInfo.row.original.timestamp, 'DD MMM YYYY')  : ''}</span>)
                            } else {
                                header = removeUnderScore(tKey)
                                width = 200
                                className = 'text-truncate'
                                accessor = tKey
                                Cell = cellInfo => cellInfo.row.original[tKey]
                            }
                            return {
                                Header: header,
                                accessor: accessor,
                                width: width,
                                Cell: Cell,
                                textColor: textColor,
                                className: className
                            }
                        })
                        
                        setState(prevState => ({ ...prevState, ["reports_"+state.selectedPeriodIndex]: reports, ['filteredReports_'+state.selectedPeriodIndex]: reports, ['report_columns_'+state.selectedPeriodIndex]: reportColumns }))
                    } else {
                        const reportColumns = []	
                        setState(prevState => ({ ...prevState, ['reports_'+state.selectedPeriodIndex]: reports, ['filteredReports_'+state.selectedPeriodIndex]: reports, ['report_columns_'+state.selectedPeriodIndex]: reportColumns }))
                    }
                })
            }
        }
    }, [state.calltoGetSelectedTrendTableDetails, state])

    const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const downloadPDF = async (index, item) => {
        const chartContainer1 = document.getElementById('chartContainer_'+index+'_0');
        const chartContainer2 = document.getElementById('chartContainer_'+index+'_1');
        const chartContainer3 = document.getElementById('chartContainer_'+index+'_2');
        const chartContainer4 = document.getElementById('chartContainer_'+index+'_3');
        const chartContainer5 = document.getElementById('chartContainer_'+index+'_4');
        const chartContainer6 = document.getElementById('chartContainer_'+index+'_5');
        // const chartContainer2 = document.getElementById('chartContainer2');
    
        if (!chartContainer1) {
          console.error("Chart container not found.");
          return;
        }
    
        try {
            const canvas1 = await html2canvas(chartContainer1);
            const canvas2 = await html2canvas(chartContainer2);
            const canvas3 = await html2canvas(chartContainer3);
            const canvas4 = await html2canvas(chartContainer4);
            const canvas5 = await html2canvas(chartContainer5);
            const canvas6 = await html2canvas(chartContainer6);
            //   const canvas2 = await html2canvas(chartContainer2);
            const pdf = new jsPDF();
            // const pdfChartHeight = 200;
            // // Add the chart to the PDF
            // const imgData = canvas1.toDataURL('image/png');
            // const imgWidth = pdf.internal.pageSize.getWidth() - 20; // Set width to full page width
            // const imgHeight = (pdfChartHeight * imgWidth) / canvas1.width;

            // const imgData = canvas.toDataURL('image/png');
            // const pdfChartWidth = pdf.internal.pageSize.getWidth() - 20;
            // const pdfChartHeight = 100;
            // const pageHeight = pdf.internal.pageSize.getHeight() - 20;

            // const contentHeight = chartContainer1.offsetHeight;
            // const totalPages = Math.ceil(pageHeight / pdfChartHeight);

            // const marginBottom = 20;
            // pdf.setPage(pdf.internal.pages.length);
            // pdf.rect(0, pdf.internal.pageSize.getHeight() - marginBottom, pdf.internal.pageSize.getWidth(), marginBottom, 'F');

            // pdf.setPage(pdf.internal.pages.length);
            // pdf.rect(0, pdf.internal.pageSize.getHeight() - marginBottom, pdf.internal.pageSize.getWidth(), marginBottom, 'F');
            
            // let selectedPeriod = state.periodOptions.filter(e => e.value === state['selected_Period_' + index]).length ? state.periodOptions.filter(e => e.value === state['selected_Period_' + index])[0].label : state['selected_Period_' + index]

            pdf.text(`Report for the cluster ${item.cluster_name}`, 10, 10) 
            pdf.text(`spanning from ${momentDateGivenFormat(props.startDate, 'DD MMM YYYY')} to ${momentDateGivenFormat(props.endDate, 'DD MMM YYYY')}`, 10, 20)

            pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 10, 30, 100, 60);
            pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 110, 30, 100, 60);
            pdf.addImage(canvas3.toDataURL('image/png'), 'PNG', 10, 100, 100, 60);
            pdf.addImage(canvas4.toDataURL('image/png'), 'PNG', 110, 100, 100, 60);
            pdf.addImage(canvas5.toDataURL('image/png'), 'PNG', 10, 170, 100, 60);
            pdf.addImage(canvas6.toDataURL('image/png'), 'PNG', 110, 170, 100, 60);
            
            const timestamp = new Date().getTime();
            pdf.save(`cluster_${timestamp}.pdf`);

            setState(prevState => ({ ...prevState, downloading: false }))

        } catch (error) {
          console.error("Error capturing charts:", error);
          setState(prevState => ({ ...prevState, downloading: false }))
        }
    };

    const handleNavigation = (direction) => {
        if (isNavigating) return; // Prevent further clicks
    
        setIsNavigating(true); // Block further navigation
        const newPage = direction === 'previous' ? state.currentPage - 1 : state.currentPage + 1;
    
        // Update the state and simulate API call
        setState((prevState) => ({ ...prevState, showLoading: true, currentPage: newPage, token: direction, callSearch: true }));
    
        // Simulate async operation or API call
        setTimeout(() => { setIsNavigating(false); // Re-enable navigation
          setState((prevState) => ({ ...prevState, showLoading: false })); // Remove loading
        }, 1000); // Simulated delay
    };

    return (
        <div className='bg-transparent' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${(state.showLoading || state.downloading) ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            {state.showSidePanel ? 
                <DetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showSidePanel: false, selectedDetails: {}, selectedType: '' }))}                    
                    selectedProvider={props.selectedProvider}selectedAccount={props.selectedAccount}
                    selectedRegion={props.selectedRegion}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    selectedDetails={state.selectedDetails}
                    selectedType={state.selectedType}
                    detailsFormat={state.detailsFormat ? state.detailsFormat : []}
                />
            : null}
            <div className='overflow-auto'>
                <div className='flex flex-wrap justify-between mb-1'>
                    <p className='mb-0 self-center text-black lg:w-1/2 w-full'>
                        Showing 
                        <span className='mx-1'>{getPaginationShowingDetails(state.clusterDetails && state.clusterDetails.length, state.totalRecords, state.currentPage, state.perPage)}</span> 
                        out of total {state.totalRecords} cluster(s)
                    </p>
                    {state.totalRecords > state.perPage ?
                        <div className='flex justify-end text-black lg:w-1/2 w-full'>
                            <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                            <div className="flex text-sm">
                                <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.listResponse && state.listResponse.previous_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.listResponse && state.listResponse.previous_token) {
                                        handleNavigation('previous');
                                    }
                                }}/></span> 
                                <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.listResponse && state.listResponse.next_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.listResponse && state.listResponse.next_token) {
                                        handleNavigation('next');
                                    }
                                }}/></span>
                            </div>
                        </div>
                    : null}
                </div>
                {state.clusterDetails && state.clusterDetails.length ?
                    state.clusterDetails.map((item, index) => {
                        return(
                            <div className={`bg-white rounded-2xl mb-4`} key={"row_" + index}>
                                <div className='flex flex-wrap'>
                                    {!state['showExpandedSectoin_' + index] ?
                                        <React.Fragment>
                                        <div className="text-base w-full bg-PrimaryTintsPurple-600 rounded-l-md px-3 py-4 lg:w-1/4 md:w-1/2 flex flex-col justify-between">
                                            <div className="flex">
                                                <div className="py-1 w-full">
                                                    <p className="b-block mb-0 text-DeepPurpleTints-600">Cluster</p>
                                                    <p className="mb-0 text-black">
                                                    {item.cluster_name ? item.cluster_name : ''}
                                                    {item.cluster_version ?
                                                        <span className="mb-0 mr-2 text-lg text-black">
                                                            (v {item.cluster_version})
                                                        </span>
                                                    : null}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="py-1 w-full">
                                                    <p className="b-block mb-0 text-DeepPurpleTints-600">Asset</p>
                                                    <p className="mb-0 text-black">{item.asset_name ? item.asset_name : ''}</p>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="py-1 w-full">
                                                    <p className="b-block mb-0 text-DeepPurpleTints-600">Resources</p>
                                                    <p className="mb-0 text-black">
                                                    {item.account_id ? <span>{' ' + item.account_id}</span> : null}
                                                    {item.region ? <span>{' ' + item.region}</span> : null}
                                                    {item.resource_type ? <span>{' ' + item.resource_type}</span> : null}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {state['showLoading_'+index] ?
                                            <div className="w-full lg:w-3/4 flex justify-center self-center py-4">
                                                <LoadingCircle />
                                            </div>
                                        :
                                            <div className="w-full lg:w-3/4 flex flex-wrap p-3">
                                                <div className='flex w-full justify-between'>
                                                    <SelectOption
                                                        label={""}
                                                        fields={["value", "label"]}
                                                        options={PERIOD_OPTIONS_NEW}
                                                        selectedValues={state['selected_Period_'+index] ? state['selected_Period_'+index] : ''}
                                                        callbackMultiSelect={(value) => {
                                                            setState((prevState) => ({ ...prevState,
                                                                ['selected_Period_' + index]: value,
                                                                ['showLoading_'+index]: true
                                                            }));
                                                            onChangePeriod(value, index, item);
                                                        }}
                                                        singleSelection={true}
                                                        dropdownWidth={'min-w-32'}
                                                        classDetails={{border: "border border-primaryPurple-600", padding: "py-2.5 pr-4 pl-3", bg: 'bg-lavender-200', rounded: 'rounded-full' }}
                                                    />
                                                    <div className='flex'>
                                                        <p className='mb-0 text-white bg-DeepPurpleTints-600 pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: item, selectedType: 'Cluster', showSidePanel: true }))}>View Details</p>
                                                        <span className='flex justify-center'>
                                                            {!state['showExpandedSectoin_'+index] ?
                                                                <Icon icon="basil:expand-outline" width={24} height={24} className={`text-white bg-DeepPurpleTints-600 h-8 w-8 rounded-full cursor-pointer self-center ml-3`}
                                                                    onClick={() => {
                                                                        expandSection(state.clusterDetails, 'expand', index)
                                                                    }} 
                                                                />
                                                            :
                                                                <Icon icon="la:compress" width={24} height={24} className={`bg-DeepPurpleTints-600 text-white h-8 w-8 rounded-full mt-3 cursor-pointer`} onClick={() => expandSection(state.clusterDetails, 'collapse', index)} />
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                {item.trend && Object.entries(item.trend).length ? 
                                                    item.order && item.order.slice(0,4).map((key, keyIndex) => {
                                                        return(
                                                            <React.Fragment key={'tr_'+keyIndex}>
                                                            {state[key+'_'+index] && Object.entries(state[key+'_'+index]).length ?
                                                                <div className="w-full lg:w-1/4 md:w-1/2 pr-3 cursor-pointer">
                                                                    <p className='text-black pl-4 pt-3 text-sm font-semibold'>{item.title && item.title[key] ? item.title[key] : key}</p>
                                                                    <div className='flex'>
                                                                        <div className='self-center w-full'>
                                                                            <ApexLineChartSpendBoard
                                                                                graphData={state[key+'_'+index]}
                                                                                sparkline={false}
                                                                                yaxis={true}
                                                                                yaxisLabel={true}
                                                                                xaxis={true}
                                                                                xaxisFormat={'datetime'}
                                                                                // xaxisTitle={item.title && item.title[key] ? item.title[key] : key}
                                                                                xaxisLabel={true}
                                                                                axisLabelColor={'#666666'}
                                                                                paddingLeft={10}
                                                                                legend={false}
                                                                                stacked={false}
                                                                                height={200}
                                                                                customHpcDashboardTooltip={true}
                                                                                className={'transparentTooltip'}
                                                                                gradient={true}
                                                                                gradientColor={['#FDA272', '#FFF3ED']}
                                                                                colors={['#FC6D22']}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            : null}
                                                            </React.Fragment>
                                                        )
                                                    })
                                                : null}
                                            </div>
                                        }
                                        </React.Fragment>
                                    : 
                                        <div className="w-full pr-0">
                                            <div className='flex justify-between p-2'>
                                                <div className="flex justify-between w-full lg:w-3/5 text-base">
                                                    <div className="flex">
                                                        <div className="py-1 w-full">
                                                            <p className="b-block mb-0 text-lightGray">Cluster</p>
                                                            <p className="mb-0 text-black">
                                                                { item.cluster_name ? item.cluster_name : ''}
                                                                {item.cluster_version ?
                                                                    <span className={`mb-0 mr-2 f18 text-black`}>
                                                                        (v {item.cluster_version})
                                                                    </span>
                                                                : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="flex pt-2">
                                                        <div className="py-1 w-full">
                                                            <p className="b-block mb-0 text-lightGray">Asset</p>
                                                            <p className="mb-0 text-black">{item.asset_name ? item.asset_name : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex pt-2">
                                                        <div className="py-1 w-full">
                                                            <p className="b-block mb-0 text-lightGray">Resources</p>
                                                            <p className="mb-0 text-black">
                                                            {item.account_id ? <span>{' ' + item.account_id}</span> : null}
                                                            {item.region ? <span>{' ' + item.region}</span> : null}
                                                            {item.resource_type ? <span>{' ' + item.resource_type}</span> : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex justify-end self-center mr-4'>
                                                    <SelectOption
                                                        label={""}
                                                        fields={["value", "label"]}
                                                        options={PERIOD_OPTIONS_NEW}
                                                        selectedValues={state['selected_Period_'+index] ? state['selected_Period_'+index] : ''}
                                                        callbackMultiSelect={(value) => {
                                                            setState((prevState) => ({ ...prevState,
                                                                ['selected_Period_' + index]: value,
                                                                ['showLoading_'+index]: true
                                                            }));
                                                            onChangePeriod(value, index, item);
                                                        }}
                                                        singleSelection={true}
                                                        dropdownWidth={'min-w-32'}
                                                        classDetails={{border: "border border-primaryPurple-600", padding: "py-2.5 pr-4 pl-3", bg: 'bg-lavender-200', rounded: 'rounded-full' }}
                                                    />
                                                    <p className='mb-0 text-white bg-DeepPurpleTints-600 pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base mx-3' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: item, selectedType: 'Cluster', showSidePanel: true }))}>View Details</p>

                                                    <Icon icon="la:compress" className={`bg-DeepPurpleTints-600 h-8 w-8 rounded-full text-white cursor-pointer self-center p-1`} width={24} height={24}
                                                        onClick={() => {
                                                            setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+index]: false }))
                                                        }} 
                                                    />

                                                    <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-8 w-8 px-1 rounded-full self-center ml-3 cursor-pointer' width={24} height={24} onClick={() => { 
                                                        setState(prevState => ({ ...prevState, downloading: true })) 
                                                        downloadPDF(index, item)
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {state['showExpandedSectoin_'+index] ?
                                    state['showLoading_'+index] ?
                                        <div className="w-full lg:w-3/4 flex justify-center self-center py-4">
                                            {/* <LoadingCircle /> */}
                                        </div>
                                    :
                                        <div className='bg-white p-3 mx-3 mt-2'>
                                            <div className="w-full pr-0">
                                                {/* <div className='flex justify-end'>
                                                    <p className='text-info mb-1 cursor-pointer' onClick={() => { 
                                                        setState(prevState => ({ ...prevState, downloading: true })) 
                                                        downloadPDF(index, item)
                                                    }}>Download PDF</p>
                                                </div> */}
                                                <div className='flex flex-wrap'>
                                                    {item.trend && Object.entries(item.trend).length ? 
                                                        item.order && item.order.map((key, keyIndex) => {
                                                            return(
                                                                <React.Fragment key={'tr_'+keyIndex}>
                                                                {state[key+'_'+index] && Object.entries(state[key+'_'+index]).length ?
                                                                    <div className="w-full lg:w-1/2 lg:px-1 pr-0 mb-3" id={'chartContainer_'+index+'_'+keyIndex}>
                                                                        <div className='bg-PrimaryTintsPurple-600 border border-NeutralGray-500 p-3 rounded-2xl'>
                                                                            <p className='mb-2 text-black'>
                                                                                <span className='text-base pr-5'>
                                                                                    {item.title && item.title[key] ? item.title[key] : removeUnderScore(key)}
                                                                                </span>
                                                                                {item.total && item.total[key] && (item.total[key].total || item.total[key].total === 0) ?
                                                                                    <span className='text-base text-NeutralGray-600 pr-5'><span className=''>Total</span> ({item.total[key].total}) </span>
                                                                                : null}
                                                                                {key === "avg_cumulative_CPU_hours" && item.total && item.total[key] && (item.total[key].avg || item.total[key].avg === 0) ?
                                                                                    <span className='text-base text-NeutralGray-600 pr-5'><span className=''>Avg </span>({item.total[key].avg})</span>
                                                                                : null}
                                                                                {item.total && item.total[key] && (item.total[key].max || item.total[key].max === 0) ?
                                                                                    <span className='text-base text-NeutralGray-600 pr-5'><span className=''>Max </span>({item.total[key].max})</span>
                                                                                : null}
                                                                            </p>
                                                                            <div className='mt-n2 mr-4 self-center'> 
                                                                                <ApexLineChartSpendBoard
                                                                                    graphData={state[key+'_'+index]}
                                                                                    sparkline={false}
                                                                                    yaxis={true}
                                                                                    yaxisLabel={true}
                                                                                    yaxisBorder={true}
                                                                                    yaxisTitle={item.labels && item.labels[key] ? item.labels[key] : ''}
                                                                                    gridXaxisBorder={false}
                                                                                    gridYaxisBorder={true}
                                                                                    gridColor={'#434B5E'}
                                                                                    axisLabelColor={'#495057'}
                                                                                    xaxis={true}
                                                                                    xaxisFormat={'datetime'}
                                                                                    xaxisLabel={true}
                                                                                    paddingLeft={10}
                                                                                    legend={false}
                                                                                    stacked={false}
                                                                                    height={300}
                                                                                    customHpcDashboardTooltip={true}
                                                                                    className={'transparentTooltip'}
                                                                                    // colors={['#43b02a', '#009FDF']}
                                                                                    gradient={true}
                                                                                    gradientColor={['#F4F1FA', '#A790D8']}
                                                                                    colors={['#7551C2']}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                : null}
                                                                </React.Fragment>
                                                            )
                                                        } )
                                                    : null}
                                                    {state['reports_'+index] ?
                                                        <div className="relative overflow-x-auto mt-3 rounded-t-lg shadow border border-lightGray-600 w-full" id={'table_'+index}>
                                                            <ResizeableDarkThemeTable
                                                                id={'table_'+index}
                                                                data={state['filteredReports_'+index].length ? state['filteredReports_'+index] : state['filteredReports_'+index]}
                                                                columns = {state['report_columns_'+index]}
                                                                onClickRow={tableRow => {}}
                                                                dashboard = {state['filteredReports_'+index].length ? true : false}
                                                                selectedColor={'bg-transparent'}
                                                                sortByColumn={"date"}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                : null}
                            </div>
                        )
                    })
                : 
                    <div className='flex justify-center m-4 text-black'>
                        {getCommonInformationMessage('cluster')}
                    </div>
                }
                
                {state.totalRecords > state.perPage ?
                    <div className='flex justify-end text-black w-full'>
                        <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                        <div className="flex text-sm">
                            <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 rounded-md ${state.listResponse && state.listResponse.previous_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                if(state.listResponse && state.listResponse.previous_token) {
                                    setState(prevState => ({ ...prevState, currentPage: state.currentPage-1, token: 'previous', callSearch: true }))
                                }
                            }}/></span> 
                            <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.listResponse && state.listResponse.next_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                if(state.listResponse && state.listResponse.next_token) {
                                    setState(prevState => ({ ...prevState, currentPage: state.currentPage+1, token: 'next', callSearch: true }))
                                }
                            }}/></span>
                        </div>
                    </div>
                : null}
            </div>
        </div>
    )
}
export default ClusterDetails