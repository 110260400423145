import React, { useEffect, useState } from 'react';
import {Icon} from "@iconify/react";

const TimeInput = ({ label, selectedValue, callback, className, placeholder, manditory, hasError }) => {
    const [time, setTime] = useState('');

    useEffect(() => {
        setTime(selectedValue)
    }, [selectedValue])

    const handleChange = (e) => {
        setTime(e.target.value);
        callback(e.target.value)
    };

    const validateTime = () => {
        // Validate that the time is in the correct format (HH:mm)
        const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return timePattern.test(time);
    };

    return (
        <div className={`w-full items-center ${className}`}>
            <div className='flex justify-between'>
                <p className="text-NeutralGray-800 text-base flex w-full">
                    {label} <span className={`text-NeutralGray-600 pl-2`}>({placeholder})</span>
                    {manditory ?
                        <Icon icon="fe:star" className='text-red-600 ml-1 self-center' width={12} height={12} />
                    : null}    
                </p>
                {!time && hasError ?
                    <p className='text-sm text-ferrariRed-600'>Required</p>
                : null}
            </div>
            <input
                type="time"
                id="timeInput"
                value={time}
                onChange={handleChange}
                className="placeholder:italic placeholder:text-NeutralGray-600 text-base block border border-NeutralGray-400 rounded-md py-2.5 pl-2 shadow-sm w-full" 
                placeholder="HH:mm"
            />
        </div>
    );
};

export default TimeInput;
