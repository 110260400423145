/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file CreateNewBudget.js
 * @author Prakash // on 25/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import { useLocation, useNavigate } from 'react-router-dom'
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import { URL_PATH } from '../../../../config/urlPath';
import Button from '../../../common/Form/Button';

const CreateNewBudget = () => {

    const [state, setState] = useState({
    })
    
    const location = useLocation();
    const receivedData = location.state;
    
    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function
    const onClickSave = useSelector(state => state?.hpc?.hpcPropsDetails?.onClickSave || false);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
        };

        const handlePopstate = () => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
        }
        };

        const handleBeforeRedirect = (location) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
            navigate(location.pathname); // Redirect manually after confirmation
        } else {
            // Prevent the redirect if the user cancels
            return false;
        }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopstate);

        // Use window.onbeforeunload to handle redirects
        const unblock = () => {};
        window.onbeforeunload = () => handleBeforeRedirect({ pathname: '/' });

        return() => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopstate);
            window.onbeforeunload = null; // Remove the onbeforeunload handler when the component is unmounted
            unblock(); // Remove the history.block when the component is unmounted
        };
    }, [navigate]); // Re-run the effect when navigate changes

    useEffect(() => {
        if(onClickSave === 'stopLoading') {
            setState(prevState => ({ ...prevState, onSaveFunction: false }))
            dispatch(setHpcPropsDetails('onClickSave', ''))
        }
    }, [onClickSave, dispatch])

    useEffect(() => {
        if(receivedData && Object.entries(receivedData).length) {
            setState(prevState => ({ 
                ...prevState, 
                selectedData: receivedData.selectedData ? receivedData.selectedData : '',
                pageType: receivedData.pageType ? receivedData.pageType : '',
            }))
        }
    }, [receivedData])

    const navigateTo = () => {
        navigate(URL_PATH.HPC_BUDGET); // Redirect to the specified path
    }

    return (
        <React.Fragment>
            <div className='bg-NeutralGray-200 px-8 py-10 text-black w-full'>
                <div className='w-full self-center'>
                    <span className='font-bold text-2xl capitalize'>{state.pageType} Budget</span>
                </div>
            </div>
            <div className='px-6 pt-6 pb-3'>
                <div className='flex flex-wrap'>
                    <div className={`lg:w-4/6 w-full flex flex-col h-100`}>
                        <div className='overflow-y-auto h-[calc(100vh-260px)]'>
                            <LeftSection 
                                receivedData={receivedData}
                            />
                        </div>
                    </div>
                    <div className={`lg:w-2/6 w-full lg:pl-2 flex flex-col h-100`}>
                        <div className='overflow-y-auto h-[calc(100vh-260px)]'>
                            <RightSection 
                                receivedData={receivedData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full p-8 shadow-lg bg-NeutralGray-700 self-start flex justify-center">
                <Button
                    classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                    label={'Cancel'}
                    callback={() => 
                        navigateTo()
                    }
                />
                {state.onSaveFunction ?
                    <Button
                        classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                        label={state.pageType !== "edit" ?  "Saving"  : "Updating"}
                        loading={true}
                    />
                :
                    <Button
                        classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                        label={state.pageType !== "edit" ?  "Save"  : "Update"}
                        callback={() => {
                            setState(prevState => ({ ...prevState, onSaveFunction: true }))
                            dispatch(setHpcPropsDetails('onClickSave', 'save'))
                        }}
                    />
                }
            </div>
        </React.Fragment>
    )
}

export default CreateNewBudget