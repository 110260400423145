/*************************************************
 * Collider
 * @exports
 * @file SavedFiltersSidePane.js
 * @author Prakash // on 18/04/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions

import { listCostTemplates, saveTemplate } from '../../../actions/cost/CostAction'

import { capitalizeFirstLetter, momentConvertionUtcToLocalTime, momentDateGivenFormat } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput';
 import { Store as CommonNotification } from 'react-notifications-component';
 import {Icon} from "@iconify/react";

const SavedFiltersSidePane = (props) => {
	const clickOut = useRef()
    const[state, setState] = useState({
		minMaxHeight: 700,
		showLoading: true,
		template_name: "",
		description: "",		

		startRecord: 0, 
		currentPage: 1,
		totalPages: 1,
		perPage: 15,
	})
	
	// const tagKeys = useSelector(state => state.filters.tagKeys);

    const dispatch = useDispatch(); // Create a dispatch function

    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel()
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return() => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

	const templateViewFunction = useCallback(() => {		
		let inputFilters = props.selectedFilters
		let selectedFilters = []
		if(inputFilters.Tags) {
			selectedFilters.push(inputFilters.Tags)
		}
		if(inputFilters.Duration) {
			selectedFilters.push(inputFilters.Duration)
		}
		selectedFilters = [...selectedFilters, ...inputFilters.filters]
		setState(prevState => ({ ...prevState, savedFilters: props.selectedFilters, selectedFilters }))
				
	}, [props.selectedFilters])

	const listCostTemplatesFunction = useCallback(() => {
		let params = {}
		params.source = props.selectedProvider
		dispatch(listCostTemplates(params))
			.then((response) => {
				if(response) {
					let totalPages = 1
					if(response.length > state.perPage) {
						totalPages = Math.ceil(response.length / state.perPage)
					}
					
					setState(prevState => ({ ...prevState, listData: response, filteredListData: response, totalPages }))
				}
			})
	}, [dispatch, props.selectedProvider, state.perPage])

	useEffect(() => {
        // const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt)

		setState(prevState => ({ ...prevState, minMaxHeight: outerHeight }))

		if(props.templateType === "view") {
			templateViewFunction()
		} else {
			setState(prevState => ({ ...prevState, selectedFilters: props.selectedFilters }))
		}
		listCostTemplatesFunction()

	}, [props.selectedFilters, props.templateType, templateViewFunction, listCostTemplatesFunction])

    const navigatePage = (action, currentPage) => {
		let startRecord = state.startRecord
		if(action === 'next' && currentPage !== state.totalPages) {
			startRecord = startRecord + state.perPage;
			setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
		} else if(action === 'previous' && currentPage !== 0) {
			setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
			startRecord = startRecord - state.perPage;
		} else if(action === 'start' && currentPage !== 0) {
			setState(prevState => ({ ...prevState, currentPage: 1 }));
			startRecord = 0;
		} else if(action === 'end' && currentPage !== 0) {
			setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
			startRecord = (state.totalPages - 1) * state.perPage;
		}
		setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

	useEffect(() => {
		if(state.callSaveFunction) {
			let hasError = false
			let params = {}
			params.source = props.selectedSource
			params.provider = props.selectedProvider		
			
			if(state.template_name && state.template_name !== "") {
				params.template_name = state.template_name
			} else {
				hasError = true
			}
	
			if(state.description && state.description !== "") {
				params.description = state.description
			} else {
				hasError = true
			}
	
			let selectedFilters = state.selectedFilters
	
			let filters = []
			if(selectedFilters.filter(e => e.label !== "Duration" && e.label !== "Tags").length) {
				selectedFilters.forEach(row => {
					if(row.label !== "Duration" && row.label !== "Tags") {
						filters.push(row)
					} else {
						params[row.label] = row
					}
				})
			}
	
			params.filters = filters
			
			if(!hasError) {
				dispatch(saveTemplate(params))
					.then((response) => {
						let messageType = 'danger'		
						if(response.status) {
							messageType = 'success'
						}
						let message = response && response.message
						CommonNotification.addNotification({
							message: message,
							type: messageType,
							insert: "top",
							container: "top-center",
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						});
						if(response.status) {
							setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false }))
							setTimeout(() => { listCostTemplates() }, 2000)
						} else {
							setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false }))
						}
					})
			} else {
				setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false, hasError }))
			}
		}

	}, [dispatch, props.selectedProvider, state.description, state.selectedFilters, state.template_name, state.callSaveFunction])

	const handleNameChange = e => {
        const { name, value } = e.target;
		setState(prevState => ({ ...prevState, [name]: value }))
	}

	const handleDesciption = e => {		
        const { name, value } = e.target;
		setState(prevState => ({ ...prevState, [name]: value }))
	}
	
	return (
		<div className="advanced-search overflow-auto" style={{ zIndex: 9999999 }}>
			{/* <div className={`loaderOverlay ${state.queryLoading ? "" : 'displayNone'}`}>
				<div className="overlayEqualizerLoader">
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
				</div>
			</div> */}
            <div className="search-content bg-GhostWhite w-4/5 h-100 flex flex-column" ref={clickOut}>
				<div className="header-search bd-highlight bg-white flex justify-between p-2">
                    <div className="flex-fill bd-highlight text-black">
                        <h5>{props.templateType === "save" ? "Save Filter (as Template)" : props.templateType === "view" ? "View Template" : "Templates"}</h5>
					</div>

					<div className="text-right flex-fill bd-highlight self-center">
						<Icon icon="jam:close" className='text-black' width="25" height="25" onClick={() => props.closeSidePanel()} />
					</div>
				</div>
				<div className={`overflow-auto p-2`}>						
					{props.templateType !== "template" ?
						<div className="bg-white m-2 p-2 rounded-md counts_threads">
							<div className="flex flex-wrap">
								<div className="py-1 w-1/3">
									<div className='w-full'>
										<div className="flex justify-between">
											<label className="mb-1">Title </label>
											{state.hasError && (!state.template_name || state.template_name === "") ?
												<small className={`text-danger mr-2`}>(required)</small>
											: null}
										</div>
										<p className="mb-0 text-black">
											{props.templateType === "view" ?
												capitalizeFirstLetter(state.savedFilters && state.savedFilters.template_name)
											: 
												<input
													type="text"
													placeholder="Enter template name"
													className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black`}
													// maxlength="128"
													name="template_name"
													value={state.template_name || ''}
													onChange={handleNameChange}
												/>
											}
										</p>
									</div>
									<div className='w-full mt-4'>
										<div className="flex justify-between">
											<label className="mb-1">Source </label>
										</div>
										<p className="mb-0 text-black">{capitalizeFirstLetter(props.selectedProvider)}</p>
									</div>
								</div>
								<div className="py-1 w-2/3 pl-3">
									<div className="flex justify-between">
										<label className="mb-1">Description </label>
										{state.hasError && (!state.description || state.description === "") ?
											<small className={`text-danger mr-2`}>(required)</small>
										: null}
									</div>
									{props.templateType === "view" ? 
										<p className="mb-0 text-black">{state.savedFilters && state.savedFilters.description}</p>
									: 
										<textarea 
											id="description" 
											name="description" 
											rows="4" 
											readOnly={state.pageType === 'View' ? true : false}
											className={`w-full p-2 mb-4 bg-transparent border border-lightGray rounded-md text-black`}
											onChange={handleDesciption}
											value={state.description}
											placeholder='Enter Description'
										/>
									}
								</div>
							</div>
							<div className='bg-BlockWhite rounded-md p-3 my-4'>
								<div className='flex justify-between mb-2'>
									<p className="mb-2 w-full text-black">Selected Filters</p>
								</div>
								<div className="flex flex-wrap">
									{state.selectedFilters && !state.selectedFilters.filter(e => e.label === "Account").length ?
										<span className="font-semibold self-center cursor-pointer text-lightGray mr-2 mt-2">Account : <span className='text-info'>All</span></span>
									: null}

									{state.selectedFilters && !state.selectedFilters.filter(e => e.label === "Region").length ?
										<span className="font-semibold self-center cursor-pointer text-lightGray mx-2 mt-2">Region : <span className='text-info'>All</span></span>
									: null}
									
									{state.selectedFilters && state.selectedFilters.length ? 
										state.selectedFilters.map((filters, index) => {
											return(
												<React.Fragment key={'sel_'+index}>
												{filters.label === "Duration" ?
													<React.Fragment>
														<span className="font-semibold self-center mt-2 cursor-pointer text-lightGray mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(filters.start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(filters.end_time, 'DD MMM YYYY')}</span> </span>
													</React.Fragment>
												: filters.value && !Array.isArray(filters.value) ?
													<span className="bg-info py-1 rounded-full mr-2 mt-2 self-center f14 px-2"><span className='text-extraLightGray'>{filters.label+" : "}</span>{filters.output_label ? filters.output_label : filters.label}</span>
												: filters.label === "Tags" ?
													<div className='flex flex-wrap'>
													{filters.value.map(row => {
														return(
															<span key={'tag_'+row.key} className="bg-info py-1 rounded-full mb-2 mr-2 mt-2 self-center f14 px-2">
																<span className='text-lightGray'>Tag :</span> 
																{row.key +" : "+row.value}
															</span>
														)
													})}
													</div>
												:
													<div className='flex flex-wrap'>
														{filters.value.map(row => {
															return(
																<span key={'row_'+row} className="bg-info py-1 rounded-full mb-2 mr-2 mt-2 self-center f14 px-2">
																	<span className='text-lightGray'>{filters.type+" : "}</span> 
																	{filters.label +" : "+row}
																</span>
															)
														})}
													</div>
												}
												</React.Fragment>
											)
										})
									: null}
								</div>
							</div>
							<div className={`flex mb-2 justify-end`}>
								{props.templateType === "save" ?
									state.saveLoading ?
										<button className="btn bg-info text-white px-2 py-1 rounded-md flex">
											<svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
												<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
												<path d="M4 12a8 8 0 0112-6.9" />
											</svg>
											Saving 
										</button>
									: 
										<button className={`flex bg-info px-2 py-1 rounded-md justify-end cursor-pointer text-white btn ${state.saveLoading ? 'pointer-events-none' : ''}`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))}>Save</button>
								: null}
								<button className={`flex justify-end cursor-pointer btn bg-lightGray text-white px-2 py-1 ml-2 rounded-md`} onClick={ () => props.closeSidePanel()}>Cancel</button>
							</div>
						</div>
					: null}
					<div className={`flex pb-2 justify-end mt-2`}>
						<div className="flex mr-2 w-2/5">
							<p className="f12 m-0 self-center pt-1">Showing {state.filteredListData && state.filteredListData.length} of total {state.listData && state.listData.length} template(s)</p>
						</div>
						<div className="flex justify-end w-3/5 mb-n2">
							{state.listData && state.listData.length ?
								<Search
									data={state.listData ? state.listData : []}
									topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                    searchClassName={'px-2'}
                                    searchIconColor={'text-black '}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent text-black'}
                                    widthHeight={25}
									filteredData={(filteredListData) => {
										let totalPages = 1
										if(filteredListData.length > state.perPage) {
											totalPages = Math.ceil(filteredListData.length / state.perPage)
										}
										setState(prevState => ({ ...prevState, filteredListData, startRecord: 0, currentPage: 1, totalPages }))
									}}
								/>
							: null}

							{state.filteredListData && state.filteredListData.length > state.perPage ?
								<div className="pagination errorPagePagination f12 mt-n1">
									<span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
									<button><i className={`fal fa-arrow-to-left cursor-pointer text-lightGray ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
									<button><i className={`fal fa-angle-left cursor-pointer text-lightGray ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
									<button><i className={`fal fa-angle-right cursor-pointer text-lightGray ${!state.nextErrorListToken && state.nextErrorListToken ==="" && state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
									<button><i className={`fal fa-arrow-to-right cursor-pointer text-lightGray ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
								</div>
							: null}
						</div>
					</div>
					<div className="relative overflow-x-auto mt-2">
						<table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
							<thead className="text-md text-black bg-lightGray">
								<tr>
									<th scope="col" className="px-6 py-3">Date</th>
									<th scope="col" className="px-6 py-3">Title</th>
									<th scope="col" className="px-6 py-3">Description</th>
									<th scope="col" className="px-6 py-3">Filters</th>
								</tr>
							</thead>
							<tbody className='text-black bg-white'>
								{state.filteredListData && state.filteredListData.length ?
									state.filteredListData.slice(state.startRecord, state.startRecord + state.perPage).map((row, i) => {
										return(
											<tr key={'sft_'+i} className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700`}>
												<td className="px-6 py-3 text-sm"><span className='text-nowrap'>{row.created_at ? momentConvertionUtcToLocalTime(row.created_at, 'DD MMM YYYY HH:mm') : ""}</span></td>
												<td className="px-6 py-3 text-sm">{row.template_name ? row.template_name : ""}</td>
												<td className="px-6 py-3 text-sm">{row.description ? row.description : ""}</td>
												<td className="px-6 py-3 text-sm">
													<div className='flex flex-wrap'>
														{Array.isArray(row.filters) && !row.filters.filter(e => e.label === "Account").length ?
															<small className="font-semibold self-center cursor-pointer text-lightGray mr-2">Account : <span className='text-info'>All</span></small>
														: null}	

														{Array.isArray(row.filters) && !row.filters.filter(e => e.label === "Region").length ?
															<small className="font-semibold self-center cursor-pointer text-lightGray mx-2">Region : <span className='text-info'>All</span></small>
														: null}

														{row["Duration"] ?
															<div className='flex ml-2'>
																<small className="font-semibold self-center cursor-pointer text-lightGray mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(row["Duration"].start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(row["Duration"].end_time, 'DD MMM YYYY')}</span> </small>
															</div>
														: null}

														{row["Tags"] ?
															<div className='flex flex-wrap'>
																{row["Tags"].value.map(tag => {
																	return(
																		<span key={'tt_'+row} className="bg-info py-1 rounded-full mr-2 mt-2 self-center f14 px-2"><span className='text-lightGray'>Tag :</span> {tag.key +" : "+tag.value}</span>
																	)
																})}
															</div>
														: null}

														{row.filters ? 
															Array.isArray(row.filters) ? 
																row.filters.map(flt => {
																return(
																	<React.Fragment key={'flt_'+flt.label}>
																	{Array.isArray(flt.value) ?
																		<div className='flex flex-wrap'>
																			{flt.value.map(item => {
																				return(
																					<span key={'fltii_'+item} className="bg-info py-1 rounded-full mr-2 mt-2 self-center f14 px-2">
																						<span className='text-lightGray'>{flt.type} : </span> 
																						{flt.label +" : "+item}
																					</span>
																				)
																			})}
																		</div>
																	: 
																		<span className="bg-info py-1 rounded-full mr-2 mt-2 self-center f14 px-2"><span className='text-lightGray'>{flt.label+" : "}</span> {flt.output_label ? flt.output_label : flt.value}</span>
																	}
																	</React.Fragment>
																)
																})
															: typeof(row.filters) === "object" ?
																Object.entries(row.filters).map(([fKey, fValue], index) => {
																return(
																	<span key={'fValue_'+index+'_'+fValue}>
																	{Array.isArray(fValue) ?
																		<div className='flex flex-wrap'>
																			{fValue.map(item => {
																				return(
																					<span key={'dddd_'+item+'_'+fKey} className="bg-info py-1 rounded-full mr-2 mt-2 self-center f14 px-2">
																						{fKey +" : "+item}
																					</span>
																				)
																			})}
																		</div>
																	: 
																		<span className="bg-info py-1 rounded-full mr-2 mt-2 self-center f14 px-2"><span className='text-lightGray'>{fKey+" : "+fValue}</span></span>
																	}
																	</span>
																)
																})
															: null
														: null}
													</div>
												</td>
											</tr>
										)
									})
								:  
									<tr>
										<td  colSpan="4" className='mb-0 text-black text-center'>There are no data on this criteria. Please try adjusting your filter.</td>
									</tr>
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SavedFiltersSidePane