import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from "../../../../actions/Collider/HpcAction";
import MultiSelectSection from "../../../common/MultiSelectSection";
import { validateVolumeName, validateMountDir } from "../../../../utils/utility";
import { Icon } from '@iconify/react'
import SelectOption from "../../../common/Form/SelectOption";
import Textbox from "../../../common/Form/Textbox";

const ExistingStorageSection = (props) => {
	const [state, setState] = useState({storageArray: [0]});
	const createUpdateCluster = useSelector(
		(state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
	);
	const propsClusterFilters = useSelector(
		(state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
	);

	const dispatch = useDispatch(); // Create a dispatch function

	const callPageVist = useCallback(() => {
		let storageConfiguration = createUpdateCluster?.storage_configuration || [];
		let storageArray = [0];
	
		if (storageConfiguration.length) {
			storageArray = [];
			storageConfiguration.forEach((row, index) => {
				storageArray.push(index);
				setState((prevState) => ({
					...prevState,
					[`storage_type_${index}`]: row.storage_type,
					...Object.entries(row).reduce((acc, [key, value]) => {
						acc[`${key}_${index}`] = value;
						return acc;
					}, {})
				}));
			});

			setState((prevState) => ({
				...prevState,
				storageArray
			}));
		}
	}, [createUpdateCluster]);

	useEffect(() => callPageVist(), [callPageVist]);

	const addSection = (array) => {
		let rowList = state[array];
		if (state[array]) {
			let value = state[array][state[array].length - 1];
			value = value + 1;
			rowList.push(value);

			setState((prevState) => ({ ...prevState, [array]: rowList }));
		}
	};

	const removeSection = (array, index, i) => {
		setState((prevState) => ({
			...prevState,
			["storage_id_" + index]: "",
			["storage_name_" + index]: "",
			["mount_dir_" + index]: ""
		}));

		let rowList = state[array];
		rowList.splice(i, 1);
		setState((prevState) => ({ ...prevState, [array]: rowList }));

		// setTimeout(() => {
		//	 handleStorageInput();
		// }, 5000);
	};

	const handleStorageInput = (index, label, value, type) => {
		let obj = createUpdateCluster ? createUpdateCluster : {};
		let storageConfiguration = obj.storage_configuration && obj.storage_configuration.length ?	obj.storage_configuration : [];

		state.storageArray.forEach((sIndex) => {
			if (!storageConfiguration.filter((e) => e.arrayIndex === sIndex).length) {
				let dataRow = { arrayIndex: sIndex, storage_type: props.storageType};
				storageConfiguration.push(dataRow);
			}
			let selectedStorageInput = storageConfiguration.filter((e) => e.arrayIndex === sIndex)[0];
		 
			if (sIndex === index) {
				setState((prevState) => ({
					...prevState,
					[label + "_" + sIndex]: value
				}));
			}
			let inputValue = sIndex === index ? value : state[label + "_" + sIndex];
			selectedStorageInput[label] = type === "integer" ? parseInt(inputValue) : inputValue;
		});
		obj["storage_configuration"] = storageConfiguration;
		dispatch(setHpcPropsDetails("createUpdateCluster", obj));
	};

	const handleChildClick = (event, type, dropdownType, section) => {
		event.stopPropagation();

		let clickedChild = [];
		if (type === "child") {
			if (state.clickedChild && state.clickedChild.length) {
				state.clickedChild.forEach((row) => {
					setState((prevState) => ({ ...prevState, [row]: false }));
				});
			}
			clickedChild.push(section);
			setState((prevState) => ({
				...prevState,
				[section]:
					dropdownType === "singleDropDown" && state[section] ? false : true,
				clickedChild,
			}));
		} else if (type === "parent") {
			if (state.clickedChild && state.clickedChild.length) {
				state.clickedChild.forEach((row) => {
					setState((prevState) => ({ ...prevState, [row]: false }));
				});
			}

			setState((prevState) => ({ ...prevState, clickedChild }));
		}
	};

	return (
		<div onClick={(event) => handleChildClick(event, "parent")}>
			{state.storageArray &&
				state.storageArray.map((index, j) => {
					return (
						<div key={index}>
						<div className="bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl mt-10 pl-8 pt-[30px] pb-[17px]">
							{props.storageType === "Efs" ? (
								<React.Fragment>
									<div className='flex flex-wrap'>
										<div className='flex flex-col justify-start w-1/2 pr-4'>
											<SelectOption
												label={"Filesystem name"}
												fields={["value", "key"]}
												options={ propsClusterFilters && propsClusterFilters.efs ? propsClusterFilters.efs.map((d) => ({ key: d, value: d})) : []}
												selectedValues={state["storage_name_" + index] ? state["storage_name_" + index] : "" }
												callbackMultiSelect={(value) => {
													handleStorageInput( index, "storage_name", value);
													setState((prevState) => ({ ...prevState, ["storage_name_" + index]: value }));
												}}
												singleSelection={true}
												manditory={true}
                            					hasError={state.inputValidationError}
												dropdownWidth={'w-full'}
												disabled={props.pageMode === 'Edit'}
											/>
											<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.storage_name}</p>
										</div>
									</div>
									<div className='flex flex-wrap pt-8'>
										<div className='flex flex-col justify-between w-4/5 lg:pr-4'>
											<Textbox
												label={"Mount Directory"}
												type="text"
												selectedValue={state["mount_dir_" + index] ? state["mount_dir_" + index] : ""}
												callback={(value) => {
													handleStorageInput( index, "mount_dir", validateMountDir(value));
												}}
												placeholder={"Enter mount directory (Eg. /data)"}
												disabled={props.pageMode === 'Edit'}
											/>
											<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.mount_dir}</p>
										</div>
										<div className='flex w-1/5 justify-center'>
											{state.storageArray.length > 1 ? 
												<span className={`flex justify-end mb-0 text-white bg-ferrariRed-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base`} onClick={() =>  removeSection("storageArray", index, j)}> Remove</span>
											: null}
										</div>
									</div>
								</React.Fragment>

							) : props.storageType === "FsxOntap" ? (
								<React.Fragment>
									<div className='flex flex-wrap'>
										<div className='flex flex-col justify-start w-1/2 pr-4'>
											<SelectOption
												label={"Storage Id"}
												fields={["value", "key"]}
												options={propsClusterFilters && propsClusterFilters.fsx ? propsClusterFilters.fsx.map((d) => ({ key: d, value: d})) : []}
												selectedValues={state["storage_id_" + index] ? state["storage_id_" + index] : ""}
												callbackMultiSelect={(value) => {
													handleStorageInput(index,"storage_id",value);
													setState((prevState) => ({ ...prevState,["storage_id_" + index]: value }));
												}}
												singleSelection={true}
												manditory={true}
                            					hasError={state.inputValidationError}
												dropdownWidth={'w-full'}
												disabled={props.pageMode === 'Edit'}
											/>
											<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.storge_id}</p>
										</div>
									</div>
									<div className='flex flex-wrap pt-8'>
										<div className='flex flex-col justify-between w-4/5 lg:pr-4'>
											<Textbox
												label={"Volume name"}
												type="text"
												selectedValue={state["storage_name_" + index] ? state["storage_name_" + index] : ""}
												callback={(value) => {
													handleStorageInput(index, "storage_name", validateVolumeName(value));
												}}
												placeholder={"Enter volume name (Eg. data)"}
												disabled={props.pageMode === 'Edit'}
											/>
											<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.storage_configuration?.volume_name}</p>
										</div>
										<div className='flex w-1/5 justify-center'>
											{state.storageArray.length > 1 ? 
												<span className={`flex justify-end mb-0 text-white bg-ferrariRed-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base`} onClick={() =>  removeSection("storageArray", index, j)}> Remove</span>
											: null}
										</div>
									</div>
								</React.Fragment>
							) : null}

						</div>
							{state.storageArray.length - 1 === j ? 
								<div className='flex justify-center mt-4'>
									<p className='mb-4 text-primaryPurple-600 border border-primaryPurple-600 bg-white pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base' onClick={() =>  addSection("storageArray")}>+ Add Storage</p>
								</div>
							: null}
						</div>
					);
				})}
		</div>
	);
};

export default ExistingStorageSection;
