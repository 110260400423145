/*************************************************
 * Collider
 * @exports
 * @file RightSection.js
 * @author Prakash // on 29/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from  '../../../../actions/Collider/HpcAction'
import { listQueues } from '../../../../actions/Collider/ClusterAction'
import { thousandSeparator } from '../../../../utils/utility';
import { listUsersByCluster } from '../../../../actions/Collider/ClusterAction'
import _ from 'lodash'
import Search from '../../../common/SearchWithHiddenInput';
import {Icon} from "@iconify/react";
import Textbox from '../../../common/Form/Textbox';

const RightSection = (props) => {
	const[state, setState] = useState({
		showDetails: false,
        callUserList: true,
        selectedQueueSection: true,
        selectedUserSection: true
	})

	const dispatch = useDispatch(); // Create a dispatch function

    const selectedAplliesTo = useSelector(state => state?.hpc?.hpcPropsDetails?.selectedAplliesTo || false);
    const createInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.createInputs || false);
    const creditSection = useSelector(state => state?.hpc?.hpcPropsDetails?.creditSection || false);
    
    useEffect(() => {
        if(!_.isEqual(selectedAplliesTo, state.selectedAplliesTo)) {
            setState(prevState => ({ ...prevState, selectedAplliesTo }))
            let params = {}
            params.provider = selectedAplliesTo.selectedProvider
            params.account_id = selectedAplliesTo.selectedAccount
            if(selectedAplliesTo.selectedRegion) {
                params.region = selectedAplliesTo.selectedRegion
            }
            params.cluster_name= selectedAplliesTo.selectedClusters

            let fieldName = selectedAplliesTo.selectedProvider+'_'+selectedAplliesTo.selectedAccount+'_'+selectedAplliesTo.selectedRegion+'_'+selectedAplliesTo.selectedClusters

            setState(prevState => ({ ...prevState, fieldName, hasError: false }));
            if(params.provider && params.cluster_name && (selectedAplliesTo.selectedProvider !== (state.selectedAplliesTo && state.selectedAplliesTo.selectedProvider) || selectedAplliesTo.selectedAccount !== (state.selectedAplliesTo && state.selectedAplliesTo.selectedAccount) || selectedAplliesTo.selectedRegion !== (state.selectedAplliesTo && state.selectedAplliesTo.selectedRegion) || selectedAplliesTo.selectedClusters !== (state.selectedAplliesTo && state.selectedAplliesTo.selectedClusters))) {
                dispatch(listQueues(params))
                    .then((response) => {
                        if(response && !response.error) {
                            setState(prevState => ({ ...prevState, queues: response }));
                        }
                    })

                dispatch(listUsersByCluster(params))
                    .then((response) => {
                        if(response && !response.error) {
                            setState(prevState => ({ ...prevState, clusterUserList: response, filterClusterUserList: response }));
                        }
                    })
            }
        }
    }, [selectedAplliesTo, state.selectedAplliesTo, dispatch])

    useEffect(() => {
        let hasError = false
        let obj = createInputs ? createInputs : {}
        let details = obj.details ? obj.details : []
        details.forEach(row => {     
            if(row.applies_to && row.applies_to.length) {
                row.applies_to.forEach(app => {
                    if(app.account_id === selectedAplliesTo.selectedAccount && app.region === selectedAplliesTo.selectedRegion && app.cluster_name === selectedAplliesTo.selectedClusters) {
                        let queueTotal = 0
                        if(app.queues && app.queues.length) {
                            queueTotal = app.queues.reduce((sum, queue) => sum + queue.budget, 0);
                        }
                        let queueError = []
                        if(queueTotal > Math.round(app.budget)) {
                            hasError = true
                            let error = 'The total queue budget should not exceed cluster '+app.cluster_name+' budget of '+app.provider+' provider'
                            queueError.push(error)
                        }
                        setState(prevState => ({ ...prevState, queueError }))
                        
                        let userTotal = 0
                        if(app.users && app.users.length) {
                            userTotal = app.users.reduce((sum, user) => sum + user.budget, 0);
                        }
    
                        let userError = []
                        if(userTotal > Math.round(app.budget)) {
                            hasError = true
                            let error = 'The total user budget should not exceed cluster '+app.cluster_name+' budget of '+app.provider+' provider'
                            userError.push(error)
                        }
                        setState(prevState => ({ ...prevState, userError }))

                        if(app.queues && app.queues.length) {
                            app.queues.forEach(queue => {
                                setState(prevState => ({ 
                                    ...prevState, 
                                    ['queue_'+state.fieldName+'_'+queue.queue_name]: queue.budget ? queue.budget : 0
                                }))
                            })
                        } else {
                            if(state.queues && state.queues.length) {
                                state.queues.forEach(app => {
                                    setState(prevState => ({ ...prevState, ['queue_'+state.fieldName+'_'+app]: 0 }))
                                })
                            }
                        }
                        if(app.users && app.users.length) {
                            app.users.forEach(user => {
                                setState(prevState => ({ 
                                    ...prevState, 
                                    ['user_'+state.fieldName+'_'+user.user_id]: user.budget ? user.budget : 0
                                }))
                            })
                        } else {
                            if(state.users && state.users.length) {
                                state.users.forEach(row => {
                                    setState(prevState => ({ ...prevState, ['user_'+state.fieldName+'_'+row.userId]: 0 }))
                                })
                            }
                        }
                        setState(prevState => ({ ...prevState, queueTotal, userTotal }))
                    }
                })
            }
        })

        setState(prevState => ({ ...prevState, hasError }))
    }, [createInputs, selectedAplliesTo, state.queues, state.users, state.fieldName])

    useEffect(() => {
        if(props.receivedData && Object.entries(props.receivedData).length) {
            setState(prevState => ({ 
                ...prevState, 
                pageType: props.receivedData.pageType ? props.receivedData.pageType : '',
            }))
        }
    }, [props.receivedData])

    useEffect(() => {
        if(state.pageType === 'edit') {
            let obj = createInputs ? createInputs : {}
            let credits = obj.credits ? obj.credits : {}
            let project_credits = credits.credits
            let under_budget = credits.under_budget ? credits.under_budget : ''
            let over_budget = credits.over_budget ? credits.over_budget : ''
            setState(prevState => ({ ...prevState, project_credits, under_budget, over_budget }))
            
            if(credits.users && credits.users.length) {
                let userTotalCreidts = 0
                credits.users.forEach(usr => {
                    setState(prevState => ({ ...prevState, ['user_credit_'+usr.user_id]: usr.credits ? usr.credits : '' }))
                    userTotalCreidts += (usr.credits ? parseInt(usr.credits) : 0)
                })

                setState(prevState => ({ ...prevState, userTotalCreidts }))
            }
        }
    }, [state.pageType, createInputs])

    const handleCreditsChange = (type, field, index, value) => {
        let obj = createInputs || {};
        let credits = typeof obj.credits === 'object' && obj.credits !== null ? obj.credits : {};
        credits.project = creditSection ? creditSection.project : undefined;
        credits.credits = (type === 'project' && field === 'credits') ? value : (state.project_credits || '');

        // credits.over_budget = (type === 'project' && field === 'over_budget' ? value : (state.over_budget ? state.over_budget : ''))
        // credits.under_budget = (type === 'project' && field === 'under_budget' ? value : (state.under_budget ? state.under_budget : ''))
        let users = []
        state.projectUserList.forEach(usr => {
            let dataRow = {}
            dataRow.user_id = usr.userId
            dataRow.credits = (type === 'user' && usr.userId === index && field === 'credits' ? value : (state['user_credit_'+usr.userId] ? state['user_credit_'+usr.userId] : ''))

            if(dataRow.credits) {
                users.push(dataRow)
            }
        })
        credits.users = users
        obj.credits = credits

        dispatch(setHpcPropsDetails('createInputs', obj))
    } 

    useEffect(() => {
        if(creditSection && Object.entries(creditSection).length) {
            let params = {}
            params.provider = creditSection.provider
            params.project = creditSection.project
    
            dispatch(listUsersByCluster(params))
                .then((response) => {
                    setState(prevState => ({ ...prevState, projectUserList: response }));
                })
        }
    }, [dispatch, creditSection])

    const onChangeHandleValidation = (type, selectedItem, value) => {
        //initiative annual amount and total monthly amount check
        let hasError = false
        let queues = []
        if(state.queues && state.queues.length) {
            state.queues.forEach(row => {
                let dataRow = {}
                dataRow.queue_name = row
                if(type === 'queue' && selectedItem === row) {
                    dataRow.budget = Math.round(value)
                } else {
                    dataRow.budget = state['queue_'+state.fieldName+'_'+row] ? Math.round(state['queue_'+state.fieldName+'_'+row]) : 0
                }
                queues.push(dataRow)
            })
        }        

        let users = []
        if(state.clusterUserList && state.clusterUserList.length) {
            state.clusterUserList.forEach(row => {
                let dataRow = {}
                dataRow.user_id = row.userId
                if(type === 'user' && selectedItem === row.userId) {
                    dataRow.budget = Math.round(value)
                } else {
                    dataRow.budget = state['user_'+state.fieldName+'_'+row.userId] ? Math.round(state['user_'+state.fieldName+'_'+row.userId]) : 0
                }
                users.push(dataRow)
            })
        }

        let queueTotal = queues.reduce((sum, queue) => sum + queue.budget, 0);
        let userTotal = users.reduce((sum, user) => sum + user.budget, 0);
        
        setState(prevState => ({ ...prevState, queueTotal, userTotal }))

        let obj = createInputs ? createInputs : {}
        let details = obj.details ? obj.details : []

        if(details && details.length) {
            // let selectedProviderDetails = details.filter(e => e.provider === selectedAplliesTo.selectedProvider) && details.filter(e => e.provider === selectedAplliesTo.selectedProvider).length ? details.filter(e => e.provider === selectedAplliesTo.selectedProvider)[0] : {}
            
            // let appliesTo = selectedProviderDetails.applies_to ? selectedProviderDetails.applies_to : []
            details.forEach(det => {
                if(det.applies_to && det.applies_to.length) {
                    det.applies_to.forEach(row => {
                        if(row.account_id === selectedAplliesTo.selectedAccount && row.region === selectedAplliesTo.selectedRegion && row.cluster_name === selectedAplliesTo.selectedClusters) {
                            row.queues = queues
                            row.users = users
    
                            let queueError = ''
                            if(Math.round(selectedAplliesTo.budget) < queueTotal) {
                                queueError = 'Queue budget total should be less than the cluster budget '+row.budget
                            }
                            let userError = ''
                            if(Math.round(selectedAplliesTo.budget) < userTotal) {
                                userError = 'User budget total should be less than the cluster budget '+row.budget
                            }
    
                            setState(prevState => ({ ...prevState, queueError, userError }))
                        }
                    })
                }
            })
        }

        let credits = obj.credits ? obj.credits : {}
        let userTotalCreidts = 0
        let creditError = ''
        
        state.projectUserList && state.projectUserList.forEach(usr => {
            let credits = ((type === 'credits' && usr.userId === selectedItem) ? value : (state['user_credit_'+usr.userId] ? state['user_credit_'+usr.userId] : 0))
            userTotalCreidts += (credits ? parseInt(credits) : 0)
        })
        
        if(userTotalCreidts > credits.credits) {
            hasError = true
            creditError = 'Total user credits should not be greater then credits'
        }
        setState(prevState => ({ ...prevState, creditError, userTotalCreidts }))

        dispatch(setHpcPropsDetails('createInputs', obj))
        
        if(hasError) {
            dispatch(setHpcPropsDetails('onClickSave', 'checkForError'))
        } else {
            if(type === 'save') {
                dispatch(setHpcPropsDetails('onClickSave', 'save'))
            } else {
                dispatch(setHpcPropsDetails('onClickSave', 'checkForErrorHasNoError'))
            }
        }
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
	
	return (
        (state.selectedAplliesTo && state.selectedAplliesTo.selectedClusters) || creditSection ?
            <div className='border border-byzantineBlue bg-AzureishWhite-600 rounded-2xl p-6' onClick={(event) => handleChildClick(event, 'parent')}>
                {creditSection ?
                    <React.Fragment>
                    <div className='bg-white border border-DeepPurpleTints-600 rounded-2xl py-6 px-4'>
                        <div className='py-1 flex justify-between text-black'>
                            <p className="b-block text-NeutralGray-800 font-bold text-[22px]">Project</p>
                        </div>
                        <div className='flex flex-wrap p-2'>
                            <p className="b-block mb-0 text-NeutralGray-800 text-base font-medium mr-3">{createInputs.project}</p>
                            <Textbox
                                label={""}
                                selectedValue={state.project_credits ? state.project_credits : ''}
                                callback={(value) => {
                                    setState(prevState => ({ ...prevState, project_credits: value }))
                                    handleCreditsChange("project", "credits", "", value)
                                    // onChangeHandleValidation('credits', usr.userId, value)
                                }}
                                inputType={state.pageType}
                                placeholder={'credits'}
                                type={'number'}
                            />
                        </div>
                    </div>
                    <div className='bg-white border border-DeepPurpleTints-600 rounded-2xl py-6 px-4 mt-6'>
                        <div className='py-1 flex text-black'>
                            <p className="b-block text-NeutralGray-800 font-bold text-[22px]">User(s)</p>
                            <span className='ml-1 text-NeutralGray-400 text-[22px]'>(Total: {state.userTotalCreidts ? state.userTotalCreidts : 0})</span>
                        </div>
                        {state.creditError ?
                            <p className="b-block text-danger mb-1">{state.creditError}</p>
                        : null}
                        {state.projectUserList && state.projectUserList.length ?
                            state.projectUserList.map((usr, usrIndex) => {
                                return(
                                    <div className='full' key={'usr_'+usr.userId+'_'+usrIndex}>
                                        <div className='flex flex-wrap p-2'>
                                            <div className='lg:w-5/12 md:w-6/12 w-full self-center'>
                                                <div className='flex justify-start'>
                                                    <p className="b-block mb-0 text-NeutralGray-800 text-base font-medium">
                                                    {usr.shortName ? usr.shortName : (usr.userName ? usr.userName : '')}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='lg:w-4/12 md:w-4/12 w-full mb-2 pl-0'>
                                                <Textbox
                                                    label={''}
                                                    selectedValue={state['user_credit_'+usr.userId] ? state['user_credit_'+usr.userId] : ''}
                                                    callback={(value) => {
                                                        setState(prevState => ({ ...prevState, ['user_credit_'+usr.userId]: value}))
                                                        handleCreditsChange("user", "credits", usr.userId, value)
                                                        onChangeHandleValidation('credits', usr.userId, value)
                                                    }}
                                                    type={'number'}
                                                    inputType={state.pageType}
                                                    placeholder={'credits'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        : null}
                    </div>
                    </React.Fragment>
                : state.selectedAplliesTo && state.selectedAplliesTo.selectedClusters ?
                    <React.Fragment>
                    <div className='pb-6'>
                        <div className='flex flex-wrap text-base w-full'>
                            <div className='flex w-1/2 pr-3'>
                                <p className='ml-1 text-NeutralGray-600 mr-3'>Account: </p>
                                {selectedAplliesTo.selectedAccount ?
                                    <p className='text-black'>{selectedAplliesTo.selectedAccount}</p>
                                : null}
                            </div>
                            <div className='flex w-1/2 pl-3'>
                                <p className='ml-1 text-NeutralGray-600 mr-3'>Region: </p>
                                {selectedAplliesTo.selectedRegion ?
                                    <p className='text-black'>{selectedAplliesTo.selectedRegion}</p>
                                : null}
                            </div>
        
                            <div className='flex w-1/2 pr-3 mt-2'>
                                <p className='ml-1 text-NeutralGray-600 mr-3'>Cluster: </p>
                                {selectedAplliesTo.selectedClusters ?
                                    <p className='text-black'>{selectedAplliesTo.selectedClusters}</p>
                                : null}
                            </div>
                            
                            <div className='flex w-1/2 pl-3 mt-2'>
                                <p className='ml-1 text-NeutralGray-600 mr-3'>Budget Amount: </p>
                                {selectedAplliesTo.budget ?
                                    <p className='text-black'>{selectedAplliesTo.budget}</p>
                                : null}
                            </div>
                        </div>
                    </div>
                    <div className='bg-white border border-DeepPurpleTints-600 rounded-2xl py-6 px-4'>
                        <div className='py-1 flex justify-between text-black' onClick={() => setState(prevState => ({ ...prevState, selectedQueueSection: !state.selectedQueueSection }))}>
                            <p className="b-block text-NeutralGray-800 font-bold text-[22px]">Queue(s)</p>
                            <Icon icon={`icon-park-outline:${state.selectedQueueSection ? 'up' : 'down'}`} width={24} height={24} className='bg-DeepPurpleTints-600 h-6 w-6 text-white rounded-full cursor-pointer' />
                        </div>
                        <div className='flex w-full pb-2'>
                            Balance to be Allocated
                            <span className={`ml-2 text-${selectedAplliesTo.budget - (state.queueTotal ? state.queueTotal : 0) <= 0 ? 'danger' : 'info'}`}>
                                {selectedAplliesTo.budget ? thousandSeparator(selectedAplliesTo.budget - (state.queueTotal ? state.queueTotal : 0)) : 0}
                            </span>
                        </div>
                        {state.selectedQueueSection ?
                            <div className={`pt-6 border-t border-DeepPurpleTints-600`}>
                            {state.hasError && state.queueError ?
                                <p className="b-block text-danger mb-1">{state.queueError}</p>
                            : null}
                            {state.queues && state.queues.length ?
                                state.queues.map((queue, queueIndex) => {
                                    return(
                                        <div className='w-full' key={'queue_'+queueIndex}>
                                            <div className='flex flex-wrap p-2'>
                                                <div className='lg:w-5/12 md:w-6/12 w-full self-center'>
                                                    <div className='flex justify-start'>
                                                        <p className="b-block mb-0 text-NeutralGray-800 text-base font-medium">{queue}</p>
                                                    </div>
                                                </div>
                                                <div className='lg:w-7/12 md:w-6/12 w-full mb-2 pl-0'>
                                                    <Textbox
                                                        label={""}
                                                        selectedValue={state['queue_'+state.fieldName+'_'+queue] ? state['queue_'+state.fieldName+'_'+queue] : ''}
                                                        callback={(value) => {
                                                            // setState(prevState => ({ ...prevState, ['queue_'+state.fieldName+'_'+queue]: value }))
                                                            onChangeHandleValidation('queue', queue, value)
                                                        }}
                                                        inputType={state.pageType}
                                                        placeholder={'amount'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            : 
                                <div className='flex justify-center m-4 text-black'>
                                    <p>There are no queues for the selected applies to.</p>
                                </div>
                            }
                            </div>
                        : null}
                    </div>
                    <div className='bg-white border border-DeepPurpleTints-600 rounded-2xl py-6 px-4 mt-6'>
                        <div className='py-1 flex justify-between text-black' onClick={() => setState(prevState => ({ ...prevState, selectedUserSection: !state.selectedUserSection }))}>
                            <p className="b-block text-NeutralGray-800 font-bold text-[22px]">User(s)</p>
                            <Icon icon={`icon-park-outline:${state.selectedUserSection ? 'up' : 'down'}`} width={24} height={24} className='bg-DeepPurpleTints-600 h-6 w-6 text-white rounded-full cursor-pointer' />
                        </div>
                        <div className='flex w-full pb-2'>
                            Balance to be Allocated
                            <span className={`ml-2 text-${selectedAplliesTo.budget - (state.userTotal ? state.userTotal : 0) <= 0 ? 'danger' : 'info'}`}>
                                {selectedAplliesTo.budget ? thousandSeparator(selectedAplliesTo.budget - (state.userTotal ? state.userTotal : 0)) : 0}
                            </span>
                        </div>
                        {state.selectedUserSection ?
                            <div className={`pt-6 border-t border-DeepPurpleTints-600`}>
                            {state.hasError && state.userError ?
                                <p className="b-block text-danger mb-1">{state.userError}</p>
                            : null}
                            
                            <div className='flex justify-end mb-3'>
                                {state.selectedUserSection ?
                                    state.clusterUserList && state.clusterUserList.length ?
                                        <Search
                                            data={state.clusterUserList ? state.clusterUserList : []}
                                            topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                                            className={'rounded-full'}
                                            filteredData={(filterClusterUserList) => setState(prevState => ({ ...prevState, filterClusterUserList }))}
                                        />
                                    : null
                                : null}
                            </div>
                            {state.filterClusterUserList && state.filterClusterUserList.length ?
                                state.filterClusterUserList.map((user, userIndex) => {
                                    return(
                                        <div className='full' key={'users_'+state.selectedProvider+'_'+userIndex}>
                                            <div className='flex flex-wrap p-2'>
                                                <div className='lg:w-5/12 md:w-6/12 w-full self-center'>
                                                    <div className='flex justify-start'>
                                                        <p className="b-block mb-0 text-NeutralGray-800 text-base font-medium">
                                                            {user.shortName ? user.shortName : (user.userName ? user.userName : '')}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='lg:w-4/12 md:w-4/12 w-full mb-2 pl-0'>
                                                    <Textbox
                                                        label={''}
                                                        selectedValue={state['user_'+state.fieldName+'_'+user.userId] ? state['user_'+state.fieldName+'_'+user.userId] : ''}
                                                        callback={(value) => {
                                                            setState(prevState => ({ ...prevState, ['user_'+state.fieldName+'_'+user.userId]: value }))
                                                            onChangeHandleValidation('user', user.userId, value)
                                                        }}
                                                        inputType={state.pageType}
                                                        placeholder={'amount'}
                                                        type={'number'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) 
                            :
                                <div className='flex justify-center m-4'>
                                    <p>There are no queues for the selected applies to.</p>
                                </div>
                            }
                            </div>
                        : null}
                    </div>
                    </React.Fragment>
                : null}
            </div>
        : null
	)
}

export default RightSection