import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import MultiSelectSection from '../../common/MultiSelectSection';
import { subscribeCatalog } from '../../../actions/CatalogAction'
import { Store as CommonNotification } from 'react-notifications-component';
import { onlyNumeric, removeUnderScore } from '../../../utils/utility';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import { LoadingCircle } from '../../common/LoadingCiricle';
import SelectOption from '../../common/Form/SelectOption';

const RequestWorkstationSidePanel = ({ selectedCatalog, catalogTypes, catalogType,closeSidePanel }) => {
    const clickOut = useRef();
    
    const [state, setState] = useState({
        showLoading: false
    })
    const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    useEffect(() => {
        if(state.onRequest) {
            setState(prevState => ({ ...prevState, onRequest: false}))
            let hasError = false
            // if(!state.workstation_name || !state.selectedInstances || !Object.entries(state.selectedInstances).length) {
            //     hasError = true
            // }

            if(catalogTypes && catalogTypes[catalogType]) {
                catalogTypes[catalogType].forEach(int => {
                    if(!state[int.output_field]) {
                        hasError = true
                    }
                })
            }


            setState(prevState => ({ ...prevState, hasError, saveLoading: !hasError ? true : false }))

            if(!hasError) {
                let params = selectedCatalog
                params.workstation_name = state.workstation_name
                if(state.additional_storage_size) {
                    params.additional_storage_size = state.additional_storage_size                    
                }
                params = {...params, ...state.selectedInstances }
                if(!params.description) {
                    delete params.description
                }

                // params.subscribed = false
                dispatch(subscribeCatalog(params))
                    .then((response) => {
                        setState(prevState => ({ ...prevState, saveLoading: false }))
                        let messageType = 'danger'
                        let message = response.message ? response.message : 'Error in submitting request'
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'Reqeust submitted successfully'
                        }

                        closeSidePanel()

                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    })
            }
        }

    }, [dispatch, state.onRequest, selectedCatalog, state.selectedInstances, state.workstation_name, closeSidePanel, state.additional_storage_size, catalogTypes, catalogType, state])

    const handleInputChange = (field, value) => {
        setState(prevState => ({ ...prevState, [field]: value }))
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-3/5 w-10/12' ref={clickOut}>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl '>Workstation Request</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="overflow-y-auto h-[calc(100vh-235px)] bg-white">
                            <div className='p-8'>
                                <p className='text-[22px] font-bold pb-4'>DETAILS</p>
                                {catalogTypes && catalogTypes[catalogType] ?
                                    catalogTypes[catalogType].map((int, index) => {
                                        return(
                                            <React.Fragment key={'init_'+index}>
                                            {int.type === 'text' ?
                                                <div className='flex pt-6'>
                                                    <div className='w-1/2'>
                                                        <p className='flex text-NeutralGray-800 text-base'>
                                                        {int.label} 
                                                            {state.hasError && !state[int.output_field] ?
                                                                <span className='text-red-600 pl-4'>required</span>
                                                            : null}
                                                        </p>
                                                        <div className='flex mt-2'>
                                                            <input
                                                                type='text'
                                                                placeholder={removeUnderScore(int.output_field)}
                                                                className="block w-full border border-NeutralGray-600 rounded-lg px-2.5 pt-3 pb-4 shadow" 
                                                                value={state[int.output_field] ? state[int.output_field] : ''}
                                                                onChange={e => handleInputChange(int.output_field, e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            : int.type === 'dropdown' ?
                                                <div className='flex pt-6'>
                                                    <div className='w-3/4'>
                                                        <div className='flex'>
                                                            <p className='flex text-NeutralGray-800 text-base'>
                                                            {int.label} 
                                                                {state.hasError && !state[int.output_field] ?
                                                                    <span className='text-red-600 pl-4'>required</span>
                                                                : null}
                                                            </p>
                                                        </div>
                                                        <SelectOption
                                                            label={""}
                                                            fields={["instance_type", "instance_type"]}
                                                            options={selectedCatalog.allowed_instance_types ? selectedCatalog.allowed_instance_types : []}
                                                            selectedValues={state.instance_type ? state.instance_type : ''}
                                                            callbackMultiSelect={(value) => {
                                                                setState(prevState => ({ ...prevState, instance_type: value, selectedInstances: selectedCatalog.allowed_instance_types.filter(e => e.instance_type === value).length ? selectedCatalog.allowed_instance_types.filter(e => e.instance_type === value)[0] : {} }))
                                                                if(value) {
                                                                    handleInputChange(int.output_field, value)
                                                                }
                                                            }}
                                                            singleSelection={true}
                                                            dropdownWidth={'min-w-32'}
                                                            classDetails={{border: "border border-NeutralGray-600", padding: "py-2.5 pr-4 pl-3", bg: ' ', rounded: 'rounded-lg' }}
                                                        />
                                                    </div>
                                                </div>
                                            : null}
                                            </React.Fragment>
                                        )
                                    })
                                : null}
                                {state.selectedInstances && state.selectedInstances.vcpus ?
                                    <div className='flex pt-6 text-base'>
                                        <p className='text-NeutralGray-600 pr-2'>vCPUs: </p>
                                        <p className='text-black'>{state.selectedInstances.vcpus}</p>
                                    </div>
                                : null}
                                {state.selectedInstances && state.selectedInstances.memory ?
                                    <div className='flex pt-6 text-base'>
                                        <p className='text-NeutralGray-600 pr-2'>Memory</p>
                                        <p className='text-black'>
                                            {state.selectedInstances.memory}
                                            {state.selectedInstances && state.selectedInstances.memory_unit ? 
                                                <span className='ml-1'>{state.selectedInstances.memory_unit}</span>
                                            : null}
                                        </p>
                                    </div>
                                : null}
                                {state.selectedInstances && selectedCatalog.storage_details && selectedCatalog.storage_details.storage_type ?
                                    <div className='flex pt-6 text-base'>
                                        <p className='text-NeutralGray-600 pr-2'>Storage Type</p>
                                        <p className='text-black'>
                                            {selectedCatalog.storage_details.storage_type}
                                        </p>
                                    </div>
                                : null}
                                {selectedCatalog && selectedCatalog.storage_details && selectedCatalog.storage_details.storage_size ?
                                    <div className='flex pt-6 text-base'>
                                        <p className='text-NeutralGray-600 pr-2'>Storage Size</p>
                                        <p className='text-black'>
                                            {selectedCatalog.storage_details.storage_size}
                                            {selectedCatalog && selectedCatalog.storage_details && selectedCatalog.storage_details.storage_unit ? 
                                                <span className='ml-1'>{selectedCatalog.storage_details.storage_unit}</span>
                                            : null}
                                        </p>
                                    </div>
                                : null}
                                {selectedCatalog && selectedCatalog.price_per_month ?
                                    <div className='flex pt-6 text-base'>
                                        <p className='text-NeutralGray-600 pr-'>Cost per Month</p>
                                        <p className='text-black'>
                                            {state.selectedInstances.price_per_month}
                                            {state.selectedInstances && state.selectedInstances.price_unit ?
                                                <span>({state.selectedInstances.price_unit})</span>
                                            : null}
                                        </p>
                                    </div>
                                : null}
                            </div>
                        </div>

                        {/* <div className='fixed bottom-0 py-10 '> */}
                            <div className={`flex justify-center py-10 bg-NeutralGray-600`}>
                                <button className={`flex border border-ferrariRed-600 py-2.5 px-[18px] bg-ferrariRed-600 text-white cursor-pointer rounded-md mr-[34px]`} onClick={ () => closeSidePanel()}>Cancel</button>
                                {state.saveLoading ?
                                    <button className='flex border border-SecondaryGreen-600 py-2.5 px-[18px] bg-SecondaryGreen-600 text-white rounded-md cursor-pointer'>
                                        <LoadingCircle />
                                        Requesting 
                                    </button>
                                : 
                                    <button className={`flex border border-SecondaryGreen-600 py-2.5 px-[18px] bg-SecondaryGreen-600 text-white rounded-md cursor-pointer`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, onRequest: true }))}>Request</button>
                                }
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestWorkstationSidePanel;