/*************************************************
 * Collider
 * @exports
 * @file utility.js
 * @author Rajasekar // on 19/06/2023
 * @copyright © 2019 Collider. All rights reserved.
*************************************************/
import moment from 'moment'

export let userSessionTimer
let sessionTime = 0
let hasReloaded = false


export function myLog(...message) {
	// console.log(...message)
}

export const refreshPage = () => {
	if(!hasReloaded) {
		hasReloaded = true
	}
	// window.location.reload(true);
}

export const dayOfThisWeek = () => {
	let dayofWeek = moment().isoWeekday() + 1
	if(dayofWeek === 8) {
		dayofWeek = 1
	}
	return '+'+dayofWeek +'d'
}
/**
 * To validate email is valid or not
 * @param {String} email
 */
export const validateEmail = email => {
	if(email !== undefined && email !== '') {
		// eslint-disable-next-line no-useless-escape
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(String(email).toLowerCase())
	} else {
		return ''
	}
}

export const validatePhone = (input) => {
	var pattern = new RegExp(/^[0-9\b]+$/);
	
	if (!pattern.test(input) || input.length < 9) {
		return false
	} else {
		return true
	}
}

export const validateVolumeName = (input) => {
	let string = input.replace(/[^a-zA-Z0-9_]{0,202}/g, '');
    if (string.length > 0 && !/^[a-zA-Z_]/.test(string[0])) {
        string = string.slice(1);
    }
    return string
}

export const validateMountDir = (input) => {
	let string = input.replace(/[^a-zA-Z0-9/]{0,202}/g, '');
    if (string.length > 0 && !/^[a-zA-Z/]/.test(string[0])) {
        string = string.slice(1);
    }
    return string
}

export const thousandSeparator = (value) => {
	if(value) {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}
	return value
}

// Format date as DD/MM/YYYY
export const formatDate = (value) => {
	// Remove non-digit characters
	const cleaned = value.replace(/\D/g, '');

	// Format to DD/MM/YYYY
	const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);

	if (match) {
	const formattedDate = [match[1], match[2], match[3]]
		.filter(Boolean) // Remove empty strings
		.join('-'); // Join parts with "/"
	return formattedDate;
	}

	return value;
};

/**
 * Change number format
 * @param {String} string
 */
export const numberFormater = string => {
	if(string !== undefined && string !== '') {
		if(parseInt(string) >= 10000 && parseInt(string) <= 99999) {
			return string.toString().slice(0,2) + 'k'	
		} else if(parseInt(string) >= 100000 && parseInt(string) <= 999999) {
			return string.toString().slice(0,3) + 'k'	
		} else if(parseInt(string) >= 1000000 && parseInt(string) <= 9999999) {
			return string.toString().slice(0,4) + 'k'	
		} else if(parseInt(string) >= 10000000 && parseInt(string) <= 99999999) {
			return string.toString().slice(0,5) + 'k'	
		} else {
			return string
		}
	} else {
		return ''
	}
}


/**
 * Change convert bytes format
 * @param {String} string
 */
export const convertBytes = (string, unit, valueType) => {
	let returnVal = ""
	if(string !== undefined && string !== '') {
		let int = parseInt(string).toString()
		if(unit === 'Bytes' || unit === 'Bytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'KB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'KB'
				}
			} else if(int.length > 5 && int.length <= 8) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'Mb'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'Mb'
				}
			} else if(int.length > 8 && int.length <= 12) {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2) + 'GB'
				}
			} else if(int.length > 12) {
				if(valueType === "round") { 
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'B'
				} else {
					returnVal = string + 'B'
				}
			}
		} else if(unit === 'Kilobytes' || unit === 'Kilobytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'Mb'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'Mb'
				}
			} else if(int.length > 5 && int.length <= 8) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'GB'
				}
			} else if(int.length > 8) {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2) + 'TB'
				}				
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'KB'
				} else {
					returnVal = string + 'KB'
				}
			}
		} else if(unit === 'Megabytes' || unit === 'Megabytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'GB'
				}
			} else if(int.length > 5) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'Mb'
				} else {
					returnVal = string + 'Mb'
				}
			}
		} else if(unit === 'Gigabytes' || unit === 'Gigabytes/Second') {
			if(int.length >= 3) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'GB'
				} else {
					returnVal = string + 'GB'
				}
			}
		} else {
			if(valueType === "round") { 
				returnVal = parseFloat(string).toFixed(0) + 'TB'
			} else {
				returnVal = string + 'TB'
			}
		}
	}
	return returnVal
}

/**
 * Change convert bytes format without unit
 * @param {String} string
 */
export const convertBytesWithoutUnit = (string, unit, valueType) => {
	let returnVal = ""
	if(string !== undefined && string !== '') {
		let int = parseInt(string).toString()
		if(unit === 'Bytes' || unit === 'Bytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0)
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2)
				}
			} else if(int.length > 5 && int.length <= 8) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0)
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2)
				}
			} else if(int.length > 8 && int.length <= 12) {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0)
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2)
				}
			} else if(int.length > 12) {
				if(valueType === "round") { 
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(0)
				} else {
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(2)
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0)
				} else {
					returnVal = string
				}
			}
		} else if(unit === 'Kilobytes' || unit === 'Kilobytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0)
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2)
				}
			} else if(int.length > 5 && int.length <= 8) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0)
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2)
				}
			} else if(int.length > 8) {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0)
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2)
				}				
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0)
				} else {
					returnVal = string
				}
			}
		} else if(unit === 'Megabytes' || unit === 'Megabytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0)
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2)
				}
			} else if(int.length > 5) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0)
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2)
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0)
				} else {
					returnVal = string
				}
			}
		} else if(unit === 'Gigabytes' || unit === 'Gigabytes/Second') {
			if(int.length >= 3) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0)
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2)
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0)
				} else {
					returnVal = string
				}
			}
		} else {
			if(valueType === "round") { 
				returnVal = parseFloat(string).toFixed(0)
			} else {
				returnVal = string
			}
		}
	}
	return returnVal
}

/**
 * Change Count format
 * @param {String} string
 */
export const countFormater = (string, valueType) => {	
	if(string !== undefined && string !== '') {
		// if(parseInt(string) > 99 && parseInt(string) <= 999) {
		// 	return parseFloat(string / 100).toFixed(2) + 'H'
		// } else 
		// string = parseInt(string)
		if(parseInt(string) >= 1000 && parseInt(string) < 1000000) {
			if(valueType === "round") {
				return parseFloat(string / 1000).toFixed(0) + 'K'
			} else {
				return parseFloat(string / 1000).toFixed(2) + 'K'
			}
		} else if(parseInt(string) >= 1000000 && parseInt(string) < 1000000000) {
			if(valueType === "round") {
				return parseFloat((string / 1000)/1000).toFixed(0) + 'M'
			} else {
				return parseFloat((string / 1000)/1000).toFixed(2) + 'M'
			}
		} else if(parseInt(string) >= 1000000000 && parseInt(string) <= 1000000000000) {
			if(valueType === "round") {
				return parseFloat(((string / 1000)/1000)/1000).toFixed(0) + 'B'
			} else {
				return parseFloat(((string / 1000)/1000)/1000).toFixed(2) + 'B'
			}
		} else if(parseInt(string) >= 1000000000000) {
			if(valueType === "round") {
				return parseFloat((((string / 1000)/1000)/1000)/1000).toFixed(0) + 'T'
			} else {
				return parseFloat((((string / 1000)/1000)/1000)/1000).toFixed(2) + 'T'
			}
		} else {
			if(valueType === "round") {
				return parseFloat(string).toFixed(0)
			} else {
				return parseFloat(string).toFixed(2)
			}
		}
	} else {
		return ''
	}
}
// export const countFormater = string => {	
// 	if(string !== undefined && string !== '') {
// 		// if(parseInt(string) > 99 && parseInt(string) <= 999) {
// 		// 	return parseFloat(string / 100).toFixed(2) + 'H'
// 		// } else 
// 		// string = parseInt(string)i
// 		let value = string.toFixed(2)
// 		let unit = ""
// 		if(parseInt(string) >= 1000 && parseInt(string) < 1000000) {
// 			value = parseFloat(string / 1000).toFixed(2)
// 			unit = 'K'
// 		} else if(parseInt(string) >= 1000000 && parseInt(string) < 1000000000) {
// 			value = parseFloat((string / 1000)/1000).toFixed(2)
// 			unit = 'M'
// 		} else if(parseInt(string) >= 1000000000 && parseInt(string) <= 1000000000000) {
// 			value = parseFloat(((string / 1000)/1000)/1000).toFixed(2)
// 			unit = 'B'
// 		} else if(parseInt(string) >= 1000000000000) {
// 			value = parseFloat((((string / 1000)/1000)/1000)/1000).toFixed(2)
// 			unit = 'T'
// 		}
// 		let val = value.split(".")
// 		if(val[1] && val[1] > 0) {
// 			return val + unit
// 		} else {
// 			return parseInt(val[0]) + unit
// 		}
// 	} else {
// 		return ''
// 	}
// }

export const countFormaterWithoutUnit = (string, valueType) => {	
	if(string !== undefined && string !== '') {
		// if(parseInt(string) > 99 && parseInt(string) <= 999) {
		// 	return parseFloat(string / 100).toFixed(2) + 'H'
		// } else 
		// string = parseInt(string)
		if(parseInt(string) >= 1000 && parseInt(string) < 1000000) {
			string = (string / 1000)
		} else if(parseInt(string) >= 1000000 && parseInt(string) < 1000000000) {
			string = ((string / 1000)/1000)
		} else if(parseInt(string) >= 1000000000 && parseInt(string) <= 1000000000000) {
			string = (((string / 1000)/1000)/1000)
		} else if(parseInt(string) >= 1000000000000) {
			string = ((((string / 1000)/1000)/1000)/1000)
		}
		if(valueType && valueType === "round") {
			return parseInt(string)
		} else {
			return parseFloat(string).toFixed(2)
		}
	} else {
		return ''
	}
}

/**
 * Change convert seconds format
 * @param {String} string
 */
export const convertSeconds = (string, unit, valueType) => {
	if(string !== undefined && string !== '') {
		if(unit === 'Seconds') {
			if(string < 1 && string > 0) {
				string =  string * 1000
				if(string < 0.01) {
					string =  string * 1000
					return string.toFixed(3) + 'ms'
				} else {
					if(string > 1) {
						return string.toFixed(2) + 'ms'
					} else {
						return string.toFixed(2) + 'ms'
					}
				}
			}
			if(string >= 60 && string < 3600) {
				return parseInt(string / 60) + 'M'
			} else if(string >= 3600 && string < 86400) {
				return parseFloat((string / 60)/60).toFixed(2) + 'H'
			} else if(string >= 86400) {
				return parseFloat(((string / 60)/60)/24) + 'D'
			} else {
				return string +'S'
			}
		} else if(unit === 'Microseconds') {
			if(string > 1000000) {
				string = (string /1000000)
				if(string >= 60 && string < 3600) {
					return parseInt(string / 60) + 'M'
				} else if(string >= 3600 && string < 86400) {
					return parseFloat((string / 60)/60).toFixed(2) + 'H'
				} else if(string >= 86400) {
					return parseFloat(((string / 60)/60)/24).toFixed(2) + 'D'
				} else {
					return string > 0 ? string.toFixed(1) : string + 'S'
				}
			} else {
				return string > 0 ? string.toFixed(2) : string + 'ms'
			}
		} else if(unit === 'Milliseconds') {
			if(string > 1000) {
				string = (string / 1000)
				if(string >= 60 && string < 3600) {
					return parseInt(string / 60) + 'M'
				} else if(string >= 3600 && string < 86400) {
					return parseFloat((string / 60)/60).toFixed(2) + 'H'
				} else if(string >= 86400) {
					return parseFloat(((string / 60)/60)/24).toFixed(2) + 'D'
				} else {
					return string > 0 ? string.toFixed(1) : string + 'S'
				}
			} else {
				return string > 0 ? string.toFixed(2) : string + 'ms'
			}
		}
	}
}

export const convertSecondsWithoutUnit = (string, unit) => {
	if(string !== undefined && string !== '') {
		//let int = parseInt(string).toString()
		if(unit === 'Seconds') {
			if(string < 1 && string > 0) {
				string =  string * 1000
				if(string < 0.01) {
					string =  string * 1000
					return string.toFixed(2)
				} else {
					if(string > 1) {
						return string.toFixed(2)
					} else {
						return string.toFixed(2)
					}
				}
			}
			if(string >= 60 && string < 3600) {
				return parseInt(string / 60)
			} else if(string >= 3600 && string < 86400) {
				return parseFloat((string / 60)/60).toFixed(2)
			} else if(string >= 86400) {
				return parseFloat(((string / 60)/60)/24)
			} else {
				return string
			}
		} else if(unit === 'Microseconds') {
			string = (string /1000000)
			if(string >= 60 && string < 3600) {
				return parseInt(string / 60)
			} else if(string >= 3600 && string < 86400) {
				return parseFloat((string / 60)/60).toFixed(2)
			} else if(string >= 86400) {
				return parseFloat(((string / 60)/60)/24)
			} else {
				return string
			}
		} else if(unit === 'Milliseconds') {
			string = (string / 1000)
			if(string >= 60 && string < 3600) {
				return parseInt(string / 60)
			} else if(string >= 3600 && string < 86400) {
				return parseFloat((string / 60)/60).toFixed(2)
			} else if(string >= 86400) {
				return parseFloat(((string / 60)/60)/24)
			} else {
				return string
			}
		}
	}
}

/**
 * Change convert bits format
 * @param {String} string
 */
export const convertBits = (string, unit, valueType) => {
	let returnVal = ""
	if(string !== undefined && string !== '') {
		let int = parseInt(string).toString()
		if(unit === 'Bits') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'KB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'KB'
				}
			} else if(int.length > 5 && int.length <= 8) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'Mb'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'Mb'
				}
			} else if(int.length > 8 && int.length <= 12) {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2) + 'GB'
				}
			} else if(int.length > 12) {
				if(valueType === "round") { 
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'B'
				} else {
					returnVal = string + 'B'
				}
			}
		} else if(unit === 'Kilobits') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'Mb'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'Mb'
				}
			} else if(int.length > 5 && int.length <= 8) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'GB'
				}
			} else if(int.length > 8) {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'KB'
				} else {
					returnVal = string + 'KB'
				}
			}
		} else if(unit === 'Megabits') {
			if(int.length >= 3 && int.length <= 5) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'GB'
				}
			} else if(int.length > 5) {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'Mb'
				} else {
					returnVal = string + 'Mb'
				}
			}
		} else if(unit === 'Gigabits') {
			if(int.length >= 3) {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'TB'
				}
			} else {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'GB'
				} else {
					returnVal = string + 'GB'
				}
			}
		} else {
			if(valueType === "round") { 
				returnVal = parseFloat(string).toFixed(0) + 'TB'
			} else {
				returnVal = parseFloat(string).toFixed(2) + 'TB'
			}
		}
	}	
	return returnVal
}

export const convertBitsWithoutUnit = (string, unit) => {
	if(string !== undefined && string !== '') {
		let int = parseInt(string).toString()
		if(unit === 'Bits') {
			if(int.length >= 3 && int.length <= 5) {
				return parseFloat(string / 1024).toFixed(2)
			} else if(int.length > 5 && int.length <= 8) {
				return parseFloat((string / 1024)/1024).toFixed(2)
			} else if(int.length > 8 && int.length <= 12) {
				return parseFloat(((string / 1024)/1024)/1024).toFixed(2)
			} else if(int.length > 12) {
				return parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(2)
			} else {
				return string
			}
		} else if(unit === 'Kilobits') {
			if(int.length >= 3 && int.length <= 5) {
				return parseFloat(string / 1024).toFixed(2)
			} else if(int.length > 5 && int.length <= 8) {
				return parseFloat((string / 1024)/1024).toFixed(2)
			} else if(int.length > 8) {
				return parseFloat(((string / 1024)/1024)/1024).toFixed(2)
			} else {
				return string
			}
		} else if(unit === 'Megabits') {
			if(int.length >= 3 && int.length <= 5) {
				return parseFloat(string / 1024).toFixed(2)
			} else if(int.length > 5) {
				return parseFloat((string / 1024)/1024).toFixed(2)
			} else {
				return string
			}
		} else if(unit === 'Gigabits') {
			if(int.length >= 3) {
				return parseFloat(string / 1024).toFixed(2)
			} else {
				return string
			}
		} else {
			return parseFloat(string).toFixed(2)
		}
	} else {
		return ''
	}
}

export const getConvertedBytesUnit = (string, unit, valueType, retrunOnlyUnit) => {
	let returnVal = ""
	if(string !== undefined && string !== '') {
		let int = parseInt(string).toString()
		if(unit === 'Bytes' || unit === 'Bytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				returnVal = 'KB'
			} else if(int.length > 5 && int.length <= 8) {
				returnVal = 'Mb'
			} else if(int.length > 8 && int.length <= 12) {
				returnVal = 'GB'
			} else if(int.length > 12) {
				returnVal = 'TB'
			} else {
				returnVal = 'B'
			}
		} else if(unit === 'Kilobytes' || unit === 'Kilobytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				returnVal = 'Mb'
			} else if(int.length > 5 && int.length <= 8) {
				returnVal = 'GB'
			} else if(int.length > 8) {
				returnVal = 'TB'
			} else {
				returnVal = 'kb'
			}
		} else if(unit === 'Megabytes' || unit === 'Megabytes/Second') {
			if(int.length >= 3 && int.length <= 5) {
				returnVal = 'GB'
			} else if(int.length > 5) {
				returnVal = 'TB'
			} else {
				returnVal = 'Mb'
			}
		} else if(unit === 'Gigabytes' || unit === 'Gigabytes/Second') {
			if(int.length >= 3) {
				returnVal = 'TB'
			} else {
				returnVal = 'GB'
			}
		} else {
			returnVal = 'TB'
		}
	}
	return returnVal
}

export const convertBytesTo = (string, unit, valueType, convertTo) => {
	let returnVal = ""
	if(string !== undefined && string !== '') {
		if(unit === 'Bytes' || unit === 'Bytes/Second') {
			if(convertTo === "B") {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'B'
				} else {
					returnVal = string + 'B'
				}
			} else if(convertTo === "KB") {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'KB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'KB'
				}				
			} else if(convertTo === "Mb") {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'Mb'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'Mb'
				}
			} else if(convertTo === "GB") {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2) + 'GB'
				}
			} else if(convertTo === "TB") {
				if(valueType === "round") { 
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(2) + 'TB'
				}
			}
		} else if(unit === 'Kilobytes' || unit === 'Kilobytes/Second') {
			if(convertTo === "KB") {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'KB'
				} else {
					returnVal = string + 'KB'
				}
			} else if(convertTo === "Mb") {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'Mb'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'Mb'
				}				
			} else if(convertTo === "GB") {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'GB'
				}
			} else if(convertTo === "TB") {
				if(valueType === "round") { 
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat(((string / 1024)/1024)/1024).toFixed(2) + 'TB'
				}
			} else if(convertTo === "TB") {
				if(valueType === "round") { 
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((((string / 1024)/1024)/1024)/1024).toFixed(2) + 'TB'
				}
			} 
		} else if(unit === 'Megabytes' || unit === 'Megabytes/Second') {
			if(convertTo === "Mb") {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'Mb'
				} else {
					returnVal = string + 'Mb'
				}
			} else if(convertTo === "GB") {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'GB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'GB'
				}				
			} else if(convertTo === "TB") {
				if(valueType === "round") { 
					returnVal = parseFloat((string / 1024)/1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat((string / 1024)/1024).toFixed(2) + 'TB'
				}
			}
		} else if(unit === 'Gigabytes' || unit === 'Gigabytes/Second') {
			if(convertTo === "GB") {
				if(valueType === "round") { 
					returnVal = parseFloat(string).toFixed(0) + 'GB'
				} else {
					returnVal = string + 'Mb'
				}
			} else if(convertTo === "TB") {
				if(valueType === "round") { 
					returnVal = parseFloat(string / 1024).toFixed(0) + 'TB'
				} else {
					returnVal = parseFloat(string / 1024).toFixed(2) + 'TB'
				}				
			}
		} else {
			if(valueType === "round") { 
				returnVal = parseFloat(string).toFixed(0) + 'TB'
			} else {
				returnVal = string + 'TB'
			}
		}
	}
	return returnVal
}

/**
 * Change first letter to capital
 * @param {String} string
 */
export const capitalizeFirstLetter = string => {
	if(string !== undefined && string !== '') {
		return string.charAt(0).toUpperCase() + string.slice(1)
	} else {
		return ''
	}
}

export const capitalizeTheFirstLetterOfEachWord = string => {
	var seperatedString = string.toLowerCase().split(' ');
	for (var i = 0; i < seperatedString.length; i++) {
	   seperatedString[i] = seperatedString[i].charAt(0).toUpperCase() +
	   seperatedString[i].substring(1);
	}
	return seperatedString.join(' ');
}

export const convertToLowerCase = string => {
	if(Array.isArray(string)) {
		if(string.length > 0) {
			string = string[0]
		} else {
			string = ""
		}
	}
	if(string) {
		return string.toLowerCase()
	} else {
		return string
	}
}

export const capitalizeAllLetter = string => {
	if(string !== undefined && string !== '') {
		return string.toUpperCase()
	} else {
		return ''
	}
}

export const removeSpacebtwString = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/ +/g, "");
	} else {
		return ''
	}
}

// let text = name.replace(/[${}]/g,'')

export const onlyNumericSemiColon = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^0-9:]/gi,'');
	} else {
		return ''
	}
}

export const onlyNumeric = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^0-9]/gi,'');
	} else {
		return ''
	}
}

export const onlyNumber = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^0-9.]/gi,'');
	} else {
		return ''
	}
}

export const restrictCharacter = (string, restrictCharacter) => {
	if(string !== undefined && string !== '') {
		return string.replace(/[/]/gi,'');
	} else {
		return ''
	}
}

export const onlyNumericWithNegative = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^0-9-]/gi,'');
	} else {
		return ''
	}
}

export const removeUnderScore = string => {
	if(string !== undefined && string !== '') {
		string = string.replace(/[_]/gi,' ');

		let split = string.split(' ')
		let camelCaseString = ''
		split.forEach((item, i) => {
			camelCaseString += i === 0 ? ''+ item.charAt(0).toUpperCase() + item.slice(1) : ' '+ item.charAt(0).toUpperCase() + item.slice(1) 
		})

		return camelCaseString
	} else {
		return ''
	}
}

export const splitString = (string, splitter) => {
	if(string !== undefined && string !== '') {
		let split = string.split(splitter)
		return split
	} else {
		return ''
	}
}

export const alphaNumeric = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^a-zA-Z0-9]/gi,'');
	} else {
		return ''
	}
}

export const validateAmiId = string => {
	if(string !== undefined && string !== '') {
		return string.replace(/[^a-zA-Z0-9-]/gi,'');
	} else {
		return ''
	}
}

export const isNumber = string =>  { 
	return /^-?[\d.]+(?:e-?\d+)?$/.test(string); 
}

export const validateClusterName = string => {
    if (string !== undefined && string !== '' && string.length <= 40) {
		return string.replace(/[^a-zA-Z0-9-]/gi,'');
    } else {
        return '';
    }
};

/**
 * remove string
 */
export const getDayFromSelectedDuration = string => {
	if(string !== undefined && string !== '' ) {
		return string.replace(/[+dh]/g,'')
	} else {
		return ''
	}
}

export const currentUTCtime = () => {
	let currentTIme = moment()
	return moment.utc(currentTIme).format('YYYY-MM-DD HH:mm:ss')
}

export const currentLocaltime = () => {
	let currentTIme = moment()
	return moment(currentTIme).format('YYYY-MM-DD HH:mm:ss')
	// return moment.utc(currentTIme)local().format('YYYY-MM-DD HH:mm:ss')
}

export const previousDay = () => {
	return true
	// const today = moment();
	// const disableFutureDt = current => {
	//   return current.isBefore(today)
	// }
}

export const momentDateTime = dateTime => {	
	// var date = new Date(dateTime +' UTC');
	// dateTime = date.toString()
	if(dateTime !== undefined && dateTime !== '' ) {
		var stillUtc = moment.utc(dateTime).toDate();
		return moment(stillUtc).local().format('DD MMM YYYY HH:mm')
	} else {
		return ''
	}
}

export const momentDateTimeSec = (date, format) => {	
	// var date = new Date(dateTime +' UTC');
	// dateTime = date.toString()
	if(date !== undefined && date !== '' ) {
		var stillUtc = moment.utc(date).toDate();
		return moment(stillUtc).local().format(format)
	} else {
		return ''
	}
}

export const momentDateFormat = (date, format) => {	
	// var date = new Date(dateTime +' UTC');
	// dateTime = date.toString()
	if(date !== undefined && date !== '' ) {
		return moment(date).local().format(format)
	} else {
		return ''
	}
}

export const momentDateGivenFormat = (date, format) => {	
	// var date = new Date(dateTime +' UTC');
	// dateTime = date.toString()
	if(date !== undefined && date !== '' ) {
		// var stillUtc = moment.utc(date).toDate();
		// return moment(stillUtc).local().format(format)

		return moment(date).format(format)
	} else {
		return ''
	}
}

export const momentConvertionUtcToLocalTime = (date, format) => {	
	if(date !== undefined && date !== '' ) {
		var stillUtc = moment.utc(date).toDate();
		return moment(stillUtc).local().format(format)
	} else {
		return ''
	}
}

export const momentSlashConvertionLocalToUtcTime = (date, format) => {	
	if(date !== undefined && date !== '' ) {
		let parsedDate = moment(date, 'DD/MM/YYYY HH:mm');
		// var stillUtc = moment.local(date).toDate();
		return parsedDate.utc().format(format)
	} else {
		return ''
	}
}

export const momentConvertionLocalToUtcTime = (date, format) => {	
	if(date !== undefined && date !== '' ) {
		// var stillUtc = moment.local(date).toDate();
		return moment(date).utc().format(format)
	} else {
		return ''
	}
}

export const momentTimeConvertionUtcToLocalTime = (date, format) => {	
	if(date !== undefined && date !== '' ) {
		var stillUtc = moment.utc(date,'HH:mm').toDate();
		return moment(stillUtc).local().format(format)
	} else {
		return ''
	}
}

export const momentTimeConvertionLocalToUtcTime = (date, format) => {	
	if(date !== undefined && date !== '' ) {
		return moment(date,'HH:mm').utc().format(format)
	} else {
		return ''
	}
}

export const momentTimeGivenFormat = (date, format) => {	
	if(date !== undefined && date !== '' ) {
		return moment(date,'HH:mm').format(format)
	} else {
		return ''
	}
}



export const momentDateFormatLocal = (date, format) => {	
	// var date = new Date(dateTime +' UTC');
	// dateTime = date.toString()
	if(date !== undefined && date !== '' ) {
		var stillUtc = moment.utc(date).toDate();
		return moment(stillUtc).local().format(format)
		//return moment(date).format(format)
	} else {
		return ''
	}
}

export const momentDate = (date, format) => {
	if(date !== undefined && date !== '' ) {
		var stillUtc = moment.utc(date).toDate();
		return moment(stillUtc).local().format(format)
	} else {
		return ''
	}
}


export const momentFullDateTime = dateTime => {
	if(dateTime !== undefined && dateTime !== '' ) {
		var date = new Date(dateTime +' UTC');
		date = date.toString()
		return date
	} else {
		return ''
	}
}

export const momentTime = dateTime => {
	if(dateTime !== undefined && dateTime !== '' ) {
		var stillUtc = moment.utc(dateTime).toDate();
		return moment(stillUtc).local().format('HH:mm')
	} else {
		return ''
	}
}

export const dayDiffrence = dateTime => {
	if(dateTime !== undefined && dateTime !== '' ) {
		let diffrence = ''
		let a = moment(moment().format('M/D/YYYY'), 'M/D/YYYY')
		let b = moment([ moment(dateTime).format('M/D/YYYY')], 'M/D/YYYY')
		diffrence = a.diff(b, 'days');

		return diffrence > 1 ? diffrence+' days' : diffrence+' day';
	} else {
		return ''
	}
}

export const getSelectedPeriod = (start_time, end_time) => {

	// let currentTIme = moment();
	let durationGap = ''
	if(start_time !== undefined && start_time !== '' && end_time !== undefined && end_time !== '' ) {
		let duration = ''
		// let diffrence = ''
		let sDate = moment([ moment(start_time).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
		let eDate = moment([ moment(end_time).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
		duration = eDate.diff(sDate, 'days');
		let durationHours = eDate.diff(sDate);

		let hourCalculation = calculateHour(durationHours)
		if(hourCalculation[0] === 0) {
			let hour = ''
			if(hourCalculation[1] > 0) {
				if(hourCalculation[1] > 1) {
					hour += hourCalculation[1] +' hours '
				} else {
					hour += hourCalculation[1] +' hour '
				}
			}
			
			let minutes = ''
			if(hourCalculation[2] > 0) {
				if(hourCalculation[2] > 1) {
					minutes += hourCalculation[2] +' minutes '
				} else {
					minutes += hourCalculation[2] +' minute '
				}
			}
			

			let seconds = ''
			if(hourCalculation[3] > 0) {
				if(hourCalculation[3] > 1) {
					seconds += hourCalculation[3] +' seconds '
				} else {
					seconds += hourCalculation[3] +' second '
				}
			}
			
			durationGap = [hour, minutes, seconds].join(' ')
		} else {

			let firstContent = calculateTimimg(duration)

			let month = ''
			if(firstContent.months > 0) {
				if(firstContent.months > 1) {
					month += firstContent.months +' Months '
				} else {
					month += firstContent.months +' Month '
				}
			}
	
			let week = ''
			if(firstContent.weeks > 0) {
				if(firstContent.weeks > 1) {
					week += firstContent.weeks +' Weeks '
				} else {
					week += firstContent.weeks +' Week '
				}
			}
	
			let day = ''
			if(firstContent.days > 0) {
				if(firstContent.days > 1) {
					day += firstContent.days +' Days '
				} else {
					day += firstContent.days +' Day '
				}
			}
			durationGap = [month, week, day].join(' ')	
		}
		
	}

	return durationGap;
}

export const getPastDays = (start_time, end_time) => {

	let currentTIme = moment();
	if(start_time !== undefined && start_time !== '' && end_time !== undefined && end_time !== '' ) {
		let duration = ''
		let diffrence = ''
		let sDate = moment([ moment(start_time).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
		let eDate = moment([ moment(end_time).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
		duration = eDate.diff(sDate, 'days');
		let durationHours = eDate.diff(sDate);

		let hourCalculation = calculateHour(durationHours)
		if(hourCalculation[0] === 0) {
			let hour = ''
			if(hourCalculation[1] > 0) {
				if(hourCalculation[1] > 1) {
					hour += hourCalculation[1] +' hours '
				} else {
					hour += hourCalculation[1] +' hour '
				}
			}
			
			let minutes = ''
			if(hourCalculation[2] > 0) {
				if(hourCalculation[2] > 1) {
					minutes += hourCalculation[2] +' minutes '
				} else {
					minutes += hourCalculation[2] +' minute '
				}
			}
			

			let seconds = ''
			if(hourCalculation[3] > 0) {
				if(hourCalculation[3] > 1) {
					seconds += hourCalculation[3] +' seconds '
				} else {
					seconds += hourCalculation[3] +' second '
				}
			}
			
			let durationGap = [hour, minutes, seconds].join(' ')

			// let cDate = moment([ moment(currentTIme).format('M/D/YYYY HH:mm:ss') ], 'M/D/YYYY HH:mm:ss')
			// diffrence = cDate.diff(eDate, 'hour');

			if(diffrence > 24) {
				duration = parseInt(diffrence / 24) 
				// let firstContent = calculateTimimg(duration)
				
				// let month = ''
				// if(firstContent.months > 0) {
				// 	if(firstContent.months > 1) {
				// 		month += firstContent.months +' Months '
				// 	} else {
				// 		month += firstContent.months +' Month '
				// 	}
				// }
		
				// let week = ''
				// if(firstContent.weeks > 0) {
				// 	if(firstContent.weeks > 1) {
				// 		week += firstContent.weeks +' Weeks '
				// 	} else {
				// 		week += firstContent.weeks +' Week '
				// 	}
				// }
		
				// let day = ''
				// if(firstContent.days > 0) {
				// 	if(firstContent.days > 1) {
				// 		day += firstContent.days +' Days '
				// 	} else {
				// 		day += firstContent.days +' Day '
				// 	}
				// }
				// let durationGap = [month, week, day].join(' ')
		
				let cDate = moment([ moment(currentTIme).format('M/D/YYYY') ], 'M/D/YYYY')
				diffrence = cDate.diff(sDate, 'days');
				diffrence = diffrence + 1
				return durationGap+', '+ (diffrence === 0 ? 'Current Day' : ' Before '+(diffrence > 1 ? diffrence+' days': diffrence+' day'));
			} else {
				return durationGap+', '+ (hour === '' ? 'Current Hour' : ' Before '+(diffrence > 1 ? diffrence+' hours': diffrence+' hour'));

			}
		} else {

			let firstContent = calculateTimimg(duration)
			
			let month = ''
			if(firstContent.months > 0) {
				if(firstContent.months > 1) {
					month += firstContent.months +' Months '
				} else {
					month += firstContent.months +' Month '
				}
			}
	
			let week = ''
			if(firstContent.weeks > 0) {
				if(firstContent.weeks > 1) {
					week += firstContent.weeks +' Weeks '
				} else {
					week += firstContent.weeks +' Week '
				}
			}
	
			let day = ''
			if(firstContent.days > 0) {
				if(firstContent.days > 1) {
					day += firstContent.days +' Days '
				} else {
					day += firstContent.days +' Day '
				}
			}
			let durationGap = [month, week, day].join(' ')

			let cDate = moment([ moment(currentTIme).format('M/D/YYYY') ], 'M/D/YYYY')
			diffrence = cDate.diff(eDate, 'days');
			
			return durationGap+', '+ (diffrence === 0 ? 'Current Day' : ' before '+(diffrence > 1 ? diffrence+' days': diffrence+' day'));
		}

	} else {
		return ''
	}
}

export const getPastDaysNew = (start_time, end_time) => {

	let currentTIme = moment();
	if(start_time !== undefined && start_time !== '' && end_time !== undefined && end_time !== '' ) {
		let duration = ''
		let diffrence = ''
		let sDate = moment([ moment(start_time).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
		let eDate = moment([ moment(end_time).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
		duration = eDate.diff(sDate, 'days');
		// duration = duration
		let durationHours = eDate.diff(sDate);
		let hourCalculation = calculateHour(durationHours)
		if(hourCalculation[0] === 0) {
			let hour = ''
			if(hourCalculation[1] > 0) {
				if(hourCalculation[1] > 1) {
					hour += hourCalculation[1] +' hours '
				} else {
					hour += hourCalculation[1] +' hour '
				}
			}
			
			let minutes = ''
			if(hourCalculation[2] > 0) {
				if(hourCalculation[2] > 1) {
					minutes += hourCalculation[2] +' minutes '
				} else {
					minutes += hourCalculation[2] +' minute '
				}
			}
			

			let seconds = ''
			if(hourCalculation[3] > 0) {
				if(hourCalculation[3] > 1) {
					seconds += hourCalculation[3] +' seconds '
				} else {
					seconds += hourCalculation[3] +' second '
				}
			}
			
			let durationGap = [hour, minutes, seconds].join(' ')

			// let cDate = moment([ moment(currentTIme).format('M/D/YYYY HH:mm:ss') ], 'M/D/YYYY HH:mm:ss')

			if(diffrence > 24) {
				duration = parseInt(diffrence / 24) 
				// let firstContent = calculateTimimg(duration)
		
				let cDate = moment([ moment(currentTIme).format('M/D/YYYY') ], 'M/D/YYYY')
				diffrence = cDate.diff(sDate, 'days');
				diffrence = diffrence + 1
				return durationGap+', '+ (diffrence === 0 ? 'Current Day' : ' Before '+(diffrence > 1 ? diffrence+' days': diffrence+' day'));
			} else {
				return durationGap+', '+ (hour === '' ? 'Current Hour' : ' Before '+(diffrence > 1 ? diffrence+' hours': diffrence+' hour'));

			}
		} else {
			let firstContent = calculateTimimg(duration)
			
			let month = ''
			if(firstContent.months > 0) {
				if(firstContent.months > 1) {
					month += firstContent.months +' Months '
				} else {
					month += firstContent.months +' Month '
				}
			}
	
			let week = ''
			if(firstContent.weeks > 0) {
				if(firstContent.weeks > 1) {
					week += firstContent.weeks +' Weeks '
				} else {
					week += firstContent.weeks +' Week '
				}
			}
	
			let day = ''
			if(firstContent.days > 0) {
				if(firstContent.days > 1) {
					day += firstContent.days +' Days '
				} else {
					day += firstContent.days +' Day '
				}
			}
			let durationGap = [month, week, day].join(' ')

			let cDate = moment([ moment(currentTIme).format('M/D/YYYY') ], 'M/D/YYYY')
			diffrence = cDate.diff(eDate, 'days');
			
			return durationGap+', '+ (diffrence === 0 ? 'Current Day' : ' before '+(diffrence > 1 ? diffrence+' days': diffrence+' day'));
		}

	} else {
		return ''
	}
}

export const getGivenPastDaysNew = (start_time, end_time, caseEndTime) => {

	if(start_time !== undefined && start_time !== '' && end_time !== undefined && end_time !== '' ) {
		let duration = ''
		let diffrence = ''
		let sDate = moment([ moment(start_time).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
		let eDate = moment([ moment(end_time).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
		let caseEDate = moment([ moment(caseEndTime).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
		duration = eDate.diff(sDate, 'days');
		// duration = duration
		let durationHours = eDate.diff(sDate);

		let hourCalculation = calculateHour(durationHours)
		if(hourCalculation[0] === 0) {
			let hour = ''
			if(hourCalculation[1] > 0) {
				if(hourCalculation[1] > 1) {
					hour += hourCalculation[1] +' hours '
				} else {
					hour += hourCalculation[1] +' hour '
				}
			}
			
			let minutes = ''
			if(hourCalculation[2] > 0) {
				if(hourCalculation[2] > 1) {
					minutes += hourCalculation[2] +' minutes '
				} else {
					minutes += hourCalculation[2] +' minute '
				}
			}
			

			let seconds = ''
			if(hourCalculation[3] > 0) {
				if(hourCalculation[3] > 1) {
					seconds += hourCalculation[3] +' seconds '
				} else {
					seconds += hourCalculation[3] +' second '
				}
			}
			
			let durationGap = [hour, minutes, seconds].join(' ')

			diffrence = caseEDate.diff(sDate, 'days');
			if(diffrence > 24) {
				duration = parseInt(diffrence / 24)
				diffrence = diffrence + 1
				return durationGap+', '+ (diffrence === 0 ? 'Case End Time' : ' Before '+(diffrence > 1 ? diffrence+' days': diffrence+' day'));
			} else {
				return durationGap+', '+ (hour === '' ? 'Case End Time' : ' Before '+(diffrence > 1 ? diffrence+' hours': diffrence+' hour'));

			}
		} else {
			let firstContent = calculateTimimg(duration)
			
			let month = ''
			if(firstContent.months > 0) {
				if(firstContent.months > 1) {
					month += firstContent.months +' Months '
				} else {
					month += firstContent.months +' Month '
				}
			}
	
			let week = ''
			if(firstContent.weeks > 0) {
				if(firstContent.weeks > 1) {
					week += firstContent.weeks +' Weeks '
				} else {
					week += firstContent.weeks +' Week '
				}
			}
	
			let day = ''
			if(firstContent.days > 0) {
				if(firstContent.days > 1) {
					day += firstContent.days +' Days '
				} else {
					day += firstContent.days +' Day '
				}
			}
			let durationGap = [month, week, day].join(' ')

			diffrence = caseEDate.diff(sDate, 'days');
			
			return durationGap+', '+ (diffrence === 0 ? 'Case End Time' : ' before '+(diffrence > 1 ? diffrence+' days': diffrence+' day'));
		}

	} else {
		return ''
	}
}

// export const getPastFutureDays = (start_time, end_time, caseEndTime) => {
// 	// let duration = ''
// 	// let diffrence = ''
// 	let sDate = moment([ moment(start_time).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
// 	let eDate = moment([ moment(end_time).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
// 	if(end_time !== caseEndTime) {
// 		// duration = caseEndTime.diff(end_time, 'days');
// 		// let durationHours = eDate.diff(sDate);
// 		// let hourCalculation = calculateHour(durationHours)
// 	}

// 	return true
// }

const calculateHour = (n, unit) => { //n in milliseconds
	if(unit !== "seconds") {
		n = n / 1000
	}
	// let month = n / (30 * 24 *)
	let day = n / (24 * 3600);
	n = n % (24 * 3600);
	let hour = n / 3600;
	n %= 3600;
	let minutes = n / 60;
	n %= 60;
	let seconds = n;
	// let data = parseInt(day) + " days " + parseInt(hour)+ " hours " + parseInt(minutes) +" minutes " + seconds + " seconds ";
	// let data = parseInt(day) + " " + "days " + parseInt(hour)
	// + " " + "hours " + parseInt(minutes) + " "
	// + "minutes " + seconds + " "
	// + "seconds ";

	return [parseInt(day), parseInt(hour), parseInt(minutes), parseInt(seconds)]
}

export const calculateMinutes = (startTime, endTime) => {

	startTime = new Date(startTime);
	endTime = new Date(endTime);

	var diffMs = (endTime - startTime); // milliseconds between now & Christmas

	// var diffMins = Math.round(((diffMs) % 3600000) / 60000); // minutes
	// let durationHours = eDate.diff(sDate);
	// n = n / 1000
	// let month = n / (30 * 24 *)
	// let day = n / (24 * 3600);
	// n = n % (24 * 3600);
	// let hour = n / 3600;
	// n %= 3600;
	let diffMins = diffMs / 60000 ;

	return diffMins
}

const calculateTimimg = d => {
	let months = 0, years = 0, days = 0, weeks = 0;
	while(d){
	   if(d >= 365){
		  years++;
		  d -= 365;
	   }else if(d >= 30){
		  months++;
		  d -= 30;
	   } else if(d >= 7){
		  weeks++;
		  d -= 7;
	   } else{
		  days++;
		  d--;
	   }
	};
	
	return {
	   years, months, weeks, days
	};
};


export const calculateSeconds = n => {
	let day = n / (24 * 3600);
	n = n % (24 * 3600);
	let hour = n / 3600;
	n %= 3600;
	let minutes = n / 60 ;
	n %= 60;
	let seconds = n;
	return [parseInt(day), parseInt(hour), parseInt(minutes), parseInt(seconds)]
}


export const twoDateTimeInterval = (start_time, end_time) => {
	let sDate = moment([ moment(start_time).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
	let eDate = moment([ moment(end_time).format('M/D/YYYY hh:mm A') ], 'M/D/YYYY hh:mm A')
	// let duration = eDate.diff(sDate, 'days');
	let durationHours = eDate.diff(sDate);

	let hourCalculation = calculateHour(durationHours)

	return hourCalculation;
}

export const twoDateDiffrence = (start_time, end_time) => {
	if(start_time !== undefined && start_time !== '' && end_time !== undefined && end_time !== '' ) {
		let diffrence = ''
		let a = moment([ moment(start_time).format('M/D/YYYY') ], 'M/D/YYYY')
		let b = moment([ moment(end_time).format('M/D/YYYY') ], 'M/D/YYYY')
		diffrence = a.diff(b, 'days');

		return diffrence;
	} else {
		return ''
	}
}

export const twoDateDiffrenceHoursMinutes = (start_time, end_time) => {
	if(end_time === 'currentTime') {
		// end_time = moment().format('YYYY-MM-DD HH:mm:ss')
		// let currentTIme = moment()
		end_time = moment.utc().format('YYYY-MM-DD HH:mm:ss')
	}
	if(start_time !== undefined && start_time !== '' && end_time !== undefined && end_time !== '' ) {
		let diffrence = ''
		var diff = moment.duration(moment(end_time).diff(moment(start_time)));
		// let diff = moment.duration(a.diff(b)).humanize()
		let hours = parseInt(diff.asHours())
		let minutes = diff.minutes()
		let seconds = diff.seconds()

		// var ms = moment(start_time,"DD/MM/YYYY HH:mm:ss").diff(moment(end_time,"DD/MM/YYYY HH:mm:ss"));
		// var d = moment.duration(ms);
		// var s = diffrence.format("hh:mm:ss");
		if(hours > 0) {
			diffrence = hours > 1 ? hours+' hours' : hours > 0 ? hours+' hour' : ''
		}

		if(minutes > 0) {
			let minutesText = minutes > 1 ? minutes+' minutes' : minutes > 0 ? minutes+' minute' : ''
			diffrence += ' '+minutesText
		} else if(diffrence !== '') {
			diffrence += ' 0 minute'
		}
		
		if(seconds > 0) {
			seconds = seconds > 1 ? seconds+' seconds' : seconds > 0 ? seconds+' second' : '0 second'
			diffrence += ' '+seconds
		} else if(diffrence === '') {
			diffrence = '0 second'
		}

		// diffrence = hours+''+hours !== '' ? minutes !== '' + minutes : '0 minute:'+''+seconds

		return diffrence;
	} else {
		return ''
	}
}

export const twoDateDiffrenceDayHoursMinutes = (start_time, end_time) => {
	if(end_time === 'currentTime') {
		// let currentTIme = moment()
		end_time = moment.utc().format('YYYY-MM-DD HH:mm:ss')
		// var stillUtc = moment.utc(end_time).toDate();
		// end_time = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
	}
	if(start_time !== undefined && start_time !== '' && end_time !== undefined && end_time !== '' ) {
		let diffrence = ''

		var diff = moment.duration(moment(end_time).diff(moment(start_time)));
		// let diff = moment.duration(a.diff(b)).humanize()
		let hours = parseInt(diff.asHours())
		let minutes = diff.minutes()
		let seconds = diff.seconds()
		let days = parseInt(hours / 24);
		hours = hours % 24;
		
		if(days > 0) {
			diffrence = days > 1 ? days+' Days,' : days === 1 ? days+' Day,' : ''
		}

		if(hours > 0) {
			let calHour = hours > 1 ? hours+' hours' : hours > 0 ? hours+' hour' : ''
			diffrence += ' '+calHour
		}

		if(minutes > 0) {
			if(hours > 0 && days < 1) {
				let minutesText = minutes > 1 ? (' and '+ minutes+' minutes') : minutes > 0 ? (' and '+minutes+' minute') : ''
				diffrence += ' '+minutesText
			} else if(days < 1) {
				let minutesText = minutes > 1 ? minutes+' minutes' : minutes > 0 ? minutes+' minute' : ''
				diffrence += ' '+minutesText
			}
		}

		if(days < 1 && hours < 1 && minutes < 1) {
			if(seconds > 0) {
				let secondsText = seconds > 1 ? seconds+' seconds' : seconds+' second'
				diffrence = secondsText
			} else {
				diffrence = '-'
			}
		}
		
		// if(seconds > 0 && (days < 1 && hours < 1)) {
		// 	seconds = seconds > 1 ? seconds+' seconds' : seconds > 0 ? seconds+' second' : '0 second'
		// 	diffrence += ' '+seconds
		// }

		// diffrence = hours+''+hours !== '' ? minutes !== '' + minutes : '0 minute:'+''+seconds
		
		return diffrence;
	} else {
		return ''
	}
}

export const calculteOpenHours = (input) => {
	let time = input
	let split = input.split(' ')
	if(split[1] === 'hours') {
		if(split[0] > 24) {
			let hour = split[0]
			let day = parseInt(hour / 24);
			hour = hour % 24;

			time = day + (day > 1 ?  " d " : ' d ');
			// time = day +"d "
			if(hour !== 0) {
				time += hour + (hour > 1 ?  " hrs" : ' hr');
				// time += hour + 'hours'
			}
		}
	}
	return time
}

export const getTimeDifference = (from, to, type) => {
	if(from !== undefined && from !== '' && to !== undefined && to !== '') {
		let diffrence = 0
		let a = moment([ moment(from).format('M/D/YYYY hh:mm')], 'M/D/YYYY hh:mm')
		let b = moment([ moment(to).format('M/D/YYYY hh:mm')], 'M/D/YYYY hh:mm')
		diffrence = a.diff(b, 'minutes');
		return diffrence > 1 ? (type === 'before' ? '-' : '+') + diffrence+' minutes' : (type === 'before' ? '-' : '+') + diffrence+' minute';
		//return diffrence > 1 ? (type === 'before' ? '-' : '+') + diffrence+ (diffrence > 60 ? parseInt(diffrence/ 60) +' hrs' :  +' minutes') : (type === 'before' ? '-' : '+') + diffrence+' minute';
	} else {
		return ''
	}
}

export const datestartEndTime = (date, type) => {
	if(type === 'start') {
		let start_time = moment(date).startOf('day');	
		return start_time.format('YYYY-MM-DD HH:mm:ss')
	} else {
		let end_time = moment(date).endOf('day');	
		return end_time.format('YYYY-MM-DD HH:mm:ss')
	}
}

export const addSubTime = (date, type, dayType, hour) => {
	let dateType = 'hour'
	// if(dayType === 'h') {
		
	// } else 
	if(dayType === 'd') {
		dateType = 'days'
	}
	let currentTIme = moment();
	if(type === 'sub') {
		let startDate = ''
		if(dateType === 'hour' && parseInt(hour) === 1 && date !== undefined) {
			let subTIme = moment(date).subtract(hour, dateType)		
			startDate = subTIme.startOf('hour');	
		} else {
			startDate = moment.utc(currentTIme)
			startDate = moment(startDate).subtract(hour, dateType)
		}
		return moment(startDate).format('YYYY-MM-DD HH:mm:ss')
	} else {
		let endDate = ''
		if(dateType === 'hour' && parseInt(hour) === 1 && date !== undefined) {
			hour = (parseInt(hour) + parseInt(1));
			let addTime = moment(date).add(hour, dateType)
			endDate = addTime.startOf('hour');	
		} else{
			endDate = moment.utc(currentTIme)
		}
		return moment(endDate).format('YYYY-MM-DD HH:mm:ss')
	}
}

export const currentStartofYear = () => {
	let data = moment().startOf('year').format('MM/DD/YYYY');
	return moment(data).local().format('YYYY-MM-DD')
	// return new Date(data).getTime()
}

export const addMonths = (date, days) => {
	let data = moment(date).add(days, 'months')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}

export const addDays = (date, days) => {
	let data = moment(date).add(days, 'days')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}
    
export const subDays = (date, days) => {
	var result = moment(date).subtract(days, 'days')
	return momentDateGivenFormat(result, 'YYYY-MM-DD HH:mm:ss')
}

export const addHours = (date, hour) => {
	let data = moment(date).add(hour, 'hour')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}   

export const subHours = (date, hours) => {
	var result = moment(date).subtract(hours, 'hour')
	return momentDateGivenFormat(result, 'YYYY-MM-DD HH:mm:ss')
}

export const addMinutes = (date, minutes) => {
	let data = moment(date).add(minutes, 'minutes')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}

export const subMinutes = (date, minutes) => {
	let data = moment(date).subtract(minutes, 'minutes')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}

export const addSeconds = (date, seconds) => {
	let data = moment(date).add(seconds, 'seconds')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}

export const addHourGetDate = (date, hour) => {
	let data = moment(date).add(hour, 'hour')
	return moment(data).local().format('YYYY-MM-DD HH:mm:ss')
	// return new Date(data).getTime()
}

export const addHourGetTime = (date, hour) => {
	let data = moment(date).add(hour, 'hour')
	return new Date(data).getTime()
}

    
export const getTimeLocal = (time) => {
	time = new Date(time).getTime()
	return time
}

export const stringLength = (string, length) => {
	if(string.length > length) {
		return string.substr(0, length) + '...'
	} else {
		return string
	}
}

/**
 * To validate password is valid or not
 * @param {String} password
 */
export const validatePassword = password => {
	if(password !== undefined && password !== '') {
		return password.length >= 8
	} else {
		return false;
	}
}

/**
 * Logout user
 */
export const logout = () => {	
	window.location.href = '/signin'
}
/**
 * Method to get account name from id
 */
export const getAccountNameFromId = (accountId, accountList) => {
	if(accountId !== undefined && accountList && accountList.length) {
		return accountList.filter(account => account.account_id === accountId).length
			? accountList.filter(account => account.account_id === accountId)[0].account_name
			: accountId
	} else {
		return  accountId
	}
}

export const getRegionName = (region, regionLIst) => {
	if(region !== undefined && regionLIst && regionLIst.length) {
		return regionLIst.filter(reg => reg.region === region).length
			? regionLIst.filter(reg => reg.region === region)[0].name
			: region
	} else {
		return region
	}
}
/**
 * Generate uuid
 */
export const generateUuid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		let r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8
		return v.toString(16)
	})
}

/**
 * shorten and split the user name
 */

export const splitterFirstPart = (name, spliter) => {
	if (name && name.length) {
		let split = name.split(spliter)
		return split[0];
	} else {
		return '-'
	}
}

export const trimmedSplitUserName1 = name => {
	if (name && name.length) {
		let test = name.split(':')[name.split(':').length - 1]
		return test.split('/')[0]
	} else {
		return '-'
	}
}

export const splitterSecondPart = (name, spliter) => {
	if (name && name.length) {
		let split = name.split(spliter)
		return ': '+split.slice(1).join(':')
	} else {
		return '-'
	}
}

export const trimmedSplitUserName2 = name => {
	if (name && name.length) {
		let test = name.split(':')[name.split(':').length - 1]
		let slash = test.split('/')
		return slash.slice(1).join('/');
	} else {
		return '-'
	}
}

export const convertToInt = string => {
	if (string !== '' && string !== undefined) {
		let split = string.split(',')
		let newString = ''
		split.forEach((item, i) => {
			newString += i === 0 ? ''+ parseInt(item) : ', ' + parseInt(item)
		})
		return newString+ ' bit'
	} else {
		return '-'
	}
}

export const MathRound = string => {
	if (string !== '' && string !== undefined) {
		return Math.round(string)
	} else {
		return '-'
	}
}

/**
 * Change convert seconds format
 * @param {String} string
 */
export const unitTimeConvertion = (string, unit) => {
	if(string !== undefined && string !== '' && unit !== undefined && unit !== '') {
		if(unit.toLowerCase() === 'seconds') {
			if(string < 1 && string > 0) {
				string =  string * 1000
				if(string < 0.01) {
					string =  string * 1000
					return string.toFixed(3) + ' ms'
				} else {
					if(string > 1) {
						return string.toFixed(2) + ' ms'
					} else {
						return string.toFixed(2) + ' ms'
					}
				}
			}
			if(string >= 60 && string < 3600) {
				return Math.round(string / 60) + ' m'
			} else if(string >= 3600) { //&& string < 86400
				return Math.round((string / 60)/60) + ' h'
			} else if(string >= 86400) {
				return Math.round(((string / 60)/60)/24) + ' d'
			} else {
				return string +'s'
			}
		} else if(unit === 'Microseconds') {
			if(string > 1000000) {
				string = (string /1000000)
				if(string >= 60 && string < 3600) {
					return parseInt(string / 60) + ' m'
				} else if(string >= 3600 && string < 86400) {
					return parseInt((string / 60)/60) + ' h'
				} else if(string >= 86400) {
					return parseInt(((string / 60)/60)/24) + ' h'
				} else {
					return string > 0 ? string.toFixed(1) : string + ' s'
				}
			} else {
				return string > 0 ? string.toFixed(2) : string + ' ms'
			}
		} else if(unit === 'Milliseconds') {
			if(string > 1000) {
				string = (string / 1000)
				if(string >= 60 && string < 3600) {
					return parseInt(string / 60) + ' m'
				} else if(string >= 3600 && string < 86400) {
					return parseInt((string / 60)/60) + ' h'
				} else if(string >= 86400) {
					return parseInt(((string / 60)/60)/24) + ' h'
				} else {
					return string > 0 ? string.toFixed(1) : string + ' s'
				}
			} else {
				return string > 0 ? string.toFixed(2) : string + ' ms'
			}
		}
	}
}

export const unitTimeConvertionDetail = (string, unit) => {
	if(string !== undefined && string !== '' && unit !== undefined && unit !== '') {
		if(unit.toLowerCase() === 'seconds') {
			if(string < 1 && string > 0) {
				string =  string * 1000
				if(string < 0.01) {
					string =  string * 1000
					return string.toFixed(3) + ' ms'
				} else {
					if(string > 1) {
						return string.toFixed(2) + ' ms'
					} else {
						return string.toFixed(2) + ' ms'
					}
				}
			}
			let hourCalculation = calculateHour(string, "seconds")
			
			let totalHours = 0
			if(hourCalculation[0] > 0) {
				totalHours = (24 * hourCalculation[0])
			}

			let hour = ""
			if(hourCalculation[1] > 0) {
				totalHours += hourCalculation[1]
				hour += totalHours +" h"
			}
			
			let minutes = ""
			if(hourCalculation[2] > 0) {
				minutes += hourCalculation[2] +" m "
			}

			let seconds = ""
			if(hourCalculation[3] > 0) {
				seconds += hourCalculation[3] +" s"
			}

			let returnData = ""
			if(hour !== "") {
				returnData += hour+" "		
			}
			if(minutes !== "") {
				returnData += minutes+" "
			}
			if(hour === "" && seconds !== "") {
				returnData += seconds
			}
			return returnData
		} else if(unit === 'Microseconds') {
			if(string > 1000000) {
				string = (string /1000000)
				if(string >= 60 && string < 3600) {
					return parseInt(string / 60) + ' m'
				} else if(string >= 3600 && string < 86400) {
					return parseInt((string / 60)/60) + ' h'
				} else if(string >= 86400) {
					return parseInt(((string / 60)/60)/24) + ' h'
				} else {
					return string > 0 ? string.toFixed(1) : string + ' s'
				}
			} else {
				return string > 0 ? string.toFixed(2) : string + ' ms'
			}
		} else if(unit === 'Milliseconds') {
			if(string > 1000) {
				string = (string / 1000)
				if(string >= 60 && string < 3600) {
					return parseInt(string / 60) + ' m'
				} else if(string >= 3600 && string < 86400) {
					return parseInt((string / 60)/60) + ' h'
				} else if(string >= 86400) {
					return parseInt(((string / 60)/60)/24) + ' h'
				} else {
					return string > 0 ? string.toFixed(1) : string + ' s'
				}
			} else {
				return string > 0 ? string.toFixed(2) : string + ' ms'
			}
		}
	}
}



/**
 * shorten the user name
 */
export const trimmedUserName = name => {
	if (name && name.length) {
		return name.split(':')[name.split(':').length - 1]
	} else {
		return '-'
	}
}

// export const redirectLogOut = (error) => {	
// 	if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
// 		// await.inital
// 		instance.loginRedirect()
// 	}
// }
/**
 * logout user when user is not active for 20 mins
 */
export const scheduleTokenRenewal = () => {
	sessionTime = 0
	userSessionTimer = setInterval(() => {
		sessionTime++
		if (sessionTime > 2400) {
			logout()
		}
	}, 1000)
}

export const debounce = (fn, time) => {
	let timer;
  
	return function exec(...args) {
	  const later = () => {
		clearTimeout(timer);
		fn(...args);
	  };
	  timer && clearTimeout(timer);
	  timer = setTimeout(later, time);
	};
}

export const isValidDateTime = (dateString) => {
	// Define a regular expression to match the expected date and time format
	const dateRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  
	// Check if the string matches the regular expression
	if (!dateRegex.test(dateString)) {
	  return false;
	}
  
	// Try creating a Date object from the string
	const date = new Date(dateString);
  
	// Check if the Date object represents a valid date and time
	return !isNaN(date.getTime());
  }
  

export const getStringBetween = (str, start, end) => {
	//exmaple str = "text {for the work} start = "{"  end = "}"
    let result = str.match(new RegExp(start + "(.*)" + end));

    return result;
}

export const convertTextToJson = (text) => {
	const lines = text.split('\n');
  
	const parseLine = (line) => {
	  const [key, value] = line.split(':');
	  return [key.trim(), value.trim()];
	};
  
	const buildObject = (lines) => {
	  const stack = [];
	  const json = {};
  
	  let currentObj = json;
  
	  for (let i = 0; i < lines.length; i++) {
		const line = lines[i];
		const indent = line.search(/\S/);
  
		if (indent === -1) {
		  continue;
		}
  
		const [key, value] = parseLine(line);
  
		if (indent === 0) {
		  currentObj[key] = value;
		} else {
		  while (indent < stack.length) {
			stack.pop();
		  }
  
		  const parentKey = stack[stack.length - 1];
		  currentObj = currentObj[parentKey];
  
		  currentObj[key] = {};
		  currentObj = currentObj[key];
  
		  stack.push(key);
		}
	  }
  
	  return json;
	};
  
	const json = buildObject(lines);
  
	return JSON.stringify(json, null, 2);
};

export const formatNumber = (number) => {
	if (Number.isInteger(number)) {
	  	return number; // Return integer as is
	} else {
	  	return number.toFixed(2); // Return with 2 decimal places
	}
}

export const noSpace = (text) => {
	return text.replace(/\s/g, '')
}

export const timeInput = (value) => {
	// Remove non-digit characters
	const cleaned = value.replace(/\D/g, '');

	// Format to DD/MM/YYYY
	const match = cleaned.match(/^(\d{0,2})(\d{0,2})$/);

	if (match) {
		const formattedDate = [match[1], match[2]]
			.filter(Boolean) // Remove empty strings
			.join(':'); // Join parts with "/"
		return formattedDate;
	}

	return value;
}

// const sessionTimeRenewal = () => {
// 	sessionTime = 0
// }

// const keyDownTextField = () => {
// 	if (isLoggedIn()) {
// 		sessionTimeRenewal()
// 	}
// }

// const keyDownEvent = () => {
// 	if (isLoggedIn()) {
// 		sessionTimeRenewal()
// 	}
// }

// const mouseMoveEvent = () => {	
// 	if (isLoggedIn()) {
// 		sessionTimeRenewal()
// 	}
// }

const offLineEvent = () => {
	console.log("check your internet")

	// let active_page = window.location.href
	// localStorage.setItem('active_page', active_page);
	
	// window.location.href = '/internet'
}

const onLineEvent = () => {
	// window.location.reload()
	// if(!localStorage.getItem('active_page')) {
	// } else {
	// 	window.location.href = localStorage.getItem('active_page')
	// }
}

// Listen to user keypress and reset timerconvertSeconds
// document.addEventListener('keypress', keyDownTextField, false)

// Listen to user keypress and reset timer
// document.addEventListener('keydown', keyDownEvent, false)

// Listen to user keypress and reset timer
// document.addEventListener('mousemove', mouseMoveEvent, false)

window.addEventListener('offline', offLineEvent, false)

window.addEventListener('online', onLineEvent, false)


