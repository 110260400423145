/*************************************************
 * Collider
 * @exports
 * @file AccountDetailSidePanel.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders} from '../../../actions/commonActionNew'
import { onboardAwsAccount } from '../../../actions/AccountAction'
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import { convertToLowerCase } from '../../../utils/utility';
// import { capitalizeFirstLetter } from '../../../utils/utility';


const AccountDetailSidePanel = ({ closeSidePanel, refreshApi, pageMode, AccountDetails }) => {
    const clickOut = useRef()

    const [state, setState] = useState({
        appliesMenu: [0],
        groupArray: [0],
        updateStrategies: ['drain', 'compute fleet stop', 'terminate', 'force update'],
        clusters: [],
        callAccountFilters: true,
        onLoad: true,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false)

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if(providers) {
            setState(prevState => ({
                ...prevState,
                providers: providers,
				selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""
            }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response }));
                    }
                })
        }
    }, [dispatch, providers]);

    useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let hasError = false
            let params = {}
            params.accounts_info = [{}]
            if(!state.account_id || !state.role_arn || !state.selectedModule) {
                hasError = true
            }
            // if(pageMode === 'Create') {
            //     if(!state.emailId || !validateEmail(state.emailId)) {
            //         hasError = true
            //     }
            //     params.emailId = state.emailId
            // }
            params.accounts_info[0].provider = convertToLowerCase(state.selectedProvider)
            params.accounts_info[0].account_id = state.account_id
            params.accounts_info[0].role_arn = state.role_arn
            if(state.selectedModule && state.selectedModule.length) {
                let modules = []
                state.selectedModule.forEach(module => {
                        modules.push(module)
    
                })
                params.accounts_info[0].modules = modules
            }
            // if(pageMode === 'Edit') {
            //     params.update_user_id = AccountDetails.userId
            // }

            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

           if(!hasError) {
                if(pageMode === 'Create') {
                    dispatch(onboardAwsAccount(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in saving Account'

                            if(response && response.status) {
                                refreshApi()
                                messageType = 'success'
                                message = response.message ? response.message : 'Account Created Successfully'

                                setTimeout(() => closeSidePanel(), 2000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }

                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
            
                            // setState(prevState => ({ ...prevState, createRespons: response }))
                        })
                } 
                // else {
                //     dispatch(updateAccount(params))
                //         .then((response) => {
                //             let messageType = 'danger'
                //             let message = response.message ? response.message : 'Error in updating Account'
                //             if(response && response.status) {
                //                 messageType = 'success'
                //                 message = response.message ? response.message : 'Account Updated Successfully'

                //                 refreshApi()
                //             }

                //             setTimeout(() => setState(prevState => ({ ...prevState, saveLoading: false })), 2000)
            
                //             // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
            
                //             CommonNotification.addNotification({
                //                 //title: 'Wonderful!',
                //                 message: message,
                //                 type: messageType,
                //                 insert: 'top',
                //                 container: 'top-center',
                //                 // animationIn: ['animate__animated', 'animate__fadeIn'],
                //                 // animationOut: ['animate__animated', 'animate__fadeOut'],
                //                 dismiss: {
                //                 duration: 5000,
                //                 onScreen: false,
                //                 pauseOnHover: true,
                //                 showIcon: true,
                //                 }
                //             });
                //         })
                // }
            }
        }
    }, [state.callSaveFunction, dispatch, pageMode, closeSidePanel, refreshApi, state, AccountDetails])    

    const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-1/2 bg-GhostWhite min-h-screen overflow-auto' ref={clickOut}>
                <div className='header-search bg-GhostWhite flex justify-between p-2'>
                    <div className='w-full'>
                        <div className='flex flex-wrap'>
                            <div className='text-black lg:w-1/4 w-full self-center'>
                                <span className='font-bold text-xl text-black'>Account Details</span>
                            </div>
                        </div>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                    <div className='m-2 overflow-auto'>
                        <div className="rounded bg-white p-3">
                            {AccountDetails && Object.keys(AccountDetails).length ?
                                <React.Fragment>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Account Id</span>
                                        <span className={`self-center text-black`}>{AccountDetails?.account_id ? AccountDetails.account_id : ""}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Account Name</span>
                                        <span className={`self-center text-black`}>{AccountDetails?.account_name ? AccountDetails.account_name : ""}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Provider</span>
                                        <span className={`self-center text-black`}>{AccountDetails?.provider ? AccountDetails.provider: ""}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Organization</span>
                                        <span className={`self-center text-black`}>{AccountDetails?.organization ? AccountDetails.organization :""}</span>
                                    </div>
                                    {AccountDetails?.billing_info?.bucket_name ?
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Billing bucket</span>
                                            <span className={`self-center text-black`}>{AccountDetails?.billing_info?.bucket_name ? AccountDetails.billing_info.bucket_name  : ""}</span>
                                        </div>
                                    : null}
                                    {AccountDetails?.log_info?.bucket_name ?
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Log Bucket</span>
                                            <span className={`self-center text-black`}>{AccountDetails?.log_info?.bucket_name ? AccountDetails.log_info.bucket_name  : ""}</span>
                                        </div>
                                    : null}
                                    {AccountDetails?.cluster_info?.bucket_name ?
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Cluster Bucket</span>
                                            <span className={`self-center text-black`}>{AccountDetails?.cluster_info?.bucket_name ? AccountDetails.cluster_info.bucket_name  : ""}</span>
                                        </div>
                                    : null}
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Role ARN</span>
                                        <span className={`self-center text-black`}>{AccountDetails?.key_metadata?.arn_value ? AccountDetails.key_metadata.arn_value  : ""}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Modules</span>
                                        <div className="flex flex-wrap gap-1">
                                        {AccountDetails?.modules ?
                                            AccountDetails.modules.map(module => {
                                                return(
                                                    <span className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{module}</span>
                                                )
                                            })
                                        : null}
                                        </div>
                                    </div>
                                </React.Fragment>
                            : null}
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default AccountDetailSidePanel