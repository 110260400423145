/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file ManageAccounts.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { getOnboardedAccountsDetails, deleteOnboardedAccount } from '../../../actions/AccountAction'
import {Icon} from "@iconify/react";
import { capitalizeAllLetter, capitalizeTheFirstLetterOfEachWord, currentLocaltime, getCommonInformationMessage, momentDateFormat, thousandSeparator } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'
import { Store as CommonNotification } from 'react-notifications-component';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import OnboardAccountSidePanel from './OnboardAccountSidePanel';
import OnboardClusterSidePanel from './OnboardClusterSidePanel';
import AccountDetailSidePanel from './AccountDetailSidePanel';
import PageLoading from '../../common/PageLoading';
import Button from '../../common/Form/Button';
import AlertConfirmation from '../../common/AlertConfirmation';

const ManageAccounts = () => {	
	const[state, setState] = useState({
		showLoading: true,
		selectedAccountDetails: {},
		selectedClusterDetails: {},
		actionsAllowed: []
	})

	const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const dispatch = useDispatch(); // Create a dispatch function\
    const location = useLocation();
	const currentPath = location.pathname;

	useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
					let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				} 
				// else {
				// 	if(row.action) {
				// 		actionsAllowed = row.action
				// 	}
				// }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

	useEffect(() => {
		setState(prevState => ({ ...prevState, callSearch: true }));
		setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 3000)
	}, [])

	useEffect(() => {
		if(state.callSearch) {
			setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {}
			dispatch(getOnboardedAccountsDetails(params))
				.then((response) => {
					if(response?.results) {
						setState(prevState => ({ ...prevState, listAccounts: response.results, filterListAccounts: response.results, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, listAccounts: [], filterListAccounts: [], showLoading: false }))
					}
				})
		}
	}, [state.callSearch, dispatch])

	useEffect(() => {
        let dataResult = state.filterListAccounts && state.filterListAccounts.length ? state.filterListAccounts : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
    }, [state.filterListAccounts])

    const deleteAccountFunction = () => {
        let item = state.deleteAccountDetails 
        let params = {}
		params.account_id = item.account_id
		dispatch(deleteOnboardedAccount(params))
			.then((response) => {
				if(response) {
					let messageType = 'danger'
					let message = response && response.message ? response.message : "Error in deleting"

					if(response.status) {
						messageType = 'success'
						// toggle()
						setTimeout(() => { setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, callSearch: true, confirmDetails: false }))}, 1000)
					}

					CommonNotification.addNotification({
						//title: 'Wonderful!',
						message: message,
						type: messageType,
						insert: 'top',
						container: 'top-center',
						// animationIn: ['animate__animated', 'animate__fadeIn'],
						// animationOut: ['animate__animated', 'animate__fadeOut'],
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					});
				}
			})

    }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	/**
	 * Renders cost comparison Landing page
	*/
	return (
		<div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>

              <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap py-2 bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Manage Accounts</p>
						<p className="text-white text-base">Manage list of accounts</p>
					</div>
					<div className="xl:col-span-6 col-span-12 self-center xl:mt-4">
						{state.allocationDetails && state.allocationDetails.length ?
							state.allocationDetails.map(row => {
								return(
									<div className='bg-GhostWhite border border-mediumDarkGray rounded-md px-5 py-3 mr-3'>
										<div className='flex justify-center'>
											<div className='w-fit-content'>
												<p className='text-black text-center mb-1 f14'>{capitalizeAllLetter(row.cloudProviderId)}&nbsp;Commitment<span className='ml-1 text-lightGray f14'>({momentDateFormat(currentLocaltime(), 'YYYY')})</span></p>
												<p className='text-center f18 mb-0 text-warning'>{thousandSeparator(row.allocation)} <span className='ml-1 text-lightGray f14'>GBP</span></p>
											</div>
										</div>
									</div>
								)
							})
						: null}
					</div>
				</div>
			</div>
			{state.showCreateAccountPanel ?
				<OnboardAccountSidePanel 
					closeSidePanel={() => setState(prevState => ({...prevState, showCreateAccountPanel: false }))}
					pageMode={state.pageMode}
					refreshApi={() => setState(prevState => ({...prevState, callSearch: true  }))}
					selectedAccountDetails={state.selectedAccountDetails}
				/>
			: null}
			{state.showAccountDetailSidePanel ?
				<AccountDetailSidePanel 
					closeSidePanel={() => setState(prevState => ({...prevState, showAccountDetailSidePanel: false }))}
					pageMode={state.pageMode}
					refreshApi={() => setState(prevState => ({...prevState, callSearch: true  }))}
					AccountDetails={state.AccountDetails}
				/>
			: null}
			{state.showOnboardClusterPanel ?
				<OnboardClusterSidePanel 
					closeSidePanel={() => setState(prevState => ({...prevState, showOnboardClusterPanel: false }))}
					pageMode={state.pageMode}
					selectedAccountId = {state.selectedAccountId}
					refreshApi={() => setState(prevState => ({...prevState, callSearch: true  }))}
					selectedClusterDetails={state.selectedClusterDetails}
					AccountDetails={state.AccountDetails}
				/>
			: null}

			<div>
				<p className='self-center text-black text-[28px] font-semibold'>Account List</p>
				<div className={`flex flex-wrap pb-6 justify-between`}> 
					<div className="flex lg:w-3/5 w-full text-sm">
						<p className="m-0 self-center pt-1">Showing {state.filterListAccounts && state.filterListAccounts.length} out of total {state.listAccounts && state.listAccounts.length} account(s)</p>
					</div>
					
					<div className="flex flex-wrap justify-end lg:w-2/5 w-full mb-n2">
						{state.listAccounts && state.listAccounts.length ?
							<div className='w-1/2'>
								<Search
									data={state.listAccounts ? state.listAccounts : []}
									topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
									className={'rounded-full'}
									filteredData={(filterListAccounts) => setState(prevState => ({ ...prevState, filterListAccounts }))}
								/>
								</div>
						: null}
						{state.actionsAllowed && state.actionsAllowed.includes('create') ?
							<Button
								classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-3', margin: 'mx-2', text: 'text-white text-base font-semibold', others: 'cursor-pointer text-center'}}
								label={'Onboard Account'}
								callback={() => setState(prevState => ({ ...prevState, showCreateAccountPanel: true, pageMode: 'Create' }))}
							/>
						: null}
						{state.listAccounts && state.listAccounts.length ?
							<CSVLink
								data={state.filterListAccounts ? state.filterListAccounts : []} 
								headers={state.tableHeaders ? state.tableHeaders : []}
								filename={'accounts-list.csv'}
								className={'self-center'}
								target='_blank'
							>
								<Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-8 w-8 px-1 rounded-full self-center cursor-pointer' width={24} height={24} />
							</CSVLink>
						: null}
					</div>
				</div>
			</div>

			<div className="w-full">
				<div className="relative overflow-x-auto rounded-t-lg shadow">
				{state.filterListAccounts && state.filterListAccounts.length ?
					<ResizeableDarkThemeTable
						columns={[
							{
								Header: 'Provider',
								accessor: 'provider',
								width: 300,
							},
							{
								Header: 'Account Id',
								accessor: 'account_id',
								Cell: cellInfo => (
									<div className="cursor-pointer">
										<div className='flex w-full text-info mr-2'
											onClick={() => setState(prevState => ({
												...prevState,
												pageMode: 'Create', 
												AccountDetails: cellInfo.row.original,
												selectedAccountId: cellInfo.row.original.account_id,
												showAccountDetailSidePanel: true
											}))}
										>{cellInfo.row.original.account_id}</div>
									</div>
								),
								width: 300,
							},
							{
								Header: 'Account Name',
								accessor: 'account_name',
								width: 300,
							},
							{
								Header: ' ',
								Cell: cellInfo => (
									<div className="flex justify-between cursor-pointer self-center">
										<div className='text-primaryPurple-600 mr-2'
											onClick={() => setState(prevState => ({
												...prevState,
												pageMode: 'Create', 
												selectedClusterDetails: cellInfo.row.original,
												AccountDetails: cellInfo.row.original,
												showOnboardClusterPanel: true
											}))}
										>Onboard Cluster </div>
										{state.actionsAllowed && state.actionsAllowed.includes('delete') ?
											<div className='text-ferrariRed-600 mr-2'
											onClick={() => {
												let obj = {
													message: 'Are you sure you want to delete the account' +(cellInfo.row.original.account_name ? ' "'+cellInfo.row.original.account_name+'"' : ''),
													buttonLabel: "Yes, I'm sure",
												}
												setState(prevState => ({ ...prevState, confirmDetails: obj, deleteModal: true, deleteAccountDetails: cellInfo.row.original }))
											}}>Remove</div>
										: null}
									</div>
								),
								width: 300
							}
						]}
						data={state.filterListAccounts}
						perPage={10}
                        selectedColor={'bg-backGroundGray'}
                        dashboard={true}
						showPaginationTop={true}
                        paginationColor={'text-black'}
						sortByColumn={''}
						riskTooltip={[0]}
						onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedListAccounts: tableRow }))}
						// tableSize={'table-sm'}
					/>
				: 
					<div className='flex justify-center m-4'>
						<p>{getCommonInformationMessage('accounts')}.</p>
					</div>
				}
				</div>
			</div>
			{state.confirmDetails && state.deleteModal ?
                <AlertConfirmation 
                    confirmDetails={state.confirmDetails}
                    confirmation={(deleteModal) => {
                        if(deleteModal) {
							deleteAccountFunction()  
						} else {
							setState(prevState => ({ ...prevState, deleteModal: false, confirmDetails: false,deleteAccountDetails: {} }))
						}                     }
                        // setState(prevState => ({ ...prevState, confirmDetails: false, deleteModal: false, deleteAccountDetails: {} }))
                    }
                />
            : null}
		</div>
	)
}

export default ManageAccounts