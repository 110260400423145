import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import _ from 'lodash'
import { listWorkstationResults, getCatalogFilters } from '../../../actions/CatalogAction'
import { numberFormater, getPaginationShowingDetails } from '../../../utils/utility'
import WorkstationSidePanel from './WorkstationSidePanel'
import {Icon} from "@iconify/react";
import { LoadingCircle } from '../../common/LoadingCiricle';
import MultiSelectSection from '../../common/MultiSelectSection';
import SelectOption from '../../common/Form/SelectOption';

let searchWorstation = null

const Summary = (props) => {

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        catalogType: props.selecedProvider && props.selecedProvider.toLowerCase(),
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion,
        emptyOptions: [],
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 10,
        catalogTypes: [],
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
    const receivedData = location.state;
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    // const locationData = location.state || false
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.workstation_action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].workstation_action
                    }
				} else if(row.workstation_action) {
                    actionsAllowed = row.workstation_action
                }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    const handleCatalogTypeChange = (value) => {
        setState(prevState => ({ ...prevState, showLoading: true, summmaryResponse: {}, listCatalogs: [], filteredCatalogs: [], token: '', catalogType: value, callListWorkstationResults: true }))
    }

    useEffect(() => {
        let params = {}
        if(state.selectedProvider && state.selectedProvider !== '') {
            params.provider = state.selectedProvider.toLowerCase()
        }        
        dispatch(getCatalogFilters(params))
            .then((response) => {
                if(response) {
                    let results = response.results ? response.results : []
                    let mappings = response.mappings ? response.mappings : []                    
                    setState(prevState => ({ ...prevState, catalogTypes: mappings, catalogResponse: results, catalogType: state.selectedProvider.toLowerCase(),  callListWorkstationResults: true }))
                }
            })
    }, [state.selectedProvider, dispatch])

    useEffect(() => {
        if(state.callListWorkstationResults) {
            setState(prevState => ({ ...prevState, callListWorkstationResults: false, showLoading: true }))
            let params = {}
            params.summary =true
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== "All")
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== "All")
            }
            
            if(props.subscribedRow && props.subscribedRow.workstation_id) {
                params.workstation_id = props.subscribedRow && props.subscribedRow.workstation_id
            }

            if(state.catalogType) {
                params.catalog_type = state.catalogType.toLowerCase()
            }

            if(state.searchText) {
                params.search_input = state.searchText
            }

            if(state.token === 'next') {
                params.next_token = state.summmaryResponse && state.summmaryResponse.next_token
            } else if(state.token === 'previous') {
                params.previous_token = state.summmaryResponse && state.summmaryResponse.previous_token
            } else if(state.token === 'current') {
                params.current_token = state.summmaryResponse && state.summmaryResponse.current_token
            }

            if(state.selectedStatus) {
                params.status = state.selectedStatus
            }

            if(state.sort_order) {
                params.sort_order = state.sort_order
                params.sort_by = state.sort_by
            }

            params.metrics = false
            params.size = state.perPage
    
            dispatch(listWorkstationResults(params))
                .then((response) => {
					if(response) {
                        
                        let results = response && response.results ? response.results : []

                        let json = response.workstation_details ? response.workstation_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])

                        setState(prevState => ({ ...prevState, summmaryResponse: response, statusSection: response.total_count ? response.total_count : {}, workstationList: results, filterdWorkStationList: results, totalRecords: response.total ? response.total : 0, selectedFilters: params, detailsFormat, showLoading: false, callFormGraph: true }));
                    }
                })
        }
    }, [state.callListWorkstationResults, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, receivedData, props, state.perPage, state.catalogType, state.searchText, state.selectedStatus, state.sort_by, state.sort_order, state.summmaryResponse, state.token])

    const getStatusColor = (status) => {        
        let color = 'border-2 border-darkPurple-600 text-darkPurple-600'
        if(status.toLowerCase() === 'approved') { color = 'border-2 border-green-600 text-green-600' } else 
        if(status.toLowerCase() === 'rejected') { color = 'border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'requested') { color = 'border-2 border-yellowOrange-600 text-yellowOrange-600' } else 
        if(status.toLowerCase() === 'running') { color = 'border-2 border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'border-2 border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'timeout') { color = 'border-2 border-yellowOrange-600 text-yellowOrange-600' }

        return color;
    }
    const getBgStatusColor = (status) => {        
        let color = 'border-2 border-darkPurple-600 text-white bg-darkPurple-600'
        if(status.toLowerCase() === 'approved') { color = 'border-2 border-green-600 text-white bg-green-600' } else 
        if(status.toLowerCase() === 'rejected') { color = 'border-2 border-red-600 text-white bg-red-600' } else 
        if(status.toLowerCase() === 'requested') { color = 'border-2 border-yellowOrange-600 text-white bg-yellowOrange-600' } else 
        if(status.toLowerCase() === 'running') { color = 'border-2 border-appleGreen-600 text-white bg-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'border-2 border-red-600 text-white bg-red-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'border-2 border-PrimaryLightOrange-600 text-white bg-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'timeout') { color = 'border-2 border-yellowOrange-600 text-white bg-yellowOrange-600' }

        return color;
    }

    const getStatusTextColor = (status) => {        
        let color = 'text-darkPurple-600'
        if(status.toLowerCase() === 'approved') { color = 'text-green-600' } else 
        if(status.toLowerCase() === 'rejected') { color = 'text-red-600' } else 
        if(status.toLowerCase() === 'requested') { color = 'text-yellowOrange-600' } else 
        if(status.toLowerCase() === 'running') { color = 'text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'text-red-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'timeout') { color = 'text-yellowOrange-600' }

        return color;
    }

    const getStatusBgColor = (status) => {        
        let color = 'bg-darkPurple-50 border-2 border-darkPurple-600 text-darkPurple-600'
        if(status.toLowerCase() === 'approved') { color = 'bg-green-50 border-2 border-green-600 text-green-600' } else 
        if(status.toLowerCase() === 'rejected') { color = 'bg-red-50 border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'requested') { color = 'bg-yellowOrange-50 border-2 border-yellowOrange-600 text-yellowOrange-600' } else 
        if(status.toLowerCase() === 'running') { color = 'bg-appleGreen-50 border-2 border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'bg-red-50 border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'bg-PrimaryLightOrange-50 border-2 border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'timeout') { color = 'bg-yellowOrange-50 border-2 border-yellowOrange-600 text-yellowOrange-600' }

        return color;
    }

    const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    }

    return (
        state.showLoading ?
            <div className='flex justify-center'>
                <LoadingCircle />
            </div>
        :
            <React.Fragment>
            {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <WorkstationSidePanel 
                    closeSidePanel={(string) => setState(prevState => ({...prevState, selectedDetails: {}, selectedAction: "", callListWorkstationResults: string === 'refresh' ? true : false, token: 'current', showLoading: false }))}
                    selectedDetails={state.selectedDetails} 
                    selectedAction={state.selectedAction}
                    detailsFormat={state.detailsFormat}
                />
            : null}
            <div className='p-2 overflow-auto' onClick={(event) => handleChildClick(event, 'parent')}>
                <p className='text-[22px] font-bold w-full'>Overview</p>
                <div className='flex flex-wrap pb-8 pt-4'>
                    {Object.entries(state.statusSection).map(([key, value], index) => {
                        return(
                            <div key={'key__'+index} className='mr-2'>
                                <div className={`rounded-xl h-16 w-32 px-3 border-l-8 ${state.selectedStatus === key ? getBgStatusColor(key) : getStatusColor(key)} cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, selectedStatus: state.selectedStatus !== key ? key : '', token: '', startRecord: 0, currentPage: 1, showLoading: true, callListWorkstationResults: true }))}>
                                    <label className='font-medium text-base w-full capitalize'>{key.toLowerCase()}</label>
                                    <p className={`text-[22px] font-semibold w-full ${state.selectedStatus === key ? 'text-white' : getStatusTextColor(key)}`}>{numberFormater(value)}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='flex flex-wrap justify-between border-t border-NeutralGray-500 pt-8'>
                    <p className='mb-0 text-sm self-center lg:w-1/2 w-full'>
                        Showing <span className='mx-1'>{getPaginationShowingDetails(state.filterdWorkStationList && state.filterdWorkStationList.length, state.totalRecords, state.currentPage, state.perPage)}</span> out of total {state.totalRecords} workstation(s)
                        {state.selectedFilters && state.selectedFilters.provider ?
                            <React.Fragment>
                            <span className='ml-1'>obtained from the</span>
                            <span className='ml-1'>{state.selectedFilters.provider.toUpperCase()}</span>
                            <span className='ml-1'>provider</span>
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.account_id && state.selectedFilters.account_id.length ?
                            <React.Fragment>
                            <span className='ml-1'>with the account</span>
                            <span className='ml-1'>{state.selectedFilters.account_id[0]}</span>
                            {state.selectedFilters.account_id.length > 1 ?
                                <span className='mr-2 text-blue-600'>more</span>
                            : null}
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.region && state.selectedFilters.region.length ?
                            <React.Fragment>
                            <span className='mx-1'>in the</span>
                            <span className='ml-1'>{state.selectedFilters.region[0]}&nbsp;</span>
                            {state.selectedFilters.region.length > 1 ?
                                <span className='ml-2 text-blue-600'>more</span>
                            : null}
                            <span className='mr-1'>region</span>
                            </React.Fragment>
                        : null}
                    </p>
                    <div className='flex justify-end lg:w-1/2 w-full'>
                        <SelectOption
                            label={""}
                            fields={["value", "label"]}
                            options={state.catalogTypes}
                            selectedValues={state.catalogType ? state.catalogType : ''}
                            callbackMultiSelect={(value) => {
                                handleCatalogTypeChange(value)
                            }}
                            singleSelection={true}
                            classDetails={{border: "border border-primaryPurple-600", padding: "py-2.5 pr-4 pl-3", bg: 'bg-lavender-200', rounded: 'rounded-full' }}
                        />
                        
                        <div class="ml-4 flex w-[394px] p-2.5 pl-3 pr-4 items-start border border-NeutralGray-600 bg-white text-lg rounded-full">
                            <span class="self-center ml-2">
                                <Icon icon="bitcoin-icons:search-outline" className="text-gray-500" width={24} height={24} />
                            </span>
                            <input
                                type="text"
                                placeholder='Search'
                                className="flex-1 bg-transparent outline-none self-center"
                                value={state.searchText}
                                onChange={e => {
                                    setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                    clearTimeout(searchWorstation)
                                    if(e.target.value.length > 2) {
                                        searchWorstation = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, token: '', currentPage: 1, summmaryResponse: {}, workstationList: [], showLoading: true, callListWorkstationResults: true }))}, 2000); 
                                    } else if(!e.target.value.length) {
                                        setState(prevState => ({ ...prevState, currentPage: 1, summmaryResponse: {}, workstationList: [], showLoading: true, token: '', callListWorkstationResults: true }))
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className='relative overflow-x-auto mt-3 rounded-t-lg shadow border border-lightGray-600'>
                    <table className="w-full text-left">
                        <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600 rounded-t-2xl text-base font-semibold text-NeutralGray-800'>
                            <tr className="flex-1 w-full">
                                <th className='tracking-wider py-2 pl-4 text-left' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'workstation_name' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'workstation_name', token: '', callListWorkstationResults: true }))}> 
                                    <span className='flex'>
                                        {state.sort_by === 'workstation_name' && (state.sort_order === 'desc' || state.sort_order === 'asc') ? 
                                            <Icon icon={`fa-solid:sort-${state.sort_order === 'asc' ? 'down' : 'up'}`} className={`self-center mr-2`} width="16" height="16" />
                                        : null}
                                        Name
                                    </span>
                                </th>
                                <th className='tracking-wider py-2 pl-4 text-left' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'status' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'status', token: '', callListWorkstationResults: true }))}>
                                    <span className='flex'>
                                        {state.sort_by === 'status' && (state.sort_order === 'desc' || state.sort_order === 'asc') ? 
                                            <Icon icon={`fa-solid:sort-${state.sort_order === 'asc' ? 'down' : 'up'}`} className={`self-center mr-2`} width="16" height="16" />
                                        : null}
                                        Status
                                    </span>
                                </th>
                                <th className='tracking-wider py-2 pl-4 text-left' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'instance_type' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'instance_type', token: '', callListWorkstationResults: true }))}>
                                    <span className='flex'>
                                        {state.sort_by === 'instance_type' && (state.sort_order === 'desc' || state.sort_order === 'asc') ? 
                                            <Icon icon={`fa-solid:sort-${state.sort_order === 'asc' ? 'down' : 'up'}`} className={`self-center mr-2`} width="16" height="16" />
                                        : null}
                                        Capacity
                                    </span>
                                </th>
                                <th className='tracking-wider py-2 pl-4 text-left' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'asset_name' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'asset_name', token: '', callListWorkstationResults: true }))}>
                                    <span className='flex'>
                                        {state.sort_by === 'asset_name' && (state.sort_order === 'desc' || state.sort_order === 'asc') ? 
                                            <Icon icon={`fa-solid:sort-${state.sort_order === 'asc' ? 'down' : 'up'}`} className={`self-center mr-2`} width="16" height="16" />
                                        : null}
                                        Asset
                                    </span>
                                </th>
                                <th className='tracking-wider py-2 pl-4 text-left' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'public_ip_address' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'public_ip_address', token: '', callListWorkstationResults: true }))}>
                                    <span className='flex'>
                                        {state.sort_by === 'public_ip_address' && (state.sort_order === 'desc' || state.sort_order === 'asc') ? 
                                            <Icon icon={`fa-solid:sort-${state.sort_order === 'asc' ? 'down' : 'up'}`} className={`self-center mr-2`} width="16" height="16" />
                                        : null}
                                        Public IP / Private Ip
                                    </span>
                                </th>
                                {/* <th className='f16'>Storage</th> */}
                                <th className='tracking-wider py-2 pl-4 text-left' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'user_name' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'user_name', token: '', callListWorkstationResults: true }))}>
                                    <span className='flex'>
                                        {state.sort_by === 'user_name' && (state.sort_order === 'desc' || state.sort_order === 'asc') ? 
                                            <Icon icon={`fa-solid:sort-${state.sort_order === 'asc' ? 'down' : 'up'}`} className={`self-center mr-2`} width="16" height="16" />
                                        : null}
                                        User
                                    </span>
                                </th>
                                <th className='tracking-wider py-2 pl-4 text-left'>Action</th>
                            </tr>
                        </thead>
                        <tbody className='text-sm text-NeutralGray-900 '>
                            {state.filterdWorkStationList && state.filterdWorkStationList.length ?
                                state.filterdWorkStationList.slice(state.startRecord, state.startRecord + state.perPage).map((row, index)=> {
                                    return(
                                        <tr key={"head1_"+index} className={`${index     % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
                                            <td className="py-2.5 pl-4 text-left ">{row.workstation_name}</td>
                                            <td className="py-2.5 pl-4 text-left ">
                                                <span className={`rounded-full px-2 py-1 ${getStatusBgColor(row.status)}`}>{row.status}</span>
                                            </td>
                                            <td className="py-2.5 pl-4 text-left ">
                                                {row.instance_type}
                                                <div className='flex'>
                                                    ({row.gpu ? <span>{row.gpu+" GPU, "}</span> : null}
                                                    <span className='ml-1'>{row.vcpus} vCPU,</span>
                                                    <span className='ml-1'>{row.memory} {row.memory_unit ? row.memory_unit : 'GB'} </span>)
                                                </div>                                                
                                                {row.volume_details && row.volume_details.length && row.volume_details[0].volume_size ?
                                                    <span className=''>Storage {row.volume_details[0].volume_size} GB</span>
                                                : null}
                                            </td>
                                            <td className="py-2.5 pl-4 text-left ">
                                                <span className='text-primaryPurple-600'>{(row.id_name_map && !row.id_name_map.key && row.id_name_map.value ? row.id_name_map.value : "")}
                                                    {row.id_name_map && row.id_name_map.key ?
                                                        (<span className={`ml-1 text-info mb-0`}>
                                                        {row.id_name_map.key }</span>)
                                                    : null}
                                                </span>
                                            </td>
                                            <td className="py-2.5 pl-4 text-left ">
                                                {row.public_ip_address ? row.public_ip_address : ''} 
                                                {row.public_ip_address && row.private_ip_address ? ' / ' : ''}
                                                {row.private_ip_address ? row.private_ip_address : ''}
                                            </td>
                                            {/* <td className="py-2.5 pl-4 text-left  displayNone">
                                                <div className='flex'>
                                                    {row.gpu ? <span>{row.gpu+" GPU, "}</span> : null}
                                                    <span className='ml-1'>{row.vcpus} vCPU,</span>
                                                    <span className='ml-1'>{row.memory} {row.memory_unit ? row.memory_unit : 'GB'} </span>
                                                    {row.volume_details && row.volume_details.length && row.volume_details[0].volume_size ?
                                                        <span className='ml-2'>({row.volume_details[0].volume_size} GB)</span>
                                                    : null}
                                                </div>
                                            </td> */}
                                            <td className="py-2.5 pl-4 text-left ">
                                                <div className='flex flex-wrap gap-2'>
                                                    {Array.isArray(row.user_name) ?
                                                        row.user_name.map((usr, usrIndex) => {
                                                            return(
                                                                <p className='mb-0'>{usr} {usrIndex+1 !== row.user_name.length ? ', ' : ''}</p>
                                                            )
                                                        })
                                                    :
                                                        <span className=''>{row.user_name}</span>
                                                    }
                                                        
                                                    {/* {Array.isArray(row.user_name) && row.user_name.length > 0 ?
                                                        <span className='text-info ml-2'>more</span>
                                                    : null} */}
                                                </div>
                                            </td>
                                            <td className="py-2.5 pl-4 text-left ">
                                                <div className="flex justify-between self-center">
                                                    <span className='text-primaryPurple-600 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}>Details</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )

                                })
                            : 
                                <tr>
                                    <td colspan={8} className="p-4 text-center">
                                        <p className='mb-0'>No summary data found.</p>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>

                {state.workstationList && state.workstationList.length ?
                    state.totalRecords > state.perPage ?
                        <div className='flex justify-center text-black w-full pt-8'>
                            <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                            <div className="flex text-sm">
                                <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.summmaryResponse && state.summmaryResponse.previous_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-500'}`} onClick={() => {
                                    if(state.summmaryResponse && state.summmaryResponse.previous_token) {
                                        setState(prevState => ({ ...prevState, showLoading: true, currentPage: state.currentPage-1, token: 'previous', callListWorkstationResults: true }))
                                    }
                                }}/></span> 
                                <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.summmaryResponse && state.summmaryResponse.next_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-500'}`} onClick={() => {
                                    if(state.summmaryResponse && state.summmaryResponse.next_token) {
                                        setState(prevState => ({ ...prevState, showLoading: true, currentPage: state.currentPage+1, token: 'next', callListWorkstationResults: true }))
                                    }
                                }}/></span>
                            </div>
                        </div>
                    : null
                : null}
            </div>
            </React.Fragment>
    );
};
export default Summary