/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file QOS LandingPage.js
 * @author Prakash // on 07/02/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { listQosPolicies, removeQosPolicy } from '../../../actions/Collider/QosAction'
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, capitalizeAllLetter, getRegionName, getAccountNameFromId, getCommonInformationMessage, } from '../../../utils/utility'

import { CSVLink } from 'react-csv';
import {Icon} from "@iconify/react";

import Search from '../../common/SearchWithHiddenInput'
import { Store as CommonNotification } from 'react-notifications-component';

import { URL_PATH } from '../../../config/urlPath';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import { qosDeleteAccount, qosListAccounts } from '../../../actions/Collider/AccountAction';
import FilterSection from '../../common/FiltersSection';
import PageLoading from '../../common/PageLoading';
import AlertConfirmation from '../../common/AlertConfirmation';
import Button from '../../common/Form/Button';

const LandingPage = () => {
    const clickOutside = useRef() 
    const [state, setState] = useState({
        showLoading: true,

        itemList: [],
        filteredList: [],
        csvheaders: [],
        emptyOptions: [],
        modal: false,
        changeManage: 'QOS',
        selectedManage: 'QOS',
        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster', 'manage_qos']
    })

    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const providers = useSelector(state => state?.filters?.providers || false);

    const location = useLocation();
    const locationData = location.state || false
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
                    // else {
                    //     if(row.action) {
                    //         actionsAllowed = row.action
                    //     }
                    // }
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
		if(locationData && Object.entries(locationData).length) {            
			setState(prevState => ({ 
				...prevState,
				selectedManage: locationData.selectedManage ? locationData.selectedManage : 'QOS',
                changeManage: locationData.selectedManage ? locationData.selectedManage : 'QOS',
			}))
		}
	}, [locationData, dispatch])

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            selectedManage: filter.changeManage ? filter.changeManage : 'QOS',
            callSearch: true,
            showLoading: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: "", selectedProjectTags: [], selectedAccount: [], selectedRegion: [], selectedClusters: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== "All")
            }            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== "All")
            }            
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters.filter(e => e !== "All")
            }
            let columns = [
                {
                    Header: 'Date',
                    accessor: d => d.modified_at ? "modified_at" : 'created_at',
                    Cell: cellInfo => (
                    <span>
                        {cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : '')}
                    </span>
                    ),
                    width: 200
                },
                {
                    Header: 'Name',
                    accessor: state.selectedManage === 'QOS' ? 'qos_name' : 'account',
                    width: 250,
                },
                {
                    Header: 'Provider',
                    accessor: d => d.provider ? capitalizeAllLetter(d.provider) : '',
                    width: 150,
                },
                {
                    Header: 'Account',
                    accessor: 'account_id',
                    width: 200,
                    Cell: cellInfo => (
                        <div className="flex justify-between self-center">
                            {cellInfo.row.original && cellInfo.row.original.account_id ? getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) : ''}
                        </div>
                    ),
                },
                {
                    Header: 'Region',
                    accessor: 'region',
                    width: 200,
                    Cell: cellInfo => (
                        <div className="flex justify-between self-center">
                            {cellInfo.row.original && cellInfo.row.original.region ? getRegionName(cellInfo.row.original.region, state.regions) : ''}
                        </div>
                    ),
                },
                {
                    Header: 'Cluster',
                    accessor: 'cluster_name',
                    width: 200,
                },
                {
                    Header: 'Actions',
                    Cell: cellInfo => (
                        <div className="flex self-center">
                            {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                                state.selectedManage === 'QOS' ?
                                    <Link 
                                        to={URL_PATH.CREATE_QOS} 
                                        state={{
                                            selectedData: cellInfo.row.original,
                                            pageType: 'edit',
                                        }}
                                        className='text-primaryPurple-600'>
                                        Edit
                                    </Link>
                                : 
                                    <Link 
                                        to={URL_PATH.CREATE_GROUP} 
                                        state={{
                                            selectedData: cellInfo.row.original,
                                            pageType: 'edit',
                                        }}
                                        className='text-primaryPurple-600'>
                                        Edit
                                    </Link>
                            : null}
                            {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                                <p className='ml-3 mb-0 text-ferrariRed-600 cursor-pointer' 
                                    onClick={() => onDelete(cellInfo.row.original)}>Delete</p>
                            : null}
                        </div>
                    ),
                    width: 200
                }
            ]

            setState(prevState => ({ ...prevState, columns }))

            if(state.selectedProvider) {
                if(state.selectedManage === 'QOS') {
                    dispatch(listQosPolicies(params))
                        .then((response) => {
                            if(response) {
                                let results = response && response.results && response.results.length ? response.results : []
                                setState(prevState => ({ ...prevState, detailsFormat: response && response.qos_details ? response.qos_details : [], itemList: results, filteredList: results, showLoading: false }));
                            }
                        })
                } else {
                    dispatch(qosListAccounts(params))
                        .then((response) => {
                            if(response) {
                                let results = response && response.results && response.results.length ? response.results : []
                                
                                setState(prevState => ({ ...prevState, detailsFormat: response && response.qos_details ? response.qos_details : [], itemList: results, filteredList: results, showLoading: false }));
                            }
                        })
                }
            } else {
                setState(prevState => ({ ...prevState, itemList: [], filteredList: [], showLoading: false }));
            }
        }
    }, [dispatch, state.accounts, state.callSearch, state.regions, state.selectedAccount, state.selectedClusters, state.selectedManage, state.selectedProjectTags, state.selectedProvider, state.selectedRegion, state.actionsAllowed])

    useEffect(() => {
        if(state.itemList && state.itemList.length) {
            let dataResult = state.itemList
            let headers = []
            Object.entries(dataResult[0]).forEach(([key, value]) => {
                if(typeof value === 'string') {
                    let headerRow = {}
                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                    headerRow.key = key
                    headers.push(headerRow)
                }
            })
    
            setState(prevState => ({ ...prevState, csvheaders: headers }))
        }
    }, [state.itemList])

    const removeQosPolicyFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.selectedDeleteItem 
        let params = {}
        if(item && item.asset_id) {
            params.asset_id = item.asset_id
        } else {
            params.provider = item.provider
            params.account_id = item.account_id
            params.region = item.region
            params.cluster_name = item.cluster_name
            if(state.selectedManage === 'QOS') {
                params.qos_name = item.qos_name
            } else {
                params.account = item.account
            }
        }
        if(state.selectedManage === 'QOS') {
            dispatch(removeQosPolicy(params))
                .then((response) => {

                    if(response) {
                        let messageType = 'danger'
                        let message = response && response.message ? response.message : "Error in deleting"
    
                        if(response.status) {
                            messageType = 'success'
                            setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, deleteModal: !state.deleteModal, confirmDetails: false}))}, 1000)
                        }
    
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    }
                })
        } else {
            dispatch(qosDeleteAccount(params))
                .then((response) => {
                    if(response) {
                        let messageType = 'danger'
                        let message = response && response.message ? response.message : "Error in deleting"
    
                        if(response.status) {
                            messageType = 'success'
                            setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, deleteModal: !state.deleteModal, confirmDetails: false }))}, 1000)
                        }
    
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    }
                })
        }

    }

    useEffect(() => {
        if(state.callDelete && state.selectedDeleteItem && Object.entries(state.selectedDeleteItem).length) {
            setState(prevState => ({ ...prevState, modal: !state.modal, callDelete: false }))
        }
    }, [state.callDelete, state.selectedDeleteItem, state.modal])



	const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const navigateTo = () => {
        dispatch(setHpcPropsDetails('qosCreateEditInputs', {}))
        let path = URL_PATH.CREATE_GROUP
        if(state.selectedManage === 'QOS') {
            path = URL_PATH.CREATE_QOS
        }
        navigate(path, { state: { pageType: 'Create' } });
    }

    const onDelete = (item) => {
        let obj = {
            message: 'Are you sure you want to delete the qos' +(item.qos_name ? ' "'+item.qos_name+'"' : ''),
            buttonLabel: "Yes, I'm sure",
        }
        setState(prevState => ({ ...prevState, confirmDetails: obj, selectedDeleteItem: item, deleteModal: true }))
    }

    return (
        state.actionsAllowed ?
        <div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="pt-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl py-7 px-6">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">QOS and Group</p>
                        <p className="text-white text-base">Consolidated view of qos and account association</p>
                    </div>
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            topFilterOptions= {state.topFilterOptions}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            screen="QOS"
                            changeManage={state.changeManage}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='min-h-screen overflow-auto'>
                <div className='flex flex-wrap justify-between py-[35px]'>
                    <div className='lg:w-1/2 w-full self-center'>
                        <p className='justify-start self-center text-sm'>Showing {state.filteredList && state.filteredList.length} of total {state.itemList && state.itemList.length} qos</p>
                    </div>
                    <div className='self-center'>
                        <div className='flex justify-end'>
                            {state.itemList && state.itemList.length ?
                                <div className='self-center mr-2'>
                                    <Search
                                        data={state.itemList ? state.itemList : []}
                                        topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                                        className={'rounded-full'}
                                        filteredData={(filteredList) => setState(prevState => ({ ...prevState, filteredList }))}
                                    />
                                </div>
                            : null}
                            {state.actionsAllowed.includes('create')?
                                <Button
                                    classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', margin: 'mx-2', text: 'text-white text-base font-medium', width: 'min-w-36', others: 'cursor-pointer'}}
                                    label={`Create ${state.selectedManage}`}
                                    callback={() => {
                                        navigateTo()
                                    }}
                                />
                            : null}
                            {state.filteredList && state.filteredList.length ?
                                <div className='self-center' title="download">
                                    <CSVLink 
                                        data={state.filteredList ? state.filteredList : []} 
                                        headers={state.csvheaders ? state.csvheaders : []}
                                        filename={'qos-lists.csv'}
                                        className={'self-center'}
                                        target='_blank'
                                    >
                                        <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center' />
                                    </CSVLink>
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
                <div className="w-full">
                    <div className="relative overflow-x-auto rounded-t-lg shadow">
                        {state.filteredList && state.filteredList.length ?
                            <ResizeableDarkThemeTable
                                columns={state.columns}
                                data={state.filteredList}
                                perPage={20}
                                selectedColor={'bg-backGroundGray'}
                                dashboard={true}
                                sortByColumn={"date"}
                                riskTooltip={[0]}
                                onClickRow={tableRow => {}}
                                // showPaginationTop={true}
                            />
                        : 
                            <div className='flex justify-center m-4'>
                                {getCommonInformationMessage('qos')}
                            </div>
                        }
                    </div>
                    {state.confirmDetails && state.deleteModal ?
                        <AlertConfirmation 
                            confirmDetails={state.confirmDetails}
                            confirmation={(deleteModal) => {
                                if(deleteModal) {
                                    removeQosPolicyFunction()
                                } else {
                                    setState(prevState => ({ ...prevState, deleteModal: false, confirmDetails: false }))
                                }
                            }}
                        />
                    : null}
                </div>
            </div>
        </div>
        : null
    )
}

export default LandingPage