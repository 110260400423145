import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import { getAccountNameFromId, getRegionName, momentDateGivenFormat } from '../../../utils/utility';
import { DURATION_OPTIONS } from '../../../utils/constants'
import { listAllProviders, listClusterFilters, listAllProjects, listUsers, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { listQueues } from '../../../actions/Collider/ClusterAction'
import {Icon} from "@iconify/react";
import SelectOption from '../../common/Form/SelectOption';
import PageLoading from '../../common/PageLoading';
import Button from '../../common/Form/Button';


const AdvancedFilters = ({ selectedFilters, filterState, closeSidePanel }) => {
    const durationRef = useRef()
    const clickOut = useRef()
    const [state, setState] = useState({
        selectedProvider: filterState.selectedProvider,
        selectedProject: filterState.selectedProject,
        selectedAccount: filterState.selectedAccount,
        selectedRegion: filterState.selectedRegion,        
        selectedDuration: filterState.selectedDuration,
        datePickerStartDate: filterState.datePickerStartDate,
        datePickerEndDate: filterState.datePickerEndDate,
        selectedClusters: filterState.selectedClusters,
        selectedQueue: filterState.selectedQueue,
        selectedUser: filterState.selectedUser,
        selectionRange: [{
            startDate: filterState.datePickerStartDate,
            endDate: filterState.datePickerEndDate,
            key: 'selection',
        }]
    })

    const [dateState, setDateState] = useState([{
        startDate: new Date(),
        endDate: null,
        key: 'selection'
    }]);

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    const usersList = useSelector(state => state?.filters?.usersList);

    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    // list all providers
    useEffect(() => {
        if (providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""), callClusterList: true, callQueueList: true, callUserList: true 
            }));
        } else { 
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                if(response) {
                    setState(prevState => ({ ...prevState, providers: response, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''), callClusterList: true, callQueueList: true, callUserList: true }));
                }
            })
        }
    }, [dispatch, providers]);

    // Call provider based accounts whenever the selectedProviders state is updated
    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
            if(state.selectedProject) {
                label = '_'+state.selectedProject
                params.project_tags = state.selectedProjectTags
            }

            if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts, state.selectedProject, state.selectedProjectTags]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedProject) {
                label = '_'+state.selectedProject
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                label = state.selectedProvider+'_'+state.selectedAccount
                params.account_id = state.selectedAccount
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
							let obj = propProjRegions ? propProjRegions : {}
							obj[label] = response
							dispatch(setCommonPropsDetails('propProjRegions', obj))
							setState(prevState => ({ ...prevState, regions: response }));
						}
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjRegions, state.selectedAccount, state.selectedProject, state.selectedProjectTags]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(propProjects && propProjects.hasOwnProperty(state.selectedProvider)) {
                setState(prevState => ({ ...prevState, projects: propProjects[state.selectedProvider] }));
            } else {
                dispatch(listAllProjects(params))
                    .then((response) => {
                        if(response) {
                            let obj = propProjects ? propProjects : {}
                            obj[state.selectedProvider] = response
                            dispatch(setCommonPropsDetails('propProjects', obj))
                            setState(prevState => ({ ...prevState, projects: response }))
                        }
                    });
            }
        }
    }, [state.selectedProvider, dispatch, propProjects]);

    useEffect(() => {
        if (state.callClusterList) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            clusters: response,
                            filteredClusters: response,
                            callClusterList: false
                        }));
                    }
            })
        }
    }, [dispatch, state.callClusterList, state.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        if (state.callQueueList) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters
            }
            dispatch(listQueues(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, queues: response, callQueueList: false }));
                    }
            })
        }
    }, [dispatch, state.callQueueList, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters])

    useEffect(() => {
        if (state.callUserList) {
            let params = {};
            if(!usersList || !usersList.length) {
                dispatch(listUsers(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({ ...prevState, usersList: response }));
                        }
                    })
            } else {
                setState(prevState => ({ ...prevState, usersList: usersList }));
            }
        }
    }, [dispatch, state.callUserList, usersList])

    const handleSelect = (date) => {
        const currentMonth = new Date().getMonth(); // Month is zero-based (0 for January, 1 for February, ..., 11 for December)

        let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "currentYear") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "lastMonth") {
            datePickerStartDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-01 00:00:00')            
            datePickerEndDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "quarterly") {
            if (currentMonth >= 0 && currentMonth <= 2) {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-03-31 23:59:59')
            } else if (currentMonth > 2 && currentMonth < 6) {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-04-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else if (currentMonth > 5 && currentMonth < 9) {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-09-30 23:59:59')
            } else {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-10-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "half-yearly") {
            if (currentMonth >= 0 && currentMonth <= 5) {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "yearly") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        } else if(state.selectedDuration === "custom") {
            datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
            if(date.selection.startDate !== date.selection.endDate) {
                datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
            }
        }

        //   if(state.selectedDuration !== 'custom') {
        //     setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));
    
        //     let dateSelection= {
        //       startDate: new Date(datePickerStartDate),
        //       endDate: new Date(datePickerEndDate),
        //       key: 'selection'
        //     }
        //     setDateState([dateSelection])
        //   }

        // let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        // let datePickerEndDate = ""
        // if(date.selection.startDate !== date.selection.endDate) {
        //     datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        // }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));    
        }

        let dateSelection= {
            startDate: new Date(datePickerStartDate),
            endDate: new Date(datePickerEndDate),
            key: 'selection'
        }

        setDateState([dateSelection])
    };

    useEffect(() => {
        const currentMonth = new Date().getMonth(); // Month is zero-based (0 for January, 1 for February, ..., 11 for December)

        let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "currentYear") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "lastMonth") {
            datePickerStartDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-01 00:00:00')            
            datePickerEndDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "quarterly") {
            if (currentMonth >= 0 && currentMonth <= 2) {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-03-31 23:59:59')
            } else if (currentMonth > 2 && currentMonth < 6) {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-04-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else if (currentMonth > 5 && currentMonth < 9) {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-09-30 23:59:59')
            } else {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-10-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "half-yearly") {
            if (currentMonth >= 0 && currentMonth <= 5) {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "yearly") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }

        //   if(state.selectedDuration !== 'custom') {
        //     setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));
    
        //     let dateSelection= {
        //       startDate: new Date(datePickerStartDate),
        //       endDate: new Date(datePickerEndDate),
        //       key: 'selection'
        //     }
        //     setDateState([dateSelection])
        //   }

        // let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        // let datePickerEndDate = ""
        // if(date.selection.startDate !== date.selection.endDate) {
        //     datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        // }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));    
        }

        let dateSelection= {
            startDate: new Date(datePickerStartDate),
            endDate: new Date(datePickerEndDate),
            key: 'selection'
        }

        setDateState([dateSelection])
    }, [state.selectedDuration])

    const removeItem = (field, value) => {
        setState(prevState => ({ ...prevState, [field]: state[field].filter(e => e !== value) }))
    }

    const onSearch = () => {
        let obj = {
            selectedProvider: state.selectedProvider,
            selectedAccount: state.selectedAccount ? state.selectedAccount : [],
            selectedProject: state.selectedProject ? state.selectedProject : '',
            selectedProjectTags: state.selectedProjectTags ? state.selectedProjectTags : [],
            selectedRegion: state.selectedRegion ? state.selectedRegion : [],
            selectedClusters: state.selectedClusters ? state.selectedClusters : [],
            selectedQueue: state.selectedQueue ? state.selectedQueue : [],
            selectedUser: state.selectedUser ? state.selectedUser : [],
            selectedDuration: state.selectedDuration,
            datePickerStartDate: state.datePickerStartDate,
            datePickerEndDate: state.datePickerEndDate
        }

        selectedFilters(obj)
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
        
		if (durationRef.current && !durationRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}

        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }

		event.stopPropagation();
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='bg-white  h-full flex flex-col w-3/5'>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black shadow-xl'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl'>Advanced Search</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black cursor-pointer self-center' width={30} height={30} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                    <div className='overflow-y-auto h-[calc(100vh-120px)]'>
                        <div className='bg-white p-8'>
                            <div className='flex flex-wrap'>
                                <div className='flex justify-start md:w-1/2 w-full pr-4'>
                                    <SelectOption
                                        label={"Provider"}
                                        fields={["provider_name", "provider_name"]}
                                        options={state.providers}
                                        selectedValues={state.selectedProvider ? state.selectedProvider : []}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: [], selectedRegion: [], selectedClusters: [], selectedQueue: [] }))
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                                <div className='flex justify-start md:w-1/2 w-full md:pl-4 md:pt-0 pt-8'>
                                    <SelectOption
                                        label={"Project"}
                                        fields={["project", "project"]}
                                        options={state.projects}
                                        selectedValues={state.selectedProject ? state.selectedProject : ''}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                setState(prevState => ({ ...prevState, 
                                                    selectedProject: value,
                                                    selectedProjectTags: state.projects.filter(e => e.project === value).length && state.projects.filter(e => e.project === value)[0] && state.projects.filter(e => e.project === value)[0].tags ? state.projects.filter(e => e.project === value)[0].tags : []
                                                }))
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-wrap pt-8'>
                                <div className='flex justify-start md:w-1/2 w-full pr-4'>
                                    <SelectOption
                                        label={"Account"}
                                        fields={["account_id", "account_name"]}
                                        options={state.accounts}
                                        selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedAccount: value, callClusterList: true, callQueueList: true }))
                                        }}
                                        singleSelection={false}
                                        manditory={false}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                                <div className='flex justify-start md:w-1/2 w-full md:pl-4 md:pt-0 pt-8'>
                                    <SelectOption
                                        label={"Region"}
                                        fields={["region", "name"]}
                                        options={state.regions}
                                        selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedRegion: value, callClusterList: true, callQueueList: true }))
                                        }}
                                        singleSelection={false}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                            </div>
                            {(state.selectedAccount && state.selectedAccount.length)|| (state.selectedRegion && state.selectedRegion.length) ?
                                <div className='flex flex-wrap pt-4'>
                                    <div className='flex flex-wrap justify-start w-1/2 pr-4'>
                                        {state.selectedAccount && state.selectedAccount.length ? 
                                            state.selectedAccount.map(row => {
                                                return(
                                                    <span key={row} className="flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all text-base">{getAccountNameFromId(row, state.accounts)}
                                                        <Icon icon="ion:close-circle-sharp" width={24} height={24}  className='text-black ml-2 self-center cursor-pointer' onClick={() => removeItem('selectedAccount', row)}/>
                                                    </span>
                                                )
                                            })
                                        : null}
                                    </div>
                                    <div className='flex flex-wrap justify-start w-1/2 pl-4'>
                                        {state.selectedRegion && state.selectedRegion.length ? 
                                            state.selectedRegion.map(row => {
                                                return(
                                                    <span key={row} className="flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all text-base">{getRegionName(row, state.regions)}
                                                        <Icon icon="ion:close-circle-sharp" width={24} height={24}  className='text-black ml-2 self-center cursor-pointer' onClick={() => removeItem('selectedRegion', row)}/>
                                                    </span>
                                                )
                                            })
                                        : null}
                                    </div>
                                </div>
                            : null}
                            
                            <div className='flex flex-wrap pt-8'>
                                <div className='flex justify-start md:w-1/2 w-full pr-4'>
                                    <SelectOption
                                        label={"Cluster"}
                                        options={state.clusters}
                                        selectedValues={state.selectedClusters ? state.selectedClusters : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedClusters: value, callQueueList: true }))
                                        }}
                                        singleSelection={false}
                                        manditory={false}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                                <div className='flex justify-start md:w-1/2 w-full md:pl-4 md:pt-0 pt-8'>
                                    <SelectOption
                                        label={"Queues"}
                                        options={state.queues}
                                        selectedValues={state.selectedQueue ? state.selectedQueue : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedQueue: value }))
                                        }}
                                        singleSelection={false}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                            </div>
                            {(state.selectedClusters && state.selectedClusters.length)|| (state.selectedQueue && state.selectedQueue.length) ?
                                <div className='flex flex-wrap pt-4'>
                                    <div className='flex flex-wrap justify-start w-1/2 pr-4'>
                                        {state.selectedClusters && state.selectedClusters.length ? 
                                            state.selectedClusters.map(row => {
                                                return(
                                                    <span key={row} className="flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all text-base">{row}
                                                        <Icon icon="ion:close-circle-sharp" width={24} height={24}  className='text-black ml-2 self-center cursor-pointer' onClick={() => removeItem('selectedClusters', row)}/>
                                                    </span>
                                                )
                                            })
                                        : null}
                                    </div>
                                    <div className='flex flex-wrap justify-start w-1/2 pl-4'>
                                        {state.selectedQueue && state.selectedQueue.length ? 
                                            state.selectedQueue.map(row => {
                                                return(
                                                    <span key={row} className="flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all text-base">{row}
                                                        <Icon icon="ion:close-circle-sharp" width={24} height={24}  className='text-black ml-2 self-center cursor-pointer' onClick={() => removeItem('selectedQueue', row)}/>
                                                    </span>
                                                )
                                            })
                                        : null}
                                    </div>
                                </div>
                            : null}

                            <div className='flex flex-wrap pt-8'>
                                <div className='flex justify-start md:w-1/2 w-full pr-4'>
                                    <SelectOption
                                        label={"Users"}
                                        options={state.usersList}
                                        selectedValues={state.selectedUser ? state.selectedUser : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedUser: value }))
                                        }}
                                        singleSelection={false}
                                        manditory={false}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                                <div className='flex justify-start md:w-1/2 w-full md:pl-4 md:pt-0 pt-8'>
                                    <SelectOption
                                        label={"Duration"}
                                        fields={["value", "label"]}
                                        options={DURATION_OPTIONS}
                                        selectedValues={state.selectedDuration ? state.selectedDuration : []}
                                        callbackMultiSelect={(value) => {
                                            if(typeof(value) === 'string') {
                                                if(value !== 'custom') {
                                                    setState(prevState => ({ ...prevState, selectedDuration: value }))
                                                } else {
                                                    setState(prevState => ({ ...prevState, showDateRangePicker: value === 'custom' ? true : false }))
                                                }
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                        dropdownWidth={'w-full'}
                                    />
                                    {state.showDateRangePicker ?
                                        <div className="absolute z-50 right-20 mt-5" ref={durationRef}>
                                            <div className='relative'>
                                                <div className="bg-white border border-byzantineBlue shadow-lg text-black">
                                                    <DateRange
                                                        editableDateInputs={true}
                                                        onChange={handleSelect}
                                                        moveRangeOnFirstSelection={false}
                                                        ranges={dateState}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    : null}
                                </div>
                            </div>

                            {state.selectedUser && state.selectedUser.length ?
                                <div className='flex flex-wrap pt-4'>
                                    {state.selectedUser && state.selectedUser.length ? 
                                        state.selectedUser.map(row => {
                                            return(
                                                <span key={row} className="flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all text-base">{row}
                                                    <Icon icon="ion:close-circle-sharp" width={24} height={24}  className='text-black ml-2 self-center cursor-pointer' onClick={() => removeItem('selectedUser', row)}/>
                                                </span>
                                            )
                                        })
                                    : null}
                                </div>
                            : null}
                        </div>

                        <div className='flex fixed bottom-4 right-4 justify-end bg-transparent'>
                            <div className='self-start'>
                                <div className='flex'>
                                    {state.pageType !== "View" ?
                                        <Button
                                            classDetails={{bg: 'bg-green-600', rounded: 'rounded-lg', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer items-center'}}
                                            label={'Search'}
                                            callback={() => {
                                                onSearch()
                                            }}
                                        />
                                    : null}
                                    <Button
                                        classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-lg', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                        label={'Cancel'}
                                        callback={() => 
                                            closeSidePanel()
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvancedFilters;