/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 24/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import _ from 'lodash'
import { capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import ClusterDetails from './ClusterDetails'
import TemplateDetails from './TemplateDetails'
import FilterSection from '../../common/FiltersSection';

import 'xterm/css/xterm.css';
import PageLoading from '../../common/PageLoading';

const LandingPage = () => {        
    const clickOutside = useRef()
    const [state, setState] = useState({
        showLoading: true,

        //filter section start

        selectedProvider: '',
        selectedAccount: [],
        isAccountOpen: false,

        selectedRegion: [],
        isRegionOpen: false,
        
        //filter section end

        currentPage: 1,

        clusterDetails: [],
        selectedClusterDetails: {},
        filteredClusterDetails: [],
        clusterheaders: [],
        selectedTab: 'Clusters',
        tabs: ["Clusters", "Templates"],
        topSectionFilters: ['provider', 'project', 'account', 'region']
    })
    
    
    const dispatch = useDispatch();
    const providers = useSelector(state => state?.filters?.providers || false);
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				} 
                // else {
                //     if(row.action) {
                //         actionsAllowed = row.action
                //     }
                // }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
            
    const handleSelectedFilters = (filter) => {
        let selectedTab = state.selectedTab
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            nextToken: [],
            clusterDetails: [],
            startRecord: 0,
            currentPage: 1,
            selectedTab: "",
            showLoading: true,
        }))

        setTimeout(() => {
            setState(prevState => ({  ...prevState, selectedTab }))
        }, 500);
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: '', selectedProjectTags: [], selectedAccount: [], selectedRegion: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.clusterDetails && state.clusterDetails.length) {
            let dataResult = state.clusterDetails
            let headers = []
            Object.entries(dataResult[0]).forEach(([key, value]) => {
                if(typeof value === 'string') {
                    let headerRow = {}
                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                    headerRow.key = key
                    headers.push(headerRow)
                }
            })
    
            setState(prevState => ({ ...prevState, clusterheaders: headers }))
        }

        //status graph of how many running days 
        state.clusterDetails && state.clusterDetails.forEach(row => {
            let donut = [{
                item_count: row.number_of_days ? row.number_of_days : 0,
                item_name:'Days'
            }]
            let donut_total_count = row.number_of_days ? row.number_of_days : 0
            // state.statusSection.trend && state.statusSection.trend.length && state.statusSection.trend[0].total && Object.entries(state.statusSection.trend[0].total).forEach(([key, value]) => {
            //     let donutRow = {}
            //     donutRow.item_count = value
            //     donutRow.item_name = key
            //     donut.push(donutRow)
            //     donut_total_count += value 
            // })
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let statusDonutData = {
                'items': donut,
                'label':'Days',
                'total_count': donut_total_count
            }
    
            setState(prevState => ({ ...prevState, ["statusDonutData_"+row.cluster_name]: statusDonutData }))
        })
    }, [state.clusterDetails])

    const handleChildClick = (event, type, dropdownType, section) => {
        
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }

            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='px-3' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>

             <div className="pt-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl py-7 px-6">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Cluster {state.selectedTab === 'Templates' ? 'Template' : ''}&nbsp;Details</p>
                        <p className="text-white text-base">Consolidated view of cluster {state.selectedTab === 'Templates' ? 'template' : ''} details</p>
                    </div>
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: false }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className={`pt-8`}>
                <ul className="flex flex-wrap gap-2.5 text-lg">
                    {state.tabs.map(tab => {
                        return(
                            <li className={`flex px-8 py-5 border border-NeutralGray-600 rounded-t-xl ${state.selectedTab === tab ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: tab }))}>{tab}</li>
                        )
                    })}
                </ul>
         
                <div className="overflow-y-auto h-[calc(100vh-120px)] border border-NeutralGray-600 bg-white rounded-b-2xl rounded-tr-2xl py-8 px-6">
                    {state.selectedTab === 'Clusters' && !!state.selectedProvider ?
                        <ClusterDetails
                            selectedTab={state.selectedTab}
                            selectedProvider={state.selectedProvider}
                            selectedProjectTags={state.selectedProjectTags}
                            selectedAccount={state.selectedAccount}
                            selectedRegion={state.selectedRegion}
                        />
                    : state.selectedTab === 'Templates' && !!state.selectedProvider ?
                        <TemplateDetails
                            selectedTab={state.selectedTab}
                            selectedProvider={state.selectedProvider}
                            selectedProjectTags={state.selectedProjectTags}
                            selectedAccount={state.selectedAccount}
                            selectedRegion={state.selectedRegion}
                        />
                    : null}
                </div>
            </div>
        </div>
    )
}
export default LandingPage