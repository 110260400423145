import React, { useEffect, useState } from 'react';

const Textarea = ({ label, callback, className, stateValue, rows }) => {
    const [textState, setTextState] = useState('')

    useEffect(() => {
        setTextState(stateValue)
    }, [])

    return (
        <div className={`w-full items-center ${className}`}>
            <p className="text-NeutralGray-800 text-base flex w-full">{label}</p>
            <textarea 
                className={`placeholder:italic placeholder:text-NeutralGray-600 text-base block ${className && className.borderClass ? className.borderClass : "border border-NeutralGray-400 rounded-2xl"} py-2.5 pl-2 shadow-sm w-full`}
                placeholder="Enter details" 
                value={textState ? textState : ''}
                onChange={e => {
                    let value = e.target.value
                    setTextState(value)
                    callback(value)
                }}
                rows={rows ? rows : 4}
            />
        </div>
    );
};

export default Textarea;
