
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 07/03/2024
 * @copyright © 2019-24 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import _ from 'lodash'

import { capitalizeTheFirstLetterOfEachWord, getAccountNameFromId, getRegionName, momentConvertionUtcToLocalTime, removeUnderScore } from '../../../utils/utility'

import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { getLicenseTopResults, listLicenses } from '../../../actions/Collider/LicenseAction'

import ApexBarChart from '../../common/charts/ApexBarChart'
import ApexDonutChart from '../../common/charts/ApexDonutChart'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

import Search from '../../common/SearchWithHiddenInput'
import ApexLineChartSpendBoard from '../../common/charts/ApexLineChartSpendBoard';
import { CSVLink } from 'react-csv';
import FilterSection from '../../common/FiltersSection';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';

const LandingPage = () => {
    const clickOutside = useRef();

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedDuration: 'currentMonth',
        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster', 'duration']
    })

    
    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    // list all providers
    // useEffect(() => {
    //     if (providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""), callClusterList: true, callToGetLicenseTopResults: true, callToGetListLicenses: true
    //         }));
    //     }
    // }, [providers]);
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            usageTrendData: {},
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            selectedApplication: filter.selectedApplication ? filter.selectedApplication : [],
            datePickerStartDate: filter.datePickerStartDate,
            datePickerEndDate: filter.datePickerEndDate,
            callSearch: true,
            showLoading: true
        }))
    }

    const onReset = () => {
        setState({ showLoading: true, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: '', selectedProjectTags: [], selectedAccount: [], selectedRegion: [], selectedApplication: [], selectedDuration: 'currentMonth', listLicenses: [], filteredListResponse: [], usageTrendData: {}, callSearch: true })
    }

    useEffect(() => {
        if (state.callSearch) {
            setState(prevState => ({ ...prevState, callToGetLicenseTopResults: true, callToGetListLicenses: true, callSearch: false }))
        }
    }, [state.callSearch])

    useEffect(() => {
        if(state.callToGetLicenseTopResults) {
            setState(prevState => ({ ...prevState, callToGetLicenseTopResults: false }))
            
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters
            }
            
            if(state.selectedApplication && state.selectedApplication.length) {
                params.application = state.selectedApplication
            }
            params.start_time = state.datePickerStartDate
            params.end_time = state.datePickerEndDate

            dispatch(getLicenseTopResults(params))
                .then((response) => {
                    if(response && !response.error) {
                        setState(prevState => ({ ...prevState, graphResponse: response, structureGraphData: true, showLoading: false }))
                    }
                })

        }
    }, [dispatch, state.callToGetLicenseTopResults, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedApplication, state.selectedClusters, state.selectedProjectTags, state.selectedProvider, state.selectedRegion])

    useEffect(() => {
        if(state.callToGetListLicenses) {
            setState(prevState => ({ ...prevState, callToGetListLicenses: false }))
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters
            }
            
            if(state.selectedApplication && state.selectedApplication.length) {
                params.application = state.selectedApplication
            }

            params.start_time = state.datePickerStartDate
            params.end_time = state.datePickerEndDate

            dispatch(listLicenses(params))
                .then((response) => {
                    if(response && !response.error) {
                        let results = response.results ? response.results : []
                        setState(prevState => ({ ...prevState, responseTableOrder: response.order ? response.order : {}, listResponse: results, structureList: true,  showLoading: false }))
                    }
                })

        }
    }, [dispatch, state.callToGetListLicenses, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedApplication, state.selectedClusters, state.selectedProjectTags, state.selectedProvider, state.selectedRegion])

    useEffect(() => {
        if(state.structureList) {
            setState(prevState => ({ ...prevState, structureList: false }))

            let list = state.listResponse
            let columns = []
            if(state.responseTableOrder && state.responseTableOrder.length) {
                state.responseTableOrder.forEach((item) => {
                    let colRow ={
                        Header: removeUnderScore(item),
                        accessor: item,
                        Cell: cellInfo => (
                            <div className="flex justify-between self-center">
                                {cellInfo.row.original ?
                                    item === 'account_id' ? 
                                        getAccountNameFromId(cellInfo.row.original[item], state.accounts) 
                                    : item === 'region' ? 
                                        getRegionName(cellInfo.row.original[item], state.regions) 
                                    : item === 'provider' ?
                                        cellInfo.row.original[item].toUpperCase()
                                    : cellInfo.row.original[item]
                                : null}
                            </div>
                        ),
                        width: item.length > 15 ? 150 : 250,
                    }
                    columns.push(colRow)
                })
            }

            setState(prevState => ({ ...prevState, filteredListResponse: list, columns }))
        }

    }, [state])

    useEffect(() => {
        let dataResult = state.listResponse && state.listResponse.length ? state.listResponse : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, headers }))
		}
    }, [state.listResponse])


    useEffect(() => {
        if(state.structureGraphData) {
            setState(prevState => ({ ...prevState, structureGraphData: false }))
            let licensesUsageCount =  state.graphResponse && state.graphResponse.licenses_usage_count ? state.graphResponse.licenses_usage_count : {}

            let donut = []
			let donut_total_count = 0
	
            Object.entries(licensesUsageCount).forEach(([key, value]) => {
                let donutRow = {}
                donutRow.item_count = value
                donutRow.item_name = key
                donut.push(donutRow)
                donut_total_count += value
            })

            let orderDonut = _.orderBy(donut, ['item_count'], ['desc'])
            
			let licenseUsgaeCountDonutData = {
				"items": orderDonut,
				"label": "Total",
				"total_count": donut_total_count
			}            

            let topLicenseUsageJobs =  state.graphResponse && state.graphResponse.top_license_usage_jobs ? state.graphResponse.top_license_usage_jobs : []
            
            let data = []
            let labels = []
            if(topLicenseUsageJobs && topLicenseUsageJobs.length) {
                _.orderBy(topLicenseUsageJobs, ['total'], ['desc']).forEach(item => {
                    labels.push(item.license_name)
                    data.push(item.total)
                })
            }

            
            // for(let i=labels.length; i<5; i++) {
            //     data.push(0)
            //     labels.push('empty')
            // }

            let topLicenseUsageJobData = {}
            topLicenseUsageJobData.data = data
            topLicenseUsageJobData.labels = labels

            let topLicenseGraphUsers =  state.graphResponse && state.graphResponse.top_license_usage_users ? state.graphResponse.top_license_usage_users : []

            let userData = []
            let userLabels = []
            if(topLicenseGraphUsers && topLicenseGraphUsers.length) {
                _.orderBy(topLicenseGraphUsers, ['total'], ['desc']).forEach(item => {
                    userData.push(item.total)
                    userLabels.push(item.user_name)
                })
            }

            
            // for(let i=labels.length; i<5; i++) {
            //     userData.push(0)
            //     userLabels.push('empty')
            // }

            let topLicenseGraphUsersData = {}
            topLicenseGraphUsersData.data = userData
            topLicenseGraphUsersData.labels = userLabels

            let topLicensesUsage =  state.graphResponse && state.graphResponse.top_licenses_usage ? state.graphResponse.top_licenses_usage : []

            let usageData = []
            let usageLabels = []
            if(topLicensesUsage && topLicensesUsage.length) {
                _.orderBy(topLicensesUsage, ['total'], ['desc']).forEach(item => {
                    usageData.push(item.total)
                    usageLabels.push(item.license_name)
                })
            }

            
            // for(let i=labels.length; i<5; i++) {
            //     usageData.push(0)
            //     usageLabels.push('empty')
            // }

            let topLicensesUsageData = {}
            topLicensesUsageData.data = usageData
            topLicensesUsageData.labels = usageLabels

            let licensesUsageTrend =  state.graphResponse && state.graphResponse.license_usage_trend ? state.graphResponse.license_usage_trend : []

            let usageTrendData = {}
            usageTrendData.labels = licensesUsageTrend.dates ? licensesUsageTrend.dates : []
            licensesUsageTrend && Object.entries(licensesUsageTrend.data).forEach(([key, value]) => {
                usageTrendData[key] = value
            })


            setState(prevState => ({ ...prevState, licenseUsgaeCountDonutData, topLicenseUsageJobData, topLicenseGraphUsersData, topLicensesUsageData, usageTrendData }))
        }
    }, [state.structureGraphData, state.graphResponse])

    const handleChildClick = (event, type, dropdownType, section) => {	

        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
		}

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="py-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl py-7 px-6">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="text-white text-[28px] text-semibold pl-4">Licenses</p>
                        <p className="text-white text-base pl-4">Consolidated view of licenses</p>
                    </div>
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            topFilterOptions= {state.topFilterOptions}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>

            <div className='bg-white shadow p-6 rounded-2xl border border-DeepPurpleTints-600'>
                <div className='flex justify-between pb-4'>
                    <p className='text-[22px] font-bold capitalize'>Overview</p>
                </div>

                <div className="flex flex-wrap m-2">
                    <div className="w-full lg:w-1/3 md:w-1/2 sm:w-1/2">
                        <div className="bg-NeutralGray-100 p-3 rounded-2xl border border-NeutralGray-600">
                            <label className="text-base font-medium text-black">Top Jobs using maximum licenses</label>
                            {state.topLicenseUsageJobData && Object.entries(state.topLicenseUsageJobData).length ?
                                <div className='mt-n2 mb-n4'> 
                                    <ApexBarChart
                                        graphData={state.topLicenseUsageJobData}
                                        sparkline={false}
                                        yaxis={true}
                                        yaxisLabel={true}
                                        hideYaxisLine={true}
                                        xaxis={true}
                                        xaxisFormat={'string'}
                                        xaxisLabel={true}
                                        axisLabelColor={'#495057'}
                                        paddingLeft={0}
                                        legend={false}
                                        stacked={false}
                                        height={325}
                                        horizontal={true}
                                        barHeight={'40%'}
                                        barEndShape={'rounded'}
                                        barRadius={8}
                                        columnWidth={'25%'}
                                        showTitle={true}
                                        //'#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80'
                                        gradient={true}
                                        gradientColor={['#2560B7', '#7551c2']}
                                        hideTooltipValue={true}
                                        // backgroundBarShape={'F'}
                                        backgroundBarColors={['#333947']}
                                        showBackgroundBarColors={false}
                                        className={'transparentTooltip'}
                                        dataLabels={true}
                                        dataLabelsTextAnchor={'middle'}
                                        dataLabelPosition={''}
                                    />
                                </div>
                            : null}
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 md:w-1/2 sm:w-1/2 mt-2 lg:mt-0 md:mt-0 lg:px-4 md:pl-2">
                        <div className="bg-NeutralGray-100 p-3 rounded-2xl border border-NeutralGray-600">
                            <label className="text-base font-medium text-black">Top Solver license usage</label>
                            {state.topLicensesUsageData && Object.entries(state.topLicensesUsageData).length ?
                                <div className='mt-n2 mb-n4'> 
                                    <ApexBarChart
                                        graphData={state.topLicensesUsageData}
                                        sparkline={false}
                                        yaxis={true}
                                        yaxisLabel={true}
                                        xaxis={true}
                                        xaxisFormat={'string'}
                                        xaxisLabel={true}
                                        axisLabelColor={'#495057'}
                                        paddingLeft={0}
                                        legend={false}
                                        stacked={false}
                                        height={325}
                                        horizontal={true}
                                        barHeight={'40%'}
                                        barEndShape={'rounded'}
                                        barRadius={8}
                                        columnWidth={'25%'}
                                        showTitle={true}
                                        //'#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80'
                                        gradient={true}
                                        gradientColor={['#2560B7', '#7551c2']}
                                        hideTooltipValue={true}
                                        // backgroundBarShape={'F'}
                                        backgroundBarColors={['#333947']}
                                        showBackgroundBarColors={false}
                                        className={'transparentTooltip'}
                                        dataLabels={true}
                                        dataLabelsTextAnchor={'middle'}
                                        dataLabelPosition={''}
                                    />
                                </div>
                            : null}
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 md:w-1/2 sm:w-1/2 mt-2 lg:mt-0 md:mt-2 sm:mt-2">
                        <div className="bg-NeutralGray-100 p-3 rounded-2xl border border-NeutralGray-600">
                            <label className="text-base font-medium text-black">License usage by App Solvers</label>
                            {state.licenseUsgaeCountDonutData && Object.entries(state.licenseUsgaeCountDonutData).length ?
                                <div className='-m-n2 flex justify-center'> 
                                    <ApexDonutChart
                                        graphData={state.licenseUsgaeCountDonutData}
                                        legend={false}
                                        showTotal={true}
                                        removeFloat={true}
                                        showTotalLable={true}
                                        showGraphHeading={false}
                                        height={340}
                                        width={340}
                                        labelSize={'18px'}
                                        labelWeight={500}
                                        valueSize={'28px'}
                                        valueWeight={600}
                                        labelColor={'text-NeutralGray-700'}
                                        labelStyle={state.labelStyle}
                                        valueStyle={state.valueStyle}
                                        legendWidth={200}
                                        size={'70%'}
                                        gradient={false}
                                        gradientColor={['#A88CCC', '#D88ACF']}
                                        colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
                                        className={'transparentTooltip'}
                                    />
                                </div>
                            : null}
                        </div>
                    </div>
                </div>

                <div className='flex flex-wrap m-2'>
                    <div className="w-full lg:w-2/5 lg:pr-4 mt-4 self-center">
                        <div className="bg-NeutralGray-100 p-3 rounded-2xl border border-NeutralGray-600">
                            <label className="text-base font-medium text-black">Top users using maximum licenses</label>
                            {state.topLicenseGraphUsersData && Object.entries(state.topLicenseGraphUsersData).length ?
                                <div className='-mt-n2 -mb-n4'> 
                                    <ApexBarChart
                                        graphData={state.topLicenseGraphUsersData}
                                        sparkline={false}
                                        yaxis={true}
                                        yaxisLabel={true}
                                        xaxis={true}
                                        xaxisFormat={'string'}
                                        xaxisLabel={true}
                                        axisLabelColor={'#999999'}
                                        paddingLeft={0}
                                        legend={false}
                                        stacked={false}
                                        height={275}
                                        horizontal={true}
                                        barHeight={'40%'}
                                        barEndShape={'rounded'}
                                        barRadius={8}
                                        columnWidth={'25%'}
                                        showTitle={true}
                                        //'#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80'
                                        gradient={true}
                                        gradientColor={['#0057b8', '#7551c2']}
                                        hideTooltipValue={true}
                                        // backgroundBarShape={'F'}
                                        backgroundBarColors={['#333947']}
                                        showBackgroundBarColors={false}
                                        className={'transparentTooltip'}
                                        dataLabels={true}
                                        dataLabelsTextAnchor={'middle'}
                                        dataLabelPosition={''}
                                    />
                                </div>
                            : null}
                        </div>
                    </div>
                    <div className="w-full lg:w-3/5 lg:mt-4 mt-2 self-center">
                        <div className="bg-NeutralGray-100 p-3 rounded-2xl border border-NeutralGray-600">
                            <label className="text-base font-medium text-black">License Usage Trend</label>
                            {state.usageTrendData && Object.entries(state.usageTrendData).length ?
                                <div className='-mt-n2 -mb-n4'>
                                    <ApexLineChartSpendBoard
                                        graphData={state.usageTrendData}
                                        sparkline={false}
                                        yaxis={true}
                                        yaxisLabel={true}
                                        yaxisTitle={''}
                                        xaxis={true}
                                        xaxisFormat={'datetime'}
                                        xaxisLabel={true}
                                        axisLabelColor={'#666666'}
                                        paddingLeft={10}
                                        legend={false}
                                        stacked={false}
                                        height={275}
                                        customTooltip={false}
                                        className={'transparentTooltip'}
                                        // colors={['#43b02a', '#009FDF']}
                                        colors={['#854097', '#693EBC', '#F44336', '#24A597', '#775BA2', '#9A77D1']}
                                    />
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
            
                <div className='pt-8'>
                    <div className="flex justify-between lg:items-center lg:flex-row flex-col mt-4">
                        <div className=''>
                            <p className="text-[28px] text-md font-semibold flex flex-row gap-3">
                                License List
                            </p>

                            <p className="text-sm pt-2.5">
                                Showing {state.filteredListResponse && state.filteredListResponse.length} out of total {state.listResponse && state.listResponse.length} license(s)
                                {state.selectedProvider && state.selectedProvider.length ?
                                    <span className="ml-1">
                                        obtained from the 
                                        <span className="ml-1"> {state.selectedProvider.toUpperCase()} </span> 
                                        {state.selectedProvider.length > 1 ?
                                            <span className="ml-1 text-slageBlue-600">more</span>
                                        : null}
                                    </span>
                                : null}
                                {state.selectedAccount && state.selectedAccount.length ?
                                    <span className="ml-1">
                                        with the account
                                        <span className="ml-1"> {state.selectedAccount[0]} </span> 
                                        {state.selectedAccount.length > 1 ?
                                            <span className="ml-1 text-slageBlue-600">more</span>
                                        : null}
                                        <span className="ml-1">region</span>
                                    </span>
                                : null}
                                {state.selectedRegion && state.selectedRegion.length ?
                                    <span className="ml-1">
                                        in the
                                        <span className="ml-1"> {state.selectedRegion[0]} </span> 
                                        {state.selectedRegion.length > 1 ?
                                            <span className="ml-1 text-slageBlue-600">more</span>
                                        : null}
                                        <span className="ml-1">region</span>
                                    </span>
                                : null}
                                {state.selectedClusters && state.selectedClusters.length ?
                                    <span className="ml-1">
                                        in the
                                        <span className="ml-1"> {state.selectedClusters[0]} </span> 
                                        {state.selectedClusters.length > 1 ?
                                            <span className="ml-1 text-slageBlue-600">more</span>
                                        : null}
                                        <span className="ml-1">region</span>
                                    </span>
                                : null}
                                {state.datePickerStartDate ?
                                    <React.Fragment>
                                    <span className='ml-1'>for the period starting from</span>
                                    <span className='ml-1 font-bold text-slageBlue-600'>{momentConvertionUtcToLocalTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm')}</span> 
                                    <span className='ml-1'>to</span>
                                    <span className='ml-1 font-bold text-slageBlue-600'>{momentConvertionUtcToLocalTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm')}</span>
                                    <span className='ml-1'>(local time)</span>
                                </React.Fragment>
                                : null}
                            </p>
                        </div>
                        
                        <div className='self-center'>
                            <div className='flex justify-end'>
                                {state.listResponse && state.listResponse.length ?
                                    <div className='self-center mr-2'>
                                        <Search
                                            data={state.listResponse ? state.listResponse : []}
                                            topClassName={'border border-NeutralGray-900 py-2.5 pr-4 pl-3 rounded-full bg-white'}
                                            className={'rounded-full'}
                                            filteredData={(filteredListResponse) => setState(prevState => ({ ...prevState, filteredListResponse }))}
                                        />
                                    </div>
                                : null}
                                {state.filteredListResponse && state.filteredListResponse.length ?
                                    <div className='self-center' title="download">
                                        <CSVLink 
                                            data={state.filteredListResponse ? state.filteredListResponse : []} 
                                            headers={state.headers ? state.headers : []}
                                            filename={'job-lists.csv'}
                                            className={'self-center'}
                                            target='_blank'
                                        >
                                            <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center' />
                                        </CSVLink>
                                    </div>
                                : null}
                                {/* <button className={`cursor-pointer btn bg-green text-black self-center`} onClick={ () => setState(prevState => ({ ...prevState, showCreateJob: true }))}>Create&nbsp;Job</button> */}
                            </div>                                    
                        </div>
                    </div>

                    <div className='w-full mt-6'>
                        <div className='relative overflow-x-auto mt-3 rounded-t-lg shadow border border-lightGray-600'>
                            {state.filteredListResponse && state.filteredListResponse.length ?
                                <ResizeableDarkThemeTable
                                    columns={state.columns}
                                    data={state.filteredListResponse}
                                    perPage={20}
                                    selectedColor={'bg-backGroundGray'}
                                    dashboard={state.filteredListResponse && state.filteredListResponse.length ? true : false}
                                    sortByColumn={''}
                                    riskTooltip={[0]}
                                    onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedlistLicenses: tableRow }))}
                                    // tableSize={'table-sm'}
                                    checkForNextSetoffRecords={true}
                                    callToFetchNewRecord={() => {
                                        if(state.nextToken && state.nextToken.length) {
                                            setState(prevState => ({ ...prevState, callListlistLicensesFunction: true }))
                                        }
                                    }}
                                />
                            : 
                                <div className='flex justify-center m-4 text-black'>
                                    There are no data on this criteria. Please try adjusting your filter.
                                </div>
                            }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage