// components/common/AppWrapper.js
import React from 'react';
import Sidebar from './Sidebar'


function AppWrapper({ children }) {
  return (
    <div className=''>
      <Sidebar />
      <div className="pl-16">
        {children}
      </div>
    </div>
  );
}

export default AppWrapper;
