/*************************************************
 * Collider
 * @exports
 * @file CreateUpdateCatalogPanel.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, getAllTagsValues } from '../../../actions/commonActionNew'
import { listCatalogFilters, createCatalog, updateCatalog  } from '../../../actions/Collider/CatalogAction'
import { setHpcPropsDetails } from "../../../actions/Collider/HpcAction";
import { Store as CommonNotification } from 'react-notifications-component';
// import yaml from 'js-yaml'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import Button from '../../common/Form/Button';
import Textbox from '../../common/Form/Textbox';
import Textarea from '../../common/Form/Textarea';
import SelectOption from '../../common/Form/SelectOption';

const CreateUpdateCatalogPanel = ({ closeSidePanel, pageMode }) => {

    const clickOut = useRef();

    const [state, setState] = useState({
        selectedProvider: '',
        selectedAccount: '',
        selectedRegion: '',
        instanceTypes: [],
    })

    // const allStates = useSelector(state => state)
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const CreateUpdateCatalog = useSelector(state => state?.hpc?.hpcPropsDetails?.CreateUpdateCatalog || false);
    // var CreateUpdateCatalog = useSelector(state => state?.hpc?.hpcPropsDetails?.CreateUpdateCatalog || false);
    const dispatch = useDispatch(); // Create a dispatch function

    const callPageVist = useCallback(() => {
        let catalogDetails = CreateUpdateCatalog ? CreateUpdateCatalog : {}
        Object.entries(catalogDetails).forEach(([key, value]) => {
            if (key === "allowed_instance_types" && value && catalogDetails?.[key]?.length) {
                setState((prevState) => ({ ...prevState, allowed_instance_types: catalogDetails?.[key]?.map((item) => item.instance_type) }));
            }  else {
                if (key === "provider" && value){
                    setState((prevState) => ({ ...prevState, selectedProvider: value }));
                } if (key === "account_id" && value){
                    setState((prevState) => ({ ...prevState, selectedAccount: value }));
                } if (key === "region" && value){
                    setState((prevState) => ({ ...prevState, selectedRegion: value }));
                }
                setState((prevState) => ({ ...prevState, [key]: value }));
            }
        });
      }, [CreateUpdateCatalog]);

    useEffect(() => callPageVist(), [callPageVist]);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (providers) {
            setState(prevState => ({ ...prevState, providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : '')
            }));
        } else { 
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''
                        }));
                    }
                })
        }
    }, [dispatch, providers]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
			if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label], selectedAccount: propProjAccounts[label].length ? propProjAccounts[label][0].account_id : '' }));
            } 
        }
    }, [state.selectedProvider, dispatch, propProjAccounts]); 

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount) {
                label = ''
                params.account_id = state.selectedAccount
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label], selectedRegion: propProjRegions[label].length ? propProjRegions[label][0].region : '' }));
            } 
        }
    }, [state.selectedProvider, dispatch, propProjRegions, state.selectedAccount]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        let params = {}
        if (state.selectedProvider) {
            params.provider = state.selectedProvider.toLowerCase()
        } if (state.selectedAccount) {
            params.account_id = state.selectedAccount
        } if (state.selectedRegion) {
            params.region = state.selectedRegion
        } if (state.platform) {
            params.platform = state.platform
        }
        dispatch(listCatalogFilters(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({
                        ...prevState,
                        VPCIdOptions: response.vpc_ids ? response.vpc_ids : {},
                        subnetIdOptions: response.subnet_ids ? response.subnet_ids : [],
                        templateOptions: response.template ? response.template : [],
                        templateNameOptions: response.template_name ? response.template_name : {},
                        instanceTypes: response.instance_type ? response.instance_type : [],
                        keyPairs: response.key_pair ? response.key_pair : [],
                        operatingSystemOptions: response.os ? response.os : [],
                        imageIdOptions: response.image_ids ? response.image_ids : []
                    }))
                }
            })
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.platform])

    useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ 'key': state.tag_key }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
                    if(response) {
					    setState(prevState => ({ ...prevState, tagValues: response }))
                    }
				})
		}
	}, [state.tag_key, dispatch])

    useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let hasError = false
            let params = CreateUpdateCatalog
            params.provider = state.selectedProvider.toLowerCase()
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            if (!params.template_name){
                params.template_name = "default"
            }

            if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.catalog_name || !state.image_id || !state.vpc_id || !state.subnet_id || !state.allowed_instance_types || !state.template_name) {
                hasError = true
            }
            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

           if(!hasError) {
                if(pageMode === 'Create') {
                    dispatch(createCatalog(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in saving Catalog'

                            if(response && response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'Catalog Created Successfully'

                                setTimeout(() => closeSidePanel('refresh'), 2000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false,  callSaveFunction: false }))
                            }

                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
                            // setState(prevState => ({ ...prevState, createRespons: response }))
                        })
                } else {
                    dispatch(updateCatalog(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in updating Catalog'

                            if(response && response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'Catalog Updated Successfully'
                                setTimeout(() => closeSidePanel('refresh'), 2000)
                                setTimeout(() => closeSidePanel('refresh'), 2000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false,  callSaveFunction: false }))
                            }

                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
                        })
                }
            }
        }
    }, [state.callSaveFunction, dispatch, pageMode, closeSidePanel, CreateUpdateCatalog, state.account_id, state.allowed_instance_types, state.catalog_name, state.image_id, state.provider, state.region, state.subnet_id, state.template_name, state.vpc_id, state.selectedAccount, state.selectedProvider, state.selectedRegion]) 


	const handleCatalogInputChange = (label, value) => {
        let clusterConfiguration = CreateUpdateCatalog ? CreateUpdateCatalog : {};
        if (label === "selectedProvider") {
            clusterConfiguration.provider = state.selectedProvider.toLocaleLowerCase()
        } if (label === "selectedAccount") {
            clusterConfiguration.account_id = state.selectedAccount
        } if (label === "selectedRegion") {
            clusterConfiguration.region = state.selectedRegion
        } else {
            clusterConfiguration[label] = value
        }
        dispatch(setHpcPropsDetails("CreateUpdateCatalog", clusterConfiguration));
        setState((prevState) => ({ ...prevState, [label]: value }));
	}

	const removefromList = (value, listField) => {
        let filteredListItem = state[listField+''].filter(e => e !== value)

        let array = []
        filteredListItem.forEach(row => {
            let rowValue = {
                value: row,
                label: row
            }
            array.push(rowValue)
        })

        setState(prevState => ({ ...prevState, [listField+'']: filteredListItem }))
    }

    const addNewTagValue = () => {
        let selectedTags = state.tags ? state.tags : [];
    
        if (state.tag_key && state.tag_value && state.tag_value.length) {
          let dataRow = {};
          dataRow.key = state.tag_key;
          dataRow.value = state.tag_value;
          selectedTags.push(dataRow);
        }
    
        setState((prevState) => ({
          ...prevState,
          tags: selectedTags,
          tag_key: "",
          tag_value: "",
        }));
    
        handleCatalogInputChange("tags", selectedTags);
      };
    
      const removeTagSection = (tag) => {
        let filteredResult =
          state.tags && state.tags.filter((e) => e !== tag);
        setState((prevState) => ({ ...prevState, tags: filteredResult }));
    
        handleCatalogInputChange("tags", filteredResult);
      };

    const addNewSoftwaresIntalled = () => {
        let softwaresInstalled = state.installed_softwares ? state.installed_softwares : [];

        if (state.software && state.version && state.version.length) {
            let dataRow = {};
            dataRow.software = state.software;
            dataRow.version = state.version;
            softwaresInstalled.push(dataRow);
        }

        setState((prevState) => ({
            ...prevState,
            installed_softwares: softwaresInstalled,
            software: "",
            version: "",
        }));

        handleCatalogInputChange("installed_softwares", softwaresInstalled);
    };

    const removeSoftwaresIntalled = (tag) => {
        let filteredResult = state.installed_softwares && state.installed_softwares.filter((e) => e !== tag);
        setState((prevState) => ({ ...prevState, installed_softwares: filteredResult }));

        handleCatalogInputChange("installed_softwares", filteredResult);
    };
    
	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-4/5' ref={clickOut}>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl'>{pageMode} Catalog</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                    <div className='overflow-y-auto h-[calc(100vh-120px)] bg-white'>
                        <div className='p-8 h-full'>
                            <div className='flex flex-wrap'>
                                <div className='flex justify-start w-1/2 pr-4'>
                                    <SelectOption
                                        label={"Provider"}
                                        fields={["provider_name", "provider_name"]}
                                        options={state.providers}
                                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: '', selectedRegion: ''}))
                                            // handleCatalogInputChange("provider", convertToLowerCase(value))
                                        }}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        singleSelection={true}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                                <div className='flex justify-start w-1/2 pl-4'>
                                    <SelectOption
                                        label={"Account"}
                                        fields={["account_id", "account_name"]}
                                        options={state.accounts}
                                        selectedValues={state.selectedAccount ? state.selectedAccount : ''}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedAccount: value }))
                                            // handleCatalogInputChange("account_id", value)
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-wrap pt-8'>
                                <div className='flex justify-start w-1/2 pr-4'>
                                    <SelectOption
                                        label={"Region"}
                                        fields={["region", "name"]}
                                        options={state.regions}
                                        selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedRegion: value }))
                                            // handleCatalogInputChange("region", value)
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                            </div>

                            <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                            <p className='font-bold text-NeutralGray-800 text-[22px] pb-4'>Catalog Configurations</p>
                                <div className='flex flex-wrap'>
                                    <div className='lg:w-1/2 w-full lg:pr-4'>
                                        <Textbox
                                            label={"Catalog Name"}
                                            type="text"
                                            selectedValue={state.catalog_name ? state.catalog_name : ''}
                                            callback={(value) => {
                                                setState(prevState => ({ ...prevState, catalog_name: value }))
                                                handleCatalogInputChange("catalog_name", value)
                                            }}
                                            placeholder={'Enter catalog name'}
                                            manditory={true}
                                            hasError={state.inputValidationError}
                                            disabled={pageMode === 'Edit'} 
                                        />
                                        <p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.catalog_name}</p>
                                    </div>
                                </div>

                                <div className='flex flex-wrap pt-8'>
                                    <div className='w-full pt-4 lg:pt-0'>
                                        <Textarea
                                            label={'Description'}
                                            placeholder={'Enter description'}
                                            rows={4}
                                            wordLength={1000}
                                            callback={(value) => {
                                                setState(prevState => ({ ...prevState, description: value }))
                                                handleCatalogInputChange("description", value)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='flex flex-wrap pt-8'>
                                    <div className='flex justify-start w-1/2 pr-4'>
                                        <div className='w-full'>
                                            <SelectOption
                                                label={"Operating system"}
                                                options={state.operatingSystemOptions}
                                                selectedValues={state.platform ? state.platform : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, platform: value }))
                                                    handleCatalogInputChange("platform", value)
                                                }}
                                                singleSelection={true}
                                                manditory={true}
                                                hasError={state.inputValidationError}
                                                dropdownWidth={'w-full'}
                                                disabled={pageMode === 'Edit'}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex justify-start w-1/2 pr-4'>
                                        <div className='w-full'>
                                            <SelectOption
                                                label={"Template name"}
                                                options={state?.templateNameOptions?.[state?.platform] ? state?.templateNameOptions?.[state?.platform] : []}
                                                selectedValues={state.template_name ? state.template_name : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, template_name: value }))
                                                    handleCatalogInputChange("template_name", value)
                                                }}
                                                singleSelection={true}
                                                manditory={true}
                                                hasError={state.inputValidationError}
                                                dropdownWidth={'w-full'}
                                                disabled={pageMode === 'Edit'}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='flex flex-wrap pt-8'>
                                    <div className='flex flex-col justify-start w-1/2 pr-4'>
                                        <div className='w-full'>
                                            <SelectOption
                                                label={"Allowed instance types"}
                                                options={state.instanceTypes ? state.instanceTypes : []}
                                                selectedValues={state.allowed_instance_types ? state.allowed_instance_types : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, allowed_instance_types: value }))
                                                    handleCatalogInputChange("allowed_instance_types", value)
                                                }}
                                                manditory={true}
                                                hasError={state.inputValidationError}
                                                dropdownWidth={'w-full'}
                                                disabled={pageMode === 'Edit'}
                                            />
                                        </div>
                                        <div className="flex flex-wrap pt-4">
                                            {state.allowed_instance_types && state.allowed_instance_types.length
                                            ? state.allowed_instance_types.map((row) => {
                                                return (
                                                    <span className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">
                                                        {row}<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(row, 'allowed_instance_types') }/>
                                                    </span>
                                                );
                                                })
                                            : null}
                                        </div>
                                    </div>
                                    <div className='lg:w-1/2 w-full lg:pr-4'>
                                        <Textbox
                                            label={"Category"}
                                            type="text"
                                            selectedValue={state.category ? state.category : ''}
                                            callback={(value) => {
                                                setState(prevState => ({ ...prevState, category: value }))
                                                handleCatalogInputChange("category", value)
                                            }}
                                            placeholder={'Enter category'}
                                            manditory={true}
                                            hasError={state.inputValidationError}
                                            disabled={pageMode === 'Edit'}
                                        />
                                        <p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.category}</p>
                                    </div>
                                </div>

                                <div className='flex flex-wrap mt-8'>
                                    <div className='lg:pr-4 w-1/3'>
                                        <Textbox
                                            label={"Tags"}
                                            type="text"
                                            selectedValue={state.tag_key ? state.tag_key : ''}
                                            callback={(value) => {
                                                setState(prevState => ({ ...prevState, tag_key: value }))
                                            }}
                                            placeholder={'Enter tag key'}
                                        />
                                    </div>
                                    <div className='lg:pl-4 pt-4 mt-6 lg:pt-0 w-1/3'>
                                        <Textbox
                                            label={""}
                                            type="text"
                                            selectedValue={state.tag_value ? state.tag_value : ''}
                                            callback={(value) => {
                                                setState(prevState => ({ ...prevState, tag_value: value }))
                                            }}
                                            placeholder={'Enter tag value'}
                                        />
                                    </div>
                                    <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-4 mt-8 cursor-pointer' onClick={() => addNewTagValue()}/>
                                </div>
                                {state.tags && state.tags.length ? 
                                    <div className='flex flex-wrap mt-4'>
                                        {state.tags.filter(e => e.value !== 'All').map((tag, tIndex) => {
                                            return(
                                                <span key={'tag_'+tIndex} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all' id={'tag_'+tIndex}>
                                                    <div className='truncate'  style={{maxWidth: '250px'}}>
                                                        {tag.key +' : '+tag.value}
                                                    </div>
                                                    <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeTagSection(tag)}/>
                                                </span>
                                            )
                                        })}
                                    </div>
                                : null}
                            </div>

                            <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                            <p className='font-bold text-NeutralGray-800 text-[22px] pb-4'>Image Configurations</p>
                                <div className='flex flex-col'>
                                    <div className='flex justify-start w-1/2 pr-4'>
                                        <div className='w-full'>
                                            <SelectOption
                                                label={"Image id"}
                                                options={state.imageIdOptions ? state.imageIdOptions : []}
                                                selectedValues={state.image_id ? state.image_id : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, image_id: value }))
                                                    handleCatalogInputChange("image_id", value)
                                                }}
                                                singleSelection={true}
                                                manditory={true}
                                                hasError={state.inputValidationError}
                                                dropdownWidth={'w-full'}
                                                disabled={pageMode === 'Edit'} 
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='flex flex-wrap pt-8'>
                                    <div className='lg:w-1/3 lg:pr-4'>
                                        <Textbox
                                            label={"Softwares installed"}
                                            type="text"
                                            selectedValue={state.software ? state.software : ''}
                                            callback={(value) => {
                                                setState(prevState => ({ ...prevState, software: value }))
                                            }}
                                            placeholder={'Enter software name'}
                                        />
                                    </div>
                                    <div className='lg:w-1/3 lg:pl-4 pt-4 mt-6 lg:pt-0'>
                                        <Textbox
                                            label={""}
                                            type="text"
                                            selectedValue={state.version ? state.version : ''}
                                            callback={(value) => {
                                                setState(prevState => ({ ...prevState, version: value }))
                                            }}
                                            placeholder={'Enter software version'}
                                        />
                                    </div>
                                    <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-4 mt-8 cursor-pointer' onClick={() => addNewSoftwaresIntalled()}/>
                                </div>
                                {state.installed_softwares && state.installed_softwares.length ? 
                                    <div className='flex flex-wrap mt-4'>
                                        {state.installed_softwares.filter(e => e.version !== 'All').map((softwares, swIndex) => {
                                            return(
                                                <span software={'installed_softwares_'+swIndex} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all' id={'installed_softwares_'+swIndex}>
                                                    <div className='truncate'  style={{maxWidth: '250px'}}>
                                                        {softwares.software +' : '+softwares.version}
                                                    </div>
                                                    <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeSoftwaresIntalled(softwares)}/>
                                                </span>
                                            )
                                        })}
                                    </div>
                                : null}
                            </div>

                            <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                            <p className='font-bold text-NeutralGray-800 text-[22px] pb-4'>Network Configurations</p>
                                <div className='flex flex-wrap'>
                                    <div className='flex justify-start w-1/2 pr-4'>
                                        <div className='w-full'>
                                            <SelectOption
                                                label={"VPC id"}
                                                options={state.VPCIdOptions ?  Object.keys(state.VPCIdOptions) : []}
                                                selectedValues={state.vpc_id ? state.vpc_id : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, vpc_id: value }))
                                                    handleCatalogInputChange("vpc_id", value)
                                                }}
                                                singleSelection={true}
                                                manditory={true}
                                                hasError={state.inputValidationError}
                                                dropdownWidth={'w-full'}
                                                disabled={pageMode === 'Edit'} 
                                            />
                                        </div>
                                    </div>
                                    <div className='flex justify-start w-1/2 pr-4'>
                                        <div className='w-full'>
                                            <SelectOption
                                                label={"Subnet id"}
                                                options={state?.VPCIdOptions?.[state?.vpc_id] ? state?.VPCIdOptions?.[state?.vpc_id] : []}
                                                selectedValues={state.subnet_id ? state.subnet_id : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, subnet_id: value }))
                                                    handleCatalogInputChange("subnet_id", value)
                                                }}
                                                singleSelection={true}
                                                manditory={true}
                                                hasError={state.inputValidationError}
                                                dropdownWidth={'w-full'}
                                                disabled={pageMode === 'Edit'} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                                <p className='font-bold text-NeutralGray-800 text-[22px] pb-4'>Security Configurations</p>
                                <div className='flex flex-wrap'>
                                    <div className='flex justify-start w-1/2 pr-4'>
                                        <div className='w-full'>
                                            <SelectOption
                                                label={"Key pair name"}
                                                options={state.keyPairs ? state.keyPairs : []}
                                                selectedValues={state.key_pair_name ? state.key_pair_name : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, key_pair_name: value }))
                                                    handleCatalogInputChange("key_pair_name", value)
                                                }}
                                                singleSelection={true}
                                                manditory={true}
                                                hasError={state.inputValidationError}
                                                dropdownWidth={'w-full'}
                                                disabled={pageMode === 'Edit'} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                                <p className='font-bold text-NeutralGray-800 text-[22px] pb-4'>Domain Joining - Authetication Configurations</p>
                                <div className='flex flex-wrap'>
                                    <div className='lg:w-1/2 w-full lg:pr-4'>
                                        <Textbox
                                            label={"Domain Name"}
                                            type="text"
                                            selectedValue={state.domain_name ? state.domain_name : ''}
                                            callback={(value) => {
                                                setState(prevState => ({ ...prevState, domain_name: value }))
                                                handleCatalogInputChange("domain_name", value)
                                            }}
                                            placeholder={'Enter domain name'}
                                            manditory={true}
                                            hasError={state.inputValidationError}
                                            disabled={pageMode === 'Edit'} 
                                        />
                                        <p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.key_pair_name}</p>
                                    </div>
                                    <div className='lg:w-1/2 w-full lg:pl-4'>
                                        <Textbox
                                            label={"Secret arn"}
                                            type="text"
                                            selectedValue={state.secret_arn ? state.secret_arn : ''}
                                            callback={(value) => {
                                                setState(prevState => ({ ...prevState, secret_arn: value }))
                                                handleCatalogInputChange("secret_arn", value)
                                            }}
                                            placeholder={'Enter secret arn'}
                                            manditory={true}
                                            hasError={state.inputValidationError}
                                            disabled={pageMode === 'Edit'} 
                                        />
                                        <p className='text-primaryPurple-600 text-sm w-full'>{state.jobFilters && state.jobFilters.secret_arn}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full mt-4 p-8 shadow-lg bg-NeutralGray-700">
                        <div className={'flex justify-center'}>
                            <Button
                                classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                label={'Cancel'}
                                callback={() => 
                                    closeSidePanel()
                                }
                            />
                            {state.saveLoading ?
                                <Button
                                    classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', margin: 'ml-4', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                    label={state.pageMode === 'Edit' ? 'Updating' : 'Saving'}
                                    loading={true}
                                />
                            :
                                <Button
                                    classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', margin: 'ml-4', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                    label={state.pageMode === 'Edit' ? 'Update' : 'Save'}
                                    callback={() => {
                                        setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateUpdateCatalogPanel