import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { momentDateGivenFormat } from '../../../utils/utility'
import moment from 'moment';
import { DateRange } from 'react-date-range';
import _ from 'lodash'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {Icon} from "@iconify/react";

import ApexBarChart from '../../common/charts/ApexBarChart'
import ApexDonutChart from '../../common/charts/ApexDonutChart';
import ApexLineChartSpendBoard from '../../common/charts/ApexLineChartSpendBoard';

import { listUserDetails, listClusterFilters } from '../../../actions/commonActionNew';
import { getHpcReorts } from '../../../actions/Collider/HpcReportAction'
import { DURATION_OPTIONS } from '../../../utils/constants';
import PageLoading from '../../common/PageLoading';
import { LoadingCircle } from '../../common/LoadingCiricle';
import SelectOption from '../../common/Form/SelectOption';

const DiskReportSection = (props) => {
    const clickOut = useRef();
    const sectionRef = useRef();
    const durationRef = useRef();
    const tableRef = useRef();
    const [state, setState] = useState({
        showLoading: true,
    })

    const [dateState, setDateState] = useState([{
          startDate: new Date(),
          endDate: null,
          key: 'selection'
    }]);

    const dispatch = useDispatch(); // Create a dispatch function
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel()
        }
    }, [props]);

    useEffect(() => {
        let selectedAccount = props.selectedAccount
        let selectedRegion = props.selectedRegion
        let selectedClusters = props.selectedClusters
        let datePickerStartDate = props.datePickerStartDate
        let datePickerEndDate = props.datePickerEndDate
        let selectedUser = ''

        if(props.selectedReportInput) {
            if(props.selectedReportInput && props.selectedReportInput.account_id && props.selectedReportInput.account_id.length) {
                selectedAccount = props.selectedReportInput.account_id
            }

            if(props.selectedReportInput && props.selectedReportInput.region && props.selectedReportInput.region.length) {
                selectedRegion = props.selectedReportInput.region
            }
            
            if(props.selectedReportInput && props.selectedReportInput.cluster_name && props.selectedReportInput.cluster_name.length) {
                selectedClusters = props.selectedReportInput.cluster_name
            }
            
            if(props.selectedReportInput && props.selectedReportInput.user_name && props.selectedReportInput.user_name.length) {
                selectedUser = props.selectedReportInput.user_name
            }
        }

        if(selectedAccount && selectedAccount.length) {
            setState(prevState => ({ ...prevState, selectedAccount}))
        }

        if(selectedRegion && selectedRegion.length) {
            setState(prevState => ({ ...prevState, selectedRegion}))
        }

        if(selectedClusters && selectedClusters.length) {
            setState(prevState => ({ ...prevState, selectedClusters}))
        }

        if(selectedUser) {
            setState(prevState => ({ ...prevState, selectedUser}))
        }

        setState(prevState => ({ 
            ...prevState,
            showLoading: true,
            selectedUser,
            datePickerStartDate,
            datePickerEndDate,
            callHpcReports: true,
            callClusterList: true
        }))
    }, [props])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown])

    useEffect(() => {
        if(props.selectedProvider) {
            setState(prevState => ({ ...prevState, callClusterList: false }));
            let params = {};
            params.provider = props.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response }));
                    }
            })
        }
    }, [dispatch, props.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        if(props.selectedProvider) {
            setState(prevState => ({ ...prevState, callClusterList: false }));
            let params = {};
            params.provider = props.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters && state.selectedClusters.length) {
                params.region = state.selectedClusters
            }
            dispatch(listUserDetails(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length ? response.results : []
                        setState(prevState => ({ ...prevState, users: results }));
                    }
            })
        }
    }, [dispatch, props.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters])

    useEffect(() => {
        if(state.callHpcReports) {
            setState(prevState => ({ ...prevState, callHpcReports: false }))
            let params = {}
            params.provider = props.selectedProvider.toLowerCase()
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters
            }

            if(state.selectedUser) {
                params.user_name = state.selectedUser
            } else if(state.selectedUserDetails && state.selectedUserDetails.user_name) {
                params.user_name = state.selectedUserDetails.user_name
            }

            if(props.selectedReport && props.selectedReport.report_id) {
                params.report_id = props.selectedReport.report_id
            }
            
            params.start_time = state.datePickerStartDate
            params.end_time = state.datePickerEndDate            
            
            if(props.selectedReportInput && !state.searchInput) {
                if(props.selectedReportInput && props.selectedReportInput.account_id && props.selectedReportInput.account_id.length) {
                    params.account_id = props.selectedReportInput.account_id
                }

                if(props.selectedReportInput && props.selectedReportInput.region && props.selectedReportInput.region.length) {
                    params.region = props.selectedReportInput.region
                }
                
                if(props.selectedReportInput && props.selectedReportInput.cluster_name && props.selectedReportInput.cluster_name.length) {
                    params.cluster_name = props.selectedReportInput.cluster_name
                }
                
                if(props.selectedReportInput && props.selectedReportInput.user_name && props.selectedReportInput.user_name.length) {
                    params.user_name = props.selectedReportInput.user_name
                }
            }
    
            dispatch(getHpcReorts(params))
                .then((response) => {
                    if(response) {
                        let results = response.results ? response.results : {}
                        setState(prevState => ({ ...prevState, reportDetails: results, structureReports: true, showLoading: false }))
                    }
                })
        }
    }, [state.callHpcReports, dispatch, state.datePickerEndDate, state.datePickerStartDate, state.searchInput, state.selectedAccount, state.selectedClusters, state.selectedRegion, state.selectedUser, state.selectedUserDetails, props])

    useEffect(() => {
        if(state.structureReports) {
            setState(prevState => ({ ...prevState , structureReports: false }))
            let graph1 = state.reportDetails.graph1 ? state.reportDetails.graph1 : {}
            let graphData1 = {}
            graphData1.description = graph1.description ? graph1.description : ''
            graphData1.graph_type = graph1.graph_type ? graph1.graph_type : ''
            if(graph1.graph_type === 'donut') {
                let donut = []
                let donut_total_count = 0
                let data = graph1.data ? graph1.data : []
                graph1 && graph1.labels && graph1.labels.forEach((lab, index) => {
                    let donutRow = {}
                    donutRow.item_name = lab
                    donutRow.item_count = data[index] ? data[index] : 0
                    donut.push(donutRow)
                    donut_total_count += data[index] ? data[index] : 0 
                })
                donut = _.orderBy(donut, ['item_count'], ['desc'])
        
                graphData1.graphValue = {
                    'items': donut,
                    'label': 'Total',
                    'total_count': donut_total_count
                }
            } else {
                graphData1.labels = graph1.labels ? graph1.labels : []
                graphData1.data = graph1.data ? graph1.data : []
                graphData1.tooltip = graph1.tooltip ? graph1.tooltip : {}
                // graphData1.unit = '%'
                // graphData1.prefixUnit = '£'
            }



            let graph2 = state.reportDetails.graph2 ? state.reportDetails.graph2 : {}
            let graphData2 = {}
            graphData2.description = graph2.description ? graph2.description : ''
            graphData2.graph_type = graph2.graph_type ? graph2.graph_type : ''
            if(graph2.graph_type === 'donut') {
                let donut = []
                let donut_total_count = 0
                let data = graph2.data ? graph2.data : []
                graph2 && graph2.labels && graph2.labels.forEach((lab, index) => {
                    let donutRow = {}
                    donutRow.item_name = lab
                    donutRow.item_count = data[index] ? data[index] : 0
                    donut.push(donutRow)
                    donut_total_count += data[index] ? data[index] : 0 
                })
                donut = _.orderBy(donut, ['item_count'], ['desc'])
        
                graphData2.graphValue = {
                    'items': donut,
                    'label': 'Total',
                    'total_count': donut_total_count
                }
            } else {
                let data = {}
                data.labels = graph2.trends && graph2.trends.length && graph2.trends[0].dates ? graph2.trends[0].dates : []

                if(graph2.trends && graph2.trends.length) {
                    graph2.trends.forEach(trd => {
                        Object.entries(trd).forEach(([key, value]) => {
                            if(key !== 'dates') {
                                data[key] = value
                            }
                        })
                    })
                }
                graphData2.data = data
            }

            setState(prevState => ({ ...prevState, graphData1, graphData2 }))

            let tableDetails = state.reportDetails.table ? state.reportDetails.table : {}

            let tableData = tableDetails.table_data ? tableDetails.table_data : []

            let tableFields = tableDetails.fields ? tableDetails.fields : []
            let dynamicTableColumn = []
            tableFields.forEach(row => {
                let width = 150
                if(row.length > 10) {
                    width = 200
                }
                let dataRow = {}
                dataRow.Header = row
                dataRow.accessor = row
                // dataRow.Cell = cellInfo => (
                //     <span className={`badge ${
                //         cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ?
                //             'risk-badge-'+cellInfo.row.original.severity.toLowerCase()
                //         : 'badge-secondary'
                //         }`}>
                //         {cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ? (cellInfo.row.original.severity.toLowerCase() === 'low' ? 'L' : cellInfo.row.original.severity.toLowerCase() === 'medium' ? 'M' :  cellInfo.row.original.severity.toLowerCase() === 'high' ? 'H' : cellInfo.row.original.severity.toLowerCase() === 'critical' ? 'C' : '-') : '-'}
                //     </span>)
                dataRow.width = width
                dynamicTableColumn.push(dataRow)
            })

            setState(prevState => ({ ...prevState, tableData, filterTableData: tableData, dynamicTableColumn, tableDetails }))
        }
    }, [state.structureReports, state.reportDetails])

    const downloadPDF = async (index, item) => {
        const chartContainer1 = document.getElementById('chartContainer1');
        const chartContainer2 = document.getElementById('chartContainer2');
        const chartContainer3 = document.getElementById('chartContainer3');
        // const table = document.getElementById('table');
        const table = tableRef.current;
    
        if (!chartContainer1) {
          console.error("Chart container not found.");
          return;
        }
    
        try {
            const canvas1 = await html2canvas(chartContainer1);
            const canvas2 = await html2canvas(chartContainer2);
            const canvas3 = chartContainer3 ? await html2canvas(chartContainer3) : ''
            
            const tableData = await html2canvas(table)
            // const pdf = new jsPDF();
            const pdf = new jsPDF('p', 'mm', 'a4');
            
            // let selectedPeriod = state.periodOptions.filter(e => e.value === state['selected_Period_' + index]).length ? state.periodOptions.filter(e => e.value === state['selected_Period_' + index])[0].label : state['selected_Period_' + index]
            let selectedPeriod = ''
            
            pdf.text(`${selectedPeriod} report for the`, 10, 10) 
            // pdf.text(`${selectedPeriod} report for the user ${item.userName}`, 10, 10) 
            // pdf.text(`spanning from ${momentDateGivenFormat(props.startDate, 'DD MMM YYYY')} to ${momentDateGivenFormat(props.endDate, 'DD MMM YYYY')}`, 10, 20)
            // pdf.text(`Report for User - ${item.userName}`, 15, 10);
            // pdf.text(`Period from - ${momentConvertionUtcToLocalTime(props.startDate, 'DD MMM YYYY') + ' to '+momentConvertionUtcToLocalTime(props.endDate, 'DD MMM YYYY') }`, 15, 10);
            // pdf.text(`Frequency from - ${momentConvertionUtcToLocalTime(props.startDate, 'DD MMM YYYY') + ' to '+momentConvertionUtcToLocalTime(props.endDate, 'DD MMM YYYY') }`, 15, 10);
            if(canvas3) {
                pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 5, 30, 65, 60);
                pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 75, 30, 65, 60);
                pdf.addImage(canvas3.toDataURL('image/png'), 'PNG', 140, 30, 65, 60);
                // pdf.addImage(tableData.toDataURL('image/png'), 'PNG', 5, 100, 200, 80);
            } else {
                pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 5, 30, 100, 60);
                pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 110, 30, 100, 60);
                // pdf.addImage(tableData.toDataURL('image/png'), 'PNG', 5, 100, 200, 80);
            }

            const pageHeight = 295; // A4 page height in mm
            const pageWidth = 210; // A4 page width in mm
            const imgHeight = (100 + tableData.height) * pageWidth / tableData.width;
            let heightLeft = imgHeight;
        
            const pdfPageCount = Math.ceil(heightLeft / pageHeight);
            
            for (let page = 1; page <= pdfPageCount; page++) {
                // Get the height of the content for the current page
                const yStart = (page - 1) * pageHeight;
                const height = Math.min(heightLeft, pageHeight);
                heightLeft -= height;
          
                // Add a new page to the PDF
                pdf.addPage();
          
                // Draw the content on the current page
                pdf.addImage(tableData, 'image/png', 0, yStart, pageWidth, height, undefined, 'FAST');
            }

            // html2canvas(table).then(tab => {
            //     const imgData = tab.toDataURL('image/png');
            //     const pdf = new jsPDF('p', 'mm', 'a4');
            //     pdf.addImage(imgData, 'PNG', 10, 10, 190, 100);
            // });
        
            const timestamp = new Date().getTime();
            pdf.save(`report_${timestamp}.pdf`);

            setState(prevState => ({ ...prevState, showDownloadLoading: false }))

        } catch (error) {
          console.error("Error capturing charts:", error);
          setState(prevState => ({ ...prevState, showDownloadLoading: false }))
        }
    }

    const handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
                selectedDuration: "custom",
                callHpcReports: true
            }));    
        }

        setDateState([date.selection])
    };

    useEffect(() => {
        const currentMonth = new Date().getMonth(); // Month is zero-based (0 for January, 1 for February, ..., 11 for December)

       	let datePickerStartDate = props.datePickerStartDate
        let datePickerEndDate = props.datePickerEndDate
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "currentYear") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "lastMonth") {
            datePickerStartDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-01 00:00:00')            
            datePickerEndDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "quarterly") {
            if (currentMonth >= 0 && currentMonth <= 2) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-03-31 23:59:59')
            } else if (currentMonth > 2 && currentMonth < 6) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-04-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else if (currentMonth > 5 && currentMonth < 9) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-09-30 23:59:59')
            } else {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-10-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "half-yearly") {
            if (currentMonth >= 0 && currentMonth <= 5) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "yearly") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }
        if(state.selectedDuration !== 'custom') {
            setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate, callHpcReports: true }));

            let dateSelection= {
				startDate: new Date(datePickerStartDate),
				endDate: new Date(datePickerEndDate),
				key: 'selection'
			}

			setDateState([dateSelection])
        }

	}, [state.selectedDuration, props])

	const handleChildClick = (event, type, dropdownType, section) => {	
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            props.closeSidePanel()
        }    	
        if (sectionRef.current && !sectionRef.current.contains(event.target)) {
            props.closeSidePanel()
        }
        
		if (durationRef.current && !durationRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-4/6 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-white'>Reports</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-white cursor-pointer self-center' width={24} height={24} onClick={() => props.closeSidePanel()} />
                            </div>
                        </div>
                        <div className='w-full text-sm pt-2'>
                            <div className='flex flex-wrap'>
                                <p className='mb-1 text-NeutralGray-400 pr-4'>Id: <span className='text-white font-bold'>{props.selectedReport && props.selectedReport.report_id ? props.selectedReport.report_id : ''}</span></p>
                                <div className="pl-4">
                                    <p className='mb-1 text-NeutralGray-400 pr-4'>Description: <span className='text-white font-bold'>{props.selectedReport && props.selectedReport.title ? props.selectedReport.title : ''}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-8 overflow-y-auto'>
                        <p className="flex flex-wrap text-sm leading-7">
                            {props && props.selectedProvider ?
                                <span className="self-end">Showing report obtained from the <span className="font-bold"> {props.selectedProvider.toUpperCase()} </span> provider with the account</span>
                            : null}
                            <div className='w-32 self-end mx-1'>
                                <SelectOption
                                    label={""}
                                    fields={["account_id", "account_name"]}
                                    options={propProjAccounts[props.selectedProvider] ? propProjAccounts[props.selectedProvider] : []}
                                    widthClass={'minWidth220'}
                                    selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedAccount: value, callClusterList: true }))}
                                    singleSelection={false}
                                    manditory={false}
                                    dropdownWidth={'min-w-32'}
                                    inputType={state.pageType}
                                    classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                />
                            </div>
                            <span className='ml-1 self-end mr-2'>in the</span>
                            <div className='w-32 self-end mx-1'>
                                <SelectOption
                                    fields={["region", "name"]}
                                    options={propProjRegions[props.selectedProvider] ? propProjRegions[props.selectedProvider] : []}
                                    widthClass={'minWidth220'}
                                    selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value, callClusterList: true }))}
                                    singleSelection={false}
                                    manditory={false}
                                    dropdownWidth={'min-w-32'}
                                    inputType={state.pageType}
                                    classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                />
                            </div>
                            <span className='ml-1 self-end mr-2'>region</span>
                            <div className='w-32 self-end mx-1'>
                                <SelectOption
                                    label={""}
                                    options={state.clusters}
                                    widthClass={'minWidth220'}
                                    selectedValues={state.selectedClusters ? state.selectedClusters : []}
                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedClusters: value, callClusterList: true }))}
                                    singleSelection={false}
                                    manditory={false}
                                    dropdownWidth={'min-w-32'}
                                    inputType={state.pageType}
                                    classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                />
                            </div>
                            <span className='ml-1 self-end mr-2'> cluster by the user</span>
                            <div className='w-32 self-end mx-1'>
                                <SelectOption
                                    label={""}
                                    fields={["userName", "userName"]}
                                    options={state.users}
                                    widthClass={'minWidth220'}
                                    selectedValues={state.selectedUser ? state.selectedUser : []}
                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedUser: value, callClusterList: true }))}
                                    manditory={false}
                                    dropdownWidth={'min-w-32'}
                                    inputType={state.pageType}
                                    classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                />
                            </div>
                            <p className="border border-primaryPurple-600 rounded-full px-2 min-w-28 text-center self-end text-primaryPurple-600 mr-2 py-1 break-all mt-2" onClick={() => {
                                if(!state.showLoading) {
                                    setState(prevState => ({ ...prevState, searchInput: true, callHpcReports: true, showLoading: true }))}
                                }
                            }>Search</p>
                        </p>
                        <div className='w-full flex justify-end mb-2'>
                            <div className={`md:w-5/12 w-full md:mt-0 flex justify-end mb-1`}>
                                <small className='self-center text-base mr-2'>Duration</small>
                                <span className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 text-black mr-2 py-1'>
                                    <div className='min-w-32 self-end mx-1'>
                                        <SelectOption
                                            fields={["value", "label"]}
                                            options={DURATION_OPTIONS}
                                            selectedValues={state.selectedDuration ? state.selectedDuration : ''}
                                            callbackMultiSelect={(value) => {
                                                if(value !== 'custom') {
                                                    setState(prevState => ({ ...prevState, selectedDuration: value }))
                                                } else {
                                                    setState(prevState => ({ ...prevState, showDateRangePicker: value === 'custom' ? true : false }))
                                                }
                                            }}
                                            singleSelection={true}
                                            manditory={false}
                                            dropdownWidth={'w-fit'}
                                            inputType={state.pageType}
                                            classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                        />
                                        {state.showDateRangePicker ?
                                            <div className="absolute z-50 right-20 mt-5" ref={durationRef}>
                                                <div className='relative'>
                                                    <div className="bg-white border border-DeepPurpleTints-600 shadow-lg text-black">
                                                        <DateRange
                                                            editableDateInputs={true}
                                                            onChange={handleSelect}
                                                            moveRangeOnFirstSelection={false}
                                                            ranges={dateState}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        : null}
                                    </div>
                                </span>
                            </div>
                            <p className='flex bg-pumpkin-600 py-2.5 pr-4 pl-3 text-white self-center cursor-pointer mr-3 rounded-full text-center' onClick={() => {
                                if(!state.showDownloadLoading) {
                                    setState(prevState => ({ ...prevState, showDownloadLoading: true })) 
                                    downloadPDF(state)
                                }
                            }}>
                                {state.showDownloadLoading ?
                                    <LoadingCircle 
                                        color={"text-white"}
                                    />
                                : null}
                                Download
                            </p>

                            {state.selectedUserDetails && state.selectedUserDetails.user_name ?
                                <p className={`bg-DeepPurpleTints-600 py-1 pr-4 pl-3 text-white rounded-full self-center cursor-pointer mr-3 min-w-28 text-center`} onClick={() => setState(prevState => ({ ...prevState, selectedUserDetails: {}, callHpcReports: true, showLoading: true, graphData1: {}, graphData2: {}, graphData3: {} }))}>Back</p>
                            : null}
                        </div>
                        
                        {state.showLoading ?
                            <div className='flex justify-center m-4'>
                                <LoadingCircle />
                            </div>
                        :
                            <React.Fragment>
                            <div className='flex flex-wrap w-full items-stretch'>
                                <div className={`md:w-5/12 md:pr-2 w-full`} id={'chartContainer1'}>
                                    {state.graphData1 && Object.entries(state.graphData1).length ?
                                        <div className='bg-NeutralGray-100 border border-NeutralGray-500 rounded-2xl p-4 h-full'>
                                            <small className='mt-1 mb-0 small text-lightGray'>{state.graphData1.description ? state.graphData1.description :''}</small>
                                            <div className='mb-n4'> 
                                                {state.graphData1.graph_type === 'horizontal' ?
                                                    <ApexBarChart
                                                        graphData={state.graphData1}
                                                        sparkline={false}
                                                        yaxis={true}
                                                        yaxisLabel={true}
                                                        xaxis={true}
                                                        xaxisFormat={'string'}
                                                        xaxisLabel={true}
                                                        axisLabelColor={'#495057'}
                                                        paddingLeft={0}
                                                        legend={false}
                                                        stacked={false}
                                                        height={275}
                                                        horizontal={true}
                                                        barHeight={'40%'}
                                                        barEndShape={'rounded'}
                                                        barRadius={8}
                                                        columnWidth={'25%'}
                                                        showTitle={true}
                                                        //'#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80'
                                                        gradient={true}
                                                        gradientColor={['#2560B7', '#7551C2']}
                                                        hideTooltipValue={true}
                                                        // backgroundBarShape={'F'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={false}
                                                        className={'transparentTooltip'}
                                                        dataLabels={true}
                                                        dataLabelsTextAnchor={'middle'}
                                                        dataLabelPosition={''}
                                                    />
                                                : state.graphData1.graph_type === 'vertical_stacked' ?
                                                    <ApexLineChartSpendBoard
                                                        graphData={state.graphData1}
                                                        sparkline={false}
                                                        yaxis={false}
                                                        yaxisLabel={true}
                                                        xaxis={true}
                                                        xaxisFormat={'categoryString'}
                                                        xaxisLabel={true}
                                                        axisLabelFontSize={'9px'}
                                                        axisLabelColor={'#B8BBBE'}
                                                        paddingLeft={-25}
                                                        legend={false}
                                                        stacked={true}
                                                        height={275}
                                                        horizontal={false}
                                                        barHeight={'40%'}
                                                        barEndShape={'flat'}
                                                        columnWidth={'25%'}
                                                        gradient={true}
                                                        gradientColor={['#039BE5', '#5F5BA2']}
                                                        hideTooltipValue={true}
                                                        backgroundBarShape={'flat'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={true}
                                                        className={"transparentTooltip"}
                                                        colors={["#9A77D1", "#2D323E"]}
                                                    />
                                                : state.graphData1.graph_type === 'donut' ?
                                                    <div className='flex justify-center'>
                                                        <ApexDonutChart
                                                            graphData={state.graphData1.graphValue}
                                                            legend={false}
                                                            showTotal={true}
                                                            showTotalLable={true}
                                                            showGraphHeading={false}
                                                            height={250}
                                                            width={250}
                                                            size={'70%'}
                                                            gradient={false}
                                                            gradientColor={['#A88CCC', '#D88ACF']}
                                                            colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                                            className={"transparentTooltip"}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                    : null}
                                </div>
                                <div className={`md:w-5/12 md:px-2 w-full`} id={'chartContainer2'}>
                                    {state.graphData2 && Object.entries(state.graphData2).length ?
                                        <div className='bg-NeutralGray-100 border border-NeutralGray-500 rounded-2xl p-4 h-full'>
                                            <small className='mt-1 mb-0 small text-lightGray'>{state.graphData2.description ? state.graphData2.description :''}</small>
                                            <div className='mb-n4'>
                                                {state.graphData2.graph_type === 'horizontal' ?
                                                    <ApexBarChart
                                                        graphData={state.graphData2}
                                                        sparkline={false}
                                                        yaxis={true}
                                                        yaxisLabel={true}
                                                        xaxis={true}
                                                        xaxisFormat={'string'}
                                                        xaxisLabel={true}
                                                        axisLabelColor={'#999999'}
                                                        paddingLeft={0}
                                                        legend={false}
                                                        stacked={false}
                                                        height={275}
                                                        horizontal={true}
                                                        barHeight={'40%'}
                                                        barEndShape={'rounded'}
                                                        barRadius={8}
                                                        columnWidth={'25%'}
                                                        showTitle={true}
                                                        //'#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80'
                                                        gradient={true}
                                                        gradientColor={['#0057b8', '#8e3a80']}
                                                        hideTooltipValue={true}
                                                        // backgroundBarShape={'F'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={false}
                                                        className={'transparentTooltip'}
                                                        dataLabels={true}
                                                        dataLabelsTextAnchor={'middle'}
                                                        dataLabelPosition={''}
                                                    />
                                                : state.graphData2.graph_type === 'vertical_stacked' ?
                                                    <ApexLineChartSpendBoard
                                                        graphData={state.graphData2.data}
                                                        sparkline={false}
                                                        yaxis={true}
                                                        yaxisLabel={true}
                                                        xaxis={true}
                                                        xaxisFormat={'categoryString'}
                                                        xaxisLabel={true}
                                                        axisLabelFontSize={'9px'}
                                                        axisLabelColor={'#B8BBBE'}
                                                        paddingLeft={-5}
                                                        legend={false}
                                                        stacked={true}
                                                        height={275}
                                                        horizontal={false}
                                                        barHeight={'40%'}
                                                        barEndShape={'flat'}
                                                        columnWidth={"25%"}
                                                        gradient={true}
                                                        gradientColor={['#039BE5', '#5F5BA2']}
                                                        hideTooltipValue={true}
                                                        backgroundBarShape={'flat'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={true}
                                                        className={"transparentTooltip"}
                                                        colors={['#693EBC', '#775BA2', '#9A77D1', '#039BE5', '#43B02A', '#F75E3F']}
                                                    />
                                                : state.graphData2.graph_type === 'donut' ?
                                                    <div className='flex justify-center'>
                                                        <ApexDonutChart
                                                            graphData={state.graphData2.graphValue}
                                                            legend={false}
                                                            showTotal={true}
                                                            showTotalLable={true}
                                                            showGraphHeading={false}
                                                            height={250}
                                                            width={250}
                                                            size={'70%'}
                                                            gradient={false}
                                                            gradientColor={['#A88CCC', '#D88ACF']}
                                                            colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                                            className={"transparentTooltip"}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                    : null}
                                </div>
                                <div className={`md:w-2/12 w-full self-center h-full`} id={'chartContainer3'}>
                                    <div className="text-base w-full border border-NeutralGray-500 rounded-2xl p-4 flex flex-col justify-between h-full">
                                        <div className="flex">
                                            <div className="py-1 w-full">
                                                <p className="b-block mb-0 text-DeepPurpleTints-600">Disk Capacity</p>
                                                <p className="mb-0 text-black">
                                                    {state.reportDetails && state.reportDetails.disk_usage && state.reportDetails.disk_usage.disk_capacity ? state.reportDetails.disk_usage.disk_capacity : 0}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="py-1 w-full">
                                                <p className="b-block mb-0 text-DeepPurpleTints-600">Disk Usage</p>
                                                <p className="mb-0 text-black">{state.reportDetails && state.reportDetails.disk_usage && state.reportDetails.disk_usage.total_disk_usage ? state.reportDetails.disk_usage.total_disk_usage : 0}</p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="py-1 w-full">
                                                <p className="b-block mb-0 text-DeepPurpleTints-600">Avg Disk Usage Per User</p>
                                                <p className="mb-0 text-black">
                                                    {state.reportDetails && state.reportDetails.disk_usage && state.reportDetails.disk_usage.average_disk_usage ? state.reportDetails.disk_usage.average_disk_usage : 0}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-wrap mx-0'>
                                <div className='w-full'>
                                    <div className='bg-white mt-2'>
                                        <div className='w-full px-3 py-1 mb-4' ref={tableRef} id={'table'}>
                                            <p className='text-black text-base mb-1'>{state.tableDetails && state.tableDetails.description ? state.tableDetails.description : ''}</p>
                                            <table className="w-full text-left">
                                                <thead className="bg-gray-50">
                                                    <tr className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600'>
                                                        {state.tableDetails && state.tableDetails.fields ?
                                                            state.tableDetails.fields.map(head => {
                                                                return(
                                                                    <th key={"head_"+head} className='tracking-wider px-3 py-3 text-left'>{head}</th>
                                                                )
                                                            })
                                                        
                                                        : null}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {state.tableDetails && state.tableDetails.table_data ?
                                                        state.tableDetails.table_data.map((row, index)=> {
                                                            return(
                                                                <tr key={"head1_"+index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-cultured-600'}`} onClick={() => {
                                                                    if(row.user_name) {
                                                                        setState(prevState => ({ ...prevState, selectedUserDetails: row, callHpcReports: true, showLoading: true, graphData1: {}, graphData2: {} }))
                                                                    }
                                                                }}>
                                                                    {state.tableDetails && state.tableDetails.fields ?
                                                                        state.tableDetails.fields.map((head, hIndex) => {
                                                                            return(
                                                                                <td key={"head2_"+hIndex} className={`py-2.5 pl-4 text-left text-md`}>
                                                                                    {row[head]}
                                                                                </td>
                                                                            )
                                                                        })
                                                                    : null}
                                                                </tr>
                                                            )
                                                        })
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    
    )
}
export default DiskReportSection;
    