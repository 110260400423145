/*************************************************
 * Collider
 * @exports
 * @file ReviewSection.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
// import { useCallback } from 'react-redux'; // Import useDispatch hook to dispatch actions

const ReviewSection = (props) => {
    const [state, setState] = useState({})
    const createUpdateCluster = useSelector(
        (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
      );

    const callPageVist = useCallback(() => {
        let clusterConfiguration = createUpdateCluster ? createUpdateCluster : {};
          if (clusterConfiguration) {
            Object.entries(clusterConfiguration).forEach(([key, value]) => {
             setState((prevState) => ({ ...prevState, [key]: value }));
            });
          }
      }, [createUpdateCluster]);

      useEffect(() => callPageVist(), [callPageVist]);
    const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();

        let clickedChild = [];
        if (type === "child") {
          if (state.clickedChild && state.clickedChild.length) {
            state.clickedChild.forEach((row) => {
              setState((prevState) => ({ ...prevState, [row]: false }));
            });
          }
          clickedChild.push(section);
          setState((prevState) => ({
            ...prevState,
            [section]:
              dropdownType === "singleDropDown" && state[section] ? false : true,
            clickedChild,
          }));
        } else if (type === "parent") {
          if (state.clickedChild && state.clickedChild.length) {
            state.clickedChild.forEach((row) => {
              setState((prevState) => ({ ...prevState, [row]: false }));
            });
          }
          setState((prevState) => ({ ...prevState, clickedChild }));
        }
      };

    return ( 
        <div onClick={(event) => handleChildClick(event, "parent")}>
            <div className="rounded-2xl bg-PrimaryTintsPurple-600 border border-NeutralGray-500 p-6 mb-3">
            <p className={`text-primaryPurple-600 text-[22px] font-bold uppercase`}>Cluster Configuration</p>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">{props.pageMode === 'Template' ? 'Template ' : 'Cluster '}
                    name</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_name ? createUpdateCluster.cluster_name : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Operating System</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.os ? createUpdateCluster.cluster_configuration.os : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Custom AMI</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.custom_ami ? createUpdateCluster.cluster_configuration.custom_ami : "Nil"}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Simultaneous multitreading</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.disable_simultaneous_multitreading ? "Enabled" : "Disabled"}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Detailed monitoring</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.detailed_monitoring ? "Enabled" : "Disabled"}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Cloudwatch dashboard</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.cloudwatch_dashboard_enabled ? "Enabled" : "Disabled"}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Cloudwatch logs</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.cloudwatch_logs_enabled ? "Enabled" : "Disabled"}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Scaledown idle time (minutes)</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.scaledown_idle_time ? createUpdateCluster.cluster_configuration.scaledown_idle_time : ""}</span>
                </div>
                {createUpdateCluster?.cluster_configuration?.cloudwatch_logs_enabled ? 
                <React.Fragment>
                    <div className="flex justify-between mt-4">
                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Deletion Policy</span>
                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.cloudwatch_logs_deletion_policy ? createUpdateCluster.cluster_configuration.cloudwatch_logs_deletion_policy : ""}</span>
                    </div>
                    <div className="flex justify-between mt-4">
                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Retention in days</span>
                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.cloudwatch_logs_retention_in_days ? createUpdateCluster.cluster_configuration.cloudwatch_logs_retention_in_days : ""}</span>
                    </div>
                </React.Fragment>

                : null}
                {createUpdateCluster && createUpdateCluster.tags ?
                    <div className="flex justify-between mt-4">
                        <span className="justify-start lg:w-1/3 md:w-1/2 text-NeutralGray-600 text-lg">Tags</span>
                        <div className="flex flex-wrap lg:w-2/3 md:w-1/2 gap-2 text-black text-base">
                            {createUpdateCluster.tags.map((item, tagIndex) => {
                                return(
                                    <span key={'item_'+tagIndex} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item.key +' : '+String(item.value)}</span>
                                )
                            })}
                        </div>
                    </div>
                : null}
            {/* </div> */}
            </div>

            <div className="rounded-2xl bg-PrimaryTintsPurple-600 border border-NeutralGray-500 p-6 mb-3">
            <p className={`text-primaryPurple-600 text-[22px] font-bold uppercase`}>Authentication (Directory) Configuration</p>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Domain name</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.domain_name ? createUpdateCluster.directory_configuration.domain_name : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Domain address</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.domain_address ? createUpdateCluster.directory_configuration.domain_address : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Password secret (ARN)</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.password_secret_arn ? createUpdateCluster.directory_configuration.password_secret_arn : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Domain read only user</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.domain_read_only_user ? createUpdateCluster.directory_configuration.domain_read_only_user : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Request certificate</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.ldap_tls_request_certificate ? createUpdateCluster.directory_configuration.ldap_tls_request_certificate : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                { createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.additional_sssd_configs ? ( //Exisiting EFS
                <React.Fragment>
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Additional SSSD options</span>
                    <div className="flex flex-wrap lg:w-2/3 md:w-1/2 gap-2 text-black text-base">
                        {Object.entries(createUpdateCluster.directory_configuration.additional_sssd_configs).map(([key, value]) => {
                            return(
                                <span key={key} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{key +' : '+String(value)}</span>
                            )
                        })}
                    </div>
                </React.Fragment>
                ) : null}
                    {/* <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Tags</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.additional_sssd_configs ? createUpdateCluster.cluster_configuration.additional_sssd_configs : ""}</span> */}
                </div>
            {/* </div> */}
            </div>

            <div className="rounded-2xl bg-PrimaryTintsPurple-600 border border-NeutralGray-500 p-6 mb-3">
            <p className={`text-primaryPurple-600 text-[22px] font-bold uppercase`}>Headnode Configuration</p>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Instance Type</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.instance_type ? createUpdateCluster.cluster_configuration.instance_type : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Subnet Id</span>
                    <div className="flex flex-wrap lg:w-2/3 md:w-1/2 gap-2 text-black text-base">
                        {createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.subnet_id ? 
                            <span className={`border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 mr-2 py-1 break-all`}> {createUpdateCluster.cluster_configuration.subnet_id} </span>
                        : null}
                     </div>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Security Group IDs</span>
                    <div className="flex flex-wrap lg:w-2/3 md:w-1/2 gap-2 text-black text-base">
                    {createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.security_group_ids ?
                        createUpdateCluster.cluster_configuration.security_group_ids.map(ids => {
                            return(
                                <span className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 mr-2 py-1 break-all'>{ids}</span>
                            )
                        })
                        : null}
                    </div>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">On node configured script</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.on_node_configured_script ? createUpdateCluster.cluster_configuration.on_node_configured_script : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">On node updated script</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.on_node_updated_script ? createUpdateCluster.cluster_configuration.on_node_updated_script : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Key pair</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.key_name ? createUpdateCluster.cluster_configuration.key_name : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">IAM Policies</span>   
                    {/* <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.policies ? createUpdateCluster.cluster_configuration.policies : ""}</span> */}
                    <div className="flex flex-wrap lg:w-2/3 md:w-1/2 gap-2 text-black text-base">
                    {createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.policies ?
                        createUpdateCluster.cluster_configuration.policies.map(ids => {
                            return(
                                <span key={ids} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 mr-2 py-1 break-all'>{ids}</span>
                            )
                        })
                        : null}
                    </div>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Root Volume Size (GB)</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.volume_size ? createUpdateCluster.cluster_configuration.volume_size : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Volume Type</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.volume_type ? createUpdateCluster.cluster_configuration.volume_type : ""}</span>
                </div>
                <div className="flex justify-between mt-4">
                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Volume Encryption</span>
                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.volume_type ? "Enabled" : "Disabled"}</span>
                </div>
                {/* <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>S3 Access</p>
                    {createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.s3_access ?
                        <div className="p-3 rounded bg-BlockWhite">
                        {createUpdateCluster.cluster_configuration.s3_access.map((buk, bukIndex) => {
                            return(
                                <div className={`flex ${(bukIndex && bukIndex+1 !== createUpdateCluster.cluster_configuration.s3_access.length) ? 'mb-2' : ''}`}>
                                <div className="">
                                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Bucket</span>
                                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{buk.bucket_name ? buk.bucket_name : ""}</span>
                                </div>
                                <div className="ml-3">
                                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Write Access</span>
                                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{buk.enable_s3_write_access ? "True" : "False"}</span>
                                </div>
                                </div>
                            )
                        })}
                        </div>
                    : null} */}
            {/* </div> */}
            </div>

            <div className="rounded-2xl bg-PrimaryTintsPurple-600 border border-NeutralGray-500 p-6 mb-3">
            <p className={`text-primaryPurple-600 text-[22px] font-bold uppercase`}>Loginode Configuration</p>
                <div className={'rounded bg-BlockWhite p-2 mb-2'}>
                    {createUpdateCluster.login_node && createUpdateCluster.login_node.map((buk, bukIndex) => {
                        return(
                            <div className={`p-3 rounded bg-BlockWhite ${((bukIndex && bukIndex===0) || bukIndex+1 !== createUpdateCluster.login_node.length) ? 'mb-2' : ''}`}>
                                <div className="flex justify-between mt-4">
                                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Instance type</span>
                                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.instance_type ? buk.instance_type : ""}</span>
                                </div>
                                <div className="flex justify-between mt-4">
                                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Instance count</span>
                                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.instance_count ? buk.instance_count : ""}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="rounded-2xl bg-PrimaryTintsPurple-600 border border-NeutralGray-500 p-6 mb-3">
            <p className={`text-primaryPurple-600 text-[22px] font-bold uppercase`}>Queue Configuration</p>
            {createUpdateCluster && createUpdateCluster.queue_configuration && createUpdateCluster.queue_configuration[0].queue_name ? ( 
                <React.Fragment>
                    {createUpdateCluster.queue_configuration.map((item, iIndex) => {
                    return(
                        <div className={`rounded-2xl border border-NeutralGray-600 bg-NeutralGray-100 py-[22px] px-[30px] mt-4 ${!iIndex ? 'mb-4' : ''}`}>
                        <div className="flex justify-between">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Queue name</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{item && item.queue_name ? item.queue_name : ""}</span>
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Update strategy</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{item && item.update_strategy ? item.update_strategy : ""}</span>
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Subnet IDs</span>
                            <div className="flex flex-wrap lg:w-2/3 md:w-1/2 gap-2 text-black text-base">
                                {item && item.subnet_ids ?
                                    item.subnet_ids.map(ids => {
                                        return(
                                            <span className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 mr-2 py-1 break-all'>{ids}</span>
                                        )
                                    })
                                : null}
                            </div>
                        </div>
                        {/* <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Security Group IDs</span>
                            <div className="flex flex-wrap lg:w-2/3 md:w-1/2 gap-2 text-black text-base">
                                {item && item.security_group_ids ?
                                    item.security_group_ids.map(ids => {
                                        return(
                                            <span className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 mr-2 py-1 break-all'>{ids}</span>
                                        )
                                    })
                                : null}
                            </div>
                        </div> */}
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Policies</span>
                            <div className="flex flex-wrap lg:w-2/3 md:w-1/2 gap-2 text-black text-base">
                                {item && item.policies ?
                                    item.policies.map(pol => {
                                        return(
                                            <span className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 mr-2 py-1 break-all'>{pol}</span>
                                        )
                                    })
                                : null}
                            </div>
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Script Location</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{item && item.on_node_configured_script ? item.on_node_configured_script : ""}</span>
                        </div>

                        <p className='font-bold text-NeutralGray-800 text-base pb-4 pt-6'>Volume Details</p>
                        <div className="flex justify-between">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Type</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{item && item.volume_type ? item.volume_type : ""}</span>
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Size</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{item && item.volume_size && item.volume_size}</span>
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Encryption</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{item && item.volume_encrypted ? "Enabled" : "Disabled"}</span>
                        </div>

                        <p className='font-bold text-NeutralGray-800 text-base pb-4 pt-6'>Compute Resources</p>
                        {item && item.compute_resources ?
                            item.compute_resources.map(comp => {
                                return (
                                <div className={`border border-NeutralGray-600  bg-white rounded-2xl py-[22px] px-[30px]`}>
                                    <div className="flex justify-between">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Name</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Compute Reservation Id</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{comp && comp.capacity_reservation_id ? comp.capacity_reservation_id : ""}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Instance Type</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{comp && comp.instance_type && comp.instance_type.length ? comp.instance_type : ""}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Min Instance Count</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Max Instance Count</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Efa</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{comp && comp.efa_enabled ? "True" : "False"}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Placement Group</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Simultaneous Multithreading</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                    </div>
                                </div>
                                )
                            })
                        : null}
                        {/* <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>S3 Access</p>
                        {item && item.s3_access ?
                            <div className="p-3 rounded bg-BlockWhite">
                            {item.s3_access.map((buk, bukIndex) => {
                                return(
                                    <div className={`flex ${(bukIndex && bukIndex+1 !== item.s3_access.length) ? 'mb-2' : ''}`}>
                                    <div className="">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Bucket</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{buk.bucket_name ? buk.bucket_name : ""}</span>
                                    </div>
                                    <div className="ml-3">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Write Access</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{buk.enable_s3_write_access ? "True" : "False"}</span>
                                    </div>
                                    </div>
                                )
                            })}
                            </div>
                        : null} */}
                        {item && item.tags ?
                            <div className="flex justify-between pt-4">
                                <span className="justify-start lg:w-1/3 md:w-1/2 text-NeutralGray-600 text-lg">Tags</span>
                                <div className="flex flex-wrap lg:w-2/3 md:w-1/2 gap-2 text-black text-base">
                                    {item.tags.map((item, tagIndex) => {
                                        return(
                                            <span key={'item_'+tagIndex} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item.key +' : '+String(item.value)}</span>
                                        )
                                    })}
                                </div>
                            </div>
                        : null}
                    </div>
                    )
                })}
                </React.Fragment>
            ) : null}
            </div>

            <div className="rounded-2xl bg-PrimaryTintsPurple-600 border border-NeutralGray-500 p-6 mb-3">
                <p className={`text-primaryPurple-600 text-[22px] font-bold uppercase`}>Database Configuration</p>
                {createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.vpc_id ? (
                    <React.Fragment>
                    {/* <div className={'rounded bg-BlockWhite p-2 mb-2'}> */}
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Database name</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.database ? createUpdateCluster.database_configuration.database : ""}</span>
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Username</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.user_name ? createUpdateCluster.database_configuration.user_name : ""}</span>
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">VPC Id</span>
                            {createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.vpc_id ? 
                            <span className={`border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 mr-2 py-1 break-all`}>{createUpdateCluster.database_configuration.vpc_id} </span>
                            : ""}
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Security Group IDs</span>
                            <div className="flex flex-wrap lg:w-2/3 md:w-1/2 gap-2 text-black text-base">
                            {createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.subnet_ids ?
                                createUpdateCluster.database_configuration.subnet_ids.map(ids => {
                                        return(
                                            <span className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 mr-2 py-1 break-all'>{ids}</span>
                                        )
                                    })
                                : null}
                            </div>
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Minimum Capacity</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.min_capacity ? createUpdateCluster.database_configuration.min_capacity : ""}</span>
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Maximum Capacity</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.max_capacity ? createUpdateCluster.database_configuration.max_capacity : ""}</span>
                        </div>
                    {/* </div> */}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                    {/* <div className={'rounded bg-BlockWhite p-2 mb-2'}> */}
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Database Uri</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.database ? createUpdateCluster.database_configuration.database : ""}</span>
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">User Name</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.user_name ? createUpdateCluster.database_configuration.user_name : ""}</span>
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Password secret (ARN)</span>
                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.password_secret_arn ? createUpdateCluster.database_configuration.password_secret_arn : ""}</span>
                        </div>
                    {/* </div> */}
                    </React.Fragment>
                )}
                </div>

                <div className="rounded-2xl bg-PrimaryTintsPurple-600 border border-NeutralGray-500 p-6 mb-3">
                    <p className={`text-primaryPurple-600 text-[22px] font-bold uppercase`}>Storage Configuration</p>
                    { createUpdateCluster && createUpdateCluster.storage_configuration && createUpdateCluster.storage_configuration[0]?.storage_name && createUpdateCluster.storage_configuration[0].create  ? ( //New EFS
                        <React.Fragment>
                            {/* <div className={'rounded bg-BlockWhite p-2 mb-2'}> */}
                                {createUpdateCluster.storage_configuration.map((buk, bukIndex) => {
                                return(
                                    <React.Fragment>
                                        <div className="flex justify-between mt-4">
                                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">File system name</span>
                                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.storage_name ? buk.storage_name : ""}</span>
                                        </div>
                                        <div className="flex justify-between mt-4">
                                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Mount Directory</span>
                                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.mount_dir ? buk.mount_dir : ""}</span>
                                        </div>
                                        <div className="flex justify-between mt-4">
                                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Encryption</span>
                                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.encrytion ? "Enabled" : "Disabled"}</span>
                                        </div>
                                        <div className="flex justify-between mt-4">
                                            <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Deletion Policy</span>
                                            <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{  buk.deletion_policy ? buk.deletion_policy : ""}</span>
                                        </div>
                                    </React.Fragment>
                                )
                                })}       
                            {/* </div> */}
                        </React.Fragment>
                    ) : createUpdateCluster.storage_configuration && createUpdateCluster.storage_configuration[0]?.root_volume_security_style && createUpdateCluster.storage_configuration[0].create ? ( //New Fsx
                        <div className={'rounded bg-BlockWhite p-2 mb-2'}>
                            {createUpdateCluster.storage_configuration.map((buk, bukIndex) => {
                            return(
                            <React.Fragment>
                                <div className="flex justify-between mt-4">
                                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Root Volume Security Style</span>
                                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{buk.root_volume_security_style ? buk.root_volume_security_style : ""}</span>
                                </div>
                                <div className="flex justify-between mt-4">
                                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Storage Capacity</span>
                                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{buk.storage_capacity ? buk.storage_capacity : ""}</span>
                                </div>
                                <div className="flex justify-between mt-4">
                                    <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Throughput Capacity</span>
                                    <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{buk.throughput_capacity ? buk.throughput_capacity : ""}</span>
                                </div>

                                <p className='font-bold text-NeutralGray-800 text-base pb-4'>Volume Details</p>
                                <div className={`border border-NeutralGray-600  bg-white rounded-2xl pt-8 mt-8 ${((bukIndex && bukIndex===0) || bukIndex+1 !== createUpdateCluster.storage_configuration.length) ? 'mb-2' : ''}`}>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Volume name</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.volume_name ? buk.volume_name : ""}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Snapshot Policy</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.snapshot_policy ? buk.snapshot_policy : ""}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Storage Efficiency</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.root_volume_security_style ? "Enabled" : "Disabled"}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Tiering Policy Name</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.tiering_policy_name ? buk.tiering_policy_name : ""}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Tiering Policy Cooling Period</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.cooling_period ? buk.cooling_period : ""}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Volume Security Style</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.volume_security_style ? buk.volume_security_style : ""}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Volume Size</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.root_volume_security_style ? "Enabled" : "Disabled"}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Volume Type</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.tiering_policy_name ? buk.tiering_policy_name : ""}</span>
                                    </div>
                                </div>
                            </React.Fragment>
                            )
                            })}
                        </div>
                    ) : createUpdateCluster.storage_configuration && createUpdateCluster.storage_configuration[0]?.storage_id ? ( //Exisiting Fsx
                        <React.Fragment>
                        {createUpdateCluster.storage_configuration.map((buk, bukIndex) => {
                            return(
                                <div className={`border border-NeutralGray-600  bg-white rounded-2xl py-[22px] px-[30px] mt-4 ${((bukIndex && bukIndex===0) || bukIndex+1 !== createUpdateCluster.storage_configuration.length) ? 'mb-4' : ''}`}>
                                    <div className="flex justify-between">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Storage Id</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.storage_id ? buk.storage_id : ""}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Volume name</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.storage_name ? buk.storage_name : ""}</span>
                                    </div>
                                </div>
                            )
                        })}
                        </React.Fragment>
                    ) : createUpdateCluster.storage_configuration && createUpdateCluster.storage_configuration.map((buk, bukIndex) => {
                            return(
                                <div className={`border border-NeutralGray-600  bg-white rounded-2xl py-[22px] px-[30px] ${((bukIndex && bukIndex===0) || bukIndex+1 !== createUpdateCluster.storage_configuration.length) ? 'mb-4' : ''}`}>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Filesystem name</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.storage_name ? buk.storage_name : ""}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="justify-start lg:w-1/3 md:w-1/2 self-center text-NeutralGray-600 text-lg">Mount Directory</span>
                                        <span className={`justify-start lg:w-2/3 md:w-1/2 self-center text-black text-lg`}>{ buk.mount_dir ? buk.mount_dir : ""}</span>
                                    </div>
                                </div>
                            )
                        }
                    )}
            </div>
        </div>

    )
}

export default ReviewSection