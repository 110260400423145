/*************************************************
 * Collider
 * @exports
 * @file RightSection.js
 * @author Prakash // on 05/12/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setCostPropsDetails } from  '../../../../actions/cost/CostAction'
import { capitalizeAllLetter, onlyNumeric, thousandSeparator } from '../../../../utils/utility';
import _ from 'lodash'
import {Icon} from "@iconify/react";
import Textbox from '../../../common/Form/Textbox';
import SelectOption from '../../../common/Form/SelectOption';
import Checkbox from '../../../common/Form/Checkbox';

const RightSection = (props) => {
	const[state, setState] = useState({
		showDetails: false,
        expandMenuId: 'Projects',
        months: [{label: 'Jan', value: 0}, {label:'Feb', value: 1}, {label:'Mar', value: 2}, {label:'Apr', value: 3}, {label:'May', value: 4}, {label:'Jun', value: 5}, {label:'Jul', value: 6}, {label:'Aug', value: 7}, {label:'Sep', value: 8}, {label:'Oct', value: 9}, {label:'Nov', value: 10}, {label:'Dec', value: 11}],        
        quarters: [
            [{label: 'Jan', value: 0}, {label: 'Feb', value: 1}, {label: 'Mar', value: 2}],
            [{label: 'Apr', value: 3}, {label: 'May', value: 4}, {label: 'Jun', value: 5}],
            [{label: 'Jul', value: 6}, {label: 'Aug', value: 7}, {label: 'Sep', value: 8}],
            [{label: 'Oct', value: 9}, {label: 'Nov', value: 10}, {label: 'Dec', value: 11}]
        ]
	})

	const dispatch = useDispatch(); // Create a dispatch function

    const selectedInitiative = useSelector(state => state.cost.costPropsDetails?.selectedInitiative || false);
    const createInputs = useSelector(state => state.cost.costPropsDetails?.createInputs || false);
    const onClickSave = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.onClickSave ? state.cost.costPropsDetails.onClickSave : false);
    const currencyUnit = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.currencyUnit ? state.cost.costPropsDetails.currencyUnit : 'usd')

    useEffect(() => {
        if(props.receivedData && Object.entries(props.receivedData).length) {
            setState(prevState => ({ 
                ...prevState, 
                department: props.receivedData.department ? props.receivedData.department : '',
                year: props.receivedData.year ? props.receivedData.year : '',
                pageType: props.receivedData.pageType ? props.receivedData.pageType : '',
            }))
        }
    }, [props.receivedData])

    useEffect(() => {
        if(onClickSave === 'stopLoading') {
            setState(prevState => ({ ...prevState, onSaveFunction: false }))
            dispatch(setCostPropsDetails('onClickSave', ''))
        }
    }, [onClickSave, dispatch])

    const projectCommitedBalanceDetails = useCallback((allocation, monthly_distribution, pIndex, row, selectedProjCloudProvider) => {
        let projectMontlyDistrubutition = monthly_distribution
        if (projectMontlyDistrubutition) {
            let projectMonthlySum = projectMontlyDistrubutition.reduce((a, b) => {
                return a + parseFloat(b);
            }, 0); // Initial value of the accumulator is set to 0
            
            let projectBalance = allocation - projectMonthlySum
            

            var quarters = [];
            for (var i = 0; i < projectMontlyDistrubutition.length; i += 3) {
                var quarter = projectMontlyDistrubutition.slice(i, i + 3);
                var quarterSum = quarter.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                quarters.push(quarterSum);
            }
            quarters.forEach((quater, qIndex) => {
                quater = parseFloat(quater).toFixed(2)
                setState(prevState => ({
                    ...prevState,
                    ['project_quater_'+row.project+'_'+qIndex+'_'+pIndex]:  parseFloat(quater)
                }))
            })

            let halfYearlyArray = []
            for (let i = 0; i < projectMontlyDistrubutition.length; i += 6) {
                let halfYearly = projectMontlyDistrubutition.slice(i, i + 6);
                let halfYearlySum = halfYearly.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                halfYearlyArray.push(halfYearlySum);
            }
            
            halfYearlyArray.forEach((half, hIndex) => {
                half = parseFloat(half).toFixed(2)
                setState(prevState => ({
                    ...prevState,
                    ['project_half_'+hIndex+'_'+row.project+'_'+pIndex]:  parseFloat(half)
                }))
            })

            setState(prevState => ({ ...prevState, ['projectMonthlySum_'+selectedProjCloudProvider+'_'+pIndex]: Math.round(projectMonthlySum), ['projectBalance_'+row.project+'_'+pIndex]: Math.round(projectBalance) }))
        }
    }, [])

    useEffect(() => {
        if(state.formtData) {
            setState(prevState => ({ ...prevState, formtData: false }))

            let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
            let obj = createInputs ? createInputs : {}
            let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex] ? obj.initiatives[selectedInitiativeIndex] : {}
    
            let projects = initiatives && initiatives.projects ? initiatives.projects : []
    
            if(projects && projects.length) {
                projects.forEach((proj, pIndex) => {
                    let totalAllocation = 0
                    let totalDistributionSum = 0
                    let projectCloudProviders = []
                    let projectCloudBudget = proj && proj.cloud_budget ? proj.cloud_budget : {}
                    if(projectCloudBudget && Object.entries(projectCloudBudget).length) {
                        projectCloudProviders = Object.keys(projectCloudBudget)
                        let selectedProjCloudProvider = projectCloudProviders[0]
                        let selectedProjProviderDetails = projectCloudBudget[selectedProjCloudProvider]
    
                        let distribution = selectedProjProviderDetails && selectedProjProviderDetails.monthly_distribution ? selectedProjProviderDetails.monthly_distribution : []
                        
                        state.months.forEach((mnt, mIndex) => {
                            setState(prevState => ({ ...prevState, ['project_'+proj.project+'_'+mnt.label+'_'+pIndex]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
                        })
                        
                        let allocation = selectedProjProviderDetails && selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0
                        let credits = selectedProjCloudProvider && selectedProjCloudProvider.credits ? selectedProjCloudProvider.credits : 0
                        let over_budget = selectedProjCloudProvider && selectedProjCloudProvider.over_budget ? selectedProjCloudProvider.over_budget : 0
                        let under_budget = selectedProjCloudProvider && selectedProjCloudProvider.under_budget ? selectedProjCloudProvider.under_budget : 0
    
                        projectCommitedBalanceDetails(allocation, distribution, pIndex, proj, selectedProjCloudProvider)
                        
                        setState(prevState => ({ ...prevState, ['projectCloudProviders_'+pIndex]: projectCloudProviders, ['selectedProjCloudProvider_'+pIndex]: selectedProjCloudProvider, ['projectAlloation_'+proj.project+'_'+pIndex]: allocation, ['credits_'+proj.project+'_'+pIndex]: credits, ['over_budget_'+proj.project+'_'+pIndex]: over_budget, ['under_budget_'+proj.project+'_'+pIndex]: under_budget }))
                    }
                    
                    let projectProviders = []
                    Object.entries(proj.cloud_budget).forEach(([key, value], objIndex) => {
    
                        // let allocation = value.allocation 
                        let projectDistrubutition = value.monthly_distribution
    
                        let projDistributionSum = 0
                        if(projectDistrubutition) {
                            projDistributionSum = projectDistrubutition.reduce((a, b) => {
                                return a + parseFloat(b);
                            }, 0)
                        }

                        totalAllocation += Math.round(value.allocation) 
                        totalDistributionSum += Math.round(projectDistrubutition)
    
                        let projRow = {}
                        projRow.provider = key
                        projRow.allocation = value.allocation 
                        projRow.distributuion = Math.round(projDistributionSum)
                        projRow.balance = Math.round(value.allocation - projDistributionSum)
        
                        projectProviders.push(projRow)

                        if(Object.entries(proj.cloud_budget).length-1 === objIndex) {
                            let totalDepRow = {}
                            totalDepRow.provider = 'Total'
                            totalDepRow.allocation = totalAllocation
                            totalDepRow.distributuion = totalDistributionSum
                            totalDepRow.balance = totalAllocation - totalDistributionSum
                            projectProviders.unshift(totalDepRow);
                        }
        
                        
                        setState(prevState => ({ ...prevState, ['projectProviders_'+proj.project]: projectProviders}))
                    })
                })

            }
        }
        //project format end
    }, [state.formtData, createInputs, projectCommitedBalanceDetails, selectedInitiative, state.months])

    useEffect(() => {
        if (selectedInitiative && !_.isEqual(selectedInitiative, state.selectedInitiativeObj)) {
            setState(prevState => ({ ...prevState, selectedInitiativeObj: selectedInitiative, formtData: true }))
        }
    }, [selectedInitiative, state.selectedInitiativeObj])

     //for Initiative// 
     const onChangeProjCloudProvider = (selectedProjCloudProvider, pIndex) => {
        let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}
        
        let projects = initiatives && initiatives.projects ? initiatives.projects : []
        
        let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}
        let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[selectedProjCloudProvider] ? selectedProjCloudBudget.cloud_budget[selectedProjCloudProvider] : {}
        
        let allocations = selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0
        

        let distribution = selectedProjProviderDetails && selectedProjProviderDetails.monthly_distribution ? selectedProjProviderDetails.monthly_distribution : []
        state.months.forEach((mnt, mIndex) => {
            setState(prevState => ({ ...prevState, ['project_'+selectedProjCloudBudget.project+'_'+mnt.label+'_'+pIndex]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
        })

        projectCommitedBalanceDetails(allocations, distribution, pIndex, selectedProjProviderDetails, selectedProjCloudProvider)
        setState(prevState => ({ ...prevState, ['projectAlloation_'+selectedProjCloudBudget.project+'_'+pIndex]: allocations }))
    }

    useEffect(() => {
        if(selectedInitiative && Object.entries(selectedInitiative).length) {
            setState(prevState => ({ 
                ...prevState, 
                selectedInitiative: selectedInitiative.initiative,
                projectOptions: selectedInitiative.projects && selectedInitiative.projects.length ? selectedInitiative.projects : [] 
            }))
        }
    }, [selectedInitiative])
    
	const handleProjectInputChange = (label, pIndex, value) => {
        let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}

        let projects = initiatives && initiatives.projects ? initiatives.projects : []

        let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}
        let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] ? selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] : {}
        
        
        // let invObj = initiatives && initiatives[pIndex] ? initiatives[pIndex] : {}
        if(label === 'allocation') {
            value = parseFloat(value)
            selectedProjProviderDetails[label] = value
            if(!state['montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]) {
                projectCommitedBalanceDetails(value, selectedProjCloudBudget.monthly_distribution, pIndex, selectedProjCloudBudget, state['selectedProjCloudProvider_'+pIndex])
            }
        } else if(label === 'credits' || label === 'over_budget' || label === 'under_budget') {
            selectedProjCloudBudget[label] = value
        }


        // invObj.projects = newProj
        // initiatives[selectedInitiative.iIndex] = invObj
        // dispatch(setCostPropsDetails('createInputs', obj))
	}

    const callProjectEqualMonthlyDistribution = useCallback((pIndex, value) => {
        let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}

        let projects = initiatives && initiatives.projects ? initiatives.projects : []

        let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}

        let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] ? selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] : {}

        let allocations = selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0

        let monthly_distribution = []
        let amount = parseFloat(value/12).toFixed(2)
        state.months.forEach(mnt => {
            monthly_distribution[mnt.value] = parseFloat(amount)
        })        
        selectedProjProviderDetails.monthly_distribution = monthly_distribution

        dispatch(setCostPropsDetails('createInputs', obj))
        projectCommitedBalanceDetails(allocations, monthly_distribution, pIndex, selectedProjCloudBudget)

    }, [state, createInputs, dispatch, selectedInitiative, projectCommitedBalanceDetails])

    const handleProjectMonthlyEqualSplit = (monthlySplit, pIndex, value, row) => {

        if(monthlySplit) {
            let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
            let obj = createInputs ? createInputs : {}
            let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}
    
            let projects = initiatives && initiatives.projects ? initiatives.projects : []
            
            let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}

            let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] ? selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] : {}
            
            let allocations = selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0
            let amount = parseFloat(allocations/12).toFixed(2)
            state.months.forEach(mnt => {
                setState(prevState => ({ ...prevState, ['project_'+row.project+'_'+mnt.label+'_'+pIndex]: parseFloat(amount) }))
            })

            callProjectEqualMonthlyDistribution(pIndex, value)
        }
    }

    const handleProjectMonthlySplit = (label, pIndex, value, row) => {
        let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}

        let projects = initiatives && initiatives.projects ? initiatives.projects : []

        let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}
        let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] ? selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] : {}

        let allocations = selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0
        
        let monthly_distribution = selectedProjProviderDetails && selectedProjProviderDetails.monthly_distribution ? selectedProjProviderDetails.monthly_distribution : []
        state.months.forEach(mnt => {
            if(label === mnt.value) {
                let amount = value ? parseFloat(value).toFixed(2) : 0
                monthly_distribution[mnt.value] = parseFloat(amount)
            } else {
                monthly_distribution[mnt.value] = state['project_'+row.project+'_'+mnt.label+'_'+pIndex] ? parseFloat(state['project_'+row.project+'_'+mnt.label+'_'+pIndex]) : 0
            }
        })
        
        selectedProjProviderDetails.monthly_distribution = monthly_distribution  
        
        projectCommitedBalanceDetails(allocations, monthly_distribution, pIndex, row)

        // obj.initiatives = initiatives
        dispatch(setCostPropsDetails('createInputs', obj))
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const expandCollapse = (section) => {
        setState(prevState => ({ ...prevState, expandMenuId: state.expandMenuId === section ? "" : section }))
    }

    const expandProjects = (row) => {
        setState(prevState => ({ ...prevState, expandProj: state.expandProj === row.project ? "" : row.project }))
    }

    const onChangeHandleValidation = (type, mnt) => {
        //initiative annual amount and total monthly amount check
        let hasError = false
        let obj = createInputs ? createInputs : {}
        let initiatives = obj.initiatives ? obj.initiatives : []
        if(initiatives.length) {
            initiatives.forEach((row, rIndex) => {
                Object.entries(row.cloud_budget).forEach(([key, value]) => {
                    let projects = row.projects ? row.projects : []
                    projects.forEach(proj => {
                        let projectAllocationError = []
                        let projectProviders = []
                        let totalAllocation = 0
                        let totalDistributionSum = 0
                        if(proj.cloud_budget && Object.entries(proj.cloud_budget).length) {
                            Object.entries(proj.cloud_budget).forEach(([key, value], objIndex) => {
                                let projecttDistrubutition = value.monthly_distribution
                                let distributionSum = 0
                                if(projecttDistrubutition) {
                                    distributionSum = projecttDistrubutition.reduce((a, b) => {
                                        return a + parseFloat(b);
                                    }, 0)
                                }

                                totalAllocation += Math.round(value.allocation) 
                                totalDistributionSum += Math.round(distributionSum)

                                let dataRow = {}
                                dataRow.provider = key
                                dataRow.allocation = Math.round(value.allocation)
                                dataRow.distributuion = Math.round(distributionSum)
                                dataRow.balance = Math.round(value.allocation) - Math.round(distributionSum)
                                
                                if(value.allocation < distributionSum) {
                                    hasError = true
                                    let error ='Annual commitment amount of '+capitalizeAllLetter(key)+' should be more than the total monthly commitment.'
                                    
                                    projectAllocationError.push(error)
                                }
                                projectProviders.push(dataRow)

                                if(Object.entries(proj.cloud_budget).length-1 === objIndex) {
                                    let totalDepRow = {}
                                    totalDepRow.provider = 'Total'
                                    totalDepRow.allocation = totalAllocation
                                    totalDepRow.distributuion = totalDistributionSum
                                    totalDepRow.balance = totalAllocation - totalDistributionSum
                                    projectProviders.unshift(totalDepRow);
                                }
                                
                                setState(prevState => ({ ...prevState, ['projectAllocationError_'+proj.project]: projectAllocationError }))
                            })
                        }

                        setState(prevState => ({ ...prevState, ['projectProviders_'+proj.project]: projectProviders }))
                    })

                    let projectTotalAllocation = 0
                    let projectIntitativeAllocationError = []
                    projects.forEach(proj => {
                        if(proj.cloud_budget.hasOwnProperty(key)) {
                            let projData = proj.cloud_budget[key]
                            projectTotalAllocation += projData.allocation
                        }
                    })
                    

                    projects.forEach(proj => {
                        projectIntitativeAllocationError = []
                        if(proj.cloud_budget.hasOwnProperty(key)) {
                            if(projectTotalAllocation > value.allocation) {
                                hasError = true
                                let error = 'Total Project Annual commitment amount of '+capitalizeAllLetter(key)+' should be less than the initiative annual commitment.'
                                projectIntitativeAllocationError.push(error)
                                setState(prevState => ({ ...prevState, ['projectIntitativeAllocationError_'+key+'_'+proj.project]: projectIntitativeAllocationError }))
                            } else {
                                setState(prevState => ({ ...prevState, ['projectIntitativeAllocationError_'+key+'_'+proj.project]: [] }))
                            }
                        }
                    })
                    
                })
            })
        }
        
        if(hasError) {
            dispatch(setCostPropsDetails('onClickSave', 'checkForError'))
        } else {
            if(type === 'save') {
                dispatch(setCostPropsDetails('onClickSave', 'save'))
            } else {
                dispatch(setCostPropsDetails('onClickSave', 'checkForErrorHasNoError'))
            }
        }
    }
	
	return (
        <div className='pl-2' onClick={(event) => handleChildClick(event, 'parent')}>
            {state.selectedInitiative ?
                <div className='flex flex-wrap text-black border-2 border-byzantineBlue rounded-2xl bg-white p-6'>
                    <div className={`w-full`}> 
                        <div className={``}>
                            <div className={`flex justify-between`}>
                                <div className='flex text-base font-bold'>
                                    <Icon icon={`${state.expandMenuId === 'Projects' ? 'iconoir:minus' :'iconoir:plus'}`} className={`mr-6 self-center cursor-pointer text-white bg-PrimaryLightOrange-600 rounded-full`} width={24} height={24} onClick={() => expandCollapse('Projects')} />
                                    Projects
                                </div>
                                <div className='flex'>
                                    <span className='mr-3 text-black text-lg font-medium self-center'>Total</span>
                                    <h5 className='text-PrimaryLightOrange-600 mb-0 text-lg'>
                                        {createInputs && createInputs.initiatives && createInputs.initiatives.length && createInputs.initiatives[selectedInitiative.iIndex] && createInputs.initiatives[selectedInitiative.iIndex].projects && createInputs.initiatives[selectedInitiative.iIndex].projects.length ?
                                            thousandSeparator(createInputs.initiatives[selectedInitiative.iIndex].projects.reduce((a, b) => {
                                                return a + parseFloat(b.allocation);
                                            }, 0))
                                        : 0}
                                    </h5>
                                </div>
                            </div>
                            {state.projInitAllocationAmount ?
                                <p className='mb-1 text-ferrariRed-600'>{state.projInitAllocationAmount}</p>
                            : null}
                        </div>
                        {state.expandMenuId === 'Projects' ?
                             <div className='px-6'>
                                <React.Fragment>
                                <p className='mb-1 ml-6'>Showing projects of {state.selectedInitiative}</p>
                                {state.projectOptions && state.projectOptions.map((row, pIndex) => {
                                    return(
                                        <div key={'proj_'+pIndex} className={`rounded-2xl p-5 mt-5 bg-NeutralGray-200`}>
                                                {/* ${state.expandInit === row.initiative ? 'border-2 border-byzantineBlue' : 'border border-DeepPurpleTints-600'} */}
                                            <div className={`mb-0 flex justify-between`}>
                                                <div className={`flex cursor-pointer font-bold text-base`} onClick={() => expandProjects(row, row)}>
                                                    <Icon icon={`${state.expandProj === row.project ? 'iconoir:minus' :'iconoir:plus'}`} className={`mr-6 self-center cursor-pointer text-white bg-PrimaryLightOrange-600 rounded-full`} width={24} height={24}/>
                                                    {row.project}
                                                </div>
                                            </div>
                                            {state.expandProj === row.project ?
                                                <div className="my-3">
                                                    {state['projectIntitativeAllocationError_'+state['selectedProjCloudProvider_'+pIndex]+'_'+row.project] && state['projectIntitativeAllocationError_'+state['selectedProjCloudProvider_'+pIndex]+'_'+row.project].length ?
                                                        state['projectIntitativeAllocationError_'+state['selectedProjCloudProvider_'+pIndex]+'_'+row.project].map((err, errIndex) => {
                                                            return(
                                                                <p key={"err_"+errIndex} className='mb-1 text-danger'>{err}</p>
                                                            )
                                                        })
                                                    : null}
                                                    {state['projectAllocationError_'+row.project] && state['projectAllocationError_'+row.project].length ?
                                                        state['projectAllocationError_'+row.project].map((err, errIndex) => {
                                                            return(
                                                                <p key={"projErr_"+errIndex} className='mb-1 text-danger'>{err}</p>
                                                            )
                                                        })
                                                    : null}
                                                    <div className='flex flex-wrap pt-5'>
                                                        <div className="lg:w-1/2 w-full">
                                                            <SelectOption
                                                                label={"Cloud Provider"}
                                                                options={state['projectCloudProviders_'+pIndex] ? state['projectCloudProviders_'+pIndex] : []}
                                                                selectedValues={state['selectedProjCloudProvider_'+pIndex] ? state['selectedProjCloudProvider_'+pIndex] : ''}
                                                                callbackMultiSelect={(value) => {
                                                                    setState(prevState => ({ 
                                                                        ...prevState, 
                                                                        ['selectedProjCloudProvider_'+pIndex]: value,
                                                                        ['project_montly_split_equal_'+value+'_'+pIndex]: state['project_montly_split_equal_'+value+'_'+pIndex] ? true : false
                                                                    }))
                                                                    onChangeProjCloudProvider(value, pIndex)
                                                                }}
                                                                singleSelection={true}
                                                                dropdownWidth={'min-w-32'}
                                                                classDetails={{padding: "py-2.5 pr-4 pl-3", bg: 'bg-white', rounded: 'rounded-lg' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-wrap pt-5'>
                                                        <div className="flex py-1 lg:w-2/5 w-full">
                                                            <Textbox
                                                                label={'Annual Budget'}
                                                                selectedValue={state['projectAlloation_'+row.project+'_'+pIndex] ? state['projectAlloation_'+row.project+'_'+pIndex] : ""}
                                                                callback={(value) => {
                                                                    setState(prevState => ({ ...prevState, ['projectAlloation_'+row.project+'_'+pIndex]: onlyNumeric(value) }))
                                                                    handleProjectInputChange('allocation', pIndex, onlyNumeric(value))
                                                                    if(state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]) {
                                                                        handleProjectMonthlyEqualSplit(true, pIndex, onlyNumeric(value), row)
                                                                    }
                                                                    onChangeHandleValidation()
                                                                }}
                                                                type={'number'}
                                                                inputType={state.pageType}
                                                                manditory={true}
                                                                placeholder={'Annual budget'}
                                                            />
                                                            <p className="text-black ml-3 pt-4 text-sm font-bold self-center">{currencyUnit}</p>
                                                        </div>       
                                                        <div className='lg:w-1/2 lg:pl-4 w-full self-center pt-4'>
                                                            <Checkbox
                                                                label="Equal Monthly Budget"
                                                                name="splitEqual"
                                                                selectedValue={state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex] ? true : false}
                                                                callback={() => {
                                                                    let monthlySplit = !state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]
                                                                    setState(prevState => ({ ...prevState, ['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]: monthlySplit }))
                                                                    handleProjectMonthlyEqualSplit(monthlySplit, pIndex, state['projectAlloation_'+row.project+'_'+pIndex], row)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-wrap pt-5'>
                                                        <div className="lg:w-1/2 lg:pr-2 w-full">
                                                            <Textbox
                                                                label={'Overutilized (in %)'}
                                                                selectedValue={state['over_budget_'+row.project+'_'+pIndex] ? state['over_budget_'+row.project+'_'+pIndex] : ""}
                                                                callback={(value) => {
                                                                    setState(prevState => ({ ...prevState, ['over_budget_'+row.project+'_'+pIndex]: onlyNumeric(value) }))
                                                                    handleProjectInputChange('over_budget', pIndex, onlyNumeric(value))
                                                                }}
                                                                type={'number'}
                                                                maxValue={100}
                                                                inputType={state.pageType}
                                                                placeholder={'Enter the value'}
                                                            />
                                                        </div>
                                                        <div className="lg:w-1/2 lg:pl-2 lg:pt-0 w-full">
                                                            <Textbox
                                                                label={'Underutilized (in %)'}
                                                                selectedValue={state['under_budget_'+row.project+'_'+pIndex] ? state['under_budget_'+row.project+'_'+pIndex] : ""}
                                                                callback={(value) => {
                                                                    setState(prevState => ({ ...prevState, ['under_budget_'+row.project+'_'+pIndex]: onlyNumeric(value) }))
                                                                    handleProjectInputChange('under_budget', pIndex, onlyNumeric(value))
                                                                }}
                                                                type={'number'}
                                                                maxValue={100}
                                                                inputType={state.pageType}
                                                                placeholder={'Enter the value'}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-wrap pt-5'>
                                                        <div className="lg:w-1/2 lg:pr-2 w-full">
                                                            <Textbox
                                                                label={'Credits'}
                                                                selectedValue={state['credits_'+row.project+'_'+pIndex] ? state['credits_'+row.project+'_'+pIndex] : ""}
                                                                callback={(value) => {
                                                                    setState(prevState => ({ ...prevState, ['credits_'+row.project+'_'+pIndex]: onlyNumeric(value) }))
                                                                    handleProjectInputChange('credits', pIndex, onlyNumeric(value))
                                                                }}
                                                                inputType={state.pageType}
                                                                placeholder={'Enter credits'}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='py-4'>
                                                        {state['projectProviders_'+row.project] && state['projectProviders_'+row.project].length ?
                                                            <div className="rounded-t-2xl overflow-x-auto border border-lightGray-600 shadow-lg">
                                                                <table className="w-full text-left">
                                                                    <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold text-base'>
                                                                        <tr className="flex-1 w-full">
                                                                            <th className='tracking-wider py-2 pl-4 text-left'>&nbsp;</th>
                                                                            <th className='tracking-wider py-2 pl-4 text-left'>Comitted</th>
                                                                            <th className='tracking-wider py-2 pl-4 text-left'>Allocated</th>
                                                                            <th className='tracking-wider py-2 pl-4 text-left'>Balance</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='text-sm text-NeutralGray-900'>
                                                                        {state['projectProviders_'+row.project].map((pro, proIndex) => {
                                                                            return(
                                                                                <tr key={"pro_"+proIndex} className={`text-base ${proIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
                                                                                    <td className={`py-2.5 pl-4 text-left`}>{pro.provider ? capitalizeAllLetter(pro.provider) : ''}</td>
                                                                                    <td className={`py-2.5 pl-4 text-left`}>{pro.allocation ? thousandSeparator(pro.allocation) : 0}</td>
                                                                                    <td className={`py-2.5 pl-4 text-left`}>{pro.distributuion ? thousandSeparator(pro.distributuion) : 0}</td>
                                                                                    <td className={`py-2.5 pl-4 text-left ${pro.balance > 0 ? '' : 'text-ferrariRed-600'}`}>{pro.balance ? thousandSeparator(pro.balance) : 0}</td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        : null}  
                                                        {/* {state['projIniSeparateMonthlyError_'+selectedInitiative.iIndex+'_'+pIndex] ?
                                                            <p className='mb-1 text-danger'>{state['projIniSeparateMonthlyError_'+selectedInitiative.iIndex+'_'+pIndex]}</p>
                                                        : null}                                                             */}
                                                    </div>
                                                    <div className='pt-5'>
                                                        <p className='text-NeutralGray-800 font-bold text-[22px]'>Monthly Budget</p>
                                                        <div className='flex flex-wrap pt-5'>  
                                                            {state.quarters && state.quarters.map((quarter, rowIndex) => {
                                                                return(
                                                                    <div className='w-1/4' key={'quater_'+rowIndex}>
                                                                        {quarter.map(mnt => {
                                                                            return(
                                                                                <div className='mb-3 pr-3 text-black' key={'dep_'+mnt.label}>
                                                                                    <Textbox
                                                                                        type={'number'}
                                                                                        label={mnt.label+' '+(state.year ? state.year : '')}
                                                                                        selectedValue={state['project_'+row.project+'_'+mnt.label+'_'+pIndex] ? state['project_'+row.project+'_'+mnt.label+'_'+pIndex] : ""}
                                                                                        callback={(value) => {
                                                                                            setState(prevState => ({ ...prevState, ['project_'+row.project+'_'+mnt.label+'_'+pIndex]: value }))
                                                                                            handleProjectMonthlySplit(mnt.value, pIndex, value, row)
                                                                                            onChangeHandleValidation('', mnt)
                                                                                        }}
                                                                                        inputType={state.pageType}
                                                                                        placeholder={'Monthly budget'}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        })}
                                                                        
                                                                        <div className="flex justify-center w-4/5">
                                                                            <div className="w-10/12 border-t-2 border-primaryPurple-600"></div>
                                                                        </div>
                                                                        <span className='flex justify-center mt-2 w-4/5 text-lg font-medium'>Q{rowIndex+1} <span className='ml-2 text-primaryPurple-600'>{state['project_quater_'+row.project+'_'+rowIndex+'_'+pIndex] ? thousandSeparator(Math.round(state['project_quater_'+row.project+'_'+rowIndex+'_'+pIndex])) : 0}</span></span>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>

                                                        <div className='flex flex-wrap justify-between mt-4'>
                                                            <div className='w-1/2'>
                                                                <div className="flex justify-center">
                                                                    <div className="w-10/12 border-t-2 border-primaryPurple-600"></div>
                                                                </div>
                                                                <span className='mt-2 flex justify-center text-lg font-medium'>H1 <span className='ml-2 text-primaryPurple-600'>{state['project_half_0_'+row.project+'_'+pIndex] ? thousandSeparator(Math.round(state['project_half_0_'+row.project+'_'+pIndex]), 0) : 0}</span></span>
                                                            </div>
                                                            <div className='w-1/2'>
                                                                <div className="flex justify-center">
                                                                    <div className="w-10/12 border-t-2 border-primaryPurple-600"></div>
                                                                </div>
                                                                <span className='mt-2 flex justify-center text-lg font-medium'>H2 <span className='ml-2 text-primaryPurple-600'>{state['project_half_1_'+row.project+'_'+pIndex] ? thousandSeparator(Math.round(state['project_half_1_'+row.project+'_'+pIndex]),0) : 0}</span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='pt-5'>
                                                        <div className="relative overflow-x-auto mt-2">
                                                            <p className='text-NeutralGray-800 font-bold text-[22px]'>Accounts</p>
                                                            <div className="rounded-t-2xl overflow-x-auto border border-lightGray-600 shadow-lg">
                                                                <table className="w-full text-left">
                                                                    <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold text-base'>
                                                                        <tr className="flex-1 w-full">
                                                                            <th className='tracking-wider py-2 pl-4 text-left'>Provider</th>
                                                                            <th className='tracking-wider py-2 pl-4 text-left'>Account</th>
                                                                            <th className='tracking-wider py-2 pl-4 text-left'>Billing Contribution</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='text-sm text-NeutralGray-900'>
                                                                        {row.accounts && row.accounts.length ?
                                                                            row.accounts.map((acc, accIndex) => {
                                                                                return(
                                                                                    <tr key={"sft_"+accIndex} className={`text-base ${accIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
                                                                                        <td className={`py-2.5 pl-4 text-left uppercase`}>{acc.cloudProviderId}</td>
                                                                                        <td className={`py-2.5 pl-4 text-left`}>{acc.billingUnit}</td>
                                                                                        <td className={`py-2.5 pl-4 text-left`}>{acc.billingContribution}%</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    )
                                })}
                                </React.Fragment>
                            </div>
                        : null}
                    </div>
			    </div>
            : null}
		</div>
	)
}

export default RightSection