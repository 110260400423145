import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
// import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import { listWorkstatioRequestHistory } from '../../../../actions/CatalogAction'
import { listRequestsHistory } from '../../../../actions/files/FileAction'
import WorkstationSidePanel from '../WorkstationSidePanel';
import Search from '../../../common/SearchWithHiddenInput'
import { CSVLink } from 'react-csv';
import ResizeableDarkThemeTable from '../../../designComponents/Table/ResizeableDarkThemeTable';
import { capitalizeFirstLetter, capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime } from '../../../../utils/utility';
import {Icon} from "@iconify/react";
import StorageSidePanel from '../StorageSidePanel';
import { LoadingCircle } from '../../../common/LoadingCiricle';
import PageLoading from '../../../common/PageLoading';

const RequestHistory = (props) => {
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion,
        selectedRequestType: "workstation",
    })

    const dispatch = useDispatch(); // Create a dispatch function
    // const history = useHistory();
    // const navigate = useNavigate();

    useEffect(() => {
        setState(prevState => ({ ...prevState, callListReqeustHistory: true }))
    }, [])

    useEffect(() => {
        if(state.callListReqeustHistory) {
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== 'All')
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== 'All')
            }

            if(state.nextHistoryToken) {
                params.next_token = state.nextHistoryToken
            }
    
            if (state.selectedRequestType && state.selectedRequestType === "workstation") {
                dispatch(listWorkstatioRequestHistory(params))
                    .then((response) => {
                        if(response) {
                            let totalResults = [] 
                            if(state.nextHistoryToken) {
                                totalResults = state.listRequestHistory &&  state.listRequestHistory.length ? state.listRequestHistory : []
                            }

                            if(response.results && response.results.length) {
                                totalResults = totalResults.concat(response.results)
                            }
                            let json = response.workstation_details ? response.workstation_details : []

                            let detailsFormat = _.orderBy(json, ['order'], ['asc'])
                            
                            setState(prevState => ({ ...prevState, listRequestHistory: totalResults, filterListRequests: totalResults, detailsFormat, selectedFilters: params, nextHistoryToken: response.next_token, showLoading: false, showSectionLoading: false, callListReqeustHistory: false, callStorageReqeustHistory: true }));
                        }
                    })
            } 
        }
    }, [state.callListReqeustHistory, state.selectedRequestType, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.listRequestHistory, state.nextHistoryToken])


    useEffect(() => {
        if(state.callStorageReqeustHistory) {
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== 'All')
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== 'All')
            }

            if(state.nextHistoryToken) {
                params.next_token = state.nextHistoryToken
            }
    
            if (state.selectedRequestType && state.selectedRequestType === "storage") {
                params.request_type = state.selectedRequestType
                dispatch(listRequestsHistory(params))
                    .then((response) => {
                        if(response) {
                            let totalResults = [] 
                            if(state.nextHistoryToken) {
                                totalResults = state.listRequestHistory &&  state.listRequestHistory.length ? state.listRequestHistory : []
                            }

                            if(response.results && response.results.length) {
                                totalResults = totalResults.concat(response.results)
                            }
                            let json = response.storage_details ? response.storage_details : []

                            let detailsFormat = _.orderBy(json, ['order'], ['asc'])
                            
                            setState(prevState => ({ ...prevState, listRequestHistory: totalResults, filterListRequests: totalResults, detailsFormat, selectedFilters: params, nextHistoryToken: response.next_token, showLoading: false, showSectionLoading: false, callStorageReqeustHistory: false, callListReqeustHistory: true }));
                        }
                    })
            }
        }
    }, [state.callStorageReqeustHistory, state.selectedRequestType, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.listRequestHistory, state.nextHistoryToken])
    

	useEffect(() => {
        let dataResult = state.filterListRequests && state.filterListRequests.length ? state.filterListRequests : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
    }, [state.filterListRequests])

    const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    }

    const getStatusBgColor = (status) => {        
        let color = 'bg-darkPurple-50 border-2 border-darkPurple-600 text-darkPurple-600'
        if(status.toLowerCase() === 'cancelled') { color = 'bg-independenceGray-50 border-2 border-independenceGray-600 text-independenceGray-600' } else 
        if(status.toLowerCase() === 'approved') { color = 'bg-SecondaryGreenTints-600 border-2 border-SecondaryGreen-600 text-NeutralGray-900' } else 
        if(status.toLowerCase() === 'failed') { color = 'bg-red-50 border-2 border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'running') { color = 'bg-appleGreen-50 border-2 border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'bg-blue-50 border-2 border-blue-600 text-blue-600' } else 
        if(status.toLowerCase() === 'rejected') { color = 'bg-red-50 border-2 border-red-600 text-NeutralGray-900' } else 
        if(status.toLowerCase() === 'timeout') { color = 'bg-yellowOrange-50 border-2 border-yellowOrange-600 text-yellowOrange-600' }

        return color;
    }

    return (
        state.showLoading ?
            <div className='flex justify-center'>
                <PageLoading />
            </div>
        :
            <React.Fragment>
            {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <WorkstationSidePanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {} }))}
                    detailsFormat={state.detailsFormat}
                    selectedDetails={state.selectedDetails}
                    showOnlyDeatils={true}
                />
			: state.selectedStorageDetails && Object.entries(state.selectedStorageDetails).length ?
                <StorageSidePanel
                    closeSidePanel={() => setState(prevState => ({...prevState, selectedStorageDetails: {} }))}
                    detailsFormat={state.detailsFormat[0]}
                    selectedDetails={state.selectedStorageDetails}
                    showOnlyDeatils={true}
                />
            : null}
            <div className='p-2 overflow-auto' onClick={(event) => handleChildClick(event, 'parent')}>
                <div className="w-full">
                    <div className={`flex justify-start`}>
                        <ul className="flex flex-wrap text-sm text-white font-bold text-center mb-4">
                            <li className="me-2">
                                <span className={`inline-block mb-0 text-base px-4 text-primaryPurple-600 rounded-t-lg ${state.selectedRequestType === "workstation" ? 'active border-b-2 border-primaryPurple-600' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedRequestType: "workstation", showSectionLoading: true}))}>Workstation</span>
                            </li>
                            <li className="me-2">
                                <span className={`inline-block mb-0 text-base px-4 text-primaryPurple-600 rounded-t-lg ${state.selectedRequestType === "storage" ? 'active border-b-2 border-primaryPurple-600' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedRequestType: "storage", showSectionLoading: true}))}>Storage</span>
                            </li>
                        </ul>
                    </div>

                    {state.showSectionLoading ?
                        <div className="w-full flex justify-center mt-4">
                            <LoadingCircle />
                        </div>
                    : state.filterListRequests && state.filterListRequests.length ?
                        <div className='border border-NeutralGray-600 p-4 rounded-2xl'>
                            <div className='flex flex-wrap justify-between'>
                                <p className='lg:w-2/3 mb-0 self-center text-sm'>
                                    <span>Showing {state.filterListRequests && state.filterListRequests.length ? state.filterListRequests.length : 0 } out of total {state.listRequestHistory && state.listRequestHistory.length ? state.listRequestHistory.length : 0} request history</span>
                                    {state.selectedProvider ?
                                        <React.Fragment>
                                        <span className='ml-1'>obtained from the</span>
                                        <span className='ml-1 font-bold text-primaryPurple-600'>{state.selectedProvider.toUpperCase()}</span>
                                        <span className='ml-1'>provider</span>
                                        </React.Fragment>
                                    : null}
                                    {state.selectedAccount && state.selectedAccount.length ?
                                        <React.Fragment>
                                        <span className='ml-1'>with the account</span>
                                        <span className='ml-1 font-bold text-primaryPurple-600'>{state.selectedAccount[0]}</span>
                                        {state.selectedAccount.length > 1 ?
                                            <span className='ml-1 font-bold text-primaryPurple-600'>more</span>
                                        : null}
                                        </React.Fragment>
                                    : null}
                                    {state.selectedRegion && state.selectedRegion.length ?
                                        <React.Fragment>
                                        <span className='ml-1'>in the</span>
                                        <span className='ml-1 font-bold text-primaryPurple-600'>{state.selectedRegion[0]}</span>
                                        {state.selectedRegion.length > 1 ?
                                            <span className='ml-1 font-bold text-primaryPurple-600'>more</span>
                                        : null}
                                        <span className='ml-1'>region</span>
                                        </React.Fragment>
                                    : null}
                                </p>
                                <div className='lg:w-1/3'>
                                    <div className='flex justify-end'>
                                        {state.listRequestHistory && state.listRequestHistory.length ?
                                        <div className='self-center w-100 mr-4'>
                                            <Search
                                                data={state.listRequestHistory ? state.listRequestHistory : []}
                                                topClassName={'border border-NeutralGray-600 rounded-full bg-white p-2.5 pl-3 pr-4'}
                                                className={'rounded-full'}
                                                filteredData={(filterListRequests) => {
                                                    let totalPages = 1                
                                                    if(filterListRequests.length > state.perPage) {
                                                        totalPages = Math.ceil(filterListRequests.length / state.perPage)
                                                    }
                                                    setState(prevState => ({ ...prevState, filterListRequests, totalPages, currentPage: 1, startRecord: 0 }))
                                                }}
                                            />
                                        </div>
                                        : null}
                                        {state.filterListRequests && state.filterListRequests.length ?
                                                <div className='self-center' title="download">
                                                    <CSVLink 
                                                        data={state.filterListRequests ? state.filterListRequests : []} 
                                                        headers={state.headers ? state.headers : []}
                                                        filename={'workstation_history_'+new Date().getTime()+'.csv'}
                                                        className={'self-center'}
                                                        target='_blank'
                                                    >
                                                        <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center' />
                                                    </CSVLink>
                                                </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            <div className="relative overflow-x-auto mt-3 rounded-t-lg shadow">
                                {state.filterListRequests && state.filterListRequests.length && state.selectedRequestType === "workstation" ?
                                    <ResizeableDarkThemeTable
                                        columns={[
                                            {
                                                Header: 'Name',
                                                // accessor: 'workstation_name',
                                                Cell: cellInfo => (
                                                    cellInfo.row.original.workstation_name ?
                                                        <div className="flex justify-between cursor-pointer self-center">
                                                            <div className='text-byzantineBlue mr-2' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original }))}>{cellInfo.row.original.workstation_name}</div>
                                                        </div>
                                                    :
                                                        <span className={`${getStatusBgColor(cellInfo.row.original.status)}`}>No Name</span>
                                                ),
                                                width: 250
                                            },
                                            {
                                                Header: 'Status',
                                                accessor: 'Status',
                                                Cell: cellInfo => (
                                                    <div className='flex'>
                                                        {cellInfo.row.original.status ?
                                                            <span className={`${getStatusBgColor(cellInfo.row.original.status)} w-fit h-fit rounded-full text-sm font-light px-2 flex justify-center items-center`}>{capitalizeFirstLetter(cellInfo.row.original.status)}</span>
                                                        : null}
                                                        {cellInfo.row.original.error_code ?
                                                            <span className={`ml-2 text-info cursor-pointer fa fa-info-circle self-center`} onClick={() => setState(prevState => ({ ...prevState, errorJobDetails: cellInfo.row.original, errorModal: true }))}></span>
                                                        : null}
                                                    </div>
                                                    // cellInfo.row.original.status ?
                                                    //     <span className={`badge badge-outline-${cellInfo.row.original.status === 'rejected' ? 'danger' : cellInfo.row.original.status === 'approved' ? 'success' : 'info'}`}>{cellInfo.row.original.status}</span>
                                                    // : 
                                                    //     <span className={`badge badge-outline-secondary`}>No Status</span>
                                                ),
                                                width: 150
                                            },
                                            {
                                                Header: 'Date',
                                                accessor: 'created_at',
                                                Cell: cellInfo => (<span>{cellInfo.row.original.created_at ?  momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : ''}</span>),
                                                width: 180
                                            },
                                            {
                                                Header: 'Requested By',
                                                accessor: 'user_name',
                                                width: 180
                                            },
                                            {
                                                Header: 'Resources',
                                                accessor: d => d.provider + ' : ' + d.account_id + ' : ' + d.region,
                                                Cell: cellInfo => (
                                                    <React.Fragment>
                                                        <span className=''>
                                                            {(cellInfo.row.original.asset_name
                                                                ? cellInfo.row.original.asset_name + ' : '
                                                                : '') +
                                                                cellInfo.row.original.provider +
                                                                ' : ' +
                                                                cellInfo.row.original.account_id +
                                                                ' : ' +
                                                                (cellInfo.row.original.region === 'NA'
                                                                    ? 'Global'
                                                                    : cellInfo.row.original.region)}
                                                        </span>
                                                    </React.Fragment>
                                                ),
                                                width: 300
                                            },
                                            {
                                                Header: 'Catalog',
                                                accessor: d => d.catalog_name ? capitalizeFirstLetter(d.catalog_name) : '',
                                                width: 300,
                                            },
                                            {
                                                Header: 'Instance Type',
                                                accessor: 'instance_type',
                                                // accessor: d => d.instance_type + ' : ' + d.vcpus + ' : ' + d.memory,
                                                Cell: cellInfo => (
                                                    <React.Fragment>
                                                        {cellInfo.row.original.instance_type ?
                                                            <span className=''>
                                                                {(cellInfo.row.original.instance_type ? cellInfo.row.original.instance_type : '') + '  ('+
                                                                cellInfo.row.original.vcpus + ' vCPUs'+
                                                                '     memory' +
                                                                cellInfo.row.original.memory +
                                                                '  '+
                                                                (cellInfo.row.original.memory_unit ? cellInfo.row.original.memory_unit : '')
                                                                +')'}
                                                            </span>
                                                        :
                                                            <span className={`${getStatusBgColor(cellInfo.row.original.status)}`}>-</span>}
                                                    </React.Fragment>
                                                ),
                                                width: 200,
                                            }
                                        ]}
                                        data={state.filterListRequests}
                                        perPage={10}
                                        selectedColor={'bg-transparent'}
                                        dashboard={state.filterListRequests && state.filterListRequests.length ? true : false}
                                        sortByColumn={''}
                                        riskTooltip={[0]}
                                        onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedlistRequestHistory: tableRow }))}
                                        // tableSize={'table-sm'}
                                        paginationIndex={state.paginationIndex ? state.paginationIndex : 0}
                                        checkForNextSetoffRecords={state.nextHistoryToken ? true : false}
                                        callToFetchNewRecord={(paginationIndex) => {
                                            if(state.nextHistoryToken) {
                                                setState(prevState => ({ ...prevState, callListReqeustHistory: true, paginationIndex }))
                                            }
                                        }}
                                        showPaginationTop={false}
                                        paginationIconClass={'text-lightGray'}
                                        paginationIconWidth={18}
                                    />
                                : state.filterListRequests && state.filterListRequests.length && state.selectedRequestType === "storage" ?
                                    <ResizeableDarkThemeTable
                                        columns={[
                                            {
                                                Header: 'Name',
                                                // accessor: 'workstation_name',
                                                accessor: 'Name',
                                                Cell: cellInfo => (
                                                    cellInfo.row.original.storage_request_id ?
                                                        <div className="flex justify-between cursor-pointer self-center">
                                                            <div className='text-byzantineBlue mr-2' onClick={() => setState(prevState => ({ ...prevState, selectedStorageDetails: cellInfo.row.original }))}>Storage&nbsp;request - {cellInfo.row.original.new_storage}&nbsp;{cellInfo.row.original.storage_unit}</div>
                                                        </div>
                                                    :
                                                        <span className={`badge badge-outline-secondary`}>No Name</span>
                                                ),
                                                width: 300
                                            },
                                            {
                                                Header: 'Status',
                                                accessor: 'Status',
                                                Cell: cellInfo => (
                                                    cellInfo.row.original.status ?
                                                        <span className={`badge badge-outline-${cellInfo.row.original.status === 'rejected' ? 'danger' : cellInfo.row.original.status === 'approved' ? 'success' : 'info'}`}>{cellInfo.row.original.status}</span>
                                                    : 
                                                        <span className={`badge badge-outline-secondary`}>No Status</span>
                                                ),
                                                width: 200
                                            },
                                            {
                                                Header: 'Date',
                                                accessor: 'created_at',
                                                Cell: cellInfo => (<span>{cellInfo.row.original.created_at ?  momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : ''}</span>),
                                                width: 200
                                            },
                                            {
                                                Header: 'Requested By',
                                                accessor: 'user_name',
                                                width: 250
                                            },
                                            {
                                                Header: 'Resources',
                                                accessor: d => d.provider + ' : ' + d.account_id + ' : ' + d.region,
                                                Cell: cellInfo => (
                                                    <React.Fragment>
                                                        <span className=''>
                                                            {(cellInfo.row.original.asset_name
                                                                ? cellInfo.row.original.asset_name + ' : '
                                                                : '') +
                                                                cellInfo.row.original.provider +
                                                                ' : ' +
                                                                cellInfo.row.original.account_id +
                                                                ' : ' +
                                                                (cellInfo.row.original.region === 'NA'
                                                                    ? 'Global'
                                                                    : cellInfo.row.original.region) +
                                                                (cellInfo.row.original.cluster_name
                                                                    ? ' : '+ cellInfo.row.original.cluster_name
                                                                    : '')}
                                                            </span>
                                                    </React.Fragment>
                                                ),
                                                width: 450
                                            }
                                        ]}
                                        data={state.filterListRequests}
                                            perPage={10}
                                            selectedColor={'bg-transparent'}
                                            dashboard={state.filterListRequests && state.filterListRequests.length ? true : false}
                                            sortByColumn={''}
                                            riskTooltip={[0]}
                                            onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedlistRequestHistory: tableRow }))}
                                            // tableSize={'table-sm'}
                                            paginationIndex={state.paginationIndex ? state.paginationIndex : 0}
                                            checkForNextSetoffRecords={state.nextHistoryToken ? true : false}
                                            callToFetchNewRecord={(paginationIndex) => {
                                                if(state.nextHistoryToken) {
                                                    setState(prevState => ({ ...prevState, callListReqeustHistory: true, paginationIndex }))
                                                }
                                            }}
                                            showPaginationTop={false}
                                            paginationIconClass={'text-lightGray'}
                                            paginationIconWidth={18}
                                            />
                                            :
                                            
                                        <div className='w-full flex justify-center m-4 text-white'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                }
                            </div>
                        </div>
                    :   <div className='w-full flex justify-center m-4 text-white'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                        }
                </div>
            </div>
            </React.Fragment>
    );
};

export default RequestHistory