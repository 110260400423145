/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file loadingpage.js
 * @author Prakash // on 28/06/2023
 * @copyright © 2022 Collider. All rights reserved.
 **************************************************/
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate } from 'react-router-dom';
import { URL_PATH } from '../config/urlPath'
// import { getUserProfile } from '../actions/commonActionNew'
import PageLoading from './common/PageLoading';
const LoadingPage = () => {
    const userMenu = useSelector(state => state.filters.userMenu);
    // const profileDetails = useSelector(state => state?.filters?.profileDetails || false);	
    // const history = useHistory();
    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function
    
    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
            // this.props.history.push(URL_PATH.LOADING_PAGE)
            // this.props.history.push(URL_PATH.AIOPS_DASHBOARD)
            // window.location.href = URL_PATH.CHECKS_SEARCH
            navigate(userMenu.defaultLandingPage && userMenu.defaultLandingPage.link ? userMenu.defaultLandingPage.link : URL_PATH.JOB_STATUS);

            // history.push(userMenu.defaultLandingPage && userMenu.defaultLandingPage.link ? userMenu.defaultLandingPage.link : URL_PATH.JOB_STATUS)
        }
        // if(!Object.entries(profileDetails).length) {
        //     dispatch(getUserProfile())
        // }
    }, [userMenu, navigate, dispatch])
    

    // componentDidUpdate = (prevProps) => {
    //     if(this.props.userMenu && Object.entries(this.props.userMenu).length && !_.isEqual(prevProps.userMenu, this.props.userMenu)) {
    //         // this.props.history.push(URL_PATH.CHECKS_SEARCH)
    //         this.props.history.push(this.props.userMenu.defaultLandingPage && this.props.userMenu.defaultLandingPage.link ? this.props.userMenu.defaultLandingPage.link : URL_PATH.JOB_STATUS)
    //     }
    // }

    /**
     * Renders Errro page
     */
    return (
        <div className='container-fluid overflow-auto flex-grow-1'>
            <PageLoading />
        </div>
    )
}

export default LoadingPage