import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Input } from 'reactstrap'
import { capitalizeAllLetter, momentSlashConvertionLocalToUtcTime } from '../../../utils/utility';
import { listAllProviders, listClusterFilters, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { getJobFilters, submitJob, getJobBudget } from '../../../actions/Collider/JobStatusAction';
 import { Store as CommonNotification } from 'react-notifications-component';
import Files from '../../hpc/files/file-list/index'
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import SelectOption from '../../common/Form/SelectOption';
import RadioOption from '../../common/Form/RadioOption';
import Textbox from '../../common/Form/Textbox';
import TimeInput from '../../common/Form/TimeInput';
import DateTimeInput from '../../common/Form/DateTime';
import Textarea from '../../common/Form/Textarea';

const CreateJobPanel = ({ closeSidePanel }) => {
    
    const [state, setState] = useState({
        showLoading: false,
        job_type: 'manually',
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        logsList: [],
        filteredLogsList: [],
        clusters: [],
        scheduleRadio: 'run_immediately',
        budgetDetails: false
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        dispatch(setCommonPropsDetails('fileManageFilterChange', {}))
    }, [dispatch])

    useEffect(() => {
        if(state.selectedProvider && state.selectedAccount && state.selectedRegion && state.selectedCluster) {
            let obj = {
                selectedProvider: state.selectedProvider,
                selectedAccount: state.selectedAccount,
                selectedRegion: state.selectedRegion,
                selectedClusters: state.selectedCluster,
              }
            dispatch(setCommonPropsDetails('fileManageFilterChange', obj))
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedCluster])
    
    useEffect(() => {
        let params = {}
        if(providers) {
            setState(prevState => ({ ...prevState, providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : '')
            }));
        } else {
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''
                        }));
                    }
            })
        }
    }, [dispatch, providers])

    // Call provider based accounts whenever the selectedProviders state is updated
    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
			if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount) {
                label = state.selectedProvider+'_'+state.selectedAccount
                params.account_id = state.selectedAccount
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
							let obj = propProjRegions ? propProjRegions : {}
							obj[label] = response
							dispatch(setCommonPropsDetails('propProjRegions', obj))
							setState(prevState => ({ ...prevState, regions: response }));
						}
                    })
            }
        }
    }, [state.selectedProvider, state.selectedAccount, dispatch, propProjRegions]);

    useEffect(() => {
        if(state.selectedCluster && state.job_type === 'manually') {        
            let jobParams = {}
            jobParams.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                jobParams.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                jobParams.region = state.selectedRegion
            }
            jobParams.cluster_name = state.selectedCluster
            
            dispatch(getJobFilters(jobParams))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            application: response.licenses?.application ? response.licenses.application  : [],
                            queues: response.queues ? response.queues : [],
                            licenses: response.licenses?.license_name ? response.licenses.license_name : [],
                            qos: response.qos ? response.qos : [],
                            qosFilter: response.queue_qos_filters ? response.queue_qos_filters : {},
                            qosParameters: response.qos_parameters ? response.qos_parameters : {},
                            jobFilters: response
                        }));
                    }
            })
        }
    }, [state.selectedCluster, dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.job_type])

    useEffect(() => {
        if(state.selectedQueue) {
            let qosFilter = state.qosFilter && state.qosFilter[state.selectedQueue] && state.qosFilter[state.selectedQueue].qos ? state.qosFilter[state.selectedQueue].qos :[]
            let qosMessage =  state.qosFilter && state.qosFilter[state.selectedQueue] && state.qosFilter[state.selectedQueue].message ? state.qosFilter[state.selectedQueue].message : ''
            setState(prevState => ({
                ...prevState,
                qos: qosFilter,
                qosMessage,
            }));
        }
    }, [state.selectedQueue ,state.qosFilter])

    useEffect(() => {
        if(state.qos_name) {
            let qosParValue = state.qosParameters[state.qos_name] ? state.qosParameters[state.qos_name] : {}
            setState(prevState => ({
                ...prevState,
                qosParValue
            }));
        }
    }, [state.qos_name, state.qosParameters])

    useEffect(() => {
        if (state.selectedProvider && state.selectedAccount && state.selectedRegion) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            clusters: response,
                            fetchingCluster: false
                        }));
                    }
            })
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])


    

	const handleCommand = value => {
		setState(prevState => ({ ...prevState, command: value }))
	}

    const handleJobScriptCommand = e => {		
        const { name, value } = e.target;
		setState(prevState => ({ ...prevState, [name]: value }))
	}

    useEffect(() => {
        if(state.getJobBudgetDetail) {
            setState(prevState => ({ ...prevState, getJobBudgetDetail: false }))

            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            params.cluster_name = state.selectedCluster
            let inputValidationError = false
            if(state.job_type && state.job_type === "manually") {
                params.queue_name = state.selectedQueue
                params.job_name = state.job_name
                if(state.selectedProvider !== 'GCP') {
                    params.qos_name = state.qos_name
                    if(state.working_directory && state.working_directory !== '') {
                        params.working_directory = state.working_directory
                    }
                    params.total_tasks = parseInt(state.total_tasks)
                    params.cpus_per_task = parseInt(state.cpus_per_task)
                    params.total_nodes = parseInt(state.total_nodes)
                    params.tasks_per_node = parseInt(state.tasks_per_node)
                    if(state.memory && state.memory !== '') {
                        params.memory = parseInt(state.memory)
                    }
                    params.command = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue ||  !state.job_name || !state.qos_name || !state.total_tasks || !state.cpus_per_task || !state.total_nodes || !state.tasks_per_node || !state.command) {
                        inputValidationError = true
                    }
                } else {
                    params.cpus = parseInt(state.cpus)
                    params.job_script = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue || !state.job_name || !state.cpus || !state.command) {
                        inputValidationError = true
                    }
                }
                // if(state.job_account && state.job_account !== '') {
                //     params.job_account = state.job_account
                // }
                if(state.walltime && state.walltime !== '') {
                    params.walltime = state.walltime
                }
                // params.unlimited_ram = state.unlimited_ram ? true : false 
                // params.environment_variables = state.environment_variables

            } else {
                params.job_script = state.job_script
                if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.job_script || state.job_script === "") {
                    inputValidationError = true
                }
            }
            if(state.scheduled_at) {
                params.run_immediately = false
                params.scheduled_at = momentSlashConvertionLocalToUtcTime(state.scheduled_at, "YYYY-MM-DD HH:mm:00")
            } else {
                params.run_immediately = true
            }
            if(state.selectedProvider === 'AWS') {
                if(state.selectedLincense) {
                    params.license_name = state.selectedLincense
                }
    
                if(state.selectedApplication) {
                    params.application = state.selectedApplication
                    params.licenses_count = parseInt(state.licenses_count)
                }
            }

            
    
            setState(prevState => ({ ...prevState, inputValidationError, saveLoading: inputValidationError ? false : true }))

            if(!inputValidationError) {
                dispatch(getJobBudget(params))
                    .then((response) => {
                        if(response && !response.error) {
                            setState(prevState => ({ ...prevState, budgetDetails: response, saveLoading: false }))
                        } else {
                            setState(prevState => ({ ...prevState, saveLoading: false, budgetDetails: [] }))
                        }
                    })
            }

        }
    }, [state, dispatch])

    useEffect(() => {
        if(state.callSaveFunction) {
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            params.cluster_name = state.selectedCluster
            params.job_budget = state.budgetDetails
            let inputValidationError = false
            if(state.job_type && state.job_type === "manually") {
                params.queue_name = state.selectedQueue
                params.job_name = state.job_name
                if(state.selectedProvider !== 'GCP') {
                    params.qos_name = state.qos_name
                    if(state.working_directory && state.working_directory !== '') {
                        params.working_directory = state.working_directory
                    }
                    params.total_tasks = parseInt(state.total_tasks)
                    params.cpus_per_task = parseInt(state.cpus_per_task)
                    params.total_nodes = parseInt(state.total_nodes)
                    params.tasks_per_node = parseInt(state.tasks_per_node)
                    if(state.memory && state.memory !== '') {
                        params.memory = parseInt(state.memory)
                    }
                    params.command = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue ||  !state.job_name || !state.qos_name || !state.total_tasks || !state.cpus_per_task || !state.total_nodes || !state.tasks_per_node || !state.command) {
                        inputValidationError = true
                    }
                } else {
                    params.cpus = parseInt(state.cpus)
                    params.job_script = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue || !state.job_name || !state.cpus || !state.command) {
                        inputValidationError = true
                    }
                }
                // if(state.job_account && state.job_account !== '') {
                //     params.job_account = state.job_account
                // }
                if(state.walltime && state.walltime !== '') {
                    params.walltime = state.walltime
                }
                // params.unlimited_ram = state.unlimited_ram ? true : false 
                // params.environment_variables = state.environment_variables

            } else {
                params.job_script = state.job_script
                if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.job_script || state.job_script === "") {
                    inputValidationError = true
                }
            }
            if(state.scheduled_at) {
                params.run_immediately = false
                params.scheduled_at = momentSlashConvertionLocalToUtcTime(state.scheduled_at, "YYYY-MM-DD HH:mm:00")
            } else {
                params.run_immediately = true
            }
            if(state.selectedProvider === 'AWS') {
                if(state.selectedLincense) {
                    params.license_name = state.selectedLincense
                    if(state.licenses_count) {
                        params.licenses_count = parseInt(state.licenses_count)
                    } else {
                        inputValidationError = true
                    }
                }
    
                if(state.selectedApplication) {
                    params.application = state.selectedApplication
                }
            }
    
            setState(prevState => ({ ...prevState, inputValidationError, saveLoading: inputValidationError ? false : true, callSaveFunction: false }))
            
            if(!inputValidationError) {
                dispatch(submitJob(params))
                    .then((response) => {
                        let messageType = 'danger'
                        let message = response && response.message ? response.message : 'Error in creating job'
                        if(response && !response.error) {
                            if(response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'Job created successfully'
                            }
                        }
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                            }
                        });

                        setState(prevState => ({ ...prevState, budgetDetails: false }))
        
                        if(response && !response.error) {
                            if(response.status) {
                                setTimeout(() => closeSidePanel(), 1000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false, budgetDetails: false }))
                            }
                        } else {
                            setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false, budgetDetails: false }))
                        }
                        // setState(prevState => ({ ...prevState, createRespons: response }))
                    })
    
            }
        }
    },  [state.callSaveFunction, closeSidePanel, dispatch, state])
    
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (event) => {
          const content = event.target.result;
          setState(prevState => ({ ...prevState, job_script: content, showLocalFileOptions: false }))
        };
    
        reader.readAsText(file);
    };

	const handleChildClick = (event, type, dropdownType, section) => {

		event.stopPropagation();
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full shadow-2xl w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col w-4/5'>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black shadow-xl'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl'>Create Job</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black cursor-pointer self-center' width={30} height={30} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                    <div className='overflow-y-auto h-[calc(100vh-120px)] bg-white'>
                        <div className='p-8 h-full'>
                            <div className='flex flex-wrap'>
                                <div className='flex justify-start w-1/2 pr-4'>
                                    <SelectOption
                                        label={"Provider"}
                                        fields={["provider_name", "provider_name"]}
                                        options={state.providers}
                                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedProvider: value, selectedProjectTags: [], selectedAccount: '', selectedRegion: '', selectedClusters: [], selectedQueue: [] }))
                                        }}
                                        manditory={true}
                                        singleSelection={true}
                                        dropdownWidth={'min-w-28'}
                                    />
                                </div>
                                <div className='flex justify-start w-1/2 pl-4'>
                                    <SelectOption
                                        label={"Account"}
                                        fields={["account_id", "account_name"]}
                                        options={state.accounts}
                                        selectedValues={state.selectedAccount ? state.selectedAccount : ''}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedAccount: value, selectedCluster: "", selectedQueue: "", qos_name: "", fetchingCluster: true, budgetDetails: false }))
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        dropdownWidth={'min-w-28'}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-wrap pt-8'>
                                <div className='flex justify-start w-1/2 pr-4'>
                                    <SelectOption
                                        label={"Region"}
                                        fields={["region", "name"]}
                                        options={state.regions}
                                        selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedRegion: value, selectedCluster: "", selectedQueue: "", qos_name: "", fetchingCluster: true, budgetDetails: false }))
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        dropdownWidth={'min-w-28'}
                                    />
                                </div>
                                <div className='flex justify-start w-1/2 pl-4'>
                                    <SelectOption
                                        label={"Cluster Name"}
                                        options={state.clusters}
                                        selectedValues={state.selectedCluster ? state.selectedCluster : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedCluster: value, selectedQueue: "", qos_name: "", budgetDetails: false }))
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        dropdownWidth={'min-w-28'}
                                    />
                                </div>
                            </div>
                            {state.selectedProvider !== 'GCP' ?
                                <div className='pt-8'>
                                    <p className="text-base font-medium text-black self-center w-full">Select Job Type </p>
                                    <div className='flex pt-3'>
                                        <RadioOption
                                            label="Manual Job"
                                            value="manually"
                                            selected={state.job_type === "manually"}
                                            callback={() => setState(prevState => ({ ...prevState, job_type: "manually" }))}
                                        />
                                        <RadioOption
                                            label="Script Job"
                                            value="script"
                                            selected={state.job_type === "script"}
                                            callback={() => setState(prevState => ({ ...prevState, job_type: "script" }))}
                                            className="pl-8"
                                        />
                                    </div>
                                </div>
                            : null}
                            {state.job_type === "manually"  ?
                                <React.Fragment>
                                    <div className='flex flex-wrap pt-8'>
                                        <div className='flex justify-start w-1/2 pr-4'>
                                            <SelectOption
                                                label={"Queue Name"}
                                                options={state.queues}
                                                selectedValues={state.selectedQueue ? state.selectedQueue : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedQueue: value, budgetDetails: false }))
                                                }}
                                                singleSelection={true}
                                                manditory={true}
                                                dropdownWidth={'min-w-28'}
                                            />
                                        </div>
                                        <div className='flex justify-start w-1/2 pl-4'>
                                            <Textbox
                                                label={"Job Name"}
                                                selectedValues={state.job_name ? state.job_name : ''}
                                                callback={(value) => {
                                                    setState(prevState => ({ ...prevState, job_name: value, budgetDetails: false }))
                                                }}
                                                manditory={true}
                                            />
                                        </div>
                                    </div>
                                    {state.selectedProvider !== 'GCP' ?
                                        <div className='flex flex-wrap pt-8'>
                                            <div className='flex justify-start w-1/2 pr-4'>
                                                <SelectOption
                                                    label={"Qos Name"}
                                                    options={state.qos}
                                                    selectedValues={state.qos_name ? state.qos_name : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, qos_name: value, budgetDetails: false }))
                                                    }}
                                                    singleSelection={true}
                                                    manditory={true}
                                                    dropdownWidth={'min-w-28'}
                                                />
                                            </div>
                                            <div className='flex justify-start w-1/2 pl-4'>
                                                <Textbox
                                                    label={"Working Directory"}
                                                    selectedValues={state.working_directory ? state.working_directory : ''}
                                                    callback={(value) => {
                                                        setState(prevState => ({ ...prevState, working_directory: value }))
                                                    }}
                                                    manditory={true}
                                                />
                                            </div>
                                        </div>
                                    : null}
                                    {state.showMessage && (state.help_field === 'qos_name' || state.help_field === 'working_directory') ?
                                        <p className='border border-primaryPurple-600 rounded-full px-4 py-2.5 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{state.jobFilters && state.jobFilters[state.help_field] ? state.jobFilters[state.help_field] :''}</p>
                                    : null}

                                    <div className='flex flex-wrap mt-2'>
                                        {state.qosParValue && Object.entries(state.qosParValue).length ?
                                            Object.entries(state.qosParValue).map(([key, value], qIndex) => {
                                                return(
                                                    <span key={"row_"+qIndex} className="border border-primaryPurple-600 rounded-full px-4 py-2.5 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{key+' : '+value}
                                                    </span>
                                                )
                                            })
                                        : state.selectedQueue && state.qos_name && state.qosParValue ?
                                            <p className="text-red-600 my-1 self-center">QOS parameters not found</p>
                                        : null}
                                    </div>
                                    
                                    {state.selectedProvider !== 'GCP' ?
                                        <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                                            <p className='font-bold text-NeutralGray-800 text-[22px] pb-4'>Parameters</p>
                                            <div className='flex flex-wrap'>
                                                <div className='flex justify-start w-1/2 pr-4'>
                                                    <Textbox
                                                        label={"Total Tasks"}
                                                        type="number"
                                                        selectedValues={state.total_tasks ? state.total_tasks : ''}
                                                        callback={(value) => {
                                                        setState(prevState => ({ ...prevState, total_tasks: value }))
                                                        }}
                                                        manditory={true}
                                                    />
                                                </div>
                                                <div className='flex justify-start w-1/2 pl-4'>
                                                    <Textbox
                                                        label={"CPUS Per Task"}
                                                        type="number"
                                                        selectedValues={state.cpus_per_task ? state.cpus_per_task : ''}
                                                        callback={(value) => {
                                                        setState(prevState => ({ ...prevState, cpus_per_task: value }))
                                                        }}
                                                        manditory={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex flex-wrap pt-8'>
                                                <div className='flex justify-start w-1/2 pr-4'>
                                                    <Textbox
                                                        label={"Total Nodes"}
                                                        type="number"
                                                        selectedValues={state.total_nodes ? state.total_nodes : ''}
                                                        callback={(value) => {
                                                        setState(prevState => ({ ...prevState, total_nodes: value }))
                                                        }}
                                                        manditory={true}
                                                    />
                                                </div>
                                                <div className='flex justify-start w-1/2 pl-4'>
                                                    <Textbox
                                                        label={"Tasks Per Node"}
                                                        type="number"
                                                        selectedValues={state.tasks_per_node ? state.tasks_per_node : ''}
                                                        callback={(value) => {
                                                        setState(prevState => ({ ...prevState, tasks_per_node: value }))
                                                        }}
                                                        manditory={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    : null}
                                    {state.showMessage && (state.help_field === 'total_tasks' || state.help_field === 'cpus_per_task' || state.help_field === 'total_nodes' || state.help_field === 'tasks_per_node') ?
                                        <p className='my-1 text-purplishRed'>{state.jobFilters && state.jobFilters[state.help_field] ? state.jobFilters[state.help_field] :''}</p>
                                    : null}
                                    
                                    <div className='flex flex-wrap pt-8'>
                                        {state.selectedProvider !== 'GCP' ?
                                            <div className='flex justify-start w-1/2 pr-4'>
                                                <Textbox
                                                    label={"Memory (MB)"}
                                                    type="number"
                                                    callback={(value) => {
                                                        setState(prevState => ({ ...prevState, memory: value }))
                                                    }}
                                                    manditory={true}
                                                />
                                            </div>
                                        : 
                                            <div className='flex justify-start w-1/2 pr-4'>
                                                <Textbox
                                                    label={"Total CPUs"}
                                                    type="number"
                                                    selectedValues={state.cpus ? state.cpus : ''}
                                                    callback={(value) => {
                                                        setState(prevState => ({ ...prevState, cpus: value }))
                                                    }}
                                                    manditory={true}
                                                />
                                            </div>
                                        }
                                        <div className='flex justify-start w-1/2 pl-4'>
                                            <TimeInput
                                                label={"Time"}
                                                callback={(value) => {
                                                    setState(prevState => ({ ...prevState, walltime: value }))
                                                }}
                                                manditory={true}
                                            />
                                        </div>
                                    </div>
                                    {state.selectedProvider === 'AWS' ?
                                        <div className={`border-t border-NeutralGray-600 pt-8 my-8`}>
                                            <p className='font-bold text-NeutralGray-800 text-[22px] pb-4'>Licenses</p>
                                            <div className='flex flex-wrap'>
                                                <div className='flex justify-start w-1/2 pr-4'>
                                                    <SelectOption
                                                        label={"Application"}
                                                        fields={["provider_name", "provider_name"]}
                                                        options={state.licenses}
                                                        selectedValues={state.selectedLincense ? state.selectedLincense : []}
                                                        callbackMultiSelect={(value) => {
                                                            setState(prevState => ({ ...prevState, selectedLincense: value, selectedApplication: state.licenses.filter(e => e.license_name === value)[0].application, budgetDetails: false }))
                                                        }}
                                                        manditory={true}
                                                        singleSelection={true}
                                                        dropdownWidth={'min-w-28'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    : null}
                                    {state.showMessage && (state.help_field === 'memory' || state.help_field === 'walltime') ?
                                        <p className='my-1 text-purplishRed'>{state.jobFilters && state.jobFilters[state.help_field] ? state.jobFilters[state.help_field] :''}</p>
                                    : null}                                
                                </React.Fragment>
                            : null}
                            <div className='pt-8'>
                                <p className="text-base font-medium text-black self-center w-full">When it should start </p>
                                <div className='flex pt-3'>
                                    <RadioOption
                                        label="Run Immediately"
                                        value="run_immediately"
                                        selected={state.scheduleRadio === "run_immediately"}
                                        callback={() => setState(prevState => ({ ...prevState, scheduleRadio: "run_immediately" }))}
                                    />
                                    <RadioOption
                                        label="Schedule At"
                                        value="schedule_at"
                                        selected={state.scheduleRadio === "schedule_at"}
                                        callback={() => setState(prevState => ({ ...prevState, scheduleRadio: "schedule_at" }))}
                                        className="pl-8"
                                    />
                                </div>
                                {state.scheduleRadio === "schedule_at" ?
                                    <div className='flex justify-start w-1/2 pt-3'>
                                        <DateTimeInput
                                            label={"Schedule At"}
                                            selectedValues={state.scheduled_at ? state.scheduled_at : ''}
                                            callback={(value) => {
                                                setState(prevState => ({ ...prevState, scheduled_at: value }))
                                            }}
                                            manditory={true}
                                        />
                                    </div>
                                : null}
                            </div>                            
                            {state.job_type === "manually" ?
                                <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                                    <Textarea
                                        label={'Command'}
                                        callback={handleCommand}
                                        manditory={true}
                                        rows={4}
                                    />
                                </div>
                            : 
                                <div className='mt-4 border-t border-darkGray'>
                                    <div className='flex justify-end w-full py-6'>
                                        <span className='cursor-pointer border border-NeutralGray-600 py-2 pl-4 pr-3 rounded-full text-NeutralGray-800 text-base' onClick={() => setState(prevState => ({ ...prevState, showLocalFileOptions: !state.showLocalFileOptions }))}>{!state.showLocalFileOptions ? 'Open' : 'Close'} a Local File</span>
                                        {state.selectedCluster ?
                                            <span className='cursor-pointer border border-NeutralGray-600 py-2 pl-4 pr-3 rounded-full text-NeutralGray-800 ml-3 text-base' onClick={() => setState(prevState => ({ ...prevState, showLocalFileOptions: false, showFileBrowser: !state.showFileBrowser }))}>Browse shared folder</span>
                                        : null}
                                    </div>

                                    {state.showLocalFileOptions ?
                                        <div className='bg-white border border-NeutralGray-600 rounded-2xl p-4 w-fit justify-center'>
                                            <p className='text-black'>Plese choose a file from your system</p>
                                            <input className='m-4' type="file" onChange={handleFileChange} />
                                        </div>
                                    : state.showFileBrowser ?
                                        <div className='file-browser bg-white py-2'>
                                            <Files 
                                                fromScreen="jobs"
                                                selectedFile={(fileContent) => {
                                                    let job_script = fileContent && fileContent.data ? fileContent.data : ''
                                                    setState(prevState => ({ ...prevState, job_script, showFileBrowser: false }))
                                                }}
                                            />
                                        </div>
                                    :
                                        // <div className='w-full pt-8'>
                                        //     <Textarea
                                        //         label={'Job Script'}
                                        //         callback={handleJobScriptCommand}
                                        //         manditory={true}
                                        //         rows={4}
                                        //     />
                                        // </div>
                                        <div className='py-1 w-full mb-5'>
                                            <div className='flex justify-between'>
                                                <p className="b-block mb-0 text-NeutralGray-600 text-2xl flex">Job Script
                                                    <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                                </p>
                                                {state.inputValidationError && !state.command ?
                                                    <span className='mb-0 text-red ml-2'>required</span>
                                                : null}
                                            </div>
                                            <textarea id="job_script" name="job_script"
                                                rows="15" 
                                                className="w-full p-2 mb-4 bg-transparent border border-NeutralGray-600 rounded-2xl p-4 text-xl text-black" 
                                                onChange={handleJobScriptCommand}
                                                value={state.job_script}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="w-full p-8 shadow-lg bg-NeutralGray-700">
                        <div className={`flex justify-${state.budgetDetails ? 'between' : 'end'}`}>
                            {state.budgetDetails ?
                                <div>
                                    <div className='flex pl-4 text-base '>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Budget&nbsp;Type:</span>
                                            <span className='text-yellowOrange-600'>
                                                {state.budgetDetails && state.budgetDetails.budget_type ? state.budgetDetails.budget_type : 0 }
                                            </span>
                                        </div>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Total&nbsp;Budget:</span>
                                            <span className='text-yellowOrange-600'>
                                                {state.budgetDetails && state.budgetDetails.total_budget ? state.budgetDetails.total_budget : 0 }
                                                <span className='ml-1 f14 text-lightGray'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span>
                                            </span>
                                        </div>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Used&nbsp;Budget:</span>
                                            <span className='text-yellowOrange-600'>
                                                {state.budgetDetails && state.budgetDetails.budget_used ? state.budgetDetails.budget_used : 0 }
                                                <span className='ml-1 f14 text-lightGray'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span>
                                            </span>
                                        </div>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Available&nbsp;Budget:</span>
                                            <span className='text-yellowOrange-600'>
                                                {state.budgetDetails && state.budgetDetails.available_budget ? state.budgetDetails.available_budget : 0 }
                                                <span className='ml-1 f14 text-lightGray'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span>
                                            </span>
                                        </div>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Job&nbsp;Cost:</span>
                                            <span className='text-yellowOrange-600'>
                                                {state.budgetDetails && state.budgetDetails.job_cost ? state.budgetDetails.job_cost : 0 }
                                                <span className='ml-1 f14 text-lightGray'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='flex mt-3 pl-4 text-base'>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Total&nbsp;Licenses:</span>
                                            <span className='text-yellowOrange-600'>
                                                {state.budgetDetails && state.budgetDetails.total_licenses ? state.budgetDetails.total_licenses : 0 }
                                            </span>
                                        </div>
                                        <div className='flex self-center mr-3 text-white'>
                                            <span className='mr-2'>Used&nbsp;Licenses:</span>
                                            <span className='text-yellowOrange-600'>
                                                {state.budgetDetails && state.budgetDetails.licenses_used ? state.budgetDetails.licenses_used : 0 }
                                            </span>
                                        </div>
                                        <div className='flex self-center mr-5 text-white'>
                                            <span className='mr-2'>Available&nbsp;Licenses:</span>
                                            <span className='text-yellowOrange-600'>
                                                {state.budgetDetails && state.budgetDetails.available_licenses ? state.budgetDetails.available_licenses : 0 }
                                            </span>
                                        </div>
                                    </div>
                                    {state.budgetDetails && state.budgetDetails.message ?
                                        <p className='flex pl-4 text-danger'>{state.budgetDetails.message}</p>
                                    : null}
                                </div>
                            : null}
                            <div className={`self-center flex justify-end`}>
                                {state.saveLoading ?
                                    <button type="button" className="bg-green-600 text-white text-base font-medium rounded-md px-[18px] py-2.5 inline-flex items-center self-center"><Icon icon="mingcute:down-small-line mt-1 mr-2"></Icon>  Submitting</button>
                                :
                                    <div className='flex'>
                                        {state.budgetDetails && state.budgetDetails ?
                                            <button type="button" className="bg-green-600 text-white text-base font-medium rounded-md px-[18px] py-2.5 inline-flex items-center self-center"
                                            onClick={ () => {
                                                if(state.budgetDetails.job_submission) {
                                                    setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))
                                                }
                                            }}>
                                                Continue
                                            </button>
                                        : !state.budgetDetails ?
                                            <button type="button" className="bg-green-600 text-white text-base font-medium rounded-md px-[18px] py-2.5 inline-flex items-center self-center" onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, getJobBudgetDetail: true }))}>Submit</button>
                                        : null}
                                        <button type="button" className="bg-ferrariRed-600 text-white text-base font-medium rounded-md px-[18px] py-2.5 inline-flex items-center self-center ml-8" onClick={ () => closeSidePanel()}>Cancel</button>
                                    </div>
                                } 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateJobPanel;