import { LogLevel } from "@azure/msal-browser";
// import { apiConfigScopes } from './apiConfig'
// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID || "",
        authority: process.env.REACT_APP_AUTHORITY || "",
        redirectUri: "/authorize",
        postLogoutRedirectUri: "/",
        validateAuthority: true,
        navigateToLoginRequestUrl: true,
        // clientCapability: ['CP1']
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    },
    system: {
        tokenRenewalOffsetSeconds: 300, // Renew tokens 5 minutes before expiration
        // allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        // console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        // console.debug(message);
                        return;
                    case LogLevel.Warning:
                        // console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"],
};

export const tokenRequest = {
    // scopes: [...apiConfigScopes.b2cScopes],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
    scopes: ["User.Read"],
    forceRefresh: true // Set this to "true" to skip a cached token and go to the server to get a new token
};