import React from 'react';

const Checkbox = ({ label, name, selectedValue, callback, className, size, labelClass, disabled }) => {
  // const [state, setState] = useState(false)

  return (
    <div className={`flex items-center ${className}`}>
      <input
        type="checkbox"
        name={name}
        className={`form-radio bg-green-600 ${size ? size : ' h-4 w-4'}`}
        checked={selectedValue}
        onChange={() => callback()} // Only call callback without changing state.
        disabled={disabled ? disabled : false} 
      />
      <label htmlFor={label} className={`${labelClass ? labelClass : 'text-NeutralGray-600'} text-base pl-2 self-end`}>{label}</label>
    </div>
  );
};

export default Checkbox;
