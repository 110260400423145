import React, { useState } from 'react';

const DateTimeInput = ({ label, callback, className }) => {
    const [dateTime, setDateTime] = useState('');
    const [error, setError] = useState('');

    // Regex pattern for validating date and time (YYYY-MM-DD HH:mm)
    const dateTimePattern = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]) ([01]\d|2[0-3]):([0-5]\d)$/;

    // Handle change for the combined date and time input
    const handleDateTimeChange = (e) => {
        const input = e.target.value;
        setDateTime(input);
        callback(input)
        // Validate the input using the regex pattern
        if (!dateTimePattern.test(input)) {
            setError('Invalid format. Use YYYY-MM-DD HH:mm');
        } else {
            setError('');

        }
    };

    return (
        <div className={`w-full items-center ${className}`}>
            <p className="text-NeutralGray-800 text-base flex w-full">{label}</p>
            <input
                type="text"
                id="dateTimeInput"
                value={dateTime}
                onChange={handleDateTimeChange}
                maxLength={16}
                className="placeholder:italic placeholder:text-NeutralGray-600 text-base block border border-NeutralGray-400 rounded-md py-2.5 pl-2 shadow-sm w-full" 
                placeholder="YYYY-MM-DD HH:mm"
                required
            />
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
    );
};

export default DateTimeInput;
