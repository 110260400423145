import React, { useState, useEffect, useRef } from 'react';
import { capitalizeAllLetter, capitalizeFirstLetter } from '../../utils/utility';
import {Icon} from "@iconify/react";
import Search from './SearchWithHiddenInput';

const MultiSelectSectionNew = ({ label, fields, options, selectedValues, callbackMultiSelect, className, singleSelection, makeLabelUpperCase, dropdownWidth, widthClass, addAllField, selectedLabel=false }) => {
    const dropdownRef = useRef()

    const [state, setState] = useState({})

    useEffect(() => {
        setState(prevState => ({ ...prevState, options, filteredOptions: options, selectedValues }))
    }, [options, selectedValues])

    useEffect(() => {
        // if(addAllField) {
        //     let filteredOptions = options
        //     // if(fields && fields.length) {
        //     //     if(!filteredOptions.filter(e => e[fields[0]] === 'All').length) {
        //     //         let dataRow = {}
        //     //         dataRow[fields[0]] = 'All'
        //     //         dataRow[fields[1]] = 'All'
        //     //         filteredOptions.unshift(dataRow)
        //     //     }
        //     // } else {
        //     //     if(!filteredOptions.filter(e => e === 'All').length) {
        //     //         filteredOptions.unshift('All')
        //     //     }
        //     // }
        //     setState(prevState => ({ ...prevState, filteredOptions, addAllField: false }))
        // }
    }, [addAllField, options, fields, selectedValues])

    const toggleDropdown = () => {
        setState(prevState => ({ ...prevState, dropdownOpen: !state.dropdownOpen }))
    };

    const handleSelectAll = (e) => {
        let opt = []
        options.forEach(row => {
            opt.push(fields && fields.length ? row[fields[0]] : row)
        })
        let selectedValues = []
        if (e.target.checked) {
            selectedValues = opt
        }
        setState(prevState => ({ ...prevState, selectedValues: opt }));

        callbackMultiSelect(selectedValues)
    };

    const handleOptionChange = (option) => {

        // event.stopPropagation();
        if(singleSelection) {
            selectedValues = option
            setState(prevState => ({ ...prevState, selectedValues, dropdownOpen: false }))
            callbackMultiSelect(option)
        } else {
            if (selectedValues && selectedValues.includes(option)) {
                selectedValues = selectedValues.filter((item) => item !== option);
            } else {
                selectedValues.push(option);
            }

            setState(prevState => ({ ...prevState, selectedValues })); 
            // if(addAllField) {
            //     callbackMultiSelect(selectedValues.filter(e => e !== 'All'))
            // } else {
            //     callbackMultiSelect(selectedValues)
            // }

            // callbackMultiSelect(selectedValues)
        }

        // let selectedValues = state.selectedValues
        // if(!singleSelection) {
        //     if (selectedValues && selectedValues.includes(option)) {
        //         selectedValues = selectedValues.filter((item) => item !== option);
        //     } else {
        //         selectedValues.push(option);
        //     }
        // } else {
        //     selectedValues = option
        // }
        // setState(prevState => ({ ...prevState, selectedValues, dropdownOpen: singleSelection ? false : true }))

        // if(Array.isArray(option)) {
        //     setState(prevState => ({ ...prevState, dropdownOpen: !state.dropdownOpen }))
        // }

        callbackMultiSelect(selectedValues)
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setState(prevState => ({ ...prevState, dropdownOpen: false }))
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return() => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`relative inline-block h-fit self-end flex flex-col cursor-pointer ${dropdownWidth ? dropdownWidth : 'min-w-28'}`} ref={dropdownRef}>
            <label className='text-white text-base font-medium pb-1'>{label}</label>
            <button className="w-full bg-white rounded-lg shadow-sm text-left cursor-default focus:outline-none sm:text-sm"
                onClick={toggleDropdown}
            >
                <p className={`flex justify-between border border-NeutralGray-400 py-2.5 pl-3 pr-4 rounded-lg`}>
                    <span className={`truncate text-base ${((Array.isArray(state.selectedValues) && state.selectedValues.length) || (singleSelection && state.selectedValues)) ? "text-textGray-600" : "text-textGray-600"}`}>
                        {selectedValues && Array.isArray(selectedValues) ?
                            selectedValues.length > 0 ? selectedValues.length + ' Selected' : 'Select'
                        : selectedValues ?
                            label === 'Provider' ?
                                state.selectedValues ? state.selectedValues.toUpperCase() : ''
                            : options && fields && fields.length && fields[0] !== fields[1] ?
                                options.filter(e => e[fields[0]] === state.selectedValues).length ? 
                                    options.filter(e => e[fields[0]] === state.selectedValues)[0][fields[1]] 
                                : 'Select'
                            :
                                state.selectedValues
                        : 'Select'}
                    </span>
                    <Icon icon="icon-park-outline:down" className={`${((Array.isArray(state.selectedValues) && state.selectedValues.length) || (singleSelection && state.selectedValues)) ? "text-textGray-600" : "text-textGray-600"} ml-1 self-center`} width="18" height="18" />                        
                </p>
            </button>
            {state.dropdownOpen ?
                <div className={`relative`}>                    
                    <div className={`absolute mt-1 w-full bg-white border border-byzantineBlue rounded-md shadow-[0_4px_6px_rgba(33,37,41,0.2),_0_0_1px_rgba(33,37,41,0.32)] z-10 min-w-[220px] ${className ? className : ''}`}>
                        <div className="max-h-80 overflow-auto">
                            {options && options.length > 5 ?
                                <div className="p-4 w-full">
                                    <Search
                                        data={state.options ? state.options : []}
                                        topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                                        className={'w-full rounded-full'}
                                        filteredData={(filteredArray, searchText) => {
                                            setState(prevState => ({ ...prevState, filteredOptions: searchText !== "" ? filteredArray : state.options }))
                                        }}
                                    />
                                </div>
                            : null}
                            <ul className="">
                                {state.filteredOptions && state.filteredOptions.length ?
                                    state.filteredOptions.map((row, index) => {
                                        return(
                                            <div className={`text-base hover:bg-water-600 ${!index ? 'pt-2' : ''}`} key={'drop_'+index}>
                                                {!singleSelection ?
                                                   <li key={index} className={`px-4 py-2 text-black ${index+1 === state.filteredOptions.length ? 'mb-1' : ''} ${state.selectedValues && state.selectedValues.includes(fields && fields.length ? row[fields[0]] : row) ? 'bg-water-600' : ''}`} onClick={() => handleOptionChange(fields && fields.length ? row[fields[0]] : row)}> 
                                                        <label className="inline-flex items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={state.selectedValues && state.selectedValues.includes(fields && fields.length ? row[fields[0]] : row) ? true : false}
                                                                // onChange={() => handleOptionChange(fields && fields.length ? row[fields[0]] : row)}
                                                                // onClick={() => handleOptionChange(fields && fields.length ? row[fields[0]] : row)}
                                                                className="form-checkbox cursor-pointer h-4 w-4 text-blue-600 option-checkbox"
                                                            />
                                                            <span className="ml-2" onClick={() => handleOptionChange(fields && fields.length ? row[fields[0]] : row)}>
                                                                {makeLabelUpperCase === 'capitalizeFirstLetter'
                                                                    ? capitalizeFirstLetter(fields && fields.length ? row[fields[1]] : row)
                                                                    : makeLabelUpperCase === 'capitalizeAllLetter'
                                                                    ? capitalizeAllLetter(fields && fields.length ? row[fields[1]] : row)
                                                                    : fields && fields.length ? row[fields[1]] : row}
                                                            </span>
                                                        </label>
                                                    </li>
                                                :   
                                                    <li key={index} className={`px-4 py-2 text-black ${index+1 === state.filteredOptions.length ? 'mb-1' : ''} ${state.selectedValues === (fields && fields.length ? row[fields[0]] : row) ? 'bg-water-600' : ''}`} onClick={(event) => handleOptionChange(fields && fields.length ? row[fields[0]] : row)}>
                                                        <label className="inline-flex items-center">
                                                            <span className="ml-2">
                                                                {makeLabelUpperCase && makeLabelUpperCase === 'capitalizeFirstLetter' ?
                                                                    capitalizeFirstLetter(fields && fields.length ? row[fields[1]] : row)
                                                                : makeLabelUpperCase && makeLabelUpperCase === 'capitalizeAllLetter' ?
                                                                    capitalizeAllLetter(fields && fields.length ? row[fields[1]] : row)
                                                                :  
                                                                    fields && fields.length ? row[fields[1]] : row
                                                                }
                                                            </span>
                                                        </label>
                                                    </li>
                                                }
                                            </div>
                                        )
                                    })
                                : 
                                    <p className="ml-2 text-gray-700 py-4">No Options</p>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            : null}
        </div>
    )
}

export default MultiSelectSectionNew