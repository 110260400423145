import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
 import { Store as CommonNotification } from 'react-notifications-component';
// import { Spinner } from 'reactstrap';
import { capitalizeFirstLetter, getDayFromSelectedDuration, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, momentDateGivenFormat, subDays, subHours, removeUnderScore, isValidDateTime, convertToLowerCase, getCommonInformationMessage, getPaginationShowingDetails } from '../../../utils/utility';
import { getWorkStationMetrics, listStackEvents, applyWorkstationActions } from '../../../actions/CatalogAction';
import MetricsTab from '../../common/Mertics/MetricsTab';
import Lifecycle from './Lifecycle'
import Search from '../../common/SearchWithHiddenInput'
import PeakDipMetrics from './PeakDipMetrics'
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import { LoadingCircle } from '../../common/LoadingCiricle';
import SelectOption from '../../common/Form/SelectOption';
import Button from '../../common/Form/Button';

let monitoringDurationOptions = [
	{value: "2h", label: "Last 2 Hrs"},
	{value: "6h", label: "Last 6 Hrs"},
	{value: "12h", label: "Last 12 Hrs"},
	{value: "24h", label: "Last 24 Hrs"},
	{value: "7d", label: "Last 7 Days"},
	{value: "14d", label: "Last 14 Days"},
	{value: "30d", label: "Last Month"},
	// {value: "60d", label: "Last 2 Months"},
	// {value: "90d", label: "Last 3 Months"}
]


const WorkstationSidePanel = ({ selectedDetails, closeSidePanel, selectedAction, detailsFormat, showOnlyDeatils }) => {
    const dispatch = useDispatch(); // Create a dispatch function
    const clickOut = useRef();
    
    const [state, setState] = useState({
        showLoading: false,
        selectedTab: "Details",
        logsList: [],
        filteredLogsList: [],

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        selectedGraphDuration: "2h"
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown])

    useEffect(() => {
        setState(prevState => ({ ...prevState, instance_type : selectedDetails.instance_type }))
        if(selectedDetails && selectedDetails.volume_details && Array.isArray(selectedDetails.volume_details)) {
            selectedDetails.volume_details.forEach(row => {
                setState(prevState => ({ ...prevState, ["volume_size_"+row.volume_id] : row.volume_size }))
            })
        }
    }, [selectedDetails])

    useEffect(() => {
        if (state.selectedTab === "Monitoring") {
            let duration = state.selectedGraphDuration

            let period = duration.substring(duration.length, duration.length - 1);
            let durationBtw = getDayFromSelectedDuration(duration);
            let startTime = '';
            if (period === 'd') {
                startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            } else {
                startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            }
            let endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');

            let params = {}
            // params.start_time = asset_id && asset_id.start_time
            // params.end_time = asset_id && asset_id.end_time
            params.duration = duration
            params.asset_id = selectedDetails && selectedDetails.asset_id

            // Fetch job metrics data here using params and update the state
            if(params.asset_id) {
                dispatch(getWorkStationMetrics(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({
                                ...prevState,
                                metricDetails: response,
                                showMetricLoading: false,
                                startTime,
                                endTime
                            }));
                        }
                    })
            } else {
                setState(prevState => ({
                    ...prevState,
                    metricDetails: [],
                    showMetricLoading: false,
                    startTime,
                    endTime
                }))
            }
        } else if (state.selectedTab === "Events") {
            let params = {}
            // params.start_time = asset_id && asset_id.start_time
            // params.end_time = asset_id && asset_id.end_time
            // params.duration = "2h"
            params.workstation_id = selectedDetails && selectedDetails.workstation_id
            // Fetch job metrics data here using params and update the state
            if(params.workstation_id) {
                dispatch(listStackEvents(params))
                    .then((response) => {
                        if(response) {
                            let results = response.results ? response.results : []
                            let totalPages = 1
                            if(results.length > state.perPage) {
                                totalPages = Math.ceil(results.length / state.perPage)
                            }
                            setState(prevState => ({
                                ...prevState,
                                listEvents: results,
                                filteredListEvents: results,
                                showEventsLoading: false,
                                totalPages
                            }));
                        }
                    })
            } else {
                setState(prevState => ({
                    ...prevState,
                    listEvents: [],
                    filteredListEvents: [],
                    showEventsLoading: false,
                }))
            }
        }
    }, [state.selectedTab, dispatch, selectedDetails, state.selectedGraphDuration, state.perPage])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    const actionFunction = (action) => {
        if(action === 'delete') {
            action = 'unsubscribe'
        }
        
        let params = {}
        params.workstation_id = selectedDetails.workstation_id
        // if(selectedAction === "stop & ")
        params.action = action
        if(selectedAction === "modify") {
            params.instance_type = state.instance_type ? state.instance_type : (selectedDetails && selectedDetails.instance_type)
            let volume_details = []
            if(selectedDetails && selectedDetails.volume_details && Array.isArray(selectedDetails.volume_details)) {
                selectedDetails.volume_details.forEach((row, vIndex) => {
                    let dataRow = {}
                    dataRow.volume_id = row.volume_id
                    dataRow.volume_size = state["volume_size_"+vIndex] ? parseInt(state["volume_size_"+vIndex]) : (row.volume_size ? parseInt(row.volume_size) : 0 )
                    volume_details.push(dataRow)
                })
            }
            params.volume_details = volume_details
        }
        
        if(params.workstation_id) {
            dispatch(applyWorkstationActions(params)).then((response) => {
                setState(prevState => ({ ...prevState, showActionLoading: false, showUpdateActionLoading: false }))
                let messageType = 'success'
                let message = response.message
                if(!response.status) {   
                    messageType = 'danger'  
                } else {
                    setState(prevState => ({ ...prevState, hideActionBtn: true }))
                    setTimeout(() => closeSidePanel('refresh'), 5000);
                }
                // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })
        }
    }

    const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value }))        
	}

    const handleChildClick = (event, type, dropdownType, section) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
        event.stopPropagation();
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    }

    const getStatusColor = (status) => {        
        let color = 'border border-darkPurple-600 text-darkPurple-600'
        if(status.toLowerCase() === 'cancelled') { color = 'border border-independenceGray-600 text-independenceGray-600' } else 
        if(status.toLowerCase() === 'completed') { color = 'border border-slateBlue-600 text-slateBlue-600' } else 
        if(status.toLowerCase() === 'failed') { color = 'border border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'running') { color = 'border border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'border border-blue-600 text-blue-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'border border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'timeout') { color = 'border border-yellowOrange-600 text-yellowOrange-600' }

        return color;
    }
    
    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-4/6 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-white'>Workstation Details</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-white cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                        <div className='w-full text-sm pt-2'>
                            <div className='flex flex-wrap'>
                                <div className="w-1/2">
                                    {selectedDetails && selectedDetails.image_name ?
                                        <p className='mb-1 text-NeutralGray-400 pr-4'>Name: <span className='text-white font-bold'>{selectedDetails.image_name}</span></p>
                                    : null}
                                </div>
                                {selectedDetails && selectedDetails.workstation_id ?
                                    <div className="w-1/2 pl-4">
                                        <p className='mb-1 text-NeutralGray-400 pr-4'>Id: <span className='text-white font-bold'>{selectedDetails.workstation_id}</span></p>
                                    </div>
                                : null}
                            </div>
                            <div className="flex flex-wrap">
                                <div className="w-1/2">
                                    <p className="mb-1 text-NeutralGray-400 pr-4">Resources:&nbsp;
                                        <span className='text-white font-bold'>
                                        {selectedDetails && selectedDetails.provider ? selectedDetails.provider.toUpperCase() : ""}
                                        {selectedDetails && selectedDetails.account_id ? (" : ")+selectedDetails.account_id : ""}
                                        {selectedDetails && selectedDetails.region ? (" : ")+selectedDetails.region : ""}
                                        </span>
                                    </p>
                                </div>
                                {selectedDetails && selectedDetails.asset_name ?
                                    <div className="w-1/2 pl-4">
                                        <p className='mb-1 text-NeutralGray-400 pr-4'>Asset : <span className='text-white font-bold'>{selectedDetails.asset_name}</span> </p>
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>

                    <div className={`mx-4 pt-4`}>
                        <ul className="flex flex-wrap text-sm text-center">
                            <li className="me-2">
                                <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Details' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                            </li>
                            {!showOnlyDeatils ?
                                <React.Fragment>
                                {selectedDetails && selectedDetails.asset_id ?
                                    <React.Fragment>
                                    <li className="me-2">
                                        <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Monitoring' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Monitoring', showMetricLoading: true  }))}>Monitoring</span>
                                    </li>
                                    {/* <li className="me-2">
                                        <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Utilization' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Utilization', showUtilizationLoading: true  }))}>Utilization</span>
                                    </li> */}
                                    <li className="me-2">
                                        <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Lifecycle' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Lifecycle', showLifecycleLoading: true }))}>Lifecycle</span>
                                    </li>
                                    </React.Fragment>
                                : null}
                                <li className="me-2">
                                    <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Events' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Events', showEventsLoading: true }))}>Events</span>
                                </li>
                                </React.Fragment>
                            : null}
                        </ul>
                        <div className="overflow-y-auto h-[calc(100vh-200px)] border border-NeutralGray-500 bg-PrimaryTintsPurple-600 rounded-r-2xl rounded-b-2xl">
                            {state.selectedTab === "Details" ?
                                <div className="p-6">
                                    {detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                                        return(
                                            <div key={'df_'+index} className={`border-b border-NeutralGray-600 pb-[40px] text-lg ${index ? 'pt-[40px]' : ''}`}>
                                                <p className={`text-primaryPurple-600 text-[22px] font-bold capitalize`}>{row.label}</p>
                                                <small className='text-NeutralGray-700'>{row.description}</small>
                                                {row.fields && row.fields.map((fld, fIndex) => {
                                                    return(
                                                        <React.Fragment key={'fld_'+fIndex}>
                                                        {fld === 'volume_details' ?
                                                            selectedDetails[fld] && Array.isArray(selectedDetails[fld]) && selectedDetails[fld].map((vol, vIndex) => {
                                                                return(
                                                                    <React.Fragment key={'key_'+vIndex}>
                                                                    {Object.entries(vol).length ?
                                                                        Object.entries(vol).map(([key, value], cIndex) => {
                                                                            return(
                                                                                <React.Fragment>
                                                                                { convertToLowerCase(key) === "tags" && !value.length ? 
                                                                                    null
                                                                                : (
                                                                                    <div key={'vol_'+cIndex} className="flex pt-8">
                                                                                        <span className="min-w-[222px] text-NeutralGray-600 self-center">{removeUnderScore(key)}:</span>
                                                                                        {key === "volume_size" && selectedAction === "modify" ?
                                                                                            <input 
                                                                                                className="border border-indepenence-600 rounded-md bg-transparent px-2 py-1 text-black" 
                                                                                                placeholder="Enter job name" 
                                                                                                value={state['volume_size_'+vIndex] || state['volume_size_'+vIndex] === "" ? state['volume_size_'+vIndex] : (value ? value : 0)}
                                                                                                onChange={e => handleInputChange('volume_size_'+vIndex, e.target.value)}
                                                                                                type="text" 
                                                                                                name="job_name"
                                                                                            />
                                                                                        : typeof value === 'boolean' ?
                                                                                            <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                                        : isValidDateTime(value) ?
                                                                                            <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                                        : convertToLowerCase(key) === "status" ?
                                                                                            <span className={`rounded-full px-2 mb-2 ${getStatusColor(value)} mr-2 py-1 break-all`}>{value}</span>
                                                                                        : convertToLowerCase(key) === "tags" ?
                                                                                            <div className="flex flex-wrap gap-2">
                                                                                                {value.map((item, vIndex) => {
                                                                                                    return(
                                                                                                        <span key={'bad_'+vIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item.key +' : '+item.value}</span>
                                                                                                    )
                                                                                                })}
                                                                                            </div>
                                                                                        : Array.isArray(value) ?
                                                                                            <div className="flex flex-wrap gap-2">
                                                                                                {value.map((item, aInex) => {
                                                                                                    return(
                                                                                                        <React.Fragment key={'kval_'+aInex}>
                                                                                                        {typeof item === 'string' ?
                                                                                                            <span className='border border-info bg-blue-600 px-2 rounded-full py-1 text-black mr-2 mb-1 text-sm'>{item}</span>
                                                                                                        : typeof item === 'object' ?
                                                                                                            Object.entries(item).map(([iKey, iValue], ikIndex) => {
                                                                                                                return(
                                                                                                                <span key={'kobj_'+ikIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>
                                                                                                                    {iKey+' '+iValue}
                                                                                                                </span>
                                                                                                                )
                                                                                                            })
                                                                                                        : null}
                                                                                                        </React.Fragment>
                                                                                                    )
                                                                                                })}
                                                                                            </div>
                                                                                        : (typeof value === 'string' || typeof value === 'number') ?
                                                                                            <span className="text-black break-all">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                                        : null}
                                                                                    </div>
                                                                                )}
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                    : null}
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        :
                                                            <div className="flex pt-8">
                                                                <span className="min-w-[222px] text-NeutralGray-600 self-center">{removeUnderScore(fld)}:</span>
                                                                {selectedDetails && selectedDetails[fld] ?
                                                                <React.Fragment>
                                                                {typeof selectedDetails[fld] === 'boolean' ?
                                                                    <span className={`text-black`}>{selectedDetails[fld] ? 'True' : 'False'}</span>
                                                                : isValidDateTime(selectedDetails[fld]) ?
                                                                    <span className="text-black">{momentConvertionUtcToLocalTime(selectedDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                : convertToLowerCase(fld) === "status" ?
                                                                    <span className={`rounded-full px-2 ${getStatusColor(selectedDetails[fld])} mr-2 py-1 break-all`}>{selectedDetails[fld]}</span>
                                                                : Array.isArray(selectedDetails[fld]) ?
                                                                    <div className="flex flex-wrap gap-2 self-center w-full lg:w-auto">
                                                                        {selectedDetails[fld].map((item, dIndex) => {
                                                                            return(
                                                                                <React.Fragment key={'kfld_'+dIndex}>
                                                                                {typeof item === "string" ?
                                                                                    <span className="border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{item}</span>
                                                                                : 
                                                                                    Object.entries(item).map(([key, value], keIndex) => {
                                                                                        return(
                                                                                            <span key={'kvFld_'+keIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{key +' : '+value}</span>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                </React.Fragment>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                :
                                                                    <React.Fragment>
                                                                    {fld === "instance_type" && selectedAction === "modify" ?
                                                                        <input
                                                                            type='text'
                                                                            placeholder='Enter instance type'
                                                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-1/5`}
                                                                            value={state.instance_type || state.instance_type === "" ? state.instance_type : (selectedDetails.instance_type ? selectedDetails.instance_type : '')}
                                                                            onChange={e => handleInputChange('instance_type', e.target.value)}
                                                                        />
                                                                    : 
                                                                        <span className="text-black break-all">{selectedDetails[fld]}</span>
                                                                    }
                                                                    </React.Fragment>
                                                                }
                                                                </React.Fragment>
                                                                : null}
                                                            </div>
                                                        }
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                    
                                    {selectedDetails.installed_softwares && selectedDetails.installed_softwares.length ?
                                        typeof selectedDetails.installed_softwares[0] === "string" ?
                                            <div className="flex py-8">
                                                <span className="text-SlateBlue min-w-[222px] self-center">Installed Softwares</span>
                                                {selectedDetails.installed_softwares.map((item, soIndex) => {
                                                    return(
                                                        <span key={'soft_'+soIndex} className='border border-SlateBlue bg-SlateBlue px-2 rounded-full py-1 text-black mr-2 mb-1 text-sm'>{item}</span>
                                                    )
                                                })}
                                            </div>
                                        :
                                            <React.Fragment>
                                            <p className={`mb-1 text-primaryPurple-600 text-[22px] font-bold capitalize py-8`}>Installed Softwares</p>
                                            <div className='relative overflow-x-auto rounded-t-lg shadow border border-lightGray-600 pb-100'>
                                                <table className="w-full text-left">
                                                    <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600 rounded-t-lg'>
                                                        <tr className="flex-1 w-full">
                                                            <th className='tracking-wider px-3 py-3 text-left'>Software Name</th>
                                                            <th className='tracking-wider px-3 py-3 text-left'>Version</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='bg-white text-black'>
                                                        {selectedDetails.installed_softwares && selectedDetails.installed_softwares.length ?
                                                            selectedDetails.installed_softwares.map((row, sftIndex)=> {
                                                                return(
                                                                    <tr key={"head1_"+sftIndex} className={`${sftIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
                                                                    {Object.entries(row).map(([key, value], sfinIndex) => {
                                                                        return(
                                                                            <td className="p-4 text-black text-left text-sm" key={'sftin_'+sfinIndex}>{value}</td>
                                                                        )
                                                                    })}
                                                                    </tr>
                                                                )
                                                            })
                                                        : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                            </React.Fragment>
                                    : null}
                                    {selectedAction ?
                                        <div className='flex justify-end mr-3 my-3'>
                                            {selectedAction === "modify" ?
                                                <React.Fragment>
                                                    {state.showUpdateActionLoading || state.showActionLoading ?
                                                        state.showUpdateActionLoading ?
                                                            <Button
                                                                classDetails={{bg: 'bg-PrimaryLightOrange-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                                                label={'Stop & Update'}
                                                                loading={true}
                                                            />
                                                        :
                                                            !state.hideAction?
                                                                <Button
                                                                    classDetails={{bg: 'bg-PrimaryLightOrange-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                                                    label={'Stop & Update'}
                                                                    callback={() => {
                                                                        setState(prevState => ({ ...prevState, showActionLoading: false, showUpdateActionLoading: true, hideActionBtn: false }))
                                                                        actionFunction("stop/update")
                                                                    }}
                                                                />
                                                            : null
                                                    : null}
                                                    {state.showUpdateActionLoading || state.showActionLoading ? 
                                                        state.showActionLoading ? 
                                                            <Button
                                                                classDetails={{bg: 'bg-PrimaryLightOrange-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                                                label={'Updating'}
                                                                loading={true}
                                                            />
                                                        :
                                                            !state.hideActionBtn ?
                                                                <Button
                                                                    classDetails={{bg: 'bg-PrimaryLightOrange-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                                                    label={'Update'}
                                                                    callback={() => {
                                                                        setState(prevState => ({ ...prevState, showActionLoading: true, showUpdateActionLoading: false, hideActionBtn: false }))
                                                                        actionFunction("update")
                                                                    }}
                                                                />
                                                            : null
                                                    : null}
                                                </React.Fragment>
                                            :
                                                state.showActionLoading ?
                                                    <Button
                                                        classDetails={{bg: 'bg-PrimaryLightOrange-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                                        label={selectedAction}
                                                        loading={true}
                                                    />
                                                : 
                                                    !state.hideActionBtn ?
                                                        <Button
                                                            classDetails={{bg: 'bg-PrimaryLightOrange-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                                            label={capitalizeFirstLetter(selectedAction)}
                                                            callback={() => {
                                                                setState(prevState => ({ ...prevState, showActionLoading: true, hideActionBtn: false }))
                                                                actionFunction(selectedAction)
                                                            }}
                                                        />
                                                    : null
                                            }
                                            <Button
                                                classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                                label={'Cancel'}
                                                callback={() => 
                                                    closeSidePanel()
                                                }
                                            />
                                        </div>
                                    : null}
                                </div>
                            : state.selectedTab === "Monitoring" ?
                                <div className="p-6">
                                    <div className='flex justify-between text-sm'>
                                        <p className='self-center text-sm'>Showing data for 
                                            <span className='font-semibold ml-1'>
                                                {state.endTime && state.startTime ?
                                                    momentDateGivenFormat(state.startTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.endTime, 'DD MMM YYYY HH:mm')
                                                : null}
                                            </span>
                                            <span className='mr-2'>(local time)</span>
                                        </p>
                                        <SelectOption
                                            label={""}
                                            fields={["value", "label"]}
                                            options={monitoringDurationOptions}
                                            selectedValues={state.selectedGraphDuration ? state.selectedGraphDuration : ''}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState, metricDetails: [], showMetricLoading: true, selectedGraphDuration: value }))
                                            }}
                                            singleSelection={true}
                                            parentClass={{marging:'mr-3'}}
                                            classDetails={{border: "border border-primaryPurple-600", padding: "py-2.5 pr-4 pl-3", bg: 'bg-lavender-200', rounded: 'rounded-full' }}

                                        />
                                        {/* <div className="flex">
                                            <label className='mb-0 mr-2 self-center text-black'></label>
                                            <div style={{ minWidth: "130px" }}>
                                                <Select
                                                    placeholder={'Select'}
                                                    isSearchable={false}
                                                    className='f13 p-0 colorBlack colorDark3'
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                    value={{
                                                        value: state.selectedGraphDuration ? state.selectedGraphDuration : 'Select',
                                                        label: state.selectedGraphDuration ? monitoringDurationOptions.filter(e => e.value === state.selectedGraphDuration)[0].label : <span className='placeholder'>Select</span>
                                                    }}
                                                    options={monitoringDurationOptions && monitoringDurationOptions.map(row => ({
                                                        value: row.value,
                                                        label: row.label,
                                                    }))}
                                                    onChange={event => {
                                                        
                                                    }}
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                    {state.showMetricLoading ?
                                        <div className="w-full flex justify-center">
                                            <LoadingCircle />
                                        </div>
                                    : state.metricDetails ?
                                        <MetricsTab
                                            metricDetails={state.metricDetails}
                                            selectedDetails={selectedDetails}
                                            height={175}
                                            showStatistics={["min", "avg", "max"]}
                                            axisLabelColor={'#495057'}
                                            gradient={true}
                                            gradientColor={['#E9E3F5', '#7052BC']}
                                            label={"No metrics information for the workstation."}
                                        />
                                    : null}
                                </div>
                            : state.selectedTab === "Events" ?
                                <div className='p-6'>
                                    {state.showEventsLoading ?
                                        <div className="w-full flex justify-center mt-4">
                                            <LoadingCircle />
                                        </div>
                                    : state.listEvents && state.listEvents.length ?
                                        <React.Fragment>
                                            <div className="flex mb-2 justify-between text-sm">
                                                <div className="flex w-2/3">
                                                <p className="self-center">
                                                    Showing <span className='mx-1'>{getPaginationShowingDetails(state.filteredListEvents && state.filteredListEvents.length, state.listEvents && state.listEvents.length, state.currentPage, state.perPage)}</span> out of total {state.totalRecords} queue(s)
                                                </p>
                                                </div>
                                                <div className="flex justify-end w-1/3">
                                                    {state.listEvents && state.listEvents.length ?
                                                        <Search
                                                            data={state.listEvents ? state.listEvents : []}
                                                            topClassName={'border border-NeutralGray-600 rounded-full bg-white'}
                                                            className={'rounded-full'}
                                                            filteredData={(filteredListEvents) => {
                                                                let totalPages = 1
                                                                if(filteredListEvents.length > state.perPage) {
                                                                    totalPages = Math.ceil(filteredListEvents.length / state.perPage)
                                                                }
                                                                setState(prevState => ({ ...prevState, filteredListEvents, totalPages }))
                                                            }}
                                                        />
                                                    : null}
                                                </div>
                                            </div>
                                            <div className="flex justify-end w-full pt-2">
                                                {state.filteredListEvents && state.filteredListEvents.length > state.perPage ?
                                                    <div className='flex justify-end text-black w-full'>
                                                        <span className='mx-3 self-center'>Page {state.currentPage} of {state.totalPages} </span>
                                                        <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentPage === 1 ?  'pointer-events-none text-NeutralGray-500' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigatePage('previous', state.currentPage)} /></span> 
                                                        <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-2 ${state.currentPage === state.totalPages ? 'pointer-events-none text-NeutralGray-500' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigatePage('next', state.currentPage)} /></span> 
                                                    </div>
                                                : null}
                                            </div>
                                            {state.filteredListEvents && state.filteredListEvents.length ?
                                                state.filteredListEvents.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                                    return(
                                                        <div key={"fil_"+i} className={`pt-8 ${!i ? "" : ""}`}> 
                                                            <div className="text-base flex cursor-pointer text-wrap">
                                                                <p className="mb-0">
                                                                    <span className="mb-0 mr-2 text-black text-sm font-bold">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ""} </span>
                                                                    {item.resource_type ?
                                                                        <span className={`mb-0 mr-2 text-sm ml-2`}>{item.resource_type}</span>
                                                                    : null}
                                                                    {item.resource_status ?
                                                                        <span className={`mb-0 mr-2 text-sm ml-2`}>{item.resource_status}</span>
                                                                    : null}
                                                                    {item.user_id ?
                                                                        <span className={`mb-0 mr-2 text-sm ml-2`}>{item.user_id}</span>
                                                                    : null}
                                                                    {item.resource_status_reason ?
                                                                        <span className="mb-0 ml-2 text-sm text-black font-bold">{item.resource_status_reason}</span>
                                                                    : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            :
                                                <div className='flex justify-center text-black text-sm'>
                                                    {getCommonInformationMessage('catalog')}.
                                                </div>
                                            }
                                        </React.Fragment>
                                    :
                                        <div className='flex justify-center text-black text-sm'>
                                            Therer are no events on this criteria.
                                        </div>
                                    }
                                </div>
                            : state.selectedTab === "Lifecycle" ?
                                <div className='p-6'>
                                    <Lifecycle 
                                        selectedDetails={selectedDetails}
                                    />
                                </div>
                            : state.selectedTab === "Utilization" ?
                                <div className='p-6'>
                                    <PeakDipMetrics 
                                        selectedDetails={selectedDetails}
                                    />
                                </div>
                            :null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkstationSidePanel;