import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import MultiSelectSection from '../../../common/MultiSelectSection';
import { onlyNumeric } from '../../../../utils/utility';
import {Icon} from "@iconify/react";
import SelectOption from '../../../common/Form/SelectOption';
import Textbox from '../../../common/Form/Textbox';
import Button from '../../../common/Form/Button';
import Checkbox from '../../../common/Form/Checkbox';
import Textarea from '../../../common/Form/Textarea';

const HeadNodeSection = (props) => {
	const [state, setState] = useState({});
	const createUpdateCluster = useSelector(
		(state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
	);
	const propsClusterFilters = useSelector(
		(state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
	);

	const dispatch = useDispatch(); // Create a dispatch function

	const callPageVist = useCallback(() => {
			let clusterConfiguration = createUpdateCluster && createUpdateCluster.cluster_configuration
					? createUpdateCluster.cluster_configuration
					: {};
	
			Object.entries(clusterConfiguration).forEach(([key, value]) => {
				if (key !== "s3_access") {
					if (key === "on_node_configured_script" && value) {
						setState((prevState) => ({ ...prevState, showNodeScript: true }));
					} else if (key === "on_node_updated_script" && value) {
						setState((prevState) => ({ ...prevState, showUpdatedScript: true }));
					}
					setState((prevState) => ({ ...prevState, [key]: value }));
				}
			});
		}, [createUpdateCluster]);

	useEffect(() => callPageVist(), [callPageVist]);

	const addPolicies = () => {
		let policies = state.policies ? state.policies : [];
		policies.push(state.policy);

		handleConfigurationInputChange("policies", policies);
		setState((prevState) => ({ ...prevState, policies, policy: "" }));
	};

	const removePolicies = (value) => {
		let policies = state.policies.filter((e) => e !== value);

		handleConfigurationInputChange("policies", policies);
		setState((prevState) => ({ ...prevState, policies }));
	};

	const removefromList = (value, listField) => {
		let filteredListItem = state[listField].filter((e) => e !== value);

		let array = [];
		filteredListItem.forEach((row) => {
			let rowValue = {
				value: row,
				label: row,
			};
			array.push(rowValue);
		});

		setState((prevState) => ({ ...prevState, [listField]: filteredListItem }));
	};

	const handleConfigurationInputChange = (label, value, valueField, type) => {
		let obj = createUpdateCluster ? createUpdateCluster : {};
		let clusterConfiguration = obj.cluster_configuration
			? obj.cluster_configuration
			: {};
	clusterConfiguration[label] =
	type === "integer" ? parseInt(value) : value;

		obj["cluster_configuration"] = clusterConfiguration;
		dispatch(setHpcPropsDetails("createUpdateCluster", obj));

		setState((prevState) => ({ ...prevState, [label]: value }));
	};

	const handleChildClick = (event, type, dropdownType, section) => {
		event.stopPropagation();

		let clickedChild = [];
		if (type === "child") {
			if (state.clickedChild && state.clickedChild.length) {
				state.clickedChild.forEach((row) => {
					setState((prevState) => ({ ...prevState, [row]: false }));
				});
			}
			clickedChild.push(section);
			setState((prevState) => ({
				...prevState,
				[section]:
					dropdownType === "singleDropDown" && state[section] ? false : true,
				clickedChild,
			}));
		} else if (type === "parent") {
			if (state.clickedChild && state.clickedChild.length) {
				state.clickedChild.forEach((row) => {
					setState((prevState) => ({ ...prevState, [row]: false }));
				});
			}

			setState((prevState) => ({ ...prevState, clickedChild }));
		}
	};

	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
			<div className='flex flex-wrap'>
				<div className='flex flex-col justify-start w-1/2 pr-4'>
					<SelectOption
						label={"Instance Type"}
						fields={["instance_type", "instance_type"]}
						options={propsClusterFilters && propsClusterFilters.instance_type ? propsClusterFilters.instance_type : []}
						selectedValues={state.instance_type ? state.instance_type : ''}
						callbackMultiSelect={(value) => {
							setState(prevState => ({ ...prevState,  instance_type: value }))
							handleConfigurationInputChange('instance_type', value)
						}}
						singleSelection={true}
						dropdownWidth={'w-full'}
						manditory={true}
						hasError={state.inputValidationError}
						disabled={props.pageMode === 'Edit'}
					/>
				</div>
				<div className='flex flex-col justify-start w-1/2 pl-4'>
					<SelectOption
						label={"Key pair"}
						fields={["value", "key"]}
						options={ propsClusterFilters && propsClusterFilters.key_pair ? propsClusterFilters.key_pair.map((d) => ({ key: d, value: d})) : []}
						selectedValues={state["key_name"] ? state["key_name"] : ""}
						callbackMultiSelect={(value) => {
							handleConfigurationInputChange("key_name", value);
							setState((prevState) => ({ ...prevState, "key_name": value}));
						}}
						singleSelection={true}
						dropdownWidth={'w-full'}
						manditory={true}
						hasError={state.inputValidationError}
						disabled={props.pageMode === 'Edit'}
					/>
					<p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.cluster_configuration?.key_name}</p>
				</div>
			</div>

			<div className='flex flex-wrap pt-8'>
				<div className='flex flex-col justify-start w-1/2 pr-4'>
					<SelectOption
						label={"Subnet IDs"}
						options={propsClusterFilters && propsClusterFilters.subnet_ids ? propsClusterFilters.subnet_ids : []}
						selectedValues={state.subnet_id ? state.subnet_id : ''}
						callbackMultiSelect={(value) => {
							setState(prevState => ({ ...prevState, subnet_id: value }))
							handleConfigurationInputChange('subnet_id', value)
						}}
						singleSelection={true}
						dropdownWidth={'w-full'}
						manditory={true}
						hasError={state.inputValidationError}
						disabled={props.pageMode === 'Edit'}
					/>
				</div>
				<div className='flex flex-col justify-start w-1/2 pl-4'>
					<SelectOption
						label={"Security Group IDs"}
						options={propsClusterFilters && propsClusterFilters.security_group_ids ? propsClusterFilters.security_group_ids : []}
						selectedValues={state.security_group_ids ? state.security_group_ids : []}
						callbackMultiSelect={(value) => {
							setState(prevState => ({ ...prevState, 'security_group_ids': value }))
							handleConfigurationInputChange('security_group_ids', value)
						}}
						manditory={true}
						hasError={state.inputValidationError}
						dropdownWidth={'w-full'}
					/>
					<div className='flex flex-wrap mt-2'>
						{state.security_group_ids && state.security_group_ids.length ? 
							state.security_group_ids.map(row => {
								return(
									<span key={row} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{row}
										<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(row, 'security_group_ids') }/>
									</span>
								)
							})
						: null}
					</div>
				</div>
			</div>

			<div className='flex flex-col mt-8'>
				<div className='flex flex-wrap justify-between'>
					<div className='lg:pr-4 w-4/5 pr-4'>
						<Textbox
							label={"IAM Policies"}
							type="text"
							selectedValue={state.policy ? state.policy : ''}
							callback={(value) => {
								setState(prevState => ({ ...prevState, policy: value}))
							}}
                            manditory={true}
                            hasError={state.inputValidationError}
							placeholder={'arn:aws:iam::<account>:policy/<policy-name-with-path>'}
						/>
					</div>
					<div className='flex justify-end pt-4 pl-4'>
						<Button
							classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center min-w-24'}}
							label={'+ Add'}
							callback={() => {
								addPolicies()
							}}
						/>
					</div>
				{/* <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 cursor-pointer' onClick={() => addDetails(index)} /> */}
				</div>
				{state.policies && Array.isArray(state.policies) && state.policies.length ? 
					state.policies.map(row => {
					return(
						<span className={'flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 my-2 text-black mr-2 py-1 break-all w-fit'}>{row}
							<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removePolicies(row) }/>
						</span>
					)
					})
				: null}
			</div>
			<div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
				<p className='font-bold text-NeutralGray-800 text-[22px] pb-4 uppercase'>Scripts</p>
				<div className='flex flex-wrap pt-6'>
					<Checkbox
						label="Run script on node configured"
						name="efa_enabled"
						selectedValue={state.showNodeScript ? true : false}
						callback={() => {
						setState(prevState => ({ ...prevState, showNodeScript: !state.showNodeScript}))
						}}
					/>
					{state.showNodeScript ? 
					<div className='w-full mt-4 lg:pt-0'>
						<Textarea
							label={'Enter Script'}
							placeholder={'Paste script here'}
							rows={2}
							wordLength={1000}
							className={ {borderClass: "border border-NeutralGray-400 rounded-lg"} }
							selectedValue={state.on_node_configured_script ? state.on_node_configured_script : ''}
							callback={(value) => {
								setState(prevState => ({ ...prevState, on_node_configured_script: value}))
								handleConfigurationInputChange('on_node_configured_script', value)
							}}
						/>
					</div>
					: null}
				</div>
				<div className='flex flex-wrap pt-8'>
					<Checkbox
						label="Run script on node updated"
						name="efa_enabled"
						selectedValue={state.showUpdatedScript ? true : false}
						callback={() => {
						setState(prevState => ({ ...prevState, showUpdatedScript: !state.showUpdatedScript}))
						}}
					/>
					{state.showUpdatedScript ? 
					<div className='w-full mt-4 lg:pt-0'>
						<Textarea
							label={'Enter Script'}
							placeholder={'Paste script here'}
							rows={2}
							wordLength={1000}
							className={ {borderClass: "border border-NeutralGray-400 rounded-lg"} }
							selectedValue={state.on_node_updated_script ? state.on_node_updated_script : ''}
							callback={(value) => {
								setState(prevState => ({ ...prevState, on_node_updated_script: value}))
								handleConfigurationInputChange('on_node_updated_script', value)
							}}
						/>
					</div>
					: null}
				</div>
			</div>
			<div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
				<p className='font-bold text-NeutralGray-800 text-[22px] pb-4 uppercase'>Local Storage</p>
				<div className='flex flex-wrap justify-between pt-4'>
					<div className='flex w-2/3'>
						<div className='flex flex-col justify-start w-1/2 pr-4'>
							<SelectOption
								label={"Volume Type"}
								// fields={["instance_type", "instance_type"]}
								options={propsClusterFilters && propsClusterFilters.volume_type ? propsClusterFilters.volume_type : []}
								selectedValues={state.volume_type ? state.volume_type : ''}
								callbackMultiSelect={(value) => {
									setState(prevState => ({ ...prevState,  volume_type: value }))
									handleConfigurationInputChange('volume_type', value)
								}}
								singleSelection={true}
								manditory={true}
								hasError={state.inputValidationError}
								dropdownWidth={'w-full'}
								disabled={props.pageMode === 'Edit'}
							/>
						</div>
						<div className='lg:pr-4 w-1/2 pr-4'>
							<Textbox
								label={"Root Volume Size (GB)"}
								type="number"
								selectedValue={state.volume_size ? state.volume_size : ''}
								callback={(value) => { handleConfigurationInputChange('volume_size', onlyNumeric(value), 'integer')}}
								placeholder={'Enter volume size'}
								disabled={props.pageMode === 'Edit'}
							/>
						</div>
					</div>
				</div>
			<div className='flex flex-wrap pt-8'>
			<p className="text-base font-medium text-black self-center w-full">Encrypt Root Volume</p>
				<div className='flex pt-3 w-1/2'>
					<Checkbox
						label="Encrypt root volume"
						name="efa_enabled"
						selectedValue={state.volume_encrypted ? true : false}
						callback={() => {
							handleConfigurationInputChange('volume_encrypted', !state.volume_encrypted)
						}}
						disabled={props.pageMode === 'Edit'}
					/>
				</div>
			</div>
			</div>
		</div>
	)
}


export default HeadNodeSection