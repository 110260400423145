/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file ManageUsers.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { deleteUser, listAllUserDetails } from '../../../actions/UserAction'
import { capitalizeAllLetter, capitalizeTheFirstLetterOfEachWord, currentLocaltime, getCommonInformationMessage, momentConvertionUtcToLocalTime, momentDateFormat, thousandSeparator } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'
import Button from '../../common/Form/Button';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { CSVLink } from 'react-csv';
import CreateUpdateUserPanel from './CreateUpdateUserPanel';
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import AlertConfirmation from '../../common/AlertConfirmation';

const ManageUsers = () => {	
	const[state, setState] = useState({
		showLoading: true,
		selectedUserDetails: {},
		actionsAllowed: []
	})

	const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const dispatch = useDispatch(); // Create a dispatch function\
    const location = useLocation();
	const currentPath = location.pathname;

	useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
					let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				} 
				// else {
				// 	if(row.action) {
				// 		actionsAllowed = row.action
				// 	}
				// }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

	useEffect(() => {
		setState(prevState => ({ ...prevState, callSearch: true }));
		setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 3000)
	}, [])

	useEffect(() => {
		if(state.callSearch) {
			setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {}
			dispatch(listAllUserDetails(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, listUsers: response, filterListUsers: response, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, listUsers: [], filterListUsers: [], showLoading: false }))
					}
				})
		}
	}, [state.callSearch, dispatch])

	useEffect(() => {
        let dataResult = state.filterListUsers && state.filterListUsers.length ? state.filterListUsers : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
    }, [state.filterListUsers])

    const deleteUserFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.deleteUserDetails 
        let params = {}
		params.delete_user_id = item.userId
		dispatch(deleteUser(params))
			.then((response) => {
				if(response) {
					let messageType = 'danger'
					let message = response && response.message ? response.message : "Error in deleting"

					if(response.status) {
						messageType = 'success'
						// toggle()
						setTimeout(() => { setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, callSearch: true, showDeleteLoading: false }))}, 1000)
					} else {
						setState(prevState => ({ ...prevState, showDeleteLoading: false }))
					}


					CommonNotification.addNotification({
						//title: 'Wonderful!',
						message: message,
						type: messageType,
						insert: 'top',
						container: 'top-center',
						// animationIn: ['animate__animated', 'animate__fadeIn'],
						// animationOut: ['animate__animated', 'animate__fadeOut'],
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					});
				}
			})
        // } else {
        //     if(!state.queue_name || state.queue_name === '') {
        //         setState(prevState => ({ ...prevState, deleteConfirmMessage: "Please enter queue name", showDeleteLoading: false }))
        //     } else if(state.queue_name !== item.queue_name) {
        //         setState(prevState => ({ ...prevState, deleteConfirmMessage: "Entered Queue name does not match selected queue name", showDeleteLoading: false }))
        //     }
        // }

    }

	/**
	 * Renders cost comparison Landing page
	*/
	return (
		<div className='px-6'>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap py-2 bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="w-full text-white text-[28px] text-semibold">Manage Users</p>
						<p className="text-white text-base">Manage list of users</p>
					</div>
					<div className="xl:col-span-6 col-span-12 self-center xl:mt-4">
						{state.allocationDetails && state.allocationDetails.length ?
							state.allocationDetails.map(row => {
								return(
									<div className='bg-GhostWhite border border-mediumDarkGray rounded-md px-5 py-3 mr-3' key={row.cloudProviderId}>
										<div className='flex justify-center'>
											<div className='w-fit-content'>
												<p className='text-white text-center mb-1 f14'>{capitalizeAllLetter(row.cloudProviderId)}&nbsp;Commitment<span className='ml-1 text-lightGray f14'>({momentDateFormat(currentLocaltime(), 'YYYY')})</span></p>
												<p className='text-center f18 mb-0 text-yellowOrange-600'>{thousandSeparator(row.allocation)} <span className='ml-1 text-lightGray f14'>GBP</span></p>
											</div>
										</div>
									</div>
								)
							})	
						: null}
					</div>
				</div>
			</div>
			{state.showCreateUserPanel ?
				<CreateUpdateUserPanel 
					closeSidePanel={() => setState(prevState => ({...prevState, showCreateUserPanel: false, selectedUserDetails: {} }))}
					pageMode={state.pageMode}
					refreshApi={() => setState(prevState => ({...prevState, callSearch: true  }))}
					selectedUserDetails={state.selectedUserDetails}
				/>
			: null}
			<div className='p-2'>
                <div className='flex justify-between pb-8'>
                    <p className='mb-0 text-sm self-center lg:w-1/2 w-full'>
                        <span>Showing {state.filterListUsers && state.filterListUsers.length} out of total {state.listUsers && state.listUsers.length} user(s)</span>
                    </p>

					<div className='flex justify-end'>                                
						{state.listUsers && state.listUsers.length ?
							<div className='self-center mr-2'>
								<Search
									data={state.listUsers ? state.listUsers : []}
									topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
									className={'rounded-full'}
									filteredData={(filterListUsers) => setState(prevState => ({ ...prevState, filterListUsers }))}
								/>
							</div>
						: null}
						{state.actionsAllowed && state.actionsAllowed.includes('create') ?
							<Button
                                classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-3', margin: 'mx-2', text: 'text-white text-base font-semibold', others: 'cursor-pointer text-center'}}
                                label={'Create User'}
                                callback={() => setState(prevState => ({ ...prevState, showCreateUserPanel: true, pageMode: 'Create' }))}
                            />
						: null}
						{state.filterListUsers && state.filterListUsers.length ?
							<div className='self-center' title="download">
								<CSVLink 
									data={state.filterListUsers ? state.filterListUsers : []} 
									headers={state.tableHeaders ? state.tableHeaders : []}
									filename={'users-list.csv'}
									className={'self-center'}
									target='_blank'
								>
									<Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center' />
								</CSVLink>
							</div>
						: null}
					</div>
				</div>
				{state.filterListUsers && state.filterListUsers.length ?
					<ResizeableDarkThemeTable
						columns={[
							{
								Header: 'Date',
								accessor: d => d.registeredDate,
								Cell: cellInfo => (
								<span>
									{cellInfo.row.original.registeredDate ? momentConvertionUtcToLocalTime(cellInfo.row.original.registeredDate, 'DD MMM YYYY HH:mm') : ''}
								</span>
								),
								width: 200
							},
							{
								Header: 'Organization',
								accessor: 'organization',
								width: 200,
							},
							{
								Header: 'Name',
								accessor: 'userName',
								Cell: cellInfo => (
									<div className="flex justify-between cursor-pointer self-center">
										<div className='text-info mr-2'
											onClick={() => setState(prevState => ({
												...prevState,
												pageMode: 'View', 
												showCreateUserPanel: true,
												selectedUserDetails: cellInfo.row.original
											}))}
										>{cellInfo.row.original.userName}</div>
									</div>
								),
								
								width: 150,
							},
							{
								Header: 'Short Name',
								accessor: 'shortName',
								Cell: cellInfo => (
									<div className="flex justify-between cursor-pointer self-center">
										<div className='text-info mr-2'
											onClick={() => setState(prevState => ({
												...prevState,
												pageMode: 'View', 
												showCreateUserPanel: true,
												selectedUserDetails: cellInfo.row.original
											}))}
										>{cellInfo.row.original.shortName}</div>
									</div>
								),
								
								width: 150,
							},
							{
								Header: 'Email',
								accessor: 'emailId',
								width: 300,
							},
							{
								Header: 'Actions',
								Cell: cellInfo => (
									<div className="flex justify-between cursor-pointer self-center">
										{/* <div className='text-info mr-2'
											onClick={() => setState(prevState => ({
												...prevState,
												pageMode: 'View', 
												selectedUserDetails: cellInfo.row.original,
												showCreateUserPanel: true
											}))}
										>View </div> */}
										{state.actionsAllowed && state.actionsAllowed.includes('update') ?
											<div className='text-primaryPurple-600 mr-2'
												onClick={() => setState(prevState => ({
													...prevState,
													pageMode: 'Edit', 
													selectedUserDetails: cellInfo.row.original,
													showCreateUserPanel: true
												}))}
											>Edit </div>
										: null}
										{state.actionsAllowed && state.actionsAllowed.includes('delete') ?
											<div className='text-ferrariRed-600 mr-2'
												onClick={() => {
                                                    let obj = {
                                                        message: 'Are you sure you want to delete the user' +(cellInfo.row.original.userName ? ' "'+cellInfo.row.original.userName+'"' : ''),
                                                        buttonLabel: "Yes, I'm sure",
                                                    }
                                                    setState(prevState => ({ ...prevState, confirmDetails: obj, deleteModal: true, deleteUserDetails: cellInfo.row.original }))
                                                }}>Remove</div>
										: null}
									</div>
								),
								width: 150
							},
							{
								Header: 'Role',
								accessor: 'roleName',
								width: 300,
							},
						]}
						data={state.filterListUsers}
						perPage={20}
                        selectedColor={'bg-transparent'}
                        dashboard={true}
						showPaginationTop={true}
						sortByColumn={''}
						onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedListUsers: tableRow }))}
						// tableSize={'table-sm'}
					/>
				: 
					<div className='flex justify-center m-4'>
						<p>{getCommonInformationMessage('user')}</p>
					</div>
				}
			</div>
			{state.confirmDetails && state.deleteModal ?
                <AlertConfirmation 
                    confirmDetails={state.confirmDetails}
                    confirmation={(deleteModal) => {
                        if(deleteModal) {
							deleteUserFunction()                        }
                        setState(prevState => ({ ...prevState, confirmDetails: false, deleteModal: false, showDeleteLoading: false, deleteUserDetails: {} }))
                    }}
                />
            : null}
		</div>
	)
}

export default ManageUsers