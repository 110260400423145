/*************************************************
 * Tvastar
 * @exports
 * @file FilterSidePanel.js
 * @author Prakash // on 09/12/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import _ from 'lodash'
import { Store as CommonNotification } from 'react-notifications-component';
import { editGovernancePolicy, setGovernancePropsDetails, setNewInitialRuleDetails } from '../../actions/governance/GovernanceAction'
import DynamicServices from './Section/DynamicServices';
import RdsServiceEnginesTemplate from './Section/RdsServiceEnginesTemplate';
import SnsServiceLogs from './Section/SnsServiceLogs';
import RdsServiceLogs from './Section/RdsServiceLogs';
import RdsServiceBlockStore from './Section/RdsServiceBlockStore';
import ElasticServiceBlockStore from './Section/ElasticServiceBlockStore';
import Ec2ServiceBlockStore from './Section/Ec2ServiceBlockStore';
import RdsServiceCapacity from './Section/RdsServiceCapacity';
import DedicatedMasterServiceCapacity from './Section/DedicatedMasterServiceCapacity';
import DataNodeServiceCapacity from './Section/DataNodeServiceCapacity';

import {Icon} from "@iconify/react";

const FilterSidePanel = (props) => {
	const clickOut = useRef();

	const [state, setState] = useState({
		governanceTypes: [
			{label: "Infrastructure Policy", value: "infra"},
			{label: "Access Managment Policy", value: "access"},
		],

		actionMethods: [
			{value: "immediately", label: "Immediately after the violation"},
			{value: "run_at", label: "At"},
			{value: "run_after", label: "After"},
		]
	})
	const dispatch = useDispatch(); // Create a dispatch function
	const editNewRuleDetails = useSelector(state => state?.governance?.editNewRuleDetails || false)

	const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
			dispatch(setGovernancePropsDetails('editNewRuleDetails', {}))
            props.closeSidePanel();
        }
    }, [dispatch, props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

	useEffect(() => {
		structureServices()
	}, )

	const structureServices = () => {
		let policy_metadata = props.masterData && props.masterData.policy_metadata ? props.masterData.policy_metadata : {}
		let policyData = props.masterData && props.masterData.policy ? props.masterData.policy : {}
		let policies = props.masterData && props.masterData.policy ? [props.masterData.policy] : []

		let masterRules = props.masterData && props.masterData.master_rule ? [props.masterData.master_rule] : []
		
		let data = []
		policies && policies.forEach(pol => {
			if(policies.filter(e => e.rule_id === pol.rule_id).length) {
				let dataRow = pol
				if(policies.filter(e => e.rule_id === pol.rule_id)[0].api_to_call) {
					dataRow.api_to_call = policies.filter(e => e.rule_id === pol.rule_id)[0].api_to_call
				}
				data.push(dataRow)
			}
		})

		let obj = policy_metadata
		obj.policies = data
		dispatch(setNewInitialRuleDetails(obj));

		let groupPolicies = data ? _.chain(data).groupBy("rule_category").map((value, key) => ({ label: key, ruleCategoryGroupPolicies: value, ruleIdGroup: _.chain(value).groupBy('rule_id').map((value, key) => ({ label: key, policies: value })).value() })).value() : []

		let appliesToArray = [0]
		if(policy_metadata && policy_metadata.applies_to && policy_metadata.applies_to.length) {
			appliesToArray = []
			policy_metadata.applies_to.forEach((row, index) => {
				appliesToArray.push(index)
				setState(prevState => ({ ...prevState, ["selected_account_"+index]: row.account_id,  ["selected_region_"+index]: (row.region ? row.region : []) }))

				if(policyData.governance_type && policyData.governance_type === "infra") {
					setState(prevState => ({ ...prevState, ["selected_tags_"+index]: (row.tags ? row.tags : [])  }))
				} else if(policyData.governance_type && policyData.governance_type === "infra") {
					setState(prevState => ({ ...prevState, ["selected_users_"+index]: (row.users ? row.users : []), ["selected_roles_"+index]: (row.roles ? row.roles : [])  }))
				}
			})
		}
		
		let notifications = policy_metadata && policy_metadata.notifications ? policy_metadata.notifications : []
		let notificationArray = [0]
		if(notifications.length) {
			notificationArray = []
			notifications.forEach((row, index) => {
				notificationArray.push(index)
				setState(prevState => ({ 
					...prevState,
					["selected_notfication_type_"+index]: row.notfication_type, 
					["shared_details_"+index]: (row.notification_id ? row.notification_id : [])
				}))
			})
		}

		setState(prevState => ({ ...prevState, policy_metadata, policyData: policies, appliesToArray, notificationArray, groupPolicies, newGroup: data, masterRules }))
	}

	const validatePolicyInput = (category, boolean, polIndex) => {
		setState(prevState => ({ ...prevState, [category]: boolean }))
	}

	const onFinish = () => {
		let hasError = false
		setState(prevState => ({ ...prevState, hasError, disableFinish: true, onClickFinish: true }))
		validateInput(hasError)	
	}

	const validateInput = (hasError) => {
		let hasPolicyError = false

		let policies = editNewRuleDetails && editNewRuleDetails.policies ? editNewRuleDetails.policies : []

		if(!hasPolicyError && !hasError) {
			policies.forEach(pol => {			
				if(pol.variables && pol.variables.length) {
					pol.variables.forEach(varb => {
						if(!hasPolicyError && (!varb.manditory || varb.manditory === "undefined")) {
							hasPolicyError = false
						} else if(!hasPolicyError) {
							if(varb.value && varb.value) {
								hasPolicyError = true
							} else if(varb.value_list && !varb.value_list.length) {
								hasPolicyError = true
							}
						}
					})
				}
			})
		
		}

		if(!hasPolicyError && !hasError) {
			setState(prevState => ({ ...prevState, queryLoading: true, callUpdatePolicy: true }))
		}
	}

	useEffect(() => {
		if(state.callUpdatePolicy) {
			setState(prevState => ({ ...prevState, callUpdatePolicy: false }))
			let params = editNewRuleDetails
			dispatch(editGovernancePolicy(params))
				.then((response) => {
					if(response) {
						let messageType = 'success'		
						let message = 'User governance policy updated successfully'
						CommonNotification.addNotification({
							//title: "Wonderful!",
							message: message,
							type: messageType,
							insert: "top",
							container: "top-center",
							// animationIn: ["animate__animated", "animate__fadeIn"],
							// animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,			  
							}
						});
						setState(prevState => ({ ...prevState, queryLoading: false, finishBtn: 'Saved' }))
						setTimeout(() => { 
							dispatch(setGovernancePropsDetails('editNewRuleDetails', {}))
							props.closeSidePanel() 
						},5000)
					} else {
						setState(prevState => ({ ...prevState, disableFinish : false, finishBtn: 'Save' }))
					}
				})
		}
	}, [dispatch, editNewRuleDetails, props, state.callUpdatePolicy])

	const handleChildClick = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
			dispatch(setGovernancePropsDetails('editNewRuleDetails', {}))
            props.closeSidePanel()
        }
    }
	
	return (
		<div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-3/5 bg-GhostWhite min-h-screen overflow-auto' ref={clickOut}>
                <div className='header-search bg-black flex justify-between p-2'>
                    <div className='text-black w-full self-center'>
                        <span className='font-bold text-xl text-black'>Details</span>
					</div>
                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black cursor-pointer' width="25" height="25" onClick={() => props.closeSidePanel()} />
                    </div>
                </div>

				<div className={`${!state.showLoading ? 'overflow-auto' : ''} m-2 overflow-auto`}>
					<div className="rounded-md bg-dark p-3" style={{minHeight: state.minMaxHeight, maxHeight: state.minMaxHeight}}>
						<div className={`rounded-md p-3 bg-dark3 border-0 mb-2`}>
							<div onClick={() => setState(prevState => ({ ...prevState, expandPolicyInformation: !state.expandPolicyInformation }))}>
								<div className="p-2 mb-0 flex self-center">
									{state.expandPolicyInformation ? 
										<Icon icon="zondicons:minus-outline" className={`text-black self-center cursor-pointer mr-2`} width="18" height="18" />
									: 
										<Icon icon="tabler:circle-plus" width="18" height="18"  className='text-black cursor-pointer mr-2 self-center' />
									}
									<p className='text-black mb-0 text-lg self-center'>Policy Information</p>
								</div>
							</div>
							{state.expandPolicyInformation ?
								<React.Fragment>
								<div className="flex mb-2">
									<div className="py-1 md:w-1/2 w-full">
										<p className="b-block mb-0">Name:</p>
										<p className="mb-0 text-black">{state.policy_metadata && state.policy_metadata.policy_name ? state.policy_metadata.policy_name : ''}</p>
									</div>
									<div className="py-1 md:w-1/2 w-full md:pl-3 pl-0">
										<p className="b-block mb-0">Type:</p>
										<p className="mb-0 text-black">{state.policy_metadata && state.policy_metadata.governance_type && state.governanceTypes.filter(e => e.value === state.policy_metadata.governance_type).length ? state.governanceTypes.filter(e => e.value === state.policy_metadata.governance_type)[0].label : ""}</p>
									</div>
								</div>

								<div className="flex mb-2 border-t border-mediumDarkGray">
									<div className="py-1 w-full">
										<p className="b-block mb-0">Description:</p>
										<p className="mb-0 text-black">{state.policy_metadata && state.policy_metadata.description ? state.policy_metadata.description : ''}</p>
									</div>
								</div>
								</React.Fragment>
							: null}
						</div>

						<div className={`rounded-md p-3 bg-dark3 border-0 mb-2`}>
							<div onClick={() => setState(prevState => ({ ...prevState, expandAppliesTo: !state.expandAppliesTo }))}>
								<div className="p-2 mb-0 flex self-center">
									{state.expandAppliesTo ? 
										<Icon icon="zondicons:minus-outline" className={`text-black self-center cursor-pointer mr-2`} width="18" height="18" />
									: 
										<Icon icon="tabler:circle-plus" width="18" height="18"  className='text-black cursor-pointer mr-2 self-center' />
									}
									<p className='text-black mb-0 text-lg self-center'>Apply Policies</p>
								</div>
							</div>
							{state.expandAppliesTo ?
								state.appliesToArray && state.appliesToArray.map((item, appIndex) => {
									return(
									<div key={'aaplies_'+appIndex} className="p-3 rounded-md bg-GhostWhite">
										<div className="flex flex-wrap mb-2">
											<div className="py-1 lg:w-1/5 md:w-1/3 w-full">
												<p className="b-block mb-0">Account:</p>
												<p className="mb-0 text-black">{state["selected_account_"+item] ? state["selected_account_"+item] : ''}</p>
											</div>
											<div className="py-1 lg:w-1/5 md:w-1/3 w-full md:pl-3 pl-0">
												<p className="b-block mb-0">Region:</p>
												<p className="mb-0 text-black">
													{state["selected_region_"+item] && Array.isArray(state["selected_region_"+item]) ?
														state["selected_region_"+item].length ? 
															(state["selected_region_"+item].includes("All")) || (state["selected_region_"+item].length === state.regions && state.regions.length) ?
																<span className="badge border-gray3 mr-2 mt-2 self-center f14 text-info px-2">All</span>
															:
																state["selected_region_"+item].map((row, riIndex) => {
																	return(
																		<span key={"reg_"+riIndex} className="badge border-gray3 mr-2 mt-2 self-center f14 text-info px-2">{state.regions && state.regions.filter(e => e.value === row).length ? state.regions.filter(e => e.value === row)[0].label : row}</span>
																	)
																})
														: null
													: state["selected_region_"+item] ?
														state["selected_region_"+item]
													: null}
												</p>
											</div>
											{state.policyData && state.policyData.governance_type && state.policyData.governance_type === "infra" ?
												<div className="py-1 w-full">
													<p className="b-block mb-0">Tags:</p>
													<p className="mb-0 text-black flex flex-wrap">
														{state["selected_tags_"+item] && state["selected_tags_"+item].length ? 
															state["selected_tags_"+item].map((tag, tIndex) => {
																return(
																	<span key={'ttag_'+tIndex} className="badge border-gray3 mr-2 mt-2 self-center f14 text-info px-2">{tag.key +" : "+tag.value}</span>
																)
															})
														:
															<span className="badge border-gray3 mr-2 mt-2 self-center f14 text-info px-2">All</span>
														}
													</p>
												</div>
											: state.policyData && state.policyData.governance_type && state.policyData.governance_type === "access" ?
												<React.Fragment>
												<div className="py-1 w-full pl-3 flex justify-between">
													<div className="">
														<p className="b-block mb-0">Users:</p>
														<p className="mb-0 text-black flex flex-wrap">
															{state["selected_users_"+item] && state["selected_users_"+item].length ? 
																state["selected_users_"+item].map((user, uIndex) => {
																	return(
																		<span key={'user_'+uIndex} className="badge border-gray3 mr-2 mt-2 self-center f14 text-info px-2">{user}</span>
																	)
																})
															:
																<span className="badge border-gray3 mr-2 mt-2 self-center f14 text-info px-2">All</span>
															}
														</p>
													</div>
												</div>
												<div className="py-1 w-full pl-3 flex justify-between">
													<div className="">
														<p className="b-block mb-0">Roles:</p>
														<p className="mb-0 text-black">
															{state["selected_roles_"+item] && state["selected_roles_"+item].length ? 
																state["selected_roles_"+item].map((role, rIndex) => {
																	return(
																		<span key={'role_'+rIndex} className="badge border-gray3 mr-2 mt-2 self-center f14 text-info px-2">{role}</span>
																	)
																})
															:
																<span className="badge border-gray3 mr-2 mt-2 self-center f14 text-info px-2">All</span>
															}	
														</p>
													</div>
												</div>
												</React.Fragment>
											: null}
										</div>
									</div>
									)
								})
							: null}
						</div>

						{state.masterRules && state.masterRules.length ?
							state.masterRules.map((pol, polIndex) => {
								return(
									<div key={'pol_'+polIndex} className={`rounded-md p-3 bg-dark border-0 mb-2 overflow-unset`}>
										<div className="flex justify-between text-black border-b border-mediumDarkGray">
											<div className="flex">
												<p className="mb-0 text-lg text-warning cursor-pointer">{pol.rule_category} policy rule</p>
											</div>
										</div>
										{!pol.template ?
											<DynamicServices
												masterData={pol}
												resourceType = {pol.resource_type}
												rule_category = {pol.rule_category}
												onClickFinish={state.onClickFinish}
												validateInput={(boo, noInputDetected) => {
													setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category + '_validation_' + polIndex]: noInputDetected ? (boo ? 'failed' : 'success') : 'failed' }))
													validatePolicyInput(pol.rule_category, boo, polIndex)
												}}
												actionMethods={state.actionMethods}
												selectedModeType={state.selectedModeType}
											/>
										: pol.rule_category === "Engine" ?
											<RdsServiceEnginesTemplate 
												masterData = {pol}
												onClickFinish={state.onClickFinish}
												validateInput={boo => {
													setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
													validatePolicyInput(pol.rule_category, boo, polIndex)
												}}
												selectedModeType={state.selectedModeType}
												actionMethods={state.actionMethods}
											/>
										: pol.rule_category === "Log" ?
											(pol.rule_id === "AWS_GOV_RDS_0002" || pol.rule_id === "AWS_GOV_AURORA_RDS_0002") ?
												<RdsServiceLogs
													masterData = {pol}
													onClickFinish={state.onClickFinish}
													validateInput={boo => {
														setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
														validatePolicyInput(pol.rule_category, boo, polIndex)
													}}
													selectedModeType={state.selectedModeType}
													actionMethods={state.actionMethods}
												/>
											: pol.rule_id === "AWS_GOV_SNS_0002" ?
												<SnsServiceLogs 
													masterData = {pol}
													onClickFinish={state.onClickFinish}
													validateInput={boo => {
														setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
														validatePolicyInput(pol.rule_category, boo, polIndex)
													}}
													selectedModeType={state.selectedModeType}
													actionMethods={state.actionMethods}
												/>
											: null
										: pol.rule_category === "BlockStore" ?
											pol.rule_id === "AWS_GOV_RDS_0007" ?
												<RdsServiceBlockStore
													masterData = {pol}
													onClickFinish={state.onClickFinish}
													validateInput={boo => {
														setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
														validatePolicyInput(pol.rule_category, boo, polIndex)
													}}
													selectedModeType={state.selectedModeType}
													actionMethods={state.actionMethods}
												/>
											: pol.rule_id === "AWS_GOV_ES_0006" ?
												<ElasticServiceBlockStore
													masterData = {pol}
													onClickFinish={state.onClickFinish}
													validateInput={boo => {
														setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
														validatePolicyInput(pol.rule_category, boo, polIndex)
													}}
													selectedModeType={state.selectedModeType}
													actionMethods={state.actionMethods}
												/>
											: pol.rule_id === "AWS_GOV_EC2_0012" ?
												<Ec2ServiceBlockStore
													masterData = {pol}
													onClickFinish={state.onClickFinish}
													validateInput={boo => {
														setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
														validatePolicyInput(pol.rule_category, boo, polIndex)
													}}
													selectedModeType={state.selectedModeType}
													actionMethods={state.actionMethods}
												/>
											: null
										: pol.rule_category === "Capacity" ?
											(pol.rule_id === "AWS_GOV_RDS_0005" || pol.rule_id === "AWS_GOV_AURORA_RDS_0005" || pol.rule_id === "AWS_GOV_EC2_0008" || pol.rule_id === "AWS_GOV_EC_0004" || pol.rule_id === "HPC_GOV_0029"|| pol.rule_id === "HPC_GOV_0032"|| pol.rule_id === "HPC_GOV_0035") ?
												<RdsServiceCapacity
													masterData = {pol}
													onClickFinish={state.onClickFinish}
													validateInput={boo => {
														setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
														validatePolicyInput(pol.rule_category, boo, polIndex)
													}}
													selectedModeType={state.selectedModeType}
													actionMethods={state.actionMethods}
												/>
												: (pol.rule_id === "AWS_GOV_ES_0003" || pol.rule_id === "AWS_GOV_OS_0003") ?
												<DedicatedMasterServiceCapacity
													masterData = {pol}
													onClickFinish={state.onClickFinish}
													validateInput={boo => {
														setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
														validatePolicyInput(pol.rule_category, boo, polIndex)
													}}
													selectedModeType={state.selectedModeType}
													actionMethods={state.actionMethods}
												/>
											: (pol.rule_id === "AWS_GOV_ES_0004" || pol.rule_id === "AWS_GOV_OS_0004") ?
												<DataNodeServiceCapacity
													masterData = {pol}
													onClickFinish={state.onClickFinish}
													validateInput={boo => {
														setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
														validatePolicyInput(pol.rule_category, boo, polIndex)
													}}
													selectedModeType={state.selectedModeType}
													actionMethods={state.actionMethods}
												/>
											
											: null
										: null}
									</div>
								)
							})
						: null}

						<div className='mt-4 flex justify-end'>
							<button className="flex btn bg-info rounded-md px-2 py-1 text-black mr-2" onClick={() => {
								if(!state.disableFinish) {
									onFinish()
								}
							}}>
								{state.disableFinish ? 
									<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
										<circle cx="12" cy="12" r="8" stroke="currentColor" strokeWidth="2" fill="none" />
										<path d="M4 12a8 8 0 0112-6.9" />
									</svg>
								: null}
								{state.finishBtn ? state.finishBtn : 'Save'} 
							</button>
							<button className="btn bg-lightGray rounded-md px-2 py-1 text-black mr-2" onClick={() => props.closeSidePanel('refresh')}>Cancel</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default FilterSidePanel