import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { getUpdates } from '../../../actions/Collider/HpcAction'
import ClusterDetails from './ClusterDetails';
import QueueDetails from './QueueDetails';
import UserDetails from './UserDetails';
import { momentDateFormat, momentDateGivenFormat } from '../../../utils/utility';
import ProjectDetails from './ProjectDetails';
import FilterSection from '../../common/FiltersSection';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';

const LandingPage = () => {
    const clickOutside = useRef();

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,        
        showPageLoading: true,
        selectedDuration: 'currentMonth',        
        showUpdateSection: true,

        startUpcoming: 0, 
        currentUpcoming: 1,
        totalUpcoming: 1,
        perPage: 5,

        startLog: 0, 
        currentLogPage: 1,
        totalLogPages: 1,
    })
    
    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const location = useLocation();
	const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
            let type = 'cluster'
			let actionsAllowed = []
            let topSectionFilters = ['provider', 'account', 'region', 'cluster', 'type', 'duration']
            let isUserDashboard = false
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.filters).length) {
                        let filters = row.submenulist.filter(e => e.link === currentPath)[0].filters
                        type = filters.default ? filters.default : 'cluster'
                        isUserDashboard = true
                        if(filters.disable) {
                            topSectionFilters = topSectionFilters.filter(e => e !== filters.disable)
                        }
                    }
				} else if(row.link === currentPath && row.filters) {
                    type = row.filters.default ? row.filters.default : 'cluster'
                    isUserDashboard = true
                    if(row.filters.disable) {
                        topSectionFilters = topSectionFilters.filter(e => e !== row.filters.disable)
                    }
                }
			})
            let selectedType = type ? type.toLowerCase() : type
            setState(prevState => ({ ...prevState, actionsAllowed, isUserDashboard, selectedType, topSectionFilters }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
        dispatch(getUpdates({}))
            .then((response) => {
                if(response) {
                    let upcomingUpdates = response.upcoming_updates ? response.upcoming_updates : []
                    // let upcomingUpdates = state.response.upcoming_updates ? state.response.upcoming_updates : []
                    let totalUpcoming = Math.ceil(upcomingUpdates.length/state.perPage)

                    let updateLogs = response.update_logs ? response.update_logs : []
                    // let updateLogs = state.response.update_logs ? state.response.update_logs : []
                    let totalLogPages = Math.ceil(updateLogs.length/state.perPage)

                    setState(prevState => ({ ...prevState, updateResponse: response, upcomingUpdates, totalUpcoming, updateLogs, totalLogPages }))
                }
            })
    }, [dispatch, state.perPage, state.response])
    
    useEffect(() => {        
        if(state.showLoading) {
            setTimeout(() => {
                setState(prevState => ({ ...prevState, showLoading: false }))
            }, 5000);
        }
    }, [state.showLoading]); // Empty dependency array ensures this runs only once on mount   

    // useEffect(() => {
    //     if(providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : '') }));
    //     }
    // }, [providers])

    useEffect(() => {
        let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } 
        setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));

	}, [state.selectedDuration])

    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            selectedType: filter.selectedType ? 
                            filter.selectedType === 'cluster_name' ? 'cluster' :
                            filter.selectedType === 'queue_name' ? 'queue' : 
                            filter.selectedType : '',
            selectedProject: filter.selectedProject ? filter.selectedProject : [],
            selectedProjectTags: filter.selectedProjectTags ? filter.selectedProjectTags : [],
            selectedUser: filter.selectedUser ? filter.selectedUser : [],
            datePickerStartDate: filter.datePickerStartDate,
            datePickerEndDate: filter.datePickerEndDate,
            showPageLoading: false,
            callSearch: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedAccount: [], selectedRegion: [], selectedClusters: [], selectedUser: [], selectedProject: '', selectedProjectTags : [], selectedDuration: 'currentMonth', datePickerStartDate: momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00'), datePickerEndDate: momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59'), showPageLoading: false, callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, showPageLoading: true }))
        }

    }, [state.callSearch])

    const navigateUpcomingPage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startUpcoming
        if(action === 'next' && currentPage !== state.totalUpcoming) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentUpcoming: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentUpcoming: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentUpcoming: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentUpcoming: state.totalUpcoming }));
            startRecord = (state.totalUpcoming - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startUpcoming: startRecord }))
    }

    const navigateLogPage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startLog
        if(action === 'next' && currentPage !== state.totalLogPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentLogPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentLogPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentLogPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentLogPage: state.totalLogPages }));
            startRecord = (state.totalLogPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startLog: startRecord }))
    }

    const handleChildClick = (event, type, dropdownType, section) => {
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

        event.stopPropagation();
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    };

    return (
        <div  className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Dashboard</p>
                        <p className="text-white text-base">Consolidated view of HPC resource consumption</p>
                    </div>
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        {state.selectedType ?
                            <FilterSection
                                filters={state.topSectionFilters}
                                selectedType={state.selectedType}
                                selectedFilters={(filter) => handleSelectedFilters(filter)}
                                clickedonParent={state.clickedonParent}
                                onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                                onReset={onReset}
                                screen="job_status"
                            />
                        : null}
                    </div>
                </div>
            </div>
            <div className='grow overflow-auto'>
                <div className=''>
                    <div className='bg-paleLavender-600 border border-DeepPurpleTints-600 p-6 rounded-2xl mb-8'>
                        <div className={`flex justify-between`}>
                            <p className={`text-[22px] text-PrimaryTintsDeepPurpleTints-600 font-bold mb-0`}>Updates</p>
                            <Icon icon={`icon-park-outline:${state.showUpdateSection ? 'up' : 'down'}`} width={24} height={24} className='bg-DeepPurpleTints-600 h-6 w-6 text-white rounded-full cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, showUpdateSection: !state.showUpdateSection }))}/>
                        </div>
                        {state.showUpdateSection ?
                            <div className="flex justify-between mt-2">
                                {(state.updateLogs && state.updateLogs.length) || (state.updateLogs && state.updateLogs.length) ?
                                    <React.Fragment>
                                    <div className="lg:w-1/2 lg:pr-2 w-full">
                                        <div className="flex justify-between">
                                            <p className="text-PrimaryTintsDeepPurpleTints-600 text-base font-bold">Upcoming Updates</p>
                                            {state.upcomingUpdates && state.upcomingUpdates.length > state.perPage ?
                                                <div className='flex justify-center text-black w-full'>
                                                    <span className='mx-3 self-center'>Page {state.currentUpcoming} of {state.totalUpcoming} </span>
                                                    <div className="flex text-sm">
                                                        <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentUpcoming === 1 ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => navigateUpcomingPage('previous', state.currentUpcoming)}/></span> 
                                                        <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-2 border border-lightGray rounded-md ${state.currentUpcoming === state.totalUpcoming ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => navigateUpcomingPage('next', state.currentUpcoming)}/></span>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                        {state.upcomingUpdates && state.upcomingUpdates.length ?
                                            state.upcomingUpdates.slice(state.startUpcoming, state.startUpcoming + state.perPage).map((row, rIndex )=> {
                                                return(
                                                    <div key={'updates_'+rIndex} className={`flex w-full pt-2 ${rIndex ? 'border-t border-lightGray': ''}`}>
                                                        <div className="flex mr-2 px-2 mb-2 pb-1 w-full">
                                                            <ul className="mb-0 justify-end pl-2">
                                                                <li className="w-full mb-0 mr-2 mb-1 text-PrimaryTintsDeepPurpleTints-600 font-bold">
                                                                    {row.date ? 
                                                                        <span className='text-PrimaryTintsDeepPurpleTints-600 font-bold mr-2'>Date: {momentDateFormat(row.date, 'DD MMM YYYY HH:mm')} (utc time)</span>
                                                                    : null}
                                                                    {row.message}
                                                                </li>
                                                                <li className="inline-block w-full mb-0 mb-1 text-PrimaryTintsDeepPurpleTints-600">{row.description ? row.description : ''}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        : null}
                                    </div>
                                    <div className="lg:w-1/2 lg:mt-0 w-full mt-2 border-l border-DeepPurpleTints-600 pl-3">
                                        <div className="flex justify-between">
                                            <p className="text-PrimaryTintsDeepPurpleTints-600 text-base font-bold">Update Logs</p>
                                            {state.updateLogs && state.updateLogs.length > state.perPage ?
                                                <div className='flex justify-center text-black w-full'>
                                                    <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                                                    <div className="flex text-sm">
                                                        <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentLogPage === 1 ?'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => navigateLogPage('previous', state.currentLogPage)}/></span> 
                                                        <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-2 border border-lightGray rounded-md ${state.currentUpcoming === state.totalUpcoming ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => navigateLogPage('next', state.currentLogPage)}/></span>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                        {state.updateLogs && state.updateLogs.length ?
                                            state.updateLogs.slice(state.startLog, state.startLog + state.perPage).map((row, rIndex)=> {
                                                return(
                                                    <div key={'logs_'+rIndex} className={`flex w-full pt-2 ${rIndex ? 'border-t border-lightGray': ''}`}>
                                                        <div className="flex mr-2 px-2 mb-2 pb-1 w-full">
                                                            <ul className="mb-0 justify-end pl-2">
                                                                <li className="w-full mb-0 mr-2 mb-1 text-PrimaryTintsDeepPurpleTints-600 font-bold">
                                                                {row.date ? 
                                                                    <span className='text-PrimaryTintsDeepPurpleTints-600 mr-2'>Date: {momentDateFormat(row.date, 'DD MMM YYYY HH:mm')} (utc time)</span>
                                                                : null}
                                                                {row.message}</li>
                                                                {/* <li className={`displayInlieBlock w-100 mb-0 mr-2 mb-1 text-PrimaryTintsDeepPurpleTints-600`}>{row.description ? row.description : ''}</li> */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        : null}
                                    </div>
                                    </React.Fragment>
                                :
                                    <div className='flex justify-center text-black w-full text-lg'>No recent updates</div>
                                }
                            </div>
                        : null}
                    </div>
                    {state.showPageLoading && state.selectedProvider && state.datePickerStartDate ? 
                        state.selectedType === 'cluster' ?
                            <ClusterDetails 
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedClusters={state.selectedClusters}
                                selectedDuration={state.selectedDuration}
                                startDate={state.datePickerStartDate}
                                endDate={state.datePickerEndDate}
                                selectedType={state.selectedType}
                                callBack={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                            />
                        : state.selectedType === 'project' ?
                            <ProjectDetails 
                                selectedProvider={state.selectedProvider}
                                selectedProject={state.selectedProject}
                                selectedProjectTags={state.selectedProjectTags}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedClusters={state.selectedClusters}
                                selectedDuration={state.selectedDuration}
                                startDate={state.datePickerStartDate}
                                endDate={state.datePickerEndDate}
                                selectedType={state.selectedType}
                                callBack={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                            />
                        : state.selectedType === 'queue' ?
                            <QueueDetails 
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedClusters={state.selectedClusters}
                                selectedDuration={state.selectedDuration}
                                startDate={state.datePickerStartDate}
                                endDate={state.datePickerEndDate}
                                selectedType={state.selectedType}
                                callBack={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                            />
                        : state.selectedType === 'user' ?
                            <UserDetails 
                                isUserDashboard={state.isUserDashboard}
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedClusters={state.selectedClusters}
                                selectedUser={state.selectedUser}
                                selectedDuration={state.selectedDuration}
                                startDate={state.datePickerStartDate}
                                endDate={state.datePickerEndDate}
                                selectedType={state.selectedType}
                                callBack={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                            />
                        : null
                    : null}
                </div>
            </div>
        </div>
    );
};

export default LandingPage