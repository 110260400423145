import React, { useCallback, useEffect, useState } from 'react';
import FileUploadModal from './FileUploadModal';
import { useDispatch, useSelector } from 'react-redux';
import { Store as CommonNotification } from 'react-notifications-component';
import { createFolder, deleteFile, downloadFile, getFileContent, extractFile, renameFile } from '../../../../actions/files/FileAction';
import EditFile from './EditFile';
import { setCommonPropsDetails } from '../../../../actions/commonActionNew';
import { noSpace } from '../../../../utils/utility';
import {Icon} from "@iconify/react";
import { LoadingCircle } from '../../../common/LoadingCiricle';
import SelectOption from '../../../common/Form/SelectOption';
import AlertConfirmation from '../../../common/AlertConfirmation';

export default function FileActions({ handleFileBrowserRefresh, state, getLastObjectFromBreadcrumb, fromScreen, selectedFile }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newState, setNewState] = useState({
    showDeleteLoading: false,
    actions: ['Create Folder', 'Upload']
  })

  const dispatch = useDispatch()

  const currentLocation = useSelector(state => state?.filters?.commonPropsDetails?.currentLocation || false);
  const doubleClickOpenFile = useSelector(state => state?.filters?.commonPropsDetails?.doubleClickOpenFile || false);
  const doubleClickOpenFileOpen = useSelector(state => state?.filters?.commonPropsDetails?.doubleClickOpenFileOpen || false);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const createFolderFunction = () => {
    setNewState(prevNewState => ({ ...prevNewState, showCreateLoading: true, hasError: false }))
    let params = {}
    params.folder_name = newState.folder_name
    let currentPath = currentLocation.path ? currentLocation.path : ''
    params.path = currentPath+'/'+newState.folder_name
    params.provider = state.selectedProvider ? state.selectedProvider.toLowerCase() : ''
    params.cluster_name = state.selectedClusters
    if(currentLocation.owner) {
      params.owner = currentLocation.owner
    }
    if(newState.folder_name) {
      dispatch(createFolder(params))
        .then((response) => {
          if(response && !response.error) {
            setNewState(prevNewState => ({ ...prevNewState, folderResponse: response }))
            let messageType = 'danger'
            let message = response && response.message ? response.message : "Folder Creation Failed"

            if(response.status) {
              message = response && response.data && response.data.message ? response.data.message : "Folder Created Successfully"
                messageType = 'success'
                setNewState(prevNewState => ({ ...prevNewState, createModal: !newState.createModal, showCreateLoading: false, folder_name: "" }))
                // toggle()
                setTimeout(() => { setNewState(prevNewState => ({ ...prevNewState, showCreateLoading: false }))}, 1000)
            } else {
                setNewState(prevNewState => ({ ...prevNewState, showCreateLoading: false }))
            }


            CommonNotification.addNotification({
                //title: 'Wonderful!',
                message: message,
                type: messageType,
                insert: 'top',
                container: 'top-center',
                // animationIn: ['animate__animated', 'animate__fadeIn'],
                // animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                }
            });
          }
        })
    } else {
      setNewState(prevNewState => ({ ...prevNewState, hasError: true, showCreateLoading : false }))
    }
  }

  useEffect(() => {
    let actions = ['Create Folder', 'Upload']
    if(state.statsDetail && state.statsDetail.data &&state.statsDetail.data.type === 'file') {
      actions = ['Download', 'Extract', 'Open', 'Rename', 'Create Folder', 'Upload', 'Delete']
    }

    setNewState(prevState => ({ ...prevState, actions }))

  }, [state.statsDetail])

  const handleFileRename = () => {
    if(state.statsDetail && state.statsDetail.data && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.old_path = data.path

      let fileName = params.old_path;
      // Split the file name into parts based on the "/"
      // let fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
      // Remove the last string (file name)
      let newPath = fileName.substring(0, fileName.lastIndexOf("/"));
      // Add the new file name to the end

      let newFileName = newPath
      newFileName += '/'+newState.fileName
      // newFileName += '.'+fileType
      params.new_path = newFileName
      // setNewState(prevNewState => ({ ...prevNewState, showRenameBtnLoading: false }))
      dispatch(renameFile(params))
        .then((response) => {
          if(response && !response.error) {
            let messageType = 'danger'
            let message = response && response.message ? response.message : "File rename failed"

            if(response.status) {
              message = response && response && response.message ? response.message : "file renamed successfull"
                messageType = 'success'
                setTimeout(() => { setNewState(prevNewState => ({ ...prevNewState, showRenameBtnLoading: false, renameModal: false }))}, 1000)
                handleFileBrowserRefresh()
                // if(response.data && response.data.result) {
                //   let fileUrl = response.data.result
                //   window.open(fileUrl, '_blank')
                // }
            } else {
                setNewState(prevNewState => ({ ...prevNewState, showRenameBtnLoading: false }))
            }

            CommonNotification.addNotification({
                //title: 'Wonderful!',
                message: message,
                type: messageType,
                insert: 'top',
                container: 'top-center',
                // animationIn: ['animate__animated', 'animate__fadeIn'],
                // animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                }
            });
          }
        })
    }
  }

  const handleFileExtract = () => {
    if(state.statsDetail && state.statsDetail.data && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.path = data.path
      // if(data.owner) {
      //   params.owner = data.owner
      // }
      dispatch(extractFile(params))
        .then((response) => {
          if(response && !response.error) {
            let messageType = 'danger'
            let message = response && response.message ? response.message : "Extract failed"

            if(response.status) {
                setTimeout(() => { setNewState(prevNewState => ({ ...prevNewState, showExtactLoading: false }))}, 1000)
                message = response && response.message ? response.message : "Extracted"
                messageType = 'success'
                handleFileBrowserRefresh()
                // if(response.data && response.data.result) {
                //   let fileUrl = response.data.result
                //   window.open(fileUrl, '_blank')
                // }
            } else {
                setNewState(prevNewState => ({ ...prevNewState, showExtactLoading: false }))
            }

            CommonNotification.addNotification({
                //title: 'Wonderful!',
                message: message,
                type: messageType,
                insert: 'top',
                container: 'top-center',
                // animationIn: ['animate__animated', 'animate__fadeIn'],
                // animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                }
            });
          }
        })
    }
  }

  const handleFileDownload = () => {
    if(state.statsDetail && state.statsDetail.data &&state.statsDetail.data.type === 'file' && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.path = data.path
      if(data.owner) {
        params.owner = data.owner
      }
      dispatch(downloadFile(params))
        .then((response) => {
          if(response && !response.error) {
            let messageType = 'danger'
            let message = response && response.message ? response.message : "Download Failed"

            if(response.status) {
              message = response && response.data && response.data.message ? response.data.message : "Download initiated Successfully"
                messageType = 'success'
                setTimeout(() => { setNewState(prevNewState => ({ ...prevNewState, showDownloadLoading: false }))}, 1000)
                if(response.data && response.data.result) {
                  let fileUrl = response.data.result
                  window.open(fileUrl, '_blank')
                }
            } else {
                setNewState(prevNewState => ({ ...prevNewState, showDownloadLoading: false }))
            }

            CommonNotification.addNotification({
                //title: 'Wonderful!',
                message: message,
                type: messageType,
                insert: 'top',
                container: 'top-center',
                // animationIn: ['animate__animated', 'animate__fadeIn'],
                // animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                }
            });
          }
        })
    }
  }

  const handleFileEdit = useCallback(() => {
    if(state.statsDetail && state.statsDetail.data &&state.statsDetail.data.type === 'file' && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.path = data.path
      if(data.owner) {
        params.owner = data.owner
      }
      dispatch(getFileContent(params))
        .then((response) => {
          if(response && !response.error) {
            if(response.status) {
              if(!fromScreen) {
                setNewState(prevNewState => ({ ...prevNewState, fileContent: response, showEditLoading: false }))
              } else {
                selectedFile(response)
              }
            } else {
              let messageType = 'danger'
              let message = response && response.message ? response.message : "Download Failed"
                setNewState(prevNewState => ({ ...prevNewState, showEditLoading: false }))
                CommonNotification.addNotification({
                  //title: 'Wonderful!',
                  message: message,
                  type: messageType,
                  insert: 'top',
                  container: 'top-center',
                  // animationIn: ['animate__animated', 'animate__fadeIn'],
                  // animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                      duration: 5000,
                      onScreen: false,
                      pauseOnHover: true,
                      showIcon: true,
                  }
              });
            }            
          }
        })
    }
  }, [dispatch, state, fromScreen, selectedFile])

  const deleteFunction = () => {
    if(state.statsDetail && state.statsDetail.data &&state.statsDetail.data.type === 'file' && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.path = data.path
      if(data.owner) {
        params.owner = data.owner
      }
      if(params.path) {
          dispatch(deleteFile(params)).then((response) => {
              let messageType = 'success'
              let message = response.data && response.data.message ? response.data.message : 'Error in deleting in file'
              if(!response.status) {   
                  messageType = 'danger'
                  setNewState(prevState => ({ ...prevState, showDeleteLoading: false }))
              } else {
                  message = response.data && response.data.message ? response.data.message : 'File Deleted Successfully'
                  setNewState(prevState => ({ ...prevState, deleteModal: false, showDeleteLoading: false }))
                  handleFileBrowserRefresh()
              }

              CommonNotification.addNotification({
                  //title: 'Wonderful!',
                  message: message,
                  type: messageType,
                  insert: 'top',
                  container: 'top-center',
                  // animationIn: ['animate__animated', 'animate__fadeIn'],
                  // animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                      duration: 5000,
                      onScreen: false,
                      pauseOnHover: true,
                      showIcon: true,
                  }
              });
          })   
      } else {
        setNewState(prevState => ({ ...prevState, showDeleteLoading: false }))
      }
    }
  }

  useEffect(() => {
    if(doubleClickOpenFileOpen) {      
      setNewState(prevNewState => ({ ...prevNewState, showEditLoading: true }))
    }
  }, [dispatch, doubleClickOpenFileOpen])

  useEffect(() => {
    if(doubleClickOpenFile) {
      dispatch(setCommonPropsDetails('doubleClickOpenFile', false))
      dispatch(setCommonPropsDetails('doubleClickOpenFileOpen', false))
      if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file') {
        handleFileEdit()
      }
    }
  },[doubleClickOpenFile, state.statsDetail, handleFileEdit, dispatch])

  const handelActionChange = (option) => {
    if(option === 'Extract') {
      setNewState(prevNewState => ({ ...prevNewState, showExtactLoading : true}))
      alert(1)
      handleFileExtract()
    } else if(option === 'Rename') {
      setNewState(prevNewState => ({ ...prevNewState, fileName: state.statsDetail && state.statsDetail.data && state.statsDetail.data.name ? state.statsDetail.data.name : "", renameModal: true }))
    } else if(option === 'Download') {
      setNewState(prevNewState => ({ ...prevNewState, showDownloadLoading : true}))
      handleFileDownload()
    } else if(option === 'Open') {
      setNewState(prevNewState => ({ ...prevNewState, showEditLoading : true}))
      handleFileEdit()
    } else if(option === 'Delete') {
      let obj = {
        message: `Are you sure you want to delete file "${state.statsDetail && state.statsDetail.data && state.statsDetail.data.name ? state.statsDetail.data.name : ''}"`,
        buttonLabel: "Yes, I'm sure",
    }
    setNewState(prevNewState => ({ ...prevNewState, confirmDetails: obj, deleteModal: true}))
    } else if(option === 'Upload') {
      setModalIsOpen(!modalIsOpen);
    } else if(option === 'Create Folder') {
      setNewState(prevNewState => ({ ...prevNewState, createModal: true }))
    }
  }

  return (
    <div className="flex flex-wrap justify-between self-center">
      <div className="w-full flex justify-end">
        <div className='flex mb-1'>
          {state.selectedClusters ?
            <React.Fragment>
              {newState.showDownloadLoading ? 
                <p className='flex text-pumpkin-600 self-center text-base'> <LoadingCircle /> Downloading </p>
              : newState.showExtactLoading ? 
                <p className='flex text-pumpkin-600 self-center text-base'> <LoadingCircle /> Extracting </p>
              : newState.showDeleteLoading ? 
                <p className='flex text-pumpkin-600 self-center text-base'> <LoadingCircle /> Deleting </p>
              : newState.showEditLoading ? 
                <p className='flex text-pumpkin-600 self-center text-base'> <LoadingCircle /> Opening </p>
              : null}
              <div className='w-32 self-end mx-1'>
                <SelectOption
                    label={""}
                    placeholder={'Actions'}
                    options={newState.actions ? newState.actions : []}
                    selectedValues={state.selectedAction ? state.selectedAction : []}
                    callbackMultiSelect={(value) => handelActionChange(value)}
                    singleSelection={true}
                    dropdownWidth={'min-w-32'}
                    classDetails={{border: "border-b border-NeutralGray-400", rounded: " ", padding: "py-1 px-2"}}
                    hideSearch={true}
                />
            </div>
            <button type="button" className={`text-base rounded-md font-bold p-2.5 items-center mr-2 bg-pumpkin-600 text-white`} onClick={handleFileBrowserRefresh}>
              <Icon icon="fa6-solid:arrows-rotate" className={`text-white`} width={20} height={20} />
            </button>
            </React.Fragment>
          : null}
          
          <FileUploadModal
            isOpen={modalIsOpen}
            toggle={toggleModal}
            setModalIsOpen={setModalIsOpen}
            handleFileBrowserRefresh={handleFileBrowserRefresh}
            state={state}
            getLastObjectFromBreadcrumb={getLastObjectFromBreadcrumb}
          />

          <div id="rename-modal" tabIndex="-1" aria-hidden="true" className={`flex ${newState.renameModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
            <div className="relative p-4 w-full max-w-md max-h-full">
                {/* Modal content */}
                <div className="relative bg-white shadow-2xl border border-DeepPurpleTints-600 rounded-2xl">
                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b border-DeepPurpleTints-600 rounded-t dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Rename File
                        </h3>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal" onClick={() => setNewState(prevNewState => ({ ...prevNewState, renameFile: !newState.renameFile,showRenameBtnLoading: false, name: '', renameModal: false }))}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* Modal body */}
                    <form className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2 text-sm">
                              <label className="block mb-2 font-medium text-NeutralGray-600 dark:text-white">Name</label>
                              <input type="text" name="name" id="name" className="border border-DeepPurpleTints-600 text-black rounded-md py-2.5 pr-4 pl-3" 
                                placeholder='rename file' 
                                required="" 
                                value={newState.fileName ? newState.fileName : ''}
                                onChange={e => setNewState(prevNewState => ({ ...prevNewState, fileName: noSpace(e.target.value)}))}
                              />
                            </div>
                        </div>
                        <div className='flex justify-end'>
                          <button type="button" className="text-white inline-flex items-center bg-green-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={() => {
                            setNewState(prevNewState => ({ ...prevNewState, showRenameBtnLoading: true }))
                            handleFileRename()
                          }}>
                            <span className='flex'>
                              {newState.showRenameBtnLoading ?
                                <LoadingCircle />
                              : null}
                              Submit
                            </span>
                          </button>
                          <button type="button" className="text-white inline-flex items-center bg-ferrariRed-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center ml-2" onClick={() => {
                            setNewState(prevNewState => ({ ...prevNewState, renameModal: !newState.renameModal, showRenameBtnLoading: false, name: '' }))
                          }}>
                              Cancel
                          </button>
                        </div>
                    </form>
                </div>
            </div>
          </div>

          {/* Main modal */}
          <div id="crud-modal" tabIndex="-1" aria-hidden="true" className={`flex ${newState.createModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
              <div className="relative p-4 w-full max-w-md max-h-full">
                  {/* Modal content */}
                  <div className="relative bg-white shadow-2xl border border-DeepPurpleTints-600 rounded-2xl">
                      {/* Modal header */}
                      <div className="flex items-center justify-between p-4 md:p-5 border-b border-DeepPurpleTints-600  rounded-t dark:border-gray-600">
                          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                              Create Folder
                          </h3>
                          <button type="button" className="bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center text-black" data-modal-toggle="crud-modal" onClick={() => setNewState(prevNewState => ({ ...prevNewState, createModal: !newState.createModal, hasError: false, folder_name: '' }))}>
                              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                              </svg>
                              <span className="sr-only">Close modal</span>
                          </button>
                      </div>
                      {/* Modal body */}
                      <form className="p-4">
                          <div className="flex flex-wrap justify-center w-full mb-5">
                              <p className="block mb-2 font-medium text-NeutralGray-600 dark:text-white w-full text-center text-sm">Enter the folder Name</p>
                              <input type="text" name="name" id="name" className="border border-DeepPurpleTints-600 text-black rounded-md text-sm py-2 pr-4 pl-3" 
                              placeholder='Enter Folder name' 
                              required="" 
                              value={newState.folder_name ? newState.folder_name : ''}
                              onChange={e => setNewState(prevNewState => ({ ...prevNewState, folder_name: e.target.value }))}
                            />
                          </div>
                          <div className='flex justify-center'>
                            <button type="button" className="text-white inline-flex items-center bg-green-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={() => {
                              if(!newState.showCreateLoading) {
                                createFolderFunction()
                              }
                            }}>
                              <span className='flex'>
                                {newState.showCreateLoading ?
                                  <LoadingCircle />
                                : null}
                                Create
                              </span>
                            </button>
                            <button type="button" className="text-white inline-flex items-center bg-ferrariRed-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center ml-2" onClick={() => setNewState(prevNewState => ({ ...prevNewState, createModal: !newState.createModal, hasError: false, folder_name: '' }))}>
                                Cancel
                            </button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>

          {newState.confirmDetails && newState.deleteModal ?
              <AlertConfirmation 
                  confirmDetails={newState.confirmDetails}
                  confirmation={(deleteModal) => {
                      if(deleteModal) {
                        deleteFunction()
                      }
                      setNewState(prevNewState => ({ ...prevNewState, confirmDetails: false, deleteModal: false, showDeleteLoading: false }))
                  }}
              />
            : null}
        </div>
      </div>

      {newState.fileContent && newState.fileContent.data ?
        <EditFile
          state={state}
          newState={newState}
          closeSidePanel={() => setNewState(prevState => ({ ...prevState, fileContent: false }))}
        />
      : null}
    </div>
  );
}