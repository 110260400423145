/*************************************************
 * Collider
 * @exports
 * @file CreateUpdateQueuePanel.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, listClusterFilters, listAllProjects, getAllTagsKeys, getAllTagsValues, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { createQueue, updateQueue, getQueueFilters } from '../../../actions/Collider/ClusterAction'
// import yaml from 'js-yaml'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import MultiSelectSection from '../../common/MultiSelectSection';
 import { Store as CommonNotification } from 'react-notifications-component';
import { momentSlashConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, alphaNumeric, capitalizeAllLetter, getAccountNameFromId, getRegionName, onlyNumeric } from '../../../utils/utility';
import {Icon} from "@iconify/react";
import { setHpcPropsDetails } from "../../../actions/Collider/HpcAction";
import PageLoading from '../../common/PageLoading';
import Button from '../../common/Form/Button';
import Textbox from '../../common/Form/Textbox';
import Checkbox from '../../common/Form/Checkbox';
import SelectOption from '../../common/Form/SelectOption';
import TimeInput from '../../common/Form/TimeInput';
import Textarea from '../../common/Form/Textarea';
import { compose } from 'redux';

const CreateUpdateQueuePanel = ({ closeSidePanel, pageMode, selectedQueueDetails }) => {

    const clickOut = useRef();

    const [state, setState] = useState({
        selectedAccount: '',
        selectedRegion: '',
        // tagValues: ['aaaaaaaaaaaaa', 'bbbbbbbbbbbbbbbbbb'],
        queueArray: [0],
        computeArray_0: [0],
        s3AccessArray_0: [0],
        clusters: [],
        instanceTypes: [],
        emptyOptions: [],
        selectedProvider: pageMode !== 'Create' ? selectedQueueDetails.provider : '',
        subnetDropdown: false,
        // queueArray: ['Queue 1']
    })

    // const allStates = useSelector(state => state)
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const tagKeys = useSelector(state => state.filters.tagKeys);
    const propsQueueFilters = useSelector((state) => state?.hpc?.hpcPropsDetails?.propsQueueFilters || false);

    const dispatch = useDispatch(); // Create a dispatch function

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (providers) {
            setState(prevState => ({ ...prevState, providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : '')
            }));
        } else { 
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''
                        }));
                    }
                })
        }
    }, [dispatch, providers]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
            if(state.selectedProjectTags) {
                label = ''
                params.project_tags = state.selectedProjectTags
            }
			if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label], selectedAccount: propProjAccounts[label].length ? propProjAccounts[label][0].account_id : '' }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                if(label !== '') {
                                    let obj = propProjAccounts ? propProjAccounts : {}
                                    obj[label] = response
                                    dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                }
                                setState(prevState => ({ ...prevState, accounts: response, selectedAccount: response.length ? response[0].account_id : '' }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts, state.selectedProject, state.selectedProjectTags]); 

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount) {
                label = ''
                params.account_id = state.selectedAccount
            }
            if(state.selectedProject) {
                label = '_'
                params.project_tags = state.selectedProjectTags
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label], selectedRegion: propProjRegions[label].length ? propProjRegions[label][0].region : '' }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
                            if(label !== '') {
                                let obj = propProjRegions ? propProjRegions : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjRegions', obj))
                            }
							setState(prevState => ({ ...prevState, regions: response, selectedRegion: response.length ? response[0].region : '' }));
						}
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjRegions, state.selectedAccount, state.selectedProject, state.selectedProjectTags]);
    
    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(propProjects && propProjects.hasOwnProperty(state.selectedProvider)) {
                setState(prevState => ({ ...prevState, projects: propProjects[state.selectedProvider], selectedProject: propProjects[state.selectedProvider].length ? propProjects[state.selectedProvider][0].project : '' }));
            } else {
                dispatch(listAllProjects(params))
                    .then((response) => {
                        if(response) {
                            let obj = propProjects ? propProjects : {}
                            obj[state.selectedProvider] = response
                            dispatch(setCommonPropsDetails('propProjects', obj))
                            setState(prevState => ({ ...prevState, projects: response, selectedProject: response.length ? response[0].project : '' }))
                        }
                    });
            }
        }
    }, [state.selectedProvider, dispatch, propProjects]);

    useEffect(() => {
        if(!tagKeys) {
			let params = {}
			// params.account_id = '265469181489'
			// params.region = 'us-east-2'
			dispatch(getAllTagsKeys(params))
				.then((response) => {
                    if(response) {
					    // setState(prevState => ({ ...prevState, showTagKeyLoading: !state.showTagKeyLoading }))
                    }
				})
		} 
    }, [dispatch, tagKeys])

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            clusters: response, selectedCluster: response.length ? response[0] : ''
                        }));
                    }
            })
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        if (state.selectedProvider) {
            setState((prevState) => ({ ...prevState, callSearch: true }));
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if (state.selectedAccount) {
              params.account_id = state.selectedAccount;
            } if (state.selectedRegion) {
              params.region = state.selectedRegion;
            }

            if (!propsQueueFilters || !Object.entries(propsQueueFilters).length) {
                dispatch(getQueueFilters(params)).then((response) => {
                  if (response) {
                    dispatch(setHpcPropsDetails("propsQueueFilters", response));
                    setState(prevState => ({
                        ...prevState,
                        securityGroupIdOptions: response.security_group_ids ? response.security_group_ids : [],
                        subnetIdOptions: response.subnet_ids ? response.subnet_ids : [],
                        policiesOptions: response.policies ? response.policies : [],
                        instanceTypes: response.instance_type ? response.instance_type : [],
                        capacityTypes: response.capacity_type ? response.capacity_type : [],
                        capacityReservations: response.capacity_reservations ? response.capacity_reservations : [],
                        instanceTypeOptions: response.update_strategy ? response.update_strategy : [],
                        updateStrategies: response.update_strategy ? response.update_strategy : [],
                        volumeTypes: response.volume_type ? response.volume_type : [],
                    }))
                  }
                });
              }
        }
    }, [state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedScheduler, dispatch, propsQueueFilters])

    useEffect(() => {
        if(pageMode !== 'Create') {
            let queueConfiguration = selectedQueueDetails.queue_configuration ? selectedQueueDetails.queue_configuration : []

            let queueArray = []
            let computeArray = []
            let s3AccessArray = []
            if(!Array.isArray(queueConfiguration)) {
                queueConfiguration = [queueConfiguration]
            }
            queueConfiguration.forEach((row, index) => {
                queueArray.push(index)
                setState(prevState => ({
                    ...prevState,
                    ['queue_name_'+index]: row.queue_name ? row.queue_name : '',
                    ['volume_type_'+index]: row.volume_type ? row.volume_type : '',
                    ['volume_size_'+index]: row.volume_size ? row.volume_size : '',
                    ['volume_encryption_'+index]: row.volume_encryption ? true : false,
                    ['subnet_ids_'+index]: row.subnet_ids ? row.subnet_ids : [],
                    ['security_group_ids_'+index]: row.security_group_ids ? row.security_group_ids : [],
                    ['policies_'+index]: row.policies ? row.policies : [],
                    ['selected_tags_'+index]: row.tags ? row.tags : [],
                    ['update_strategy_'+index]: row.update_strategy ? row.update_strategy : '',
                    ['capacity_type_'+index]: row.capacity_type ? row.capacity_type : '',
                    ['on_node_configured_script_'+index]: row.on_node_configured_script ? row.on_node_configured_script : '',
                    ['showNodeScript_'+index]: row.on_node_configured_script ? true : false
                }))

                row.compute_resources && row.compute_resources.forEach((com, cIndex) => {
                    computeArray.push(cIndex)
                    setState(prevState => ({
                        ...prevState,
                        ['compute_resource_name_'+index+'_'+cIndex]: com.compute_resource_name ? com.compute_resource_name : '',
                        ['capacity_reservation_id_'+index+'_'+cIndex]: com.capacity_reservation_id ? com.capacity_reservation_id : '',
                        ['instance_type_'+index+'_'+cIndex]: com.instance_type ? com.instance_type : '',
                        ['max_instance_count_'+index+'_'+cIndex]: com.max_instance_count ? com.max_instance_count : 0,
                        ['min_instance_count_'+index+'_'+cIndex]: com.efa_enabled ? com.efa_enabled : 0,
                        ['efa_enabled_'+index+'_'+cIndex]: com.efa_enabled ? true : false,
                        ['placement_group_enabled_'+index+'_'+cIndex]: com.placement_group_enabled ? true : false,
                        ['disable_simultaneous_multithreading_'+index+'_'+cIndex]: com.disable_simultaneous_multithreading ? true : false,
                    }))
                })

                s3AccessArray.push(index)
                if(row.s3_access && row.s3_access.length) {
                    if(row.s3_access && row.s3_access.length) {
                        s3AccessArray = []
                        row.s3_access.forEach((row, sIndex) => {
                            s3AccessArray.push(sIndex)
                            setState(prevState => ({
                                ...prevState,
                                ['bucket_name_'+index+'_'+sIndex]: row.bucket_name ? row.bucket_name : '',
                                ['enable_write_access_'+index+'_'+sIndex]: row.enable_write_access ? true : false,
                            }))
                        })
                    }
                }

                setState(prevState => ({
                    ...prevState,
                    ['computeArray_'+index]: computeArray,
                    ['s3AccessArray_'+index]: s3AccessArray,
    
                }))
            })

            setState(prevState => ({
                ...prevState,
                selectedProvider: selectedQueueDetails.provider ? selectedQueueDetails.provider : '',
                selectedAccount: selectedQueueDetails.account_id ? selectedQueueDetails.account_id : '',
                selectedRegion: selectedQueueDetails.region ? selectedQueueDetails.region : '',
                selectedCluster: selectedQueueDetails.cluster_name ? selectedQueueDetails.cluster_name : '',
                selectedProject: selectedQueueDetails.project ? selectedQueueDetails.project : '',
                scheduled_at: selectedQueueDetails.scheduled_at ?  momentConvertionUtcToLocalTime(selectedQueueDetails.scheduled_at, "DD/MM/YYYY HH:mm")  : '',
                queueArray,

            }))
        }
    }, [pageMode, selectedQueueDetails])

    useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ 'key': state.tag_key }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
                    if(response) {
					    setState(prevState => ({ ...prevState, tagValues: response }))
                    }
				})
		}
	}, [state.tag_key, dispatch])

    // const getAllTagsValuesFunction = (key, index) => {
    //     setState(prevState => ({ ...prevState, ['tag_key_'+index]: key, ['tagValues_'+index]: [], }))
    //     let params = {}
    //     params.tags = [{ 'key': key }]
    //     dispatch(getAllTagsValues(params))
    //         .then((response) => {
    //             if(response) {
    //                 setState(prevState => ({ ...prevState, ["tagValues_"+index]: response }))
    //             }
    //         })
    // }
	
	const handleInputChange = (index, label, value) => {
        if(index !== '') {
            setState(prevState => ({ ...prevState, [label+'_'+index]: value }))
        } else {
            setState(prevState => ({ ...prevState, [label]: value }))
        }
	}

    const handleMultiInputChange = (index, label, value) => {
        setState(prevState => ({ ...prevState, [label+'_'+index]: value }))
	}

    const addDetails = (index) => {
        let policies = state['policies_'+index] ? state['policies_'+index] : []
        if(state['input_policy_'+index]) {
            policies.push(state['input_policy_'+index])
        }
        
        setState(prevState => ({ ...prevState, ['policies_'+index]: policies, ['input_policy_'+index]: "" }))
    }

    const handleInnerInputChange = (index, cIndex, label, value) => {
        let computerResourceNameAlreadyExist = false
        if(label === 'compute_resource_name' && state['computeArray_'+index].length) {
            if(state['computeArray_'+index].filter(e => e.compute_resource_name === value).length > 1) {
                computerResourceNameAlreadyExist = true
            }
        }

		setState(prevState => ({ ...prevState, [label+'_'+index+'_'+cIndex]: value, computerResourceNameAlreadyExist }))
	}

	const onKeyDownTimeRange = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { //backspace
			str = str.slice(0, -1)
			validateDateTime(str, state)
		} else {
			validateDateTime(time, state)
		}
	}

    const validateDateTime = (dateTime, state) => {
		let str = dateTime.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let date = str.substring(0, 2)

        let scheduleAt = ''
        if(strLength > 1 && parseInt(date) > 31) {
			scheduleAt += '01/'
		} else if(strLength > 1 && parseInt(date) < 10) {
			if(date.length === 2) {
				scheduleAt = date+'/'
			} else {
				scheduleAt += '0'+date+'/'
			}
		} else if(strLength > 1 && parseInt(date) <= 31) {
			scheduleAt += date+'/'
		}

		if(scheduleAt === '') {
			scheduleAt = dateTime
		}
		
		let month = str.substring(2, 4)
		
		if(month.length) {
			if(strLength > 3 && parseInt(month) > 12) {
				scheduleAt += '01/'
			} else if(strLength > 3 && parseInt(month) > 10) {
				scheduleAt += month+'/'
			} else if(strLength > 3 && month.length === 2) {
				scheduleAt += month+'/'
			} else {
                scheduleAt += month
            }
		}
		
        let year = str.substring(4, 8)
		if(strLength > 4 && year.length < 4) {
            scheduleAt += year
		} else if(strLength > 5 && year.length === 4) {
            scheduleAt += year+' '
        }

		let firstHour = str.substring(8, 10)

		if(strLength > 8 && parseInt(firstHour) > 23) {
			scheduleAt += '00:'
		} else if(strLength > 9 && parseInt(firstHour) <= 23) {
            scheduleAt += firstHour+':'
		} else if(strLength > 8 && parseInt(firstHour) <= 23) {
			scheduleAt += firstHour            
        } 
		
		let firstMinute = str.substring(10, 12)
		
		if(firstMinute.length) {
			if(strLength > 10 && parseInt(firstMinute) > 59) {
				scheduleAt += '00'
			} else if(strLength > 10 && parseInt(firstMinute) > 10) {
				scheduleAt += firstMinute
			} else {
				scheduleAt += firstMinute
			}
		}

        setState(prevState => ({ ...prevState, [state]: scheduleAt }))

    }

    // const formatYamlString = (data, indentLevel = 0) => {
    //     const spaces = ' '.repeat(indentLevel * 2);
    //     let formattedString = '';
    
    //     for (const key in data) {
    //         if (data.hasOwnProperty(key)) {
    //             const value = data[key];
    //             if (typeof value === 'object') {
    //                 if (Array.isArray(value)) {
    //                     formattedString += `${spaces}${key}:\n${formatYamlString(value, indentLevel + 1)}`;
    //                 } else {
    //                     formattedString += `${spaces}${key}:\n${formatYamlString(value, indentLevel + 1)}`;
    //                 }
    //             } else {
    //                 formattedString += `${spaces}${key}: ${value}\n`;
    //             }
    //         }
    //     }
    
    //     return formattedString.trim().replace(/\n/g, '\\n');
    // }    

    // const handleJsYamlConvertsion = (event) => {
    //     let value = event.target.value
    //     try {
    //         const yamlConfig = yaml.load(value);
    //         if (yamlConfig === null || typeof yamlConfig !== 'object') {
    //             throw new Error('Invalid YAML format');
    //         }
    
    //         const formattedYamlString = formatYamlString(yamlConfig);

    //         setState(prevState => ({ ...prevState, formattedYamlString }))
    
    //     } catch (error) {
    //         if (error.message.includes('unexpected end of the stream within a double quoted scalar')) {
    //             // console.error('YAML parsing error: Unexpected end of the stream within a double quoted scalar');
    //         } else {
    //             // console.error('Error converting YAML:', error.message);
    //         }
    //     }

    //     setState(prevState => ({ ...prevState, description: value }))
    
    //     // const yamlConfig = yaml.load(value);
    //     // const formattedYamlString = formatYamlString(yamlConfig);
    
    //     // const yamlConfig = yaml.dump(JSON.parse(value));
    // }

	// const addtoList = (textField, listField, index) => {
	// 	// let sharedDetails = state['shared_details_'+item] ? state['shared_details_'+item] : []
    //     // if(state['shared_to_'+item] && state['shared_to_'+item] !== '') {
	// 	// 	sharedDetails.push(state['shared_to_'+item])
	// 	// }

    //     let listItems = state[listField+'_'+index] ? state[listField+'_'+index] : []
    //     if(state[textField+'_'+index]) {
    //         listItems.push(state[textField+'_'+index])
    //     }

	// 	setState(prevState => ({ ...prevState, [listField+'_'+index]: listItems, [textField+'_'+index]: '' }))
    // }

	const removefromList = (index, value, listField) => {
		// let filteredResult = state['shared_details_'+item].filter(e => e !== sharedTo)
        // setState({ ['shared_details_'+item]: filteredResult })

        let filteredListItem = state[listField+'_'+index].filter(e => e !== value)

        let array = []
        filteredListItem.forEach(row => {
            let rowValue = {
                value: row,
                label: row
            }
            array.push(rowValue)
        })

        setState(prevState => ({ ...prevState, [listField+'_'+index]: filteredListItem }))
    }

	const removeTagSection = (tag, index) => {
		let filteredResult = state['selected_tags_'+index] && state['selected_tags_'+index].filter(e => e !== tag)
		setState(prevState => ({ ...prevState, ['selected_tags_'+index]: filteredResult }))
	}

    const addSection = (array) => {
        let rowList = state[array];
        if(state[array]) {
            let value = state[array][state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        setState(prevState => ({ ...prevState, [array]: rowList }))
    }

    // const addSectionQuerySection = (array) => {
    //     let rowList = state[array];
    //     if(state[array]) {
    //         let value = state[array][state[array].length - 1]
    //         value = value+1 
    //         rowList.push(value);

    //         setState(prevState => ({ ...prevState, [array]: rowList, ['computeArray_'+value]: [0], ['s3AccessArray_'+value]: [0] }))
    //     }
        
    //     setState(prevState => ({ ...prevState, [array]: rowList }))
    // }

    // const removeSection = (array, index, i) => {
    //     setState(prevState => ({ ...prevState, ['queue_name_'+index]: '', ['volume_type_'+index]: '', ['subnet_ids_'+index]: [], ['security_group_ids_'+index]: [], ['policies_'+index]: [], ['selected_tags_'+index]: []}))

    //     let rowList = state[array];
    //     rowList.splice(i, 1);
    //     setState(prevState => ({ ...prevState, [array]: rowList }))
    // }
    const addSectionQuerySection = (array) => {
        let rowList = [...state[array]]; // Create a copy of the current queue array
        let newQueueIndex = rowList.length + 1; // Determine the new queue index
        rowList.push(`${newQueueIndex}`); // Add the new queue
    
        setState(prevState => ({
            ...prevState,
            [array]: rowList,
            [`computeArray_${newQueueIndex}`]: [0],
            [`s3AccessArray_${newQueueIndex}`]: [0]
        }));
    }

    const removeSection = (array, index) => {
        let rowList = [...state[array]]; // Create a copy of the current queue array
    
        if (rowList.length > 1) { // Check if more than one queue exists
            rowList.splice(index, 1); // Remove the specified queue
            setState(prevState => ({ ...prevState, [array]: rowList }));
        }
    }
    const removeCRSection = (array, index, cIndex, j) => {
        if(array === 's3AccessArray') {
            setState(prevState => ({ ...prevState, ['bucket_name_'+index+'_'+cIndex]: '', ['instance_type_'+index+'_'+cIndex]: '' }))
        } else {
            setState(prevState => ({ ...prevState, ['max_instance_count_'+index+'_'+cIndex]: '', ['enable_write_access_'+index+'_'+cIndex]: false }))
        }

        let rowList = state[array+'_'+index];
        rowList.splice(j, 1);
        setState(prevState => ({ ...prevState, [array+'_'+index]: rowList }))
    }

    useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            params.cluster_name = state.selectedCluster
            params.project = state.selectedProject
            params.project_tags = state.selectedProjectTags ? state.selectedProjectTags : []
            params.force_update = state.force_update

            let inputValidationError = false
            if(!state.selectedProvider || !state.selectedProject || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster) {
                inputValidationError = true
            }
            if(state['scheduled_at']) {
                params.scheduled_at = momentSlashConvertionLocalToUtcTime(state['scheduled_at'], "YYYY-MM-DD HH:mm:00")
            }

            let queueConfiguration = []

            let queueArray = state.queueArray
            queueArray.forEach((index, i) => {
                let rowData = {}
                rowData.queue_name = state['queue_name_'+index]
                rowData.capacity_type = state['capacity_type_'+index]
                rowData.update_strategy = state['update_strategy_'+index]
                rowData.volume_type = state['volume_type_'+index]
                rowData.volume_size = state['volume_size_'+index]
                rowData.volume_encryption = state['volume_encryption_'+index]
                rowData.subnet_ids = state['subnet_ids_'+index]
                rowData.security_group_ids = state['security_group_ids_'+index]
                rowData.policies = state['policies_'+index]
                rowData.tags = state['selected_tags_'+index] ? state['selected_tags_'+index] : []
                rowData.on_node_configured_script = state['showNodeScript_'+index] && state['on_node_configured_script_'+index] ? state['on_node_configured_script_'+index] : ''

                if(!rowData.queue_name || !rowData.subnet_ids || !rowData.subnet_ids.length || !rowData.capacity_type || !rowData.update_strategy) {
                    inputValidationError = true
                }

                let computeResources = []
                state['computeArray_'+index].forEach((cIndex, j) => {
                    if(!state['max_instance_count_'+index+'_'+cIndex]) {
                        inputValidationError = true
                    }
                    let comRow = {
                        compute_resource_name: state['compute_resource_name_'+index+'_'+cIndex],
                        capacity_reservation_id: state['capacity_reservation_id_'+index+'_'+cIndex],
                        instance_type: state['instance_type_'+index+'_'+cIndex],
                        min_instance_count: state['min_instance_count_'+index+'_'+cIndex] ? Math.round(parseFloat(state['min_instance_count_'+index+'_'+cIndex])) : 0,
                        max_instance_count: state['max_instance_count_'+index+'_'+cIndex] ? Math.round(parseFloat(state['max_instance_count_'+index+'_'+cIndex])) : 0,
                        efa_enabled: state['efa_enabled_'+index+'_'+cIndex] ? true : false,
                        placement_group_enabled: state['placement_group_enabled_'+index+'_'+cIndex] ? true : false,
                        disable_simultaneous_multithreading: state['disable_simultaneous_multithreading_'+index+'_'+cIndex] ? true : false,
                    }
                    if(Object.entries(comRow).length) {
                        computeResources.push(comRow)
                    }
                })
                rowData.compute_resources = computeResources

                let s3Access = []
                state['s3AccessArray_'+index].forEach(sIndex => {
                    let comRow = {
                        bucket_name: state['bucket_name_'+index+'_'+sIndex],
                        enable_write_access: state['enable_write_access_'+index+'_'+sIndex] ? true : false
                    }
                    if(state['bucket_name_'+index+'_'+sIndex]) {
                        s3Access.push(comRow)
                    }

                })
                if(!s3Access.length) {
                    inputValidationError = true
                }
                rowData.s3_access = s3Access

                queueConfiguration.push(rowData)
            })
            params.queue_configuration = queueConfiguration

            setState(prevState => ({ ...prevState, inputValidationError, saveLoading: inputValidationError ? false : false }))
            if(!inputValidationError) {
                if(pageMode !== 'Edit') {
                    dispatch(createQueue(params))
                        .then((response) => {
                            let messageType = ''
                            let message = ''
                            let createErrorMessage = ''
                            if(response) {
                                if(response.status) {
                                    messageType = 'success'
                                    message = 'Queue Created Successfully'
                                } else {
                                    messageType = 'danger'
                                    message = 'Error in saving Queue'
                                    createErrorMessage = response.message
            
                                }
                            } else {
                                messageType = 'danger'
                                message = 'Error in saving Queue'
                            }
            
                            // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
            
                            if(response.status) {
                                setTimeout(() => closeSidePanel(), 1000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
                            }
                            // setState(prevState => ({ ...prevState, createRespons: response }))
                        })
                } else {
                    dispatch(updateQueue(params))
                        .then((response) => {
                            let messageType = ''
                            let message = ''
                            let createErrorMessage = ''
                            if(response) {
                                if(response.status) {
                                    messageType = 'success'
                                    message = 'Queue Updated Successfullt'
                                } else {
                                    messageType = 'danger'
                                    message = 'Error in updating Queue'
                                    createErrorMessage = response.message
            
                                }
                            } else {
                                messageType = 'danger'
                                message = 'Error in updating Queue'
                            }
            
                            // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
            
                            if(response.status) {
                                setTimeout(() => closeSidePanel(), 1000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
                            }
                            // setState(prevState => ({ ...prevState, createRespons: response }))
                        })
                }

            }
        }
    }, [state.callSaveFunction, state, closeSidePanel, dispatch, pageMode])

    const addNewTagValue = (index) => {
		let selectedTags = state['selected_tags_'+index] ? state['selected_tags_'+index] : []

		if(state['tag_key_'+index] && state['tag_key_'+index] !== '' && state['tag_value_'+index] && state['tag_value_'+index].length) {
            let dataRow = {}
            dataRow.key = state['tag_key_'+index]
            dataRow.value = state['tag_value_'+index]
            selectedTags.push(dataRow)
		}

		setState(prevState => ({ ...prevState, ['selected_tags_'+index]: selectedTags, ['tag_key_'+index]: '',  ['tag_value_'+index]: '' }))
	}

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='bg-white' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-3/4 w-10/12' ref={clickOut}>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-black'>{pageMode} Queue</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                    <div className={`p-4`}>
                        <div className='flex justify-between'>
                            <ul className="flex flex-wrap gap-2 text-sm text-center">
                                {state.queueArray.map((queue, index) => (
                                    <li key={index} className="relative me-2">
                                        <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 font-bold text-white bg-DeepPurpleTints-600 underline`}>
                                            Queue {parseInt(queue ? queue : 1)}
                                        </span>
                                        {state.queueArray.length > 1 && ( // Show close icon only if more than one tab exists

                                            <Icon icon="jam:close" width={24} height={24}  className='cursor-pointer top-0 right-0 h-4 w-4 absolute bg-orange-500 text-white rounded-full hover:bg-orange-600 transition duration-200' onClick={() => removeSection('queueArray', index)}/>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            <div className='flex justify-end'>
                                <Button
                                    classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center'}}
                                    label={'+ Add Queue'}
                                    callback={() => {
                                        addSectionQuerySection('queueArray')
                                    }}
                                />
                            </div>
                        </div>

                        <div className="overflow-y-auto h-[calc(100vh-325px)] border border-NeutralGray-500 rounded-r-2xl rounded-b-2xl">
                            <div className='p-8'>
                            {state.createErrorMessage ?
                                <p className='text-red mb-2 text-center f18'>Error in saving data</p>
                            : null}
                            <div className='flex flex-wrap'>
                                <div className='flex justify-start w-1/2 pr-4'>
                                    <SelectOption
                                        label={"Provider"}
                                        fields={["provider_name", "provider_name"]}
                                        options={state.providers}
                                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                        callbackMultiSelect={(value) => {
                                            dispatch(setHpcPropsDetails('propsQueueFilters', {}))
                                            setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: "", selectedProject: "", selectedProjectTags: [], selectedRegion: "", selectedCluster: "" }))
                                        }}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        singleSelection={true}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                                <div className='flex justify-start w-1/2 pl-4'>
                                    <SelectOption
                                        label={"Project"}
                                        fields={["project", "project"]}
                                        options={state.projects}
                                        selectedValues={state.selectedProject ? state.selectedProject : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedProject: value, selectedProjectTags: state.projects.filter(e => e.project === value).length && state.projects.filter(e => e.project === value)[0] && state.projects.filter(e => e.project === value)[0].tags ? state.projects.filter(e => e.project === value)[0].tags : [] }))
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-wrap pt-8'>
                                <div className='flex justify-start w-1/2 pr-4'>
                                    <SelectOption
                                        label={"Account"}
                                        fields={["account_id", "account_name"]}
                                        options={state.accounts}
                                        selectedValues={state.selectedAccount ? state.selectedAccount : ''}
                                        callbackMultiSelect={(value) => {
                                            dispatch(setHpcPropsDetails('propsQueueFilters', {}))
                                            setState(prevState => ({ ...prevState,  selectedAccount: value, selectedCluster: "" }))
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                                <div className='flex justify-start w-1/2 pl-4'>
                                    <SelectOption
                                        label={"Region"}
                                        fields={["region", "name"]}
                                        options={state.regions}
                                        selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                        callbackMultiSelect={(value) => {
                                            dispatch(setHpcPropsDetails('propsQueueFilters', {}))
                                            setState(prevState => ({ ...prevState, selectedRegion: value, selectedCluster: ""}))
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-wrap pt-8'>
                                <div className='flex justify-start w-1/2 pr-4'>
                                    <SelectOption
                                        label={"Cluster name"}
                                        // fields={["account_id", "account_name"]}
                                        options={state.clusters}
                                        selectedValues={state.selectedCluster ? state.selectedCluster : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedCluster: value }))
                                        }}
                                        singleSelection={true}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                                <div className='lg:w-1/2 w-full lg:pl-4 pt-4 lg:pt-0'>
                                    <TimeInput
                                        label={"Schedule At"}
                                        value={state.scheduled_at ? state.scheduled_at : ''}
                                        callback={(value) => {
                                            setState(prevState => ({ ...prevState, scheduled_at: value }))
                                        }}
                                        placeholder={'DD/MM/YYY | HH:mm'}
                                    />
                                </div>
                            </div>
                            <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                            <p className='font-bold text-NeutralGray-800 text-[22px] uppercase'>Queue Configurations</p>
                            {state.queueArray && state.queueArray.map((index, i) => {
                                return(
                                    <div key={'q_'+index}>
                                        <div className='flex flex-wrap pt-8'>
                                            <div className='lg:w-1/2 w-full lg:pr-4'>
                                                <Textbox
                                                    label={"Queue Name"}
                                                    type="text"
                                                    selectedValue={state['queue_name_'+index]  ? state['queue_name_'+index]  : ''}
                                                    callback={(value) => {
                                                        setState(prevState => ({ ...prevState, ['queue_name_'+index] : value }))
                                                        handleInputChange(index, 'queue_name', value)
                                                    }}
                                                    placeholder={'Enter queue name'}
                                                    manditory={true}
                                                    hasError={state.inputValidationError}
                                                />
                                            </div>
                                            <div className='flex justify-start w-1/2 pl-4'>
                                                <Textbox
                                                    label={"Scaledown idle time (minutes)"}
                                                    type="number"
                                                    selectedValue={state['scaledown_idle_time_'+index] ? state['scaledown_idle_time_'+index] : ''}
                                                    callback={(value) => {
                                                        setState(prevState => ({ ...prevState, ['scaledown_idle_time_'+index]: value }))
                                                        // handleInputChange(index, 'scaledown_idle_time', value)
                                                    }}
                                                    placeholder={'Enter minutes'}
                                                />
                                            </div>
                                        </div>

                                        <div className='flex flex-wrap pt-8'>
                                            <div className='flex flex-col justify-start w-1/2 pr-4'>
                                                <SelectOption
                                                    label={"Subnet IDs"}
                                                    options={state.subnetIdOptions ? state.subnetIdOptions : []}
                                                    selectedValues={state['subnet_ids_'+index] ? state['subnet_ids_'+index] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState,  ['subnet_ids_'+index]: value  }))
                                                        // handleInputChange(index, 'subnet_ids', value)
                                                    }}
                                                    dropdownWidth={'w-full'}
                                                />
                                                <div className="flex flex-wrap pt-4">
                                                    {state['subnet_ids_'+index] && state['subnet_ids_'+index].length ? state['subnet_ids_'+index].map((row) => {
                                                        return (
                                                            <span className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">
                                                                {row}<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(row, ['subnet_ids_'+index]) }/>
                                                            </span>
                                                        );
                                                        })
                                                    : null}
                                                </div>
                                            </div>
                                            <div className='flex flex-col justify-start w-1/2 pl-4'>
                                                <SelectOption
                                                    label={"Security Group IDs"}
                                                    options={state.securityGroupIdOptions ? state.securityGroupIdOptions : []}
                                                    selectedValues={state['security_group_ids_'+index] ? state['security_group_ids_'+index] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, ['security_group_ids_'+index]: value }))
                                                    }}
                                                    dropdownWidth={'w-full'}
                                                />
                                                 <div className="flex flex-wrap pt-4">
                                                    {state['security_group_ids_'+index] && state['security_group_ids_'+index].length ? state['security_group_ids_'+index].map((row) => {
                                                        return (
                                                            <span className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">
                                                                {row}<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(row, ['security_group_ids_'+index]) }/>
                                                            </span>
                                                        );
                                                        })
                                                    : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex flex-wrap pt-8'>
                                            <div className='flex justify-start w-1/2 pr-4'>
                                                <SelectOption
                                                    label={"Queue update strategy"}
                                                    options={state.updateStrategies}
                                                    selectedValues={state['update_strategy_'+index] ? state['update_strategy_'+index] : ''}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState,  ['update_strategy_'+index]: value  }))
                                                        handleInputChange(index, 'update_strategy', value)
                                                    }}
                                                    singleSelection={true}
                                                    manditory={true}
                                                    hasError={state.inputValidationError}
                                                    dropdownWidth={'w-full'}
                                                />
                                            </div>
                                            <div className='flex justify-start w-1/2 pl-4'>
                                                <SelectOption
                                                    label={"Capacity Type"}
                                                    options={state.capacityTypes}
                                                    selectedValues={state['capacity_type_'+index] ? state['capacity_type_'+index] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, ['capacity_type_'+index]: value }))
                                                        handleInputChange(index, 'capacity_type', value)
                                                    }}
                                                    singleSelection={true}
                                                    manditory={true}
                                                    hasError={state.inputValidationError}
                                                    dropdownWidth={'w-full'}
                                                />
                                            </div>
                                        </div>

                                        <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                                            <p className='font-bold text-NeutralGray-800 text-[22px] pb-4 uppercase'>Compute Resources</p>
                                            {state['computeArray_'+index] && state['computeArray_'+index].map((cIndex, j) => {
                                                return(
                                                    <div key={'compArr_'+index+'_'+cIndex+'_'+j} className=''>
                                                        <div className='bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl p-6 mb-4'>
                                                        {state['computeArray_'+index].map(row => {
                                                            return(
                                                            (row !== cIndex && state['compute_resource_name_'+index+'_'+cIndex] && state['compute_resource_name_'+index+'_'+cIndex] === state['compute_resource_name_'+index+'_'+row]) ?
                                                                <p key={'dup_'+index+'_'+cIndex+'_'+j} className='mb-1 text-danger'>
                                                                    Duplicate Name
                                                                </p>
                                                            : null
                                                            )
                                                        })}
                                                        <div className='flex flex-wrap mt-2'>
                                                            <div className='w-full'>
                                                                <div className='flex flex-wrap'>
                                                                    <div className='lg:w-1/3 w-full lg:pr-4'>
                                                                        <Textbox
                                                                            label={"Name"}
                                                                            type="text"
                                                                            selectedValue={state['compute_resource_name_'+index+'_'+cIndex]  ? state['compute_resource_name_'+index+'_'+cIndex]  : ''}
                                                                            callback={(value) => {
                                                                                setState(prevState => ({ ...prevState, ['compute_resource_name_'+index+'_'+cIndex] : value }))
                                                                            }}
                                                                            placeholder={'Enter compute resource name'}
                                                                            manditory={true}
                                                                            hasError={state.inputValidationError}
                                                                        />
                                                                    </div>
                                                                    <div className='flex justify-start w-1/3 pr-4'>
                                                                        <SelectOption
                                                                            label={"Capacity Reservation Id"}
                                                                            fields={["capacity_reservation_id", "capacity_reservation_id"]}
                                                                            options={state.capacityReservations}
                                                                            selectedValues={state['capacity_reservation_id_'+index+'_'+cIndex] ? state['capacity_reservation_id_'+index+'_'+cIndex] : []}
                                                                            callbackMultiSelect={(value) => {
                                                                                const capacityReservation = state.capacityReservations?.filter(
                                                                                    (reservation) => reservation.capacity_reservation_id === value
                                                                                )[0];
                                                                                setState((prevState) => ({
                                                                                    ...prevState,
                                                                                    ["capacity_reservation_id_" + index + "_" + cIndex]: value,
                                                                                    ["instance_type_" + index + "_" + cIndex]: capacityReservation.instance_type,
                                                                                    ["min_instance_count_" + index + "_" + cIndex]: capacityReservation.min_count,
                                                                                    ["max_instance_count_" + index + "_" + cIndex]: capacityReservation.max_count,
                                                                                    ["placement_group_enabled_" + index + "_" + cIndex]: capacityReservation.use_placement_group,
                                                                                }));
                                                                            }}
                                                                            singleSelection={true}
                                                                            manditory={true}
                                                                            hasError={state.inputValidationError}
                                                                            dropdownWidth={'w-full'}
                                                                        />
                                                                    </div>
                                                                    <div className='flex justify-start w-1/3 pr-4'>
                                                                        <SelectOption
                                                                            label={"Instance Type"}
                                                                            options={state.instanceTypes}
                                                                            selectedValues={state['instance_type_'+index+'_'+cIndex] ? state['instance_type_'+index+'_'+cIndex] : []}
                                                                            callbackMultiSelect={(value) => {
                                                                                setState(prevState => ({ ...prevState, ['instance_type_'+index+'_'+cIndex]: value }))
                                                                            }}
                                                                            singleSelection={true}
                                                                            manditory={true}
                                                                            hasError={state.inputValidationError}
                                                                            dropdownWidth={'w-full'}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='flex justify-between self-center'>
                                                                    <div className='flex flex-col w-1/3'>
                                                                        <div className='w-full lg:pr-4 pt-6'>
                                                                            <Textbox
                                                                                label={"Min Instance Count"}
                                                                                type="text"
                                                                                selectedValue={state['min_instance_count_'+index+'_'+cIndex]  ? state['min_instance_count_'+index+'_'+cIndex]  : ''}
                                                                                callback={(value) => {
                                                                                    setState(prevState => ({ ...prevState, ['min_instance_count_'+index+'_'+cIndex] : value }))
                                                                                    handleInnerInputChange(index, cIndex, 'min_instance_count', onlyNumeric(value))
                                                                                }}
                                                                                placeholder={'Enter min instance count'}
                                                                                // manditory={true}
                                                                                // hasError={state.inputValidationError}
                                                                            />
                                                                        </div>
                                                                        <div className='w-full lg:pr-4 pt-6'>
                                                                            <Textbox
                                                                                label={"Max Instance Count"}
                                                                                type="text"
                                                                                selectedValue={state['max_instance_count_'+index+'_'+cIndex]  ? state['max_instance_count_'+index+'_'+cIndex]  : ''}
                                                                                callback={(value) => {
                                                                                    setState(prevState => ({ ...prevState, ['max_instance_count_'+index+'_'+cIndex] : value }))
                                                                                    handleInnerInputChange(index, cIndex, 'max_instance_count', onlyNumeric(value))
                                                                                }}
                                                                                placeholder={'Enter max instance count'}
                                                                                // manditory={true}
                                                                                // hasError={state.inputValidationError}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className='flex flex-col w-1/3 pt-6'>
                                                                    <p className="text-base text-black self-center w-full">Select Preferences</p>
                                                                        <div className='border border-NeutralGray-400 p-3 mb-4 bg-white rounded-2xl'>
                                                                            <div className='flex'>
                                                                                <Checkbox
                                                                                    label="Use EFA"
                                                                                    name="efa_enabled"
                                                                                    selectedValue={state['efa_enabled_'+index+'_'+cIndex]}
                                                                                    callback={() => {
                                                                                        setState(prevState => ({ ...prevState,  ['efa_enabled_'+index+'_'+cIndex]: !state['efa_enabled_'+index+'_'+cIndex]}))
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className='flex pt-6'>
                                                                                <Checkbox
                                                                                    label="Use placement group"
                                                                                    name="efa_enabled"
                                                                                    selectedValue={state['placement_group_enabled_'+index+'_'+cIndex]}
                                                                                    callback={() => {
                                                                                        setState(prevState => ({ ...prevState,  ['placement_group_enabled_'+index+'_'+cIndex]: !state['placement_group_enabled_'+index+'_'+cIndex]}))
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className='flex pt-6'>
                                                                                <Checkbox
                                                                                    label="Turn off multithreading"
                                                                                    name="efa_enabled"
                                                                                    selectedValue={state['disable_simultaneous_multithreading_'+index+'_'+cIndex]}
                                                                                    callback={() => {
                                                                                        setState(prevState => ({ ...prevState,  ['disable_simultaneous_multithreading_'+index+'_'+cIndex]: !state['disable_simultaneous_multithreading_'+index+'_'+cIndex]}))
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex w-1/3 justify-end pt-6'>
                                                                        {state['computeArray_'+index].length > 1 ?
                                                                            <span className={`flex justify-end mb-0 text-white bg-ferrariRed-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base`} onClick={() => removeCRSection('computeArray', index, cIndex, j)}> Remove</span>
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        {(state['computeArray_'+index].length - 1) === j ?
                                                            <div className='flex justify-center'>
                                                                <p className='mb-4 text-primaryPurple-600 border border-primaryPurple-600 bg-white pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base' onClick={() =>  addSection('computeArray_'+index)}>+ Add Resource</p>
                                                            </div>
                                                        : null}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                                            <p className='font-bold text-NeutralGray-800 text-[22px] pb-4 uppercase'>Scripts</p>
                                            <div className='flex pt-3'>
                                                <Checkbox
                                                    label="Run script on node configured"
                                                    name="efa_enabled"
                                                    selectedValue={state['showNodeScript_'+index]}
                                                    callback={() => {
                                                        setState(prevState => ({ ...prevState,  ['showNodeScript_'+index]: !state['showNodeScript_'+index]}))
                                                    }}
                                                />
                                            </div>
                                            <div className='flex flex-wrap pt-8'>
                                                <div className='w-full pt-4 lg:pt-0'>
                                                    <Textarea
                                                        label={'Enter Script'}
                                                        placeholder={'Paste script here'}
                                                        rows={4}
                                                        wordLength={1000}
                                                        callback={(value) => {
                                                            setState(prevState => ({ ...prevState, ['on_node_configured_script_'+index]: value }))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                                        <p className='font-bold text-NeutralGray-800 text-[22px] pb-4 uppercase'>Root Volume</p>
                                            <div className='flex flex-wrap'>
                                                <div className='flex justify-start w-1/2 pr-4'>
                                                    <SelectOption
                                                        label={"Volume Type"}
                                                        options={state.volumeTypes ? state.volumeTypes : []}
                                                        selectedValues={state['volume_type_'+index] ? state['volume_type_'+index] : ''}
                                                        callbackMultiSelect={(value) => {
                                                            setState(prevState => ({ ...prevState,  ['volume_type_'+index]: value  }))
                                                        }}
                                                        singleSelection={true}
                                                        // manditory={true}
                                                        // hasError={state.inputValidationError}
                                                        dropdownWidth={'w-full'}
                                                    />
                                                </div>
                                                <div className='flex justify-start w-1/2 pl-4'>
                                                    <Textbox
                                                        label={"Root Volume Size"}
                                                        type="text"
                                                        selectedValue={state['volume_size_'+index]  ? state['volume_size_'+index]  : ''}
                                                        callback={(value) => {
                                                            setState(prevState => ({ ...prevState, ['volume_size_'+index] : value }))
                                                            handleInputChange(index, 'volume_size', onlyNumeric(value))
                                                        }}
                                                        placeholder={'Enter volume size'}
                                                        // manditory={true}
                                                        // hasError={state.inputValidationError}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex flex-wrap pt-8'>
                                        <p className="text-base font-medium text-black self-center w-full">Encryption</p>
                                            <div className='flex pt-3 w-1/2'>
                                                <Checkbox
                                                    label="Encrypt root volume"
                                                    name="efa_enabled"
                                                    selectedValue={state['volume_encryption_'+index]}
                                                    callback={() => {
                                                        setState(prevState => ({ ...prevState,  ['volume_encryption_'+index]: !state['volume_encryption_'+index]}))
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
                                        <p className='font-bold text-NeutralGray-800 text-[22px] pb-4 uppercase'>S3 Access</p>
                                
                                            {state['s3AccessArray_'+index] && state['s3AccessArray_'+index].map((sIndex, x) => {
                                                return(
                                                <div key={'s3_'+index+'_'+sIndex} className=''>
                                                    <div className='bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl p-6 mb-4'>
                                                        
                                                    <div className='flex flex-wrap justify-between pt-4 pr-4'>
                                                        <div className='flex w-1/2 lg:pr-4'>
                                                            <Textbox
                                                                label={"Bucket name"}
                                                                type="text"
                                                                selectedValue={state['bucket_name_'+index+'_'+sIndex]  ? state['bucket_name_'+index+'_'+sIndex]  : ''}
                                                                callback={(value) => {
                                                                    setState(prevState => ({ ...prevState, ['bucket_name_'+index+'_'+sIndex] : value }))
                                                                }}
                                                                placeholder={'Enter bucket name'}
                                                                // manditory={true}
                                                                // hasError={state.inputValidationError}
                                                            />
                                                        </div>
                                                        <div className='flex justify-between pt-4 w-1/2 pl-4'>
                                                            {/* <p className="text-base font-medium text-black self-center w-full">Encryption</p> */}
                                                            <Checkbox
                                                                label="Enable write access"
                                                                name="efa_enabled"
                                                                selectedValue={state['enable_write_access_'+index+'_'+sIndex]}
                                                                callback={() => {
                                                                    setState(prevState => ({ ...prevState,  ['enable_write_access_'+index+'_'+sIndex]: !state['volume_encryption_'+index]}))
                                                                }}
                                                            />
                                                            {state['s3AccessArray_'+index].length > 1 ?
                                                                <span className={`flex justify-end mb-0 text-white bg-ferrariRed-600 px-3 py-2.5 rounded-full self-center cursor-pointer text-base`} onClick={() => removeCRSection('s3AccessArray', index, sIndex, x)}> Remove</span>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex justify-center'>
                                                {(state['s3AccessArray_'+index].length - 1) === x ?
                                                    <span className={`'mb-4 text-primaryPurple-600 border border-primaryPurple-600 bg-white pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base`} onClick={() => addSection('s3AccessArray_'+index)}>+ Add Resource</span>
                                                : null}
                                                </div>
                                                </div>
                                                )
                                            })}
                                        </div>

                                        <div className='flex flex-col mt-8'>
                                            <div className='flex flex-wrap justify-between'>
                                                <div className='lg:pr-4 w-4/5 pr-4'>
                                                    <Textbox
                                                        label={"IAM Policies"}
                                                        type="text"
                                                        selectedValue={state['input_policy_'+index] ? state['input_policy_'+index] : ''}
                                                        callback={(value) => {
                                                            setState(prevState => ({ ...prevState, ['input_policy_'+index]: value }))
                                                            handleMultiInputChange(index, 'input_policy', value)
                                                        }}
                                                        placeholder={'Enter policies'}
                                                    />
                                                </div>
                                                <div className='flex justify-end pt-4 pl-4'>
                                                    <Button
                                                        classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center min-w-24'}}
                                                        label={'+ Add'}
                                                        callback={() => {
                                                            addDetails(index)
                                                        }}
                                                    />
                                                </div>
                                            {/* <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 cursor-pointer' onClick={() => addDetails(index)} /> */}
                                            </div>     

                                                {state['policies_'+index] && Array.isArray(state['policies_'+index]) && state['policies_'+index].length ? 
                                                    <div className='flex flex-wrap pt-4'>
                                                        {state['policies_'+index].map(row => {
                                                            return(
                                                                <span key={'policies_'+index+'_'+row} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{row}
                                                                    {pageMode !== 'View' ?
                                                                        <Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-black ml-2 self-center cursor-pointer' onClick={ () => removefromList(index, row, 'policies') } />
                                                                    : null}
                                                                </span>
                                                            )
                                                        })}
                                                    </div>
                                                : null}    
                                        </div>

                                        <div className='flex flex-wrap justify-between mt-8'>
                                            <div className='flex w-4/5'>
                                                <div className='lg:pr-4 w-full'>
                                                    <Textbox
                                                        label={"Tags"}
                                                        type="text"
                                                        selectedValue={state['tag_key_'+index] ? state['tag_key_'+index] : ''}
                                                        callback={(value) => {
                                                            setState(prevState => ({ ...prevState, ['tag_key_'+index]: value }))
                                                            handleInputChange(index, 'tag_key', value)
                                                        }}
                                                        placeholder={'Enter tag key'}
                                                    />
                                                </div>
                                                <div className='lg:pl-4 pt-4 mt-6 lg:pt-0 w-full'>
                                                    <Textbox
                                                        label={""}
                                                        type="text"
                                                        selectedValue={state['tag_value_'+index]  ? state['tag_value_'+index]  : ''}
                                                        callback={(value) => {
                                                            setState(prevState => ({ ...prevState, ['tag_value_'+index] : value }))
                                                            handleInputChange(index, 'tag_value', value)
                                                        }}
                                                        placeholder={'Enter tag value'}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex justify-end pt-4 pl-4'>
                                                    <Button
                                                        classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center min-w-24'}}
                                                        label={'+ Add'}
                                                        callback={() => {
                                                            addNewTagValue(index)
                                                        }}
                                                    />
                                                </div>
                                            {/* <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-4 mt-8 cursor-pointer' onClick={() => addNewTagValue()}/> */}
                                        </div>
                                        {state['selected_tags_'+index] && state['selected_tags_'+index].length ? 
                                            <div className='flex flex-wrap pt-4'>
                                                {state['selected_tags_'+index].filter(e => e.value !== 'All').map((tag, tIndex) => {
                                                    return(
                                                        <span key={'tag_'+index+'_'+tIndex} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all' id={'tag_'+tIndex}>
                                                            <div className='truncate'  style={{maxWidth: '250px'}}>
                                                                {tag.key +' : '+tag.value}
                                                            </div>
                                                            {pageMode !== 'View' ?
                                                                <Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-black ml-2 self-center cursor-pointer' onClick={ () => removeTagSection(tag, index) } />
                                                            : null}
                                                        </span>
                                                    )
                                                })}
                                            </div>
                                        : null}
                                        {/* {pageMode !== 'View' ?
                                            <div className='flex w-full justify-end'>
                                                {state.queueArray.length > 1 ?
                                                    <span className={`cursor-pointer text-red f16 self-center ml-4`} onClick={() => removeSection('queueArray', index, i)}> Remove Queue</span>
                                                : null}
                                                {(state.queueArray.length - 1) ===  i ?
                                                    <span className={`cursor-pointer text-info f16 self-center ml-4`} onClick={() => addSectionQuerySection('queueArray')}> Add Queue</span>
                                                : null}
                                            </div>
                                        : null} */}
                                    </div>
                                )
                            })}
                            </div>
                        
                            </div>
                        </div>
                    </div>
                    <div className={`w-full p-8 mt-4 flex shadow-lg bg-NeutralGray-700`}>
                        <div className={`flex justify-start w-1/2`}>
                            <label className="flex pl-4 self-center ">
                                <input type="checkbox" 
                                    className="mt-1" 
                                    checked={state.force_update ? true : false}
                                    onChange={() => {
                                        setState(prevState => ({ ...prevState, force_update: !state.force_update }))
                                    }}
                                />
                                <span className="slider round small pl-1"></span>
                            </label>
                            <span className="mb-0 text-sm self-center text-white">Force update: Edit the cluster while the compute fleet is still running</span>
                        </div>
                        <div className={`self-center flex justify-end w-1/2`}>
                        {pageMode !== 'View' ?
                            state.saveLoading ?
                                <Button
                                    classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                    label={state.pageMode === 'Edit' ? 'Updating' : 'Saving'}
                                    loading={true}
                                />
                            :
                                <Button
                                    classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                    label={state.pageMode === 'Edit' ? 'Update' : 'Save'}
                                    callback={() => {
                                        setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))
                                    }}
                                />
                        :null}
                        <Button
                            classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                            label={'Cancel'}
                            callback={() => 
                                closeSidePanel()
                            }
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateUpdateQueuePanel