/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file NotificationsTab.js
 * @author Prakash // on 14/08/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { momentConvertionUtcToLocalTime } from '../../../utils/utility'
import { listNotifications } from '../../../actions/ImagebuilderAction'
import {Icon} from "@iconify/react";


const NotificationsTab = ({ selectedDetails }) => {
    
    const dispatch = useDispatch(); // Create a dispatch function

    const [state, setState] = useState({
        showLoading: true,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 5,
        callNotificaions: true
    })

    useEffect(() => {
        if(state.callNotificaions) {
            setState(prevState => ({ ...prevState, callNotificaions: false }));
            let params = {
                image_id: selectedDetails.image_id
            };
            
            if(state.token === 'next' && state.listResponse && state.listResponse.next_token) {
                params.next_token = state.listResponse && state.listResponse.next_token
            } else if(state.token === 'previous' && state.listResponse && state.listResponse.previous_token) {
                params.previous_token = state.listResponse && state.listResponse.previous_token
            }
            
            params.size = state.perPage
    
            dispatch(listNotifications(params))
                .then((response) => {
                    if(response) {
                        let results = response.results && response.results.length ? response.results : []
                        let startRecord = 0
                        let currentPage = 1
    
                        setState(prevState => ({ ...prevState, listResponse: response, notificationList: results, filteredNotList: results, startRecord, currentPage, showLoading: false }));
                    }
                })
        }
    }, [state.callNotificaions, dispatch, selectedDetails, state.perPage, state.notificationList, state.startRecord, state.currentPage, state.listResponse, state.token])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.listResponse && state.listResponse.next_token) {
            setState(prevState => ({ ...prevState, showLoading: true, token: 'next', callNotificaions: true }))
        } else if(action === 'previous' && currentPage === 1 && state.listResponse && state.listResponse.previous_token) {
            setState(prevState => ({ ...prevState, showLoading: true, token: 'previous', callNotificaions: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }
    
    return (
        <div className={`m-2`}>
            <div className={`w-full rounded-md bg-white p-3`}>
                <div className='flex justify-between'>
                    <p className='flex mr-2 w-2/3'>
                        <span className='text-lightGray text-xs m-0 self-center pt-1'>Showing {state.filteredNotList && state.filteredNotList.length} of total {state.notificationList && state.notificationList.length} notification(s)</span>
                    </p>
                    <div className='w-1/3 mb-n2'>
                        {/* <div className='flex justify-end'>
                            {state.notificationList && state.notificationList.length ?
                                <Search
                                    data={state.notificationList ? state.notificationList : []}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'bg-transparent text-black border border-mediumDarkGray rounded-md'}
                                    searchClassName={'px-2'}
                                    searchIconColor={'text-lightGray'}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent text-black'}
                                    widthHeight={22}
                                    filteredData={(filteredNotList) => {
                                        let totalPages = 1
                                        setState(prevState => ({ ...prevState, filteredNotList, totalPages, currentPage: 1, startRecord: 0 }))
                                    }}
                                />
                            : null}
                        </div> */}
                        {(state.listResponse && state.listResponse.total) > (state.filteredNotList && state.filteredNotList.length) ?
                            <div className='flex justify-end text-black text-xs mt-2'>
                                <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></Icon></button> 
                                <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? (state.listResponse && state.listResponse.previous_token ? 'text-info' : 'text-ligthGray') : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></Icon></button>
                                <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? (state.listResponse && state.listResponse.next_token ? 'text-info' : 'pointer-events-none text-ligthGray') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></Icon></button>
                                <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-ligthGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></Icon></button>
                            </div>
                        : null}
                    </div>
                </div>

                <div className='flex overflow-auto h-screen'>
                    <div className={`w-full`}>
                        <div className={`py-3`} >
                            {state.showLoading ? 
                                <div className='flex justify-center m-4'>
                                    <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="white" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="white" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                </div>
                            : 
                                state.filteredNotList && state.filteredNotList.length ?
                                    state.filteredNotList.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                        return(
                                            <div className={`flex mr-2 px-2 mb-2 pb-1 ${(state.startRecord + state.perPage !== state.startRecord) && state.filteredNotList.length !== i+1 ? 'border-b border-mediumDarkGray' : ''} ${state.selectedAlertId !== item.group_id ? 'cursor-pointer' : ' text-black pt-1'}`} key={'key_'+i}>
                                                <div className='flex'>
                                                    <p className='mb-0'>
                                                        <span className='mb-0 mr-2 text-black text-xs font-bold'>{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ''} </span>
                                                        <span className='mb-0 mr-2 text-xs text-black font-bold'>{item.message}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                :
                                    <div className='flex justify-center m-4 text-black'>
                                        <p>There are no data to show.</p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationsTab