/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 
 * @exports
 * @file AlertConfirmation.js
 * @author Prakash
 *************************************************/
import React, { useState } from 'react';
import Button from './Form/Button';

const AlertConfirmation = (props) => {
    const [state, setState] = useState({
        alertModal: true
    })

    return (
        <div id="popup-modal" tabIndex="-1" className={`flex ${state.alertModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow-lg border border-NeutralGray-400 dark:bg-gray-700">
                    <button type="button" className="absolute top-3 end-2.5 text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => {
                        setState(prevstate => ({ ...prevstate, alertModal: false, showAlertLoading: false, deleteUserDetails: {} }))
                        props.confirmation()
                    }}>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-black w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-black">{props.confirmDetails && props.confirmDetails.message ? props.confirmDetails.message : 'Please confirm the action'} ?</h3>

                        <div className='flex mt-4 justify-center'>
                            <Button
                                classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer'}}
                                label={'No, Cancel'}
                                callback={() => props.confirmation()}
                            />
                            <Button
                                classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer'}}
                                label={props.confirmDetails && props.confirmDetails.buttonLabel ? props.confirmDetails.buttonLabel : 'Confirm'}
                                callback={() => {
                                    setState(prevState => ({ ...prevState, showLoading: true }))
                                    props.confirmation('confirmed')
                                }}
                                loading={state.showLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertConfirmation