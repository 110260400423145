import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import MultiSelectSection from '../../../common/MultiSelectSection';
import {Icon} from "@iconify/react";
import Textbox from '../../../common/Form/Textbox';
import SelectOption from '../../../common/Form/SelectOption';
import Button from '../../../common/Form/Button';

const AuthenticationSection = (props) => {
    const [state, setState] = useState({});
	const createUpdateCluster = useSelector(
		(state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
	);
	const propsClusterFilters = useSelector(
		(state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
	);

	const dispatch = useDispatch(); // Create a dispatch function

	const addNewkeyValue = () => {
		let selectedKeys = state.selected_keys ? state.selected_keys : {};

		if (state.key && state.key !== "" && state.value && state.value.length) {
			selectedKeys[state.key] = state.value;
		}

		setState((prevState) => ({
			...prevState,
			selected_keys: selectedKeys,
			key: "",
			value: "",
		}));

		setTimeout(() => {
			handleDirectoryInput("", selectedKeys, "additional_sssd_configs");
		}, 500);
	};

	const removeKeyValue = (key) => {
		let filteredResult =
			state.selected_keys && state.selected_keys.filter((e) => e !== key);
		setState((prevState) => ({ ...prevState, selected_keys: filteredResult }));
	};

	const handleDirectoryInput = (label, value, field) => {
		let obj = createUpdateCluster ? createUpdateCluster : {};
		let directoryConfiguration = obj.directory_configuration
			? obj.directory_configuration
			: {};
		if (field === "additional_sssd_configs") {
			// let additionalSSdConfigs = directoryConfiguration.additional_sssd_configs ? directoryConfiguration.additional_sssd_configs : []
			// additionalSSdConfigs[label] = value
			directoryConfiguration["additional_sssd_configs"] = value;
		} else {
			// if(label !== 'password_secret_arn') {
			directoryConfiguration[label] = value;
			// }
		}

		obj["directory_configuration"] = directoryConfiguration;
		dispatch(setHpcPropsDetails("createUpdateCluster", obj));

		setState((prevState) => ({ ...prevState, [label]: value }));
	};

	const handleChildClick = (event, type, dropdownType, section) => {
		event.stopPropagation();

		let clickedChild = [];
		if (type === "child") {
			if (state.clickedChild && state.clickedChild.length) {
				state.clickedChild.forEach((row) => {
					setState((prevState) => ({ ...prevState, [row]: false }));
				});
			}
			clickedChild.push(section);
			setState((prevState) => ({
				...prevState,
				[section]:
					dropdownType === "singleDropDown" && state[section] ? false : true,
				clickedChild,
			}));
		} else if (type === "parent") {
			if (state.clickedChild && state.clickedChild.length) {
				state.clickedChild.forEach((row) => {
					setState((prevState) => ({ ...prevState, [row]: false }));
				});
			}

			setState((prevState) => ({ ...prevState, clickedChild }));
		}
	};

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='flex flex-wrap'>
                <div className='flex flex-col justify-start lg:w-1/2 w-full lg:pr-4'>
                    <Textbox
                        label={"Domain name"}
                        type="text"
                        selectedValue={createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.domain_name ? createUpdateCluster.directory_configuration.domain_name : ''}
                        callback={(value) => {
                            handleDirectoryInput('domain_name', value)
                        }}
                        placeholder={'Enter name'}
                    />
                    <p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.directory_configuration?.domain_name}</p>
                </div>
                <div className='flex flex-col justify-start lg:w-1/2 w-full lg:pl-4'>
                    <Textbox
                        label={"Domain address"}
                        type="text"
                        selectedValue={createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.domain_address ? createUpdateCluster.directory_configuration.domain_address : ''}
                        callback={(value) => {
                            handleDirectoryInput('domain_address', value)
                        }}
                        placeholder={'Enter address'}
                    />
                    <p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.directory_configuration?.domain_address}</p>
                </div>
            </div>

            <div className='flex flex-wrap pt-8'>
                <div className='flex flex-col justify-start lg:w-1/2 w-full lg:pr-4'>
                    <Textbox
                        label={"Password secret amazon resource name (ARN)"}
                        type="text"
                        selectedValue={createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.password_secret_arn ? createUpdateCluster.directory_configuration.password_secret_arn : ''}
                        callback={(value) => {
                            handleDirectoryInput('password_secret_arn', value)
                        }}
                        placeholder={'Enter secret arn'}
                    />
                    <p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.directory_configuration?.password_secret_arn}</p>
                </div>
                <div className='flex flex-col justify-start lg:w-1/2 w-full lg:pl-4'>
                    <Textbox
                        label={"Domain read only user"}
                        type="text"
                        selectedValue={createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.domain_read_only_user ? createUpdateCluster.directory_configuration.domain_read_only_user : ''}
                        callback={(value) => {
                            handleDirectoryInput('domain_read_only_user', value)
                        }}
                        placeholder={'Enter user'}
                    />
                    <p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.directory_configuration?.domain_read_only_user}</p>
                </div>
            </div>

			<div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
            <div className='flex flex-col justify-between'>
                <div className='flex flex-wrap'>
                    <div className='lg:pr-4 lg:w-2/5 w-full'>
                        <Textbox
                            label={"Additional SSSD options"}
                            type="text"
                            selectedValue={state.key ? state.key : ''}
                            callback={(value) => {
                                setState(prevState => ({ ...prevState, key: value}))
                            }}
                            placeholder={'Key'}
                        />
                    </div>
                    <div className='lg:pl-4 pt-4 mt-6 lg:pt-0 lg:w-2/5 w-full'>
                        <Textbox
                            label={""}
                            type="text"
                            selectedValue={state.value ? state.value : ''}
                            callback={(value) => {
                                setState(prevState => ({ ...prevState, value: value }))
                            }}
                            placeholder={'Value'}
                        />
                    </div>
                    <div className='flex justify-end pt-4 pl-4'>
                        <Button
                            classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center min-w-24'}}
                            label={'+ Add'}
                            callback={() => {
                                addNewkeyValue()
                            }}
                        />
                    </div>
                </div>
                {/* <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-4 mt-8 cursor-pointer' onClick={() => addNewTagValue()}/> */}

                {createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.additional_sssd_configs && Object.entries(createUpdateCluster.directory_configuration.additional_sssd_configs).length ? 
                    <div className='flex flex-wrap mt-4'>
                        {Object.entries(createUpdateCluster.directory_configuration.additional_sssd_configs).map(([key, value], tIndex) => {
                            return(
                                <span className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all' key={'row_'+tIndex}>
                                    <div className='truncate'  style={{maxWidth: '250px'}}>
                                        {key +' : '+value}
                                    </div>
                                    {props.pageMode !== 'Edit' ?
                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeKeyValue(key, value) }/>
                                    : null}
                                </span>
                            )
                        })}
                    </div>
                : null}
            </div>
			</div>

			<div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
            <div className='flex flex-wrap'>
                <div className='flex flex-col justify-start w-1/2 pr-4'>
                    <SelectOption
                        label={"Request certificate"}
                        // fields={["value", "key"]}
                        options={propsClusterFilters && propsClusterFilters.ldap_tls_request_certificate ? propsClusterFilters.ldap_tls_request_certificate : []}
                        selectedValues={createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.ldap_tls_request_certificate ? createUpdateCluster.directory_configuration.ldap_tls_request_certificate : ''}
                        callbackMultiSelect={(value) => {
                            setState(prevState => ({ ...prevState, ldap_tls_request_certificate: value }))
                            handleDirectoryInput('ldap_tls_request_certificate', value)
                        }}
                        singleSelection={true}
                        manditory={true}
                        hasError={state.inputValidationError}
                        dropdownWidth={'w-full'}
                        disabled={state.pageMode === 'Edit'}
                    />
                    {/* <p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.cluster_configuration?.os}</p> */}
                </div>
            </div>
			</div>
        </div>
    )
}


export default AuthenticationSection