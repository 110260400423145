/*************************************************
 * Collider
 * @exports
 * @file ClusterDetailsSidePanel.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {Icon} from "@iconify/react";

import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase } from '../../../utils/utility';
import Events from './Events';
import PageLoading from '../../common/PageLoading';

const ClusterDetailsSidePanel = ({ selectedClusterDetails, closeSidePanel, detailsFormat }) => {
    const clickOut = useRef()
    // const accounts = useSelector(state => state.filters.accounts);
    // const regions = useSelector(state => state.filters.regions);

    const [state, setState] = useState({
        showLoading: false,
        selectedTab: 'Details',
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const getStatusColor = (status) => {
        let color = 'border border-darkPurple-600 text-darkPurple-600'
        if(status.toLowerCase() === 'cancelled') { color = 'border border-independenceGray-600 text-independenceGray-600' } else 
        if(status.toLowerCase() === 'completed') { color = 'border border-slateBlue-600 text-slateBlue-600' } else 
        if(status.toLowerCase() === 'failed') { color = 'border border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'running') { color = 'border border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'border border-blue-600 text-blue-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'border border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'timeout') { color = 'border border-yellowOrange-600 text-yellowOrange-600' }

        return color;
    }

    const getStatusActiveColor = (status) => {        
        let color = 'border border-ferrariRed-600 text-ferrariRed-600'
        if(status) {
            if(status.toLowerCase() === 'active') { color = 'border border-green-600 text-green-600' } 
        }

        return color;
    }

    const handleChildClick = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
    }

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-3/5 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-white'>Cluster Details</span>
                                </div>
                            </div>

                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-white cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                <div className={`mx-4 pt-4`}>
                    <ul className="flex flex-wrap text-sm text-center">
                        <li className="me-2">
                            <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Details' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                        </li>
                        <li className="me-2">
                            <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Events' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Events', showEventsLoading: true }))}>Events</span>
                        </li>
                    </ul>

                    <div className="overflow-y-auto h-[calc(100vh-200px)] border border-NeutralGray-500 bg-PrimaryTintsPurple-600 rounded-r-2xl rounded-b-2xl">
                        {state.selectedTab === 'Details' ?
                          <div className="p-6">
                            {detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                                return(
                                    <div key={'det_'+index} className={`border-b border-NeutralGray-600 pb-[40px] text-lg ${!index && index === 0 ? '' : 'pt-[40px]'}`}>
                                        <p className={`text-primaryPurple-600 text-[22px] font-bold capitalize`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                        <small className='text-NeutralGray-700'>{row.description}</small>
                                        {row.fields && row.fields.map((fld, fIndex) => {
                                            return(
                                                <React.Fragment key={'fld_'+index+'_'+fIndex}>
                                                    {fld === "queue_configuration" ?
                                                        selectedClusterDetails[fld] && selectedClusterDetails[fld].length && selectedClusterDetails[fld].map((item, iIndex) => {
                                                            return(
                                                                <div key={'fItem_'+index+'_'+fIndex+'_'+iIndex} className={`rounded-2xl bg-NeutralGray-200 p-3 mb-2 ${!iIndex ? 'mt-2' : ''}`}>
                                                                    <div className="flex pt-4">
                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Subnet IDs</span>
                                                                        <div className="flex flex-wrap gap-2">
                                                                            {item && item.subnet_ids ?
                                                                                item.subnet_ids.map((ids, idsIndex) => {
                                                                                    return(
                                                                                        <span key={'idsIndex_'+idsIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{ids}</span>
                                                                                    )
                                                                                })
                                                                            : null}
                                                                        </div>
                                                                    </div>
                                                                    {item && item.security_group_ids ?
                                                                        <div className="flex pt-4">
                                                                            <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Security Group IDs</span>
                                                                            <div className="flex flex-wrap gap-2">
                                                                                {item.security_group_ids.map((ids, grpIndes) => {
                                                                                    return(
                                                                                        <span key={'grpIndes_'+grpIndes} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{ids}</span>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                    <div className="flex pt-4">
                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Policies</span>
                                                                        <div className="flex flex-wrap gap-2">
                                                                            {item && item.policies ?
                                                                                item.policies.map((pol, polIndex) => {
                                                                                    return(
                                                                                        <span key={'polIndex_'+polIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{pol}</span>
                                                                                    )
                                                                                })
                                                                            : null}
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex pt-4">
                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Script Location</span>
                                                                        <span className={`self-center text-black break-all`}>{item && item.on_node_configured_script ? item.on_node_configured_script : ""}</span>
                                                                    </div>

                                                                    <p className={`mb-1 mt-2 text-slateBlue-600 pb-1 border-b border-DeepPurpleTints-600 pt-4`}>Volume Details</p>
                                                                    <div className="flex pt-4">
                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Type</span>
                                                                        <span className={`self-center text-black`}>{item && item.volume_type ? item.volume_type : ""}</span>
                                                                    </div>
                                                                    <div className="flex pt-4">
                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Size</span>
                                                                        <span className={`self-center text-black`}>{item && item.volume_size && item.volume_size}</span>
                                                                    </div>
                                                                    <div className="flex pt-4">
                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Encryption</span>
                                                                        <span className={`self-center text-black`}>{item && item.volume_encryption ? "True" : "False"}</span>
                                                                    </div>

                                                                    <p className={`mb-1 mt-2 text-slateBlue-600 pb-1 border-b border-DeepPurpleTints-600 pt-4`}>S3 Access</p>
                                                                    <div className="flex pt-4 text-base">
                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Bucket</span>
                                                                        <span className={`self-center text-black break-all`}>{item && item.bucket_name ? item.bucket_name : ""}</span>
                                                                    </div>
                                                                    <div className="flex pt-4">
                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Write Access</span>
                                                                        <span className={`self-center text-black break-all`}>{item && item.enable_s3_write_access ? "True" : "False"}</span>
                                                                    </div>

                                                                    <p className={`mb-1 mt-2 text-slateBlue-600 pb-1 border-b border-DeepPurpleTints-600 pt-4`}>Compute Resources</p>
                                                                    {item && item.compute_resources ?
                                                                        item.compute_resources.map((comp, compIndex) => {
                                                                            return(
                                                                                <div key={'comp_'+compIndex} className='bg-paleLavender-600 p-2 rounded mb-2'>
                                                                                    <div className="flex pt-4">
                                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Name</span>
                                                                                        <span className={`self-center text-black`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                                                                    </div>
                                                                                    <div className="flex pt-4">
                                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Instance Type</span>
                                                                                        <span className={`self-center text-black`}>{comp && comp.instance_type ? comp.instance_type : ""}</span>
                                                                                    </div>
                                                                                    <div className="flex pt-4">
                                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Min Instance Count</span>
                                                                                        <span className={`self-center text-black`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                                                                    </div>
                                                                                    <div className="flex pt-4">
                                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Max Instance Count</span>
                                                                                        <span className={`self-center text-black`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                                                                    </div>
                                                                                    <div className="flex pt-4">
                                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Efa Enabled</span>
                                                                                        <span className={`self-center text-black`}>{comp && comp.ena_enabled ? "True" : "False"}</span>
                                                                                    </div>
                                                                                    <div className="flex pt-4">
                                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Placement Group Enabled</span>
                                                                                        <span className={`self-center text-black`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                                                                    </div>
                                                                                    <div className="flex pt-4">
                                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">Disable Simultaneous Multithreading</span>
                                                                                        <span className={`self-center text-black`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    : null}
                                                                </div>
                                                            )
                                                        })
                                                    : fld === "login_node" ?
                                                        selectedClusterDetails[fld] &&selectedClusterDetails[fld].length && selectedClusterDetails[fld].map((login_node, strIndex) => {
                                                            return (
                                                                <React.Fragment key={'login_node_'+strIndex}>
                                                                {Object.entries(login_node).length ?
                                                                    Object.entries(login_node).map(([key, value], soIndex) => {
                                                                        return(
                                                                            <div key={'str_'+index+'_'+fIndex+'_'+strIndex+'_'+soIndex} className="flex pt-4">
                                                                                <span className="min-w-[350px] text-NeutralGray-600 self-center">{removeUnderScore(key)}</span>
                                                                                {typeof value === 'boolean' ?
                                                                                    <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                                : isValidDateTime(value) ?
                                                                                    <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                                : convertToLowerCase(key) === "status" ?
                                                                                    <span className={`px-2 mb-2 ${getStatusColor(value)} rounded-full mr-2 py-1 break-all`}>{value}</span>
                                                                                : convertToLowerCase(key) === "tags" ?
                                                                                    <div className="flex flex-wrap gap-2">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span key={'tagV_'+index+'_'+fIndex+'_'+strIndex+'_'+soIndex+'_'+item.key} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item.key +' : '+item.value}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : Array.isArray(value) ?
                                                                                    <div className="flex flex-wrap gap-2">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span key={'tagV_'+index+'_'+fIndex+'_'+strIndex+'_'+soIndex+'_'+item} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : 
                                                                                    <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                : null}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    : fld === "login_node_details" ?
                                                        selectedClusterDetails[fld] && Object.entries(selectedClusterDetails[fld]).length ?
                                                            Object.entries(selectedClusterDetails[fld]).map(([key, value], stoIndex) => {
                                                                return(
                                                                    <div key={'stoIndex_'+stoIndex} className="flex pt-5">
                                                                        <span className="min-w-[222px] max-w-[222px] text-wrap max-w-[222px] text-wrap mr-3 text-NeutralGray-600 self-center">{removeUnderScore(key)}:</span>
                                                                        {typeof value === 'boolean' ?
                                                                            <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                        : isValidDateTime(value) ?
                                                                            <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                        : convertToLowerCase(key) === "status" ?
                                                                            <span className={`self-center ${getStatusActiveColor(value)} rounded-full px-2`}>{value}</span>
                                                                        : Array.isArray(value) ?
                                                                            <div className="flex flex-wrap gap-2 text-base">
                                                                                {value.map((item, vIndex) => {
                                                                                    return(
                                                                                        <span key={'vIndex_'+vIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item}</span>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        : (typeof value === 'string' || typeof value === 'number') ?
                                                                            <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                        : null}
                                                                    </div>
                                                                )
                                                            })
                                                        : null
                                                    : fld === "storage_configuration" ?
                                                        selectedClusterDetails[fld] && selectedClusterDetails[fld].map((storage, strIndex) => {
                                                            return (
                                                                <React.Fragment key={'storage_'+strIndex}>
                                                                {Object.entries(storage).length ?
                                                                    Object.entries(storage).map(([key, value], soIndex) => {
                                                                        return(
                                                                            <div key={'str_'+index+'_'+fIndex+'_'+strIndex+'_'+soIndex} className="flex pt-4">
                                                                                <span className="min-w-[350px] text-NeutralGray-600 self-center">{removeUnderScore(key)}</span>
                                                                                {typeof value === 'boolean' ?
                                                                                    <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                                : isValidDateTime(value) ?
                                                                                    <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                                : convertToLowerCase(key) === "status" ?
                                                                                    <span className={`px-2 mb-2 ${getStatusColor(value)} rounded-full mr-2 py-1 break-all`}>{value}</span>
                                                                                : convertToLowerCase(key) === "tags" ?
                                                                                    <div className="flex flex-wrap gap-2">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span key={'tagV_'+index+'_'+fIndex+'_'+strIndex+'_'+soIndex+'_'+item.key} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item.key +' : '+item.value}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : Array.isArray(value) ?
                                                                                    <div className="flex flex-wrap gap-2">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span key={'tagV_'+index+'_'+fIndex+'_'+strIndex+'_'+soIndex+'_'+item} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : 
                                                                                    <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                : null}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    : fld === "head_node" || fld === "cluster_configuration" ?
                                                        selectedClusterDetails[fld] && Object.entries(selectedClusterDetails[fld]).length ?
                                                            Object.entries(selectedClusterDetails[fld]).map(([key, value], clIndex) => {
                                                                return(
                                                                    key === 's3_access' && Array.isArray(value) ?
                                                                    <div className="flex pt-4">
                                                                        <span className="min-w-[330px] mr-3 text-NeutralGray-600 self-center">{removeUnderScore(key)}</span>
                                                                        <div className='w-full mr-2'>
                                                                            <div className="flex flex-wrap">
                                                                                {value.map((item, itIndex) => {
                                                                                    return(
                                                                                        typeof item === 'string' ?
                                                                                            <span key={"tak_key"+item} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item}</span>
                                                                                        : typeof item === 'object' ?
                                                                                            <div className='p-3 bg-paleLavender-600 rounded-md w-full mr-2 mt-2 text-base'>
                                                                                                {Object.entries(item).map(([iKey, iValue], iiIndex) => {
                                                                                                    return(
                                                                                                        typeof iValue === 'boolean'?
                                                                                                            <p key={itIndex+'_key_'+iiIndex} className='mr-2 mb-1 mt-3'>{removeUnderScore(iKey)}: <span className='text-black ml-3'>{iValue ? 'True' : 'False'}</span></p>
                                                                                                        :
                                                                                                            <p key={itIndex+'_key_'+iiIndex} className='mr-2 mb-1'>{removeUnderScore(iKey)}: <span className='text-black ml-3'>{iValue}</span></p>
                                                                                                    )
                                                                                                })}
                                                                                            </div>
                                                                                        : null
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                        <div className="flex pt-4">
                                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">{removeUnderScore(key)}</span>
                                                                            {typeof value === 'boolean' ?
                                                                                <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                            : isValidDateTime(value) ?
                                                                                <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                            : convertToLowerCase(key) === "status" ?
                                                                                <span className={`px-2 mb-2 ${getStatusColor(value)} rounded-full mr-2 py-1 break-all`}>{value}</span>
                                                                            : convertToLowerCase(key) === "tags" ?
                                                                                <div className="flex flex-wrap gap-2">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span key={'cls_tag_'+index+'_'+fIndex+'_'+clIndex+'_'+item.key} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item.key +' : '+item.value}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : Array.isArray(value) ?
                                                                                <div className="flex flex-wrap gap-2">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span key={'cls_str_'+index+'_'+fIndex+'_'+clIndex+'_'+item} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : 
                                                                                <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                            }
                                                                        </div>
                                                                )
                                                            })
                                                        : null
                                                    : fld === "database_configuration" ?
                                                        selectedClusterDetails[fld] && Array.isArray(selectedClusterDetails[fld]) ? 
                                                        selectedClusterDetails[fld].map((dat, datIndex) => {
                                                            return(
                                                                <React.Fragment key={'datt_'+index+'_'+fIndex+'_'+datIndex}>
                                                                {Object.entries(dat).length ?
                                                                    Object.entries(dat).map(([key, value], dcIndex) => {
                                                                        return(
                                                                            <div key={'dc_'+index+'_'+fIndex+'_'+datIndex+'_'+dcIndex} className="flex pt-4">
                                                                                <span className="min-w-[350px] text-NeutralGray-600 self-center">{removeUnderScore(key)}</span>
                                                                                {typeof value === 'boolean' ?
                                                                                    <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                                : isValidDateTime(value) ?
                                                                                    <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                                : convertToLowerCase(key) === "status" ?
                                                                                    <span className={`px-2 mb-2 ${getStatusColor(value)} rounded-full mr-2 py-1 break-all`}>{value}</span>
                                                                                : convertToLowerCase(key) === "tags" ?
                                                                                    <div className="flex flex-wrap gap-2">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span key={'dc_'+index+'_'+fIndex+'_'+datIndex+'_'+dcIndex+'_'+item.key} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item.key +' : '+item.value}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : Array.isArray(value) ?
                                                                                    <div className="flex flex-wrap gap-2">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span key={'dc_'+index+'_'+fIndex+'_'+datIndex+'_'+dcIndex+'_'+item} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : 
                                                                                    <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                : null}
                                                                </React.Fragment>
                                                            )
                                                            })
                                                        : 
                                                            selectedClusterDetails[fld] && Object.entries(selectedClusterDetails[fld]).length ?
                                                                Object.entries(selectedClusterDetails[fld]).map(([key, value], cdIndex) => {
                                                                    return(
                                                                        <div key={'cd_'+index+'_'+fIndex+'_'+cdIndex} className="flex pt-4">
                                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">{removeUnderScore(key)}</span>
                                                                            {typeof value === 'boolean' ?
                                                                                <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                            : isValidDateTime(value) ?
                                                                                <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                            : convertToLowerCase(key) === "status" ?
                                                                                <span className={`px-2 mb-2 ${getStatusColor(value)} rounded-full mr-2 py-1 break-all`}>{value}</span>
                                                                            : convertToLowerCase(key) === "tags" ?
                                                                                <div className="flex flex-wrap gap-2">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span key={'cd_'+index+'_'+fIndex+'_'+cdIndex+'_'+item.key} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item.key +' : '+item.value}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : Array.isArray(value) ?
                                                                                <div className="flex flex-wrap gap-2">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span key={'cd_'+index+'_'+fIndex+'_'+cdIndex+'_'+item} className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : 
                                                                                <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            : null
                                                    : selectedClusterDetails && selectedClusterDetails[fld] && fld !== 'head_node' && fld !== "cluster_configuration" && fld !== "database_configuration" && fld !== "queue_configuration" && fld !== "storage_configuration" ?
                                                        <div className="flex pt-4">
                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">{removeUnderScore(fld)}</span>
                                                            {typeof selectedClusterDetails[fld] === 'boolean' ?
                                                                <span className={`text-black break-all`}>{selectedClusterDetails[fld] ? 'True' : 'False'}</span>
                                                            : isValidDateTime(selectedClusterDetails[fld]) ?
                                                                <span className="text-black break-all">{momentConvertionUtcToLocalTime(selectedClusterDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                            : convertToLowerCase(fld) === "status" ?
                                                                <span className={`px-2 mb-2 ${getStatusColor(selectedClusterDetails[fld])} rounded-full mr-2 py-1 break-all`}>{selectedClusterDetails[fld]}</span>
                                                            : convertToLowerCase(fld) === "tags" ?
                                                                <div className="flex flex-wrap">
                                                                    {selectedClusterDetails[fld].map(item => {
                                                                        return(
                                                                            <span className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all' key={item.key}>{item.key +' : '+item.value}</span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : (typeof selectedClusterDetails[fld] === 'string' || typeof selectedClusterDetails[fld] === 'number') ?
                                                                <span className="text-black break-all">{fld === 'provider' ? selectedClusterDetails[fld].toUpperCase() : selectedClusterDetails[fld]}</span>
                                                            : null}
                                                        </div>
                                                    : null}
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                            </div>
                        : state.selectedTab === 'Events' ?
                            <Events 
                                selectedClusterDetails={selectedClusterDetails}
                            />
                        : null}
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ClusterDetailsSidePanel