/*************************************************
 * Collider
 * @exports
 * @file JobStatusAction.js
 * @author Prakash // on 28/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
//import Cookies from 'js-cookie';

import ApiCalls from '../../components/ApiCalls'

// import refreshToken from '../../components/ApiCalls'

import {
    GET_JOB_STATUS_APIS,
	LIST_JOB_DETAILS,
	GET_BATCH_JOB_SUMMARY,
	GET_JOB_METRICS,
	GET_JOB_LOGS,
	START_SYSTEM_JOB_LOGS,
	GET_SYSTEM_JOB_LOGS,
	DOWNLOAD_JOB_LOGS,
	LIST_DOWNLOAD_RESULTS,
	DELETE_DOWNLOAD_RESULTS,
	GET_JOB_TASK_VALUES,	

	
	GET_JOB_FILTERS,
	GET_JOB_BUDGET,
	SUBMIT_JOB,
	CANCEL_JOB,
} from '../../config'
import { refreshPage } from '../../utils/utility';
// import { myLog, momentTime, momentDate, convertBytes, convertSeconds, convertBits, countFormater } from '../../utils/utility'
// import { ACTION_TYPES } from '../types'

// import { getToken } from '../../authConfig'

// const token = getToken();

// const getToken = sessionStorage.getItem('msal.account.keys');

// const accessToken = getToken && JSON.parse(getToken) && JSON.parse(getToken).length ? JSON.parse(getToken)[0] : ''
/**
 * Action to get-job-status-apis
 * @param {Object} body
 * @param {Function} callback
 */

export const getJobStatusApis = (params) => {
	return async () => {
		try {
			const url = GET_JOB_STATUS_APIS;
			const response = await ApiCalls.post(url, params)

			// const url = GET_JOB_STATUS_APIS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			// const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				// //localStorage.setItem('ACCESS_TOKEN', '');
				//localStorage.setItem('ACCESS_TOKEN', '');
				// //window.location.href = '/signin';
				// await refreshToken()

			}
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to list-job-details
 * @param {Object} body
 * @param {Function} callback
 */
export const listJobDetails = (params) => {
	return async () => {
		try {
			const url = LIST_JOB_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to get-batch-job-summary
 * @param {Object} body
 * @param {Function} callback
 */
export const getBatchJobSummary = (params) => {
	return async () => {
		try {
			const url = GET_BATCH_JOB_SUMMARY;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to get-job-metrics
 * @param {Object} body
 * @param {Function} callback
 */
export const getJobMetrics = (params) => {
	return async () => {
		try {
			const url = GET_JOB_METRICS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to get-job-logs
 * @param {Object} body
 * @param {Function} callback
 */
export const getJobLogs = (params) => {
	return async () => {
		try {
			const url = GET_JOB_LOGS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to start-system-job-logs
 * @param {Object} body
 * @param {Function} callback
 */
export const sartSystemJobLogs = (params) => {
	return async () => {
		try {
			const url = START_SYSTEM_JOB_LOGS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to get-system-job-logs
 * @param {Object} body
 * @param {Function} callback
 */
export const getSystemJobLogs = (params) => {
	return async () => {
		try {
			const url = GET_SYSTEM_JOB_LOGS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to download-job-logs
 * @param {Object} body
 * @param {Function} callback
 */
export const downloadJobLogs = (params) => {
	return async () => {
		try {
			const url = DOWNLOAD_JOB_LOGS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to list-download-results
 * @param {Object} body
 * @param {Function} callback
 */
export const listDownloadResults = (params) => {
	return async () => {
		try {
			const url = LIST_DOWNLOAD_RESULTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to delete-download-results
 * @param {Object} body
 * @param {Function} callback
 */
export const deleteDownloadResults = (params) => {
	return async () => {
		try {
			const url = DELETE_DOWNLOAD_RESULTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to get-job-task-values
 * @param {Object} body
 * @param {Function} callback
 */
export const getJobTaskValues = (params) => {
	return async () => {
		try {
			const url = GET_JOB_TASK_VALUES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to get-job-filters
 * @param {Object} body
 * @param {Function} callback
 */
export const getJobFilters = (params) => {
	return async () => {
		try {
			const url = GET_JOB_FILTERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to submit-job
 * @param {Object} body
 * @param {Function} callback
 */
export const submitJob = (params) => {
	return async () => {
		try {
			const url = SUBMIT_JOB;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to cancel-job
 * @param {Object} body
 * @param {Function} callback
 */
export const cancelJob = (params) => {
	return async () => {
		try {
			const url = CANCEL_JOB;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to get-job-budget
 * 11/03/24
 * @param {Object} body
 * @param {Function} callback
 */
export const getJobBudget = (params) => {
	return async () => {
		try {

			const url = GET_JOB_BUDGET;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)
			
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				// //localStorage.setItem('ACCESS_TOKEN', '');
				// //window.location.href = '/signin';
			}
			return error && error.response && error.response.data ? error.response.data : {}
		}
	};
}