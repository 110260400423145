// App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppWrapper from './common/AppWrapper';
import { AppRoutes } from '../routes'; // Import your separate route configuration
import { AppConfig } from "../AppConfig";
import ErrorBoundary from './common/ErrorBoundary';
// MSAL imports
// import { MsalProvider } from "@azure/msal-react";
import { AuthenticatedTemplate, useMsal, MsalProvider } from "@azure/msal-react";
import { loginRequest, msalConfig } from '../authConfig'

import { PublicClientApplication } from "@azure/msal-browser";
// import { momentDateFormat } from '../utils/utility';

const msalInstance = new PublicClientApplication(msalConfig);

const basePath = AppConfig.appBasePath;

const WrappedView = () => {
    const { instance } = useMsal()

    const handleRedirect = () => {
        localStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem('ACCESS_TOKEN_EXPIERS_ON');
        instance.loginRedirect({...loginRequest})
            .catch((error) => console.log(error))
    };

    const account = msalInstance.getAllAccounts()[0];
    if(!account) {
        handleRedirect()
    }
    return (
        <React.Fragment>
            <AuthenticatedTemplate>
                <ErrorBoundary>
                <Router basename={basePath}>
                    <AppWrapper>
                        <AppRoutes />
                    </AppWrapper>
                </Router>
                </ErrorBoundary>
            </AuthenticatedTemplate>
        </React.Fragment>
    )
}

function App({ msalInstance }) {  
    return (
        <MsalProvider instance={msalInstance}>
            <WrappedView />
        </MsalProvider>
    );
}
export default App