/*************************************************
 * Collider
 * @exports
 * @file CreateCluster.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { Store as CommonNotification } from "react-notifications-component";
// import { momentConvertionUtcToLocalTime } from '../../../utils/utility'
import {
  listAllProviders,
  listClusterFilters,
  setCommonPropsDetails,
} from "../../../../actions/commonActionNew";
import { setHpcPropsDetails } from "../../../../actions/Collider/HpcAction";
import {
  createCluster,
  updateCluster,
  getClusterFilters,
  createClusterTemplate,
} from "../../../../actions/Collider/ClusterAction";
import ClusterSection from "./ClusterSection";
import HeadNodeSection from "./HeadNodeSection";
import LoginNodeSection from "./LoginNodeSection";
import DatabaseSection from "./DatabaseSection";
import QueueSection from "./QueueSection";
import StorageSection from "./StorageSection";
import ReviewSection from "./ReviewSection"
import { URL_PATH } from "../../../../config/urlPath";
import AuthenticationSection from "./AuthenticationSection";
import MultiSelectSection from "../../../common/MultiSelectSection";
import {
  capitalizeAllLetter,
  getAccountNameFromId,
  getRegionName,
} from "../../../../utils/utility";
import {Icon} from "@iconify/react";
import PageLoading from '../../../common/PageLoading';

const CreateCluster = () => {
  const providers = useSelector((state) => state?.filters?.providers || false);
  const propProjAccounts = useSelector((state) => state?.filters?.commonPropsDetails?.propProjAccounts || false);
  const propProjRegions = useSelector((state) => state?.filters?.commonPropsDetails?.propProjRegions || false);
  const createUpdateCluster = useSelector((state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false);
  const propsClusterFilters = useSelector((state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false);
  const dispatch = useDispatch(); // Create a dispatch function
  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state || false;
  const stepsWithLoginNode = [
    "Cluster",
    "Authentication",
    "Head Node",
    "Login Node",
    "Queues",
    "Database",
    "Storage",
    "Review"
  ]

  const [pageNumber, setPageNumber] = useState(0);
  // let pageNumber = 0
  const [state, setState] = useState({
    steps: [...stepsWithLoginNode],
    selectedSection: "Cluster"
  });

  useEffect(() => {
    if (createUpdateCluster && createUpdateCluster.cluster_version) {
        let parts = createUpdateCluster.cluster_version.split('.');
        let version = parts.length > 2 ? parseInt(parts[1]) : 0; // Extract version number
        const newSteps = version >= 8
          ? [...stepsWithLoginNode] // Include all steps
          : state.steps.filter(step => step !== "Login Node");
        setState(prevState => ({ ...prevState, steps: newSteps }));
    }
  }, [createUpdateCluster]); // Removed 'version' from dependencies

  useEffect(() => {
    if (receivedData && Object.entries(receivedData).length) {
      setState((prevState) => ({
        ...prevState,
        selectedData: receivedData.selectedData
          ? receivedData.selectedData
          : "",
        pageType: receivedData.pageType ? receivedData.pageType : "",
      }));
      if (receivedData.pageType === "Create") {
        setState((prevState) => ({ ...prevState, pageType: "Create" }));
        let obj = {};
        obj.provider = "aws";
        obj.scheduler = "cluster";
        dispatch(setHpcPropsDetails("createUpdateCluster", obj));
      } else if (receivedData.pageType === "Fix"){
        setState((prevState) => ({ ...prevState, pageType: "Create" }));
        dispatch(setHpcPropsDetails("createUpdateCluster", receivedData.selectedData));
      } else {
        setState((prevState) => ({ ...prevState, pageType: receivedData.pageType }));
        dispatch(setHpcPropsDetails("createUpdateCluster", receivedData.selectedData));
      }
    } else {
    }
  }, [receivedData, dispatch]);

  // list all providers
  useEffect(() => {
    if (providers) {
      setState((prevState) => ({
        ...prevState,
        providers: providers,
        selectedProvider:
          providers && providers.length
            ? providers.filter((e) => e.provider_name === "AWS").length
              ? "AWS"
              : providers[0].provider_name
            : "",
      }));
    } else {
      let params = {};
      dispatch(listAllProviders(params));
    }
  }, [dispatch, providers]);

  useEffect(() => {
    if (state.selectedProvider) {
      let params = {};
      params.provider = state.selectedProvider.toLowerCase();
      params.aggregate_by = "account_id";
      let label = state.selectedProvider;

      if (propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
        setState((prevState) => ({
          ...prevState,
          accounts: propProjAccounts[label],
        }));
      } else {
        dispatch(listClusterFilters(params)).then((response) => {
          if (response) {
            if (response.length) {
              let obj = propProjAccounts ? propProjAccounts : {};
              obj[label] = response;
              dispatch(setCommonPropsDetails("propProjAccounts", obj));
              setState((prevState) => ({ ...prevState, accounts: response }));
            }
          }
        });
      }
    }
  }, [state.selectedProvider, propProjAccounts, dispatch]);

  useEffect(() => {
    if (state.selectedProvider) {
      let params = {};
      params.provider = state.selectedProvider.toLowerCase();
      params.aggregate_by = "region";
      let label = state.selectedProvider;
      if (state.selectedAccount && state.selectedAccount.length) {
        label = "_" + state.selectedAccount;
        params.account_id = state.selectedAccount;
      }

      if (propProjRegions && propProjRegions.hasOwnProperty(label)) {
        setState((prevState) => ({
          ...prevState,
          regions: propProjRegions[label],
        }));
      } else {
        dispatch(listClusterFilters(params)).then((response) => {
          if (response && response.length) {
            let obj = propProjRegions ? propProjRegions : {};
            obj[label] = response;
            dispatch(setCommonPropsDetails("propProjRegions", obj));
            setState((prevState) => ({ ...prevState, regions: response }));
          }
        });
      }
    }
  }, [
    state.selectedProvider,
    state.selectedAccount,
    propProjRegions,
    dispatch,
  ]);

  useEffect(() => {
    if (state.selectedProvider) {
      let params = {};
      params.provider = state.selectedProvider.toLowerCase();
      if (state.selectedAccount) {
        params.account_id = state.selectedAccount;
      } if (state.selectedRegion) {
        params.region = state.selectedRegion;
      } if (state.selectedScheduler) {
        params.cluster_version = propsClusterFilters?.cluster_version?.filter(e => e.label === state.selectedScheduler)[0]?.cluster_version;
      }

      if (!propsClusterFilters || !Object.entries(propsClusterFilters).length) {
        dispatch(getClusterFilters(params)).then((response) => {
          if (response) {
            dispatch(setHpcPropsDetails("propsClusterFilters", response));
          }
        });
      }
    }
  }, [state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedScheduler, dispatch, propsClusterFilters]);

  const handleInputChange = (label, value) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    if ( label === "scheduler" && value ) {
      obj.scheduler = value
      obj.cluster_version = propsClusterFilters?.cluster_version?.filter(e => e.label === value)[0]?.cluster_version;
      obj.slurm_version = propsClusterFilters?.cluster_version?.filter(e => e.label === value)[0]?.slurm_version;
    } else {
      obj[label] = value;
    }
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

    setState((prevState) => ({ ...prevState, [label]: value }));
  };

  const onFinish = () => {
    let hasError = false;
    let data = createUpdateCluster;

    if (!data.provider || !data.account_id || !data.region) {
      hasError = true;
    }
    if (!data.cluster_name) {
      hasError = true;
    }
    dispatch(setHpcPropsDetails("createUpdateClusterValidation", hasError));
    // setState(prevState => ({ ...prevState, saveLoading: hasError ? false : true }))
    setState((prevState) => ({
      ...prevState,
      hasError,
      saveLoading: hasError ? false : true,
    }));

    if (!hasError) {
      if (!state.pageType || state.pageType !== "Edit") {
        if (state.pageType === "Template"){
          setState((prevState) => ({
            ...prevState,
            callCreateClusterTemplate: true,
            saveLoading: true,
          }));
        } else {
        setState((prevState) => ({
          ...prevState,
          callCreateCluster: true,
          saveLoading: true,
        }));
      }
      } else {
        setState((prevState) => ({
          ...prevState,
          callUpdateCluster: true,
          saveLoading: true,
        }));
      }
    }
  };

  useEffect(() => {
    if (state.callCreateClusterTemplate) {
      setState((prevState) => ({ ...prevState, callCreateClusterTemplate: false }));
      let params = createUpdateCluster;
      params.template_name = params.cluster_name;
      delete params.cluster_name;
      delete params.scheduler;
      dispatch(createClusterTemplate(params)).then((response) => {
        if (response) {
          let messageType = "danger";
          let message = response.message
            ? response.message
            : "Error in saving cluster";
          if (response.status) {
            messageType = "success";
            message = response.message
              ? response.message
              : "Cluster saved successfully";
            dispatch(setHpcPropsDetails("createUpdateCluster", {}));
            navigate(URL_PATH.CLUSTER_DETAILS, {state : {selectedTab: "Templates"} });
          } else {
            setState((prevState) => ({ ...prevState, saveLoading: false }));
          }
          CommonNotification.addNotification({
            //title: 'Wonderful!',
            message: message,
            type: messageType,
            insert: "top",
            container: "top-center",
            // animationIn: ['animate__animated', 'animate__fadeIn'],
            // animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
            },
          });

          if (response.status) {
            setState((prevState) => ({
              ...prevState,
              disableFinish: false,
              finishBtn: "Save",
              queryLoading: false,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              showLoading: false,
              finishBtn: "Saved",
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            disableFinish: false,
            finishBtn: "Save",
            queryLoading: false,
          }));
        }
      });
    }
  }, [state.callCreateClusterTemplate, createUpdateCluster, dispatch, navigate]);


  useEffect(() => {
    if (state.callCreateCluster) {
      setState((prevState) => ({ ...prevState, callCreateCluster: false }));
      let params = createUpdateCluster;
      delete params.scheduler;
      dispatch(createCluster(params)).then((response) => {
        if (response) {
          let messageType = "danger";
          let message = response.message
            ? response.message
            : "Error in saving cluster";
          if (response.status) {
            messageType = "success";
            message = response.message
              ? response.message
              : "Cluster saved successfully";
            dispatch(setHpcPropsDetails("createUpdateCluster", {}));
            navigate(URL_PATH.CLUSTER_DETAILS);
          } else {
            setState((prevState) => ({ ...prevState, saveLoading: false }));
          }
          CommonNotification.addNotification({
            //title: 'Wonderful!',
            message: message,
            type: messageType,
            insert: "top",
            container: "top-center",
            // animationIn: ['animate__animated', 'animate__fadeIn'],
            // animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
            },
          });

          if (response.status) {
            setState((prevState) => ({
              ...prevState,
              disableFinish: false,
              finishBtn: "Save",
              queryLoading: false,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              showLoading: false,
              finishBtn: "Saved",
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            disableFinish: false,
            finishBtn: "Save",
            queryLoading: false,
          }));
        }
      });
    }
  }, [state.callCreateCluster, createUpdateCluster, dispatch, navigate]);

  useEffect(() => {
    if (state.callUpdateCluster) {
      setState((prevState) => ({ ...prevState, callUpdateCluster: false }));
      let params = createUpdateCluster;
      dispatch(updateCluster(params)).then((response) => {
        if (response) {
          let messageType = "danger";
          let message = response.message
            ? response.message
            : "Error in updating cluster";
          if (response.status) {
            messageType = "success";
            message = response.message
              ? response.message
              : "Cluser updated successfully";
          }
          setState((prevState) => ({ ...prevState, saveLoading: false }));
          CommonNotification.addNotification({
            //title: "Wonderful!",
            message: message,
            type: messageType,
            insert: "top",
            container: "top-center",
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
            },
          });

          if (response.status) {
            setState((prevState) => ({
              ...prevState,
              disableFinish: false,
              finishBtn: "Save",
              queryLoading: false,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              showLoading: false,
              finishBtn: "Saved",
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            disableFinish: false,
            finishBtn: "Save",
            queryLoading: false,
          }));
        }
      });
    }
  }, [state.callUpdateCluster, createUpdateCluster, dispatch]);

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  const handlePreviousPage = () => {
    setPageNumber(pageNumber - 1);
    setState((prevState) => ({
      ...prevState,
      selectedSection: state.steps[pageNumber - 1],
    }));
  };

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
    setState((prevState) => ({
      ...prevState,
      selectedSection: state.steps[pageNumber + 1],
    }));
  };


    return (
        <div className='bg-white' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="bg-NeutralGray-200 px-8 py-10 text-NeutralGray-800 shadow-xl">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center ">
                      <p className="font-bold text-2xl">{state.pageType === 'Edit' ? 'Modify' : 'Create'} Cluster {state.pageType === 'Template' ? 'Template' : ''}</p>
					        </div>
                </div>
            </div>
            <div className='p-2 min-h-screen overflow-auto'>
                <div className='rounded bg-white p-3 mb-2'>
                    <div className={`flex flex-wrap`}> 
                        <div className='py-1 lg:w-1/4 md:w-1/2 w-full lg:pr-3 md:pr-3'>
                            <div className='flex justify-between'>
                                <label className='text-lightGray self-center m-0'>Provider </label>
                                {state.hasError && (createUpdateCluster && !createUpdateCluster.provider) ? 
                                    <small className='text-issues'>required</small>
                                : null}
                            </div>
                            <div className='border bg-transparent rounded-md'
                                onClick={(event) => {
                                    if(!state.isProviderOpen) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                                    }
                                }}
                            >
                                <p className={`flex bg-transparent justify-between mt-2 px-2 mb-2 ${createUpdateCluster && createUpdateCluster.provider ? 'text-black truncate' : 'placeholder'}`}>
                                    {createUpdateCluster && createUpdateCluster.provider ? capitalizeAllLetter(createUpdateCluster.provider) : 'Select'}
                                    <Icon icon="icon-park-solid:down-one" className={`${createUpdateCluster.provider ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                </p>
                                {state.isProviderOpen && state.pageType !== 'Edit' ?
                                    <MultiSelectSection
                                        fields={["provider_name", "provider_name"]}
                                        options={state.providers}
                                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                handleInputChange('provider', value.toLowerCase())
                                                dispatch(setHpcPropsDetails('propsClusterFilters', {}))
                                                setState(prevState => ({ ...prevState, selectedProvider: value, accounts: [], regions: [], selectedAccount: '', selectedRegion: '' }))
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                        hideSearch={false}
                                        topClass={'auto'}
                                        widthClass={'minWidth220'}
                                    />
                                : null}
                            </div>
                        </div>
                        <div className='py-1 lg:w-1/4 md:w-1/2 w-full lg:pr-3 lg:mt-0 md:mt-0 mt-2'>
                            <div className='flex justify-between'>
                                <label className='text-lightGray self-center m-0'>Account </label>
                                {state.hasError && createUpdateCluster && !createUpdateCluster.account_id ?  
                                    <small className='text-issues'>required</small>
                                : null}
                            </div>
                            <div className='border bg-transparent rounded-md'
                                onClick={(event) => {
                                    if(!state.isAccountOpen) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "isAccountOpen")
                                    }
                                }}
                            >
                                <p className={`flex bg-transparent justify-between mt-2 px-2 mb-2 ${createUpdateCluster && createUpdateCluster.account_id ? 'text-black truncate' : 'placeholder'}`}>
                                    {createUpdateCluster && createUpdateCluster.account_id ? getAccountNameFromId(createUpdateCluster.account_id, state.accounts) : 'Select'}
                                    <Icon icon="icon-park-solid:down-one" className={`${createUpdateCluster.account_id? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                </p>
                                {state.isAccountOpen && state.pageType !== 'Edit' ?
                                    <MultiSelectSection
                                        fields={["account_id", "account_name"]}
                                        options={state.accounts}
                                        selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                setState(prevState => ({  ...prevState,  selectedAccount: value, cluster_name: '' }))
                                                handleInputChange('account_id', value)
                                                handleInputChange('region', '')
                                                dispatch(setHpcPropsDetails('propsClusterFilters', {}))
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                        removeTopOptions={true}
                                        hideSearch={false}
                                        topClass={'auto'}
                                        widthClass={'minWidth220'}
                                        // addAllField={true}
                                    />
                                : null}
                            </div>
                        </div>
                        <div className='py-1 lg:w-1/4 md:w-1/2 w-full lg:mt-0 sm:mt-2 lg:pr-3 md:pr-3'>
                            <div className='flex justify-between'>
                                <label className='text-lightGray self-center m-0'>Region </label>
                                {state.hasError && createUpdateCluster && !createUpdateCluster.region ? 
                                    <small className='text-issues hid'>required</small>
                                : null}
                            </div>
                            <div className='border bg-transparent rounded-md'
                                onClick={(event) => {
                                    if(!state.isRegionOpen) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "isRegionOpen")
                                    }
                                }}
                            >
                                <p className={`flex bg-transparent justify-between mt-2 px-2 mb-2 ${createUpdateCluster && createUpdateCluster.region ? 'text-black truncate' : 'placeholder'}`}>
                                    {createUpdateCluster && createUpdateCluster.region ? getRegionName(createUpdateCluster.region, state.regions) : 'Select'}
                                    <Icon icon="icon-park-solid:down-one" className={`${createUpdateCluster.region ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                </p>
                                {state.isRegionOpen && state.pageType !== 'Edit' ?
                                    <MultiSelectSection
                                        fields={["region", "name"]}
                                        options={state.regions}
                                        selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                setState(prevState => ({ ...prevState, selectedRegion: value, cluster_name: '' }))
                                                handleInputChange('region', value)
                                                dispatch(setHpcPropsDetails('propsClusterFilters', {}))
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                        removeTopOptions={true}
                                        hideSearch={false}
                                        topClass={'auto'}
                                        widthClass={'minWidth220'}
                                        // addAllField={true}
                                    />
                                : null}
                            </div>
                        </div>
                        <div className='py-1 lg:w-1/4 md:w-1/2 w-full lg:mt-0 sm:mt-2'>
                            <div className='flex justify-between'>
                                <label className='text-lightGray self-center m-0'>Scheduler</label>
                                {state.hasError && (createUpdateCluster && !createUpdateCluster.scheduler) ? 
                                    <small className='text-issues'>required</small>
                                : null}
                            </div>
                            <div className='border bg-transparent rounded-md'
                                onClick={(event) => {
                                    if(!state.showScheduler) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "showScheduler")
                                    }
                                }}
                            >
                                <p className={`flex bg-transparent justify-between mt-2 px-2 mb-2 ${state.selectedScheduler ? 'text-black truncate' : 'placeholder'}`}>
                                    {state.selectedScheduler ? state.selectedScheduler  : 'Select'}
                                    <Icon icon="icon-park-solid:down-one" className={`${state.selectedScheduler ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                </p>
                                {state.showScheduler && state.pageType !== 'Edit' ?
                                    <MultiSelectSection
                                        fields={["label", "label"]}
                                        options={propsClusterFilters?.cluster_version}
                                        selectedValues={state.selectedScheduler ? state.selectedScheduler : ''}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                handleInputChange('scheduler', value)
                                                setState(prevState => ({ ...prevState, selectedScheduler: value }))
                                                dispatch(setHpcPropsDetails('propsClusterFilters', {}))
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                        removeTopOptions={true}
                                        hideSearch={false}
                                        topClass={'auto'}
                                        widthClass={'minWidth220'}
                                    />
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="bg-GhostWhite p-3 rounded-md">
                    <div className="stepper flex justify-between items-center relative py-4 mx-0 text-sm">
                        {state.steps && state.steps.map((stp, index) => {
                            return(
                                <div className={`step relative flex-1 text-center ${state.selectedSection === stp ? '' : 'cursor-pointer'}`} key={'stp_'+index} onClick={() => setState(prevState => ({ ...prevState, selectedSection: stp}))}>
                                    <div className={`circle w-8 h-8 rounded-full bg-text-black flex items-center justify-center mx-auto mb-2 ${state.selectedSection === stp ? 'bg-PrimaryTintsPurpleTints-80' : 'bg-black cursor-pointer'}`}>{index+1}</div>
                                    <div className="label mt-2 text-lightGray">
                                        Step {index+1}
                                        <p className={`mb-0 ${state.selectedSection === stp ? 'text-info' : 'cursor-pointer'}`}>{stp}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            
                <div className='col-sm-12 py-2'>
                    <div className="card bg-BlockWhite mb-2 hidden">
                        <div className="card-body p-0 w-100">
                            <div className="stepper mx-0">
                                {state.steps && state.steps.map((stp, index) => {
                                    return(
                                        <div key={'stp_'+index} className={`step ${state.selectedSection === stp ? '' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedSection: stp}))}>
                                            <div className={`circle ${state.selectedSection === stp ? 'bg-info' : ''}`}>{index+1}</div>
                                            <div className="label">
                                                <strong>Step {index+1}</strong>
                                                <p className={`mb-0 ${state.selectedSection === stp ? 'text-info' : 'cursor-pointer'}`}>{stp}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    
                    {state.selectedSection === 'Cluster' ? 
                        <ClusterSection 
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                        />
                    : state.selectedSection === 'Authentication' ? 
                        <AuthenticationSection 
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                        />
                    : state.selectedSection === 'Head Node' ? 
                        <HeadNodeSection 
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                        />
                    : state.selectedSection === 'Login Node' ? 
                      <LoginNodeSection 
                          pageMode={state.pageType}
                          selectedData={state.selectedData}
                      />
                    : state.selectedSection === 'Database' ? 
                        <DatabaseSection
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                            provider={state.provider}
                            accounts={state.accounts}
                            regions={state.regions}
                        />
                    : state.selectedSection === 'Queues' ? 
                        <QueueSection 
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                        />
                    : state.selectedSection === 'Storage' ? 
                        <StorageSection
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                            provider={state.provider}
                            accounts={state.accounts}
                            regions={state.regions}
                        />
                    : state.selectedSection === "Review" ? (
                        <ReviewSection
                            closeSidePanel={() => setState(prevState => ({ ...prevState, showClusterDetails: false, selectedData: {} }))}
                            selectedClusterDetails={state.selectedData}
                            detailsFormat={state.selectedData}
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                            provider={state.provider}
                            accounts={state.accounts}
                            regions={state.regions}
                        />
                        ) : null}
                </div>
                <div className="flex justify-end bg-transparent border-0 my-2">
                  {state.pageType === "Edit" ? (
                    <div className="py-1 align-self-center mr-3">
                      <label className="mb-0">
                        <input
                          type="checkbox"
                          className="inputdemo mt-1"
                          checked={state.force_update ? true : false}
                          onChange={() => {
                            let value = !state.force_update;
                            handleInputChange("force_update", value);
                          }}
                        />
                        <span className="slider round small pl-1"></span>
                      </label>
                      <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
                        Force update: Edit the cluster while the compute fleet is still
                        running
                      </span>
                    </div>
                  ) : null}
                  <div className="flex">
                  <p
                      className={
                        pageNumber === 0 ? "hidden" : "bg-PrimaryTintsDeepPurpleTints-600 text-white text-base font-medium rounded-lg px-[18px] py-2.5 inline-flex items-center self-center cursor-pointer ml-2"
                      }
                      onClick={() => handlePreviousPage()}
                    >
                      Previous
                    </p>
                    <p
                      className={
                        pageNumber < state.steps.length - 1
                          ? "bg-PrimaryTintsDeepPurpleTints-600 text-white text-base font-medium rounded-lg px-[18px] py-2.5 inline-flex items-center self-center cursor-pointer ml-2"
                          :  "hidden"
                      }
                      onClick={() => handleNextPage()}
                    >
                      Next
                    </p>
                    {state.pageType !== "View" ? (
                      state.saveLoading ? (
                        <button className={`btn bg-info mr-2`}>
                          <Spinner size="sm" color="light" className="mx-2" />
                          {state.pageType === "Edit" ? "Updating" : "Saving"}
                        </button>
                      ) : (
                        <button
                          className={pageNumber === state.steps.length - 1 ? `bg-green-600 text-white text-base font-medium rounded-lg px-[18px] py-2.5 inline-flex items-center self-center cursor-pointer ml-2` : 'hidden'}
                          onClick={() => onFinish()}
                        >
                          {state.pageType === "Edit" ? "Update" : "Submit"}
                        </button>
                      )
                    ) : null}
                    
                    <p
                      className="bg-ferrariRed-600 text-white text-base font-medium rounded-lg px-[18px] py-2.5 inline-flex items-center self-center cursor-pointer ml-2"
                      onClick={() => {
                        {state.pageType === 'Template'?
                          navigate(URL_PATH.CLUSTER_DETAILS, { state: { selectedTab: 'Templates' } })
                        :
                          navigate(URL_PATH.CLUSTER_DETAILS, { state: { selectedTab: 'Clusters' } });}
                      }}
                    >
                      Cancel
                    </p>
                  </div>
                </div>
            </div>
        </div>
    )
}

export default CreateCluster