import React, { useEffect, useState } from 'react';
import {Icon} from "@iconify/react";

const Textarea = ({ label, callback, className, rows, selectedValue, placeholder, manditory, wordLength, hasError, showRemainigCharacters }) => {
    const [textState, setTextState] = useState('')

    useEffect(() => {
        setTextState(selectedValue)
    }, [selectedValue])

    return (
        <div className={`w-full items-center ${className}`}>
            <div className='flex justify-between'>
                <p className="text-NeutralGray-800 text-base flex w-full">
                    {label}
                    {manditory ?
                        <Icon icon="fe:star" className='text-red-600 ml-1 self-center' width={12} height={12} />
                    : null}
                </p>
                {!textState && manditory && hasError ?
                    <p className='text-sm text-ferrariRed-600'>Required</p>
                : null}
            </div>
            <textarea 
                className={`placeholder:italic placeholder:text-NeutralGray-600 text-base block ${className && className.borderClass ? className.borderClass : "border border-NeutralGray-400 rounded-2xl"} py-2.5 pl-2 shadow-sm w-full`}
                placeholder={placeholder ? placeholder : "Enter details"}
                value={textState ? textState : ''}
                onChange={e => {
                    let value = e.target.value
                    setTextState(value)
                    callback(value)
                }}
                rows={rows ? rows : 4}
                maxLength={wordLength ? wordLength : 2000}
            />
            {showRemainigCharacters ?
                <p className="text-right mt-2 text-sm text-gray-600">
                    {(wordLength ? wordLength : 2000) - (textState && textState.length ? textState.length : 0)}  characters remaining
                </p>
            : null}
        </div>
    );
};

export default Textarea;
