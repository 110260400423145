import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from "../../../../actions/Collider/HpcAction";
import MultiSelectSection from "../../../common/MultiSelectSection";
import ExistingStorageSection from "./ExistingStorageSection";
import NewStorageSection from "./NewStorageSection";
import { Icon } from '@iconify/react'
import RadioOption from "../../../common/Form/RadioOption";
import SelectOption from "../../../common/Form/SelectOption";

const StorageSection = (props) => {
	const [state, setState] = useState({});
	const createUpdateCluster = useSelector(
		(state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
	);
	const propsClusterFilters = useSelector(
		(state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
	);

	const dispatch = useDispatch(); // Create a dispatch function

	const callPageVist = useCallback(() => {
		let storageConfiguration = createUpdateCluster?.storage_configuration || [{
			arrayIndex : 0,
		}];
	
		if (storageConfiguration.length) {
			if (!state.storageOption) {
				setState((prevState) => ({
					...prevState,
					storageOption: Object.keys(storageConfiguration[0]).includes("create") ? "new" : "existing"
				}));
			}
			if (!state.storage_type) {
				setState((prevState) => ({
					...prevState,
					storage_type: storageConfiguration[0].storage_type
				}));
			}
		}
		else{
			if (!state.storageOption) {
				setState((prevState) => ({
					...prevState,
					storageOption: "existing"
				}));
			}
		}
	}, [createUpdateCluster, state.storageOption, state.storage_type]);

	useEffect(() => callPageVist(), [callPageVist]);

	const handleStorageOptionChange = (value) => {
	if (window.confirm("If you change the storage option, the values already entered in the storage fields will be lost. Are you sure you want to proceed?")) {
		setState((prevState) => ({
			...prevState,
			storageOption: value
		}));

		let obj = createUpdateCluster ? createUpdateCluster : {};
		const storage_type = obj.storage_configuration && obj.storage_configuration.length ? obj.storage_configuration[0].storage_type : null;

		if (value === 'new') {
			obj["storage_configuration"] = storage_type ? [{
				storage_type: storage_type,
				create: true
			}] : [];
		} else {
			// Append storage_type to existing configurations
			obj["storage_configuration"] = storage_type ?
				obj.storage_configuration.forEach(config => ({ ...config, storage_type: storage_type}))
				: [];
		}
		dispatch(setHpcPropsDetails("createUpdateCluster", obj));
	}
};

const handleStorageInput = (label, value) => {
	let obj = createUpdateCluster ? createUpdateCluster : {};
	if (label === 'storage_type') {
		obj["storage_configuration"] = [ ];
	}

	dispatch(setHpcPropsDetails("createUpdateCluster", obj));
	setState((prevState) => ({
		...prevState,
		[label]: value,
	}));
};


// const handleStorageInput = (label, value) => {
//	 let obj = createUpdateCluster ? createUpdateCluster : {};
//	 obj["storage_configuration"] = [{
//		 [label]: value,
//	 }];
//	 dispatch(setHpcPropsDetails("createUpdateCluster", obj));
//	 setState((prevState) => ({ ...prevState, [label]: value }));
// };


	const handleChildClick = (event, type, dropdownType, section) => {
		event.stopPropagation();

		let clickedChild = [];
		if (type === "child") {
			if (state.clickedChild && state.clickedChild.length) {
				state.clickedChild.forEach((row) => {
					setState((prevState) => ({ ...prevState, [row]: false }));
				});
			}
			clickedChild.push(section);
			setState((prevState) => ({
				...prevState,
				[section]:
					dropdownType === "singleDropDown" && state[section] ? false : true,
				clickedChild,
			}));
		} else if (type === "parent") {
			if (state.clickedChild && state.clickedChild.length) {
				state.clickedChild.forEach((row) => {
					setState((prevState) => ({ ...prevState, [row]: false }));
				});
			}

			setState((prevState) => ({ ...prevState, clickedChild }));
		}
	};

	return (
		<div onClick={(event) => handleChildClick(event, "parent")}>
			<p className="text-base font-medium text-black self-center w-full">Storage Options</p>
			<div className='flex pt-3'>
				<RadioOption
					label="Use Existing"
					value="existing"
					selected={state.storageOption === "existing"}
					callback={() => handleStorageOptionChange("existing")}
				/>
				<RadioOption
					label="Create New"
					value="new"
					selected={state.storageOption === "new"}
					callback={() => handleStorageOptionChange("new")}
					className="pl-8"
				/>
			</div>

            <div className='flex flex-wrap pt-8'>
                <div className='flex flex-col justify-start w-1/2 pr-4'>
                    <SelectOption
						label={"Storage type"}
                        fields={["value", "key"]}
                    	options={propsClusterFilters && propsClusterFilters.storage_type ? propsClusterFilters.storage_type : []}
                    	selectedValues={state["storage_type"] ? state["storage_type"] : ""}
                    	callbackMultiSelect={(value) => {
							handleStorageInput("storage_type", value);
							setState((prevState) => ({...prevState, storage_type: value}));
                        }}
                        singleSelection={true}
                        dropdownWidth={'w-full'}
                    />
                </div>
			</div>
			{state.storageOption && state["storage_type"] && (
				<div>
					{state.storageOption === "existing" ? (
					<ExistingStorageSection
						pageMode={props.pageMode}
						storageType={state.storage_type}
					/>
					) : (
					<NewStorageSection
						pageMode={props.pageMode}
						storageOption={state.storageOption}
						storageType={state.storage_type}
					/>
					)}
				</div>
			)}
		</div>
	);
};

export default StorageSection;
