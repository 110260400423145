/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertsTab.js
 * @author Prakash // on 05/04/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
// import PropTypes from 'prop-types'
import { listAlerts, getAlertsDailyTrend } from '../../../actions/Collider/ObservabilityAction'
// import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'

import _ from 'lodash'
import { momentDateGivenFormat, momentConvertionUtcToLocalTime, capitalizeFirstLetter, momentConvertionLocalToUtcTime, subDays, getDayFromSelectedDuration, subHours } from '../../../utils/utility'

import Search from '../../common/SearchWithHiddenInput'
import ApexTrendBarChart from '../../common/charts/ApexTrendBarChart'
import MultiSelectSection from '../../common/MultiSelectSection'
import { DateRange } from 'react-date-range'
import {Icon} from "@iconify/react";
import { LoadingCircle } from '../../common/LoadingCiricle'
import SelectOption from '../../common/Form/SelectOption'

const durationOptions = [    
    { value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
    { value: '+14d', option: 'Last 2 weeks' },
    { value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const AlertsTab = (props) => {
    const dateRef = useRef();

    const [state, setState] = useState({
        alertListLoader: true,
        
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        overAllTotalPages: 1,
        perPage: 100,
        
        pageType: "Alerts",
        alertType: "All",
        groupedBy: 'severity',
        selectedCategory: 'Important / All',
        minimizeGraphSection: false,
        selectedGraphDuration: '+3d',
        alertTypes: ['All', 'Application', 'Infra'],
        groupedType: [
            {label: 'Severity', value: 'severity'},
            {label: 'Name', value: 'event_name'}
        ]
    })

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);

    const dispatch = useDispatch()

    

    useEffect(() => {
       	let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedGraphDuration !== 'custom') {
            let period = state.selectedGraphDuration.substring(state.selectedGraphDuration.length, state.selectedGraphDuration.length - 1);
            let durationBtw = getDayFromSelectedDuration(state.selectedGraphDuration);
            
            if (period === 'd') {
                datePickerStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            } else {
                datePickerStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            }
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');

            setState(prevState => ({ 
                ...prevState, 
                datePickerStartDate, 
                datePickerEndDate,
                alertListLoader: true,
                graphData: {},
                callAlertsDatilyTrend: true,
                callAlertGroups: true
            }));

            let dateSelection= {
				startDate: new Date(datePickerStartDate),
				endDate: new Date(datePickerEndDate),
				key: 'selection'
			}

            setDateState([dateSelection])
        }

	}, [state.selectedGraphDuration])

    useEffect(() => {
        if(state.callAlertsDatilyTrend) {
            setState(prevState => ({ ...prevState, callAlertsDatilyTrend: false }))
            let params = {}
            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            let assetId = props.selectedData && props.selectedData.asset_id ? props.selectedData.asset_id : ""
            // params.asset_id = assetId
            if(props.selectedClusterDetails && Object.entries(props.selectedClusterDetails).length) {
                params.provider = props.selectedClusterDetails.provider
                params.account_id = props.selectedClusterDetails.account_id
                params.region = props.selectedClusterDetails.region
                params.cluster_name = props.selectedClusterDetails.cluster_name
            } else {
                params.asset_id = assetId
            }

            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            dispatch(getAlertsDailyTrend(params))
                .then((response) => {
                    if(response && !response.error) {
                        setState(prevState => ({ ...prevState, alertDailyTrendData: response }))   
                    } else {
                        setState(prevState => ({ ...prevState, alertDailyTrendData: {} }))
                    }
                })
                
            setState(prevState => ({ ...prevState, callAlertsTrend: true }))

        }
    }, [state.callAlertsDatilyTrend, dispatch, state.selectedAsset, state.datePickerStartDate, state.datePickerEndDate, props])

    useEffect(() => {
        // let graphData = {
        //     start_time: momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
        //     end_time: momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
        // }
        let graphData = {}
        let trendSeverityCount = state.alertDailyTrendData && state.alertDailyTrendData.total_count ? state.alertDailyTrendData.total_count : {}
        let graphDetails = state.alertDailyTrendData && state.alertDailyTrendData.results ? state.alertDailyTrendData.results : {}           
        if(graphDetails && graphDetails.data && graphDetails.data.count) {
            graphData = {
                data: graphDetails.data.count,
                labels: graphDetails.labels,
                // start_time: momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                // end_time: momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            }
        }

        setState(prevState => ({ ...prevState, graphData, trendSeverityCount }))

    }, [state.alertDailyTrendData,])

    const resetGraph = () => {
        let graphData = state.graphData
        setState(prevState => ({ ...prevState, graphData: {}, zoomedStartDate: "", zoomedEndDate: "", calltoGetCategoryALerts: true }))
        setTimeout(() => {
            setState(prevState => ({ ...prevState, graphData }))            
        }, 2000); 
    }
    
    const navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = state.startRecord
            if(action === 'next' && currentPage !== state.totalPages) {
                startRecord = startRecord + state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = state.totalPages
                startRecord = (state.totalPages - 1) * state.perPage
            }
            setState(prevState => ({ ...prevState, currentPage, startRecord }))
        } 
	}

    // const gotoPage = (pageNumber) => {
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState,  currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState,  pageNumber: state.currentPage })  )
    //     } else {
    //         setState(prevState => ({ ...prevState,  pageNumber }))
    //     }
    // }

    useEffect(() => {
        if(state.callAlertGroups) {
            setState(prevState => ({ ...prevState, callAlertGroups: false }))
            let params = {}
            // params.alerts_case_id = '2408-1028-7602'
            
            if(props.selectedClusterDetails && Object.entries(props.selectedClusterDetails).length) {
                params.provider = props.selectedClusterDetails.provider
                params.account_id = props.selectedClusterDetails.account_id
                params.region = props.selectedClusterDetails.region
                params.cluster_name = props.selectedClusterDetails.cluster_name
            } else {
                params.asset_id = props.selectedData && props.selectedData.asset_id ? props.selectedData.asset_id : ""
            }

            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            // let assetId = state.selectedAsset && state.selectedAsset.asset_id ? state.selectedAsset.asset_id : ""
            // params.asset_id = assetId

            params.aggregate_by = state.groupedBy
            
            if(state.alertType !== "All") {
                params.alert_type = state.alertType
            }   
            
            dispatch(listAlerts(params))
                .then((response) => {
                    if(response && !response.error) {
                        let data = response.results && response.results.details ? response.results.details : []
                        setState(prevState => ({ ...prevState, alertResponse: data, alertListLoader: false }))
                    } else {
                        setState(prevState => ({ ...prevState, alertResponse: [], alertListLoader: false }))
                    }
                })
        }

    }, [state.callAlertGroups, state.alertGroupData, dispatch, props, state.alertType, state.groupedBy, state.selectedAsset, state.datePickerEndDate, state.datePickerStartDate])

    useEffect(() => {
        let listAllAlerts = []
        let totalCount = 0
        let alertsCategory = []
        if(state.alertResponse && state.alertResponse.length) {
            totalCount = state.alertResponse.reduce( function(a, b){ return a + b.count;}, 0);
            state.alertResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array

                alertsCategory.push(dataRow)
                item.alerts.forEach(alt => {
                    listAllAlerts.push(alt)
                })
            })
        }

        let dataRow = {}
        dataRow.count = ""
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        alertsCategory.push(dataRow)

        setState(prevState => ({ ...prevState, listAllAlerts, alertsList: listAllAlerts, alertsCategory: _.orderBy(alertsCategory, ['orderBy'], ['asc']), calltoGetCategoryALerts: true  }))
    }, [state.alertResponse])

    useEffect(() => {
        if(state.calltoGetCategoryALerts) {
            let filterdCategoryAlerts = state.listAllAlerts
            if(state.selectedCategory !== 'Important / All') {
                filterdCategoryAlerts = state.alertResponse.filter(arr => arr.label === state.selectedCategory)
                if(filterdCategoryAlerts && filterdCategoryAlerts.length) {
                    filterdCategoryAlerts = filterdCategoryAlerts[0].alerts ? filterdCategoryAlerts[0].alerts : []
                }
            }
    
            let orderedArray = _.orderBy(filterdCategoryAlerts, ['event_time'], ['desc'])
    
            let totalPages = 1
            if(orderedArray.length > state.perPage) {
                totalPages = Math.ceil(orderedArray.length / state.perPage)
            }
            setState(prevState => ({ ...prevState, totalFilteredArray: orderedArray, filteredArray: orderedArray, selectedAlert: "", selectedAlertId: "", totalPages, startRecord: 0, currentPage: 1, calltoGetCategoryALerts: false, }))
        }
    }, [state.calltoGetCategoryALerts, state.alertResponse, state.listAllAlerts, state.perPage, state.selectedCategory])

    const filterZoomData = (zoomedStartDate, zoomedEndDate) => {
        let data = state.totalFilteredArray ? state.totalFilteredArray : []

        data = data.filter(e => momentConvertionUtcToLocalTime(e.event_time, "YYYY-MM-DD HH:mm:ss") >= zoomedStartDate && momentConvertionUtcToLocalTime(e.event_time, "YYYY-MM-DD HH:mm:ss") <= zoomedEndDate)

        data = _.orderBy(data, ['event_time'], ['desc'])

        let totalPages = 1
		if(data.length > state.perPage) {
			totalPages = Math.ceil(data.length / state.perPage)
		}

        setState(prevState => ({ ...prevState, totalFilteredArray: data, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, zoomedStartDate, zoomedEndDate }))

    }

    const getAlertDetails = (alertItemDetails) => {
        setState(prevState => ({ 
            ...prevState, 
            selectedAlertId: alertItemDetails.event_id === state.selectedAlertId ? "" : alertItemDetails.event_id, 
            selectedAlert: alertItemDetails.event_id === state.selectedAlertId ? "" : alertItemDetails,
            expandAlertDetails: true,
            expandAssetDetails: false
        }))
    }

    const getStatusBgColor = (status) => {
        let color = 'bg-darkPurple-50'
        if (status) {
            if(status.toLowerCase() === 'critical') { color = 'bg-customRed' } else 
            if(status.toLowerCase() === 'high') { color = 'bg-blue-600' } else 
            if(status.toLowerCase() === 'medium') { color = 'bg-primaryPurple-600' }
        }
        return color;
    }
    
    const handleChange = (value) => {
        let groupedBy = state.groupedBy
        if(value === "Application" && groupedBy !== "severity" && groupedBy!== "event_name") {
            groupedBy = "severity"
        }

        let groupedType = [
            {label: 'Severity', value: 'severity'},
            {label: 'Name', value: 'event_name'}
        ]
        if(value !== "Application") {
            groupedType.push({label: 'Category', value: 'category'})
        }
        setState(prevState => ({ ...prevState, alertType: value, groupedType, groupedBy, selectedAlert: "", selectedAlertId: "", alertListLoader: true, callAlertGroups: true }))
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (dateRef.current && !dateRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
                showDateRangePicker: false,
                selectedGraphDuration: "custom",                
                alertListLoader: true,
                graphData: {},
                callAlertsDatilyTrend: true,
                callAlertGroups: true
            }));
    
        }
        setDateState([date.selection])
    };

    return (
        <div className="p-3" onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='flex flex-wrap justify-between text-sm'>
                <p className='mb-1 lg:w-1/2 w-full self-center'>Showing alerts for the period of <span className='font-bold'>{momentConvertionLocalToUtcTime(state.datePickerStartDate, 'MMM DD YYYY HH:mm')} - {momentConvertionLocalToUtcTime(state.datePickerEndDate, 'MMM DD YYYY HH:mm')}</span> (utc time)</p>
                <div className=''>
                    <SelectOption
                        label={""}
                        fields={["value", "option"]}
                        options={durationOptions}
                        selectedValues={state.selectedGraphDuration ? state.selectedGraphDuration : ''}
                        callbackMultiSelect={(value) => {
                            if(value !== 'custom') {
                                setState(prevState => ({ ...prevState, selectedGraphDuration: value }))
                            } else {
                                setState(prevState => ({ ...prevState, showDateRangePicker: true }))
                            }
                        }}
                        singleSelection={true}
                        classDetails={{border: "border border-primaryPurple-600", padding: "py-2.5 pr-4 pl-3", bg: 'bg-lavender-200', rounded: 'rounded-full', optionPosition: "right-0" }}
                    />
                    {state.showDateRangePicker ?                                                
                        <div className="absolute z-50 right-20 mt-5" ref={dateRef}>
                            <div className='relative'>
                                <div className="bg-white border border-byzantineBlue shadow-lg text-black">
                                    <DateRange
                                        editableDateInputs={true}
                                        onChange={handleSelect}
                                        moveRangeOnFirstSelection={false}
                                        ranges={dateState}
                                    />
                                </div>
                            </div>
                        </div>
                    : null}
                </div>
            </div>
            <div className="border border-DeepPurpleTints-600 p-6 rounded-2xl mt-6">
                <div className="flex justify-between">
                    <div className="flex">
                        <p className="mb-0 self-center">Total ({state.trendSeverityCount && state.trendSeverityCount.total ? state.trendSeverityCount.total : 0})</p>
                        <div className="flex ml-2 self-center">
                            <div className={`text-xs text-white bg-customRed px-2 py-1 self-center`}>C</div>
                            <p className="m-0 text-sm text-black self-center"><span className="mx-1">:</span> {state.trendSeverityCount && state.trendSeverityCount.severity && state.trendSeverityCount.severity.Critical ? state.trendSeverityCount.severity.Critical : 0}</p>
                        </div>
                        <div className="flex ml-2 self-center">
                            <div className={`text-xs text-white bg-blue-600 px-2 py-1 self-center`}>H</div>
                            <p className="m-0 text-sm text-black self-center"><span className="mx-1">:</span> {state.trendSeverityCount && state.trendSeverityCount.severity && state.trendSeverityCount.severity.High ? state.trendSeverityCount.severity.High : 0}</p>
                        </div>
                        <div className="flex ml-2 self-center">
                            <div className={`text-xs text-white bg-primaryPurple-600 px-2 py-1 self-center`}>M</div>
                            <p className="m-0 text-sm text-black self-center"><span className="mx-1">:</span> {state.trendSeverityCount && state.trendSeverityCount.severity && state.trendSeverityCount.severity.Medium ? state.trendSeverityCount.severity.Medium : 0}</p>
                        </div>
                        {/* <div className="flex ml-2 self-center">
                            <div className={`text-xs text-white bg-green-600 px-2 py-1 self-center`}>L</div>
                            <p className="m-0 text-sm text-black self-center"><span className="mx-1">:</span> {state.trendSeverityCount && state.trendSeverityCount.severity && state.trendSeverityCount.severity.Low ? state.trendSeverityCount.severity.Low : 0}</p>
                        </div> */}
                    </div>
                    <div className="flex">
                        {!state.minimizeGraphSection ?
                            <i className='far fa-redo cursor-pointer' onClick={()=> resetGraph()}></i>
                        : null}
                        <i className={`fa fa-caret-${state.minimizeGraphSection ? "down" : "down"} cursor-pointer ml-3 text-gray2`} onClick={() => setState(prevState => ({ ...prevState, minimizeGraphSection: !state.minimizeGraphSection }))}></i>
                    </div>
                </div>
                {state.graphData && Object.keys(state.graphData).length && !state.minimizeGraphSection ?
                    <div className=""> 
                        <ApexTrendBarChart
                            graphData={state.graphData}
                            graphRiskCountSection={false}
                            sparkline={false}
                            yaxis={false}
                            yaxisLabel={true}
                            yaxisTickAmount={2}
                            xaxis={true}
                            xaxisFormat={'string'}
                            xaxisLabel={true}
                            axisLabelColor={'#50586A'}
                            legend={false}
                            stacked={false}
                            height={150}
                            horizontal={false}
                            barHeight={'40%'}
                            barEndShape={'flat'}
                            columnWidth={'0%'}
                            backgroundBarShape={'rounded'}
                            backgroundBarColors={['#333947']}
                            showBackgroundBarColors={true}
                            className={"transparentTooltip"}
                            colors={['#3DAFE8']}
                            zoomEnabled={true}
                            getZoomPeriod={true}
                            zoomPeriod={(zoomedStartDate, zoomedEndDate) => {filterZoomData(zoomedStartDate, zoomedEndDate)}}
                        />
                    </div>
                : null}
            </div>
            <div className="border border-DeepPurpleTints-600 p-6 rounded-2xl mt-6">
                <div className={`flex flex-wrap gap-2 text-sm mt-2 ml-1 mb-2 ${state.alertListLoader ? 'disabled' : ""}`}>
                    <div className="flex">
                        <p className="self-center mr-2">Show </p>
                        <SelectOption
                            label={""}
                            options={state.alertTypes}
                            selectedValues={state.alertType ? state.alertType : []}
                            callbackMultiSelect={(value) => {
                                handleChange(value)
                            }}
                            singleSelection={true}
                            classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-2", bg: ' ', rounded: ' ' }}
                        />
                        <p className="self-center mx-2 text-nowrap"> alerts grouped by</p>
                        <SelectOption
                            label={""}
                            fields={["value", "label"]}
                            options={state.groupedType}
                            selectedValues={state.groupedBy ? state.groupedBy : []}
                            callbackMultiSelect={(value) => {
                                setState(prevState => ({ ...prevState, groupedBy: value, selectedAlert: "", selectedAlertId: "", selectedCategory: "Important / All", alertListLoader: true, callAlertGroups: true }))
                            }}
                            singleSelection={true}
                            classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-2", bg: ' ', rounded: ' ' }}
                        />
                    </div>
                    <div className="flex">
                        <p className="self-center mr-2">Category</p>
                        {state.alertsCategory ?
                            <SelectOption
                                label={""}
                                fields={['category', 'category']}
                                options={state.groupedTalertsCategoryype}
                                selectedValues={state.selectedCategory ? state.selectedCategory : []}
                                callbackMultiSelect={(value) => {
                                    setState(prevState => ({ ...prevState, selectedCategory: value, calltoGetCategoryALerts: true }))
                                }}
                                singleSelection={true}
                                classDetails={{border: "border-b border-NeutralGray-600", padding: "pb-1 px-2", bg: ' ', rounded: ' ' }}
                            />
                        : null}
                    </div>
                </div>
                <div className="flex flex-wrap mb-2 justify-between w-full">
                    <div className="flex w-2/3 self-center pl-1 pr-2">
                        <p className="text-sm self-center pt-1">Showing {state.filteredArray && state.filteredArray.length} of total {state.totalFilteredArray && state.totalFilteredArray.length} alert(s)</p>
                        
                        {state.zoomedStartDate && state.zoomedStartDate !== "" ? 
                            <p className="text-sm self-center pt-1 font-bold ml-1">{momentDateGivenFormat(state.zoomedStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.zoomedEndDate, 'DD MMM YYYY HH:mm')}</p>
                        :
                            <p className="text-sm self-center pt-1 font-bold ml-1">{state.brushStartTime ? momentDateGivenFormat(state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.brushEndTime, 'DD MMM YYYY HH:mm') : ""}</p>
                        }
                    </div>
                    <div className="flex justify-end w-1/3 self-center">
                        {state.totalFilteredArray && state.totalFilteredArray.length ?
                            <Search
                                data={state.totalFilteredArray ? state.totalFilteredArray : []}
                                topClassName={'border border-NeutralGray-600 rounded-full bg-white p-2.5 pl-3 pr-4 w-full'}
                                className={'rounded-full'}
                                filteredData={(filteredArray) => setState(prevState => ({ ...prevState, filteredArray }))}
                            />
                        : null}
                    </div>
                    <div className="flex justify-end w-full self-center">
                        {state.filteredArray && state.filteredArray.length > state.perPage ?
                            <div className='flex justify-end text-black w-full'>
                                <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                                <div className="flex text-sm">
                                    <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 rounded-md ${state.currentPage === 1 ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => navigatePage('previous', state.currentPage)}/></span> 
                                    <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => navigatePage('next', state.currentPage)}/></span>
                                </div>
                            </div>
                        :null}
                    </div>
                </div>
                
                <div className="flex flex-wrap">
                    <div className={`bg-white py-3 pl-1 w-full`}>
                        {state.alertListLoader ? 
                            <div className='flex justify-center m-4'>
                                <LoadingCircle />
                            </div>
                        : state.filteredArray && state.filteredArray.length ?
                            state.filteredArray.map((item, i) => {
                                return(
                                    <div key={'item_'+i} className={`text-base flex cursor-pointer text-wrap`}> 
                                        <div className="flex cursor-pointer border border-NeutralGray-600 rounded-md mb-4" onClick={() => getAlertDetails(item)}>
                                            <span className={`flex w-8 rounded-l-md h-full mr-2 text-base ${getStatusBgColor(item.severity)} text-white px-1`}>
                                                <span className='self-center'>
                                                    {item.severity ? 
                                                        (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                    : 'U'}
                                                </span>
                                            </span>
                                            <p className="mb-0">
                                                <span className="mb-0 mr-2 text-black text-sm font-weight-bold text-lightGray">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                <span className={`mb-0 mr-2 text-sm`}>
                                                    {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                    {item.event_name ? ' : ' + item.event_name : ""}
                                                    {item.event_type ? ' : '+ item.event_type : ""}</span>
                                                {item.asset_name ?
                                                    <span className="mb-0 mr-2 text-sm font-weight-bold">{item.asset_name}</span>
                                                : null}
                                                <span className="mb-0 small mr-1 text-NeutralGray-600">Resource</span>
                                                {item.account_id ?
                                                    <span className="mb-0 text-sm text-lightGray font-weight-bold">{' '+item.account_id}</span>
                                                : null}
                                                {item.region ?
                                                    <span className="mb-0 text-sm text-lightGray font-weight-bold">{' : '+item.region}</span>
                                                : null}
                                                {item.resource_type ?
                                                    <span className="mb-0 text-sm text-lightGray font-weight-bold">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                : null}
                                                {item.service_name ?
                                                    <span className="mb-0 text-sm text-lightGray font-weight-bold mr-2">{' : '+item.service_name}</span>
                                                : null}
                                                {item.description ?
                                                    <span className="mb-0 ml-2 text-sm text-black font-weight-bold">{item.description}</span>
                                                : null}
                                            </p>
                                        </div>
                                        {state.selectedAlertId1 && state.selectedAlertId1 === item.event_id ?
                                            <div className="rounded-2xl bg-dark3 p-3">
                                                <div className={`bg-dark p-3 rounded-md mb-2`}>
                                                    <div id={'heading_event'} onClick={() => setState(prevState => ({ ...prevState, expandAlertDetails: !state.expandAlertDetails }))}>
                                                        <div className="p-3 mb-0 flex justify-between">
                                                            <span className='ml-2'>Alert Details</span>
                                                            {state.expandAlertDetails ?
                                                                <Icon icon="mdi-light:minus" className={`text-black slef-center cursor-pointer`} width="20" height="20" />
                                                            : 
                                                                <Icon icon="iconoir:plus" width="20" height="20"  className='text-black slef-center cursor-pointer' />
                                                            }
                                                        </div>
                                                    </div>
                                                    {state.expandAlertDetails ?
                                                        <div className="bg-black p-3 rounded-md">
                                                            <div className="flex flex-wrap justify-between mb-2 pb-2">
                                                                <div>
                                                                    <p className="mb-0 text-lg text-black">{state.selectedAlert.event_name ? state.selectedAlert.event_name : ""}</p>
                                                                    <p className="mb-0 text-sm">{state.selectedAlert.description ? state.selectedAlert.description : ""}</p>
                                                                </div>
                                                                <div>
                                                                    <p className="b-block mb-0">Severity</p>
                                                                    <span className={`mr-2 bg-${state.selectedAlert.severity ? state.selectedAlert.severity.toLowerCase() : 'transparent border border-lightGray' }`}>  
                                                                        {state.selectedAlert.severity ? capitalizeFirstLetter(state.selectedAlert.severity) : 'Unknown'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap mb-1 border-t border-mediumDarkGray">
                                                                <div className="py-1 lg:w-1/2 w-full">
                                                                    <p className="b-block mb-0">Alert Time</p>
                                                                    <p className="mb-0 text-black">
                                                                        {state.selectedAlert.type === 'alert' ?
                                                                            state.selectedAlert.event_time ? momentConvertionUtcToLocalTime(state.selectedAlert.event_time, 'DD MMM YYYY HH:mm') : ""
                                                                        :
                                                                            state.selectedAlert.created_at ? momentConvertionUtcToLocalTime(state.selectedAlert.created_at, 'DD MMM YYYY HH:mm') : ""
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="py-1 lg:w-1/2 w-full lg:pl-3 pl-0">
                                                                    <p className="b-block mb-0">Alert Metrics</p>
                                                                    {state.selectedAlert.type === 'alert' ?
                                                                        <p className="mb-0 text-black">
                                                                            {state.selectedAlert.event_source ? state.selectedAlert.event_source : ""}
                                                                            {state.selectedAlert.event_type ? (' : ')+state.selectedAlert.event_type : ""}
                                                                        </p>
                                                                    : null}
                                                                </div>
                                                            </div>
                                                        
                                                            <div className="flex flex-wrap mb-1 border-t border-mediumDarkGray">
                                                                <div className="py-1 lg:w-1/2 w-full">
                                                                    <p className="b-block mb-0">Threshold / Statistic / Period</p>
                                                                    <p className="mb-0 text-warning">
                                                                        {state.selectedAlert.threshold && state.selectedAlert.threshold}{state.selectedAlert.unit ? (state.selectedAlert.unit ? (state.selectedAlert.unit.toLowerCase() === 'percent' ? ' %' : state.selectedAlert.unit) : "") : ""}
                                                                        {state.selectedAlert.statistic ? (' / ')+state.selectedAlert.statistic : ""}
                                                                        {state.selectedAlert.period ? (' / ')+state.selectedAlert.period : ""}
                                                                    </p>
                                                                </div>
                                                                <div className="py-1 lg:w-1/2 w-full lg:pl-3 pl-0">
                                                                    <p className="b-block mb-0">Provider : Account : Region </p>
                                                                    <p className="mb-0 text-black">
                                                                        {state.selectedAlert.provider ? state.selectedAlert.provider.toUpperCase() : ""}
                                                                        {state.selectedAlert.account_id ? (' : ')+state.selectedAlert.account_id : ""}
                                                                        {state.selectedAlert.region ? (' : ')+state.selectedAlert.region : ""}
                                                                    </p>
                                                                </div>
                                                            </div>                                                                       
                                                        </div>
                                                    : null}
                                                </div>

                                                <div className={`p-2 bg-black rounded-md mb-2`}>
                                                    <div id={'heading_config_assets'} onClick={() => setState(prevState => ({ ...prevState, expandAssetDetails: !state.expandAssetDetails }))}>
                                                        <div className="p-3 mb-0 flex justify-between">
                                                            <span className='ml-2'>Asset Details</span>
                                                            {state.expandAssetDetails ?
                                                                <Icon icon="mdi-light:minus" className={`text-black slef-center cursor-pointer`} width="20" height="20" />
                                                            : 
                                                                <Icon icon="iconoir:plus" width="20" height="20"  className='text-black slef-center cursor-pointer' />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div id={'collapse_config_assets'} className={`collapse ${state.expandAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            <div className="flex mb-2">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Name</p>
                                                                    <p className="mb-0 text-black">{state.selectedAlert.asset_name ? state.selectedAlert.asset_name : ""}</p>
                                                                </div>
                                                                <div className="py-1 w-50 pl-3">
                                                                    <p className="b-block mb-0">Id</p>
                                                                    <p className="mb-0 text-black">{state.selectedAlert && state.selectedAlert
                                                                    .asset_id ? state.selectedAlert.asset_id : ""}</p>
                                                                </div>
                                                            </div>
                                                            <div className="flex mb-2 border-top">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Resource</p>
                                                                    <p className="mb-0 text-black">
                                                                        {state.selectedAlert.provider ? state.selectedAlert.provider.toUpperCase() : ""}
                                                                        {state.selectedAlert.account_id ? (" : ")+state.selectedAlert.account_id : ""}
                                                                        {state.selectedAlert.region ? (" : ")+state.selectedAlert.region : ""}
                                                                    </p>
                                                                </div>
                                                                <div className="py-1 w-50 pl-3">
                                                                    <p className="b-block mb-0">Services</p>
                                                                    <p className="mb-0 text-black">
                                                                        {state.selectedAlert.resource_type ? state.selectedAlert.resource_type : ""}
                                                                        {state.selectedAlert.service_name ? (" : ")+state.selectedAlert.service_name : ""}
                                                                    </p>
                                                                </div>
                                                            </div>                                                        
                                                            <div className="flex mb-2 border-top">
                                                                <div className="py-1 w-100">
                                                                    <p className="b-block mb-0">ARN</p>
                                                                    <p className="mb-0 text-black">{state.selectedAlert.asset_arn ? state.selectedAlert.asset_arn : ""}</p>
                                                                </div>
                                                            </div>
                                                            {state.selectedAlert && state.selectedAlert.category ? 
                                                                <div className="flex mb-2 border-top">
                                                                    <div className="py-1 w-100">
                                                                        <p className="b-block mb-0">Category</p>
                                                                        <div className={`flex flex-wrap`}>
                                                                            {Array.isArray(state.selectedAlert.category) ?
                                                                                state.selectedAlert.category.map(item => {
                                                                                    return(
                                                                                        <small key={'alert_'+item} className={`mr-1 mb-1 badge badge-light`}>{item}</small>
                                                                                    )
                                                                                })
                                                                            : 
                                                                                <small className={`mr-1 mb-1 badge badge-light`}>{state.selectedAlert.category}</small>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null}
                                    </div>
                                )
                            })
                        :
                            <div className='flex justify-center m-4'>
                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertsTab