import React from 'react';
import { URL_PATH } from '../../config/urlPath'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  takeToHome = () => {
    window.location.href = URL_PATH.JOB_STATUS
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    console.error("Caught an error:", error, errorInfo);

    window.addEventListener("error", (event) => {
      console.error("Global error:", event.error);
    });
    
    window.addEventListener("unhandledrejection", (event) => {
      console.error("Unhandled Promise Rejection:", event.reason);
    });
    
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI here
      return (
        <div className="flex justify-center items-center h-screen">
          <p className="flex items-center text-[30px]">
            <span>Something went wrong while loading the application. Please try again.</span>
            <span
              className="text-primaryPurple-600 cursor-pointer pl-3"
              onClick={() => this.takeToHome()}
            >
              Go to Home
            </span>
          </p>
        </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
