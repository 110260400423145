import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import FileList from "./file-list";
import FileFilter from './file-filter';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import RequestStoragesidePanel from './RequestStoragesidePanel'
import _ from 'lodash'
import { getUserQuotaDetails} from '../../../actions/files/FileAction'
import { capitalizeAllLetter } from "../../../utils/utility"

export default function Files() {
	const filterRef = useRef();
	const clickOutside = useRef();

	const[state, setState] = useState({
		showLoading: true,
	})
	const fileManageFilterChange = useSelector(state => state?.filters?.commonPropsDetails?.fileManageFilterChange || false);


	const dispatch = useDispatch(); // Create a dispatch function

	const handleClickOutside = (event) => {
        
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
			dispatch(setCommonPropsDetails('clickedonParent', true))
		}
	}

    useEffect(() => {
		let params = {}
		params.provider = state.selectedProvider?.toLowerCase()

		if(state.selectedAccount && state.selectedAccount.length) {
			params.account_id = state.selectedAccount[0]
		} if(state.selectedRegion && state.selectedRegion.length) {
			params.region = state.selectedRegion[0]
		} if(state.selectedClusters && state.selectedClusters.length) {
			params.cluster_name = state.selectedClusters
		}

		if(state.selectedAccount && state.selectedAccount[0] && state.selectedRegion && state.selectedRegion[0] && state.selectedClusters){
			dispatch(getUserQuotaDetails(params))
			.then((response) => {
				if(response) {
					setState(prevState => ({ ...prevState, storageDetails: response?.results, showLoading: false }))
				}
			})
		}

	}, [dispatch, state.selectedAccount, state.selectedRegion, state.selectedClusters, state.selectedClusters])

		useEffect(() => {
			if(!_.isEqual(state.fileManageFilterChange, fileManageFilterChange)) {
          setState(prevState => ({ 
            ...prevState, 
            selectedProvider: fileManageFilterChange.selectedProvider,
            selectedAccount: fileManageFilterChange.selectedAccount ? fileManageFilterChange.selectedAccount : [],
            selectedRegion: fileManageFilterChange.selectedRegion ? fileManageFilterChange.selectedRegion : [],
            selectedClusters: fileManageFilterChange.selectedClusters ? fileManageFilterChange.selectedClusters : '',
            fileManageFilterChange,
            filteredFiles: []
          }))

          if(fileManageFilterChange.selectedClusters) {
            setState(prevState => ({ ...prevState, callUserFiles: true, filesDataLoading: true, statsDetail: {} }))
          }
        }
      }, [fileManageFilterChange, state.fileManageFilterChange])
	
	return (
		<div className='px-3'>
			<div className="file-browser top-0 relative z-10 py-6" onClick={(event) => handleClickOutside(event)}>
				<div className="flex justify-between bg-DeepPurpleTints-600 rounded-2xl px-6 py-[19px]">
					<div className="lg:w-1/8 self-center">
						<p className="text-white text-[28px] text-semibold">File Manager</p>
					</div>
					
					<div className='lg:w-2/8 pl-2 self-center' ref={filterRef}>
					{state.storageDetails?.total && state.selectedClusters ? (
						<div className='flex justify-start border border-NeutralGray-400 p-3 rounded-lg'>
							<p className='font-medium text-white text-lg border-r border-NeutralGray-400 pr-3'>Total</p>
							<div className='flex self-center mx-3 text-white'>
								<span className='mr-2'>Allocated&nbsp;Storage</span>
								<span className='mr-2'>:</span>
								<span className='text-warning'>
									{state.storageDetails?.total.allocated_storage ? state.storageDetails?.total.allocated_storage : 0 }
									<span className='ml-1 f14 text-lightGray'>{state.storageDetails?.total.storage_unit ? capitalizeAllLetter(state.storageDetails?.total.storage_unit) : '' }</span>
								</span>
							</div>
							<div className='flex self-center mr-3 text-white'>
								<span className='mr-2'>Used&nbsp;Storage</span>
								<span className='mr-2'>:</span>
								<span className='text-warning'>
									{state.storageDetails?.total.storage_used ? state.storageDetails?.total.storage_used : 0 }
									<span className='ml-1 f14 text-lightGray'>{state.storageDetails?.total.storage_unit ? capitalizeAllLetter(state.storageDetails?.total.storage_unit) : '' }</span>
								</span>
							</div>
							<div className='flex self-center mr-3 text-white'>
								<span className='mr-2'>Available&nbsp;Storage</span>
								<span className='mr-2'>:</span>
								<span className='text-warning'>
									{state.storageDetails?.total.available_storage ? state.storageDetails?.total.available_storage : 0 }
									<span className='ml-1 f14 text-lightGray'>{state.storageDetails?.total.storage_unit ? capitalizeAllLetter(state.storageDetails?.total.storage_unit) : '' }</span>
								</span>
							</div>
						</div>
					) : null}
					{state.storageDetails?.user && state.selectedClusters ?
						<div className='flex justify-start border border-NeutralGray-400 p-3 rounded-lg'>
							<p className='font-medium text-white text-lg border-r border-NeutralGray-400 pr-3'>User</p>
							<div className='flex self-center mx-3 text-white'>
								<span className='mr-2'>Allocated&nbsp;Storage</span>
								<span className='mr-2'>:</span>
								<span className='text-warning'>
									{state.storageDetails?.user.allocated_storage ? state.storageDetails?.user.allocated_storage : 0 }
									<span className='ml-1 f14 text-lightGray'>{state.storageDetails?.user.storage_unit ? capitalizeAllLetter(state.storageDetails?.user.storage_unit) : '' }</span>
								</span>
							</div>
							<div className='flex self-center mr-3 text-white'>
								<span className='mr-2'>Used&nbsp;Storage</span>
								<span className='mr-2'>:</span>
								<span className='text-warning'>
									{state.storageDetails?.user.storage_used ? state.storageDetails?.user.storage_used : 0 }
									<span className='ml-1 f14 text-lightGray'>{state.storageDetails?.user.storage_unit ? capitalizeAllLetter(state.storageDetails?.user.storage_unit) : '' }</span>
								</span>
							</div>
							<div className='flex self-center mr-3 text-white'>
								<span className='mr-2'>Available&nbsp;Storage</span>
								<span className='mr-2'>:</span>
								<span className='text-warning'>
									{state.storageDetails?.user.available_storage ? state.storageDetails?.user.available_storage : 0 }
									<span className='ml-1 f14 text-lightGray'>{state.storageDetails?.user.storage_unit ? capitalizeAllLetter(state.storageDetails?.user.storage_unit) : '' }</span>
								</span>
							</div>
						</div>
					: null}
				</div>
				<div className='lg:w-3/8'>
					<div className="flex justify-end">
						<FileFilter  ref={filterRef}
							onFilterChange={(cluster) => {
								// setSelectedCluster(cluster)
								// setState(prevState => ({
								// ...prevState,
								// selectedClusters: cluster
								// }))
							}}
						/>
						{state.selectedProvider && state.selectedAccount[0] && state.selectedRegion[0] && state.selectedClusters[0] ?
							<div className='flex justify-end lg:mx-3 md:mx-3 mx-0 pt-2 lg:mt-6 md:mb-2 mb-2'>
							{/* {state.actionsAllowed && state.actionsAllowed.includes('create') ? */}
								<button className={`text-base bg-pumpkin-600 rounded-lg text-white font-bold py-2.5 pr-4 pl-3 inline-flex items-center mr-2`} onClick={() => {
									setState(prevState => ({ ...prevState, showRequestStoragePanel: true, pageMode: 'Create' }))
								}}>Request&nbsp;Storage</button>
							{/* : null} */}
							</div>
						: null}
					</div>
					{state.showRequestStoragePanel ?
						<RequestStoragesidePanel 
							closeSidePanel={() => setState(prevState => ({...prevState, showRequestStoragePanel: false }))}
							pageMode={state.pageMode}
							refreshApi={() => setState(prevState => ({...prevState, callSearch: true  }))}
							selectedProvider={state.selectedProvider}
                            selectedAccount={state.selectedAccount[0]}
                            selectedRegion={state.selectedRegion[0]}
							selectedClusters={state.selectedClusters}
							allocatedStorage={state.storageDetails.user.allocated_storage}
							storageUsed={state.storageDetails.user.storage_used}
							availableStorage={state.storageDetails.user.available_storage}
							storageUnit={state.storageDetails.user.storage_unit}
						/>
					: null}
				</div>
			</div>
			<div className="flex flex-wrap m-2" ref={clickOutside}>
				<FileList />
			</div>
		</div>
		</div>
	);
}