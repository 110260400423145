/*************************************************
 * Collider
 * @exports
 * @file CreateCluster.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { useLocation, useNavigate } from "react-router-dom";
import { Store as CommonNotification } from "react-notifications-component";
// import { momentConvertionUtcToLocalTime } from '../../../utils/utility'

import { setHpcPropsDetails } from "../../../../actions/Collider/HpcAction";
import {
  createCluster,
  updateCluster,
  createClusterTemplate,
} from "../../../../actions/Collider/ClusterAction";
import ClusterSection from "./ClusterSection";
import HeadNodeSection from "./HeadNodeSection";
import LoginNodeSection from "./LoginNodeSection";
import DatabaseSection from "./DatabaseSection";
import QueueSection from "./QueueSection";
import StorageSection from "./StorageSection";
import ReviewSection from "./ReviewSection"
import { URL_PATH } from "../../../../config/urlPath";
import AuthenticationSection from "./AuthenticationSection";
import {Icon} from "@iconify/react";
import PageLoading from '../../../common/PageLoading';
import Button from '../../../common/Form/Button';
import AlertConfirmation from "../../../common/AlertConfirmation";
import Checkbox from "../../../common/Form/Checkbox";
import Textbox from "../../../common/Form/Textbox";

const CreateCluster = () => {
  const createUpdateCluster = useSelector((state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false);
  const propsClusterFilters = useSelector((state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false);
  const dispatch = useDispatch(); // Create a dispatch function
  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state || false;
  const stepsWithLoginNode = [
    "Cluster info",
    "Authentication",
    "Head Node",
    "Login Node",
    "Queues",
    "Database",
    "Storage",
    "Review"
  ]

  const [state, setState] = useState({
    steps: [...stepsWithLoginNode],
    selectedSection: "Cluster info",
    pageNumber: 0
  });

  useEffect(() => {
    if (createUpdateCluster && createUpdateCluster.cluster_version) {
        let parts = createUpdateCluster.cluster_version.split('.');
        let version = parts.length > 2 ? parseInt(parts[1]) : 0; // Extract version number
        const newSteps = version >= 8
            ? [...stepsWithLoginNode]
            : stepsWithLoginNode.filter(step => step !== "Login Node");

        // Only update state if newSteps is different from current state
        if (JSON.stringify(newSteps) !== JSON.stringify(state.steps)) {
            setState(prevState => ({ ...prevState, steps: newSteps }));
        }
    }
}, [createUpdateCluster, stepsWithLoginNode]);

  useEffect(() => {
    if (receivedData && Object.entries(receivedData).length) {
      setState((prevState) => ({
        ...prevState,
        selectedData: receivedData.selectedData
          ? receivedData.selectedData
          : "",
        pageType: receivedData.pageType ? receivedData.pageType : "",
      }));
      if (receivedData.pageType === "Create" && !receivedData.selectedData?.asset_name) {
        setState((prevState) => ({ ...prevState, pageType: "Create" }));
        let obj = {};
        obj.provider = "aws";
        obj.scheduler = "cluster";
        dispatch(setHpcPropsDetails("createUpdateCluster", obj));
      } else if (receivedData.pageType === "Fix"){
        setState((prevState) => ({ ...prevState, pageType: "Create" }));
        dispatch(setHpcPropsDetails("createUpdateCluster", receivedData.selectedData));
      } else {
        setState((prevState) => ({ ...prevState, pageType: receivedData.pageType }));
        dispatch(setHpcPropsDetails("createUpdateCluster", receivedData.selectedData));
      }
    } else {
    }
  }, [receivedData, dispatch]);

  const handleInputChange = (label, value) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    if ( label === "scheduler" && value ) {
      obj.scheduler = value
      obj.cluster_version = propsClusterFilters?.cluster_version?.filter(e => e.label === value)[0]?.cluster_version;
      obj.slurm_version = propsClusterFilters?.cluster_version?.filter(e => e.label === value)[0]?.slurm_version;
    } else {
      obj[label] = value;
    }
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

    setState((prevState) => ({ ...prevState, [label]: value }));
  };

  const onFinish = () => {
    let hasError = false;
    let data = createUpdateCluster;

    if (!data.provider || !data.account_id || !data.region) {
      hasError = true;
    }
    if (state.pageType !== "Template" && !data.cluster_name) {
      hasError = true;
    }
    dispatch(setHpcPropsDetails("createUpdateClusterValidation", hasError));
    // setState(prevState => ({ ...prevState, saveLoading: hasError ? false : true }))
    setState((prevState) => ({
      ...prevState,
      hasError,
      saveLoading: hasError ? false : true,
    }));
    if (!hasError) {
      if (!state.pageType || state.pageType !== "Edit") {
        if (state.pageType === "Template"){
          setState((prevState) => ({
            ...prevState,
            callCreateClusterTemplate: true,
            saveLoading: true,
          }));
        } else {
        setState((prevState) => ({
          ...prevState,
          callCreateCluster: true,
          saveLoading: true,
        }));
      }
      } else {
        setState((prevState) => ({
          ...prevState,
          callUpdateCluster: true,
          saveLoading: true,
        }));
      }
    }
  };

  useEffect(() => {
    if (state.callCreateClusterTemplate) {
      setState((prevState) => ({ ...prevState, callCreateClusterTemplate: false }));
      let params = createUpdateCluster;
      params.template_name = params.cluster_name;
      delete params.cluster_name;
      delete params.scheduler;
      dispatch(createClusterTemplate(params)).then((response) => {
        if (response) {
          let messageType = "danger";
          let message = response.message
            ? response.message
            : "Error in saving cluster";
          if (response.status) {
            messageType = "success";
            message = response.message
              ? response.message
              : "Cluster saved successfully";
            dispatch(setHpcPropsDetails("createUpdateCluster", {}));
            navigate(URL_PATH.CLUSTER_DETAILS, {state : {selectedTab: "Templates"} });
          } else {
            setState((prevState) => ({ ...prevState, saveLoading: false }));
          }
          CommonNotification.addNotification({
            //title: 'Wonderful!',
            message: message,
            type: messageType,
            insert: "top",
            container: "top-center",
            // animationIn: ['animate__animated', 'animate__fadeIn'],
            // animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
            },
          });

          if (response.status) {
            setState((prevState) => ({
              ...prevState,
              disableFinish: false,
              finishBtn: "Save",
              queryLoading: false,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              showLoading: false,
              finishBtn: "Saved",
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            disableFinish: false,
            finishBtn: "Save",
            queryLoading: false,
          }));
        }
      });
    }
  }, [state.callCreateClusterTemplate, createUpdateCluster, dispatch, navigate]);


  useEffect(() => {
    if (state.callCreateCluster) {
      setState((prevState) => ({ ...prevState, callCreateCluster: false }));
      let params = createUpdateCluster;
      delete params.scheduler;
      dispatch(createCluster(params)).then((response) => {
        if (response) {
          let messageType = "danger";
          let message = response.message
            ? response.message
            : "Error in saving cluster";
          if (response.status) {
            messageType = "success";
            message = response.message
              ? response.message
              : "Cluster saved successfully";
            dispatch(setHpcPropsDetails("createUpdateCluster", {}));
            navigate(URL_PATH.CLUSTER_DETAILS);
          } else {
            setState((prevState) => ({ ...prevState, saveLoading: false }));
          }
          CommonNotification.addNotification({
            //title: 'Wonderful!',
            message: message,
            type: messageType,
            insert: "top",
            container: "top-center",
            // animationIn: ['animate__animated', 'animate__fadeIn'],
            // animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
            },
          });

          if (response.status) {
            setState((prevState) => ({
              ...prevState,
              disableFinish: false,
              finishBtn: "Save",
              queryLoading: false,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              showLoading: false,
              finishBtn: "Saved",
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            disableFinish: false,
            finishBtn: "Save",
            queryLoading: false,
          }));
        }
      });
    }
  }, [state.callCreateCluster, createUpdateCluster, dispatch, navigate]);

  useEffect(() => {
    if (state.callUpdateCluster) {
      setState((prevState) => ({ ...prevState, callUpdateCluster: false }));
      let params = createUpdateCluster;
      dispatch(updateCluster(params)).then((response) => {
        if (response) {
          let messageType = "danger";
          let message = response.message
            ? response.message
            : "Error in updating cluster";
          if (response.status) {
            messageType = "success";
            message = response.message
              ? response.message
              : "Cluser updated successfully";
          }
          setState((prevState) => ({ ...prevState, saveLoading: false }));
          CommonNotification.addNotification({
            //title: "Wonderful!",
            message: message,
            type: messageType,
            insert: "top",
            container: "top-center",
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
            },
          });

          if (response.status) {
            setState((prevState) => ({
              ...prevState,
              disableFinish: false,
              finishBtn: "Save",
              queryLoading: false,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              showLoading: false,
              finishBtn: "Saved",
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            disableFinish: false,
            finishBtn: "Save",
            queryLoading: false,
          }));
        }
      });
    }
  }, [state.callUpdateCluster, createUpdateCluster, dispatch]);

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  const handlePreviousPage = () => {
    let pageNumber = state.pageNumber-1
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedSection: state.steps[pageNumber],
    }));
  };

  const handleNextPage = () => {
    let pageNumber = state.pageNumber+1
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedSection: state.steps[pageNumber],
    }));
  };


    return (
        <div className='bg-white' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="bg-NeutralGray-200 px-8 py-10 text-NeutralGray-800 shadow-xl">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center ">
                      <p className="font-bold text-2xl">{state.pageType === 'Edit' ? 'Modify' : 'Create'} Cluster {state.pageType === 'Template' ? 'Template' : ''}</p>
					        </div>
                </div>
            </div>
            <div className='overflow-y-auto h-[calc(100vh-230px)]'>
                <div className="bg-white py-5 mb-10 border-b border-NeutralGray-600">
                    <div className="stepper flex justify-between items-center relative py-4 mx-0 text-sm">
                        {state.steps && state.steps.map((stp, index) => {
                            return(
                                <div className={`step relative flex-1 text-center ${state.selectedSection === stp ? '' : 'cursor-pointer'}`} key={'stp_'+index} onClick={() => setState(prevState => ({ ...prevState, selectedSection: stp, pageNumber: index}))}>
                                    <div className={`circle w-8 h-8 rounded-full font-semibold text-base flex items-center justify-center mx-auto mb-2 ${state.selectedSection === stp ? 'bg-primaryPurple-600' : 'bg-NeutralGray-600 cursor-pointer'}`}>{index+1}</div>
                                    <div className="label mt-2 text-xs">
                                        Step {index+1}
                                        <p className={`mb-0 font-medium text-base text-DarkGray ${state.selectedSection === stp ? 'text-black' : 'cursor-pointer'}`}>{stp}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            
                <div className='col-sm-12 py-2 px-10'>
                    <div className="card bg-white mb-2 hidden">
                        <div className="card-body p-0 w-100">
                            <div className="stepper mx-0">
                                {state.steps && state.steps.map((stp, index) => {
                                    return(
                                        <div key={'stp_'+index} className={`step ${state.selectedSection === stp ? '' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedSection: stp}))}>
                                            <div className={`circle ${state.selectedSection === stp ? 'bg-info' : ''}`}>{index+1}</div>
												<div className="label">
													<strong>Step {index+1}</strong>
													<p className={`mb-0 ${state.selectedSection === stp ? 'text-info' : 'cursor-pointer'}`}>{stp}</p>
												</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    
                    {state.selectedSection === 'Cluster info' ? 
                        <ClusterSection 
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                        />
                    : state.selectedSection === 'Authentication' ? 
                        <AuthenticationSection 
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                        />
                    : state.selectedSection === 'Head Node' ? 
                        <HeadNodeSection 
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                        />
                    : state.selectedSection === 'Login Node' ? 
                      <LoginNodeSection 
                          pageMode={state.pageType}
                          selectedData={state.selectedData}
                      />
                    : state.selectedSection === 'Database' ? 
                        <DatabaseSection
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                            provider={state.provider}
                            accounts={state.accounts}
                            regions={state.regions}
                        />
                    : state.selectedSection === 'Queues' ? 
                        <QueueSection 
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                        />
                    : state.selectedSection === 'Storage' ? 
                        <StorageSection
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                            provider={state.provider}
                            accounts={state.accounts}
                            regions={state.regions}
                        />
                    : state.selectedSection === "Review" ? 
                        <ReviewSection
                            closeSidePanel={() => setState(prevState => ({ ...prevState, showClusterDetails: false, selectedData: {} }))}
                            selectedClusterDetails={state.selectedData}
                            detailsFormat={state.selectedData}
                            pageMode={state.pageType}
                            selectedData={state.selectedData}
                            provider={state.provider}
                            accounts={state.accounts}
                            regions={state.regions}
                        />
                    : null}
                </div>
            </div>
                <div className="w-full p-8 shadow-lg bg-NeutralGray-700">
					<div className={`flex justify-center`}>
						<div className="flex">
							<Button
								classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-lg', padding: 'py-2.5 px-3', text: 'text-white text-base font-medium', width: 'min-w-36', others: 'cursor-pointer text-center'}}
								label={'Cancel'}
								callback={() => {
                                    let obj = {
                                        message: "If you cancel, all unsaved modifications will be lost. Are you sure you want to cancel your changes",
                                        buttonLabel: "Yes, I'm sure",
                                    }
                                    setState(prevState => ({ ...prevState, confirmDetails: obj, cancelModal: true }))
                                }}
							/>
						{state.pageNumber !== 0 ?
							<Button
								classDetails={{bg: 'bg-PrimaryTintsPurpleTints-80', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
								label={'Previous'}
								callback={() => {
									handlePreviousPage()
								}}
							/>
						: null}
						{state.pageNumber < state.steps.length - 1 ?
							<Button
								classDetails={{bg: 'bg-PrimaryTintsPurpleTints-80', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
								label={'Next'}
								callback={() => {
									handleNextPage()
								}}
							/>
						: null}
						{state.pageType !== "View" ? (
							state.saveLoading ?
							<Button
                                classDetails={{bg: 'bg-green-600', rounded: 'rounded-lg', padding: 'py-2.5 px-3', text: 'text-white text-base font-medium', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                label={state.pageType === 'Edit' ? 'Updating' : 'Submitting'}
                                loading={true}
							/>
						:
							<Button
                                classDetails={state.pageNumber === state.steps.length - 1 ? {bg: 'bg-green-600', rounded: 'rounded-lg', padding: 'py-2.5 px-3', text: 'text-white text-base font-medium', width: 'min-w-36', others: 'cursor-pointer text-center'} : {others:'hidden'}}
                                label={state.pageType === 'Edit' ? 'Update' : 'Submit'}
                                callback={() => {
                                    if (state.pageType === "Template"){
                                        onFinish()
                                    } else {
                                        setState(prevState => ({ ...prevState, submitModal: true }))
                                    }
                                }}
							/>
						) : null}
						</div>
					</div>
				</div>
                {state.confirmDetails && state.cancelModal ?
                    <AlertConfirmation 
                        confirmDetails={state.confirmDetails}
                        confirmation={(cancelModal) => {
                            if(cancelModal) {
                                state.pageType === 'Template' ?
                                    navigate(URL_PATH.CLUSTER_DETAILS, { state: { selectedTab: 'Templates' } })
                                :
                                    navigate(URL_PATH.CLUSTER_DETAILS, { state: { selectedTab: 'Clusters' } });
                            } else {
                                setState(prevState => ({ ...prevState, cancelModal: false, confirmDetails: false }))
                            }
                        }}
                    />
                : null}

            <div id="popup-modal" tabIndex="-1" className={`flex ${state.submitModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow-lg border border-NeutralGray-400 dark:bg-gray-700">
                        <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, submitModal: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-black w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <h3 className="text-lg font-normal text-center">Confirm these actions</h3>

                            <div className='flex flex-col py-6 px-4'>
                                {state.pageType === "Edit" ?
                                    <div className='lg:pr-4 pt-4 w-full'>
                                        <Checkbox
                                            label="Force update: Edit the cluster while the compute fleet is still running"
                                            selectedValue={state.force_update ? true : false}
                                            size='h-7 w-7'
                                            callback={() => {
                                                let value = !state.force_update;
                                                handleInputChange("force_update", value);
                                            }}
                                        />
                                    </div>
                                : null}
                                <div className='lg:pr-4 pt-4 w-full'>
                                    <Checkbox
                                        label="Save as template"
                                        selectedValue={state.save_as_template ? true : false}
                                        callback={() => {
                                            let value = !state.save_as_template;
                                            handleInputChange("save_as_template", value);
                                        }}
                                    />
                                </div>
                                {state.save_as_template ?
                                <div className='lg:pr-4 pt-4 w-full'>
                                    <Textbox
                                        label={"Template Name"}
                                        type="text"
                                        selectedValue={state.template_name ? state.template_name : state.cluster_name+'-tmpl-1'}
                                        callback={(value) => {
                                            // setState(prevState => ({ ...prevState, template_name: value })) 
                                            handleInputChange("template_name", value);
                                        }}
                                        placeholder={'Enter template name'}
                                    />
                                </div>
                                : null}
                            </div>
                            <div className='lg:pr-4 pt-4 flex justify-center w-full'>
                                <Button 
                                    label={"Proceed"}
                                    classDetails={{bg:'bg-green-600', rounded:'rounded-lg'}}
                                    callback={() => {
                                        setState(prevState => ({ ...prevState, submitModal: !state.submitModal }))
                                        onFinish()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateCluster