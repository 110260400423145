/*************************************************
 
 * @exports
 * @file DetailsSidePanel.js
 * @author Prakash
 *************************************************/
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {Icon} from "@iconify/react";
import DetailsTab from '../../common/DetailsTab';
import PageLoading from '../../common/PageLoading';


const DetailsSidePanel = (props) => {
    const clickOut = useRef();
    const [state, setState] = useState({
        showLoading: false,
        summaryLoader: true,
        selectedTab: 'Details',
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        selectedGraphDuration: '+3h'
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const handleClickOutside = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            props.closeSidePanel()
        }
    }

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={handleClickOutside}>
                <div className='h-full flex flex-col bg-white lg:w-3/5 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 flex justify-between pl-6 py-4'>
                        <span className='font-bold text-2xl text-white'>{props.selectedType} Details</span>
                        <Icon icon="jam:close" className='text-white cursor-pointer self-center' width="25" height="25" onClick={() => props.closeSidePanel()} />
                    </div>

                    <div className={`mx-4 pt-4`}>
                        <ul className="flex flex-wrap text-sm font-bold text-center">
                            <li className="me-2">
                                <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Details' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                            </li>
                        </ul>
                        <div className="overflow-y-auto h-[calc(100vh-120px)] border border-NeutralGray-600 bg-PrimaryTintsPurple-600">
                            {state.selectedTab === "Details" ?
                                <DetailsTab
                                    detailsFormat={props.detailsFormat}
                                    selectedDetails={props.selectedDetails}
                                    selectedType={props.selectedType}
                                />
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DetailsSidePanel;