import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import Catalog from './Catalog';
import Workstation from './Workstation';
import FilterSection from '../../common/FiltersSection';
import Summary from './Summary';
import PageLoading from '../../common/PageLoading';

const LandingPage = () => {
    const clickOutside = useRef();

    // State variables for filters
    const [state, setState] = useState({
        topSectionFilters: ['provider', 'account', 'region'],
        selectedTab: "Workstations",
        tabs: ['Summary', 'Catalog', 'Workstations']
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
  
    // useEffect(() => {
    //     if (providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""), selectedTab: "Workstations" }));
    //     }
    // }, [providers]);
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            callSearch: true            
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: "", selectedAccount: [], selectedRegion: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            let prevValue = state.selectedTab
            setState(prevState => ({ ...prevState, callSearch: false, selectedTab: "" }))
            setTimeout(() => {
                setState(prevState => ({ ...prevState, selectedTab: prevValue })) // Set the same value
            }, 1000); // Change to the desired delay (in milliseconds)
        }
    }, [state.callSearch, state.selectedTab])

    const handleClickOutside = (event) => {
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }
    };

    return (
        <div className='px-3' onClick={(event) => handleClickOutside(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="pt-6">
                <div className="flex flex-wrap py-2 bg-DeepPurpleTints-600 w-full rounded-2xl py-7 px-6">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Workstations</p>
                        <p className="text-white text-base">Consolidated view of workstations</p>
                    </div>
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            topFilterOptions= {state.topFilterOptions}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className={`pt-8`}>
                <ul className="flex flex-wrap gap-2.5 text-lg">
                    {state.tabs.map(tab => {
                        return(
                            <li className={`flex inline-block px-8 py-5 border border-NeutralGray-600 rounded-t-xl ${state.selectedTab === tab ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: tab }))}>{tab}</li>
                        )
                    })}
                </ul>
                <div className="overflow-y-auto h-[calc(100vh-120px)] border border-NeutralGray-600 bg-white rounded-md py-8 px-6">
                    {state.selectedTab === 'Catalog' && state.selectedProvider ?
                        <Catalog
                            onSubscribe={(subscribedRow) => setState(prevState => ({...prevState, subscribedRow, selectedTab: 'Workstations' }))}
                            selectedProvider={state.selectedProvider} 
                            catalogType={state.selecedProvider}
                            selectedAccount={state.selectedAccount} 
                            selectedRegion={state.selectedRegion} 
                        />
                    : state.selectedTab === 'Workstations' && state.selectedProvider ?
                        <Workstation
                            selectedProvider={state.selectedProvider} 
                            selectedAccount={state.selectedAccount} 
                            selectedRegion={state.selectedRegion} 
                            subscribedRow={state.subscribedRow}
                        />
                    : state.selectedTab === 'Summary' && state.selectedProvider ?
                        <Summary
                            selectedProvider={state.selectedProvider} 
                            selectedAccount={state.selectedAccount} 
                            selectedRegion={state.selectedRegion} 
                            subscribedRow={state.subscribedRow}
                        />
                    : null}
                </div>
            </div>
        </div>
    );
};

export default LandingPage