/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file TemplateDetails.js
 * @author Prakash // on 24/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation, useNavigate } from 'react-router-dom';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { listClusterTemplates, deleteClusterTemplate } from '../../../actions/Collider/ClusterAction'
import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, getCommonInformationMessage,getPaginationShowingDetails } from '../../../utils/utility'
import { Store as CommonNotification } from 'react-notifications-component';

import { CSVLink } from 'react-csv';
import ClusterDetailsSidePanel from './ClusterDetailsSidePanel'
import Search from '../../common/SearchWithHiddenInput'
import { URL_PATH } from '../../../config/urlPath';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import AlertConfirmation from '../../common/AlertConfirmation';
import Button from '../../common/Form/Button';

const TemplateDetails = ({selectedProvider, selectedProjectTags, selectedAccount, selectedRegion}) => {
    const clickOutside = useRef()
    const [state, setState] = useState({
        showLoading: true,

        currentPage: 1,
        perPage: 5,

        clusterDetails: [],
        selectedTemplateDetails: {},
        filteredClusterDetails: [],
        clusterheaders: [],
        callSearch: true,
    })
    
    
    const dispatch = useDispatch(); // Create a dispatch function
    const navigate = useNavigate();
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				} 
                // else {
                //     if(row.action) {
                //         actionsAllowed = row.action
                //     }
                // }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))  
            let params = {}
            params.provider = selectedProvider.toLowerCase()
            if(selectedProjectTags && selectedProjectTags.length) {
                params.project_tags = selectedProjectTags
            }
            if(selectedAccount && selectedAccount.length) {
                params.account_id = selectedAccount
            }
            if(selectedRegion && selectedRegion.length) {
                params.region = selectedRegion
            }

            if(state.token === 'next') {
                params.next_token = state.listResponse && state.listResponse.next_token
            } else if(state.token === 'previous') {
                params.previous_token = state.listResponse && state.listResponse.previous_token
            } else if(state.token === 'current') {
                params.current_token = state.listResponse && state.listResponse.current_token
            }

            params.size = state.perPage
            dispatch(listClusterTemplates(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results?.results && response.results?.results.length ? response.results?.results : []
                        setState(prevState => ({ ...prevState, listResponse: response.results, clusterDetails: results, filteredClusterDetails: results, detailsFormat: response && response.cluster_details ? response.cluster_details : [], showLoading: false, totalRecords: response?.results?.total }));
                    }
            })
        }
    }, [state.callSearch, dispatch, state.perPage, selectedAccount, selectedProvider, selectedRegion, selectedProjectTags, state.clusterDetails, state.listResponse, state.token])

    useEffect(() => {
        if(state.clusterDetails && state.clusterDetails.length) {
            let dataResult = state.clusterDetails
            let headers = []
            Object.entries(dataResult[0]).forEach(([key, value]) => {
                if(typeof value === 'string') {
                    let headerRow = {}
                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                    headerRow.key = key
                    headers.push(headerRow)
                }
            })
    
            setState(prevState => ({ ...prevState, clusterheaders: headers }))
        }

        //status graph of how many running days 
        state.clusterDetails && state.clusterDetails.forEach(row => {
            let donut = [{
                item_count: row.number_of_days ? row.number_of_days : 0,
                item_name:'Days'
            }]
            let donut_total_count = row.number_of_days ? row.number_of_days : 0
            // state.statusSection.trend && state.statusSection.trend.length && state.statusSection.trend[0].total && Object.entries(state.statusSection.trend[0].total).forEach(([key, value]) => {
            //     let donutRow = {}
            //     donutRow.item_count = value
            //     donutRow.item_name = key
            //     donut.push(donutRow)
            //     donut_total_count += value 
            // })
            donut = _.orderBy(donut, ['item_count'], ['desc'])

            let statusDonutData = {
                'items': donut,
                'label':'Days',
                'total_count': donut_total_count
            }

            setState(prevState => ({ ...prevState, ["statusDonutData_"+row.template_name]: statusDonutData }))
        })
    }, [state.clusterDetails])

    useEffect(() => {
        if(state.selectedTemplateDetails && Object.entries(state.selectedTemplateDetails).length)
        setState(prevState => ({ ...prevState, showTemplateDetails: true }))
    }, [state.selectedTemplateDetails])

    const navigateTo = () => {
        navigate(URL_PATH.CREATE_CLUSTER, { state: { pageType: 'Template'} });
    }

    const deleteFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.selectedDeleteItem 
        let params = {}
        // params.provider = item.provider
        // params.account_id = item.account_id
        // params.region = item.region
        params.template_id = item.template_id
        params.template_name = item.template_name
        
        dispatch(deleteClusterTemplate(params))
            .then((response) => {
                if(response) {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : "Error in deleting"

                    if(response.status) {
                        messageType = 'success'
                        setTimeout(() => { setState(prevState => ({ ...prevState, token: 'current', callSearch: true, deleteModal: !state.deleteModal, showDeleteLoading: false }))}, 1000)
                    } else {
                        setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                    }


                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                } else {
                    setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                }
            })
    }

    useEffect(() => {
        if(state.editTemplate && state.editTemplateDetails) {
            setState(prevState => ({ ...prevState, editTemplate: false })) 
            navigate(URL_PATH.CREATE_CLUSTER, { state: { pageType: 'Template', selectedData: state.editTemplateDetails } });
        }
    }, [state.editTemplate, state.editTemplateDetails, navigate, dispatch])

    useEffect(() => {
        if(state.createCluster && state.createClusterTemplateDetails) {
            setState(prevState => ({ ...prevState, createCluster: false }))
            navigate(URL_PATH.CREATE_CLUSTER, { state: { pageType: 'Create', selectedData: state.createClusterTemplateDetails } })
        }
    }, [state.createCluster, state.createClusterTemplateDetails, navigate, dispatch])

    const handleChildClick = (event, type, dropdownType, section) => {
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }

            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const onDelete = (item) => {
        let obj = {
            message: 'Are you sure you want to delete the cluster template' +(item.template_name ? ' "'+item.template_name+'"' : ''),
            buttonLabel: "Yes, I'm sure",
        }
        setState(prevState => ({ ...prevState, confirmDetails: obj, selectedDeleteItem: item, deleteModal: true }))
    }

    return (
        state.showLoading ?
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                    <PageLoading />
            </div>
        :
            <React.Fragment>
            {state.showTemplateDetails ? 
                <ClusterDetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showTemplateDetails: false, selectedTemplateDetails: {} }))}
                    selectedClusterDetails={state.selectedTemplateDetails}
                    detailsFormat={state.detailsFormat}
                />
            : null}

                <div onClick={(event) => handleChildClick(event, 'parent')}>
                    <div className='flex justify-between pb-8'>
                        <p className='mb-0 text-sm self-center lg:w-1/2 w-full'>
                            Showing <span className='mx-1'>{getPaginationShowingDetails(state.filteredClusterDetails && state.filteredClusterDetails.length, state.totalRecords, state.currentPage, state.perPage)}</span> out of total {state.totalRecords} templates(s)
                        </p>
                        <div className='flex justify-end lg:w-1/2 w-full'>
                            {state.clusterDetails && state.clusterDetails.length ?
                                <div className='self-center w-100 ml-4'>
                                <Search
                                    data={state.clusterDetails ? state.clusterDetails : []}
                                    topClassName={'border border-NeutralGray-600 rounded-full bg-white'}
                                    className={'rounded-full'}
                                    filteredData={(filteredClusterDetails) => {
                                        setState(prevState => ({ ...prevState, filteredClusterDetails, currentPage: 1 })
                                    )}}
                                />
                                </div>
                            : null}
                            {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                                <Button
                                    classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', margin: 'mx-2', text: 'text-white text-base font-medium', width: 'min-w-36', others: 'cursor-pointer'}}
                                    label={'Create Template'}
                                    callback={() => {
                                        navigateTo()
                                    }}
                                />
                            : null}
                            {state.filteredClusterDetails && state.filteredClusterDetails.length ?
                                <div className='self-center' title="download">
                                    <CSVLink 
                                        data={state.filteredClusterDetails ? state.filteredClusterDetails : []} 
                                        headers={state.clusterheaders ? state.clusterheaders : []}
                                        filename={'cluster-lists.csv'}
                                        className={'self-center'}
                                        target='_blank'
                                    >
                                        <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center' />
                                    </CSVLink>
                                </div>
                            : null}
                        </div>
                    </div>
                    {state.totalRecords > state.perPage ?
                        <div className='flex justify-end text-black w-full'>
                            <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                            <div className="flex text-sm">
                                <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 border border-lightGray rounded-md ${state.listResponse && state.listResponse.previous_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.listResponse && state.listResponse.previous_token) {
                                        setState(prevState => ({ ...prevState, showLoading: true, currentPage: state.currentPage-1, token: 'previous', callSearch: true }))
                                    }
                                }}/></span> 
                                <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 border border-lightGray rounded-md ${state.listResponse && state.listResponse.next_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.listResponse && state.listResponse.next_token) {
                                        setState(prevState => ({ ...prevState, showLoading: true, currentPage: state.currentPage+1, token: 'next', callSearch: true }))
                                    }
                                }}/></span>
                            </div>
                        </div>
                    : null}
                </div>

                {state.filteredClusterDetails && state.filteredClusterDetails.length ?
                    state.filteredClusterDetails.map((item, index) => {
                        return(
                            <div className='flex flex-wrap bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl p-6 mb-6' key={"row_"+index}> 
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full bg-SecondaryTintsBlueTints border-r border-NeutralGray-600">
                                <div className='flex flex-col justify-between h-full'>
                                    <div className="flex flex-col justify-between h-full">
                                        <div className="flex">
                                            <div className="w-full">
                                                <p className="b-block mb-0 text-NeutralGray-600 text-sm">Template</p>
                                                <p className="mb-0 text-base text-black">
                                                    { item.template_name ? item.template_name : ''}
                                                    {/* {item.template_id ?
                                                        <span className={`mb-0 mr-2 f18 text-black`}>
                                                            &nbsp;({item.template_id})
                                                        </span>
                                                    : null} */}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="w-full">
                                                <p className="b-block mb-0 text-NeutralGray-600 text-sm">Asset</p>
                                                <p className="mb-0 text-base text-black">{item.asset_name ? item.asset_name : ''}</p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="w-full">
                                                <p className="b-block mb-0 text-NeutralGray-600 text-sm">Resources</p>
                                                <p className="mb-0 text-base text-black">
                                                    {item.account_id ?
                                                        <span>{' '+item.account_id}</span>
                                                    : null}
                                                    {item.region ?
                                                        <span>{' '+item.region}</span>
                                                    : null}
                                                    {item.resource_type ?
                                                        <span>{' '+item.resource_type}</span>
                                                    : null}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-full border-r border-NeutralGray-600 pr-0">
                                    <div className="flex flex-col justify-between h-full pl-11">
                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(0, 3).map(([key, value], tIndex) => {
                                            return(
                                                <div key={'tag_' + tIndex} className={`flex`}>
                                                    <div className="w-full">
                                                        <p className="b-block mb-0 text-NeutralGray-600 text-sm">{key}</p>
                                                        {value ? 
                                                            <p className="mb-0 text-base text-black">{value}</p>   
                                                        : 
                                                            <span>&nbsp;</span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-full pr-0">
                                    <div className="flex flex-col justify-between h-full pl-11">
                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(3, 6).map(([key, value], tIndex) => {
                                            return(
                                                <div key={'ptag_' + tIndex} className={`flex`}>
                                                    <div className="w-full">
                                                        <p className="b-block mb-0 text-NeutralGray-600 text-sm">{key}</p>
                                                        {value ? 
                                                            <p className="mb-0 text-base text-black">{value}</p>  
                                                        : 
                                                            <span>&nbsp;</span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-full">
                                    <div className='flex justify-between'>
                                        <div className='p-4 rounded-lg border border-DeepPurpleTints-600 bg-SecondaryTintsBlueTints w-1/2 flex flex-col justify-center'>
                                            <div className='flex'>
                                                <div className="pt-3 w-full">
                                                    <p className="b-block mb-0 text-NeutralGray-600 text-sm">Created at</p>
                                                    <p className="mb-0 text-black text-base">{item.created_at ? momentConvertionUtcToLocalTime(item.created_at, 'DD MMM YYYY HH:mm') : <span>&nbsp;</span>}</p>
                                                </div>
                                            </div>

                                            <div className='flex'>
                                                <div className="pt-3 w-full">
                                                    <p className="b-block mb-0 text-NeutralGray-600 text-sm">Modified at</p>
                                                    <p className="mb-0 text-black text-base">{item.last_modified_at ? momentConvertionUtcToLocalTime(item.last_modified_at, 'DD MMM YYYY HH:mm') : 'Not Modified'}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='self-center'>
                                            <p className='text-DeepPurpleTints-600 w-full mb-[17px] px-3 py-[6px] text-base text-center cursor-pointer border border-DeepPurpleTints-600 rounded-full' onClick={() => setState(prevState => ({ ...prevState, selectedTemplateDetails: item }))}>Details</p>

                                            {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                                                <p className='text-PrimaryLightOrange-600 w-full mb-[17px] px-3 py-[6px] text-base text-center cursor-pointer border border-PrimaryLightOrange-600 rounded-full' onClick={() => setState(prevState => ({ ...prevState, editTemplateDetails: item, editTemplate: true }))}>Edit</p>
                                            : null}

                                            {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                                                <p className='text-PrimaryLightOrange-600 w-full mb-[17px] px-3 py-[6px] text-base text-center cursor-pointer border border-PrimaryLightOrange-600 rounded-full' 
                                                    onClick={() => onDelete(item)}>Delete</p>
                                            : null}

                                            {item.template_id && item.template_name ?
                                                <p className='text-DeepPurpleTints-600 w-full px-3 py-[6px] text-base text-center cursor-pointer border border-DeepPurpleTints-600 rounded-full' onClick={() => setState(prevState => ({ ...prevState, createClusterTemplateDetails: item, createCluster: true }))}>Create Cluster</p>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                :
                    <div className='flex justify-center text-black m-4'>
                        {getCommonInformationMessage('template')}
                    </div>
                }

                {state.confirmDetails && state.deleteModal ?
                    <AlertConfirmation 
                        confirmDetails={state.confirmDetails}
                        confirmation={(deleteModal) => {
                            if(deleteModal) {
                                deleteFunction()
                            }
                            setState(prevState => ({ ...prevState, confirmDetails: false, deleteModal: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))
                        }}
                    />
                : null}
        </React.Fragment>
    )
}
export default TemplateDetails