/*************************************************
 * Collider
 * @exports
 * @file ClusterAction.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import ApiCalls from '../../components/ApiCalls'
//import Cookies from 'js-cookie';

import {
	LIST_CLUSTER_DETAILS,
	LIST_DASHBOARD_RESULTS,
	LIST_PROJECT_DETAILS,
	GET_CLUSTER_DETAILS,
	LIST_QUEUES,
	LIST_QUEUE_DETAILS,
	LIST_CLUSTERS,
	CREATE_CLUSTER,
	UPDATE_CLUSTER,
	DELETE_CLUSTER,
	CREATE_QUEUE,
	UPDATE_QUEUE,
	DELETE_QUEUE,
	GET_QUEUE_FILTERS,
	LIST_USERS_BY_CLUSTER,
	GET_QUEUE_DETAILS,
	GET_JOB_DETAILS,
	GET_QUEUE_AVAILABILITY_DETAILS,
	GET_CLUSTER_FILTERS,	
	LIST_CLUSTER_TEMPLATES,
	CREATE_CLUSTER_TEMPLATE,
	DELETE_CLUSTER_TEMPLATE,
} from '../../config'
import { refreshPage } from '../../utils/utility';
// import { myLog, momentTime, momentDate, convertBytes, convertSeconds, convertBits, countFormater } from '../../utils/utility'
// //import Cookies from 'js-cookie'
import { ACTION_TYPES } from '../types';

/**
 * Action to list-cluster-details
 * @param {Object} body
 * @param {Function} callback
 */
export const listClusterDetails = (params) => {
	return async () => {
		try {
			const url = LIST_CLUSTER_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-dashboard-results
 * @param {Object} body
 * @param {Function} callback
 */
export const listDashboardResults = (params) => {
	return async () => {
		try {
			const url = LIST_DASHBOARD_RESULTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)
			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-cluster-details
 * @param {Object} body
 * @param {Function} callback
 */
export const listProjectDetails = (params) => {
	return async () => {
		try {
			const url = LIST_PROJECT_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-cluster-details
 * @param {Object} body
 * @param {Function} callback
 */
export const getClusterDetails = (params) => {
	return async () => {
		try {
			const url = GET_CLUSTER_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-clusters
 * @param {Object} body
 * @param {Function} callback
 */
export const listClusters = (params) => {
	return async dispatch => {
		try {
			const url = LIST_CLUSTERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			dispatch({ type: ACTION_TYPES.SET_LIST_CLUSTER_NAME, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to create-cluster
 * @param {Object} body
 * @param {Function} callback
 */
export const createCluster = (params) => {
	return async dispatch => {
		try {
			const url = CREATE_CLUSTER;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to update-cluster
 * @param {Object} body
 * @param {Function} callback
 */
export const updateCluster = (params) => {
	return async dispatch => {
		try {
			const url = UPDATE_CLUSTER;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to delete-cluster
 * @param {Object} body
 * @param {Function} callback
 */
export const deleteCluster = (params) => {
	return async dispatch => {
		try {
			const url = DELETE_CLUSTER;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to create-queue
 * @param {Object} body
 * @param {Function} callback
 */
export const createQueue = (params) => {
	return async () => {
		try {
			const url = CREATE_QUEUE;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to update-queue
 * @param {Object} body
 * @param {Function} callback
 */
export const updateQueue = (params) => {
	return async () => {
		try {
			const url = UPDATE_QUEUE;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to delete-queue
 * @param {Object} body
 * @param {Function} callback
 */
export const deleteQueue = (params) => {
	return async () => {
		try {
			const url = DELETE_QUEUE;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-queues
 * @param {Object} body
 * @param {Function} callback
 */
export const listQueues = (params) => {
	return async () => {
		try {
			const url = LIST_QUEUES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-queue-details
 * @param {Object} body
 * @param {Function} callback
 */
export const listQueueDetails = (params) => {
	return async () => {
		try {
			const url = LIST_QUEUE_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}


/**
 * Action to update-queue
 * 25/08/23
 * @param {Object} body
 * @param {Function} callback
 */
export const getQueueFilters = (params) => {
	return async () => {
		try {
			const url = GET_QUEUE_FILTERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-users-by-cluster
 * 25/08/23
 * @param {Object} body
 * @param {Function} callback
 */
export const listUsersByCluster = (params) => {
	return async () => {
		try {
			const url = LIST_USERS_BY_CLUSTER;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-queue-details
 * 25/08/23
 * @param {Object} body
 * @param {Function} callback
 */
export const getQueueDetails = (params) => {
	return async () => {
		try {
			const url = GET_QUEUE_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-job-details
 * 25/08/23
 * @param {Object} body
 * @param {Function} callback
 */
export const getJobDetails = (params) => {
	return async () => {
		try {
			const url = GET_JOB_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-queue-availability-details
 * 13/03/24
 * @param {Object} body
 * @param {Function} callback
 */
export const getQueueAvailabilityDetails = (params) => {
	return async () => {
		try {
			const url = GET_QUEUE_AVAILABILITY_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}


/**
 * Action to get-queue-availability-details
 * 13/03/24
 * @param {Object} body
 * @param {Function} callback
 */
export const getClusterFilters = (params) => {
	return async () => {
		try {
			const url = GET_CLUSTER_FILTERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-clusters
 * @param {Object} body
 * @param {Function} callback
 */
export const listClusterTemplates = (params) => {
	return async () => {
		try {
			const url = LIST_CLUSTER_TEMPLATES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to create-cluster
 * @param {Object} body
 * @param {Function} callback
 */
export const createClusterTemplate = (params) => {
	return async dispatch => {
		try {
			const url = CREATE_CLUSTER_TEMPLATE;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to delete-cluster
 * @param {Object} body
 * @param {Function} callback
 */
export const deleteClusterTemplate = (params) => {
	return async dispatch => {
		try {
			const url = DELETE_CLUSTER_TEMPLATE;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}
