import React, { useState, useEffect, useRef } from 'react';
import { capitalizeAllLetter, capitalizeFirstLetter } from '../../../utils/utility';
import {Icon} from "@iconify/react";
import Search from './Search';

const SelectOption = ({ label, valueLabel, fields, options, selectedValues, callbackMultiSelect, singleSelection, makeLabelUpperCase, dropdownWidth, addAllField, parentClass, classDetails, manditory, placeholder, hideSearch, common, labelClass, hasError, disabled }) => {
    const dropdownRef = useRef()

    const [state, setState] = useState({})

    useEffect(() => {
        setState(prevState => ({ ...prevState, options, filteredOptions: options, selectedValues }))
    }, [options, selectedValues, singleSelection])

    useEffect(() => {
        // if(addAllField) {
        //     let filteredOptions = options
        //     // if(fields && fields.length) {
        //     //     if(!filteredOptions.filter(e => e[fields[0]] === 'All').length) {
        //     //         let dataRow = {}
        //     //         dataRow[fields[0]] = 'All'
        //     //         dataRow[fields[1]] = 'All'
        //     //         filteredOptions.unshift(dataRow)
        //     //     }
        //     // } else {
        //     //     if(!filteredOptions.filter(e => e === 'All').length) {
        //     //         filteredOptions.unshift('All')
        //     //     }
        //     // }
        //     setState(prevState => ({ ...prevState, filteredOptions, addAllField: false }))
        // }
    }, [addAllField, options, fields, selectedValues])

    const toggleDropdown = () => {
        setState(prevState => ({ ...prevState, dropdownOpen: !state.dropdownOpen }))
    };

    // const handleSelectAll = (e) => {
    //     let opt = []
    //     options.forEach(row => {
    //         opt.push(fields && fields.length ? row[fields[0]] : row)
    //     })
    //     let selectedValues = []
    //     if (e.target.checked) {
    //         selectedValues = opt
    //     }
    //     setState(prevState => ({ ...prevState, selectedValues: opt }));

    //     callbackMultiSelect(selectedValues)
    // };

    const handleOptionChange = (option) => {
        if(singleSelection) {
            selectedValues = option
            setState(prevState => ({ ...prevState, selectedValues, dropdownOpen: false }))
        } else {
            if (selectedValues && selectedValues.includes(option)) {
                selectedValues = selectedValues.filter((item) => item !== option);
            } else {
                selectedValues.push(option);
            }

            setState(prevState => ({ ...prevState, selectedValues })); 
            // if(addAllField) {
            //     callbackMultiSelect(selectedValues.filter(e => e !== 'All'))
            // } else {
            //     callbackMultiSelect(selectedValues)
            // }

            // callbackMultiSelect(selectedValues)
        }

        // let selectedValues = state.selectedValues
        // if(!singleSelection) {
        //     if (selectedValues && selectedValues.includes(option)) {
        //         selectedValues = selectedValues.filter((item) => item !== option);
        //     } else {
        //         selectedValues.push(option);
        //     }
        // } else {
        //     selectedValues = option
        // }
        // setState(prevState => ({ ...prevState, selectedValues, dropdownOpen: singleSelection ? false : true }))

        // if(Array.isArray(option)) {
        //     setState(prevState => ({ ...prevState, dropdownOpen: !state.dropdownOpen }))
        // }

        callbackMultiSelect(selectedValues)
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setState(prevState => ({ ...prevState, dropdownOpen: false }))
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return() => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`relative inline-block h-fit flex flex-col cursor-pointer 
        ${parentClass && parentClass.margin ? parentClass.margin : ''} 
        ${parentClass && parentClass.selfOption ? parentClass.selfOption : 'self-end'} 
        ${dropdownWidth ? dropdownWidth : 'min-w-28'}`} ref={dropdownRef}>
            <div className='flex justify-between'>
                <p className={`text-base flex
                        ${labelClass && labelClass.color ? labelClass.color : 'text-NeutralGray-800'}
                    `}>
                    {label} 
                    {manditory ?
                        <Icon icon="fe:star" className='text-red-600 ml-1 self-center' width={12} height={12} />
                    : null}
                </p>
                {typeof state.selectedValues === "string" && !state.selectedValues && hasError && manditory ?
                    <p className='text-sm text-ferrariRed-600'>Required</p>
                : null}
                {(Array.isArray(state.selectedValues) && !state.selectedValues.length) && hasError && manditory ?
                    <p className='text-sm text-ferrariRed-600'>Required</p>
                : null}
            </div>
            <p className={`flex justify-between w-full shadow-sm text-left cursor-default focus:outline-none sm:text-sm
                ${classDetails && classDetails.common ? classDetails.common : ''}
                ${classDetails && classDetails.bg ? classDetails.bg : 'bg-white'} 
                ${classDetails && classDetails.rounded ? classDetails.rounded : 'rounded-lg'} 
                ${classDetails && classDetails.border ? classDetails.border : 'border border-NeutralGray-400'} 
                ${classDetails && classDetails.padding ? classDetails.padding : 'py-2.5 pl-3 pr-4'} 
                ${classDetails && classDetails.selectedFieldClass ? classDetails.selectedFieldClass : ''}                
            `} 
            onClick={toggleDropdown}>
                <span className={`truncate text-base text-NeutralGray-600`}>
                    {valueLabel ?
                        <span className='pr-2'>{valueLabel}</span>
                    : null}
                    {selectedValues && Array.isArray(selectedValues) ?
                        selectedValues.length > 0 ? selectedValues.length + ' Selected' : (placeholder ? placeholder : 'Select')
                    : selectedValues ?
                        label === 'Provider' ?
                            state.selectedValues ? state.selectedValues.toUpperCase() : ''
                        : options && fields && fields.length && fields[0] !== fields[1] ?
                            options.filter(e => e[fields[0]] === state.selectedValues).length ? 
                                options.filter(e => e[fields[0]] === state.selectedValues)[0][fields[1]] 
                            : (placeholder ? placeholder : 'Select')
                        :
                            state.selectedValues
                    : (placeholder ? placeholder : 'Select')}
                </span>
                <Icon icon="icon-park-outline:down" className={`text-NeutralGray-600 self-center`} width={24} height={24} />
            </p>
            {!disabled && state.dropdownOpen ?
                <div className={`relative`}>
                    <div className={`absolute mt-1 bg-white border border-byzantineBlue rounded-md shadow-[0_4px_6px_rgba(33,37,41,0.2),_0_0_1px_rgba(33,37,41,0.32)] z-10 
                        ${classDetails && classDetails.optionWidth ? classDetails.optionWidth : 'min-w-[220px]'}
                        ${classDetails && classDetails.optionPosition}`}>
                        <div className="max-h-80 overflow-auto">
                            {!hideSearch && options && options.length > 5 ? 
                                <div className="p-4">
                                    <Search
                                        data={state.options ? state.options : []}
                                        topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                                        className={'w-full rounded-full'}
                                        filteredData={(filteredArray, searchText) => {
                                            setState(prevState => ({ ...prevState, filteredOptions: searchText !== "" ? filteredArray : state.options }))
                                        }}
                                    />
                                </div>
                            : null}
                            <ul className="">
                                {state.filteredOptions && state.filteredOptions.length ?
                                    state.filteredOptions.map((row, index) => {
                                        return(
                                            <div className={`text-base hover:bg-water-600 ${!index ? 'pt-2' : ''}`} key={'drop_'+index}>
                                                {!singleSelection ?
                                                   <li key={index} className={`px-4 py-2 text-black ${index+1 === state.filteredOptions.length ? 'mb-1' : ''} ${state.selectedValues && Array.isArray(state.selectedValues) && state.selectedValues.includes(fields && fields.length ? row[fields[0]] : row) ? 'bg-water-600' : ''}`} onClick={() => handleOptionChange(fields && fields.length ? row[fields[0]] : row)}> 
                                                        <label className="inline-flex items-center">
                                                            <div className='w-4'>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={state.selectedValues && state.selectedValues.includes(fields && fields.length ? row[fields[0]] : row) ? true : false}
                                                                    onChange={() => {}}
                                                                    className="form-checkbox cursor-pointer h-4 w-4 text-blue-600 option-checkbox"
                                                                />
                                                            </div>
                                                            <span className="ml-2 mb-1" onClick={() => handleOptionChange(fields && fields.length ? row[fields[0]] : row)}>
                                                                {makeLabelUpperCase === 'capitalizeFirstLetter'
                                                                    ? capitalizeFirstLetter(fields && fields.length ? row[fields[1]] : row)
                                                                    : makeLabelUpperCase === 'capitalizeAllLetter'
                                                                    ? capitalizeAllLetter(fields && fields.length ? row[fields[1]] : row)
                                                                    : fields && fields.length ? row[fields[1]] : row}
                                                            </span>
                                                        </label>
                                                    </li>
                                                :   
                                                    <li key={index} className={`px-4 py-2 text-black ${index+1 === state.filteredOptions.length ? 'mb-1' : ''} ${state.selectedValues && Array.isArray(state.selectedValues) && state.selectedValues.includes(fields && fields.length ? row[fields[0]] : row) ? 'bg-water-600' : ''}`} onClick={(event) => handleOptionChange(fields && fields.length ? row[fields[0]] : row)}>
                                                        <label className="inline-flex items-center">
                                                            <span className="ml-2">
                                                                {makeLabelUpperCase && makeLabelUpperCase === 'capitalizeFirstLetter' ?
                                                                    capitalizeFirstLetter(fields && fields.length ? row[fields[1]] : row)
                                                                : makeLabelUpperCase && makeLabelUpperCase === 'capitalizeAllLetter' ?
                                                                    capitalizeAllLetter(fields && fields.length ? row[fields[1]] : row)
                                                                :  
                                                                    fields && fields.length ? row[fields[1]] : row
                                                                }
                                                            </span>
                                                        </label>
                                                    </li>
                                                }
                                            </div>
                                        )
                                    })
                                : 
                                    <li className={`px-4 py-2 text-black`}>No Options</li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            : null}
        </div>
    )
}

export default SelectOption