import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import { subscribeImage } from '../../../actions/ImagebuilderAction';

const SubscriptionSidePanel = ({ selectedDetails, closeSidePanel }) => {
    const clickOut = useRef();
    
    const [state, setState] = useState({
        showLoading: false
    })
    const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    useEffect(() => {
            setState(prevState => ({
                ...prevState, 
                vulnerabilities: selectedDetails && selectedDetails.subscription_details && selectedDetails.subscription_details.vulnerabilities ? true : false,
                updates: selectedDetails && selectedDetails.subscription_details && selectedDetails.subscription_details.updates ? true : false
         }))
    }, [selectedDetails])

    useEffect(() => {
        if(state.onSubmit) {
            setState(prevState => ({ ...prevState, onSubmit: false}))
            let hasError = false
            setState(prevState => ({ ...prevState, hasError, saveLoading: !hasError ? true : false }))

            if(!hasError) {
                let params = {
                    provider: selectedDetails.provider,
                    account_id: selectedDetails.account_id,
                    region: selectedDetails.region,
                };
        
                if (selectedDetails.image_id) {
                    params.image_id = selectedDetails.image_id;
                }
                params.vulnerabilities = state.vulnerabilities ? true : false
                params.updates = state.updates ? true : false
                dispatch(subscribeImage(params))
                    .then((response) => {
                        setState(prevState => ({ ...prevState, saveLoading: false }))
                        let messageType = 'danger'
                        let message = response.message ? response.message : 'Error in request'
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'Reqeust submitted successfully'
                            setTimeout(() => { closeSidePanel('refresh') }, 2000); 
                        }                        

                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    })
            }
        }

    }, [dispatch, state.onSubmit, closeSidePanel, selectedDetails, state.updates, state.vulnerabilities])

    const handleClickOutside = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
    }

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={handleClickOutside}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-2/5 bg-GhostWhite min-h-screen overflow-auto' ref={clickOut}>
                <div className='header-search bg-GhostWhite flex justify-between p-2'>
                    <div className='text-black lg:w-1/2 w-full self-center'>
                        <span className='font-bold text-xl text-black'>Subscription Details</span>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2 overflow-auto'>
                    <div className="rounded-md bg-white p-3">
                        <div className='flex pt-2 border-darkGray-top'>
                            <div className='py-1 md:w-1/2 w-full self-center'>
                                {/* <p className='b-block mb-1 flex'>{selectedDetails.subscription ? 'Un Subscribe' : 'Subscribe'}</p> */}
                                <div className='flex self-center mt-3'>
                                    <label className="mb-0">
                                        <input type="checkbox" 
                                            className="mt-1" 
                                            checked={state.vulnerabilities ? true : false}
                                            onChange={() => {
                                                setState(prevState => ({ ...prevState, vulnerabilities: !state.vulnerabilities }))
                                            }}
                                        />
                                        <span className="slider round small pl-1"></span>
                                    </label>
                                    <span className="mb-0 text-black" onClick={() => setState(prevState => ({ ...prevState, vulnerabilities: !state.vulnerabilities }))}>Notify Image Vulnerabilities</span>
                                </div>
                            </div>
                        </div>

                        <div className='flex mt-2'>
                            <div className='py-1 md:w-1/2 w-full self-center'>
                                {/* <p className='b-block mb-1 flex'>{selectedDetails.subscription ? 'Un Subscribe' : 'Subscribe'}</p> */}
                                <div className='flex self-center mt-3'>
                                    <label className="mb-0">
                                        <input type="checkbox" 
                                            className="mt-1" 
                                            checked={state.updates ? true : false}
                                            onChange={() => {
                                                setState(prevState => ({ ...prevState, updates: !state.updates }))
                                            }}
                                        />
                                        <span className="slider round small pl-1"></span>
                                    </label>
                                    <span className="mb-0 text-black" onClick={() => setState(prevState => ({ ...prevState, updates: !state.updates }))}>Notify Image Updates</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`flex mb-2 mt-4 justify-end`}>
                        {state.saveLoading ?
                            <button className='flex border border-lightGray px-2 py-1 bg-HalloweenOrange text-white rounded-md'>
                                <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                    <path d="M4 12a8 8 0 0112-6.9" />
                                </svg>
                                Submitting
                            </button>
                        : 
                            <button className={`flex justify-end cursor-pointer border border-lightGray px-2 py-1 bg-HalloweenOrange rounded-md text-white`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, onSubmit: true }))}>Submit</button>
                        }
                        <button className={`ml-2 flex justify-end cursor-pointer border border-lightGray bg-lightGray text-white rounded-md px-2 py-1`} onClick={ () => closeSidePanel()}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionSidePanel;