import moment from 'moment-timezone';

const formatedDate = (
  date,
  fill = ' ',
  withSeconds = false,
  monthType = 'short',
  hideTime = false,
  showTimeZone = false,
) => {
  if (!date) return '';

  // Handle ISO 8601 formatted dates

  const d = new Date(date);

  const day = d.getDate().toString().padStart(2, '0');
  const month = d.toLocaleString('default', { month: monthType || 'short' });
  const year = d.getFullYear();
  const hours = d.getHours();
  const minutes = d.getMinutes().toString().padStart(2, '0');
  const seconds = withSeconds ? d.getSeconds().toString().padStart(2, '0') : '';

  let formattedTime = '';
  if (!hideTime) {
    formattedTime = `${hours}:${minutes}${withSeconds ? `:${seconds}` : ''}`;
  }

  let timeZoneString = '';
  if (showTimeZone) {
    // Get offset in hours and minutes
    const timeZoneOffset = -(d.getTimezoneOffset() / 60);
    const sign = timeZoneOffset >= 0 ? '+' : '-';
    const hoursOffset = Math.abs(Math.floor(timeZoneOffset)).toString().padStart(2, '0');
    const minutesOffset = Math.abs(d.getTimezoneOffset() % 60)
      .toString()
      .padStart(2, '0');

    // Build time zone string with IST (remove parentheses, adjust offset display)
    timeZoneString = `GMT${sign}${hoursOffset}:${minutesOffset} IST`;
  }

  return `${day}${fill}${month}${fill}${year}${hideTime ? '' : ` ${formattedTime}`}${
    showTimeZone ? ` (${timeZoneString})` : ''
  }`;
};

export const secsToMins = (secs) => {
  const milliseconds = secs;
  const duration = moment.duration(milliseconds);
  let hours = duration.hours(milliseconds);
  let minutes = duration.minutes(milliseconds);
  let seconds = duration.seconds(milliseconds);
  if (('' + hours).length === 1) {
    hours = `0${hours}`;
  }
  if (('' + minutes).length === 1) {
    minutes = `0${minutes}`;
  }
  if (('' + seconds).length === 1) {
    seconds = `0${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
};

export default formatedDate;

export const FullTimeStampFormatedDate = (date) => {
  return formatedDate(date, '-', true);
};

export const showDateFormate = (date, fill = ' ') => {
  const d = new Date(date); // In JavaScript, we can easily get the current date or time by using the new Date() object. By default, it uses our browser's time zone and displays the date as a full text string that contains the current date, time, and time zone.
  const day = d.getDate();
  const month = d.toLocaleString('default', { month: '2-digit' });
  const year = d.getFullYear();
  return `${month}${fill}${day}${fill}${year}`;
};

export const convertedTimeToReadableTimeFormat = (sec, local, showSeconds = true) => {
  if (!sec) return '-';
  const milliseconds = sec * 1000;
  const date = new Date(milliseconds);
  if (local === true) {
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    const returnValue = `${day}-${month}-${year} ${hour}:${minute}${
      showSeconds ? `:${second}` : ''
    }`;
    return returnValue;
  } else {
    const year = date.getUTCFullYear();
    const month = date.toLocaleString('default', { month: 'short', timeZone: 'UTC' });
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hour = date.getUTCHours().toString().padStart(2, '0');
    const minute = date.getUTCMinutes().toString().padStart(2, '0');
    const second = date.getUTCSeconds().toString().padStart(2, '0');
    const returnValue = `${day}-${month}-${year} ${hour}:${minute}:${second}`;
    return returnValue;
  }
};
export const parseDuration = (durationString) => {
  const regex = /(\d+h)?(\d+m)?(\d+s)?/;
  const [, hoursMatch, minutesMatch, secondsMatch] = durationString.match(regex) || [];

  const hours = hoursMatch ? parseInt(hoursMatch, 10) : 0;
  const minutes = minutesMatch ? parseInt(minutesMatch, 10) : 0;
  const seconds = secondsMatch ? parseInt(secondsMatch, 10) : 0;

  return hours * 3600 + minutes * 60 + seconds;
};
export const convertDateToSeconds = (secs, toString = false) => {
  const converted = Math.round(new Date(secs).getTime() / 1000);
  return toString ? `${converted}s` : converted;
};
export const getValueAsDate = (value) => {
  value = value.trim();
  let date;
  if (/^[0-9-]+$/.test(value)) {
    // Format like "4-1-2024"
    const [day, month, year] = value.split('-');
    date = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day)));
  } else if (/^[0-9]+-[a-zA-Z]+-[0-9]+$/.test(value)) {
    // Format like "4-Jan-2024"
    const [day, month, year] = value.split('-');
    const monthIndex = new Date(`${month} 1, 2000`).getMonth(); // Getting the month index
    date = new Date(Date.UTC(parseInt(year), monthIndex, parseInt(day)));
  } else {
    // Handle invalid format
    throw new Error('Invalid date format');
  }

  const formattedDate = date.toISOString().split('T')[0];
  return formattedDate;
};
export const getUTCSeconds = (date) => {
  return Math.floor(new Date(date).getTime() / 1000);
};
