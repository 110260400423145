/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listTroubleshootResults } from '../../../actions/Collider/ObservabilityAction';
import { getCommonInformationMessage, momentConvertionUtcToLocalTime } from '../../../utils/utility';
import {Icon} from "@iconify/react";
import Search from '../../common/SearchWithHiddenInput';

const TroubleshootTab = ({ selectedCluster }) => {
    // State to manage component data
    const [minMaxHeight, setMinMaxHeight] = useState("700px");
    const [pageNumber, setPageNumber] = useState(1);
    const [startRecord, setStartRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage] = useState(10);
    const [showClusterDetails, setShowClusterDetails] = useState(false);
    const [troubleshootDetails, setTroubleshootDetails] = useState([ /* Initial data here */ ]);
    const [filteredTroubleshootDetails, setFilteredTroubleshootDetails] = useState([ /* Initial data here */ ]);

    const dispatch = useDispatch(); // Create a dispatch function

    // Function to fetch data on component mount
    useEffect(() => {
        getWindowSize();
        let params = {
            account_id: selectedCluster.account_id,
            region: selectedCluster.region,
            cluster_name: selectedCluster.cluster_name
        };
        
        dispatch(listTroubleshootResults(params))
            .then((response) => {
                if(response) {
                    setTroubleshootDetails(response)
                    setFilteredTroubleshootDetails(response)

                    let totalPages = 1
                    if(response.length > perPage) {
                        totalPages = Math.ceil(response.length / perPage)
                    }
                    

                    setTotalPages(totalPages)
                }
        })
    }, [dispatch, selectedCluster, perPage]);

    // Function to get window size and update state
    const getWindowSize = () => {
        // Calculate outerHeight and set it to state
        
		// const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 120
        let otherHeight = 130
				
		let calculatedOuterHeight = (window.outerHeight - browserBorder - headerHeight - otherHeight ) + "px"
        
        setMinMaxHeight(calculatedOuterHeight);
    }

    // Function to navigate between pages
    const navigatePage = (action) => {
        // Update startRecord and currentPage based on action        
        let startRecordVar = startRecord;
        if(action === 'next' && currentPage !== totalPages) {
            startRecordVar = startRecordVar + perPage;
            setCurrentPage(currentPage + 1);
        } else if(action === 'previous' && currentPage !== 0) {
            setCurrentPage(currentPage - 1);
            startRecordVar = startRecordVar - perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setCurrentPage(1);
            startRecordVar = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setCurrentPage(totalPages);
            startRecordVar = (totalPages - 1) * perPage;
        }
        setStartRecord(startRecordVar);
    }

    // Function to go to a specific page
    const gotoPage = (pageNumber) => {
        // Go to the specified page and update state        
        if(pageNumber > 0  && pageNumber <= totalPages) {
            let startRecord = (pageNumber - 1) * perPage
            setCurrentPage(currentPage)
            setStartRecord(startRecord)
            setPageNumber(pageNumber)
        } else  if(pageNumber > totalPages) {
            setPageNumber(currentPage)
        } else {
            setPageNumber(pageNumber)
        }
    }

    // JSX for rendering the component
    return (
        <div className='overflowYAuto' style={{minHeight: minMaxHeight, maxHeight: minMaxHeight}}>
            <div className="p-3 rounded bg-GhostWhite my-2">
                <div className="flex justify-between">
                    <h5 className="mb-0 cursor-pointer text-black flex" onClick={() => setShowClusterDetails(!showClusterDetails)}>Cluster Details</h5>
                    
                    <Icon icon={`fa:angle-${showClusterDetails ? "up" : "down"}`} width="20" height="20" className='text-lightGray cursor-pointer' onClick={() => setShowClusterDetails(!showClusterDetails)}/>
                </div>
                {showClusterDetails ?
                    <div className='mt-2'>
                        <div className="flex">
                            <div className="py-1 w-50">
                                <p className="b-block mb-0">Name</p>
                                <p className="mb-0 text-black">
                                    {troubleshootDetails && troubleshootDetails.length && troubleshootDetails[0].cluster_name}
                                </p>
                            </div>
                            <div className="py-1 w-50 pl-3">
                                <p className="b-block mb-0">Cluster Account</p>
                                <p className="mb-0 text-black">
                                    <span className={`mr-2 font-weight-bold f12 cursor-pointer self-center badge badge-outline-info text-black mb-1`}>{troubleshootDetails && troubleshootDetails.length && troubleshootDetails[0].cluster_account ? troubleshootDetails[0].cluster_account : ""}</span>
                                </p>
                            </div>
                        </div>
                        <div className="flex pt-2 mt-2 border-top">
                            <div className="py-1 w-50">
                                <p className="b-block mb-0">User Name</p>
                                <p className="mb-0 text-black">
                                    {troubleshootDetails && troubleshootDetails.length && troubleshootDetails[0].user_name}
                                </p>
                            </div>
                            <div className="py-1 w-50 pl-3">
                                <p className="b-block mb-0">Resource</p>
                                <p className="mb-0 text-black">
                                    {troubleshootDetails && troubleshootDetails.length && troubleshootDetails[0].account_id ? troubleshootDetails[0].account_id : ""}
                                    {troubleshootDetails && troubleshootDetails.length && troubleshootDetails[0].region ? (" : ")+troubleshootDetails[0].region : ""}
                                </p>
                            </div>
                        </div>
                        <div className="flex pt-2 mt-2 border-top">
                            <div className="py-1 w-50">
                                <p className="b-block mb-0">Start Time</p>
                                <p className="mb-0 text-black">{troubleshootDetails && troubleshootDetails.length && troubleshootDetails[0].start_time ? momentConvertionUtcToLocalTime(troubleshootDetails[0].start_time, "DD MMM YYY HH:mm") : ""}</p>
                            </div>
                            <div className="py-1 w-50 pl-3">
                                <p className="b-block mb-0">End TIme</p>
                                <p className="mb-0 text-black">{troubleshootDetails && troubleshootDetails.length && troubleshootDetails[0].end_time ? momentConvertionUtcToLocalTime(troubleshootDetails[0].end_time, "DD MMM YYY HH:mm") : ""}</p>
                            </div>
                        </div>
                    </div>
                : null}
            </div>
            <div className="p-3 rounded bg-backGroundGray mb-2">
                <div className='flex justify-between mb-2'>
                    <div className=''>
                        <p className="f12 mb-1 self-center pt-1">Showing {filteredTroubleshootDetails && filteredTroubleshootDetails.length} of total {troubleshootDetails && troubleshootDetails.length} troubleshooting(s)</p>
                    </div>
                    <div className='self-center w-50'>
                        <div className="flex">
                            {/* {filteredTroubleshootDetails && filteredTroubleshootDetails.length ?
                                <CSVLink 
                                    data={filteredTroubleshootDetails ? filteredTroubleshootDetails : []} 
                                    headers={clusterheaders ? clusterheaders : []}
                                    filename={"cluster-lists.csv"}
                                    className={"mt-2"}
                                    target="_blank"
                                >
                                    <span className="flex text-lightGray mr-2">
                                        <i className="fas fa-download text-lightGray mr-2"/>
                                    </span>
                                </CSVLink>
                            : null} */}
                            {troubleshootDetails && troubleshootDetails.length ?
                                <Search
                                    data={troubleshootDetails ? troubleshootDetails : []}
                                    filteredData={(filteredTroubleshootDetails) => {
                                        let totalPages = 1
                                        if(filteredTroubleshootDetails.length > perPage) {
                                            totalPages = Math.ceil(filteredTroubleshootDetails.length / perPage)
                                        }
                                        
                                        setFilteredTroubleshootDetails(filteredTroubleshootDetails)
                                        setTotalPages(totalPages)
                                    }}
                                />
                            : null}
                        </div>
                        {filteredTroubleshootDetails && filteredTroubleshootDetails.length > perPage ?
                            <div className="pagination errorPagePagination f12">
                                <span className="mx-3">Page <strong>{currentPage} of {totalPages}</strong> </span>
                                <button><i className={`fal fa-arrow-to-left cursor-pointer ${currentPage === 1 ? 'disabled text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', currentPage)}></i></button> 
                                <button><i className={`fal fa-angle-left cursor-pointer ${currentPage === 1 ? 'disabled text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', currentPage)}></i></button> 
                                <span className='displayNone'>
                                    Go to
                                    <input
                                        type='number'
                                        value={pageNumber || pageNumber === "" ? pageNumber : currentPage}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) : ""
                                            gotoPage(page)
                                        }}
                                        className="ml-1 inputClass"
                                    />
                                </span>
                                <button><i className={`fal fa-angle-right cursor-pointer ${currentPage === totalPages ? 'disabled text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', currentPage)}></i></button> 
                                <button><i className={`fal fa-arrow-to-right cursor-pointer ${currentPage === totalPages ? 'disabled text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', currentPage)}></i></button>
                            </div>
                        : null}
                    </div>
                </div>
                
                {filteredTroubleshootDetails && filteredTroubleshootDetails.length ?
                    filteredTroubleshootDetails.map((row, rIndex) => {
                        return(
                            <div key={'arr_'+rIndex} className='bg-dark rounded p-2 mb-2'>
                            {row.checks && row.checks.length ?
                                row.checks.slice(startRecord, startRecord + perPage).map((item, i) => {
                                    return(
                                        <React.Fragment key={'item_'+i}>
                                        <div className={`p-2 mb-2 bg-lightGray ${startRecord + perPage !== startRecord + i ? 'border-bottom-black5' : ''} cursor-pointer`} style={{minHeight: "40px"}}>
                                            {/* <div className="flex">
                                                <span className={`f10 mr-2 self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                    {item.severity ? 
                                                        (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                    : 'U'}
                                                </span>
                                                <p className="mb-0">
                                                    <span className="mb-0 mr-2 text-black f12 font-weight-bold text-lightGray">{item.event_time ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ''} </span>
                                                    <span className={`mb-0 mr-2 f12 text-purple-2`}>
                                                        {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                        {item.event_name ? ' : ' + item.event_name : ''}
                                                        {item.event_type ? ' : '+ item.event_type : ''}</span>
                                                    {item.asset_name ?
                                                        <span className="mb-0 mr-2 f12 text-black font-weight-bold">{item.asset_name}</span>
                                                    : null}
                                                    <span className="mb-0 small mr-1 text-lightGray">Resource</span>
                                                    {item.account_id ?
                                                        <span className="mb-0 f12 text-lightGray font-weight-bold">{' '+item.account_id}</span>
                                                    : null}
                                                    {item.region ?
                                                        <span className="mb-0 f12 text-lightGray font-weight-bold">{' : '+item.region}</span>
                                                    : null}
                                                    {item.resource_type ?
                                                        <span className="mb-0 f12 text-lightGray font-weight-bold">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                    : null}
                                                    {item.service_name ?
                                                        <span className="mb-0 f12 text-lightGray font-weight-bold mr-2">{' : '+item.service_name}</span>
                                                    : null}
                                                    {item.description ?
                                                        <span className="mb-0 ml-2 f12 text-black font-weight-bold">{item.description}</span>
                                                    : null}
                                                </p>
                                            </div> */}
                                            <div className='flex justify-between'>
                                                <div className='self-center'>
                                                    <div className='flex justify-between'>
                                                        <p className="mb-0 self-center mb-1">
                                                            <span className={`mb-0 mr-2 f18 text-purple`}>
                                                                {item.label}
                                                            </span>
                                                        </p>
                                                        {item.tag ?
                                                            <span className={`mr-2 font-weight-bold f12 cursor-pointer self-center badge badge-outline-info text-black mb-1`}>{item.tag}</span>                                                
                                                        : null}
                                                    </div>
                                                    <p className="mb-0 self-center mb-1">
                                                        {item.data && item.data.description ?
                                                            <React.Fragment>
                                                            <span className="mb-0 small mr-1 text-lightGray">Description</span>
                                                            <span className="mb-0 ml-2 f12 text-black font-weight-bold">{item.data.description}</span>
                                                            </React.Fragment>
                                                        : null}
                                                    </p>
                                                    <p className="mb-0 self-center mb-1">
                                                        {item.data && item.data.description ?
                                                            <React.Fragment>
                                                            <span className="mb-0 small mr-1 text-lightGray">Resolution</span>
                                                            <span className="mb-0 ml-2 f12 text-black font-weight-bold">{item.data.resolution}</span>
                                                            </React.Fragment>
                                                        : null}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        </React.Fragment>
                                    )
                                })
                            : null}
                            </div>
                        )
                    })
                :
                    <div className='flex justify-center m-4'>
                        {getCommonInformationMessage('qos')}
                    </div>
                }
            </div>
        </div>
    );
}

export default TroubleshootTab;