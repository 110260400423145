/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file ClusterDetails.js
 * @author Prakash // on 24/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation, useNavigate } from 'react-router-dom';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { deleteCluster, listClusterDetails } from '../../../actions/Collider/ClusterAction'
import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'
import { Store as CommonNotification } from 'react-notifications-component';

import { CSVLink } from 'react-csv';
import ClusterDetailsSidePanel from './ClusterDetailsSidePanel'
import Search from '../../common/SearchWithHiddenInput'
import { URL_PATH } from '../../../config/urlPath';
import {Icon} from "@iconify/react";

import 'xterm/css/xterm.css';
import ClusterTerminal from './ClusterTerminal';
import PageLoading from '../../common/PageLoading';

const ClusterDetails = ({selectedTab, selectedProvider, selectedProjectTags, selectedAccount, selectedRegion}) => {
    const clickOutside = useRef()
    const [state, setState] = useState({
        showLoading: true,

        currentPage: 1,
        perPage: 5,

        clusterDetails: [],
        selectedClusterDetails: {},
        filteredClusterDetails: [],
        clusterheaders: [],
        callSearch: true,
    })
    
    
    const dispatch = useDispatch(); // Create a dispatch function
    const navigate = useNavigate();
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				} 
                // else {
                //     if(row.action) {
                //         actionsAllowed = row.action
                //     }
                // }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))  
            let params = {}
            params.provider = selectedProvider.toLowerCase()
            if(selectedProjectTags && selectedProjectTags.length) {
                params.project_tags = selectedProjectTags
            }
            if(selectedAccount && selectedAccount.length) {
                params.account_id = selectedAccount
            }
            if(selectedRegion && selectedRegion.length) {
                params.region = selectedRegion
            }

            if(state.token === 'next') {
                params.next_token = state.listResponse && state.listResponse.next_token
            } else if(state.token === 'previous') {
                params.previous_token = state.listResponse && state.listResponse.previous_token
            } else if(state.token === 'current') {
                params.current_token = state.listResponse && state.listResponse.current_token
            }

            params.size = state.perPage
            dispatch(listClusterDetails(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length ? response.results : []
                        
                        setState(prevState => ({ ...prevState, listResponse: response, clusterDetails: results, filteredClusterDetails: results, detailsFormat: response && response.cluster_details ? response.cluster_details : [], showLoading: false, totalRecords: response.total }));
                    }
            })
        }
    }, [state.callSearch, dispatch, state.perPage, selectedAccount, selectedProvider, selectedRegion, selectedProjectTags, state.clusterDetails, state.listResponse, state.token, selectedTab])

    useEffect(() => {
        if(state.clusterDetails && state.clusterDetails.length) {
            let dataResult = state.clusterDetails
            let headers = []
            Object.entries(dataResult[0]).forEach(([key, value]) => {
                if(typeof value === 'string') {
                    let headerRow = {}
                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                    headerRow.key = key
                    headers.push(headerRow)
                }
            })
    
            setState(prevState => ({ ...prevState, clusterheaders: headers }))
        }

        //status graph of how many running days 
        state.clusterDetails && state.clusterDetails.forEach(row => {
            let donut = [{
                item_count: row.number_of_days ? row.number_of_days : 0,
                item_name:'Days'
            }]
            let donut_total_count = row.number_of_days ? row.number_of_days : 0
            // state.statusSection.trend && state.statusSection.trend.length && state.statusSection.trend[0].total && Object.entries(state.statusSection.trend[0].total).forEach(([key, value]) => {
            //     let donutRow = {}
            //     donutRow.item_count = value
            //     donutRow.item_name = key
            //     donut.push(donutRow)
            //     donut_total_count += value 
            // })
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let statusDonutData = {
                'items': donut,
                'label':'Days',
                'total_count': donut_total_count
            }
    
            setState(prevState => ({ ...prevState, ["statusDonutData_"+row.cluster_name]: statusDonutData }))
        })
    }, [state.clusterDetails])

    useEffect(() => {
        if(state.selectedClusterDetails && Object.entries(state.selectedClusterDetails).length)
        setState(prevState => ({ ...prevState, showClusterDetails: true }))
    }, [state.selectedClusterDetails])

    const navigateTo = () => {
        navigate(URL_PATH.CREATE_CLUSTER, { state: { pageType: 'Create' } });
    }

    const deleteFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.selectedDeleteItem 
        let params = {}
        params.provider = item.provider
        params.account_id = item.account_id
        params.region = item.region
        params.cluster_name = item.cluster_name
        
        dispatch(deleteCluster(params))
            .then((response) => {
                if(response) {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : "Error in deleting"

                    if(response.status) {
                        messageType = 'success'
                        setTimeout(() => { setState(prevState => ({ ...prevState, token: 'current', callSearch: true, deleteModal: !state.deleteModal, showDeleteLoading: false }))}, 1000)
                    } else {
                        setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                    }


                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                } else {
                    setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                }
            })
    }

    const getStatusBgColor = (status) => {
        let color = 'bg-darkPurple-50 border-2 border-darkPurple-600 text-darkPurple-600'
        if(status === 'CREATE_IN_PROGRESS') { color = 'bg-independenceGray-50 border-2 border-independenceGray-600 text-independenceGray-600' } else 
        if(status === 'CREATE_COMPLETE') { color = 'bg-slateBlue-50 border-2 border-slateBlue-600 text-slateBlue-600' } else 
        if(status === 'CREATE_FAILED') { color = 'bg-red-50 border-2 border-red-600 text-red-600' } else 
        if(status === 'RUNNING') { color = 'bg-appleGreen-50 border-2 border-appleGreen-600 text-appleGreen-600' } else 
        if(status === 'STOPPED') { color = 'bg-blue-50 border-2 border-blue-600 text-blue-600' } else 
        if(status === 'UNKNOWN') { color = 'bg-PrimaryLightOrange-50 border-2 border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } else 
        if(status === 'CREATE_INITIATED') { color = 'bg-yellowOrange-50 border-2 border-yellowOrange-600 text-yellowOrange-600' }

        return color;
    }

    useEffect(() => {
        if(state.editCluster && state.editClusterDetails) {
            setState(prevState => ({ ...prevState, editCluster: false }))            
            navigate(URL_PATH.CREATE_CLUSTER, { state: { pageType: 'Edit', selectedData: state.editClusterDetails } });
        }
    }, [state.editCluster, state.editClusterDetails, navigate, dispatch])

    useEffect(() => {
        if(state.fixCluster && state.fixClusterDetails) {
            setState(prevState => ({ ...prevState, fixCluster: false }))            
            navigate(URL_PATH.CREATE_CLUSTER, { state: { pageType: 'Fix', selectedData: state.fixClusterDetails } })
        }
    }, [state.fixCluster, state.fixClusterDetails, navigate, dispatch])

    const handleChildClick = (event, type, dropdownType, section) => {
        
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }

            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        state.showLoading ?
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                    <PageLoading />
            </div>
        :
            <React.Fragment>
            {state.showClusterDetails ? 
                <ClusterDetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showClusterDetails: false, selectedClusterDetails: {} }))}
                    selectedClusterDetails={state.selectedClusterDetails}
                    detailsFormat={state.detailsFormat}
                />
            : null}
            {state.showTerminal ?
                <ClusterTerminal
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showTerminal: false, selectedClusterDetails: {} }))}
                    selectedClusterDetails={state.selectedClusterItem}
                />
            : null}
                <div className='p-2' onClick={(event) => handleChildClick(event, 'parent')}>
                    <div className='flex justify-between pb-8'>
                        <p className='mb-0 text-sm self-center lg:w-1/2 w-full'>Showing {state.filteredClusterDetails && state.filteredClusterDetails.length} of total {state.totalRecords} cluster(s)</p>
                        <div className='flex justify-end lg:w-1/2 w-full'>
                            {state.clusterDetails && state.clusterDetails.length ?
                                <div className='self-center w-100 ml-4'>
                                <Search
                                    data={state.clusterDetails ? state.clusterDetails : []}
                                    topClassName={'border border-NeutralGray-600 rounded-full bg-white p-2.5 pl-3 pr-4'}
                                    className={'rounded-full'}
                                    filteredData={(filteredClusterDetails) => {
                                        setState(prevState => ({ ...prevState, filteredClusterDetails, currentPage: 1 })
                                    )}}
                                />
                                </div>
                            : null}
                            {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                                <button className={`bg-DeepPurpleTints-600 text-white mx-2 font-semibold px-5 py-2.5 rounded-full self-center text-base`} onClick={() => navigateTo()}>Create&nbsp;Cluster</button>
                            : null}
                            {state.filteredClusterDetails && state.filteredClusterDetails.length ?
                                <div className='self-center' title="download">
                                    <CSVLink 
                                        data={state.filteredClusterDetails ? state.filteredClusterDetails : []} 
                                        headers={state.clusterheaders ? state.clusterheaders : []}
                                        filename={'cluster-lists.csv'}
                                        className={'self-center'}
                                        target='_blank'
                                    >
                                        <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center' />
                                    </CSVLink>
                                </div>
                            : null}
                        </div>
                    </div>
                    {state.totalRecords > state.perPage ?
                        <div className='flex justify-end text-black w-full'>
                            <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                            <div className="flex text-sm">
                                <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 border border-lightGray rounded-md ${state.listResponse && state.listResponse.previous_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.listResponse && state.listResponse.previous_token) {
                                        setState(prevState => ({ ...prevState, showLoading: true, currentPage: state.currentPage-1, token: 'previous', callSearch: true }))
                                    }
                                }}/></span> 
                                <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 border border-lightGray rounded-md ${state.listResponse && state.listResponse.next_token ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.listResponse && state.listResponse.next_token) {
                                        setState(prevState => ({ ...prevState, showLoading: true, currentPage: state.currentPage+1, token: 'next', callSearch: true }))
                                    }
                                }}/></span>
                            </div>
                        </div>
                    : null}
                </div>

                {state.filteredClusterDetails && state.filteredClusterDetails.length ?
                    state.filteredClusterDetails.map((item, index) => {
                        return(
                            <div className='flex flex-wrap bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl mb-6' key={"row_"+index}> 
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full bg-SecondaryTintsBlueTints rounded-l-2xl">
                                <div className='py-[30px] px-8 flex flex-col justify-between h-full'>
                                    <div className="flex flex-col justify-between h-full">
                                        <div className="flex">
                                            <div className="w-full">
                                                <p className="b-block mb-0 text-NeutralGray-600 text-sm">Cluster</p>
                                                <p className="mb-0 text-base text-black">
                                                    {item.cluster_name ? item.cluster_name : ''}
                                                    {item.cluster_version ? (
                                                        <span className={`mb-0 mr-2 f18 text-base text-black font-bold`}>
                                                            (v {item.cluster_version})
                                                        </span>
                                                    ) : null}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="w-full">
                                                <p className="b-block mb-0 text-NeutralGray-600 text-sm">Asset</p>
                                                <p className="mb-0 text-base text-black">{item.asset_name ? item.asset_name : ''}</p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="w-full">
                                                <p className="b-block mb-0 text-NeutralGray-600 text-sm">Resources</p>
                                                <p className="mb-0 text-base text-black">
                                                    {item.account_id ? (
                                                        <span>{' ' + item.account_id}</span>
                                                    ) : null}
                                                    {item.region ? (
                                                        <span>{' ' + item.region}</span>
                                                    ) : null}
                                                    {item.resource_type ? (
                                                        <span>{' ' + item.resource_type}</span>
                                                    ) : null}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-full my-[30px] border-r border-NeutralGray-600 pr-0">
                                    <div className="flex flex-col justify-between h-full pl-11">
                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(0, 3).map(([key, value], tIndex) => {
                                            return (
                                                <div key={'tag_' + tIndex} className={`flex`}>
                                                    <div className="w-full">
                                                        <p className="b-block mb-0 text-NeutralGray-600 text-sm">{key}</p>
                                                        {value ? 
                                                            <p className="mb-0 text-base text-black">{value}</p>   
                                                        : 
                                                            <span>&nbsp;</span>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-full my-[30px] pr-0">
                                    <div className="flex flex-col justify-between h-full pl-11">
                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(3, 6).map(([key, value], tIndex) => {
                                            return (
                                                <div key={'ptag_' + tIndex} className={`flex`}>
                                                    <div className="w-full">
                                                        <p className="b-block mb-0 text-NeutralGray-600 text-sm">{key}</p>
                                                        {value ? 
                                                            <p className="mb-0 text-base text-black">{value}</p>   
                                                        : 
                                                            <span>&nbsp;</span>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full pr-0">
                                    <div className='flex justify-between'>
                                        <div className='my-[20px] px-[23px] py-[10px] rounded-lg border border-DeepPurpleTints-600 bg-SecondaryTintsBlueTints w-3/5'>
                                            <div className="flex">
                                                <div className="w-100">
                                                    <p className="b-block mb-1 text-sm">Status</p>
                                                    <p className={`mb-0 text-black text-base`}>
                                                        <span className={`px-[11px] py-[3px] rounded-full ${getStatusBgColor(item.status)}`}>{item.status}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="pt-6 w-100">
                                                    <p className="b-block mb-0 text-NeutralGray-600 text-sm">Days Running</p>
                                                    <p className="mb-0 text-black text-base">
                                                        <span>{item.number_of_days ? item.number_of_days : 0}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="pt-6 w-100">
                                                    <p className="b-block mb-0 text-NeutralGray-600 text-sm">Created at</p>
                                                    <p className="mb-0 text-black text-base">{item.created_at ? momentConvertionUtcToLocalTime(item.created_at, 'DD MMM YYYY HH:mm') : <span>&nbsp;</span>}</p>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="pt-6 w-100">
                                                    <p className="b-block mb-0 text-NeutralGray-600 text-sm">Modified at</p>
                                                    <p className="mb-0 text-black text-base">{item.last_modified_at ?  momentConvertionUtcToLocalTime(item.last_modified_at, 'DD MMM YYYY HH:mm') : 'Not Modified'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-0 self-center mr-[35px]'>
                                            <p className='text-DeepPurpleTints-600 w-full mb-[17px] px-3 py-[6px] text-base text-center cursor-pointer border border-DeepPurpleTints-600 rounded-full' onClick={() => setState(prevState => ({ ...prevState, selectedClusterDetails: item }))}>Details</p>
                                            {state.actionsAllowed && state.actionsAllowed.includes('update') ? (
                                                item.status === "CREATE_FAILED" ? 
                                                    <p className='text-PrimaryLightOrange-600 w-full mb-[17px] px-3 py-[6px] text-base text-center cursor-pointer border border-PrimaryLightOrange-600 rounded-full' onClick={() => setState(prevState => ({ ...prevState, fixClusterDetails: item, fixCluster: true }))}>Fix</p>
                                                :
                                                    <p className='text-PrimaryLightOrange-600 w-full mb-[17px] px-3 py-[6px] text-base text-center cursor-pointer border border-PrimaryLightOrange-600 rounded-full' onClick={() => setState(prevState => ({ ...prevState, editClusterDetails: item, editCluster: true }))}>Edit</p>
                                            ) : null}
                                            {state.actionsAllowed && state.actionsAllowed.includes('delete') ? (
                                                <p className='text-PrimaryLightOrange-600 w-full mb-[17px] px-3 py-[6px] text-base text-center cursor-pointer border border-PrimaryLightOrange-600 rounded-full' onClick={() => setState(prevState => ({ ...prevState, selectedDeleteItem: item, deleteModal: true }))}>Delete</p>
                                            ) : null}
                                            {item.status && item.status.toLowerCase() === "running" && item.login_node_details && item.login_node_details.address ?
                                                <p className='text-DeepPurpleTints-600 w-full px-3 py-[6px] text-base text-center cursor-pointer border border-DeepPurpleTints-600 rounded-full' onClick={() => { setState(prevState => ({ ...prevState, selectedConnectDetails: item, connectLoginModal: true })) }}>Connect to Login Node</p>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                :
                    <div className='flex justify-center text-black m-4'>
                        There are no data on this criteria. Please try adjusting your filter.
                    </div>
                }
            <div id="popup-modal" tabIndex="-1" className={`flex ${state.deleteModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, deleteModal: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete the cluster "{state.selectedDeleteItem && state.selectedDeleteItem.cluster_name ? state.selectedDeleteItem.cluster_name : ''}"?</h3>

                            {/* <p>Please enter the cluster name to delete</p>
                            <input
                                type='text'
                                placeholder={'Enter cluster name'}
                                className={`bg-transparent rounded-sm px-1 border border-mediumDarkGray text-black`}
                                // maxlength='128'
                                value={state.name ? state.name : ''}
                                onChange={e => handleInputChange('name', noSpace(e.target.value))}
                            /> */}
                            <div className='flex mt-4 justify-center'>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-HalloweenOrange mr-2" 
                                    onClick={() => {
                                        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
                                        if(!state.showDeleteLoading) {
                                            deleteFunction()
                                        }
                                    }}
                                >
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md bg-lightGray text-white" onClick={() => {
                                    if(!state.showDeleteLoading) {
                                        setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false, name: '' }))
                                    }
                                }}>
                                No, cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="popup-modal" tabIndex="-1" className={`flex ${state.connectLoginModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, connectLoginModal: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-center text-gray-500 dark:text-gray-400">Steps to connect Login Node</h3>

                            <p className='text-base mb-2'>Step-1 : Open an SSH client</p>
                            <p className='text-base mb-2'>Step-2 : Connect to the Login Node using the following SSH command</p>
                            <p className='text-base mb-2 border border-box px-1'>ssh {state.selectedConnectDetails && state.selectedConnectDetails.login_node_details && state.selectedConnectDetails.login_node_details.address ? state.selectedConnectDetails.login_node_details.address : 'john@112.83.72.1'}</p>
                            <p className='text-base mb-2 text-lightGray'>Note: Use your AD username and password</p>

                            <div className='flex mt-4 justify-center'>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-HalloweenOrange mr-2" 
                                    onClick={() => {
                                        setState(prevState => ({ ...prevState, connectLoginModal: !state.connectLoginModal }))
                                        // if(!state.showDeleteLoading) {
                                        //     deleteFunction()
                                        // }
                                    }}
                                >
                                    Okay
                                </button>
                                {/* <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md bg-lightGray text-white" onClick={() => {
                                    if(!state.showDeleteLoading) {
                                        setState(prevState => ({ ...prevState, connectLoginModal: !state.connectLoginModal, showDeleteLoading: false, name: '' }))
                                    }
                                }}>
                                No, cancel
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ClusterDetails