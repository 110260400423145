/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 
 * @exports
 * @file LandingPage.js
 * @author Prakash
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { deleteDepartment, listDepartmentsMappings } from '../../../actions/cost/BudgetAction'
import { capitalizeTheFirstLetterOfEachWord, getCommonInformationMessage, } from '../../../utils/utility'
import { CSVLink } from 'react-csv';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Button from '../../common/Form/Button';
import Search from '../../common/SearchWithHiddenInput'
import { Store as CommonNotification } from 'react-notifications-component';
import { URL_PATH } from '../../../config/urlPath';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import AlertConfirmation from '../../common/AlertConfirmation';

const LandingPage = () => {
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        callSearch: true
    })

    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation()
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				} 
                // else {
				// 	if(row.action) {
				// 		actionsAllowed = row.action
				// 	}
				// }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }));
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
    
            dispatch(listDepartmentsMappings(params))
                .then((response) => {
                    if(response) {
                        let results = response.results ? response.results : []
                        setState(prevState => ({
                            ...prevState,
                            listResponse: results,
                            filterdListResponse: results,
                            showLoading: false
                        }));
                    }
                })
        }
    }, [state.callSearch, dispatch, state.selectedAccount, state.selectedProvider])

    useEffect(() => {
        let dataResult = state.listResponse && state.listResponse.length ? state.listResponse : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, headers }))
		}
    }, [state.listResponse])

    const navigateTo = () => {
        dispatch(setHpcPropsDetails('createMappingInputs', {}))
        navigate(URL_PATH.ADMIN_DEPARTMENT_MAPPING_CREATE, { state: { pageType: 'Create' } });
    }

    const deleteDepartmentFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let params = state.deleteDepartment
        dispatch(deleteDepartment(params))
            .then((response) => {
                if(response) {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : "Error in deleting"

                    if(response.status) {
                        messageType = 'success'
                        setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: true, callSearch: true, deleteModal: !state.deleteModal, showDeleteLoading: false }))}, 1000)
                    } else {
                        setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                    }


                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                } else {
                    setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                }
            })
    }

    const getTotalProjects = (data) => {
        let totalProjects = 0;
        data.initiatives.forEach(initiative => {
          totalProjects += initiative.projects.length;
        });
        return totalProjects;
    }

    const getTotalAccounts = (data) => {
        let totalAccounts = []
        data.initiatives.forEach(initiative => {
            initiative.projects.forEach(project => {
                project.accounts.forEach(acc => {
                    totalAccounts.push(acc.billingUnit)
                })
                // totalAccounts += project.accounts.length;
            })
        });
        
        return  [...new Set(totalAccounts)].length;
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap py-2 bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Manage Departments</p>
                        <p className="text-white text-base">Consolidated view of departments</p>
                    </div>
                </div>
            </div>

            <div>
                <p className='self-center text-black text-[28px] font-semibold'>Department List</p>
                <div className={`flex flex-wrap pb-6 justify-between`}> 
                    <div className="flex lg:w-3/5 w-full text-sm">
                        <p className="m-0 self-center pt-1">Showing {state.filterdListResponse && state.filterdListResponse.length} out of total {state.listResponse && state.listResponse.length} department(s)</p>
                    </div>
                                
                    <div className="flex flex-wrap justify-end lg:w-2/5 w-full mb-2">
                        {state.listResponse && state.listResponse.length ?
                            <div className='w-1/2'>
                                <Search
                                    data={state.listResponse ? state.listResponse : []}
                                    topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
                                    className={'rounded-full'}
                                    filteredData={(filterdListResponse) => setState(prevState => ({ ...prevState, filterdListResponse }))}
                                />
                            </div>
                        : null}
                        {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                            <Button
                                classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-3', margin: 'mx-2', text: 'text-white text-base font-semibold', others: 'cursor-pointer text-center'}}
                                label={'Create Department'}
                                callback={() => navigateTo()}
                            />
                        : null}
                        {state.filterdListResponse && state.filterdListResponse.length ?
                            <CSVLink 
                                data={state.filterdListResponse ? state.filterdListResponse : []} 
                                headers={state.headers ? state.headers : []}
                                filename={'job-lists.csv'}
                                className={'self-center'}
                                target='_blank'
                            >
                                <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-8 w-8 px-1 rounded-full self-center cursor-pointer' width={24} height={24} />
                            </CSVLink>
                        : null}
                    </div>
                </div>
            </div>

            <div className="relative overflow-x-auto rounded-t-lg shadow border border-lightGray-600">
                {state.filterdListResponse && state.filterdListResponse.length ?
                    <ResizeableDarkThemeTable
                        columns={[
                            {
                                Header: 'Department',
                                accessor: 'department',
                                width: 250,
                            },
                            {
                                Header: 'Initiatives',
                                // accessor: 'job_id',
                                Cell: cellInfo => (<span>{cellInfo.row.original.initiatives && cellInfo.row.original.initiatives.length ? cellInfo.row.original.initiatives.length : ''}</span>),
                                width: 350,
                            },
                            {
                                Header: 'Projects',
                                Cell: cellInfo => (<span>
                                    {getTotalProjects(cellInfo.row.original)}
                                </span>),
                                width: 250,
                            },
                            {
                                Header: 'Accounts',
                                Cell: cellInfo => (<span>
                                    {getTotalAccounts(cellInfo.row.original)}
                                </span>),
                                width: 250,
                            },
                            {
                                Header: 'Action',
                                Cell: cellInfo => (
                                    <div className="flex self-center">
                                        {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                                            <Link 
                                                to={URL_PATH.ADMIN_DEPARTMENT_MAPPING_CREATE} 
                                                state={{
                                                    selectedData: cellInfo.row.original,
                                                    pageType: 'Edit',

                                                }}
                                                className='text-primaryPurple-600'>
                                                Edit
                                            </Link>
                                        : null}
                                        {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                                            <p className='ml-3 mb-0 text-ferrariRed-600 cursor-pointer'
                                                onClick={() => {
                                                    let obj = {
                                                        message: 'Are you sure you want to delete the department' +(cellInfo.row.original.department ? ' "'+cellInfo.row.original.department+'"' : ''),
                                                        buttonLabel: "Yes, I'm sure",
                                                    }
                                                    setState(prevState => ({ ...prevState, confirmDetails: obj, deleteModal: true, deleteDepartment: cellInfo.row.original }))
                                                }}>Delete</p>
                                        : null}
                                    </div>
                                ),
                                width: 200,
                            },
                            {
                                Header: ' ',
                                width: 1,
                                // accessor: 'end_time',
                                // Cell: cellInfo => (<span className='text-info cursor-pointer'>Details</span>)
                            },
                        ]}
                        data={state.filterdListResponse}
                        perPage={10}
                        selectedColor={'bg-backGroundGray'}
                        dashboard={true}
                        sortByColumn={''}
                        riskTooltip={[0]}
                        onClickRow={tableRow => {}}
                    />
                : 
                    <div className='flex justify-center m-4 text-black'>
                        <p>{getCommonInformationMessage('department')}</p>
                    </div>
                }
            </div>

            {state.confirmDetails && state.deleteModal ?
                <AlertConfirmation 
                    confirmDetails={state.confirmDetails}
                    confirmation={(deleteModal) => {
                        if(deleteModal) {
                            deleteDepartmentFunction()
                        }
                        setState(prevState => ({ ...prevState, confirmDetails: false, deleteModal: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))
                    }}
                />
            : null}
        </div>
    );
};

export default LandingPage    