/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 10/04/2023
 * @copyright © 2023 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions

import MultiSelectSection from '../../common/MultiSelectSection';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { costGetCostTrend, getCostFilters, getCostFilterValues, listCostTemplates, getCostAllTagsKeys, getCostAllTagsValues, setCostPropsDetails, getCostAllocationTags } from '../../../actions/cost/CostAction'

import _ from 'lodash'

import moment from 'moment'
import { capitalizeAllLetter, currentUTCtime, momentDateGivenFormat } from '../../../utils/utility'

import ApexStackedBarChart from '../../common/charts/ApexStackedBarChart';

import StickyDarkThemeTable from '../../designComponents/Table/StickyDarkThemeTable';
import { CSVLink } from 'react-csv';
import Search from '../../common/SearchComponent';
import SavedFiltersSidePanel from './SavedFiltersSidePanel';
import { useCallback } from 'react';
import {Icon} from "@iconify/react";

const LandingPage = () => {
	const durationRef = useRef();

	const tagRef = useRef();
	const searchRef = useRef();
	
	const[state, setState] = useState({
		showLoading: true,
		providers: [],
		minMaxHeight: "770px",
		durationActive: true,
		selectedDuration: 'currentMonth',
		colors: ["#8e3a80", "#ff8200", "#D2291C", "#43B02A", "#0057b8", "#FFCD00"],
		// colors: ["#DEC9E9", "#D2B7E5", "#B185DB", "#7251B5", "#FFE169", "#FAD643", "#DBB42C", "#A47E1B", "#99E2B4", "#88D4AB", "#67B99A", "#358F80", "#FFE0E9", "#FF9EBB", "#E05780", "#8A2846"],
		transColor: ["#8e3a80", "#000000"],
		granualrityOptions: [
			{label: 'Daily', value: "daily"},
			{label: 'Hourly', value: "hourly"},
			{label: 'Monthly', value: "monthly"}
		],
		selectedGranularity: 'daily'
	})

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);

	const tagKeys = useSelector(state => state?.cost?.costTagKeys || false);
	const providers = useSelector(state => state?.filters?.providers || false);
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);
	const costFilterValues = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.costFilterValues ? state.cost.costPropsDetails.costFilterValues : {});

    const dispatch = useDispatch(); // Create a dispatch function

	useEffect(() => {
		if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callSearch: true }));
        } else {
			let params = {}
			dispatch(listAllProviders(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({
							...prevState,
							providers: response,
							selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '',
							callSearch: true,
							showLoading: response && !response.length ? false : true
						}));
					}
				})
		}
		
    }, [dispatch, providers]);
	
	useEffect(() => {
		if(state.showLoading) {
			setTimeout(() => { setTimeout(prevState => ({ ...prevState, showLoading: false })) }, 5000);
		}
	}, [state.showLoading])

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

	// useEffect(() => {
		
	// 	let params = {}
	// 	if(state.selectedProvider) {
	// 		params.provider = state.selectedProvider.toLowerCase()
	// 	}
	// 	if(state.selectedSource) {
	// 		params.source = state.selectedSource
	// 	}
	// 	if(state.selectedAccount && state.selectedAccount.length) {
	// 		params.account_id = state.selectedAccount
	// 	}

	// 	// params.aggregate_by = cValue.output_field

	// 	params.start_time = state.datePickerStartDate
	// 	params.end_time = state.datePickerEndDate
		
	// 	dispatch(getCostFilterValues(params))
	// 		.then((response) => {
	// 			if(response) {
	// 				setState(prevState => ({ ...prevState, accountsNew: response }))
	// 			}
	// 		})
	// }, [])

	useEffect(() => {
		if(state.selectedDuration) {
			let datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
			let datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
	
			setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));
		}
	}, [state.selectedDuration])

	useEffect(() => {
		if((state.datePickerStartDate && state.datePickerEndDate)) {
			setState(prevState => ({ ...prevState, callFormSelectedFitlersFunction: true }))

			let dateSelection= {
				startDate: new Date(state.datePickerStartDate),
				endDate: new Date(state.datePickerEndDate),
				key: 'selection'
			}

			setDateState([dateSelection])
		}
		

	}, [state.datePickerStartDate, state.datePickerEndDate])

	const getCostFiltersFunction = useCallback(() => {
		// let selectedProvider = state.selectedProvider.toLowerCase()
		let params = {}
		dispatch(getCostFilters(params))
			.then((response) => {
				if(response) {
					// let resResult = {}
					// let selectedSource = ""
					// if(selectedProvider && response[selectedProvider]) {
						// let results = response[selectedProvider]
						// if(results && Object.entries(results).length) {
						// 	resResult = results[Object.keys(results)[0]]
						// 	selectedSource = Object.keys(results)[0]
						// }
					// }
					
					//sort object
					// const sortedArray = Object.entries(resResult)
					// 	.sort(([, a], [, b]) => a.order - b.order);
					
					// const sortedObject = Object.fromEntries(sortedArray);

					// setState(prevState => ({ ...prevState, costFiltersResponse: response, costFilters: sortedObject, selectedSource, showLoading: selectedSource ? true : false }))
					setState(prevState => ({ ...prevState, costFiltersResponse: response }))
				}
			})
	}, [dispatch])

	// Call getCostFilters whenever the providers state is updated
    useEffect(() => {
        if (state.providers && state.providers.length) {
			let selectedProvider = state.selectedProvider.toLowerCase()
			if(state.costFiltersResponse && Object.entries(state.costFiltersResponse).length) {
				let resResult = {}
				let selectedSource = ""
				if(selectedProvider && state.costFiltersResponse[selectedProvider]) {
					let results = state.costFiltersResponse[selectedProvider]
					if(results && Object.entries(results).length) {
						resResult = results[Object.keys(results)[0]]
						selectedSource = Object.keys(results)[0]
					}
				}

				//sort object
				const sortedArray = Object.entries(resResult)
				.sort(([, a], [, b]) => a.order - b.order);
			
				const sortedObject = Object.fromEntries(sortedArray);

				setState(prevState => ({ ...prevState, costFilters: sortedObject, selectedSource, showLoading: selectedSource ? true : false, onChangeProvider: true }))

				// setState(prevState => ({ ...prevState, showLoading: true, onChangeProvider: true }))
			} else {
				getCostFiltersFunction();
			}
        }
    }, [state.providers, state.selectedProvider, state.costFiltersResponse, getCostFiltersFunction]);

	useEffect(() => {
		if(state.costFilters && Object.entries(state.costFilters).length) {
			setState(prevState => ({ ...prevState, callStructureCostFilterResponse: true, callListCostTemplatesFunction: true }))
			// structureCostFilterResponse()
			// listCostTemplatesFunction()
		}
	}, [state.costFilters])

	useEffect(() => {
		if(state.selectedSource && state.callListCostTemplatesFunction) {
			let params = {}
			// params.source = state.selectedSource
			// params.provider = state.selectedProvider
			dispatch(listCostTemplates(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, listData: response, filteredListData: response, searchResultState: response, callListCostTemplatesFunction: false }))
					}
				})
		}
	}, [dispatch, state.selectedSource, state.callListCostTemplatesFunction])
	
	useEffect(() => {
		if(state.callStructureCostFilterResponse) {
			setState(prevState => ({ ...prevState, callStructureCostFilterResponse: false }))
			let costFilters = state.costFilters
			costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
				if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
					if(cValue.default) {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: cValue.default, ["selected_value_"+cIndex]: cValue.default }))
					}
				} else {
					setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))
				}
	
				if(Object.entries(costFilters).length - 1 === cIndex) {
					setState(prevState => ({ ...prevState, showLoading: false, callFormSelectedFitlersFunction: true, callCostGetCostTrendFunction: true }))
				}
			})
		}
	}, [state.callStructureCostFilterResponse, state.costFilters])

	useEffect(() => {
		if(state.onChangeProvider) {
			let costFilters = state.costFilters
	
			costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
				if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
					if(cValue.default) {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: cValue.default }))
					} else {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "" }))
					}
				} else {
					setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))
					if(cValue.output_type === "string") {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "" }))
					} else {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: [] }))
					}
				}
	
				if(Object.entries(costFilters).length - 1 === cIndex) {
					let selectedProvider = state.selectedProvider.toLowerCase()
					let costSources = state.costFiltersResponse[selectedProvider] ? state.costFiltersResponse[selectedProvider] : {}
					
					let resResult = {}
					let selectedSource = ""
					if(costSources && Object.entries(costSources).length) {
						resResult = costSources[Object.keys(costSources)[0]]
						selectedSource = Object.keys(costSources)[0]
					}

					//sort object
					const sortedArray = Object.entries(resResult)
					.sort(([, a], [, b]) => a.order - b.order);
				
					const sortedObject = Object.fromEntries(sortedArray);
	
					setState(prevState => ({ ...prevState, costSources, costFilters: sortedObject, selectedSource, onChangeProvider: false }))
				}
			})
		}
	}, [state.onChangeProvider, state.costFilters, state.costFiltersResponse, state.selectedProvider])

	// function to search every object in array by recurseive
	const recursiveSearch = useCallback((item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] && item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}, [])
	// const recursiveSearch = useCallback((item, searchValue) => {
	// 	return Object.keys(item).map(key => {
	// 		if (item[key] !== null && typeof item[key] == 'object') {
	// 			return recursiveSearch(item[key], searchValue)
	// 			// eslint-disable-next-line valid-typeof
	// 		} else if (typeof item[key] === 'array') {
	// 			return item.map((arrayElement, i) => recursiveSearch(arrayElement[i], searchValue))
	// 		} else {
	// 			// if(props.searchFilter.includes(key)){
	// 			return item[key] !== null
	// 				? item[key]
	// 						.toString()
	// 						.toLowerCase()
	// 						.indexOf(searchValue.toString().toLowerCase()) > -1
	// 				: false
	// 			// }else{
	// 			// return false;
	// 			// }
	// 		}
	// 	})
	// }, [])

	useEffect(() => {
		let results = state.listData
		if (state.tempSearchText && state.tempSearchText.length > 2) {
			results =
				results &&
				results.filter(item => {
					let isPresent = []
					isPresent = recursiveSearch(item, state.tempSearchText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}

		setState(prevState => ({ ...prevState, searchResultState: results }))
	}, [state.tempSearchText, recursiveSearch, state.listData])

	const clearFilter = () => {
		let costFilters = state.costFilters

		costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
			state["filteredDropOptions_"+cIndex] && state["filteredDropOptions_"+cIndex].forEach((row, index) => {
				setState(prevState => ({ ...prevState, ["input_change_"+cIndex+"_"+index]: false }))
			})
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(cValue.default) {
					setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: cValue.default, ["selected_value_"+cIndex]: cValue.default }))
				} else {
					setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "", ["selected_value_"+cIndex]: "" }))
				}
			} else {
				setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))
				if(cValue.output_type === "string") {
					setState(prevState => ({ ...prevState, ["selected_value_"+cIndex]: "" }))
				} else {
					setState(prevState => ({ ...prevState, ["selected_value_"+cIndex]: [], ["select_all_"+cIndex]: false }))
				}
			}
		})

		setState(prevState => ({ ...prevState, clearFilter: true, durationActive: true, selectedDuration: "+30d", tagsSelected: [], selectedTags: [], selectedGranularity: 'daily' }))
	}

	useEffect(() => {
		if(state.clearFilter) {
			setState(prevState => ({ ...prevState, clearFilter: false, callFormSelectedFitlersFunction: true, callCostGetCostTrendFunction: true }))}
	}, [state.clearFilter])

	useEffect(() => {
		if(state.callCostGetCostTrendFunction && state.selectedSource) {
			setState(prevState => ({ ...prevState, callCostGetCostTrendFunction: false }))
			let params = {}
			params.provider = state.selectedProvider.toLowerCase()
			params.source = state.selectedSource
			if(state.selectedAccount && state.selectedAccount.length) {
				params.account_id = state.selectedAccount
			}
			if(state.selectedRegion && state.selectedRegion.length) {
				params.region = state.selectedRegion
			}
			params.duration_aggregate_by = state.selectedGranularity

			params.start_time = state.datePickerStartDate
			params.end_time = state.datePickerEndDate
			

			if(state.tagsSelected && state.tagsSelected) {
				params.tags = state.tagsSelected
			}
			if(state.forecast) {
				params.forecast = state.forecast
			}
			let excludes = {}
			let groupBy = ""
			state.costFilters && Object.entries(state.costFilters).forEach(([cKey, cValue], cIndex) => {
				if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
					if(state["input_change_"+cIndex] && state["input_change_"+cIndex] !== "") {
						if(cValue.output_field === "aggregate_by") {
							Object.entries(cValue.mapped_values).length && Object.entries(cValue.mapped_values).forEach(([mKey, mValue]) => {
								if(state["input_change_"+cIndex] === mValue) {
									groupBy = mKey
								}
							})
						}
						params[cValue.output_field] = state["input_change_"+cIndex]
					}
				} else {
					if(state["selected_value_"+cIndex] && state["selected_value_"+cIndex].length) {
						if(state["includes_"+cIndex] === "Includes") {
							params[cValue.output_field] = state["selected_value_"+cIndex]
						} else {
							excludes[cValue.output_field] = state["selected_value_"+cIndex]
						}
					}
				}
			})
	
			if(excludes && Object.entries(excludes).length) {
				params.excludes = excludes
			}
			if(params.aggregate_by && params.aggregate_by !== "") {
				params.radial = true				
			}
			
			let granualrity = ""
			if(params.duration_aggregate_by && params.duration_aggregate_by !== "") {
				granualrity = params.duration_aggregate_by
			}
	
			dispatch(costGetCostTrend(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, trendData: response.results ? response.results : [], applyLoading: false, trendGraphData: {}, groupBy, granualrity, showLoading: false }))
					}
				})

		}
	}, [state.callCostGetCostTrendFunction, dispatch, state])
	

	const trendGraphStructure = useCallback(() => {
        let graphData = {}
		let labels = []
		let forcastMessage = ""
        state.trendData && state.trendData.forEach(item => {
            if(item.data && Array.isArray(item.data)) {
				let spend = []
			    let forecast = []
			    item.dates.forEach((lab, index) => {
				    labels.push(lab)
				    spend.push(item.data[index])
					forecast.push(0)
			    })
				if(item.forecast && item.forecast.dates) {
				    item.forecast && item.forecast.dates.forEach((lab, index) => {					
				        labels.push(lab)
				        spend.push(0)
					    forecast.push(item.forecast.data[index])
				    })
				}
				graphData.labels = labels
				graphData.spend = spend
				if(item.forecast && item.forecast.dates) {
				    graphData.forecast = forecast
				}
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
			} else if(item.data && !Array.isArray(item.data) && Object.entries(item.data).length) {
				graphData.labels = item.dates ? item.dates : []
				labels.push()
				Object.entries(item.data).forEach(([key, value]) => {
					graphData[key] = value
				})
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
			} else if(item.data && !Array.isArray(item.data) && !Object.entries(item.data).length) {
				graphData.labels = item.dates ? item.dates : []
				let data = []
				graphData.labels.forEach(row => {
					data.push(0)
				})
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
				graphData["cost"] = data
			} else {
				graphData["cost"] = item.data
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
			}
        })
		
        setState(prevState => ({ ...prevState, trendGraphData: graphData, forcastMessage }))
    }, [state.trendData])

	const trendTableData = useCallback(() => {
		var trendTable = []
		state.trendData && state.trendData.forEach(item => {
            let labels = item.dates ? item.dates : []
            if(item.data && !Array.isArray(item.data) && Object.entries(item.data).length) {
				Object.entries(item.data).forEach(([key, value]) => {
					// let totalCount = value.reduce(function(a, b){
					// 	return a + b;
					// }, 0);
					var result = {
						"service": key,
						'total': item.count && item.count[key] ? item.count[key] : 0
					}
				
					value.forEach((item,index) => {
						result[labels[index]] = item
					})
					trendTable.push(result);
				})
			} else if(item.data && Array.isArray(item.data)) {

				let data = item.data
				// let totalCount = item.data.reduce(function(a, b){
				// 	return a + b;
				// }, 0);
				var result = {
					"service": "Cost",
					'total': item.total
				}
				
				data.forEach((item,index) => {
					if(momentDateGivenFormat(labels[index], "YYYY-MM-DD") <= momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
						result[labels[index]] = item
					}
				})
				
				if(item.forecast && item.forecast.dates) {
				    item.forecast && item.forecast.dates.forEach((lab, index) => {					
				        result[lab] = item.forecast.data[index]
				    })
				}

				trendTable.push(result);
			}
		})
		
		if(trendTable.length) {
			const columns = Object.entries(trendTable[0]).map(([tKey, tValue]) =>{
				let sticky = false
				let header = ''
				let width = '100%'
				let textColor = ''
				// if(state.durationSelector > 7) {
				// 	width = 75
				// }
				if(tKey === 'service') {
					sticky = true
					header = state.groupBy
					width = 300
				} else if(tKey === 'total') {
					sticky = true
					header = 'Total'
					width = 75
				} else {
					if(momentDateGivenFormat(tKey, "YYYY-MM-DD") > momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
						textColor = "text-info"
					}
					if(state.granualrity === 'monthly') {
						header = moment(tKey).format('MMM YY')
						width = 75
					} else  if(state.granualrity === 'daily') {
						header = moment(tKey).format('MMM DD')
						width = 75
					} else  {
						header = moment(tKey).format('HH mm')
						width = 75
					}
				}
				return {
					Header: header,
					accessor: tKey,
					width: width,
					sticky: sticky ? "left" : "",
					textColor: textColor
				}
			})
			
			setState(prevState => ({ ...prevState, trendTable, filteredArray: trendTable, columns }))
		} else {
			const columns = []	
			setState(prevState => ({ ...prevState, trendTable, filteredArray: trendTable, columns }))
		}
	}, [state.granualrity, state.groupBy, state.trendData])

	useEffect(() => {
		trendGraphStructure()
		trendTableData()
	}, [state.trendData, trendTableData, trendGraphStructure])

	useEffect(() => {
		if(state.filteredArray && state.filteredArray.length) {
			let headers = [] 
			Object.keys(state.filteredArray[0]).forEach(item => {
				let dataRow = {}
				dataRow.label = item
				dataRow.key = item
				headers.push(dataRow)
			})

			setState(prevState => ({ ...prevState, headers }))
		}
	}, [state.filteredArray])

	useEffect(() => {
		if(state.callOnChangeSourceFunction) {
			let selectedProvider = state.selectedProvider.toLowerCase()
			let resResult = {}
		
			let results = state.costFiltersResponse[selectedProvider] ? state.costFiltersResponse[selectedProvider] : {}
			if(results && Object.entries(results)) {
				resResult = results[state.selectedSource]
			}

			//sort object
			const sortedArray = Object.entries(resResult)
			.sort(([, a], [, b]) => a.order - b.order);
		
			const sortedObject = Object.fromEntries(sortedArray);
		
			setState(prevState => ({ ...prevState, costFilters: sortedObject, callOnChangeSourceFunction: false }))
		}
	}, [state.callOnChangeSourceFunction, state.costFiltersResponse, state.selectedProvide, state.selectedSource, state.selectedProvider])

	//on change filter items

    const handleSelect = (date) => {
		
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
        	setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate, showDateRangePicker: false, durationActive: false }))
		}

		setDateState([date.selection])
	}

	useEffect(() => {
		if(!tagKeys) {
			let params = {}
			dispatch(getCostAllTagsKeys(params))
		}
	}, [dispatch, tagKeys])

	useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ "key": state.tag_key }]
			dispatch(getCostAllTagsValues(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, tagValues: response }))
					}
				})
		}
	}, [dispatch, state.tag_key])

	// useEffect(() => {
	// 	if(state.tagsSelected && state.tagsSelected.filter(e => e.key === state.tag_key).length) {
	// 		let value = state.tagsSelected.filter(e => e.key === state.tag_key).map(e => e.value)
	// 		handleMultiSelectChange("tag_value", value, state.tagValues)			
	// 	}
	// }, [state.tagValues, state.tag_key, state.tagsSelected])

	const addNewTag = () => {
		let tagsSelected = state.tagsSelected ? state.tagsSelected : []

		if(state.tag_key && state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== state.tag_key)
			state.tag_value && state.tag_value.length && state.tag_value.forEach(row => {
				let dataRow = {
					key: state.tag_key,
					value: row,
				}
				filteredTags.push(dataRow)
			})

			setState(prevState => ({ ...prevState, tagsSelected: filteredTags, tag_key: "",  tag_value: "", tagValues: [] }))
		}
	}

	const removeTagSection = (tag) => {
		let filteredResult = state.tagsSelected.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, tagsSelected: filteredResult }))
	}

	const onClickGetDropOptions = (cKey, cValue, cIndex) => {
		
		let params = {}
		if(state.selectedProvider) {
			params.provider = state.selectedProvider.toLowerCase()
		}
		if(state.selectedSource) {
			params.source = state.selectedSource
		}
		if(state.selectedAccount && state.selectedAccount.length) {
			params.account_id = state.selectedAccount
		}
		if(state.selectedRegion && state.selectedRegion.length) {
			params.region = state.selectedRegion
		}
		let filterSelected = []
		state.costFilters && Object.entries(state.costFilters).forEach(([cKey, cValue], cIndex) => {			
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(state["input_change_"+cIndex] && state["input_change_"+cIndex] !== "") {
					if(cValue.output_field === "duration_aggregate_by") {
						params[cValue.output_field] = state["input_change_"+cIndex]
						filterSelected.push(cValue.output_field)
					}
				}
			}
			
			if(cValue.output_field === "resource_type") {
				if(state["selected_value_"+cIndex] && Array.isArray(state["selected_value_"+cIndex]) && state["selected_value_"+cIndex].length) {
					params[cValue.output_field] = state["selected_value_"+cIndex]
					filterSelected.push(cValue.output_field)
				} else if(state["selected_value_"+cIndex] && !Array.isArray(state["selected_value_"+cIndex])) {
					params[cValue.output_field] = state["selected_value_"+cIndex]
					filterSelected.push(cValue.output_field)
				}
			}
		})

		params.aggregate_by = cValue.output_field

		params.start_time = state.datePickerStartDate
		params.end_time = state.datePickerEndDate
		// params.radial = true
		// removeSpacebtwString
		if(!cValue.hasOwnProperty("mapped_values") && !cValue.hasOwnProperty("values")) {
			if(!costFilterValues.hasOwnProperty(state.selectedSource+"_"+cValue.output_field)) {
				dispatch(getCostFilterValues(params))
					.then((response) => {
                        if(response) {
							let results = response.results ? response.results : []
							setState(prevState => ({
								...prevState, 
								["hideDropLoading_"+cIndex]: true, 
								["dropOptions_"+cIndex]: results, 
								["filteredDropOptions_"+cIndex]: results,
								filterSelected,
							}))

							if(results.length && _.isEqual(filterSelected, state[filterSelected])) {
								let obj = costFilterValues
								obj[state.selectedSource+"_"+cValue.output_field] = results
								dispatch(setCostPropsDetails("costFilterValues", obj))
							} else {
								let obj = {}
								obj[state.selectedSource+"_"+cValue.output_field] = results
								dispatch(setCostPropsDetails("costFilterValues", obj))
							}
						}
					})
			} else {
				setState(prevState => ({
					...prevState, 
					["hideDropLoading_"+cIndex]: true, 
					["dropOptions_"+cIndex]: costFilterValues[state.selectedSource+"_"+cValue.output_field], 
					["filteredDropOptions_"+cIndex]: costFilterValues[state.selectedSource+"_"+cValue.output_field]
				}))
			}
		}

		state.costFilters && Object.entries(state.costFilters).forEach(([loopKey, loopValue], loopIndex) => {
			if(loopIndex !== cIndex) {
				setState(prevState => ({ ...prevState, ["showDetails_"+loopIndex]: false }))
			} else {
				setState(prevState => ({ ...prevState,  ["showDetails_"+cIndex]: !prevState["showDetails_"+cIndex] }))
			}
		})

		setState(prevState => ({ ...prevState, showDateRangePicker: false, showTagFilter: false }))
	}

    const searchData  = (e, searchKey, cIndex, state) => {
		searchKey = searchKey.toLowerCase()
        let newList = []
		if (searchKey !== "" && searchKey.length >=3 ) {
			let currentList = state
			if(Array.isArray(currentList) && currentList.every(item => typeof item === 'string')) {
				let searchTerm = searchKey.toLowerCase();
				newList = currentList.filter(e => e.toLowerCase().includes(searchTerm));
			} else if(typeof currentList === 'object') {
				newList = Object.entries(currentList).filter(([key, value]) => 
					key.toLowerCase().includes(searchKey) || value.toLowerCase().includes(searchKey)
				).reduce((obj, [key, value]) => {
					obj[key] = value;
					return obj;
				}, {});
			} else {
				newList =
					currentList &&
					currentList.filter(item => {
						let isPresent = []
						isPresent = recursiveSearch(item, searchKey)
							.flat(Infinity)
							.filter(bool => bool === true)  // for multi arrray [[1,2],[3,4],[4,5]]
						if (isPresent[0]) {
							return true
						} else {
							return false
						}
					})
			}
		} else {
            newList = state
		}
        // if (searchKey !== "" && searchKey.length >=3 ) {
        //     let currentList = state
		// 	console.log("currentList", currentList)
        //     newList =
        //         currentList &&
        //         currentList.filter(item => {
        //             let isPresent = []
        //             if(typeof item === "string") {
        //                 return item !== null
        //                     ? item
        //                             .toString()
        //                             .toLowerCase()
        //                             .indexOf(searchKey.toString().toLowerCase()) > -1
        //                     : false
        //             } else {
        //                 isPresent = recursiveSearch(item, searchKey)
        //                     .flat()
        //                     .filter(bool => bool === true)
        //                 if (isPresent[0]) {
        //                     return true
        //                 } else {
        //                     return false
        //                 }
        //             }
                    
        //         })
        // } else {
        //     newList = state
		// }
        
        setState(prevState => ({ ...prevState, ["filteredDropOptions_"+cIndex]: newList }))
    }

	const onChangeSelectInput = (state, value, field, index, cIndex, cKey) => {
		if(cKey === 'Group by' && value === 'TAG') {
			
			dispatch(getCostAllocationTags({}))
				.then((response) => {
					let tagDropOPtions = response && response.results ? response.results : []

					setState(prevState => ({ ...prevState, ["tagDropOPtions_"+cIndex]: tagDropOPtions }))
				})
		} else if(cKey === 'Group by' && value !== 'TAG') {
			let aggregate_by_tag = ''
			setState(prevState => ({ ...prevState, aggregate_by_tag }))
			setState(prevState => ({ ...prevState, ["tagDropOPtions_"+cIndex]: [] }))
		}
		
		setState(prevState => ({ ...prevState, [field+"_"+cIndex]: value, ["selected_value_"+cIndex]: value, callFormSelectedFitlersFunction: true, ["showDetails_"+cIndex]: false }))
	}

	const onChangeMultiSelectInput = (options, value, field, index, cIndex) => {
		let selectedValue = state["selected_value_"+cIndex] ? state["selected_value_"+cIndex] : []
		if(value !== "All") {
			if(state[field+"_"+cIndex+"_"+index]) {
				selectedValue = selectedValue.filter(e => e !== value)
				setState(prevState => ({ ...prevState, ["select_all_"+cIndex]: false, [field+"_"+cIndex+"_"+index]: !prevState[field+"_"+cIndex+"_"+index], ["selected_value_"+cIndex]: selectedValue, callFormSelectedFitlersFunction: true }))
			} else {
				selectedValue.push(value)
				setState(prevState => ({ ...prevState, [field+"_"+cIndex+"_"+index]: !prevState[field+"_"+cIndex+"_"+index], ["selected_value_"+cIndex]: selectedValue, callFormSelectedFitlersFunction: true }))
			}
		} else {
			if(state["select_all_"+cIndex]) {
				let selectedValue = []
				options.forEach((row, rIndex) => {
					setState(prevState => ({ ...prevState, ["select_all_"+cIndex]: false, [field+"_"+cIndex+"_"+rIndex]: false, ["selected_value_"+cIndex]: selectedValue, callFormSelectedFitlersFunction: true }))
				})
			} else {
				let selectedValue = []
				options.forEach((row, rIndex) => {
					selectedValue.push(row)
					setState(prevState => ({ ...prevState, ["select_all_"+cIndex]: true, [field+"_"+cIndex+"_"+rIndex]: true, ["selected_value_"+cIndex]: selectedValue, callFormSelectedFitlersFunction: true }))
				})
			}
		}
	}

	useEffect(() => {
		if(state.callFormSelectedFitlersFunction) {
			let selectedFilters = []
			
			if(state.datePickerStartDate) {
				let dataRow = {}
				dataRow.label = "Duration"
				dataRow.start_time = state.datePickerStartDate
				dataRow.end_time = state.datePickerEndDate

				selectedFilters.push(dataRow)
			}

			if(state.tagsSelected && state.tagsSelected.length) {
				let dataRow = {}
				dataRow.label = "Tags"
				dataRow.output_field = "tags"
				dataRow.value = state.selectedTags

				selectedFilters.push(dataRow)
			}

			if(state.selectedAccount && state.selectedAccount.length) {
				let dataRow = {}
				dataRow.label = "Account"
				dataRow.output_field = "account_id"
				dataRow.type = 'Includes'
				dataRow.value = state.selectedAccount

				selectedFilters.push(dataRow)
			}

			if(state.selectedGranularity) {
				let dataRow = {}
				dataRow.label = "Granularity"
				dataRow.output_field = "duration_aggregate_by"
				dataRow.value = state.selectedGranularity

				selectedFilters.push(dataRow)
			}

			state.costFilters && Object.entries(state.costFilters).forEach(([cKey, cValue], cIndex) => {
				let dataRow = {}
				if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
					if(state["input_change_"+cIndex] && state["input_change_"+cIndex] !== "") {
						let outputLabel = state["input_change_"+cIndex]
						if(Object.entries(cValue.mapped_values).length) {
							outputLabel = Object.keys(cValue.mapped_values).find(key => cValue.mapped_values[key] === state["input_change_"+cIndex]);	
						}
						dataRow.label = cKey
						dataRow.output_label = outputLabel
						dataRow.output_field = cValue.output_field
						dataRow.value = state["input_change_"+cIndex]
					}
				} else {
					if(state["selected_value_"+cIndex] && state["selected_value_"+cIndex].length) {
						dataRow.label = cKey
						dataRow.output_field = cValue.output_field
						dataRow.type = state["includes_"+cIndex]
						dataRow.value = state["selected_value_"+cIndex]
					}
				}
				
				if(Object.entries(dataRow).length) {
					selectedFilters.push(dataRow)
				}
			})

			if(state.aggregate_by_tag) {
				let dataRow = {}
				dataRow.label = "aggregate_by_tag"
				dataRow.output_field = "aggregate_by_tag"
				dataRow.value = state.aggregate_by_tag

				selectedFilters.push(dataRow)
			}

			setState(prevState => ({ ...prevState, selectedFilters, callFormSelectedFitlersFunction: false }))
		}
	}, [state.callFormSelectedFitlersFunction, state])

	const handleChildClick = (event, type, dropdownType, section) => {
        if (tagRef.current && !tagRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showTagFilterSection: false }))
		}

		if (durationRef.current && !durationRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDateRangePicker: false }))
		}

		if (searchRef && !searchRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDropdown: false }))
		}
        
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	const refreshFilters = (row) => {		
		let savedFilters = row.filters
		if(row["Duration"]) {
			setState(prevState => ({ 
				...prevState,
				datePickerStartDate: row["Duration"].start_time,
				datePickerEndDate: row["Duration"].end_time
			}))
		}

		if(row["Tags"]) {
			setState(prevState => ({ ...prevState, selectedTags: row["Tags"].value, tagsSelected: row["Tags"].value }))
		}


		let costFilters = state.costFilters

		costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(savedFilters.filter(e => e.output_field === cValue.output_field).length) {
					setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: savedFilters.filter(e => e.output_field === cValue.output_field)[0].value }))
				} else {
					setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "" }))
				}
			} else {
				if(savedFilters.filter(e => e.output_field === cValue.output_field).length) {
					setState(prevState => ({ 
						...prevState, 
						["input_change_"+cIndex]: savedFilters.filter(e => e.output_field === cValue.output_field)[0].value, 
						["includes_"+cIndex]: savedFilters.filter(e => e.output_field === cValue.output_field)[0].type
					}))
				} else {
					if(cValue.output_type === "string") {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "" }))
					} else {
						setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: [] }))
					}
				}
			}

			if(Object.entries(costFilters).length - 1 === cIndex) {
				setState(prevState => ({ 
					...prevState, 
					durationActive: false,
					// selectedFilters: [],
					refereshFilters: true,
				}))}
		})
	}

	useEffect(() => {
		if(state.refereshFilters) {
			setState(prevState => ({ ...prevState, refereshFilters: false, callFormSelectedFitlersFunction: true, callCostGetCostTrendFunction: true }))}
	}, [state.refereshFilters])

	const onClickMore = (row) => {
		setState(prevState => ({ ...prevState, savedFilters: row, templateType: "view", clickedMore: true }))
	}

	useEffect(() => {
		if(state.clickedMore){
			setState(prevState => ({ ...prevState, clickedMore: false, showTemplatesSection: true }))
		}
	}, [state.clickedMore])

	const handleSearchChange = e => {
        const { name, value } = e.target;
		// let params = {}
		// params.template_name = value
		// if(value.length > 3) {
		// 	dispatch(listCostTemplates(params))
		// 		.then((response) => {
		// 			if(response) {					
		// 				setState(prevState => ({ ...prevState, searchTemplateList: response }))
		// 			}
		// 		})
		// }

		setState(prevState => ({ ...prevState, [name]: value, searchResultState: [], isLoading: true }))
	}

	/**
	 * Renders
	 */
	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
			{state.showTemplatesSection ?
				<SavedFiltersSidePanel
					closeSidePanel={() => setState(prevState => ({ ...prevState, showTemplatesSection: false, savedFilters: [], callListCostTemplatesFunction: true }))}
					templateType={state.templateType}
					selectedSource={state.selectedSource}
					selectedFilters={state.savedFilters && Object.entries(state.savedFilters).length ? state.savedFilters : state.selectedFilters}
				/>
			: null}
            <div className="bg-white w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-2 ml-4">
					<div className="xl:col-span-3 col-span-12 self-center xl:mt-4">
						<p className="text-black text-xl md:mt-0 mt-4 font-semibold">Cost Explorer</p>
						<p className='text-black m-0'>New cost and usage report</p>
					</div>
					                    
                    <div className="xl:col-span-9 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4 flex flex-wrap justify-end pr-2 gap-2">
						<div className={`bg-GhostWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer w-[120px]`}
							onClick={(event) => {
								if(!state.isProviderOpen) {
									event.preventDefault();
									handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
								}
							}}
						>
							<p className="text-xs text-black font-semibold">Provider</p>
							<p className={`flex justify-between text-sm font-medium mt-3 mb-0 ${state.selectedProvider ? 'text-black' : 'text-lightGray'}`}>
								{state.selectedProvider ? state.selectedProvider : 'Select'}
								<Icon icon="icon-park-solid:down-one" className={`${state.selectedProvider ? "text-black" : "text-lightGray"}`} width="16" height="16" />
							</p>
							{state.isProviderOpen ?
								<div className='relative'>
									<MultiSelectSection 
										fields={["provider_name", "provider_name"]}
										options={state.providers}
										selectedValues={state.selectedProvider ? state.selectedProvider : ''}
										callbackMultiSelect={(value) => {
											if(!value || typeof(value) === 'string') {
												setState(prevState => ({ ...prevState, selectedProvider: value }))
											} else {
												value.preventDefault()
												handleChildClick(value, "search", 'singleDropDown', "")
											}
										}}
										singleSelection={true}
										widthClass={'minWidth220'}
										removeTopOptions={true}
									/>
								</div>
							: null}
						</div>
						<div className={`bg-GhostWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer w-[120px]`}
							onClick={(event) => {
								if(!state.isSourceOpen) {
									event.preventDefault();
									handleChildClick(event, 'child', 'singleDropDown', "isSourceOpen")
								}
							}}
						>
							<p className="text-xs text-black font-semibold">Source</p>
							<p className={`flex justify-between text-sm font-medium mt-3 mb-0 ${state.selectedSource ? 'text-black' : 'text-lightGray'}`}>
								{state.selectedSource ? capitalizeAllLetter(state.selectedSource) : 'Select'}
								<Icon icon="icon-park-solid:down-one" className={`${state.selectedSource ? "text-black" : "text-lightGray"}`} width="16" height="16" />
							</p>
							{state.isSourceOpen ?
								<div className='relative'>
									<MultiSelectSection 
										options={state.costSources ? Object.keys(state.costSources) : []}
										selectedValues={state.selectedSource ? state.selectedSource : []}
										callbackMultiSelect={(value) => {
											if(!value || typeof(value) === 'string') {
												setState(prevState => ({ ...prevState, selectedSource: value, callOnChangeSourceFunction: true }))
											} else {
												value.preventDefault()
												handleChildClick(value, "search", 'singleDropDown', "")
											}
										}}
										singleSelection={true}
										widthClass={'minWidth220'}
										removeTopOptions={true}
										// makeLabelUpperCase={'capitalizeAllLetter'}
									/>
								</div>
							: null}
						</div>
						<div className={`bg-GhostWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer w-[120px]`}
							onClick={(event) => {
								event.preventDefault();
								handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
							}}
						>
							<p className="text-xs text-black font-semibold">Account</p>
							<p className={`flex justify-between text-sm font-medium mt-3 mb-0 ${state.selectedAccount && state.selectedAccount.length ? 'text-black' : 'text-lightGray'}`}>
								{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}
								<Icon icon="icon-park-solid:down-one" className={`${state.selectedAccount && state.selectedAccount.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />
							</p>
							{state.isAccountOpen ?
								<div className='relative'>
									<MultiSelectSection 
										fields={["account_id", "account_name"]}
										options={state.accounts}
										selectedValues={state.selectedAccount ? state.selectedAccount : []}
										callbackMultiSelect={(value) => {
											setState(prevState => ({ ...prevState, selectedAccount: value }))
											clearFilter()
										}}
										widthClass={'minWidth220'}
										removeTopOptions={true}
									/>
								</div>
							: null}
						</div>
						<div className={`bg-GhostWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer w-[120px]`}
							onClick={(event) => {
								event.preventDefault();
								handleChildClick(event, 'child', 'muliselectDropDown', "isRegionOpen")
							}}
						>
							<p className="text-xs text-black font-semibold">Region</p>
							<p className={`flex justify-between text-sm font-medium mt-3 mb-0 ${state.selectedRegion && state.selectedRegion.length ? 'text-black' : 'text-lightGray'}`}>
								{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}
								<Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion && state.selectedRegion.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />
							</p>
							{state.isRegionOpen ?
								<div className='relative'>
									<MultiSelectSection 
										fields={["region", "name"]}
										options={state.regions}
										selectedValues={state.selectedRegion ? state.selectedRegion : []}
										callbackMultiSelect={(value) => {
											setState(prevState => ({ ...prevState, selectedRegion: value }))
											clearFilter()
										}}
										widthClass={'minWidth220'}
										removeTopOptions={true}
									/>
								</div>
							: null}
						</div>
						<div className={`bg-GhostWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer w-[120px]`}
							onClick={(event) => {
								if(!state.isGranularityOpen) {
									event.preventDefault();
									handleChildClick(event, 'child', 'singleDropDown', "isGranularityOpen")
								}
							}}
						>
							<p className="text-xs text-black font-semibold">Granularity</p>
							<p className={`flex justify-between text-sm font-medium mt-3 mb-0 ${state.selectedGranularity ? 'text-black' : 'text-lightGray'}`}>
								{state.selectedGranularity ? capitalizeAllLetter(state.selectedGranularity) : 'Select'}
								<Icon icon="icon-park-solid:down-one" className={`${state.selectedGranularity ? "text-black" : "text-lightGray"}`} width="16" height="16" />
							</p>
							{state.isGranularityOpen ?
								<div className='relative'>
									<MultiSelectSection 
										fields={["value", "label"]}
										options={state.granualrityOptions}
										selectedValues={state.selectedGranularity ? state.selectedGranularity : []}
										callbackMultiSelect={(value) => {
											if(!value || typeof(value) === 'string') {
												setState(prevState => ({ ...prevState, selectedGranularity: value, callOnChangeSourceFunction: true }))
											} else {
												value.preventDefault()
												handleChildClick(value, "search", 'singleDropDown', "")
											}
										}}s
										singleSelection={true}
										widthClass={'minWidth220'}
										removeTopOptions={true}
										makeLabelUpperCase={'capitalizeAllLetter'}
									/>
								</div>
							: null}
						</div>
						<div className={`bg-GhostWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer w-[120px] relative`} ref={tagRef}>
							<div className="" onClick={() => setState(prevState => ({ ...prevState, showTagFilterSection: true }))}>
								<label className='my-1 flex'>Tags</label>
								<p className={`flex justify-between mb-0 truncate ${state.selectedTags && state.selectedTags.length ? 'text-black' : 'text-lightGray'}`}>
									{state.selectedTags && state.selectedTags.length ? state.selectedTags.length+' Selected' : 'Select'}
									<Icon icon="icon-park-solid:down-one" className={`${state.selectedTags && state.selectedTags.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />
								</p>
							</div>
							{state.showTagFilterSection ?
								<div className="absolute top80 z-10 border-lightGray mt-1 w-[500px] right-0">
									<div className="p-3 bg-GhostWhite">
										<div className={`w-full mt-1`}>
											<p className="mb-2 w-100 text-black">Tags</p>
											<div className={`flex mt-3`}>
												<div className={`mr-3 w-2/5 relative`}
													onClick={(event) => {
														if(!state.isTagKey) {
															event.preventDefault();
															handleChildClick(event, 'child', 'singleDropDown', "isTagKey")
														}
													}}
												>
													<label className='my-1 flex'>Key</label>
													<p className={`border-b border-lightGray px-2 py-1 flex justify-between mb-0 truncate ${state.tag_key ? 'text-black' : 'text-lightGray'}`}>
														{state.tag_key ? state.tag_key : 'Select'}
														<Icon icon="icon-park-solid:down-one" className={`${state.tag_key ? "text-black" : "text-lightGray"}`} width="16" height="16" />
													</p>
													{state.isTagKey ?
														<MultiSelectSection
															options={tagKeys}
															selectedValues={state.tag_key ? state.tag_key : ''}
															callbackMultiSelect={(value) => {
																if(!value || typeof(value) === 'string') {
																	setState(prevState => ({ ...prevState, tag_key: value, tagValues: [] }))
																} else {
																	value.preventDefault()
																	handleChildClick(value, "search", 'singleDropDown', "")
																}
															}}  
															singleSelection={true}
															hideSearch={false}
															className={'top80 left-0'}
															widthClass={'minWidth220'}
															removeTopOptions={true}
														/>
													: null}
												</div>
												{state.tag_key && state.tag_key !== "" && !state.tagValues.length ?
													<div className='ml-3'>
														<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
															<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
															<path d="M4 12a8 8 0 0112-6.9" />
														</svg>
													</div>
												:
													<React.Fragment>
														<div className={`mr-3 w-2/5 relative`}
															onClick={(event) => {
																event.preventDefault();
																handleChildClick(event, 'child', 'muliselectDropDown', "isTagValue")
															}}
														>
															<label className='my-1 flex'>Value</label>
															<p className={`border-b border-lightGray px-2 py-1 flex justify-between mb-0 truncate ${state.tag_value && state.tag_value.length ? 'text-black' : 'text-lightGray'}`}>
																{state.tag_value && state.tag_value.length ? state.tag_value.length+' Selected' : 'Select'}
																<Icon icon="icon-park-solid:down-one" className={`${state.tag_value && state.tag_value.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />
															</p>
															{state.isTagValue ?
																<div className='relative'>
																	<MultiSelectSection
																		options={state.tagValues}
																		selectedValues={state.tag_value ? state.tag_value : []}
																		callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, tag_value: value }))}
																		widthClass={'minWidth220'}
																		removeTopOptions={true}
																	/>
																</div>
															: null}
														</div>
														<div className='w-1/5 self-center'>
															<Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 cursor-pointer' onClick={() => addNewTag("tags")} />
														</div>
													</React.Fragment>
												}
											</div>
										</div>
										{state.tagsSelected && state.tagsSelected.length ? 
											<div className='flex flex-wrap'>
												{state.tagsSelected.map((tag, index) => {
													return(
														<span key={'tt_'+index} className="flex border border-info rounded-full px-2 py-1 mr-2 mt-2 self-center">{tag.key +(tag.value ? " : "+tag.value : "")}
															<Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-black ml-2 self-center' onClick={ () => removeTagSection(tag) } />
														</span>
													)
												})}
											</div>
										: null}
										
										<div className="flex mt-3">
											<span className={`text-info mr-2 self-center cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, tagsSelected: [], selectedTags: [], tag_key: "", tag_value: [], tagValues: [] }))}>Clear</span>
											<button className={`btn bg-info px-2 py-1 text-black rounded-md cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, showTagFilter: false, selectedTags: state.tagsSelected, callFormSelectedFitlersFunction: true }))}>Select</button>
										</div>
									</div>
								</div>
							: null}
						</div>
						<div className={`bg-GhostWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer w-[180px] relative`} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: true }))}>
							<p className="text-xs text-black font-semibold">Duration</p>
							<p className={`flex mt-2 mb-1 ${state.datePickerStartDate ? 'text-black' : 'placeholder'}`}>
								{state.datePickerStartDate ?
									<span> {momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY')}</span>
								: 'Select'}
							</p>
						</div>
						{state.showDateRangePicker ?
							<div className='relative'>
								<div className="bg-BlockWhite border border-lightGray p-3 absolute right-0 z-10 h-[500px] top-10" ref={durationRef}>
									<div className="text-black5 pt-2 ml-n1">
										<div className='flex justify-end'>
											{/* <p className="mb-2 w-100 text-black">Date Picker</p> */}
											<Icon icon="jam:close" className='text-black cursor-pointer' width="25" height="25" onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false }))} />
										</div>
										<div style={{zIndex: '999999999','right': '30px', 'top': '50px'}}>
											<DateRange
												editableDateInputs={true}
												onChange={handleSelect}
												moveRangeOnFirstSelection={false}
												ranges={dateState}
											/>
										</div>
										<div className={`flex flex-wrap pt-2 border-top mt-4`}>
											<span className={`mr-2 text-xs self-center text-black`}>Last</span>
											<span className={`mr-2 font-semibold text-xs cursor-pointer self-center ${state.selectedDuration === "+7d" ? "bg-info px-2 py-1 rounded-full text-black" : "text-info"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+7d" }))}>1 Week</span>
											<span className={`mr-2 font-semibold text-xs cursor-pointer self-center ${state.selectedDuration === "+14d" ? "bg-info px-2 py-1 rounded-full text-black" : "text-info"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+14d" }))}>2 Week</span>
											<span className={`mr-2 font-semibold text-xs cursor-pointer self-center ${state.selectedDuration === "+30d" ? "bg-info px-2 py-1 rounded-full text-black" : "text-info"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+30d" }))}>1 Month</span>
											<span className={`mr-2 font-semibold text-xs cursor-pointer self-center ${state.selectedDuration === "+60d" ? "bg-info px-2 py-1 rounded-full text-black" : "text-info"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+60d" }))}>2 Month</span>
											<span className={`mr-2 font-semibold text-xs cursor-pointer self-center ${state.selectedDuration === "+90d" ? "bg-info px-2 py-1 rounded-full text-black" : "text-info"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+90d" }))}>3 Months</span>
										</div>
										<div className='flex justify-end pt-2 mt-2 border-top'>
											<button className={`btn-sm bg-light text-decoration-nonep-2 text-black mr-3`} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false }))}>Cancel</button>
										</div>
									</div>
								</div>
							</div>
						: null}
					</div>
				</div>
			</div>

			<div className='m-3'>
                <div className='flex flex-wrap'>
					<div className='lg:w-3/5 w-full flex flex-col'>
						<p className='font-semibold m-0 f15 text-black'>Trends</p>
						{state.forcastMessage && state.forcastMessage !== "" ?
							<h6 className='text-danger mb-1'>{state.forcastMessage}</h6>
						: null}
						{!state.showSlectedFilters ?
							<div className='flex pt-2 justify-between'>
								<div className='flex'>
									{state.selectedFilters && state.selectedFilters.length ? 
										<React.Fragment>
											{state.selectedFilters.filter(e => e.label === "Account").length ?
												<div className='flex mr-2'>
													<small className="font-semibold self-center cursor-pointer text-lightGray text-md">Account : {state.selectedFilters.filter(e => e.label === "Account")[0].value} </small>
												</div>
											: 
												<small className="font-semibold self-center cursor-pointer text-lightGray text-md mr-2">Account : <span className='text-info'>All</span></small>
											}	

											{state.selectedFilters.filter(e => e.label === "Region").length ?
												<div className='flex mx-2'>
													<small className="font-semibold self-center cursor-pointer text-lightGray text-md">Region : {state.selectedFilters.filter(e => e.label === "Region")[0].value} </small>
													{/* {state.selectedFilters.filter(e => e.label === "Region")[0].value.length > 1 ?
														<span className="text-fino mx-2 mt-2 self-center f10 px-2 cursor-pointer" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: !state.showSlectedFilters }))}>more</span>
													: null} */}
												</div>
											: 
												<small className="font-semibold self-center cursor-pointer text-lightGray text-md mx-2">Region : <span className='text-info'>All</span></small>
											}
											{state.selectedFilters.filter(e => e.label === "Granularity").length ?
												<div className='flex ml-2'>
													<small className="font-semibold self-center cursor-pointer text-lightGray text-md mr-2">Granularity : <span className="text-info">{state.selectedFilters.filter(e => e.label === "Granularity")[0].output_label ? state.selectedFilters.filter(e => e.label === "Granularity")[0].output_label : state.selectedFilters.filter(e => e.label === "Granularity")[0].value}</span> </small>
												</div>
											: null}
											{state.selectedFilters.filter(e => e.label === "Group by").length ?
												<div className='flex ml-2'>
													<small className="font-semibold self-center cursor-pointer text-lightGray text-md mr-2">Group by : <span className="text-info">{state.selectedFilters.filter(e => e.label === "Group by")[0].output_label ? state.selectedFilters.filter(e => e.label === "Group by")[0].output_label : state.selectedFilters.filter(e => e.label === "Group by")[0].value}</span> </small>
												</div>
											: null}
											{state.selectedFilters.filter(e => e.label === "Duration").length ?
												<div className='flex ml-2'>
													<small className="font-semibold self-center cursor-pointer text-lightGray text-md mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(state.selectedFilters.filter(e => e.label === "Duration")[0].start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.selectedFilters.filter(e => e.label === "Duration")[0].end_time, 'DD MMM YYYY')}</span> </small>
												</div>
											: null}
										</React.Fragment>
									: null}
								</div>
								{state.selectedFilters && state.selectedFilters.length ? 
									<p className="text-info mb-0 font-semibold self-center cursor-pointer mr-2 ml-4" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: !state.showSlectedFilters }))}>{state.selectedFilters.length} Filters Selected</p>
								: null}
							</div>
						: null}
						{state.showSlectedFilters ?
							<div className="bg-lightGray p-3">
								<div className='flex justify-between mb-1'>
									<p className="mb-0 w-100 text-black">Selected Filters</p>
									<Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-black ml-2 self-center' onClick={() => setState(prevState => ({ ...prevState, showSlectedFilters: false }))} />
								</div>
								<div className='flex flex-wrap'>
									{!state.selectedFilters.filter(e => e.label === "Account").length ?
										<small className="font-semibold self-center text-lightGray cursor-pointer mr-2 mt-2">Account : <span className='text-info'>All</span></small>
									: null}

									{!state.selectedFilters.filter(e => e.label === "Region").length ?
										<small className="font-semibold self-center text-lightGray cursor-pointer mx-2 mt-2">Region : <span className='text-info'>All</span></small>
									: null}
									
									{state.selectedFilters && state.selectedFilters.length ? 
										state.selectedFilters.map((filters, index) => {
											return(
												<React.Fragment key={'fil_'+index}>
												{filters.label === "Duration" ?
													<React.Fragment>
														<small className="font-semibold self-center text-lightGray cursor-pointer mt-2 mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(filters.start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(filters.end_time, 'DD MMM YYYY')}</span> </small>
													</React.Fragment>
												: filters.value && !Array.isArray(filters.value) ?
													<span className="bg-info rounded-full py-1 text-black mr-2 mt-2 self-center px-2"><span className='text-darkGray'>{filters.label+" : "}</span>{filters.output_label ? filters.output_label : filters.value}</span>
												: filters.label === "Tags" ?
													<div className='flex flex-wrap'>
														{filters.value.map(row => {
															return(
																<span className="bg-info px-2 py-1 rounded-full text-black mr-2 mt-2 self-center">
																	<span className='text-darkGray'>Tag :</span> 
																	{row.key +" : "+row.value}
																</span>
															)
														})}
													</div>
												:
													<div className='flex flex-wrap'>
														{filters.value.map(row => {
															return(
																<span className="bg-info px-2 py-1 rounded-full text-black mr-2 mt-2 self-center">
																	<span className='text-darkGray'>{filters.type+" : "}</span> 
																	{filters.label +" : "+row}
																</span>
															)
														})}
													</div>
												}
												</React.Fragment>
											)
										})
									: null}
								</div>
							</div>
						: null}
					</div>
					<div className='pl-2 lg:w-2/5 w-full self-center'>
						<div className='flex justify-end mt-2'>
							<span className='text-info cursor-pointer mt-3' onClick={() => setState(prevState => ({ ...prevState, showTemplatesSection: true, templateType: "template" }))}>View Templates</span>
						</div>
						<div className="input-group search-drop dark-feild mt-1 placeholderForLightGray">
							<div className="input-group-prepend">
								<div className="input-group-text bg-lightGray">
									<i className="fal fa-search text-black"></i>
								</div>
							</div>
							<input
								ref={searchRef}
								type='text'
								name={"tempSearchText"}
								value={state.tempSearchText}
								onChange={handleSearchChange}
								// onChange={e => {
								// 	setState(prevState => ({ ...prevState, tempSearchText: e.target.value, searchResultState: [], isLoading: true }))
								// }}
								className='border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-3/4'
								placeholder='Search Template'
								onClick={(e => setState(prevState => ({ ...prevState, showDropdown: true })))}
							/>
							
							<div className={`bg-white`}>
								{state.showDropdown ?
									state.searchResultState && state.searchResultState.length ? 
									state.searchResultState.map((row, index) => {
										return(
											<div key={index} className={`cursor-pointer ${index ? 'border-t border-mediumDarkGray' : ''} p-2`}>
												<div className="w-full" onClick={() => {
													clearFilter()
													refreshFilters(row)
												}}>
													<span className='font-semibold m-0 text-black text-left p-0'>{row.template_name ? row.template_name : ''}</span>
													<span className='small m-0 text-left p-0 text-lightGray'>{row.description ? " : "+row.description : ''}</span>
												</div>
												<div className='flex justify-between'>
													<div className="flex flex-wrap mt-2 w-100 self-center text-lightGray" onClick={() => {
														clearFilter()
														refreshFilters(row)
													}}>
														{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Account").length ?
															<div className='flex mr-2'>
																<small className="font-semibold self-center cursor-pointer text-black">Account : {row.filters.filter(e => e.label === "Account")[0].value} </small>
																{row.filters.filter(e => e.label === "Account").length > 1 ?
																	<span className="text-info mx-2 mt-2 self-center f10 px-2 cursor-pointer" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: !state.showSlectedFilters }))}>more</span>
																: null}
															</div>
														: 
															<small className="font-semibold self-center cursor-pointer mr-2 text-black">Account : <span className='text-info'>All</span></small>
														}	

														{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Region").length ?
															<div className='flex mx-2'>
																<small className="font-semibold self-center cursor-pointer text-black">Region : {row.filters.filter(e => e.label === "Region")[0].value} </small>
																{row.filters.filter(e => e.label === "Region")[0].value.length > 1 ?
																	<span className="text-fino mx-2 mt-2 self-center f10 px-2 cursor-pointer" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: !state.showSlectedFilters }))}>more</span>
																: null}
															</div>
														: 
															<small className="font-semibold self-center cursor-pointer mx-2 text-black">Region : <span className='text-info'>All</span></small>
														}
														{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Granularity").length ?
															<div className='flex ml-2'>
																<small className="font-semibold self-center cursor-pointer mr-2 text-black">Granularity : <span className="text-info">{row.filters.filter(e => e.label === "Granularity")[0].output_label ? row.filters.filter(e => e.label === "Granularity")[0].output_label : row.filters.filter(e => e.label === "Granularity")[0].value}</span> </small>
															</div>	
														: null}
														{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Group by").length ?
															<div className='flex ml-2'>
																<small className="font-semibold self-center cursor-pointer mr-2 text-black">Group by : <span className="text-info">{row.filters.filter(e => e.label === "Group by")[0].output_label ? row.filters.filter(e => e.label === "Group by")[0].output_label : row.filters.filter(e => e.label === "Group by")[0].value}</span> </small>
															</div>
														: null}
														{row["Duration"] ?
															<div className='flex ml-2'>
																<small className="font-semibold self-center cursor-pointer mr-2 text-black">Duration : <span className='text-info'> {momentDateGivenFormat(row["Duration"].start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(row["Duration"].end_time, 'DD MMM YYYY')}</span> </small>
															</div>
														: null}
													</div>
													{Array.isArray(row.filters) && row.filters.length > 3 ?
														<span className="bg-info rounded-full py-1 text-black mr-2 self-center f10 px-2" onClick={() => onClickMore(row)}>more</span>
													: null}
												</div>
												{/* <div className="w-20 self-center">
													<span className="badge badge-outline-success mr-1">123</span>
													<span className="badge badge-outline-danger">123</span>
												</div> */}
												{/* <div className="w-10 self-center">
													<i class={`fal fa-star ${item.favourite ? 'fillStar' : 'displayNone' } `}></i>
												</div> */}
											</div>
										)
									})
								: state.searchResultState && !state.searchResultState.length ? 
									<div className="cursor-pointer border-bottom p-2" >
										<div className="w-100 text-black"> No Templates</div>
									</div>
								:
									state.showDropdown && state.isLoading &&
										<div className='flex justify-center m-4'>
											<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
												<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
												<path d="M4 12a8 8 0 0112-6.9" />
											</svg>
										</div>
									
								: null}
							</div>
						</div>
					</div>
				</div>
				<div className='flex flex-wrap overflow-y-auto mt-2'>
					<div className={`${state.hideFilterPanel ? "lg:w-11/12 md:w-10/12" : "lg:w-4/5 md:w-4/6"} flex flex-col pr-3`}>
						<div className='bg-lightGray border border-lightGray h-100 p-3 rounded-md'>
							{state.trendData && state.trendData[0] ?
								<div className='flex justify-between'>
									<div className=''>
										<p className='mb-0'>Total Cost</p>
										<p className='mb-0 text-xl text-black'>
											${state.trendData[0].total && state.trendData[0].total}
										</p>
									</div>
									<div className='border-l-2 border-lightGray pl-2'>
										<p className='mb-0'>Average Cost</p>
										<p className='mb-0 text-xl text-black'>
											${state.trendData[0].avg && state.trendData[0].avg}
										</p>
									</div>
								</div>
							: null}
							{state.trendGraphData && Object.entries(state.trendGraphData).length ?
								<ApexStackedBarChart
									tooltipType={"dontConvertDate"}
									start_time={state.datePickerStartDate}
									end_time={state.datePickerEndDate}
									graphData={state.trendGraphData}
									sparkline={false}
									yaxis={true}
									yaxisLabel={true}
									xaxis={true}
									xaxisFormat={state.granualrity === "monthly" ? "category" : state.granualrity === "hourly" ? "datetime" : 'date'}
									xaxisLabel={true}
									axisLabelFontSize={'9px'}
									axisLabelColor={'#B8BBBE'}
									paddingLeft={-5}
									legend={true}
									stacked={true}
									height={450}
									horizontal={false}
									barHeight={'40%'}
									barEndShape={'flat'}
									columnWidth={state.granualrity === "monthly" ? '5%' :  ""}
									gradient={true}
									gradientColor={['#039BE5', '#5F5BA2']}
									hideTooltipValue={true}
									backgroundBarShape={'flat'}
									backgroundBarColors={['#333947']}
									showBackgroundBarColors={true}
									className={"transparentTooltip"}
									// colors={["#EDDCD2", "#FFF1E6", "#FDE2E4", "#FAD2E1", "#C5DEDD", "#DBE7E4", "#F0EFEB", "#D6E2E9", "#BCD4E6", "#99C1DE", "#CDB4DB"]}
									colors={state.forecast && !state.forcastMessage ? state.transColor : state.colors}
									forecast={state.forecast && !state.forcastMessage ? true : false}
								/>
							: null}
						</div>
						{state.trendTable ?
							<div className="mt-3">
								<div className="flex justify-between mt-3 py-2">
									<p className="mb-0 self-center">Showing {state.filteredArray && state.filteredArray.length} of total {state.trendTable && state.trendTable.length} Cost and usage breakdown</p>
									{state.trendTable && state.trendTable.length ?
										<div className="flex">
											<CSVLink
												data={state.filteredArray ? state.filteredArray : []} 
												headers={state.headers ? state.headers : []}
												filename={"cost-explorers.csv"}
												className={"self-center mt-2"}
												target="_blank"
											>
												<Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
											</CSVLink>
											<div className={`mb-n2`}>
												<Search
													data={state.trendTable ? state.trendTable : []}
													filteredData={filteredArray => {
														setState(prevState => ({ ...prevState, filteredArray }))
													}}
													topClassName={'bg-GhostWhite self-center w-80 mr-1 border-lightGray rounded-5 text-xs'}
													searchClassName={'px-2 text-xs'}
													searchIconColor={'text-black text-xs'}
													searchPlaceHolder={'Search....'}
													className={"bg-transparent text-black pl-0 form-control-sm text-xs"}
												/>
											</div>
										</div>
									: null}
								</div>
								<div className='stickyTable'>
									<StickyDarkThemeTable
										data={state.filteredArray.length ? state.filteredArray : state.filteredArray}
										columns = { state.columns }
										onClickRow={tableRow => {}}
										dashboard = {state.filteredArray.length ? true : false}
										//tooltipFor={[]}
										marginRight={10}
										tableHead={'bg-lightGray text-black'}
										tableBody={'bg-white'}
										tableType={'table-light-hover'}
										perPageClass={`bg-transparent text-lightGray`}
										textColor={'text-black'}
										selectedColor={'bg-backGroundGray'}
										sortByColumn={"anomaly_time"}
										riskTooltip={[]}
									/>
								</div>
							</div>
						: null}
					</div>
					<div className={`${state.hideFilterPanel ? "lg:w-1/12 md:w-2/12" : "lg:w-1/5 md:w-2/6"} flex flex-col`}>
						<div className='bg-white h-100 p-3 rounded-md'>
							<div className="flex flex-wrap justify-between mb-2">
								<p className="font-semibold mb-1 lg:w-1/2 w-full" onClick={() => setState(prevState => ({ ...prevState, hideFilterPanel: !state.hideFilterPanel }))}>Filters</p>
								{state.hideFilterPanel ?
									<Icon icon="system-uicons:push-left" width={16} height={16} className="self-center text-black cursor-pointer mb-1 lg:w-1/2 w-full" onClick={() => setState(prevState => ({ ...prevState, hideFilterPanel: false }))} />
								: 
									<div className='flex lg:w-1/2 w-full'>
										<p className="text-info font-semibold self-center cursor-pointer mr-2 text-nowrap" onClick={ () => setState(prevState => ({ ...prevState, showTemplatesSection: !state.showTemplatesSection, templateType: "save" }))}>Save Template</p>
										<Icon icon="ion:close-circle-sharp" width="18" height="18" className='text-black ml-2 self-center' onClick={() => setState(prevState => ({ ...prevState, hideFilterPanel: true }))} />
									</div>
								}
							</div>
							{!state.hideFilterPanel ?
								<React.Fragment>
								<div className="flex flex-wrap mb-2 justify-between">
									{state.applyLoading ?
										<button className="flex btn bg-info text-black rounded-md px-2 py-1">
											<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
												<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
												<path d="M4 12a8 8 0 0112-6.9" />
											</svg>
											Applying
										</button>
									: 
										<button className={`flex justify-end self-center cursor-pointer btn bg-info text-white rounded-md px-2 py-1 ${state.applyLoading ? 'pointer-events-none' : ''}`} 
										onClick={ () => setState(prevState => ({ ...prevState, applyLoading: true, callCostGetCostTrendFunction: true }))}>Apply</button>
									}
									<p className="lg:w-1/2 w-full text-info font-semibold self-center cursor-pointer" onClick={ () => clearFilter()}>CLEAR ALL</p>
								</div>

								<div className="">
									{state.costFilters && Object.entries(state.costFilters).map(([cKey, cValue], cIndex) => {
										return(
											<div key={'cosFIl_'+cIndex} className="mb-2">
												<div className="relative">
													<div className={`bg-GhostWhite border-lightGray text-black`} readOnly={true}
														onClick={() => onClickGetDropOptions(cKey, cValue, cIndex)}
													>
														<div className='flex justify-between p-1 cursor-pointer'>
															{state["selected_value_"+cIndex] && Array.isArray(state["selected_value_"+cIndex]) && state["selected_value_"+cIndex].length ?
																state["selected_value_"+cIndex].length +" "+cKey
															: !state["selected_value_"+cIndex] || (state["selected_value_"+cIndex] && Array.isArray(state["selected_value_"+cIndex]) && !state["selected_value_"+cIndex].length) ?
																"Choose "+cKey																
															: state["selected_value_"+cIndex] ?
																cValue.mapped_values && Object.entries(cValue.mapped_values).length ?
																	// Object.entries(cValue.mapped_values).find(([key, val]) => val === state["selected_value_"+cIndex]) && Object.entries(cValue.mapped_values).find(([key, val]) => val === state["selected_value_"+cIndex]).length ? 
																	// 	Object.entries(cValue.mapped_values).find(([key, val]) => val === state["selected_value_"+cIndex])[0]
																	// : state["selected_value_"+cIndex]
																	cKey
																: state["selected_value_"+cIndex]
															: null}
															
															<Icon icon={`gravity-ui:caret-${state["showDetails_"+cIndex] ? 'up' : 'down'}`} className={`text-lightGray`} width="16" height="16" />
														</div>
													</div>

													{state["showDetails_"+cIndex] ?
														cValue.mapped_values && Object.entries(cValue.mapped_values).length ?
															<div className='bg-GhostWhite pb-2'>
																<div className='border border-lightGray mx-2 rounded-md pt-2'>
																	{Object.entries(cValue.mapped_values).length > 8 ?
																		<div contenteditable="true" 
																			className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black mx-2`}
																			placeholder="search..."
																			id={"search_"+cIndex}
																			onKeyUp={e => searchData(e, e.currentTarget.textContent, cIndex, cValue.mapped_values)}
																		></div>
																	: null}
																	
																	<div className='max-h-[300px] overflow-y-auto'>
																		{Object.entries(state['filteredDropOptions_'+cIndex] ? state['filteredDropOptions_'+cIndex] : cValue.mapped_values).map(([mRow, mValue], mIndex) => {
																			return(
																				<div key={'map_'+mIndex} className={`flex flex-wrap pt-2 px-2 pb-1 cursor-pointer ${state["input_change_"+cIndex] === mValue ? "bg-white" : "" } ${Object.entries(cValue.mapped_values).length - 1 !== mIndex ? "border-b border-lightGray" : ""}`} onClick={() => {
																					onChangeSelectInput(cValue.mapped_values, mValue, "input_change", mIndex, cIndex, cKey)
																					// setState(prevState => ({ ...prevState, ['filteredDropOptions_'+cIndex]: cValue.mapped_values }))
																				}}>
																					<div style={{ display: "inline" }} className={`mr-2`}>
																						<div className="form-check">
																							<p className={`form-check-label mb-0 mr-2  ${state["input_change_"+cIndex] === mValue ? "text-info" : "text-black" }`}>{mRow}</p>
																						</div>
																					</div>
																				</div>
																			)
																		})}
																	</div>
																</div>
															</div>
														: cValue.mapped_values && !Object.entries(cValue.mapped_values).length && cValue.values ?
															<div className='bg-GhostWhite pb-2'>
																<div className='border border-lightGray mx-2 rounded-md max-h-[300px] overflow-y-auto'>
																	{cValue.values.map((vRow, vIndex) => {
																		return(
																			<div className={`flex flex-wrap pt-2 px-2 pb-1 cursor-pointer ${state["input_change_"+cIndex] === vRow ? "bg-white" : "" } ${Object.entries(cValue.mapped_values).length - 1 !== vIndex ? "border-b border-lightGray" : ""}`}>
																				<div style={{ display: "inline" }} className={`mr-2`}>
																					<div className="form-check">
																						<p className={`form-check-label mb-0 mr-2  ${state["input_change_"+cIndex] === vRow ? "text-info" : "text-white" }`} onClick={() => onChangeSelectInput(cValue.values, vRow, "input_change", vIndex, cIndex, cKey)}>{vRow}</p>
																					</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
															</div>
														: cValue.output_type === "string" ?
															<div className='bg-GhostWhite pb-2'>
																<div className='border border-lightGray mx-2 rounded-md h-[300pz]'>
																	<div className='flex flex-wrap mb-1'>
																		<div className="pr-2">
																			<div className="flex mx-2">
																				<input type="radio"
																					onChange={() => setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))}
																					className="form-check-input"
																					checked={state["includes_"+cIndex] === "Includes" ? true : false}
																				/>
																				<p className="form-check-label mb-0 ml-2 text-black">Includes</p>
																			</div>
																		</div>
																		<div className="">
																			<div className="flex mx-2">
																				<input type="radio"
																					onChange={() => {
																						setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Excludes" }))
																					}}
																					className="form-check-input"
																					checked={state["includes_"+cIndex] === "Excludes" ? true : false}
																				/>
																				<p className="form-check-label mb-0 ml-2 text-black">Excludes</p>
																			</div>
																		</div>
																	</div>
																	{state["filteredDropOptions_"+cIndex].map((row, sIndex) => {
																		return(
																			<div className={`flex flex-wrap pt-2 px-2 pb-1 cursor-pointer ${state["input_change_"+cIndex] === row ? "bg-white" : "" } ${state["filteredDropOptions_"+cIndex].length - 1 !== sIndex ? "border-b border-lightGray" : ""}`}>
																				<div style={{ display: "inline" }} className={`mr-2`}>
																					<div className="form-check">
																						<p className={`form-check-label mb-0 mr-2  ${state["input_change_"+cIndex] === row ? "text-info" : "text-black" }`} onClick={() => onChangeSelectInput(cValue.values, row, "input_change", sIndex, cIndex, cKey)}>{row}</p>
																					</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
															</div>
														:
															<div className='bg-GhostWhite pb-2'>
																<div className='border border-lightGray mx-2 rounded-md'>
																	<div className='flex flex-wrap mb-1'>
																		<div className="pr-2">
																			<div className="flex mx-2">
																				<input type="radio"
																					onChange={() => setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))}
																					className="form-check-input"
																					checked={state["includes_"+cIndex] === "Includes" ? true : false}
																				/>
																				<p className="form-check-label mb-0 ml-2 text-black">Includes</p>
																			</div>
																		</div>
																		<div className="">
																			<div className="flex mx-2">
																				<input type="radio"
																					onChange={() => {
																						setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Excludes" }))
																					}}
																					className="form-check-input"
																					checked={state["includes_"+cIndex] === "Excludes" ? true : false}
																				/>
																				<p className="form-check-label mb-0 ml-2 text-black">Excludes</p>
																			</div>
																		</div>
																	</div>
																	{state["hideDropLoading_"+cIndex]  ?
																		state["dropOptions_"+cIndex] ?
																			<div className=''>
																				<div contenteditable="true" 
																					className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black mx-2`}
																					placeholder="search..."
																					id={"search_"+cIndex}
																					onKeyUp={e => searchData(e, e.currentTarget.textContent, cIndex, state["dropOptions_"+cIndex])}
																				></div>
																				<div className={`topAuto mt-2 bg-GhostWhite p-2 max-h-[300px] overflow-y-auto`}>
																					{state["dropOptions_"+cIndex].length === state["filteredDropOptions_"+cIndex].length ?
																						<div className="flex flex-wrap border-b border-lightGray">
																							<div style={{ display: "inline" }} className="mr-2 self-end">
																								<div className="flex">
																									<input type="checkbox"
																										onChange={() => onChangeMultiSelectInput(state["dropOptions_"+cIndex], "All", "input_change", "", cIndex)}
																										className="form-check-input"
																										checked={state["select_all_"+cIndex]}
																									/>
																									<p className="form-check-label mb-0 mr-2 text-black" onClick={() => onChangeMultiSelectInput(state["dropOptions_"+cIndex], "All", "input_change", "", cIndex)}>Select All({state["filteredDropOptions_"+cIndex].length})</p>
																								</div>
																							</div>
																						</div>
																					: null}
																					{state["filteredDropOptions_"+cIndex].map((row, index) => {
																						return(
																							<div className={`flex flex-wrap pt-2 ${state["filteredDropOptions_"+cIndex].length - 1 !== index ? "border-b border-lightGray" : ""}`}>
																								<div style={{ display: "inline" }} className="mr-2 self-end">
																									<div className="flex">
																										<input type="checkbox"
																											onChange={() => onChangeMultiSelectInput(state["dropOptions_"+cIndex], row, "input_change", index, cIndex)}
																											className="form-check-input"
																											checked={state["input_change_"+cIndex+"_"+index]}
																										/>
																										<p className="form-check-label mb-0 mr-2 text-black" onClick={() => onChangeMultiSelectInput(state["dropOptions_"+cIndex], row, "input_change", index, cIndex)}>{row}</p>
																									</div>
																								</div>
																							</div>
																						)
																					})}
																				</div>
																			</div>
																		: null
																	: !state["hideDropLoading_"+cIndex] ?
																		<div className='flex justify-center m-4'>
																			<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
																				<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
																				<path d="M4 12a8 8 0 0112-6.9" />
																			</svg>
																		</div>
																	: null}
																</div>
															</div>
													: 
														cValue.mapped_values && Object.entries(cValue.mapped_values).length && state["input_change_"+cIndex] ?	
															<div className='bg-GhostWhite pl-2 pb-2'>
																<p className='mb-0 text-info'>{state["input_change_"+cIndex]}</p>	
															</div>
														: null
													}
													{state["input_change_"+cIndex] === 'TAG' ?
														<div className='bg-GhostWhite pb-2'>
															<div className={`bg-GhostWhite border-lightGray text-black px-2`}
																onClick={() => {
																	setState(prevState => ({ ...prevState, ['show_ropOptions_'+cIndex]: !state['show_ropOptions_'+cIndex] }))
																}}
															>
																<div className='flex justify-between p-1 cursor-pointer'>
																	Choose Tag
																	<Icon icon={`gravity-ui:caret-${state["showDetails_"+cIndex] ? 'up' : 'down'}`} className={`text-lightGray`} width="16" height="16" />
																</div>
															</div>

															{state['show_ropOptions_'+cIndex] ?
																<div className='border border-lightGray mx-2 rounded-md'>
																	{/* {Object.entries(cValue.mapped_values).length > 8 ?
																		<div contenteditable="true" 
																			className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black mx-2`}
																			placeholder="search..."
																			id={"search_"+cIndex}
																			onKeyUp={e => searchData(e, e.currentTarget.textContent, cIndex, cValue.mapped_values)}
																		></div>
																	: null} */}
																
																	<div className='max-h-[300px] overflow-y-auto'>
																		{state["tagDropOPtions_"+cIndex] ? 
																			state["tagDropOPtions_"+cIndex].map((tagItem, tIndex) => {
																				return(
																					<div key={'map_'+tIndex} className={`flex flex-wrap pt-2 px-2 pb-1 cursor-pointer ${state.aggregate_by_tag === tagItem ? "bg-white" : "" } ${state["tagDropOPtions_"+cIndex].length - 1 !== tIndex ? "border-b border-lightGray" : ""}`} onClick={() => {
																						setState(prevState => ({ ...prevState, aggregate_by_tag: tagItem, callFormSelectedFitlersFunction: true }))
																					}}>
																						<div style={{ display: "inline" }} className={`mr-2`}>
																							<div className="form-check">
																								<p className={`form-check-label mb-0 mr-2  ${state.aggregate_by_tag === tagItem ? "text-info" : "text-black" }`}>{tagItem}</p>
																							</div>
																						</div>
																					</div>
																				)
																			})
																		: null}
																	</div>
																</div>
															: null}
														</div>
													: null}
												</div>
											</div>
										)
									})}
								</div>
								<div className='p-3 bg-GhostWhite my-3'>
									<div className="flex self-center">
										{/* <input type="checkbox" className="form-check-input" id="forecastCheck" /> */}
										<input 
											type="checkbox" 
											className="form-check-input" 
											id="forecastCheck" 
											checked={state.forecast}
											onChange={e => setState(prevState => ({ ...prevState, forecast: state.forecast ? false : true }))}
										/>
										<label className="form-check-label f16 cursor-pointer text-black" onClick={() => setState(prevState => ({ ...prevState, forecast: state.forecast ? false : true }))}>Forecast Data</label>
									</div>
								</div>

								<div className="flex mb-2 justify-between">
									{state.applyLoading ?
										<button className="flex btn bg-info text-white rounded-md px-2 py-1">
											<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
												<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
												<path d="M4 12a8 8 0 0112-6.9" />
											</svg>
											Applying 
										</button>
									: 
										<button className={`flex justify-end self-center cursor-pointer btn bg-info text-white rounded-md px-2 py-1 ${state.applyLoading ? 'pointer-events-none' : ''}`} 
										onClick={ () => setState(prevState => ({ ...prevState, applyLoading: true, callCostGetCostTrendFunction: true }))}>Apply</button>
									}
									{/* <small className="text-blue font-semibold self-center f13 cursor-pointer" onClick={ () => this.clearFilter()}>CLEAR ALL</small> */}
								</div>
								</React.Fragment>
							: null}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LandingPage