/*************************************************
 * Collider
 * @exports
 * @file RequestStoragesidePanel.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useRef} from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import { convertToLowerCase, capitalizeAllLetter } from "../../../utils/utility"
import { requestStorage} from '../../../actions/files/FileAction'
import PageLoading from '../../common/PageLoading';
import Textbox from '../../common/Form/Textbox';

const RequestStoragesidePanel = ({ closeSidePanel, selectedProvider, selectedAccount, selectedRegion, selectedClusters, allocatedStorage, storageUsed, availableStorage, storageUnit }) => {
    const clickOut = useRef()

    const [state, setState] = useState({
        onLoad: true,
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        console.log("use effect call")
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let inputValidationError = false
            let params = {}
            if(!selectedProvider || !selectedAccount || !selectedRegion || !selectedClusters || !state.new_storage) {
                inputValidationError = true
            }
            params.provider = convertToLowerCase(selectedProvider)
            params.region = convertToLowerCase(selectedRegion)
            params.account_id = selectedAccount
            params.cluster_name = selectedClusters
            params.existing_storage = allocatedStorage
            params.new_storage = state.new_storage
            params.storage_unit = storageUnit

            console.log(selectedProvider, selectedAccount, selectedRegion, selectedClusters, state.new_storage, inputValidationError, params)
            setState(prevState => ({ ...prevState, inputValidationError, saveLoading: inputValidationError ? false : true }))

           if(!inputValidationError) {
            dispatch(requestStorage(params))
            .then((response) => {
                console.log(params, response)
                let messageType = 'danger'
                let message = response.message ? response.message : 'Error in requesting storage'

                if(response && response.status) {
                    messageType = 'success'
                    message = response.message ? response.message : 'Storage requested Successfully'

                    setTimeout(() => closeSidePanel(), 2000)
                } else {
                    setState(prevState => ({ ...prevState, saveLoading: false }))
                }

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                    }
                });

                // setState(prevState => ({ ...prevState, createRespons: response }))
            })
            }
        }
    }, [state.callSaveFunction, dispatch, closeSidePanel, state, allocatedStorage, selectedProvider, selectedAccount, selectedRegion, selectedClusters, storageUnit])    

    const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }

            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col w-3/5'>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black shadow-xl'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl'>Request Storage</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black cursor-pointer self-center' width={30} height={30} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                    <div className='overflow-y-auto h-[calc(100vh-120px)] bg-white'>
                        <div className='p-8 h-full'>
                            <div className={`border-b border-NeutralGray-600 pb-8 mb-8`}>
                                <div className='flex justify-start w-1/5 pr-2'>
                                    <Textbox
                                        label={"Additional Storage"}
                                        type="number"
                                        placeholder={"Enter storage size"}
                                        selectedValues={state.new_storage ? state.new_storage : ''}
                                        callback={(value) => {
                                            setState(prevState => ({ ...prevState, new_storage: value }))
                                        }}
                                        manditory={true}
                                    />
                                    {state.inputValidationError ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                    <span className='text-base text-NuetralGray-400 mt-4 ml-2 self-center'>GB</span>
                                </div>
                            </div>
                            <div className='flex justify-start border border-NeutralGray-400 p-3 rounded-lg'>
                                {/* <p className='font-medium text-black text-lg border-r border-NeutralGray-400 pr-3'>User</p> */}
                                <div className='flex self-center mx-3 text-black'>
                                    <span className='mr-2'>Allocated&nbsp;Storage</span>
                                    <span className='mr-2'>:</span>
                                    <span className='text-warning'>
                                        {allocatedStorage ? allocatedStorage : 0 }
                                        <span className='ml-1 f14 text-lightGray'>{storageUnit ? capitalizeAllLetter(storageUnit) : '' }</span>
                                </span>
                                </div>
                                <div className='flex self-center mr-3 text-black'>
                                    <span className='mr-2'>Used&nbsp;Storage</span>
                                    <span className='mr-2'>:</span>
                                    <span className='text-warning'>
                                        {storageUsed ? storageUsed : 0 }
                                        <span className='ml-1 f14 text-lightGray'>{storageUnit ? capitalizeAllLetter(storageUnit) : '' }</span>
                                    </span>
                                </div>
                                <div className='flex self-center mr-3 text-black'>
                                    <span className='mr-2'>Available&nbsp;Storage</span>
                                    <span className='mr-2'>:</span>
                                    <span className='text-warning'>
                                        {availableStorage ? availableStorage : 0 }
                                        <span className='ml-1 f14 text-lightGray'>{storageUnit ? capitalizeAllLetter(storageUnit) : '' }</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full p-8 shadow-lg bg-NeutralGray-700">
                        <div className={`flex justify-end`}>
                            <div className={`self-center flex justify-end`}>
                                    {state.saveLoading ?
                                        <button type="button" className="bg-green-600 text-white text-base font-medium rounded-md px-[18px] py-2.5 inline-flex items-center self-center"><Icon icon="mingcute:down-small-line mt-1 mr-2"></Icon>  Requesting</button>
                                    :
                                    <button className={`bg-green-600 text-white text-base font-medium rounded-lg px-[18px] py-2.5 inline-flex items-center self-center`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))}>
                                        Request
                                    </button>
                                }
                                <button className={`bg-ferrariRed-600 text-white text-base font-medium rounded-lg px-[18px] py-2.5 inline-flex items-center self-center ml-8`} onClick={ () => closeSidePanel()}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {console.log(state.callSaveFunction, state.inputValidationError)}
            </div>
        </div>
    )
}

export default RequestStoragesidePanel