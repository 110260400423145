/*************************************************
 * @exports
 * @file Sidebar.js
 * @author Prakash
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Icon} from "@iconify/react";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { getUserMenu, getUserProfile } from '../../actions/commonActionNew'
import { URL_PATH } from '../../config/urlPath';
import { AppConfig } from '../../AppConfig';
import { useMsal } from "@azure/msal-react";
import { msalConfig } from '../../authConfig';

const icons = [
	{label: "HPC", value: "eos-icons:monitoring"},
	{label: "Resources", value: "meteor-icons:cube"}, 
	{label: "Cost", value: "fluent-emoji-high-contrast:money-bag"},
	{label: "Governance", value: "bi:book"},
	{label: "ImageBuilder", value: "ri:image-ai-line"},
	{label: "Admin", value: "ix:user-settings"},
]

const subPath = ''

const Sidebar = () => {
	const { instance } = useMsal()

	const [state, setState] = useState({
		showSidebar: false,
	})
	const userMenu = useSelector(state => state?.filters?.userMenu || false);
	const profileDetails = useSelector(state => state?.filters?.profileDetails || false);
	
	const dispatch = useDispatch(); // Create a dispatch function
	const location = useLocation();
	const navigate = useNavigate();
	const currentPath = location.pathname;
	useEffect(() => {
		dispatch(getUserMenu({}))
	}, [dispatch])

	useEffect(() => {
        if(!profileDetails) {
            dispatch(getUserProfile())
        }
    }, [userMenu, navigate, profileDetails, dispatch])
	
	useEffect(() => {
		if(userMenu && Object.entries(userMenu).length) {

			let subMenus = ["/admin/accounts", "/admin/settings"]
			userMenu.menu && userMenu.menu.forEach(row => {
				if(row.submenulist && row.submenulist.length) {
					let menus = row.submenulist.map(e => e.link);
					subMenus = [...subMenus, ...menus]
				} else {
					subMenus.push(row.link)
				}
			})
			if(userMenu.additionalUrls) {
				subMenus = [...subMenus, ...userMenu.additionalUrls]
			}
			// additionalUrls
			let trimmedPath = currentPath
			if(!subMenus.includes(trimmedPath)) {
	            navigate(URL_PATH.PERMISSION_DENINED);
			}
			if(userMenu && Object.entries(userMenu).length && (window.location.pathname === '/' || window.location.pathname === AppConfig.appBasePath))  {
				navigate(userMenu.defaultLandingPage && userMenu.defaultLandingPage.link ? subPath+''+userMenu.defaultLandingPage.link : URL_PATH.JOB_STATUS);
			}

			// dispatch(setCommonPropsDetails('userMenuAccess', subMenus))
		}
	}, [userMenu, currentPath, navigate])

	const handleLogout = () => {		
		localStorage.clear();
		sessionStorage.clear()
		
		const logoutRequest = {
			postLogoutRedirectUri: msalConfig.auth.redirectUri,
		};
	
		instance.logoutRedirect(logoutRequest);;
	}
	/**
	 * Renders sidebar of the screen
	 */
	return (
		<React.Fragment>
		<div className="group w-16 h-screen overflow-x-hidden bg-darkPurple-600 fixed transition-all duration-300 ease-in-out hover:w-fit pb-4 pl-4 pr-4 z-50 flex flex-col">
			<div className="flex justify-center group-hover:hidden text-FluorescentBlue-600 mt-6 group-hover:mt-0 text-[30px] mx-auto sideBarFont">
				T
			</div>
			<div className='hidden group-hover:block'>
				<p className="text-FluorescentBlue-600 group-hover:mt-6 text-[30px] text-center sideBarFont">Tachyon</p>
			</div>

			<div className="flex-1 mt-12 overflow-y-auto overflow-x-hidden pr-8">
				<div className="space-y-6">
					{userMenu && userMenu.menu && userMenu.menu.map((item, index) => {
						return (
							<div key={"parent_child_index_"+index}>
								{item.submenulist && item.submenulist.length ?
									<React.Fragment>
									<div className={`group-hover:text-white text-base flex flex-row gap-2 items-center cursor-pointer ${((item.submenulist && item.submenulist.length && item.submenulist.filter(e => e.link === currentPath.replace(subPath, '')).length) || item.link === currentPath.replace(subPath, '')) && (currentPath.replace(subPath, '') === item.link || item.submenulist) ? '' : ''}`}
										onClick={() => setState(prevState => ({ ...prevState, ['show_menu_'+index]: !state['show_menu_'+index] }))}
									>
										{icons.filter(e => e.label === item.menu).length && icons.filter(e => e.label === item.menu)[0].value ?
										<span className='flex'>
											<Icon icon={`${icons.filter(e => e.label === item.menu)[0].value}`} className="text-NeutralGray-600" width={24} height={24} />
										</span>
										: null}
										<p className={`pl-6 invisible group-hover:visible text-md text-white`}>{item.menu}</p>
									</div>
									{state['show_menu_'+index] ?
										<div className="flex justify-end flex-col m-0 menu">
											{item.submenulist.map((sub, i) => {
												return(
													<Link
														key={"child_index_"+index+"_"+i}
														className={`pl-9 text-base text-start mx-5 p-3 invisible group-hover:visible hover:bg-black4 whitespace-nowrap ${(sub.link === currentPath.replace(subPath, '')) ? 'text-LavenderIndigo-600 underline underline-offset-4' : 'text-CadetGrey-600'}`}
														to={subPath+''+sub.link}
													>
														{sub.submenu}
													</Link>
												)
											})}
										</div>
									: null}
									</React.Fragment>				
								: 
									<div className={`group-hover:text-white flex flex-row gap-2 items-center cursor-pointer`}>
										{icons.filter(e => e.label === item.menu).length && icons.filter(e => e.label === item.menu)[0].value ?
										<span className='flex'>
											<Icon icon={`${icons.filter(e => e.label === item.menu)[0].value}`} className="text-white font-medium" width={24} height={24} />
										</span>
										: null}
										<Link
											key={"parent_index_"+index}
											className={`text-base text-start hover:bg-transparent whitespace-nowrap text-white invisible group-hover:visible `}
											to={(!item.submenulist || (item.submenulist && !item.submenulist.length)) ? subPath+''+item.link : ""}
											onClick={event => {
												if(item.submenulist && item.submenulist.length) {
													event.preventDefault()
													setState(prevState => ({ ...prevState, ['show_menu_'+index]: !state['show_menu_'+index] }))
												} else {
													setState(prevState => ({ ...prevState, activeMenu: item.menu }))
												}
											}}
										>
											<span className={`pl-6 text-base nav-text text-white`}>{item.menu}</span>
										</Link>
									</div>
								}
							</div>
						)
					})}
				</div>
			</div>
			<div className="flex items-center cursor-pointer pt-8" onClick={handleLogout}>
				<div className='flex'>
					<div id={'username'} className="bg-PrimaryLightOrange-600 w-8 h-8 rounded-full flex justify-center h items-center text-white text-base mx-auto self-end relative tooltip">{profileDetails && profileDetails.userName ? profileDetails.userName[0].toUpperCase() : (profileDetails && profileDetails.firstName ? profileDetails.firstName[0].toUpperCase() : "")}</div>
					<span className={`pl-6 text-base text-start hover:bg-transparent whitespace-nowrap text-PrimaryTintsPurpleTints-40 invisible group-hover:visible self-center`}>Logout</span>
					<div className="absolute z-[75] invisible tooltip-hover:visible bottom-[55px] px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm">
						{profileDetails && profileDetails.userName ? profileDetails.userName : (profileDetails && profileDetails.firstName ? profileDetails.firstName : "")}
					</div>
				</div>
			</div>
		</div>
		</React.Fragment>
	)
}

export default Sidebar
