import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash'
import {Icon} from "@iconify/react";

import moment from 'moment';

import { momentDateGivenFormat } from '../../utils/utility';
import { listAllProviders, listAllProjects, listClusterFilters, listUsers, setCommonPropsDetails } from '../../actions/commonActionNew'
import { listQueues } from '../../actions/Collider/ClusterAction'
import MultiSelectSectionNew from './MultiSelectSectionNew';
import SmartDateRangePicker from './SmartDateRangePicker';

const FilterSection = (props) => {
    const durationRef = useRef()
    const clickOut = useRef()

    const [dateRange, setDateRange] = useState(defaultDateRangeValue());
    const [queryDate, setQueryDate] = useState(defaultDateRangeValue());


    const [state, setState] = useState({
        selectedDuration: 'currentMonth',
        screens: ['QOS', 'Group'],
        changeManage: props.changeManage,
        reportViewMode: [
            {value: "cluster_name", label: "Cluster"},
            {value: "queue_name", label: "Queue"},
            {value: "user", label: "User"},
            {value: "project", label: "Project"}
        ],
        selectedType: 'cluster_name',
        onLoad: true,
        projectLoader: true,
        accountLoader: true,
        regionLoader: true,
        clusterLoader: true,

        lifeCylcePolicyTypes: ["Asset", "Service", "Tags"],
        selectedPolicy: props.selectedPolicy,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    const clickedonParent = useSelector(state => state?.filters?.commonPropsDetails?.clickedonParent || false);    
    const filterSelectedFilters = useSelector(state => state?.filters?.commonPropsDetails?.filterSelectedFilters || false);
    const usersList = useSelector(state => state?.filters?.usersList)

    const location = useLocation();
    const locationData = location.state || false

    useEffect(() => {
        if(props.screen === 'job_status' && !_.isEqual(filterSelectedFilters, state.filterSelectedFilters)) {
            setState(prevState => ({ 
                ...prevState,
                selectedProvider: filterSelectedFilters.selectedProvider,
                selectedProject: filterSelectedFilters.selectedProject,
                selectedProjectTags: filterSelectedFilters.selectedProjectTags,
                selectedAccount: filterSelectedFilters.selectedAccount ? filterSelectedFilters.selectedAccount : [],
                selectedRegion: filterSelectedFilters.selectedRegion ? filterSelectedFilters.selectedRegion : [],
                selectedClusters: filterSelectedFilters.selectedClusters ? filterSelectedFilters.selectedClusters : [],
                selectedQueue: filterSelectedFilters.selectedQueue ? filterSelectedFilters.selectedQueue : [],
                selectedUser: filterSelectedFilters.selectedUser ? filterSelectedFilters.selectedUser : [],
                datePickerStartDate: filterSelectedFilters.datePickerStartDate,
                datePickerEndDate: filterSelectedFilters.datePickerEndDate,
                filterSelectedFilters,
                callSearch: true,
            }))
        }
    }, [props.screen, filterSelectedFilters, state.filterSelectedFilters])
    
    // list all providers
    useEffect(() => {
        if (providers) {
            setState(prevState => ({
                ...prevState,
                providers: providers,
                selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "",
                callAccountList: true,
                callRegionList: true,
                callClusterList: true
            }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
        }
    }, [dispatch, providers]);   
    
    useEffect(() => {
        if(state.onLoad) {
            if(state.selectedProvider) {
                if(!props.onLoadFields) {
                    if(props.filters.includes('duration')) {
                        if(state.datePickerStartDate) {
                            setState(prevState => ({ ...prevState, onLoad: false, callSearch: true }))    
                        }
                    } else {
                        setState(prevState => ({ ...prevState, onLoad: false, callSearch: true }))
                    }
                } else {
                    let exist = true
                    props.onLoadFields.forEach(fil => {
                        if(!state[fil]) {
                            exist = false
                        }
                    })

                    if(exist) {
                        setState(prevState => ({ ...prevState, onLoad: false, callSearch: true }))    
                    }
                }
            }
        }
    }, [state.onLoad, state.datePickerStartDate, state.selectedProvider, props.filters, props.onLoadFields, state])

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(propProjects && propProjects.hasOwnProperty(state.selectedProvider)) {
                setState(prevState => ({ ...prevState, projects: propProjects[state.selectedProvider], projectLoader: false }));
            } else {
                dispatch(listAllProjects(params))
                    .then((response) => {
                        if(response) {
                            let obj = propProjects ? propProjects : {}
                            obj[state.selectedProvider] = response
                            dispatch(setCommonPropsDetails('propProjects', obj))
                            setState(prevState => ({ ...prevState, projects: response, projectLoader: false }))
                        }
                    });
            }
        }
    }, [state.selectedProvider, dispatch, propProjects]);

    // Call provider based accounts whenever the selectedProviders state is updated
    useEffect(() => {
        if (state.callAccountList && props.filters && props.filters.includes('account')) {
            setState(prevState => ({ ...prevState, callAccountList: false, accountLoader: true }));
            let params = {};
            let label = ''
            params.aggregate_by = 'account_id';
            params.provider = state.selectedProvider.toLowerCase();
            label = state.selectedProvider
            if(state.selectedProject) {
                label = '_'+state.selectedProject
                params.project_tags = state.selectedProjectTags
            }

            if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label], selectedAccount: [], accountLoader: false }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
                            }
                            setState(prevState => ({ ...prevState, accounts: response, selectedAccount: [], accountLoader: false }));
                        }
                    })
            }
        }
    }, [state.callAccountList, state.selectedProvider, dispatch, propProjAccounts, state.selectedProject, state.selectedProjectTags, props.filters]);

    useEffect(() => {
        if (state.callRegionList && props.filters && props.filters.includes('region')) {
            setState(prevState => ({ ...prevState, callRegionList: false, regionLoader: true }));
            let params = {};
            let label = ''
            params.aggregate_by = 'region';
            params.provider = state.selectedProvider.toLowerCase();
            label = state.selectedProvider
            if(state.selectedProject) {
                label = '_'+state.selectedProject
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            
            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label], selectedRegion: [], regionLoader: false }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
                            let obj = propProjRegions ? propProjRegions : {}
                            obj[state.selectedProvider] = response
                            dispatch(setCommonPropsDetails('propProjRegions', obj))
                        }
                        setState(prevState => ({ ...prevState, regions: response, selectedRegion: [], regionLoader: false }))
                    })
            }
        }
    }, [state.callRegionList, state.selectedProvider, state.selectedAccount, dispatch, propProjRegions, state.selectedProject, state.selectedProjectTags, props.filters]);

    useEffect(() => {
        if (state.callClusterList && props.filters && props.filters.includes('cluster')) {
            setState(prevState => ({ ...prevState, callClusterList: false, clusterLoader: true }));
            let params = {};
            params.aggregate_by = 'cluster_name';
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            
            if(state.selectedProject) {
                params.project_tags = state.selectedProjectTags
            }
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response, clusterLoader: false }));
                        if(props.showSelectedCluster) {
                            setState(prevState => ({ ...prevState, selectedClusters: response.length ? response[0] : "" }));
                        }
                    }
            })
        }
    }, [dispatch, state.callClusterList, state.selectedProject, state.selectedProjectTags, state.selectedProvider, state.selectedAccount, state.selectedRegion, props])

    useEffect(() => {
        if (state.callQueueList && props.filters && props.filters.includes('queue')) {
            setState(prevState => ({ ...prevState, callQueueList: false, filterLoader: true }));
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters
            }
            dispatch(listQueues(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, queues: response, selectedQueue: "", filterLoader: false }));
                    }
            })
        }
    }, [dispatch, state.callQueueList, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, props])

    useEffect(() => {
        if (state.callUserList && props.filters && props.filters.includes('user')) {
            setState(prevState => ({ ...prevState, callUserList: false, userLoader: true }));
            let params = {};
            if(!usersList || !usersList.length) {
                dispatch(listUsers(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({ ...prevState, usersList: response, userLoader: false }));
                        }
                    })
            } else {
                setState(prevState => ({ ...prevState, usersList: usersList }));
            }
        }
    }, [dispatch, state.callUserList, usersList, props])
  
    useEffect(() => {
      const currentMonth = new Date().getMonth(); // Month is zero-based (0 for January, 1 for February, ..., 11 for December)
  
      let datePickerStartDate = ''
      let datePickerEndDate = ''
      if(state.selectedDuration === "currentMonth") {
        datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
        datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
      } else if(state.selectedDuration === "currentYear") {
        datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
        datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
      } else if(state.selectedDuration === "lastMonth") {
        datePickerStartDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-01 00:00:00')            
        datePickerEndDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')
      } else if(state.selectedDuration === "quarterly") {
        if (currentMonth >= 0 && currentMonth <= 2) {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-03-31 23:59:59')
        } else if (currentMonth > 2 && currentMonth < 6) {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-04-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
        } else if (currentMonth > 5 && currentMonth < 9) {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-09-30 23:59:59')
        } else {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-10-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }
      } else if(state.selectedDuration === "half-yearly") {
        if (currentMonth >= 0 && currentMonth <= 5) {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
        } else {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }
      } else if(state.selectedDuration === "yearly") {
        datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
        datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
      }
      if(state.selectedDuration !== 'custom') {
        setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));
      }
  
    }, [state.selectedDuration])

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: '', selectedProjectTags: [], selectedAccount: [], selectedRegion: [], selectedClusters: [], selectedDuration: 'currentMonth', selectedUser: [], selectedQueue: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch && state.selectedProvider) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            let obj = {
                selectedProvider: state.selectedProvider,
                selectedAccount: state.selectedAccount ? state.selectedAccount : [],
                selectedProject: state.selectedProject ? state.selectedProject : [],
                selectedProjectTags: state.selectedProjectTags ? state.selectedProjectTags : [],
                selectedRegion: state.selectedRegion ? state.selectedRegion : [],
                selectedClusters: state.selectedClusters ? state.selectedClusters : [],
                selectedQueue: state.selectedQueue ? state.selectedQueue : [],
                selectedUser: state.selectedUser ? state.selectedUser : [],
                selectedType: state.selectedType ? state.selectedType : '',
                datePickerStartDate: momentDateGivenFormat(dateRange[0], "YYYY-MM-DD 00:00:00"),
                datePickerEndDate: momentDateGivenFormat(dateRange[1], "YYYY-MM-DD 23:59:59"),
                changeManage: state.changeManage
            }
            if(props.screen === 'QOS') {
                if(props.changeManage) {
                    props.selectedFilters(obj)
                }
            } else {
                props.selectedFilters(obj)
            }
        }
    }, [state.callSearch, props, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedClusters, state.selectedProject, state.selectedProjectTags, state.selectedProvider, state.selectedQueue, state.selectedRegion, state.selectedUser, state.changeManage, state.selectedType, dateRange])

    // useEffect(() => {
    //     if(props.changeManage && props.screen === 'QOS') {
    //         setState(prevState => ({ ...prevState, changeManage: props.changeManage, callSearch: true }))
    //     }
    // }, [props.changeManage, props.screen, state.changeManage])
    useEffect(() => {
		if(locationData && Object.entries(locationData).length) {            
			setState(prevState => ({  ...prevState, changeManage: locationData.selectedManage ? locationData.selectedManage : 'QOS', callSearch: true }))
		}
	}, [locationData, dispatch])

	const handleChildClick = (event, type, dropdownType, section) => {	
		if (durationRef.current && !durationRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    useEffect(() => {
        if(state.selectedType === 'user') {
            dispatch(listUsers({}))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, usersList: response }));
                    }
            })
        }
    }, [state.selectedType, dispatch])

    useEffect(() => {
        if(state.selectedType === 'project' && state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(propProjects && propProjects.hasOwnProperty(state.selectedProvider)) {
                setState(prevState => ({ ...prevState, projects: propProjects[state.selectedProvider] }));
            } else {
                dispatch(listAllProjects(params))
                    .then((response) => {
                        if(response) {
                            let obj = propProjects ? propProjects : {}
                            obj[state.selectedProvider] = response
                            dispatch(setCommonPropsDetails('propProjects', obj))
                            setState(prevState => ({ ...prevState, projects: response }))
                        }
                    });
            }
        }
    }, [state.selectedType, state.selectedProvider, dispatch, propProjects]); 

    useEffect(() => {
        if(clickedonParent) {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            setState(prevState => ({ ...prevState, clickedChild: [] }))

            dispatch(setCommonPropsDetails('clickedonParent', false))
        }

    }, [clickedonParent, dispatch, state.clickedChild])

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')} className='flex flex-wrap justify-end mr-2 self-end gap-2'>
            {props.filters && props.filters.includes('provider') ?
                <div className=' self-end'>
                    <MultiSelectSectionNew
                        label={"Provider"}
                        fields={["provider_name", "provider_name"]}
                        options={state.providers}
                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                        callbackMultiSelect={(value) => {
                            setState(prevState => ({ ...prevState, selectedProvider: value, selectedProjectTags: [], selectedAccount: [], selectedRegion: [], selectedClusters: [], selectedQueue: [], callAccountList: true, callRegionList: true, callClusterList: true, selectedStatus: "", projectLoader: true }))
                        }}
                        singleSelection={true}
                        dropdownWidth={'w-28'}
                    />
                </div>
            : null}
            {props.filters && props.filters.includes('project') ?
                <div className=' self-end'>
                    <MultiSelectSectionNew
                        label={"Project"}
                        fields={["project", "project"]}
                        options={state.projects}
                        selectedValues={state.selectedProject ? state.selectedProject : ''}
                        callbackMultiSelect={(value) => {
                            setState(prevState => ({ 
                                ...prevState, 
                                selectedProject: value, 
                                selectedProjectTags: state.projects.filter(e => e.project === value).length && state.projects.filter(e => e.project === value)[0] && state.projects.filter(e => e.project === value)[0].tags ? state.projects.filter(e => e.project === value)[0].tags : [], selectedAccount: [], selectedRegion: [], selectedClusters: [], accounts: [], regions: [], clusters: [], callAccountList: true, callRegionList: true, callClusterList: true }))
                        }}
                        singleSelection={true}
                        dropdownWidth={'w-28'}
                    />
                </div>
            : null}
            {props.filters && props.filters.includes('account') ?
                <div className=' self-end'>
                    <MultiSelectSectionNew
                        label={"Account"}
                        fields={["account_id", "account_name"]}
                        options={state.accounts}
                        selectedValues={state.selectedAccount ? state.selectedAccount : []}
                        callbackMultiSelect={(value) => {
                            setState(prevState => ({ ...prevState, selectedAccount: value, callRegionList: true, callClusterList: true }))
                        }}
                        dropdownWidth={'w-28'}
                    />
                </div>
            : null}
            {props.filters && props.filters.includes('region') ?
                <div className=' self-end'>
                    <MultiSelectSectionNew
                        label={"Region"}
                        fields={["region", "name"]}
                        options={state.regions}
                        selectedValues={state.selectedRegion ? state.selectedRegion : []}
                        callbackMultiSelect={(value) => {
                            setState(prevState => ({ ...prevState, selectedRegion: value, callClusterList: true }))
                        }}
                        dropdownWidth={'w-28'}
                    />
                </div>
            : null}
            {props.filters && props.filters.includes('cluster') ?
                !props.showSelectedCluster ?
                    <div className=' self-end'>
                        <MultiSelectSectionNew
                            label={"Cluster"}
                            options={state.clusters}
                            selectedValues={state.selectedClusters ? state.selectedClusters : []}
                            callbackMultiSelect={(value) => {
                                setState(prevState => ({ ...prevState, selectedClusters: value }))
                            }}
                            dropdownWidth={'w-28'}
                        />
                    </div>
                : 
                    <div className=' self-end'>
                        <MultiSelectSectionNew
                            label={"Cluster"}
                            options={state.clusters}
                            selectedValues={state.selectedClusters ? state.selectedClusters : ''}
                            callbackMultiSelect={(value) => {
                                setState(prevState => ({ ...prevState, selectedClusters: value }))
                            }}
                            dropdownWidth={'w-28'}
                            singleSelection={true}
                        />
                    </div>
            : null}
            {props.filters && props.filters.includes('queue') ?
                <div className=' self-end'>
                    <MultiSelectSectionNew
                        label={"Cluster"}
                        options={state.queues}
                        selectedValues={state.selectedQueue ? state.selectedQueue : []}
                        callbackMultiSelect={(value) => {
                            setState(prevState => ({ ...prevState, selectedQueue: value }))
                        }}
                        dropdownWidth={'w-28'}
                    />
                </div>
            : null}
            {props.filters && props.filters.includes('user') ?
                <div className=' self-end'>
                    <MultiSelectSectionNew
                        label={"User"}
                        options={state.usersList}
                        selectedValues={state.selectedUser ? state.selectedUser : []}
                        callbackMultiSelect={(value) => {
                            setState(prevState => ({ ...prevState, selectedUser: value }))
                        }}
                        dropdownWidth={'w-28'}
                    />
                </div>
            : null}
                
            {props.filters && props.filters.includes('manage_qos') ?
                <div className=' self-end'>
                    <MultiSelectSectionNew
                        label={"Manage"}
                        options={state.screens}
                        selectedValues={state.changeManage ? state.changeManage : ''}
                        callbackMultiSelect={(value) => {
                            setState(prevState => ({ ...prevState, changeManage: value }))
                        }}
                        dropdownWidth={'w-28'}
                        singleSelection={true}
                    />
                </div>
            : null}

            {props.filters && props.filters.includes('type') ?
                <React.Fragment>
                <div className=' self-end'>
                    <MultiSelectSectionNew
                        label={"Type"}
                        fields={["value", "label"]}
                        options={state.reportViewMode}
                        selectedLabel={true}
                        selectedValues={state.selectedType ? state.selectedType : ''}
                        callbackMultiSelect={(value) => {
                            setState(prevState => ({ ...prevState, selectedType: value }))
                        }}
                        dropdownWidth={'w-28'}
                        singleSelection={true}
                    />
                </div>
                {state.selectedType === "user" ?
                    <div className=' self-end'>
                        <MultiSelectSectionNew
                            label={"User"}
                            options={state.usersList}
                            selectedValues={state.selectedUser ? state.selectedUser : []}
                            callbackMultiSelect={(value) => {
                                setState(prevState => ({ ...prevState, selectedUser: value }))
                            }}
                            dropdownWidth={'w-28'}
                        />
                    </div>
                : state.selectedType === 'project' ?
                    <div className=' self-end'>
                        <MultiSelectSectionNew
                            label={"Project"}
                            fields={["project", "project"]}
                            options={state.projects}
                            selectedValues={state.selectedProject ? state.selectedProject : ''}
                            callbackMultiSelect={(value) => {
                                setState(prevState => ({ 
                                    ...prevState, 
                                    selectedProject: value, 
                                    selectedProjectTags: state.projects.filter(e => e.project === value).length && state.projects.filter(e => e.project === value)[0] && state.projects.filter(e => e.project === value)[0].tags ? state.projects.filter(e => e.project === value)[0].tags : [], selectedAccount: [], selectedRegion: [], selectedClusters: [], accounts: [], regions: [], clusters: [], callAccountList: true, callRegionList: true, callClusterList: true }))
                            }}
                            singleSelection={true}
                            dropdownWidth={'w-28'}
                        />
                    </div>
                : null}
                </React.Fragment>
            : null}

            {props.filters && props.filters.includes('duration') ?
                <div className='mr-3 pr-2 self-end'>
                    <label className='text-white text-base font-medium'>Duration</label>
                    <SmartDateRangePicker
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        setQueryDate={setQueryDate}
                        queryDate={queryDate}
                    />
                </div>
            : null}
                
            {props.filters && props.filters.includes('lifecycle_policy_type') ?
                <div className=' self-end'>
                    <MultiSelectSectionNew
                        label={"Policy Type"}
                        options={state.lifeCylcePolicyTypes}
                        selectedValues={state.selectedPolicy ? state.selectedPolicy : ''}
                        callbackMultiSelect={(value) => {
                            setState(prevState => ({ ...prevState, selectedPolicy: value  }))
                        }}
                        singleSelection={true}
                        dropdownWidth={'w-28'}
                    />
                </div>
            : null}

            <div className='self-end'>
                {props.filterAdvancedSearch ?
                    <p className='pl-[33px] pr-[34px] py-[3px] text-base border border-paleLavender-600  rounded-full text-white flex justify-center cursor-pointer mb-4' onClick={() => props.onClickAdvancedSearch()}>Advanced</p>
                : null}
                <div className="flex space-x-0 w-fit" role="group">
                    <button type="button" className="text-base bg-pumpkin-600 rounded-md text-white font-bold py-2.5 pr-4 pl-3 inline-flex items-center mr-2"
                        onClick={() => setState(prevState => ({ ...prevState, callSearch: true }))}
                    >
                        <Icon icon="weui:search-filled" className="text-white" width={24} height={24} />
                        <span className='pl-1 text-white'>Search</span>
                    </button>
                    <button type="button" className="text-xl bg-pumpkin-600 rounded-md text-white font-bold px-2.5 pr-3 pl-4 inline-flex items-center"
                        onClick={() => onReset()}
                    >
                        <Icon icon="fa6-solid:arrows-rotate" className="text-white" width={24} height={24} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FilterSection;


export const defaultDateRangeValue = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    let startMonth = currentMonth;
    let startYear = currentYear;
    if (startMonth < 0) {
      startMonth += 12;
      startYear--;
    }
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    endDate.setHours(
      today.getHours(),
      today.getMinutes(),
      today.getSeconds(),
      today.getMilliseconds(),
    );
    const startDate = new Date(startYear, startMonth, 1);
    return [startDate, endDate];
};