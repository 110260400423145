/*************************************************
 * Collider
 * @exports
 * @file CreateUpdateCatalogPanel.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, getAllTagsValues } from '../../../actions/commonActionNew'
import { listCatalogFilters, createCatalog, updateCatalog  } from '../../../actions/Collider/CatalogAction'
import { setHpcPropsDetails } from "../../../actions/Collider/HpcAction";
import { Store as CommonNotification } from 'react-notifications-component';
// import yaml from 'js-yaml'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import MultiSelectSection from '../../common/MultiSelectSection';
import { capitalizeAllLetter, getAccountNameFromId, getRegionName, convertToLowerCase } from '../../../utils/utility';
import {Icon} from "@iconify/react";
import { LoadingCircle } from '../../common/LoadingCiricle';
import PageLoading from '../../common/PageLoading';

const CreateUpdateCatalogPanel = ({ closeSidePanel, pageMode }) => {

    const clickOut = useRef();

    const [state, setState] = useState({
        selectedProvider: '',
        selectedAccount: '',
        selectedRegion: '',
        instanceTypes: [],
    })

    // const allStates = useSelector(state => state)
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const CreateUpdateCatalog = useSelector(state => state?.hpc?.hpcPropsDetails?.CreateUpdateCatalog || false);
    // var CreateUpdateCatalog = useSelector(state => state?.hpc?.hpcPropsDetails?.CreateUpdateCatalog || false);
    const dispatch = useDispatch(); // Create a dispatch function

    const callPageVist = useCallback(() => {
        let catalogDetails = CreateUpdateCatalog ? CreateUpdateCatalog : {}
        Object.entries(catalogDetails).forEach(([key, value]) => {
            if (key === "allowed_instance_types" && value && catalogDetails?.[key]?.length) {
                setState((prevState) => ({ ...prevState, allowed_instance_types: catalogDetails?.[key]?.map((item) => item.instance_type) }));
            }  else {
                if (key === "provider" && value){
                    setState((prevState) => ({ ...prevState, selectedProvider: value }));
                } if (key === "account_id" && value){
                    setState((prevState) => ({ ...prevState, selectedAccount: value }));
                } if (key === "region" && value){
                    setState((prevState) => ({ ...prevState, selectedRegion: value }));
                }
                setState((prevState) => ({ ...prevState, [key]: value }));
            }
        });
      }, [CreateUpdateCatalog]);

    useEffect(() => callPageVist(), [callPageVist]);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? '' : providers[0].provider_name) : "", callSearch: true,
            }));
        } else { 
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? '' : response[0].provider_name) : '', callSearch: true
                        }));
                    }
                })
        }
    }, [dispatch, providers]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
            if(state.selectedProjectTags) {
                label = ''
                params.project_tags = state.selectedProjectTags
            }
			if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
            } 
        }
    }, [state.selectedProvider, dispatch, propProjAccounts, state.selectedProject, state.selectedProjectTags]); 

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount) {
                label = ''
                params.account_id = state.selectedAccount
            }
            if(state.selectedProject) {
                label = '_'
                params.project_tags = state.selectedProjectTags
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
            } 
        }
    }, [state.selectedProvider, dispatch, propProjRegions, state.selectedAccount, state.selectedProject, state.selectedProjectTags]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        let params = {}
        if (state.provider) {
            params.provider = state.provider
        } if (state.account_id) {
            params.account_id = state.account_id
        } if (state.region) {
            params.region = state.region
        } if (state.platform) {
            params.platform = state.platform
        }
        dispatch(listCatalogFilters(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({
                        ...prevState,
                        VPCIdOptions: response.vpc_ids ? response.vpc_ids : {},
                        subnetIdOptions: response.subnet_ids ? response.subnet_ids : [],
                        templateOptions: response.template ? response.template : [],
                        templateNameOptions: response.template_name ? response.template_name : {},
                        instanceTypes: response.instance_type ? response.instance_type : [],
                        keyPairs: response.key_pair ? response.key_pair : [],
                        operatingSystemOptions: response.os ? response.os : [],
                        imageIdOptions: response.image_ids ? response.image_ids : []
                    }))
                }
            })
    }, [dispatch, state.provider, state.account_id, state.region, state.platform])

    useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ 'key': state.tag_key }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
                    if(response) {
					    setState(prevState => ({ ...prevState, tagValues: response }))
                    }
				})
		}
	}, [state.tag_key, dispatch])

    useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let hasError = false
            let params = CreateUpdateCatalog
            if (!params.template_name){
                params.template_name = "default"
            }

            if(!state.provider || !state.account_id || !state.region || !state.catalog_name || !state.image_id || !state.vpc_id || !state.subnet_id || !state.allowed_instance_types || !state.template_name) {
                hasError = true
            }
            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

           if(!hasError) {
                if(pageMode === 'Create') {
                    dispatch(createCatalog(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in saving Catalog'

                            if(response && response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'Catalog Created Successfully'

                                setTimeout(() => closeSidePanel('refresh'), 2000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }

                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
            
                            // setState(prevState => ({ ...prevState, createRespons: response }))
                        })
                } else {
                    dispatch(updateCatalog(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in updating Catalog'
                            if(response && response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'Catalog Updated Successfully'
                                setTimeout(() => closeSidePanel('refresh'), 2000)
                            }

                            setTimeout(() => setState(prevState => ({ ...prevState, saveLoading: false })), 2000)
            
                            // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
                        })
                }
            }
        }
    }, [state.callSaveFunction, dispatch, pageMode, closeSidePanel, CreateUpdateCatalog])    


	const handleCatalogInputChange = (label, value) => {
        let clusterConfiguration = CreateUpdateCatalog ? CreateUpdateCatalog : {};
        clusterConfiguration[label] = value;
        dispatch(setHpcPropsDetails("CreateUpdateCatalog", clusterConfiguration));
        setState((prevState) => ({ ...prevState, [label]: value }));
	}

	const removefromList = (value, listField) => {
        let filteredListItem = state[listField+''].filter(e => e !== value)

        let array = []
        filteredListItem.forEach(row => {
            let rowValue = {
                value: row,
                label: row
            }
            array.push(rowValue)
        })

        setState(prevState => ({ ...prevState, [listField+'']: filteredListItem }))
    }

    const addNewTagValue = () => {
        let selectedTags = state.tags ? state.tags : [];
    
        if (state.tag_key && state.tag_value && state.tag_value.length) {
          let dataRow = {};
          dataRow.key = state.tag_key;
          dataRow.value = state.tag_value;
          selectedTags.push(dataRow);
        }
    
        setState((prevState) => ({
          ...prevState,
          tags: selectedTags,
          tag_key: "",
          tag_value: "",
        }));
    
        handleCatalogInputChange("tags", selectedTags);
      };
    
      const removeTagSection = (tag) => {
        let filteredResult =
          state.tags && state.tags.filter((e) => e !== tag);
        setState((prevState) => ({ ...prevState, tags: filteredResult }));
    
        handleCatalogInputChange("tags", filteredResult);
      };

    const addNewSoftwaresIntalled = () => {
        let softwaresInstalled = state.installed_softwares ? state.installed_softwares : [];

        if (state.software && state.version && state.version.length) {
            let dataRow = {};
            dataRow.software = state.software;
            dataRow.version = state.version;
            softwaresInstalled.push(dataRow);
        }

        setState((prevState) => ({
            ...prevState,
            installed_softwares: softwaresInstalled,
            software: "",
            version: "",
        }));

        handleCatalogInputChange("installed_softwares", softwaresInstalled);
    };

    const removeSoftwaresIntalled = (tag) => {
        let filteredResult = state.installed_softwares && state.installed_softwares.filter((e) => e !== tag);
        setState((prevState) => ({ ...prevState, installed_softwares: filteredResult }));

        handleCatalogInputChange("installed_softwares", filteredResult);
    };
    
	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-3/4 w-10/12' ref={clickOut}>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-black'>{pageMode} Catalog</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                    <div className='overflow-y-auto p-8'>
                        <div className='rounded-md bg-white p-3'>
                            {state.createErrorMessage ?
                                <p className='text-red mb-2 text-center f18'>Error in saving data</p>
                            : null}
                            <div className='flex flex-wrap'>
                                <div className='py-1 lg:w-1/3 md:w-1/2 w-full'>
                                    <div className='w-2/3'>
                                        <div className='flex justify-between'>
                                            <p className="b-block mb-0">Provider</p>
                                            {state.hasError && !state.selectedProvider ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </div>
                                        <div onClick={(event) => {
                                            if(!state.isProviderOpen) {
                                                event.preventDefault();
                                                handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                                            }
                                        }}>
                                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state.selectedProvider ? 'text-black' : 'text-lightGray'}`}>
                                                {state.selectedProvider ? capitalizeAllLetter(state.selectedProvider) : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${state.selectedProvider ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            {state.isProviderOpen && pageMode === 'Create' ?
                                                <div className='relative'>
                                                    <MultiSelectSection 
                                                        fields={["provider_name", "provider_name"]}
                                                        options={state.providers}
                                                        selectedValues={state.selectedProvider ? state.selectedProvider : []}
                                                        callbackMultiSelect={(value) => {
                                                            if(!value || typeof(value) === 'string') {
                                                                setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: "", selectedRegion: ""}))
                                                                handleCatalogInputChange("provider", convertToLowerCase(value))
                                                            } else {
                                                                value.preventDefault()
                                                                handleChildClick(value, "search", 'singleDropDown', "")
                                                            }
                                                        }}
                                                        singleSelection={true}
                                                        widthClass={'minWidth220'}
                                                        removeTopOptions={true}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='py-1 lg:w-1/3 md:w-1/2 w-full'>
                                    <div className='w-2/3'>
                                        <div className='flex justify-between'>
                                            <p className="b-block mb-0">Account</p>
                                            {state.hasError && !state.selectedAccount ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </div>
                                        <div onClick={(event) => {
                                            if(!state.isAccountOpen) {
                                                event.preventDefault();
                                                handleChildClick(event, 'child', 'singleDropDown', "isAccountOpen")
                                            }
                                        }}>
                                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state.selectedAccount ? 'text-black' : 'text-lightGray'}`}>
                                                {state.selectedAccount ? getAccountNameFromId(state.selectedAccount, state.accounts) : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${state.selectedAccount ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            {state.isAccountOpen && pageMode === 'Create' ?
                                                <div className='relative'>
                                                    <MultiSelectSection 
                                                        fields={["account_id", "account_name"]}
                                                        options={state.accounts}
                                                        selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                                        callbackMultiSelect={(value) => {
                                                            if(!value || typeof(value) === 'string') {
                                                                setState(prevState => ({ ...prevState,  selectedAccount: value }))
                                                                handleCatalogInputChange("account_id", value)
                                                            } else {
                                                                value.preventDefault()
                                                                handleChildClick(value, "search", 'singleDropDown', "")
                                                            }
                                                        }}
                                                        singleSelection={true}
                                                        removeTopOptions={true}
                                                        widthClass={'minWidth220'}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='py-1 lg:w-1/3 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                    <div className='w-2/3'>
                                        <div className='flex justify-between'>
                                            <p className="b-block mb-0">Region</p>
                                            {state.hasError && !state.selectedRegion ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </div>
                                        <div onClick={(event) => {
                                            if(!state.isRegionOpen) {
                                                event.preventDefault();
                                                handleChildClick(event, 'child', 'singleDropDown', "isRegionOpen")
                                            }
                                        }}>
                                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state.selectedRegion ? 'text-black' : 'text-lightGray'}`}>
                                                {state.selectedRegion ? getRegionName(state.selectedRegion, state.regions) : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            {state.isRegionOpen && pageMode === 'Create' ?
                                                <div className='relative'>
                                                    <MultiSelectSection 
                                                        fields={["region", "name"]}
                                                        options={state.regions}
                                                        selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                                        callbackMultiSelect={(value) => {
                                                            if(!value || typeof(value) === 'string') {
                                                                setState(prevState => ({ ...prevState, selectedRegion: value }))
                                                                handleCatalogInputChange("region", value)
                                                            } else {
                                                                value.preventDefault()
                                                                handleChildClick(value, "search", 'singleDropDown', "")
                                                            }
                                                        }}
                                                        singleSelection={true}
                                                        removeTopOptions={true}
                                                        widthClass={'minWidth220'}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className='b-block mt-3 mb-1 text-info f18'>Catalog Configurations</p>
                        <div className='rounded-md bg-white p-3 my-2'>
                            <div className='flex flex-wrap mt-2'>
                                <div className='py-1 lg:w-5/12 md:w-1/2 w-full'>
                                    <p className='b-block mb-0 flex'>
                                        Catalog Name <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                        {state.hasError && !state['catalog_name'] ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </p>
                                        <input
                                            type='text'
                                            placeholder='Enter catalog name'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                            // maxlength='128'
                                            value={state['catalog_name'] ? state['catalog_name'] : ''}
                                            onChange={e => handleCatalogInputChange('catalog_name', e.target.value)}
                                            disabled={pageMode === 'Edit'} 
                                        />
                                </div>
                                <div className='py-1 lg:w-5/12 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                    <p className='b-block mb-0 flex'>
                                        Description
                                    </p>
                                        <textarea
                                            id="description"
                                            name="description"
                                            rows="4"
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                            onChange={e => { handleCatalogInputChange('description', e.target.value) }}
                                            value={state.description}
                                            placeholder='Enter description'
                                        />
                                </div>
                            </div>

                            <div className='flex flex-wrap mt-2'>
                                <div className='py-1 lg:w-5/12 md:w-1/2 w-full'>
                                    <div onClick={(event) => {
                                        if(!state['showOperatingSystem']) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', 'showOperatingSystem')
                                        }
                                    }}>
                                        <p className='b-block mb-0 flex truncate'>Operating System <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                            {state.hasError && !state['platform'] ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </p>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state['platform'] ? 'text-black' : 'text-lightGray'}`}>
                                            {state['platform'] ? state['platform'] : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state['platform'] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                            {state['showOperatingSystem'] && pageMode === 'Create' ?
                                            <div className='relative'>
                                            <MultiSelectSection
                                                options={state.operatingSystemOptions ? state.operatingSystemOptions : []}
                                                selectedValues={state['platform'] ? state['platform'] : []}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, platform: value }))
                                                        handleCatalogInputChange("platform", value)
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}
                                                singleSelection={true}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                <div className='py-1 lg:w-5/12 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                    <div onClick={(event) => {
                                        if(!state['showTemplateName']) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', 'showTemplateName')
                                        }
                                    }}>
                                        <p className='b-block mb-0 flex truncate'>Template <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                            {state.hasError && !state['template_name'] ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </p>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state['template_name'] ? 'text-black' : 'text-lightGray'}`}>
                                            {state['template_name'] ? state['template_name'] : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state['template_name'] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                            {state['showTemplateName'] && pageMode === 'Create' ?
                                            <div className='relative'>
                                            <MultiSelectSection
                                                options={state?.templateNameOptions?.[state?.platform] ? state?.templateNameOptions?.[state?.platform] : []}
                                                selectedValues={state.template_name ? state.template_name : ""}
                                                callbackMultiSelect={(value) => {
                                                    if (!value || typeof value === "string") {
                                                        setState((prevState) => ({ ...prevState, template_name: value}));
                                                        handleCatalogInputChange("template_name", value)
                                                    } else {
                                                        value.preventDefault();
                                                        handleChildClick(value, "search", "singleDropDown", "");
                                                    }
                                                }}
                                                singleSelection={true}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-wrap mt-2'>
                                <div className="py-1 lg:w-5/12 md:w-1/2 w-full">
                                    <div
                                        onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, "child", "muliselectDropDown", "showInstanceTypes");
                                        }}
                                    >
                                        <div className="flex flex-wrap">
                                            <p className="b-block mb-0 flex truncate">Allowed Instance Types <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                                {state.hasError && !state['allowed_instance_types'] ?
                                                    <span className='mb-0 text-red ml-2'>required</span>
                                                : null}
                                            </p>
                                        </div>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 ${state.allowed_instance_types && state.allowed_instance_types.length ? 'text-black' : 'text-lightGray'}`}>
                                            {state.allowed_instance_types && state.allowed_instance_types.length ? state.allowed_instance_types.length+' Selected' : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.allowed_instance_types && state.allowed_instance_types.length ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.showInstanceTypes && pageMode !== 'View'? (
                                        <div className="relative">
                                            <MultiSelectSection
                                            removeTopOptions={true}
                                            options={state.instanceTypes? state.instanceTypes : []}
                                            selectedValues={state.allowed_instance_types ? state.allowed_instance_types : []}
                                            callbackMultiSelect={(value) => {
                                                setState((prevState) => ({ ...prevState, allowed_instance_types: value}));
                                                handleCatalogInputChange("allowed_instance_types", value)
                                            }}
                                            widthClass={"minWidth220"}
                                            />
                                        </div>
                                        ) : null}
                                        </div>
                                        <div className="flex flex-wrap">
                                        {state.allowed_instance_types && state.allowed_instance_types.length
                                        ? state.allowed_instance_types.map((row) => {
                                            return (
                                                <span className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">
                                                    {row}<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(row, 'allowed_instance_types') }/>
                                                </span>
                                            );
                                            })
                                        : null}
                                    </div>
                                </div>
                                <div className='py-1 lg:w-5/12 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                    <p className='b-block mb-0 flex'> 
                                        Category
                                    </p>
                                        <input
                                            type='text'
                                            placeholder='Enter category'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                            // maxlength='128'
                                            value={state['category'] ? state['category'] : ''}
                                            onChange={e => handleCatalogInputChange('category', e.target.value)}
                                        />
                                </div>
                            </div>

                            <div className='py-3 w-full'>
                                <p className='b-block mb-0'>Tags</p>
                                <div className='flex flex-wrap mb-1'>
                                    <div className='md:w-5/12 w-full'>
                                        <input
                                            type='text'
                                            placeholder='Enter key'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black lg:w-full md:w-1/2`}
                                            // maxlength='128'
                                            value={state.tag_key ? state.tag_key : ''}
                                            onChange={e =>
                                                setState(prevState => ({ ...prevState, tag_key: e.target.value
                                            }))}
                                        />
                                    </div>
                                    <div className='md:w-5/12 w-full lg:pl-3 md:pl-3 pl-0 lg:mt-0 md:mt-0 mt-2'>
                                        <input
                                            type='text'
                                            placeholder='Enter value'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black lg:w-full md:w-1/2`}
                                            // maxlength='128'
                                            value={state.tag_value ? state.tag_value : ''}
                                            onChange={e =>
                                                setState(prevState => ({ ...prevState, tag_value: e.target.value
                                            }))}
                                        />
                                    </div>
                                    <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 mt-0 cursor-pointer' onClick={() => addNewTagValue()}/>
                                </div>
                                {state.tags && state.tags.length ? 
                                    <div className='flex flex-wrap'>
                                        {state.tags.filter(e => e.value !== 'All').map((tag, tIndex) => {
                                            return(
                                                <span key={'tag_'+tIndex} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all' id={'tag_'+tIndex}>
                                                    <div className='truncate'  style={{maxWidth: '250px'}}>
                                                        {tag.key +' : '+tag.value}
                                                    </div>
                                                    <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeTagSection(tag)}/>
                                                </span>
                                            )
                                        })}
                                    </div>
                                : null}
                            </div>
                        </div>

                        <p className='b-block mt-3 mb-1 text-info f18'>Image Configurations</p>
                        <div className='rounded-md bg-white p-3 my-2'>
                            <div className='flex flex-wrap mt-2'>
                            <div className='py-1 lg:w-5/12 md:w-1/2 w-full'>
                                    <div onClick={(event) => {
                                        if(!state['showImageId']) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', 'showImageId')
                                        }
                                    }}>
                                        <p className='b-block mb-0 flex truncate'>Image Id <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                            {state.hasError && !state['image_id'] ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </p>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state['image_id'] ? 'text-black' : 'text-lightGray'}`}>
                                            {state['image_id'] ? state['image_id'] : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state['image_id'] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                            {state['showImageId'] && pageMode === 'Create' ?
                                            <div className='relative'>
                                            <MultiSelectSection
                                                options={state.imageIdOptions ? state.imageIdOptions : []}
                                                selectedValues={state['image_id'] ? state['image_id'] : []}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, image_id: value }))
                                                        handleCatalogInputChange("image_id", value)
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}
                                                singleSelection={true}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                            <div className='py-3 w-full'>
                                <p className='b-block mb-0'>Softwares Installed</p>
                                <div className='flex flex-wrap mb-1'>
                                    <div className='md:w-5/12 w-full'>
                                        <input
                                            type='text'
                                            placeholder='Enter software name'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black lg:w-full md:w-1/2`}
                                            // maxlength='128'
                                            value={state.software ? state.software : ''}
                                            onChange={e => setState(prevState => ({ ...prevState, software: e.target.value }))}
                                        />
                                    </div>
                                    <div className='md:w-5/12 w-full lg:pl-3 md:pl-3 pl-0 lg:mt-0 md:mt-0 mt-2'>
                                        <input
                                            type='text'
                                            placeholder='Enter software version'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black lg:w-full md:w-1/2`}
                                            // maxlength='128'
                                            value={state.version ? state.version : ''}
                                            onChange={e => setState(prevState => ({ ...prevState, version: e.target.value }))}
                                        />
                                    </div>
                                    <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 mt-0 cursor-pointer' onClick={() => addNewSoftwaresIntalled()}/>
                                </div>
                                {state.installed_softwares && state.installed_softwares.length ? 
                                    <div className='flex flex-wrap'>
                                        {state.installed_softwares.filter(e => e.version !== 'All').map((softwares, swIndex) => {
                                            return(
                                                <span software={'installed_softwares_'+swIndex} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all' id={'installed_softwares_'+swIndex}>
                                                    <div className='truncate'  style={{maxWidth: '250px'}}>
                                                        {softwares.software +' : '+softwares.version}
                                                    </div>
                                                    <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeSoftwaresIntalled(softwares)}/>
                                                </span>
                                            )
                                        })}
                                    </div>
                                : null}
                            </div>
                        </div>

                        <p className='b-block mt-3 mb-1 text-info f18'>Network Configurations</p>
                        <div className='rounded-md bg-white p-3 my-2'>
                            <div className='flex flex-wrap mt-2'>
                            <div className='py-1 lg:w-5/12 md:w-1/2 w-full'>
                                    <div onClick={(event) => {
                                        if(!state['showVpcId']) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', 'showVpcId')
                                        }
                                    }}>
                                        <p className='b-block mb-0 flex truncate'>VPC Id<Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                            {state.hasError && !state['vpc_id'] ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </p>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state['vpc_id'] ? 'text-black' : 'text-lightGray'}`}>
                                            {state['vpc_id'] ? state['vpc_id'] : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state['vpc_id'] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                            {state['showVpcId'] && pageMode === 'Create' ?
                                            <div className='relative'>
                                            <MultiSelectSection
                                                options={state.VPCIdOptions ?  Object.keys(state.VPCIdOptions) : []}
                                                selectedValues={state['vpc_id'] ? state['vpc_id'] : []}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, vpc_id: value}))
                                                        handleCatalogInputChange("vpc_id", value)
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}
                                                singleSelection={true}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                <div className='py-1 lg:w-5/12 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                    <div onClick={(event) => {
                                        if(!state['showSubnetId']) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', 'showSubnetId')
                                        }
                                    }}>
                                        <p className='b-block mb-0 flex truncate'>Subnet Id <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                            {state.hasError && !state['subnet_id'] ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </p>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state['subnet_id'] ? 'text-black' : 'text-lightGray'}`}>
                                            {state['subnet_id'] ? state['subnet_id'] : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state['subnet_id'] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                            {state['showSubnetId'] && pageMode === 'Create' ?
                                            <div className='relative'>
                                            <MultiSelectSection
                                                options={state?.VPCIdOptions?.[state?.vpc_id] ? state?.VPCIdOptions?.[state?.vpc_id] : []}
                                                selectedValues={state.subnet_id ? state.subnet_id : ""}
                                                callbackMultiSelect={(value) => {
                                                    if (!value || typeof value === "string") {
                                                        setState((prevState) => ({ ...prevState, subnet_id: value}));
                                                        handleCatalogInputChange("subnet_id", value)
                                                    } else {
                                                        value.preventDefault();
                                                        handleChildClick(value, "search", "singleDropDown", "");
                                                    }
                                                }}
                                                singleSelection={true}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className='b-block mt-3 mb-1 text-info f18'>Security Configurations</p>
                        <div className='rounded-md bg-white p-3 my-2'>
                            <div className='flex flex-wrap mt-2'>
                            <div className="py-1 lg:w-5/12 md:w-1/2 w-full">
                                    <div className="flex justify-content-between">
                                    <label className="self-center m-0 text-black">Key pair name</label>
                                    {/* {state.hasError && !state.key_pair_name ? (
                                        <small className="text-issues">required</small>
                                    ) : null} */}
                                    </div>
                                    <div
                                    className="text-black border-lightGray bg-transparent rounded-5"
                                    onClick={(event) => {
                                        if (!state.showKeyPair) {
                                        event.preventDefault();
                                        handleChildClick(event,"child","singleDropDown","showKeyPair");
                                        }
                                    }}
                                    >
                                    <p
                                        className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                                        state.key_pair_name ? "text-black" : "placeholder"
                                        }`}
                                    >
                                        {state.key_pair_name ? state.key_pair_name : "Select"}
                                        {pageMode === "Create" ? (
                                        <Icon icon="icon-park-solid:down-one"  className={`${state.key_pair_name ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        ) : null}
                                    </p>
                                        {state.showKeyPair && pageMode === 'Create' ?
                                        <div className="relative">
                                            <MultiSelectSection
                                            options={ state.keyPairs ? state.keyPairs : [] }
                                            selectedValues={state.key_pair_name ? state.key_pair_name : ""}
                                            callbackMultiSelect={(value) => {
                                                if (!value || typeof value === "string") {
                                                    setState((prevState) => ({ ...prevState, key_pair_name: value}));
                                                    handleCatalogInputChange("key_pair_name", value)
                                                } else {
                                                    value.preventDefault();
                                                    handleChildClick(value, "search", "singleDropDown", "");
                                                }
                                            }}
                                            singleSelection={true}
                                            hideSearch={false}
                                            widthClass={"minWidth220"}
                                            removeTopOptions={true}
                                            />
                                        </div>
                                    : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className='b-block mt-3 mb-1 text-info f18'>Domain Joining - Authetication Configurations</p>
                        <div className='rounded-md bg-white p-3 my-2'>
                            <div className='flex flex-wrap mt-2'>
                                <div className='py-1 lg:w-5/12 md:w-1/2 w-full'>
                                    <p className='b-block mb-0 flex'>
                                        Domain Name
                                        {/* {state.hasError && !state['domain_name'] ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null} */}
                                    </p>
                                        <input
                                            type='text'
                                            placeholder='Enter domain name'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                            // maxlength='128'
                                            value={state['domain_name'] ? state['domain_name'] : ''}
                                            onChange={e => handleCatalogInputChange('domain_name', e.target.value)}
                                        />
                                </div>
                                <div className='py-1 lg:w-5/12 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                    <p className='b-block mb-0 flex'>
                                        Secret Arn
                                        {/* {state.hasError && !state['secret_arn'] ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null} */}
                                    </p>
                                        <input
                                            type='text'
                                            placeholder='Enter secret arn'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                            // maxlength='128'
                                            value={state['secret_arn'] ? state['secret_arn'] : ''}
                                            onChange={e => handleCatalogInputChange('secret_arn', e.target.value)}
                                        />
                                </div>
                            </div>
                        </div>

                        <div className='flex bottom-4 right-4 justify-end bg-transparent my-2'>
                            <div className={`flex justify-end w-full`}>
                                {state.saveLoading ?
                                    <button className={`flex bg-green-600 text-white text-base font-medium rounded-md min-w-24 px-3 py-2.5 text-center self-center cursor-pointer`}>
                                        <LoadingCircle />
                                        {pageMode === 'Edit' ? 'Updating' : 'Saving'}
                                    </button>
                                :  pageMode !== 'View' ? 
                                    <button className={`bg-green-600 text-white text-base font-medium rounded-md min-w-24 px-3 py-2.5 text-center self-center cursor-pointer`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))}>
                                        {pageMode === 'Edit' ? 'Update' : 'Save'}
                                    </button>
                                : null}
                                <button className={`ml-3 bg-ferrariRed-600 text-white text-base font-medium rounded-md min-w-24 px-3 py-2.5 text-center self-center cursor-pointer`} onClick={ () => closeSidePanel()}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateUpdateCatalogPanel