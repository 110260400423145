/*************************************************
 * Tvastar
 * @exports
 * @file Ec2ServiceBlockStore.js
 * @author Prakash // on 14/09/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Icon} from "@iconify/react";
import MultiSelectSection from '../../common/MultiSelectSection';
import { setGovernancePropsDetails, setNewEditRuleDetails } from '../../../actions/governance/GovernanceAction'
import { DynamicApiToDevCall } from '../../../actions/commonActionNew'
import { onlyNumeric } from '../../../utils/utility'

const Ec2ServiceBlockStore = (props) => {

	const [state, setState] = useState({
		policyArray: [0],

		hasErrorInRuleForm: false,
		hasError: props.hasError,
		timeTypeOptions: [
			{value: "h", label: "Hour(s)"},
			{value: "m", label: "Minute(s)"}
		],
		time_type_0: "h",
		onLoad: true
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const editNewRuleDetails = useSelector(state => state?.governance?.editNewRuleDetails)
	const onClickFinish = useSelector(state => state?.governance?.governancePropsDetails?.onClickFinish||false)

	const structureAPiCallResponse = useCallback((response, variable) => {
		let options= []
		response.length && response.forEach(item => {
			let dataRow = {}
			dataRow.label = item.volume_details.volume_type
			dataRow.value = item.volume_details.volume_type
			options.push(dataRow)
		})

		setState(prevState => ({ ...prevState, [variable+"_options"]: options }))

		if(props.viewMode) {
			setState(prevState => ({ ...prevState, savedData: [props.masterData], callSavedData: true }))
		} else {
			if(editNewRuleDetails && editNewRuleDetails.policies) {
				if(editNewRuleDetails.policies.filter(e => e.rule_category === props.masterData.rule_category).length) {
					let savedData = editNewRuleDetails.policies.filter(f => f.rule_id === props.masterData.rule_id)
					if(savedData.length) {
						setState(prevState => ({ ...prevState, savedData, callSavedData: true }))
					}
				}
			}
		}
	}, [editNewRuleDetails, props.masterData, props.viewMode])

	const apiToCall = useCallback((apiDetails, variable) => {
		apiDetails.forEach(item => {
			let params = {}
	
			let valueRequest =  item.body && item.body.values
			valueRequest && valueRequest.forEach(req => {
				if(req.value) {
					params[req.key] = req.value
				} else if(req.value_object) {
					let valuObj = {}
					req.value_object.forEach(subReq => {
						valuObj[subReq.key] = subReq.value
					})
					params[req.key] = valuObj
				}
			})
	
			let url = item.name
			let API_END_POINT = item.api
			let apiMethod = item.method
			
			dispatch(DynamicApiToDevCall(API_END_POINT, apiMethod, url, params))
				.then((response) => {
					if(response) {
						let results = response.length && response[0].results ? response[0].results : []
						setState(prevState => ({ ...prevState, [variable+"_response"]: results.length ? results : [], loadingConditionPage: false }))
						if(props.viewMode) {
							setState(prevState => ({ ...prevState, savedData: [props.masterData], callSavedData: true }))
						} else {
							if(editNewRuleDetails && editNewRuleDetails.policies) {
								if(editNewRuleDetails.policies.filter(e => e.rule_category === props.masterData.rule_category).length) {						
									let savedData = editNewRuleDetails.policies.filter(e => e.rule_id === props.masterData.rule_id)
									if(savedData.length) {
										setState(prevState => ({ ...prevState, savedData, callSavedData: true }))
									}
								}
							}
						}
						structureAPiCallResponse(results.length ? results : [], variable)	
						
					} else {
						setState(prevState => ({ ...prevState, [variable+"_response"]: [], loadingConditionPage: false }))
					}
				})
		})
	}, [dispatch, editNewRuleDetails, props.masterData, props.viewMode, structureAPiCallResponse])
	
	useEffect(() => {
		if(state.onLoad) {
			setState(prevState => ({ ...prevState, onLoad: false }))

			let masterData = props.masterData
			let actionDetails = []
			if(masterData.actions && masterData.actions.length) {
				actionDetails = masterData.actions
			}
			let variables = masterData.variables
			variables.forEach(item => {
				if(item.options && (item.component === "select" || item.component === "multiselect")) {
					setState(prevState => ({ ...prevState, [item.variable+"_options"]: item.options, [item.variable+"_0"]: item.default ? item.default : "" }))
				} else {
					setState(prevState => ({ ...prevState, [item.variable+"_0"]: item.default ? item.default : "" }))
				}
	
				setState(prevState => ({ ...prevState, ["mode_"+item.variable]: item.mode ? item.mode : "" }))
			})
	
			setState(prevState => ({ ...prevState, tags: masterData.tags, description: masterData.description ? masterData.description : "", actionDetails }))
	
			if(masterData.api_to_call) {
				apiToCall(masterData.api_to_call, "volume_type")
			} else {					
				if(props.viewMode) {
					setState(prevState => ({ ...prevState, savedData: [props.masterData], callSavedData: true }))
				} else {
					if(editNewRuleDetails && editNewRuleDetails.policies) {
						if(editNewRuleDetails.policies.filter(e => e.rule_category === props.masterData.rule_category).length) {
							let savedData = editNewRuleDetails.policies.filter(f => f.rule_id === props.masterData.rule_id)
							if(savedData.length) {
								setState(prevState => ({ ...prevState, savedData, callSavedData: true }))
							}
						}
					}
				}
			}
		}

	}, [state.onLoad, apiToCall, editNewRuleDetails, props.masterData, props.viewMode])

	useEffect(() => {
		if(state.callSavedData) {
			setState(prevState => ({ ...prevState, callSavedData: false }))
			let savedData = state.savedData
			let policyArray = []
			savedData.forEach((row, index) => {
				policyArray.push(index)
				setState(prevState => ({ ...prevState, ["policy_id_"+index]: row.policy_id }))
				if(row.action && Object.entries(row.action)) {
					Object.entries(row.action).forEach(([key, value]) => {
						if(key === "schedule") {
							let timeSplit = value.substring(0, value.length - 1)
							let timeTypeSplit = value.substring(value.length - 1, value.length)
							if(timeTypeSplit === "h" || timeTypeSplit === "m") {
								setState(prevState => ({ ...prevState, ["time_type_"+index]: timeTypeSplit, [key+"_"+index]: timeSplit }))
							} else {
								setState(prevState => ({ ...prevState, [key+"_"+index]: value, ["time_type_"+index]: "" }))
							}
						} else {
							if(key === "name") {
								key = "selected_action"
							}
							setState(prevState => ({ ...prevState, [key+"_"+index]: value }))
						}
					})
				}
				row.variables.forEach(item => {
					setState(prevState => ({ ...prevState, [item.variable+"_"+index]: item.value_list ? item.value_list : item.value, inputDetected: true, show_child_policy_0: true }))
				})

				if(row.enabled) {
					setState(prevState => ({ ...prevState, ["enabled_"+index]: true }))
				}
				
			})
			setState(prevState => ({ ...prevState, policyArray }))
		}
	}, [state.callSavedData, state.savedData])

	const showPolicy = (array, showField, index) => {	
		state[array].forEach(item => {
			if(index === item) {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: !state[showField+"_"+item] }))
			} else {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: false }))
			}
		})
	}

	const addPolicies = (array, showField, index) => {
		let rowList = state[array];
		if(state[array]) {
			let value = state[array][state[array].length - 1]
			value = value+1 
			rowList.push(value);
		}
		rowList.forEach(item => {
			if(index === item) {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: true }))
			} else {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: false }))
			}
		})

		setState(prevState => ({ ...prevState, [array]: rowList, onChangeInput: true }))
	}

	useEffect(() => {
		if(state.onChangeInput) {
			setState(prevState => ({ ...prevState, onChangeInput: false }))
			let data = []
			state.policyArray.forEach(item => {
				let obj = {}
				if(state.edit) {
					obj.policy_id = "id"
				}
				obj.rule_category = props.masterData.rule_category
				obj.resource_type = props.masterData.resource_type
				obj.governance_type = props.masterData.governance_type
				obj.tags = props.masterData.tags
				obj.mode =  props.masterData.mode
				obj.enabled = state["enabled_"+item] ? true : false
				// obj.disabled = state["disable_"+item] ? true : false
				obj.rule_id = props.masterData.rule_id
				obj.template = false
				
				if(state["policy_id_"+item]) {
					obj.policy_id = state["policy_id_"+item]
				}

				let variables = []
				let masterVaiables = props.masterData.variables ? props.masterData.variables : []
				let inputMissing = false
				masterVaiables.forEach(itmVar => {
					let dataRow = {}
					let inputValue = state[itmVar.variable+"_"+item]
					Object.entries(itmVar).forEach(([key, value]) => {		
						if(key === "value_list" || key === "value") {
							dataRow[key] = inputValue
						} else {
							dataRow[key] = value
						}
						if(typeof inputValue !== 'boolean') {
							if(!inputValue || (inputValue && Array.isArray(inputValue) && !inputValue.length)) {
								inputMissing = true
							}
						}
					})
					variables.push(dataRow)
				})
				obj.variables = variables
				obj.description = state.description

				if(state.actionDetails && state.actionDetails.length) {
					let actionObj = {}
					actionObj.variables = []
					if(state["selected_action_"+item]) {
						actionObj.processor_rule_id = state["action_processor_rule_id_"+item]
						actionObj.name = state["selected_action_"+item]				
						if(state["apply_method_"+item]) {
							actionObj.apply_method = state["apply_method_"+item]					
							if(state["schedule_"+item] && state["schedule_"+item] !== "") {
								if(state["apply_method_"+item] === "run_after") {
									actionObj.schedule = state["schedule_"+item]+""+state["time_type_"+item]
								} else if(state["apply_method_"+item] === "run_at") {
									actionObj.schedule = state["schedule_"+item]
								}
							}
						}
						obj.action = actionObj
					}
				}

				if(!inputMissing) {
					data.push(obj)
				}
			})

			let allPolicies = editNewRuleDetails.policies ? editNewRuleDetails.policies : []

			let currentPolicies = []
			let policies = []

			if(allPolicies.length) {
				allPolicies.forEach(row => {
					if(row.rule_id === props.masterData.rule_id) {
						currentPolicies.push(row)
					} else {
						policies.push(row)
					}
				})
			}

			if(data && data.length) {
				policies = [...data, ...policies]
			}

			setTimeout(() => { dispatch(setNewEditRuleDetails('policies', policies)) }, 500);

			setState(prevState => ({ ...prevState, inputDetected: true }))
		}

	}, [dispatch, editNewRuleDetails.policies, props.governance_type, props.masterData, props.resource_type, props.rule_category, state])

	useEffect(() => {
		if(onClickFinish) {
		dispatch(setGovernancePropsDetails('onClickFinish', false))
		let hasError = false
		let inputDetected = false
		if(state.variables && state.variables.length) {
			state.policyArray.forEach(item => {
				state.variables.forEach((varb, varbIndex) => {
					if(varb.value) {
						if(state[varb.variable+"_"+item] || state[varb.variable+"_"+item] !== "") {
							inputDetected = true
						}
					} else if(varb.value_list) {
						if(state[varb.variable+"_"+item] && state[varb.variable+"_"+item].length) {
							inputDetected = true
						}
					}

					if(!hasError && (!varb.manditory || varb.manditory === "undefined")) {
						hasError = false
					} else if(!hasError && varb.manditory) {
						if(varb.value) {
							if(!state[varb.variable+"_"+item] || state[varb.variable+"_"+item] === "") {
								hasError = true
							}
						} else if(varb.value_list) {
							if(!state[varb.variable+"_"+item] && !state[varb.variable+"_"+item].length) {
								hasError = true
							}
						}
					}
				})
			})
		}

		setState(prevState => ({ ...prevState, hasError }))
		props.validateInput(hasError, inputDetected)
	    }
	}, [onClickFinish, props, state, dispatch])

	const resetPolicy = (item) => {
		setState(prevState => ({ ...prevState, ["allow_"+item]: "", ["volume_type_"+item]: "", ["max_size_per_instance_"+item]: "", ["max_iops_per_instance_"+item]: '', ["max_throughput_"+item]: '',  onChangeInput: true }))
	}
	
	// const deletePolicy = (item) => {
	// 	let array = state.policyArray.filter(e => e !== item)

	// 	if(!array.length) {
	// 		array = [0]
	// 	}
	// 	let deletedPolicies = editNewRuleDetails && editNewRuleDetails.deleted_policies ? editNewRuleDetails.deleted_policies : []
	// 	deletedPolicies.push(state["policy_id_"+item])
	// 	dispatch(setNewEditRuleDetails("deleted_policies", deletedPolicies))
		
	// 	setState(prevState => ({ ...prevState, policyArray: array, ["show_child_policy_"+item]: false }))
	// 	resetPolicy(item)
	// }

	const onKeyDownTime = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			validateTime(str, state)
		} else {
			validateTime(time, state)
		}
	}

	const validateTime = (time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 23) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length === 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time	
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00:"
			} else {
				data += firstMinute+":"
			}
		}
		
		let firstSecond = str.substring(4, 6)
		
		if(firstSecond.length) {
			if(strLength > 5 && parseInt(firstSecond) > 59) {
				data += "00"
			} else {
				data += firstSecond
			}
		}

		setState(prevState => ({ ...prevState, [state]: data, onChangeInput: true }))
	}
	
	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
			{state.hasError ?
				<p className='mb-0 text-danger'>Please check the input</p>
			: null}
			{state.policyArray && state.policyArray.map((item, index) => {
				return(
					<React.Fragment key={'policy_'+index}>
					<div className={`${!props.viewMode ? "py-3" : "py-1"} mb-2 border-b border-mediumDarkGray`}>
						{!props.viewMode ?
							<React.Fragment>
							<div className={`flex justify-between pl-2`}>
								{!state["allow_"+item] && !state["volume_type_"+item] && !state["max_size_per_instance_"+item]  && !state["max_iops_per_instance_"+item] && !state["max_throughput_"+item] ?
									<p className={`mx-2 self-center mb-1 text-lightGray cursor-pointer`} onClick={() => showPolicy("policyArray", "show_child_policy", item)}>{props.masterData && props.masterData.description ? props.masterData.description : "Choose policy details"}</p>
								:
									<p className={`mx-2 self-center mb-1 text-black`} onClick={() => showPolicy("policyArray", "show_child_policy", item)}>
										{state["allow_"+item] && state["allow_"+item] !== "" ?
											<span className="mr-2 text-info">{state["allow_"+item]}</span>
										: null}
										{state["volume_type_"+item] && state["volume_type_"+item] !== "" ?
											<React.Fragment>
											<span className="mr-2">volume typ</span>
											<span className="mr-2 text-info">{state["volume_type_"+item]}</span>
											</React.Fragment>
										: null}
										{state["max_size_per_instance_"+item] && state["max_size_per_instance_"+item] !== "" ?
											<React.Fragment>
											<span className="mr-2">max size per instance</span>
											<span className="mr-2 text-info">{state["max_size_per_instance_"+item]}</span>
											</React.Fragment>
										: null}
										{state["max_iops_per_instance_"+item] && state["max_iops_per_instance_"+item] !== "" ?
											<React.Fragment>
											<span className="mr-2">max ipos per instance</span>
											<span className="mr-2 text-info">{state["max_iops_per_instance_"+item]}</span>
											</React.Fragment>
										: null}
										{state["max_throughput_"+item] && state["max_throughput_"+item] !== "" ?
											<React.Fragment>
											<span className="mr-2">max though</span>
											<span className="mr-2 text-info">{state["max_throughput_"+item]}</span>
											</React.Fragment>
										: null}
									</p>
								}
								<div className='flex'>
									{state["tags"] && state["tags"].length ?
										state["tags"].map((tag, ttIndex) => {
											return(
												tag !== "" ?
													<span key={'tag_'+ttIndex} className={`border border-lightGray px-2 py-1 mr-2 self-center text-info ml-2`} > {tag} </span>
												: null
											)
										})
									: null}
									{!state["show_child_policy_"+index] ?
										<div className="flex">
											<Icon icon="octicon:pencil-24" width='16' height='16' className="mr-1 self-center cursor-pointer text-black" onClick={() => showPolicy("policyArray", "show_child_policy", item)} />
											<Icon icon={`fa:angle-${state["show_child_policy_"+item] ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)} />
										</div>
									: 
										<div className="flex">
											<Icon icon="mdi:reload" className="text-black self-center cursor-pointer" width="18" height="18" onClick={() => resetPolicy(item)} />
											{/* <span className={`fal fa-trash cursor-pointer mr-3 text-lightGray f15`} onClick={() => deletePolicy(item)} /> */}
											<Icon icon={`fa:angle-${state["show_child_policy_"+item] ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)} />
										</div>
									}
								</div>
							</div>
							{state["show_child_policy_"+item] ?
								<div className={`pl-5`}>
									<div className={`flex flex-wrap mb-2`}>
										<div className={`form-check form-check-inline self-center mt-2 mr-2 ${state["enabled_"+item] ? "" : "checkboxGrayColor"}`}>
											<input
												className='form-check-input m-0'
												type='checkbox'
												checked={state["enabled_"+item]}
												onClick={e => setState(prevState => ({ ...prevState,  ["enabled_"+item]: !state["enabled_"+item], onChangeInput: true }))}	
												title={state["enabled_"+item] ? "not enable" : "enable"}								
											/>
											{!state["enabled_"+item] ?
												<span className="checkmark" onClick={e => setState(prevState => ({ ...prevState, ["enabled_"+item]: !state["enabled_"+item], onChangeInput: true }))}></span>
											: null}
										</div>
										<Icon icon="fa:ban" className={`${state["disable_"+item] ? "text-danger" : "text-lightGray"} self-center`} width="14" height="14" onClick={() => setState(prevState => ({ ...prevState, ["disable_"+item]: !state["disable_"+item], onChangeInput: true }))}/>

										<div className='min-w-32 mr-2' 
											onClick={(event) => {
												if(!state["showAllow_"+item]) {
													event.preventDefault();
													handleChildClick(event, 'child', 'singleDropDown', "showAllow_"+item)
												}
											}}
										>
											<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["allow_"+item] ? 'text-black' : 'text-lightGray'}`}>
												{state["allow_"+item] ? state.allow_options.filter(e => e.value === state["allow_"+item])[0].label : 'Select'}
												<Icon icon="icon-park-solid:down-one" className={`${state["allow_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
											</p>	
											{state["showAllow_"+item] ?
												<MultiSelectSection
													fields={["value", "label"]}
													options={state.allow_options ? state.allow_options : []}
													selectedValues={state["allow_"+item] ? state["allow_"+item] : ''}
													callbackMultiSelect={(value) => {
														if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
															setState(prevState => ({ ...prevState, ["allow_"+item]: value, onChangeInput: true }))
														} else {
															value.preventDefault()
															handleChildClick(value, "search", 'singleDropDown', "")
														}
													}}
													singleSelection={true}
													hideSearch={false}
													topClass={'auto'}
													widthClass={'minWidth220'}
													removeTopOptions={true}
												/>
											: null}
										</div>

										<p className={`mb-0 ml-2 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-black"}`}>Volume type</p>
										
										<div className='min-w-32 mr-2' 
											onClick={(event) => {
												if(!state["showVolumeType_"+item]) {
													event.preventDefault();
													handleChildClick(event, 'child', 'singleDropDown', "showVolumeType_"+item)
												}
											}}
										>
											<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["volume_type_"+item] ? 'text-black' : 'text-lightGray'}`}>
												{state["volume_type_"+item] ? (state.volume_type_options ? state.volume_type_options.filter(e => e.value === state["volume_type_"+item])[0].label : state["volume_type_"+item]) : 'Select'}
												<Icon icon="icon-park-solid:down-one" className={`${state["volume_type_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
											</p>	
											{state["showVolumeType_"+item] ?
												<MultiSelectSection
													fields={["value", "label"]}
													options={state.volume_type_options ? state.volume_type_options : []}
													selectedValues={state["volume_type_"+item] ? state["volume_type_"+item] : ''}
													callbackMultiSelect={(value) => {
														if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
															setState(prevState => ({ ...prevState, ["volume_type_"+item]: value, onChangeInput: true }))
														} else {
															value.preventDefault()
															handleChildClick(value, "search", 'singleDropDown', "")
														}
													}}
													singleSelection={true}
													hideSearch={false}
													topClass={'auto'}
													widthClass={'minWidth220'}
													removeTopOptions={true}
												/>
											: null}
										</div>
									</div>
									<div className={'flex flex-wrap mb-2'} style={{marginLeft:"45px"}}>
										<p className={`mb-0 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-black"}`}>Max size per instance</p>
										<input 
											type="text" 
											className={`bg-transparent border-b border-lightGray px-2 py-1 text-black min-w-32`}
											style={{minHeight: "38px"}}
											placeholder={"max size"}
											value={state["max_size_per_instance_"+item]}
											onChange={e => setState(prevState => ({ ...prevState, ["max_size_per_instance_"+item]: onlyNumeric(e.target.value), onChangeInput: true }))}
										/>

										<p className={`mb-0 ml-2 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-black"}`}>Max iops per instance </p>
										<input 
											type="text" 
											className={`bg-transparent border-b border-lightGray px-2 py-1 text-black min-w-32`}
											style={{minHeight: "38px"}}
											placeholder={"max iops"}
											value={state["max_iops_per_instance_"+item]}
											onChange={e => setState(prevState => ({ ...prevState, ["max_iops_per_instance_"+item]: onlyNumeric(e.target.value), onChangeInput: true }))}
										/>
										{/* <p className={`mb-0 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-black"}`}>days</p> */}
										

										<p className={`mb-0 ml-2 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-black"}`}>Max throughput </p>
										<input 
											type="text" 
											className={`bg-transparent border-b border-lightGray px-2 py-1 text-black min-w-32`}
											style={{minHeight: "38px"}}
											placeholder={"max throughput"}
											value={state["max_throughput_"+item]}
											onChange={e => setState(prevState => ({ ...prevState, ["max_throughput_"+item]: onlyNumeric(e.target.value), onChangeInput: true }))}
										/>
									</div>

									<div className={'flex flex-wrap mb-2'} style={{marginLeft:"45px"}}>						
										{state["tags_"+item] && state["tags_"+item].length ?
											state["tags_"+item].map((tag, tagIndex) => {
												return(
													<span key={'tt_'+tagIndex} className={`border border-lightGray px-2 py-1 mr-2 self-center text-info`} > {tag} </span>
												)
											})
										: null}
									</div>
							
									{state.actionDetails && state.actionDetails.length ?
										<div className="rounded bg-GhostWhite p-3 mt-5">
											<p className="mb-0 text-lg text-purple3 cursor-pointer">Rule Action</p>
											<div className={`flex flex-wrap mb-2`}>
												<p className={`mb-0 mt-2 self-center`}>Select Action</p>
												<div className='min-w-32 mr-2' 
													onClick={(event) => {
														if(!state["showAction_"+item]) {
															event.preventDefault();
															handleChildClick(event, 'child', 'singleDropDown', "showAction_"+item)
														}
													}}
												>
													<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["selected_action_"+item] ? 'text-black' : 'text-lightGray'}`}>
														{state["selected_action_"+item] ? state["selected_action_"+item] : 'Select'}
														<Icon icon="icon-park-solid:down-one" className={`${state["selected_action_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
													</p>
													{state["showAction_"+item] ?
														<MultiSelectSection
															fields={["action", "action"]}
															options={state.actionDetails ? state.actionDetails : []}
															selectedValues={state["selected_action_"+item] ? state["selected_action_"+item] : ''}
															callbackMultiSelect={(value) => {
																if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																	setState(prevState => ({ ...prevState, 
																		["selected_action_"+item]: value,
																		["action_processor_rule_id_"+item]: state.actionDetails.filter(e => e.action === value) && state.actionDetails.filter(e => e.action === value).length && state.actionDetails.filter(e => e.action === value)[0].processor_rule_id ? state.actionDetails.filter(e => e.action === value)[0].processor_rule_id : "",
																		onChangeInput: true
																	}))
																} else {
																	value.preventDefault()
																	handleChildClick(value, "search", 'singleDropDown', "")
																}
															}}
															singleSelection={true}
															hideSearch={false}
															topClass={'auto'}
															widthClass={'minWidth220'}
															removeTopOptions={true}
														/>
													: null}
												</div>
											</div>
											
											{state["selected_action_"+item] ?
												<div className={`flex flex-wrap mb-2 ml-5`}>
													<p className={`mb-0 mt-2 self-center`}>Apply Method</p>
													<div className='min-w-32 mr-2' 
														onClick={(event) => {
															if(!state["showMethod_"+item]) {
																event.preventDefault();
																handleChildClick(event, 'child', 'singleDropDown', "showMethod_"+item)
															}
														}}
													>
														<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["apply_method_"+item] ? 'text-black' : 'text-lightGray'}`}>
															{state["apply_method_"+item] && props.actionMethods ? props.actionMethods.filter(e => e.value === state["apply_method_"+item])[0].label : 'Select'}
															<Icon icon="icon-park-solid:down-one" className={`${state["apply_method_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
														</p>
														{state["showMethod_"+item] ?
															<MultiSelectSection
																fields={["value", "label"]}
																options={props.actionMethods ? props.actionMethods : []}
																selectedValues={state["apply_method_"+item] ? state["apply_method_"+item] : ''}
																callbackMultiSelect={(value) => {
																	if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																		setState(prevState => ({ ...prevState, ["apply_method_"+item]: value, ["schedule_"+item]: "", onChangeInput: true }))
																	} else {
																		value.preventDefault()
																		handleChildClick(value, "search", 'singleDropDown', "")
																	}
																}}
																singleSelection={true}
																hideSearch={false}
																topClass={'auto'}
																widthClass={'minWidth220'}
																removeTopOptions={true}
															/>
														: null}
													</div>
													{state["apply_method_"+item] && state["apply_method_"+item] !== "immediately" ?
														<React.Fragment>
														<p className={`mb-0 ml-1 mr-2 self-center`}>Schedule</p>
														{state["apply_method_"+item] === "run_at" ?
															<input 
																type="text" 
																className={`bg-transparent border-b border-lightGray px-2 py-1 text-black min-w-32`}
																style={{minHeight: "38px"}}
																placeholder={"HH:MM:SS"}
																value={state["schedule_"+item]}
																maxLength="8"
																onKeyDown={e => onKeyDownTime(e, e.target.value, "schedule_"+item)}
																onChange={e => validateTime(e.target.value, "schedule_"+item)}
															/>
														: state["apply_method_"+item] === "run_after" ?
															<div className="flex">
																<input 
																	type="text" 
																	className={`bg-transparent border-b border-lightGray px-2 py-1 text-black min-w-32`}
																	style={{minHeight: "38px"}}
																	placeholder={""}
																	value={state["schedule_"+item]}
																	maxLength="2"
																	onChange={e => setState(prevState => ({ ...prevState, ["schedule_"+item]: onlyNumeric(e.target.value), onChangeInput: true }))}
																/>
																<div className='min-w-32 mr-2' 
																	onClick={(event) => {
																		if(!state["showTimeType_"+item]) {
																			event.preventDefault();
																			handleChildClick(event, 'child', 'singleDropDown', "showTimeType_"+item)
																		}
																	}}
																>
																	<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["time_type_"+item] ? 'text-black' : 'text-lightGray'}`}>
																		{state["time_type_"+item] && state.timeTypeOptions ? state.timeTypeOptions.filter(e => e.value === state["time_type_"+item])[0].label : 'Select'}
																		<Icon icon="icon-park-solid:down-one" className={`${state["time_type_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
																	</p>
																	{state["showTimeType_"+item] ?
																		<MultiSelectSection
																			fields={["value", "label"]}
																			options={state.timeTypeOptions ? state.timeTypeOptions : []}
																			selectedValues={state["time_type_"+item] ? state["time_type_"+item] : ''}
																			callbackMultiSelect={(value) => {
																				if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																					setState(prevState => ({ ...prevState, ["time_type_"+item]: value, onChangeInput: true }))
																				} else {
																					value.preventDefault()
																					handleChildClick(value, "search", 'singleDropDown', "")
																				}
																			}}
																			singleSelection={true}
																			hideSearch={false}
																			topClass={'auto'}
																			widthClass={'minWidth220'}
																			removeTopOptions={true}
																		/>
																	: null}
																</div>
															</div>

														: null}
														</React.Fragment>
													: null}
												</div>
											: null}
										</div>
									: null}
								</div>
							: null}
							</React.Fragment>
						:

							<React.Fragment>
							<div className="flex justify-between ml-3">
								<div className={`w-80"`}>
									<div className={`flex flex-wrap mb-2`}>
										<p className={`mb-0 mt-2 self-center text-info`}>{state["allow_"+item] && state["allow_"+item] !== "" && state.allow_options ? state.allow_options.filter(e => e.value === state["allow_"+item])[0].label : ""}</p>

										<p className={`mb-0 ml-2 mt-2 self-center ${props.selectedModeType === state.mode_volume_type ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-black") : "text-black"}`}>volume type</p>

										<p className={`mb-0 ml-2 mt-2 self-center text-info`}>{state["volume_type_"+item] && state["volume_type_"+item] !== "" ? (state.volume_type_options && state.volume_type_options.filter(e => e.value === state["volume_type_"+item]).length ? state.volume_type_options.filter(e => e.value === state["volume_type_"+item])[0].label : state["volume_type_"+item]) : ""}</p>
									</div>

									<div className={`flex flex-wrap mb-2 ml-3`}>
										<p className={`mb-0 ml-2 mt-2 self-center ${props.selectedModeType === state.mode_max_size_per_instance ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-black") : "text-black"}`}>max size per instance</p>

										<p className={`mb-0 ml-2 mt-2 self-center text-info`}>{state["max_size_per_instance_"+item]}</p>

										<p className={`mb-0 ml-2 mt-2 self-center ${props.selectedModeType === state.mode_max_iops_per_instance ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-black") : "text-black"}`}>max iops per instance</p>

										<p className={`mb-0 ml-2 mt-2 self-center text-info`}>{state["max_iops_per_instance_"+item]}</p>

										<p className={`mb-0 ml-2 mt-2 self-center ${props.selectedModeType === state["max_throughput_"+item] ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-black") : "text-black"}`}>max throughput</p>

										<p className={`mb-0 ml-2 mt-2 self-center text-info`}>{state["max_throughput_"+item]}</p>
									</div>
								</div>
								{!index ?
									<div className={`flex flex-wrap w-20 self-start justify-end mt-2`}>
										{state["tags"] && state["tags"].length ?
											state["tags"].map((tag, taIndex) => {
												return(
													tag !== "" ?
														<span key={"ta_"+taIndex} className={`border border-lightGray px-2 py-1 mr-2 self-center text-info ml-2 mb-2`} > {tag} </span>
													: null
												)
											})
										: null}
									</div>
								: null}
							</div>
										
							{state["selected_action_"+item] && state["selected_action_"+item] !== "" ?
								<div className="rounded bg-GhostWhite p-3 ml-4 mt-2">
									<div className={`flex flex-wrap mb-2`}>
										<span className={`mb-0 ml-2 mt-2 self-center text-lightGray`}>Selected Action <span className="text-black mx-1">{state["selected_action_"+item]}</span></span>
										{state["apply_method_"+item] && state["apply_method_"+item] ?
											<span className={`mb-0 ml-2 mt-2 self-center text-lightGray`}><span className="text-black mx-1">{props.actionMethods.filter(e => e.value === state["apply_method_"+item]) && props.actionMethods.filter(e => e.value === state["apply_method_"+item]).length ? props.actionMethods.filter(e => e.value === state["apply_method_"+item])[0].label : ""}</span></span>
										: null}
										{state["schedule_"+item] && state["schedule_"+item] ?
											<span className={`mb-0 ml-2 mt-2 self-center text-lightGray`}><span className="text-black mx-1">{state["schedule_"+item]} {state["time_type_"+item] !== "" ? <span className="ml-1">{state["time_type_"+item]}</span>: null}</span></span>
										: null}
									</div>
								</div>
							: null}
							</React.Fragment>
						}
					</div>
					{state.policyArray.length === (index+1) && !props.viewMode ?
						<span className="text-info mt-2 ml-3 mb-5 cursor-pointer" onClick={() => addPolicies("policyArray", "show_child_policy", item+1)}>+ Add new block store policy</span>
					: null}
					{/* {state.policyArray.length > 1 ?
						<span className="text-info mt-2 ml-3 mb-5 cursor-pointer" onClick={() => deletePolicy("policyArray", item)}>Remove</span>
					: null} */}
					</React.Fragment>
				)
			})}
		</div>
	)
}
export default Ec2ServiceBlockStore
