import React from 'react';
import { LoadingCircle } from '../LoadingCiricle';

const Button = ({ label, callback, classDetails, loading }) => {
    return (
        <button type="button" className={`flex justify-center self-center 
            ${classDetails && classDetails.bg ? classDetails.bg : 'bg-Neutral-600'} 
            ${classDetails && classDetails.text ? classDetails.text : 'text-white text-base'} 
            ${classDetails && classDetails.padding ? classDetails.padding : 'py-2.5 px-2.5'} 
            ${classDetails && classDetails.margin ? classDetails.margin : 'ml-2'} 
            ${classDetails && classDetails.rounded ? classDetails.rounded : 'rounded-full'}
            ${classDetails && classDetails.others ? classDetails.others : ''}
            ${classDetails && classDetails.width ? classDetails.width : ''}`}
            onClick={() => {
                if(callback) {
                    callback()
                }
            }}
        >
            {loading ?
                <LoadingCircle color={'text-white'} />
            : null}
            {label}
        </button>
    );
};

export default Button;
