/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * @exports
 * @file CloudCommitmentSpend.js
 * @author Prakash // on 10/01/2024
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { momentDateGivenFormat } from '../../../utils/utility';
import ApexBarChart from '../../common/charts/ApexBarChart';
import ApexNonStackedBarChart from '../../common/charts/ApexNonStackedBarChart';
import { getCloudCommitmentVsSpend } from '../../../actions/cost/BudgetAction'
import { setCostPropsDetails } from '../../../actions/cost/CostAction';
import SelectOption from '../../common/Form/SelectOption';

const CloudCommitmentSpend = (props) => {
    const [state, setState] = useState({
        showLoading: true,
        currencyUnit: props.currencyUnit,
        callCloudProviderDetailApi: true,
        currencyTypes: props.currencyTypes
    })

    const dispatch = useDispatch(); // Create a dispatch function

    const cloudCommitVsSpend = useSelector(state => state?.cost?.costPropsDetails?.cloudCommitVsSpend);

    useEffect(() => {
        setState(prevState => ({ ...prevState, callCloudProviderDetailApi: false }))
        if(cloudCommitVsSpend && cloudCommitVsSpend.hasOwnProperty(state.currencyUnit)) {
            setState(prevState => ({ ...prevState, cloudCommitment: cloudCommitVsSpend[state.currencyUnit], structureCloudProvider: true, showLoading: false }));
        }
    }, [cloudCommitVsSpend, state.currencyUnit])

    useEffect(() => {
        if(state.callCloudProviderDetailApi) {
            setState(prevState => ({ ...prevState, callCloudProviderDetailApi: false }))
            props.updateCurrency(state.currencyUnit)
            
            if(cloudCommitVsSpend && cloudCommitVsSpend.hasOwnProperty(state.currencyUnit)) {
                setState(prevState => ({ ...prevState, cloudCommitment: cloudCommitVsSpend[state.currencyUnit], structureCloudProvider: true, showLoading: false }));
            } else {
                let depParams = {
                    'start_time': momentDateGivenFormat(props.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                    'end_time': momentDateGivenFormat(props.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
                }
                if(props.without_tax) {
                    depParams.without_tax = props.without_tax
                }
                if(props.without_savings) {
                    depParams.without_savings = props.without_savings
                }
                if(props.without_credits_discounts) {
                    depParams.without_credits_discounts = props.without_credits_discounts
                }
                depParams.currency_unit = state.currencyUnit
                dispatch(getCloudCommitmentVsSpend(depParams))
                    .then((response) => {
                        if(response && !response.error) {
                            let results =  response && response.results ? response.results : []
                            setState(prevState => ({ ...prevState, cloudCommitment: results, structureCloudProvider: true, showLoading: false }))

                            if(results && Object.entries(results).length) {
                                let obj = cloudCommitVsSpend ? cloudCommitVsSpend : {}
                                obj[state.currencyUnit] = results
                                dispatch(setCostPropsDetails('cloudCommitVsSpend', obj))
                            }
                        } else {
                            setState(prevState => ({ ...prevState, cloudCommitment: {}, structureCloudProvider: true, showLoading: false }))
                        }
                    })
            }
        }
    }, [state.callCloudProviderDetailApi, state, cloudCommitVsSpend, state.currencyUnit, dispatch, props])

    
    useEffect(() => {
		if(state.structureCloudProvider) {
            setState(prevState => ({ ...prevState, structureCloudProvider: false }))
            let labels = ['Commitment', 'Spend']
            if(state.cloudCommitment && Object.entries(state.cloudCommitment).length) {
                Object.entries(state.cloudCommitment).forEach(([key, value]) => {
                    value.forEach(row => {
                        let graphData = {}
                        if(row.type === 'monthly') {
                            graphData.labels = row.dates ? row.dates : []
                            graphData.commitment = row.commitment ? row.commitment : []
                            graphData.spend = row.spend ? row.spend : []
                        } else {
                            graphData.labels = labels
                            graphData.data = [row.commitment ? row.commitment : 0, row.spend ? row.spend : 0]
                        }
                        setState(prevState => ({ ...prevState, [key+'_'+row.type]: graphData, [key+'_'+row.type+'_description']: row.description ? row.description : '', showCloudCommitment: true }))
                    })
                })
            }
		}
	}, [state.cloudCommitment, state.structureCloudProvider])

    return (
        <div className='bg-white rounded-2xl border border-DeepPurpleTints-600 p-6'>
            <div className='flex justify-between col-lg-12'>
                <p className='mb-1 text-[22px] font-bold text-black'>Cloud Provider Commitment</p>
                <div className="w-1/2 items-center flex flex-wrap justify-end self-end">
                    <div className='flex w-full justify-end'>
                        <span className="mr-3 self-center text-sm font-medium text-gray-900 dark:text-gray-300 text-black">Currency is in</span>
                        <SelectOption
                            label={""}
                            options={state.currencyTypes}
                            selectedValues={state.currencyUnit ? state.currencyUnit : []}
                            callbackMultiSelect={(value) => {
                                setState(prevState => ({ ...prevState, currencyUnit: value, showLoading: true, callCloudProviderDetailApi: true }))
                            }}
                            singleSelection={true}
                            makeLabelUpperCase={'capitalizeAllLetter'}
                            dropdownWidth={'min-w-12'}
                            classDetails={{common:'uppercase', padding: "py-0 px-2", bg: 'bg-white', rounded: 'rounded-full', optionPosition: 'right-0', optionWidth: 'min-w-25' }}
                        />  
                    </div>
                    {state.conversionDetails ?
                        <div className='flex mb-1 text-right text-black text-xs w-full flex justify-end'>{state.conversionDetails}</div>
                    : null}
                </div>
            </div>
            {state.showCloudCommitment ?
                state.cloudCommitment && Object.entries(state.cloudCommitment).length ?
                    Object.keys(state.cloudCommitment).map(key => {
                        return (
                            <div className='flex flex-wrap pt-8' key={key}>
                                <div className={`mb-3 lg:w-1/3 w-full flex flex-col flex-grow lg:pr-3`}>
                                    <div className='bg-NeutralGray-100 border border-NeutralGray-500 p-5 rounded-2xl h-full'>
                                        <div className=''>
                                            <p className='text-lg text-medium text-black'><span className='uppercase'>{key}</span> Yearly Commitment </p>
                                            <p className='text-sm text-lightGray'>{state[key+'_yearly_description'] ? state[key+'_yearly_description'] : <span>&nbsp;</span> }</p>
                                        </div>
                                        {state[key+'_yearly'] && Object.entries(state[key+'_yearly']).length ?
                                            <div className='-mb-3'>
                                                <ApexBarChart
                                                    graphData={state[key+'_yearly']}
                                                    sparkline={false}
                                                    yaxis={true}
                                                    yaxisLabel={true}
                                                    xaxis={true}
                                                    xaxisFormat={'string'}
                                                    xaxisLabel={true}
                                                    showTitle={true} //xaxis title
                                                    axisLabelColor={'#666666'}
                                                    paddingLeft={0}
                                                    legend={false}
                                                    legendPostion={'bottom'}
                                                    legendHorizontalAlign={'center'}
                                                    legendUseSeriesColor={true}
                                                    stacked={false}
                                                    height={275}
                                                    horizontal={true}
                                                    barHeight={'40%'}
                                                    barEndShape={'rounded'}
                                                    columnWidth={'25%'}
                                                    gradient={false}
                                                    gradientColor={['#009fdf', '#CD5A9F']}
                                                    hideTooltipValue={true}
                                                    backgroundBarShape={'rounded'}
                                                    backgroundBarColors={['#333947']}
                                                    showBackgroundBarColors={false}
                                                    className={'transparentTooltip'}
                                                    dataLabels= {true}
                                                    dataLabelsTextAnchor = {'end'}
                                                    dataLabelPosition={'right'}
                                                    colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                                    dataLabelsOffsetX={0}
                                                    chartDistributed={true}
                                                    dataPointSelection={false}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                <div className={`mb-3 lg:w-2/3 w-full flex flex-col flex-grow lg:pl-3`}>
                                    <div className='bg-NeutralGray-100 border border-NeutralGray-500 p-5 rounded-2xl h-full'>
                                        <div className=''>
                                            <p className='text-lg text-medium text-black'><span className='uppercase'>{key}</span> Monthly Commitment </p>
                                            <p className='text-sm text-lightGray'>{state[key+'_monthly_description'] ? state[key+'_monthly_description'] : <span>&nbsp;</span> }</p>
                                        </div>
                                        {state[key+'_monthly'] && Object.entries(state[key+'_monthly']).length ?
                                            <div className='-mb-4'>
                                                <ApexNonStackedBarChart
                                                    graphData={state[key+'_monthly']}
                                                    sparkline={false}
                                                    yaxis={true}
                                                    yaxisLabel={true}
                                                    xaxis={true}
                                                    xaxisFormat={'datetime'}
                                                    xaxisLabel={true}
                                                    axisLabelColor={'#666666'}
                                                    paddingLeft={-5}
                                                    legend={true}
                                                    stacked={false}
                                                    height={300}
                                                    horizontal={false}
                                                    // barHeight={'40%'}
                                                    barEndShape={'flat'}
                                                    columnWidth={'55%'}
                                                    gradient={true}
                                                    stroke={'hide'}
                                                    // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                    // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                    colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                                    hideTooltipValue={true}
                                                    backgroundBarShape={'flat'}
                                                    backgroundBarColors={['#333947']}
                                                    showBackgroundBarColors={false}
                                                    className={'transparentTooltip'}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                : null
            : 
                <div className='flex justify-center m-4'>
                    <svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                        <path d="M4 12a8 8 0 0112-6.9" />
                    </svg>
                </div>
            }
        </div>
    )
}
export default CloudCommitmentSpend