import React, { useState, useEffect } from 'react';
import Search from './SearchWithHiddenInput'
import { capitalizeAllLetter, capitalizeFirstLetter } from '../../utils/utility';


const MultiSelectSection = ({ fields, options, selectedValues, callbackMultiSelect, className, singleSelection, hideSearch, makeLabelUpperCase, widthClass, addAllField, removeTopOptions=false }) => {
    const [state, setState] = useState({
        options,
        filteredOptions: options ? options : [],
        selectedValues,
    })

    useEffect(() => {
        if(addAllField) {
            let filteredOptions = options
            if(fields && fields.length) {
                if(!filteredOptions.filter(e => e[fields[0]] === 'All').length) {
                    let dataRow = {}
                    dataRow[fields[0]] = 'All'
                    dataRow[fields[1]] = 'All'
                    filteredOptions.unshift(dataRow)
                }
            } else {
                if(!filteredOptions.filter(e => e === 'All').length) {
                    filteredOptions.unshift('All')
                }
            }
            setState(prevState => ({ ...prevState, filteredOptions, addAllField: false }))
        }
    }, [addAllField, options, fields, selectedValues])

    const updateMultiSelectedOptions = (value) => {
        if(singleSelection) {
            let selectedValues = ''
            selectedValues = value
            setState(prevState => ({ ...prevState, selectedValues }))
            callbackMultiSelect(selectedValues)
        } else {
            let selectedValues = [...(state.selectedValues || [])];
            if(!selectedValues.includes(value)) {                
                if(addAllField && (value === 'All' || (!selectedValues.includes('All') && selectedValues.length+1 === options.length-1))) {
                    if(fields && fields.length) {
                        selectedValues = options.map(e => e[fields[0]])
                    } else {
                        selectedValues = options.map(e => e)
                    }
                } else {
                    selectedValues.push(value);
                }   
            } else {
                if(value === 'All') {
                    selectedValues = []
                } else {
                    selectedValues = selectedValues.filter(e => e !== 'All' && e !== value)
                }
            }

            setState(prevState => ({ ...prevState, selectedValues })); 
            if(addAllField) {
                callbackMultiSelect(selectedValues.filter(e => e !== 'All'))
            } else {
                callbackMultiSelect(selectedValues)
            }
        }
    }

    return (
        <div className={`absolute overflow-auto rounded-md ${className ? className : ''} z-10 bg-white mt-3 border border-extraLightGray text-black shadow-md w-fit max-h-[300px] ${!removeTopOptions ? '' : ''} ${widthClass ? widthClass : 'minWidth220'}`}>
            {!hideSearch && state.options && state.options.length > 5 ?
                <div className={`px-2 pt-4`} 
                    onClick={(event) => {
                        if(singleSelection) {
                            callbackMultiSelect(event)
                        }
                    }}
                >
                    <Search
                        data={state.options ? state.options : []}
                        topClassName={'border border-NeutralGray-600 rounded-full bg-white p-1 pl-3 pr-4'}
                        className={'rounded-full'}
                        filteredData={(filteredArray, searchText) => {
                            setState(prevState => ({ ...prevState, filteredOptions: searchText !== "" ? filteredArray :  state.options }))
                        }}
                    />
                </div>
            : null}
            {state.filteredOptions && state.filteredOptions.length ?
                state.filteredOptions.map((row, index) => {
                    return(
                        <div key={'drop_'+index}>
                            {!singleSelection ?
                                <div className={`text-base flex items-center py-2 pl-3 ${selectedValues && selectedValues.length && selectedValues.includes(fields && fields.length ? row[fields[0]] : row) ? 'bg-info text-white' : 'bg-white text-black'}`} onClick={() => updateMultiSelectedOptions(fields && fields.length ? row[fields[0]] : row)}>
                                    <input 
                                        type="checkbox" 
                                        className="mr-2" 
                                        checked={state.selectedValues && state.selectedValues.includes(fields && fields.length ? row[fields[0]] : row) ? true : false}
                                        onChange={() => updateMultiSelectedOptions(fields && fields.length ? row[fields[0]] : row)}
                                    />
                                    <p className="ml-1 text-black mb-0 text-left">
                                        {makeLabelUpperCase && makeLabelUpperCase === 'capitalizeFirstLetter' ?
                                            capitalizeFirstLetter(fields && fields.length ? row[fields[1]] : row)
                                        : makeLabelUpperCase && makeLabelUpperCase === 'capitalizeAllLetter' ?
                                            capitalizeAllLetter(fields && fields.length ? row[fields[1]] : row)
                                        :  
                                            fields && fields.length ? row[fields[1]] : row
                                        }
                                    </p>
                                </div>
                            :
                                <div className={`text-base flex items-center py-3 pl-3 ${selectedValues === (fields && fields.length ? row[fields[0]] : row) ? 'bg-info text-white' : 'optionDiv'}`} onClick={() => updateMultiSelectedOptions(fields && fields.length ? row[fields[0]] : row)}>
                                    <p className={`ml-1 text-black mb-0 text-left f13`}>
                                        {makeLabelUpperCase && makeLabelUpperCase === 'capitalizeFirstLetter' ?
                                            capitalizeFirstLetter(fields && fields.length ? row[fields[1]] : row)
                                        : makeLabelUpperCase && makeLabelUpperCase === 'capitalizeAllLetter' ?
                                            capitalizeAllLetter(fields && fields.length ? row[fields[1]] : row)
                                        :  
                                            fields && fields.length ? row[fields[1]] : row
                                        }
                                    </p>
                                </div>
                            }
                        </div>
                    )
                })
            : 
                <div className="flex py-1 optionDiv bg-white text-black cursor-pointer">
                    <p className="ml-1 mb-0 text-left f13"> No Options</p>
                </div>
            }
        </div>
    )
}

export default MultiSelectSection