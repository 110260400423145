/*************************************************
 * Tvastar
 * @exports
 * @file ApexAreaChart.js
 * @author Prakash // on 09/01/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { momentConvertionUtcToLocalTime , capitalizeFirstLetter} from '../../../utils/utility'
import Chart from 'react-apexcharts'

const ApexAreaChart = (props) => {

    const [state, setState] = useState({})

    const drawGraph = useCallback((graphData) => {
        let graphLables = graphData.labels
        let axisColors = []
        let data = []
        let labels = []
        for(let x=0;x<graphLables.length;x++) {
            axisColors.push('#969AA7')
            labels.push(momentConvertionUtcToLocalTime(graphLables[x], 'DD MMM Y'))
            data.push(graphData.data[x] ? graphData.data[x] : 0)
        }
        let series = [
            {
                name: props.name ? props.name : 'New',
                data
            }
        ]

        let annotations = {}
        if(props.annotations) {
            let xaxisAnnotaions = []
            if(props.annotationToShow) {
                let borderColor = ''
                let background = ''
                let color = ''
                let text = ''
                let location = ''
                props.annotationToShow.forEach(item => {
                    text = item.value
                    if(item.value === 'S') {
                        borderColor = '#FF6F00'
                        background = '#FF6F00'
                        color = '#fff'
                        location = labels[10]
                    } else if(item.value === 'C') {
                        borderColor = '#24A597'
                        background = '#24A597'
                        color = '#fff'
                        location = labels[18]
                    }  else if(item.value === 'E') {
                        borderColor = '#F44336'
                        background = '#F44336'
                        color = '#fff'
                        location = labels[25]
                    } 

                    let dataRow = {
                        x: location,
                        strokeDashArray: 0,
                        borderColor: borderColor,
                        opacity: 1,
                        label: {
                            borderColor: borderColor,
                            orientation: 'horizontal',
                            text: text,
                            style: {
                                color: color,
                                background: background,
                                fontSize: '10px',
                                // width: '10px',
                            },
                        }
                    }
                    xaxisAnnotaions.push(dataRow)
                })
            }
            annotations = {
                xaxis: xaxisAnnotaions
            }
        }

        let fillData = {}
        if(props.gradient) {
            fillData = {
                type: "gradient",
                gradient: {
                    gradientToColors: [props.gradientColor ? props.gradientColor[0] : '#bee9de'],
                    shadeIntensity: 1,
                    opacityFrom: 1,
                    opacityTo: 0.8,
                    stops: [0, 85, 100]
                }
            }
        }

        let markerSize = props.markerSize
        if(props.hideMarkerLength && graphLables.length >= props.hideMarkerLength ) {
            markerSize = 0
        }

        let options = {
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: props.zoom,
                },
                sparkline: {
                    enabled: props.sparkline
                },
            },
            colors: props.colors,
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 1,
            },
            xaxis: {
                show: props.xaxis,
                categories: labels,
                labels: {
                    show: props.xaxisLabel ? props.xaxisLabel : false,
                    style: {
                        colors: props.axisLabelColor,
                        fontSize: props.axisLabelFontSize ?  props.axisLabelFontSize : '13px',
                        fontWeight: 'bolder',
                    },
                },
                axisBorder: {
                    show: props.xaxisBorder ? props.xaxisBorder : false,
                    // color:'#434959',
                    color: '#434B5E',
                },
                axisTicks: {
                    show: false
                },
                tooltip: {
                    enabled: false
                }
            },
            
            yaxis: {
                show: props.yaxis,
                labels: {
                    show: props.yaxisLabel ? props.yaxisLabel : false,
                    style: {
                        colors: props.axisLabelColor,
                        fontSize: props.axisLabelFontSize ?  props.axisLabelFontSize : '13px',
                        fontWeight: 'bolder',
                    },
                },
                axisBorder: {
                    show: props.yaxisBorder ? props.yaxisBorder : false,
                    color: '#434B5E',
                },
                offsetX: -15
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex];
                    let labelName = w.globals.categoryLabels[dataPointIndex]
                    let name = w.globals.seriesNames[seriesIndex]
                    return '<div class="arrow_box"> '+ labelName +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  capitalizeFirstLetter(name) + '  '+ val + '</span> </div>'
                },
            },
            
            fill: fillData,
            grid: {
                show: props.grid ? props.grid : false,
                borderColor: '#434B5E',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: props.grid ? props.grid : false
                    },
                    labels: {
                        style: {
                            colors: ['#434B5E'],
                        }
                    },  
                },   
                yaxis: {
                    lines: {
                        show: props.grid ? props.grid : false,
                    },
                    labels: {
                        style: {
                            colors: axisColors,
                        }
                    }
                },                
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 15
                },
            },
            markers: {
                size: markerSize,
                colors: props.colors,
                strokeColors: props.colors,
            },
                        
            annotations: annotations,
        }

        setState(prevState => ({ ...prevState, series, options }))
    }, [props])
    
    useEffect(() => {
        let graphData = props.graphData;
        if(graphData === 'mockData') {
            graphData = {}
            graphData.data = [45,67,34,71,41,93,64,77,36,34,44,10,1,3,91,45,32,3,37,21,65,45,38,59,16,3,66,85,30,62,49]
            graphData.labels = ["2021-05-12 00:00:00", "2021-05-13 00:00:00", "2021-05-14 00:00:00", "2021-05-15 00:00:00", "2021-05-16 00:00:00", "2021-05-17 00:00:00", "2021-05-18 00:00:00", "2021-05-19 00:00:00", "2021-05-20 00:00:00", "2021-05-21 00:00:00", "2021-05-22 00:00:00", "2021-05-23 00:00:00", "2021-05-24 00:00:00", "2021-05-25 00:00:00", "2021-05-26 00:00:00", "2021-05-27 00:00:00", "2021-05-28 00:00:00", "2021-05-29 00:00:00", "2021-05-30 00:00:00", "2021-05-31 00:00:00", "2021-06-01 00:00:00", "2021-06-02 00:00:00", "2021-06-03 00:00:00", "2021-06-04 00:00:00", "2021-06-05 00:00:00", "2021-06-06 00:00:00", "2021-06-07 00:00:00", "2021-06-08 00:00:00", "2021-06-09 00:00:00", "2021-06-10 00:00:00", "2021-06-11 00:00:00"]
        }
        if(graphData && Object.entries(graphData).length) {
            drawGraph(graphData)
        }
    }, [drawGraph, props.graphData])
    
    return(
        state.series ?
            <div className={`${props.className}`}>
                <Chart options={state.options} series={state.series} type="area" height={props.height} width={props.width} />
            </div>
        : null
    )
}

export default ApexAreaChart