/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * @file PageLoading.js
 * @author Prakash
 **************************************************/
import React from 'react';
const PageLoading = () => {
    return (
        <div className='fixed inset-0 bg-independenceGray-600 bg-opacity-30  flex items-center justify-center z-50'>
            <span className='sr-only'>Loading...</span>
            <div className='h-8 w-8 bg-yellowOrange-600 rounded-full animate-bounce [animation-delay:-0.15s] mr-2'></div>
            <div className='h-8 w-8 bg-slateBlue-600 rounded-full animate-bounce [animation-delay:-0.3s] mr-2'></div>
            <div className='h-8 w-8 bg-PrimaryLightOrange-600 rounded-full animate-bounce'></div>
        </div>
    )
}

export default PageLoading