/*************************************************
 * Collider
 * @exports
 * @file ResizeableDarkThemeTable.js
 * @author Prakash //on 15/06/2023
 * @copyright © 2022 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useTable, useRowSelect, useBlockLayout, useResizeColumns, useSortBy, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { LoadingCircle } from '../../common/LoadingCiricle';
import RunsActions from '../../hpc/JobStatus/RunsActions';

function ResizeableDarkThemeTable({
	columns,
	data,
	totalData = 0,
	dashboard,
	perPage = 10,
	perPageClass = false,
	sortByColumn,
	sortByType = true,
	onClickRow,
	onChangeAction,
	callToFetchNewRecord,
	selectedRecord,
	tableHead = false,
	rounded = false,
	emptyMessage = '',
	defaultSelectedRecord = 0,
	checkForNextSetoffRecords = false,
	paginationIndex = 0,
	paginationColor = false,
}) {
	const [state, setState] = useState({
		fetchNextRecord: false,
	});
	// const [dropdownOpen, setDropdownOpen] = useState(false)
	// const dropdownRef = useRef()

	let sortBy = [];

	columns = React.useMemo(() => columns, [columns]);

	const defaultColumn = React.useMemo(
		() => ({
			minWidth: 30,
			width: 200,
			maxWidth: 600,
		}),
		[]
	);

	if (sortByColumn && sortByColumn !== '') {
		if (!sortByType) {
			sortBy = [{
				id: sortByColumn,
				asc: true,
			}]
		} else {
			sortBy = [{
				id: sortByColumn,
				desc: true,
			}]
		}
	}

	// useEffect(() => {
    //     const handleClickOutside = (event) => {
    //       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //         setDropdownOpen(false);
    //       }
    //     };
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //       document.removeEventListener('mousedown', handleClickOutside);
    //     };
	// }, []);

	// const handleDropdownToggle = (e, i) => {
    //     e.stopPropagation();
    //     setDropdownOpen(i)
    // }

    // const handleOptionSelect = (action) => {
    //     // onChangeAction(action)
    // }

	useEffect(() => {
		if(state.fetchNextRecord) {
			setTimeout(() => { setState(prevState => ({ ...prevState, fetchNextRecord: false })) }, 2000);
		}
	}, [state.fetchNextRecord]);

	const [selectedRowIndex, setSelectedRowIndex] = useState(defaultSelectedRecord);
	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize, state: { pageIndex, pageSize }} = useTable(
		{
			columns,
			defaultColumn,
			data,
			initialState: {
				pageIndex: paginationIndex,
				pageSize: perPage,
				sortBy: sortBy,
			},
		},
		useBlockLayout,
		useResizeColumns,
		useSortBy,
		usePagination,
		useRowSelect,
		useSticky
	);

	const getNextPage = () => {
		if (pageIndex + 2 <= pageOptions.length) {
			nextPage();
		} else if (checkForNextSetoffRecords) {
			callToFetchNewRecord(pageIndex + 1);
			setState((prevState) => ({ ...prevState, fetchNextRecord: true }));
		}
	};

	// const getActions = (data) => {
	// 	let row = data.row.original
	// 	let actions = data.column.actions
	// 	let checkDynamicActions = data.column.checkDynamicActions
    //     if(checkDynamicActions && row[checkDynamicActions.key]) {
    //         actions.push(checkDynamicActions.label)
    //     }
    //     return actions
	// }

	return (
		<React.Fragment>
			{dashboard && (totalData > perPage || data.length > perPage) ? (
				<div className={`flex justify-end text-sm my-1 ${paginationColor ? paginationColor : ''}`}>
					Rows per page:
					<select
						className={`${perPageClass ? perPageClass : "bg-white text-black border border-DeepPurpleTints-600 ml-1 rounded"}`}
						value={pageSize}
						onChange={e => {
							setPageSize(Number(e.target.value))
						}}
					>
						{[perPage * 1, perPage * 2, perPage * 3, perPage * 4, perPage * 5].map(pageSize => (
							<option className='text-lightGray' key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
					<span className={`mx-3 self-center ${paginationColor ? paginationColor : ''}`}>
						Page {pageIndex + 1} of {pageOptions.length}{' '}
					</span>
					{/* <button className='mr-1' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						<Icon icon="system-uicons:push-left" className=' mr-1' />
					</button>{' '} */}
					<button className='mr-1' onClick={() => previousPage()} disabled={!canPreviousPage}>
						<Icon icon="mynaui:arrow-left-square" className=' mr-1' width={22} height={22} />
					</button>{' '}
					{!state.fetchNextRecord ?
						<button className='mr-1' onClick={() => getNextPage()}>
							{/* onClick={() => nextPage()} */}
							{/* disabled={!canNextPage} */}
							<Icon icon="mynaui:arrow-right-square" className=' mr-1' width={22} height={22} />
						</button>
					:
						<LoadingCircle />
					}{' '}
					{/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						<Icon icon="system-uicons:push-right" />
					</button> */}
				</div>
			) : null}
			<div className="relative overflow-x-auto rounded-t-2xl border border-NeutralGray-400">
				<table
					{...getTableProps()}
					className={`w-full text-left`}
				>
					<thead className={`capitalize ${tableHead ? tableHead : 'bg-NeutralGray-400 text-tableHeading-600 font-semibold rounded-t-2xl text-base'}`}>
						{headerGroups.map((headerGroup, i) => {
							const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps(); // Destructure key
							return (
								<tr key={`headerGroup-${i}`} {...restHeaderGroupProps} className="flex-1 w-full">
									{headerGroup.headers.map((column, index) => {
										const { key, ...restColumnProps } = column.getHeaderProps(); // Destructure key
										return (
											<th
												key={`header-${index}`}
												scope="col"
												className={`tracking-wider py-2 pl-5 text-left ${column.textColor ? column.render('textColor') : ''}`}
												{...restColumnProps}
											>
												<div {...column.getResizerProps()}>
													<span
														{...column.getSortByToggleProps({ title: 'Sort' })}
														style={{ marginRight: 10, cursor: 'pointer' }}
														className="flex"
													>
														{column.isSorted ?
															<Icon icon={`fa-solid:sort-${column.isSortedDesc ? 'down' : 'up'}`} className="text-gray-900 pt-1" width="16" height="16" />
														: null}
														<span className={`${column.isSorted ? 'ml-1' : ''}`}>{column.render('Header')}</span> 
														{column.HeaderUnit ?
															<span className="text-gray-900 self-center ml-1">({column.HeaderUnit})</span>
														: null}
													</span>
												</div>
											</th>
										);
									})}
								</tr>
							);
						})}
					</thead>

					<tbody className='text-sm text-NeutralGray-900' {...getTableBodyProps()}>
						{page.map((row, i) => {
							// if (selectedRecord && !i && !selectedRowIndex && selectedRecord !== row.original) {
							// 	onClickRow(row.original, '');
							// }
							prepareRow(row);

							// Destructure key from row props
							const { key, style, ...restRowProps } = row.getRowProps(); // Destructure key
							// rounded-b-lg border-x border-b border-lightGray-600
							return (
								<tr
									className={`${i % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}
									key={`row-${i}`} // Using a unique key for each row
									{...restRowProps} // Ensure restRowProps does not contain key
									onClick={(events) => {
										onClickRow(events, row.original, 'click');
										setSelectedRowIndex(i);
									}}
								>
									{row.cells.map((cell, index) => {
										// Destructure key from cell props
										const { key: cellKey, ...restCellProps } = cell.getCellProps(); // Destructure key
										// const actions = []
										// const newActions = cell.column.actions ? cell.column.actions : []
										// const checkDynamicActions = cell.column.checkDynamicActions ? cell.column.checkDynamicActions : false
										// if(checkDynamicActions && cell.row.original[checkDynamicActions.key]) {
										// 	newActions.push(checkDynamicActions.label)
										// }
										return (
											<td
												colSpan={columns.length}
												key={index} // Use index as a key for cells (or a unique id if available)
												className={`p-4 text-black text-left text-sm ${cell.column.textColor} ${!cell.column.className || cell.column.className === '' ? 'truncate' : cell.column.className} self-center`}
												{...restCellProps} // Ensure restCellProps does not contain key
												id={`targetIdNotify${i}${index}`}
											>
												<div style={{ display: 'flex', width: '100%' }}>
													{cell.column.id === "actions" ?
														<RunsActions
															data={cell.row.original}
															rowIndex={i}
															actions={cell.column.actions ? cell.column.actions : []}
															// checkDynamicActions={cell.column.checkDynamicActions}
															onChangeAction={(events, action) => {
																onChangeAction(events, action, cell.row.original)
															}}
														/>
													: 
														cell.render('Cell')
													}
												</div>
											</td>
										);
									})}
								</tr>
							);
						})}

						{data.length === 0 && (
							<tr className="bg-gray-100 text-center">
								<td colSpan={columns.length} className="truncate text-black">
									{emptyMessage !== '' ? (
										<span>{emptyMessage}</span>
									) : (
										<span>No information found.</span>
									)}
								</td>
							</tr>
						)}
					</tbody>



				</table>
			</div>						
		</React.Fragment>
	)
}

export default ResizeableDarkThemeTable