/*************************************************
 * Collider
 * @exports
 * @file urlPath.js
 * @author Prakash // on 19/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/

export const URL_PATH = {	
	
	ERROR_PAGE: '/error',
	LOADING_PAGE: '/authorize',
	PERMISSION_DENINED: '/permission/denied',
	LIFECYCLE_NOTIFICATIONS: '/lifecycle-notification',
	
	JOB_STATUS: '/hpc/jobstatus',
	FILES: '/hpc/files',
	HPC_DASHBOARD: '/hpc/dashboard',
	HPC_SPENDBOARD: '/hpc/spendboard',
	HPC_STATUS: '/hpc/status',
	HPC_LICENSES: '/hpc/licenses',
	CATALOG_LANDINGPAGE: '/hpc/workstation',
	WORKSTATION_REQUEST_LANDINGPAGE: '/hpc/request',
	CLUSTER_DETAILS: '/hpc/cluster',
	CREATE_CLUSTER: '/hpc/cluster/create',
	LIST_CLUSTER_TEMPLATES: '/hpc/cluster',
	CREATE_CLUSTER_TEMPLATE: '/hpc/cluster/create',	
	QUEUE_DETAILS: '/hpc/queue',
	OBSERVABILITY: '/hpc/observability',
	MANAGE_QOS: '/hpc/qos',
	CREATE_QOS: '/hpc/qos/create',
	CREATE_GROUP: '/hpc/group/create',
	EDIT_QOS: '/hpc/qos/edit',
	HPC_ALERTS: '/hpc/alerts',
	HPC_REPORTS: '/hpc/reports',
	HPC_BUDGET: '/hpc/budget',
	HPC_CREATE_BUDGET: '/hpc/budget/create',
	
	ADMIN_USERS: '/admin/users',
	ADMIN_ACCOUNTS: '/admin/accounts',
	ADMIN_DEPARTMENT_MAPPING: '/admin/departments',
	ADMIN_DEPARTMENT_MAPPING_CREATE: '/admin/departments/create',
	ADMIN_SETTINGS: 'admin/settings',

	GOVERNANCE_DASHBOARD: '/governance/dashboard',
	GOVERNANCE_MANAGE_RULES: '/governance/rules',
	GOVERNANCE_CREATE_NEW_RULE: '/governance/rule/create',
	GOVERNANCE_EDIT_NEW_RULE: '/governance/rule/edit',

	RESOURCES_INVENTORY: '/resources/inventory',
	RESOURCES_LIFECYCLE: '/resources/lifecycle',
	RESOURCES_QUERIES: '/resources/queries',
	RESOURCES_COMPARISON: '/resources/compare/assets',
	RESOURCES_SEARCH: '/resources/search',

	COST_BUDGET_DASHBOARD: '/cost/budget/dashboard',
	COST_BUDGET_LIST: '/cost/budget',
	COST_BUDGET_CREATE: '/cost/budget/create',
	COST_EXPLORER: '/cost/explorer',
	COST_QUERIES: '/cost/queries',

	// COST_RECOMMENDATION: '/cost/recommendations',
	// COST_POWER_TUNING: '/cost/powertuning',
	// COST_ANOMALIES: '/cost/anomalies',
	// COST_COMPARISON: '/cost/comparison',


	IMAGE_BUILDER_DASHBOARD: '/imagebuilder/image',
	IMAGE_BUILDER_AUDIT: '/imagebuilder/audit',
}
