import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getBatchJobSummary } from '../../../actions/Collider/JobStatusAction';
import {Icon} from "@iconify/react";
import Summary from './Summary';
import Runs from './Runs';
import { LoadingCircle } from '../../common/LoadingCiricle';

const BatchJobDetailsSidePanel = ({ selectedBatchJobDetails, closeSidePanel, onJobSelection }) => {
    const clickOut = useRef();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        showLoading: true,        
        tabs: ['Summary', 'Runs']
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {

        let params = {
            provider: selectedBatchJobDetails.provider,
            account_id: selectedBatchJobDetails.account_id,
            region: selectedBatchJobDetails.region,
            cluster_name: selectedBatchJobDetails.cluster_name,
            job_id: selectedBatchJobDetails.job_id
        }

        dispatch(getBatchJobSummary(params))
            .then((response) => {                
                setState(prevState => ({ ...prevState, summaryDetails: response, selectedTab: "Summary", showLoading: false }))
            })
    }, [dispatch, selectedBatchJobDetails])

    const handleChildClick = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
    }

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='flex justify-center'>
                    <LoadingCircle />
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-3/4 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-white'>Job Details</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-white cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                
                    <div className={`mx-4 pt-4`}>
                        <ul className="flex flex-wrap text-sm text-center">
                            {state.tabs.map(tab => {
                                return(
                                    <li className="me-2">
                                        <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === tab ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: tab }))}>{tab}</span>
                                    </li>

                                )
                            })}
                        </ul>
                        <div className="overflow-y-auto h-[calc(100vh-120px)] border border-NeutralGray-500 bg-PrimaryTintsPurple-600 rounded-r-2xl rounded-b-2xl">
                            {state.selectedTab === "Summary" ?
                                <Summary
                                    selectedBatchJobDetails={selectedBatchJobDetails}
                                    summaryDetails={state.summaryDetails}
                                />
                            : state.selectedTab === "Runs" ?
                                <Runs
                                    selectedBatchJobDetails={selectedBatchJobDetails}
                                    onJobSelection={(job) => onJobSelection(job)}
                                    summaryDetails={state.summaryDetails}
                                />
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BatchJobDetailsSidePanel;