/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file MetricsTab.js
 * @author Prakash // on 05/06/2023
  @copyright © 2023 Collider. All rights reserved.
 *************************************************/
  import React, { useEffect, useState} from 'react';
//   import { useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions

// import { Spinner } from 'reactstrap'
// import _ from 'lodash'

import { momentDateGivenFormat, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertSeconds, countFormater, convertBits, getCommonInformationMessage } from '../../../utils/utility'

import Chart from 'react-apexcharts'

const MetricsTab = (props) => {

    const [state, setState] = useState({

        showAnnotation: '',
        coreMetricsListLoader: '',
    })

	useEffect(() => {
        let graphDetails = props.metricDetails ? props.metricDetails : []        
        if(graphDetails && graphDetails.length) {
            graphDetails.forEach(graph => {
                let graphData = graph.data
                let graphLabels = graph.dates
                Object.entries(graphData).forEach(([key, item], i) => {
                    let colors = []
                    let series = []
                    let options = {}

                    let  strokeWidth = []
                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    // let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    // let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1

                    // let convertedMinValue = minValue
                    // let convertedAvgValue = avgValue
                    // let convertedMaxValue = maxValue
                    
                    
                    let graphConvertTo = ""
                    if(item.unit) {
                        graphConvertTo = getConvertedUnit(maxValue, item.unit)                        
                    }

                    let fillData = {}
                    if(props.gradient) {
                        fillData = {
                            colors:[props.gradientColor ? props.gradientColor[0] : "#26B68F"],
                            type: 'gradient', // gradient
                            gradient: {
                                type: props.horizontal ? 'horizontal' : 'vertical', // The gradient in the horizontal direction
                                gradientToColors: [props.gradientColor ? props.gradientColor[1] : '#bee9de'], // The color at the end of the gradient
                                opacityFrom: .9, // transparency
                                opacityTo: 1,
                                stops:[0, 120]
                            }
                        }
                    }

                    if(item.values && item.values.length) {
                        if(item.values && item.values.length) { //statistic
                            if(item.statistic !== "Maximum") {
                                colors.push('')
                            } else {
                                colors.push('')
                            }
                            strokeWidth.push(2)
                            let dataRow = {}
                            dataRow.name = item.statistic === "Maximum" ? "max" : item.statistic === "Average" ? "avg" : item.statistic === "Sum" ? "sum" : item.statistic
                            dataRow.type = item.statistic !== "Maximum" ? "area" : "line"
                            let plotValue = []
                            graphLabels.forEach((lab, i) => {
                                let dataArray = []
                                dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                                let val = item.values[i]
                                if(item.unit) {
                                    val = getGrapUnitValue(val, item.unit, "", graphConvertTo)
                                }
                                dataArray.push(val)
                                plotValue.push(dataArray)
                            })
                            dataRow.data = plotValue
                            series.push(dataRow)
                        }
                        
                        let xaxisAnnotation = []
                        // if(state.showAnnotation) {
                        //     let alerts = [props.details.event_time]

                        //     if(alerts.length) {
                        //         alerts.forEach((item, z) => {
                        //             let alertRow = {}
                        //             // alertRow.x = new Date(momentConvertionUtcToLocalTime(addMinutes(item, (z*10)), 'YYYY-MM-DD HH:mm:ss')).getTime()
                        //             alertRow.x = new Date(momentConvertionUtcToLocalTime(item, 'YYYY-MM-DD HH:mm:ss')).getTime()
                        //             alertRow.strokeDashArray = 0
                        //             alertRow.borderColor = '#FF6F00'
                        //             alertRow.label = {
                        //                 borderColor: '#FF6F00',
                        //                 style: {
                        //                     // fontSize: '12px',
                        //                     color: '#fff',
                        //                     background: '#FF6F00'
                        //                 },
                        //                 orientation: 'horizontal',
                        //                 text: ''
                        //                 // text: momentConvertionUtcToLocalTime(item, 'DD MMM YY HH:mm'),
                        //             }

                        //             xaxisAnnotation.push(alertRow)
                        //         })
                        //     }
                        // }

                        let annotations = {
                            xaxis: xaxisAnnotation
                        }

                        options = {
                            chart: {
                                // group: 'metrics_sre',
                                // id: 'chart_rate_'+index,
                                // redrawOnParentResize: true,
                                stacked: true,
                                toolbar: {
                                    show: false,
                                },
                                zoom: {
                                    enabled: false
                                },
                                sparkline: {
                                    enabled: false
                                },
                                type: "line",
                                width: '100%',
                                animations: {
                                    enabled: false
                                }
                            },
                            colors: colors,
                            dataLabels: {
                                enabled: false,
                            },
                            stroke: {
                                width: strokeWidth,
                                curve: 'smooth',
                            },
                            legend: {
                                show: false
                            },
                            // fill: {
                            //     type:'solid',
                            //     opacity: [0.25, 1],
                            // },
                            fill: fillData,
                            tooltip: {
                                // custom: function({series, seriesIndex, dataPointIndex, w}) {
                                //     let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                //     dateLable = dateLable.toString()
                                //     dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                //     let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                //     return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                                // },
                                custom: function({series, seriesIndex, dataPointIndex, w}) {         
                                    let returnData = ''
                                    
                                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                        Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                            if(!index) {
                                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                            }
                                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                        })
                                    }

                                    returnData += '</div>'
                                    return returnData
                                },
                                fixed: {
                                    enabled: false
                                },
                                x: {
                                    show: false,
                                },
                                y: {
                                    show: false
                                },        
                                marker: {
                                    show: false
                                }
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    style: {
                                        colors: props.axisLabelColor ? props.axisLabelColor : '#999999'
                                    },
                                    // formatter: function (value) {
                                    //     return momentDateGivenFormat(value, 'HH:mm')
                                    // },
                                },                            
                                axisTicks: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: false
                                },
                            },
                            // dataLabels: {
                            //     enabled: false
                            // },
                            yaxis: [
                                {
                                    show: true,
                                    axisTicks: {
                                        show: false,
                                    },
                                    axisBorder: {
                                        show: false,
                                        color: '#E6E6E6'
                                    },
                                    tooltip: {
                                        enabled: false
                                    },
                                    // tickAmount: 2,
                                    min: 0,
                                    // max: yaxisMaxValue, 
                                    // tickAmount: 1,
                                    labels: {
                                        offsetX: -10,
                                        // minWidth: 50,
                                        show: true,
                                        style: {
                                            fontSize: '9px',
                                            colors: props.axisLabelColor ? props.axisLabelColor : '#999999'
                                        },
                                        formatter: (value) => { 
                                            if(Number(value) === value && value % 1 === 0) {
                                                return Math.ceil(value.toFixed(1))
                                            } else {
                                                return Math.ceil(value.toFixed(1))
                                            }
                                            
                                            // if(!value) {
                                            //     return 0
                                            // } else {
                                            //     let val = value.toFixed(2)
                                            //     val = val.split(".")
                                            //     if(val[1] && val[1] > 0) {
                                            //         return value.toFixed(2)
                                            //     } else {
                                            //         return parseInt(val[0])
                                            //     }
                                            // }
                                        },
                                        // offsetX: 0,
                                        // offsetY: 0,
                                    },
                                }
                            ],
                            grid: {
                                show: true,
                                borderColor: '#E6E6E6',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#E6E6E6'],
                                        }
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },   
                                yaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#E6E6E6'],
                                        }
                                    }
                                },                
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                            },
                            annotations: annotations,
                            // noData: {
                            //     text: "Loading...",
                            //     align: "center",
                            //     verticalAlign: "middle",
                            //     offsetX: 0,
                            //     offsetY: 0,
                            //     style: {
                            //       color: "#999",
                            //       fontSize: "18px",
                            //     },
                            // },
                        }
                    }
                    setState(prevState => ({ 
                        ...prevState, 
                        [key+"_series_"+graph.asset_id]: series, 
                        [key+"_options_"+graph.asset_id]: options, 
                        [key+"_unit_"+graph.asset_id]: graphConvertTo,
                        coreMetricsListLoader: false 
                    }))
                })
            })
        }
    }, [props])
    
    const getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    const getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.round(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }                         
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.round(val) : 0
                val = countFormater(val, "round")
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.round(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    // const getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
    //     if(val !== '' && unit !== '') {
    //         if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
    //             val = convertBytesWithoutUnit(val, unit, valueType)                            
    //         } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
    //             val = convertSecondsWithoutUnit(val, unit, valueType)
    //         } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
    //             val = convertBitsWithoutUnit(val, unit, valueType)
    //         } else if(unit === 'Count') {
    //             val = val ? Math.ceil(val) : 0
    //             val = countFormaterWithoutUnit(val)
    //         } else {
    //             val = parseInt(val)
    //         }
    //     } else if(val !== '') {           
    //         val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
    //     }
    //     return val
    // }

    return (
        props.metricDetails && props.metricDetails.length ? 
            props.metricDetails.map((item, iIndex) => {
                return (
                    <div className='text-sm' key={'key_'+iIndex}>
                        {Object.entries(item.data) && Object.entries(item.data).length ?
                            Object.entries(item.data).map(([key, value]) => {
                                return (
                                    <div className={`rounded-2xl bg-white border border-NeutralGray-500 mb-6 text-base p-4`} key={key}>
                                        <div className="pl-2 pt-2.5 flex justify-between">
                                            <div className="flex mb-2">
                                                <span className="text-black mb-0 f17">{key}</span>
                                                {state[key+"_unit_"+item.asset_id] ?
                                                    <p className='mb-0 ml-2 text-info'>({state[key+"_unit_"+item.asset_id]})</p>
                                                : null}
                                                
                                                {props.showStatistics && props.showStatistics.length ?
                                                    <div className='flex self-center'>
                                                        {props.showStatistics.map(stat => {
                                                            return(
                                                                <p key={stat} className='ml-2 text-textGray-600 capitalize'>{stat} 
                                                                    <span className='text-black ml-1'>
                                                                        {value.statistics && value.statistics[stat] && state[key+"_unit_"+item.asset_id] ? 
                                                                            getGrapUnitValue(value.statistics[stat] , value.unit, "", state[key+"_unit_"+item.asset_id])
                                                                        : value.statistics && value.statistics[stat] ?
                                                                            value.statistics[stat]
                                                                        : 0}
                                                                    </span>
                                                                </p>
                                                            )
                                                        })}
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                        {state[key+"_series_"+item.asset_id] && state[key+"_series_"+item.asset_id].length && state[key+"_options_"+item.asset_id] ?
                                            <div className="cursor-pointer transparentTooltip mr-3">
                                                <div className={`"w-full`}>
                                                    <Chart options={state[key+"_options_"+item.asset_id]} series={state[key+"_series_"+item.asset_id]} type="line" height={props.height}/>
                                                </div>
                                            </div>
                                        : 
                                            <div style={{minHeight:"85px"}} className="flex self-center justify-center">
                                                <div className="self-center text-lightGray">
                                                    No Data
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        :
                            <div className='flex justify-center m-4'>
                                <p>{props.label ? props.label : getCommonInformationMessage('asset')}</p>
                            </div>
                        }
                    </div>
                )
            })
        : 
            <div className='flex justify-center m-4'>
                <p>{props.label ? props.label : getCommonInformationMessage('asset')}</p>
            </div>
        
    )
}

export default MetricsTab