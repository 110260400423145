import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { buildPlan, listPlans } from '../../../actions/ImagebuilderAction'
import {Icon} from "@iconify/react";
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { momentDateGivenFormat } from '../../../utils/utility';
import DetailsSidePanel from './DetailsSidePanel';
import ShareSidePanel from './ShareSidePanel';
import { Store as CommonNotification } from 'react-notifications-component';
import AlertConfirmation from '../../common/AlertConfirmation';

let searchInput = null
const PlanSection = (props) => {
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        perPage: 100,        
        dynamicColumns: []
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed, callListPlans: true }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
        if(state.callListPlans) {
            setState(prevState => ({ ...prevState, callListPlans: false }))
            let params = {}
            if(props.selectedProvider) {
                params.provider = props.selectedProvider.toLowerCase()
            }
            // if(props.selectedAccount && props.selectedAccount.length) {
            //     params.account_id = props.selectedAccount
            // }
            // if(props.selectedRegion && props.selectedRegion.length) {
            //     params.region = props.selectedRegion
            // }
            // if(props.selectedProvider === 'docker') {
            //     params.image_type = "docker"
            // }
            if(props.selectedImage) {
                params[props.selectedProvider === 'docker' ? 'image_name' : 'os'] = props.selectedImage
            }

            if(state.searchText) {
                params.search_input = state.searchText
            }
    
            // params.size = state.perPage
    
            // if(state.nextCatalogToken && state.nextCatalogToken.length) {
            //     params.next_token = state.nextCatalogToken
            // }

            let dynamicColumns = []
            let dataRow = {}
		
            dataRow.Header = 'Image'
            dataRow.accessor = 'image_name'
            dataRow.Cell = cellInfo => (
                <div className='cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original, showDetailsSection: true }))}>
                    <p className='flex w-full text-info'>
                        {cellInfo.row.original.image_name} 
                    </p>
                </div>
            )
            dataRow.width = 250
            dynamicColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Name'
            dataRow.accessor = 'plan_name'
            dataRow.width = 250
            dynamicColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Key'
            dataRow.accessor = 'plan_key'
            dataRow.width = 250
            dynamicColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Versions'
            dataRow.accessor = 'total_versions'
            dataRow.width = 120
            dynamicColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Builds'
            dataRow.accessor = 'total_builds'
            dataRow.width = 120
            dynamicColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Last Build'
            dataRow.accessor = 'last_build_at'
            dataRow.Cell = cellInfo => (
                <span>
                    {cellInfo.row.original.last_build_at ? momentDateGivenFormat(cellInfo.row.original.last_build_at, 'DD MMM YYYY HH:mm') : ''}
                </span>
            )
            dynamicColumns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Status'
            dataRow.accessor = 'enabled'
            dataRow.Cell = cellInfo => (
                <span className={`border border-${cellInfo.row.original.enabled ? 'success' : 'failed'} text-black rounded-full px-2 py-1`}>
                    {cellInfo.row.original.enabled ? 'Enabled' : 'Disabled'}
                </span>
            )
            dynamicColumns.push(dataRow)
            
            if(state.selectedProvider === 'docker') {
                dataRow = {}
                dataRow.Header = 'Action'
                dataRow.Cell = cellInfo => (
                    <div className="flex self-center">
                        {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                            <p className='cursor-pointer text-HalloweenOrange' onClick={() => {
                                setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original }))
                                confirmActionDetails()
                            }} title="Build">
                                <Icon icon="octicon:play-24" className='text-info cursor-pointer' width={20} height={20} />
                            </p>
                        : null}
                    </div>
                )
                dataRow.width  = 100
                dynamicColumns.push(dataRow)
            }

            dataRow = {}
            dataRow.Header = ' '
            dataRow.width  = 1
            dynamicColumns.push(dataRow)

            setState(prevState => ({ ...prevState, dynamicColumns }))
    
            dispatch(listPlans(params))
                .then((response) => {
                    if(response) {
                        let totalResults = response && response.results ? response.results : []
    
                        setState(prevState => ({ ...prevState, detailsFormat: response.plan_details ? response.plan_details : [], listItems: totalResults, filterListItems: totalResults, totalRecords: response.total ? response.total : 0, showLoading: false }));
                    }
                })
        }
    }, [dispatch, props.selectedProvider, props.selectedAccount, props.selectedImage, props.selectedImageType, props.selectedRegion, state.searchText, state.perPage, state.callListPlans, state.actionsAllowed, state.selectedProvider])

    const confirmActionDetails = (field, index) => {
        let obj = {
            field: field,
            index: index,
            message: 'Are you sure you want to run the build',
            heading: 'Confirm Buil'
        }
        setState(prevState => ({ ...prevState, confirmDetails: obj, confirmAction: true }))
    }

    useEffect(() => {
        if(state.callBuildFunction) {
            setState(prevState => ({ ...prevState, callBuildFunction: false }))

            let params = {}
            // if(props.selectedProvider) {
            //     params.provider = props.selectedProvider.toLowerCase()
            // }
            // if(props.selectedAccount && props.selectedAccount.length) {
            //     params.account_id = props.selectedAccount
            // }
            // if(props.selectedRegion && props.selectedRegion.length) {
            //     params.region = props.selectedRegion
            // }            
            if(state.selectedDetails.image_id) {
                params.image_id = state.selectedDetails.image_id
            }
            if(state.selectedDetails.image_name) {
                params.image_name = state.selectedDetails.image_name
            }            
            if(state.selectedDetails.image_version) {
                params.image_version = state.selectedDetails.image_version
            }            
            if(state.selectedDetails.os) {
                params.os = state.selectedDetails.os
            }            
            if(state.selectedDetails.os_version) {
                params.os_version = state.selectedDetails.os_version
            }
            if(state.selectedDetails.plan_key) {
                params.plan_key = state.selectedDetails.plan_key
            }            
    
            dispatch(buildPlan(params))
                .then((response) => {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : 'Error in build'
                    if(response && !response.error) {
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'building successfully'
                        }
                    }

                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                        }
                    });
                    
                    setState(prevState => ({ ...prevState, confirmAction: false, selectedDetails: {}, showPlanLoading: false }))
                })
        }

    }, [state.callBuildFunction, dispatch, state.selectedDetails])

    return (
        state.showLoading ?
            <div className='flex justify-center m-4'>
                <svg className="animate-spin h-5 w-5 ml-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                    <path d="M4 12a8 8 0 0112-6.9" />
                </svg>
            </div>
        :
            <React.Fragment>
                {state.showDetailsSection ? 
                    <DetailsSidePanel
                        detailsFormat={state.detailsFormat}
                        selectedDetails={state.selectedDetails}
                        fromPage={'Plan'}
                        closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {}, showDetailsSection: false }))}
                    />
                : null}
                {state.showShareSection ? 
                    <ShareSidePanel
                        selectedDetails={state.selectedDetails}
                        closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {}, showShareSection: false }))}
                    />
                : null}

                {state.confirmAction && state.confirmDetails ?
                    <AlertConfirmation
                        confirmDetails={state.confirmDetails}
                        confirmation={(action) => {
                            if(action === 'confirmed') {
                                setState(prevState => ({ ...prevState, callBuildFunction: true, showPlanLoading: true, confirmAction: false }))
                            } else {
                                setState(prevState => ({ ...prevState, confirmAction: false, selectedDetails: {} }))
                            }
                        }}
                    />
                : null}

                <div className={`${state.showPlanLoading ? '' : 'hidden'}`}>
                    <div className='loading-wave'>
                        <div className='overlayEqualizerLoader'>
                            <div className="loading-bar"></div>
                            <div className="loading-bar"></div>
                            <div className="loading-bar"></div>
                            <div className="loading-bar"></div>
                        </div>
                    </div>
                </div>
                <div className='p-2 overflow-auto'>
                    <div className='flex flex-wrap justify-between my-3'>
                        <p className='mb-0 self-center md:w-1/2 w-full'>
                            <small>Showing {state.filterListItems && state.filterListItems.length} of total {state.totalRecords} plan(s)</small>
                            {state.selectedProvider ?
                                <React.Fragment>
                                <small className='ml-1'>obtained from the</small>
                                <small className='ml-1 text-info'>{state.selectedProvider.toUpperCase()}</small>
                                <small className='ml-1'>provider</small>
                                </React.Fragment>
                            : null}
                        </p>
                        <div className='flex flex-wrap self-center'>
                            <div className='flex md:w-1/2 w-full md:ml-3 ml-0 md:mt-0 mt-2'>
                                <div className='flex border border-lightGray rounded-md'>
                                    <Icon icon={`bitcoin-icons:search-outline`} className={`text-black w-5 self-center`} width={25} height={25} />
                                    <div className={`w-11/12`}>
                                        <input
                                            value={state.searchText}
                                            onChange={e => {
                                                setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                                clearTimeout(searchInput)
                                                if(e.target.value.length > 2) {
                                                    searchInput = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, startRecord: 0, currentPage: 1, totalPages: 1, listImages: [], filterListItems: [], showLoading: true, callListPlans: true }))}, 1000); 
                                                } else if(!e.target.value.length) {
                                                    setState(prevState => ({ ...prevState, startRecord: 0, currentPage: 1, totalPages: 1, listImages: [], filterListItems: [], showLoading: true, callListPlans: true }))
                                                }
                                            }}
                                            type='text'
                                            className={`bg-transparent text-black text-md pl-1 py-1`}
                                            placeholder='Search'
                                        />
                                    </div>
                                </div>
                                    {/* <Search
                                        data={state.listItems ? state.listItems : []}
                                        topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                        searchClassName={'px-2'}
                                        searchIconColor={'text-black '}
                                        searchPlaceHolder={'Search....'}
                                        className={'bg-transparent text-black'}
                                        widthHeight={25}
                                        filteredData={(filterListItems) => setState(prevState => ({ ...prevState, filterListItems }))}
                                    /> */}
                            </div>
                            {/* <button className={`flex bg-green px-2 py-1 rounded-md ml-3  lg:mt-0 md:mt-0 mt-2 text-black self-center`} onClick={() => {
                                setState(prevState => ({ ...prevState, showCreateImage: true, pageMode: 'Create' }))
                            }}>Create Image</button> */}
                        </div>
                    </div>
                    {state.filterListItems && state.filterListItems.length && state.dynamicColumns ?
                        <ResizeableDarkThemeTable
                            columns={state.dynamicColumns}
                            data={state.filterListItems}
                            perPage={20}
                            tableHead={'bg-lightGray text-black'}
                            tableBody={'text-black'}
                            tableBodyColor={'bg-GhostWhite'}
                            tableType={'table-light-hover'}
                            perPageClass={`bg-lightGray text-black rounded-md ml-2`}
                            textColor={'text-black'}
                            selectedColor={'bg-transparent'}
                            dashboard={true}
                            showPaginationTop={false}
                            paginationColor={'text-black'}
                            onClickRow={tableRow => {}}
                        />
                    : 
                        <div className='flex justify-center m-4'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                    }
                </div>
            </React.Fragment>
    );
};
export default PlanSection