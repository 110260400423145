import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import { validateAmiId, validateClusterName } from "../../../../utils/utility";
import {Icon} from "@iconify/react";
import SelectOption from '../../../common/Form/SelectOption';
import Textbox from '../../../common/Form/Textbox';
import Checkbox from '../../../common/Form/Checkbox';
import Button from '../../../common/Form/Button';
import { listAllProviders, listClusterFilters, setCommonPropsDetails} from "../../../../actions/commonActionNew";
import AlertConfirmation from '../../../common/AlertConfirmation';
import { getClusterFilters } from "../../../../actions/Collider/ClusterAction";
import { listClusterTemplates } from '../../../../actions/Collider/ClusterAction'

const ClusterSection = (props) => {
    const [state, setState] = useState({});
    const providers = useSelector((state) => state?.filters?.providers || false);
    const propProjAccounts = useSelector((state) => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector((state) => state?.filters?.commonPropsDetails?.propProjRegions || false);
    
    const createUpdateCluster = useSelector(
        (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
    );
    const propsClusterFilters = useSelector(
        (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
    );

    const dispatch = useDispatch(); // Create a dispatch function

    const callPageVist = useCallback(() => {
        let clusterConfiguration = createUpdateCluster && createUpdateCluster.cluster_configuration
                ? createUpdateCluster.cluster_configuration
                : {};

        Object.entries(clusterConfiguration).forEach(([key, value]) => {
            if (key === "provider" && value) {
                setState((prevState) => ({ ...prevState, selectedProvider: value }));
            } if (key === "account_id" && value) {
                setState((prevState) => ({ ...prevState, selectedAccount: value }));
            } if (key === "region" && value) {
                setState((prevState) => ({ ...prevState, selectedRegion: value }));
            }
            let clusterFields = [
                "cluster_name",
                "template_name",
                "os",
                "custom_ami",
                "cloudwatch_logs_deletion_policy",
                "disable_simultaneous_multitreading",
                "detailed_monitoring",
                "cloudwatch_logs_retention_in_days",
                "cloudwatch_logs_enabled",
                "cloudwatch_dashboard_enabled"
            ];
            if (key === "os" && value) {
                setState((prevState) => ({ ...prevState, operatingSystem: true }));
            } else if (key === "custom_ami" && value) {
                setState((prevState) => ({ ...prevState, customAmi: true }));
            }
            if (clusterFields.includes(key)) {
                setState((prevState) => ({ ...prevState, [key]: value }));
            }
        });

        let selected_tags = createUpdateCluster && createUpdateCluster.tags && createUpdateCluster.tags.length ? createUpdateCluster.tags : [];

        setState((prevState) => ({ ...prevState, selected_tags }));
    }, [createUpdateCluster]);

    useEffect(() => callPageVist(), [callPageVist]);

    // list all providers
    useEffect(() => {
    let params = {}
        if(providers) {
            let selectedProvider = state.selectedProvider
            if(!selectedProvider) {
                selectedProvider = providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ''
            }
            setState(prevState => ({ ...prevState, providers, selectedProvider }));
        } else {
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        let selectedProvider = state.selectedProvider
                        if(!selectedProvider) {
                            selectedProvider = response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''
                        }
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider }));
                    }
            })
        }
    }, [dispatch, providers, state.selectedProvider])

    useEffect(() => {
    if(state.selectedProvider) {
        let params = {};
        params.provider = state.selectedProvider.toLowerCase();
        params.aggregate_by = 'account_id';
        let label = state.selectedProvider
        if(propProjAccounts && propProjAccounts.hasOwnProperty(label) ) {
            let selectedAccount = state.selectedAccount ? state.selectedAccount : []
            if(!selectedAccount.length) {
                selectedAccount = propProjAccounts[label].length ? propProjAccounts[label][0].account_id : ''
            }
            setState(prevState => ({ ...prevState, accounts: propProjAccounts[label], selectedAccount }));
        } else {
            dispatch(listClusterFilters(params))
            .then((response) => {
                if(response) {
                    if(response.length) {
                        let obj = propProjAccounts ? propProjAccounts : {}
                        obj[label] = response
                        dispatch(setCommonPropsDetails('propProjAccounts', obj))
                        let selectedAccount = state.selectedAccount ? state.selectedAccount : []
                        if(!selectedAccount.length) {
                            selectedAccount = response.length ? response[0].account_id : ''
                        }
                        setState(prevState => ({ ...prevState, accounts: response, selectedAccount }));
                    }
                }
            })
        }
    }
    }, [state.selectedProvider, dispatch, propProjAccounts, state.selectedAccount]);

    useEffect(() => {
    if(state.selectedProvider) {
        let params = {};
        params.provider = state.selectedProvider.toLowerCase();
        params.aggregate_by = 'region';
        let label = state.selectedProvider
        if(state.selectedAccount) {
            label = state.selectedProvider+'_'+state.selectedAccount
            params.account_id = state.selectedAccount
        }

        if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
            let selectedRegion = state.selectedRegion ? state.selectedRegion : []
            if(!selectedRegion.length) {
                selectedRegion = propProjRegions[label].length ? propProjRegions[label][0].region : ''
            }
            setState(prevState => ({ ...prevState, regions: propProjRegions[label], selectedRegion }));
        } else {
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response && response.length) {
                        let obj = propProjRegions ? propProjRegions : {}
                        obj[label] = response
                        dispatch(setCommonPropsDetails('propProjRegions', obj))
                        let selectedRegion = state.selectedRegion ? state.selectedRegion : []
                        if(!selectedRegion.length) {
                            selectedRegion = response.length ? response[0].region : ''
                        }
                        setState(prevState => ({ ...prevState, regions: response, selectedRegion }));
                    }
                })
        }
    }
    }, [state.selectedProvider, state.selectedAccount, dispatch, propProjRegions, state.selectedRegion]);

    useEffect(() => {
    if (state.selectedProvider) {
      let params = {};
      params.provider = state.selectedProvider.toLowerCase();
      if (state.selectedAccount) {
        params.account_id = state.selectedAccount;
      } if (state.selectedRegion) {
        params.region = state.selectedRegion;
      } if (state.selectedScheduler) {
        params.cluster_version = propsClusterFilters?.cluster_version?.filter(e => e.label === state.selectedScheduler)[0]?.cluster_version;
      }

      if (!propsClusterFilters || !Object.entries(propsClusterFilters).length) {
        dispatch(getClusterFilters(params)).then((response) => {
          if (response) {
            dispatch(setHpcPropsDetails("propsClusterFilters", response));
          }
        });
      }
    }
    }, [state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedScheduler, dispatch, propsClusterFilters]);

    useEffect(() => {
        let params = {}
        if (state.selectedAccount){
            params.provider = state.selectedProvider.toLowerCase()
        }
        if(state.selectedAccount) {
            params.account_id = state.selectedAccount
        }
        if(state.selectedRegion) {
            params.region = state.selectedRegion
        }
        // if(state.selectedScheduler) {
        //     params.cluster_version = state.selectedScheduler
        // }

        dispatch(listClusterTemplates(params))
            .then((response) => {
                if(response) {
                    let results = response && response.results?.results && response.results?.results.length ? response.results?.results : []
                    let listTemplates = results.map(item => item.template_name);
                    setState(prevState => ({ ...prevState, listTemplates: listTemplates, templates: results}));
                }
        })
    }, [dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion])


    const addNewTagValue = () => {
        let selectedTags = state.selected_tags ? state.selected_tags : [];

        if (state.tag_key && state.tag_value && state.tag_value.length) {
            let dataRow = {};
            dataRow.key = state.tag_key;
            dataRow.value = state.tag_value;
            selectedTags.push(dataRow);
        }

        setState((prevState) => ({
            ...prevState,
            selected_tags: selectedTags,
            tag_key: "",
            tag_value: "",
        }));

        handleInputChange("tags", selectedTags);
    };

    const removeTagSection = (tag) => {
        let filteredResult =
            state.selected_tags && state.selected_tags.filter((e) => e !== tag);
        setState((prevState) => ({ ...prevState, selected_tags: filteredResult }));

        handleInputChange("tags", filteredResult);
    };

    const handleInputChange = (label, value) => {
        let obj = createUpdateCluster ? createUpdateCluster : {};
        obj[label] = value;
        if (label === "provider" && value) {
            label = "selectedProvider"
            obj["account_id"] = "";
            obj["region"] = "";
        } else if (label === "account_id" && value) {
            label = "selectedAccount"
        } else if (label === "region" && value) {
            label = "selectedRegion"
        } else if (label === "scheduler" && value) {
            label = "selectedCluster"
        } else if (label === "custom_ami") {
            delete obj.os;
        } else if (label === "os") {
            delete obj.custom_ami;
        }
        dispatch(setHpcPropsDetails("createUpdateCluster", obj));

        setState((prevState) => ({ ...prevState, [label]: value }));
    };

    const handleConfigurationInputChange = (label, value, type) => {
        let obj = createUpdateCluster ? createUpdateCluster : {};
        let clusterConfiguration = obj.cluster_configuration
            ? obj.cluster_configuration
            : {};
        clusterConfiguration[label] = value;
        obj["cluster_configuration"] = clusterConfiguration
        dispatch(setHpcPropsDetails("createUpdateCluster", obj));

        clusterConfiguration[label] = type === "integer" ? parseInt(value) : value;
        setState((prevState) => ({ ...prevState, [label]: value }));
    };

    const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();

        let clickedChild = [];
        if (type === "child") {
            if (state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach((row) => {
                    setState((prevState) => ({ ...prevState, [row]: false }));
                });
            }
            clickedChild.push(section);
            setState((prevState) => ({
                ...prevState,
                [section]:
                    dropdownType === "singleDropDown" && state[section] ? false : true,
                clickedChild,
            }));
        } else if (type === "parent") {
            if (state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach((row) => {
                    setState((prevState) => ({ ...prevState, [row]: false }));
                });
            }

            setState((prevState) => ({ ...prevState, clickedChild }));
        }
    };

    return (
    <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='flex flex-wrap'>
                <div className='flex justify-start	w-1/2	pr-4'>
                    <SelectOption
                        label={"Provider"}
                        fields={["provider_name",	"provider_name"]}
                        options={state.providers}
                        selectedValues={state.selectedProvider	?	state.selectedProvider	:	''}
                        callbackMultiSelect={(value)	=>	{
                            handleInputChange('provider',	value.toLowerCase())
                            dispatch(setHpcPropsDetails('propsClusterFilters',	{}))
                        }}
                        manditory={true}
                        hasError={state.inputValidationError}
                        singleSelection={true}
                        dropdownWidth={'w-full'}
                        disabled={props.pageMode === 'Edit'}
                    />
                </div>
                <div className='flex justify-start	w-1/2	pl-4'>
                    <SelectOption
                        label={"Account"}
                        fields={["account_id",	"account_name"]}
                        options={state.accounts}
                        selectedValues={state.selectedAccount	?	state.selectedAccount	:	''}
                        callbackMultiSelect={(value) =>	{
                            handleInputChange('account_id',	value)
                            handleInputChange('region',	'')
                            dispatch(setHpcPropsDetails('propsClusterFilters',	{}))		
                        }}
                        singleSelection={true}
                        manditory={true}
                        hasError={state.inputValidationError}
                        dropdownWidth={'w-full'}
                        disabled={props.pageMode === 'Edit'}
                    />
                </div>
            </div>
            <div className='flex flex-wrap pt-8'>
                <div className='flex justify-start	w-1/2	pr-4'>
                    <SelectOption
                        label={"Region"}
                        fields={["region",	"name"]}
                        options={state.regions}
                        selectedValues={state.selectedRegion	?	state.selectedRegion	:	[]}
                        callbackMultiSelect={(value) =>	{
                            setState(prevState	=>	({	...prevState,	selectedRegion:	value }))
                            handleInputChange('region',	value)
                            dispatch(setHpcPropsDetails('propsClusterFilters',	{}))
                        }}
                        singleSelection={true}
                        manditory={true}
                        hasError={state.inputValidationError}
                        dropdownWidth={'w-full'}
                        disabled={props.pageMode === 'Edit'}
                    />
                </div>
                <div className='flex justify-start	w-1/2	pl-4'>
                    <SelectOption
                        label={"Scheduler"}
                        fields={["label",	"label"]}
                        options={propsClusterFilters?.cluster_version}
                        selectedValues={state.selectedScheduler	?	state.selectedScheduler	:	''}
                        callbackMultiSelect={(value) =>	{
                            handleInputChange('scheduler',	value)
                            setState(prevState	=>	({	...prevState,	selectedScheduler:	value	}))
                            dispatch(setHpcPropsDetails('propsClusterFilters',	{}))
                        }}
                        singleSelection={true}
                        manditory={true}
                        hasError={state.inputValidationError}
                        dropdownWidth={'w-full'}
                        disabled={props.pageMode === 'Edit'}
                    />
                </div>
            </div>
            {props.pageMode !== 'Template'?
                <div className='flex flex-wrap pt-8'>
                    <div className='flex justify-start	w-1/2	pr-4'>
                        <SelectOption
                            label={"Template"}
                            // fields={["label",	"label"]}
                            options={state.listTemplates}
                            selectedValues={state.selectedTemplate	?	state.selectedTemplate	:	''}
                            callbackMultiSelect={(value) =>	{
                                    let obj = {
                                        message: "If you change, already existing data in this cluster will be lost/replaced. Are you sure you want to change the template",
                                        buttonLabel: "Yes, I'm sure",
                                    }
                                    setState(prevState => ({ ...prevState, confirmDetails: obj, templateModal: true, templateValue: value }))
                            }}
                            singleSelection={true}
                            manditory={true}
                            hasError={state.inputValidationError}
                            dropdownWidth={'w-full'}
                            disabled={props.pageMode === 'Edit'}
                        />
                    </div>
                </div>
            : null}

            <div className={`border-t border-NeutralGray-600 mt-8`}>

                <div className='flex flex-wrap pt-8'>
                    <div className='flex flex-col justify-start lg:w-1/2 w-full lg:pr-4'>
                        <Textbox
                            label={props.pageMode === 'Template' ? 'Template' : 'Cluster'} name
                            type="text"
                            selectedValue={createUpdateCluster && createUpdateCluster.cluster_name ? createUpdateCluster.cluster_name : ''}
                            callback={(value) => {
                                setState(prevState => ({ ...prevState, cluster_name:  validateClusterName(value)}))
                                handleInputChange('cluster_name',  validateClusterName(value))
                            }}
                            placeholder={props.pageMode === 'Template' ? 'Enter template name' : 'Enter cluster name'}
                            manditory={true}
                            hasError={state.inputValidationError}
                        />
                        <p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.cluster_name}</p>
                    </div>
                    <div className='flex flex-col justify-start w-1/2 pl-4'>
                        <SelectOption
                            label={"Operating System"}
                            fields={["value", "key"]}
                            options={propsClusterFilters && propsClusterFilters.os ? propsClusterFilters.os : []}
                            selectedValues={state.os ? state.os : ''}
                            callbackMultiSelect={(value) => {
                                setState(prevState => ({ ...prevState,  os: value }))
                                handleConfigurationInputChange('os', value)
                            }}
                            singleSelection={true}
                            manditory={true}
                            hasError={state.inputValidationError}
                            dropdownWidth={'w-full'}
                            disabled={props.pageMode === 'Edit'}
                        />
                        <p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.cluster_configuration?.os}</p>
                    </div>
                </div>

                <div className='flex flex-wrap pt-8'>
                    <div className='flex flex-col pt-8 justify-start lg:w-1/2 w-full lg:pr-4'>
                        <Textbox
                            label={'Custom AMI'}
                            type="text"
                            selectedValue={state.custom_ami  ? state.custom_ami  : ''}
                            callback={(value) => {
                                setState(prevState => ({ ...prevState, custom_ami:  validateAmiId(value)}))
                                handleConfigurationInputChange('custom_ami',  validateAmiId(value))
                            }}
                            placeholder={'Enter custom ami'}
                            disabled={props.pageMode === 'Edit'}
                        />
                        <div className='flex pt-6'>
                            <Checkbox
                                label="Disable simultaneous multitreading"
                                selectedValue={state.disable_simultaneous_multitreading ? true : false}
                                disabled={props.selectedData && props.selectedData.cluster_configuration && props.selectedData.cluster_configuration.disable_simultaneous_multitreading ? true : false}
                                callback={() => {
                                    if(props.pageMode !== 'Edit' || (props.selectedData && props.selectedData.cluster_configuration && !props.selectedData.cluster_configuration.disable_simultaneous_multitreading)) {
                                        let disable_simultaneous_multitreading = !state.disable_simultaneous_multitreading
                                        setState(prevState => ({ ...prevState, disable_simultaneous_multitreading: !state.disable_simultaneous_multitreading}))
                                        handleConfigurationInputChange("disable_simultaneous_multitreading", disable_simultaneous_multitreading)
                                    }
                                }}
                            />
                            {/* <p className='text-primaryPurple-600 text-sm w-full'>{propsClusterFilters?.description?.custom_ami}</p> */}
                        </div>
                    </div>

                <div className='flex flex-col w-1/2 pt-6 pl-4'>
                <p className="text-base text-black self-center w-full">Monitoring</p>
                    <div className='border border-NeutralGray-400 p-3 mb-4 bg-white rounded-2xl'>
                        <div className='flex'>
                            <Checkbox
                                label="Enable detailed monitoring"
                                selectedValue={state.detailed_monitoring ? true : false}
                                callback={() => {
                                    if(props.pageMode !== 'Edit' || (props.selectedData && props.selectedData.cluster_configuration && !props.selectedData.cluster_configuration.detailed_monitoring)) {
                                        let detailed_monitoring = !state.detailed_monitoring
                                        setState(prevState => ({ ...prevState, detailed_monitoring: !state.detailed_monitoring}))
                                        handleConfigurationInputChange("detailed_monitoring", detailed_monitoring)
                                    }
                                }}
                                disabled={props.pageMode === 'Edit'}
                            />
                        </div>
                        <div className='flex pt-6'>
                            <Checkbox
                                label="Enable cloudwatch dashboard"
                                selectedValue={state.cloudwatch_dashboard_enabled ? true : false}
                                callback={() => {
                                    if(props.pageMode !== 'Edit' || (props.selectedData && props.selectedData.cluster_configuration && !props.selectedData.cluster_configuration.cloudwatch_dashboard_enabled)) {
                                        let cloudwatch_dashboard_enabled = !state.cloudwatch_dashboard_enabled
                                        setState(prevState => ({ ...prevState, cloudwatch_dashboard_enabled: !state.cloudwatch_dashboard_enabled}))
                                        handleConfigurationInputChange("cloudwatch_dashboard_enabled", cloudwatch_dashboard_enabled)
                                    }
                                }}
                                disabled={props.pageMode === 'Edit'}
                            />
                        </div>
                        <div className='flex pt-6'>
                            <Checkbox
                                label="Enable cloudwatch logs"
                                selectedValue={state.cloudwatch_logs_enabled ? true : false}
                                callback={() => {
                                    if(props.pageMode !== 'Edit' || (props.selectedData && props.selectedData.cluster_configuration && !props.selectedData.cluster_configuration.cloudwatch_logs_enabled)) {
                                        let cloudwatch_logs_enabled = !state.cloudwatch_logs_enabled
                                        // setState(prevState => ({ ...prevState, cloudwatch_logs_enabled: !state.cloudwatch_logs_enabled}))
                                        handleConfigurationInputChange("cloudwatch_logs_enabled", cloudwatch_logs_enabled)
                                    }
                                }}
                                disabled={props.pageMode === 'Edit'}
                            />
                        </div>

                        {state.cloudwatch_logs_enabled ?
                        <div className='flex flex-wrap pt-8'>
                            <div className='flex justify-start	w-1/2	pr-4'>
                                <SelectOption
                                    label={"Deletion policy"}
                                    options={propsClusterFilters && propsClusterFilters.deletion_policy ? propsClusterFilters.deletion_policy : []}
                                    selectedValues={state.cloudwatch_logs_deletion_policy ? state.cloudwatch_logs_deletion_policy : ""}
                                    callbackMultiSelect={(value)	=>	{
                                        setState(prevState => ({ ...prevState, cloudwatch_logs_deletion_policy: value }))
                                        handleConfigurationInputChange('cloudwatch_logs_deletion_policy', value)
                                    }}
                                    singleSelection={true}
                                    dropdownWidth={'w-full'}
                                    disabled={props.pageMode === 'Edit'}
                                />
                            </div>
                            <div className='flex justify-start	w-1/2	pl-4'>
                                <SelectOption
                                    label={"Retention in days"}
                                    fields={["value", "key"]}
                                    options={
                                    propsClusterFilters && propsClusterFilters.cloudwatch_logs_retention_in_days
                                        ? propsClusterFilters.cloudwatch_logs_retention_in_days.map(
                                            (d) => ({ key: d, value: `${d}` })
                                        ) : []
                                    }
                                    selectedValues={ state.cloudwatch_logs_retention_in_days ? state.cloudwatch_logs_retention_in_days.toString() : ""}
                                    callbackMultiSelect={(value) =>	{
                                        handleConfigurationInputChange("cloudwatch_logs_retention_in_days", value, "integer");
                                        setState((prevState) => ({ ...prevState, "cloudwatch_logs_retention_in_days": value }));
                                    }}
                                    singleSelection={true}
                                    dropdownWidth={'w-full'}
                                    disabled={props.pageMode === 'Edit'}
                                />
                            </div>
                        </div>
                        : null}
                    </div>
                </div>
                </div>

                <div className='flex flex-col justify-between pt-8'>
                    <div className='flex flex-wrap'>
                        <div className='lg:pr-4 lg:w-2/5 w-full'>
                            <Textbox
                                label={"Tags"}
                                type="text"
                                selectedValue={state.tag_key ? state.tag_key : ''}
                                callback={(value) => {
                                    setState(prevState => ({ ...prevState, tag_key: value })) 
                                }}
                                placeholder={'Enter tag key'}
                            />
                        </div>
                        <div className='lg:pl-4 pt-4 mt-6 lg:pt-0 lg:w-2/5 w-full'>
                            <Textbox
                                label={""}
                                type="text"
                                selectedValue={state.tag_value ? state.tag_value : ''}
                                callback={(value) => {
                                    setState(prevState => ({ ...prevState, tag_value: value }))
                                }}
                                placeholder={'Enter tag value'}
                            />
                        </div>
                        <div className='flex justify-end pt-4 pl-4'>
                            <Button
                                classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer text-center min-w-24'}}
                                label={'+ Add'}
                                callback={() => {
                                    addNewTagValue()
                                }}
                            />
                        </div>
                    </div>
                    {/* <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-4 mt-8 cursor-pointer' onClick={() => addNewTagValue()}/> */}

                    {state.selected_tags && state.selected_tags.length ? 
                        <div className='flex flex-wrap mt-4'>
                            {state.selected_tags.filter(e => e.value !== 'All').map((tag, tIndex) => {
                                return(
                                    <span key={'tag_'+tIndex} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all' id={'tag_'+tIndex}>
                                        <div className='truncate'  style={{maxWidth: '250px'}}>
                                            {tag.key +' : '+tag.value}
                                        </div>
                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeTagSection(tag)}/>
                                    </span>
                                )
                            })}
                        </div>
                    : null}
                </div>
            </div>
            {state.confirmDetails && state.templateModal ?
                <AlertConfirmation 
                    confirmDetails={state.confirmDetails}
                    confirmation={(templateModal) => {
                        if(templateModal) {
                            handleInputChange('template', state.templateValue)
                            setState(prevState	=>	({	...prevState,	selectedTemplate:	state.templateValue	}))
                            // dispatch(setHpcPropsDetails('propsClusterFilters',	{}))
                            let templateDetails = state.templates.filter(template => template.template_name === state.templateValue)[0]
                            dispatch(setHpcPropsDetails("createUpdateCluster", templateDetails));
                        }
                        setState(prevState => ({ ...prevState, templateModal: false, confirmDetails: false }))
                    }}
                />
            : null}
    </div>
    )
}


export default ClusterSection