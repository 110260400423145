import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateImage } from '../../../actions/ImagebuilderAction'
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";

const UpdateImage = ({ selectedDetails, closeSidePanel, pageMode }) => {
    const clickOut = useRef();
    const [state, setState] = useState({
        showLoading: false,
        selected_tags: selectedDetails.tags ? selectedDetails.tags : []
    })

    const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 5000); 
        }
    }, [state.showLoading])

    useEffect(() => {
        if(state.callUpdateImage) {
            let params = {}
            params.provider = selectedDetails.provider
            params.image_id = selectedDetails.image_id
            
            let delete_tags = []
            selectedDetails.tags.forEach(tag => {
                if(!state.selected_tags.includes(tag)) (
                    delete_tags.push(tag)
                )
            })
            let tags = []
            state.selected_tags.forEach(tag => {
                if(!selectedDetails.tags.includes(tag)) (
                    tags.push(tag)
                )
            })

            params.tags = tags
            params.delete_tags = delete_tags

            let hasError = false
            // if(!state.image_name) {
            //     hasError = true
            // }
            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true, callUpdateImage: false }))
            
            if(!hasError) {
                dispatch(updateImage(params))
                    .then((response) => {
                        let messageType = 'danger'
                        let message = response && response.message ? response.message : 'Error in updating image'
                        if(response && !response.error) {
                            if(response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'Image update successfully'
                            }
                        }
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                            }
                        });
        
                        if(response && !response.error) {
                            if(response.status) {
                                setTimeout(() => closeSidePanel(), 1000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }
                        } else {
                            setState(prevState => ({ ...prevState, saveLoading: false }))
                        }
                    })
    
            }
        }
    },  [state.callUpdateImage, closeSidePanel, dispatch, selectedDetails, state])

    const addNewTagValue = () => {
		let selectedTags = state.selected_tags ? state.selected_tags : []

		if(state.tag_key && state.tag_value && state.tag_value.length) {
            let dataRow = {}
            dataRow.key = state.tag_key
            dataRow.value = state.tag_value
            selectedTags.push(dataRow)
		}

		setState(prevState => ({ ...prevState, selected_tags: selectedTags, tag_key: '',  tag_value: '' }))
	}

	const removeTagSection = (tag) => {
		let filteredResult = state.selected_tags && state.selected_tags.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, selected_tags: filteredResult }))
	}

	const handleChildClick = (event, type, dropdownType, section) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }

		event.stopPropagation();
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='bg-GhostWhite w-4/5 overflow-auto' ref={clickOut}>
                <div className='header-search bg-GhostWhite flex justify-between p-2'>
                    <div className='text-black'>
                        <p className='text-xl text-black'>Update Image</p>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2'>
                    <div className='rounded bg-white p-3'>
                        <div className='flex flex-wrap w-full'>
                            <div className='md:w-1/3 w-full mt-2'>
                                <p className='b-block mb-0 w-full'>Provider</p>
                                <p className={`px-2 py-1 w-full text-black`}>{selectedDetails.provider ? selectedDetails.provider.toUpperCase() : ''}</p>
                            </div>
                            <div className='md:w-1/3 w-full mt-2 md:pl-3'>
                                <p className='b-block mb-0 w-full'>Account</p>
                                <p className={`px-2 py-1 w-full text-black`}>{selectedDetails.account_id ? selectedDetails.account_id : ''}</p>
                            </div>
                            <div className='md:w-1/3 w-full mt-2 md:pl-3'>
                                <p className='b-block mb-0 w-full'>Region</p>
                                <p className={`px-2 py-1 w-full text-black`}>{selectedDetails.region ? selectedDetails.region : ''}</p>
                            </div>
                        </div>

                        <div className='flex flex-wrap w-full my-3'>
                            <div className='md:w-1/3 w-full mt-2'>
                                <p className='b-block mb-0 w-full'>Image Name</p>
                                <p className={`px-2 py-1 w-full text-black`}>{selectedDetails.image_name ? selectedDetails.image_name : ''}</p>
                            </div>
                            
                            <div className='md:w-2/3 w-full mt-2 md:pl-3'>
                                <p className='b-block mb-0 w-full'>Ami Name</p>
                                <p className={`px-2 py-1 w-full text-black`}>{selectedDetails.ami_name ? selectedDetails.ami_name : ''}</p>
                            </div>
                        </div>

                        <div className='flex'>
                            <div className='py-3 w-full'>
                                <p className='b-block mb-0'>Tags</p>
                                <div className='flex flex-wrap gap-2 mb-2'>
                                    <input
                                        type='text'
                                        placeholder='Enter Key'
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black lg:w-1/3 md:w-1/2 w-full`}
                                        // maxlength='128'
                                        value={state.tag_key ? state.tag_key : ''}
                                        onChange={e => setState(prevState => ({ ...prevState, tag_key: e.target.value }))}
                                    />
                                    <input
                                        type='text'
                                        placeholder='Enter value'
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black lg:w-1/3 md:w-1/2 w-full`}
                                        // maxlength='128'
                                        value={state.tag_value ? state.tag_value : ''}
                                        onChange={e => setState(prevState => ({ ...prevState, tag_value: e.target.value }))}
                                    />
                                    <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 lg:mt-2 md:mt-2 mt-0 cursor-pointer' onClick={() => addNewTagValue()}/>
                                </div>
                                {state.selected_tags && state.selected_tags.length ? 
                                    <div className='flex flex-wrap'>
                                        {state.selected_tags.map((tag, tIndex) => {
                                            return(
                                                <span key={'tag_'+tIndex} className='flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace' id={'tag_'+tIndex}>
                                                    <div className='truncate'  style={{maxWidth: '250px'}}>
                                                        {tag.key +' : '+tag.value}
                                                    </div>
                                                    <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeTagSection(tag)}/>
                                                </span>
                                            )
                                        })}
                                    </div>
                                : null}
                            </div>
                        </div>

                        <div className='my-3 flex justify-end mx-2'>
                            {state.saveLoading ?
                                <button type="button" className="bg-HalloweenOrange text-white ml-2 font-bold px-2 py-1 rounded-md inline-flex items-center self-center">
                                    <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                    Updating
                                </button>
                            :
                                <div className='flex'>
                                    <button type="button" className="bg-HalloweenOrange text-white ml-2 font-bold px-2 py-1 rounded-md inline-flex items-center self-center" onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, callUpdateImage: true }))}>Update</button>
                                    <button type="button" className="bg-lightGray text-white ml-2 font-bold px-2 py-1 rounded-md inline-flex items-center self-center" onClick={ () => closeSidePanel()}>Cancel</button>
                                </div>
                            }
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateImage;