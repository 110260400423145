import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listAllProviders, listClusterFilters, setCommonPropsDetails } from '../../../../actions/commonActionNew';
import SelectOption from '../../../common/Form/SelectOption';

export default function FileFilter(props) {
    const dispatch = useDispatch();
    const [ state, setState ] = useState({
        accounts: [],
        regions: []
    })
    const closeFilters = useSelector(state => state?.filters?.commonPropsDetails?.closeFilters || false);
    const providers = useSelector(state => state?.filters?.providers || false)
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);

    const clickedonParent = useSelector(state => state?.filters?.commonPropsDetails?.clickedonParent || false);
  
    // list all providers
    useEffect(() => {
        dispatch(setCommonPropsDetails('fileManageFilterChange', {}))
    }, [dispatch])

    useEffect(() => { 
        if(providers) {
        setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "" }));
        } else {
        let params = {}
        dispatch(listAllProviders(params))
            .then((response) => {
            if(response) {
                setState(prevState => ({ ...prevState, providers: response }));
            }
            })
        }
    }, [dispatch, providers]);

    useEffect(() => {
        if(state.selectedProvider && state.accounts && !state.accounts.length) {
        let params = {};
        params.provider = state.selectedProvider.toLowerCase();
        params.aggregate_by = 'account_id';
        let label = state.selectedProvider
    
        if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
            setState(prevState => ({ ...prevState, accounts: propProjAccounts[label], selectedAccount: propProjAccounts[label] && propProjAccounts[label].length ? propProjAccounts[label][0].account_id : '' }));
        } else {
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        if(response.length) {
                            let obj = propProjAccounts ? propProjAccounts : {}
                            obj[label] = response
                            dispatch(setCommonPropsDetails('propProjAccounts', obj))
                        }
                        setState(prevState => ({ ...prevState, accounts: response, selectedAccount: response && response.length ? response[0].account_id : '' }));
                    }
                })
        }
        }
    }, [state.selectedProvider, propProjAccounts, state.accounts, dispatch])

    useEffect(() => {
        if(state.selectedProvider && state.regions && !state.regions.length) {
        let params = {};
        params.provider = state.selectedProvider.toLowerCase();
        params.aggregate_by = 'region';
        let label = state.selectedProvider
        if(state.selectedAccount && state.selectedAccount.length) {
            label = ''
            params.account_id = state.selectedAccount
        }

        if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
            setState(prevState => ({ ...prevState, regions: propProjRegions[label], selectedRegion: propProjRegions[label] && propProjRegions[label].length ? propProjRegions[label][0].region : '' }));
        } else {
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response && response.length) {
                        if(label) {
                            let obj = propProjRegions ? propProjRegions : {}
                            obj[label] = response
                            dispatch(setCommonPropsDetails('propProjRegions', obj))
                        }
                        setState(prevState => ({ ...prevState, regions: response, selectedRegion: response && response.length ? response[0].region : '' }))
                    }
                })
        }
        }
    }, [dispatch, propProjRegions, state.selectedProvider, state.regions, state.selectedAccount])

    // const getClusterList = (region) => {
    //     let params = {};
    //     params.provider = state.selectedProvider.toLowerCase();
    //     params.aggregate_by = 'cluster_name';
    //     params.account_id = state.selectedAccount
    //     params.region = region
    //     dispatch(listClusterFilters(params))
    //         .then((response) => {
    //             if(response && response.length) {
    //                 setState(prevState => ({ ...prevState, clusters: response, selectedClusters: response[0] }))
    //             }
    //         })
    // }

    useEffect(() => {
        if(state.selectedProvider && state.selectedAccount && state.selectedRegion) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'cluster_name';
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response && response.length) {
                        setState(prevState => ({ ...prevState, clusters: response, selectedClusters: response[0] }))
                    }
                })
        }
    }, [state.selectedProvider, state.selectedAccount, state.selectedRegion, dispatch])

    //     console.log(state.selectedProvider, state.selectedAccount, state.selectedRegion)
    // }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        if(closeFilters) {
        setState(prevState => ({ ...prevState, isProviderOpen: false, isAccountOpen: false, isRegionOpen: false }))

        dispatch(setCommonPropsDetails('closeFilters', false))
        }
    }, [closeFilters, dispatch])

    useEffect(() => {
        if(state.selectedClusters) {
            let obj = {
                selectedProvider: state.selectedProvider,
                selectedAccount: state.selectedAccount,
                selectedRegion: state.selectedRegion,
                selectedClusters: state.selectedClusters,
            }
            
            dispatch(setCommonPropsDetails('fileManageFilterChange', obj))
        }
    }, [state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, dispatch])

    useEffect(() => {
        if(clickedonParent) {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            setState(prevState => ({ ...prevState, clickedChild: [] }))

            dispatch(setCommonPropsDetails('clickedonParent', false))
        }

    }, [clickedonParent, dispatch, state.clickedChild])

    const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();
        
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    }

    return (
        <div className='flex flex-wrap mx-0 my-2 justify-end gap-2' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className=''>
                <SelectOption
                    label={"Provider"}
                    labelClass={{color:'text-white'}}
                    fields={["provider_name", "provider_name"]}
                    options={state.providers}
                    selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                    callbackMultiSelect={(value) => {
                        setState(prevState => ({ ...prevState, selectedProvider: value, accounts: [], regions: [], selectedAccount: '', selectedRegion: '', selectedClusters: '' }))
                    }}
                    singleSelection={true}
                    dropdownWidth={'min-w-32'}
                    classDetails={{bg: 'bg-white', border: "border border-NeutralGray-400", padding: "py-2.5 pr-4 pl-3", rounded: 'rounded-lg' }}
                />
            </div>
            <div className=''>
                <SelectOption
                    label={"Account"}
                    labelClass={{color:'text-white'}}
                    fields={["account_id", "account_name"]}
                    options={state.accounts}
                    selectedValues={state.selectedAccount ? state.selectedAccount : []}
                    callbackMultiSelect={(value) => {
                        setState(prevState => ({ ...prevState, selectedAccount: value }))
                    }}
                    singleSelection={true}
                    classDetails={{bg: 'bg-white', border: "border border-NeutralGray-400", padding: "py-2.5 pr-4 pl-3", rounded: 'rounded-lg' }}
                />
            </div>
            <div className=''>
                <SelectOption
                    label={"Region"}
                    labelClass={{color:'text-white'}}
                    fields={["region", "name"]}
                    options={state.regions}
                    selectedValues={state.selectedRegion ? state.selectedRegion : []}
                    callbackMultiSelect={(value) => {
                        setState(prevState => ({ ...prevState, selectedRegion: value }))
                        // getClusterList(value)
                    }}
                    singleSelection={true}
                    classDetails={{bg: 'bg-white', border: "border border-NeutralGray-400", padding: "py-2.5 pr-4 pl-3", rounded: 'rounded-lg' }}
                />
            </div>
            <div className='relative'>
                <SelectOption
                    label={"Cluster"}
                    labelClass={{color:'text-white'}}
                    options={state.clusters}
                    selectedValues={state.selectedClusters ? state.selectedClusters : ''}
                    callbackMultiSelect={(value) => {
                        setState(prevState => ({ ...prevState, selectedClusters: value }))
                    }}
                    singleSelection={true}
                    classDetails={{bg: 'bg-white', border: "border border-NeutralGray-400", padding: "py-2.5 pr-4 pl-3", rounded: 'rounded-lg' }}
                />
            </div>
        </div>
    )
}