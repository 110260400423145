import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import _ from 'lodash'
import { listWorkstationCatalogDetails, subscribeCatalog, getCatalogFilters } from '../../../actions/CatalogAction'
 import { Store as CommonNotification } from 'react-notifications-component';
import windows from '../../../assets/images/windows-pngwing.png'
import linux from '../../../assets/images/linux-pngwing.png'
import aws from '../../../assets/images/aws-black-pngwing.png'
import gcp from '../../../assets/images/gcp-pngwing.png'
// import ubuntu from '../../../assets/images/ubuntu-pngwing.png'
import CatalogDetailsSidePanel from './CatalogDetailsSidePanel';
import { deleteCatalog } from '../../../actions/Collider/CatalogAction'
import Search from '../../common/SearchWithHiddenInput'
import RequestWorkstationSidePanel from './RequestWorkstationSidePanel';
import { capitalizeFirstLetter, getCommonInformationMessageFilter, getPaginationShowingDetails } from '../../../utils/utility';
import {Icon} from "@iconify/react";
import CreateUpdateCatalogPanel from "./CreateUpdateCatalogPanel"
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import { LoadingCircle } from '../../common/LoadingCiricle';
import SelectOption from '../../common/Form/SelectOption';
import AlertConfirmation from '../../common/AlertConfirmation';
import Button from '../../common/Form/Button';

const Catalog = (props) => {
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        catalogType: props.selecedProvider && props.selecedProvider.toLowerCase(),
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion,
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 9,
		selectedCatalog: {},
		actionsAllowed: []
    })

    const location = useLocation();
    const currentPath = location.pathname;
	const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const dispatch = useDispatch(); // Create a dispatch function<

    const handleCatalogTypeChange = (value) => {
        setState(prevState => ({ ...prevState, showLoading: true, listCatalogs: [], filteredCatalogs: [], token: '', catalogType: value, callListWorkstationCatalogDetails: true }))
    }
    useEffect(() => {
        let params = {}
        if(state.selectedProvider && state.selectedProvider !== '') {
            params.provider = state.selectedProvider.toLowerCase()
        }
        dispatch(getCatalogFilters(params))
            .then((response) => {
                if(response) {
                    let results = response.results ? response.results : []
                    let mappings = response.mappings ? response.mappings : []
                    setState(prevState => ({ ...prevState, catalogTypes: mappings, catalogResponse: results, catalogType: state.selectedProvider.toLowerCase(),  callListWorkstationCatalogDetails: true }))
                }
            })
    }, [state.selectedProvider, dispatch])

    useEffect(() => {
        if(state.callListWorkstationCatalogDetails) {
            setState(prevState => ({ ...prevState, callListWorkstationCatalogDetails: false, showLoading: true }))
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== 'All')
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== 'All')
            }
            if(state.catalogType) {
                params.catalog_type = state.catalogType.toLowerCase()
            }

            if(state.searchText) {
                params.search_input = state.searchText
            }

            if(state.token === 'next') {
                params.next_token = state.listCatalogResponse && state.listCatalogResponse.next_token
            } else if(state.token === 'previous') {
                params.previous_token = state.listCatalogResponse && state.listCatalogResponse.previous_token
            } else if(state.token === 'current') {
                params.current_token = state.listCatalogResponse && state.listCatalogResponse.current_token
            }

            params.size = state.perPage
    
            dispatch(listWorkstationCatalogDetails(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results ? response.results : []
                        let json = response.catalog_details ? response.catalog_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])
                        
                        setState(prevState => ({ ...prevState, listCatalogResponse: response, listCatalogs: results, filteredCatalogs: results, detailsFormat, selectedFilters: params, showLoading: false, totalRecords: response.total ? response.total : 0 }))
                    }
                })
        }
    }, [state.callListWorkstationCatalogDetails, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.listCatalogs, state.perPage, state.token, state.catalogType, state.listCatalogResponse, state.searchText])

    useEffect(() => {
        if(state.onSubscribe && state.subscribedRow) {
            let params = state.subscribedRow
            // params.subscribed = false
            dispatch(subscribeCatalog(params))
                .then((response) => {
                    setState(prevState => ({
                        ...prevState,
                        subscribed: true,
                        onSubscribe: false,
                        loadingSubscription: false,
                    }))
                    if(response.status) {
                        props.onSubscribe(state.subscribedRow)
                    } else {
                        let messageType = 'danger'
                        let message = response.message
            
                        // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    }
                })
        }

    }, [dispatch, state.onSubscribe, state.subscribedRow, props])

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			if(userMenu && Object.entries(userMenu).length) {
                let actionsAllowed = []
                userMenu.menu && userMenu.menu.forEach(row => {
                    if(row.submenulist && row.submenulist.length) {
                        let trimmedPath = currentPath
                        if(row.submenulist.filter(e => e.link === trimmedPath && e.catalog_action).length) {
                            actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].catalog_action
                        }
                    } else if(row.catalog_action) {
                        actionsAllowed = row.catalog_action
                    }
                })
                setState(prevState => ({ ...prevState, actionsAllowed }))
            }
		}
    }, [userMenu, currentPath])

    const deleteCatalogFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.deleteCatalogDetails 
        let params = {}
		params.catalog_id = item.catalog_id
		dispatch(deleteCatalog(params))
			.then((response) => {
				if(response) {
					let messageType = 'danger'
					let message = response && response.message ? response.message : "Error in deleting"

					if(response.status) {
						messageType = 'success'
						setTimeout(() => { setState(prevState => ({ ...prevState, token: 'current', deleteModal: !state.deleteModal, callListWorkstationCatalogDetails: true, showDeleteLoading: false }))}, 1000)
					} else {
						setState(prevState => ({ ...prevState, showDeleteLoading: false }))
					}

					CommonNotification.addNotification({
						message: message,
						type: messageType,
						insert: 'top',
						container: 'top-center',
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					});
				}
			})
    }

    const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    }
    const onDelete = (item, index) => {
        let obj = {
            message: 'Are you sure you want to delete the catalog' +(item.catalog_name ? ' "'+item.catalog_name+'"' : ''),
            buttonLabel: "Yes, I'm sure",
        }
        setState(prevState => ({ ...prevState, confirmDetails: obj, selectedCatalog: item, selectedIndex: index, deleteModal: true, deleteCatalogDetails: item }))
    }

    return (
        state.showLoading ?
            <div className="w-full flex justify-center mt-4">
                <LoadingCircle />
            </div>
        :
            <React.Fragment>
            {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <CatalogDetailsSidePanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {} }))}
                    detailsFormat={state.detailsFormat}
                    selectedDetails={state.selectedDetails}
                />
            : null}
            {state.showCreateCatalog ? (
                <CreateUpdateCatalogPanel 
                    closeSidePanel={(refresh) => {
                        setState(prevState => ({ ...prevState, showCreateCatalog: false, selectedCatalog: {}, callListWorkstationCatalogDetails: refresh ? true : false }))
                        dispatch(setHpcPropsDetails("CreateUpdateCatalog", false));
                    }}
                    pageMode={state.pageMode}
                />
            ) : null}
            {state.showRequestSection && Object.entries(state.selectedCatalog).length ?
                <RequestWorkstationSidePanel
                    closeSidePanel={() => setState(prevState => ({...prevState, showRequestSection: false, selectedCatalog: {} }))}
                    selectedCatalog={state.selectedCatalog}
                    catalogTypes={state.catalogResponse}
                    catalogType={state.catalogType}
                />
            : null}
            <div className='p-2' onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='flex justify-between pb-8'>
                    <p className='mb-0 text-sm self-center lg:w-1/2 w-full'>
                        Showing <span className='mx-1'>{getPaginationShowingDetails(state.filteredCatalogs && state.filteredCatalogs.length, state.totalRecords, state.currentPage, state.perPage)}</span> out of total {state.totalRecords} catalog(s)
                        {state.selectedFilters && state.selectedFilters.provider ?
                            <React.Fragment>
                            <span className='ml-1'>obtained from the</span>
                            <span className='ml-1'>{state.selectedFilters.provider.toUpperCase()}</span>
                            <span className='ml-1'>provider</span>
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.account_id && state.selectedFilters.account_id.length ?
                            <React.Fragment>
                            <span className='ml-1'>with the account</span>
                            <span className='ml-1'>{state.selectedFilters.account_id[0]}</span>
                            {state.selectedFilters.account_id.length > 1 ?
                                <span className='ml-2 text-slateBlue-600'>more</span>
                            : null}
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.region && state.selectedFilters.region.length ?
                            <React.Fragment>
                            <span className='ml-1'>in the</span>
                            <span className='ml-1'>{state.selectedFilters.region[0]}&nbsp;</span>
                            {state.selectedFilters.region.length > 1 ?
                                <span className='ml-2  text-slateBlue-600'>more</span>
                            : null}
                            <span className='ml-1'>region</span>
                            </React.Fragment>
                        : null}
                    </p>
                    <div className='flex justify-end lg:w-1/2 w-full'>
                        <SelectOption
                            label={""}
                            fields={["value", "label"]}
                            options={state.catalogTypes}
                            selectedValues={state.catalogType ? state.catalogType : ''}
                            callbackMultiSelect={(value) => {
                                handleCatalogTypeChange(value)
                            }}
                            singleSelection={true}
                            dropdownWidth={'min-w-32'}
                            classDetails={{border: "border border-primaryPurple-600", padding: "py-2.5 px-4", bg: 'bg-lavender-200', rounded: 'rounded-full' }}
                        />
                        <div className='flex justify-end self-center'>
                            {state.listCatalogs && state.listCatalogs.length ? 
                                <div className='self-center w-100 ml-4'>
                                    <Search
                                        data={state.listCatalogs ? state.listCatalogs : []}
                                        topClassName={'border border-NeutralGray-600 rounded-full bg-white'}
                                        className={'rounded-full'}
                                        filteredData={(filteredCatalogs) => {
                                            let totalPages = 1                
                                            if(filteredCatalogs.length > state.perPage) {
                                                totalPages = Math.ceil(filteredCatalogs.length / state.perPage)
                                            }
                                            setState(prevState => ({ ...prevState, filteredCatalogs, totalPages, currentPage: 1, startRecord: 0 }))
                                        }}
                                    />
                                </div>
                            : null}
                            {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                                <Button
                                    classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px-4', text: 'text-white text-base font-semibold', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                    label={'Create Catalog'}
                                    callback={() => setState(prevState => ({ ...prevState, showCreateCatalog: true,  pageMode: 'Create' }))}
                                />
                            : null}
                        </div>
                    </div>
                </div>   
                {state.filteredCatalogs && state.filteredCatalogs.length ?
                    state.totalRecords > state.perPage ?
                        <div className='flex justify-end text-black w-full'>
                            <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                            <div className="flex text-sm">
                                <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.listCatalogResponse && state.listCatalogResponse.previous_token ?'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.listCatalogResponse && state.listCatalogResponse.previous_token) {
                                        setState(prevState => ({ ...prevState, showLoading: true, currentPage: state.currentPage-1, token: 'previous', callListWorkstationCatalogDetails: true }))
                                    }
                                }}/></span> 
                                <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-2 border border-lightGray rounded-md ${state.listCatalogResponse && state.listCatalogResponse.next_token ?'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => {
                                    if(state.listCatalogResponse && state.listCatalogResponse.next_token) {
                                        setState(prevState => ({ ...prevState, showLoading: true, currentPage: state.currentPage+1, token: 'next', callListWorkstationCatalogDetails: true }))
                                    }
                                }}/></span>
                            </div>
                        </div>
                    : null
                : null}             
                <div className='flex flex-wrap items-stretch'>
                    {state.filteredCatalogs && state.filteredCatalogs.length ?
                        state.filteredCatalogs.slice(state.startRecord, state.startRecord + state.perPage).map((row, index) => {
                            return(
                                <div className={`flex flex-col w-full lg:w-1/3 md:w-1/2 mb-4 cursor-pointer mb-3 cursor-pointer ${(index + 1) % 3 === 2 ? "px-3": ""}`} key={'row_'+index}>
                                    <div className="w-full bg-PrimaryTintsPurple-600 border border-platinum-600 rounded-2xl h-full" onClick={(e) => setState(prevState => ({ ...prevState, selectedDetails: row }))}>
                                        <div className={`w-full flex pl-5 pr-3 py-2.5 justify-between bg-NeutralGray-300 rounded-t-2xl`}>
                                            <p className={`py-1 text-center text-base font-semibold ${row.category === 'bronze' ? 'text-white' : 'text-black'}`}>{row.catalog_name ? capitalizeFirstLetter(row.catalog_name) : 'Catalog name'}</p>
                                            <p className='text-DeepPurpleTints-600 border border-DeepPurpleTints-600 rounded-full py-1.5 px-3' 
                                                onClick={(e) => {
                                                    setState(prevState => ({ ...prevState, selectedDetails: row }))
                                                }}
                                            >Details</p>
                                        </div>
                                        <div className='flex py-2 px-3 justify-between mb-1' >
                                            <div className='self-center w-1/12'>
                                                {row.platform === 'windows' ?
                                                    <img src={windows} className='img-fluid mb-3' alt='WINDOWS' />
                                                :
                                                    <img src={linux} className='img-fluid mb-3' alt='LINUX'/>
                                                }
                                                {row.provider === 'aws' || row.provider === 'AWS' ?
                                                    <img src={aws} className='img-fluid mb-3' alt='AWS' />
                                                :
                                                    <img src={gcp} className='img-fluid mb-3' alt='GCP' />
                                                }
                                                {/* <img src={ubuntu} className='img-fluid' alt='UBUNTU' /> */}
                                            </div>
                                            <div className='pl-[27px] w-11/12 flex flex-wrap justify-between'>
                                                <div className='w-8/12 break-all'>
                                                    <p className='mb-2 text-lightGray'>
                                                        <span className='text-NeutralGray-600 pr-2'>Name:</span> 
                                                        <span className='text-black'>{row.image_name}</span>
                                                    </p>
                                                    <p className='mb-2 text-lightGray'>
                                                        <span className='text-NeutralGray-600 pr-2'>Os:</span> 
                                                        <span className='text-black'>{row.platform}</span>
                                                    </p>
                                                    <p className='mb-2 text-lightGray'>
                                                        <span className='text-NeutralGray-600 pr-2'>Resources:</span>
                                                        <span className='text-black'>
                                                            {row.provider ? row.provider.toUpperCase() : <span>&nbsp;</span>}
                                                            {row.account_id ? (' : ')+row.account_id : <span>&nbsp;</span>}
                                                            {row.region ?  (' : ')+row.region : <span>&nbsp;</span>}
                                                        </span>
                                                    </p>
                                                    <p className='mb-2 text-lightGray'>
                                                        <span className='text-NeutralGray-600 pr-2'>Description:</span> 
                                                        <span className='text-black'>{row.description}</span>
                                                    </p>

                                                    <p className='mb-2 hidden'>
                                                        <span className='mr-1'>Capacity</span>
                                                        <span className='text-black'>{row.instance_type} </span>
                                                        <span className='text-black ml-2'>(
                                                            {row.vcpus} vCPU,
                                                            {row.memory} GB memory 
                                                        )</span>
                                                    </p>
                                                </div>
                                                <div className='self-center'>
                                                    {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                                                        <p className='text-PrimaryLightOrange-600 border border-PrimaryLightOrange-600 rounded-full py-1.5 px-3 flex justify-center' 
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                dispatch(setHpcPropsDetails("CreateUpdateCatalog", row));
                                                                setState(prevState => ({ ...prevState, selectedIndex: index, showCreateCatalog: true, pageMode: "Edit" }))
                                                            }}
                                                        >
                                                            {state.loadingSubscription && state['loadingSubscription_'+index] ?
                                                                <span className='flex'>
                                                                    <LoadingCircle />
                                                                    {'Updating'}
                                                                </span>
                                                            : 'Edit'}

                                                        </p>
                                                    : null}
                                                    {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                                                        <p className='text-PrimaryLightOrange-600 border border-PrimaryLightOrange-600 rounded-full py-1.5 px-3 flex justify-center mt-3' 
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                onDelete(row, index)
                                                            }}
                                                        >
                                                            {state.loadingSubscription && state['loadingSubscription_'+index] ?
                                                                <span className='flex'>
                                                                    {'Deleting'}
                                                                    <LoadingCircle />
                                                                </span>
                                                            : 'Delete'}

                                                        </p>
                                                    : null}
                                                    {state.actionsAllowed && state.actionsAllowed.includes('subscribe') ?
                                                        <p className='text-PrimaryLightOrange-600 border border-PrimaryLightOrange-600 rounded-full py-1.5 px-3 flex justify-center mt-3' 
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setState(prevState => ({ ...prevState, selectedCatalog: row, selectedIndex: index, showRequestSection: true }))
                                                            }}
                                                        >
                                                            {state.loadingSubscription && state['loadingSubscription_'+index] ?
                                                                <span className='flex'>
                                                                    <LoadingCircle />
                                                                    {'Subscribing'}
                                                                </span>
                                                            : 
                                                                <span className='flex'>
                                                                    {'Subscribe'}
                                                                </span>
                                                            }

                                                        </p>
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    :
                        <div className='flex justify-center m-4 w-full'>
                            {getCommonInformationMessageFilter('catalog')}
                        </div>
                    }
                </div>
                {state.confirmDetails && state.deleteModal ?
                    <AlertConfirmation 
                        confirmDetails={state.confirmDetails}
                        confirmation={(deleteModal) => {
                            if(deleteModal) {
                                deleteCatalogFunction()
                            }
                            setState(prevState => ({ ...prevState, confirmDetails: false, deleteModal: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))
                        }}
                    />
                : null}
            </div>
            </React.Fragment>
    );
};

export default Catalog