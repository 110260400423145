/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Rajasekar // on 07/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import moment from 'moment'

import { CSVLink } from "react-csv";
import { listAssets, listClusterAssets, listAdvancedSearchAssets, getAdvancedSearchAssetsCount, getTotalAssetCount, listClusterNames } from '../../../actions/assets/assetsActionNew'
import { CUSTOM_CURRENT_DURATION_FILTER_OPTIONS } from '../../../utils/constants'
import { listAllProviders, listAllAccounts, listAllRegions, listAllServices, getAllTagsKeys, getAllTagsValues, getSearchMetaResults } from '../../../actions/commonActionNew'
import {
	getAssetDetails,
	getTopEvents,
	getRecentEvents,
	getEventGraphCounts,
} from '../../../actions/detectors/detectorsAction'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../../common/SearchWithHiddenInput'
// import AdvancedSearch from '../../common/AdvancedSearch'
import DetailsSection from './DetailsSection'
import { getAccountNameFromId, momentDateTime, capitalizeTheFirstLetterOfEachWord, getRegionName } from '../../../utils/utility'
import _ from 'lodash'
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import MultiSelectSection from '../../common/MultiSelectSection';

import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import ResourcesAdvancedSearch from './AdvancedSearch/ResourcesAdvancedSearch';
// import AssetDetailsSidePanel from './AssetDetailsSidePanel'
import {Icon} from "@iconify/react";
let searchAssetInventory = null
const LandingPage = () => {
	const tabRef = useRef()
	const dayPickerRef = useRef()
	const durationRef = useRef()
	const serviceRef = useRef()
	const searchRef = useRef()
	const tagRef = useRef()	
	const tagValueRef = useRef()
	const clusterRef = useRef()
	const clusterValueRef = useRef()

	const dropdownRef = useRef()
	
	const [state, setState] = useState({
		selectedTab: 'asset_details',
		showLoading: true,
		showTabListDropdown: false,
		isActionDropdownOpen: false,
		showSupressInDetailsSection: false,
		showConfigureSection: false,

		showDetailsAssetSectionLoading: false,
		showDetailsMonitoringSectionLoading: false,
		showDetailsBillingSectionLoading: false,
		showDetailsEventsSectionLoading: false,
		showDetailsRelatedIssueLoading: false,
		showSupressSectionLoading: false,

		supressRules: [],
		showDetailsSection: false,
		showSupressForm: false,

		providers: [],
		providerIsOpen: false,
		selectedProvider: '',
		
		accounts: [],
		allAccounts: [],
		selectedAccount: [],
		isAccountOpen: false,
		
		selectedRegion: [],
		isRegionOpen: false,

		services: [],
		showDropdownTree: false,
		serviceResourceArray: [],
		filteredServiceResourceArray: [],
		selectedService: [],
		selectedServiceContent: 'All',
		isServiceOpen: false, 

		selectedCategory: '',
		selectedDuration: 'Current',
		relatedDurtaion: '',
		start_time : '',
		end_time: '',
		previousCustomDays: moment().subtract('180', 'days'),
		showDatepicker: false,
		durationType: '',
		filteredArray: [],
		totalAssetsList: [],
		dynamicHeaders: [],
		selectedTableRow: {},

		categoryCounts: [],

		assetActionSelectedTab: 0,

		topEventsData: {},
		recentEventsData: [],
		eventCountData: {},

		detailsTabNames: [
			{ name: 'Resource', value: 'asset_details' },
			{ name: 'Events', value: 'events' }
		],
		selectedTableTab: -1,
		selectedDetailsTab: 0,
		showDetailsTabListDropdown: false,
		onClickSupressCreateButton: false,
		selectedRowAssetDetails: [],
		selectedRowBillingDetails: [],
		selectedRowMonitoringSnapshot: {},

		dayWiseCountGraphData: {},
		relatedIssuesData: [],
		filteredRelatedIssuesData: [],
		assetConfigrationsList: [],
		summaryGraphData: {},

		tabItemsToShow: 0,

		detailsTabItemsToShow: 2,

		selectedMonitoringDuration: '2h',

		eventSeries: [],
		eventOptions: [],

		showAdvancedSearchModal: false,
		selectedAdvancedSearch: {},
		newSearchText: '',
		showSearchOptions: false,
		keyupTimer: undefined,
		showDropdown: false,
		
		modalHeader: 'Close Issue',
		modalClose: false,
		modalAlert: false,
		
		closeLoading: false,
		closeStatus: 'resolved',
		closeStatusType: '',
		closeAttachment: '',
		closeAttachmentKey: false,
		
		
		responseMessage: '',
		showMessage: false,
		hasErrorInRuleForm: false,

		tableClass: '',
		clusterList: [
			{label: "hpc-SLURM", value: "hpc-SLURM"},
			{label: "hpc-PBSPRO", value: "hpc-PBSPRO"},
			{label: "k8s-EKS", value: "k8s-EKS"},
			{label: "k8s-GKE", value: "k8s-GKE"}
		],
		emptyOptions: [],
	})

	const [selected, setSelected] = useState(null);
	

	const dispatch = useDispatch(); // Create a dispatch function
	const tagKeys = useSelector(state => state.filters.tagKeys);

	useEffect(() => {
		
		let assetTableColumns = []
		let dataRow = {}
		dataRow.Header = 'Date'
		dataRow.accessor = 'asset_time'
		dataRow.Cell = cellInfo => (
			<React.Fragment>
				<span>{momentDateTime(cellInfo.row.original.asset_time)}</span>
			</React.Fragment>
		)
		dataRow.width = 180
		assetTableColumns.push(dataRow)

		dataRow = {}
		dataRow.Header = 'Provider'
		dataRow.accessor = d => d.provider + ': ' + getAccountNameFromId(d.account_id, state.accounts) + ': ' + d.region === 'NA' ? 'Global' : d.region
		dataRow.Cell = cellInfo => (
			<React.Fragment>
			<span className=''>
				{cellInfo.row.original.provider + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) + ': ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region)}
			</span>
			</React.Fragment>
		)
		dataRow.width = 300
		assetTableColumns.push(dataRow)

		dataRow = {}
		dataRow.Header = 'Resource'
		dataRow.accessor = d => d.service_name + ' : ' + d.asset_name
		dataRow.Cell = cellInfo => (
			<span className=''>{cellInfo.row.original.service_name + ' : ' + cellInfo.row.original.asset_name}
			</span>
		)
		dataRow.width = 400
		assetTableColumns.push(dataRow)

		dataRow = {}
		dataRow.Header = 'Network'
		dataRow.accessor = 'vpc_id'		
		dataRow.width = 420			
		assetTableColumns.push(dataRow)

		setState(prevState => ({ ...prevState, assetTableColumns }))

		
		let cluserAssetTableColumns = []
		let clusterRow = {}
		clusterRow.Header = 'Date'
		clusterRow.accessor = 'asset_time'
		clusterRow.Cell = cellInfo => (
			<React.Fragment>
				<span>{momentDateTime(cellInfo.row.original.asset_time)}</span>
			</React.Fragment>
		)
		clusterRow.width = 180
		cluserAssetTableColumns.push(clusterRow)

		clusterRow = {}
		clusterRow.Header = 'Provider'
		clusterRow.accessor = d => d.provider + ': ' + getAccountNameFromId(d.account_id, state.accounts) + ': ' + d.region === 'NA' ? 'Global' : d.region
		clusterRow.Cell = cellInfo => (
			<React.Fragment>
			<span className=''>
				{cellInfo.row.original.provider + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) + ': ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region)}
			</span>
			</React.Fragment>
		)
		clusterRow.width = 200
		cluserAssetTableColumns.push(clusterRow)

		clusterRow = {}
		clusterRow.Header = 'Resource'
		clusterRow.accessor = d => d.service_name + ' : ' + d.asset_name
		clusterRow.Cell = cellInfo => (
			<span className=''>{cellInfo.row.original.service_name + ' : ' + cellInfo.row.original.asset_name}
			</span>
		)
		clusterRow.width = 400
		cluserAssetTableColumns.push(clusterRow)

		clusterRow = {}
		clusterRow.Header = 'Network'
		clusterRow.accessor = 'vpc_id'		
		clusterRow.width = 320			
		cluserAssetTableColumns.push(clusterRow)

		clusterRow = {}
		clusterRow.Header = 'Component'
		clusterRow.accessor = 'component'		
		clusterRow.width = 200			
		cluserAssetTableColumns.push(clusterRow)

		setState(prevState => ({ ...prevState, cluserAssetTableColumns }))
		let params = {}
		dispatch(listAllProviders(params))
			.then((response) => {
				if(response) {
					setState(prevState => ({
						...prevState,
						providers: response,
						selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''),
						callFilterIdentifiers: true
					}));
				}
		})

		// const _this = this
		window.addEventListener('resize', () => {
			setState(prevState => ({ ...prevState, callSetTabs: true }))
		})		
	}, [dispatch, state.accounts])

    
    // Call provider based regions whenever the selectedProviders state is updated
    useEffect(() => {
        if (state.selectedProvider && state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();

			dispatch(listAllAccounts(params))
                .then((response) => {
					if(response) {
                        let filteredAccounts = response
                        // if (filteredAccounts.length) {
                        //     let accRow = { account_id: 'All', account_name: 'All', date: null, disabled: false, provider: 'All' };
                        //     filteredAccounts.unshift(accRow);
                        // }
                        setState(prevState => ({ ...prevState, accounts: filteredAccounts }));
                    }
            	})

			dispatch(listAllRegions(params))
				.then((response) => {
					if(response) {
						let providerRegions = response[0]?.provider_regions || [];
						// if (providerRegions.length) {
						//     let provider_regions_row = { availability_zones: [], city: 'All', country: 'All', geography: 'All', name: 'All', region: 'All', region_id: 'All', state: 'All' };
						//     providerRegions.unshift(provider_regions_row);
						// }
						
						setState(prevState => ({ ...prevState, regions: providerRegions  }))
					}
				});
			
			// get services
			dispatch(listAllServices(params))
				.then((response) => {
					let services = response
					let serviceResourceArray = []
					let serviceArray = []
					services && services.length &&
					_.chain(services)
					.groupBy('resource_type')
					.map((value, key) => ({ label: key, sub: value }))
					.value()
					.forEach((service, i) => {
						if(i === 0) {
							let dataRow = {}							
							dataRow.category = 'All'
							dataRow.default_region = 'All'
							dataRow.provider_name = 'All'
							dataRow.resource_type = 'All'
							dataRow.service_id = 'All'
							dataRow.service_name = 'All'
							serviceArray.push(dataRow)

							let serRow = {}
							serRow.label = 'All'
							serRow.value = 'All'
							serRow.expanded = true
							let serArray = []
							serRow.children = serArray
							serviceResourceArray.push(serRow)
						}
						
						let serRow = {}
						serRow.label = service.label
						serRow.value = service.label
						serRow.expanded = true
						let serArray = []
						//serviceResourceArray.push(service)
						service.sub.forEach((item, i) => {
							let serItem = {}
							serItem.label = item.service_name
							serItem.value = item.service_name
							serItem.expanded = true
							serArray.push(serItem)
							serviceArray.push(item)
						})
						serRow.children = serArray
						serviceResourceArray.push(serRow)
					})
					setState(prevState => ({ ...prevState, services, serviceArray, serviceResourceArray, filteredServiceResourceArray: serviceResourceArray }))
				})

			//get tag kyes
			dispatch(getAllTagsKeys(params))
        }
    }, [state.selectedProvider, dispatch])
	
	useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ "key": state.tag_key }]
			if(state.tag_key) {
				dispatch(getAllTagsValues(params))
					.then((response) => {
						setState(prevState => ({ ...prevState, tagValues: response }))
					})
			}
		}
	}, [state.tag_key, dispatch])

	useEffect(() => {
        if (state.callClusterNames) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
			params.cluster_type = state.clusterType
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
			if(state.selectedTags && state.selectedTags.length) {
				let tags = []
				let groupTags = _.groupBy(state.selectedTags, "key")
				
				Object.entries(groupTags).forEach(([key, value]) => {
					let dataRow = {}
					
					if(value.filter(e => e.value) && value.filter(e => e.value).length) {
						dataRow.key = key 
						dataRow.value = value.filter(e => e.value) && value.filter(e => e.value).length ? value.map(e => e.value) : []
					} else {
						dataRow.key = key
					}
					tags.push(dataRow)
				})
				params.tags = tags
			}

            dispatch(listClusterNames(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
							clustersItems: response,
                            callClusterNames: false
                        }));
                    }
            })
        }
    }, [dispatch, state.callClusterNames, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedTags, state.clusterType])

	const addNewTag = () => {
		let tagsSelected = state.tagsSelected ? state.tagsSelected : []

		if(state.tag_key && state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== state.tag_key)
			if(state.tag_value && state.tag_value.length) {
				state.tag_value.forEach(row => {
					let dataRow = {
						key: state.tag_key,
						value: row,
					}
					filteredTags.push(dataRow)
				})
			} else {
				let dataRow = {
					key: state.tag_key
				}
				filteredTags.push(dataRow)
			}

			setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, tagsSelected: filteredTags, tag_key: "",  tag_value: [], tagValues: [] }))
		}
	}

	const removeTagSection = (tag) => {
		let filteredResult = state.tagsSelected.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, tagsSelected: filteredResult }))
	}

	const addNewCluster = () => {
		let clusterSelected = state.clusterSelected ? state.clusterSelected : []

		if(state.clusterType && state.clusterType !== "") {
			let filteredCluster = clusterSelected.filter(e => e.key !== state.clusterType)
			if(state.clusterNames && state.clusterNames.length) {
				// state.clusterNames.forEach(row => {
				// })
				let dataRow = {}
				dataRow.key = state.clusterType
				dataRow.value = state.clusterNames
				filteredCluster.push(dataRow)
				// dataRow.value = state.clusterNames
			} else {
				let dataRow = {
					key: state.clusterType
				}
				filteredCluster.push(dataRow)
			}

			setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, clusterSelected: filteredCluster, clusterType: "",  clusterNames: [], changesInFilter: true }))
		}
	}

	const removeClusterSection = (item) => {
		let filteredResult = state.clusterSelected.filter(e => e !== item)
		setState(prevState => ({ ...prevState, clusterSelected: filteredResult }))
	}

	const removeClusterValue = (name) => {
		let value = state.clusterSelected[0].value
		let filteredResult = value.filter(e => e !== name)

		let clusterSelected = state.clusterSelected
		clusterSelected[0].value = filteredResult
		setState(prevState => ({ ...prevState, clusterSelected }))
	}
	/**
	 * Method to get all status, risk and category counts
	 */
	useEffect(() => {
		if(state.callToGetCounts) {
			let params = {}
			if (state.selectedProvider !== '' && state.selectedProvider !== 'All') {
				params.provider = state.selectedProvider.toLowerCase()
			}
			
			if (state.selectedAccount.length && !state.selectedAccount.includes('All')) {
				params.account_id = state.selectedAccount
			}

			if (state.selectedRegion.length && !state.selectedRegion.includes('All')) {
				params.region = state.selectedRegion
			}

			if (state.selectedService && state.selectedService.length && !state.selectedService.includes('All')) {
				params.service_name = state.selectedService
			}
			
			if (state.selectedCategory !== '' && state.selectedCategory !== 'all' && state.selectedCategory.length) {
				params.category = state.selectedCategory
			}
			
			if (state.selectedDuration !== 'Current') {
				if(state.selectedDuration === 'custom')  {
					params.start_time = state.start_time
					params.end_time = state.end_time
				} else {
					params.duration = state.selectedDuration
				}
			}
			
			if(state.selectedAdvancedSearch && Object.entries(state.selectedAdvancedSearch).length) {
				let advancedParams = {}
				advancedParams.aggregate_by = ["category"]
				if(state.selectedAdvancedSearch) {
					if(state.selectedAdvancedSearch.hasOwnProperty("event_name")) {
						advancedParams.event_name = state.selectedAdvancedSearch.event_name
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("users")) {
						advancedParams.users = state.selectedAdvancedSearch.users
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("ip")) {
						advancedParams.ip = state.selectedAdvancedSearch.ip
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("instance_type")) {
						advancedParams.instance_type = state.selectedAdvancedSearch.instance_type
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("service_name")) {
						advancedParams.service_name = state.selectedAdvancedSearch.service_name
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("vpc_id")) {
						advancedParams.vpc_id = state.selectedAdvancedSearch.vpc_id
					}							
				}
				if(state.selectedTags && state.selectedTags.length) {
					let tags = []
					let groupTags = _.groupBy(state.selectedTags, "key")
					
					Object.entries(groupTags).forEach(([key, value]) => {
						let dataRow = {}
						
						if(value.filter(e => e.value) && value.filter(e => e.value).length) {
							dataRow.key = key 
							dataRow.value = value.filter(e => e.value) && value.filter(e => e.value).length ? value.map(e => e.value) : []
						} else {
							dataRow.key = key
						}
						tags.push(dataRow)
					})
					advancedParams.tags = tags
				}
				if(state.selectedTags && state.selectedTags.length) {
					let tags = []
					let groupTags = _.groupBy(state.selectedTags, "key")
					
					Object.entries(groupTags).forEach(([key, value]) => {
						let dataRow = {}
						
						if(value.filter(e => e.value) && value.filter(e => e.value).length) {
							dataRow.key = key 
							dataRow.value = value.filter(e => e.value) && value.filter(e => e.value).length ? value.map(e => e.value) : []
						} else {
							dataRow.key = key
						}
						tags.push(dataRow)
					})
					advancedParams.tags = tags
				}
				advancedParams = {...params, ...advancedParams}

				dispatch(getAdvancedSearchAssetsCount(advancedParams))
					.then((response) => {
						setState(prevState => ({ ...prevState, categoryCounts: response, tabItemsToShow: response.length, callSetTabs: true }))
					})

				let assetCountParams = {}
				assetCountParams = {...params, ...assetCountParams}
				assetCountParams.aggregate_by = ["asset_id"]
				dispatch(getAdvancedSearchAssetsCount(assetCountParams))
					.then((response) => {
						setState(prevState => ({ ...prevState, totalAssetsCount: response && response.length, callToGetCounts: false }))					
					})

			} else {
				params.aggregate_by = ['category']
				dispatch(getTotalAssetCount(params))
					.then((response) => {
						setState(prevState => ({ ...prevState, categoryCounts: response, tabItemsToShow: response.length, callSetTabs: true }))
					})

				let assetCountParam = {}
				assetCountParam = {...params, ...assetCountParam}
				assetCountParam.aggregate_by = ['asset_id']
				
				dispatch(getTotalAssetCount(assetCountParam))
					.then((response) => {
						setState(prevState => ({ ...prevState, totalAssetsCount: response.length, callToGetCounts: false }))
					})
			}
		}
	}, [dispatch, state.callToGetCounts, state.end_time, state.selectedAccount, state.selectedAdvancedSearch, state.selectedCategory, state.selectedDuration, state.selectedProvider, state.selectedRegion, state.selectedService, state.selectedTags, state.start_time])
		
	useEffect(() => {
		if (state.callSetTabs && tabRef.current) {
			const maxTabWidth = tabRef.current.clientWidth - 130
			let reqTabWidth = 0
			let allowedTabLength = -1
			for (let i = 0; i < tabRef.current.childNodes.length; i++) {
				reqTabWidth += tabRef.current.childNodes[i].clientWidth
				if (reqTabWidth < maxTabWidth) {
					allowedTabLength++
				}
			}
			setState(prevState => ({ ...prevState, tabItemsToShow: allowedTabLength, callSetTabs: false }))
		}
	}, [state.callSetTabs])

	const getAssetDetailsFunction = useCallback((asset) => {
		setState(prevState => ({ ...prevState, showDetailsAssetSectionLoading: true, showSupressSectionLoading: true }))
		let params = {
			service_name: asset.service_name,
			asset_id: asset.asset_id,
		}
		dispatch(getAssetDetails(params))
			.then((response) => {
				setState(prevState => ({ ...prevState, 
					selectedRowAssetDetails: response,
					showDetailsAssetSectionLoading: false,
					showSupressSectionLoading: false,
				}))
			})
	}, [dispatch])

	useEffect(() => {
		if(state.callFilterIdentifiers) {
			setState(prevState => ({ 
				...prevState,
				showLoading: true, 
				filteredArray: [], 
				// totalAssetsList: [],
				dynamicHeaders: state.selectedCluster && state.selectedCluster.length ? state.cluserAssetTableColumns : state.assetTableColumns,
				selectedTab: 'asset_details', 
				relatedDurtaion: state.selectedDuration,
				categoryCounts: [],
				showDetailsSection: false,
				callFilterIdentifiers: false
			}))
			
			let params = {}
			if (state.selectedProvider !== '' && state.selectedProvider !== 'All') {
				params.provider = state.selectedProvider.toLowerCase()
			}

			if (state.selectedAccount.length && !state.selectedAccount.includes('All')) {
				params.account_id = state.selectedAccount
			}
			
			if (state.selectedRegion.length && !state.selectedRegion.includes('All')) {
				params.region = state.selectedRegion
			}

			if (state.selectedService && state.selectedService.length) {
				params.service_name = state.selectedService
			}

			if (state.selectedCategory && state.selectedCategory !== 'all' && state.selectedCategory.length) {
				params.category = state.selectedCategory	
			}
			
			if (state.selectedDuration !== 'Current') {
				if(state.selectedDuration === 'custom')  {
					params.start_time = state.start_time
					params.end_time = state.end_time
				} else {
					params.duration = state.selectedDuration
				}
			}

			let statusCountParams = {}
			statusCountParams = {...params, ...statusCountParams}

			//header satus pies chart		
			statusCountParams.aggregate_by = ['status']
			dispatch(getTotalAssetCount(statusCountParams))


			if(state.selectedAdvancedSearch && Object.entries(state.selectedAdvancedSearch).length && !state.searchText) {
				if(state.selectedService !== '') {
					let filteredServiceResourceArray = []
					state.serviceResourceArray.forEach(item => {
						let dataRow = {}
						dataRow.label = item.label
						dataRow.expanded = true
						dataRow.checked = false
						let childArray = []
						item.children.forEach(child => {
							let childRow = {}
							childRow.label = child.label
							childRow.value = child.label
							childRow.expanded = true
							childRow.checked = false
							if(state.selectedService.includes(child.label)) {
								childRow.checked = true
							}
							childArray.push(childRow)
							dataRow.children = childArray
						})
			
						filteredServiceResourceArray.push(dataRow)
					})
					
					// let selectedServiceContent = state.selectedService && state.selectedService.length === 1 ? state.selectedService[0] : state.selectedService && state.selectedService.length > 1 ? state.selectedService.length +' Selected' : 'All'

					let selectedServiceContent = state.selectedService && state.selectedService.length ? state.selectedService.length +' Selected' : 'All'

					setState(prevState => ({ ...prevState, filteredServiceResourceArray, selectedServiceContent }))
				}

				let advancedParams = {}
				if(state.selectedAdvancedSearch) {
					if(state.selectedAdvancedSearch.hasOwnProperty("event_name")) {
						advancedParams.event_name = state.selectedAdvancedSearch.event_name
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("users")) {
						advancedParams.users = state.selectedAdvancedSearch.users
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("ip")) {
						advancedParams.ip = state.selectedAdvancedSearch.ip
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("instance_type")) {
						advancedParams.instance_type = state.selectedAdvancedSearch.instance_type
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("service_name")) {
						advancedParams.service_name = state.selectedAdvancedSearch.service_name
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("vpc_id")) {
						advancedParams.vpc_id = state.selectedAdvancedSearch.vpc_id
					}
				}
				advancedParams = {...params, ...advancedParams}
				if(state.selectedTags && state.selectedTags.length) {
					let tags = []
					let groupTags = _.groupBy(state.selectedTags, "key")
					
					Object.entries(groupTags).forEach(([key, value]) => {
						let dataRow = {}
						
						if(value.filter(e => e.value) && value.filter(e => e.value).length) {
							dataRow.key = key 
							dataRow.value = value.filter(e => e.value) && value.filter(e => e.value).length ? value.map(e => e.value) : []
						} else {
							dataRow.key = key
						}
						tags.push(dataRow)
					})
					advancedParams.tags = tags
				}
					
				if(state.nextToken) {
					advancedParams.next_token = state.nextToken
				}

				dispatch(listAdvancedSearchAssets(advancedParams))
					.then((response) => {
						let nextToken = ""
						if(response.next_token) {
							nextToken = response.next_token	
						}
							
						let results = [] 
						if(state.nextToken && state.nextToken.length) {
							results = state.totalAssetsList && state.totalAssetsList.length ? state.totalAssetsList : []
						}
						
						if(response.results && response.results.length) {
							results = results.concat(response.results)
						}

						setState(prevState => ({
							...prevState,
							showLoading: false,
							nextToken,
							filteredArray: results,
							totalAssetsList: results,
							dynamicHeaders: state.selectedCluster && state.selectedCluster.length ? state.cluserAssetTableColumns : state.assetTableColumns,
							selectedTableRow: results && results.length ? results[0] : {},
							showDetailsSection: true,
							selectedDetailsTab: 0,
							callToGetCounts: true,
							callFormHeader: true
						}))
							// this.getAllCounts("advancedSearch")  need to work
						if(results && results.length) {
							getAssetDetailsFunction(results[0])
						}
					})
			} else {

				let asstParams = {}
				asstParams = {...params, ...asstParams}
				if (state.selectedDuration !== 'Current') {
					if(state.selectedDuration === 'custom')  {
						asstParams.start_time = state.start_time
						asstParams.end_time = state.end_time
					} else {
						asstParams.duration = state.selectedDuration
					}
				}
				if(state.selectedTags && state.selectedTags.length) {
					let tags = []
					let groupTags = _.groupBy(state.selectedTags, "key")
					
					Object.entries(groupTags).forEach(([key, value]) => {
						// if(value.fitler(e => e.value))
						let dataRow = {}
						
						if(value.filter(e => e.value) && value.filter(e => e.value).length) {
							dataRow.key = key 
							dataRow.value = value.filter(e => e.value) && value.filter(e => e.value).length ? value.map(e => e.value) : []
						} else {
							dataRow.key = key
						}
						tags.push(dataRow)
					})
					asstParams.tags = tags
				}
				
				if(state.selectedCluster && state.selectedCluster.length) {
					asstParams.cluster_type = state.selectedCluster[0].key
					if(state.selectedCluster[0].value && state.selectedCluster[0].value.length) {
						asstParams.cluster_name = state.selectedCluster[0].value
					}
					
					if(state.nextToken) {
						asstParams.next_token = state.nextToken
					}

					// if(state.searchText) {
					// 	asstParams.asset_search = state.searchText
					// }

					dispatch(listClusterAssets(asstParams))
						.then((response) => {

							setState(prevState => ({ ...prevState, showLoading: false }))
							let nextToken = ""
							if(response.next_token) {
								nextToken = response.next_token	
							}
								
							let results = [] 
							if(state.nextToken && state.nextToken.length) {
								results = state.totalAssetsList && state.totalAssetsList.length ? state.totalAssetsList : []
							}
							
							if(response.results && response.results.length) {
								results = results.concat(response.results)
							}
	
							if (results && results.length) {
								let categoryCounts = response.category_agg ? response.category_agg : []
	
								setState(prevState => ({ 
									...prevState, 
									showLoading: false,
									nextToken,
									filteredArray: results,
									totalAssetsList: results,
									categoryCounts,
									dynamicHeaders: state.selectedCluster && state.selectedCluster.length ? state.cluserAssetTableColumns : state.assetTableColumns,
									selectedTableRow: results && results.length ? results[0] : {},
									showDetailsSection: true,
									selectedDetailsTab: 0,
									totalAssetsCount: response.total ? response.total : 0,
									callFormHeader: true
								}))
							} else {
								setState(prevState => ({ ...prevState, showDropdownTree: false, selectedTableRow: {} }))
							}

							if(results && results.length) {
								getAssetDetailsFunction(results[0])
							}
						})
				} else {
					if(state.nextToken) {
						asstParams.next_token = state.nextToken
					}
					if(state.searchText) {
						asstParams.asset_search = state.searchText
					}
					dispatch(listAssets(asstParams))
						.then((response) => {
							let nextToken = ""
							if(response.next_token) {
								nextToken = response.next_token	
							}
								
							let results = [] 
							if(state.nextToken && state.nextToken.length) {
								results = state.totalAssetsList && state.totalAssetsList.length ? state.totalAssetsList : []
							}
							
							if(response.results && response.results.length) {
								results = results.concat(response.results)
							}
							
							if (results && results.length) {
								setState(prevState => ({ 
									...prevState,
									showLoading: false,
									nextToken,
									filteredArray: results,
									totalAssetsList: results,
									dynamicHeaders: state.selectedCluster && state.selectedCluster.length ? state.cluserAssetTableColumns : state.assetTableColumns,
									selectedTableRow: results && results.length ? results[0] : {},
									showDetailsSection: true,
									selectedDetailsTab: 0,
									callToGetCounts: true,
									callFormHeader: true,
								}))
										// getAllCounts("common") need to work
								if(results && results.length) {
									getAssetDetailsFunction(results[0])
								}									
							} else {
								setState(prevState => ({ ...prevState, showDropdownTree: false, selectedTableRow: {}, showLoading: false }))
							}
						})
				}
				setState(prevState => ({ ...prevState, selectedAdvancedSearch: {} }))
			}

		}
	}, [dispatch, state.callFilterIdentifiers, state.selectedDuration, state.selectedCluster, state.cluserAssetTableColumns, state.assetTableColumns, state, getAssetDetailsFunction])

	useEffect(() => {
		if(state.callStructureAdvancedSearchParams) {
			let adParams = state.selectedAdvancedSearch
		
			let selectedTags = []
			if(adParams.tags && adParams.tags.length)  {
				adParams.tags.forEach(row => {
					let addRow = {}
					addRow.key = row.label
					addRow.value = row.value
					selectedTags.push(addRow)
				})
			}
			
			if(adParams.duration)  {
				setState(prevState => ({ ...prevState, selectedDuration: adParams.duration }))
			} else if(adParams.start_time && adParams.end_time) {
				let start_time = adParams.start_time
				let end_time = adParams.end_time
				setState(prevState => ({ ...prevState, selectedDuration: "custom", start_time, end_time }))
			}

			setState(prevState => ({ 
				...prevState,
				showAdvancedSearchModal: false,
				showLoading: true,
				selectedAdvancedSearchFilters: adParams,
				selectedProvider: adParams.provider ? adParams.provider : "aws",
				selectedAccount: adParams.account_id && adParams.account_id.length ? adParams.account_id : [],
				selectedRegion: adParams.region && adParams.region.length ? adParams.region : [],
				selectedService: adParams.service_name && adParams.service_name.length ? adParams.service_name : [],
				selectedCategory: adParams.category && adParams.category.length ? adParams.category : [],
				selectedTags,
				tagsSelected: selectedTags,
				selectedTableTab: -1,
				totalAssetsList: [],
				nextToken: "",
				callFilterIdentifiers: true,
				callStructureAdvancedSearchParams: false
			}))
		}
	}, [state.callStructureAdvancedSearchParams, state.selectedAdvancedSearch])

	const getNextSetoffRecords = () => {
		setState(prevState => ({ ...prevState, callFilterIdentifiers: true }))
	}

	/**
	 * Method to return total category counts
	 */
	const getTotalCategoryCounts = () => {
		let count = 0
		if (state.categoryCounts && state.categoryCounts.length) {
			state.categoryCounts.forEach(item => {
				count += item.total_count
			})
		}
		return count
	}

	/**
	 * Handles on click tab dropdown
	 */
	const onClickDropDownItem = (item, index) => {
		let tempArray = state.categoryCounts
		tempArray.splice(index, 1)
		tempArray.splice(state.tabItemsToShow - 2, 0, item)
		setState(prevState => ({
			...prevState,
			categoryCounts: tempArray,
			selectedTableTab: state.tabItemsToShow - 2,
			selectedCategory: item.category,			
		}))
		getAllAssetsOnTabChange(item.category)
	}

	const getAllAssetsOnTabChange = selectedCategory => {
		setState(prevState => ({ ...prevState, nextToken: [], selectedCategory: selectedCategory !== "All" ? selectedCategory : [], callFilterIdentifiers: true }))
	}

	const getRelatedTableData = (category, selectedResourceStatus, status) => {

		let filteredRelatedIssuesData = state.relatedIssuesData
		if(category !== '' && category !== undefined) {
			filteredRelatedIssuesData = filteredRelatedIssuesData.filter(item => item.category.includes(category))
		} 
		if(selectedResourceStatus !== '' && selectedResourceStatus !== undefined) {
			filteredRelatedIssuesData = filteredRelatedIssuesData.filter(item => item.resolution_status_info ? item.resolution_status_info.status === selectedResourceStatus : selectedResourceStatus === 'pending' ? item.status === 'fail' : item.status === 'pass')
		}
		if(status !== '' && status !== undefined) {
			filteredRelatedIssuesData = filteredRelatedIssuesData.filter(item => item.status === status)
		} 
		setState(prevState => ({ ...prevState, filteredRelatedIssuesData }))
	}
	/**
	 * Get asset details of the detector
	 */
	

	const openCloseModal = (closeRow, relatedIssueDurtaion) => {
		setState(prevState => ({ ...prevState, 
			modalClose: !state.modalClose, selectedRelativeIssue: closeRow, relatedIssueDurtaion, closeLoading: false
		}))
	}

	/**
	 * Get asset events
	 */
	const getAssetEvents = asset => {
		setState(prevState => ({ ...prevState, showDetailsEventsSectionLoading: true }))
		
		const params = {}
		params.provider = asset.provider.toLowerCase()
		params.service_name = asset.service_name
		params.asset_id = asset.asset_id
		params.aggregate_by = 'event'
		if (state.selectedDuration !== 'Current') {
			if(state.selectedDuration === 'custom')  {
				params.start_time = state.start_time
				params.end_time = state.end_time
			} else {
				params.duration = state.selectedDuration
			}
		}
		params.account_id = asset.account_id
		params.region = asset.region
		dispatch(getTopEvents(params))
			.then((response) => {
				setState(prevState => ({ ...prevState, topEventsData: response, showDetailsEventsSectionLoading: false }))
			})

		const recentEventsParams = {
			service_name: asset.service_name,
			provider: asset.provider.toLowerCase(),
			asset_id: asset.asset_id,
		}
		dispatch(getRecentEvents(recentEventsParams))
			.then((response) => {
				setState(prevState => ({ ...prevState, recentEventsData: response, showDetailsEventsSectionLoading: false }))
			})

		const eventCountsParams = {}
		eventCountsParams.service_name = asset.service_name
		eventCountsParams.provider = asset.provider.toLowerCase()
		eventCountsParams.asset_id = asset.asset_id
		if (state.selectedDuration !== 'Current') {
			if(state.selectedDuration === 'custom')  {
				eventCountsParams.start_time = state.start_time
				eventCountsParams.end_time = state.end_time
				// eventCountsParams.duration = '+14d'
			} else {
				eventCountsParams.duration = state.selectedDuration
			}
		} else {
			eventCountsParams.duration = '+1d'
		}
		dispatch(getEventGraphCounts(eventCountsParams))
			.then((response) => {
				setState(prevState => ({ ...prevState, eventCountData: response, showDetailsEventsSectionLoading: false, eventSeries: [], eventOptions: [], showApexEventsGraph: true }))
			})
	}

	useEffect(()=> {
		if(state.showApexEventsGraph && state.eventCountData) {
			let graphLabel = []
			let createdGraphData = []
			// let modifiedGraphData = []
			let fontColors = []
			let startEndLabels = []
			if(state.eventCountData.data && state.eventCountData.data.count && state.eventCountData.data.count.length) {
				state.eventCountData.labels.forEach((item, x) => {
					graphLabel.push(moment(item).format('DD MMM'))
					if(x === 0 || x === (state.eventCountData.labels.length - 1)) {
						startEndLabels.push(moment(state.eventCountData.labels[x]).format('DD MMM'))
					} 
	
				})
				let data1 = []	
				state.eventCountData.data.count.forEach((item, x) => {
					createdGraphData.push(item)
					let dataItems1 = {}
					dataItems1.x = moment(state.eventCountData.labels[x]).format('DD MMM')
					dataItems1.y  = item
					data1.push(dataItems1)
	
					fontColors.push('#000')
				})
	
				let series = []
				let dataRow = {}
				dataRow.data = data1
				dataRow.name = 'pass'
				dataRow.labels = graphLabel
				series.push(dataRow)
	
				let options = {
					chart: {
					height: 100,
					type: 'area',
					zoom: {
						enabled: false
					},
					sparkline: {
						enabled: false
					},
					toolbar: {
						show: false,
					}
					},
					legend: {
						show: false
					},
					dataLabels: {
					enabled: false
					},
					stroke: {
						show: true,
						curve: 'smooth',
						lineCap: 'butt',
						colors: undefined,
						width: 2,
						dashArray: 0,      
					},
					title: {
					text: ''
					},
					grid: {
						xaxis: {
							lines: {
								show: false
							},
						},
						yaxis: {
							lines: {
								show: false
							}
						},
					},			
					yaxis: {
						labels: {
							show: true,
							style: {
								colors: fontColors
							},
							formatter: function(value) {
								return parseInt(value)
							}
						},
						tickAmount: 1
					},
					labels: graphLabel,
					xaxis: {
						Categories: [graphLabel],
						show: true,
						labels: {
							rotate: 0,
							show: true,
							formatter: function(value) {
								if(value === startEndLabels[0] || value === startEndLabels[1]) {
									return value
								}
							},
							style: {
								colors: fontColors
							},
							axisTicks: {
								show: false
							}
						},
						tooltip: {
							enabled: false
						},
						// min: min,
						// max: max,
					},
					colors: ['#FFD200', '#B96422'],
					// colors: ['#09D261', '#F44336'],
					// fill: {
					// 	colors: ["#CEFBFF"],
					// 	opacity: 0.7,
					// 	type: 'solid',
					// 	//opacity: 0.5,
					// },
					tooltip: {
						enabled: true,
						enabledOnSeries: false,
						shared: false,
						followCursor: false,
						intersect: false,
						inverseOrder: false,
						custom: function({series, seriesIndex, dataPointIndex, w}) {
							let val  = series[seriesIndex][dataPointIndex];
							//let val  = series[seriesIndex][dataPointIndex];
							let labelName = w.globals.initialSeries[0].labels[dataPointIndex]
							//let label = w.globals.initialSeries[seriesIndex][dataPointIndex]
							//val = Math.abs(Math.round(val))
							//let labelName = w.globals.categoryLabels[dataPointIndex]
							//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
							return '<div class="arrow_box"> <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val +
							'</div>'
						},
						fillSeriesColor: false,
						theme: false,
						style: {
						fontSize: '12px',				
						},
						onDatasetHover: {
							highlightDataSeries: false,
						},
						x: {
							show: false,
							format: 'dd MMM',
							formatter: undefined,
						},
						y: {
							show: false,
							formatter: undefined,
							title: {
								formatter: (seriesName) => seriesName,
							},
						},
						marker: {
							show: false,
						}
					}
				}
				
				setState(prevState => ({ ...prevState, eventSeries: series, eventOptions: options, showApexEventsGraph: false }))
			}
		}
	}, [state.showApexEventsGraph, state.eventCountData])

	/**
	 * Handles loading toggle from details section screen
	 */
	const handleLoadingToggle = (from, boolean) => {
		if (from === 'asset_details') setState(prevState => ({ ...prevState, howDetailsAssetSectionLoading: boolean }))
		if (from === 'events') setState(prevState => ({ ...prevState, howDetailsEventsSectionLoading: boolean }))

		setState(prevState => ({ ...prevState, selectedTab: from }))
	}
	
	const onClickAdvancedSearhBox = () => {
		if(state.newSearchText === '') {
			setState(prevState => ({ ...prevState, isLoading: true, showDropdown: true, searchResultState: [] }))

			let params = {
				provider: state.selectedProvider.toLowerCase(),
				size: 5,
			}		
			dispatch(getSearchMetaResults(params))
				.then((response) => {
					setState(prevState => ({ ...prevState, searchResultState: response, isLoading: false }))
				})
		}
	}

	const getSearchMetaResultsFunction = () => {
		if (state.keyupTimer) {
			clearTimeout(state.keyupTimer)
		}
		if(state.newSearchText.length > 2) {
			setState(prevState => ({ ...prevState, showDropdown: true }))
			let keyupTimer = setTimeout(() => {
				setState(prevState => ({ ...prevState, isLoading: true }))
				let params = {
					provider: state.selectedProvider.toLowerCase(),
					search_input: state.newSearchText,
					size: 5,
				}
				dispatch(getSearchMetaResults(params))
					.then((response) => {
						setState(prevState => ({ ...prevState, searchResultState: response, isLoading: false }))
					})
			}, 500)
			setState(prevState => ({ ...prevState, keyupTimer }))
		} else {
			setState(prevState => ({ ...prevState, showDropdown: false }))
		}		
	}

	const handleResetClick = () => {	
		setState(prevState => ({ ...prevState, from: undefined, to: undefined }))
	}

	const handleSelect = (date) => {
		setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null }))
		setSelected(date)
		// if(date.from && date.to) {
		// 	setState(prevState => ({ ...prevState, from: date.from, to: date.to, showDatepicker: false }))
		// }
	}

	const onChangeServiceDropdown = (currentNode, selectedNodes) => {
		let filteredServiceResourceArray = []	
		let selectedTreeService = []
		let selectedService = []
		if(selectedNodes.length && currentNode.label !== 'All') {
			selectedNodes.forEach(item => {
				if(item._depth === 0) {
					let child = {}
					child.label = item.label
					child.parent = 1
					selectedTreeService.push(child)
					state.serviceResourceArray.forEach(service => {
						if(service.label === item.label) {
							service.children.forEach(child => {
								selectedService.push(child.label)
							})
						}
					})
				} else {
					let child = {}
					child.label = item.label
					child.parent = 0
					selectedTreeService.push(child)
					selectedService.push(item.label)
				}
			})

			filteredServiceResourceArray = []
			state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					if(selectedTreeService.length) {					
						selectedTreeService.forEach(ser => {
							if(ser.parent === 1 && item.label === ser.label) {
								dataRow.checked = true
							}						
							if(ser.parent === 1 && item.label === ser.label) {
								childRow.checked = true
							} else if(ser.label === child.label){
								childRow.checked = true
							}			
						})
					}
					childArray.push(childRow)
					dataRow.children = childArray
				})		
				
				if(item.label === 'All' && currentNode.label === 'All') {
					dataRow.checked = true
				}
	
				filteredServiceResourceArray.push(dataRow)
			})
		} else if(currentNode.label === 'All' || !selectedNodes.length) {
			selectedTreeService= []
			selectedService = []
			filteredServiceResourceArray = []
			state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				if(currentNode.checked) {
					dataRow.checked = true
				} else if(!currentNode.checked) {
					dataRow.checked = false
				}
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					childRow.parent = 1
					if(currentNode.checked) {
						childRow.checked = true
						selectedService.push(childRow.value)
					} else if(!currentNode.checked) {
						childRow.checked = false
					}
					childArray.push(childRow)
					selectedTreeService.push(childRow)
					dataRow.children = childArray					
				})
				filteredServiceResourceArray.push(dataRow)
			})
		}	

		// let selectedServiceContent = selectedService.length === 1 ? selectedService : selectedService.length > 2 ? selectedService.length +' Selected' : 'All'
		let selectedServiceContent = selectedService.length ? selectedService.length +' Selected' : 'All'
		setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, filteredServiceResourceArray, selectedService, selectedServiceContent, callFilterIdentifiers: true }))
	}

	useEffect(() => {
		if(state.callFormHeader) {
			let dataResult = state.filteredArray && state.filteredArray.length ? state.filteredArray : []
			if(dataResult.length) {
				let headers = []
				Object.entries(dataResult[0]).forEach(([key, value]) => {
					if(typeof value === "string") {
						let headerRow = {}
						headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
						headerRow.key = key
						headers.push(headerRow)
					}
				})
				setState(prevState => ({ ...prevState, headers, callFormHeader: false }))
			}
		}
	}, [state.callFormHeader, state.filteredArray])

	const handleChildClick = (event, type, dropdownType, section) => {	
		if(!state.isServiceOpen) {
			event.stopPropagation();
		}
		if(serviceRef.current && serviceRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, isServiceOpen: true }))
		} else {
			setState(prevState => ({ ...prevState, isServiceOpen: false }))
		}
		if(durationRef.current && !durationRef.current.contains(event.target)) {
			if (dayPickerRef && !dayPickerRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, showDatepicker: false }))
				handleResetClick()
			}
		}
		if(state.showTagFilterSection) {
			if (tagRef.current && !tagRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, showTagFilterSection: false }))
			}

			if (tagValueRef.current &&  !tagValueRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, isTagValueOpen: false }))
			} else {
				setState(prevState => ({ ...prevState, isTagValueOpen: true }))
			}
		}
		if(state.showClusterFilterSection) {
			if (clusterRef.current && !clusterRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, showClusterFilterSection: false }))
			}

			if (clusterValueRef.current &&  !clusterValueRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, isCLusterOpen: false }))
			} else {
				setState(prevState => ({ ...prevState, isCLusterOpen: true }))
			}
		}

		if (searchRef.current && !searchRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDropdown: false }))
		} else {
			setState(prevState => ({ ...prevState, showDropdownTree: false }))
		}
		
		let clickedChild = []
		if(type === 'child') {			
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	useEffect(() => {
		/**
		 * If clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, isDropdownOpen: false }));
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return() => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdownRef]);

	/**
	 * Renders detectors Dashboard
	 */
	// const { from, to } = state;
	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
			{state.showAdvancedSearchModal ? (
				<ResourcesAdvancedSearch
					start_time={state.start_time}
					end_time={state.end_time}
					resetForm={state.resetForm}
					categoryCounts={state.categoryCounts}
					searchFrom='resources'
					providers={state.providers}
					closeSidePanel={() => setState(prevState => ({ ...prevState, showAdvancedSearchModal: false }))}
					selectedProvider={state.selectedProvider}
					selectedAccount={state.selectedAccount}
					getFilteredList={(adParams, searchTitle) => {
						setState(prevState => ({ ...prevState, selectedAdvancedSearchTitle: searchTitle, selectedAdvancedSearch: adParams, callStructureAdvancedSearchParams: true }))
					}}
				/>
			) : null}
			<div className="flex flex-wrap">
				<div className={`${state.filteredArray && state.filteredArray.length && state.showDetailsSection ? 'lg:w-9/12 w-full' : 'w-full'}`}>
					<div className='bg-white px-3 py-3'>
						<div className='flex flex-wrap justify-between'>
							<div className='lg:w-1/2 md:w-2/3 w-full'>
								<h6 className='text-black m-0'>Search Resources</h6>
								<p className='text-black m-0 mb-2'>Search the resources present in your Cloud</p>
							</div>
							{!state.showLoading ?
								<div className={`flex flex-wrap justify-end lg:w-1/2 md:w-1/3 w-full}`}>
									<div className="input-group relative dark-feild mb-2 w-full">
										<div className="input-group-prepend">
											<div className="input-group-text bg-transparent">
												<i className="fal fa-search"></i>
											</div>
										</div>
										<input
											ref={searchRef}
											type='text'
											value={state.newSearchText}
											onChange={e => {
												setState(prevState => ({ ...prevState, newSearchText: e.target.value, searchResultState: [], isLoading: true }))
												getSearchMetaResultsFunction(e.target.value)
											}}
											className='border border-lightGray rounded-md bg-transparent px-2 py-1 w-full'
											placeholder='Search'
											onClick={(e => onClickAdvancedSearhBox())}
										/>
										
										{state.showDropdown && state.searchResultState && state.searchResultState.length ? 
											<div className={`absolute bg-white rounded-md z-10 p-2 w-full`}>
												{state.searchResultState.map((item, index) => {
													return(
														<div key={index} className="flex justify-between cursor-pointer border-bottom p-2"
															onClick ={() =>  setState(prevState => ({ ...prevState, showAdvancedSearchModal: false, showLoading: true, selectedProvider: item.provider ? item.provider : "aws", selectedAdvancedSearch: item.parameters, callStructureAdvancedSearchParams: true }))}
														>
															<div className="w-100">
																<p className='font-weight-bold m-0 text-dark text-left p-0'>{item.title ? item.title : ''}</p>
															</div>
														</div>
													)
												})}
												<div className="flex justify-between p-2 bg-light">
													<p className="flex m-0 text-info" onClick={() =>
													setState(prevState => ({ ...prevState, resetForm: state.resetForm + 1, showAdvancedSearchModal: true, showDropdown: false, newSearchText: '' }))}>
														<Icon icon={`bitcoin-icons:search-outline`} className={`text-info w-rull} self-center cursor-pointer`} width={22} height={22} />

														Advanced Search
													</p>
												</div>
											</div>
										: 
											state.showDropdown && state.isLoading && (
												<div className={`absolute bg-white rounded-md z-10 p-2 w-full`}>
													<div className='flex justify-center m-4 w-full'>
														<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
															<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
															<path d="M4 12a8 8 0 0112-6.9" />
														</svg>
													</div>
												</div>
											)
										}
										{/* {state.showDropdown && state.searchResultState && !state.isLoading ?
											<div className={`absolute bg-white rounded-md z-10 p-2 w-full`}>
												<div className="flex justify-between cursor-pointer p-2 bg-light">
													<p className="m-0 text-info" onClick={() =>
													setState(prevState => ({ ...prevState,resetForm: state.resetForm + 1, showAdvancedSearchModal: true, showDropdown: false, newSearchText: '' }))}>
													<span className="fal fa-search mr-1"></span> Advanced Search</p>
												</div>
											</div>
										: null} */}
									</div>
									<div className='flex justify-between'>
										<p className='text-info mr-5 mb-0 cursor-pointer' onClick={() =>
												setState(prevState => ({ ...prevState,  newSearchText: '', selectedAdvancedSearch: {}, selectedCategory: [], selectedProvider: '', selectedAccount: [], selectedRegion: [], selectedDuration: 'Current', selectedService: [], selectedServiceContent: 'All', filteredServiceResourceArray: [], selectedTags: [], tagsSelected: [], clusterType: '', clustersItems: [], clusterNames: [], selectedCluster: [], callFilterIdentifiers: true }))
											}
										>
											Reset Filter
										</p>
										<p className='text-info mb-0 cursor-pointer' onClick={() =>
												setState(prevState => ({ ...prevState, resetForm: state.resetForm + 1, showAdvancedSearchModal: true }))
											}
										> Advanced Search</p>
									</div>
								</div>
							: null}
						</div>
						<div className='flex flex-wrap justify-end gap-2'>
							<div className={`bg-BlockWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer lg:w-1/12 md:w-1/6 relative`}
								onClick={(event) => {
									if(!state.isProviderOpen) {
										event.preventDefault();
										handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
									}
								}}
							>
								<label className='my-1 flex'>Provider</label>
								<p className={`flex justify-between mb-0 truncate ${state.selectedProvider ? 'text-black' : 'text-lightGray'}`}>
									{state.selectedProvider ? state.selectedProvider : 'Select'}
									<Icon icon="icon-park-solid:down-one" className={`${state.selectedProvider ? "text-black" : "text-lightGray"}`} width="16" height="16" />
								</p>
								{state.isProviderOpen ?
									<MultiSelectSection 
										fields={["provider_name", "provider_name"]}
										options={state.providers}
										className={'top80 left-0'}
										widthClass={'minWidth220'}
										removeTopOptions={true}
										selectedValues={state.selectedProvider ? state.selectedProvider : ''}
										callbackMultiSelect={(value) => {
											if(!value || typeof(value) === 'string') {
												setState(prevState => ({ ...prevState, selectedProvider: value, totalAssetsList: [], filteredArray: [], nextToken: null,  changesInFilter: true, selectedAccount: [], selectedRegion: [], callFilterIdentifiers: true }))
											} else {
												value.preventDefault()
												handleChildClick(value, "search", 'singleDropDown', "")
											}
										}}
										singleSelection={true}
									/>
								: null}
							</div>
							<div className={`bg-BlockWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer lg:w-1/12 md:w-1/6 relative`}
								onClick={(event) => {
									event.preventDefault();
									handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
								}}
							>
								<label className='my-1 flex'>Account</label>
								<p className={`flex justify-between mb-0 truncate ${state.selectedAccount && state.selectedAccount.length ? 'text-black' : 'text-lightGray'}`}>
									{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}
									<Icon icon="icon-park-solid:down-one" className={`${state.selectedAccount && state.selectedAccount.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />
								</p>
								{state.isAccountOpen ?
									<MultiSelectSection 
										fields={["account_id", "account_name"]}
										options={state.accounts}
										selectedValues={state.selectedAccount ? state.selectedAccount : []}
										callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, selectedAccount: value, callClusterList: true, callFilterIdentifiers: true }))}
										widthClass={'minWidth220'}
										className={'top80 left-0'}
										removeTopOptions={true}
									/>
								: null}
							</div>
							<div className={`bg-BlockWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer lg:w-1/12 md:w-1/6 relative`}
								onClick={(event) => {
									event.preventDefault();
									handleChildClick(event, 'child', 'muliselectDropDown', "isRegionOpen")
								}}
							>
								<label className='my-1 flex'>Region</label>
								<p className={`flex justify-between mb-0 truncate ${state.selectedRegion && state.selectedRegion.length ? 'text-black' : 'text-lightGray'}`}>
									{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}
									<Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion && state.selectedRegion.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />
								</p>
								{state.isRegionOpen ?
									<MultiSelectSection 
										fields={["region", "name"]}
										options={state.regions}
										selectedValues={state.selectedRegion ? state.selectedRegion : []}
										callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, selectedRegion: value, callClusterList: true, callFilterIdentifiers: true }))}
										widthClass={'minWidth220'}
										className={'top80 left-0'}
										removeTopOptions={true}
									/>
								: null}
							</div>
							<div className={`bg-BlockWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer lg:w-1/12 md:w-1/6 h-[66px]`} ref={serviceRef}>
								<label className='mt-1 mb-0 flex'>Service</label>
								<div className={`serviceTreeDropdown mb-1 ${state.filteredServiceResourceArray.length ? '': 'pointer-events-none'}`}>
									<DropdownTreeSelect 
										//values={state.selectedServiceContent}
										texts={{ placeholder: state.selectedServiceContent }}
										data={state.filteredServiceResourceArray}
										onChange={onChangeServiceDropdown}
										className="bootstrap-demo"
										keepTreeOnSearch={true}
										keepChildrenOnSearch={true}
									/>
								</div>
							</div>
							<div className={`bg-BlockWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer lg:w-1/12 md:w-1/6 relative`} ref={tagRef}>
								<div className="" onClick={() => setState(prevState => ({ ...prevState, showTagFilterSection: true }))}>
									<label className='my-1 flex'>Tags</label>
									<p className={`flex justify-between mb-0 truncate ${state.selectedTags && state.selectedTags.length ? 'text-black' : 'text-lightGray'}`}>
										{state.selectedTags && state.selectedTags.length ? state.selectedTags.length+' Selected' : 'Select'}
										<Icon icon="icon-park-solid:down-one" className={`${state.selectedTags && state.selectedTags.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />
									</p>
								</div>
								{state.showTagFilterSection ?
									<div className="absolute top80 z-10 border-lightGray mt-1 w-[500px] right-0">
										<div className="p-3 bg-GhostWhite">
											<div className={`w-full mt-1`}>
												<p className="mb-2 w-100 text-black">Tags</p>
												<div className={`flex mt-3`}>
													<div className={`mr-3 w-2/5 relative`}
														onClick={(event) => {
															if(!state.isTagKey) {
																event.preventDefault();
																handleChildClick(event, 'child', 'singleDropDown', "isTagKey")
															}
														}}
													>
														<label className='my-1 flex'>Key</label>
														<p className={`border-b border-lightGray px-2 py-1 flex justify-between mb-0 truncate ${state.tag_key ? 'text-black' : 'text-lightGray'}`}>
															{state.tag_key ? state.tag_key : 'Select'}
															<Icon icon="icon-park-solid:down-one" className={`${state.tag_key ? "text-black" : "text-lightGray"}`} width="16" height="16" />
														</p>
														{state.isTagKey ?
															<MultiSelectSection
																options={tagKeys}
																selectedValues={state.tag_key ? state.tag_key : ''}
																callbackMultiSelect={(value) => {
																	if(!value || typeof(value) === 'string') {
																		setState(prevState => ({ ...prevState, tag_key: value,  tagValues: []}))
																	} else {
																		value.preventDefault()
																		handleChildClick(value, "search", 'singleDropDown', "")
																	}
																}}  
																singleSelection={true}
																hideSearch={false}
																className={'top80 left-0'}
																widthClass={'minWidth220'}
																removeTopOptions={true}
															/>
														: null}
													</div>
													{state.tag_key && state.tag_key !== "" && !state.tagValues.length ?
														<div className='ml-3'>
															<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
																<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
																<path d="M4 12a8 8 0 0112-6.9" />
															</svg>
														</div>
													:
														<React.Fragment>
															<div className={`mr-3 w-2/5 relative`}
																onClick={(event) => {
																	event.preventDefault();
																	handleChildClick(event, 'child', 'muliselectDropDown', "isTagValue")
																}}
															>
																<label className='my-1 flex'>Value</label>
																<p className={`border-b border-lightGray px-2 py-1 flex justify-between mb-0 truncate ${state.tag_value && state.tag_value.length ? 'text-black' : 'text-lightGray'}`}>
																	{state.tag_value && state.tag_value.length ? state.tag_value.length+' Selected' : 'Select'}
																	<Icon icon="icon-park-solid:down-one" className={`${state.tag_value && state.tag_value.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />
																</p>
																{state.isTagValue ?
																	<MultiSelectSection 
																		// fields={["account_id", "account_name"]}
																		className={'top80 left-0'}
																		removeTop={true}
																		options={state.tagValues}
																		selectedValues={state.tag_value ? state.tag_value : []}
																		callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, tag_value: value }))}
																		widthClass={'minWidth220'}
																		removeTopOptions={true}
																	/>
																: null}
															</div>
															<div className='w-1/5 self-center'>
																<Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 cursor-pointer' onClick={() => addNewTag("tags")} />
															</div>
														</React.Fragment>
													}
												</div>
											</div>
											{state.tagsSelected && state.tagsSelected.length ? 
												<div className='flex flex-wrap'>
													{state.tagsSelected.map((tag, index) => {
														return(
															<span key={'tt_'+index} className="flex border border-info rounded-full px-2 py-1 mr-2 mt-2 self-center">{tag.key +(tag.value ? " : "+tag.value : "")}
																<Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-black ml-2 self-center' onClick={ () => removeTagSection(tag) } />
															</span>
														)
													})}
												</div>
											: null}
											
											<div className="flex mt-3">
												<span className={`text-info mr-2 self-center cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState,  tagsSelected: [] }))}>Clear</span>
												<button className={`btn bg-info px-2 py-1 rounded-md cursor-pointer`} onClick={() => {
													setState(prevState => ({ ...prevState, showClusterFilterSection: false,  selectedCluster: state.clusterSelected, totalAssetsList: [], nextToken: "" , callFilterIdentifiers: true }))
													setState(prevState => ({ ...prevState, selectedTags: state.tagsSelected, changesInFilter: true, callFilterIdentifiers: true }))
												}}>Select</button>
											</div>
										</div>
									</div>
								: null}
							</div>
							<div className={`bg-BlockWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer lg:w-1/12 md:w-1/6 relative`} ref={clusterRef}>
								<div className="" onClick={() => setState(prevState => ({ ...prevState, showClusterFilterSection: true }))}>
									<label className='my-1 flex'>Cluster</label>
									<p className={`flex justify-between mb-0 truncate ${state.selectedCluster && state.selectedCluster.length ? 'text-black' : 'text-lightGray'}`}>
										{state.selectedCluster && state.selectedCluster.length ? state.selectedCluster.length+' Selected' : 'Select'}
										<Icon icon="icon-park-solid:down-one" className={`${state.selectedCluster && state.selectedCluster.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />
									</p>
								</div>
								{state.showClusterFilterSection ?
									<div className="absolute top80 z-10 border-lightGray mt-1 w-[500px] right-0">
										<div className="p-3 bg-GhostWhite">
											<div className={`w-full mt-1`}>
												<p className="mb-2 w-100 text-black">Type</p>
												<div className={`flex mt-3`}>
													<div className={`mr-3 w-2/5 relative`}
														onClick={(event) => {
															if(!state.isTypeOpen) {
																event.preventDefault();
																handleChildClick(event, 'child', 'singleDropDown', "isTypeOpen")
															}
														}}
													>
														{/* <label className='my-1 flex'>Key</label> */}
														<p className={`border-b border-lightGray px-2 py-1 flex justify-between mb-0 truncate ${state.clusterType ? 'text-black' : 'text-lightGray'}`}>
															{state.clusterType ? state.clusterList.filter(e => e.value === state.clusterType)[0].label : 'Select'}
															<Icon icon="icon-park-solid:down-one" className={`${state.clusterType ? "text-black" : "text-lightGray"}`} width="16" height="16" />
														</p>
														{state.isTypeOpen ?
															<MultiSelectSection
																fields={["value", "label"]}
																options={state.clusterList}
																widthClass={'minWidth220'}
																className={'top80 left-0'}
																removeTopOptions={true}
																selectedValues={state.clusterType ? state.clusterType : ''}
																callbackMultiSelect={(value) => {
																	if(!value || typeof(value) === 'string') {
																		setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, clusterType: value, clusterNames: [], clustersItems: [], callClusterNames: true }))
																	} else {
																		value.preventDefault()
																		handleChildClick(value, "search", 'singleDropDown', "")
																	}
																}}
																singleSelection={true}
															/>
														: null}
													</div>

													{state.callClusterNames ?
														<div className='ml-3'>
															<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
																<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
																<path d="M4 12a8 8 0 0112-6.9" />
															</svg>
														</div>
													:
														<React.Fragment>
														<div className={`mr-3 w-2/5 relative`}
															onClick={(event) => {
																event.preventDefault();
																handleChildClick(event, 'child', 'muliselectDropDown', "isTagValue")
															}}
														>
															{/* <label className='my-1 flex'>Value</label> */}
															<p className={`border-b border-lightGray px-2 py-1 flex justify-between mb-0 truncate ${state.clusterNames && state.clusterNames.length ? 'text-black' : 'text-lightGray'}`}>
																{state.clusterNames && state.clusterNames.length ? state.clusterNames.length+' Selected' : 'Select'}
																<Icon icon="icon-park-solid:down-one" className={`${state.clusterNames && state.clusterNames.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />
															</p>
															{state.isTagValue ?
																<MultiSelectSection 
																	fields={["cluster_name", "cluster_name"]}
																	className={'top80 left-0'}
																	options={state.clustersItems}
																	selectedValues={state.clusterNames ? state.clusterNames : []}
																	callbackMultiSelect={(value) => setState(prevState => ({ ...prevState,  totalAssetsList: [], filteredArray: [], nextToken: null, clusterNames: value }))}
																	widthClass={'minWidth220'}
																	removeTopOptions={true}
																/>
															: null}
														</div>
														<div className='w-1/5 self-center'>
															<Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 cursor-pointer' onClick={() => addNewCluster()} />
														</div>
														</React.Fragment>
													}
												</div>
											</div>
											
											{state.clusterSelected && state.clusterSelected.length ? 
												state.clusterSelected.map((item, index) => {
													return(
													<div className='flex flex-wrap' key={'clus_'+index}>
														{item.value && item.value.length ?
															item.value.map(nam => {
																return(
																	<span key={'clus_val_'+nam} className="flex border border-info rounded-full px-2 py-1 mr-2 mt-2 self-center">{item.key +" : "+nam}
																		<Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-black ml-2 self-center' onClick={ () => removeClusterValue(nam) } />
																	</span>
																)
															})
														:
															<span className="border border-info rounded-full px-2 py-1 mr-2 mt-2 self-center">{item.key}
																<Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-black ml-2 self-center' onClick={ () => removeClusterSection(item) } />
															</span>
														}
													</div>
													)
												})
											: null}
											<div className="flex mt-3">
												<span className={`text-info mr-2 self-center cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState,  clusterSelected: [], selectedCluster: [] }))}>Clear</span>
												<button className={`btn bg-info px-2 py-1 rounded-md cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, showClusterFilterSection: false,  selectedCluster: state.clusterSelected, totalAssetsList: [], nextToken: "" , callFilterIdentifiers: true }))}>Select</button>
											</div>
										</div>
									</div>
								: null}
							</div>
							<div ref={durationRef} className='lg:w-1/12 md:w-1/6'>
								<div className='bg-BlockWhite h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer'
									onClick={(event) => {
										if(!state.isDurationOpen) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isDurationOpen")
										}
								}}>
									<p className="text-xs text-black font-semibold">Duration</p>
									<p className={`flex justify-between text-sm font-medium mt-3`}>
										<span className={`truncate text-sm font-bold ${state.selectedDuration ? "text-black" : "text-lightGray"}`}>{state.selectedDuration}</span>
										<Icon icon="icon-park-solid:down-one" className={`${state.selectedDuration ? "text-black" : "text-lightGray"}`} width="16" height="16" />                        
									</p>
									{state.isDurationOpen ?
										<div className='relative'>
										<MultiSelectSection
											fields={["value", "option"]}
											options={CUSTOM_CURRENT_DURATION_FILTER_OPTIONS}
											widthClass={'minWidth220'}
											selectedValues={state.selectedDuration ? state.selectedDuration : ''}
											callbackMultiSelect={(value) => {
												if(value && typeof(value) === 'string') {
													if(value !== 'custom') {
														setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, selectedDuration: value,  relatedDurtaion: value,  showDatepicker: false,  selectedDatePicker: false,  durationType: '',  callFilterIdentifiers: true }))
													} else {
														setState(prevState => ({ ...prevState, selectedDuration: value, showDatepicker: true }))
													}
												} else {
													value.preventDefault()
													handleChildClick(value, "search", 'singleDropDown', "")
												}
											}}
											singleSelection={true}
											className={'right-0'}
											removeTopOptions={true}
										/>
										</div>
									: null}
								</div>
								<div className={`relative`} ref={dayPickerRef}>
									{state.showDatepicker ?
										<div className='absolute bg-white border border-lightGray z-10 right-0 text-xs'>
											<DayPicker
												mode={'range'}
												// toMonth={new Date()}
												disabled={[{
													after: new Date(),
													before: (new Date(state.previousCustomDays))
												}]}
												// className="Selectable"
												numberOfMonths= {1}																			
												selected={selected}
												// onSelect={setSelected}
												onSelect={handleSelect}
												// tabIndex={0}
											/>								
											<div className="flex mx-2 justify-end">
												<button className={`btn px-2 py-1 bg-info rounded-md mr-3 text-black mb-1 ${selected && selected.from && selected.to ? 'cursor-pointer' : 'pointer-events-none'}`} onClick={() => setState(prevState => ({ ...prevState, from: selected.from, to: selected.to, showDatepicker: false })) } >Submit</button>
												<p className="text-info mb-1 self-center" onClick={() => setSelected()}>Reset</p>
											</div>
										</div>
									: null}
								</div>
							</div>
						</div>
					</div>
							
					<div className='m-3 p-3 rounded-md border border-lightGray bg-white'>
						{/* custom-margin relative z-10 */}
						{state.categoryCounts && state.categoryCounts.length ? (
							<div>
								<ul ref={tabRef} className={`flex space-x-2 bg-backGroundGray text-black ${state.showLoading ? 'pointer-events-none' : ''}`} id='myTab' role='tablist'>
									{state.categoryCounts && getTotalCategoryCounts() !== 0 && state.categoryCounts.length ? 
										state.categoryCounts.map((item, index) => {
											if (index < state.tabItemsToShow && item.total_count !== 0)
												return(
													<li key={"cat_"+index} className='flex' onClick={() => getAllAssetsOnTabChange(item.category)}>
														<span className={`flex flex-col items-center justify-center p-2 text-black ${state.selectedTableTab === index ? 'bor' : ''}`}
															onClick={() => setState(prevState => ({ ...prevState, selectedTableTab: index }))}
														>
															{item.category}
															<span className='border border-lightGray px-3 py-1 rounded-full text-xs text-black d-table m-auto'>{item.total_count}</span>
														</span>
													</li>
												)
											return null
										})
									: null}
									{state.categoryCounts && state.categoryCounts.length >= state.tabItemsToShow ?
										<li className={`flex bd-highlight justify-end self-center relative`} ref={dropdownRef} style={{marginLeft: 'auto'}}>
											<Icon icon="icon-park-outline:down-square" className={`text-lightGray mr-2 cursor-pointer`} width={18} height={18} onClick={() => setState(prevState => ({ ...prevState, isDropdownOpen: !state.isDropdownOpen }))}/>

											{state.isDropdownOpen ?
												state.categoryCounts &&
													<div className="absolute top80 mt-2 bg-white rounded-md z-50 mr-5 shadow-lg">
														{state.categoryCounts.map((item, index) => {
															return(
																index >= 1 && item.total_count !== 0 ?
																	<p key={index} className="block px-10 py-2 text-base text-black cursor-pointer" onClick={() => onClickDropDownItem(item, index)}>
																		{item.category}
																	</p>
																: null
															)
														})}
													</div>
											: null}
										</li>
									: null}
								</ul>
							</div>
						) : null}
						{/* {!state.showSelectedFilters ? 
							<p className='mb-0 text-info self-center mx-3 pt-3 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, showSelectedFilters: true }))}>Selected Filters</p>
						: null} */}
						
						{!state.showLoading ?
							<div className="">
								<div className="flex flex-wrap mt-2">
									<div className="w-2/5 self-center text-black">
										<small>Showing {state.totalAssetsList && state.totalAssetsList.length} out of total {state.totalAssetsCount && state.totalAssetsCount} asset(s)</small> 
										{state.nextToken ?
											<small className='text-info ml-3 cursor-pointer' onClick={getNextSetoffRecords}>Next</small>
										: null}
									</div>
									<div className={`flex w-3/5 justify-end mb-n2`}>
										{!state.showSelectedFilters ? 
											<p className='mb-0 text-info self-center mr-3 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, showSelectedFilters: true }))}>Selected&nbsp;Filters</p>
										: null}											
										<CSVLink 
											data={state.filteredArray} 
											headers={state.headers ? state.headers : []}
											filename={"resource-search.csv"}
											className={"self-center"}
											target="_blank"
										>
											<Icon icon="material-symbols:download" className='text-gray5 mr-2 self-center cursor-pointer' width="22" height="22"/>
										</CSVLink>
										<div className='w-1/2'>
											{state.selectedCluster && state.selectedCluster.length ?
												<Search
													data={state.totalAssetsList && state.totalAssetsList}
													topClassName={'bg-transparent text-black border border-lightGray rounded-lg cursor-pointer'}
													searchClassName={'px-1'}
													searchIconColor={'text-lightGray'}
													searchPlaceHolder={'Search....'}
													className={'bg-transparent text-black'}
													widthHeight={25}
													filteredData={filteredArray => {
														if (filteredArray.length) {
															setState(prevState => ({ ...prevState, filteredArray, selectedTableRow: filteredArray[0] }))
														} else {
															setState(prevState => ({ ...prevState, filteredArray: [], selectedTableRow: {} }))
														}
													}}
												/>
											:
												<div className='flex border border-lightGray rounded-md'>
													<Icon icon={`bitcoin-icons:search-outline`} className={`text-black w-5 self-center`} width={25} height={25} />
													<div className={`w-11/12`}>
														<input
															value={state.searchText}
															onChange={e => {
																setState(prevState => ({ ...prevState, searchText: e.target.value }))
																clearTimeout(searchAssetInventory)
																if(e.target.value.length > 2) {
																	searchAssetInventory = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, nextToken: null,  totalAssetsList: [], filteredArray: [], showLoading: true, callFilterIdentifiers: true }))}, 1000);
																} else if(!e.target.value.length) {
																	setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, showLoading: true, callFilterIdentifiers: true }))
																}
															}}
															type='text'
															className={`bg-transparent text-black text-md pl-1 py-1 w-full`}
															placeholder='Search'
														/>
													</div>
												</div>
											}
										</div>
									</div>
									
									{state.showSelectedFilters ?
										<div className='w-full mt-2 flex justify-center mb-2'>
											<div className="bg-darkGray p-3 w-4/5">
												<div className='flex justify-between'>
													<p className="mb-2 w-full text-black">Selected Filters</p>
													<div onClick={() => setState(prevState => ({ ...prevState, showSelectedFilters: false }))}>
														<Icon icon="ion:close-circle-sharp" width="18" height="18"  className='text-black self-center cursor-pointer' />
													</div>
												</div>
												<div className='flex flex-wrap'>
													{state.selectedAccount && state.selectedAccount.length ?
														state.selectedAccount.map(row => {
															return(
																<span key={"acc_"+row} className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																	<span className='text-lightGray'>Account : </span>
																	{getAccountNameFromId(row, state.accounts)}
																</span>

															)
														})
													: 
														<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
															<span className='text-lightGray'>Account :</span>
															All
														</span>
													}
													{state.selectedRegion && state.selectedRegion.length ?
														state.selectedRegion.map(row => {
															return(
																<span key={"reg_"+row} className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																	<span className='text-lightGray'>Region :</span> 
																	{getRegionName(row, state.regions)}
																</span>

															)
														})
													: 
														<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
															<span className='text-lightGray'>Region :</span>
															All
														</span>
													}
													{state.selectedService && state.selectedService.length ?
														state.selectedService.map(row => {
															return(
																<span key={"ser_"+row} className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																	<span className='text-lightGray'>Service : </span> 
																	{row}
																</span>
															)
														})
													: 
														<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
															<span className='text-lightGray'>Service : </span> 
															All
														</span>
													}
													{state.selectedCategory && Array.isArray(state.selectedCategory) && state.selectedCategory.length && !state.selectedCategory.includes('All') ?
														state.selectedCategory.map(row => {
															return(
																<span key={"catss_"+row} className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																	<span className='text-lightGray'>Category : </span> 
																	{row}
																</span>
															)
														})
													: 
														<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
															<span className='text-lightGray'>Service : </span> 
															All
														</span>
													}
													{state.selectedTags && state.selectedTags.length ?
														state.selectedTags.map((row, tagIndex) => {
															return(
																<span key={"tagg_"+tagIndex} className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																	<span className='text-lightGray'>Tag :</span> 
																	{row.key +" : "+ row.value}
																</span>
															)
														})
													: null}
													{state.selectedCluster && state.selectedCluster.length ?
														<div className='flex mr-2'>
															<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																<span className='text-lightGray'>Cluster :</span>
																{state.selectedCluster[0].key +" : "+ state.selectedCluster[0].value}
															</span>
														</div>
													: null}
													
													{state.selectedAdvancedSearch && Object.entries(state.selectedAdvancedSearch).length ?
														<React.Fragment>
														{state.selectedAdvancedSearch.hasOwnProperty("event_name") ?
															<React.Fragment>
															{state.selectedAdvancedSearch.event_name.include ?
																state.selectedAdvancedSearch.event_name.include.map(evt => {
																	return(
																		<div key={"adv_"+evt} className='flex mr-2'>
																			<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																				<span className='text-lightGray'>Event : include :</span>
																				{evt}
																			</span>
																		</div>
																	)
																})
															: null}
															{state.selectedAdvancedSearch.event_name.exclude ?
																state.selectedAdvancedSearch.event_name.exclude.map(evt => {
																	return(
																		<div key={"ext_"+evt} className='flex mr-2'>
																			<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																				<span className='text-lightGray'>Event : exclude :</span>
																				{evt}
																			</span>
																		</div>
																	)
																})
															: null}
															</React.Fragment>
														: null}
														{state.selectedAdvancedSearch.hasOwnProperty("users") ?
															state.selectedAdvancedSearch.users.map(usr => {
																return(
																	<div key={"usr_"+usr} className='flex mr-2'>
																		<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																			<span className='text-lightGray'>Accessed By :</span>
																			{usr}
																		</span>
																	</div>
																)
															})
														: null}
														{state.selectedAdvancedSearch.hasOwnProperty("ip") ?
															<React.Fragment>
															{state.selectedAdvancedSearch.ip.include ?
																state.selectedAdvancedSearch.ip.include.map(evt => {
																	return(
																		<div key={"ip_"+evt} className='flex mr-2'>
																			<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																				<span className='text-lightGray'>IP : include :</span>
																				{evt}
																			</span>
																		</div>
																	)
																})
															: null}
															{state.selectedAdvancedSearch.ip.exclude ?
																state.selectedAdvancedSearch.ip.exclude.map(evt => {
																	return(
																		<div key={"adexc_"+evt} className='flex mr-2'>
																			<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																				<span className='text-lightGray'>IP : exclude :</span>
																				{evt}
																			</span>
																		</div>
																	)
																})
															: null}
															</React.Fragment>
														: null}
														{state.selectedAdvancedSearch.hasOwnProperty("instance_type") ?
															state.selectedAdvancedSearch.instance_type.map(inst => {
																return(
																	inst.instance_type.map(typ => {
																		return(
																		<div key={"typ_"+typ} className='flex mr-2'>
																			<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																				<span className='text-lightGray'>{inst.resource_type} :</span>
																				{typ}
																			</span>
																		</div>
																		)
																	})
																)
															})
														: null}
														{state.selectedAdvancedSearch.hasOwnProperty("vpc_id") ?
															<React.Fragment>
															{state.selectedAdvancedSearch.vpc_id.include ?
																state.selectedAdvancedSearch.vpc_id.include.map(evt => {
																	return(
																		<div key={"incss_"+evt} className='flex mr-2'>
																			<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																				<span className='text-lightGray'>Network : include :</span>
																				{evt}
																			</span>
																		</div>
																	)
																})
															: null}
															{state.selectedAdvancedSearch.vpc_id.exclude ?
																state.selectedAdvancedSearch.vpc_id.exclude.map(evt => {
																	return(
																		<div key={"excvpc_"+evt} className='flex mr-2'>
																			<span className="border border-info py-1 rounded-full mr-2 mt-2 self-center px-2 text-info">
																				<span className='text-lightGray'>Network : exclude :</span>
																				{evt}
																			</span>
																		</div>
																	)
																})
															: null}
															</React.Fragment>
														: null}
														</React.Fragment>
													: null}
												</div>
												{state.selectedFilters && state.selectedFilters.length ? 
													<p className="text-info mb-0 font-semibold self-center cursor-pointer mr-2 ml-4" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: false }))}>Hide Filters</p>
												: null}
											</div>
										</div>
									: null}
								</div>
								
								<div className='mt-1'>
									{state.filteredArray && state.filteredArray.length && !state.showLoading ? (
										<ResizeableDarkThemeTable
											columns={state.dynamicHeaders}											
											data={state.filteredArray}
											perPage={20}
											tableHead={'bg-lightGray text-black'}
											tableBody={'bg-white'}
											tableType={'table-light-hover'}
											perPageClass={`bg-transparent text-lightGray`}
											textColor={'text-black'}
											selectedColor={'bg-backGroundGray'}
											riskTooltip={[0]}
											rounded={true}
											selectedRecord={state.selectedTableRow}
											onClickRow={tableRow => {
												setState(prevState => ({ ...prevState, selectedTableRow: tableRow, selectedMonitoringDuration: '2h', selectedDetailsTab: 0, selectedTab: 'asset_details', relatedDurtaion: state.selectedDuration }));														
												getAssetDetailsFunction(tableRow)
											}}
											dashboard={state.filteredArray && state.filteredArray.length ? true : false}
										/>
									) : (!state.showLoading && state.filteredArray && !state.filteredArray.length) ||
										(state.filteredArray && !state.filteredArray.length && !state.showLoading) ? (
										<div className='flex justify-center m-4 text-black'>
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									) : null}
								</div>
							</div>
						: state.showLoading ? (
							<div className='flex justify-center m-4'>
								<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
									<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
									<path d="M4 12a8 8 0 0112-6.9" />
								</svg>
							</div>
						) : null}
					</div>
				</div>
				<div className={state.filteredArray && state.filteredArray.length  ? 'lg:w-3/12 w-full' : 'hidden'}>
					<div className={ state.filteredArray && state.filteredArray.length && state.selectedTableRow &&
							Object.entries(state.selectedTableRow).length && !state.showConfigureSection ? 'detail' : 'hidden'
						}
					>
						<div className='bg-BlockWhite'>
							<div className='flex'>
								<div className="w-16 h-16 bg-lightGray rounded-md overflow-hidden my-auto mx-2">
									<div className="h-1/2 bg-white flex items-center justify-center">&nbsp;</div>
									<div className="h-1/2 bg-info flex items-center justify-center">
										<span className="text-black">Health</span>
									</div>
								</div>
								<div className='self-center text-black'>
									<table>
										<tbody>
											<tr>
												<td className='text-right pr-2 top'>{state.selectedTableRow.provider ? state.selectedTableRow.provider.toUpperCase() : ''}&nbsp;: </td>
												<td>
													{state.selectedTableRow.region === 'NA' ? 
														'Global' 
													: state.regions && state.selectedTableRow && state.selectedTableRow.region ? 
														getRegionName(state.selectedTableRow.region, state.regions)
													: 
														state.selectedTableRow && state.selectedTableRow.region
													}
												</td>
											</tr>
											<tr>
												<td className='text-right pr-2 top'>Account&nbsp;: </td>
												<td>
													{state.accounts && state.selectedTableRow && state.selectedTableRow.account_id ? 
														getAccountNameFromId(state.selectedTableRow.account_id, state.accounts)
													: 
														state.selectedTableRow && state.selectedTableRow.account_id
													}
												</td>
											</tr>
											<tr>
												<td className='text-right pr-2 top'>Resource&nbsp;: </td>
												<td>{state.selectedTableRow.resource_type}</td>
											</tr>
											<tr>
												<td className='text-right pr-2 top'>Service&nbsp;: </td>
												<td>{state.selectedTableRow.service_name}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<span className='badge badge-pill badge-secondary p-2 mt-2 text-break text-wrap text-black font-weight-bold'>
								{state.selectedTableRow && state.selectedTableRow.asset_name}
							</span>
						</div>
						<div className='detail-content bg-backGroundGray'>
							{state.showDetailsSection ? (
								<DetailsSection
									selectedTableRow={state.selectedTableRow}
									selectedTab={state.selectedTab}
									detailsTabNames={state.detailsTabNames}
									detailsTabItemsToShow={state.detailsTabItemsToShow}
									showDetailsTabListDropdown={state.showDetailsTabListDropdown}
									selectedDetailsTab={state.selectedDetailsTab}
									selectedRowAssetDetails={state.selectedRowAssetDetails}
									selectedRowMonitoringSnapshot={state.selectedRowMonitoringSnapshot}
									selectedRowBillingDetails={state.selectedRowBillingDetails}
									showDetailsAssetSectionLoading={state.showDetailsAssetSectionLoading}
									showDetailsRelatedIssueLoading={state.showDetailsRelatedIssueLoading}
									showDetailsBillingSectionLoading={state.showDetailsBillingSectionLoading}
									showDetailsMonitoringSectionLoading={state.showDetailsMonitoringSectionLoading}
									showDetailsEventsSectionLoading={state.showDetailsEventsSectionLoading}
									toggleDetailsSectionLoading={(from, bool) => handleLoadingToggle(from, bool)}
									getAssetDetails={item => getAssetDetailsFunction(item)}
									openCloseModal={(item, relatedIssueDurtaion) => openCloseModal(item, relatedIssueDurtaion)}
									getAssetEvents={(item, duration) => getAssetEvents(item, duration)}
									eventSeries={state.eventSeries}
									eventOptions={state.eventOptions}
									dayWiseCountGraphData={state.dayWiseCountGraphData}
									recentSeries = {state.recentSeries}
									recentOptions = {state.recentOptions}
									setSelectedDetailstabIndex={index => setState(prevState => ({ ...prevState, selectedDetailsTab: index }))}
									relatedIssuesData={state.relatedIssuesData}
									filteredRelatedIssuesData={state.filteredRelatedIssuesData}
									getRelatedTableData={(category, selectedResourceStatus, status) => getRelatedTableData(category, selectedResourceStatus, status)}
									topEventsData={state.topEventsData}
									recentEventsData={state.recentEventsData}
									eventGraphData={state.eventCountData}
									relatedDurtaion={state.relatedDurtaion}
									categoryCounts={state.categoryCounts}
								/>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LandingPage
