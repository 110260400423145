/*************************************************
 * Collider
 * @exports
 * @file CreateProviderAllcoation.js
 * @author Prakash // on 13/09/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback} from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import MultiSelectSection from '../../common/MultiSelectSection';
import { getCloudProviderBudget, insertCloudProviderBudget } from '../../../actions/cost/BudgetAction';
import { Store as CommonNotification } from 'react-notifications-component';
import { capitalizeAllLetter, onlyNumeric } from '../../../utils/utility';
import {Icon} from "@iconify/react";

const CreateProviderAllcoation = (props) => {
	const[state, setState] = useState({
	})
	
	const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);        
    }, [handleKeyDown]);

	useEffect(() => {
		const currentYear = new Date().getUTCFullYear();
        // Calculate the next 10 years
        const next10Years = Array.from({ length: 11 }, (_, index) => currentYear + index);
        setState(prevState => ({ ...prevState, yearOptions: next10Years }))
	}, [])
	
	
	// useEffect(() => {
	// 	if(state.callGetCostTotalCount) {

	// 		let params = {}
	// 		if(state.selectedDetails.region) {
	// 			params.region = state.selectedDetails.region
	// 		}
	// 		if(state.selectedDetails.tags && state.selectedDetails.tags.length) {
	// 			params.tags = state.selectedDetails.tags
	// 		}
	// 		params.start_time = state.selectedTableRecord.start_time
	// 		params.end_time = state.selectedTableRecord.end_time
	// 		params.period = state.selectedPeriod
	// 		params.radial = false
	// 		// params.top = 4
	
	// 		let aggregateBy = ["resource_type"]
	// 		if(state.selectedGroupBy !== "Service") {
	// 			aggregateBy = ["operation"]
	// 		}
	// 		params.aggregate_by = aggregateBy
			
	// 		dispatch(getCostTotalCount(params))
	// 			.then((response) => {
	// 				if(response) {
	// 					setState(prevState => ({ ...prevState, costTotalCount: response, callGetDonutGraphDetails: true }))
	// 				}					
	// 			})
	// 	}
	// }, [state.callGetCostTotalCount, dispatch, state.selectedDetails.account_id, state.selectedDetails.provider, state.selectedDetails.region, state.selectedDetails.tags, state.selectedGroupBy, state.selectedPeriod, state.selectedTableRecord.end_time, state.selectedTableRecord.start_time])

	const handleChildClick = (event, type, dropdownType, section) => {
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	const onChangeGetProviderAllocation = (year) => {
		let params = {
			year: year
		}

		dispatch(getCloudProviderBudget(params))
			.then((response) => {
				if(response && !response.error) {
					let results = response.results ? response.results : []
					if(results && results.length) {
						results.forEach(row => {
							setState(prevState => ({ ...prevState, [row.cloudProviderId]: row.allocation ? row.allocation : '', showLoading: false }))
						})
					}
					setState(prevState => ({ ...prevState, allocationDetails: results, showLoading: false }))
				} else {
					setState(prevState => ({ ...prevState, allocationDetails: [], showLoading: false }))
				}
			})
	}

	const onChangeHandleValidation = () => {
		let params = {}
		let hasError = false
		if(!state.year) {
			hasError = true
		}
		params.year = state.year
		let providers = []
		if(state.allocationDetails && state.allocationDetails.length){
			state.allocationDetails.forEach(row => {
				if(!state[row.cloudProviderId]) {
					hasError = true
				} else {
					row.allocation = parseInt(state[row.cloudProviderId])
					providers.push(row)
				}
			})
		}

		params.providers = providers

		if(!hasError) {
			dispatch(insertCloudProviderBudget(params))
				.then((response) => {
					setState(prevState => ({ ...prevState, onSaveFunction: false}))
					let messageType = 'danger'
					let message = response.message
					if(response) {
                        // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
						if(response.status) {
							messageType = 'success'
							props.refreshApi()
						}
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
					}
				})
		}
	}
	

	return (
		<div className="advanced-search" onClick={(event) => handleChildClick(event, 'parent')}>
			<div className="search-content bg-GhostWhite lg:w-2/5 md:w-3/5 w-4/5">
				<div className="header-search bd-highlight flex justify-between">
					<div className="flex justify-between flex-fill bg-black py-2">
						<h5 className='self-center text-black ml-2 py-1'>Provider Allocations</h5>
						<div className="text-right text-black flex justify-end flex-fill bd-highlight self-center f16">
							<i className={`far fa-times cursorPointer ${state.onSaveFunction ? "disbaled" : ""}`} onClick={() => props.closeSidePanel()}></i>
						</div>
					</div>
				</div>
				<div className="m-2 p-2 rounded bg-white w-100">
					<div className="flex mb-3">
						<div className="py-1 w-2/5 pl-3 flex">
							<p className="mb-0 text-lightGray w-1/4 self-center">Year</p>
							<div className={`w-3/4 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
								event.preventDefault();
								handleChildClick(event, 'child', 'singleDropDown', "showYear")
							}}>
								<p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${state.year ? 'text-black' : 'text-lightGray'}`}>
									{state.year ? state.year : 'Select'}
									<Icon icon="icon-park-solid:down-one" className={`${state.year? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
								</p>
								{state.showYear ?
									<div className='position-relative'>
										<MultiSelectSection
											options={state.yearOptions}
											selectedValues={state.year ? state.year : ''}
											callbackMultiSelect={(value) => {
												setState(prevState => ({ ...prevState, year: value, showLoading: true }))
												onChangeGetProviderAllocation(value)
											}}
											singleSelection={true}
											removeTopOptions={true}
											widthClass={'minWidth220'}
										/>
									</div>
								: null}
							</div>
						</div>
					</div>
					{!state.showLoading ? 
						state.year && state.allocationDetails && state.allocationDetails.length ?
							state.allocationDetails.map(row => {
								return(
									<div key={row.cloudProviderId} className="flex mb-3">
										<div className="py-1 w-2/5 pl-3 flex">
											<p className="mb-0 text-lightGray w-1/4 self-center">{row.cloudProviderId ? capitalizeAllLetter(row.cloudProviderId) : ''}</p>
											<input
												type="text"
												placeholder="Enter allocation"
												className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-3/4`}
												value={state[row.cloudProviderId]}
												onChange={e => setState(prevState => ({ ...prevState, [row.cloudProviderId]: onlyNumeric(e.target.value)}))}
											/>
										</div>
										<p className="text-black mb-0 ml-2 self-center">USD</p>
									</div>
								)
							})
						: null
					:
						<div className='flex justify-center m-4'>
							<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
								<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
								<path d="M4 12a8 8 0 0112-6.9" />
							</svg>
						</div>
					}

					<div className='mt-4 flex justify-end'>
						<div className='self-start'>
							{state.onSaveFunction ?
								<button className={`btn bg-info px-2 py-1 rounded-md text-white mr-2`}>
									<svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
										<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
										<path d="M4 12a8 8 0 0112-6.9" />
									</svg>
									{state.pageType !== "Edit" ?  "Saving"  : "Updating"}
								</button>
							:
								<div className='flex'>
									{state.year ?
										<button className={`btn bg-info px-2 py-1 rounded-md text-white mr-2`} 
											onClick={() => {
												setState(prevState => ({ ...prevState, onSaveFunction: true }))
												onChangeHandleValidation('save')
											}}>
											{state.pageType !== "Edit" ?  "Save"  : "Update"}
										</button>
									: null}
									<button className={`btn bg-lightGray px-2 py-1 rounded-md text-white mr-2`} onClick={() => props.closeSidePanel()}> Cancel </button>
								</div>
							} 
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CreateProviderAllcoation