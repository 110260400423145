/*************************************************
 * Collider
 * @exports
 * @file CreateProviderAllcoation.js
 * @author Prakash // on 13/09/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback} from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { getCloudProviderBudget, insertCloudProviderBudget } from '../../../actions/cost/BudgetAction';
import { Store as CommonNotification } from 'react-notifications-component';
import { capitalizeAllLetter, onlyNumeric } from '../../../utils/utility';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import SelectOption from '../../common/Form/SelectOption';
import { LoadingCircle } from '../../common/LoadingCiricle';
import Textbox from '../../common/Form/Textbox';
import Button from '../../common/Form/Button';

const CreateProviderAllcoation = (props) => {
	const[state, setState] = useState({
	})
	
	const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);        
    }, [handleKeyDown]);

	useEffect(() => {
		const currentYear = new Date().getUTCFullYear();
        // Calculate the next 10 years
        const next10Years = Array.from({ length: 11 }, (_, index) => currentYear + index);
        setState(prevState => ({ ...prevState, yearOptions: next10Years }))
	}, [])
	
	
	// useEffect(() => {
	// 	if(state.callGetCostTotalCount) {

	// 		let params = {}
	// 		if(state.selectedDetails.region) {
	// 			params.region = state.selectedDetails.region
	// 		}
	// 		if(state.selectedDetails.tags && state.selectedDetails.tags.length) {
	// 			params.tags = state.selectedDetails.tags
	// 		}
	// 		params.start_time = state.selectedTableRecord.start_time
	// 		params.end_time = state.selectedTableRecord.end_time
	// 		params.period = state.selectedPeriod
	// 		params.radial = false
	// 		// params.top = 4
	
	// 		let aggregateBy = ["resource_type"]
	// 		if(state.selectedGroupBy !== "Service") {
	// 			aggregateBy = ["operation"]
	// 		}
	// 		params.aggregate_by = aggregateBy
			
	// 		dispatch(getCostTotalCount(params))
	// 			.then((response) => {
	// 				if(response) {
	// 					setState(prevState => ({ ...prevState, costTotalCount: response, callGetDonutGraphDetails: true }))
	// 				}					
	// 			})
	// 	}
	// }, [state.callGetCostTotalCount, dispatch, state.selectedDetails.account_id, state.selectedDetails.provider, state.selectedDetails.region, state.selectedDetails.tags, state.selectedGroupBy, state.selectedPeriod, state.selectedTableRecord.end_time, state.selectedTableRecord.start_time])

	const handleChildClick = (event, type, dropdownType, section) => {
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	const onChangeGetProviderAllocation = (year) => {
		let params = {
			year: year
		}

		dispatch(getCloudProviderBudget(params))
			.then((response) => {
				if(response && !response.error) {
					let results = response.results ? response.results : []
					if(results && results.length) {
						results.forEach(row => {
							setState(prevState => ({ ...prevState, [row.cloudProviderId]: row.allocation ? row.allocation : '', showLoading: false }))
						})
					}
					setState(prevState => ({ ...prevState, allocationDetails: results, showLoading: false }))
				} else {
					setState(prevState => ({ ...prevState, allocationDetails: [], showLoading: false }))
				}
			})
	}

	const onChangeHandleValidation = () => {
		let params = {}
		let hasError = false
		if(!state.year) {
			hasError = true
		}
		params.year = state.year
		let providers = []
		if(state.allocationDetails && state.allocationDetails.length){
			state.allocationDetails.forEach(row => {
				if(!state[row.cloudProviderId]) {
					hasError = true
				} else {
					row.allocation = parseInt(state[row.cloudProviderId])
					providers.push(row)
				}
			})
		}

		params.providers = providers

		if(!hasError) {
			dispatch(insertCloudProviderBudget(params))
				.then((response) => {
					setState(prevState => ({ ...prevState, onSaveFunction: false}))
					let messageType = 'danger'
					let message = response.message
					if(response) {
                        // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
						if(response.status) {
							messageType = 'success'
							props.refreshApi()
						}
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
					}
				})
		}
	}
	

	return (
		<div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
				<div className='h-full flex flex-col bg-white lg:w-3/5 w-10/12'>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl'>Provider Allocations</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className={`text-black ${state.onSaveFunction ? "pointer-events-none" : "cursor-pointer"} self-center`} width={24} height={24} onClick={() => props.closeSidePanel()} />
                            </div>
                        </div>
					</div>
					<div className='overflow-y-auto h-[calc(100vh-120px)] bg-white'>
                        <div className='p-8 h-full'>
							<div className="w-2/5">
								<SelectOption
									label={"Year"}
									options={state.yearOptions}
									selectedValues={state.year ? state.year : ''}
									callbackMultiSelect={(value) => {
										setState(prevState => ({ ...prevState, year: value, showLoading: true }))
										onChangeGetProviderAllocation(value)
									}}
									singleSelection={true}
									dropdownWidth={'min-w-32'}
									classDetails={{border: "border border-NeutralGray-400", padding: "py-2.5 pr-4 pl-3", bg: ' ', rounded: 'rounded-lg' }}
								/>
							</div>
							{!state.showLoading ? 
								state.year && state.allocationDetails && state.allocationDetails.length ?
									state.allocationDetails.map(row => {
										return(
											<div key={row.cloudProviderId} className="flex pt-4">
												<div className="py-1 w-2/5 flex">
													<Textbox
														label={capitalizeAllLetter(row.cloudProviderId)}
														selectedValue={state[row.cloudProviderId] ? state[row.cloudProviderId] : ''}
														callback={(value) => {
															setState(prevState => ({ ...prevState, [row.cloudProviderId]: onlyNumeric(value)}))
														}}
														inputType={state.pageType}
														manditory={true}
													/>
												</div>
												<p className="text-black ml-3 pt-6 text-sm font-bold self-center">USD</p>
											</div>
										)
									})
								: null
							:
								<div className='flex justify-center m-4'>
									<LoadingCircle />
								</div>
							}
						</div>
					</div>
					<div className="w-full p-8 shadow-lg bg-NeutralGray-700">
						<div className='self-start flex justify-center'>
							<Button
								classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer'}}
								label={'Cancel'}
								callback={() => props.closeSidePanel()}
							/>
							{state.onSaveFunction ?
								<Button
									classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36' }}
									loading={true}
									label={state.pageType === 'Edit' ? 'Updating' : 'Saving'}
								/>
							:
								<div className='flex'>
									{state.year ?
										<Button
											classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36' }}
											label={state.pageType === 'Edit' ? 'Update' : 'Save'}
											callback={() => {
												setState(prevState => ({ ...prevState, onSaveFunction: true }))
												onChangeHandleValidation('save')
											}}
										/>
									: null}
								</div>
							} 
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CreateProviderAllcoation