/*************************************************
 * Collider
 * @exports
 * @file index.js
 * @author Rajasekar // on 19/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import { combineReducers } from 'redux'
import { filtersReducer } from './commonReducer'
import { assetsReducer } from './assets/assetsReducer'
import { governanceReducer } from './governance/governanceReducer'
import { detectorsReducer } from './detectors/detectorsReducer'
import { suppressorsReducer } from './detectors/suppressorsReducer'
import { identifiersReducer } from './detectors/configurationsReducer'
import { observabilityReducer } from './observabilityReducer'
import { costReducer } from './cost/costReducer'
import { hpcReducer } from './hpcReducer'

// import { clusterReducer } from './clusterReducer'
// import { detectorsReducer } from './detectors/detectorsReducer'
// import { authenticationReducer } from './signInReducer'
// import { iamReducer } from './governance/IamReducer'
// import { dlpReducer } from './dlp/dlpReducer'
// import { aiopsReducer } from './aiops/aiopsReducer'

/**
 * Method to combine all reducers used in the application
 */
export default combineReducers({
	// authentication: authenticationReducer,
	// iam: iamReducer,
	// dlp: dlpReducer,
	// aiops: aiopsReducer,
	// clusters: clusterReducer,
	suppressors: suppressorsReducer,
	identifiers: identifiersReducer,
	detectors: detectorsReducer,
	filters: filtersReducer,
	assets: assetsReducer,
	governance: governanceReducer,
	observability: observabilityReducer,
	cost: costReducer,
	hpc: hpcReducer
})
