/*************************************************
 * Tvastar
 * @exports
 * @file RdsServiceEnginesTemplate.js  //copy of query list from the observability Events QueryDetail page
 * @author Prakash // on 06/04/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {Icon} from "@iconify/react";
import MultiSelectSection from '../../common/MultiSelectSection'

import { setGovernancePropsDetails, setNewEditRuleDetails } from '../../../actions/governance/GovernanceAction'
import { DynamicApiToDevCall } from '../../../actions/commonActionNew'
import { onlyNumeric } from '../../../utils/utility'

const RdsServiceEnginesTemplate = (props) => {
	const [state, setState] = useState({
		onLoad: true,
		policyArray: [0],
		engionVersionType: ["Latest", "Specific"],

		hasErrorInRuleForm: false,
		hasError: props.hasError,
		timeTypeOptions: [
			{value: "h", label: "Hour(s)"},
			{value: "m", label: "Minute(s)"}
		],
		time_type_0: "h"
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const editNewRuleDetails = useSelector(state => state?.governance?.editNewRuleDetails)
	const onClickFinish = useSelector(state => state?.governance?.governancePropsDetails?.onClickFinish||false)

	const apiToCall = useCallback((apiDetails) => {
		apiDetails.forEach(item => {
			let params = {}
	
			let valueRequest =  item.body && item.body.values
			valueRequest && valueRequest.forEach(req => {
				if(req.value) {
					params[req.key] = req.value
				} else if(req.value_object) {
					let valuObj = {}
					req.value_object.forEach(subReq => {
						valuObj[subReq.key] = subReq.value
					})
					params[req.key] = valuObj
				}
			})
	
			let url = item.name
			let API_END_POINT = item.api
			let apiMethod = item.method
			
			dispatch(DynamicApiToDevCall(API_END_POINT, apiMethod, url, params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, engineDetails: response.length ? response[0].results : [], loadingConditionPage: false }))
						if(props.viewMode) {
							setState(prevState => ({ ...prevState, savedData: [props.masterData], callSavedData: true }))
						} else {
							if(editNewRuleDetails && editNewRuleDetails.policies) {
								if(editNewRuleDetails.policies.filter(e => e.rule_category === props.masterData.rule_category).length) {						
									let savedData = editNewRuleDetails.policies.filter(e => e.rule_id === props.masterData.rule_id)
									if(savedData.length) {
										setState(prevState => ({ ...prevState, savedData, callSavedData: true }))
									}
								}
							}
						}
					} else {
						setState(prevState => ({ ...prevState, engineDetails: [], loadingConditionPage: false }))
					}
				})
		})
	}, [dispatch, editNewRuleDetails, props.masterData, props.viewMode])

	useEffect(() => {
		if(state.onLoad) {
			setState(prevState => ({ ...prevState, onLoad: false }))
			let masterData = props.masterData
			let actionDetails = []
			if(masterData.actions && masterData.actions.length) {
				actionDetails = masterData.actions
			}
			
			let variables = masterData.variables
			variables.forEach(item => {
				if(item.options && (item.component === "select" || item.component === "multiselect")) {
					setState(prevState => ({ ...prevState, [item.variable+"_options"]: item.options, [item.variable+"_0"]: item.default ? item.default : "" }))
				} else {
					setState(prevState => ({ ...prevState, apiCallVariable: item.variable, [item.variable+"_0"]: item.default ? item.default : "" }))
				}

				setState(prevState => ({ ...prevState, ["mode_"+item.variable]: item.mode ? item.mode : "" }))
			})
			setState(prevState => ({ ...prevState, tags: masterData.tags, description: masterData.description ? masterData.description : "", actionDetails }))
			
			if(masterData.api_to_call) {
				apiToCall(masterData.api_to_call)
			} else if(props.viewMode) {
				setState(prevState => ({ ...prevState, savedData: [props.masterData], callSavedData: true }))
			}
		}
	}, [state.onLoad, apiToCall, editNewRuleDetails, props.masterData, props.viewMode])

	const filterEngineDetails = useCallback((engineType, item) => {
		let selectedEngineDetails = state.engineDetails.filter(e => e.name === engineType)

		setState(prevState => ({ ...prevState, ["selectedEngineDetails_"+item]: selectedEngineDetails[0] }))
	}, [state.engineDetails])

	useEffect(() => {
		if(state.callSavedData) {
			setState(prevState => ({ ...prevState, callSavedData: false }))
			let savedData = state.savedData
			let policyArray = []
			savedData.forEach((row, index) => {
				policyArray.push(index)
				setState(prevState => ({ ...prevState, ["policy_id_"+index]: row.policy_id }))
				if(row.action && Object.entries(row.action)) {
					Object.entries(row.action).forEach(([key, value]) => {
						if(key === "schedule") {
							let timeSplit = value.substring(0, value.length - 1)
							let timeTypeSplit = value.substring(value.length - 1, value.length)
							if(timeTypeSplit === "h" || timeTypeSplit === "m") {
								setState(prevState => ({ ...prevState, ["time_type_"+index]: timeTypeSplit, [key+"_"+index]: timeSplit }))
							} else {
								setState(prevState => ({ ...prevState, [key+"_"+index]: value, ["time_type_"+index]: "" }))
							}
						} else {
							if(key === "name") {
								key = "selected_action"
								setState(prevState => ({ ...prevState, ["action_processor_rule_id_"+index]: state.actionDetails.filter(e => e.action === value) && state.actionDetails.filter(e => e.action === value).length && state.actionDetails.filter(e => e.action === value)[0].processor_rule_id ? state.actionDetails.filter(e => e.action === value)[0].processor_rule_id : "" }))
							}
							setState(prevState => ({ ...prevState, [key+"_"+index]: value }))
						}
					})
				}
				row.variables.forEach(item => {
					
					setState(prevState => ({ ...prevState, [item.variable+"_"+index]: item.value_list ? item.value_list : item.value, inputDetected: true, "show_child_policy_0": true }))

					if(item.variable === "engine_type" && !props.viewMode) {
						filterEngineDetails(item.value_list ? item.value_list : item.value, index)
					}
				})

				if(row.enabled) {
					setState(prevState => ({ ...prevState, ["enabled_"+index]: true }))
				}
				
			})
			setState(prevState => ({ ...prevState, policyArray }))
		}
	}, [filterEngineDetails, props.viewMode, state.actionDetails, state.callSavedData, state.savedData])

	const showPolicy = (array, showField, index) => {
		state[array].forEach(item => {
			if(index === item) {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: !state[showField+"_"+item] }))
			} else {
				// setState(prevState => ({ ...prevState, [showField+"_"+item]: false }))
			}
		})
	}

	const addPolicies = (array, showField, index) => {
        let rowList = state[array];
        if(state[array]) {
            let value = state[array][state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
		rowList.forEach(item => {
			if(index === item) {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: true }))
			} else {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: false }))
			}
		})
        
        setState(prevState => ({ ...prevState, [array]: rowList, onChangeInput: true }))
    }

	const removeSelectedDetails = (field, removeItem) => {
		let filteredResult = state[field].filter(e => e !== removeItem)
        setState(prevState => ({ ...prevState, [field]: filteredResult }))
	}

	useEffect(() => {
		if(state.onChangeInput) {
			setState(prevState => ({ ...prevState, onChangeInput: false }))
			let data = []
			state.policyArray.forEach(item => {
				let obj = {}
				obj.rule_category = props.masterData.rule_category
				obj.rule_id = props.masterData.rule_id
				obj.tags = props.masterData.tags
				obj.mode =  props.masterData.mode
				obj.enabled = state["enabled_"+item] ? true : false
				// obj.disabled = state["disable_"+item] ? true : false
				obj.resource_type = props.masterData.resource_type
				obj.template = true
				obj.governance_type = props.masterData.governance_type

				if(state["policy_id_"+item]) {
					obj.policy_id = state["policy_id_"+item]
				}

				let variables = []
				let masterVaiables = props.masterData.variables ? props.masterData.variables : []
				let inputMissing = false
				masterVaiables.forEach(itmVar => {
					let dataRow = {}
					let inputValue = state[itmVar.variable+"_"+item]
					Object.entries(itmVar).forEach(([key, value]) => {
						if(key === "value_list" || key === "value") {
							dataRow[key] = inputValue
						} else {
							dataRow[key] = value
						}
						
						if(typeof inputValue !== 'boolean') {
							if(!inputValue || (inputValue && Array.isArray(inputValue) && !inputValue.length)) {
								inputMissing = true
							}
						}
					})
					variables.push(dataRow)
				})
				obj.variables = variables
				obj.description = state.description

				if(state.actionDetails && state.actionDetails.length) {
					let actionObj = {}
					actionObj.variables = []
					if(state["selected_action_"+item]) {
						actionObj.processor_rule_id = state["action_processor_rule_id_"+item]
						actionObj.name = state["selected_action_"+item]				
						if(state["apply_method_"+item]) {
							actionObj.apply_method = state["apply_method_"+item]					
							if(state["schedule_"+item] && state["schedule_"+item] !== "") {
								if(state["apply_method_"+item] === "run_after") {
									actionObj.schedule = state["schedule_"+item]+""+state["time_type_"+item]
								} else if(state["apply_method_"+item] === "run_at") {
									actionObj.schedule = state["schedule_"+item]
								}
							}
						}
						obj.action = actionObj
					}
				}

				if(!inputMissing) {
					data.push(obj)
				}
			})

			let allPolicies = editNewRuleDetails.policies ? editNewRuleDetails.policies : []

			let currentPolicies = []
			let policies = []

			if(allPolicies.length) {
				allPolicies.forEach(row => {
					if(row.rule_id === props.masterData.rule_id) {
						currentPolicies.push(row)
					} else {
						policies.push(row)
					}
				})
			}

			if(data && data.length) {
				policies = [...data, ...policies]
			}

			setTimeout(() => { dispatch(setNewEditRuleDetails('policies', policies)) }, 500);
			setState(prevState => ({ ...prevState, inputDetected: true }))
		}
	}, [dispatch, editNewRuleDetails.policies, props.masterData.governance_type, props.masterData, state])

	useEffect(() => {
		if(onClickFinish) {
			dispatch(setGovernancePropsDetails('onClickFinish', false))
			let hasError = false
			state.policyArray.forEach(item => {
				if((!state["allow_"+item] || state["allow_"+item] === "") || (!state["engine_type_"+item] || state["engine_type_"+item] === "") || (!state["latest_"+item] || state["latest_"+item] === "")) {
					hasError = true
				} else if(state["latest_"+item] === "Specific" && !state["versions_to_use_"+item].length){
					hasError = true
				}
			})
			
			setState(prevState => ({ ...prevState, hasError }))
			
			props.validateInput(hasError)
		}
	}, [onClickFinish, props, state, dispatch])

	const onKeyDownTime = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			validateTime(str, state)
		} else {
			validateTime(time, state)
		}
	}

	const validateTime = (time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 23) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length === 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time	
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00:"
			} else {
				data += firstMinute+":"
			}
		}
		
		let firstSecond = str.substring(4, 6)
		
		if(firstSecond.length) {
			if(strLength > 5 && parseInt(firstSecond) > 59) {
				data += "00"
			} else {
				data += firstSecond
			}
		}

		setState(prevState => ({ ...prevState, [state]: data, onChangeInput: true }))
	}

	const resetPolicy = (item) => {
		setState(prevState => ({ ...prevState, ["allow_"+item]: "", ["engine_type_"+item]: "", ["latest_"+item]: "", ["versions_to_use_"+item]: [], onChangeInput: true }))
	}
	
	// const deletePolicy = (item) => {
	// 	let array = state.policyArray.filter(e => e !== item)

	// 	if(!array.length) {
	// 		array = [0]
	// 	}
	// 	let deletedPolicies = editNewRuleDetails && editNewRuleDetails.deleted_policies ? editNewRuleDetails.deleted_policies : []
	// 	deletedPolicies.push(state["policy_id_"+item])
	// 	dispatch(setNewEditRuleDetails("deleted_policies", deletedPolicies))
		
	// 	setState(prevState => ({ ...prevState, policyArray: array, ["show_child_policy_"+item]: false }))
	// 	resetPolicy(item)
	// }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
		{state.hasError ?
			<p className='mb-0 text-danger'>Please check the input</p>
		: null}
		{state.policyArray && state.policyArray.map((item, index) => {
			return(
				<React.Fragment key={'rds_endinge_'+index}>
				<div className={`${!props.viewMode ? "py-3" : "py-1"} mb-2 border-b border-mediumDarkGray`}>
					{!props.viewMode ?
						<React.Fragment>
						<div className={`flex flex-wrap justify-between pl-2`}>
							<div className='flex flex-wrap w-3/5'>
								{(!state["allow_"+item] || state["allow_"+item] === "") && (!state["engine_type_"+item] || state["engine_type_"+item] === "") ?
									<p className={`mx-2 self-center mb-1 text-lightGray cursor-pointer`} onClick={() => showPolicy("policyArray", "show_child_policy", item)}>{props.masterData && props.masterData.description ? props.masterData.description : "Choose policy details"}</p>
								:
									<React.Fragment>
									<p className={`mx-2 self-center mb-1 text-black`} onClick={() => showPolicy("policyArray", "show_child_policy", item)}>
										{state["allow_"+item] && state["allow_"+item] !== "" ?
											<span className="mr-2 text-info">{state["allow_"+item]}</span>
										: null}
										{state["engine_type_"+item] && state["engine_type_"+item] !== "" ?
											<React.Fragment>
												<span className="mr-2">usage of</span>
												<span className="mr-2 text-info">{state["engine_type_"+item]}</span>
												<span className="mr-2">engine.</span>
											</React.Fragment>
										: null}
										{state["latest_"+item] && state["latest_"+item] !== "" ?
											<React.Fragment>
											<span className="mr-2 text-info">Use</span>
											<span className="mr-2 text-info">{state.latest_options && state.latest_options.filter(e => e.value === state["latest_"+item]).length  ? state.latest_options.filter(e => e.value === state["latest_"+item])[0].label : state["latest_"+item]}</span>
											<span className="mr-2">engine versions</span>
											</React.Fragment>
										: null}
									</p>
									{state["versions_to_use_"+item] && state["versions_to_use_"+item].length ?
										<React.Fragment>
											<p className="mr-2 self-center mb-1 text-black">to use</p>													
											{state["versions_to_use_"+item] && state["versions_to_use_"+item].length ? 
												state["versions_to_use_"+item].map(row => {
													return(
														<span key={'ver_'+row} className="flex border border-lightGray rounded-full px-2 py-1 mr-2 self-center text-info">{row}
															<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => removeSelectedDetails("versions_to_use_"+item, row) } />
														</span>
													)
												})
											: null}
										</React.Fragment>
									: null}
									</React.Fragment>
								}
							</div>
							<div className="flex flex-wrap justify-end self-center w-2/5">
								{state["tags"] && state["tags"].length ?
									state["tags"].map((tag, tIndex) => {
										return(
											<span key={"tag_"+tIndex} className={`border border-lightGray px-2 py-1 mr-2 self-center text-info ml-2`} > {tag} </span>
										)
									})
								: null}
								{!state["show_child_policy_"+index] ?
									<React.Fragment>
										<Icon icon="octicon:pencil-24" width='16' height='16' className="mr-1 self-center cursor-pointer text-black" onClick={() => showPolicy("policyArray", "show_child_policy", item)} />
										<Icon icon={`fa:angle-${state["show_child_policy_"+item] ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)} />
									</React.Fragment>
								: 
									<div className="flex">
										<Icon icon="mdi:reload" className="text-black self-center cursor-pointer" width="18" height="18" onClick={() => resetPolicy(item)}/>
										{/* <span className={`fal fa-trash cursor-pointer mr-3 text-lightGray f15`} onClick={() => deletePolicy(item)}></span> */}
										<Icon icon={`fa:angle-${state["show_child_policy_"+item] ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)} />
									</div>
								}
							</div>
						</div>
						
						{state["show_child_policy_"+item] ?
							<div className={`pl-5`}>
								<div className={`flex flex-wrap mb-2`}>
									<div className={`form-check form-check-inline self-center mt-2 mr-2 ${state["enabled_"+item] ? "" : "checkboxGrayColor"}`}>
										<input
											className='form-check-input m-0'
											type='checkbox'
											checked={state["enabled_"+item]}
											onClick={e => setState(prevState => ({ ...prevState, ["enabled_"+item]: !state["enabled_"+item], onChangeInput: true }))}	
											title={state["enabled_"+item] ? "not enable" : "enable"}								
										/>
										{!state["enabled_"+item] ?
											<span className="checkmark" onClick={e => setState(prevState => ({ ...prevState, ["enabled_"+item]: !state["enabled_"+item], onChangeInput: true }))}></span>
										: null}
									</div>
									<Icon icon="fa:ban" className={`${state["disable_"+item] ? "text-danger" : "text-lightGray"} self-center`} width="14" height="14"  onClick={() => setState(prevState => ({ ...prevState, ["disable_"+item]: !state["disable_"+item], onChangeInput: true }))} />

									<div className='min-w-32 mr-2' 
										onClick={(event) => {
											if(!state["showAllow_"+item]) {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "showAllow_"+item)
											}
										}}
									>
										<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["allow_"+item] ? 'text-black' : 'text-lightGray'}`}>
											{state["allow_"+item] && state["allow_"+item] !== "" ? state.allow_options.filter(e => e.value === state["allow_"+item])[0].label : 'Select'}
											<Icon icon="icon-park-solid:down-one" className={`${state["allow_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
										</p>	
										{state["showAllow_"+item] ?
											<MultiSelectSection
												fields={["value", "label"]}
												options={state.allow_options ? state.allow_options : []}
												selectedValues={state["allow_"+item] ? state["allow_"+item] : ''}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
														setState(prevState => ({ ...prevState, ["allow_"+item]: value, onChangeInput: true }))
													} else {
														value.preventDefault()
														handleChildClick(value, "search", 'singleDropDown', "")
													}
												}}
												singleSelection={true}
												hideSearch={false}
												topClass={'auto'}
												widthClass={'minWidth220'}
												removeTopOptions={true}
											/>
										: null}
									</div>
									<p className={`mb-0 ml-2 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-black"}`}>usage of</p>

									<div className='min-w-32 mr-2' 
										onClick={(event) => {
											if(!state["engineType_"+item]) {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "engineType_"+item)
											}
										}}
									>
										<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["engine_type_"+item] ? 'text-black' : 'text-lightGray'}`}>
											{state["engine_type_"+item] ? state["engine_type_"+item] : 'Select'}
											<Icon icon="icon-park-solid:down-one" className={`${state["engine_type_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
										</p>	
										{state["engineType_"+item] ?
											<MultiSelectSection
												fields={["name", "name"]}
												options={state.engineDetails ? state.engineDetails : []}
												selectedValues={state["engine_type_"+item] ? state["engine_type_"+item] : ''}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
														setState(prevState => ({ ...prevState, ["engine_type_"+item]: value, ["selectedEngineDetails_"+item]: {}, ["versions_to_use_"+item]: [], onChangeInput: true }))
														
														filterEngineDetails(value, item)
													} else {
														value.preventDefault()
														handleChildClick(value, "search", 'singleDropDown', "")
													}
												}}
												singleSelection={true}
												hideSearch={false}
												topClass={'auto'}
												widthClass={'minWidth220'}
												removeTopOptions={true}
											/>
										: null}
									</div>
									<p className={`mb-0 ml-2 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-black"}`}>engine</p>
								</div>
								<div className={`flex flex-wrap mb-2 ml-5`}>
									{state["selectedEngineDetails_"+item] && state["selectedEngineDetails_"+item].versions && state["selectedEngineDetails_"+item].versions.length && state["allow_"+item] && state["allow_"+item] !== "Deny" ?
										<React.Fragment>
										<p className={`mb-0 ml-2 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-black"}`}>Use</p>
										

										<div className='min-w-32 mr-2' 
											onClick={(event) => {
												if(!state["latestOption_"+item]) {
													event.preventDefault();
													handleChildClick(event, 'child', 'singleDropDown', "latestOption_"+item)
												}
											}}
										>
											<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["latest_"+item] ? 'text-black' : 'text-lightGray'}`}>
												{state.latest_options && state.latest_options.filter(e => e.value === state["latest_"+item]).length  ? state.latest_options.filter(e => e.value === state["latest_"+item])[0].label : state["latest_"+item] ? state["latest_"+item] : 'Select'}
												<Icon icon="icon-park-solid:down-one" className={`${state["latest_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
											</p>	
											{state["latestOption_"+item] ?
												<MultiSelectSection
													fields={["value", "label"]}
													options={state.latest_options ? state.latest_options : []}
													selectedValues={state["latest_"+item] ? state["latest_"+item] : ''}
													callbackMultiSelect={(value) => {
														if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
															setState(prevState => ({ ...prevState, ["latest_"+item]: value, ["versions_to_use_"+item]: [] }))
														} else {
															value.preventDefault()
															handleChildClick(value, "search", 'singleDropDown', "")
														}
													}}
													singleSelection={true}
													hideSearch={false}
													topClass={'auto'}
													widthClass={'minWidth220'}
													removeTopOptions={true}
												/>
											: null}
										</div>
										
										<p className={`mb-0 ml-2 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-black"}`}>engine versions</p>

										{state["latest_"+item] && state["latest_"+item] === "0" ?
											<React.Fragment>
											<div className='min-w-32 mx-2'
												onClick={(event) => {
													event.preventDefault();
													handleChildClick(event, 'child', 'muliselectDropDown', "doprdown_"+item)
												}}
											>
												<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["versions_to_use_"+item] && state["versions_to_use_"+item].length ? 'text-black' : 'text-lightGray'}`}>
													{state["versions_to_use_"+item] && state["versions_to_use_"+item].length ? state["versions_to_use_"+item].length+' Selected' : 'Select'}
													<Icon icon="icon-park-solid:down-one" className={`${state["versions_to_use_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
												</p>
												{state['doprdown_'+item] ?
													<MultiSelectSection
														// fields={["value", "label"]}
														options={state["selectedEngineDetails_"+item].versions}
														selectedValues={state["versions_to_use_"+item] ? state["versions_to_use_"+item] : []}
														callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, ["versions_to_use_"+item]: value, onChangeInput: true }))}
														singleSelection={false}
														hideSearch={false}
														topClass={'auto'}
														widthClass={'minWidth220'}
														removeTopOptions={true}
													/>
												: null}
											</div>
											{state["versions_to_use_"+item] && state["versions_to_use_"+item].length ? 
												state["versions_to_use_"+item].map(row => {
													return(
														<span  key={"ver1_"+row} className="flex border border-lightGray rounded-full px-2 py-1 mr-2 mt-2 self-center text-info">{row}
															<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => removeSelectedDetails("versions_to_use_"+item, row) } />
														</span>
													)
												})
											: null}
											</React.Fragment>
										: null}
										</React.Fragment>
									: null}
								</div>
								{state.actionDetails && state.actionDetails.length ?
									<div className="rounded bg-GhostWhite p-3 mt-5">
										<p className="mb-0 text-lg text-purple3 cursor-pointer">Rule Action</p>
										<div className={`flex flex-wrap mb-2`}>
											<p className={`mb-0 mt-2 self-center`}>Select Action</p>
											<div className='min-w-32 mr-2' 
												onClick={(event) => {
													if(!state["showAction_"+item]) {
														event.preventDefault();
														handleChildClick(event, 'child', 'singleDropDown', "showAction_"+item)
													}
												}}
											>
												<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["selected_action_"+item] ? 'text-black' : 'text-lightGray'}`}>
													{state["selected_action_"+item] ? state["selected_action_"+item] : 'Select'}
													<Icon icon="icon-park-solid:down-one" className={`${state["selected_action_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
												</p>
												{state["showAction_"+item] ?
													<MultiSelectSection
														fields={["action", "action"]}
														options={state.actionDetails ? state.actionDetails : []}
														selectedValues={state["selected_action_"+item] ? state["selected_action_"+item] : ''}
														callbackMultiSelect={(value) => {
															if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																setState(prevState => ({ ...prevState, 
																	["selected_action_"+item]: value,
																	["action_processor_rule_id_"+item]: state.actionDetails.filter(e => e.action === value) && state.actionDetails.filter(e => e.action === value).length && state.actionDetails.filter(e => e.action === value)[0].processor_rule_id ? state.actionDetails.filter(e => e.action === value)[0].processor_rule_id : "",
																	onChangeInput: true
																}))
															} else {
																value.preventDefault()
																handleChildClick(value, "search", 'singleDropDown', "")
															}
														}}
														singleSelection={true}
														hideSearch={false}
														topClass={'auto'}
														widthClass={'minWidth220'}
														removeTopOptions={true}
													/>
												: null}
											</div>
										</div>
										
										{state["selected_action_"+item] ?
											<div className={`flex flex-wrap mb-2 ml-5`}>
												<p className={`mb-0 mt-2 self-center`}>Apply Method</p>
												<div className='min-w-32 mr-2' 
													onClick={(event) => {
														if(!state["showMethod_"+item]) {
															event.preventDefault();
															handleChildClick(event, 'child', 'singleDropDown', "showMethod_"+item)
														}
													}}
												>
													<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["apply_method_"+item] ? 'text-black' : 'text-lightGray'}`}>
														{state["apply_method_"+item] && props.actionMethods ? props.actionMethods.filter(e => e.value === state["apply_method_"+item])[0].label : 'Select'}
														<Icon icon="icon-park-solid:down-one" className={`${state["apply_method_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
													</p>
													{state["showMethod_"+item] ?
														<MultiSelectSection
															fields={["value", "label"]}
															options={props.actionMethods ? props.actionMethods : []}
															selectedValues={state["apply_method_"+item] ? state["apply_method_"+item] : ''}
															callbackMultiSelect={(value) => {
																if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																	setState(prevState => ({ ...prevState, ["apply_method_"+item]: value, ["schedule_"+item]: "", onChangeInput: true }))
																} else {
																	value.preventDefault()
																	handleChildClick(value, "search", 'singleDropDown', "")
																}
															}}
															singleSelection={true}
															hideSearch={false}
															topClass={'auto'}
															widthClass={'minWidth220'}
															removeTopOptions={true}
														/>
													: null}
												</div>
												{state["apply_method_"+item] && state["apply_method_"+item] !== "immediately" ?
													<React.Fragment>
													<p className={`mb-0 ml-1 mr-2 self-center`}>Schedule</p>
													{state["apply_method_"+item] === "run_at" ?
														<input 
															type="text" 
															className={`bg-transparent border-b border-lightGray px-2 py-1 text-black min-w-32`}
															style={{minHeight: "38px"}}
															placeholder={"HH:MM:SS"}
															value={state["schedule_"+item]}
															maxLength="8"
															onKeyDown={e => onKeyDownTime(e, e.target.value, "schedule_"+item)}
															onChange={e => validateTime(e.target.value, "schedule_"+item)}
														/>
													: state["apply_method_"+item] === "run_after" ?
														<div className="flex">
															<input 
																type="text" 
																className={`bg-transparent border-b border-lightGray px-2 py-1 text-black min-w-32`}
																style={{minHeight: "38px"}}
																placeholder={""}
																value={state["schedule_"+item]}
																maxLength="2"
																onChange={e => setState(prevState => ({ ...prevState, ["schedule_"+item]: onlyNumeric(e.target.value), onChangeInput: true }))}
															/>
															<div className='min-w-32 mr-2' 
																onClick={(event) => {
																	if(!state["showTimeType_"+item]) {
																		event.preventDefault();
																		handleChildClick(event, 'child', 'singleDropDown', "showTimeType_"+item)
																	}
																}}
															>
																<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["time_type_"+item] ? 'text-black' : 'text-lightGray'}`}>
																	{state["time_type_"+item] && state.timeTypeOptions ? state.timeTypeOptions.filter(e => e.value === state["time_type_"+item])[0].label : 'Select'}
																	<Icon icon="icon-park-solid:down-one" className={`${state["time_type_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
																</p>
																{state["showTimeType_"+item] ?
																	<MultiSelectSection
																		fields={["value", "label"]}
																		options={state.timeTypeOptions ? state.timeTypeOptions : []}
																		selectedValues={state["time_type_"+item] ? state["time_type_"+item] : ''}
																		callbackMultiSelect={(value) => {
																			if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																				setState(prevState => ({ ...prevState, ["time_type_"+item]: value, onChangeInput: true }))
																			} else {
																				value.preventDefault()
																				handleChildClick(value, "search", 'singleDropDown', "")
																			}
																		}}
																		singleSelection={true}
																		hideSearch={false}
																		topClass={'auto'}
																		widthClass={'minWidth220'}
																		removeTopOptions={true}
																	/>
																: null}
															</div>
														</div>

													: null}
													</React.Fragment>
												: null}
											</div>
										: null}
									</div>
								: null}
							</div>
						: null}
						</React.Fragment>							
					: 
						<React.Fragment>
						<div className="flex justify-between ml-3">
							<div className={`w-80"`}>
								<div className={`flex flex-wrap mb-2`}>
									<p className={`mb-0 mt-2 self-center text-info`}>{state["allow_"+item] && state["allow_"+item] !== "" && state.allow_options ? state.allow_options.filter(e => e.value === state["allow_"+item])[0].label : ""}</p>

									<p className={`mb-0 ml-2 mt-2 self-center ${props.selectedModeType === state.mode_engine_type ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-black") : "text-black"}`}>usage of</p>

									<p className={`mb-0 ml-2 mt-2 self-center text-info`}>{state["engine_type_"+item] ? state["engine_type_"+item] : ""}</p>

									<p className={`mb-0 ml-2 mt-2 self-center ${props.selectedModeType === state.mode_engine_type ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-black") : "text-black"}`}>engine</p>
								</div>

								<div className={`flex flex-wrap mb-2 ml-3`}>
									<p className={`mb-0 ml-2 mt-2 self-center ${props.selectedModeType === state.mode_engine_type ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-black") : "text-black"}`}>Use</p>

									<p className={`mb-0 ml-2 mt-2 self-center text-info`}>{state.latest_options && state.latest_options.filter(e => e.value === state["latest_"+item]).length  ? state.latest_options.filter(e => e.value === state["latest_"+item])[0].label : state["latest_"+item]}</p>

									<p className={`mb-0 mx-2 mt-2 self-center ${props.selectedModeType === state.mode_engine_type ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-black") : "text-black"}`}>engine versions</p>
									{state["versions_to_use_"+item] && state["versions_to_use_"+item].length ? 
										state["versions_to_use_"+item].map(row => {
											return(
												<span key={"ver2_"+row} className="border border-lightGray px-2 py-1 mr-2 self-center text-info">{row}</span>
											)
										})
									: null}
								</div>
							</div>
							{!index ?
								<div className={`flex flex-wrap w-20 self-start justify-end mt-2`}>
									{state["tags"] && state["tags"].length ?
										state["tags"].map((tag, tIndex) => {
											return (
												tag !== "" ?
													<span key={"tag1_"+tIndex} className={`border border-lightGray px-2 py-1 mr-2 self-center text-info ml-2 mb-2`} > {tag} </span>
												: null
											)
										})
									: null}
								</div>
							: null}
						</div>
						{state["selected_action_"+item] && state["selected_action_"+item] !== "" ?
							<div className="rounded bg-GhostWhite p-3 ml-4 mt-2">
								<div className={`flex flex-wrap mb-2`}>
									<span className={`mb-0 ml-2 mt-2 self-center text-lightGray`}>Selected Action <span className="text-black mx-1">{state["selected_action_"+item]}</span></span>
									{state["apply_method_"+item] && state["apply_method_"+item] ?
										<span className={`mb-0 ml-2 mt-2 self-center text-lightGray`}><span className="text-black mx-1">{props.actionMethods.filter(e => e.value === state["apply_method_"+item]) && props.actionMethods.filter(e => e.value === state["apply_method_"+item]).length ? props.actionMethods.filter(e => e.value === state["apply_method_"+item])[0].label : ""}</span></span>
									: null}
									{state["schedule_"+item] && state["schedule_"+item] ?
										<span className={`mb-0 ml-2 mt-2 self-center text-lightGray`}><span className="text-black mx-1">{state["schedule_"+item]} {state["time_type_"+item] !== "" ? <span className="ml-1">{state["time_type_"+item]}</span>: null}</span></span>
									: null}
								</div>
							</div>
						: null}
						</React.Fragment>
					}
				</div>
				{state.policyArray.length === (index+1) && !props.viewMode ?
					<span className="text-info mt-2 ml-3 mb-5 cursor-pointer" onClick={() => addPolicies("policyArray", "show_child_policy", item+1)}>+ Add new Engine policy</span>
				: null}
				</React.Fragment>
			)
		})}
		</div>
	)
}

export default  RdsServiceEnginesTemplate
