/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LogsTab.js
 * @author Prakash // on 06/08/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime } from '../../../utils/utility'
import { getBuildLogs } from '../../../actions/ImagebuilderAction'
import Search from '../../common/SearchWithHiddenInput'
import {Icon} from "@iconify/react";
import { CSVLink } from 'react-csv';


const LogsTab = ({ selectedDetails, backGroundColor }) => {
    
    const dispatch = useDispatch(); // Create a dispatch function

    const [state, setState] = useState({
        minMaxHeight: '700px',
        showLogLoading: true,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 500,
        selectedJobLog: 'job',
        callBuildLogs: true
    })

    useEffect(() => {
        if(state.callBuildLogs) {
            let params = {
                provider: selectedDetails.provider,
                account_id: selectedDetails.account_id,
                region: selectedDetails.region,
            };
    
            if (selectedDetails.build_result_key) {
                params.build_result_key = selectedDetails.build_result_key;
            }
            
            if(state.nextLogToken) {
                params.next_token = state.nextLogToken
            }

            // params.size = 5
    
            dispatch(getBuildLogs(params))
                .then((response) => {
                    if(response) {
                        let totalResults = [] 
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextLogToken) {
                            totalResults = state.logsList &&  state.logsList.length ? state.logsList : []
                            // startRecord = startRecord + state.perPage;
                            // currentPage = currentPage+ 1
                        }

                        if(response.results && response.results.length) {
                            totalResults = totalResults.concat(response.results)
                        }

                        let totalPages = 1                
                        if(totalResults && totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }
    
                        setState(prevState => ({ ...prevState, logsList: totalResults, filteredLogsList: totalResults, totalPages, startRecord, currentPage, showLogLoading: false, callBuildLogs: false, nextLogToken: response.next_token, }));
                    }
                })
        }
    }, [state.callBuildLogs, dispatch, selectedDetails, state.perPage, state.logsList, state.nextLogToken, state.startRecord, state.currentPage, state.totalPages])

    useEffect(() => {
        let dataResult = state.logsList && state.logsList.length ? state.logsList : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, headers }))
		}
    }, [state.logsList])

    // Function to navigate between pages
    const navigateLogPage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextLogToken) {            
            setState(prevState => ({ ...prevState, showLogLoading: true, callBuildLogs: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }
    
    return (
        <div className={`${!backGroundColor ? 'm-2' : 'mt-2'}`}>
            <div className={`w-full rounded-md ${backGroundColor ? backGroundColor : 'bg-white'} p-3`}>
                <div className='flex justify-between'>
                    <p className='flex mr-2 w-2/3'>
                        <span className='text-lightGray text-xs m-0 self-center pt-1'>Showing {state.filteredLogsList && state.filteredLogsList.length} of total {state.logsList && state.logsList.length} log&nbsp;event(s)</span>
                    </p>
                    <div className='w-1/3 mb-n2'>
                        <div className='flex justify-end'>
                            {state.filteredLogsList && state.filteredLogsList.length && !backGroundColor ?
                                <CSVLink 
                                    data={state.filteredLogsList ? state.filteredLogsList : []} 
                                    headers={state.headers ? state.headers : []}
                                    filename={`${selectedDetails.build_result_key+'_'+selectedDetails.image_id}-logs.csv`}
                                    className={'self-center'}
                                    target='_blank'
                                >
                                    <Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
                                </CSVLink>
                            : null}
                            {state.logsList && state.logsList.length ?
                                <Search
                                    data={state.logsList ? state.logsList : []}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'bg-transparent text-black border border-mediumDarkGray rounded-md'}
                                    searchClassName={'px-2'}
                                    searchIconColor={'text-lightGray'}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent text-black'}
                                    widthHeight={22}
                                    filteredData={(filteredLogsList) => {
                                        let totalPages = 1
                                        if(filteredLogsList.length > state.perPage) {
                                            totalPages = Math.ceil(filteredLogsList.length / state.perPage)
                                        }
                                        setState(prevState => ({ ...prevState, filteredLogsList, totalPages, currentPage: 1, startRecord: 0 }))
                                    }}
                                />
                            : null}
                        </div>
                        {state.filteredLogsList && state.filteredLogsList.length > state.perPage ?
                            <div className='flex justify-end text-black text-xs mt-2'>
                                <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigateLogPage('start', state.currentPage)}></Icon></button> 
                                <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigateLogPage('previous', state.currentPage)}></Icon></button>
                                <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? (state.nextLogToken ? 'text-info' : 'text-ligthGray') : 'text-info'}`} onClick={() => navigateLogPage('next', state.currentPage)}></Icon></button>
                                <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-ligthGray' : 'text-info'}`} onClick={() => navigateLogPage('end', state.currentPage)}></Icon></button>
                            </div>
                        : null}
                    </div>
                </div>

                <div className='flex overflow-auto h-screen'>
                    <div className={`w-full`}>
                        <div className={`py-3`} >
                            {state.showLogLoading ? 
                                <div className='flex justify-center m-4'>
                                    <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="white" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="white" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                </div>
                            : 
                                state.filteredLogsList && state.filteredLogsList.length ?
                                    state.filteredLogsList.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                        return(
                                            <div className={`flex mr-2 px-2 mb-2 pb-1 ${(state.startRecord + state.perPage !== state.startRecord) && state.filteredLogsList.length !== i+1 ? 'border-b border-mediumDarkGray' : ''} ${state.selectedAlertId !== item.group_id ? 'cursor-pointer' : ' text-black pt-1'}`} key={'key_'+i}>
                                                <div className='flex'>
                                                    <p className='mb-0'>
                                                        <span className='mb-0 mr-2 text-black text-xs font-bold'>{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ''} </span>
                                                        {/* <span className={`mb-0 mr-2 text-xs text-lightGray mr-2`}>
                                                            Ingestion Time
                                                        </span>
                                                        <span className='mb-0 mr-2 text-black text-xs font-weight-bold'>{item.ingestionTime ? momentConvertionUtcToLocalTime(item.ingestionTime, 'DD MMM YYYY HH:mm') : ''} </span> */}
                                                        <span className='mb-0 mr-2 text-xs text-black font-bold'>{item.message}</span>
                                                        {/* {item.message && JSON.parse(item.message) && Object.entries(JSON.parse(item.message)).length ?
                                                            Object.entries(JSON.parse(item.message)).map(([key, value]) => {
                                                                return(
                                                                    <React.Fragment>
                                                                        <span className='mb-0 mr-2 text-xs text-lightGray font-weight-bold'>{key}</span>
                                                                        <span className='mb-0 mr-2 text-xs text-black font-weight-bold'>{item.message}</span>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        : null} */}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                :
                                    <div className='flex justify-center m-4 text-black'>
                                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogsTab