/*************************************************
 * Collider
 * @exports
 * @file OnboardAccountSidePanel.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, listAllRegions} from '../../../actions/commonActionNew'
import { onboardAwsAccount, getRoleYaml} from '../../../actions/AccountAction'
import MultiSelectSection from '../../common/MultiSelectSection';
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import { convertToLowerCase }from "../../../utils/utility"
// import * as fs from 'fs';
import yaml from 'js-yaml';
import PageLoading from '../../common/PageLoading';
import { LoadingCircle } from '../../common/LoadingCiricle';
import Button from '../../common/Form/Button';
import Textbox from '../../common/Form/Textbox';
import SelectOption from '../../common/Form/SelectOption';

const OnboardAccountSidePanel = ({ closeSidePanel, refreshApi, pageMode, selectedAccountDetails }) => {
    const clickOut = useRef()

    const [state, setState] = useState({
        clusters: [],
        callAccountFilters: true,
        onLoad: true,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (providers) {
            setState(prevState => ({ ...prevState, providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : '')
            }));
        } else { 
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''
                        }));
                    }
                })
        }
    }, [dispatch, providers]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
			if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label], selectedAccount: propProjAccounts[label].length ? propProjAccounts[label][0].account_id : '' }));
            } 
        }
    }, [state.selectedProvider, dispatch, propProjAccounts]); 

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount) {
                label = ''
                params.account_id = state.selectedAccount
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label], selectedRegion: propProjRegions[label].length ? propProjRegions[label][0].region : '' }));
            } 
        }
    }, [state.selectedProvider, dispatch, propProjRegions, state.selectedAccount]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        if(state.selectedProvider) {
            // setState(prevState => ({ ...prevState, callAccountFilters: false }))
            let params = { "provider" : convertToLowerCase(state.selectedProvider) };
            dispatch(listAllRegions(params))
                .then((response) => {
                    if(response) {
                        // let roleDetails = []
                        // if(pageMode !== 'Create') {
                        //     roleDetails = response.results ? response.results.filter(e => e.account_id === selectedAccountDetails.roleId).length ? response.results.filter(e => e.account_id === selectedAccountDetails.account_id) : [] : []
                        // }
                        let regions = response[0]?.provider_regions ? response[0].provider_regions : []
                        setState(prevState => ({ ...prevState, regions }));
                    }
                })
        }
    }, [state.callAccountFilters, dispatch, pageMode, state.selectedProvider, selectedAccountDetails])

    useEffect(() => {
        if(state.callDownloadFunction) {
            setState(prevState => ({ ...prevState,callDownloadFunction: false }))
            let params = {};
            dispatch(getRoleYaml(params))
                .then((response) => {
                    if(response) {
                        // Convert JSON to YAML
                        const yamlData = yaml.dump(response);

                        // Create a Blob from the YAML content
                        const blob = new Blob([yamlData], { type: 'text/yaml' });

                        // Create a download link
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = 'roles.yaml'; // File name for download
                        link.click(); // Trigger download

                        // Clean up the URL object
                        URL.revokeObjectURL(link.href);
                    }
                })
        }
    }, [state.callDownloadFunction, dispatch, pageMode, closeSidePanel, refreshApi, state])

    useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let hasError = false
            let params = {}
            params.accounts_info = [{}]
            if(!state.account_id || !state.account_name || !state.role_arn || !state.selectedRegion) {
                hasError = true
            }
            // if(pageMode === 'Create') {
            //     if(!state.emailId || !validateEmail(state.emailId)) {
            //         hasError = true
            //     }
            //     params.emailId = state.emailId
            // }
            params.accounts_info[0].provider = convertToLowerCase(state.selectedProvider)
            params.accounts_info[0].region = convertToLowerCase(state.selectedRegion)
            params.accounts_info[0].account_id = state.account_id
            params.accounts_info[0].account_name = state.account_name
            params.accounts_info[0].role_arn = state.role_arn
            // if(pageMode === 'Edit') {
            //     params.update_user_id = selectedAccountDetails.userId
            // }

            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

           if(!hasError) {
                if(pageMode === 'Create') {
                    dispatch(onboardAwsAccount(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in saving Account'

                            if(response && response.status) {
                                refreshApi()
                                messageType = 'success'
                                message = response.message ? response.message : 'Account Created Successfully'

                                setTimeout(() => closeSidePanel(), 2000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }

                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
            
                            // setState(prevState => ({ ...prevState, createRespons: response }))
                        })
                } 
                // else {
                //     dispatch(updateAccount(params))
                //         .then((response) => {
                //             let messageType = 'danger'
                //             let message = response.message ? response.message : 'Error in updating Account'
                //             if(response && response.status) {
                //                 messageType = 'success'
                //                 message = response.message ? response.message : 'Account Updated Successfully'

                //                 refreshApi()
                //             }

                //             setTimeout(() => setState(prevState => ({ ...prevState, saveLoading: false })), 2000)
            
                //             // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
            
                //             CommonNotification.addNotification({
                //                 //title: 'Wonderful!',
                //                 message: message,
                //                 type: messageType,
                //                 insert: 'top',
                //                 container: 'top-center',
                //                 // animationIn: ['animate__animated', 'animate__fadeIn'],
                //                 // animationOut: ['animate__animated', 'animate__fadeOut'],
                //                 dismiss: {
                //                 duration: 5000,
                //                 onScreen: false,
                //                 pauseOnHover: true,
                //                 showIcon: true,
                //                 }
                //             });
                //         })
                // }
            }
        }
    }, [state.callSaveFunction, dispatch, pageMode, closeSidePanel, refreshApi, state, selectedAccountDetails])    

    const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='bg-white' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-3/4 w-10/12' ref={clickOut}>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <p className='text-black text-xl'>{pageMode === "View" ? '' : (pageMode === "Edit" ? "Edit" : "Onboard")} {pageMode === "View" ? 'Details' : 'Account'}</p>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                    <div className='overflow-y-auto h-[calc(100vh-120px)] bg-white'>
                        <div className='p-8 h-full'>
                            <div className='flex flex-wrap'>
                                <div className='flex justify-start w-1/2 pr-4'>
                                    <SelectOption
                                        label={"Provider"}
                                        fields={["provider_name", "provider_name"]}
                                        options={state.providers}
                                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedProvider: value}))
                                        }}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        singleSelection={true}
                                        dropdownWidth={'w-full'}
                                    />
                                </div>
                                <div className='lg:w-1/2 w-full lg:pr-4'>
                                    <Textbox
                                        label={"Account Id"}
                                        type="text"
                                        selectedValue={state.account_id ? state.account_id : ''}
                                        callback={(value) => {
                                            setState(prevState => ({ ...prevState, account_id: value }))
                                        }}
                                        placeholder={'Enter account id'}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        disabled={pageMode === 'Edit'}
                                    />
                                </div>
                            </div>

                            <div className='flex flex-wrap pt-8'>
                                <div className='lg:w-1/2 w-full lg:pr-4'>
                                    <Textbox
                                        label={"Account name"}
                                        type="text"
                                        selectedValue={state.account_name ? state.account_name : ''}
                                        callback={(value) => {
                                            setState(prevState => ({ ...prevState, account_name: value }))
                                        }}
                                        placeholder={'Enter account name'}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                    />
                                </div>
                                <div className='flex justify-start w-1/2 pr-4'>
                                {console.log(state.regions)}
                                    <SelectOption
                                        label={"Region"}
                                        fields={["region", "name"]}
                                        options={state.regions}
                                        selectedValues={state.selectedRegion ? state.selectedRegion : ''}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedRegion: value }))
                                        }}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        singleSelection={true}
                                        dropdownWidth={'w-full'}
                                        disabled={pageMode === 'Edit'}
                                    />
                                </div>
                            </div>

                            <p className={`text-primaryPurple-600 text-sm w-full pt-8 py-1`}>{ "Note: To allow the Tachyon platform to access AWS resources and HPC clusters, download the YAML file for the CloudFormation Template (CFT), which creates the necessary role with required policies. Create the role and then provide the Role ARN as input once the role is created."}</p>

                            <div className='flex flex-wrap pt-4'>
                                <div className='lg:w-1/2 w-full lg:pr-4'>
                                    <Textbox
                                        label={"Role arn"}
                                        type="text"
                                        selectedValue={state.role_arn ? state.role_arn : ''}
                                        callback={(value) => {
                                            setState(prevState => ({ ...prevState, role_arn: value }))
                                        }}
                                        placeholder={'arn:aws:iam::<account-id>:role/<role-name>'}
                                        manditory={true}
                                        hasError={state.inputValidationError}
                                        disabled={pageMode === 'Edit'}
                                    />
                                </div>
                            </div>
                    </div>
                    <div className="w-full p-8 shadow-lg bg-NeutralGray-700">
                        <div className={`flex justify-center`}>
                            {state.hasError ?
                                <p className='text-white text-lg mr-3 self-center'>Please fill all the required fields</p>
                            : null}
                            <div className={`self-center flex justify-end`}>
                                <Button
                                    classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                    label={'Cancel'}
                                    callback={() => 
                                        closeSidePanel()
                                    }
                                />
                                {state.pageType !== 'View' ?
                                    state.saveLoading ?
                                    <Button
                                        classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                        label={pageMode === 'Edit' ? 'Updating' : 'Submitting'}
                                        loading={true}
                                    />
                                :
                                    <Button
                                        classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36', others: 'cursor-pointer text-center'}}
                                        label={pageMode === 'Edit' ? 'Update' : 'Submit'}
                                        callback={() => {
                                            setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))
                                        }}
                                    />
                                :null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default OnboardAccountSidePanel