/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file CreateNewBudget.js
 * @author Prakash // on 25/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import { useLocation, useNavigate } from 'react-router-dom'
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import { URL_PATH } from '../../../../config/urlPath';
import { LoadingCircle } from '../../../common/LoadingCiricle';

const CreateNewBudget = () => {

    const [state, setState] = useState({
    })
    
    const location = useLocation();
    const receivedData = location.state;
    
    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function
    const onClickSave = useSelector(state => state?.hpc?.hpcPropsDetails?.onClickSave || false);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
        };

        const handlePopstate = () => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
        }
        };

        const handleBeforeRedirect = (location) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
            navigate(location.pathname); // Redirect manually after confirmation
        } else {
            // Prevent the redirect if the user cancels
            return false;
        }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopstate);

        // Use window.onbeforeunload to handle redirects
        const unblock = () => {};
        window.onbeforeunload = () => handleBeforeRedirect({ pathname: '/' });

        return() => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopstate);
            window.onbeforeunload = null; // Remove the onbeforeunload handler when the component is unmounted
            unblock(); // Remove the history.block when the component is unmounted
        };
    }, [navigate]); // Re-run the effect when navigate changes

    useEffect(() => {
        if(onClickSave === 'stopLoading') {
            setState(prevState => ({ ...prevState, onSaveFunction: false }))
            dispatch(setHpcPropsDetails('onClickSave', ''))
        }
    }, [onClickSave, dispatch])

    useEffect(() => {
        if(receivedData && Object.entries(receivedData).length) {
            setState(prevState => ({ 
                ...prevState, 
                selectedData: receivedData.selectedData ? receivedData.selectedData : '',
                pageType: receivedData.pageType ? receivedData.pageType : '',
            }))
        }
    }, [receivedData])

    const navigateTo = () => {
        navigate(URL_PATH.HPC_BUDGET); // Redirect to the specified path
    }

    return (
        <div className=''>
            <div className='bg-NeutralGray-200 px-8 py-10 text-black w-full'>
                <div className='w-full self-center'>
                    <span className='font-bold text-2xl capitalize'>{state.pageType} Budget</span>
                </div>
            </div>
            <div className='px-3 pt-6'>
                <div className='flex flex-wrap'>
                    <div className={`md:w-4/6 w-full`}>
                        <LeftSection 
                            receivedData={receivedData}
                        />
                    </div>
                    <div className={`md:w-2/6 w-full lg:pl-3 pl-0`}>
                        <RightSection 
                            receivedData={receivedData}
                        />
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-end bg-transparent py-2 pr-3'>
                {state.onSaveFunction ?
                    <button className={`flex bg-green-600 py-2.5 pr-4 pl-3 rounded-md mr-2 text-white font-bold text-base cursor-pointer`}>
                        <LoadingCircle 
                            color={'text-white'}
                        />
                        {state.pageType !== "edit" ?  "Saving"  : "Updating"}
                    </button>
                :
                    <button className={`bg-green-600 py-2.5 pr-4 pl-3 rounded-md mr-2 text-white font-bold text-base cursor-pointer`} onClick={() => {
                        setState(prevState => ({ ...prevState, onSaveFunction: true }))
                        dispatch(setHpcPropsDetails('onClickSave', 'save'))
                    }}>
                        {state.pageType !== "edit" ?  "Save"  : "Update"}
                    </button>
                
                }
                <button className={`bg-ferrariRed-600 py-2.5 pr-4 pl-3 rounded-md text-white font-bold text-base ${state.onSaveFunction ? "cursor-pointer-events" : " cursor-pointer"}`} onClick={() => navigateTo()}>Cancel</button>
            </div>
        </div>
    )
}

export default CreateNewBudget