import React from 'react';

const RadioOption = ({ label, value, selected, callback, className }) => {
  return (
    <div className={`flex items-center ${className}`}>
      <input
        type="radio"
        name={value}
        className="form-radio h-4 w-4 text-blue-600"
        checked={selected ? true : false}
        onChange={callback}
      />
      <label htmlFor={label} className="text-NeutralGray-600 text-base pl-2">{label}</label>
    </div>
  );
};

export default RadioOption;
