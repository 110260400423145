import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Icon} from "@iconify/react";
import { getImageFilters } from '../../../actions/ImagebuilderAction'
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import MultiSelectSection from '../../common/MultiSelectSection';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import { getDayFromSelectedDuration, momentDateGivenFormat, subDays, subHours } from '../../../utils/utility';

const ImagebuilderFilterSection = (props) => {
    const clickOut = useRef()
    const durationRef = useRef()
    const [state, setState] = useState({
        imageDuration: [
            { value: '+1d', label: 'Last 1 day' },
            { value: '+3d', label: 'Last 3 days' },
            { value: '+5d', label: 'Last 5 days' },
            { value: '+7d', label: 'Last 1 week' },
            { value: '+21d', label: 'Last 3 week' },
            { value: '+30d', label: 'Last 1 month' },
            { value: 'custom', label: 'Custom Date' }
        ],
        selectedDuration: '+5d',
        onLoad: true,        
    })

    const [dateState, setDateState] = useState([{
        startDate: new Date(),
        endDate: null,
        key: 'selection'
    }]);

    const dispatch = useDispatch(); // Create a dispatch function
    const clickedonParent = useSelector(state => state?.filters?.commonPropsDetails?.clickedonParent || false);

    useEffect(() => {
        const currentMonth = new Date().getMonth(); // Month is zero-based (0 for January, 1 for February, ..., 11 for December)
    
        let datePickerStartDate = ''
        let datePickerEndDate = ''
        let duration = state.selectedDuration;
        let period = duration.substring(duration.length, duration.length - 1);
        let periodType = duration.substring(0, 1);
        if(periodType === '+' && (period === 'd' || period === 'h')) {
            let durationBtw = getDayFromSelectedDuration(duration);
            if (period === 'd') {
                datePickerStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            } else {
                datePickerStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            }
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
        } else if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "currentYear") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "lastMonth") {
            datePickerStartDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-01 00:00:00')            
            datePickerEndDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "quarterly") {
            if (currentMonth >= 0 && currentMonth <= 2) {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-03-31 23:59:59')
            } else if (currentMonth > 2 && currentMonth < 6) {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-04-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else if (currentMonth > 5 && currentMonth < 9) {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-09-30 23:59:59')
            } else {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-10-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "half-yearly") {
            if (currentMonth >= 0 && currentMonth <= 5) {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "yearly") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }
        if(state.selectedDuration !== 'custom') {
            setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));
    
            let dateSelection= {
            startDate: new Date(datePickerStartDate),
            endDate: new Date(datePickerEndDate),
            key: 'selection'
            }
            setDateState([dateSelection])
        }
    }, [state.selectedDuration])
    
    useEffect(() => {
        let params = {}
        dispatch(getImageFilters(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({ 
                        ...prevState, 
                        imageFIlters: response,
                        filterDetails: response,
                        selectedProvider: response && response.provider && response.provider.length ? response.provider[0] : "",
                        callSearch: true,
                        onChangeFilter: true
                    }))
                }
            })
    }, [dispatch])

    // useEffect(() => {
    //     if(state.onChangeImageType) {
    //         setState(prevState => ({ ...prevState, onChangeImageType: false }))
    //         let params = {};
    //         params.provider = state.selectedProvider;
    //         if(state.selectedAccount && state.selectedAccount.length) {
    //             params.account_id = state.selectedAccount
    //         }
    //         if(state.selectedRegion && state.selectedRegion.length) {
    //             params.region = state.selectedRegion
    //         }
    //         params.image_type = state.selectedImageType
    //         params.aggregate_by = ['os']
    //         dispatch(getImageFilters(params))
    //             .then((response) => {
    //                 if(response) {
    //                     setState(prevState => ({ ...prevState, osDetails: response, imageLoader: false }))
    //                 }
    //             })
    //     }
    // }, [state.onChangeImageType, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedImageType,  dispatch]);

    useEffect(() => {
        if(state.onChangeFilter)  {
            setState(prevState => ({ ...prevState, onChangeFilter: false }))

            let aggregate_by = ['image_name']
            if(state.selectedProvider !== 'docker') {
                aggregate_by = ['account_id', 'region', 'image_name']
            }
            let params = {};
            params.provider = state.selectedProvider
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedProvider !== 'docker') {
                params.image_type = state.selectedImageType
            }
            if(state.selectedImageType) {
                aggregate_by.push('os')
            }
            params.aggregate_by = aggregate_by
            dispatch(getImageFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, filterDetails: response, imageLoader: false }))
                    }
                })
        }
    }, [state.onChangeFilter, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedImageType,  dispatch]);

    const onReset = () => {
        setState(prevState => ({ 
            ...prevState, 
            selectedProvider: state.filterDetails && state.filterDetails.provider && state.filterDetails.provider.length ? state.filterDetails.provider[0] : "", 
            selectedAccount: [], 
            selectedRegion: [], 
            selectedImageType: '', 
            selectedImage: '',
            selectedDuration: 'currentMonth', 
            datePickerStartDate: momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00'), 
            datePickerEndDate: momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59'),
            callSearch: true 
        }));
    }

    useEffect(() => {
        if(state.callSearch && state.selectedProvider) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            let obj = {
                selectedProvider: state.selectedProvider,
                selectedAccount: state.selectedAccount ? state.selectedAccount : [],
                selectedRegion: state.selectedRegion ? state.selectedRegion : [],
                selectedImageType: state.selectedImageType ? state.selectedImageType : '',
                selectedImage: state.selectedImage ? state.selectedImage : '',                
                datePickerStartDate: state.datePickerStartDate,
                datePickerEndDate: state.datePickerEndDate,
            }
            props.selectedFilters(obj)
        }
    }, [state.callSearch, props, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.selectedImageType, state.selectedImage, state.datePickerEndDate, state.datePickerStartDate])

    // useEffect(() => {
    //     if(props.changeManage && props.screen === 'QOS') {
    //         setState(prevState => ({ ...prevState, changeManage: props.changeManage, callSearch: true }))
    //     }
    // }, [props.changeManage, props.screen, state.changeManage])

	const handleChildClick = (event, type, dropdownType, section) => {
        if (durationRef.current && !durationRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
        
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    useEffect(() => {
        if(clickedonParent) {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            setState(prevState => ({ ...prevState, clickedChild: [] }))

            dispatch(setCommonPropsDetails('clickedonParent', false))
        }

    }, [clickedonParent, dispatch, state.clickedChild])

    const handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate, selectedDuration: "custom", showDateRangePicker: false }));
        }

        setDateState([date.selection])
    };

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')} className='flex flex-wrap justify-end gap-2'>
            {props.filters && props.filters.includes('provider') ?
                <div className={`bg-black5 h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer `} style={{minWidth: '150px', maxWidth: '150px'}} 
                    onClick={(event) => {
                        if(!state.isProviderOpen) {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                        }
                    }}
                >
                    <p className="text-xs text-black font-semibold">Provider</p>
                    <p className={`flex justify-between text-sm font-medium mt-3`}>
                        <span className={`truncate text-sm font-bold ${state.selectedProvider ? "text-black" : "text-lightGray"}`}>{state.selectedProvider ? state.selectedProvider.toUpperCase() : 'Select'}</span>
                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedProvider ? "text-black" : "text-lightGray"}`} width="16" height="16" />                        
                    </p>
                    {state.isProviderOpen ?
                        <div className='relative'>
                        <MultiSelectSection 
                            options={state.imageFIlters && state.imageFIlters.provider ? state.imageFIlters.provider : []}
                            selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                            callbackMultiSelect={(value) => {
                                if(!value || typeof(value) === 'string') {
                                    setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: [], selectedRegion: [], selectedImageType: '', selectedImage: '', onChangeFilter: true }))
                                } else {
                                    value.preventDefault()
                                    handleChildClick(value, "search", 'singleDropDown', "")
                                }
                            }}
                            widthClass={'minWidth220'}
                            singleSelection={true}
                            removeTopOptions={true}
                            makeLabelUpperCase={'capitalizeAllLetter'}
                        />
                        </div>
                    : null}
                </div>
            : null}
            {props.filters && props.filters.includes('account') && state.selectedProvider !== 'docker' ?
                <div className={`bg-black5 w-full h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer lg:right-auto right-0`} style={{minWidth: '120px', maxWidth: '120px'}}
                    onClick={(event) => {
                        event.preventDefault();
                        handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
                    }}
                >
                    <p className="text-xs text-black font-semibold">Account</p>
                    <p className={`flex justify-between text-sm font-medium mt-3`}>
                        <span className={`truncate text-sm font-bold ${state.selectedAccount && state.selectedAccount.length ? "text-black" : "text-lightGray"}`}>{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}</span>
                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedAccount && state.selectedAccount.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />                        
                    </p>    
                    {state.isAccountOpen ?
                        <div className='relative'>
                            <MultiSelectSection 
                                options={state.filterDetails && state.filterDetails.account_id ? state.filterDetails.account_id : []}
                                widthClass={'minWidth220'}
                                selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedAccount: value, selectedRegion: [], selectedImageType: '', selectedImage: '', onChangeFilter: true }))}
                                removeTopOptions={true}
                                className={'lg:right-auto md:right-0'}
                            />
                        </div>
                    : null}
                </div>
            : null}
            {props.filters && props.filters.includes('region') && state.selectedProvider !== 'docker' ?
                <div className={`bg-black5 w-full h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer lg:right-auto right-0`} style={{minWidth: '120px', maxWidth: '120px'}}
                    onClick={(event) => {
                        event.preventDefault();
                        handleChildClick(event, 'child', 'muliselectDropDown', "isRegionOpen")
                    }}
                >
                    <p className="text-xs text-black font-semibold">Region</p>
                    <p className={`flex justify-between text-sm font-medium mt-3`}>
                        <span className={`truncate text-sm font-bold ${state.selectedRegion && state.selectedRegion.length ? "text-black" : "text-lightGray"}`}>{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}</span>
                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion && state.selectedRegion.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />                        
                    </p>
                    {state.isRegionOpen ?
                        <div className='relative'>
                            <MultiSelectSection 
                                options={state.filterDetails && state.filterDetails.region ? state.filterDetails.region : []}
                                widthClass={'minWidth220'}
                                selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value, selectedImageType: '', selectedImage: '', onChangeFilter: true }))}
                                removeTopOptions={true}
                                className={'lg:right-auto md:right-0'}
                            />
                        </div>
                    : null}
                </div>
            : null}

            {props.filters && props.filters.includes('type') && state.selectedProvider !== 'docker' ?
                <div className={`bg-black5 w-full h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer`} style={{minWidth: '120px', maxWidth: '120px'}} 
                    onClick={(event) => {
                        if(!state.isimageBuilderTypeOpen) {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'singleDropDown', "isimageBuilderTypeOpen")
                        }
                }}>
                    <p className="text-xs text-black font-semibold">Type</p>
                    <p className={`flex justify-between text-sm font-medium mt-3`}>
                        <span className={`truncate text-sm font-bold ${state.selectedImageType ? "text-black" : "text-lightGray"}`}>{state.selectedImageType ? state.selectedImageType : 'Select'}</span>
                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedImageType ? "text-black" : "text-lightGray"}`} width="16" height="16" />                        
                    </p>
                    {state.isimageBuilderTypeOpen ?
                        <div className='relative'>
                            <MultiSelectSection
                                options={state.filterDetails && state.filterDetails.image_type ? state.filterDetails.image_type : []}
                                selectedValues={state.selectedImageType ? state.selectedImageType : ''}
                                callbackMultiSelect={(value) => {
                                    if(!value || typeof(value) === 'string') {
                                        setState(prevState => ({ ...prevState, selectedImageType: value, selectedImage: '', imageLoader: true, onChangeFilter: true }))
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                                removeTopOptions={true}
                                widthClass={'minWidth220'}
                                className={'lg:right-auto md:right-0'}
                            />
                        </div>
                    : null}
                </div>
            : null}

            {state.selectedImageType || state.selectedProvider === 'docker' || (props.filters && props.filters.includes('image_name')) ?
                <div className={`bg-black5 w-full h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer`} style={{minWidth: '120px', maxWidth: '120px'}} 
                    onClick={(event) => {
                        if(!state.isImageOpen) {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'singleDropDown', "isImageOpen")
                        }
                }}>
                    <p className="text-xs text-black font-semibold">
                        {(state.selectedProvider === 'docker' || (props.filters && props.filters.includes('image_name'))) ? 'Image' : 'OS'}
                    </p>
                    
                    <p className={`flex justify-between text-sm font-medium mt-3`}>
                        {state.imageLoader ? 
                            <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                <path d="M4 12a8 8 0 0112-6.9" />
                            </svg>
                        :
                            <span className={`truncate text-sm font-bold ${state.selectedImage ? "text-black" : "text-lightGray"}`}>{state.selectedImage ? state.selectedImage : 'Select'}</span>
                        }
                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion && state.selectedRegion.length ? "text-black" : "text-lightGray"}`} width="16" height="16" />                        
                    </p>
                    {state.isImageOpen && !state.imageLoader ?
                        <div className='relative'>
                            <MultiSelectSection
                                options={
                                    (state.selectedProvider === 'docker' || (props.filters && props.filters.includes('image_name'))) ?
                                        state.filterDetails && state.filterDetails.image_name ? state.filterDetails.image_name : []
                                    :
                                        state.filterDetails && state.filterDetails.os ? state.filterDetails.os : []

                                }
                                selectedValues={state.selectedImage ? state.selectedImage : ''}
                                callbackMultiSelect={(value) => {
                                    if(!value || typeof(value) === 'string') {
                                        setState(prevState => ({ ...prevState, selectedImage: value }))
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                                removeTopOptions={true}
                                widthClass={'minWidth220'}
                                className={'lg:right-auto md:right-0'}
                            />
                        </div>
                    : null}
                </div>
            : null}

            
            
            {props.filters && props.filters.includes('duration') ?
                <div>
                <div className={`bg-black5 w-full h-fit p-2 text-black flex flex-col justify-between rounded-md cursor-pointer`} style={{minWidth: '210px', maxWidth: '210px'}}
                    onClick={(event) => {
                        if(!state.isDurationOpen) {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'singleDropDown', "isDurationOpen")
                        }
                }}>
                    <p className="text-xs text-black font-semibold">Duration</p>
                    <p className={`flex justify-between text-sm font-medium mt-3`}>
                        <span className={`truncate text-sm font-bold ${state.datePickerStartDate ? "text-black" : "text-lightGray"}`}>{momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY')}</span>
                        <Icon icon="icon-park-solid:down-one" className={`${state.datePickerStartDate ? "text-black" : "text-lightGray"}`} width="16" height="16" />                        
                    </p>
                    {state.isDurationOpen ?
                        <div className='relative'>
                            <MultiSelectSection
                                fields={["value", "label"]}
                                options={state.imageDuration}
                                widthClass={'minWidth220'}
                                removeTopOptions={true}
                                selectedValues={state.selectedDuration ? state.selectedDuration : ''}
                                callbackMultiSelect={(value) => {
                                    if(typeof(value) === 'string') {
                                        if(value !== 'custom') {
                                            setState(prevState => ({ ...prevState, selectedDuration: value }))
                                        } else {
                                            setState(prevState => ({ ...prevState, showDateRangePicker: value === 'custom' ? true : false }))
                                        }
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                            />
                        </div>
                    : null}
                </div>
                {state.showDateRangePicker ?
                    <div className="relative" ref={durationRef}>
                        <div className="absolute text-black5 pt-2 ml-n1 md:right-0 right-auto z-10">
                            {/* <div className='flex justify-between'>
                                <p className="mb-2 w-100 text-black">Date Picker</p>
                                <i className="far fa-times self-center cursor-pointer f18 mb-1" onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false }))}></i>
                            </div> */}
                            <div className='headerDateRange' style={{zIndex: '999999999','right': '30px', 'top': '50px'}}>
                                <DateRange
                                    editableDateInputs={true}
                                    onChange={handleSelect}
                                    moveRangeOnFirstSelection={false}
                                    ranges={dateState}
                                />
                            </div>
                            <div className={`flex flex-wrap pt-2 border-top hidden`}>
                                <span className={`mr-2 f12 self-center text-black`}>Last</span>
                                <span className={`mr-2 font-weight-bold f12 cursor-pointer self-center ${state.selectedDuration === "+7d" ? "badge badge-primary text-black" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+7d" }))}>1 Week</span>
                                <span className={`mr-2 font-weight-bold f12 cursor-pointer self-center ${state.selectedDuration === "+14d" ? "badge badge-primary text-black" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+14d" }))}>2 Week</span>
                                <span className={`mr-2 font-weight-bold f12 cursor-pointer self-center ${state.selectedDuration === "+30d" ? "badge badge-primary text-black" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+30d" }))}>1 Month</span>
                                <span className={`mr-2 font-weight-bold f12 cursor-pointer self-center ${state.selectedDuration === "+60d" ? "badge badge-primary text-black" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+60d" }))}>2 Month</span>
                                <span className={`mr-2 font-weight-bold f12 cursor-pointer self-center ${state.selectedDuration === "+90d" ? "badge badge-primary text-black" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+90d" }))}>3 Months</span>
                            </div>
                            <div className='flex justify-end pt-2 mt-2 border-top hidden'>
                                <button className={`btn-sm bg-light text-decoration-nonep-2 text-black mr-3`} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false }))}>Cancel</button>
                            </div>
                        </div>
                    </div>
                : null}
                </div>
            : null}

            <div className='self-center mr-3'>
                <div className="flex space-x-0 justify-center" role="group">
                    <button type="button" className="bg-info hover:bg-blue-700 text-black border-r-2 font-bold px-2 py-1 rounded-l inline-flex items-center"
                        onClick={() => setState(prevState => ({ ...prevState, callSearch: true }))}
                    >
                        <span className='text-black'>Search</span>
                    </button>
                    <button type="button" className="bg-info hover:bg-red-700 text-black font-bold px-2 py-1 rounded-r inline-flex items-center"
                        onClick={() => onReset()}
                    >
                        <Icon icon="mdi:reload" className="text-black" width="18" height="18" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ImagebuilderFilterSection;