import React, { useState, useEffect, useCallback, useRef } from 'react';
// import { useDispatch } from 'react-redux';
// import { Spinner } from 'reactstrap';
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase } from '../../../utils/utility';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
// import { getJobMetrics } from '../../../actions/Collider/JobStatusAction';

// import MetricsTab from '../../common/Mertics/MetricsTab';


const CatalogDetailsSidePanel = ({ selectedDetails, closeSidePanel, detailsFormat }) => {
    // const dispatch = useDispatch(); // Create a dispatch function
    const clickOut = useRef();
    
    const [state, setState] = useState({
        showLoading: false,
        minMaxHeight: "770px",
        selectedTab: "Details",
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        logsList: [],
        filteredLogsList: []
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        getWindowSize();
    }, [handleKeyDown]);

    const getWindowSize = () => {
        let browserBorder = 100;
        let headerHeight = 120;
        let minMaxHeight = (window.outerHeight - browserBorder - headerHeight) + "px";
        setState(prevState => ({ ...prevState, minMaxHeight }))
    };

    // useEffect(() => {
    //     if (state.selectedTab === "Observability") {
    //         let params = {}
    //         params.start_time = selectedDetails && selectedDetails.start_time
    //         params.end_time = selectedDetails && selectedDetails.end_time
    //         params.asset_id = selectedDetails && selectedDetails.asset_id
    //         // Fetch job metrics data here using params and update the state
    //         dispatch(getJobMetrics(params))
    //                 .then((response) => {
    //                     setState(prevState => ({
    //                         ...prevState,
    //                         metricDetails: response,
    //                         showMetricLoading: false,
    //                     }));
    //             })
    //     }
    // }, [state.selectedTab, dispatch, selectedDetails])

    const handleChildClick = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
    }

    const getStatusColor = (status) => {        
        let color = 'border border-darkPurple-600 text-darkPurple-600'
        if(status.toLowerCase() === 'cancelled') { color = 'border border-independenceGray-600 text-independenceGray-600' } else 
        if(status.toLowerCase() === 'completed') { color = 'border border-slateBlue-600 text-slateBlue-600' } else 
        if(status.toLowerCase() === 'failed') { color = 'border border-red-600 text-red-600' } else 
        if(status.toLowerCase() === 'running') { color = 'border border-appleGreen-600 text-appleGreen-600' } else 
        if(status.toLowerCase() === 'stopped') { color = 'border border-blue-600 text-blue-600' } else 
        if(status.toLowerCase() === 'suspended') { color = 'border border-PrimaryLightOrange-600 text-PrimaryLightOrange-600' } else 
        if(status.toLowerCase() === 'timeout') { color = 'border border-yellowOrange-600 text-yellowOrange-600' }

        return color;
    }

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-4/6 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-white'>Catalog Details</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-white cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                    <div className={`mx-4 pt-4`}>
                        <div className="overflow-y-auto h-[calc(100vh-120px)] border border-NeutralGray-500 bg-PrimaryTintsPurple-600 rounded-2xl">
                            {state.selectedTab === "Details" ?
                                <div className="p-6">
                                    {detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                                        return(
                                            <div  key={'df_'+index} className={`border-b border-NeutralGray-600 pb-[40px] text-lg ${index ? 'pt-[40px]' : ''}`}>
                                                <p className={`text-primaryPurple-600 text-[22px] font-bold capitalize`}>{row.label}</p>
                                                <small className='text-NeutralGray-700'>{row.description}</small>
                                                {row.fields && row.fields.map((fld, fIndex) => {
                                                    return(
                                                        <div className="flex pt-8">
                                                            <span className="min-w-[222px] text-NeutralGray-600 self-center">{removeUnderScore(fld)}:</span>
                                                            {typeof selectedDetails[fld] === 'boolean' ?
                                                                <span className={`text-black`}>{selectedDetails[fld] ? 'True' : 'False'}</span>
                                                            : isValidDateTime(selectedDetails[fld]) ?
                                                                <span className="text-black">{momentConvertionUtcToLocalTime(selectedDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                            : convertToLowerCase(fld) === "status" ?
                                                                <span className={`rounded-full px-2 mb-2 ${getStatusColor(selectedDetails[fld])} mr-2 py-1 break-all`}>{selectedDetails[fld]}</span>
                                                            : Array.isArray(selectedDetails[fld]) ?
                                                                <div className="flex flex-wrap gap-2 self-center w-full lg:w-auto">
                                                                    {selectedDetails[fld].map((item, dIndex) => {
                                                                        return(
                                                                            <React.Fragment key={'kfld_'+dIndex}>
                                                                            {typeof item === "string" ?
                                                                                <span className="border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{item}</span>
                                                                            : 
                                                                                Object.entries(item).map(([key, value], keIndex) => {
                                                                                    return(
                                                                                        <span key={'kvFld_'+keIndex} className='border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{key +' : '+value}</span>
                                                                                    )
                                                                                })
                                                                            }
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : 
                                                                <span className="text-black break-all">{selectedDetails[fld]}</span>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                    
                                    {selectedDetails.installed_softwares && selectedDetails.installed_softwares.length ?
                                        typeof selectedDetails.installed_softwares[0] === "string" ?
                                            <div className="flex py-8">
                                                <span className="text-SlateBlue min-w-[222px] self-center">Installed Softwares</span>
                                                {selectedDetails.installed_softwares.map((item, soIndex) => {
                                                    return(
                                                        <span key={'soft_'+soIndex} className='border border-SlateBlue bg-SlateBlue px-2 rounded-full py-1 text-black mr-2 mb-1 text-sm'>{item}</span>
                                                    )
                                                })}
                                            </div>
                                        :
                                            <React.Fragment>
                                            <p className={`mb-1 text-primaryPurple-600 text-[22px] font-bold capitalize py-8`}>Installed Softwares</p>
                                            <div className='relative overflow-x-auto rounded-t-lg shadow border border-lightGray-600 pb-100'>
                                                <table className="w-full text-left">
                                                    <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600 rounded-t-lg'>
                                                        <tr className="flex-1 w-full">
                                                            <th className='tracking-wider px-3 py-3 text-left'>Software Name</th>
                                                            <th className='tracking-wider px-3 py-3 text-left'>Version</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='bg-BlockWhite text-black'>
                                                        {selectedDetails.installed_softwares && selectedDetails.installed_softwares.length ?
                                                            selectedDetails.installed_softwares.map((row, sftIndex)=> {
                                                                return(
                                                                    <tr key={"head1_"+sftIndex} className={`${sftIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
                                                                    {Object.entries(row).map(([key, value], sfinIndex) => {
                                                                        return(
                                                                            <td className="p-4 text-black text-left text-sm" key={'sftin_'+sfinIndex}>{value}</td>
                                                                        )
                                                                    })}
                                                                    </tr>
                                                                )
                                                            })
                                                        : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                            </React.Fragment>
                                    : null}
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CatalogDetailsSidePanel;