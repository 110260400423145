import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listClusterFilters } from '../../../actions/commonActionNew'
import { getHpcReportQueries } from '../../../actions/Collider/HpcReportAction'
import { listQueues, listUsersByCluster } from '../../../actions/Collider/ClusterAction';

import _ from 'lodash'
import { getAccountNameFromId, getRegionName, momentDateGivenFormat } from '../../../utils/utility'

import ReportSection from './ReportSection';
import Search from '../../common/SearchWithHiddenInput';
import FilterSection from '../../common/FiltersSection';
import {Icon} from "@iconify/react";

import DiskReportSection from './DiskReportSection';
import CostReportSection from './CostReportSection';
import ScheduleReport from './ScheduleReport';
import SelectOption from '../../common/Form/SelectOption';

const ReportSample = () => {
    const clickOutside = useRef();

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedDuration: 'currentMonth',

        selectedCategory: "All",
		startRecord: 0, 
		currentPage: 1,
		totalPages: 1,
		overAllTotalPages: 1,
		perPage: 10,

        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster', 'duration'],
        typeOptions: ['User', 'Location', 'Software']
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    // useEffect(() => {
    //     if (providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""), callSearch: true }));
    //     }
    // }, [providers]);

    useEffect(() => {
        let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        }
        setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));
     
    }, [state.selectedDuration])

    const getQueues = useCallback((item, selectedClusters) => {
        if(state.selectedProvider && item.category && item.category.includes('Queue')) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state['selectedClusters_'+item.report_id] || selectedClusters) {
                params.cluster_name = state['selectedClusters_'+item.report_id] ? state['selectedClusters_'+item.report_id] : selectedClusters
            }
            dispatch(listQueues(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, ['queues_'+item.report_id]: response, ['selectedQueues_'+item.report_id]: response && response.length ? response[0] : '' }));
                    }
            })
        }
    }, [dispatch, state])

    const getReportClusters = (account_id, region, item) => {
        let params = {}
        params.provider = state.selectedProvider.toLowerCase()
        if(account_id && account_id.length) {
            params.account_id = account_id
        }
        if(region && region.length) {
            params.region = region
        }
        params.aggregate_by = 'cluster_name'
        dispatch(listClusterFilters(params))
            .then((response) => {
                if(response) {
                    let selectedClusters = ((item.category && !item.category.includes('User') && !item.category.includes('User')) && (item.report_id === 'HPC014' || item.report_id === 'HPC017' || item.report_type === 'spendboard')) ? response[0] : []
                    setState(prevState => ({ ...prevState, ['clusters_'+item.report_id]: response, ['selectedClusters_'+item.report_id]: selectedClusters }));

                    getQueues(item, selectedClusters)
                }
        })
    }
                                                                                
    const getReportUsers = (account_id, region, cluster, report_id) => {
        let params = {}
        params.provider = state.selectedProvider.toLowerCase()
        if(account_id && account_id.length) {
            params.account_id = account_id
        }
        if(region && region.length) {
            params.region = region
        }
        if(cluster && ((Array.isArray(cluster) && cluster.length) || !Array.isArray(cluster))) {
            params.cluster_name = cluster
        }

        dispatch(listUsersByCluster(params))
            .then((response) => {
                if(response && !response.error) {
                    setState(prevState => ({ ...prevState, ['users_'+report_id]: response, filterClusterUserList: response }));
                }
            })

        // dispatch(listUserDetails(params))
        //     .then((response) => {
        //         if(response) {
        //             let totalResults = []

        //             let results = response && response.results && response.results.length ? response.results : []
        //             if(results && results.length) {
        //                 totalResults = totalResults.concat(results)
        //             }
                    
        //             setState(prevState => ({
        //                 ...prevState,
        //                 ['users_'+report_id]: totalResults
        //             }));
        //         }
        // })
    }

    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],            
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            datePickerStartDate: filter.datePickerStartDate,
            datePickerEndDate: filter.datePickerEndDate,
            callSearch: true,
            showLoading: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedAccount: [], selectedRegion: [], selectedClusters: [], selectedDuration: 'currentMonth', callSearch: true }));
    }

    useEffect(() => {
		if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {}
			params.provider = state.selectedProvider && state.selectedProvider.toLowerCase()
			if(state.selectedAccount && state.selectedAccount.length) {
				params.account_id = state.selectedAccount.filter(e => e !== "All")
			}
			
			if(state.selectedRegion && state.selectedRegion.length) {
				params.region = state.selectedRegion.filter(e => e !== "All")
			}
			
			if(state.selectedClusters && state.selectedClusters.length) {
				params.cluster_name = state.selectedClusters.filter(e => e !== "All")
			}
            
			dispatch(getHpcReportQueries(params))
				.then((response) => {
					if(response) {                        
                        // let dataRow = {
                        //     "category": [
                        //         "Cost",
                        //         "Queue",
                        //         "User"
                        //     ],
                        //     "description": "Cost Budget Report",
                        //     "order_by": "high",
                        //     "provider": [
                        //         "aws",
                        //         "gcp"
                        //     ],
                        //     "report_id": "HPC018",
                        //     "title": "Cost Budget Report"
                        // }

                        // response.unshift(dataRow)
						setState(prevState => ({ ...prevState, queryListJson: response, listLoading: false, formQueryList: true, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, queryListJson: [], listLoading: false, showLoading: false }))
					}
				})
		}
	}, [state.callSearch, dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters]) //state.selectedAccount, state.selectedRegion, state.selectedClusters

    useEffect(() => {
		if(state.formQueryList && state.queryListJson && state.queryListJson.length) {
            setState(prevState => ({ ...prevState, formQueryList: false }))
			let catArray = []
			let category = []
			state.queryListJson.forEach((item, index) => {
				item.category.forEach(cat => {
					let catRow = {}
					catRow.category = cat
					if(!category.includes(cat)) {
						category.push(cat)
					}
					if(item.sub_category) {
						catRow.sub_category = item.sub_category
					}
					catArray.push(catRow)
				})
			})
	
			let categories = []
			let totalCatCount = 0
			_.chain(catArray)
				.groupBy('category')
				.map((value, key) => ({ label: key, sub: value })).value()
				.forEach((category, i) => {
					let totalCount = 0
					let dataRow = {}
					dataRow.category = category.label
					let sub_category = []
					_.chain(category.sub)
					.groupBy('sub_category')
					.map((value, key) => ({ slabel: key, sSub: value })).value()
					.forEach((subCat, i) => {
						if(category.sub.filter(e => e.sub_category).length) {
							let subCatRow = {}
							subCatRow.sub_category = subCat.slabel
							subCatRow.count = subCat.sSub.length
							sub_category.push(subCatRow)
						}
						totalCatCount += subCat.sSub.length
						totalCount += subCat.sSub.length
					})
					dataRow.count = totalCount
					if(sub_category && sub_category.length) {
						dataRow.sub_category = sub_category
					}
                    categories.push(dataRow)
				})
	
			let dataRow = {}
			dataRow.category = "All"
			dataRow.count = totalCatCount
			dataRow.sub_category = ""	
			categories.push(dataRow)
	
			setState(prevState => ({ ...prevState, totalQueryList: state.queryListJson, queryList: state.queryListJson, categories: _.orderBy(categories, ['count'], ['desc']), getAllQueries: true, filterQueryList: true }))
		}
	}, [state.formQueryList, state.queryListJson])

	useEffect(() => {
		if(state.filterQueryList) {
			let data = state.totalQueryList
			if(state.selectedSubCategory) {
				data = data.filter(e => e.category.includes(state.selectedCategory) && e.sub_category === state.selectedSubCategory)
			} else if(state.selectedCategory && state.selectedCategory !== "" && state.selectedCategory !== "All") {
				data = data.filter(e => e.category.includes(state.selectedCategory))
			}
	
			let totalPages = 1
			if(data.length > state.perPage) {
				totalPages = Math.ceil(data.length / state.perPage)
			}

            // let filteredArray =   _.orderBy(data, ['report_id'], ['asc'])
	
			setState(prevState => ({ ...prevState, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, filterQueryList: false }))
		}
	}, [state.filterQueryList, state.perPage, state.selectedCategory, state.selectedSubCategory, state.totalQueryList])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    const querySelection = (i, item) => {
        let selectedReport = {}
        if(item !== state.selectedReport) {
            selectedReport = item
        }
        
        getReportClusters([], [], item)
        getReportUsers([], [], [], item.report_id)
        
        setState(prevState => ({  ...prevState, selectedReport, getUsers: true }))
    }

    const removeItem = (field, value) => {
        let results = state[field].filter(e => e !== value)

        setState(prevState => ({ ...prevState, [field]: results}))

    }

    const handleChildClick = (event, type, dropdownType, section) => {	
        
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='px-3' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap py-2 bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Reports</p>
                        <p className="text-white text-base">Consolidated view of reports</p>
                    </div>
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            {state.showSchedule ?
                <ScheduleReport  
                    selectedDetails={state.selectedReport}
                    closeSidePanel={() => setState(prevState => ({...prevState, showSchedule: false }))}
                />
            : null}
            {state.showReportDetails ?
                <ReportSection 
                    selectedProvider={state.selectedProvider}
                    accounts={state.accounts}
                    selectedAccount={state.selectedAccount}
                    regions={state.regions}
                    selectedRegion={state.selectedRegion}
                    selectedClusters={state.selectedClusters}
                    selectedReportInput={{
                        account_id: state['selectedAccount_'+state.selectedReport.report_id],
                        region: state['selectedRegion_'+state.selectedReport.report_id],
                        cluster_name: state['selectedClusters_'+state.selectedReport.report_id],
                        type: state['selectedType_'+state.selectedReport.report_id],
                        user_name: state['selectedUser_'+state.selectedReport.report_id],
                    }}
                    datePickerStartDate={state.datePickerStartDate}
                    datePickerEndDate={state.datePickerEndDate}
                    selectedReport={state.selectedReport}
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showReportDetails: false }))}
                />
            : state.showCostReport ?
                <CostReportSection
                    selectedProvider={state.selectedProvider}
                    accounts={state.accounts}
                    selectedAccount={state.selectedAccount}
                    regions={state.regions}
                    selectedRegion={state.selectedRegion}
                    selectedClusters={state.selectedClusters}
                    selectedReportInput={{
                        account_id: state['selectedAccount_'+state.selectedReport.report_id],
                        region: state['selectedRegion_'+state.selectedReport.report_id],
                        cluster_name: state['selectedClusters_'+state.selectedReport.report_id],
                        queue_name: state['selectedQueues_'+state.selectedReport.report_id],
                        project: state['selectedProject_'+state.selectedReport.report_id],
                        user_name: state['selectedUser_'+state.selectedReport.report_id],
                    }}
                    datePickerStartDate={state.datePickerStartDate}
                    datePickerEndDate={state.datePickerEndDate}
                    selectedReport={state.selectedReport}
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showCostReport: false }))}
                />
            // : state.showLicenseUsageReport
            //     <showLicenseUsageReport 
            //         selectedProvider={state.selectedProvider}
            //         accounts={state.accounts}
            //         selectedAccount={state.selectedAccount}
            //         regions={state.regions}
            //         selectedRegion={state.selectedRegion}
            //         selectedClusters={state.selectedClusters}
            //         selectedReportInput={{
            //             account_id: state['selectedAccount_'+state.selectedReport.report_id],
            //             region: state['selectedRegion_'+state.selectedReport.report_id],
            //             cluster_name: state['selectedClusters_'+state.selectedReport.report_id],
            //             user_name: state['selectedUser_'+state.selectedReport.report_id],
            //         }}
            //         datePickerStartDate={state.datePickerStartDate}
            //         datePickerEndDate={state.datePickerEndDate}
            //         selectedReport={state.selectedReport}
            //         closeSidePanel={() => setState(prevState => ({ ...prevState, showReportDetails: false }))}
            //     /> 
            // 
            : state.showDiskReport ? 
                <DiskReportSection
                    selectedProvider={state.selectedProvider}
                    accounts={state.accounts}
                    selectedAccount={state.selectedAccount}
                    regions={state.regions}
                    selectedRegion={state.selectedRegion}
                    selectedClusters={state.selectedClusters}
                    selectedReportInput={{
                        account_id: state['selectedAccount_'+state.selectedReport.report_id],
                        region: state['selectedRegion_'+state.selectedReport.report_id],
                        cluster_name: state['selectedClusters_'+state.selectedReport.report_id],
                        user_name: state['selectedUser_'+state.selectedReport.report_id],
                    }}
                    datePickerStartDate={state.datePickerStartDate}
                    datePickerEndDate={state.datePickerEndDate}
                    selectedReport={state.selectedReport}
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showDiskReport: false }))}
                />
            : null}
            <div className='pt-4'>
                <div className='bg-white rounded-2xl border border-DeepPurpleTints-600'>
                    <div className={`flex flex-wrap justify-between px-6 py-8 border-b border-DeepPurpleTints-600`}>
                        <div className='lg:w-1/2 w-full'>
                            <p className='text-2xl font-semibold'>All Reports</p>
                            <div className="flex text-sm lg:w-3/5 w-full">
                                <p className="self-center">Showing {state.filteredArray && state.filteredArray.length} of total {state.queryList && state.queryList.length}{state.queryList && state.queryList.length > 1 ? ' queries' : ' query'}</p>
                            </div>
                        </div>
                        <div className='lg:w-1/2 w-full flex justify-end'>
                            {state.queryList && state.queryList.length ?
                                <div className='w-1/2'>
                                    <Search
                                        data={state.queryList ? state.queryList : []}
                                        topClassName={'border border-NeutralGray-900 py-2.5 pr-4 pl-3 rounded-full bg-white'}
                                        className={'rounded-full'}
                                        filteredData={(filteredArray) => {
                                            setState(prevState => ({ ...prevState, filteredArray, startRecord: 0, currentPage: 1 }))
                                        }}
                                    />
                                </div>
                            : null}
                        </div>
                        <div className='flex justify-end w-full'>
                            {state.filteredArray && state.filteredArray.length > state.perPage ?
                                <div className='text-sm flex justify-end text-black lg:w-1/2 w-full text-sm'>
                                    <span className='mx-3 self-center'>Page {state.currentPage} of {state.totalPages} </span>
                                    <div className="flex text-sm">
                                        <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentPage === 1 ? 'pointer-events-none text-NeutralGray-600' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigatePage('previous', state.currentPage)}/></span> 
                                        <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-3 ${state.currentPage !== state.totalPages ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-600'}`} onClick={() => navigatePage('next', state.currentPage)}/></span>
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                    <div className='flex flex-wrap px-6 py-8'>
                        {state.categories ? 
                            <div className="lg:w-1/5 md:w-1/5 w-full lg:pr-3 md:pr-3 bg-NeutralGray-400 border border-NeutralGray-600 rounded-2xl pt-[22px] px-3 ">
                                {state.categories.map((item, index) => {
                                    return(
                                        <div className='pb-4' key={'cat_'+index}>
                                            {item.category === "All" ?
                                                <div className={`flex cursor-pointer text-base font-bold ${state.selectedCategory === item.category ? 'text-byzantineBlue pointer-events-none' : 'text-black'}`} onClick={() => {
                                                    setState(prevState => ({ ...prevState, selectedCategory: "All", filterQueryList: true }))
                                                }}>
                                                    <p className="m-0 text-lg">All Reports</p>
                                                </div>
                                            :
                                                <React.Fragment>
                                                <div className={`bg-white rounded-full py-3 px-5 text-base font-medium flex flex-wrap justify-between text-black ${item.category.includes(state.selectedCategory) ? 'pointer-events-none border-2 border-byzantineBlue' : ''}`} onClick={() => {
                                                    if(item.count && (state.selectedSubCategory || !item.category.includes(state.selectedCategory))) {
                                                        setState(prevState => ({ ...prevState, selectedCategory: item.category, filterQueryList: true }))
                                                    }
                                                }}>
                                                    <p className={`w-3/5`}>{item.category}</p>
                                                    <span className='font-bold'>{item.count !== '' ? item.count : ''}</span>
                                                </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        : null}
                        <div className={`pl-5 ${state.categories ? 'lg:w-4/5 md:w-4/5' : ''} w-full lg:mt-0 md:mt-0 mt-2`}>
                            {state.filteredArray && state.filteredArray.length ?
                                state.filteredArray.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                    return(
                                        <div className='flex flex-wrap bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl p-6 mb-6' key={'filArr_'+i}>
                                            <div id={'heading_'+i} className="cursor-pointer w-full" onClick={() => querySelection(i, item)}>
                                                <div className="font-semibold text-base flex">
                                                    {state.selectedReport && state.selectedReport.report_id === item.report_id ?
                                                        <Icon icon="zondicons:minus-outline" className='bg-pumpkin-600 h-6 w-6 text-white rounded-full self-center cursor-pointer mr-3' width={20} height={20} />
                                                    : 
                                                        <Icon icon="stash:plus-solid" className='bg-pumpkin-600 h-6 w-6 text-white rounded-full self-center cursor-pointer mr-3' width={20} height={20} />
                                                    }
                                                    {item.report_id +" - "+ (item.title ? item.title : "")}
                                                </div>
                                            </div>
                                            {state.selectedReport && state.selectedReport.report_id === item.report_id ?
                                                <div className={`py-3 w-full`}>
                                                    <p className='flex flex-wrap'>
                                                        <span className='mr-2 self-end text-black'>Account</span>
                                                        <div className='w-32 self-end'>
                                                            <SelectOption
                                                                label={""}
                                                                fields={["account_id", "account_name"]}
                                                                options={propProjAccounts[state.selectedProvider]}
                                                                selectedValues={state['selectedAccount_'+item.report_id] ? state['selectedAccount_'+item.report_id] : []}
                                                                callbackMultiSelect={(value) => {
                                                                    setState(prevState => ({ ...prevState, ['selectedAccount_'+item.report_id]: value, ['selectedClusters_'+item.report_id]: [], ['selectedUser_'+item.report_id]: '' }))
                                                                    getReportClusters(value, state['selectedRegion_'+item.report_id], item)
                                                                    getReportUsers(value, state['selectedRegion_'+item.report_id], '', item.report_id)
                                                                }}
                                                                singleSelection={false}
                                                                manditory={false}
                                                                dropdownWidth={'min-w-32'}
                                                                inputType={state.pageType}
                                                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                                            />
                                                        </div>
                                                        {state['selectedAccount_'+item.report_id] && state['selectedAccount_'+item.report_id].length ? 
                                                            state['selectedAccount_'+item.report_id].map((tab, addIndex) => {
                                                                return(
                                                                    <span key={'tab2_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
                                                                    {getAccountNameFromId(tab, state.accounts)}
                                                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center cursor-pointer' 
                                                                        onClick={() => {
                                                                            removeItem('selectedAccount_'+item.report_id, tab)
                                                                        }}/>
                                                                    </span>
                                                                )
                                                            })
                                                        : null}

                                                        <span className='mx-2 self-end text-black'>Region</span>
                                                        <div className='w-32 self-end'>
                                                            <SelectOption
                                                                label={""}
                                                                fields={["region", "name"]}
                                                                options={propProjRegions[state.selectedProvider]}
                                                                selectedValues={state['selectedRegion_'+item.report_id] ? state['selectedRegion_'+item.report_id] : []}
                                                                callbackMultiSelect={(value) => {
                                                                    setState(prevState => ({ ...prevState, ['selectedRegion_'+item.report_id]: value, ['selectedClusters_'+item.report_id]: [], ['selectedUser_'+item.report_id]: '' }))
                                                                    getReportClusters(state['selectedAccount_'+item.report_id], value, item)
                                                                    getReportUsers(state['selectedAccount_'+item.report_id], value, '', item.report_id)
                                                                }}
                                                                singleSelection={false}
                                                                manditory={false}
                                                                dropdownWidth={'min-w-32'}
                                                                inputType={state.pageType}
                                                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                                            />
                                                        </div>
                                                        {state['selectedRegion_'+item.report_id] && state['selectedRegion_'+item.report_id].length ? 
                                                            state['selectedRegion_'+item.report_id].map((tab, addIndex) => {
                                                                return(
                                                                    <span key={'tab2_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
                                                                    {getRegionName(tab, state.regions)}
                                                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center cursor-pointer' 
                                                                        onClick={() => {
                                                                            removeItem('selectedRegion_'+item.report_id, tab)
                                                                        }}/>
                                                                    </span>
                                                                )
                                                            })
                                                        : null}

                                                        <span className='mx-2 self-end text-black'>Cluster</span>
                                                        <div className='w-32 self-end'>
                                                            <SelectOption
                                                                label={""}
                                                                options={state['clusters_'+item.report_id]}
                                                                selectedValues={state['selectedClusters_'+item.report_id] ? state['selectedClusters_'+item.report_id] : ''}
                                                                callbackMultiSelect={(value) => {
                                                                    if(item.category && !item.category.includes('Project') && !item.category.includes('User') && (item.report_id === 'HPC014' || item.report_id === 'HPC017'|| item.report_type === 'spendboard')) {
                                                                        if(!value || typeof(value) === 'string') {
                                                                            setState(prevState => ({ ...prevState, ['selectedClusters_'+item.report_id]: value, ['selectedUser_'+item.report_id]: ((item.category && !item.category.includes('Project') && !item.category.includes('User') && (item.report_id === 'HPC014' || item.report_id === 'HPC017'|| item.report_type === 'spendboard')) ? '' : [])}))
                                                                            getReportUsers(state['selectedAccount_'+item.report_id], state['selectedRegion_'+item.report_id], value, item.report_id)
                                                                        } else {
                                                                            value.preventDefault()
                                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                                        }
                                                                    } else {
                                                                        setState(prevState => ({ ...prevState, ['selectedClusters_'+item.report_id]: value, ['selectedUser_'+item.report_id]: '' }))
                                                                        getReportUsers(state['selectedAccount_'+item.report_id], state['selectedRegion_'+item.report_id], value, item.report_id)
                                                                    }
                                                                    
                                                                }}
                                                                singleSelection={(item.category && !item.category.includes('Project') && !item.category.includes('User') && (item.report_id === 'HPC014' || item.report_id === 'HPC017'|| item.report_type === 'spendboard')) ? true : false}
                                                                manditory={false}
                                                                dropdownWidth={'min-w-32'}
                                                                inputType={state.pageType}
                                                                classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                                            />
                                                        </div>
                                                        {state['selectedClusters_'+item.report_id] && Array.isArray(state['selectedClusters_'+item.report_id]) ? 
                                                            state['selectedClusters_'+item.report_id].map((tab, addIndex) => {
                                                                return(
                                                                    <span key={'clus_'+addIndex} className={`flex border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
                                                                        {tab}
                                                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center cursor-pointer' 
                                                                        onClick={() => {
                                                                            removeItem('selectedClusters_'+item.report_id, tab)
                                                                        }}/>
                                                                    </span>
                                                                )
                                                            })
                                                        : null}
                                                        
                                                        {item.report_type === 'spendboard' && item.category && item.category.includes('Queue') ?
                                                            <React.Fragment>
                                                            <span className='mx-2 self-end text-black'>Queue</span>
                                                            <div className='w-32 self-end'>
                                                                <SelectOption
                                                                    label={""}
                                                                    options={state['queues_'+item.report_id]}
                                                                    selectedValues={state['selectedQueues_'+item.report_id] ? state['selectedQueues_'+item.report_id] : ''}
                                                                    callbackMultiSelect={(value) => {
                                                                        if(item.report_id === 'HPC014' || item.report_id === 'HPC017'|| item.report_type === 'spendboard') {
                                                                            if(!value || typeof(value) === 'string') {
                                                                                setState(prevState => ({ ...prevState, ['selectedQueues_'+item.report_id]: value, ['selectedUser_'+item.report_id]: '' }))
                                                                                getReportUsers(state['selectedAccount_'+item.report_id], state['selectedRegion_'+item.report_id], value, item.report_id)
                                                                            } else {
                                                                                value.preventDefault()
                                                                                handleChildClick(value, "search", 'singleDropDown', "")
                                                                            }
                                                                        } else {
                                                                            setState(prevState => ({ ...prevState, ['selectedQueues_'+item.report_id]: value, ['selectedUser_'+item.report_id]: '' }))
                                                                        }
                                                                        
                                                                    }}
                                                                    singleSelection={true}
                                                                    manditory={false}
                                                                    dropdownWidth={'min-w-32'}
                                                                    inputType={state.pageType}
                                                                    classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                                                />
                                                            </div>
                                                            </React.Fragment>
                                                        : null}
                                                        {item.report_type === 'spendboard' && item.category && item.category.includes('Project') ?
                                                            <React.Fragment>
                                                            <span className='mx-2 self-end text-black'>Project</span>
                                                            <div className='w-32 self-end'>
                                                                <SelectOption
                                                                    label={""}
                                                                    fields={['project', 'project']}
                                                                    options={propProjects[state.selectedProvider]}
                                                                    selectedValues={state['selectedProject_'+item.report_id] ? state['selectedProject_'+item.report_id] : ''}
                                                                    callbackMultiSelect={(value) => {
                                                                        if(item.report_id === 'HPC014' || item.report_id === 'HPC017'|| item.report_type === 'spendboard') {
                                                                            if(!value || typeof(value) === 'string') {
                                                                                setState(prevState => ({ ...prevState, ['selectedProject_'+item.report_id]: value, ['selectedUser_'+item.report_id]: '' }))
                                                                                getReportUsers(state['selectedAccount_'+item.report_id], state['selectedRegion_'+item.report_id], value, item.report_id)
                                                                            } else {
                                                                                value.preventDefault()
                                                                                handleChildClick(value, "search", 'singleDropDown', "")
                                                                            }
                                                                        } else {
                                                                            setState(prevState => ({ ...prevState, ['selectedProject_'+item.report_id]: value, ['selectedUser_'+item.report_id]: '' }))
                                                                        }
                                                                        
                                                                    }}
                                                                    singleSelection={true}
                                                                    manditory={false}
                                                                    dropdownWidth={'min-w-32'}
                                                                    inputType={state.pageType}
                                                                    classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                                                />
                                                            </div>
                                                            </React.Fragment>
                                                        : null}
                                                        

                                                        {item.report_type !== 'spendboard' || (item.report_type === 'spendboard' && item.category.includes('User')) ?
                                                            <React.Fragment>
                                                            <span className='mx-2 self-end text-black'>User</span>
                                                            <div className='w-32 self-end'>
                                                                <SelectOption
                                                                    label={""}
                                                                    fields={["userId", "shortName"]}
                                                                    options={state['users_'+item.report_id]}
                                                                    selectedValues={state['selectedUser_'+item.report_id] ? state['selectedUser_'+item.report_id] : ''}
                                                                    callbackMultiSelect={(value) => {
                                                                        if(!value || typeof(value) === 'string') {
                                                                            setState(prevState => ({ ...prevState, ['selectedUser_'+item.report_id]: value }))
                                                                        } else {
                                                                            value.preventDefault()
                                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                                        }
                                                                    }}
                                                                    singleSelection={true}
                                                                    manditory={false}
                                                                    dropdownWidth={'min-w-32'}
                                                                    inputType={state.pageType}
                                                                    classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                                                />
                                                            </div>
                                                            </React.Fragment>
                                                        : item.report_id === 'HPC017' && item.report_type !== 'spendboard' ?
                                                            <React.Fragment>
                                                            <span className='mx-2 self-end text-black'>Type</span>
                                                            <div className='w-32 self-end'>
                                                                <SelectOption
                                                                    label={""}
                                                                    options={state.typeOptions}
                                                                    selectedValues={state['selectedType_'+item.report_id] ? state['selectedType_'+item.report_id] : ''}
                                                                    callbackMultiSelect={(value) => {
                                                                        if(!value || typeof(value) === 'string') {
                                                                            setState(prevState => ({ ...prevState, ['selectedType_'+item.report_id]: value}))
                                                                        } else {
                                                                            value.preventDefault()
                                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                                        }
                                                                    }}
                                                                    singleSelection={true}
                                                                    manditory={false}
                                                                    dropdownWidth={'min-w-32'}
                                                                    inputType={state.pageType}
                                                                    classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: "bg-transparent", rounded: " "}}
                                                                />
                                                            </div>
                                                            </React.Fragment>
                                                        : null}
                                                    </p>
                                                    <div className={`flex justify-end w-full mt-6 ${state.queryLoading ? "pointer-events-none" : ""}`}>
                                                        <span className={`flex mb-0 text-white bg-primaryPurple-600 pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base mr-3  min-w-28`} onClick={() => setState(prevState => ({ ...prevState, showSchedule: true }))}>
                                                            <Icon icon="mingcute:schedule-line" className={`mr-1 text-white cursor-pointer`} width={20} height={20} /> Schedule
                                                        </span>
                                                        <span className={`mb-0 text-white bg-pumpkin-600 pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base mr-3  min-w-28
                                                            ${state.hasError ? "" : ""}`} 
                                                                onClick={() => {
                                                                    if(state.selectedReport && state.selectedReport.report_id === 'HPC014') {
                                                                        setState(prevState => ({ ...prevState, showDiskReport: true }))
                                                                    } else if(state.selectedReport && state.selectedReport.report_id === 'HPC0171') {
                                                                        setState(prevState => ({ ...prevState, showLicenseUsageReport: true }))
                                                                    } else if(state.selectedReport && state.selectedReport.report_type === 'spendboard') {
                                                                        setState(prevState => ({ ...prevState, showCostReport: true }))  
                                                                    } else {
                                                                        setState(prevState => ({ ...prevState, showReportDetails: true }))
                                                                    }
                                                                }}
                                                        >
                                                            Run Report
                                                        </span>
                                                        <span className={`mb-0 text-white bg-DeepPurpleTints-600 pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base min-w-28 text-center`}onClick={() => setState(prevState => ({ ...prevState, ['expand_'+i]: false, selectedQuery: "", selectedReport: {} }))}>Close</span>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    )
                                })
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportSample