/*************************************************
 * Tvastar
 * @exports
 * @file ApexRiskStackedBarChart.js
 * @author Prakash // on 09/01/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../utils/utility'
import _ from 'lodash'
import Chart from 'react-apexcharts'


const ApexRiskStackedBarChart = (props) => {

    const [state, setState] = useState({})

    const getStatusTextColor = (text) => {
        let color = 'border-2 border-darkPurple-600'
        if(text.toLowerCase() === 'critical') { color = 'text-red-600' } else 
        if(text.toLowerCase() === 'high') { color = 'text-slateBlue-600' } else 
        if(text.toLowerCase() === 'medium') { color = 'text-green-600' } else 
        if(text.toLowerCase() === 'low') { color = 'text-blue-600' } else 
        if(text.toLowerCase() === 'detected') { color = 'text-red-600' } else 
        if(text.toLowerCase() === 'redacted') { color = 'text-slateBlue-600' }

        return color;
    }

    const getStatusBgColor = (text) => {
        let color = 'border-2 border-darkPurple-600'
        if(text.toLowerCase() === 'critical') { color = 'bg-red-600' } else 
        if(text.toLowerCase() === 'high') { color = 'bg-slateBlue-600' } else 
        if(text.toLowerCase() === 'medium') { color = 'bg-green-600' } else 
        if(text.toLowerCase() === 'low') { color = 'bg-blue-600' } else 
        if(text.toLowerCase() === 'detected') { color = 'bg-red-600' } else 
        if(text.toLowerCase() === 'redacted') { color = 'bg-slateBlue-600' }

        return color;
    }
    
    useEffect(() => {
        let graphData = props.graphData;
        if(graphData){
            let graphLables = []
            let colors = props.colors ? props.colors : ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']
            let totalCount = 0
            let series = []
            if(graphData.labels) {
                graphLables = graphData.labels
            } else {
                let data = {}
                if(graphData.Critical) {
                    graphLables = graphData.Critical.labels
                    data.Critical = graphData.Critical.data && graphData.Critical.data.fail ? graphData.Critical.data.fail : graphData.Critical.data
                } else {
                    data.Critical = []
                }
                if(graphData.High) {
                    graphLables = graphData.High.labels
                    data.High = graphData.High.data && graphData.High.data.fail ? graphData.High.data.fail : graphData.High.data
                } else {
                    data.High = []
                }
                if(graphData.Medium) {
                    graphLables = graphData.Medium.labels
                    data.Medium = graphData.Medium.data && graphData.Medium.data.fail ? graphData.Medium.data.fail : graphData.Medium.data
                } else {
                    data.Medium = []
                }
                if(graphData.Low) {
                    graphLables = graphData.Low.labels
                    data.Low = graphData.Low.data && graphData.Low.data.fail ? graphData.Low.data.fail : graphData.Low.data
                } else {
                    data.Low = []
                }
                graphData.data = data
            }
            Object.keys(graphData.data).forEach((key, index) => {
                let dataArray =[]
                let orderBy = 0
                let riskColor = ''
                for(let x=0;x<graphLables.length;x++) {
                    let date = momentConvertionUtcToLocalTime(graphLables[x], 'DD MMM Y')
                    let dataItems = {}
                    dataItems.x = date
                    dataItems.y = graphData.data[key] && graphData.data[key].length ? graphData.data[key][x]  : 0
                    
                    if(key.toLowerCase() === 'critical') {
                        riskColor = colors[0]
                        orderBy = 1
                    } else if(key.toLowerCase() === 'high') {
                        riskColor = colors[1]
                        orderBy = 2
                    } else if(key.toLowerCase() === 'medium') {
                        riskColor = colors[2]
                        orderBy = 3
                    } else if(key.toLowerCase() === 'low') {
                        riskColor = colors[3]
                        orderBy = 4
                    } else if(key.toLowerCase() === 'detected') {
                        riskColor = colors[0]
                        orderBy = 1
                    } else if(key.toLowerCase() === 'redacted') {
                        riskColor = colors[1]
                        orderBy = 2
                    }
                    dataArray.push(dataItems)
                }
                
                let count = graphData.data[key].reduce(function(a, b){
                    return a + b;
                }, 0);

                totalCount += count

                let seriesRow = {}
                seriesRow.name = capitalizeFirstLetter(key)
                seriesRow.color = riskColor
                seriesRow.data = dataArray
                seriesRow.count = count
                seriesRow.orderBy = orderBy
                series.push(seriesRow)
            })

            series = _.orderBy(series, ['orderBy'], ['asc'])
        
            let backgroundColors = {}
            if(props.showBackgroundBarColors) {
                backgroundColors = {
                    ranges: [{
                        from: 1,
                        to: 0.9,
                        color: 'red'
                    }],
                    backgroundBarColors: props.backgroundBarColors ? props.backgroundBarColors : ['#EEEEEE'],
                    backgroundBarOpacity: 1,
                    // backgroundBarRadius: props.backgroundBarShape && props.backgroundBarShape === 'rounded' ? (props.barRadius ? props.barRadius : 10) : 0,
                    backgroundBarRadius: 0
                }
            }

            let options = {
                tooltip: {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let val  = series[seriesIndex][dataPointIndex];
                        //val = Math.abs(Math.round(val))
                        let labelName = w.globals.initialSeries[seriesIndex].name
                        //let val = str.replace('-',series[seriesIndex][dataPointIndex]);
                        return '<div class="arrow_box">' +
                            '<span> '+ w.globals.initialSeries[0].data[dataPointIndex].x + 
                            ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
                            '</div>'
                    },
                    style: {
                    fontSize: '9px',
                    },
                },				
                chart: {
                    redrawOnParentResize: true,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: props.sparkline
                    },
                    type: 'bar',
                    height: 150,
                    stacked: props.stacked,
                },
                colors: colors,
                plotOptions: {
                    bar: {
                        horizontal: props.horizontal,
                        barHeight: props.barHeight,
                        borderRadius: props.barEndShape && props.barEndShape === 'rounded' ? (props.barRadius ? props.barRadius : 10) : 0,
                        startingShape: 'flat',
                        endingShape: props.barEndShape ? props.barEndShape : 'flat',
                        columnWidth: props.columnWidth ? props.columnWidth : '60%',
                        distributed: false,
                        rangeBarOverlap: false,
                        colors: backgroundColors
                                
                    },       
                },
                dataLabels: {
                    enabled: false
                },                
                grid: {
                    show: false,			        
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: -5
                    },
                },
                xaxis: {
                    show: props.xaxis,
                    tooltip: {
                        enabled: false
                    },
                    axisTicks: {
                        show: false
                    },
                    type: 'datetime',
                    axisBorder: {
                        show: true,
                        color: '#434B5E',
                    },
                    labels: {
                        style: {
                            colors: props.axisLabelColor,
                            fontSize: '11px',
                            fontWeight: 'bolder',
                        },
                    },
                },
                yaxis: {
                    show: props.yaxis,
                    axisBorder: {
                        show: true,
                        color: '#434B5E',
                    },
                    labels: {
                        style: {
                            colors: props.axisLabelColor,
                            fontSize: '11px',
                            fontWeight: 'bolder',
                        },
                        formatter: function(val, index) {
                            if ((val / 1000) >= 1) {
                                val = val / 1000
                            }
                            if(val % 1 !== 0) {
                                val = val.toFixed(1);
                                return val + "K";
                            }
                            return val
                        },
                        offsetX: -15
                    },
                },
                legend: {
                    show: props.legend,
                    markers: {
                        radius: 12,
                    },
                    labels: {
                        colors: colors,
                        useSeriesColors: true
                    },
                },
            }
            setState(prevState => ({ ...prevState, series, options, totalCount }))
        }
    }, [props])

    return(
        <div className={`${props.className}`}>
            <div className={`flex justify-between ${props.graphRiskCountSection ? '' : 'hidden'}`}>
                <p className="text-lg font-medium text-black">Risk Count</p>
                <div className="flex">
                    {state.series && state.series.length > 0 && state.series.map((riskDetails, i) => {
                        let text = riskDetails.name
                        return (
                            <div key={i} className={`mr-3 flex`}>
                                {/* <h6 className="m-0">{riskDetails.count}</h6> */}
                                <p className={`${getStatusBgColor(text)} h-3 w-3 rounded-full self-center`}></p>
                                <p className={`text-xs ${getStatusTextColor(text)} self-center pl-1`}>{text}</p>
                            </div>
                            // <div key={i} className="mr-4" style={{color: color}}>
                            //     {/* <h6 className="m-0">{riskDetails.count}</h6> */}
                            //     <p className="m-0 text-nowrap small">{text}</p>
                            // </div>
                        )
                    })}
                </div>
            </div>
            {state.series ?
                <Chart options={state.options} series={state.series} type="bar" height={props.height} />
            : null}
        </div>
    )
}

export default ApexRiskStackedBarChart