/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file BudgetLandingPage.js
 * @author Prakash // on 17/05/2023
 * @copyright © 2023 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom'
import { listBudgetDetails, deleteBudgetDetails, setHpcPropsDetails } from '../../../actions/Collider/HpcAction'
import Button from '../../common/Form/Button';
import { capitalizeTheFirstLetterOfEachWord, getCommonInformationMessage, momentConvertionUtcToLocalTime } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { URL_PATH } from '../../../config/urlPath';
import { CSVLink } from 'react-csv';
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import AlertConfirmation from '../../common/AlertConfirmation';

const BudgetLandingPage = () => {
	const[state, setState] = useState({
		showLoading: true,
		forecastOptions: [
			{label: "Monthly", value: "monthly"},
			{label: "Quarterly", value: "quarterly"},
			{label: "Half Yearly", value: "half-yearly"},
			{label: "Annual", value: "yearly"},
		],
		// selectedPeriod: "quarterly",
		totalItemShowing: 5,
		itemMovedLeft: 0,
		itemMovedRight: 0,

		emptyOptions: [],
		actionsAllowed: [],
		callSearch: true
	})
	
	const userMenu = useSelector(state => state?.filters?.userMenu || false);
	
	const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function\
    const location = useLocation();
	const currentPath = location.pathname;

	useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
					let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				} else if(row.action) {
					actionsAllowed = row.action
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

	useEffect(() => {
		if(state.callSearch) {
			setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {}
			dispatch(listBudgetDetails(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, listBudgets: response, filterListBudgets: response, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, listBudgets: [], filterListBudgets: [], showLoading: false }))
					}
				})
		}
	}, [state.callSearch, dispatch])

	useEffect(() => {
        let dataResult = state.filterListBudgets && state.filterListBudgets.length ? state.filterListBudgets : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
    }, [state.filterListBudgets])	

    const navigateTo = () => {
        navigate(URL_PATH.HPC_CREATE_BUDGET); // Redirect to the specified path
    }

	const deleteRecord = () => {
		setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let params = {}
		console.log("state.deleteDetails", state.deleteDetails)
        params.budget_id = state.deleteDetails && state.deleteDetails.budget_id
        if(params.budget_id) {
			setState(prevState => ({ ...prevState, confirmDetails: false, deleteModal: false, showDeleteLoading: false, deleteDetails: {} }))
            dispatch(deleteBudgetDetails(params)).then((response) => {
                // setState(prevState => ({ ...prevState, showActionLoading: false, showUpdateActionLoading: false }))
                let messageType = 'success'
                let message = response.message
                if(!response.status) {   
                    messageType = 'danger'
					setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                } else {
                    setState(prevState => ({ ...prevState, deleteModal: false, callSearch: true, showDeleteLoading: false, deleteDetails: {} }))
                }
                // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })   
        }
	}

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	const onDelete = (item) => {
        let obj = {
            message: 'Are you sure you want to delete this budget' + (item.budget_name ? ' "'+item.budget_name+'"' : ''),
            buttonLabel: "Yes, I'm sure",
        }
        setState(prevState => ({ ...prevState, confirmDetails: obj, deleteDetails: item, deleteModal: true }))
    }

	/**
	 * Renders cost comparison Landing page
	*/
	return (
		<div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            
            <div className="top-0 relative z-10 py-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl px-6 py-[19px]">
                    <div className="w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Budgets</p>
						<p className="text-white text-base">Showing the list of Budgets</p>
					</div>			
				</div>
			</div>
			<div className={`flex flex-wrap pb-6 justify-between`}> 
				<div className="flex lg:w-3/5 w-full text-sm">
					<p className="m-0 self-center pt-1">Showing {state.filterListBudgets && state.filterListBudgets.length} out of total {state.listBudgets && state.listBudgets.length} budget(s)</p>
				</div>
				<div className="flex flex-wrap justify-end lg:w-2/5 w-full mb-n2">
					{state.listBudgets && state.listBudgets.length && state.listBudgets.length ?
						<div className='w-1/2'>
							<Search
								data={state.listBudgets ? state.listBudgets : []}
								topClassName={'border border-NeutralGray-900 rounded-full bg-white'}
								className={'rounded-full'}
								filteredData={(filterListBudgets) => setState(prevState => ({ ...prevState, filterListBudgets }))}
							/>
						</div>
					: null}
						<Button
							classDetails={{bg: 'bg-DeepPurpleTints-600', rounded: 'rounded-full', padding: 'py-2.5 px3', margin: 'mx-2', text: 'text-white text-base font-semibold', width: 'min-w-36', others: 'cursor-pointer self-center'}}
							label={'Create Budget'}
							callback={() => {
								dispatch(setHpcPropsDetails('createInputs', {}))
								dispatch(setHpcPropsDetails('selectedAplliesTo', {}))
								navigateTo()
							}}
						/>
					
					{state.filterListBudgets && state.filterListBudgets.length ?
						<CSVLink 
							data={state.filterListBudgets ? state.filterListBudgets : []} 
							headers={state.tableHeaders ? state.tableHeaders : []}
							filename={'job-lists.csv'}
							className={'mr-2 self-center'}
							target='_blank'
						>	
							<Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-8 w-8 px-1 rounded-full self-center cursor-pointer' width={24} height={24} />
						</CSVLink>
					: null}
				</div>
			</div>
			
			<div className="w-full">
				<div className="relative overflow-x-auto rounded-t-2xl shadow border border-lightGray-600">
					{state.filterListBudgets && state.filterListBudgets.length ?
						<ResizeableDarkThemeTable
							columns={[
								{
									Header: 'Date',
									accessor: d => d.modified_at ? "modified_at" : 'created_at',
									Cell: cellInfo => (
									<span>
										{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : '')}
									</span>
									),
									width: 200
								},
								{
									Header: 'Name',
									accessor: 'budget_name',
									// Cell: cellInfo => (
									// 	<div className="flex justify-between self-center">
									// 		{state.actionsAllowed && state.actionsAllowed.includes('update') ?
									// 			<Link 
									// 				to={URL_PATH.HPC_CREATE_BUDGET} 
									// 				state={{
									// 					selectedData: cellInfo.row.original,
									// 					pageType: 'edit',
									// 				}}
									// 				className='text-info'>
									// 				{cellInfo.row.original.budget_name}
									// 			</Link>
									// 		: null}
									// 	</div>
									// ),
									width: 200,
								},
								{
									Header: 'Year',
									accessor: 'financial_year',
									width: 100,
								},
								{
									Header: 'Department',
									accessor: 'department',
									width: 300,
								},
								{
									Header: 'Initiative',
									accessor: 'initiative',
									width: 300,
								},
								{
									Header: 'Project',
									accessor: 'project',
									width: 150,
								},
								{
									Header: 'Actions',
									Cell: cellInfo => (
										<div className="flex self-center">
											{state.actionsAllowed && state.actionsAllowed.includes('update') ?
												<Link 
													to={URL_PATH.HPC_CREATE_BUDGET} 
													state={{
														selectedData: cellInfo.row.original,
														pageType: 'edit',
													}}
													className='text-primaryPurple-600'>
													Edit
												</Link>
											: null}
											{state.actionsAllowed && state.actionsAllowed.includes('delete') ?
												<p className='ml-3 mb-0 text-ferrariRed-600 cursor-pointer' 
													onClick={() => onDelete(cellInfo.row.original)}>Delete</p>
											: null}
										</div>
									),
									width: 150
								},
								{
									Header: 'Description',
									accessor: 'description',
									width: 300
								},
							]}
							data={state.filterListBudgets}
							perPage={20}
							selectedColor={'bg-backGroundGray'}
							dashboard={state.filterListBudgets && state.filterListBudgets.length ? true : false}
							onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedlistBudgets: tableRow }))}
						/>
					: 
						<div className='flex justify-center m-4 text-black'>
							<p>No budget information available.</p>
						</div>
					}
				</div>
			</div>
			{state.confirmDetails && state.deleteModal ?
                <AlertConfirmation 
                    confirmDetails={state.confirmDetails}
                    confirmation={(deleteModal) => {
                        if(deleteModal) {
                            deleteRecord()
                        } else {
							setState(prevState => ({ ...prevState, confirmDetails: false, deleteModal: false, showDeleteLoading: false, deleteDetails: {} }))
						}
                    }}
                />
            : null}
		</div>
	)
}

export default BudgetLandingPage