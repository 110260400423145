/*************************************************
 * Tvastar
 * @exports
 * @file AppliesToSection.js
 * @author Prakash // on 03/04/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import _ from 'lodash'
import { listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues, setCommonPropsDetails } from '../../actions/commonActionNew'
import MultiSelectSection from '../common/MultiSelectSection';
import { setNewEditRuleDetails } from '../../actions/governance/GovernanceAction';
import { listClusters } from '../../actions/Collider/ClusterAction';
import {Icon} from "@iconify/react";
import SelectOption from '../common/Form/SelectOption';

const AppliesToSection = (props) => {
    const [state, setState] = useState({
		showAppliedSection: false,
		appliesToArray: [0],
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);
	const tagKeys = useSelector(state => state?.filters?.tagKeys);	
	const editNewRuleDetails = useSelector(state => state?.governance?.editNewRuleDetails)
    const appliesToError = useSelector(state => state?.governance?.governancePropsDetails?.appliesToError || false)

    const location = useLocation();
    const { ruleDetails } = location.state || false;
    
    // useEffect(() => {
    //     setState(prevState => ({ ...prevState, 'selected_account_0': '', 'selected_region_0': [], 'tag_key_0': '', 'tag_value_0': '', 'selected_users_0': [], 'selected_roles_0': [], 'cluster_name_0': '', 'cluster_id_0': '', 'name_space_to_0': '',  appliesToArray: [0] }))
    // }, [editNewRuleDetails.governance_type, ruleDetails])

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (editNewRuleDetails.provider) {
            let params = {};
            params.provider = editNewRuleDetails.provider.toLowerCase();
            let label = editNewRuleDetails.provider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [editNewRuleDetails.provider, propAccounts, dispatch]);

    useEffect(() => {
        if (editNewRuleDetails.provider) {
            let params = {};
            params.provider = editNewRuleDetails.provider.toLowerCase();
            let label = editNewRuleDetails.provider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [editNewRuleDetails.provider, propRegions, dispatch]);

    const callStateResetFunction = useCallback(() => {
        setState({ appliesToArray: [0], accounts: state.accounts, regions: state.regions })
    }, [state.accounts, state.regions])

    useEffect(() => {
        if(!ruleDetails || (ruleDetails && !Object.entries(ruleDetails).length)) {
            callStateResetFunction()
        }
    }, [ruleDetails, callStateResetFunction])

   

    const getClusterDetails = useCallback((account, region, item) => {
        let params = {};
        params.provider = editNewRuleDetails.provider
        
        if(account) {
            params.account_id = account
        }
        if(region && region.length) {
            params.region = region
        }
        dispatch(listClusters(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({ ...prevState, ['clusters_'+item]: response, ['selected_cluster_'+item]: [] }));
                }
        })
    }, [dispatch, editNewRuleDetails.provider])
    
    useEffect(() => {
		if(ruleDetails && Object.entries(ruleDetails).length && !_.isEqual(state.ruleDetailsState, ruleDetails)) {
            let policyBasicDetails = ruleDetails.policy_metadata ? ruleDetails.policy_metadata : {}
			setState(prevState => ({ ...prevState, ruleDetailsState: ruleDetails }))

			let applies_to = policyBasicDetails.applies_to ? policyBasicDetails.applies_to : []
			let appliesToArray = [0]
			if (applies_to && applies_to.length) {
				appliesToArray = []
				applies_to.forEach((row, index) => {
					appliesToArray.push(index)
					setState(prevState => ({ ...prevState, 
						['selected_account_' + index]: row.account_id,
						['selected_region_' + index]: row.region ? row.region : '',
                        ['selected_cluster_'+ index]: row.cluster_name ? row.cluster_name : [],
                        // appliesRow.cluster_name = state['selected_cluster_' + item] ? state['selected_cluster_' + item] : ''
						['selected_tags_' + index]: row.tags ? row.tags : [],
						['cluster_id_' + index]: row.cluster_id,
						// ['cluster_name_' + index]: row.cluster_name                       
					}))
				})
			}
            setState(prevState => ({ ...prevState, appliesToArray, applies_to, ruleDetailsState: ruleDetails }))
		}
	}, [ruleDetails, state.ruleDetailsState, dispatch])

    // useEffect(() => {
    //     let applies_to = editNewRuleDetails.applies_to ? editNewRuleDetails.applies_to : []
    //     let appliesToArray = [0]
    //     if (applies_to.length) {
    //         appliesToArray = []
    //         applies_to.forEach((row, index) => {
    //             appliesToArray.push(index)
    //             setState(prevState => ({ ...prevState, 
    //                 ['selected_account_' + index]: row.account_id,
    //                 ['selected_region_' + index]: row.region ? row.region : [],
    //                 ['selected_tags_' + index]: row.tags ? row.tags : [],
    //                 ['cluster_id_' + index]: row.cluster_id,
    //                 ['cluster_name_' + index]: row.cluster_name,
    //             }))
    //         })
    //     }

    //     setState(prevState => ({ ...prevState, appliesToArray, applies_to }))
    // }, [editNewRuleDetails])

    
    useEffect(() => {
        if (!tagKeys) {
            let params = {}
            dispatch(getAllTagsKeys(params))
        }
    }, [tagKeys, dispatch])

	const removeItem = (field, row) => {
		let filteredResult = state[field].filter(e => e !== row)
		setState(prevState => ({ ...prevState, [field]: filteredResult, onChangeApliesTo: true }))
	}

	const removeUserRolsSection = (field, item, index, row) => {
		let filteredResult = state[field + '_' + index].filter(e => e !== row)
		setState(prevState => ({ ...prevState, [field + '_' + index]: filteredResult, onChangeApliesTo: true }))
	}

	const getAllTagsValuesFunction = item => {
		let params = {}
		params.tags = [{ key: state['tag_key_' + item] }]
		dispatch(getAllTagsValues(params))
			.then((response) => {
				if (response) {
					setState(prevState => ({ ...prevState, ['tagValues' + item]: response }))
				}
			})
	}

	const addNewTag = item => {
		let selectedTags = state['selected_tags_' + item] ? state['selected_tags_' + item] : []

		if (
			state['tag_key_' + item] &&
			state['tag_key_' + item] !== '' &&
			state['tag_value_' + item] &&
			state['tag_value_' + item] !== ''
		) {
			let dataRow = {
				key: state['tag_key_' + item],
				value: state['tag_value_' + item],
			}
			selectedTags.push(dataRow)
		}

		setState(prevState => ({ 
			...prevState, 
			['selected_tags_' + item]: selectedTags,
			['tag_key_' + item]: '',
			['tag_value_' + item]: '',
			tagValues: [],
		}))
	}

	const removeTagSection = (item, tag) => {
		let filteredResult = state['selected_tags_' + item].filter(e => e !== tag)
		setState(prevState => ({ ...prevState, ['selected_tags_' + item]: filteredResult, onChangeApliesTo: true }))
	}

	const addNewNameSpace = item => {
		let selectedNameSpace = state['name_space_' + item] ? state['name_space_' + item] : []

		if (state['name_space_to_' + item] && state['name_space_to_' + item] !== '') {
			let data = state['name_space_to_' + item]

			selectedNameSpace.push(data)
		}

		setState(prevState => ({ ...prevState, 
			['name_space_' + item]: selectedNameSpace,
			['name_space_to_' + item]: '',
			onChangeApliesTo: true
		}))
	}

	const removeNameSpace = (item, spaceTo) => {
		let filteredResult = state['name_space_' + item].filter(e => e !== spaceTo)
		setState(prevState => ({ ...prevState, ['name_space_' + item]: filteredResult, onChangeApliesTo: true }))
	}

	const addSection = array => {
		let rowList = state[array]
		if (state[array]) {
			let value = state[array][state[array].length - 1]
			value = value + 1
			rowList.push(value)
		}

		setState(prevState => ({ ...prevState, [array]: rowList }))
	}

	const removeSection = (array, i, item) => {
        setState(prevState => ({
            ...prevState,
            ['selected_account_' + item]: '',
            ['selected_region_' + item]: '',
            ['selected_cluster_' + item]: [],            
            ['tag_key_' + item]: '',
            ['tag_value_' + item]: '',
            ['selected_users_' + item]: [],
            ['selected_roles_' + item]: [],
            ['cluster_name_' + item]: [],
            ['cluster_id_' + item]: '',
            ['name_space_to_' + item]: '',
        }))
		let rowList = state[array].filter(e => e !== i)
		setState(prevState => ({ ...prevState, [array]: rowList }))
	}

    const handleInputChange = useCallback((label, value) => {
		if (label === 'governance_type' && state.governance_type) {
			// if(window.confirm( 'Are you sure you want to delete this Card?') == true) {
			dispatch(setNewEditRuleDetails('policies', []))
			dispatch(setNewEditRuleDetails('applies_to', []))
			dispatch(setNewEditRuleDetails(label, value))

			if (state.appliesToArray && state.appliesToArray.length) {
				state.appliesToArray.forEach(item => {
					setState(prevState => ({
						...prevState,
						['selected_account_' + item]: '',
						['selected_region_' + item]: '',
                        ['selected_cluster_' + item]: [],
						['selected_users_' + item]: [],
						['selected_roles_' + item]: [],
						['selected_tags_' + item]: [],
						['tag_key_' + item]: '',
						['tag_value_' + item]: '',
						['cluster_name_' + item]: [],
						['cluster_id_' + item]: '',
						['name_space_' + item]: [],
					}))
				})
			}

			setState(prevState => ({ ...prevState, appliesToArray: [0] }))
		} else {
			dispatch(setNewEditRuleDetails(label, value))
		}

	}, [dispatch, state.appliesToArray, state.governance_type])

    useEffect(() => {
		if(state.onChangeApliesTo) {
			setState(prevState => ({ ...prevState, onChangeApliesTo: false }))
			let applies_to = []
	
			if (state.appliesToArray && state.appliesToArray.length) {
				if (state.appliesToArray) {
                    let appliesError = false
					state.appliesToArray.forEach(item => {
						let appliesRow = {}
						if (editNewRuleDetails && editNewRuleDetails.governance_type !== 'opa_kubernetes') {
                            if (state['selected_account_' + item]) {
								appliesRow.account_id = state['selected_account_' + item]
                                if (state.governance_type === 'infra') {
									appliesRow.tags =
										state['selected_tags_' + item] && state['selected_tags_' + item].length
											? state['selected_tags_' + item]
											: []
								} else {
									appliesRow.users =
										state['selected_users_' + item] && state['selected_users_' + item].length
											? state['selected_users_' + item]
											: []
									appliesRow.roles =
										state['selected_roles_' + item] && state['selected_roles_' + item].length
											? state['selected_roles_' + item]
											: []
								}
                            }
                            if(editNewRuleDetails.governance_type !== 'hpc-slurm') {
                                if(!state['selected_account_' + item]) {
                                    appliesError = true
                                } else {
                                    appliesRow.region = state['selected_region_' + item]
                                }
                            } else {
                                if(!state['selected_account_' + item] || !state['selected_region_' + item] || !state['selected_cluster_' + item] || !state['selected_cluster_' + item].length) {
                                    appliesError = true
                                } else {
                                    appliesRow.region = state['selected_region_' + item] ? state['selected_region_' + item] : []
                                    appliesRow.cluster_name = state['selected_cluster_' + item] ? state['selected_cluster_' + item] : []
                                }
                            }
							if(!appliesError) {
                                applies_to.push(appliesRow)
							}
						} else if (state['cluster_name_' + item] && state['cluster_name_' + item].length) {
							appliesRow.cluster_name = state['cluster_name_' + item]
							appliesRow.cluster_id = state['cluster_id_' + item]
							appliesRow.namespaces =
								state['name_space_' + item] && state['name_space_' + item].length
									? state['name_space_' + item]
									: []
							applies_to.push(appliesRow)
						}
					})
				}
			}
			setState(prevState => ({ ...prevState, applies_to }))
			setTimeout(() => { handleInputChange('applies_to', applies_to) }, 500);
		}
	}, [state.onChangeApliesTo, handleInputChange, editNewRuleDetails, state])

    const handleChildClick = (event, type, dropdownType, section) => {	
        event.stopPropagation();
    
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    }

    return (
        <div className={`mt-6 p-6 bg-white border border-NeutralGray-600 rounded-2xl`} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='flex justify-between'>
                <div className='flex text-lg font-bold'>
                    <Icon icon={`${state.showAppliedSection ? 'iconoir:minus' :'iconoir:plus'}`} className={`mr-6 self-center cursor-pointer text-white bg-PrimaryLightOrange-600 rounded-full`} width={24} height={24} onClick={() => setState(prevState => ({ ...prevState, showAppliedSection: !state.showAppliedSection }))} />
                    Step 1: Apply Policies
                </div>

                {state.showAppliedSection && editNewRuleDetails && editNewRuleDetails.governance_type !== 'opa_kubernetes' ?
                    <div className='flex'>
                        {appliesToError || state.appliesError ?
                            <span className='self-center ml-3 text-danger'>Please fill the apply section</span>
                        : null}
                    </div>  
                : state.showAppliedSection && editNewRuleDetails && (editNewRuleDetails.governance_type === 'opa_kubernetes') ?
                    <div className='flex'>
                        {state.hasError && state.appliesToArray.length === 1 && (!state.appliesToArray[0].cluster_id || !state.appliesToArray[0].cluster_name ) ?
                            <span className='self-center ml-3 text-danger'>Please fill apply the section</span>
                        : null }
                    </div>
                : null}
            </div>

            <div className={`${state.showAppliedSection ? 'mt-5 border-t border-NeutralGray-600 pt-5' : ''}`}>
                {state.showAppliedSection && editNewRuleDetails && editNewRuleDetails.governance_type !== 'opa_kubernetes' ?
                    state.appliesToArray.map((item, itemIndex) => {
                        return(
                            <React.Fragment key={'appliesTo_'+item}>
                            <div className='border boder-NeutralGray-600 bg-NeutralGray-100 p-6 rounded-2xl mt-6'>
                                <div className='flex flex-wrap'>
                                    <p className={`text-sm font-semibold self-end text-black`}>Restricted to the account</p>
                                    <div className='ml-2 self-end'>
                                        <SelectOption
                                            label={""}
                                            fields={["account_id", "account_name"]}
                                            options={state.accounts}
                                            selectedValues={state['selected_account_' + item] ? state['selected_account_' + item] : ''}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState, ['selected_account_' + item]: value, ['selected_users_' + item]: [], ['selected_roles_' + item]: [], onChangeApliesTo: true }))
                                                getClusterDetails(value, [], item)
                                            }}
                                            singleSelection={true}
                                            dropdownWidth={'min-w-40'}
                                            classDetails={{border: "border-b border-NeutralGray-600", rounded: ' ', padding: 'p-1', bg: ' '}}
                                        />
                                    </div>
                                    <p className={`text-sm font-semibold self-end text-black ml-2`}>in region</p>
                                    <div className='ml-2 self-end'>
                                        <SelectOption
                                            label={""}
                                            fields={["region", "name"]}
                                            options={state.regions}
                                            selectedValues={state['selected_region_' + item] ? state['selected_region_' + item] : (editNewRuleDetails.governance_type === 'hpc-slurm' ? '' : [])}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState, ['selected_region_' + item]: value, onChangeApliesTo: true }))
                                                getClusterDetails(state['selected_account_' + item], value, item)
                                            }}
                                            singleSelection={editNewRuleDetails.governance_type === 'hpc-slurm' ? true : false}
                                            dropdownWidth={'min-w-40'}
                                            classDetails={{border: "border-b border-NeutralGray-600", rounded: ' ', padding: 'p-1', bg: ' '}}
                                        />
                                    </div>
                                    {editNewRuleDetails.governance_type === 'hpc-slurm' ?
                                        <React.Fragment>
                                        <p className={`text-sm font-semibold self-end text-black ml-2`}>with cluster</p>
                                        <div className='ml-2 self-end'>
                                            <SelectOption
                                                label={""}
                                                options={state['clusters_'+item]}
                                                selectedValues={state['selected_cluster_' + item] ? state['selected_cluster_' + item] : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, ['selected_cluster_' + item]: value, onChangeApliesTo: true }))
                                                }}
                                                dropdownWidth={'min-w-40'}
                                                classDetails={{border: "border-b border-NeutralGray-600", rounded: ' ', padding: 'p-1', bg: ' '}}
                                            />
                                        </div>
                                        {state['selected_cluster_' + item] && state['selected_cluster_' + item].map((row, index) => {
                                            return(
                                                <span key={'reg_'+index} className='flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all self-center mt-2'>
                                                    {row}
                                                    <Icon icon="ion:close-circle-sharp" width={24} height={24}  className='text-black ml-2 self-center' onClick={() => removeItem('selected_cluster_'+item, row)} />
                                                </span>
                                            )
                                        })}
                                        </React.Fragment>
                                    : null}

                                    {state.governance_type === 'infra' ? (
                                        <React.Fragment>
                                            <p className={`ml-2 mr-2 mt-2 mb-1 self-end text-black`}>tags</p>
                                            <div className='w-44'
                                                onClick={(event) => {
                                                    if(!state["show_applies_tag_key_"+item]) {
                                                        event.preventDefault();
                                                        handleChildClick(event, 'child', 'singleDropDown', "show_applies_tag_key_"+item)
                                                    }
                                                }}
                                            >
                                                <p className={`flex justify-between hiddenEllipses mt-2 mb-0 pb-1 pl-2 boderDottedBottom ${state['tag_key_' + item] ? 'text-info' : 'text-lightGray'}`}>
                                                    {state['tag_key_' + item] ? state['tag_key_' + item] : 'Select key'}
                                                    <Icon icon="icon-park-solid:down-one" className={`${state.selectedGovernanceType ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                                </p>
                                                {state['show_applies_tag_key_'+item] ?
                                                    <MultiSelectSection
                                                        // fields={["account_id", "account_name"]}
                                                        options={tagKeys ? tagKeys : []}
                                                        selectedValues={state['tag_key_' + item] ? state['tag_key_' + item] : ''}
                                                        callbackMultiSelect={(value) => {
                                                            if(!value || typeof(value) === 'string') {
                                                                setState(prevState => ({ ...prevState, ['tag_key_' + item]: value, ['tagValues' + item]: [] }))
                                                                if(value) {
                                                                    getAllTagsValuesFunction(value, item)
                                                                }
                                                            } else {
                                                                value.preventDefault()
                                                                handleChildClick(value, "search", 'singleDropDown', "")
                                                            }
                                                        }}
                                                        singleSelection={true}
                                                        hideSearch={false}
                                                        topClass={'auto'}
                                                        widthClass={'minWidth220'}
                                                        removeTopOptions={true}
                                                    />
                                                : null}
                                            </div>
                                            <div className='w-44 mx-2'
                                                onClick={(event) => {
                                                    if(!state["show_applies_tag_value_"+item]) {
                                                        event.preventDefault();
                                                        handleChildClick(event, 'child', 'singleDropDown', "show_applies_tag_value_"+item)
                                                    }
                                                }}
                                            >
                                                <p className={`flex justify-between hiddenEllipses mt-2 mb-0 pb-1 pl-2 boderDottedBottom ${state['tag_value_' + item] ? 'text-info' : 'text-lightGray'}`}>
                                                    {state['tag_value_' + item] ? state['tag_value_' + item] : 'Select value'}
                                                    <Icon icon="icon-park-solid:down-one" className={`${state.selectedGovernanceType ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                                </p>
                                                {state['show_applies_tag_value_'+item] ?
                                                    <MultiSelectSection
                                                        // fields={["account_id", "account_name"]}
                                                        options={state.tagValues ? state.tagValues : []}
                                                        selectedValues={state['tag_value_' + item] ? state['tag_value_' + item] : ''}
                                                        callbackMultiSelect={(value) => {
                                                            if(!value || typeof(value) === 'string') {
                                                                setState(prevState => ({ ...prevState, ['tag_value_' + item]: value, onChangeApliesTo: true }))
                                                            } else {
                                                                value.preventDefault()
                                                                handleChildClick(value, "search", 'singleDropDown', "")
                                                            }
                                                        }}
                                                        singleSelection={true}
                                                        hideSearch={false}
                                                        topClass={'auto'}
                                                        widthClass={'minWidth220'}
                                                        removeTopOptions={true}
                                                    />
                                                : null}
                                            </div>
                                            <span
                                                className={`far fa-plus cursor-pointer f18 mt-2 mb-1 self-end mr-4`}
                                                onClick={() => addNewTag(item)}
                                            ></span>
                                            {state['selected_tags_' + item] && state['selected_tags_' + item].length ? 
                                                state['selected_tags_' + item].map((tag, index) => {
                                                    return(
                                                        <span key={'tag_'+index} className='flex border border-lightGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-info'>
                                                            {tag.key + ' : ' + tag.value}
                                                            <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeTagSection(item, tag)} />
                                                        </span>
                                                    )
                                                })
                                            : null}
                                        </React.Fragment>
                                    ) : state.governance_type === 'access' ? (
                                        <React.Fragment>
                                            <p className={`ml-2 mr-2 mt-2 mb-1 self-end text-black`}>user</p>
                                            <div className='w-44 ml-2'
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    handleChildClick(event, 'child', 'muliselectDropDown', "show_user_"+item)
                                                }}
                                            >
                                                <p className={`flex justify-between hiddenEllipses mt-2 mb-0 pb-1 pl-2 boderDottedBottom ${state['selected_users_' + item] && state['selected_users_' + item].length ? 'text-black' : 'text-lightGray'}`}>
                                                    {state['selected_users_' + item] && state['selected_users_' + item].length ? state['selected_users_' + item].length+' Selected' : 'Select'}
                                                    <Icon icon="icon-park-solid:down-one" className={`${state.selectedGovernanceType ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                                </p>
                                                {state["show_user_"+item] ?
                                                    <MultiSelectSection
                                                        fields={["region", "name"]}
                                                        options={state['applies_to_users_'+item]}
                                                        selectedValues={state['selected_users_' + item] ? state['selected_users_' + item] : ''}
                                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, ['selected_users_' + item]: value, onChangeApliesTo: true }))}
                                                        singleSelection={false}
                                                        hideSearch={false}
                                                        topClass={'auto'}
                                                        widthClass={'minWidth220'}
                                                        removeTopOptions={true}
                                                    />
                                                : null}
                                            </div>
                                            {state['selected_users_' + item] && state['selected_users_' + item].length ? (
                                                state['selected_users_' + item].includes('All') ||
                                                (state['selected_users_' + item].length ===
                                                    state['applies_to_users_' + item] &&
                                                    state['applies_to_users_' + item].length) ? (
                                                    <span className='flex border border-lightGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-info'>
                                                        All
                                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => setState(prevState => ({ ...prevState, ['selected_users_' + item]: [] }))} />
                                                    </span>
                                                ) : (
                                                    state['selected_users_' + item].map((row, index) => {
                                                        return(
                                                            <span key={'user_'+index} className='flex border border-lightGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-info'>
                                                                {row}
                                                                <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeUserRolsSection('selected_users', item, index, row)} />
                                                            </span>
                                                        )
                                                    })
                                                )
                                            ) : null}
                                            <p className={`ml-2 mr-2 mt-2 mb-1 self-end text-black`}>role</p>
                                            <div className='w-44 mx-2'
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    handleChildClick(event, 'child', 'muliselectDropDown', "show_role_"+item)
                                                }}
                                            >
                                                <p className={`flex justify-between hiddenEllipses mt-2 mb-0 pb-1 pl-2 boderDottedBottom ${state['selected_roles_' + item] && state['selected_roles_' + item].length ? 'text-black' : 'text-lightGray'}`}>
                                                    {state['selected_roles_' + item] && state['selected_roles_' + item].length ? state['selected_roles_' + item].length+' Selected' : 'Select'}
                                                    <Icon icon="icon-park-solid:down-one" className={`${state.selectedGovernanceType ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                                </p>
                                                {state["show_role_"+item] ?
                                                    <MultiSelectSection
                                                        fields={["region", "name"]}
                                                        options={state['applies_to_roles_'+item]}
                                                        selectedValues={state['selected_roles_' + item] ? state['selected_roles_' + item] : ''}
                                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, ['selected_roles_' + item]: value, onChangeApliesTo: true }))}
                                                        singleSelection={false}
                                                        hideSearch={false}
                                                        topClass={'auto'}
                                                        widthClass={'minWidth220'}
                                                        removeTopOptions={true}
                                                    />
                                                : null}
                                            </div>
                                            {state['selected_roles_' + item] && state['selected_roles_' + item].length ?
                                                state['selected_roles_' + item].includes('All') ||
                                                (state['selected_roles_' + item].length ===
                                                    state['applies_to_roles_' + item] &&
                                                    state['applies_to_roles_' + item].length) ?
                                                    <span className='flex border border-lightGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-info'>
                                                        All
                                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => setState(prevState => ({ ...prevState, ['selected_roles_' + item]: [] }))} />
                                                    </span>
                                                :
                                                    state['selected_roles_' + item].map((row, index) => {
                                                        return(
                                                            <span key={'role_'+index} className='flex border border-lightGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-info'>
                                                                {row}
                                                                <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeUserRolsSection('selected_roles', item, index, row) } />
                                                            </span>
                                                        )
                                                    })
                                                
                                            : null}
                                        </React.Fragment>
                                    ) : null}
                                </div>

                                {state.appliesToArray.length > 1 ?
                                    <div className='mt-6 flex justify-end'>
                                        <span className={`cursor-pointer rounded-full text-base bg-ferrariRed-600 px-4 py-2.5 text-white text-center self-center`} onClick={() => removeSection('appliesToArray', itemIndex, item)}> Remove</span>
                                    </div>
                                : null}
                            </div>
                            
                            {(state.appliesToArray.length - 1) ===  itemIndex ?
                                <div className='mt-6 flex justify-center'>
                                    <p className='mb-4 text-primaryPurple-600 border border-primaryPurple-600 bg-white pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base text-center min-w-28' onClick={() => addSection('appliesToArray')}>+ Add</p>
                                </div>
                            : null}
                            </React.Fragment>
                        )
                    })
                : state.showAppliedSection && editNewRuleDetails && (editNewRuleDetails.governance_type === 'opa_kubernetes' || editNewRuleDetails.governance_type === 'hpc-slurm') ?
                    state.appliesToArray.map((item, itemIndex) => {
                        return(
                            <React.Fragment key={'appliesArr_'+item}>
                                <div className='flex flex-wrap mb-3'>
                                    <p className={`mr-2 mt-2 mb-1 self-end`}>Cluster Name</p>
                                    <div className='selectBoxBottomColor w-15 text-black'>
                                        <input
                                            type='textarea'
                                            className={`inputField w-100`}
                                            style={{ minHeight: '38px' }}
                                            placeholder={'Enter'}
                                            value={state['cluster_name_' + item]}
                                            onChange={e => {
                                                setState(prevState => ({ ...prevState, ['cluster_name_' + item]: e.target.value, onChangeApliesTo: true }))
                                            }}
                                        />
                                    </div>
                                    {editNewRuleDetails.governance_type !== 'hpc-slurm' ?
                                        <React.Fragment>
                                        <p className={`mr-2 mt-2 mb-1 ml-3 self-end text-black`}>Cluster ID</p>
                                        <div className='selectBoxBottomColor w-15'>
                                            <input
                                                type='text'
                                                className={`inputField w-100`}
                                                style={{ minHeight: '38px' }}
                                                placeholder={'Enter'}
                                                value={state['cluster_id_' + item]}
                                                onChange={e => {
                                                    setState(prevState => ({ ...prevState, ['cluster_id_' + item]: e.target.value, onChangeApliesTo: true }))
                                                }}
                                            />
                                        </div>
                                        <p className={`mr-2 mt-2 mb-1 ml-3 self-end text-black`}>Name Space</p>
                                        <div className='selectBoxBottomColor w-15'>
                                            <input
                                                type='text'
                                                className={`inputField w-100`}
                                                style={{ minHeight: '38px' }}
                                                placeholder={'Enter'}
                                                value={state['name_space_to_' + item]}
                                                onChange={e => {
                                                    setState(prevState => ({ ...prevState, ['name_space_to_' + item]: e.target.value, onChangeApliesTo: true }))
                                                }}
                                            />
                                        </div>
                                        <span
                                            className={`far fa-plus cursor-pointer f18 mt-2 mb-1 self-end mr-4`}
                                            onClick={() => addNewNameSpace(item)}
                                        ></span>
                                        {state['name_space_' + item] && state['name_space_' + item].length ?
                                            state['name_space_' + item].map((space, index) => {
                                                return(
                                                    <span key={'name_space_'+item+'_index'} className='flex border border-lightGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-info'>
                                                        {space}
                                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeNameSpace(item, space)} />
                                                    </span>
                                                )
                                            })
                                        : null}
                                        </React.Fragment>
                                    : null}
                                </div>
                                <div className='flex justify-start'>
                                    {(state.appliesToArray.length - 1) ===  itemIndex ?
                                        <span className={`cursor-pointer text-info f14 self-center mr-4`} onClick={() => addSection('appliesToArray')}> Add</span>
                                    : null}
                                    {state.appliesToArray.length > 1 ?
                                        <span className={`cursor-pointer text-red f14 self-center`} onClick={() => removeSection('appliesToArray', itemIndex, item)}> Remove</span>
                                    : null}
                                </div>
                            </React.Fragment>
                        )
                    })
                : null}
            </div>
        </div>
    )
}

export default AppliesToSection