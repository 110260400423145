/*************************************************
 * Collider
 * @exports
 * @file LeftSection.js
 * @author Prakash // on 05/12/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate } from 'react-router-dom'
import MultiSelectSection from '../../../common/MultiSelectSection';
import { setCostPropsDetails } from  '../../../../actions/cost/CostAction'
import { listDepartments, getCloudProviderCommitment, updateBudget, getDepartmentBudget } from '../../../../actions/cost/BudgetAction'
import { capitalizeAllLetter, onlyNumeric, thousandSeparator } from '../../../../utils/utility'
import { Store as CommonNotification } from 'react-notifications-component';
import { URL_PATH } from '../../../../config/urlPath';
import {Icon} from "@iconify/react";

const LeftSection = (props) => {
	const[state, setState] = useState({
		showDetails: false,
        expandMenuId: 'Department',
        months: [{label: 'Jan', value: 0}, {label:'Feb', value: 1}, {label:'Mar', value: 2}, {label:'Apr', value: 3}, {label:'May', value: 4}, {label:'Jun', value: 5}, {label:'Jul', value: 6}, {label:'Aug', value: 7}, {label:'Sep', value: 8}, {label:'Oct', value: 9}, {label:'Nov', value: 10}, {label:'Dec', value: 11}],        
        quarters: [
            [{label: 'Jan', value: 0}, {label: 'Feb', value: 1}, {label: 'Mar', value: 2}],
            [{label: 'Apr', value: 3}, {label: 'May', value: 4}, {label: 'Jun', value: 5}],
            [{label: 'Jul', value: 6}, {label: 'Aug', value: 7}, {label: 'Sep', value: 8}],
            [{label: 'Oct', value: 9}, {label: 'Nov', value: 10}, {label: 'Dec', value: 11}]
        ],
        currencyUnit: 'usd'
        // department: 'RDD'
	})
    const createInputs = useSelector(state => state?.cost?.costPropsDetails?.createInputs || false);
    const onClickSave = useSelector(state => state?.cost?.costPropsDetails.onClickSave || false);
    const departmentList = useSelector(state => state?.cost?.listDepartments || false);

    const navigate = useNavigate();
	const dispatch = useDispatch(); // Create a dispatch function
    
    useEffect(() => {
        // let shortCurrentYear = momentDateFormat(new Date(), "YY") 
        const currentYear = new Date().getUTCFullYear();
        // Calculate the next 10 years
        const next10Years = Array.from({ length: 11 }, (_, index) => currentYear + index);
        setState(prevState => ({ ...prevState, yearOptions: next10Years }))

        // let params = {}
        if(!departmentList || !departmentList.length) {
            let depParams = {}
            dispatch(listDepartments(depParams))
                .then((response) => {
                    let results =  response && response.results ? response.results : []
                    setState(prevState => ({
                        ...prevState,
                        departmentsList: results,
                    }))
                })
        } else {
            setState(prevState => ({
                ...prevState,
                departmentsList: departmentList,
                filteredDepartment: departmentList,
                selectedDepartment: departmentList && departmentList.length ? departmentList[0] : ""
            }))
        }
        
        // let obj = {}
        // obj.initiatives = state.initiativesOptions
        // dispatch(setCostPropsDetails('createInputs', obj))

    }, [dispatch, departmentList])

    useEffect(() => {
        if(props.receivedData && Object.entries(props.receivedData).length) {
            setState(prevState => ({ 
                ...prevState, 
                department: props.receivedData.department ? props.receivedData.department : '',
                year: props.receivedData.year ? props.receivedData.year : '',
                pageType: props.receivedData.pageType ? props.receivedData.pageType : '',
            }))
        }
    }, [props.receivedData])

    const createBudget = useCallback(() => {
        let params = createInputs

        dispatch(updateBudget(params))
            .then((response) => {
                let messageType = 'danger'
                let errorMessage = 'Error in inserting budget'
                if(response.status) {
                    messageType = 'success'
                    errorMessage = 'Inserted budget'
                    navigate(URL_PATH.COST_BUDGET_LIST); // Redirect to the specified path
                }

                dispatch(setCostPropsDetails('onClickSave', 'stopLoading'))

                let message = response && response.results ? response.results : errorMessage

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })
    }, [createInputs, dispatch, navigate])

    const onChangeHandleValidation = useCallback((value, mnt) => {
        let hasError = false
        let obj = createInputs ? createInputs : {}
        
        let depProviders = []
        if(obj.cloud_budget && Object.entries(obj.cloud_budget).length) {
            let totalAllocation = 0
            let totalDistributionSum = 0
            let departmentInitiativeMonthlyTotalError = []
            let departmentInitiativeAllocationError = []
            let initiativeDepartmentAllocationError = []
            Object.entries(obj.cloud_budget).forEach(([key, value], objIndex) => {
                let departmentDistrubutition = value.monthly_distribution
                let distributionSum = 0
                if(departmentDistrubutition) {
                    distributionSum = departmentDistrubutition.reduce((a, b) => {
                        return a + parseFloat(b);
                    }, 0)
                }

                totalAllocation += Math.round(value.allocation) 
                totalDistributionSum += Math.round(distributionSum)

                let dataRow = {}
                dataRow.provider = key
                dataRow.allocation = Math.round(value.allocation)
                dataRow.distributuion = Math.round(distributionSum)
                dataRow.balance = Math.round(value.allocation - distributionSum)

                let departmentAllocationError = ''
                // if(value.allocation < distributionSum) {
                //     hasError = true
                //     departmentAllocationError = 'Annual commitment amount should be more than the total monthly commitment.'
                // }

                let departmentOrgAllocationError = []
                // if(state.orgLevelCommitmentState && state.orgLevelCommitmentState.filter(e => e.cloudProviderId === key).length) {
                //     let availablePlusDepartmentAllocation = state.orgLevelCommitmentState.filter(e => e.cloudProviderId === key)[0].availablePlusDepartmentAllocation ? state.orgLevelCommitmentState.filter(e => e.cloudProviderId === key)[0].availablePlusDepartmentAllocation : 0
                //     if(availablePlusDepartmentAllocation < value.allocation) {
                //         hasError = true
                //         let error = 'Annual commitment amount '+capitalizeAllLetter(key)+' should be less than the org level commitment.'
                //         departmentOrgAllocationError.push(error)
                //     }

                // }

                depProviders.push(dataRow)

                if(Object.entries(obj.cloud_budget).length-1 === objIndex) {
                    let totalDepRow = {}
                    totalDepRow.provider = 'Total'
                    totalDepRow.allocation = totalAllocation
                    totalDepRow.distributuion = totalDistributionSum
                    totalDepRow.balance = totalAllocation - totalDistributionSum
                    depProviders.unshift(totalDepRow);
                }                
                
                setState(prevState => ({ ...prevState, ['departmentAllocationError_'+key]: departmentAllocationError, departmentProviders: depProviders, ['departmentOrgAllocationError_'+key]: departmentOrgAllocationError }))

                let totalInitAllocation = 0                

                let initiatives = obj.initiatives ? obj.initiatives : []
                if(initiatives.length) {
                    initiatives.forEach(row => {
                        if(row.cloud_budget.hasOwnProperty(key)) {
                            let initData = row.cloud_budget[key]                            
                            totalInitAllocation += initData.allocation
                        }
                    })
                    if(value.allocation < totalInitAllocation) {
                        hasError = true
                        let depError = 'Department Annual commitment amount of '+capitalizeAllLetter(key)+' should be more than the total initiative annual commitment.'
                        departmentInitiativeAllocationError.push(depError)

                        let intError = 'Intitative total Annual commitment amount of '+capitalizeAllLetter(key)+' should be less than the department annual commitment.'
                        initiativeDepartmentAllocationError.push(intError)
                    }
                }
                
                setState(prevState => ({ ...prevState, departmentInitiativeAllocationError, initiativeDepartmentAllocationError }))

                state.months && state.months.forEach((month, mIndex) => {
                    let departmentMonthlyDistrubutition = departmentDistrubutition[mIndex] ? departmentDistrubutition[mIndex] : 0
                    
                    let initiativeMonthlyAlllcationSum = 0
                    let initiatives = obj.initiatives ? obj.initiatives : []
                    initiatives.forEach(init => {
                        if(init.cloud_budget.hasOwnProperty(key)) {
                            let initData = init.cloud_budget[key]
                            let initDistrubutition = initData.monthly_distribution
                            initiativeMonthlyAlllcationSum += initDistrubutition[mIndex] ? initDistrubutition[mIndex] : 0
                        }
                    })
    
                    let departmentInitiativeMonthlyError = ''
                    if(parseFloat(departmentMonthlyDistrubutition) < parseFloat(initiativeMonthlyAlllcationSum)) {
                        hasError = true
                        departmentInitiativeMonthlyError = 'The total amount for initiative for '+key+ ' in '+ month.label+' month ('+parseFloat(initiativeMonthlyAlllcationSum)+') should not exceed the department '+ month.label+' month ('+parseFloat(departmentMonthlyDistrubutition)+').'
    
                        departmentInitiativeMonthlyTotalError.push(departmentInitiativeMonthlyError)                            
                    }
                })
            })            
            
            setState(prevState => ({ ...prevState, departmentInitiativeMonthlyTotalError }))
            
            let initiatives = obj.initiatives ? obj.initiatives : []
            if(initiatives.length) {
                initiatives.forEach((row, rIndex) => {                    
                    let initiProviders = []
                    let initProjectAllocationAmountError = []
                    let initiativeProjectMonthlyTotalError = []
                    let totalInitAllocation = 0
                    let totalInitDistributionSum = 0
                    Object.entries(row.cloud_budget).forEach(([key, value]) => {
                        let allocation = value.allocation 
                        let initiativetDistrubutition = value.monthly_distribution        
                        
                        totalInitAllocation += value.allocation 
                        
                        let distributionSum = 0
                        if(initiativetDistrubutition) {
                            distributionSum = initiativetDistrubutition.reduce((a, b) => {
                                return a + parseFloat(b);
                            }, 0)
                        }

                        totalInitDistributionSum += Math.round(distributionSum)
        
                        let initiativeAllocationError = ''
                        if(allocation < distributionSum) {
                            hasError = true
                            initiativeAllocationError ='Annual commitment amount of '+capitalizeAllLetter(key)+' should be more than the total monthly commitment.'
                        }
                        
                        setState(prevState => ({ ...prevState, ['initiativeAllocationError_'+row.initiative]: initiativeAllocationError, ['initiativeProviders_'+row.initiative]: initiProviders }))

                        let initRow = {}
                        initRow.provider = key
                        initRow.allocation = value.allocation 
                        initRow.distributuion = Math.round(distributionSum)
                        initRow.balance = Math.round(value.allocation - distributionSum)        
                        initiProviders.push(initRow)
                        
                        let projects = row.projects ? row.projects : []
                        
                        let projectTotal = 0
                        projects.forEach(proj => {
                            if(proj.cloud_budget.hasOwnProperty(key)) {
                                let projData = proj.cloud_budget[key]
                                let allocation = projData.allocation 
                                let projectDistrubutition = projData.monthly_distribution

                                projectTotal += allocation 

                                let projDistributionSum = 0
                                if(projectDistrubutition) {
                                    projDistributionSum = projectDistrubutition.reduce((a, b) => {
                                        return a + parseFloat(b);
                                    }, 0)
                                }
        
                                let projAllocationError = ''
                                if(allocation < projDistributionSum) {
                                    hasError = true
                                    projAllocationError ='Annual commitment amount should be more than the total monthly commitment.'
                                }
                
                                // initiProviders.push(dataRow)                                
                                setState(prevState => ({ ...prevState, ['projectAllocationError_'+proj.project+'_'+key]: projAllocationError}))

                            }  
                        })

                        let initProjectAllocationAmount = ''
                        if(projectTotal > value.allocation) {
                            hasError = true
                            initProjectAllocationAmount = 'The total commitment amount for the '+capitalizeAllLetter(key)+' projects ('+ projectTotal +') should not exceed the annual commitment for the initiative ('+value.allocation+').'

                            initProjectAllocationAmountError.push(initProjectAllocationAmount)
                        }
                        
                        setState(prevState => ({ ...prevState, ['initProjectAllocationAmount_'+row.initiative]: initProjectAllocationAmountError }))

                        state.months && state.months.forEach((month, mIindex) => {
                            let initiativeMonthlyAllocation = initiativetDistrubutition[mIindex] ? initiativetDistrubutition[mIindex] : 0
                            
                            let projectMonthlyAlllcationSum = 0
                            let projects = row.projects ? row.projects : []
                            projects.forEach(proj => {
                                if(proj.cloud_budget.hasOwnProperty(key)) {
                                    let projData = proj.cloud_budget[key]
                                    let projectDistrubutition = projData.monthly_distribution
                                    projectMonthlyAlllcationSum += projectDistrubutition[mIindex] ? projectDistrubutition[mIindex] : 0
                                }
                            })

                            let initiativeProjectMonthlyError = ''
                            if(parseFloat(initiativeMonthlyAllocation) < parseFloat(projectMonthlyAlllcationSum)) {
                                hasError = true
                                initiativeProjectMonthlyError = 'The total amount for projects for '+key+ ' in '+ month.label+' month ('+parseFloat(projectMonthlyAlllcationSum)+') should not exceed the initiative '+ month.label+' month ('+parseFloat(initiativeMonthlyAllocation)+').'

                                initiativeProjectMonthlyTotalError.push(initiativeProjectMonthlyError)                            
                            }
                        })
                    })

                    if(initiatives.length-1 === rIndex) {
                        let totalinitRow = {}
                        totalinitRow.provider = 'Total'
                        totalinitRow.allocation = totalInitAllocation
                        totalinitRow.distributuion = totalInitDistributionSum
                        totalinitRow.balance = totalInitAllocation - totalInitDistributionSum
                        initiProviders.unshift(totalinitRow);
                    }

                    setState(prevState => ({ ...prevState, ['initiativeProviders_'+row.initiative]: initiProviders, initiativeProjectMonthlyTotalError }))                    
                })
            }
        }

        let orgLevelCommitment = state.orgLevelCommitmentState ? state.orgLevelCommitmentState : []
        let orgLevelCommitmentState = []
        let totalCommitment = 0
        let totalAllocated = 0
        let totalBalance = 0
        if(depProviders && depProviders.length && orgLevelCommitment.length) {
            orgLevelCommitment.forEach(item => {
                if(depProviders.filter(e => e.provider === item.cloudProviderId && e.provider !== 'Total').length) {
                    let allocated = item.otherDepAllocation + (depProviders.filter(e => e.provider === item.cloudProviderId)[0].allocation ? depProviders.filter(e => e.provider === item.cloudProviderId)[0].allocation : 0)
                    //balance amout can be allocated

                    item.allocated = allocated
                    item.balance = item.commitment - allocated
                    
                    totalCommitment += item.commitment
                    totalAllocated += allocated
                    totalBalance += item.commitment - allocated

                    orgLevelCommitmentState.push(item)
                }
            })

            
            let dataRow = {}
            dataRow.cloudProviderId = 'Total'
            dataRow.commitment = totalCommitment
            dataRow.allocated = totalAllocated
            dataRow.balance = totalBalance
            orgLevelCommitmentState.unshift(dataRow)
            
        }
        
        setState(prevState => ({ ...prevState, orgLevelCommitmentState }))

        if(onClickSave === 'save' && !hasError) {
            createBudget()
        } else if(onClickSave) {
            dispatch(setCostPropsDetails('onClickSave', 'stopLoading'))
        }
    }, [createInputs, state, createBudget, dispatch, onClickSave])

    useEffect(() => {
        if(onClickSave && onClickSave !== 'stopLoading') {
            dispatch(setCostPropsDetails('onClickSave', ''))
            onChangeHandleValidation()
        }
    }, [onClickSave, onChangeHandleValidation, dispatch])

    useEffect(() => {
        if(state.year && state.currencyUnit) {
            let params = {}
            params.year = state.year
            params.currency_unit = state.currencyUnit
            dispatch(getCloudProviderCommitment(params))
                .then((response) => {   
                    setState(prevState => ({
                        ...prevState,
                        cloudProviderCommitment: response,
                    }))
                })
        }
    }, [state.year, dispatch, state.currencyUnit])

    useEffect(() => {
        if(state.department && state.year && state.currencyUnit) {
            let params = {}
            params.department = state.department
            params.year = state.year
            params.currency_unit = state.currencyUnit

            // let initiativesOptions = getDepBudgetResponse && getDepBudgetResponse.initiatives ? getDepBudgetResponse.initiatives : []

            // // setState(prevState => ({
            // //     ...prevState,
            // //     departmentDetails: getDepBudgetResponse,
            // //     departemntAllocatedAmount: getDepBudgetResponse.allocation ? getDepBudgetResponse.allocation : 0,
            // //     initiativesOptions,
            // //     allocation: getDepBudgetResponse.allocation ? getDepBudgetResponse.allocation : '',
            // //     formatDepartmentResponse: true
            // // }))

            // dispatch(setCostPropsDetails('createInputs', getDepBudgetResponse))

            dispatch(getDepartmentBudget(params))
                .then((response) => {
                    let initiativesOptions = response && response.initiatives ? response.initiatives : []
                    // if(initiativesOptions && initiativesOptions.length) {
                    //     expandInitiatives(initiativesOptions[0], 0)
                    // }
                    setState(prevState => ({
                        ...prevState,
                        departmentDetails: response,
                        // departemntAllocatedAmount: response.allocation ? response.allocation : 0,
                        initiativesOptions,
                        // allocation: response.allocation ? response.allocation : '',
                        formatDepartmentResponse: true
                    }))

                    dispatch(setCostPropsDetails('createInputs', response))
                })
        }
    }, [state.department, state.year, state.currencyUnit, dispatch])

    const departmentCommitedBalanceDetails = useCallback((allocation, monthly_distribution, selectedDepCloudProvider) => {
        let departmentMontlyDistrubutition = monthly_distribution
        if(departmentMontlyDistrubutition && departmentMontlyDistrubutition.length) {
            let departmentMonthlySum = departmentMontlyDistrubutition.reduce((a, b) => {
                return a + parseFloat(b);
            }, 0); // Initial value of the accumulator is set to 0

            let departmentBalance = allocation - departmentMonthlySum

            var quarters = [];
            for (var i = 0; i < departmentMontlyDistrubutition.length; i += 3) {
                var quarter = departmentMontlyDistrubutition.slice(i, i + 3);
                var quarterSum = quarter.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                quarters.push(quarterSum);
            }
            quarters.forEach((quater, qIndex) => {
                setState(prevState => ({
                    ...prevState,
                    ['department_quater_'+qIndex]:  parseFloat(quater)
                }))
            })

            let halfYearlyArray = []
            for (let i = 0; i < departmentMontlyDistrubutition.length; i += 6) {
                let halfYearly = departmentMontlyDistrubutition.slice(i, i + 6);
                let halfYearlySum = halfYearly.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                halfYearlyArray.push(halfYearlySum);
            }
            
            halfYearlyArray.forEach((half, hIndex) => {
                setState(prevState => ({
                    ...prevState,
                    ['department_half_'+hIndex]:  parseFloat(half)
                }))
            })

            setState(prevState => ({ ...prevState, ['departmentMonthlySum_'+selectedDepCloudProvider]: Math.round(departmentMonthlySum), ['departmentBalance_'+selectedDepCloudProvider]: Math.round(departmentBalance) }))
        }
    }, [])

    const initiativeCommitedBalanceDetails = useCallback((allocation, monthly_distribution, iIndex, selectedInitCloudProvider) => {
        let initiativeMontlyDistrubutition = monthly_distribution
        if(initiativeMontlyDistrubutition && initiativeMontlyDistrubutition.length) {
            let initiativeMonthlySum = initiativeMontlyDistrubutition.reduce((a, b) => {
                return a + parseFloat(b);
            }, 0); // Initial value of the accumulator is set to 0

            let initiativeBalance = allocation - initiativeMonthlySum

            var quarters = [];
            for (var i = 0; i < initiativeMontlyDistrubutition.length; i += 3) {
                var quarter = initiativeMontlyDistrubutition.slice(i, i + 3);
                var quarterSum = quarter.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                quarters.push(quarterSum);
            }
            quarters.forEach((quater, qIndex) => {
                setState(prevState => ({
                    ...prevState,
                    ['initiative_quater_'+qIndex+'_'+iIndex]:  parseFloat(quater)
                }))
            })

            let halfYearlyArray = []
            for (let i = 0; i < initiativeMontlyDistrubutition.length; i += 6) {
                let halfYearly = initiativeMontlyDistrubutition.slice(i, i + 6);
                let halfYearlySum = halfYearly.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                halfYearlyArray.push(halfYearlySum);
            }
            
            halfYearlyArray.forEach((half, hIndex) => {
                setState(prevState => ({
                    ...prevState,
                    ['initiative_half_'+hIndex+'_'+iIndex]:  parseFloat(half)
                }))
            })

            setState(prevState => ({ ...prevState, ['initiativeMonthlySum_'+iIndex]: Math.round(initiativeMonthlySum), ['initiativeBalance_'+iIndex]: Math.round(initiativeBalance) }))
        }
    }, [])

    useEffect(() => {
        if(state.formatDepartmentResponse) {
            let res = state.departmentDetails

            let orgLevelCommitment = res.org_level_commitment

            if(res.cloud_budget && Object.entries(res.cloud_budget).length) {
                let depProviders = []
                let totalAllocation = 0
                let totalDistributionSum = 0
                Object.entries(res.cloud_budget).forEach(([key, value], objIndex) => {
                    let departmentDistrubutition = value.monthly_distribution
                    let distributionSum = 0
                    if(departmentDistrubutition) {
                        distributionSum = departmentDistrubutition.reduce((a, b) => {
                            return a + parseFloat(b);
                        }, 0)
                    }
                    totalAllocation += Math.round(value.allocation) 
                    totalDistributionSum += Math.round(distributionSum)
                    let dataRow = {}
                    dataRow.provider = key
                    dataRow.allocation = Math.round(value.allocation)
                    dataRow.distributuion = Math.round(distributionSum)
                    dataRow.balance = Math.round(value.allocation - distributionSum)    
                    depProviders.push(dataRow)

                    if(Object.entries(res.cloud_budget).length-1 === objIndex) {
                        let totalDepRow = {}
                        totalDepRow.provider = 'Total'
                        totalDepRow.allocation = totalAllocation
                        totalDepRow.distributuion = totalDistributionSum
                        totalDepRow.balance = totalAllocation - totalDistributionSum
                        depProviders.unshift(totalDepRow);
                    }
                    
                    setState(prevState => ({ ...prevState, departmentProviders: depProviders, ['departmentAllocationError_'+key]: '', departmentInitiativeMonthlyTotalError: [], departmentInitiativeAllocationError: [], initiativeDepartmentAllocationError: [] }))                    
                })
                let orgLevelCommitmentState = []
                let totalCommitment = 0
                let totalAllocated = 0
                let totalBalance = 0
                if(depProviders && depProviders.length && orgLevelCommitment && orgLevelCommitment.length) {
                    orgLevelCommitment.forEach(item => {
                        if(depProviders.filter(e => e.provider === item.cloudProviderId && e.allocation >= 0).length) {
                            
                            //balance amout can be allocated
                            item.balance = item.available_for_allocation
                            
                            //remainaing available balance include the current department
                            let availablePlusDepartmentAllocation = item.available_for_allocation+(depProviders.filter(e => e.provider === item.cloudProviderId)[0].allocation ? depProviders.filter(e => e.provider === item.cloudProviderId)[0].allocation : 0)
                            item.availablePlusDepartmentAllocation = availablePlusDepartmentAllocation

                            //get other department allcoations of provider
                            item.otherDepAllocation = item.commitment - availablePlusDepartmentAllocation
                            
                            orgLevelCommitmentState.push(item)

                            totalCommitment += item.commitment
                            totalAllocated += item.allocated
                            totalBalance += item.available_for_allocation
                        }
                    })

                    let dataRow = {}
                    dataRow.cloudProviderId = 'Total'
                    dataRow.commitment = totalCommitment
                    dataRow.allocated = totalAllocated
                    dataRow.balance = totalBalance
                    orgLevelCommitmentState.unshift(dataRow)                    

                    setState(prevState => ({ ...prevState, orgLevelCommitmentState }))
                }

                
            }
            
            let initiativesProvider = res.initiatives ? res.initiatives : []
            if(initiativesProvider && initiativesProvider.length) {
                let totalInitAllocation = 0
                let totalInitDistributionSum = 0
                initiativesProvider.forEach((row, rIndex) => {
                    let initiProviders = []
                    Object.entries(row.cloud_budget).forEach(([key, value]) => {
                        let initiativetDistrubutition = value.monthly_distribution                            
                        
                        let distributionSum = 0
                        if(initiativetDistrubutition) {
                            distributionSum = initiativetDistrubutition.reduce((a, b) => {
                                return a + parseFloat(b);
                            }, 0)
                        }

                        totalInitAllocation += value.allocation 
                        totalInitDistributionSum += Math.round(distributionSum)

                        let initRow = {}
                        initRow.provider = key
                        initRow.allocation = value.allocation 
                        initRow.distributuion = Math.round(distributionSum)
                        initRow.balance = Math.round(value.allocation - distributionSum)
        
                        initiProviders.push(initRow)
                    })

                    if(initiativesProvider.length-1 === rIndex) {
                        let totalinitRow = {}
                        totalinitRow.provider = 'Total'
                        totalinitRow.allocation = totalInitAllocation
                        totalinitRow.distributuion = totalInitDistributionSum
                        totalinitRow.balance = totalInitAllocation - totalInitDistributionSum
                        initiProviders.unshift(totalinitRow);
                    }
    
                    setState(prevState => ({ ...prevState, ['initiativeProviders_'+row.initiative]: initiProviders, ['initiativeAllocationError_'+row.initiative]: '', ['initProjectAllocationAmount_'+row.initiative]: [], initiativeProjectMonthlyTotalError: [] }))
                })
            }
                
            setState(prevState => ({ ...prevState, formatDepartmentResponse: false }))
            let departmentCloudBudget = state.departmentDetails && state.departmentDetails.cloud_budget ? state.departmentDetails.cloud_budget : {}
            
            let departmentCloudProviders = []
            if(departmentCloudBudget && Object.entries(departmentCloudBudget).length) {
                departmentCloudProviders = Object.keys(departmentCloudBudget)
                let selectedDepCloudProvider = departmentCloudProviders[0]
                let selectedCloudProviderBudget = departmentCloudBudget[selectedDepCloudProvider]
                let distribution = selectedCloudProviderBudget && selectedCloudProviderBudget.monthly_distribution ? selectedCloudProviderBudget.monthly_distribution : []
                
                state.months.forEach((mnt, mIndex) => {
                    setState(prevState => ({ ...prevState, ['department_'+selectedDepCloudProvider+'_'+mnt.label]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
                })
                let allocation = selectedCloudProviderBudget && selectedCloudProviderBudget.allocation ? selectedCloudProviderBudget.allocation : 0

                departmentCommitedBalanceDetails(allocation, distribution, selectedDepCloudProvider)

                setState(prevState => ({ ...prevState, departmentCloudProviders, selectedDepCloudProvider }))
            }
            let initiatives = state.departmentDetails && state.departmentDetails.initiatives ? state.departmentDetails.initiatives : []
            if(initiatives && initiatives.length) {
                initiatives.forEach((init, iIndex) => {
                    let initiativeCloudProviders = []
                    let initiativeCloudBudget = init && init.cloud_budget ? init.cloud_budget : {}
                    if(initiativeCloudBudget && Object.entries(initiativeCloudBudget).length) {
                        initiativeCloudProviders = Object.keys(initiativeCloudBudget)
                        let selectedInitCloudProvider = initiativeCloudProviders[0]
                        let selectedCloudInitBudget = initiativeCloudBudget[selectedInitCloudProvider]

                        let distribution = selectedCloudInitBudget && selectedCloudInitBudget.monthly_distribution ? selectedCloudInitBudget.monthly_distribution : []
                        
                        state.months.forEach((mnt, mIndex) => {
                            setState(prevState => ({ ...prevState, ['initiative_'+mnt.label+'_'+iIndex]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
                        })
                        
                        let allocation = selectedCloudInitBudget && selectedCloudInitBudget.allocation ? selectedCloudInitBudget.allocation : 0

                        initiativeCommitedBalanceDetails(allocation, distribution, iIndex, selectedInitCloudProvider)
                        
                        setState(prevState => ({ ...prevState, ['initiativeCloudProviders_'+iIndex]: initiativeCloudProviders, ['selectedInitCloudProvider_'+iIndex]: selectedInitCloudProvider, ['initiativeAlloation_'+iIndex]: allocation }))
                    }
                })
            }
        }
    }, [state.formatDepartmentResponse, state.months, state.departmentDetails, departmentCommitedBalanceDetails, initiativeCommitedBalanceDetails])

    //for departemnt
    useEffect(() => {
        if(state.onChangeDeparmentProvider) {
            setState(prevState => ({ ...prevState, onChangeDeparmentProvider: false }))
            let departmentCloudBudget = state.departmentDetails && state.departmentDetails.cloud_budget ? state.departmentDetails.cloud_budget : {}

            if(departmentCloudBudget && Object.entries(departmentCloudBudget).length) {
                let selectedDepCloudProvider = state.selectedDepCloudProvider
                let selectedCloudProviderBudget = departmentCloudBudget[selectedDepCloudProvider]
                let distribution = selectedCloudProviderBudget && selectedCloudProviderBudget.monthly_distribution ? selectedCloudProviderBudget.monthly_distribution : []
                state.months.forEach((mnt, mIndex) => {
                    setState(prevState => ({ ...prevState, ['department_'+selectedDepCloudProvider+'_'+mnt.label]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
                })
                let allocation = selectedCloudProviderBudget && selectedCloudProviderBudget.allocation ? selectedCloudProviderBudget.allocation : 0

                departmentCommitedBalanceDetails(allocation, distribution, selectedDepCloudProvider)
            }
            
            let initiatives = state.departmentDetails && state.departmentDetails.initiatives ? state.departmentDetails.initiatives : []
            if(initiatives && initiatives.length) {
                initiatives.forEach((init, iIndex) => {
                    let initiativeCloudProviders = []
                    let initiativeCloudBudget = init && init.cloud_budget ? init.cloud_budget : {}
                    if(initiativeCloudBudget && Object.entries(initiativeCloudBudget).length) {
                        initiativeCloudProviders = Object.keys(initiativeCloudBudget)
                        let selectedInitCloudProvider = initiativeCloudProviders[0]
                        let selectedCloudInitBudget = initiativeCloudBudget[selectedInitCloudProvider]

                        let distribution = selectedCloudInitBudget && selectedCloudInitBudget.monthly_distribution ? selectedCloudInitBudget.monthly_distribution : []
                        
                        state.months.forEach((mnt, mIndex) => {
                            setState(prevState => ({ ...prevState, ['initiative_'+mnt.label+'_'+iIndex]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
                        })
                        
                        let allocation = selectedCloudInitBudget && selectedCloudInitBudget.allocation ? selectedCloudInitBudget.allocation : 0

                        initiativeCommitedBalanceDetails(allocation, distribution, iIndex, selectedInitCloudProvider)
                        
                        setState(prevState => ({ ...prevState, ['initiativeCloudProviders_'+iIndex]: initiativeCloudProviders, ['selectedInitCloudProvider_'+iIndex]: selectedInitCloudProvider, ['initiativeAlloation_'+iIndex]: allocation }))
                    }
                })
            }
        }
    }, [state.onChangeDeparmentProvider, state.months, state.departmentDetails, departmentCommitedBalanceDetails, initiativeCommitedBalanceDetails, state.selectedDepCloudProvider])
    
	const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value }))
		let obj = createInputs ? createInputs : {}
        obj[label] = value

        dispatch(setCostPropsDetails('createInputs', obj))
	}

    //for departemnt
    const handleDepartmentInputChange = (label, value) => {        
		let obj = createInputs ? createInputs : {}
        let selectedDepProviderDetails = obj.cloud_budget[state.selectedDepCloudProvider] ? obj.cloud_budget[state.selectedDepCloudProvider] : {}
        if(label === 'allocation') {
            value = parseFloat(value)
            selectedDepProviderDetails.allocation = value

            if(!state.splitEqual) {
                departmentCommitedBalanceDetails(value, selectedDepProviderDetails.monthly_distribution, state.selectedDepCloudProvider)
            }

            setState(prevState => ({ ...prevState, [label+'_'+state.selectedDepCloudProvider]: value }))
        }
        
        dispatch(setCostPropsDetails('createInputs', obj))
	}

    //for departemnt
    const callEqualMonthlyDistribution = useCallback(() => {
        let obj = createInputs ? createInputs : {}

        let selectedDepProviderDetails = obj.cloud_budget[state.selectedDepCloudProvider] ? obj.cloud_budget[state.selectedDepCloudProvider] : {}

		let allocation = selectedDepProviderDetails.allocation ? selectedDepProviderDetails.allocation : 0

        let monthly_distribution = []
        let amount = parseFloat(allocation/12).toFixed(2)
        state.months.forEach(mnt => {
            monthly_distribution[mnt.value] = parseFloat(amount)
        })
        
        selectedDepProviderDetails.monthly_distribution = monthly_distribution

        dispatch(setCostPropsDetails('createInputs', obj))
        departmentCommitedBalanceDetails(allocation, monthly_distribution, state.selectedDepCloudProvider)
    }, [state.months, createInputs, state.selectedDepCloudProvider, dispatch, departmentCommitedBalanceDetails])

    //for departemnt
    useEffect(() => {
        if(state.departmentMonthlyDistribution) {
            setState(prevState => ({ ...prevState, departmentMonthlyDistribution: false }))

            // if(state.splitEqual) {
                let obj = createInputs ? createInputs : {}
                let allocation = obj.cloud_budget && obj.cloud_budget[state.selectedDepCloudProvider] && obj.cloud_budget[state.selectedDepCloudProvider].allocation ? obj.cloud_budget[state.selectedDepCloudProvider].allocation : 0
                if(allocation) {
                    state.months.forEach(mnt => {
                        let amount = parseFloat(allocation/12).toFixed(2)
                        setState(prevState => ({ ...prevState, ['department_'+state.selectedDepCloudProvider+'_'+mnt.label]: parseFloat(amount) }))
                    })

                    callEqualMonthlyDistribution()
                    onChangeHandleValidation()
                }
            // }
        }
    }, [state.departmentMonthlyDistribution, state, createInputs, callEqualMonthlyDistribution, onChangeHandleValidation])    
    

    //for departemnt
    const handleMonthlyDistributionChange = (label, value) => {
        let obj = createInputs ? createInputs : {}
		
        let selectedDepProviderDetails = obj.cloud_budget[state.selectedDepCloudProvider] ? obj.cloud_budget[state.selectedDepCloudProvider] : {}
        let monthly_distribution = selectedDepProviderDetails && selectedDepProviderDetails.monthly_distribution ? selectedDepProviderDetails.monthly_distribution : []
            
        state.months.forEach(mnt => {
            if(label === mnt.value) {
                let amount = value ? parseFloat(value).toFixed(2) : 0
                monthly_distribution[mnt.value] = parseFloat(amount)
            } else {
                // monthly_distribution[mnt.value] = state['department_'+state.selectedDepProviderDetails+'_'+mnt.label] ? parseFloat(state['department_'+state.selectedDepProviderDetails+'_'+mnt.label]) : 0
            }
        })
        
        selectedDepProviderDetails.monthly_distribution = monthly_distribution

		let allocation = selectedDepProviderDetails.allocation ? selectedDepProviderDetails.allocation : 0
        departmentCommitedBalanceDetails(allocation, monthly_distribution, state.selectedDepCloudProvider)
        // dispatch(setCostPropsDetails('createInputs', obj))
    }
    

    //for Initiative
	const handleInitiativeInputChange = (label, iIndex, value) => {
        
		let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[iIndex]? obj.initiatives[iIndex] : {}
        let selectedInitProviderDetails = initiatives && initiatives.cloud_budget && initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] ? initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] : {}
        
        // let invObj = initiatives && initiatives[iIndex] ? initiatives[iIndex] : {}
        if(label === 'allocation') {
            value = parseFloat(value)

            selectedInitProviderDetails[label] = value

            if(!state['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex]) {
                initiativeCommitedBalanceDetails(value, selectedInitProviderDetails.monthly_distribution, iIndex, state['selectedInitCloudProvider_'+iIndex])
            }
            
        }
        // dispatch(setCostPropsDetails('createInputs', obj))
	}
    
    //for Initiative// 
    const onChangeInitCloudProvider = (selectedInitCloudProvider, iIndex) => {
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[iIndex]? obj.initiatives[iIndex] : {}
        let selectedInitProviderDetails = initiatives && initiatives.cloud_budget && initiatives.cloud_budget[selectedInitCloudProvider] ? initiatives.cloud_budget[selectedInitCloudProvider] : {}
        let allocations = selectedInitProviderDetails.allocation ? selectedInitProviderDetails.allocation : 0

        let distribution = selectedInitProviderDetails && selectedInitProviderDetails.monthly_distribution ? selectedInitProviderDetails.monthly_distribution : []
        state.months.forEach((mnt, mIndex) => {
            setState(prevState => ({ ...prevState, ['initiative_'+mnt.label+'_'+iIndex]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
        })

        initiativeCommitedBalanceDetails(allocations, distribution, iIndex, selectedInitCloudProvider)
        
        setState(prevState => ({ ...prevState, ['initiativeAlloation_'+iIndex]: allocations }))
    }

    //for Initiative
    const callInitiativeEqualMonthlyDistribution = useCallback((iIndex, value) => {
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[iIndex]? obj.initiatives[iIndex] : {}
        let selectedInitProviderDetails = initiatives && initiatives.cloud_budget && initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] ? initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] : {}
        let allocations = selectedInitProviderDetails.allocation ? selectedInitProviderDetails.allocation : 0

        let monthly_distribution = []
        let amount = parseFloat(value/12).toFixed(2)
        state.months.forEach(mnt => {
            monthly_distribution[mnt.value] = parseFloat(amount)
        })
        
        selectedInitProviderDetails.monthly_distribution = monthly_distribution
        // obj.initiatives = initiatives
        
        initiativeCommitedBalanceDetails(allocations, monthly_distribution, iIndex, state['selectedInitCloudProvider_'+iIndex])
        // dispatch(setCostPropsDetails('createInputs', obj))
    }, [createInputs, state, initiativeCommitedBalanceDetails])

    //for Initiative
    const handleInitiativeMonthlyEqualSplit = (initiativeSplitEqual, iIndex, value) => {
        if(initiativeSplitEqual) {
            let obj = createInputs ? createInputs : {}
            let initiatives = obj && obj.initiatives && obj.initiatives[iIndex]? obj.initiatives[iIndex] : {}
            let selectedInitProviderDetails = initiatives && initiatives.cloud_budget && initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] ? initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] : {}

            let allocations = selectedInitProviderDetails.allocation ? selectedInitProviderDetails.allocation : 0
            
            let amount = parseFloat(allocations/12).toFixed(2)
            state.months.forEach(mnt => {
                setState(prevState => ({ ...prevState, ['initiative_'+mnt.label+'_'+iIndex]: parseFloat(amount) }))
            })

            callInitiativeEqualMonthlyDistribution(iIndex, allocations)
        }
    }

    //for Initiative
    const handleInitiativeMonthlySplit = (label, iIndex, value) => {
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[iIndex]? obj.initiatives[iIndex] : {}
        let selectedInitProviderDetails = initiatives && initiatives.cloud_budget && initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] ? initiatives.cloud_budget[state['selectedInitCloudProvider_'+iIndex]] : {}
		
        let monthly_distribution = selectedInitProviderDetails && selectedInitProviderDetails.monthly_distribution ? selectedInitProviderDetails.monthly_distribution : []
        state.months.forEach(mnt => {
            if(label === mnt.value) {
                let amount = value ? parseFloat(value).toFixed(2) : 0
                monthly_distribution[mnt.value] = parseFloat(amount)
            } else {
                let amount = parseFloat(state['initiative_'+mnt.label+'_'+iIndex] ? state['initiative_'+mnt.label+'_'+iIndex] : 0).toFixed(2)
                monthly_distribution[mnt.value] = parseFloat(amount)
            }
        })
        let allocations = selectedInitProviderDetails.allocation ? selectedInitProviderDetails.allocation : 0
        selectedInitProviderDetails.monthly_distribution = monthly_distribution

        initiativeCommitedBalanceDetails(allocations, monthly_distribution, iIndex, state['selectedInitCloudProvider_'+iIndex])

        // obj.initiatives = initiatives
        // dispatch(setCostPropsDetails('createInputs', obj))
    }

    //for Initiative
	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    //for Department
    const expandCollapse = (section) => {
        setState(prevState => ({ ...prevState, expandMenuId: state.expandMenuId === section ? "" : section }))
    }

    //for Initiative
    const expandInitiatives = (row, iIndex) => {
        setState(prevState => ({ ...prevState, expandInit: state.expandInit === row.initiative ? "" : row.initiative }))
        let selectedInitiative = ''
        if(state.expandInit !== row.initiative) {
            row.iIndex = iIndex
            selectedInitiative = row
        }
        dispatch(setCostPropsDetails("selectedInitiative", selectedInitiative))
    }
	
	return (
        <div className='border-r border-BlockWhite' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='flex flex-wrap text-black p-2 bg-white'>
                <div className='flex flex-wrap justify-between w-full'>
                    <p className='text-black mb-0 self-center text-lg'>Create Budget</p>
                    {state.pageType === 'View' ?
                        <div className='flex mb-1 text-right text-lightGray'>Currency in
                            <div className="flex mx-1 self-center">
                                <input type="radio"
                                    className=""
                                    checked={state.currencyUnit === 'usd' ? true : false}  
                                    onChange={() => {
                                        setState(prevState => ({ ...prevState, currencyUnit: 'usd', showLoading: true }))
                                        dispatch(setCostPropsDetails('currencyUnit', 'usd'))
                                    }}
                                    value={state.currencyUnit ? true : false}
                                />
                            </div>
                            <p className="mb-0 mr-1 self-center text-lightGray" onClick={() => setState(prevState => ({ ...prevState, currencyUnit: 'usd', showLoading: true }))}>USD</p>
                            <div className="flex mx-1 self-center">
                                <input type="radio"
                                    className=""
                                    checked={state.currencyUnit === 'gbp' ? true : false}  
                                    onChange={() => {
                                        setState(prevState => ({ ...prevState, currencyUnit: 'gbp', showLoading: true }))
                                        dispatch(setCostPropsDetails('currencyUnit', 'gbp'))
                                    }}
                                    value={state.currencyUnit ? true : false}
                                />
                            </div>
                            <p className="mb-0 mr-1 self-center text-lightGray" onClick={() => setState(prevState => ({ ...prevState, currencyUnit: 'gbp', showLoading: true }))}>GBP</p>
                        </div>
                    :
                        <div className='d-flex mb-1 text-right text-lightGray'>Currency in USD</div>
                    }
                    {state.cloudProviderCommitment && state.orgLevelCommitmentState && !state.showProviderSplitUp ?
                        <div className='w-full'>
                            <div className="flex flex-wrap justify-between bg-GhostWhite py-1 px-2 mb-2 rounded-md">
                                <div className='self-center lg:w-1/4 w-full'>
                                    <p className="text-black mb-1 text-lg"> <span className='text-info'>{state.selectedDepCloudProvider ? capitalizeAllLetter(state.selectedDepCloudProvider) : ''}</span> Cloud Provider</p>
                                    <p className="mb-1 text-md text-info cursor-pointer flex justify-center" onClick={() => setState(prevState => ({ ...prevState, showProviderSplitUp: true }))}>View All </p>
                                </div>
                                {state.orgLevelCommitmentState && state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total')).length ?
                                    <div className='flex flex-wrap lg:w-3/4 w-full'>
                                        <div className={`flex md:w-1/3 w-full`}>
                                            <div className="py-1 self-center">
                                                <p className="text-black text-left mb-0 text-lg">Commitment</p>
                                                <p className="text-right mb-0 text-warning text-lg md:pl-0 pl-3">
                                                    {state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total'))[0].commitment ? thousandSeparator(state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total'))[0].commitment) : 0}
                                                </p>
                                            </div>
                                        </div>
                                        <div className={`flex md:w-1/3 w-full`}>
                                            <div className="py-1 self-center">
                                                <p className="text-black text-left mb-0 text-lg">Allocated</p>
                                                <p className="text-right mb-0 text-warning text-lg md:pl-0 pl-3">
                                                    {state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total'))[0].allocated ? thousandSeparator(state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total'))[0].allocated) : 0}
                                                </p>
                                            </div>
                                        </div>
                                        {/* <div className={`flex md:w-1/3 w-full`}>
                                            <div className="py-1 self-center">
                                                <p className="text-black text-left mb-0 text-lg">Balance</p>
                                                <p className={`text-right mb-0 text-lg md:pl-0 pl-3 text-${state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total'))[0].balance > 0 ? 'info' : 'danger'}`}>
                                                    {state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total'))[0].balance ? thousandSeparator(state.orgLevelCommitmentState.filter(e => e.cloudProviderId === (state.selectedDepCloudProvider ? state.selectedDepCloudProvider : 'Total'))[0].balance) : 0}
                                                </p>
                                            </div>
                                        </div> */}
                                    </div>
                                : null}
                            </div>
                        </div>
                    : null}
                    {/* <p className='text-black m-0'>Summary of analysis and observations of the chosen Cloud resources</p> */}
                </div>

                {state.showProviderSplitUp && state.orgLevelCommitmentState && state.orgLevelCommitmentState.length ?
                    <div className='bg-GhostWhite p-2 rounded-md w-full my-2'>
                        <div className='flex'>
                            <p className="text-black text-left mb-2 text-lg self-center">Cloud Provider</p>
                            <p className="mb-2 self-center text-info ml-3 cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, showProviderSplitUp: false }))}>Hide Provider details</p>
                        </div>
                        {state.orgLevelCommitmentState.map((org, orgIndex) => {
                            return(
                                <div key={'dep_'+orgIndex} className={`flex flex-wrap justify-between my-1 ${orgIndex ? 'pt-2 border-t border-mediumDarkGray' : ''}`}>
                                    <div className='self-center lg:w-2/12 w-full'>
                                        <div className="py-1 pl-3">
                                            <span className="ml-2 text-lg text-center mb-0 text-info">{org.cloudProviderId ? capitalizeAllLetter(org.cloudProviderId) : ''}</span>
                                        </div>
                                    </div>
                                    <div className='self-center lg:w-3/12 w-full'>
                                        <div className="flex justify-between py-1 pl-3">
                                            <p className="text-black mb-0 text-lg">Committed</p>
                                            <span className="ml-2 text-lg mb-0 text-warning">{org.commitment ? thousandSeparator(org.commitment) : 0}</span>
                                        </div>
                                    </div>
                                    <div className='self-center lg:w-3/12 w-full'>
                                        <div className="flex justify-between py-1 pl-3">
                                            <p className="text-black mb-0 text-lg">Allocated</p>
                                            <span className="ml-2 text-lg mb-0 text-warning">{org.allocated ? thousandSeparator(org.allocated) : 0}</span>
                                        </div>
                                    </div>
                                    {/* <div className='self-center lg:w-3/12 w-full'>
                                        <div className="flex justify-between py-1 pl-3">
                                            <p className="text-black mb-0 text-lg">Balance</p>
                                            <span className={`ml-2 text-lg mb-0 ${org.balance > 0 ? 'text-info' : 'text-danger'}`}>{org.balance ? thousandSeparator(org.balance) : 0}</span>
                                        </div>
                                    </div> */}
                                </div>  
                            )
                        })}
                    </div>
                : null}

                <div className="p-2 rounded-md bg-BlockWhite w-full">
                    <div className="flex flex-wrap mb-3">
                        <div className="py-1 lg:w-1/3 w-full">
                            <div className='w-full'>
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray">Name</p>
                                    {state.hasError && !createInputs.budget_name ?
                                        <span className='text-danger self-center'>required</span>
                                    : null}
                                </div>
                                <input
                                    type="text"
                                    className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-4/5`}
                                    placeholder="Enter budget name"
                                    // maxlength="128"
                                    readOnly={state.pageType === 'View' ? true : false}
                                    value={createInputs && createInputs.budget_name ? createInputs.budget_name : ""}
                                    onChange={e => handleInputChange("budget_name", e.target.value)}
                                />
                            </div>
                            <div className="mt-2 w-full">
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray">Year</p>
                                    {state.hasError && !state.year ?
                                        <span className='text-danger self-center'>required</span>
                                    : null}
                                </div>
                                <div className={`w-4/5 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showYear")
                                }}>
                                    <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 truncate ${createInputs.year ? 'text-black' : 'text-lightGray'}`}>
                                        {createInputs.year ? createInputs.year : 'Select'}
                                        <Icon icon="icon-park-solid:down-one" className={`${createInputs.year ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                    </p>
                                    {state.showYear ?
                                        <div className='relative'>
                                            <MultiSelectSection 
                                                options={state.yearOptions}
                                                selectedValues={createInputs.year ? createInputs.year : ''}
                                                callbackMultiSelect={(value) => {
                                                    handleInputChange('year', value)
                                                }}
                                                singleSelection={true}
                                                removeTopOptions={true}
                                                widthClass={'minWidth220'}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                        <div className="py-1 lg:w-2/3 w-full lg:pl-3">
                            <p className="b-block mb-0 text-lightGray">Description</p>
                            <textarea 
                                id="description" 
                                name="description" 
                                rows="4" 
                                readOnly={state.pageType === 'View' ? true : false}
                                className={`w-full px-2 py-1 bg-transparent border border-lightGray rounded-md text-black`}
                                onChange={e => {
                                    handleInputChange("description", e.target.value)
                                }}
                                value={createInputs && createInputs.description ? createInputs.description : ""}
                                placeholder='Enter Policy Description'
                                maxLength='256'
                            />
                        </div>
                    </div>

                    <div className={`px-2 py-3 mt-2 bg-white rounded-md`}> 
                        <div className={`mb-0 cursor-pointer`} onClick={() => expandCollapse('Department')}>
                            <div className='flex'>
                                <Icon icon={`${state.expandMenuId === 'Department' ? 'zondicons:minus-outline' :'tabler:circle-plus'}`} className={`mr-1 self-center cursor-pointer text-black`} width={16} height={16} />
                                Step 1 Department
                            </div>
                            {state.providerAllocationError ?
                                <p className='mb-0 text-danger'>{state.providerAllocationError}</p>
                            : null}
                            {state.expandMenuId !== 'Department' ?
                                state.depInitAllocationAmount ?
                                    <p className='mb-0 text-danger'>{state.depInitAllocationAmount}</p>
                                : null
                            : null}                                
                        </div>
                        {state.expandMenuId === 'Department' && state.year ?
                            <div className='border border-lightGray rounded-md p-3 mt-2'>
                                <div className="flex flex-wrap mb-3">
                                    <div className="py-1 lg:w-1/3 md:w-1/2 w-full">
                                        <div className='flex justify-between'>
                                            <p className="b-block mb-0 text-lightGray">Department</p>
                                            {state.hasError && !createInputs.department ?
                                                <span className='text-danger self-center'>required</span>
                                            : null}
                                        </div>
                                        <div className={`w-4/5 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "showDepartment")
                                        }}>
                                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 truncate ${createInputs.department ? 'text-black' : 'text-lightGray'}`}>
                                                {createInputs.department ? createInputs.department : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${createInputs.department ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            {state.showDepartment ?
                                                <div className='relative'>
                                                    <MultiSelectSection 
                                                        options={state.departmentsList}
                                                        selectedValues={createInputs.department ? createInputs.department : []}
                                                        callbackMultiSelect={(value) => 
                                                            handleInputChange('department', value)
                                                        }
                                                        singleSelection={true}
                                                        removeTopOptions={true}
                                                        widthClass={'minWidth220'}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="py-1 lg:w-1/3 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                        <div className='flex justify-between'>
                                            <p className="b-block mb-0 text-lightGray">Cloud Provider</p>
                                        </div>
                                        <div className={`w-4/5 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "showDepCloudProvider")
                                        }}>
                                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 truncate ${state.selectedDepCloudProvider ? 'text-black' : 'text-lightGray'}`}>
                                                {state.selectedDepCloudProvider ? capitalizeAllLetter(state.selectedDepCloudProvider) : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${state.selectedDepCloudProvider ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            {state.showDepCloudProvider ?
                                                <div className='relative'>
                                                    <MultiSelectSection
                                                        options={state.departmentCloudProviders}
                                                        selectedValues={state.selectedDepCloudProvider ? state.selectedDepCloudProvider : ''}
                                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedDepCloudProvider: value, splitEqual: false, onChangeDeparmentProvider: true }))}
                                                        singleSelection={true}
                                                        removeTopOptions={true}
                                                        widthClass={'minWidth220'}
                                                        makeLabelUpperCase={'capitalizeAllLetter'}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                                {state.department ?
                                    <React.Fragment>                                        
                                    {state.departmentProviders && state.departmentProviders.length ?
                                        <div className='bg-GhostWhite p-2 rounded-md mb-2'>
                                            {state.departmentProviders.map((dep, depIndex) => {
                                                return(
                                                    <div key={'dep_'+depIndex} className={`flex flex-wrap justify-between my-1 ${depIndex ? 'pt-2 border-t border-mediumDarkGray' : ''}`}>
                                                        <div className='self-center lg:w-2/12 w-full'>
                                                            <div className="py-1 pl-3">
                                                                <span className="ml-2 text-lg text-center mb-0 text-info">{dep.provider ? capitalizeAllLetter(dep.provider) : ''}</span>
                                                            </div>
                                                        </div>
                                                        <div className='self-center lg:w-3/12 w-full'>
                                                            <div className="flex justify-between py-1 pl-3">
                                                                <p className="text-black mb-0 text-lg">Committed</p>
                                                                <span className="ml-2 text-lg mb-0 text-warning">{dep.allocation ? thousandSeparator(dep.allocation) : 0}</span>
                                                            </div>
                                                        </div>
                                                        <div className='self-center lg:w-3/12 w-full'>
                                                            <div className="flex justify-between py-1 pl-3">
                                                                <p className="text-black mb-0 text-lg">Allocated</p>
                                                                <span className="ml-2 text-lg mb-0 text-warning">{dep.distributuion ? thousandSeparator(dep.distributuion) : 0}</span>
                                                            </div>
                                                        </div>
                                                        {/* <div className='self-center lg:w-3/12 w-full'>
                                                            <div className="flex justify-between py-1 pl-3">
                                                                <p className="text-black mb-0 text-lg">Balance</p>
                                                                <span className={`ml-2 text-lg mb-0 ${dep.balance > 0 ? 'text-info' : 'text-danger'}`}>{dep.balance ? thousandSeparator(dep.balance) : 0}</span>
                                                            </div>
                                                        </div> */}
                                                    </div>  
                                                )
                                            })}
                                        </div>
                                    : null}
                                    {state['departmentAllocationError_'+state.selectedDepCloudProvider] ?
                                        <p className='mb-0 text-danger mt-2'>{state['departmentAllocationError_'+state.selectedDepCloudProvider]}</p>
                                    : null}
                                    {state.departmentInitiativeAllocationError && state.departmentInitiativeAllocationError.length ?
                                        state.departmentInitiativeAllocationError.map(err => {
                                            return(
                                                err ?
                                                    <p className='mb-1 text-danger'>{err}</p>
                                                : null
                                            )
                                        })
                                    : null}
                                    {state['departmentOrgAllocationError_'+state.selectedDepCloudProvider] && state['departmentOrgAllocationError_'+state.selectedDepCloudProvider].length ?
                                        state['departmentOrgAllocationError_'+state.selectedDepCloudProvider].map(err => {
                                            return(
                                                err ?
                                                    <p className='mb-1 text-danger'>{err}</p>
                                                : null
                                            )
                                        })
                                    : null}
                                    <div className="flex flex-wrap mt-3">
                                        <div className="py-1 lg:w-2/5 w-full">
                                            <div className=''>
                                                <div className='flex justify-between'>
                                                    <p className="b-block mb-1 text-lightGray">Annual Budget</p>
                                                </div>
                                                <div className='flex'>
                                                    <input
                                                        type="text"
                                                        className={`border ${state.hasError && state.departmentInitiativeAllocation ? 'border-danger' : 'border-lightGray'} rounded-md bg-transparent px-2 py-1 text-black w-4/5`}
                                                        placeholder="Enter amount"
                                                        readOnly={state.pageType === 'View' ? true : false}
                                                        // maxlength="128"
                                                        value={createInputs && createInputs.cloud_budget && createInputs.cloud_budget[state.selectedDepCloudProvider] && createInputs.cloud_budget[state.selectedDepCloudProvider].allocation ? createInputs.cloud_budget[state.selectedDepCloudProvider].allocation : ''}
                                                        onChange={e => {
                                                            handleDepartmentInputChange("allocation", onlyNumeric(e.target.value))
                                                            setTimeout(() => { onChangeHandleValidation() }, 1000); 
                                                            if(state.splitEqual) {
                                                                setState(prevState => ({ ...prevState, departmentMonthlyDistribution: true }))
                                                            }
                                                        }}
                                                    />
                                                    <p className="text-black mb-0 ml-2 self-center">{capitalizeAllLetter(state.currencyUnit)}</p>
                                                </div>
                                            </div>    
                                        </div>
                                        <div className='py-1 lg:w-2/5 w-full self-center lg:ml-4'>
                                            <div className='flex self-center mt-3'>
                                                <p className="mb-0 text-lightGray mr-2 self-center" onClick={() => {
                                                    if(state.pageType !== 'View') {
                                                        setState(prevState => ({ ...prevState, splitEqual: !state.splitEqual }))
                                                    }
                                                }}>
                                                    Equal Monthly Budget
                                                </p>
                                                <label className="mb-0">
                                                    <input type="checkbox" 
                                                        className="mt-2"
                                                        readOnly={state.pageType === 'View' ? true : false}
                                                        checked={state.splitEqual ? true : false}  
                                                        onChange={() => {
                                                            if(state.pageType !== 'View') {
                                                                setState(prevState => ({ ...prevState, splitEqual: !state.splitEqual, departmentMonthlyDistribution: true}))
                                                            }
                                                        }}
                                                        value={state.splitEqual ? true : false}
                                                    />
                                                    <span className="slider round small pl-1"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>                                                                

                                    <div className=''>   
                                        {state.departmentMonthlyAllocationError ?
                                            <p className='mb-1 text-danger'>{state.departmentMonthlyAllocationError}</p>
                                        : state.depIniMonthlyError ?
                                            <p className='mb-0 text-danger'>{state.depIniMonthlyError}</p>
                                        : null}
                                        
                                        {state.departmentInitiativeMonthlyTotalError && state.departmentInitiativeMonthlyTotalError.length ?
                                            state.departmentInitiativeMonthlyTotalError.map(err => {
                                                return(
                                                    err ?
                                                        <p className='mb-1 text-danger'>{err}</p>
                                                    : null
                                                )
                                            })
                                        : null}
                                        <p className='text-black my-1'>Monthly Budget</p>
                                        <div className='flex flex-wrap mt-1'>
                                            {state.quarters && state.quarters.map((quarter, rowIndex) => {
                                                return(
                                                    <div className='w-1/4 pl-0' key={rowIndex}>
                                                        {quarter.map(mnt => {
                                                            return(
                                                                <div className='mb-3' key={'dep_'+mnt.label}>
                                                                    <div className='flex justify-between'>
                                                                        <p className="mb-0 text-lightGray">{mnt.label+' '+(state.year ? state.year : '')}</p>
                                                                        {/* {state.hasError && !state.budget_name ?
                                                                            <span className='text-danger self-center'>required</span>
                                                                        : null} */}
                                                                    </div>
                                                                    <input
                                                                        type="text"
                                                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-4/5`}
                                                                        readOnly={state.pageType === 'View' ? true : false}
                                                                        value={state['department_'+state.selectedDepCloudProvider+'_'+mnt.label] ? state['department_'+state.selectedDepCloudProvider+'_'+mnt.label] : ''}
                                                                        onChange={e => {
                                                                            setState(prevState => ({ ...prevState, ['department_'+state.selectedDepCloudProvider+'_'+mnt.label]: onlyNumeric(e.target.value) }))
                                                                            handleMonthlyDistributionChange(mnt.value, onlyNumeric(e.target.value))
                                                                            onChangeHandleValidation(onlyNumeric(e.target.value), mnt)
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                        <div className="flex justify-center w-4/5">
                                                            <div className="w-1/2 border-t-2 border-info"></div>
                                                        </div>
                                                        <span className='flex justify-center mt-2 w-4/5'>Q{rowIndex+1} <span className='ml-2 text-warning'>{thousandSeparator(Math.round(state['department_quater_'+rowIndex] ? state['department_quater_'+rowIndex] : 0))}</span></span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='flex flex-wrap justify-between mt-4'>
                                            <div className='w-1/2'>
                                                <div className="flex justify-center">
                                                    <div className="w-1/2 border-t-2 border-info"></div>
                                                </div>
                                                <span className='mt-2 flex justify-center'>H1 <span className='ml-2 text-warning'>{state.department_half_0 ? thousandSeparator(Math.round(state.department_half_0)) : 0}</span></span>
                                            </div>
                                            <div className='w-1/2'>
                                                <div className="flex justify-center">
                                                    <div className="w-1/2 border-t-2 border-info"></div>
                                                </div>
                                                <span className='mt-2 flex justify-center'>H2 <span className='ml-2 text-warning'>{state.department_half_1 ? thousandSeparator(Math.round(state.department_half_1)) : 0}</span></span>
                                            </div>
                                        </div>
                                        {/* <div className='flex justify-between mt-2'>
                                            <span className='w-50 flex justify-center'>H1 <span className='ml-2 text-warning'>{state.department_half_0 ? thousandSeparator(Math.round(state.department_half_0)) : 0}</span></span>
                                            <span className='w-50 flex justify-center'>H2 <span className='ml-2 text-warning'>{state.department_half_0 ? thousandSeparator(Math.round(state.department_half_1)) : 0}</span></span>
                                        </div>                                         */}
                                    </div>
                                    </React.Fragment>
                                : null}
                            </div>
                        : null}
                    </div>
                                
                    {state.department && state.year ?
                        <div className={`px-2 mt-2 py-3 bg-white mb-4`}> 
                            <div className={`mb-0 cursor-pointer`} onClick={() => expandCollapse('initiatives')}>
                                <div className={`flex`}>
                                    <Icon icon={`${state.expandMenuId === 'initiatives' ? 'zondicons:minus-outline' :'tabler:circle-plus'}`} className={`mr-1 self-center cursor-pointer text-black`} width={16} height={16} />
                                    Step 2 Initiatives
                                </div>
                                {state['initDepAllocationAmount'] ?
                                    <p className='mb-1 text-danger'>{state['initDepAllocationAmount']}</p>
                                : null}
                            </div>
                            {state.expandMenuId === 'initiatives' ?
                                <div className='border border-lightGray rounded-md p-3 mt-2'>
                                    {state.departmentDetails && state.departmentDetails.initiatives.length ?
                                        state.departmentDetails.initiatives.map((row, iIndex) => {
                                            return(
                                                <div key={'init_'+iIndex} className={`px-2 py-4 bg-GhostWhite ${iIndex ? 'border-t border-mediumDarkGray' : ""}`}> 
                                                    <div className={`flex cursor-pointer`} onClick={() => expandInitiatives(row, iIndex)}>
                                                        <Icon icon={`${state.expandInit === row.initiative ? 'zondicons:minus-outline' :'tabler:circle-plus'}`} className={`mr-1 self-center cursor-pointer text-black`} width={16} height={16} />
                                                        {row.initiative}
                                                    </div>
                                                    {state.expandInit === row.initiative ?
                                                        <div className="my-3">
                                                            {state['initiativeAllocationError_'+row.initiative] ?
                                                                <p className='mb-1 text-danger'>{state['initiativeAllocationError_'+row.initiative]}</p>
                                                            : null}
                                                            {state['initProjectAllocationAmount_'+row.initiative] && state['initProjectAllocationAmount_'+row.initiative].length ?
                                                                state['initProjectAllocationAmount_'+row.initiative].map(err => {
                                                                    return(
                                                                        <p className='mb-1 text-danger'>{err}</p>
                                                                    )
                                                                })
                                                            : null}
                                                            {state.initiativeDepartmentAllocationError && state.initiativeDepartmentAllocationError.length ?
                                                                state.initiativeDepartmentAllocationError.map(err => {
                                                                    return(
                                                                        err ?
                                                                            <p className='mb-1 text-danger'>{err}</p>
                                                                        : null
                                                                    )
                                                                })
                                                            : null}
                                                            <div className='flex flex-wrap'>
                                                                <div className="py-1 lg:w-2/5 w-full">
                                                                    <div className='flex justify-between'>
                                                                        <p className="mb-0 text-lightGray">Cloud Provider</p>
                                                                    </div>
                                                                    <div className={`w-3/5 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                                                        event.preventDefault();
                                                                        handleChildClick(event, 'child', 'singleDropDown', "showInitCloudProvider_"+iIndex)
                                                                    }}>
                                                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${state['selectedInitCloudProvider_'+iIndex] ? 'text-black' : 'text-lightGray'}`}>
                                                                            {state['selectedInitCloudProvider_'+iIndex] ? capitalizeAllLetter(state['selectedInitCloudProvider_'+iIndex]) : 'Select'}
                                                                            <Icon icon="icon-park-solid:down-one" className={`${state['selectedInitCloudProvider_'+iIndex] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                                                        </p>
                                                                        {state["showInitCloudProvider_"+iIndex] ?
                                                                            <div className='relative'>
                                                                                <MultiSelectSection 
                                                                                    options={state['initiativeCloudProviders_'+iIndex] ? state['initiativeCloudProviders_'+iIndex] : []}
                                                                                    selectedValues={state['selectedInitCloudProvider_'+iIndex] ? state['selectedInitCloudProvider_'+iIndex] : ''}
                                                                                    makeLabelUpperCase={'capitalizeAllLetter'}
                                                                                    callbackMultiSelect={(value) => {
                                                                                        setState(prevState => ({ ...prevState, ['selectedInitCloudProvider_'+iIndex]: value,
                                                                                        ['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]]: state['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]] ? true : false }))
                                                                                        onChangeInitCloudProvider(value, iIndex)
                                                                                    }}
                                                                                    singleSelection={true}
                                                                                    widthClass={'minWidth220'}
                                                                                    removeTopOptions={true}
                                                                                />
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                                <div className="py-1 lg:w-1/2 w-full">
                                                                    <div className=''>
                                                                        <p className="mr-2 self-center text-lightGray">Annual Budget</p>
                                                                        <div className='flex'>
                                                                            <input
                                                                                type="text"
                                                                                className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-4/5`}
                                                                                placeholder="Enter amount"
                                                                                readOnly={state.pageType === 'View' ? true : false}
                                                                                // maxlength="128"
                                                                                value={state['initiativeAlloation_'+iIndex] ? state['initiativeAlloation_'+iIndex] : 0}
                                                                                onChange={e => {
                                                                                    setState(prevState => ({ ...prevState, ['initiativeAlloation_'+iIndex]: e.target.value }))
                                                                                    handleInitiativeInputChange('allocation', iIndex, onlyNumeric(e.target.value))
                                                                                    if(state['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex]) {
                                                                                        handleInitiativeMonthlyEqualSplit(true, iIndex, onlyNumeric(e.target.value))
                                                                                    }
                                                                                    onChangeHandleValidation()
                                                                                }}
                                                                            />
                                                                            <p className="text-black mb-0 ml-2 self-center">{capitalizeAllLetter(state.currencyUnit)}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='py-1 flex self-center mt-3'>
                                                                <p className="mb-0 text-lightGray mr-2 self-center" onClick={() => {
                                                                    if(state.pageType !== 'View') {
                                                                        setState(prevState => ({ ...prevState, ['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex]: !state['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex] }))
                                                                    }
                                                                }}>Equal Monthly Budget</p>
                                                                <label className="mb-0">
                                                                    <input type="checkbox" 
                                                                        className="mt-2"
                                                                        readOnly={state.pageType === 'View' ? true : false}
                                                                        checked={state['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex] ? true : false}
                                                                        onChange={(e) => {
                                                                            if(state.pageType !== 'View') {
                                                                                let monthlySplit = !state['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex]
                                                                                setState(prevState => ({ ...prevState, ['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex]: monthlySplit }))
                                                                                handleInitiativeMonthlyEqualSplit(monthlySplit, iIndex, state['initiativeAlloation_'+iIndex])
                                                                            }
                                                                        }}
                                                                        value={state['montly_split_equal_'+state['selectedInitCloudProvider_'+iIndex]+'_'+iIndex] ? true : false}
                                                                    />
                                                                    <span className="slider round small pl-1"></span>
                                                                </label>
                                                            </div>

                                                            <div className=''>
                                                                {state.initiativeProjectMonthlyTotalError && state.initiativeProjectMonthlyTotalError.length ?
                                                                    state.initiativeProjectMonthlyTotalError.map(err => {
                                                                        return(
                                                                            err ?
                                                                                <p className='mb-1 text-danger'>{err}</p>
                                                                            : null
                                                                        )
                                                                    })
                                                                : null}
                                                                {state['initiativeProviders_'+row.initiative] && state['initiativeProviders_'+row.initiative].length ?
                                                                    <div className='bg-BlockWhite m-1 p-2 rounded-md'>
                                                                        {state['initiativeProviders_'+row.initiative].map((ini, iniIndex) => {
                                                                            return(
                                                                                <div key={'ini_'+iniIndex} className={`flex flex-wrap justify-between my-1 ${iniIndex ? 'pt-2 border-t border-mediumDarkGray' : ''}`}>
                                                                                    <div className='self-center lg:w-2/12 w-full'>
                                                                                        <div className="py-1 pl-3">
                                                                                            <span className="ml-2 text-lg text-center mb-0 text-info">{ini.provider ? capitalizeAllLetter(ini.provider) : ''}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='self-center lg:w-3/12 w-full'>
                                                                                        <div className="flex justify-between py-1 pl-3">
                                                                                            <p className="text-black mb-0 text-lg">Committed</p>
                                                                                            <span className="ml-2 text-lg mb-0 text-warning">{ini.allocation ? thousandSeparator(ini.allocation) : 0}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='self-center lg:w-3/12 w-full'>
                                                                                        <div className="flex justify-between py-1 pl-3">
                                                                                            <p className="text-black mb-0 text-lg">Allocated</p>
                                                                                            <span className="ml-2 text-lg mb-0 text-warning">{ini.distributuion ? thousandSeparator(ini.distributuion) : 0}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='self-center lg:w-3/12 w-full'>
                                                                                        <div className="flex justify-between py-1 pl-3">
                                                                                            <p className="text-black mb-0 text-lg">Balance</p>
                                                                                            <span className={`ml-2 text-lg mb-0 ${ini.balance > 0 ? 'text-info' : 'text-danger'}`}>{ini.balance ? thousandSeparator(ini.balance) : 0}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>  
                                                                            )
                                                                        })}
                                                                    </div>
                                                                : null}
                                                                <p className='mb-0 my-1'>Monthly Budget</p>
                                                                <div className='flex flex-wrap mt-2'>
                                                                    {state.quarters && state.quarters.map((quarter, rowIndex) => {
                                                                        return(
                                                                            <div className='w-1/4' key={"montly_q_"+rowIndex}>
                                                                                {quarter.map(mnt => {
                                                                                    return(
                                                                                        <div className='mb-3 text-lightGray' key={'ini_'+mnt.label}>
                                                                                            <div className='flex justify-between'>
                                                                                                <p className="mb-0 text-lightGray">{mnt.label+' '+(state.year ? state.year : '')}</p>
                                                                                            </div>
                                                                                            <input
                                                                                                type="text"
                                                                                                className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-4/5`}
                                                                                                readOnly={state.pageType === 'View' ? true : false}
                                                                                                value={state['initiative_'+mnt.label+'_'+iIndex] ? state['initiative_'+mnt.label+'_'+iIndex] : ''}
                                                                                                onChange={e => {
                                                                                                    setState(prevState => ({ ...prevState, ['initiative_'+mnt.label+'_'+iIndex]: onlyNumeric(e.target.value) }))
                                                                                                    handleInitiativeMonthlySplit(mnt.value, iIndex, onlyNumeric(e.target.value))
                                                                                                    onChangeHandleValidation(onlyNumeric(e.target.value), mnt)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                                
                                                                                <div className="flex justify-center w-4/5">
                                                                                    <div className="w-1/2 border-t-2 border-info"></div>
                                                                                </div>
                                                                                <span className='flex justify-center mt-2 w-4/5'>Q{rowIndex+1} {state['initiative_quater_'+rowIndex+'_'+iIndex] ? Math.round(state['initiative_quater_'+rowIndex+'_'+iIndex],0) : 0}</span>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                                
                                                                <div className='flex flex-wrap justify-between mt-4'>
                                                                    <div className='w-1/2'>
                                                                        <div className="flex justify-center">
                                                                            <div className="w-1/2 border-t-2 border-info"></div>
                                                                        </div>
                                                                        <span className='mt-2 flex justify-center'>H1 {state['initiative_half_0_'+iIndex] ? Math.round(state['initiative_half_0_'+iIndex],0) : 0}</span>
                                                                    </div>
                                                                    <div className='w-1/2'>
                                                                        <div className="flex justify-center">
                                                                            <div className="w-1/2 border-t-2 border-info"></div>
                                                                        </div>
                                                                        <span className='mt-2 flex justify-center'>H2 {state['initiative_half_1_'+iIndex] ? Math.round(state['initiative_half_1_'+iIndex],0) : 0}</span>
                                                                    </div>
                                                                </div>
                                                                
                                                                {/* <div className='flex justify-between mt-4'>
                                                                    <div className='w-50 borderBottomMiddleBlue'></div>
                                                                    <div className='w-50 borderBottomMiddleBlue'></div>
                                                                </div>
                                                                <div className='flex justify-between mt-2'>
                                                                    <span className='w-50 flex justify-center'>H1 {state['initiative_half_0_'+iIndex] ? Math.round(state['initiative_half_0_'+iIndex],0) : 0}</span>
                                                                    <span className='w-50 flex justify-center'>H2 {state['initiative_half_1_'+iIndex] ? Math.round(state['initiative_half_1_'+iIndex],0) : 0}</span>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                            )
                                        })
                                    : null}
                                </div>
                            : null}
                        </div>
                    : null}
                </div>
			</div>
		</div>
	)
}

export default LeftSection