/*************************************************
 * Tvastar
 * @exports
 * @file GroupAssocationDetails.js
 * @author Prakash // on 06/03/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions

import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction'
import { listUsers } from '../../../actions/commonActionNew'
import MultiSelectSection from '../../common/MultiSelectSection';
import { useLocation } from 'react-router-dom';
import {Icon} from "@iconify/react";
import SelectOption from '../../common/Form/SelectOption';

const GroupAssocationDetails = (props) => {
	const [state, setState] = useState({
		hasErrorInRuleForm: false,
		hasError: props.hasError,
		masterData: props.masterData,
		filteredMasterData: props.masterData,
		callUserList: true
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const qosCreateEditInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.qosCreateEditInputs || false)
	const usersList = useSelector(state => state?.filters?.listUsers || false);

	const location = useLocation();
	const receivedData = location.state || false

	const handleInputChange = useCallback((label, value) => {
		let obj = qosCreateEditInputs ? qosCreateEditInputs : {}
		obj[label] = value
		dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
	}, [dispatch, qosCreateEditInputs])

	useEffect(() => {
		if(receivedData && Object.entries(receivedData).length) {
			setState(prevState => ({ 
				...prevState,
				selectedUsers: receivedData.selectedData && receivedData.selectedData.user_name ? receivedData.selectedData.user_name : []
			}))

			dispatch(setHpcPropsDetails('qosCreateEditInputs', receivedData.selectedData))
		} else {
			setState(prevState => ({ 
				...prevState,
				pageType: 'Create',
			}))
		}
	}, [receivedData, dispatch])

	useEffect(() => {
        if (state.callUserList) {
			setState(prevState => ({ ...prevState, callUserList: false }));
            let params = {};
            if(!usersList) {
                dispatch(listUsers(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({ ...prevState, usersList: response }));
                        }
                    })
            } else {
                setState(prevState => ({ ...prevState, usersList }));
            }
        }
    }, [dispatch, state.callUserList, usersList])

    const removeItem = (field, value) => {
        setState(prevState => ({ ...prevState, [field]: state[field].filter(e => e !== value) }))
		handleInputChange('requested_user_name', state[field].filter(e => e !== value))
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div onClick={(event) => props.onChildPageClick(event)}>
			<div className={`border-t border-NeutralGray-600 pt-8 mt-8`}>
				<p className='font-bold text-NeutralGray-800 text-[22px] pb-4'>Associations</p>
				<div className='flex flex-wrap'>
					<div className='flex flex-col justify-start w-1/2 pr-4'>
						<SelectOption
							label={"User"}
							// fields={["user", "label"]}
							options={state.usersList}
							selectedValues={state.selectedUsers ? state.selectedUsers : ''}
							callbackMultiSelect={(value) => {
								setState(prevState => ({ ...prevState, selectedUsers: value }))
								handleInputChange('requested_user_name', value)
							}}
							dropdownWidth={'w-full'}
							singleSelection={false}
						/>
						{state.selectedUsers && state.selectedUsers.length ? 
							<div className='flex flex-wrap mt-4'>
								{state.selectedUsers.map(row => {
									return(
										<span key={row} className="flex border border-primaryPurple-600 rounded-lg px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all">{row}
											<Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-black ml-2 self-center' onClick={ () => removeItem('selectedUsers', row) } />
										</span>
									)
								})}
							</div>
						: null}
					</div>
				</div>
			</div>
		</div>
	)
}

export default GroupAssocationDetails
