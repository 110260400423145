import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
// import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import { listWorkstationRequests, changeWorkstationStatus, applyWorkstationActions } from '../../../../actions/CatalogAction'
import { listRequests, changeRequestStatus } from '../../../../actions/files/FileAction'
import { Store as CommonNotification } from 'react-notifications-component';
import windows from '../../../../assets/images/windows-pngwing.png'
import linux from '../../../../assets/images/linux-pngwing.png'
import aws from '../../../../assets/images/aws-black-pngwing.png'
import gcp from '../../../../assets/images/gcp-pngwing.png'
// import ubuntu from '../../../../assets/images/ubuntu-pngwing.png'
import WorkstationSidePanel from '../WorkstationSidePanel';
import StorageSidePanel from '../StorageSidePanel';
import Search from '../../../common/SearchWithHiddenInput'
import { capitalizeFirstLetter, momentConvertionUtcToLocalTime } from '../../../../utils/utility';
import {Icon} from "@iconify/react";
import PageLoading from '../../../common/PageLoading';
import { LoadingCircle } from '../../../common/LoadingCiricle';

const NewRequest = (props) => {
    // State variables for filters
    const [state, setState] = useState({
        showPageLoading: true,
        selectedProvider: props.selectedProvider,
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion,
        selectedRequestType: "workstation",
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 9,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    // const history = useHistory();
    // const navigate = useNavigate();

    useEffect(() => {
        setState(prevState => ({ ...prevState, callListRequest: true, callListStorageRequest: false }))
    }, [])

    useEffect(() => {
        if(state.callListRequest) {
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== 'All')
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== 'All')
            }

            if(state.nextReqeustToken !== '') {
                params.next_token = state.nextReqeustToken
            }

            // params.size = 9

            if (state.selectedRequestType && state.selectedRequestType === "workstation"){
                dispatch(listWorkstationRequests(params))
                    .then((response) => {
                        if(response) {
                            let totalResults = []
                            let startRecord = state.startRecord
                            let currentPage = state.currentPage 
                            if(state.nextReqeustToken) {
                                totalResults = state.listNewReqeusts &&  state.listNewReqeusts.length ? state.listNewReqeusts : []
                                startRecord = startRecord + state.perPage;
                                currentPage = currentPage+ 1
                            }
                            if(response.results && response.results.length) {
                                totalResults = totalResults.concat(response.results)
                            }
                            

                            let totalPages = 1
                            if(totalResults && totalResults.length > state.perPage) {
                                totalPages = Math.ceil(totalResults.length / state.perPage)
                            }
                            let json = response.workstation_details ? response.workstation_details : []

                            let detailsFormat = _.orderBy(json, ['order'], ['asc'])
                            
                            setState(prevState => ({ ...prevState, listNewReqeusts: totalResults, filterNewReqeust: totalResults, detailsFormat, selectedFilters: params, nextReqeustToken: response.next_token, showPageLoading: false, showLoading:false, callListRequest: false, callListStorageRequest: true, startRecord, currentPage, totalPages, totalRecords: response.total ? response.total : 0 }));
                        }
                    })
            }
        }
    }, [state.callListRequest, state.selectedRequestType, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.listNewReqeusts, state.nextReqeustToken, state.currentPage, state.perPage, state.startRecord])

    useEffect(() => {
        if(state.callListStorageRequest) {
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== 'All')
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== 'All')
            }

            if(state.nextReqeustToken !== '') {
                params.next_token = state.nextReqeustToken
            }

            // params.size = 9
    
            if (state.selectedRequestType && state.selectedRequestType === "storage"){
                params.request_type = state.selectedRequestType
                dispatch(listRequests(params))
                    .then((response) => {
                        if(response) {
                            let totalResults = []
                            let startRecord = state.startRecord
                            let currentPage = state.currentPage 
                            if(state.nextReqeustToken) {
                                totalResults = state.listNewReqeusts &&  state.listNewReqeusts.length ? state.listNewReqeusts : []
                                startRecord = startRecord + state.perPage;
                                currentPage = currentPage+ 1
                            }
                            if(response.results && response.results.length) {
                                totalResults = totalResults.concat(response.results)
                            }
                            

                            let totalPages = 1
                            if(totalResults && totalResults.length > state.perPage) {
                                totalPages = Math.ceil(totalResults.length / state.perPage)
                            }
                            let json = response.storage_details ? response.storage_details : []

                            let detailsFormat = _.orderBy(json, ['order'], ['asc'])
                            
                            setState(prevState => ({ ...prevState, listNewReqeusts: totalResults, filterNewReqeust: totalResults, detailsFormat, selectedFilters: params, nextReqeustToken: response.next_token, showPageLoading: false, showLoading: false, callListStorageRequest: false, callListRequest: true, startRecord, currentPage, totalPages, totalRecords: response.total ? response.total : 0 }));
                        }
                    })
            }
        }
    }, [state.callListStorageRequest, state.selectedRequestType, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.listNewReqeusts, state.nextReqeustToken, state.currentPage, state.perPage, state.startRecord])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextReqeustToken) {
            setState(prevState => ({ ...prevState, showLoading: true, callListRequest: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    useEffect(() => {
        if(state.selectedStatus && state.selectedCatalog && state.selectedCatalog.request_type === 'workstation') {
            if(state.selectedStatus === 'launch') {
                let params = {}
                params.workstation_id = state.selectedCatalog.workstation_id
                params.action = state.selectedStatus

                dispatch(applyWorkstationActions(params))
                    .then((response) => {
                        setState(prevState => ({ ...prevState, callListRequest: true, ['hideApproved_'+state.selectedCatalog.workstation_id]: true }))
                        let messageType = 'success'
                        let message = response.message
                        if(!response.status) {
                            messageType = 'danger'
                        } else {
                            setState(prevState => ({ ...prevState, hideActionBtn: true }))
                        }
                        // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    })
            } else {
                let params = state.selectedCatalog
                params.status = state.selectedStatus
    
                dispatch(changeWorkstationStatus(params))
                .then((response) => {
                    setState(prevState => ({ ...prevState, requestLoading: false }))

                    let messageType = 'danger'
                    let message = response.message ? response.message : 'Error in '+state.selectedStatus+' request'
                    if(response.status) {
                        messageType = 'success'
                        message = response.message ? response.message : 'Reqeust '+state.selectedStatus+' successfully'

                        if (state.selectedCatalog.workstation_id)
                        {
                            if(state.selectedStatus === 'rejected') {
                                setState(prevState => ({ ...prevState, ['hideRejected_'+state.selectedCatalog.workstation_id]: true, callListRequest: true }))
                            } else {
                                setState(prevState => ({ ...prevState, ['hideApproved_'+state.selectedCatalog.workstation_id]: true, callListRequest: true }))
                            }
                        }
                    } else {
                        if (state.selectedCatalog.workstation_id){
                            setState(prevState => ({...prevState, ['loadingRejected_'+state.selectedCatalog.workstation_id]: false, ['loadingApproved_'+state.selectedCatalog.workstation_id]: false }))
                        }
                    }

                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                })
            }
        }

    }, [dispatch, state.selectedStatus, state.selectedCatalog])

    
    useEffect(() => {
        if(state.selectedStatus && state.selectedCatalog && state.selectedCatalog.request_type === 'storage') {
            // if(state.selectedStatus === 'approved') {
            //     let params = {}
            //     params.storage_request_id = state.selectedCatalog.storage_request_id
            //     params.action = state.selectedStatus

            //     dispatch(applyStorageActions(params))
            //         .then((response) => {
            //             setState(prevState => ({ ...prevState, callListRequest: true, ['hideApproved_'+state.selectedCatalog.storage_request_id]: true }))
            //             let messageType = 'success'
            //             let message = response.message
            //             if(!response.status) {
            //                 messageType = 'danger'
            //             } else {
            //                 setState(prevState => ({ ...prevState, hideActionBtn: true }))
            //             }
            //             // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
        
            //             CommonNotification.addNotification({
            //                 //title: 'Wonderful!',
            //                 message: message,
            //                 type: messageType,
            //                 insert: 'top',
            //                 container: 'top-center',
            //                 // animationIn: ['animate__animated', 'animate__fadeIn'],
            //                 // animationOut: ['animate__animated', 'animate__fadeOut'],
            //                 dismiss: {
            //                     duration: 5000,
            //                     onScreen: false,
            //                     pauseOnHover: true,
            //                     showIcon: true,
            //                 }
            //             });
            //         })
            // } else {
                let params = state.selectedCatalog
                params.status = state.selectedStatus

                dispatch(changeRequestStatus(params))
                    .then((response) => {
                        setState(prevState => ({ ...prevState, requestLoading: false }))
    
                        let messageType = 'danger'
                        let message = response.message ? response.message : 'Error in '+state.selectedStatus+' request'
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'Reqeust '+state.selectedStatus+' successfully'

                            if (state.selectedCatalog.storage_request_id)
                            {
                                if(state.selectedStatus === 'rejected') {
                                    setState(prevState => ({ ...prevState, ['hideRejected_'+state.selectedCatalog.storage_request_id]: true, callListStorageRequest: true }))
                                } else {
                                    setState(prevState => ({ ...prevState, ['hideApproved_'+state.selectedCatalog.storage_request_id]: true, callListStorageRequest: true }))
                                }
                            } 
                        } else {
                            if (state.selectedCatalog.storage_request_id){
                                setState(prevState => ({...prevState, ['loadingRejected_'+state.selectedCatalog.storage_request_id]: false, ['loadingApproved_'+state.selectedCatalog.storage_request_id]: false }))
                            }
                        }

                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    })
            // }
        }

    }, [dispatch, state.selectedStatus, state.selectedCatalog])

    const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();
    
        let clickedChild = [];
        if (type === "child") {
          if (state.clickedChild && state.clickedChild.length) {
            state.clickedChild.forEach((row) => {
              setState((prevState) => ({ ...prevState, [row]: false }));
            });
          }
          clickedChild.push(section);
          setState((prevState) => ({
            ...prevState,
            [section]:
              dropdownType === "singleDropDown" && state[section] ? false : true,
            clickedChild,
          }));
        } else if (type === "parent") {
          if (state.clickedChild && state.clickedChild.length) {
            state.clickedChild.forEach((row) => {
              setState((prevState) => ({ ...prevState, [row]: false }));
            });
          }
    
          setState((prevState) => ({ ...prevState, clickedChild }));
        }
    }

    return (
        state.showPageLoading ?
            <div className="w-full flex justify-center mt-4">
                <PageLoading />
            </div>
        : 
            <React.Fragment>
           {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                    <WorkstationSidePanel 
                        closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {} }))}
                        detailsFormat={state.detailsFormat}
                        selectedDetails={state.selectedDetails}
                        showOnlyDeatils={true}
                    />
                : state.selectedStorageDetails && Object.entries(state.selectedStorageDetails).length ?
            
                    <StorageSidePanel
                        closeSidePanel={() => setState(prevState => ({...prevState, selectedStorageDetails: {} }))}
                        detailsFormat={state.detailsFormat[0]}
                        selectedDetails={state.selectedStorageDetails}
                        showOnlyDeatils={true}
                    />
                : null}
                <div className='p-2' onClick={(event) => handleChildClick(event, 'parent')}>
                
                <div className='justify-between w-full my-2'>
                    <div className={`flex justify-start px-2`}>
                        <ul className="flex flex-wrap text-sm text-white font-bold text-center mb-1 mt-3">
                            <li className="me-2">
                                <span className={`inline-block mb-0 text-base px-4 text-primaryPurple-600 rounded-t-lg ${state.selectedRequestType === "workstation" ? 'active border-b-2 border-primaryPurple-600' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedRequestType: "workstation", showLoading: true}))}>Workstation</span>
                            </li>
                            <li className="me-2">
                                <span className={`inline-block mb-0 text-base px-4 text-primaryPurple-600 rounded-t-lg ${state.selectedRequestType === "storage" ? 'active border-b-2 border-primaryPurple-600' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedRequestType: "storage", showLoading: true}))}>Storage</span>
                            </li>
                        </ul>
                    </div>
                    </div>

                    {state.showLoading ?
                    <div className="w-full flex justify-center mt-4">
                        <LoadingCircle />
                    </div>
                : state.filterNewReqeust && state.filterNewReqeust.length ? (
                        <React.Fragment>
                        
                    <div className='border border-NeutralGray-600 p-4 rounded-2xl'>
                    <div className='flex justify-between pb-8'>
                        <p className='mb-0 text-sm self-center lg:w-1/2 w-full'>
                            <span>Showing {state.filterNewReqeust && state.filterNewReqeust.length} out of total {state.totalRecords} request(s)</span>
                            {state.selectedFilters && state.selectedFilters.provider ?
                                <React.Fragment>
                                <span className='ml-1'>obtained from the</span>
                                <span className='ml-1 font-bold text-primaryPurple-600'>{state.selectedFilters.provider.toUpperCase()}</span>
                                <span className='ml-1'>provider</span>
                                </React.Fragment>
                            : null}
                            {state.selectedFilters && state.selectedFilters.account_id && state.selectedFilters.account_id.length ?
                                <React.Fragment>
                                <span className='ml-1'>with the account</span>
                                <span className='ml-1 font-bold text-primaryPurple-600'>{state.selectedFilters.account_id[0]}</span>
                                {state.selectedFilters.account_id.length > 1 ?
                                    <span className='ml-2 text-slateBlue-600'>more</span>
                                : null}
                                </React.Fragment>
                            : null}
                            {state.selectedFilters && state.selectedFilters.region && state.selectedFilters.region.length ?
                                <React.Fragment>
                                <span className='ml-1'>in the</span>
                                <span className='ml-1 font-bold text-primaryPurple-600'>{state.selectedFilters.region[0]}</span>
                                {state.selectedFilters.region.length > 1 ?
                                    <span className='ml-2  text-slateBlue-600'>more</span>
                                : null}
                                <span className='ml-1'>region</span>
                                </React.Fragment>
                            : null}
                        </p>
                        <div className='justify-end lg:w-1/2 w-full'>
                            <div className='flex justify-end self-center'>
                                {state.listNewReqeusts && state.listNewReqeusts.length ? 
                                    <div className='self-center w-100 ml-4'>
                                        <Search
                                            data={state.listNewReqeusts ? state.listNewReqeusts : []}
                                            topClassName={'border border-NeutralGray-600 rounded-full bg-white p-2.5 pl-3 pr-4'}
                                            className={'rounded-full'}
                                            filteredData={(filterNewReqeust) => {
                                                let totalPages = 1                
                                                if(filterNewReqeust.length > state.perPage) {
                                                    totalPages = Math.ceil(filterNewReqeust.length / state.perPage)
                                                }
                                                setState(prevState => ({ ...prevState, filterNewReqeust, totalPages, currentPage: 1, startRecord: 0 }))
                                            }}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                    {state.totalRecords > state.perPage ?
                        <div className='flex justify-end text-black w-full'>
                            <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                            <div className="flex text-sm">
                                <button><Icon icon="mynaui:arrow-left-square" width="20" height="20" className={`ml-2 ${state.currentPage === 1 ? 'pointer-events-none text-NeutralGray-600' : 'cursor-pointer text-DeepPurpleTints-600' }`} onClick={() => navigatePage('previous', state.currentPage)} /></button>
                                <button><Icon icon="mynaui:arrow-right-square" width="20" height="20" className={`ml-3 ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ?'pointer-events-none text-NeutralGray-600' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                            </div>
                        </div>
                    : null}
                    
                    <div className='flex flex-wrap items-stretch'>
                    {state.filterNewReqeust && state.filterNewReqeust.length ?
                        state.filterNewReqeust.slice(state.startRecord, state.startRecord + state.perPage).map((row, index) => {
                            return(
                                <React.Fragment>
                                {row.request_type === 'workstation'?
                                    <div className={`flex flex-col w-full lg:w-1/3 md:w-1/2 mb-4 cursor-pointer ${(index + 1) % 3 === 2 ? "px-3": ""}`} key={'row_'+index}>
                                        <div className="w-full bg-PrimaryTintsPurple-600 border border-platinum-600 rounded-2xl  h-full" onClick={(e) => setState(prevState => ({ ...prevState, selectedDetails: row }))}>
                                            <div className={`w-full flex pl-5 pr-3 py-2.5 justify-between bg-NeutralGray-300 rounded-t-2xl`}>
                                                <p className={`py-1 text-center text-base font-semibold ${row.category === 'bronze' ? 'text-white' : 'text-black'}`}>{row.catalog_name ? capitalizeFirstLetter(row.catalog_name) : 'Catalog name'}</p>
                                                <p className='text-DeepPurpleTints-600 border border-DeepPurpleTints-600 rounded-full py-1.5 px-3' 
                                                    onClick={(e) => {
                                                        setState(prevState => ({ ...prevState, selectedDetails: row }))
                                                    }}
                                                >Details</p>
                                            </div>
                                            <div className='flex py-2 px-3 justify-between mb-1' >
                                                <div className='self-center w-1/12'>
                                                    {row.platform === 'windows' ?
                                                        <img src={windows} className='img-fluid mb-3' alt='WINDOWS' />
                                                    :
                                                        <img src={linux} className='img-fluid mb-3' alt='LINUX'/>
                                                    }
                                                    {row.provider === 'aws' || row.provider === 'AWS' ?
                                                        <img src={aws} className='img-fluid mb-3' alt='AWS' />
                                                    :
                                                        <img src={gcp} className='img-fluid mb-3' alt='GCP' />
                                                    }
                                                    {/* <img src={ubuntu} className='img-fluid' alt='UBUNTU' /> */}
                                                </div>
                                                <div className='pl-[27px] w-11/12 flex flex-wrap justify-between'>
                                                    <div className='w-8/12 break-all'>
                                                        <p className='mb-2 text-lightGray'>
                                                            <span className='text-NeutralGray-600 pr-2'>Name:</span> 
                                                            <span className='text-black'>{row.image_name}</span>
                                                        </p>
                                                        <p className='mb-2 text-lightGray'>
                                                            <span className='text-NeutralGray-600 pr-2'>Os:</span> 
                                                            <span className='text-black'>{row.platform}</span>
                                                        </p>
                                                        <p className='mb-2 text-lightGray'>
                                                            <span className='text-NeutralGray-600 pr-2'>Resources:</span>
                                                            <span className='text-black'>
                                                                {row.provider ? row.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                {row.account_id ? (' : ')+row.account_id : <span>&nbsp;</span>}
                                                                {row.region ?  (' : ')+row.region : <span>&nbsp;</span>}
                                                            </span>
                                                        </p>
                                                        <p className='mb-2 text-lightGray'>
                                                            <span className='text-NeutralGray-600 pr-2'>Description:</span> 
                                                            <span className='text-black'>{row.description}</span>
                                                        </p>

                                                        <p className='mb-2 hidden'>
                                                            <span className='mr-1'>Capacity</span>
                                                            <span className='text-black'>{row.instance_type} </span>
                                                            <span className='text-black ml-2'>(
                                                                {row.vcpus} vCPU,
                                                                {row.memory} GB memory 
                                                            )</span>
                                                        </p>
                                                    </div>
                                                    <div className='self-center'>
                                                        {!state['hideRejected_'+row.workstation_id] && !state['hideApproved_'+row.workstation_id] ?
                                                            <p className='text-PrimaryLightOrange-600 border border-PrimaryLightOrange-600 rounded-full py-1.5 px-3 flex justify-center mt-3' 
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    if(!state['loadingRejected_'+row.workstation_id] && !state['loadingApproved_'+row.workstation_id]) {
                                                                        setState(prevState => ({ ...prevState, selectedCatalog: row, selectedStatus: 'rejected', ['loadingRejected_'+row.workstation_id]: true }))
                                                                    }
                                                                }}
                                                            >
                                                                {state['loadingRejected_'+row.workstation_id] ?
                                                                    <span className='flex'>
                                                                        {'Rejecting'}
                                                                        <LoadingCircle />
                                                                    </span>
                                                                : 'Reject'}

                                                            </p>
                                                        : null}
                                                        {row.approve_launch ?
                                                            <p className='text-PrimaryLightOrange-600 border border-PrimaryLightOrange-600 rounded-full py-1.5 px-3 flex justify-center mt-3' 
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    if(!state['loadingRejected_'+row.workstation_id] && !state['loadingApproved_'+row.workstation_id]) {
                                                                        setState(prevState => ({ ...prevState, selectedCatalog: row, selectedStatus: 'launch', ['loadingApproved_'+row.workstation_id]: true }))
                                                                    }
                                                                }}
                                                            >
                                                                {state['loadingApproved_'+row.workstation_id] ? 
                                                                    <span className='flex'>
                                                                        <LoadingCircle />
                                                                        {'Approving'}
                                                                    </span>
                                                                : 
                                                                    <span className='flex'>
                                                                        {'Approve & Lanuch'}
                                                                    </span>
                                                                }

                                                            </p>
                                                        : !state['hideRejected_'+row.workstation_id] && !state['hideApproved_'+row.workstation_id] ?
                                                            <p className='text-PrimaryLightOrange-600 border border-PrimaryLightOrange-600 rounded-full py-1.5 px-3 flex justify-center mt-3' 
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    if(!state['loadingRejected_'+row.workstation_id] && !state['loadingApproved_'+row.workstation_id]) {
                                                                        setState(prevState => ({ ...prevState, selectedCatalog: row, selectedStatus: 'approved', ['loadingApproved_'+row.workstation_id]: true }))
                                                                    }
                                                                }}
                                                            >
                                                                {state['loadingApproved_'+row.workstation_id] ? 
                                                                    <span className='flex'>
                                                                        <LoadingCircle />
                                                                        {'Approving'}
                                                                    </span>
                                                                : 
                                                                    <span className='flex'>
                                                                        {'Approve'}
                                                                    </span>
                                                                }

                                                            </p>
                                                        : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : row.request_type === 'storage'?
                                    <div className={`flex flex-col w-full lg:w-1/3 md:w-1/2 mb-4 cursor-pointer ${(index + 1) % 3 === 2 ? "px-3": ""}`} key={'row_'+index}>
                                        <div className="w-full bg-PrimaryTintsPurple-600 border border-platinum-600 rounded-2xl h-full" onClick={(e) => setState(prevState => ({ ...prevState, selectedStorageDetails: row }))}>
                                            <div className={`w-full flex pl-5 pr-3 py-2.5 justify-between bg-NeutralGray-300 rounded-t-2xl`}>
                                                <p className={`py-1 text-center text-base font-semibold `}>Storage - {row.new_storage}&nbsp;{row.storage_unit}</p>
                                                <p className='text-DeepPurpleTints-600 border border-DeepPurpleTints-600 rounded-full py-1.5 px-3' 
                                                    onClick={(e) => {
                                                        setState(prevState => ({ ...prevState, selectedStorageDetails: row }))
                                                    }}
                                                >Details</p>
                                            </div>
                                            <div className='flex py-2 px-3 justify-between mb-1' >
                                                <div className='self-center w-1/12'>
                                                    {row.provider === 'aws' || row.provider === 'AWS' ?
                                                        <img src={aws} className='img-fluid mb-3' alt='AWS' />
                                                    :
                                                        <img src={gcp} className='img-fluid mb-3' alt='GCP' />
                                                    }
                                                    {/* <img src={ubuntu} className='img-fluid' alt='UBUNTU' /> */}
                                                </div>
                                                <div className='pl-[27px] w-11/12 flex flex-wrap justify-between'>
                                                    <div className='w-8/12 break-all'>
                                                        <p className='mb-2'>
                                                            <span className='text-NeutralGray-600 pr-2'>Resources:</span>
                                                            <span className='text-black'>
                                                                {row.provider ? row.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                {row.account_id ? (' : ')+row.account_id : <span>&nbsp;</span>}
                                                                {row.region ?  (' : ')+row.region : <span>&nbsp;</span>}
                                                                {row.cluster_name ?  (' : ')+row.cluster_name : <span>&nbsp;</span>}
                                                            </span>
                                                        </p>
                                                        <p className='mb-2'>
                                                            <span className='text-NeutralGray-600 pr-2'>Requested Storage Size</span> 
                                                            <span className='text-black'>{row.new_storage}&nbsp;{row.storage_unit}</span>
                                                        </p>
                                                        <p className='mb-2'>
                                                            <span className='text-NeutralGray-600 pr-2'>Requested By</span>
                                                            <span className='text-black'>{row.user_name}</span>
                                                        </p>
                                                        <p className='mb-2'>
                                                            <span className='text-NeutralGray-600 pr-2'>Requested At</span>
                                                            <span className='text-black'>{row.created_at}</span>
                                                        </p>
                                                    </div>
                                                    <div className='self-center'>
                                                    {!state['hideRejected_'+row.storage_request_id] && !state['hideApproved_'+row.storage_request_id] ?
                                                        <p className='text-PrimaryLightOrange-600 border border-PrimaryLightOrange-600 rounded-full py-1.5 px-3 flex justify-center mt-3' 
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                if(!state['loadingRejected_'+row.storage_request_id] && !state['loadingApproved_'+row.storage_request_id]) {
                                                                    setState(prevState => ({ ...prevState, selectedCatalog: row, selectedStatus: 'rejected', ['loadingRejected_'+row.storage_request_id]: true }))
                                                                }
                                                            }}
                                                        >
                                                            {state['loadingRejected_'+row.storage_request_id] ?
                                                                <span className='flex'>
                                                                    {'Deleting'}
                                                                    <LoadingCircle />
                                                                </span>
                                                            : 'Reject'}

                                                        </p>
                                                    : null}
                                                    {!state['hideRejected_'+row.storage_request_id] && !state['hideApproved_'+row.storage_request_id] ?
                                                        <p className='text-PrimaryLightOrange-600 border border-PrimaryLightOrange-600 rounded-full py-1.5 px-3 flex justify-center mt-3' 
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                if(!state['loadingRejected_'+row.storage_request_id] && !state['loadingApproved_'+row.storage_request_id]) {
                                                                    setState(prevState => ({ ...prevState, selectedCatalog: row, selectedStatus: 'approved', ['loadingApproved_'+row.storage_request_id]: true }))
                                                                }
                                                            }}
                                                        >
                                                        {state['loadingApproved_'+row.storage_request_id] ? 
                                                                <span className='flex'>
                                                                    <LoadingCircle />
                                                                    {'Approving'}
                                                                </span>
                                                            : 
                                                                <span className='flex'>
                                                                    {'Approve'}
                                                                </span>
                                                            }

                                                        </p>
                                                    : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : null}
                                </React.Fragment>
                            )
                        })
                    :  <div className='w-full flex justify-center m-4 text-white'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div> 
                    }
                    </div>
                </div>
                </React.Fragment>
            ) :
                <div className='flex justify-center m-4 text-sm self-center lg:w-1/2 w-full'>
                    There are no data on this criteria. Please try adjusting your filter.
                </div>
            }
            </div>
        </React.Fragment>
    );
};

export default NewRequest