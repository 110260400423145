import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listClusterFilters, listUsers, setCommonPropsDetails } from '../../../actions/commonActionNew'
 import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import { getAccountNameFromId, getRegionName } from '../../../utils/utility';
import { hpcReportListSchedule, hpcReportCreateSchedule, hpcReportUpdateSchedule, hpcReportdeleteSchedule } from '../../../actions/Collider/HpcReportAction';
import { PERIOD_OPTIONS } from '../../../utils/constants';
import PageLoading from '../../common/PageLoading';
import SelectOption from '../../common/Form/SelectOption';

const ScheduleReport = ({ selectedDetails, closeSidePanel, pageMode }) => {
    const clickOut = useRef();
    const [state, setState] = useState({
        showLoading: false,
        callListPackages: true,
        existingRecord: false,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 100,
        durations: ['Monthly', 'Yearly'],
        selectedTab: 'Create',
        filteredListResponse1: [
            {provider: 'aws', account_id: '123', region: '', duration: 'Monthly', user_name: ['text', 'rest'], duration_aggregate_by: 'Weekly', schedule_expression: '0 1 * *'},
            {provider: 'aws', account_id: '123', region: '', duration: 'Monthly', user_name: ['text'], duration_aggregate_by: 'Weekly', schedule_expression: '0 2 * *'}
        ],
        pageMode: 'Create'
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const usersList = useSelector(state => state?.filters?.usersList)
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 5000); 
        }
    }, [state.showLoading])

    useEffect(() => {
        setState(prevState => ({ ...prevState, accountLoader: true }));
        let params = {};
        let label = ''
        params.aggregate_by = 'account_id';
        params.provider = state.selectedProvider && state.selectedProvider.toLowerCase();
        label = state.selectedProvider

        if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
            setState(prevState => ({ ...prevState, accounts: propProjAccounts[label], accountLoader: false }));
        } else {
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        if(response.length) {
                            let obj = propProjAccounts ? propProjAccounts : {}
                            obj[label] = response
                            dispatch(setCommonPropsDetails('propProjAccounts', obj))
                        }
                        setState(prevState => ({ ...prevState, accounts: response, accountLoader: false }));
                    }
                })
        }
    }, [state.selectedProvider, dispatch, propProjAccounts]);

    useEffect(() => {
        setState(prevState => ({ ...prevState, regionLoader: true }));
        let params = {};
        let label = ''
        params.aggregate_by = 'region';
        params.provider = state.selectedProvider && state.selectedProvider.toLowerCase();
        label = state.selectedProvider
        if(state.selectedAccount) {
            params.account_id = state.selectedAccount
        }
        
        if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
            setState(prevState => ({ ...prevState, regions: propProjRegions[label], regionLoader: false }));
        } else {
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response && response.length) {
                        let obj = propProjRegions ? propProjRegions : {}
                        obj[state.selectedProvider] = response
                        dispatch(setCommonPropsDetails('propProjRegions', obj))
                    }
                    setState(prevState => ({ ...prevState, regions: response, regionLoader: false }))
                })
        }
    }, [state.selectedProvider, state.selectedAccount, dispatch, propProjRegions]);

    useEffect(() => {
        let params = {};
        if(!usersList || !usersList.length) {
            dispatch(listUsers(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, usersList: response, userLoader: false }));
                    }
                })
        } else {
            setState(prevState => ({ ...prevState, usersList: usersList }));
        }
    }, [dispatch, usersList])

    useEffect(() => {
        let params = {}
        params.report_id = selectedDetails && selectedDetails.report_id

        setState(prevState => ({ ...prevState, refreshList: false }))
        
        dispatch(hpcReportListSchedule(params))
            .then((response) => {
                if(response) {
                    let totalResults = response && response.results ? response.results : []

                    setState(prevState => ({ ...prevState, listReponse: totalResults, filteredListResponse: totalResults, showLoading: false }));
                }
            })
    }, [selectedDetails, dispatch, state.refreshList]);
	
	// const handleInputChange = (label, value) => {
    //     if(label === 'packages') {
    //         let selectedPackages = state.selectedPackages ? state.selectedPackages : []

    //         // if(selectedPackages.filter(e => e === value).length) {
    //         //     selectedPackages = selectedPackages.filter(e => e !== value)
    //         // } else {
    //         //     selectedPackages.push(value)
    //         // }

    //         // label = 'selectedPackages'
    //         // value = selectedPackages

    //         selectedPackages.forEach(pac => {
    //             setState(prevState => ({ ...prevState, [pac]: true }))
    //         })
    //     }
    //     setState(prevState => ({ ...prevState, [label]: value }))
	// }

    const handleSchedule = (value) => {
        // Remove non-digit characters
        const cleaned = value.replace(/\D/g, '');

        // Format to DD/MM/YYYY
        const match = cleaned.match(/^(\d{0,2})(\d{0,2})$/);

        if (match) {
        const formattedDate = [match[1], match[2], match[3]]
            .filter(Boolean) // Remove empty strings
            .join(':'); // Join parts with "/"
        return formattedDate;
        }

        return value;
    };

    const handleChange = (e, label) => {
      const inputValue = e.target.value;
      const formattedValue = handleSchedule(inputValue);
      setState(prevState => ({ ...prevState, [label]: formattedValue }))
    };

    useEffect(() => {
        if(state.saveFunction) {
            setState(prevState => ({ ...prevState, saveFunction: false }))
            let hasError = false
            let params = {}
            params.report_id = selectedDetails && selectedDetails.report_id
            params.provider = state.selectedProvider
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            } else {
                hasError = true
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
            if(state.selectedDuration) {
                params.duration = state.selectedDuration
            }
            if(state.selectedUsers && state.selectedUsers.length) {
                params.user_name = state.selectedUsers
            }
            if(state.duration_aggregate_by) {
                params.duration_aggregate_by = state.duration_aggregate_by
            }

            let schedule_expression = ''
            if(!state.minutes || !state.hours || !state.day_of_month || !state.month || !state.day_of_week) {
                hasError = true
            } else {
                schedule_expression = state.minutes +' '+ state.hours +' '+ state.day_of_month +' '+ state.month +' '+ state.day_of_week
            }

            params.schedule_expression = schedule_expression
    
            setState(prevState => ({ ...prevState, schedule_expression, hasError, saveLoading: hasError ? false : true }))
            
            if(!hasError) {
                if(state.pageMode === 'Create') {
                    dispatch(hpcReportCreateSchedule(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response && response.message ? response.message : 'Error in scheduling'
                            if(response && !response.error) {
                                if(response.status) {
                                    messageType = 'success'
                                    message = response.message ? response.message : 'Scheduling successfully'
                                }
                            }
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: false,
                                    pauseOnHover: true,
                                    showIcon: true,
                                }
                            });
            
                            if(response && !response.error) {
                                if(response.status) {
                                    setTimeout(() => closeSidePanel("refresh"), 1000)
                                } else {
                                    setState(prevState => ({ ...prevState, saveLoading: false }))
                                }
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }
                        })
                } else {
                    dispatch(hpcReportUpdateSchedule(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response && response.message ? response.message : 'Error in updating scheduling'
                            if(response && !response.error) {
                                if(response.status) {
                                    messageType = 'success'
                                    message = response.message ? response.message : 'Scheduling updated successfully'
                                }
                            }
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: false,
                                    pauseOnHover: true,
                                    showIcon: true,
                                }
                            });
            
                            if(response && !response.error) {
                                if(response.status) {
                                    setTimeout(() => closeSidePanel("refresh"), 1000)
                                } else {
                                    setState(prevState => ({ ...prevState, saveLoading: false }))
                                }
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }
                        })
                }
    
            }
        }
    },  [state.saveFunction, closeSidePanel, dispatch, selectedDetails, pageMode, state])

	const onClickDelete = (deleteDetails) => {
		let params = {}
		params.schedule_id = deleteDetails.schedule_id
		dispatch(hpcReportdeleteSchedule(params))
			.then((response) => {
				if(response) {
					let messageType = 'danger'
					let message = response.message ? response.message : 'Error in deleting'
					if(response && response.status) {
						messageType = 'success'
						message = response.message ? response.message : 'Schedule deleted successfully'

						setTimeout(() => { setState(prevState => ({ ...prevState, refreshList: true, deleteDetails: {}, deleteModal: false })) }, 2000)
					}

					setTimeout(() => { setState(prevState => ({ ...prevState, showDeleteLoading: false })) }, 2000)
	
					// setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
	
					CommonNotification.addNotification({
						//title: 'Wonderful!',
						message: message,
						type: messageType,
						insert: 'top',
						container: 'top-center',
						// animationIn: ['animate__animated', 'animate__fadeIn'],
						// animationOut: ['animate__animated', 'animate__fadeOut'],
						dismiss: {
						duration: 5000,
						onScreen: false,
						pauseOnHover: true,
						showIcon: true,
						}
					});
				}
			})
	}

    const onClickEditRule = (item) => {
        let selectedProvider = item.provider
        let selectedAccount = item.account_id
        let selectedRegion = item.region
        let selectedDuration = item.duration
        let duration_aggregate_by = item.duration_aggregate_by
        let selectedUsers = item.user_name
        if(item.schedule_expression) {
            let split = item.schedule_expression.split(' ')
            setState(prevState => ({ ...prevState, 
                minutes: split[0],
                hours: split[1],
                day_of_month: split[2],
                month: split[3],
                day_of_week: split[4],
                // year: split[5]
            }))
        }
        setState(prevState => ({ ...prevState, selectedProvider, selectedAccount, selectedRegion, selectedDuration, duration_aggregate_by, selectedUsers, pageMode: 'Edit', selectedTab: 'Create' }))
    }

	const handleChildClick = (event, type, dropdownType, section) => {
        // if (clickOut.current && !clickOut.current.contains(event.target)) {
        //     closeSidePanel()
        // }

		event.stopPropagation();
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-lavender-600 lg:w-3/5 w-10/12' ref={clickOut}>
                    <div className='bg-NeutralGray-200 px-8 py-10 text-black'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl '>Schedule Report</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                    <div className={`mx-4 pt-4`}>
                        <ul className="flex flex-wrap text-sm text-center">
                            <li className="me-2">
                                <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Create' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} 
                                    onClick={() => setState(prevState => ({ ...prevState, selectedProvider: '', selectedAccount: '', selectedRegion: '', selectedDuration: '', duration_aggregate_by: '', selectedUsers: '', minutes: '', hours: '', day_of_month: '', month: '', day_of_week: '', selectedTab: 'Create', pageMode: 'Create', showcreateSchedukeLoading: true }))}
                                >
                                Create
                                </span>
                            </li>
                            <li className="me-2">
                                <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Schedules' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Schedules' }))}>Schedules</span>
                            </li>
                        </ul>
                        <div className="overflow-y-auto h-[calc(100vh-200px)] border border-NeutralGray-500 bg-PrimaryTintsPurple-600 bg-white rounded-r-2xl rounded-b-2xl">
                            {state.selectedTab === 'Create' ?
                                <div className="p-6">
                                    <div className='flex flex-wrap'>
                                        <div className='md:w-1/2 w-full mt-2 pr-2'>
                                            <SelectOption
                                                label={"Provider"}
                                                options={selectedDetails && selectedDetails.provider}
                                                selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, selectedProvider: value, selectedRegion: '', selectedAccount: '' }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}
                                                singleSelection={true}
                                                manditory={false}
                                                dropdownWidth={'min-w-32'}
                                                inputType={state.pageType}
                                            />
                                        </div>
                                        <div className='md:w-1/2 w-full mt-2 pr-2'>
                                            <SelectOption
                                                label={"Account"}
                                                fields={['account_id', 'account_name']}
                                                options={state.accounts ? state.accounts  : []}
                                                selectedValues={state.selectedAccount ? state.selectedAccount : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, selectedAccount: value }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}  
                                                singleSelection={true}
                                                manditory={false}
                                                dropdownWidth={'min-w-32'}
                                                inputType={state.pageType}
                                            />
                                        </div>
                                        <div className='md:w-1/2 w-full mt-2 pr-2'>
                                            <SelectOption
                                                label={"Region"}
                                                fields={['region', 'name']}
                                                options={state.regions ? state.regions  : []}
                                                selectedValues={state.selectedRegion ? state.selectedRegion : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, selectedRegion: value }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}    
                                                singleSelection={true}
                                                manditory={false}
                                                dropdownWidth={'min-w-32'}
                                                inputType={state.pageType}
                                            />
                                        </div>
                                        <div className='md:w-1/2 w-full mt-2 pr-2'>
                                            <SelectOption
                                                label={"Duration"}
                                                options={state.durations}
                                                selectedValues={state.selectedDuration ? state.selectedDuration : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, selectedDuration: value }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}
                                                singleSelection={true}
                                                manditory={false}
                                                dropdownWidth={'min-w-32'}
                                                inputType={state.pageType}
                                            />
                                        </div>
                                        <div className='w-full mt-2'>
                                            <div className='py-1 md:w-1/2 w-full'>
                                                <SelectOption
                                                    label={"User"}
                                                    options={state.usersList}
                                                    selectedValues={state.selectedUsers ? state.selectedUsers : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, selectedUsers: value }))
                                                        // handleInputChange('requested_user_name', value)
                                                    }}
                                                    singleSelection={false}
                                                    manditory={false}
                                                    dropdownWidth={'min-w-32'}
                                                    inputType={state.pageType}
                                                />
                                            </div>
                                            <div className='flex flex-wrap'>
                                                {state.selectedUsers && Array.isArray(state.selectedUsers) && state.selectedUsers.length ? 
                                                    state.selectedUsers.map(row => {
                                                        return(
                                                            <span key={row} className='flex border border-primaryPurple-600 rounded-full px-2 min-w-28 text-center self-end text-primaryPurple-600 mr-2 py-1 break-all'>{row}
                                                                <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => {
                                                                    setState(prevState => ({ ...prevState, selectedUsers: state.selectedUsers.filter(e => e !== row)}))
                                                                }}/>
                                                            </span>
                                                        )
                                                    })
                                                : null}
                                            </div>
                                        </div>
                                        <div className='md:w-1/3 w-full mt-2 pr-2'>
                                            <SelectOption
                                                label={"Frequency"}
                                                fields={['value', 'label']}
                                                options={PERIOD_OPTIONS}
                                                selectedValues={state.duration_aggregate_by ? state.duration_aggregate_by : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, duration_aggregate_by: value }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}
                                                singleSelection={true}
                                                manditory={false}
                                                dropdownWidth={'min-w-32'}
                                                inputType={state.pageType}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap mt-5'>
                                        <label className='w-full self-center mb-2 text-black font-semibold text-base'>Schedule</label>
                                        <p className='self-start mb-0 text-black pr-2 font-bold'>Cron (</p>
                                        {state.hasError && !state.schedule_expression ?
                                            <span className='text-danger self-center'>required all cron inputs</span>
                                        : null}
                                        <div className='md:w-1/12 w-full mr-2'>
                                            <input
                                                type='text'
                                                placeholder='0-59'
                                                maxLength={5}
                                                className={`text-base block border-b border-NeutralGray-400 py-1 pl-2 w-full`}
                                                value={state.minutes ? state.minutes : ''}
                                                onChange={(e) => {
                                                    // handleChange(e, "minutes") // Use onChange instead of onKeyUp
                                                    setState(prevState => ({ ...prevState, minutes: e.target.value, nextSchedules: [] }))
                                                }}
                                            />
                                            <div className='flex'>
                                                <p className="b-block mb-0 text-lightGray mr-4">Minutes</p>
                                            </div>
                                        </div>
                                        <div className='md:w-1/12 w-full mr-2'>
                                            <input
                                                type='text'
                                                placeholder='0-23'
                                                maxLength={5}
                                                className={`text-base block border-b border-NeutralGray-400 py-1 pl-2 w-full`}
                                                value={state.hours ? state.hours : ''}
                                                onChange={(e) => {
                                                    // handleChange(e, "hours") // Use onChange instead of onKeyUp
                                                    setState(prevState => ({ ...prevState, hours: e.target.value, nextSchedules: [] }))
                                                }}
                                            />
                                            <div className='flex'>
                                                <p className="b-block mb-0 text-lightGray mr-4">hours</p>
                                            </div>
                                        </div>
                                        <div className='md:w-1/12 w-full mr-2'>
                                            <input
                                                type='text'
                                                placeholder='1-31'
                                                maxLength={5}
                                                className={`text-base block border-b border-NeutralGray-400 py-1 pl-2 w-full`}
                                                value={state.day_of_month ? state.day_of_month : ''}
                                                onChange={(e) => {
                                                    // handleChange(e, "day_of_month") // Use onChange instead of onKeyUp
                                                    setState(prevState => ({ ...prevState, day_of_month: e.target.value, nextSchedules: [] }))
                                                }}
                                            />
                                            <div className='flex'>
                                                <p className="b-block mb-0 text-lightGray mr-4">Day of month</p>
                                            </div>
                                        </div>
                                        <div className='md:w-1/12 w-full mr-2'>
                                            <input
                                                type='text'
                                                placeholder='1-12(or JAN to DEC)'
                                                maxLength={5}
                                                className={`text-base block border-b border-NeutralGray-400 py-1 pl-2 w-full`}
                                                value={state.month ? state.month : ''}
                                                onChange={(e) => {
                                                    handleChange(e, "month") // Use onChange instead of onKeyUp
                                                    setState(prevState => ({ ...prevState, month: e.target.value, nextSchedules: [] }))
                                                }}
                                            />
                                            <div className='flex'>
                                                <p className="b-block mb-0 text-lightGray mr-4">Month</p>
                                            </div>
                                        </div>
                                        <div className='md:w-1/12 w-full mr-2'>
                                            <input
                                                type='text'
                                                placeholder='0-6 (or SUN to SAT;or 7 for sunday)'
                                                maxLength={5}
                                                className={`text-base block border-b border-NeutralGray-400 py-1 pl-2 w-full`}
                                                value={state.day_of_week ? state.day_of_week : ''}
                                                onChange={(e) => {
                                                    handleChange(e, "day_of_week") // Use onChange instead of onKeyUp
                                                    setState(prevState => ({ ...prevState, day_of_week: e.target.value, nextSchedules: [] }))
                                                }}
                                            />
                                            <div className='flex'>
                                                <p className="b-block mb-0 text-lightGray mr-4">Day of week</p>
                                            </div>
                                        </div>
                                        {/* <div className='md:w-1/12 w-full mr-2'>
                                            <input
                                                type='text'
                                                placeholder='0'
                                                maxLength={5}
                                                className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                                value={state.year ? state.year : ''}
                                                onChange={(e) => handleChange(e, "year")} // Use onChange instead of onKeyUp
                                            />
                                            <div className='flex'>
                                                <p className="b-block mb-0 text-lightGray mr-4">Year</p>
                                            </div>
                                        </div> */}
                                        <p className='self-start mb-0 text-black font-bold'>)</p>
                                        <span className='text-lightGray ml-2'>(UTC time)</span>
                                    </div>

                                    <div className='self-center mt-5 md:w-1/2 w-full'>
                                        {state.nextSchedules && state.nextSchedules.length ?
                                            <React.Fragment>
                                            <p className='text-black mb-5'>Next 10 trigger date(s) in UTC time</p>
                                            {state.nextSchedules.map((rec, recIndex) => {
                                                return(
                                                    <p key={"rec_"+recIndex} className='w-full'>{rec}</p>
                                                )
                                            })}
                                            </React.Fragment>
                                        : null}
                                    </div>

                                    <div className='my-3 flex justify-end mx-2'>
                                        {state.saveLoading ?
                                            <button type="button" className="bg-info text-white ml-2 px-2 py-1 rounded-md inline-flex items-center self-center">
                                                <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                    <path d="M4 12a8 8 0 0112-6.9" />
                                                </svg>
                                                {state.pageMode === 'Create' ? 'Saving' : 'Updating'}
                                            </button>
                                        :
                                            <div className='flex'>
                                                <span className="flex bg-green-600 py-2.5 pr-4 pl-3 rounded-md mr-2 text-white font-bold text-base" onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, saveFunction: true }))}
                                                >{state.pageMode === 'Create' ? 'Save' : 'Update'}</span>

                                                <span className="flex bg-ferrariRed-600 py-2.5 pr-4 pl-3 rounded-md mr-2 text-white font-bold text-base" onClick={ () => closeSidePanel()}>Cancel</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            : 
                                <div className="overflow-x-scroll p-6">
                                    <div className='relative overflow-x-auto mt-3 rounded-t-lg shadow border border-lightGray-600'>
                                        <table className="w-full text-left">
                                            <thead className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600 rounded-t-2xl text-base font-semibold text-NeutralGray-800'>
                                                <tr className="flex-1 w-full">
                                                    <th className={`tracking-wider py-2 pl-4 text-left`}>Resources</th>
                                                    <th className={`tracking-wider py-2 pl-4 text-left`}>Duration</th>
                                                    <th className={`tracking-wider py-2 pl-4 text-left`}>Users</th>
                                                    <th className={`tracking-wider py-2 pl-4 text-left`}>Frequency</th>
                                                    <th className={`tracking-wider py-2 pl-4 text-left`}>Resources</th>
                                                    <th className={`tracking-wider py-2 pl-4 text-left`}>Cron Details</th>
                                                    <th className={`tracking-wider py-2 pl-4 text-left`}>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody className='text-sm text-NeutralGray-900'>
                                                {state.filteredListResponse && state.filteredListResponse.length ?
                                                    state.filteredListResponse.map((col, colIndex) => {
                                                        return(
                                                            <tr key={"head1_"+colIndex} className={`${colIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'} w-full`}>
                                                                <td className={`text-black py-2.5 pl-4 text-left text-md`}>{(col.provider ? col.provider.toUpperCase() : '') + ': ' + getAccountNameFromId(col.account_id, state.accounts) +' '+ (col.region ? (': ' +(col.region === 'NA' ? 'Global' : getRegionName(col.region, state.regions))) : '')}</td>
                                                                
                                                                <td className={`text-black py-2.5 pl-4 text-left text-md`}>{col.duration}</td>
                                                                <td className={`text-black py-2.5 pl-4 text-left text-md`}>{col.user_name ? col.user_name.join(', ') : ''}</td>
                                                                <td className={`text-black py-2.5 pl-4 text-left text-md`}>{col.duration_aggregate_by}</td>
                                                                <td className={`text-black py-2.5 pl-4 text-left text-md`}>{col.duration}</td>
                                                                <td>
                                                                    <div className='flex'>
                                                                        <Icon icon="octicon:pencil-24" width={24} height={24} className="mr-1 self-center cursor-pointer text-black" 
                                                                        onClick={() => onClickEditRule(col)} 
                                                                    />
                                                                        <Icon icon="mdi:trash" width={24} height={24} className="mr-1 self-center cursor-pointer text-ferrariRed-600" 
                                                                            onClick={() => setState(prevState =>({ ...prevState, deleteDetails: col, deleteModal: true }))} 
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                : 
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <p className='text-center'>No schedule found</p>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div id="popup-modal" tabIndex="-1" className={`flex ${state.deleteModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
				<div className="relative p-4 w-full max-w-md max-h-full">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<button type="button" className="absolute top-3 end-2.5 text-NeutralGray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, deleteDetails: {} }))}>
							<svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
								<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
							</svg>
							<span className="sr-only">Close modal</span>
						</button>
						<div className="p-4 md:p-5 text-center">
							<svg className="mx-auto mb-4 text-NeutralGray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
								<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
							</svg>
							<h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete the schedule ?</h3>

							<div className='flex mt-4 justify-center'>
							    <button data-modal-hide="popup-modal" type="button" className="text-white inline-flex items-center bg-green-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center" 
							    	onClick={() => {
							    		setState(prevState => ({ ...prevState, showDeleteLoading: true }))
							    		if(!state.showDeleteLoading) {
							    			onClickDelete(state.deleteDetails)
							    		}
							    	}}
							    >
							    	Yes, I'm sure
							    </button>
							    <button data-modal-hide="popup-modal" type="button" className="text-white inline-flex items-center bg-ferrariRed-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={() => {
							    	if(!state.showDeleteLoading) {
							    		setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false }))
							    	}
							    }}>
							    No, cancel
							    </button>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
    );
};

export default ScheduleReport;