/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file QOS LandingPage.js
 * @author Prakash // on 07/02/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { listQosPolicies, removeQosPolicy } from '../../../actions/Collider/QosAction'
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, capitalizeAllLetter, getRegionName, getAccountNameFromId, noSpace, } from '../../../utils/utility'

import { CSVLink } from 'react-csv';
import {Icon} from "@iconify/react";

import Search from '../../common/SearchWithHiddenInput'
import { Store as CommonNotification } from 'react-notifications-component';

import { URL_PATH } from '../../../config/urlPath';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import { qosDeleteAccount, qosListAccounts } from '../../../actions/Collider/AccountAction';
import FilterSection from '../../common/FiltersSection';
import PageLoading from '../../common/PageLoading';

const LandingPage = () => {
    const clickOutside = useRef() 
    const [state, setState] = useState({
        showLoading: true,

        itemList: [],
        filteredList: [],
        csvheaders: [],
        emptyOptions: [],
        modal: false,
        changeManage: 'QOS',
        selectedManage: 'QOS',
        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster', 'manage_qos']
    })

    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const providers = useSelector(state => state?.filters?.providers || false);

    const location = useLocation();
    const locationData = location.state || false
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
                    // else {
                    //     if(row.action) {
                    //         actionsAllowed = row.action
                    //     }
                    // }
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
		if(locationData && Object.entries(locationData).length) {            
			setState(prevState => ({ 
				...prevState,
				selectedManage: locationData.selectedManage ? locationData.selectedManage : 'QOS',
                changeManage: locationData.selectedManage ? locationData.selectedManage : 'QOS',
			}))
		}
	}, [locationData, dispatch])

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            selectedManage: filter.changeManage ? filter.changeManage : 'QOS',
            callSearch: true,
            showLoading: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: "", selectedProjectTags: [], selectedAccount: [], selectedRegion: [], selectedClusters: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== "All")
            }            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== "All")
            }            
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters.filter(e => e !== "All")
            }
            let columns = [
                {
                    Header: 'Date',
                    accessor: d => d.modified_at ? "modified_at" : 'created_at',
                    Cell: cellInfo => (
                    <span>
                        {cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : '')}
                    </span>
                    ),
                    width: 200
                },
                {
                    Header: 'Name',
                    accessor: state.selectedManage === 'QOS' ? 'qos_name' : 'account',
                    width: 250,
                },
                {
                    Header: 'Provider',
                    accessor: d => d.provider ? capitalizeAllLetter(d.provider) : '',
                    width: 150,
                },
                {
                    Header: 'Account',
                    accessor: 'account_id',
                    width: 200,
                    Cell: cellInfo => (
                        <div className="flex justify-between self-center">
                            {cellInfo.row.original && cellInfo.row.original.account_id ? getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) : ''}
                        </div>
                    ),
                },
                {
                    Header: 'Region',
                    accessor: 'region',
                    width: 200,
                    Cell: cellInfo => (
                        <div className="flex justify-between self-center">
                            {cellInfo.row.original && cellInfo.row.original.region ? getRegionName(cellInfo.row.original.region, state.regions) : ''}
                        </div>
                    ),
                },
                {
                    Header: 'Cluster',
                    accessor: 'cluster_name',
                    width: 200,
                },
                {
                    Header: 'Actions',
                    Cell: cellInfo => (
                        <div className="flex self-center">
                            {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                                state.selectedManage === 'QOS' ?
                                    <Link 
                                        to={URL_PATH.CREATE_QOS} 
                                        state={{
                                            selectedData: cellInfo.row.original,
                                            pageType: 'edit',
                                        }}
                                        className='text-primaryPurple-600'>
                                        Edit
                                    </Link>
                                : 
                                    <Link 
                                        to={URL_PATH.CREATE_GROUP} 
                                        state={{
                                            selectedData: cellInfo.row.original,
                                            pageType: 'edit',
                                        }}
                                        className='text-primaryPurple-600'>
                                        Edit
                                    </Link>
                            : null}
                            {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                                <p className='ml-3 mb-0 text-ferrariRed-600 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, deleteModal: true, selectedDeleteItem: cellInfo.row.original }))}>Delete</p>
                            : null}
                        </div>
                    ),
                    width: 200
                }
            ]

            setState(prevState => ({ ...prevState, columns }))

            if(state.selectedProvider) {
                if(state.selectedManage === 'QOS') {
                    dispatch(listQosPolicies(params))
                        .then((response) => {
                            if(response) {
                                let results = response && response.results && response.results.length ? response.results : []
                                setState(prevState => ({ ...prevState, detailsFormat: response && response.qos_details ? response.qos_details : [], itemList: results, filteredList: results, showLoading: false }));
                            }
                        })
                } else {
                    dispatch(qosListAccounts(params))
                        .then((response) => {
                            if(response) {
                                let results = response && response.results && response.results.length ? response.results : []
                                
                                setState(prevState => ({ ...prevState, detailsFormat: response && response.qos_details ? response.qos_details : [], itemList: results, filteredList: results, showLoading: false }));
                            }
                        })
                }
            } else {
                setState(prevState => ({ ...prevState, itemList: [], filteredList: [], showLoading: false }));
            }
        }
    }, [dispatch, state.accounts, state.callSearch, state.regions, state.selectedAccount, state.selectedClusters, state.selectedManage, state.selectedProjectTags, state.selectedProvider, state.selectedRegion, state.actionsAllowed])

    useEffect(() => {
        if(state.itemList && state.itemList.length) {
            let dataResult = state.itemList
            let headers = []
            Object.entries(dataResult[0]).forEach(([key, value]) => {
                if(typeof value === 'string') {
                    let headerRow = {}
                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                    headerRow.key = key
                    headers.push(headerRow)
                }
            })
    
            setState(prevState => ({ ...prevState, csvheaders: headers }))
        }
    }, [state.itemList])
	
	const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value, deleteConfirmMessage: '' }))
	}

    const removeQosPolicyFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.selectedDeleteItem 
        let params = {}
        if(item && item.asset_id) {
            params.asset_id = item.asset_id
        } else {
            params.provider = item.provider
            params.account_id = item.account_id
            params.region = item.region
            params.cluster_name = item.cluster_name
            if(state.selectedManage === 'QOS') {
                params.qos_name = item.qos_name
            } else {
                params.account = item.account
            }
        }
        if(state.selectedManage === 'QOS') {
            if(state.qos_name && state.qos_name === state.selectedDeleteItem.qos_name) {
                dispatch(removeQosPolicy(params))
                    .then((response) => {
                        if(response) {
                            let messageType = 'danger'
                            let message = response && response.message ? response.message : "Error in deleting"
        
                            if(response.status) {
                                messageType = 'success'
                                setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, deleteModal: !state.deleteModal, showDeleteLoading: false }))}, 1000)
                            } else {
                                setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                            }
        
        
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: false,
                                    pauseOnHover: true,
                                    showIcon: true,
                                }
                            });
                        } else {
                            setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                        }
                    })
            } else {
                if(!state.name || state.name === '') {
                    setState(prevState => ({ ...prevState, deleteConfirmMessage: "Please enter qos name", showDeleteLoading: false }))
                } else if(state.name !== item.qos_name) {
                    setState(prevState => ({ ...prevState, deleteConfirmMessage: "Entered qos name does not match selected qos name", showDeleteLoading: false }))
                }
            }            
        } else {
            if(state.name && state.name === state.selectedDeleteItem.account) {
                dispatch(qosDeleteAccount(params))
                    .then((response) => {
                        if(response) {
                            let messageType = 'danger'
                            let message = response && response.message ? response.message : "Error in deleting"
        
                            if(response.status) {
                                messageType = 'success'
                                setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, deleteModal: !state.deleteModal, showDeleteLoading: false }))}, 1000)
                            } else {
                                setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                            }
        
        
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: false,
                                    pauseOnHover: true,
                                    showIcon: true,
                                }
                            });
                        } else {
                            setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                        }
                    })
            } else {
                if(!state.name || state.name === '') {
                    setState(prevState => ({ ...prevState, deleteConfirmMessage: "Please enter account", showDeleteLoading: false }))
                } else if(state.name !== item.account) {
                    setState(prevState => ({ ...prevState, deleteConfirmMessage: "Entered account does not match selected account", showDeleteLoading: false }))
                }
            }  
        }

    }

    useEffect(() => {
        if(state.callDelete && state.selectedDeleteItem && Object.entries(state.selectedDeleteItem).length) {
            setState(prevState => ({ ...prevState, modal: !state.modal, callDelete: false }))
        }
    }, [state.callDelete, state.selectedDeleteItem, state.modal])



	const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const navigateTo = () => {
        dispatch(setHpcPropsDetails('qosCreateEditInputs', {}))
        let path = URL_PATH.CREATE_GROUP
        if(state.selectedManage === 'QOS') {
            path = URL_PATH.CREATE_QOS
        }
        navigate(path, { state: { pageType: 'Create' } });
    }

    return (
        state.actionsAllowed ?
        <div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="pt-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl py-7 px-6">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">QOS and Group</p>
                        <p className="text-white text-base">Consolidated view of qos and account association</p>
                    </div>
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            topFilterOptions= {state.topFilterOptions}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            screen="QOS"
                            changeManage={state.changeManage}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='min-h-screen overflow-auto'>
                <div className='flex flex-wrap justify-between py-[35px]'>
                    <div className='lg:w-1/2 w-full self-center'>
                        <p className='justify-start self-center text-sm'>Showing {state.filteredList && state.filteredList.length} of total {state.itemList && state.itemList.length} qos</p>
                    </div>
                    <div className='self-center'>
                        <div className='flex justify-end'>                                
                            {state.itemList && state.itemList.length ?
                                <div className='self-center mr-2'>
                                    <Search
                                        data={state.itemList ? state.itemList : []}
                                        topClassName={'border border-NeutralGray-900 py-2.5 pl-4 pr-3 rounded-full bg-white'}
                                        className={'rounded-full'}
                                        filteredData={(filteredList) => setState(prevState => ({ ...prevState, filteredList }))}
                                    />
                                </div>
                            : null}
                            {state.actionsAllowed.includes('create')?
                            <button type="button" className="bg-DeepPurpleTints-600 text-white text-base font-semibold mx-2 py-2.5 px-[19px] rounded-full self-center" onClick={() => navigateTo()}>Create {state.selectedManage}</button>
                            : null}
                            {state.filteredList && state.filteredList.length ?
                                <div className='self-center' title="download">
                                    <CSVLink 
                                        data={state.filteredList ? state.filteredList : []} 
                                        headers={state.csvheaders ? state.csvheaders : []}
                                        filename={'qos-lists.csv'}
                                        className={'self-center'}
                                        target='_blank'
                                    >
                                        <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center' />
                                    </CSVLink>
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
                <div className="w-full">
                    <div className="relative overflow-x-auto rounded-t-lg shadow">
                        {state.filteredList && state.filteredList.length ?
                            <ResizeableDarkThemeTable
                                columns={state.columns}
                                data={state.filteredList}
                                perPage={20}
                                selectedColor={'bg-backGroundGray'}
                                dashboard={true}
                                sortByColumn={"date"}
                                riskTooltip={[0]}
                                onClickRow={tableRow => {}}
                                // showPaginationTop={true}
                            />
                        : 
                            <div className='flex justify-center m-4'>
                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                            </div>
                        }
                    </div>
                    <div id="popup-modal" tabIndex="-1" className={`flex ${state.deleteModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                        <div className="relative p-4 w-full max-w-md max-h-full">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, deleteModal: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))}>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4 md:p-5 text-center">
                                    <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                    </svg>
                                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete the {state.selectedManage} "{state.selectedDeleteItem && state.selectedDeleteItem.queue_name ? state.selectedDeleteItem.queue_name : ''}"?</h3>

                                    <p>Please enter the
                                        <span className='f18 mr-1'>
                                            {state.selectedManage === 'QOS' ? 
                                                <React.Fragment>
                                                <span className='mx-1'>qos name</span>
                                                {state.selectedDeleteItem && state.selectedDeleteItem.qos_name ? state.selectedDeleteItem.qos_name : ''}
                                                </React.Fragment>
                                            :
                                                <React.Fragment>
                                                <span className='mx-1'>account name</span>
                                                {state.selectedDeleteItem && state.selectedDeleteItem.account ? state.selectedDeleteItem.account : ''}
                                                </React.Fragment>
                                            }
                                        </span> 
                                        to delete the {state.selectedManage}
                                    </p>
                                    <input
                                        type='text'
                                        placeholder={'Enter '+state.selectedManage+' name'}
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                        // maxlength='128'
                                        value={state.name ? state.name : ''}
                                        onChange={e => handleInputChange('name', noSpace(e.target.value))}
                                    />
                                    <div className='flex mt-4 justify-center'>
                                    <button data-modal-hide="popup-modal" type="button" className="text-white inline-flex items-center bg-green-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-8" 
                                        onClick={() => {
                                            setState(prevState => ({ ...prevState, showDeleteLoading: true }))
                                            if(!state.showDeleteLoading) {
                                                removeQosPolicyFunction()
                                            }
                                        }}
                                    >
                                        Yes, I'm sure
                                    </button>
                                    <button data-modal-hide="popup-modal" type="button" className="text-white inline-flex items-center bg-ferrariRed-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={() => {
                                        if(!state.showDeleteLoading) {
                                            setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false, name: '' }))
                                        }
                                    }}>
                                    No, cancel
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : null
    )
}

export default LandingPage