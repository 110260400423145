/*************************************************
 * Collider
 * @exports
 * @file RightSection.js
 * @author Prakash // on 05/12/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate } from 'react-router-dom'
import { setCostPropsDetails } from  '../../../../actions/cost/CostAction'
import { capitalizeAllLetter, onlyNumeric, thousandSeparator } from '../../../../utils/utility';
import { URL_PATH } from '../../../../config/urlPath';
import MultiSelectSection from '../../../common/MultiSelectSection';
import _ from 'lodash'
import {Icon} from "@iconify/react";

const RightSection = (props) => {
	const[state, setState] = useState({
		showDetails: false,
        expandMenuId: 'projects',
        months: [{label: 'Jan', value: 0}, {label:'Feb', value: 1}, {label:'Mar', value: 2}, {label:'Apr', value: 3}, {label:'May', value: 4}, {label:'Jun', value: 5}, {label:'Jul', value: 6}, {label:'Aug', value: 7}, {label:'Sep', value: 8}, {label:'Oct', value: 9}, {label:'Nov', value: 10}, {label:'Dec', value: 11}],        
        quarters: [
            [{label: 'Jan', value: 0}, {label: 'Feb', value: 1}, {label: 'Mar', value: 2}],
            [{label: 'Apr', value: 3}, {label: 'May', value: 4}, {label: 'Jun', value: 5}],
            [{label: 'Jul', value: 6}, {label: 'Aug', value: 7}, {label: 'Sep', value: 8}],
            [{label: 'Oct', value: 9}, {label: 'Nov', value: 10}, {label: 'Dec', value: 11}]
        ]
	})

	const navigate = useNavigate();
	// const tagKeys = useSelector(state => state.cost.costTagKeys);
	const dispatch = useDispatch(); // Create a dispatch function

    const selectedInitiative = useSelector(state => state.cost.costPropsDetails?.selectedInitiative || false);
    const createInputs = useSelector(state => state.cost.costPropsDetails?.createInputs || false);
    const onClickSave = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.onClickSave ? state.cost.costPropsDetails.onClickSave : false);
    const currencyUnit = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.currencyUnit ? state.cost.costPropsDetails.currencyUnit : 'usd')

    useEffect(() => {
        if(props.receivedData && Object.entries(props.receivedData).length) {
            setState(prevState => ({ 
                ...prevState, 
                department: props.receivedData.department ? props.receivedData.department : '',
                year: props.receivedData.year ? props.receivedData.year : '',
                pageType: props.receivedData.pageType ? props.receivedData.pageType : '',
            }))
        }
    }, [props.receivedData])

    useEffect(() => {
        if(onClickSave === 'stopLoading') {
            setState(prevState => ({ ...prevState, onSaveFunction: false }))
            dispatch(setCostPropsDetails('onClickSave', ''))
        }
    }, [onClickSave, dispatch])

    const projectCommitedBalanceDetails = useCallback((allocation, monthly_distribution, pIndex, row, selectedProjCloudProvider) => {
        let projectMontlyDistrubutition = monthly_distribution
        if (projectMontlyDistrubutition) {
            let projectMonthlySum = projectMontlyDistrubutition.reduce((a, b) => {
                return a + parseFloat(b);
            }, 0); // Initial value of the accumulator is set to 0
            
            let projectBalance = allocation - projectMonthlySum
            

            var quarters = [];
            for (var i = 0; i < projectMontlyDistrubutition.length; i += 3) {
                var quarter = projectMontlyDistrubutition.slice(i, i + 3);
                var quarterSum = quarter.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                quarters.push(quarterSum);
            }
            quarters.forEach((quater, qIndex) => {
                quater = parseFloat(quater).toFixed(2)
                setState(prevState => ({
                    ...prevState,
                    ['project_quater_'+row.project+'_'+qIndex+'_'+pIndex]:  parseFloat(quater)
                }))
            })

            let halfYearlyArray = []
            for (let i = 0; i < projectMontlyDistrubutition.length; i += 6) {
                let halfYearly = projectMontlyDistrubutition.slice(i, i + 6);
                let halfYearlySum = halfYearly.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                halfYearlyArray.push(halfYearlySum);
            }
            
            halfYearlyArray.forEach((half, hIndex) => {
                half = parseFloat(half).toFixed(2)
                setState(prevState => ({
                    ...prevState,
                    ['project_half_'+hIndex+'_'+row.project+'_'+pIndex]:  parseFloat(half)
                }))
            })

            setState(prevState => ({ ...prevState, ['projectMonthlySum_'+selectedProjCloudProvider+'_'+pIndex]: Math.round(projectMonthlySum), ['projectBalance_'+row.project+'_'+pIndex]: Math.round(projectBalance) }))
        }
    }, [])

    useEffect(() => {
        if(state.formtData) {
            setState(prevState => ({ ...prevState, formtData: false }))

            let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
            let obj = createInputs ? createInputs : {}
            let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}
    
            let projects = initiatives && initiatives.projects ? initiatives.projects : []
    
            if(projects && projects.length) {
                projects.forEach((proj, pIndex) => {
                    let totalAllocation = 0
                    let totalDistributionSum = 0
                    let projectCloudProviders = []
                    let projectCloudBudget = proj && proj.cloud_budget ? proj.cloud_budget : {}
                    if(projectCloudBudget && Object.entries(projectCloudBudget).length) {
                        projectCloudProviders = Object.keys(projectCloudBudget)
                        let selectedProjCloudProvider = projectCloudProviders[0]
                        let selectedProjProviderDetails = projectCloudBudget[selectedProjCloudProvider]
    
                        let distribution = selectedProjProviderDetails && selectedProjProviderDetails.monthly_distribution ? selectedProjProviderDetails.monthly_distribution : []
                        
                        state.months.forEach((mnt, mIndex) => {
                            setState(prevState => ({ ...prevState, ['project_'+proj.project+'_'+mnt.label+'_'+pIndex]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
                        })
                        
                        let allocation = selectedProjProviderDetails && selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0
                        let credits = selectedProjCloudProvider && selectedProjCloudProvider.credits ? selectedProjCloudProvider.credits : 0
                        let over_budget = selectedProjCloudProvider && selectedProjCloudProvider.over_budget ? selectedProjCloudProvider.over_budget : 0
                        let under_budget = selectedProjCloudProvider && selectedProjCloudProvider.under_budget ? selectedProjCloudProvider.under_budget : 0
    
                        projectCommitedBalanceDetails(allocation, distribution, pIndex, proj, selectedProjCloudProvider)
                        
                        setState(prevState => ({ ...prevState, ['projectCloudProviders_'+pIndex]: projectCloudProviders, ['selectedProjCloudProvider_'+pIndex]: selectedProjCloudProvider, ['projectAlloation_'+proj.project+'_'+pIndex]: allocation, ['credits_'+proj.project+'_'+pIndex]: credits, ['over_budget_'+proj.project+'_'+pIndex]: over_budget, ['under_budget_'+proj.project+'_'+pIndex]: under_budget }))
                    }
                    
                    let projectProviders = []
                    Object.entries(proj.cloud_budget).forEach(([key, value], objIndex) => {
    
                        // let allocation = value.allocation 
                        let projectDistrubutition = value.monthly_distribution
    
                        let projDistributionSum = 0
                        if(projectDistrubutition) {
                            projDistributionSum = projectDistrubutition.reduce((a, b) => {
                                return a + parseFloat(b);
                            }, 0)
                        }

                        totalAllocation += Math.round(value.allocation) 
                        totalDistributionSum += Math.round(projectDistrubutition)
    
                        let projRow = {}
                        projRow.provider = key
                        projRow.allocation = value.allocation 
                        projRow.distributuion = Math.round(projDistributionSum)
                        projRow.balance = Math.round(value.allocation - projDistributionSum)
        
                        projectProviders.push(projRow)

                        if(Object.entries(proj.cloud_budget).length-1 === objIndex) {
                            let totalDepRow = {}
                            totalDepRow.provider = 'Total'
                            totalDepRow.allocation = totalAllocation
                            totalDepRow.distributuion = totalDistributionSum
                            totalDepRow.balance = totalAllocation - totalDistributionSum
                            projectProviders.unshift(totalDepRow);
                        }
        
                        
                        setState(prevState => ({ ...prevState, ['projectProviders_'+proj.project]: projectProviders}))
                    })
                })

            }
        }
        //project format end
    }, [state.formtData, createInputs, projectCommitedBalanceDetails, selectedInitiative, state.months])

    useEffect(() => {
        if (!_.isEqual(selectedInitiative, state.selectedInitiativeObj)) {
            setState(prevState => ({ ...prevState, formtData: true, selectedInitiativeObj: selectedInitiative }))
        }
    }, [selectedInitiative, state.selectedInitiativeObj])

     //for Initiative// 
     const onChangeProjCloudProvider = (selectedProjCloudProvider, pIndex) => {
        let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}
        
        let projects = initiatives && initiatives.projects ? initiatives.projects : []
        
        let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}
        let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[selectedProjCloudProvider] ? selectedProjCloudBudget.cloud_budget[selectedProjCloudProvider] : {}
        
        let allocations = selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0
        

        let distribution = selectedProjProviderDetails && selectedProjProviderDetails.monthly_distribution ? selectedProjProviderDetails.monthly_distribution : []
        state.months.forEach((mnt, mIndex) => {
            setState(prevState => ({ ...prevState, ['project_'+selectedProjCloudBudget.project+'_'+mnt.label+'_'+pIndex]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
        })

        projectCommitedBalanceDetails(allocations, distribution, pIndex, selectedProjProviderDetails, selectedProjCloudProvider)
        setState(prevState => ({ ...prevState, ['projectAlloation_'+selectedProjCloudBudget.project+'_'+pIndex]: allocations }))
    }

    useEffect(() => {
        if(selectedInitiative && Object.entries(selectedInitiative).length) {
            setState(prevState => ({ 
                ...prevState, 
                selectedInitiative: selectedInitiative.initiative,
                projectOptions: selectedInitiative.projects && selectedInitiative.projects.length ? selectedInitiative.projects : [] 
            }))
        }
    }, [selectedInitiative])
    
	const handleProjectInputChange = (label, pIndex, value) => {
        let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}

        let projects = initiatives && initiatives.projects ? initiatives.projects : []

        let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}
        let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] ? selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] : {}
        
        
        // let invObj = initiatives && initiatives[pIndex] ? initiatives[pIndex] : {}
        if(label === 'allocation') {
            value = parseFloat(value)
            selectedProjProviderDetails[label] = value
            if(!state['montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]) {
                projectCommitedBalanceDetails(value, selectedProjCloudBudget.monthly_distribution, pIndex, selectedProjCloudBudget, state['selectedProjCloudProvider_'+pIndex])
            }
        } else if(label === 'credits' || label === 'over_budget' || label === 'under_budget') {
            selectedProjCloudBudget[label] = value
        }


        // invObj.projects = newProj
        // initiatives[selectedInitiative.iIndex] = invObj
        // dispatch(setCostPropsDetails('createInputs', obj))
	}

    const callProjectEqualMonthlyDistribution = useCallback((pIndex, value) => {
        let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}

        let projects = initiatives && initiatives.projects ? initiatives.projects : []

        let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}

        let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] ? selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] : {}

        let allocations = selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0

        let monthly_distribution = []
        let amount = parseFloat(value/12).toFixed(2)
        state.months.forEach(mnt => {
            monthly_distribution[mnt.value] = parseFloat(amount)
        })        
        selectedProjProviderDetails.monthly_distribution = monthly_distribution

        dispatch(setCostPropsDetails('createInputs', obj))
        projectCommitedBalanceDetails(allocations, monthly_distribution, pIndex, selectedProjCloudBudget)

    }, [state, createInputs, dispatch, selectedInitiative, projectCommitedBalanceDetails])

    const handleProjectMonthlyEqualSplit = (monthlySplit, pIndex, value, row) => {

        if(monthlySplit) {
            let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
            let obj = createInputs ? createInputs : {}
            let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}
    
            let projects = initiatives && initiatives.projects ? initiatives.projects : []
            
            let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}

            let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] ? selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] : {}
            
            let allocations = selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0
            let amount = parseFloat(allocations/12).toFixed(2)
            state.months.forEach(mnt => {
                setState(prevState => ({ ...prevState, ['project_'+row.project+'_'+mnt.label+'_'+pIndex]: parseFloat(amount) }))
            })

            callProjectEqualMonthlyDistribution(pIndex, value)
        }
    }

    const handleProjectMonthlySplit = (label, pIndex, value, row) => {
        let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}

        let projects = initiatives && initiatives.projects ? initiatives.projects : []

        let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}
        let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] ? selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] : {}

        let allocations = selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0
        
        let monthly_distribution = selectedProjProviderDetails && selectedProjProviderDetails.monthly_distribution ? selectedProjProviderDetails.monthly_distribution : []
        state.months.forEach(mnt => {
            if(label === mnt.value) {
                let amount = value ? parseFloat(value).toFixed(2) : 0
                monthly_distribution[mnt.value] = parseFloat(amount)
            } else {
                monthly_distribution[mnt.value] = state['project_'+row.project+'_'+mnt.label+'_'+pIndex] ? parseFloat(state['project_'+row.project+'_'+mnt.label+'_'+pIndex]) : 0
            }
        })
        
        selectedProjProviderDetails.monthly_distribution = monthly_distribution  
        
        projectCommitedBalanceDetails(allocations, monthly_distribution, pIndex, row)

        // obj.initiatives = initiatives
        dispatch(setCostPropsDetails('createInputs', obj))
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const expandCollapse = (section) => {
        setState(prevState => ({ ...prevState, expandMenuId: state.expandMenuId === section ? "" : section }))
    }

    const expandProjects = (row) => {
        setState(prevState => ({ ...prevState, expandProj: state.expandProj === row.project ? "" : row.project }))
    }

    const navigateTo = () => {
        navigate(URL_PATH.COST_BUDGET_LIST); // Redirect to the specified path
    }

    const onChangeHandleValidation = (type, mnt) => {
        //initiative annual amount and total monthly amount check
        let hasError = false
        let obj = createInputs ? createInputs : {}
        let initiatives = obj.initiatives ? obj.initiatives : []
        if(initiatives.length) {
            initiatives.forEach((row, rIndex) => {
                Object.entries(row.cloud_budget).forEach(([key, value]) => {
                    let projects = row.projects ? row.projects : []
                    projects.forEach(proj => {
                        let projectAllocationError = []
                        let projectProviders = []
                        let totalAllocation = 0
                        let totalDistributionSum = 0
                        if(proj.cloud_budget && Object.entries(proj.cloud_budget).length) {
                            Object.entries(proj.cloud_budget).forEach(([key, value], objIndex) => {
                                let projecttDistrubutition = value.monthly_distribution
                                let distributionSum = 0
                                if(projecttDistrubutition) {
                                    distributionSum = projecttDistrubutition.reduce((a, b) => {
                                        return a + parseFloat(b);
                                    }, 0)
                                }

                                totalAllocation += Math.round(value.allocation) 
                                totalDistributionSum += Math.round(distributionSum)

                                let dataRow = {}
                                dataRow.provider = key
                                dataRow.allocation = Math.round(value.allocation)
                                dataRow.distributuion = Math.round(distributionSum)
                                dataRow.balance = Math.round(value.allocation) - Math.round(distributionSum)
                                
                                if(value.allocation < distributionSum) {
                                    hasError = true
                                    let error ='Annual commitment amount of '+capitalizeAllLetter(key)+' should be more than the total monthly commitment.'
                                    
                                    projectAllocationError.push(error)
                                }
                                projectProviders.push(dataRow)

                                if(Object.entries(proj.cloud_budget).length-1 === objIndex) {
                                    let totalDepRow = {}
                                    totalDepRow.provider = 'Total'
                                    totalDepRow.allocation = totalAllocation
                                    totalDepRow.distributuion = totalDistributionSum
                                    totalDepRow.balance = totalAllocation - totalDistributionSum
                                    projectProviders.unshift(totalDepRow);
                                }
                                
                                setState(prevState => ({ ...prevState, ['projectAllocationError_'+proj.project]: projectAllocationError }))
                            })
                        }

                        setState(prevState => ({ ...prevState, ['projectProviders_'+proj.project]: projectProviders }))
                    })

                    let projectTotalAllocation = 0
                    let projectIntitativeAllocationError = []
                    projects.forEach(proj => {
                        if(proj.cloud_budget.hasOwnProperty(key)) {
                            let projData = proj.cloud_budget[key]
                            projectTotalAllocation += projData.allocation
                        }
                    })
                    

                    projects.forEach(proj => {
                        projectIntitativeAllocationError = []
                        if(proj.cloud_budget.hasOwnProperty(key)) {
                            if(projectTotalAllocation > value.allocation) {
                                hasError = true
                                let error = 'Total Project Annual commitment amount of '+capitalizeAllLetter(key)+' should be less than the initiative annual commitment.'
                                projectIntitativeAllocationError.push(error)
                                setState(prevState => ({ ...prevState, ['projectIntitativeAllocationError_'+key+'_'+proj.project]: projectIntitativeAllocationError }))
                            } else {
                                setState(prevState => ({ ...prevState, ['projectIntitativeAllocationError_'+key+'_'+proj.project]: [] }))
                            }
                        }
                    })
                    
                })
            })
        }

        // if(initiatives.length) {
        //     initiatives.forEach((int, iIndex) => {
        //         let projectAllocations = 0
        //         if(int.projects && int.projects.length) {
        //             int.projects.forEach(proj => {
        //                 projectAllocations += parseFloat(proj.allocation ? proj.allocation : 0)
        //             })

        //             int.projects.forEach((proj, pIndex) => {
        //                 let projectAllocationError = ''
        //                 if(proj.monthly_distribution) {
        //                     const projSum = proj.monthly_distribution.reduce((a, b) => {
        //                         return a + parseFloat(b);
        //                     }, 0); // Initial value of the accumulator is set to 0
        //                     if(parseFloat(projSum) > parseFloat(proj.allocation) && !state['project_montly_split_equal_'+proj.project+'_'+pIndex]) {
        //                         hasError = true
        //                         projectAllocationError ='Annual commitment amount should be greater than the monthly commitment.'
        //                     }

        //                     setState(prevState => ({ 
        //                         ...prevState,
        //                         ['projectAllocationError_'+iIndex+'_'+pIndex]: projectAllocationError
        //                     }))
        //                 }

        //                 let projInitAllocationAmount = ''
        //                 if(projectAllocations > int.allocation) {
        //                     hasError = true
        //                     projInitAllocationAmount = 'The total commitment amount for the project should not exceed the annual commitment for the initiative.'
        //                 }

        //                 setState(prevState => ({ ...prevState, 
        //                     projInitAllocationAmount,
        //                     ['projInitAllocationAmount_'+iIndex+'_'+pIndex]: projInitAllocationAmount
        //                 }))

        //                 if(mnt) {
        //                     let projects = int.projects
        //                     let initiativeMonthlyAllocation = int.monthly_distribution[mnt.value] ? int.monthly_distribution[mnt.value] : 0
        //                     const values = projects.map(subArray => subArray.monthly_distribution[mnt.value]);
        //                     const projectsMontlyAllocation = values.reduce((a, b) => a + b, 0);
        //                     let projectMonthlyAllocatonError = ''
        //                     if(parseFloat(initiativeMonthlyAllocation) < parseFloat(projectsMontlyAllocation)) {
        //                         hasError = true
        //                         projectMonthlyAllocatonError = 'The total amount for projects in '+ mnt.label+'  ('+parseFloat(projectsMontlyAllocation)+') should not exceed to the initiative '+ mnt.label+' month ('+parseFloat(initiativeMonthlyAllocation)+').'
        //                     }
    
        //                     setState(prevState => ({ ...prevState, ['projIniSeparateMonthlyError_'+iIndex+'_'+pIndex]: projectMonthlyAllocatonError }))
        //                 }
        //             })

        //         }

        //         let initProjectAllocationAmount = ''
        //         if(projectAllocations > int.allocation) {
        //             if(projectAllocations > int.allocation) {
        //                 hasError = true
        //                 initProjectAllocationAmount = 'The total commitment amount for the project should not exceed the annual commitment for the initiative.'
        //             }
        //         }
        //         setState(prevState => ({ ...prevState,
        //             ['initProjectAllocationAmount_'+iIndex]: initProjectAllocationAmount,
        //         }))        
        //     })
        // }
        if(hasError) {
            dispatch(setCostPropsDetails('onClickSave', 'checkForError'))
        } else {
            if(type === 'save') {
                dispatch(setCostPropsDetails('onClickSave', 'save'))
            } else {
                dispatch(setCostPropsDetails('onClickSave', 'checkForErrorHasNoError'))
            }
        }
    }
	
	return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='flex flex-wrap text-black mx-0 pb-2 bg-white'>
                {state.selectedInitiative ?
                    <div className="p-2 rounded-md overflow-auto w-full mb-5">
                        <div className=''>
                            <div className={`px-2 mt-2 py-3 bg-BlockWhite`}> 
                                <div className={`mb-0 cursor-pointer`} 
                                onClick={() => expandCollapse('projects')}>
                                    <div className={`mb-0 flex justify-between`}>
                                        <div className='flex'>
                                            <Icon icon={`${state.expandMenuId === 'projects' ? 'zondicons:minus-outline' :'tabler:circle-plus'}`} className={`mr-1 self-center cursor-pointer text-black`} width={16} height={16} />
                                            Projects
                                        </div>
                                        <div className='flex'>
                                            <span className='mr-3 text-black f14 font-weight-normal self-center'>Total</span>
                                            <h5 className='text-warning mb-0'>
                                                {createInputs && createInputs.initiatives && createInputs.initiatives.length && createInputs.initiatives[selectedInitiative.iIndex] && createInputs.initiatives[selectedInitiative.iIndex].projects && createInputs.initiatives[selectedInitiative.iIndex].projects.length ?
                                                    thousandSeparator(createInputs.initiatives[selectedInitiative.iIndex].projects.reduce((a, b) => {
                                                        return a + parseFloat(b.allocation);
                                                    }, 0))
                                                : 0}
                                            </h5>
                                        </div>
                                    </div>
                                    {state.projInitAllocationAmount ?
                                        <p className='mb-1 text-danger'>{state.projInitAllocationAmount}</p>
                                    : null}
                                </div>
                                {state.expandMenuId === 'projects' ?
                                    <div className='border-lightGray rounded-md p-3 mt-2'>
                                        <React.Fragment>
                                        <p className='mb-1'>Showing projects of {state.selectedInitiative}</p>
                                        {state.projectOptions && state.projectOptions.map((row, pIndex) => {
                                            return(
                                                <div key={'proj_'+pIndex} className={`px-2 py-3 bg-GhostWhite ${pIndex ? 'border-t border-mediumDarkGray' : ""}`}> 
                                                    <div className={`mb-0 flex justify-between cursor-pointer`} 
                                                        onClick={() => expandProjects(row)}>
                                                        <div className='flex'>
                                                            <Icon icon={`${state.expandProj === row.project ? 'zondicons:minus-outline' :'tabler:circle-plus'}`} className={`mr-1 self-center cursor-pointer text-black`} width={16} height={16} />
                                                            {row.project}
                                                        </div>
                                                    </div>
                                                    {state.expandProj === row.project ?
                                                        <div className="my-3">
                                                            {state['projectIntitativeAllocationError_'+state['selectedProjCloudProvider_'+pIndex]+'_'+row.project] && state['projectIntitativeAllocationError_'+state['selectedProjCloudProvider_'+pIndex]+'_'+row.project].length ?
                                                                state['projectIntitativeAllocationError_'+state['selectedProjCloudProvider_'+pIndex]+'_'+row.project].map(err => {
                                                                    return(
                                                                        <p className='mb-1 text-danger'>{err}</p>
                                                                    )
                                                                })
                                                            : null}
                                                            {state['projectAllocationError_'+row.project] && state['projectAllocationError_'+row.project].length ?
                                                                state['projectAllocationError_'+row.project].map(err => {
                                                                    return(
                                                                        <p className='mb-1 text-danger'>{err}</p>
                                                                    )
                                                                })
                                                            : null}
                                                            <div className='flex flex-wrap mb-3'>                            
                                                                <div className="py-1 lg:w-2/5 w-full">
                                                                    <p className="mb-0 mr-2 self-center text-lightGray">Credits</p>
                                                                    <div className='flex'>
                                                                        <input
                                                                            type="text"
                                                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-4/5`}
                                                                            placeholder="Enter amount"
                                                                            value={state['credits_'+row.project+'_'+pIndex] ? state['credits_'+row.project+'_'+pIndex] : ""}
                                                                            readOnly={state.pageType === 'View' ? true : false}
                                                                            onChange={e => {
                                                                                setState(prevState => ({ ...prevState, ['credits_'+row.project+'_'+pIndex]: onlyNumeric(e.target.value) }))
                                                                                handleProjectInputChange('credits', pIndex, onlyNumeric(e.target.value))
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="py-1 lg:w-2/5 w-full">
                                                                    <p className="mb-0 mr-2 self-center text-lightGray">Underutilized</p>
                                                                    <div className='flex'>
                                                                        <input
                                                                            type="text"
                                                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-4/5`}
                                                                            placeholder="Enter amount"
                                                                            value={state['under_budget_'+row.project+'_'+pIndex] ? state['under_budget_'+row.project+'_'+pIndex] : ""}
                                                                            readOnly={state.pageType === 'View' ? true : false}
                                                                            onChange={e => {
                                                                                if(e.target.value <= 100) {
                                                                                    setState(prevState => ({ ...prevState, ['under_budget_'+row.project+'_'+pIndex]: onlyNumeric(e.target.value) }))
                                                                                    handleProjectInputChange('under_budget', pIndex, onlyNumeric(e.target.value))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="py-1 lg:w-2/5 w-full">
                                                                    <p className="mb-0 mr-2 self-center text-lightGray">Overutilized</p>
                                                                    <div className='flex'>
                                                                        <input
                                                                            type="text"
                                                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-4/5`}
                                                                            placeholder="Enter amount"
                                                                            value={state['over_budget_'+row.project+'_'+pIndex] ? state['over_budget_'+row.project+'_'+pIndex] : ""}
                                                                            readOnly={state.pageType === 'View' ? true : false}
                                                                            onChange={e => {
                                                                                if(e.target.value <= 100) {
                                                                                    setState(prevState => ({ ...prevState, ['over_budget_'+row.project+'_'+pIndex]: onlyNumeric(e.target.value) }))
                                                                                    handleProjectInputChange('over_budget', pIndex, onlyNumeric(e.target.value))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='flex flex-wrap'>
                                                                <div className="py-1 lg:w-1/5 w-full">
                                                                    <div className='flex justify-between'>
                                                                        <p className="b-block mb-0 text-lightGray">Cloud Provider</p>
                                                                    </div>
                                                                    <div className={`w-4/5 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                                                        event.preventDefault();
                                                                        handleChildClick(event, 'child', 'singleDropDown', "showProjCloudProvider_"+pIndex)
                                                                    }}>
                                                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 truncate ${state['selectedProjCloudProvider_'+pIndex] ? 'text-black' : 'text-lightGray'}`}>
                                                                            {state['selectedProjCloudProvider_'+pIndex] ? capitalizeAllLetter(state['selectedProjCloudProvider_'+pIndex]) : 'Select'}
                                                                            <Icon icon="icon-park-solid:down-one" className={`${state['selectedProjCloudProvider_'+pIndex] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                                                        </p>
                                                                        {state["showProjCloudProvider_"+pIndex] ?
                                                                            <div className='relative'>
                                                                                <MultiSelectSection
                                                                                    options={state['projectCloudProviders_'+pIndex] ? state['projectCloudProviders_'+pIndex] : []}
                                                                                    selectedValues={state['selectedProjCloudProvider_'+pIndex] ? state['selectedProjCloudProvider_'+pIndex] : ''}
                                                                                    makeLabelUpperCase={'capitalizeAllLetter'}
                                                                                    callbackMultiSelect={(value) => {
                                                                                        setState(prevState => ({ 
                                                                                            ...prevState, 
                                                                                            ['selectedProjCloudProvider_'+pIndex]: value,
                                                                                            ['project_montly_split_equal_'+value+'_'+pIndex]: state['project_montly_split_equal_'+value+'_'+pIndex] ? true : false
                                                                                        }))
                                                                                        onChangeProjCloudProvider(value, pIndex)
                                                                                    }}
                                                                                    singleSelection={true}
                                                                                    removeTopOptions={true}
                                                                                    widthClass={'minWidth220'}
                                                                                />
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                                <div className="py-1 lg:w-2/5 w-full">
                                                                    <p className="mb-0 mr-2 self-center text-lightGray">Annual&nbsp;Budget</p>
                                                                    <div className='flex'>
                                                                        <input
                                                                            type="text"
                                                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-4/5`}
                                                                            placeholder="Enter amount"
                                                                            value={state['projectAlloation_'+row.project+'_'+pIndex] ? state['projectAlloation_'+row.project+'_'+pIndex] : ""}
                                                                            readOnly={state.pageType === 'View' ? true : false}
                                                                            onChange={e => {
                                                                                setState(prevState => ({ ...prevState, ['projectAlloation_'+row.project+'_'+pIndex]: onlyNumeric(e.target.value) }))
                                                                                handleProjectInputChange('allocation', pIndex, onlyNumeric(e.target.value))
                                                                                if(state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]) {
                                                                                    handleProjectMonthlyEqualSplit(true, pIndex, onlyNumeric(e.target.value), row)
                                                                                }
                                                                                onChangeHandleValidation()
                                                                            }}
                                                                            />
                                                                        <p className="text-black mb-0 ml-2 self-center">{currencyUnit ? capitalizeAllLetter(currencyUnit) : ''}</p>
                                                                    </div>
                                                                </div>        
                                                                <div className="py-1 lg:w-2/5 w-full lg:pl-3 self-center">
                                                                    <div className='flex self-center mt-3'>
                                                                        <p className="mb-0 text-lightGray mr-2 self-center" onClick={() => setState(prevState => ({ ...prevState, ['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]: !state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex] }))}>Equal Monthly Budget</p>
                                                                        <label className="mb-0">
                                                                            <input type="checkbox" 
                                                                                className="mt-2"
                                                                                readOnly={state.pageType === 'View' ? true : false}
                                                                                checked={state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex] ? true : false}  
                                                                                onChange={(e) => {
                                                                                    let monthlySplit = !state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]
                                                                                    setState(prevState => ({ ...prevState, ['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]: monthlySplit }))
                                                                                    handleProjectMonthlyEqualSplit(monthlySplit, pIndex, state['projectAlloation_'+row.project+'_'+pIndex], row)
                                                                                }}
                                                                                value={state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex] ? true : false}
                                                                            />
                                                                            <span className="slider round small pl-1"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                                {state['projectProviders_'+row.project] && state['projectProviders_'+row.project].length ?
                                                                    <div className='bg-BlockWhite m-1 p-2 rounded-md'>
                                                                        {state['projectProviders_'+row.project].map((pro, proIndex) => {
                                                                            return(
                                                                                <div key={'pro_'+proIndex} className={`flex flex-wrap justify-between my-1 ${proIndex ? 'pt-2 border-t border-mediumDarkGray' : ''}`}>
                                                                                    <div className='self-center lg:w-2/12 w-full'>
                                                                                        <div className="py-1 pl-3">
                                                                                            <span className="ml-2 text-lg text-center mb-0 text-info">{pro.provider ? capitalizeAllLetter(pro.provider) : ''}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='self-center lg:w-3/12 w-full'>
                                                                                        <div className="flex justify-between py-1 pl-3">
                                                                                            <p className="text-black mb-0 text-lg">Committed</p>
                                                                                            <span className="ml-2 text-lg mb-0 text-warning">{pro.allocation ? thousandSeparator(pro.allocation) : 0}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='self-center lg:w-3/12 w-full'>
                                                                                        <div className="flex justify-between py-1 pl-3">
                                                                                            <p className="text-black mb-0 text-lg">Allocated</p>
                                                                                            <span className="ml-2 text-lg mb-0 text-warning">{pro.distributuion ? thousandSeparator(pro.distributuion) : 0}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='self-center lg:w-3/12 w-full'>
                                                                                        <div className="flex justify-between py-1 pl-3">
                                                                                            <p className="text-black mb-0 text-lg">Balance</p>
                                                                                            <span className={`ml-2 text-lg mb-0 ${pro.balance > 0 ? 'text-info' : 'text-danger'}`}>{pro.balance ? thousandSeparator(pro.balance) : 0}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                : null}                                                            
                                                                {state['projIniSeparateMonthlyError_'+selectedInitiative.iIndex+'_'+pIndex] ?
                                                                    <p className='mb-1 text-danger'>{state['projIniSeparateMonthlyError_'+selectedInitiative.iIndex+'_'+pIndex]}</p>
                                                                : null}                                                            
                                                                <p className='mb-0 mt-2'>Monthly Budget</p>
                                                                <div className='flex flex-wrap mt-2'>
                                                                    {state.quarters && state.quarters.map((quarter, rowIndex) => {
                                                                        return(
                                                                            <div className='w-1/4' key={'quater_'+rowIndex}>
                                                                                {quarter.map(mnt => {
                                                                                    return(
                                                                                        <div className='mb-3 text-lightGray' key={'dep_'+mnt.label}>
                                                                                            <div className='flex justify-between'>
                                                                                                <p className="mb-0 text-lightGray">{mnt.label+' '+(state.year ? state.year : '')}</p>
                                                                                            </div>
                                                                                            <input
                                                                                                type="text"
                                                                                                className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-4/5`}
                                                                                                readOnly={state.pageType === 'View' ? true : false}
                                                                                                value={state['project_'+row.project+'_'+mnt.label+'_'+pIndex] ? state['project_'+row.project+'_'+mnt.label+'_'+pIndex] : ""}
                                                                                                onChange={e => {
                                                                                                    setState(prevState => ({ ...prevState, ['project_'+row.project+'_'+mnt.label+'_'+pIndex]: e.target.value }))
                                                                                                    handleProjectMonthlySplit(mnt.value, pIndex, e.target.value, row)
                                                                                                    onChangeHandleValidation('', mnt)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                                
                                                                                <div className="flex justify-center w-4/5">
                                                                                    <div className="w-1/2 border-t-2 border-info"></div>
                                                                                </div>
                                                                                <span className='flex justify-center mt-2 w-4/5'>Q{rowIndex+1}  {state['project_quater_'+row.project+'_'+rowIndex+'_'+pIndex] ? thousandSeparator(Math.round(state['project_quater_'+row.project+'_'+rowIndex+'_'+pIndex])) : 0}</span>
                                                                                
                                                                                {/* <div className='borderBottomMiddleBlue'></div>
                                                                                <span className='flex justify-center mt-2'>Q{rowIndex+1} {state['project_quater_'+row.project+'_'+rowIndex+'_'+pIndex] ? thousandSeparator(Math.round(state['project_quater_'+row.project+'_'+rowIndex+'_'+pIndex])) : 0}</span> */}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>

                                                                <div className='flex flex-wrap justify-between mt-4'>
                                                                    <div className='w-1/2'>
                                                                        <div className="flex justify-center">
                                                                            <div className="w-1/2 border-t-2 border-info"></div>
                                                                        </div>
                                                                        <span className='mt-2 flex justify-center'>H1 {state['project_half_0_'+row.project+'_'+pIndex] ? thousandSeparator(Math.round(state['project_half_0_'+row.project+'_'+pIndex]), 0) : 0}</span>
                                                                    </div>
                                                                    <div className='w-1/2'>
                                                                        <div className="flex justify-center">
                                                                            <div className="w-1/2 border-t-2 border-info"></div>
                                                                        </div>
                                                                        <span className='mt-2 flex justify-center'>H2 {state['project_half_1_'+row.project+'_'+pIndex] ? thousandSeparator(Math.round(state['project_half_1_'+row.project+'_'+pIndex]),0) : 0}</span>
                                                                    </div>
                                                                </div>

                                                                {/* <div className='flex justify-between mt-4'>
                                                                    <div className='w-50 borderBottomMiddleBlue'></div>
                                                                    <div className='w-50 borderBottomMiddleBlue'></div>
                                                                </div>
                                                                <div className='flex justify-between mt-2'>
                                                                    <span className='w-50 flex justify-center'>H1 {state['project_half_0_'+row.project+'_'+pIndex] ? thousandSeparator(Math.round(state['project_half_0_'+row.project+'_'+pIndex]), 0) : 0}</span>
                                                                    <span className='w-50 flex justify-center'>H2 {state['project_half_1_'+row.project+'_'+pIndex] ? thousandSeparator(Math.round(state['project_half_1_'+row.project+'_'+pIndex]),0) : 0}</span>
                                                                </div> */}

                                                                <div className="relative overflow-x-auto mt-2">
                                                                    <p className='mb-0 text-black'>Accounts</p>
                                                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                                        <thead className="text-md text-black bg-lightGray">
                                                                            <tr>
                                                                                <th scope="col" className="px-6 py-3">Provider</th>
                                                                                <th scope="col" className="px-6 py-3">Account</th>
                                                                                <th scope="col" className="px-6 py-3">Billing Contribution</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className='text-black bg-white'>
                                                                            
                                                                            {row.accounts && row.accounts.length ?
                                                                                row.accounts.map((acc, accIndex) => {
                                                                                    return(
                                                                                        <tr key={'sft_'+accIndex} className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700`}>
                                                                                            <td className="px-6 py-3 text-sm">{capitalizeAllLetter(acc.cloudProviderId)}</td>
                                                                                            <td className="px-6 py-3 text-sm">{acc.billingUnit}</td>
                                                                                            <td className="px-6 py-3 text-sm">{acc.billingContribution}%</td>
                                                                                        </tr>
                                                                                    )

                                                                                })
                                                                            : null}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                            )
                                        })}
                                        </React.Fragment>
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                : null}
			</div>
            <div className='w-full flex justify-end bg-transparent mb-4 pr-3'>
            {/* <div className='fixed bottom-4 right-4 justify-end bg-transparent'> */}
                {state.onSaveFunction ?
                    <button className={`flex bg-info rounded-md px-2 py-1 text-white mr-2`}>
                        <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                            <path d="M4 12a8 8 0 0112-6.9" />
                        </svg>
                        {state.pageType !== "Edit" ?  "Saving"  : "Updating"}
                    </button>
                :
                    <div className='flex'>
                        {state.pageType !== "View" ?
                            <button className={`bg-info rounded-md px-2 py-1 text-white mr-2`} 
                                onClick={() => {
                                    setState(prevState => ({ ...prevState, onSaveFunction: true }))
                                    onChangeHandleValidation('save')
                                }}>
                                {state.pageType !== "Edit" ?  "Save"  : "Update"}
                            </button>
                        : null}
                        <button className={`bg-lightGray rounded-md px-2 py-1 text-white mr-2`} 
                            onClick={navigateTo}>
                            Cancel
                        </button>
                    </div>
                }
            </div>
		</div>
	)
}

export default RightSection