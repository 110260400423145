import React, { useEffect, useRef, useState } from 'react';
import {Icon} from "@iconify/react";

const RunsActions = ({data, rowindex, actions, checkDynamicActions, onChangeAction}) => {
    const dropdownRef = useRef()
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [dropActions, setDropActions] = useState([])
 
    const handleDropdownToggle = (e) => {
        e.stopPropagation();
        setDropdownOpen(!dropdownOpen)
    }

    const handleOptionSelect = (e, action) => {
        setDropdownOpen(false);
        onChangeAction(e, action)
    }

    useEffect(() => {
        let dropActions = actions
        if(data && data.job_cancel_request && !dropActions.includes('Cancel')) {
            dropActions.push('Cancel')
        } else {
            dropActions = dropActions.filter(e => e !== 'Cancel')
        }
        setDropActions(dropActions)
    }, [actions, checkDynamicActions, data])

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
          }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    return (
        <div className="" ref={dropdownRef}>
            <Icon icon='streamline:horizontal-menu-circle-solid' className='text-black cursor-pointer' width={24} height={24}  onClick={(e) => handleDropdownToggle(e)} />
            {dropdownOpen ?
                <div className="absolute mt-1 bg-white border border-byzantineBlue rounded-md shadow-[0_4px_6px_rgba(33,37,41,0.2),_0_0_1px_rgba(33,37,41,0.32)] z-10 w-fit text-base ">
                    <ul className="">
                        {dropActions.map((act, actIndex) => {
                            return(
                                act ? 
                                    <div className={`text-base hover:bg-water-600`} key={'drop_'+actIndex}>
                                        <li className="cursor-pointer py-2 px-4" onClick={(e) => handleOptionSelect(e, act)}>{act}</li>
                                    </div>
                                : null
                            )
                        })}
                    </ul>
                </div>
            : null}
        </div>
    )
}

export default RunsActions