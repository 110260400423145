/*************************************************
 * Collider
 * @exports
 * @file types.js
 * @author Rajasekar // on 23/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
export const ACTION_TYPES = {

	//Resources
	SET_RESOURCES_PROPS_DETAILS: 'SET_RESOURCES_PROPS_DETAILS',

	//Resource 

	//clusters
	SET_LIST_CLUSTER_NAME: 'SET_LIST_CLUSTER_NAME',


	SET_AUTH_TOKENS: 'SET_AUTH_TOKENS',
	SET_SUPPRESSION_LIST: 'SET_SUPPRESSION_LIST',
	SET_PROVIDERS_LIST: 'SET_PROVIDERS_LIST',
	SET_SERVICES_LIST: 'SET_SERVICES_LIST',
	SET_REGIONS_LIST: 'SET_REGIONS_LIST',
	SET_PROJECTS_LIST: 'SET_PROJECTS_LIST',
	SET_ACCOUNTS_LIST: 'SET_ACCOUNTS_LIST',
	SET_PROFILE_DETAILS: 'SET_PROFILE_DETAILS',
	SET_USER_MENU: 'SET_USER_MENU',
	SET_CATEGORIES_LIST: 'SET_CATEGORIES_LIST',
	SET_ACCOUNTS_DETAILS_LIST: 'SET_ACCOUNTS_DETAILS_LIST',
	SET_SEARCH_META_LIST: 'SET_SEARCH_META_LIST',
	SET_RESOURCE_SEARCH_META_LIST: 'SET_RESOURCE_SEARCH_META_LIST',
	SET_TAG_KEYS: 'SET_TAG_KEYS',
	
	SET_PROVIDER_COUNT: 'SET_PROVIDERS_COUNT',
	SET_STATUS_COUNT: 'SET_STATUS_COUNT',
	SET_ASSET_COUNT: 'SET_ASSET_COUNT',
	SET_CATEGORY_COUNT: 'SET_CATEGORY_COUNT',
	SET_SELECTED_FILTER: 'SET_SELECTED_FILTER',
	SET_SELECTED_GOVERNANCE_FILTER: 'SET_SELECTED_GOVERNANCE_FILTER',

	SET_EDIT_RULE_PREVIEW_DETAILS: 'SET_EDIT_RULE_PREVIEW_DETAILS',

	SET_EDIT_TAG_GROUP_DETAILS_TO_INITIAL: 'SET_EDIT_TAG_GROUP_DETAILS_TO_INITIAL',

	SET_EDIT_TAG_GROUPS_DETAILS: 'SET_EDIT_TAG_GROUPS_DETAILS',
	SET_INITIAL_RULE_DETAILS: 'SET_INITIAL_RULE_DETAILS',

	SET_SELECTED_TABLE_ROW: 'SET_SELECTED_TABLE_ROW',
	SET_ADVANCED_SELECTED_TABLE_ROW: 'SET_ADVANCED_SELECTED_TABLE_ROW',
	SET_GOVERNANCE_SELECTED_TABLE_ROW: 'SET_GOVERNANCE_SELECTED_TABLE_ROW',
	SET_GOVERNANCE_CATEGORY_LIST: 'SET_GOVERNANCE_CATEGORY_LIST',
	SET_GOVERNANCE_CATEGORY_LIST_NEW: 'SET_GOVERNANCE_CATEGORY_LIST_NEW',
	SET_GOVERNANCE_SIZING_EC2: 'SET_GOVERNANCE_SIZING_EC2',
	SET_GOVERNANCE_BLOCKSTORE: 'SET_GOVERNANCE_BLOCKSTORE',
	SET_GOVERNANCE_LOGTYPE: 'SET_GOVERNANCE_LOGTYPE',
	SET_GOVERNANCE_ENGINE_RDS: 'SET_GOVERNANCE_ENGINE_RDS',
	SET_GOVERNANCE_PROPS_DETAILS: 'SET_GOVERNANCE_PROPS_DETAILS',

	SET_LIST_OPERATING_SYSTEM: 'SET_LIST_OPERATING_SYSTEM',
	
	SET_ASSETS_BY_TAG_OPERATIONS: 'SET_ASSETS_BY_TAG_OPERATIONS',
	SET_NON_COMPLAINT_ASSETS: 'SET_NON_COMPLAINT_ASSETS',
	SET_TAG_GROUPS: 'SET_TAG_GROUPS',
	SET_TAG_GROUPS_SELECTED_TABLE_ROW: 'SET_TAG_GROUPS_SELECTED_TABLE_ROW',
	SET_TAG_COMPLIANCE_AGGREGATION_COUNT: 'SET_TAG_COMPLIANCE_AGGREGATION_COUNT',
	SET_TAG_COMPLIANCE_TOTAL_COUNT: 'SET_TAG_COMPLIANCE_TOTAL_COUNT',
	SET_TAG_OPERATION_COUNT: 'SET_TAG_OPERATION_COUNT',
	SET_APPLIED_TAGS_DAYWISE_COUNT: 'SET_APPLIED_TAGS_DAYWISE_COUNT',
	SET_ASSETS_LIST: 'SET_ASSETS_LIST',
	SET_ADVANCED_ASSETS_LIST: 'SET_ADVANCED_ASSETS_LIST',
	SET_RULES_LIST: 'SET_RULES_LIST',
	
	SET_NEW_RULES_LIST: 'SET_NEW_RULES_LIST',
	SET_NEW_EDIT_RULE_DETAILS: 'SET_NEW_EDIT_RULE_DETAILS',
	
	SET_POLICY_LIST: 'SET_POLICY_LIST',
	SET_POLICY_SELECTED_TABLE_ROW: 'SET_POLICY_SELECTED_TABLE_ROW',
	SET_IAM_USERS_ASSETS_LIST: 'SET_IAM_USERS_ASSETS_LIST',
	SET_IAM_EVENTS_CONDITIONS: 'SET_IAM_EVENTS_CONDITIONS',
	SET_IAM_ROLES_ASSETS_LIST: 'SET_IAM_ROLES_ASSETS_LIST',
	SET_CREATE_POLICY_DETAILS: 'SET_CREATE_POLICY_DETAILS',
	SET_EDIT_CATEGORY_SELECTED: 'SET_EDIT_CATEGORY_SELECTED',
	SET_CREATE_POLICY_DETAILS_EMPTY: 'SET_CREATE_POLICY_DETAILS_EMPTY',
	SET_IAM_EVENTS_CONDITIONS_EMPTY: 'SET_IAM_EVENTS_CONDITIONS_EMPTY',
	
	SET_ASSET_DETAILS: 'SET_ASSET_DETAILS',
	SET_ASSET_NETWORK_DETAILS: 'SET_ASSET_NETWORK_DETAILS',
	SET_ASSET_STORAGE_DETAILS: 'SET_ASSET_STORAGE_DETAILS',
	SET_ASSET_SG_DETAILS: 'SET_ASSET_SG_DETAILS',
	SET_IDENTIFIERS_LIST: 'SET_IDENTIFIERS_LIST',
	SET_FILTERED_IDENTIFIERS_LIST: 'SET_FILTERED_IDENTIFIERS_LIST',
	SET_DETECTORS_LIST: 'SET_DETECTORS_LIST',
	SET_CONFIGURATION_LIST: 'SET_CONFIGURATION_LIST',
	SET_ALL_TAGS: 'SET_ALL_TAGS',
	SET_ALL_MASTER_TAG_GROUPS: 'SET_ALL_MASTER_TAG_GROUPS',
	SET_TAG_GROUP_ID: 'SET_TAG_GROUP_ID',
	SET_ASSET_TAG_GROUP_CONDITION_ID: 'SET_ASSET_TAG_GROUP_CONDITION_ID',
	SET_ASSET_TAG_GROUP_CONDITIONS: 'SET_ASSET_TAG_GROUP_CONDITIONS',
	SET_TO_INITIAL_CREATE: 'SET_TO_INITIAL_CREATE',
	
	//observability query start
	SET_OBSERVABILITY_QUERY_RESULT: 'SET_OBSERVABILITY_QUERY_RESULT',
	SET_OBSERVABILITY_QUERY_LIST: 'SET_OBSERVABILITY_QUERY_LIST',
	//observability query end
	
	
	//cost start
	SET_COST_PROPS_DETAILS: 'SET_COST_PROPS_DETAILS',
	SET_COST_TAG_KEYS: 'SET_COST_TAG_KEYS',
	SET_LIST_DEPARTMENT: 'SET_LIST_DEPARTMENT',
	SET_LIST_DEPARTMENTS_INITIATIVES_PROJECTS_ACCOUNTS: 'SET_LIST_DEPARTMENTS_INITIATIVES_PROJECTS_ACCOUNTS',
	SET_CLOUD_COMMITMENT_VS_SPEND: 'SET_CLOUD_COMMITMENT_VS_SPEND',
	SET_CURRENT_CLOUD_COMMITMENT_VS_SPEND_OBSERVATIONS: 'SET_CURRENT_CLOUD_COMMITMENT_VS_SPEND_OBSERVATIONS',
	SET_GET_OBSERVATIONS: 'SET_GET_OBSERVATIONS',
	SET_COST_QUERY_RESULT: 'SET_COST_QUERY_RESULT',
	//cost end

	SET_USERS_LIST: 'SET_USERS_LIST',
	SET_HPC_PROPS_DETAILS: 'SET_HPC_PROPS_DETAILS',
	SET_COMMON_PROPS_DETAILS: 'SET_COMMON_PROPS_DETAILS'
	
}
