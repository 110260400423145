import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {Icon} from "@iconify/react";

import moment from 'moment';
// import _ from 'lodash'

import { currentUTCtime, momentDateGivenFormat } from '../../../utils/utility'
import { COST_DURATION_OPTIONS } from '../../../utils/constants'
import { listUserDetails, listClusterFilters } from '../../../actions/commonActionNew';
import { listQueues, listClusterDetails, listQueueDetails, listProjectDetails, listUsersByCluster } from '../../../actions/Collider/ClusterAction';
import ApexBarChart from '../../common/charts/ApexBarChart'
import ApexMixedBarLineChart from '../../common/charts/ApexMixedBarLineChart';
import ApexStackedBarChart from '../../common/charts/ApexStackedBarChart';
import { CSVLink } from 'react-csv';
import PageLoading from '../../common/PageLoading';
import SelectOption from '../../common/Form/SelectOption';
import { LoadingCircle } from '../../common/LoadingCiricle';


const CostReportSection = (props) => {
    const clickOut = useRef();
    const durationRef = useRef();
    const tableRef = useRef();
    const csvLinkRef = useRef();
    const csvLinkCreditRef = useRef();

    const [state, setState] = useState({
        showLoading: true,
        typeOptions: ['User', 'Location', 'Software'],
        selectedDuration: 'lastMonth',
        periodOptions: [
            {label: "Daily", value: "daily"},
            {label: "Weekly", value: "weekly"},
			{label: "Monthly", value: "monthly"},
		],
        selectedPeriod: "monthly",
        colors: ['#7052BC', '#AA2217', '#57AF3E', '#2D60A6', '#FC6D22', '#CF9124' ]
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel()
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown])

    useEffect(() => {
        const currentMonth = new Date().getMonth(); // Month is zero-based (0 for January, 1 for February, ..., 11 for December)

       	let datePickerStartDate = ""
        let datePickerEndDate = ""
        // let today = new Date();
        // let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "currentYear") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "lastMonth") {
            datePickerStartDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-01 00:00:00')            
            datePickerEndDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "quarterly") {
            if (currentMonth >= 0 && currentMonth <= 2) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-03-31 23:59:59')
            } else if (currentMonth > 2 && currentMonth < 6) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-04-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else if (currentMonth > 5 && currentMonth < 9) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-09-30 23:59:59')
            } else {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-10-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "half-yearly") {
            if (currentMonth >= 0 && currentMonth <= 5) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "yearly") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }
        if(state.selectedDuration !== 'custom') {
            setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));

            // let dateSelection= {
			// 	startDate: new Date(datePickerStartDate),
			// 	endDate: new Date(datePickerEndDate),
			// 	key: 'selection'
			// }

			// setDateState([dateSelection])
        }

	}, [state.selectedDuration, props])

    useEffect(() => {
        if(state.callClusterList) {
            setState(prevState => ({ ...prevState, callClusterList: false }));
            let params = {};
            params.provider = props.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response, callQueueList: true }));
                    }
            })
        }
    }, [dispatch, props.selectedProvider, state.selectedAccount, state.selectedRegion, state.callClusterList])

    useEffect(() => {
        if (state.callQueueList) {
            setState(prevState => ({ ...prevState, callQueueList: false }));
            if(props.selectedReport.category && props.selectedReport.category.includes('Queue')) {
                let params = {};
                params.provider = props.selectedProvider.toLowerCase();
                
                if(state.selectedAccount && state.selectedAccount.length) {
                    params.account_id = state.selectedAccount
                }
                if(state.selectedRegion && state.selectedRegion.length) {
                    params.region = state.selectedRegion
                }
                if(state.selectedClusters) {
                    params.cluster_name = state.selectedClusters
                }
                dispatch(listQueues(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({ ...prevState, queues: response }));
                        }
                })
            }
        }
    }, [dispatch, state.callQueueList, props.selectedReport.category, props.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters])

    useEffect(() => {
        let selectedAccount = props.selectedAccount
        let selectedRegion = props.selectedRegion
        let selectedClusters = props.selectedClusters
        let selectedQueue = props.selectedQueue
        let datePickerStartDate = props.datePickerStartDate
        let datePickerEndDate = props.datePickerEndDate
        let selectedUser = ''
        let selectedProject = ''

        if(props.selectedReportInput) {
            if(props.selectedReportInput && props.selectedReportInput.account_id && props.selectedReportInput.account_id.length) {
                selectedAccount = props.selectedReportInput.account_id
            }

            if(props.selectedReportInput && props.selectedReportInput.region && props.selectedReportInput.region.length) {
                selectedRegion = props.selectedReportInput.region
            }
            
            if(props.selectedReportInput && props.selectedReportInput.cluster_name) {
                selectedClusters = props.selectedReportInput.cluster_name
            }
            
            if(props.selectedReportInput && props.selectedReportInput.queue_name) {
                selectedQueue = props.selectedReportInput.queue_name
            }
            
            if(props.selectedReportInput && props.selectedReportInput.project) {
                selectedProject = props.selectedReportInput.project
            }
            
            if(props.selectedReportInput && props.selectedReportInput.user_name) {
                selectedUser = props.selectedReportInput.user_name
            }
        }
        setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate, selectedAccount, selectedRegion, selectedClusters, selectedQueue, selectedUser, selectedProject, showLoading: true, callClusterList: true, callHpcReports: true }))
        
    }, [props])

    useEffect(() => {
        let params = {}
        params.provider = props.selectedProvider.toLowerCase()
        if(state.selectedAccount && state.selectedAccount.length) {
            params.account_id = state.selectedAccount
        }
        if(state.selectedRegion && state.selectedRegion.length) {
            params.region = state.selectedRegion
        }
        if(state.selectedClusters) {
            params.cluster_name = state.selectedClusters
        }

        dispatch(listUsersByCluster(params))
            .then((response) => {
                if(response && !response.error) {
                    setState(prevState => ({ ...prevState, clusterUserList: response, filterClusterUserList: response }));
                }
            })
    }, [dispatch, props.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters])
    
    useEffect(() => {
        if(state.callHpcReports) {
            setState(prevState => ({ ...prevState, callHpcReports: false, budgetSpendData: {}, budgetSpendBarLineGraphData: {}, spendStackedTrendData: {}, trendTable: [], showLoading: true }))
            
            let params = {}
            // params.report_id = props.selectedReport.report_id
            params.provider = props.selectedProvider.toLowerCase()
            params.spendboard = true
            params.start_time = state.datePickerStartDate
            params.end_time = state.datePickerEndDate
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedPeriod) {
                params.duration_aggregate_by = state.selectedPeriod
            }
            params.credits = true
            if(props.selectedReport.category && !props.selectedReport.category.includes('Project')) {
                if(state.selectedClusters) {
                    params.cluster_name = state.selectedClusters
                }
                
                if(state.selectedQueue) {
                    params.queue_name = state.selectedQueue
                }
            }
            
            if(props.selectedReport.category && props.selectedReport.category.includes('User')) {
                if(state.selectedUser) {
                    params.requested_user_id = state.clusterUserList.filter(e => e.userId === state.selectedUser)[0].userName
                }
            } 
            
            if(props.selectedReport.category && props.selectedReport.category.includes('Project')) {
                if(state.selectedProject) {
                    params.project = state.selectedProject
                }
            }

            if(state.selectedClusters && props.selectedReport.category && props.selectedReport.category.includes('Cluster')) {
                dispatch(listClusterDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []
                            
                            setState(prevState => ({ ...prevState, responseDetails: results, showLoading: false }));
                        }
                    })
            } else if(state.selectedQueue && props.selectedReport.category && props.selectedReport.category.includes('Queue')) {
                dispatch(listQueueDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []
                            
                            setState(prevState => ({ ...prevState, responseDetails: results, showLoading: false }));
                        }
                    })
            } else if(state.selectedUser && props.selectedReport.category && props.selectedReport.category.includes('User')) {
                dispatch(listUserDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []
                            
                            setState(prevState => ({ ...prevState, responseDetails: results, showLoading: false }));
                        }
                    })
            } else if(state.selectedProject && props.selectedReport.category && props.selectedReport.category.includes('Project')) {
                dispatch(listProjectDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []
                            
                            setState(prevState => ({ ...prevState, responseDetails: results, showLoading: false }));
                        }
                    })
            } else {
                setState(prevState => ({ ...prevState, showLoading: false }))
            }
        }
    }, [dispatch, state.callHpcReports, props.selectedProvider, props.selectedReport.category, props.selectedReport.report_id, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedClusters, state.selectedPeriod, state.selectedProject, state.selectedQueue, state.selectedRegion, state.selectedUser])

    useEffect(() => {
        state.responseDetails && state.responseDetails.forEach(item => {
            if(item.trend) {
                let budgetSpendTrendData = item.trend ? item.trend : []
                //horizontal spend and budget bar
                let data = [budgetSpendTrendData && budgetSpendTrendData.budget_total ? budgetSpendTrendData.budget_total : 0, budgetSpendTrendData && budgetSpendTrendData.spend_total ? budgetSpendTrendData.spend_total : 0]
                let labels = ['Budget', 'Spend']
                let dataUnit = ''
            
                let horiBarData = {}
                horiBarData.data = data
                horiBarData.labels = labels        
                horiBarData.unit = dataUnit                
                
                let graphData = {}
                graphData.labels = item.dates ? item.dates : []
                graphData['Budget'] = budgetSpendTrendData.budget
                graphData['Spend'] = budgetSpendTrendData.spend
                
                setState(prevState => ({ ...prevState, budgetSpendData: horiBarData, budgetSpendBarLineGraphData: graphData, budgetSpendTrendData }))
            }
        })

    }, [state.responseDetails])

    useEffect(() => {
        state.responseDetails && state.responseDetails.forEach((item, index) => {
            if(item.breakdown) {
                let spendStackedTrend = item.breakdown
                let graphData = {}
                graphData.labels = item.dates ? item.dates : []
        
                Object.entries(spendStackedTrend).length && spendStackedTrend.spend && Object.entries(spendStackedTrend.spend).forEach(([key, value]) => {
                    graphData[key] = value
                })
                
                setState(prevState => ({ ...prevState, spendStackedTrendData: graphData }))
            }
        })
    }, [state.responseDetails])

    useEffect(() => {
        if(state.responseDetails) {
            var trendTable = []
            var creditTable = []
            state.responseDetails.forEach((item, index) => {

                let budgetSpendTrendData = item.data ? item.data : []

                let jobsData = {}
                jobsData.labels = item.dates ? item.dates : []
                jobsData.total = budgetSpendTrendData.hasOwnProperty('jobs') ? budgetSpendTrendData['jobs'] : []
                jobsData.success = budgetSpendTrendData.hasOwnProperty('success') ? budgetSpendTrendData['success'] : []
                jobsData.failed = budgetSpendTrendData.hasOwnProperty('failed') ? budgetSpendTrendData['failed'] : []
                
                let nodesData = {}
                nodesData.labels = item.dates ? item.dates : []
                nodesData.nodes = budgetSpendTrendData.hasOwnProperty('nodes') ? budgetSpendTrendData['nodes'] : []

                let vcpusData = {}
                vcpusData.labels = item.dates ? item.dates : []
                vcpusData.vxpus = budgetSpendTrendData.hasOwnProperty('cpus') ? budgetSpendTrendData['cpus'] : []

                let cpuHours = {}
                cpuHours.labels = item.dates ? item.dates : []
                cpuHours.cpu_hours = budgetSpendTrendData.hasOwnProperty('cpu_hours') ? budgetSpendTrendData['cpu_hours'] : []

                let nodeHours = {}
                nodeHours.labels = item.dates ? item.dates : []
                nodeHours.node_hours = budgetSpendTrendData.hasOwnProperty('node_hours') ? budgetSpendTrendData['node_hours'] : []

                setState(prevState => ({ 
                    ...prevState, 
                    ["nodes_"+state.selectedItemIndex]: nodesData,
                    ["cpus_"+state.selectedItemIndex]: vcpusData,
                    ["cpu_hours_"+state.selectedItemIndex]: cpuHours,
                    ["node_hours_"+state.selectedItemIndex]: nodeHours,
                    ["jobs_"+state.selectedItemIndex]: jobsData,                        
                }))

                let labels = item.dates ? item.dates : []
                if(item.breakdown && item.breakdown.spend && Object.entries(item.breakdown.spend).length) {
                    Object.entries(item.breakdown.spend).forEach(([key, value]) => {
                        var result = {
                            "Title": key,
                            'total': item.breakdown.spend_total && item.breakdown.spend_total[key] ? item.breakdown.spend_total[key] : 0
                        }
                        
                        value.forEach((vItem, vIndex) => {
                            result[labels[vIndex]] = vItem
                        })
                        trendTable.push(result);
                    })
                } else if(item.data && Array.isArray(item.data)) {

                    let data = item.data
                    // let totalCount = item.data.reduce(function(a, b){
                    // 	return a + b;
                    // }, 0);
                    var result = {
                        "Title": "Cost",
                        'total': item.total
                    }
                    
                    data.forEach((item,index) => {
                        if(momentDateGivenFormat(labels[index], "YYYY-MM-DD") <= momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                            result[labels[index]] = item
                        }
                    })
                    
                    if(item.forecast && item.forecast.dates) {
                        item.forecast && item.forecast.dates.forEach((lab, index) => {					
                            result[lab] = item.forecast.data[index]
                        })
                    }

                    trendTable.push(result);
                }
                
                if(trendTable.length) {
                    let clusterHeaders = ['Title']
                    const columns = Object.entries(trendTable[0]).map(([tKey, tValue]) =>{
                        let sticky = false
                        let header = ''
                        let width = 100
                        let textColor = ''
                        if(tKey === 'Title') {
                            sticky = true
                            header = tKey
                            width = 300
                        } else if(tKey === 'total') {
                            sticky = true
                            header = 'Total'
                        } else {
                            if(momentDateGivenFormat(tKey, "YYYY-MM-DD") > momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                                textColor = "text-info"
                            }
                            // if(state.granualrity === 'monthly') {
                            //     header = moment(tKey).format('MMM YY')
                            //     width = 75
                            // } else  if(state.granualrity === 'daily') {
                            //     header = moment(tKey).format('MMM DD')
                            //     width = 75
                            // } else  {
                            header = moment(tKey).format('MMM DD')
                            clusterHeaders.push(tKey)
                            // width = 300
                            // }
                        }
                        return {
                            Header: header,
                            accessor: tKey,
                            width: width,
                            sticky: sticky ? "left" : "",
                            textColor: textColor,
                            className: 'text-truncate text-right'
                        }
                    })
                    
                    setState(prevState => ({ ...prevState, trendTable, filteredArray: trendTable, columns, clusterHeaders }))
                } else {
                    const columns = []	
                    setState(prevState => ({ ...prevState, trendTable, filteredArray: trendTable, columns }))
                }

                if(item.credits && Object.entries(item.credits).length) {
                    var creditResult = {}
                    let creditLabels  = item.credits && item.credits.dates ? item.credits.dates : []

                    if(item.credits && item.credits.spend) {
                        creditResult = {
                            "Title": 'Spend',
                        }
                        
                        item.credits.spend.forEach((vItem, vIndex) => {
                            creditResult[creditLabels[vIndex]] = vItem
                        })
                        creditTable.push(creditResult);
                    }

                    if(item.credits && item.credits.spend) {
                        creditResult = {
                            "Title": 'Forecast',
                        }
                        
                        item.credits.forecast.forEach((vItem, vIndex) => {
                            creditResult[creditLabels[vIndex]] = vItem
                        })
                        creditTable.push(creditResult);
                    }

                    if(item.credits && item.credits.credits) {
                        creditResult = {
                            "Title": 'Credits',
                        }
                        
                        item.credits.forecast.forEach((vItem, vIndex) => {
                            creditResult[creditLabels[vIndex]] = vItem
                        })
                        creditTable.push(creditResult);
                    }

                    if(item.credits && item.credits.total) {
                        creditResult = {
                            "Title": 'Total',
                        }
                        
                        item.credits.forecast.forEach((vItem, vIndex) => {
                            creditResult[creditLabels[vIndex]] = vItem
                        })
                        creditTable.push(creditResult);
                    }

                    if(creditTable.length) {
                        let creditHeaders = ['Title']
                        const creditColumns = Object.entries(creditTable[0]).map(([tKey, tValue]) =>{
                            let sticky = false
                            let header = ''
                            let width = 100
                            let textColor = ''
                            if(tKey === 'Title') {
                                sticky = true
                                header = tKey
                                width = 300
                            } else {
                                if(momentDateGivenFormat(tKey, "YYYY-MM-DD") > momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                                    textColor = "text-info"
                                }
                                header = moment(tKey).format('MMM DD')
                                creditHeaders.push(tKey)
                            }
                            return {
                                Header: header,
                                accessor: tKey,
                                width: width,
                                sticky: sticky ? "left" : "",
                                textColor: textColor,
                                className: 'text-truncate text-right'
                            }
                        })
                        
                        setState(prevState => ({ ...prevState, creditTable, filteredCreditArray: creditTable, creditColumns, creditHeaders }))
                    }

                } else {
                    setState(prevState => ({ ...prevState, creditTable, filteredCreditArray: creditTable }))
                }
            })
            
        }
    }, [state.responseDetails, state.selectedItemIndex])

    const downloadPDF = async (state) => {
        const chartContainer1 = document.getElementById('chartContainer1');
        const chartContainer2 = document.getElementById('chartContainer2');
        const chartContainer3 = document.getElementById('chartContainer3');
        // const table = document.getElementById('table');
        const table = tableRef.current;

        if (csvLinkRef.current) {
            csvLinkRef.current.link.click(); // Programmatically trigger the download
        }

        if (csvLinkCreditRef.current) {
            csvLinkCreditRef.current.link.click(); // Programmatically trigger the download
        }
    
        if (!chartContainer1) {
          console.error("Chart container not found.");
          return;
        }
    
        try {
            const canvas1 = await html2canvas(chartContainer1);
            const canvas2 = await html2canvas(chartContainer2);
            const canvas3 = chartContainer3 ? await html2canvas(chartContainer3) : ''
            
            const tableData = await html2canvas(table)
            // const pdf = new jsPDF();
            const pdf = new jsPDF('p', 'mm', 'a4');
            
            let selectedPeriod = 'for the period off '+ (momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY')+ ' '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY'))
            
            let account = state.selectedAccount && state.selectedAccount.length ? 'with the account '+ (state.selectedAccount.join(", ")) : ''
            let region = state.selectedRegion && state.selectedRegion.length ? 'in the region '+ (state.selectedRegion.join(", ")) : ''
            let cluster = state.selectedClusters ? state.selectedClusters : ''
            if(state.selectedClusters) {
                cluster = 'in the cluster '+ state.selectedClusters
            }
            const textBoxWidth = 150;
            // const textBoxHeight = 50;
            const fontSize = 14;
            const lineHeight = 8;

            let longText = `Showing ${props.selectedReport && props.selectedReport.report_id ? props.selectedReport.report_id : ''} report obtained from the ${props.selectedProvider} ${account} ${region} ${cluster} ${selectedPeriod}`

            let words = longText.split(' ');
            let text = '';
            let y = 8; // Starting y-coordinate for text
            for (const word of words) {
                const textWithWord = text + word + ' ';
                const textWidth = pdf.getStringUnitWidth(textWithWord) * fontSize / pdf.internal.scaleFactor;
                if (textWidth > textBoxWidth) {
                    pdf.text(text, 10, y); // Draw the text
                    text = word + ' '; // Start a new line
                    y += lineHeight; // Move to the next line
                } else {
                    text = textWithWord; // Continue current line
                }
            }

            // Draw the remaining text
            pdf.text(text, 10, y); 
            // pdf.text(`${selectedPeriod} report for the user ${item.userName}`, 10, 10) 
            // pdf.text(`spanning from ${momentDateGivenFormat(props.startDate, 'DD MMM YYYY')} to ${momentDateGivenFormat(props.endDate, 'DD MMM YYYY')}`, 10, 20)
            // pdf.text(`Report for User - ${item.userName}`, 15, 10);
            // pdf.text(`Period from - ${momentConvertionUtcToLocalTime(props.startDate, 'DD MMM YYYY') + ' to '+momentConvertionUtcToLocalTime(props.endDate, 'DD MMM YYYY') }`, 15, 10);
            // pdf.text(`Frequency from - ${momentConvertionUtcToLocalTime(props.startDate, 'DD MMM YYYY') + ' to '+momentConvertionUtcToLocalTime(props.endDate, 'DD MMM YYYY') }`, 15, 10);
            let startHeight = y+8
            pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 5, startHeight, 65, 60);
            pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 75, startHeight, 65, 60);
            pdf.addImage(canvas3.toDataURL('image/png'), 'PNG', 140, startHeight, 65, 60);

            // pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 5, startHeight, 100, 60);
            // pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 105, startHeight, 100, 60);
            // pdf.addImage(canvas3.toDataURL('image/png'), 'PNG', 5, startHeight+100, 150, 60);
            // pdf.addImage(tableData.toDataURL('image/png'), 'PNG', 5, 100, 200, 80);

            const pageHeight = 295; // A4 page height in mm
            const pageWidth = 210; // A4 page width in mm
            const imgHeight = (100 + tableData.height) * pageWidth / tableData.width;
            let heightLeft = imgHeight;
        
            const pdfPageCount = Math.ceil(heightLeft / pageHeight);
            
            for (let page = 1; page <= pdfPageCount; page++) {
                // Get the height of the content for the current page
                const yStart = (page - 1) * pageHeight;
                const height = Math.min(heightLeft, pageHeight);
                heightLeft -= height;
          
                // Add a new page to the PDF
                pdf.addPage();
          
                // Draw the content on the current page
                pdf.addImage(tableData, 'image/png', 0, yStart, pageWidth, height, undefined, 'FAST');
            }

            // html2canvas(table).then(tab => {
            //     const imgData = tab.toDataURL('image/png');
            //     const pdf = new jsPDF('p', 'mm', 'a4');
            //     pdf.addImage(imgData, 'PNG', 10, 10, 190, 100);
            // });
        
            const timestamp = new Date().getTime();
            pdf.save(`report_${timestamp}.pdf`);

            setState(prevState => ({ ...prevState, showDownloadLoading: false }))

        } catch (error) {
          console.error("Error capturing charts:", error);
          setState(prevState => ({ ...prevState, showDownloadLoading: false }))
        }
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            props.closeSidePanel()
        }
        
		if (durationRef.current && !durationRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-4/6 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-white'>Reports</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-white cursor-pointer self-center' width={24} height={24} onClick={() => props.closeSidePanel()} />
                            </div>
                        </div>
                        <div className='w-full text-sm pt-2'>
                            <div className='flex flex-wrap'>
                                <p className='mb-1 text-NeutralGray-400 pr-4'>Id: <span className='text-white font-bold'>{props.selectedReport && props.selectedReport.report_id ? props.selectedReport.report_id : ''}</span></p>
                                <div className="pl-4">
                                    <p className='mb-1 text-NeutralGray-400 pr-4'>Description: <span className='text-white font-bold'>{props.selectedReport && props.selectedReport.title ? props.selectedReport.title : ''}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-8 overflow-y-auto'>
                        <p className="flex flex-wrap text-sm leading-7">
                            {props && props.selectedProvider ?
                                <span className="self-end">Showing report obtained from the <span className="font-bold"> {props.selectedProvider.toUpperCase()} </span> provider with the account</span>
                            : null}
                            <div className='w-32 self-end mx-1'>
                                <SelectOption
                                    label={""}
                                    fields={["account_id", "account_name"]}
                                    options={propProjAccounts[props.selectedProvider] ? propProjAccounts[props.selectedProvider] : []}
                                    selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedAccount: value, callClusterList: true }))}
                                    singleSelection={false}
                                    manditory={false}
                                    dropdownWidth={'min-w-32'}
                                    inputType={state.pageType}
                                    classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                />
                            </div>
                            <span className='ml-1 self-end mr-2'>in the</span>
                            <div className='w-32 self-end mx-1'>
                                <SelectOption
                                    label={""}
                                    fields={["region", "name"]}
                                    options={propProjRegions[props.selectedProvider] ? propProjRegions[props.selectedProvider] : []}
                                    selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value, callClusterList: true }))}
                                    singleSelection={false}
                                    manditory={false}
                                    dropdownWidth={'min-w-32'}
                                    inputType={state.pageType}
                                    classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                />
                            </div>
                            <span className='ml-1 self-end mr-2'>region</span>
                            <div className='w-32 self-end mx-1'>
                                <SelectOption
                                    label={""}
                                    options={state.clusters}
                                    selectedValues={state.selectedClusters}
                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, 
                                        selectedClusters: value, 
                                        selectedQueue: '', 
                                        callQueueList: props.selectedReport.category && !props.selectedReport.category.includes('Project') && !props.selectedReport.category.includes('User') ? true : false
                                    }))}
                                    singleSelection={props.selectedReport.category && !props.selectedReport.category.includes('Project') && !props.selectedReport.category.includes('User') ? true : false}
                                    manditory={false}
                                    dropdownWidth={'min-w-32'}
                                    inputType={state.pageType}
                                    classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                />
                            </div>
                            <span className='ml-1 self-end mr-2'> cluster</span>
                            {props.selectedReport.category && props.selectedReport.category.includes('Queue') ? 
                                <React.Fragment>
                                <div className='w-32 self-end mx-1'>
                                    <SelectOption
                                        label={""}
                                        options={state.queues}
                                        selectedValues={state.selectedQueue}
                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedQueue: value }))}
                                        singleSelection={true}
                                        manditory={false}
                                        dropdownWidth={'min-w-32'}
                                        inputType={state.pageType}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                    />
                                </div>
                                <span className='ml-1 self-end mr-2'> queue</span>
                                </React.Fragment>
                            : null}
                            {props.selectedReport && props.selectedReport.report_type && props.selectedReport.report_type === 'spendboard' && (props.selectedReport.category && props.selectedReport.category.includes('User')) ?
                                <React.Fragment>
                                <span className='ml-1 self-end mr-2'> by the user</span>
                                <div className='w-32 self-end mx-1'>
                                    <SelectOption
                                        label={""}
                                        fields={["userId", "shortName"]}
                                        options={state.clusterUserList}
                                        selectedValues={state.selectedUser ? state.selectedUser : []}
                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedUser: value }))}
                                        singleSelection={true}
                                        manditory={false}
                                        dropdownWidth={'min-w-32'}
                                        inputType={state.pageType}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                    />
                                </div>
                                </React.Fragment>
                            : null}
                            {props.selectedReport && props.selectedReport.report_type && props.selectedReport.report_type === 'spendboard' && (props.selectedReport.category && props.selectedReport.category.includes('Project')) ?
                                <React.Fragment>
                                <span className='ml-1 self-end mr-2'> for the project</span>
                                <div className='w-32 self-end mx-1'>
                                    <SelectOption
                                        label={""}
                                        fields={["project", "project"]}
                                        options={propProjects[props.selectedProvider]}
                                        selectedValues={state.selectedProject ? state.selectedProject : ''}
                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedProject: value }))}
                                        singleSelection={true}
                                        manditory={false}
                                        dropdownWidth={'min-w-32'}
                                        inputType={state.pageType}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                    />
                                </div>
                                </React.Fragment>
                            : null}
                            <span className='ml-1 self-end mr-2'>for the duration</span>
                            <div className='w-32 self-end mx-1'>
                                <SelectOption
                                    label={""}
                                    fields={["value", "label"]}
                                    options={COST_DURATION_OPTIONS}
                                    selectedValues={state.selectedDuration ? state.selectedDuration : ''}
                                    callbackMultiSelect={(value) => {
                                        setState(prevState => ({ ...prevState, selectedDuration: value }))
                                    }}
                                    singleSelection={true}
                                    manditory={false}
                                    dropdownWidth={'min-w-32'}
                                    inputType={state.pageType}
                                    classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                />
                            </div>
                            <p className="bg-pumpkin-600 rounded-full px-2 min-w-28 text-center self-end text-white ml-3 py-1 break-all mt-2" onClick={() => {
                                if(!state.showLoading) {
                                    setState(prevState => ({ ...prevState, searchInput: true, callHpcReports: true, showLoading: true }))}
                                }
                            }>Search</p>
                        </p>

                        {state.showLoading ?
                            <div className='flex justify-center m-4'>
                                <LoadingCircle />
                            </div>
                        :
                            <React.Fragment>
                            <div className='w-full flex justify-end mb-2'>
                                <div className='w-32 self-end mx-1'>
                                    <SelectOption
                                        label={""}
                                        fields={["value", "label"]}
                                        options={state.periodOptions ? state.periodOptions : []}
                                        selectedValues={state.selectedPeriod ? state.selectedPeriod : ''}
                                        callbackMultiSelect={(value) => setState((prevState) => ({ ...prevState, selectedPeriod: value, showLoading: true, callHpcReports: true }))}
                                        singleSelection={true}
                                        manditory={false}
                                        dropdownWidth={'min-w-32'}
                                        inputType={state.pageType}
                                        classDetails={{border: "border-b border-NeutralGray-400", padding: "py-1 px-2", bg: " ", rounded: " "}}
                                    />
                                </div>
                                <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center' onClick={() => {
                                    if(!state.showDownloadLoading) {
                                        setState(prevState => ({ ...prevState, showDownloadLoading: true })) 
                                        downloadPDF(state)
                                    }
                                }}/>
                                    {state.showDownloadLoading ?
                                        <LoadingCircle 
                                            color={"text-white"}
                                        />
                                    : null}
                            </div>
                            
                            <div className='flex flex-wrap'>
                                <div className={`lg:w-1/3 md:w-1/2 w-full pr-2`} id={'chartContainer1'}>
                                    <div className='bg-NeutralGray-100 border border-NeutralGray-500 rounded-2xl p-4'>
                                        {state.budgetSpendData && Object.entries(state.budgetSpendData).length ?
                                            <div className='-mt-2 -mb-2 mr-4 self-center'>
                                                <ApexBarChart
                                                    graphData={state.budgetSpendData}
                                                    sparkline={false}
                                                    yaxis={true}
                                                    yaxisLabel={true}
                                                    xaxis={false}
                                                    xaxisFormat={'string'}
                                                    xaxisLabel={false}
                                                    axisLabelColor={'#495057'}
                                                    paddingLeft={0}
                                                    legend={false}
                                                    legendPostion={'bottom'}
                                                    legendHorizontalAlign={'center'}
                                                    legendUseSeriesColor={true}
                                                    stacked={false}
                                                    height={200}
                                                    horizontal={true}
                                                    barHeight={'40%'}
                                                    barEndShape={'rounded'}
                                                    columnWidth={'25%'}
                                                    gradient={false}
                                                    gradientColor={['#009fdf', '#CD5A9F']}
                                                    hideTooltipValue={true}
                                                    backgroundBarShape={'rounded'}
                                                    backgroundBarColors={['#333947']}
                                                    showBackgroundBarColors={false}
                                                    className={'transparentTooltip'}
                                                    dataLabels= {true}
                                                    dataLabelsTextAnchor = {'end'}
                                                    dataLabelPosition={'top'}
                                                    colors={['#57AF3E', '#3576BE']}
                                                    dataLabelsOffsetX={0}
                                                    chartDistributed={true}
                                                />
                                                <p className={`flex justify-center self-center`}>
                                                    {state.responseDetails && state.responseDetails.length ?
                                                        <span className={`${state.responseDetails[0].underutilized ? 'text-green' : (state.responseDetails[0].overutilized ? 'text-red' : '')})`}>
                                                            <span className='mr-2'>{state.responseDetails[0].underutilized ? 'Underutilized' : (state.responseDetails[0].overutilized ? 'Overutilized' : '')}</span>    
                                                            {state.responseDetails[0].underutilized ? state.responseDetails[0].underutilized : (state.responseDetails[0].overutilized ? state.responseDetails[0].overutilized : '')}
                                                        </span>
                                                    : null}
                                                </p>
                                                {/* <p className={`flex justify-center self-center ${state.budgetSpendTrendData && Math.round(100 - (state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100) > 0 ? 'text-green' : 'text-red'}`}>
                                                    <span className='ml-1 text-md self-center'>{state.budgetSpendTrendData && Math.round(100 - (state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100) > 0 ? 'Underutilized' : 'Overutilized'}</span>
                                                    {state.budgetSpendTrendData && Math.round(100 - (state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100) > 0 ?
                                                        <span className='ml-1 text-md self-center'>{state.budgetSpendTrendData && Math.round(100 - (state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100)}%</span>
                                                    : 
                                                        <span className='ml-1 text-md self-center'>{state.budgetSpendTrendData && Math.round((state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100)-100}%</span>
                                                    }
                                                </p> */}
                                            </div>
                                        : null}
                                    </div>
                                </div>

                                <div className={`lg:w-1/3 md:w-1/2 w-full lg:pl-2 md:pl-2 pl-0 pr-2`} id={'chartContainer2'}>
                                    <div className='bg-NeutralGray-100 border border-NeutralGray-500 rounded-2xl p-4'>
                                        {/* <span className='mt-1 mb-0 small text-lightGray'>{state.graphData2.description ? state.graphData2.description :''}</span> */}
                                        {state.budgetSpendBarLineGraphData && Object.entries(state.budgetSpendBarLineGraphData).length ?
                                            <div className='mb-n3'>
                                                <ApexMixedBarLineChart
                                                    graphData={state.budgetSpendBarLineGraphData}
                                                    sparkline={false}
                                                    yaxis={false}
                                                    yaxisLabel={false}
                                                    xaxis={true}
                                                    xaxisFormat={'datetime'}
                                                    xaxisLabel={true}
                                                    axisLabelColor={'#666666'}
                                                    paddingLeft={10}
                                                    legend={true}
                                                    legendPostion={'bottom'}
                                                    legendHorizontalAlign={'center'}
                                                    legendUseSeriesColor={true}
                                                    stacked={false}
                                                    height={200}
                                                    horizontal={false}
                                                    barHeight={'40%'}
                                                    barEndShape={'flat'}
                                                    columnWidth={'25%'}
                                                    gradient={true}
                                                    gradientColor={['#039BE5', '#5F5BA2']}
                                                    hideTooltipValue={true}
                                                    backgroundBarShape={'flat'}
                                                    backgroundBarColors={['#333947']}
                                                    showBackgroundBarColors={false}
                                                    className={'transparentTooltip'}
                                                    colors={['#57AF3E', '#3576BE']}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>

                                <div className={`lg:w-1/3 md:w-1/2 w-full lg:pl-2 md:pl-2 pl-0 lg:mt-0 md:mt-0 mt-3`} id={'chartContainer3'}>
                                    <div className='bg-NeutralGray-100 border border-NeutralGray-500 rounded-2xl p-4'>
                                        {/* <small className='mt-1 mb-0 small text-lightGray'>{state.graphData3.description ? state.graphData3.description :''}</small> */}
                                        {state.spendStackedTrendData && Object.entries(state.spendStackedTrendData).length ?
                                        <React.Fragment>
                                        <div className='-mt-3 -mb-3 mr-2'>
                                            <ApexStackedBarChart
                                                graphData={state.spendStackedTrendData}
                                                start_time={state.datePickerStartDate}
                                                end_time={state.datePickerEndDate}
                                                sparkline={false}
                                                yaxis={false}
                                                yaxisLabel={true}
                                                xaxis={true}
                                                xaxisFormat={'datetime'}
                                                xaxisLabel={true}
                                                axisLabelColor={'#666666'}
                                                paddingLeft={-25}
                                                legend={true}
                                                stacked={true}
                                                height={225}
                                                horizontal={false}
                                                // barHeight={'40%'}
                                                barEndShape={'flat'}
                                                columnWidth={'25%'}
                                                gradient={true}
                                                stroke={'hide'}
                                                // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                colors={state.colors}
                                                hideTooltipValue={true}
                                                backgroundBarShape={'flat'}
                                                backgroundBarColors={['#333947']}
                                                showBackgroundBarColors={false}
                                                className={'transparentTooltip'}
                                            />
                                        </div>
                                        {/* <ul className="w-1/4 self-center mb-0 mt-3 text-black">
                                            {state["trendBreakDownTotal_" + (state.startRecord + index)] && Object.values(state["trendBreakDownTotal_" + (state.startRecord + index)]).map((value, tIndex) => {
                                                return(
                                                    <li key={'trend_' + tIndex} className='flex mb-2'><span className={`legend-circle bg-${state.trendBreakDownColor[tIndex]}`}></span> <span className="pt-1 ml-2 text-gray-400 d-inline-block"> &nbsp;{thousandSeparator(value)}</span></li>
                                                )
                                            })}
                                        </ul> */}
                                        </React.Fragment>
                                        : null}
                                    </div>
                                </div>
                            </div>

                            {state.creditTable && state.creditTable.length ?
                                <div className="w-full mt-3 text-sm">
                                    <div className="flex justify-between pt-2 mb-1">
                                        <p className="mb-0 self-center">Showing budget, spend and credits</p>
                                        <div className='flex'>
                                            <div className="flex">
                                                <CSVLink
                                                    data={state.filteredCreditArray ? state.filteredCreditArray : []} 
                                                    headers={state.creditHeaders ? state.creditHeaders : []}
                                                    filename={'credits-'+new Date().getTime()+'.csv'}
                                                    className={"mt-2 hidden"}
                                                    ref={csvLinkCreditRef} // Attach the reference
                                                    target="_blank"
                                                >
                                                    <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-8 w-8 px-1 rounded-full self-center ml-3 cursor-pointer' width={24} height={24} />
                                                </CSVLink>
                                            </div>
                                        </div>
                                    </div>
                                    {state.creditColumns && state.creditColumns.length ? 
                                        <div className="overflow-x-scroll">
                                            <table className="w-full text-left">
                                                <thead className="bg-gray-50">
                                                    <tr className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600'>
                                                        {state.creditColumns.map((col, colIndex) => {
                                                            return(
                                                                <th scope="col" key={'col_'+colIndex} className={`tracking-wider px-3 py-3 text-left ${col.sticky ? 'sticky z-10 left-0 bg-NeutralGray-600 text-white' : 'bg-NeutralGray-400 text-black'} ${colIndex === 1 ? 'sticky z-10 left-[300px] bg-NeutralGray-600 text-white' : ''} text-left text-base font-semisolid uppercase`} style={{minWidth: col.width+'px'}}>{col.Header}</th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {state.filteredCreditArray && state.filteredCreditArray.length ?
                                                        state.filteredCreditArray.map((col, colIndex) => {
                                                            return(
                                                                <tr className={`${colIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'}`} key={'colval_'+colIndex}>
                                                                    {state.creditColumns.map((obj, objIndex) => {
                                                                        return(
                                                                            <td key={objIndex+'_'+colIndex} className={`p-4 text-black text-left text-sm ${objIndex < 1 ? 'sticky z-10 left-0 bg-NeutralGray-100' : ''} ${objIndex === 1 ? 'sticky z-10 left-[300px] bg-NeutralGray-100 ' : ''} whitespace-nowrap`}>{col[obj.accessor]}</td>
                                                                        )
                                                                    })}
                                                                </tr>
                                                            )
                                                        })
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    : null}
                                </div>
                            : null}

                            {state.trendTable ?
                                <div className="w-full mt-3 text-sm">
                                    <div className="flex justify-between pt-2 mb-1">
                                        <p className="mb-0 self-center">Showing cost and usage breakdown</p>
                                        <div className='flex'>
                                            {state.trendTable && state.trendTable.length ?
                                                <div className="flex">
                                                    <CSVLink
                                                        data={state.filteredArray ? state.filteredArray : []} 
                                                        headers={state.clusterHeaders ? state.clusterHeaders : []}
                                                        filename={'report-breakdown-'+new Date().getTime()+'.csv'}
                                                        className={"mt-2 hidden"}
                                                        ref={csvLinkRef} // Attach the reference
                                                        target="_blank"
                                                    >
                                                        <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-8 w-8 px-1 rounded-full self-center ml-3 cursor-pointer' width={24} height={24} />
                                                    </CSVLink>
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    {state.columns && state.columns.length ? 
                                        <div className="overflow-x-scroll">
                                            <table className="w-full text-left">
                                                <thead className="bg-gray-50">
                                                    <tr className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600'>
                                                        {state.columns.map((col, colIndex) => {
                                                            return(
                                                                <th scope="col" key={'col_'+colIndex} className={`tracking-wider px-3 py-3 text-left ${col.sticky ? 'sticky z-10 left-0 bg-NeutralGray-600 text-white' : 'bg-NeutralGray-400 text-black'} ${colIndex === 1 ? 'sticky z-10 left-[300px] bg-NeutralGray-600 text-white' : ''} text-left text-base font-semisolid uppercase`} style={{minWidth: col.width+'px'}}>{col.Header}</th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {state.filteredArray && state.filteredArray.length ?
                                                        state.filteredArray.map((col, colIndex) => {
                                                            return(
                                                                <tr className={`${colIndex % 2 === 0 ? 'bg-white' : 'bg-cultured-600'}`} key={'colval_'+colIndex}>
                                                                    {state.columns.map((obj, objIndex) => {
                                                                        return(
                                                                            <td key={objIndex+'_'+colIndex} className={`p-4 text-black text-left text-sm ${objIndex < 1 ? 'sticky z-10 left-0 bg-NeutralGray-100' : ''} ${objIndex === 1 ? 'sticky z-10 left-[300px] bg-NeutralGray-100 ' : ''} whitespace-nowrap`}>{col[obj.accessor]}</td>
                                                                        )
                                                                    })}
                                                                </tr>
                                                            )
                                                        })
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    : null}
                                </div>
                            : null}
                                        
                            <div className='flex flex-wrap'>
                                <div className='bg-white rounded-md mt-2 w-full'>
                                    <div className='w-full px-3 py-1 mb-4 overflow-auto' ref={tableRef} id={'table'}>
                                        <table className="w-full text-left">
                                            <thead className="bg-gray-50">
                                                <tr className='uppercase bg-lightGray-600 text-NeutralGray-800 font-semibold border border-lightGray-600'>
                                                    {state.tableDetails && state.tableDetails.fields ?
                                                        state.tableDetails.fields.map(head => {
                                                            return(
                                                                <th key={"head_"+head} className='tracking-wider px-3 py-3 text-left'>{head}</th>
                                                            )
                                                        })
                                                    
                                                    : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.tableDetails && state.tableDetails.table_data ?
                                                    state.tableDetails.table_data.map((row, index)=> {
                                                        return(
                                                            <tr key={"head1_"+index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-cultured-600'}`} onClick={() => {
                                                                if(row.user_name) {
                                                                    setState(prevState => ({ ...prevState, selectedUserDetails: row, callHpcReports: true, showLoading: true, graphData1: {}, graphData2: {}, graphData3: {} }))
                                                                }
                                                            }}>
                                                                {state.tableDetails && state.tableDetails.fields ?
                                                                    state.tableDetails.fields.map((head, hIndex) => {
                                                                        return(
                                                                            <td key={"head2_"+hIndex} className={`${head === 'User Name' ?'text-byzantineBlue cursor-pointer' : 'text-black'} py-2.5 pl-4 text-left text-md ${!hIndex ? '' : ''}`}>
                                                                                {head === 'status1' ?
                                                                                    <span className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{row[head]}</span>
                                                                                :
                                                                                    row[head]
                                                                                }
                                                                            </td>
                                                                        )
                                                                    })
                                                                : null}
                                                            </tr>

                                                            
                                                        )

                                                    })
                                                : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    
    )
}
export default CostReportSection;
    