/*************************************************
 * Collider
 * @exports
 * @file QueueDetailsSidePanel.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { listStackEvents } from '../../../actions/CatalogAction';
import Search from '../../common/SearchComponent'
import Select from 'react-select';
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase, getDayFromSelectedDuration, momentConvertionLocalToUtcTime, subDays, subHours, momentDateGivenFormat } from '../../../utils/utility';
import MetricsTab from '../../common/Mertics/MetricsTab';
import { getQueueMetrics } from '../../../actions/Collider/ObservabilityAction';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import { LoadingCircle } from '../../common/LoadingCiricle';

const durationOptions = [    
    { value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
    { value: '+14d', option: 'Last 2 weeks' },
    { value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const QueueDetailsSidePanel = ({ selectedQueueDetails, closeSidePanel, detailsFormat }) => {
    const [state, setState] = useState({
        showLoading: false,
        summaryLoader: true,
        selectedTab: 'Details',
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        selectedGraphDuration: '+3h'
    })

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);

    const clickOut = useRef();
    const dateRef = useRef();
    const dispatch = useDispatch(); // Create a dispatch function    
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (state.callEventsList && selectedQueueDetails) {
            setState(prevState => ({ ...prevState, callEventsList: false, showEventsLoading: true }));
            let params = {}
            params.provider = selectedQueueDetails.provider
            params.account_id = selectedQueueDetails.account_id
            params.region = selectedQueueDetails.region
            params.cluster_name = selectedQueueDetails.cluster_name
            params.queue_name = selectedQueueDetails.queue_name
            
				
			if(state.nextToken) {
				params.next_token = state.nextToken
			}

            // Fetch job metrics data here using params and update the state
            dispatch(listStackEvents(params))
                .then((response) => {
                    if(response) {
                        let nextToken = ""
                        if(response.next_token) {
                            nextToken = response.next_token	
                        }

                        let results = [] 
                        if(state.nextToken && state.nextToken.length) {
                            results = state.listEvents && state.listEvents.length ? state.listEvents : []
                        }
                        
                        if(response.results && response.results.length) {
                            results = results.concat(response.results)
                        }
                        
                        let totalPages = 1
                        if(results && results.length) {
                            totalPages = Math.ceil(results.length / state.perPage)
                        }
                        setState(prevState => ({ ...prevState, listEvents: results, totalEvents: response.total ? response.total : 0, totalPages, nextToken, filteredListEvents: results, showEventsLoading: false }));
                    }
                })
        }
    }, [state.callEventsList, dispatch, selectedQueueDetails, state.nextToken, state.listEvents, state.perPage])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextToken && state.nextToken.length) {            
            setState(prevState => ({ ...prevState, showEventsLoading: true, callEventsList: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    useEffect(() => {
        if(state.callMetricAPi) {

            let duration = state.selectedGraphDuration
            let startTime = '';
            let endTime = ''
            if(duration === 'custom') {
                startTime = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss');
                endTime = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss');
            } else {
                let period = duration.substring(duration.length, duration.length - 1);
                let durationBtw = getDayFromSelectedDuration(duration);
                
                if (period === 'd') {
                    startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
                } else {
                    startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
                }
                endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');
            }
    
            let params = {};
            params.start_time = startTime;
            params.end_time = endTime;
            params.asset_id = selectedQueueDetails && selectedQueueDetails.asset_id;
            if(selectedQueueDetails && selectedQueueDetails.queue_name) {
                params.queue_name = selectedQueueDetails.queue_name
            }
            
            if(selectedQueueDetails && selectedQueueDetails.account_id) {
                params.account_id = selectedQueueDetails.account_id
            }
            if(selectedQueueDetails && selectedQueueDetails.region) {
                params.region = selectedQueueDetails.region
            }
        
            if (selectedQueueDetails && selectedQueueDetails.asset_id) {            
                dispatch(getQueueMetrics(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({
                                ...prevState,
                                metricDetails: response,
                                showMetricLoading: false,
                                callMetricAPi: false
                            }));
                        }
                    });
            }
        }
    }, [state.callMetricAPi, dispatch, state.selectedGraphDuration, selectedQueueDetails, state.datePickerEndDate, state.datePickerStartDate])

    const handleChildClick = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
        if (dateRef.current && !dateRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
    }

    const handleSelect = (date) => {
        // let datePickerStartDate = momentDateGivenFormat(
        //     date.selection.startDate,
        //     'YYYY-MM-DD 00:00:00'
        // );
        // let datePickerEndDate = momentDateGivenFormat(
        //     date.selection.endDate,
        //     'YYYY-MM-DD 23:59:59'
        // );
        // setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }))     
        
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
                showDateRangePicker: false,
                selectedGraphDuration: "custom",
                metricDetails: [], 
                showMetricLoading: true,
                callMetricAPi: true
            }));
    
        }
        setDateState([date.selection])
    };

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-3/5 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap gap-2 w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-white'>Queue Details</span>
                                </div>
                            </div>
                        
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-black' width="25" height="25" onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>              
                    <div className={`mx-4 pt-4`}>
                        <ul className="flex flex-wrap gap-2 text-sm text-center">
                            <li className="me-2">
                                <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Details' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                            </li>
                            <li className="me-2">
                                <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === 'Events' ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Events', callEventsList: true }))}>Events</span>
                            </li>
                        </ul>
    
                    <div className="overflow-y-auto h-[calc(100vh-200px)] border border-NeutralGray-500 bg-PrimaryTintsPurple-600 rounded-r-2xl rounded-b-2xl">
                        {state.selectedTab === 'Details' ?
                        <div className="p-6">
                            {detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                                return(
                                    <div className={`border-b border-NeutralGray-600 pb-[40px] text-lg ${!index && index === 0 ? '' : 'pt-[40px]'}`}>
                                        <p className={`text-primaryPurple-600 text-[22px] font-bold capitalize`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                        <small className='text-NeutralGray-700'>{row.description}</small>
                                        {row.fields && row.fields.map(fld => {
                                            return(
                                                fld === "queue_configuration" ?
                                                    <div className={`rounded-md bg-GhostWhite p-2 mb-2 ${!index ? 'mt-2' : ''}`}>
                                                        <div className="flex pt-8">
                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">Subnet IDs</span>
                                                            <div className="flex flex-wrap gap-2">
                                                                {selectedQueueDetails[fld] && selectedQueueDetails[fld].subnet_ids ?
                                                                    selectedQueueDetails[fld].subnet_ids.map(ids => {
                                                                        return(
                                                                            <span className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{ids}</span>
                                                                        )
                                                                    })
                                                                : null}
                                                            </div>
                                                        </div>
                                                        <div className="flex pt-8">
                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">Security Group IDs</span>
                                                            <div className="flex flex-wrap gap-2">
                                                                {selectedQueueDetails[fld] && selectedQueueDetails[fld].security_group_ids ?
                                                                    selectedQueueDetails[fld].security_group_ids.map(ids => {
                                                                        return(
                                                                            <span className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{ids}</span>
                                                                        )
                                                                    })
                                                                : null}
                                                            </div>
                                                        </div>
                                                        <div className="flex pt-8">
                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">Policies</span>
                                                            <div className="flex flex-wrap gap-2">
                                                                {selectedQueueDetails[fld] && selectedQueueDetails[fld].policies ?
                                                                    selectedQueueDetails[fld].policies.map(pol => {
                                                                        return(
                                                                            <span className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{pol}</span>
                                                                        )
                                                                    })
                                                                : null}
                                                            </div>
                                                        </div>
                                                        <div className="flex pt-8">
                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">Script Location</span>
                                                            <span className={`self-center text-black break-all`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].script_location ? selectedQueueDetails[fld].script_location : ""}</span>
                                                        </div>

                                                        <div className="flex pt-8">
                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">Capacity Reservation Id</span>
                                                            <span className={`self-center text-black break-all`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].reservation_id ? selectedQueueDetails[fld].reservation_id : ""}</span>
                                                        </div>

                                                        <p className={`mb-1 mt-2 text-purple f18 pb-1 border-b border-lightGray`}>Volume Details</p>
                                                        <div className="flex pt-8">
                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">Type</span>
                                                            <span className={`self-center text-black`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].volume_type ? selectedQueueDetails[fld].volume_type : ""}</span>
                                                        </div>
                                                        <div className="flex pt-8">
                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">Size</span>
                                                            <span className={`self-center text-black`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].volume_size && selectedQueueDetails[fld].volume_size}</span>
                                                        </div>
                                                        <div className="flex pt-8">
                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">Encryption</span>
                                                            <span className={`self-center text-black`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].volume_encryption ? "True" : "False"}</span>
                                                        </div>

                                                        <p className={`mb-1 mt-2 text-purple f18 pb-1 border-b border-lightGray`}>S3 Access</p>
                                                        <div className="flex pt-8">
                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">Bucket</span>
                                                            <span className={`self-center text-black`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].bucket_name ? selectedQueueDetails[fld].bucket_name : ""}</span>
                                                        </div>
                                                        <div className="flex pt-8">
                                                            <span className="min-w-[350px] text-NeutralGray-600 self-center">Write Access</span>
                                                            <span className={`self-center text-black`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].enable_s3_write_access ? "True" : "False"}</span>
                                                        </div>

                                                        <p className={`mb-1 mt-2 text-purple f18 pb-1 border-b border-lightGray`}>Compute Resources</p>
                                                        {selectedQueueDetails[fld] && selectedQueueDetails[fld].compute_resources ?
                                                            selectedQueueDetails[fld].compute_resources.map((comp, cIndex) => {
                                                                return (
                                                                    <div className={`bg-BlockWhite p-2 rounded mb-2 ${!cIndex ? "mt-2" : ""}`}>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[350px] text-NeutralGray-600 self-center">Name</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                                                    </div>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[350px] text-NeutralGray-600 self-center">Instance Type</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.instance_type && comp.instance_type.length ? comp.instance_type[0] : ""}</span>
                                                                    </div>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[350px] text-NeutralGray-600 self-center">Min Instance Count</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                                                    </div>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[350px] text-NeutralGray-600 self-center">Max Instance Count</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                                                    </div>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[350px] text-NeutralGray-600 self-center">Efa Enabled</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.ena_enabled ? "True" : "False"}</span>
                                                                    </div>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[350px] text-NeutralGray-600 self-center">Placement Group Enabled</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                                                    </div>
                                                                    <div className="flex pt-8">
                                                                        <span className="min-w-[350px] text-NeutralGray-600 self-center">Disable Simultaneous Multithreading</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                                                    </div>
                                                                    </div>
                                                                )
                                                            })
                                                        : null}
                                                    </div>
                                                :
                                                    <div className="flex pt-8">
                                                        <span className="min-w-[350px] text-NeutralGray-600 self-center">{removeUnderScore(fld)}</span>
                                                        {selectedQueueDetails && selectedQueueDetails[fld] && fld !== "queue_configuration" ?
                                                            <React.Fragment>
                                                            {typeof selectedQueueDetails[fld] === 'boolean' ?
                                                                <span className={`text-black break-all`}>{selectedQueueDetails[fld] ? 'True' : 'False'}</span>
                                                            : isValidDateTime(selectedQueueDetails[fld]) ?
                                                                <span className="text-black break-all">{momentConvertionUtcToLocalTime(selectedQueueDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                            : convertToLowerCase(fld) === "status" ?
                                                                <span className={`border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all`}>{selectedQueueDetails[fld]}</span>
                                                            : convertToLowerCase(fld) === "tags" ?
                                                                <div className="flex flex-wrap gap-2">
                                                                    {selectedQueueDetails[fld].map(item => {
                                                                        return(
                                                                            <span className='border border-primaryPurple-600 rounded-full px-2 bg-primaryPurple-100 mb-2 text-black mr-2 py-1 break-all'>{item.key +' : '+item.value}</span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : (typeof selectedQueueDetails[fld] === 'string' || typeof selectedQueueDetails[fld] === 'number') ?
                                                                <span className="text-black break-all">{fld === 'provider' ? selectedQueueDetails[fld].toUpperCase() : selectedQueueDetails[fld]}</span>
                                                            : null}
                                                            </React.Fragment>
                                                        : null}
                                                    </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                        : state.selectedTab === "Events" ?
                            <div className='m-2 flex-grow'>
                                {state.showEventsLoading ?
                                    <div className="w-full flex justify-center mt-4">
                                        <LoadingCircle />
                                    </div>
                                : state.listEvents && state.listEvents.length ?
                                    <React.Fragment>
                                        <div className="flex mb-2 justify-between">
                                            <div className="flex w-2/3">
                                                <p className="text-NeutralGray-600 text-base font-bold self-center">Showing {state.filteredListEvents && state.filteredListEvents.length} of total {state.listEvents && state.listEvents.length} event(s)</p>
                                            </div>
                                            <div className="flex justify-end w-1/3 pb-4">
                                                {state.listEvents && state.listEvents.length ?
                                                    <Search
                                                        data={state.listEvents ? state.listEvents : []}
                                                        topClassName={'border border-NeutralGray-600 rounded-full bg-white p-2.5 pl-3 pr-4'}
                                                        className={'rounded-full'}
                                                        applyLiteDarkTags={true}
                                                        filteredData={(filteredListEvents) => {
                                                            let totalPages = 1
                                                            if(filteredListEvents.length > state.perPage) {
                                                                totalPages = Math.ceil(filteredListEvents.length / state.perPage)
                                                            }
                                                            setState(prevState => ({ ...prevState, filteredListEvents, totalPages }))
                                                        }}
                                                    />
                                                : null}
                                                {state.listEvents && state.listEvents.length > state.perPage ?
                                                    <div className='flex justify-end text-black w-full pt-2'>
                                                        <span className='mx-3'>Page <strong>{state.currentPage} of {Math.ceil(state.listEvents / state.perPage)}</strong> </span>
                                                        <div className="pagination text-sm">
                                                            <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                                            <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
                                                            {/* <span>
                                                                Go to
                                                                <input
                                                                    type='number'
                                                                    value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                                                    onChange={e => {
                                                                        const page = e.target.value ? Number(e.target.value) : ""
                                                                        gotoPage(page)
                                                                    }}
                                                                    className="ml-1 inputClass"
                                                                />
                                                            </span> */}
                                                            <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                                            <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                        <div className="px-6 py-[26px] bg-white border border-NeutralGray-500 rounded-2xl">
                                            {state.filteredListEvents && state.filteredListEvents.length ?
                                                state.filteredListEvents.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                                    return(
                                                        <div key={"fil_"+i} className={`pt-4 ${!i ? "" : ""}`}> 
                                                            <div className="text-base flex cursor-pointer text-wrap">
                                                                <p className="mb-0">
                                                                    <span className="mb-0 mr-2 text-black text-sm">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ""} </span>
                                                                    {item.resource_type ?
                                                                        <span className={`mb-0 mr-2 text-sm ml-2`}>{item.resource_type}</span>
                                                                    : null}
                                                                    {item.resource_status ?
                                                                        <span className={`mb-0 mr-2 ml-2 text-primaryPurple-600 text-sm font-bold`}>{item.resource_status}</span>
                                                                    : null}
                                                                    {item.user_id ?
                                                                        <span className={`mb-0 mr-2 text-sm ml-2`}>{item.user_id}</span>
                                                                    : null}
                                                                    {item.resource_status_reason ?
                                                                        <span className="mb-0 ml-2 text-sm text-black">{item.resource_status_reason}</span>
                                                                    : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            :
                                                <div className='flex justify-center text-black text-sm'>
                                                    There are no events on this criteria. Please try adjusting your filter.
                                                </div>
                                            }
                                            </div>
                                    </React.Fragment>
                                :
                                    <div className='flex justify-center m-4 text-black'>
                                        Therer are no events on this criteria.
                                    </div>
                                }
                            </div>
                        : state.selectedTab === 'Observability' ?  
                            <div className='rounded bg-black m-2 p-2 overflow-y-auto min-h-screen'>
                                {selectedQueueDetails && selectedQueueDetails.asset_id ?
                                    <div className='flex justify-between mb-1'>
                                        <p className='mb-1'>Showing data for the period of <span className='ml-1 text-info'>{state.datePickerStartDate ? momentDateGivenFormat(state.datePickerStartDate, "DD MMM YYYY HH:mm") : ''} - {state.datePickerStartDate ? momentDateGivenFormat(state.datePickerEndDate, "DD MMM YYYY HH:mm") : ''}</span> <span className='ml-2'>(local time)</span>
                                        </p>
                                        <div className="flex">
                                            <label className='mb-0 mr-2 self-center'>Duration</label>
                                            <div style={{ minWidth: "130px" }}>
                                                <Select
                                                    placeholder={'Select'}
                                                    isSearchable={false}
                                                    className='f13 p-0 filterSelectDrop'
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                    value={{
                                                        value: state.selectedGraphDuration ? state.selectedGraphDuration : 'Select',
                                                        label: state.selectedGraphDuration ? durationOptions.filter(e => e.value === state.selectedGraphDuration)[0].option : <span className='placeholder'>Select</span>
                                                    }}
                                                    options={durationOptions && durationOptions.map(row => ({
                                                        value: row.value,
                                                        label: row.option,
                                                    }))}
                                                    onChange={event => {
                                                        if(event.value !== 'custom') {
                                                            setState(prevState => ({ ...prevState, metricDetails: [], showMetricLoading: true, selectedGraphDuration: event.value, showDateRangePicker: false }))
                                                        } else {
                                                            if(state.selectedGraphDuration === 'custom') {
                                                                setState(prevState => ({ ...prevState, showDateRangePicker: true }))
                                                            } else {
                                                                setState(prevState => ({ ...prevState, selectedGraphDuration: 'custom', showDateRangePicker: true }))
                                                            }
                                                        }                                                    
                                                    }}
                                                />
                                                {state.showDateRangePicker ?
                                                    <div className="absolute z-50 right-20 mt-5" ref={dateRef}>
                                                        <div className='relative'>
                                                            <div className="bg-white border border-DeepPurpleTints-600 shadow-lg text-black">
                                                                <DateRange
                                                                    editableDateInputs={true}
                                                                    onChange={handleSelect}
                                                                    moveRangeOnFirstSelection={false}
                                                                    ranges={dateState}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <p className='f12 m-3 self-center'>Please select an asset to get the Metric details</p>
                                }
                                <div className='flex justify-between mb-1'>
                                    {selectedQueueDetails && selectedQueueDetails.asset_name ?
                                        <span className={`ml-1 text-info mb-0`}>
                                            <span className='mr-1'>{state.selectedProcess ? state.selectedProcess : (state.selectedQueue ? state.selectedQueue : "")}</span>
                                            {selectedQueueDetails.asset_name}
                                        </span>
                                    : null}
                                    {/* {selectedQueueDetails && selectedQueueDetails.asset_name ?
                                        <div className={`rounded mr-2 bg-black text-info px-2 py-1 f12 truncate self-center`} id={`metricsTitle`}>
                                            {selectedQueueDetails.asset_name}
                                        </div>
                                    : null} */}
                                </div>
                                {state.showMetricLoading ?
                                    <div className='flex justify-center m-4'>
                                        <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                            <path d="M4 12a8 8 0 0112-6.9" />
                                        </svg>
                                    </div>
                                : Object.entries(selectedQueueDetails).length && !state.showMetricLoading ?
                                    <MetricsTab
                                        metricDetails={state.metricDetails}
                                        selectedDetails={selectedQueueDetails}
                                        height={115}
                                    />
                                : null}
                            </div>
                        : null}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default QueueDetailsSidePanel;