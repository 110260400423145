/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file CreateNewBudget.js
 * @author Prakash // on 05/12/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation, useNavigate } from 'react-router-dom'
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import Button from '../../../common/Form/Button';
import { URL_PATH } from '../../../../config/urlPath';
import { setCostPropsDetails } from  '../../../../actions/cost/CostAction'
import { capitalizeAllLetter } from '../../../../utils/utility';

const CreateNewBudget = () => {
    const location = useLocation();

    const [state, setState] = useState({})
    const receivedData = location.state;
    const navigate = useNavigate();
	const dispatch = useDispatch();

    const createInputs = useSelector(state => state?.cost?.costPropsDetails?.createInputs || false);
    const onClickSave = useSelector(state => state?.cost?.costPropsDetails.onClickSave || false);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
        };

        const handlePopstate = () => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
        }
        };

        const handleBeforeRedirect = (location) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
            navigate(location.pathname); // Redirect manually after confirmation
        } else {
            // Prevent the redirect if the user cancels
            return false;
        }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopstate);

        // Use window.onbeforeunload to handle redirects
        const unblock = () => {};
        window.onbeforeunload = () => handleBeforeRedirect({ pathname: '/' });

        return() => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopstate);
            window.onbeforeunload = null; // Remove the onbeforeunload handler when the component is unmounted
            unblock(); // Remove the history.block when the component is unmounted
        };
    }, [navigate]); // Re-run the effect when navigate changes

    useEffect(() => {
        if(receivedData && Object.entries(receivedData).length) {            
            setState(prevState => ({ 
                ...prevState, 
                selectedData: receivedData.selectedData ? receivedData.selectedData : '',
                pageType: receivedData.pageType ? receivedData.pageType : '',
            }))
        }
    }, [receivedData])

    const navigateTo = () => {
        navigate(URL_PATH.COST_BUDGET_LIST); // Redirect to the specified path
    }

    const onChangeHandleValidation = (type, mnt) => {
        //initiative annual amount and total monthly amount check
        let hasError = false
        let obj = createInputs ? createInputs : {}
        let initiatives = obj.initiatives ? obj.initiatives : []
        if(initiatives.length) {
            initiatives.forEach((row, rIndex) => {
                Object.entries(row.cloud_budget).forEach(([key, value]) => {
                    let projects = row.projects ? row.projects : []
                    projects.forEach(proj => {
                        let projectAllocationError = []
                        let projectProviders = []
                        let totalAllocation = 0
                        let totalDistributionSum = 0
                        if(proj.cloud_budget && Object.entries(proj.cloud_budget).length) {
                            Object.entries(proj.cloud_budget).forEach(([key, value], objIndex) => {
                                let projecttDistrubutition = value.monthly_distribution
                                let distributionSum = 0
                                if(projecttDistrubutition) {
                                    distributionSum = projecttDistrubutition.reduce((a, b) => {
                                        return a + parseFloat(b);
                                    }, 0)
                                }

                                totalAllocation += Math.round(value.allocation) 
                                totalDistributionSum += Math.round(distributionSum)

                                let dataRow = {}
                                dataRow.provider = key
                                dataRow.allocation = Math.round(value.allocation)
                                dataRow.distributuion = Math.round(distributionSum)
                                dataRow.balance = Math.round(value.allocation) - Math.round(distributionSum)
                                
                                if(value.allocation < distributionSum) {
                                    hasError = true
                                    let error ='Annual commitment amount of '+capitalizeAllLetter(key)+' should be more than the total monthly commitment.'
                                    
                                    projectAllocationError.push(error)
                                }
                                projectProviders.push(dataRow)

                                if(Object.entries(proj.cloud_budget).length-1 === objIndex) {
                                    let totalDepRow = {}
                                    totalDepRow.provider = 'Total'
                                    totalDepRow.allocation = totalAllocation
                                    totalDepRow.distributuion = totalDistributionSum
                                    totalDepRow.balance = totalAllocation - totalDistributionSum
                                    projectProviders.unshift(totalDepRow);
                                }
                                
                                setState(prevState => ({ ...prevState, ['projectAllocationError_'+proj.project]: projectAllocationError }))
                            })
                        }

                        setState(prevState => ({ ...prevState, ['projectProviders_'+proj.project]: projectProviders }))
                    })

                    let projectTotalAllocation = 0
                    let projectIntitativeAllocationError = []
                    projects.forEach(proj => {
                        if(proj.cloud_budget.hasOwnProperty(key)) {
                            let projData = proj.cloud_budget[key]
                            projectTotalAllocation += projData.allocation
                        }
                    })
                    

                    projects.forEach(proj => {
                        projectIntitativeAllocationError = []
                        if(proj.cloud_budget.hasOwnProperty(key)) {
                            if(projectTotalAllocation > value.allocation) {
                                hasError = true
                                let error = 'Total Project Annual commitment amount of '+capitalizeAllLetter(key)+' should be less than the initiative annual commitment.'
                                projectIntitativeAllocationError.push(error)
                                setState(prevState => ({ ...prevState, ['projectIntitativeAllocationError_'+key+'_'+proj.project]: projectIntitativeAllocationError }))
                            } else {
                                setState(prevState => ({ ...prevState, ['projectIntitativeAllocationError_'+key+'_'+proj.project]: [] }))
                            }
                        }
                    })
                    
                })
            })
        } else {
            hasError = true
        }
        
        setState(prevState => ({ ...prevState,  hasError, onSaveFunction: hasError ? false : true }))
        dispatch(setCostPropsDetails('budgetSaveError', hasError))
        if(hasError) {
            dispatch(setCostPropsDetails('onClickSave', 'checkForError'))
        } else {
            if(type === 'save') {
                dispatch(setCostPropsDetails('onClickSave', 'save'))
            } else {
                dispatch(setCostPropsDetails('onClickSave', 'checkForErrorHasNoError'))
            }
        }
    }

    useEffect(() => {
        if(onClickSave === "stopLoading") {
            setState(prevState => ({ ...prevState,  hasError: false, onSaveFunction: false }))
            dispatch(setCostPropsDetails('onClickSave', false))
        }
    }, [onClickSave, dispatch])

    return (
        <React.Fragment>
            <div className='bg-NeutralGray-200 px-8 py-10 text-black w-full'>
                <div className='w-full self-center'>
                    <span className='font-bold text-2xl capitalize'>{state.pageType ? state.pageType : 'Create'} Budget</span>
                </div>
            </div>
            <div className='p-6'>
                <div className='flex flex-wrap'>
                    <div className={`lg:w-1/2 w-full flex flex-col h-100`}>
                        <div className='overflow-y-auto h-[calc(100vh-270px)]'>
                            <LeftSection 
                                receivedData={receivedData}
                                hasError={state.hasError}
                            />
                        </div>
                    </div>
                    <div className={`lg:w-1/2 w-full lg:pl-2 flex flex-col h-100`}>
                        <div className='overflow-y-auto h-[calc(100vh-270px)]'>
                            <RightSection 
                                receivedData={receivedData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="flex justify-center w-full p-8 shadow-lg bg-NeutralGray-700">
                <Button
                    classDetails={{bg: 'bg-ferrariRed-600', rounded: 'rounded-md', padding: 'py-2.5 px-4 mr-2', text: 'text-white text-base', others: 'cursor-pointer', width: 'min-w-36'}}
                    label={'Cancel'}
                    callback={() => navigateTo()}
                />
                {state.onSaveFunction ?
                    <Button
                        classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', width: 'min-w-36' }}
                        loading={true}
                        label={state.pageType === 'Edit' ? 'Updating' : 'Saving'}
                    />
                :
                    <div className='flex'>
                        <Button
                            classDetails={{bg: 'bg-green-600', rounded: 'rounded-md', padding: 'py-2.5 px-4', text: 'text-white text-base', others: 'cursor-pointer', width: 'min-w-36'}}
                            label={state.pageType !== "Edit" ?  "Save"  : "Update"}
                            callback={() => {
                                setState(prevState => ({ ...prevState, onSaveFunction: true }))
                                onChangeHandleValidation('save')
                            }}
                        />
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default CreateNewBudget