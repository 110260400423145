import React, { useState } from 'react';
import { useTable, useRowSelect } from 'react-table';
import { LoadingCircle } from '../../common/LoadingCiricle';
import {Icon} from "@iconify/react";

const FileManagerTable = ({
  columns,
  data,
  loading,
  onRowSelect,
  selectedColor = false,
}) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(false)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
      data
    },
    useRowSelect
  );

  const onRowClick = (row) => {
    // if (row.original.type === 'directory') {
      onRowSelect(row.original, `${row.original.current_directory}/${row.original.url}`);
    // }
  };

  const renderTableSkeleton = () => {
    return(
      <div className="flex justify-center data-loading text-center">
        <LoadingCircle />
      </div>
    )
	}

  const renderNoData = () => {
		return (
			<>
        <table {...getTableProps()} className="table-auto text-black w-full">
          <thead className='bg-extraLightGray p-2'>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th className='py-2.5 pl-3 text-base font-bold' {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <tr>
              <td className="text-center text-black" colSpan={5}>This folder is empty.</td>
            </tr>
          </tbody>
        </table>
      </>
		)
	}

  return (
    <>
      {loading ? (
				renderTableSkeleton()
			) : (
        <>
        {(!data || !data.length)  ? (
          renderNoData()
          ) : (
            <>
              <div className="table-responsive mb-4">
                <table {...getTableProps()} className="table-auto w-full text-black">
                  <thead className='bg-extraLightGray p-2'>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th className='text-left px-2 py-1 text-base font-bold' {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className='p-2 bg-white text-sm' {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr className={`${selectedRowIndex === i ? (selectedColor ? selectedColor : 'bg-extraLightGray') : ''} w-100`}
                          {...row.getRowProps()} onClick={() => {
                            setSelectedRowIndex(i)
                            onRowClick(row)
                          }}
                        >
                          {row.cells.map(cell => (
                            <td className='py-2 px-2 cursor-pointer' {...cell.getCellProps()}>
                              <div className='flex'>
                                {cell.column.Header === 'Name' ?
                                  <Icon icon={`${row.original && row.original.type && row.original.type === 'directory' ? "material-symbols-light:folder-outline" : "mdi-light:file"}`} width={20} height={20} className="mr-2  self-center" /> 
                                : null}
                                {cell.render('Cell')}
                              </div>
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FileManagerTable