/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * collider
 * @exports
 * @file Evetns.js
 * @author Prakash // on 03/09/2024
 * @copyright © 2024 collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState} from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listStackEvents } from '../../../actions/CatalogAction';
import { getCommonInformationMessage, momentConvertionUtcToLocalTime } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'
import {Icon} from "@iconify/react";
import { LoadingCircle } from '../../common/LoadingCiricle';

const Events = ({ selectedClusterDetails }) => {
    const [state, setState] = useState({
        showEventsLoading: true,
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 100,
    })

    const dispatch = useDispatch(); 

    useEffect(() => {
        let params = {}
        params.provider = selectedClusterDetails && selectedClusterDetails.provider
        params.account_id = selectedClusterDetails && selectedClusterDetails.account_id
        params.region = selectedClusterDetails && selectedClusterDetails.region
        params.cluster_name = selectedClusterDetails && selectedClusterDetails.cluster_name
        // Fetch job metrics data here using params and update the state
        if(params.cluster_name) {
            dispatch(listStackEvents(params))
                .then((response) => {
                    if(response) {
                        let results = response.results ? response.results : []

                        let totalPages = 1
                        if(results.length > state.perPage) {
                            totalPages = Math.ceil(results.length / state.perPage)
                        }
                        setState(prevState => ({ ...prevState, listEvents: results, filteredListEvents: results, showEventsLoading: false, totalPages }));
                    }
                })
        } else {
            setState(prevState => ({ ...prevState, listEvents: [], filteredListEvents: [], showEventsLoading: false  }))
        }
    }, [dispatch, selectedClusterDetails, state.perPage])

	const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    return (
        <div className='p-6'>
            {state.showEventsLoading ?
                <div className="w-full flex justify-center mt-4">
                    <LoadingCircle />
                </div>
            : state.listEvents && state.listEvents.length ?
                <React.Fragment>
                    <div className="flex mb-2 justify-between">
                        <div className="flex w-2/3">
                            <p className="text-NeutralGray-600 text-base font-bold self-center">Showing {state.filteredListEvents && state.filteredListEvents.length} of total {state.listEvents && state.listEvents.length} event(s)</p>
                        </div>
                        <div className="flex justify-end w-1/3 pb-4">
                            {state.listEvents && state.listEvents.length ?
                                <Search
                                    data={state.listEvents ? state.listEvents : []}
                                    topClassName={'border border-NeutralGray-600 rounded-full bg-white'}
                                    className={'rounded-full'}
                                    applyLiteDarkTags={true}
                                    filteredData={(filteredListEvents) => {
                                        let totalPages = 1
                                        if(filteredListEvents.length > state.perPage) {
                                            totalPages = Math.ceil(filteredListEvents.length / state.perPage)
                                        }
                                        setState(prevState => ({ ...prevState, filteredListEvents, totalPages }))
                                    }}
                                />
                            : null}
                        </div>
                    </div>
                    <div className="flex justify-end w-full pt-2">
                        {state.filteredListEvents && state.filteredListEvents.length > state.perPage ?
                            <div className='flex justify-center text-black w-full'>
                                <span className='mx-3 self-center'>Page {state.currentPage} of {state.totalPages} </span>
                                <span><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentPage === 1 ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-500'}`} onClick={() => navigatePage('previous', state.currentPage)} /></span> 
                                <span><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-2 ${state.currentPage === state.totalPages ? 'cursor-pointer text-DeepPurpleTints-600' : 'pointer-events-none text-NeutralGray-500'}`} onClick={() => navigatePage('next', state.currentPage)} /></span> 
                            </div>
                        : null}
                    </div>
                    <div className="px-6 py-[26px] bg-white border border-NeutralGray-500 rounded-2xl">
                    {state.filteredListEvents && state.filteredListEvents.length ?
                        state.filteredListEvents.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                            return(
                                <div key={"fil_"+i} className={`pt-4 ${!i ? "" : ""}`}> 
                                    <div className="text-base flex cursor-pointer text-wrap">
                                        <p className="mb-0">
                                            <span className="mb-0 mr-2 text-black text-sm">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ""} </span>
                                            {item.resource_type ?
                                                <span className={`mb-0 mr-2 text-sm ml-2`}>{item.resource_type}</span>
                                            : null}
                                            {item.resource_status ?
                                                <span className={`mb-0 mr-2 ml-2 text-primaryPurple-600 text-sm font-bold`}>{item.resource_status}</span>
                                            : null}
                                            {item.user_id ?
                                                <span className={`mb-0 mr-2 text-sm ml-2`}>{item.user_id}</span>
                                            : null}
                                            {item.resource_status_reason ?
                                                <span className="mb-0 ml-2 text-sm text-black">{item.resource_status_reason}</span>
                                            : null}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    :
                        <div className='flex justify-center text-black text-sm'>
                            {getCommonInformationMessage('event')}
                        </div>
                    }
                    </div>
                </React.Fragment>
            :
                <div className='flex justify-center text-black text-sm'>
                    Therer are no events on this criteria.
                </div>
            }
        </div>
    )
}

export default Events