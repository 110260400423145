
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertDetails.js
 * @author Prakash // on 05/02/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../utils/utility'
import {Icon} from "@iconify/react";
import { LoadingCircle } from '../../common/LoadingCiricle';

const AlertDetails = (props) => {
    const [state, setState] = useState({
        expandAlertDetails: true
    })
    const alertDetails = useSelector(state => state?.hpc?.hpcPropsDetails?.alertDetails || false);

    useEffect(() => {
        if(!_.isEqual(alertDetails, state.alertDetails)) {
            setState(prevState => ({
                ...prevState, 
                alertDetails,
                showSpinner: true,
                expandAlertDetails: true,
                expandAssetDetails: false
            }))

            setTimeout(() => setState(prevState => ({ ...prevState, showSpinner: false })), 2000)
        }
    }, [alertDetails, state.alertDetails])

    const getStatusBgColor = (status) => {
        let color = 'bg-darkPurple-50'
        if (status) {
            if(status.toLowerCase() === 'critical') { color = 'bg-customRed' } else 
            if(status.toLowerCase() === 'high') { color = 'bg-blue-600' } else 
            if(status.toLowerCase() === 'medium') { color = 'bg-primaryPurple-600' }
        }
        return color;
    }

    const getStatusColor = (status) => {
        let color = 'text-darkPurple-600'
        if (status) {
            if(status.toLowerCase() === 'critical') { color = 'text-customRed' } else 
            if(status.toLowerCase() === 'high') { color = 'text-blue-600' } else 
            if(status.toLowerCase() === 'medium') { color = 'text-primaryPurple-600' }
        }
        return color;
    }

    return (
            state.refreshLoading ?
                <div className="flex justify-center min-h-48">
                    <div className="relative overflow-x-auto mt-3">
                        <LoadingCircle />
                    </div>
                </div>
            :
            
                (alertDetails.details && Object.entries(alertDetails.details).length ?
                    <div className="flex flex-wrap">
                        <div className="border-2 border-byzantineBlue rounded-2xl bg-aliceBlue">
                            <div className="flex mb-2 justify-between border-b border-PrimaryTintsDeepPurpleTints-600">
                                <div className="self-center mr-2 lg:w-3/5 w-full pl-4 py-7">
                                    <p className='mb-0 self-center text-base font-bold'>Showing Alert Info</p>
                                    <p className="mb-0 self-center text-sm font-semibold text-primaryPurple-600">{alertDetails.details.event_source} : {alertDetails.details.event_name} : {alertDetails.details.event_type}</p>
                                </div>
                                <div className='mt-[17px] pr-4 pitems-start'>
                                    <p className='rounded-full px-[11px] py-[1px] text-white bg-red-600 text-lg'>{alertDetails.details.severity}</p>
                                </div>
                            </div>
                            <React.Fragment>
                            <div className={`bg-white border border-PrimaryTintsDeepPurpleTints-600 rounded-2xl my-10 mx-4`}>
                                <div className="flex text-black p-4" onClick={() => setState(prevState => ({ ...prevState, expandAlertDetails: !state.expandAlertDetails }))}>
                                    {state.expandAlertDetails ?
                                        <Icon icon="zondicons:minus-outline" className='mr-1 self-center cursor-pointer' width={16} height={16} />
                                    :
                                        <Icon icon="tabler:circle-plus" className='mr-1 self-center cursor-pointer' width={20} height={20} />
                                    }
                                    <span className='self-center text-md font-semibold'>Alert Details</span>
                                </div>
                                {state.expandAlertDetails ?
                                    <div className="p-4">
                                        <div className="flex justify-between mb-3">
                                            {alertDetails.details.type === 'alert' ?
                                                <div>
                                                    <p className="mb-0 text-sm">{alertDetails && alertDetails.details && alertDetails.details.description ? alertDetails.details.description : ''}</p>
                                                </div>
                                            : 
                                                <div>
                                                    <div className="flex flex-wrap">
                                                        <p className="mb-0 text-md text-black">{alertDetails && alertDetails.details && alertDetails.details.resource_type ? alertDetails.details.resource_type : ''}</p>
                                                        <p className="mb-0 text-md text-black">{alertDetails && alertDetails.details && alertDetails.details.event_type ? '_'+alertDetails.details.event_type : ''}</p>
                                                        <p className="mb-0 text-md text-black">{alertDetails && alertDetails.details && alertDetails.details.asset_name ? '_'+alertDetails.details.asset_name : ''}</p>
                                                    </div>
                                                    <p className="mb-0 text-xs">{alertDetails && alertDetails.details && alertDetails.details.event_type ? alertDetails.details.event_type : ''}</p>
                                                </div>
                                            }
                                        </div>
                                        {alertDetails.details.type === 'group' ?
                                            <React.Fragment>
                                            <div className="flex flex-wrap py-3 border-t border-NeutralGray-500">
                                                <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                                    <div className="flex flex-wrap"> 
                                                        <p className="b-block mb-0 mr-2 text-black">Severity</p>
                                                        <p className={`self-center mb-0 mr-2 badge ${alertDetails.details.first_event_severity ? 'risk-badge-'+alertDetails.details.first_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                            {alertDetails.details.first_event_severity ? capitalizeFirstLetter(alertDetails.details.first_event_severity) : 'Unknown'}
                                                        </p>
                                                        <p className="b-block mb-0 mr-2 text-black">for the first alert observed on</p>
                                                        <p className="mb-0 text-black">{alertDetails && alertDetails.details && alertDetails.details.first_event ? momentConvertionUtcToLocalTime(alertDetails.details.first_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                                    <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                    <p className="mb-0 text-warning">
                                                        {alertDetails.details.first_event_threshold && alertDetails.details.first_event_threshold}
                                                        {alertDetails.details.unit ? (alertDetails.details.unit ? (alertDetails.details.unit.toLowerCase() === 'percent' ? ' %' : alertDetails.details.unit) : '') : ''}
                                                        {alertDetails.details.first_event_statistic ? (' : ')+alertDetails.details.first_event_statistic : ''}
                                                        {alertDetails.details.first_event_period ? (' : ')+alertDetails.details.first_event_period : ''}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap py-3 border-t border-NeutralGray-500">
                                                <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                                    <div className="flex flex-wrap"> 
                                                        <p className="b-block mb-0 mr-2 text-black">Severity</p>
                                                        <p className={`self-center mb-0 mr-2 badge ${alertDetails.details.last_event_severity ? 'risk-badge-'+alertDetails.details.last_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                            {alertDetails.details.last_event_severity ? capitalizeFirstLetter(alertDetails.details.last_event_severity) : 'Unknown'}
                                                        </p>
                                                        <p className="b-block mb-0 mr-2 text-black">for the last alert observed on</p>
                                                        <p className="mb-0 text-black">{alertDetails && alertDetails.details && alertDetails.details.last_event ? momentConvertionUtcToLocalTime(alertDetails.details.last_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                                    <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                    <p className="mb-0 text-warning">
                                                        {alertDetails.details.last_event_threshold && alertDetails.details.last_event_threshold}
                                                        {alertDetails.details.unit ? (alertDetails.details.unit ? (alertDetails.details.unit.toLowerCase() === 'percent' ? ' %' : alertDetails.details.unit) : '') : ''}
                                                        {alertDetails.details.last_event_statistic ? (' : ')+alertDetails.details.last_event_statistic : ''}
                                                        {alertDetails.details.last_event_period ? (' : ')+alertDetails.details.last_event_period : ''}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap py-3 border-t border-NeutralGray-500">
                                                <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                                    <div className="flex flex-wrap"> 
                                                        <p className="b-block mb-0 mr-2 text-black">Severity</p>
                                                        <p className={`self-center mb-0 mr-2 badge ${alertDetails.details.highest_event_severity ? 'risk-badge-'+alertDetails.details.highest_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                            {alertDetails.details.highest_event_severity ? capitalizeFirstLetter(alertDetails.details.highest_event_severity) : 'Unknown'}
                                                        </p>
                                                        <p className="b-block mb-0 mr-2 text-black">for the highest alert observed on</p>
                                                        <p className="mb-0 text-black">{alertDetails && alertDetails.details && alertDetails.details.highest_event ? momentConvertionUtcToLocalTime(alertDetails.details.highest_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                                    <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                    <p className="mb-0 text-warning">
                                                        {alertDetails.details.highest_event_threshold && alertDetails.details.highest_event_threshold}
                                                        {alertDetails.details.unit ? (alertDetails.details.unit ? (alertDetails.details.unit.toLowerCase() === 'percent' ? ' %' : alertDetails.details.unit) : '') : ''}
                                                        {alertDetails.details.highest_event_statistic ? (' : ')+alertDetails.details.highest_event_statistic : ''}
                                                        {alertDetails.details.highest_event_period ? (' : ')+alertDetails.details.highest_event_period : ''}
                                                    </p>
                                                </div>
                                            </div>
                                            </React.Fragment>

                                        : null}
                                        {alertDetails.details.type === 'alert' ?
                                            <div className="flex flex-wrap py-3 border-t border-NeutralGray-500">
                                                <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                                    <p className="b-block mb-0">Alert Time:</p>
                                                    <p className="mb-0 text-black">
                                                        {alertDetails.details.type === 'alert' ?
                                                            alertDetails.details.event_time ? momentConvertionUtcToLocalTime(alertDetails.details.event_time, 'DD MMM YYYY HH:mm') : ''
                                                        :
                                                            alertDetails.details.created_at ? momentConvertionUtcToLocalTime(alertDetails.details.created_at, 'DD MMM YYYY HH:mm') : ''
                                                        }
                                                    </p>
                                                </div>
                                                <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                                    <p className="b-block mb-0">Alert Metrics:</p>
                                                    {alertDetails.details.type === 'alert' ?
                                                        <p className="mb-0 text-black">
                                                            {alertDetails.details.event_source ? alertDetails.details.event_source : ''}
                                                            {alertDetails.details.event_type ? (' : ')+alertDetails.details.event_type : ''}
                                                        </p>
                                                    : null}
                                                </div>
                                            </div>
                                        : null}
                                        {alertDetails.details.type === 'alert' ?
                                            <div className="flex flex-wrap py-3 border-t border-NeutralGray-500">
                                                <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                                    <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                    <p className="mb-0 text-warning">
                                                        {alertDetails.details.threshold && alertDetails.details.threshold}{alertDetails.details.unit ? (alertDetails.details.unit ? (alertDetails.details.unit.toLowerCase() === 'percent' ? ' %' : alertDetails.details.unit) : '') : ''}
                                                        {alertDetails.details.statistic ? (' : ')+alertDetails.details.statistic : ''}
                                                        {alertDetails.details.period ? (' : ')+alertDetails.details.period : ''}
                                                    </p>
                                                </div>
                                                <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                                    <p className="b-block mb-0">Provider / Account / Region :</p>
                                                    <p className="mb-0 text-black">
                                                        {alertDetails.details.provider ? alertDetails.details.provider.toUpperCase() : ''}
                                                        {alertDetails.details.account_id ? (' : ')+alertDetails.details.account_id : ''}
                                                        {alertDetails.details.region ? (' : ')+alertDetails.details.region : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        : null}
                                        {alertDetails.details.type !== 'alert' ?
                                            <div className="flex flex-wrap py-3 border-t border-NeutralGray-500">
                                                <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                                    <p className="b-block mb-0">Provider / Account / Region :</p>
                                                    <p className="mb-0 text-black">
                                                        {alertDetails.details.provider ? alertDetails.details.provider.toUpperCase() : ''}
                                                        {alertDetails.details.account_id ? (' : ')+alertDetails.details.account_id : ''}
                                                        {alertDetails.details.region ? (' : ')+alertDetails.details.region : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        : null}
                                    </div>
                                : null}
                            </div>

                            {/* {state.assetDetails && state.assetDetails.length ? */}
                                <div className={`bg-white border border-NeutralGray-600 rounded-2xl my-10 mx-4`}>
                                    <div className="flex text-black p-4" onClick={() => setState(prevState => ({ ...prevState, expandAssetDetails: !state.expandAssetDetails }))}>
                                        {state.expandAssetDetails ?
                                            <Icon icon="zondicons:minus-outline" className='mr-1 self-center cursor-pointer' width={16} height={16} />
                                        :
                                            <Icon icon="tabler:circle-plus" className='mr-1 self-center cursor-pointer' width={20} height={20} />
                                        }
                                        <span className='self-center text-md font-semibold'>Asset Details</span>
                                    </div>
                                    {state.expandAssetDetails ?
                                        // state.assetDetails.map((item, iIndex) => {
                                            // return(
                                            <div key={'asset_0'} className="p-4">
                                                <div className="flex flex-wrap py-3">
                                                    <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                                        <p className="b-block mb-0">Name:</p>
                                                        <p className="mb-0 text-black break-all">{alertDetails && alertDetails.details && alertDetails.details.asset_name ? alertDetails.details.asset_name : ''}</p>
                                                    </div>
                                                    <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                                        <p className="b-block mb-0">Id:</p>
                                                        <p className="mb-0 text-black break-all">{alertDetails && alertDetails.details && alertDetails.details.asset_id ? alertDetails.details.asset_id : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap py-3 border-t border-NeutralGray-500">
                                                    <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                                        <p className="b-block mb-0">Resource:</p>
                                                        <p className="mb-0 text-black break-all">
                                                            {alertDetails.details.provider ? alertDetails.details.provider.toUpperCase() : ""}
                                                            {alertDetails.details.account_id ? (" : ")+alertDetails.details.account_id : ""}
                                                            {alertDetails.details.region ? (" : ")+alertDetails.details.region : ""}
                                                        </p>
                                                    </div>
                                                    <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                                        <p className="b-block mb-0">Services:</p>
                                                        <p className="mb-0 text-black break-all">
                                                            {alertDetails.details.resource_type ? alertDetails.details.resource_type : ''}
                                                            <span className='ml-2'>{alertDetails.details.service_name ? alertDetails.details.service_name : ''}</span>
                                                        </p>
                                                    </div>
                                                </div>                                                        
                                                <div className="flex flex-wrap py-3 border-t border-NeutralGray-500">
                                                    <div className="py-1 w-full">
                                                        <p className="b-block mb-0">ARN:</p>
                                                        <p className="mb-0 text-black break-all">{alertDetails && alertDetails.details && alertDetails.details.asset_arn ? alertDetails.details.asset_arn : ''}</p>
                                                    </div>
                                                </div>
                                                {alertDetails && alertDetails.details && alertDetails.details.category ? 
                                                    <div className="flex flex-wrap py-3 border-t border-NeutralGray-500">
                                                        <div className="py-1 w-full">
                                                            <p className="b-block mb-1">Category:</p>
                                                            <div className={`flex flex-wrap`}>
                                                                {Array.isArray(alertDetails.details.category) ?
                                                                    alertDetails.details.category.map(item => {
                                                                        return (
                                                                            <small key={'cat_'+item} className={`mr-1 mb-1 bg-lightGray text-white rounded-full px-2 py-1`}>{item}</small> 
                                                                        )
                                                                    })
                                                                : 
                                                                    <small className={`mr-1 mb-1 bg-lightGray text-white rounded-full px-2 py-1 break-all`}>{alertDetails.details.category}</small> 
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                        //     )
                                        // })
                                    : null}
                                </div>
                            {/* : null} */}
                            </React.Fragment>
                        </div>
                    </div>
                :
                    <div className='flex justify-center m-4 text-black'>
                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                    </div>
                )
            
  
    )
}

export default AlertDetails