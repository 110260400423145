/*************************************************
 * Tvastar
 * @exports
 * @file RdsServiceCapacity.js
 * @author Prakash // on 04/04/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {Icon} from "@iconify/react";
import MultiSelectSection from '../../common/MultiSelectSection'

import { setGovernancePropsDetails, setNewEditRuleDetails } from '../../../actions/governance/GovernanceAction'
import { DynamicApiToDevCall } from '../../../actions/commonActionNew'
import { onlyNumeric } from '../../../utils/utility'
import SelectOption from '../../common/Form/SelectOption';
import Checkbox from '../../common/Form/Checkbox';

const RdsServiceCapacity = (props) => {
	const [state, setState] = useState({
		onLoad: true,
		policyArray: [0],
		hasErrorInRuleForm: false,
		hasError: props.hasError,
		timeTypeOptions: [
			{value: "h", label: "Hour(s)"},
			{value: "m", label: "Minute(s)"}
		],
		time_type_0: "h"
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const editNewRuleDetails = useSelector(state => state?.governance?.editNewRuleDetails)
	const onClickFinish = useSelector(state => state?.governance?.governancePropsDetails?.onClickFinish||false)

	const apiToCall = useCallback((apiDetails) => {
		apiDetails.forEach(item => {
			let params = {}
	
			let valueRequest =  item.body && item.body.values
			valueRequest && valueRequest.forEach(req => {
				if(req.value) {
					params[req.key] = req.value
				} else if(req.value_object) {
					let valuObj = {}
					req.value_object.forEach(subReq => {
						valuObj[subReq.key] = subReq.value
					})
					params[req.key] = valuObj
				}
			})
			let dynamicValues = item.body && item.body.dynamic_values
			dynamicValues && dynamicValues.forEach(req => {
				if(editNewRuleDetails && editNewRuleDetails.applies_to && editNewRuleDetails.applies_to.length) {
					let dat = []
					editNewRuleDetails.applies_to.forEach(app => {
						// if(req.key === "account_id") {
						// 	dat.push(app[req.key])
						// 	if(!dat.includes(app[req.key])) {
						// 		dat.push(app[req.key])
						// 	}
						// } else {
							if(Array.isArray(app[req.key])) {
								dat = [...dat, ...app[req.key]]
								dat = [...new Set(dat)];
							} else {
								if(!dat.includes(app[req.key])) {
									dat.push(app[req.key])
								}
							}
						// }
					})
					params[req.key] = dat
				}
			})
	
			let url = item.name
			let API_END_POINT = item.api
			let apiMethod = item.method
			dispatch(DynamicApiToDevCall(API_END_POINT, apiMethod, url, params))
				.then((response) => {
					if(response) {
						let results = response ? response : []
						setState(prevState => ({ ...prevState, totalInstances: results, structureSizingResponse: true }))
						if(props.viewMode) {
							setState(prevState => ({ ...prevState, savedData: [props.masterData], callSavedData: true }))
						} else {
							if(editNewRuleDetails && editNewRuleDetails.policies) {
								if(editNewRuleDetails.policies.filter(e => e.rule_category === props.masterData.rule_category).length) {						
									let savedData = editNewRuleDetails.policies.filter(e => e.rule_id === props.masterData.rule_id)
									if(savedData.length) {
										setState(prevState => ({ ...prevState, savedData, callSavedData: true }))
									}
								}
							}
						}
					}
				})
		})
	}, [dispatch, editNewRuleDetails, props.masterData, props.viewMode])

	useEffect(() => {
		if(state.structureSizingResponse) {
			setState(prevState => ({ ...prevState, structureSizingResponse: false }))
			let instance_category_options = state.totalInstances ? state.totalInstances.map(e => e.instance_category ? e.instance_category : e) : []
			setState(prevState => ({ ...prevState, instance_category_options }))
			if(props.viewMode) {
				setState(prevState => ({ ...prevState, savedData: [props.masterData], callSavedData: true }))
			} else {
				if(editNewRuleDetails && editNewRuleDetails.policies) {
					if(editNewRuleDetails.policies.filter(e => e.rule_category === props.masterData.rule_category).length) {
						let savedData = editNewRuleDetails.policies.filter(f => f.rule_id === props.masterData.rule_id)
						if(savedData.length) {
							setState(prevState => ({ ...prevState, savedData, callSavedData: true }))
						}
					}
				}
			}
			
		}
	}, [state.structureSizingResponse, editNewRuleDetails, props.masterData, props.viewMode, state.totalInstances])

	useEffect(() => {
		if(state.onLoad) {
			setState(prevState => ({ ...prevState, onLoad: false }))
			let masterData = props.masterData
			let actionDetails = []
			if(masterData.actions && masterData.actions.length) {
				actionDetails = masterData.actions
			}
			let variables = masterData.variables
			variables.forEach(item => {
				if(item.component === "select" || item.component === "multiselect") {
					if(item.options) {
						setState(prevState => ({ ...prevState, [item.variable+"_options"]: item.options }))
					} else {
						setState(prevState => ({ ...prevState, [item.variable+"_0"]: item.default ? item.default : "" }))
					}
				}
			})
			setState(prevState => ({ ...prevState, tags: masterData.tags, variables, template: masterData.template, description: masterData.description ? masterData.description : "", actionDetails }))
			if(masterData.api_to_call) {
				apiToCall(masterData.api_to_call)
			}		
		}
	}, [apiToCall, props.masterData, state.onLoad])

	const getInstanceSeries = useCallback((value, item) => {
		let instancesCategoryResult = state.totalInstances.filter(e => e.instance_category === value)
		let instance_series_options = instancesCategoryResult && instancesCategoryResult.length && instancesCategoryResult[0].series ? instancesCategoryResult[0].series.map(e => e.series ? e.series : e) : []
		let instanceSeries = instancesCategoryResult && instancesCategoryResult.length && instancesCategoryResult[0].series ? instancesCategoryResult[0].series : []
		setState(prevState => ({ ...prevState, ["instance_series_options_"+item]: instance_series_options, ["instanceSeries_"+item]: instanceSeries }))
	}, [state])

	const showPolicy = (array, showField, index) => {		
		state[array].forEach(item => {
			if(index === item) {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: !state[showField+"_"+item] }))
			} else {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: false }))
			}
		})
	}

	const addPolicies = (array, showField, index) => {
		let rowList = state[array];
		if(state[array]) {
			let value = state[array][state[array].length - 1]
			value = value+1 
			rowList.push(value);
		}
		rowList.forEach(item => {
			if(index === item) {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: true }))
			} else {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: false }))
			}
		})
		
		setState(prevState => ({ ...prevState, [array]: rowList, onChangeInput: true }))
	}

	const removeSelectedDetails = (field, removeItem) => {
		let filteredResult = state[field].filter(e => e !== removeItem)
		setState(prevState => ({ ...prevState, [field]: filteredResult }))
	}

	const setAllowedInstanceSeries = (item, value, series) => {
		let allowedInstance = state["instance_types_"+item] ? state["instance_types_"+item] : []
		if(state["instanceSeries_"+item] && state["instanceSeries_"+item].length) {
			let filterdItem = state["instanceSeries_"+item].filter(e => e.series === series)
			filterdItem.forEach(row => {
				let instanceTypes = row.instance_types
				if(!value) {
					allowedInstance = allowedInstance.filter(val => !instanceTypes.includes(val));
				} else {
					allowedInstance = [...allowedInstance, ...instanceTypes]
				}

				setState(prevState => ({ ...prevState, ["instance_types_"+item]: allowedInstance, onChangeInput: true }))

			})
		}
	}

	const checkSelectedInstances = useCallback((item) => {
		let instancesCategoryResult = state.totalInstances.filter(e => e.instance_category === state["instance_category_"+item])
		let instanceSeries = instancesCategoryResult && instancesCategoryResult.length && instancesCategoryResult[0].series ? instancesCategoryResult[0].series : []

		if(instanceSeries && instanceSeries.length) {
			instanceSeries.forEach(row => {
				let instanceTypes = row.instance_types

				let selectedInstanceTypes = state["instance_types_"+item] ? state["instance_types_"+item] : []
				let found = instanceTypes.every(e => selectedInstanceTypes.includes(e))

				setState(prevState => ({ ...prevState, ["allowed_series_"+row.series+"_"+item]: found }))

			})
		}
	}, [state])

	const setAllowedInstanceTypes = useCallback((type, item) => {
		let allowedInstance = state["instance_types_"+item] ? state["instance_types_"+item] : []
		let exist = allowedInstance.includes(type)
		if (exist) {
			allowedInstance = allowedInstance.filter(e => e !== type )
		} else {
			allowedInstance.push(type)
		}
		setState(prevState => ({ ...prevState, ["instance_types_"+item]: allowedInstance, onChangeInput: true }))

		checkSelectedInstances(item) //to check if all the instance type selected in a sereis
	}, [checkSelectedInstances, state])


	useEffect(() => { 
		if(state.onChangeInput) {
			setState(prevState => ({ ...prevState, onChangeInput: false }))
			let data = []
			state.policyArray.forEach(item => {
				let obj = {}
				obj.rule_category = props.masterData.rule_category
				obj.tags = props.masterData.tags
				obj.mode =  props.masterData.mode
				obj.enabled = state["enabled_"+item] ? true : false
				obj.rule_id = props.masterData.rule_id
				obj.resource_type = props.masterData.resource_type
				obj.template = true
				obj.governance_type = props.masterData.governance_type
	
				if(state["policy_id_"+item]) {
					obj.policy_id = state["policy_id_"+item]
				}
	
				let variables = []
				let masterVaiables = props.masterData.variables ? props.masterData.variables : []
				let inputMissing = false
				masterVaiables.forEach(itmVar => {
					let dependsOnFieldFullfiled = true
					let paddingLeft = 0
					if(itmVar.depends_on && itmVar.depends_on.length) {
						itmVar.depends_on.forEach(dep => {
							if(!state[dep.key+"_"+item] || state[dep.key+"_"+item] !== dep.value) {
								dependsOnFieldFullfiled = false
							}
						})
						paddingLeft = parseInt(2 + itmVar.depends_on.length)+"rem"
					}
					setState(prevState => ({ ...prevState, [itmVar.variable+"_depends_on"]: dependsOnFieldFullfiled, [itmVar.variable+"_paddingLeft"]: paddingLeft }))
	
					let inputValue = state[itmVar.variable+"_"+item]				
					if(itmVar.component === "daytimerange") {
						let dayTimeRange = ""
						if(state["day_"+item] && state["time_"+item] && state["time_"+item] !== "") {
							let splitTime = state["time_"+item].split("-")
							if(splitTime.length > 1) {
								splitTime.forEach((dT, index) => {
									if(index) {
										dayTimeRange += "-"	
									}
									dayTimeRange += state["day_"+item]+":"+dT
								})
							}
						}
						setState(prevState => ({ ...prevState, [itmVar.variable+"_"+item]: dayTimeRange }))
	
						inputValue = dayTimeRange
					}

					console.log("inputValue", inputValue)
					
					let dataRow = {}
					Object.entries(itmVar).forEach(([key, value]) => {		
						if(key === "value_list" || key === "value") {
							dataRow[key] = inputValue
						} else {
							dataRow[key] = value
						}
						if(typeof inputValue !== 'boolean') {
							if(!inputValue || (inputValue && Array.isArray(inputValue) && !inputValue.length)) {
								inputMissing = true
							}
						}
					})
					variables.push(dataRow)
				})
				obj.variables = variables
				obj.description = state.description

				console.log("obj", obj)
	
				if(state.actionDetails && state.actionDetails.length) {
					let actionObj = {}
					actionObj.variables = []
					if(state["selected_action_"+item]) {
						actionObj.processor_rule_id = state["action_processor_rule_id_"+item]
						actionObj.name = state["selected_action_"+item]				
						if(state["apply_method_"+item]) {
							actionObj.apply_method = state["apply_method_"+item]					
							if(state["schedule_"+item] && state["schedule_"+item] !== "") {
								if(state["apply_method_"+item] === "run_after") {
									actionObj.schedule = state["schedule_"+item]+""+state["time_type_"+item]
								} else if(state["apply_method_"+item] === "run_at") {
									actionObj.schedule = state["schedule_"+item]
								}
							}
						}
						obj.action = actionObj
					}
				}
	
				if(!inputMissing) {
					data.push(obj)
				}
			})
	
			let allPolicies = editNewRuleDetails.policies ? editNewRuleDetails.policies : []
	
			let currentPolicies = []
			let policies = []
	
			if(allPolicies.length) {
				allPolicies.forEach(row => {
					if(row.rule_id === props.masterData.rule_id) {
						currentPolicies.push(row)
					} else {
						policies.push(row)
					}
				})
			}
			if(data && data.length) {
				policies = [...data, ...policies]
			}
	
			setTimeout(() => { dispatch(setNewEditRuleDetails('policies', policies)) }, 500);
	
			setState(prevState => ({ ...prevState, inputDetected: true }))

		}
	}, [dispatch, editNewRuleDetails.policies, props.masterData.governance_type, props.masterData, state])

	useEffect(() => {
		if(onClickFinish) {
			dispatch(setGovernancePropsDetails('onClickFinish', false))
			let hasError = false
			state.policyArray.forEach(item => {
				if((!state["allow_"+item] || state["allow_"+item] === "") || (!state["instance_types_"+item] || state["instance_types_"+item] === "")) {
					hasError = true
				}

				// if(props.masterData.resource_type === 'RDS') {
				// 	if(!state["max_instances_to_use_"+item] || state["max_instances_to_use_"+item] === "") {
				// 		hasError = true
				// 	}
				// }
			})

			
			setState(prevState => ({ ...prevState, hasError }))
			
			props.validateInput(hasError)
		}
	}, [onClickFinish, props, state, dispatch])

	const onKeyDownTime = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			validateTime(str, state)
		} else {
			validateTime(time, state)
		}
	}

	const validateTime = (time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 23) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length === 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time	
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00:"
			} else {
				data += firstMinute+":"
			}
		}
		
		let firstSecond = str.substring(4, 6)
		
		if(firstSecond.length) {
			if(strLength > 5 && parseInt(firstSecond) > 59) {
				data += "00"
			} else {
				data += firstSecond
			}
		}

		setState(prevState => ({ ...prevState, [state]: data, onChangeInput: true }))
		
	}

	useEffect(()=> {
		if(state.callSavedData) {
			setState(prevState => ({ ...prevState, callSavedData: false }))
			let savedData = state.savedData
	
			let policyArray = []
			savedData.forEach((row, index) => {
				policyArray.push(index)
				setState(prevState => ({ ...prevState, ["policy_id_"+index]: row.policy_id }))
				if(row.action && Object.entries(row.action)) {
					Object.entries(row.action).forEach(([key, value]) => {
						if(key === "schedule") {
							let timeSplit = value.substring(0, value.length - 1)
							let timeTypeSplit = value.substring(value.length - 1, value.length)
							if(timeTypeSplit === "h" || timeTypeSplit === "m") {
								setState(prevState => ({ ...prevState, ["time_type_"+index]: timeTypeSplit, [key+"_"+index]: timeSplit }))
							} else {
								setState(prevState => ({ ...prevState, [key+"_"+index]: value, ["time_type_"+index]: "" }))
							}
						} else {
							if(key === "name") {
								key = "selected_action"
							}
							setState(prevState => ({ ...prevState, [key+"_"+index]: value }))
						}
					})
				}
				row.variables.forEach(item => {
					setState(prevState => ({ ...prevState, [item.variable+"_"+index]: item.value_list ? item.value_list : item.value, inputDetected: true }))
					if(item.variable === "instance_category") {
						getInstanceSeries(item.value, index)
					} else if(item.variable === "instance_types") {
						checkSelectedInstances(index)							
					}
							
					if(item.component === "daytimerange") {
						let timeRange = ""
						if(state[item.variable+"_"+index] && state[item.variable+"_"+index] !== "") {
							let splitTime = state[item.variable+"_"+index].split("-")
							if(splitTime.length > 1) {
								splitTime.forEach((dT, dTindex) => {
									let dayTime = dT.split(":")
									setState(prevState => ({ ...prevState, ["day_"+index]: dayTime[0] }))
									if(dTindex) {
										timeRange += "-"	
									}
									timeRange += dayTime[1]+":"+dayTime[2]
								})
							}
						}
						setState(prevState => ({ ...prevState, ["time_"+index]: timeRange }))
					}
	
				})
	
				if(row.enabled) {
					setState(prevState => ({ ...prevState, ["enabled_"+index]: true }))
				}
			})
			setState(prevState => ({ ...prevState, policyArray }))
		}
	}, [checkSelectedInstances, getInstanceSeries, state])

	const resetPolicy = (item) => {
		setState(prevState => ({ ...prevState, ["allow_"+item]: "", ["engine_type_"+item]: "", ["latest_"+item]: "", ["instance_category_"+item]: '', ["instance_series_"+item]: [], onChangeInput: true }))
	}
	
	// const deletePolicy = (item) => {
	// 	let array = state.policyArray.filter(e => e !== item)

	// 	if(!array.length) {
	// 		array = [0]
	// 	}
	// 	let deletedPolicies = editNewRuleDetails && editNewRuleDetails.deleted_policies ? editNewRuleDetails.deleted_policies : []
	// 	deletedPolicies.push(state["policy_id_"+item])
	// 	props.setNewEditRuleDetails("deleted_policies", deletedPolicies)
		
	// 	setState(prevState => ({ ...prevState, policyArray: array, ["show_child_policy_"+item]: false }))
	// 	resetPolicy(item)
	// }	

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
			{state.hasError ?
			    <p className='mb-0 text-danger'>Please check the input</p>
		    : null}
			
			{state.policyArray && state.policyArray.map((item, index) => {
				return(
					<React.Fragment key={'itt_'+index}>
					<div className={`${!props.viewMode ? "py-3" : "py-1"} bg-white border border-NeutralGray-600 rounded-2xl p-4 mt-3`}>
						{!props.viewMode ?
							<React.Fragment>
							<div className={`flex flex-wrap justify-between pl-2`}>
								<div className='flex flex-wrap w-3/5'>
									{(!state["allow_"+item] || state["allow_"+item] === "") && (!state["instance_category_"+item] || !state["instance_category_"+item].length) && (!state["instance_series_"+item] || state["instance_series_"+item] === "") ?
										<p className={`mx-2 font-medium text-base self-center mb-1 cursor-pointer`} onClick={() => showPolicy("policyArray", "show_child_policy", item)}>{props.masterData && props.masterData.description ? props.masterData.description : "Choose policy details"}</p>
									:
										<React.Fragment>
											<p className={`mx-2 font-medium text-base self-center mb-1 cursor-pointer`} onClick={() => showPolicy("policyArray", "show_child_policy", item)}>
												{state["allow_"+item] && state["allow_"+item] !== "" ?
													<span className="mr-2 text-info">{state["allow_"+item]}</span>
												: null}		
												{state["instance_category_"+item] && state["instance_category_"+item] !== "" ?
													<React.Fragment>
													<span className="mr-2">instance category</span>
													<span className="mr-2 text-info">{state["instance_category_"+item]}</span>
													</React.Fragment>
												: null}		
											</p>
											
											{state["instance_series_"+item] && state["instance_series_"+item].length ?
												<React.Fragment>
													<p className="mb-1 font-medium text-base self-center cursor-pointer mr-2">instance series</p>
													{state["instance_series_"+item] && state["instance_series_"+item].length ? 
														state["instance_series_"+item].map((row, rIndex) => {
															return(
																<span key={'series_'+rIndex} className="flex border border-lightGray rounded-full px-2 py-1 mr-2 self-center text-info">{row}
																	<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => removeSelectedDetails("instance_series_"+item, row) } />
																</span>
															)
														})
													: null}
												</React.Fragment>
											: null}
										</React.Fragment>
									}		
								</div>														
								
								<div className="flex flex-wrap justify-end self-center w-2/5">
									{state["tags"] && state["tags"].length ?
										state["tags"].map((tag, ttIndex) => {
											return(
												tag !== "" ?
													<span key={'tag_'+ttIndex} className={`bg-PrimaryLightOrangeTints px-4 py-1.5 mr-2 self-center text-black rounded-full text-sm`} > {tag} </span>
												: null
											)
										})
									: null}
									{!state["show_child_policy_"+index] ?
										<div className="flex">
											{/* <Icon icon="octicon:pencil-24" width='16' height='16' className="mr-1 self-center cursor-pointer text-black" onClick={() => showPolicy("policyArray", "show_child_policy", item)} /> */}
											<Icon icon={`icon-park-outline:${state["show_child_policy_"+item] ? 'up' : 'down'}`} width={24} height={24} className='bg-white h-6 w-6 text-black rounded-full cursor-pointer border border-DeepPurpleTints-600 self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)}/>
											{/* <Icon icon={`fa:angle-${state["show_child_policy_"+item] ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)} /> */}
										</div>
									: 
										<div className="flex">
											{/* <Icon icon="mdi:reload" className="text-black self-center cursor-pointer" width="18" height="18" onClick={() => resetPolicy(item)} /> */}
											{/* <span className={`fal fa-trash cursor-pointer mr-3 text-lightGray f15`} onClick={() => deletePolicy(item)}></span> */}
											<Icon icon={`icon-park-outline:${state["show_child_policy_"+item] ? 'up' : 'down'}`} width={24} height={24} className='bg-white h-6 w-6 text-black rounded-full cursor-pointer border border-DeepPurpleTints-600 self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)}/>
											{/* <Icon icon={`fa:angle-${state["show_child_policy_"+item] ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)} /> */}
										</div>
									}
								</div>
							</div>
							{state["show_child_policy_"+item] ?
								<div className={`pl-5`}>
									<div className={`flex flex-wrap mb-2`}>
										<div className={`form-check form-check-inline self-center mt-2 mr-2`}>
											{/* <Checkbox
                                                // label="Equal Monthly Budget"
                                                name={"enabled_"+item}
                                                selectedValue={state["enabled_"+item]}
                                                callback={() => {
                                                    setState(prevState => ({ ...prevState, ["enabled_"+item]: !state["enabled_"+item], onChangeInput: true }))
                                                }}
                                            /> */}
											<input
												className='form-check-input m-0'
												type='checkbox'
												checked={state["enabled_"+item]}
												onClick={e => setState(prevState => ({ ...prevState, ["enabled_"+item]: !state["enabled_"+item], onChangeInput: true }))}
												title={state["enabled_"+item] ? "not enable" : "enable"}								
											/>
											{!state["enabled_"+item] ?
												<span className="checkmark" onClick={e => setState(prevState => ({ ...prevState, ["enabled_"+item]: !state["enabled_"+item], onChangeInput: true }))}></span>
											: null}
										</div>
										{/* <span className={`fas fa-ban self-center mt-2 mr-2 ${state["disable_"+item] ? "text-issues" : "text-lightGray"}`} title={state["disable_"+item] ? "disable" : "not disbale"}  onClick={() => setState(prevState => ({ ...prevState, ["disable_"+item]: !state["disable_"+item], onChangeInput: true }))}></span> */}

										<div className='md:w-1/5 w-full md:pr-3'>
											<SelectOption
												fields={["value", "label"]}
												options={state.allow_options ? state.allow_options : []}
												selectedValues={state["allow_"+item] ? state["allow_"+item] : ''}
												callbackMultiSelect={(value) => {
													setState(prevState => ({ ...prevState, ["allow_"+item]: value, onChangeInput: true }))
												}}
												singleSelection={true}
												classDetails={{border: "border-b border-NeutralGray-600", rounded: ' ', padding: 'p-1', bg: ' '}}
											/>
										</div>
										<p className={`mb-0 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-issues" : "text-black"}`}>Instance Category</p>
										<div className='md:w-1/5 w-full md:pr-3'>
											<SelectOption
												options={state.instance_category_options ? state.instance_category_options : []}
												selectedValues={state["instance_category_"+item] ? state["instance_category_"+item] : ''}
												callbackMultiSelect={(value) => {
													setState(prevState => ({ ...prevState, ["instance_category_"+item]: value, ["instance_series_"+item]: [], ["instance_types_"+item]: [], ["instance_series_options_"+item]: [], onChangeInput: true }))
													getInstanceSeries(value, item)
												}}
												singleSelection={true}
												classDetails={{border: "border-b border-NeutralGray-600", rounded: ' ', padding: 'p-1', bg: ' '}}
											/>
										</div>
									</div>
									<div className={'flex flex-wrap mb-2 ml-10'}>
										<p className={`mb-0 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-issues" : "text-black"}`}>Instance series</p>
										<div className='md:w-1/5 w-full md:pr-3'>
											<SelectOption
												options={state["instance_series_options_"+item]}
												selectedValues={state["instance_series_"+item] ? state["instance_series_"+item] : []}
												callbackMultiSelect={(value) => {
													setState(prevState => ({ ...prevState, ["instance_series_"+item]: value, onChangeInput: true }))
												}}
												classDetails={{border: "border-b border-NeutralGray-600", rounded: ' ', padding: 'p-1', bg: ' '}}
											/>
										</div>
										{state["instance_series_"+item] && state["instance_series_"+item].length ? 
											state["instance_series_"+item].map((row, rrIndex) => {
												return(
													<span key={'ins_'+rrIndex} className="flex border border-lightGray rounded-full px-2 py-1 mr-2 mt-2 self-center text-info">{row}
														<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => removeSelectedDetails("instance_series_"+item, row) } />
													</span>
												)
											})
										: null}
									</div>
									{state["instanceSeries_"+item] && state["instanceSeries_"+item].length ? 
										state["instanceSeries_"+item].map((row, rrrIndex) => {
											return(
												<React.Fragment key={'row_'+rrrIndex}>
												{state["instance_series_"+item] && state["instance_series_"+item].includes(row.series) ?
													<div className={'bg-BlockWhite rounded mb-2 p-3 w-90'} style={{marginLeft:"45px"}}>
														<div className="form-group form-check mb-3">
															{/* <Checkbox
																// label="Equal Monthly Budget"
																name={"allowed_series_"+row.series+"_"+item}
																selectedValue={state["allowed_series_"+row.series+"_"+item]}
																callback={() => {
																	setState(prevState => ({ ...prevState, ["allowed_series_"+row.series+"_"+item]: !state["allowed_series_"+row.series+"_"+item] }))
																	setAllowedInstanceSeries(item, !state["allowed_series_"+row.series+"_"+item], row.series)
																}}
															/> */}
															<input 
																type="checkbox" 
																className="form-check-input larger" 
																checked={ state["allowed_series_"+row.series+"_"+item]}
																onChange={e => {
																	setState(prevState => ({ ...prevState, ["allowed_series_"+row.series+"_"+item]: !state["allowed_series_"+row.series+"_"+item] }))
																	setAllowedInstanceSeries(item, !state["allowed_series_"+row.series+"_"+item], row.series)
																}}
															/>
															<label className="form-check-label text-lg text-info ml-2" onClick={() => {
																setState(prevState => ({ ...prevState, ["allowed_series_"+row.series+"_"+item]: !state["allowed_series_"+row.series+"_"+item] }))
																setAllowedInstanceSeries(item, !state["allowed_series_"+row.series+"_"+item], row.series)
															}}>{row.series}</label>
														</div>
														<div className="flex flex-wrap">
															{row.instance_types && row.instance_types.map((inFam, i) => {
																return(
																	<div className="form-group form-check mr-3 mb-3">
																		{/* <Checkbox
																			label={inFam}
																			name={"allowed_series_"+row.series+"_"+item}
																			selectedValue={state["instance_types_"+item] && state["instance_types_"+item].length && state["instance_types_"+item].includes(inFam) ? true : false}
																			callback={() => {
																				setAllowedInstanceTypes(inFam, item)
																			}}
																		/> */}
																		<input 
																			type="checkbox" 
																			className="form-check-input" 
																			checked={ state["instance_types_"+item] && state["instance_types_"+item].length && state["instance_types_"+item].includes(inFam) ? true : false }
																			onChange={e => setAllowedInstanceTypes(inFam, item)}
																		/>
																		<label className="form-check-label text-md text-lightGray ml-1" onClick={ () => setAllowedInstanceTypes(inFam, item)}>{inFam}</label>
																	</div>
																)
															})}
														</div>
													</div>
												: null}
												</React.Fragment>
											)
										})
									: null}
							
									{state.actionDetails && state.actionDetails.length ?
										<div className="rounded bg-GhostWhite p-3 mt-5">
											<p className="mb-0 text-lg text-purple3 cursor-pointer">Rule Action</p>
											<div className={`flex flex-wrap mb-2`}>
												<p className={`mb-0 mt-2 self-center`}>Select Action</p>
												<div className='min-w-32 mr-2' 
													onClick={(event) => {
														if(!state["showAction_"+item]) {
															event.preventDefault();
															handleChildClick(event, 'child', 'singleDropDown', "showAction_"+item)
														}
													}}
												>
													<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["selected_action_"+item] ? 'text-black' : 'text-lightGray'}`}>
														{state["selected_action_"+item] ? state["selected_action_"+item] : 'Select'}
														<Icon icon="icon-park-solid:down-one" className={`${state["selected_action_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
													</p>
													{state["showAction_"+item] ?
														<MultiSelectSection
															fields={["action", "action"]}
															options={state.actionDetails ? state.actionDetails : []}
															selectedValues={state["selected_action_"+item] ? state["selected_action_"+item] : ''}
															callbackMultiSelect={(value) => {
																if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																	setState(prevState => ({ ...prevState, 
																		["selected_action_"+item]: value,
																		["action_processor_rule_id_"+item]: state.actionDetails.filter(e => e.action === value) && state.actionDetails.filter(e => e.action === value).length && state.actionDetails.filter(e => e.action === value)[0].processor_rule_id ? state.actionDetails.filter(e => e.action === value)[0].processor_rule_id : "",
																		onChangeInput: true
																	}))
																} else {
																	value.preventDefault()
																	handleChildClick(value, "search", 'singleDropDown', "")
																}
															}}
															singleSelection={true}
															hideSearch={false}
															topClass={'auto'}
															widthClass={'minWidth220'}
															removeTopOptions={true}
														/>
													: null}
												</div>
											</div>
											
											{state["selected_action_"+item] ?
												<div className={`flex flex-wrap mb-2 ml-5`}>
													<p className={`mb-0 mt-2 self-center`}>Apply Method</p>
													<div className='min-w-32 mr-2' 
														onClick={(event) => {
															if(!state["showMethod_"+item]) {
																event.preventDefault();
																handleChildClick(event, 'child', 'singleDropDown', "showMethod_"+item)
															}
														}}
													>
														<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["apply_method_"+item] ? 'text-black' : 'text-lightGray'}`}>
															{state["apply_method_"+item] && props.actionMethods ? props.actionMethods.filter(e => e.value === state["apply_method_"+item])[0].label : 'Select'}
															<Icon icon="icon-park-solid:down-one" className={`${state["apply_method_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
														</p>
														{state["showMethod_"+item] ?
															<MultiSelectSection
																fields={["value", "label"]}
																options={props.actionMethods ? props.actionMethods : []}
																selectedValues={state["apply_method_"+item] ? state["apply_method_"+item] : ''}
																callbackMultiSelect={(value) => {
																	if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																		setState(prevState => ({ ...prevState, ["apply_method_"+item]: value, ["schedule_"+item]: "", onChangeInput: true }))
																	} else {
																		value.preventDefault()
																		handleChildClick(value, "search", 'singleDropDown', "")
																	}
																}}
																singleSelection={true}
																hideSearch={false}
																topClass={'auto'}
																widthClass={'minWidth220'}
																removeTopOptions={true}
															/>
														: null}
													</div>
													{state["apply_method_"+item] && state["apply_method_"+item] !== "immediately" ?
														<React.Fragment>
														<p className={`mb-0 ml-1 mr-2 self-center`}>Schedule</p>
														{state["apply_method_"+item] === "run_at" ?
															<input 
																type="text" 
																className={`bg-transparent border-b border-lightGray px-2 py-1 text-black min-w-32`}
																style={{minHeight: "38px"}}
																placeholder={"HH:MM:SS"}
																value={state["schedule_"+item]}
																maxLength="8"
																onKeyDown={e => onKeyDownTime(e, e.target.value, "schedule_"+item)}
																onChange={e => validateTime(e.target.value, "schedule_"+item)}
															/>
														: state["apply_method_"+item] === "run_after" ?
															<div className="flex">
																<input 
																	type="text" 
																	className={`bg-transparent border-b border-lightGray px-2 py-1 text-black min-w-32`}
																	style={{minHeight: "38px"}}
																	placeholder={""}
																	value={state["schedule_"+item]}
																	maxLength="2"
																	onChange={e => setState(prevState => ({ ...prevState, ["schedule_"+item]: onlyNumeric(e.target.value), onChangeInput: true }))}
																/>
																<div className='min-w-32 mr-2' 
																	onClick={(event) => {
																		if(!state["showTimeType_"+item]) {
																			event.preventDefault();
																			handleChildClick(event, 'child', 'singleDropDown', "showTimeType_"+item)
																		}
																	}}
																>
																	<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-0 mx-2 truncate cursor-pointer ${state["time_type_"+item] ? 'text-black' : 'text-lightGray'}`}>
																		{state["time_type_"+item] && state.timeTypeOptions ? state.timeTypeOptions.filter(e => e.value === state["time_type_"+item])[0].label : 'Select'}
																		<Icon icon="icon-park-solid:down-one" className={`${state["time_type_"+item] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
																	</p>
																	{state["showTimeType_"+item] ?
																		<MultiSelectSection
																			fields={["value", "label"]}
																			options={state.timeTypeOptions ? state.timeTypeOptions : []}
																			selectedValues={state["time_type_"+item] ? state["time_type_"+item] : ''}
																			callbackMultiSelect={(value) => {
																				if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																					setState(prevState => ({ ...prevState, ["time_type_"+item]: value, onChangeInput: true }))
																				} else {
																					value.preventDefault()
																					handleChildClick(value, "search", 'singleDropDown', "")
																				}
																			}}
																			singleSelection={true}
																			hideSearch={false}
																			topClass={'auto'}
																			widthClass={'minWidth220'}
																			removeTopOptions={true}
																		/>
																	: null}
																</div>
															</div>

														: null}
														</React.Fragment>
													: null}
												</div>
											: null}
										</div>
									: null}
								</div>
							: null}
							</React.Fragment>
						: 
							<div className="flex justify-between ml-3">
								<div className={`w-80"`}>
									<div className={`flex flex-wrap mb-2`}>
										<p className={`mb-0 mt-2 self-center text-info`}>{state["allow_"+item] && state["allow_"+item] !== "" && state.allow_options ? state.allow_options.filter(e => e.value === state["allow_"+item])[0].label : ""}</p>

										<p className={`mb-0 ml-2 mt-2 self-center ${props.selectedModeType === state.mode_instance_category ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-black") : "text-black"}`}>instance category</p>

										<p className={`mb-0 ml-2 mt-2 self-center text-info`}>{state["instance_category_"+item] ? state["instance_category_"+item] : ""}</p>
									</div>
									
									<div className={'flex flex-wrap mb-2 ml-3'}>
										<p className={`mb-0 ml-2 mt-2 self-center ${props.selectedModeType === state.mode_instance_series ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-black") : "text-black"}`}>instance series</p>

										{state["instance_series_"+item] && state["instance_series_"+item].length ? 
											state["instance_series_"+item].map((row, sIndex) => {
												return(
													<span key={'rows_'+sIndex} className="border border-lightGray px-2 py-1 mr-2 self-center text-info">{row}</span>
												)
											})
										: null}
									</div>

									<div className={`flex flex-wrap mb-2 ml-3`}>
										{state["instanceSeries_"+item] && state["instanceSeries_"+item].length ? 
											state["instanceSeries_"+item].map((row, ssIndex) => {
												return(
													<React.Fragment key={'rowss_'+ssIndex}>
													{state["instance_series_"+item] && state["instance_series_"+item].includes(row.series) ?
														<div className={'bg-BlockWhite rounded mb-2 p-3 w-100'}>
															<div className="form-group form-check mb-3">
																<input 
																	type="checkbox"
																	className="form-check-input larger disabled" 
																	checked={ state["allowed_series_"+row.series+"_"+item]}
																/>
																<label className="form-check-label f18 text-info">{row.series}</label>
															</div>
															<div className="flex flex-wrap">
																{row.instance_types && row.instance_types.map((inFam, i) => {
																	return(
																		<div key={'rowi_'+i} className="form-group form-check mr-3 mb-3">
																			<input 
																				type="checkbox" 
																				className="form-check-input" 
																				checked={ state["instance_types_"+item] && state["instance_types_"+item].length && state["instance_types_"+item].includes(inFam) ? true : false }
																			/>
																			<label className="form-check-label f16">{inFam}</label>
																		</div>
																	)
																})}
															</div>
														</div>
													: null}
													</React.Fragment>
												)
											})
										: null}
									</div>									
										
									{state["selected_action_"+item] && state["selected_action_"+item] !== "" ?
										<div className="rounded bg-GhostWhite p-3 ml-4 mt-2">
											<div className={`flex flex-wrap mb-2`}>
												<span className={`mb-0 ml-2 mt-2 self-center text-lightGray`}>Selected Action <span className="text-black mx-1">{state["selected_action_"+item]}</span></span>
												{state["apply_method_"+item] && state["apply_method_"+item] ?
													<span className={`mb-0 ml-2 mt-2 self-center text-lightGray`}><span className="text-black mx-1">{props.actionMethods.filter(e => e.value === state["apply_method_"+item]) && props.actionMethods.filter(e => e.value === state["apply_method_"+item]).length ? props.actionMethods.filter(e => e.value === state["apply_method_"+item])[0].label : ""}</span></span>
												: null}
												{state["schedule_"+item] && state["schedule_"+item] ?
													<span className={`mb-0 ml-2 mt-2 self-center text-lightGray`}><span className="text-black mx-1">{state["schedule_"+item]} {state["time_type_"+item] !== "" ? <span className="ml-1">{state["time_type_"+item]}</span>: null}</span></span>
												: null}
											</div>
										</div>
									: null}
								</div>
								{!index ?
									<div className={`flex flex-wrap w-20 self-start justify-end mt-2`}>
										{state["tags"] && state["tags"].length ?
											state["tags"].map(tag => {
												return(
													tag !== "" ?
														<span className={`border border-lightGray px-2 py-1 mr-2 self-center text-info ml-2 mb-2`} > {tag} </span>
													: null
												)
											})
										: null}
									</div>
								: null}
							</div>
						}
					</div>
					{state.policyArray.length === (index+1) && !props.viewMode ?
						<div className='mt-3 mb-6 flex justify-start'>
							<p className='text-primaryPurple-600 rounded-full self-center cursor-pointer text-base text-center min-w-28' onClick={() => addPolicies("policyArray", "show_child_policy", item+1)}>+ Add new Capacity policy</p>
							{/* <p className='mb-4 text-primaryPurple-600 border border-primaryPurple-600 bg-white pr-4 pl-3 py-2.5 rounded-full self-center cursor-pointer text-base text-center min-w-28' onClick={() => addPolicies("policyArray", "show_child_policy", item+1)}>+ Add new Capacity policy</p> */}
						</div>
					: null}
					</React.Fragment>
				)
			})}
		</div>
	)
}

export default RdsServiceCapacity
