/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file Inventory.js
 * @author Prakash // on 18/06/2023
 * @copyright © 2022 Collider. All rights reserved.
 * 
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions

import { listAssetsNew } from '../../../actions/assets/assetsAction'
import { getCoveredServicesNew } from '../../../actions/detectors/detectorsAction'

import { UncontrolledTooltip } from 'reactstrap'
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import { CSVLink } from "react-csv";

import Search from '../../common/SearchWithHiddenInput'
import {Icon} from "@iconify/react";

let searchAssetInventory = null

const Inventory = (props) => {
    
    const [state, setState] = useState({
        showLoading: true,

        catServiceType: ["Category", "Services"],
        selectedCatSer: "Services", 

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 100,
        changesInFilter: true,
        filteredAssetList: [],
        callGetCoveredServices: true
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.callGetCoveredServices) {
            let params = {}
            params.provider = props.selectedProvider && props.selectedProvider !== "" ? props.selectedProvider.toLowerCase() : ""
            if(props.selectedAccount && props.selectedAccount.length && !props.selectedAccount.includes("All")) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length && !props.selectedRegion.includes("All")) {
                params.region = props.selectedRegion
            }
            let aggregateBy = "resource_type"
            if(state.selectedCatSer === "Category") {
                aggregateBy = "category"
            }
            params.aggregate_by = [aggregateBy, "service_name"]
            params.assets_present = true
            params.radial = true
            dispatch(getCoveredServicesNew(params))
                .then((response) => {
                    let results = response && response.results && response.results.length ? response.results : []
                    let selectedCatService = results.length && results[0] ? results[0] : {}
                    let selectedService = selectedCatService.data && Object.entries(selectedCatService.data).length ? Object.keys(selectedCatService.data)[0] : ""

                    let selectedCategory = results.length && results[0] ? results[0].resource_type : ""
                    if(state.selectedCatSer === "Category") {
                        selectedCategory = results.length && results[0] ? results[0].category : ""
                    }
                    
                    setState(prevState => ({ ...prevState, categoryList: results, filterCategoryList: results, selectedCategory, selectedCatService, selectedService, selectedServiceCall: true, showLoading: false, assetListLoader: false, callGetCoveredServices: false }))
                })
                
        }
    }, [dispatch, state.callGetCoveredServices, props.selectedProvider, props.selectedAccount, props.selectedRegion, state.selectedCatSer])

    const onClickCategory = (item) => {
        let selectedCategory = item.category ? item.category : item.resource_type

        let selectedService = item.data && Object.entries(item.data).length ? Object.keys(item.data)[0] : ""

        setState(prevState => ({ ...prevState, selectedCategory, selectedCatService: item, selectedService, selectedServiceCall: true, assetsList: [], filteredAssetList: [], assetListLoader: true }))
    }

    useEffect(() => {
        if(state.selectedServiceCall) {
            setState(prevState => ({ ...prevState, selectedServiceCall: false }))
            let params = {}
            params.provider = props.selectedProvider && props.selectedProvider !== "" ? props.selectedProvider.toLowerCase() : ""
            if(props.selectedAccount && props.selectedAccount.length && !props.selectedAccount.includes("All")) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length && !props.selectedRegion.includes("All")) {
                params.region = props.selectedRegion
            }
            params.service_name = state.selectedService

            // if(state.assetResponse && state.assetResponse.next_token && state.fetchRecord === 'next') {
            //     params.next_token = state.assetResponse.next_token
            // } else if(state.assetResponse && state.assetResponse.next_token && state.fetchRecord === 'previous') {
            //     params.previous_token = state.assetResponse.previous_token
            // }

            params.size = 1000

            if(state.searchText) {
                params.asset_search = state.searchText
            }

            // assetResponse: response,
            
            dispatch(listAssetsNew(params))
                .then((response) => {
                    let results = response && response.results && response.results.length ? response.results : []
    
                    let totalPages = 1
                    if(results && results.length > state.perPage) {
                        totalPages = Math.ceil(results.length / state.perPage)
                    }
    
                    setState(prevState => ({ ...prevState, assetsList: results, filteredAssetList: results, totalPages, currentPage: 1, startRecord: 0, totalRecords: response && response.total ? response.total : 0, assetListLoader: false, callFormHeaders: true, showLoading: false, selectedServiceCall: false }))
                })
        }
    }, [state.selectedServiceCall, dispatch, props.selectedAccount, props.selectedProvider, props.selectedRegion, state.perPage, state.selectedService, state.searchText])

	useEffect(() => {
        if(state.callFormHeaders) {
            let dataResult = state.filteredAssetList && state.filteredAssetList.length ? state.filteredAssetList : []
            if(dataResult.length) {
                let headers = []
                Object.entries(dataResult[0]).forEach(([key, value]) => {
                    if(typeof value === "string") {
                        let headerRow = {}
                        headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                        headerRow.key = key
                        headers.push(headerRow)
                    }
                })
                setState(prevState => ({ ...prevState, headers, callFormHeaders: false }))
            } else {
                setState(prevState => ({ ...prevState, callFormHeaders: false }))
            }
        }
	}, [state.filteredAssetList, state.callFormHeaders])

    const navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = state.startRecord
            if(action === 'next' && currentPage === state.totalPages && state.assetResponse  && state.assetResponse.next_token) {
                setState(prevState => ({ ...prevState, showLoading: true, fetchRecord: 'next', selectedServiceCall: true }))
            } else if(action === 'previous' && currentPage === 1 && state.assetResponse  && state.assetResponse.previous_token) {
                setState(prevState => ({ ...prevState, showLoading: true, fetchRecord: 'previous', selectedServiceCall: true }))
            } else if(action === 'next' && currentPage !== state.totalPages) {
                startRecord = startRecord + state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = state.totalPages
                startRecord = (state.totalPages - 1) * state.perPage
            }

            setState(prevState => ({ ...prevState, currentPage, startRecord }))
        } 
    }  

    const getAssetDetails = (item) => {
        setState(prevState => ({
            ...prevState, 
            selectedAssetId: item.asset_id === state.selectedAssetId ? "" : item.asset_id, 
            selectedAsset: item.asset_id === state.selectedAssetId ? "" : item,
            expandAssetDetails: item.asset_id === state.selectedAssetId ? false : true,
        }))
    }

    const handleChange = (e) => {
        setState(prevState => ({  ...prevState, selectedCatSer: e.target.value,  showServiceTypeOptions: false, categoryList: [], filterCategoryList: [], emptySearchText: true, selectedCatService: [], assetListLoader: true , callGetCoveredServices: true }))
    }

    const handleClickOutside = (event) => {
    }

    return (
        <div className='min-h-100' onClick={ handleClickOutside}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='m-2'>
                <div className="flex flex-wrap justify-between">
                    <div className='flex flex-wrap w-full'>
                        <div className='flex md:w-3/5 w-full'>
                            <select className="self-center bg-transparent border-b text-black text-xs w-fit px-2 pb-1 focus-visible:border-b border-mediumDarkGray mr-2" onChange={handleChange} value={state.selectedCatSer}>
                                {state.catServiceType.map(item => {
                                    return(
                                        <option key={item} className='text-black' value={item}>{item}</option>
                                    )
                                })}
                            </select>
                            {state.categoryList && state.categoryList.length ?
                                <Search
                                    data={state.categoryList ? state.categoryList : []}
                                    topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                    searchClassName={'px-2'}
                                    searchIconColor={'text-black'}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent text-black px-2 py-1'}
                                    widthHeight={25}
                                    filteredData={(filterCategoryList, searchText) => {
                                        setState(prevState => ({ ...prevState, filterCategoryList, emptySearchText: false }))
                                    }}
                                    resetSearchBox={state.emptySearchText}
                                />
                            : null}
                        </div>
                        <div className='flex md:w-2/5 w-full self-center justify-end'>
                            <CSVLink 
                                data={state.filteredAssetList} 
                                headers={state.headers ? state.headers : []}
                                filename={"resource-inventory.csv"}
                                className={"self-center"}
                                target="_blank"
                            >
                                <Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
                            </CSVLink>
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap item-stretch'>
                    <div className="lg:w-1/12 md:w-2/12 w-full border-r border-info">
                        <div className="bg-white w-100 overflow-auto h-full">
                            {state.filterCategoryList && state.filterCategoryList.length ?
                                state.filterCategoryList.map((item, i) => {
                                    return(
                                    <div key={"category_"+i} className={`px-2 cursor-pointer ${i ? "border-top" : ""} ${state.selectedCatService && state.selectedCatService.category === (item.category || item.resource_type) ? 'text-info disabled' : ''}`} onClick={() => onClickCategory(item)}>
                                        <div className="hiddenEllipses d-flex" id={"category_"+i}>
                                            <p className={`m-0 f-20 pl pr-1 pt-2 pb-2 cursor-pointer ${state.selectedCategory && state.selectedCategory === (item.category || item.resource_type) ? 'text-info' : 'text-black'}`}>{item.category ? item.category : item.resource_type}</p>
                                        </div>
                                        <UncontrolledTooltip placement='right' target={"category_"+i}>
                                            <div className='bg-GhostWhite rounded-md p-3'>
                                                <div className="text-left">  
                                                    <p className="mb-1 text-lightGray"><span className="text-black mr-1">{state.selectedCatSer}: </span>{(item.category ? item.category : item.resource_type) + (item.total ? " ("+item.total+") " : "")}</p>
                                                    <p className="mb-1 text-black">Assets: </p>
                                                    {item.data && Object.entries(item.data).length ?
                                                        Object.entries(item.data).map(([key, value], j) => {
                                                            return(
                                                                <p key={"asset_"+j} className="mb-1 text-lightGray">{key+" : "+value}</p>
                                                            )
                                                        })
                                                    : null}
                                                </div>    
                                            </div>
                                        </UncontrolledTooltip>
                                    </div>
                                    )
                                })
                            : null}
                        </div>
                    </div>
                    <div className="lg:w-2/12 md:w-3/12 w-full">
                        <div className="bg-white w-100 overflow-auto h-full">
                            {state.selectedCatService && state.selectedCatService.data ?
                                Object.entries(state.selectedCatService.data).map(([key, value], i) => {
                                    return(
                                    <div key={"selectedCatService_"+i} className={`px-2 cursor-pointer ${i ? "border-top" : ""} ${state.selectedService === key ? 'text-info disabled' : ''}`} onClick={() => setState(prevState => ({ ...prevState,assetListLoader: true, selectedService: key, selectedServiceCall: true }))}>
                                        <div className="hiddenEllipses d-flex" id={"services_"+i}>
                                            <p className={`m-0 f-20 pl pr-1 pt-2 pb-2 cursor-pointer ${state.selectedService === key ? 'text-info' : 'text-black'}`}>{key + (value ? " ("+value+") " : "")}</p>
                                        </div>
                                        <UncontrolledTooltip target={"services_"+i}>
                                            <div className='bg-GhostWhite rounded-md p-3'>
                                                <div className="text-left">  
                                                    <p className="mb-1 text-lightGray"><span className="text-black mr-1">Asset: </span>{key + (value ? " ("+value+") " : "")}</p>
                                                </div>    
                                            </div>
                                        </UncontrolledTooltip>
                                    </div>
                                    )
                                })
                            : null}
                        </div>
                    </div>
                    <div className="lg:w-9/12 md:w-7/12 w-full">
                        <div className='h-full'>
                            {state.assetListLoader ? 
                                <div className='flex justify-center m-4'>
                                    <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                </div>
                            : 
                                state.filteredAssetList ?
                                    <div className="bg-white w-full h-full">
                                        <div className="flex flex-wrap justify-between mb-1 pt-2 bg-whie pb-2">
                                            <p className="mb-0 self-center lg:w-3/5 w-full">Showing 
                                                {state.assetResponse && state.assetResponse.previous_token && !state.assetResponse.next_token ? ' last ' : ''}
                                                <span className="ml-1">{state.filteredAssetList && state.filteredAssetList.length} of total {state.totalRecords} assets in </span>
                                                <span className="text-info">{state.selectedService}</span>
                                            </p>
                                            <div className="flex flex-wrap justify-end lg:w-2/5 w-full">
                                                {state.filteredAssetList && state.filteredAssetList.length > state.perPage ?
                                                    <div className='flex justify-end self-center  text-black pr-2 w-full'>
                                                        <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                                        <div className="pagination text-sm">
                                                            <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                                            <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
                                                            {/* <span>
                                                                Go to
                                                                <input
                                                                    type='number'
                                                                    value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                                                    onChange={e => {
                                                                        const page = e.target.value ? Number(e.target.value) : ""
                                                                        gotoPage(page)
                                                                    }}
                                                                    className="ml-1 inputClass"
                                                                />
                                                            </span> */}
                                                            <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                                            <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                                                        </div>
                                                    </div>
                                                : null}
                                                <div className='w-full pr-2'>
                                                    {/* {state.assetsList && state.assetsList.length ?
                                                        <Search
                                                            data={state.assetsList ? state.assetsList : []}
                                                            topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                                            searchClassName={'px-2'}
                                                            searchIconColor={'text-black'}
                                                            searchPlaceHolder={'Search....'}
                                                            className={'bg-transparent text-black px-2 py-1 w-full'}
                                                            widthHeight={25}
                                                            filteredData={(filteredAssetList) => {
                                                                let totalPages = 1
                                                                if(filteredAssetList && filteredAssetList.length > state.perPage) {
                                                                    totalPages = Math.ceil(filteredAssetList.length / state.perPage)
                                                                }

                                                                setState(prevState => ({ ...prevState, filteredAssetList, totalPages, currentPage: 1, startRecord: 0 }))
                                                            }}
                                                        />
                                                    : null} */}
                                                    <div className='flex border border-lightGray rounded-md'>
                                                        <Icon icon={`bitcoin-icons:search-outline`} className={`text-black w-5 self-center`} width={25} height={25} />
                                                        <div className={`w-11/12`}>
                                                            <input
                                                                value={state.searchText}
                                                                onChange={e => {
                                                                    setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                                                    clearTimeout(searchAssetInventory)
                                                                    if(e.target.value.length > 2) {
                                                                        searchAssetInventory = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, nextCatalogToken: [], startRecord: 0, currentPage: 1, totalPages: 1, assetsList: [], filteredAssetList: [], showLoading: true, selectedServiceCall: true }))}, 1000);
                                                                    } else if(!e.target.value.length) {
                                                                        setState(prevState => ({ ...prevState, startRecord: 0, currentPage: 1, totalPages: 1, assetsList: [], filteredAssetList: [], showLoading: true, selectedServiceCall: true }))
                                                                    }
                                                                }}
                                                                type='text'
                                                                className={`bg-transparent text-black text-md pl-1 py-1 w-full`}
                                                                placeholder='Search'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>                                                 
                                            </div>
                                        </div>
                                        
                                        {state.filteredAssetList && state.filteredAssetList.length ?
                                            state.filteredAssetList.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                                return(
                                                    <div key={"filteredAssetList_"+i} className={`p-2 ${i ? "border-t border-lightGray" : ""} ${state.selectedAssetId && state.selectedAssetId === item.asset_id ? "bg-GhostWhite" : ""}`}> 
                                                        <div className={`flex cursor-pointer ${state.selectedAssetId && state.selectedAssetId === item.asset_id ? "pb-1" : ""}`} onClick={() => getAssetDetails(item)}>
                                                            <p className={`mb-0 break-all ${state.selectedAssetId && state.selectedAssetId === item.asset_id ? "text-black" : "text-black"}`}>
                                                            <span className="mb-0 small mr-1 ">Resource</span>
                                                                {item.account_id ?
                                                                    <span className="mb-0 f12  font-semibold">{' '+item.account_id}</span>
                                                                : null}
                                                                {item.region ?
                                                                    <span className="mb-0 f12  font-semibold">{' : '+item.region}</span>
                                                                : null}
                                                                {item.resource_type ?
                                                                    <span className="mb-0 f12  font-semibold">{' : '+item.resource_type}</span>
                                                                : null}
                                                                {item.service_name ?
                                                                    <span className="mb-0 f12  font-semibold mr-2">{' : '+item.service_name}</span>
                                                                : null}
                                                                {item.id_name_map ?
                                                                    <React.Fragment>
                                                                    <span className="mb-0 mr-2 f12 text-info font-semibold">
                                                                        {item.id_name_map.value}
                                                                    </span>
                                                                    {item.id_name_map.key ?
                                                                        <span className="mb-0 mr-2 f12 text-info font-semibold">
                                                                            {item.id_name_map.key}
                                                                        </span>
                                                                    : null}
                                                                    </React.Fragment>
                                                                : item.asset_name ?
                                                                    <span className="mb-0 mr-2 f12 text-info font-semibold">{item.asset_name}</span>
                                                                : null}
                                                                {item.asset_id ?
                                                                    <span className="mb-0 mr-2 f12 font-semibold">{item.asset_id}</span>
                                                                : null}
                                                                {item.asset_arn && item.asset_arn !== item.asset_name ?
                                                                    <span className="mb-0 mr-2 f12 font-semibold">{item.asset_arn}</span>
                                                                : null}                
                                                            </p>
                                                        </div>
                                                        {state.selectedAssetId && state.selectedAssetId === item.asset_id ?
                                                            <React.Fragment>
                                                                <div className={`bg-GhostWhite p-2 rounded-md border-0 mb-2`}>
                                                                    <div className='cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, expandAssetDetails: !state.expandAssetDetails }))}>
                                                                        <div className="p-3 mb-0 flex" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                            {state.expandAssetDetails ?
                                                                                <Icon icon="zondicons:minus-outline" className='mr-1 self-center cursor-pointer text-black' width={16} height={16} />
                                                                            : 
                                                                                <Icon icon="tabler:circle-plus" className='mr-1 self-center cursor-pointer text-black' width={20} height={20} />
                                                                            }
                                                                            <p className="b-block text-black mb-0">Asset Details</p>
                                                                        </div>
                                                                    </div>

                                                                    {state.expandAssetDetails ?
                                                                        <div className="p-3">
                                                                            <div className="flex flex-wrap mb-2">
                                                                                <div className="py-1 lg:w-1/3 md:w-1/2 w-full">
                                                                                    <p className="b-block mb-0 text-lightGray">Name</p>
                                                                                    <p className="mb-0 text-black break-all">{state.selectedAsset.asset_name ? state.selectedAsset.asset_name : ""}</p>
                                                                                </div>
                                                                                <div className="py-1 lg:w-1/3 md:w-1/2 w-full md:pl-3 pl-0">
                                                                                    <p className="b-block mb-0 text-lightGray">Id</p>
                                                                                    <p className="mb-0 text-black break-all">{state.selectedAsset.asset_id ? state.selectedAsset.asset_id : ""}</p>
                                                                                </div>
                                                                                <div className="py-1 lg:w-1/3 w-full lg:pl-3 pl-0">
                                                                                    <p className="b-block mb-0 text-lightGray">ARN</p>
                                                                                    <p className="mb-0 text-black">{state.selectedAsset.asset_arn ? state.selectedAsset.asset_arn : ""}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex flex-wrap mb-2 border-t border-lightGray">
                                                                                <div className="py-1 lg:w-1/3 md:w-1/2 w-full">
                                                                                    <p className="b-block mb-0 text-lightGray">Resource</p>
                                                                                    <p className="mb-0 text-black break-all">
                                                                                        {state.selectedAsset.provider ? state.selectedAsset.provider.toUpperCase() : ""}
                                                                                        {state.selectedAsset.account_id ? (" : ")+state.selectedAsset.account_id : ""}
                                                                                        {state.selectedAsset.region ? (" : ")+state.selectedAsset.region : ""}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="py-1 lg:w-1/3 md:w-1/2 w-full md:pl-3 pl-0">
                                                                                    <p className="b-block mb-0 text-lightGray">Services</p>
                                                                                    <p className="mb-0 text-black break-all">
                                                                                        {state.selectedAsset.resource_type ? state.selectedAsset.resource_type : ""}
                                                                                        {state.selectedAsset.service_name ? (" : ")+state.selectedAsset.service_name : ""}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="py-1 lg:w-1/3 w-full lg:pl-3 pl-0">
                                                                                    <p className="b-block mb-0 text-lightGray">Created At</p>
                                                                                    <p className="mb-0 text-black break-all">
                                                                                        {state.selectedAsset.created_at ? momentConvertionUtcToLocalTime(state.selectedAsset.created_at, 'DD MMM YYYY HH:mm') : ""}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex flex-wrap mb-2 border-t border-lightGray">
                                                                                <div className="py-1 lg:w-1/3 md:w-1/2 w-full">
                                                                                    <p className="b-block mb-0 text-lightGray">Created By</p>
                                                                                    <p className="mb-0 text-black break-all">
                                                                                        {state.selectedAsset.created_by ? state.selectedAsset.created_by : "-"}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="py-1 lg:w-1/3 md:w-1/2 w-full md:pl-3 pl-0">
                                                                                    <p className="b-block mb-0 text-lightGray">Modified At</p>
                                                                                    <p className="mb-0 text-black break-all">
                                                                                        {state.selectedAsset.modified_at ? momentConvertionUtcToLocalTime(state.selectedAsset.modified_at, 'DD MMM YYYY HH:mm') : state.selectedAsset.last_modified_at ? momentConvertionUtcToLocalTime(state.selectedAsset.last_modified_at, 'DD MMM YYYY HH:mm') : ""}
                                                                                    </p>
                                                                                </div><div className="py-1 lg:w-1/3 w-full lg:pl-3 pl-0">
                                                                                    <p className="b-block mb-0 text-lightGray">Modified By</p>
                                                                                    <p className="mb-0 text-black break-all">
                                                                                        {state.selectedAsset.modified_by ? state.selectedAsset.modified_by : "-"}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex flex-wrap mb-2 border-t border-lightGray">
                                                                                <div className="py-1 w-full">
                                                                                    <p className="b-block mb-0 text-lightGray">Category:</p>
                                                                                    <div className={`flex flex-wrap`}>
                                                                                        {state.selectedAsset.category && Array.isArray(state.selectedAsset.category) ?
                                                                                            state.selectedAsset.category.map(cat => {
                                                                                                return(
                                                                                                    <small key={"selectedAsset_"+i} className={`mr-1 mb-1 border border-lightGray px-2 py-1 rounded-full text-info`}>{cat}</small>
                                                                                                )
                                                                                            })
                                                                                        : 
                                                                                            <small className={`mr-1 mb-1 border border-lightGray px-2 py-1 rounded-full text-info`}>{state.selectedAsset.category}</small>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex flex-wrap mb-2 border-t border-lightGray">
                                                                                <div className="py-1 w-full">
                                                                                    <p className="b-block mb-0 text-lightGray">Tags:</p>
                                                                                    <div className='flex flex-wrap gap-2'>
                                                                                    {state.selectedAsset.tags && state.selectedAsset.tags.map(tag => {
                                                                                        return(
                                                                                            <small key={"selectedAssetTag_"+i} className={`border border-lightGray px-2 py-1 rounded-full text-info`}>{tag.key+': '+tag.value}</small>
                                                                                        )
                                                                                    })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                            </React.Fragment>
                                                        : null}
                                                    </div>
                                                )
                                            })
                                        :
                                            <div className='flex justify-center m-4'>
                                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                            </div>
                                        }
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inventory