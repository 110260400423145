/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file QueueDetails.js
 * @author Prakash // on 24/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { listQueueDetails, deleteQueue } from '../../../actions/Collider/ClusterAction'
import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, convertBytesTo, } from '../../../utils/utility'
import { CSVLink } from 'react-csv';
import QueueDetailsSidePanel from './QueueDetailsSidePanel'
import Search from '../../common/SearchWithHiddenInput'
import CreateUpdateQueuePanel from './CreateUpdateQueuePanel';
 import { Store as CommonNotification } from 'react-notifications-component';
import FilterSection from '../../common/FiltersSection';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';

const QueueDetails = () => {
    const clickOutside = useRef()
        
    const [state, setState] = useState({
        showLoading: true,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 10,

        queueDetails: [],
        selectedQueueDetails: {},
        filteredQueueDetails: [],
        queueheaders: [],
        deleteModal: false,

        actionsAllowed: [],
        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster']
    })    

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
	const currentPath = location.pathname;

    const providers = useSelector(state => state?.filters?.providers || false);
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				} 
                // else {
				// 	if(row.action) {
				// 		actionsAllowed = row.action
				// 	}
				// }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],            
            callSearch: true,
            showLoading: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: '', selectedProjectTags: [], selectedAccount: [], selectedRegion: [], selectedClusters: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, startRecord: 0,  currentPage: 1 }))
            
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== "All")
            }            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== "All")
            }            
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters.filter(e => e !== "All")
            }

            if(state.nextToken && state.nextToken.length) {
                params.next_token = state.nextToken
            }
            // params.size = 10
            if(state.selectedProvider) {
                dispatch(listQueueDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []

                            let totalResults = [] 
                            let startRecord = state.startRecord
                            let currentPage = state.currentPage 
                            if(state.nextToken && state.nextToken.length) {
                                totalResults = state.queueDetails &&  state.queueDetails.length ? state.queueDetails : []
                                startRecord = startRecord + state.perPage;
                                currentPage = currentPage+ 1
                            }

                            if(results && results.length) {
                                totalResults = totalResults.concat(results)
                            }
                            let totalPages = 1
                            if(totalResults.length > state.perPage) {
                                totalPages = Math.ceil(totalResults.length / state.perPage)
                            }
                            
                            setState(prevState => ({ ...prevState, queueDetails: totalResults, filteredQueueDetails: totalResults, detailsFormat: response && response.queue_details ? response.queue_details : [], showLoading: false, totalPages, startRecord, currentPage, nextToken: response.next_token, totalRecords: response.total }));
                        }
                })
            } else {
                setState(prevState => ({ ...prevState, queueDetails: [], filteredQueueDetails: [], showLoading: false }));
            }
        }
    }, [dispatch, state.callSearch, state.perPage, state.selectedAccount, state.queueDetails, state.selectedClusters, state.selectedProjectTags, state.selectedProvider, state.selectedRegion, state.currentPage, state.nextToken, state.startRecord])

    useEffect(() => {
        if(state.queueDetails && state.queueDetails.length) {
            let dataResult = state.queueDetails
            let headers = []
            Object.entries(dataResult[0]).forEach(([key, value]) => {
                if(typeof value === 'string') {
                    let headerRow = {}
                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                    headerRow.key = key
                    headers.push(headerRow)
                }
            })
    
            setState(prevState => ({ ...prevState, queueheaders: headers }))
        }

        //status graph of how many running days 
        state.queueDetails && state.queueDetails.forEach(row => {
            let donut = [{
                item_count: row.number_of_days ? row.number_of_days : 0,
                item_name: 'Days'
            }]
            let donut_total_count = row.number_of_days ? row.number_of_days : 0
            // state.statusSection.trend && state.statusSection.trend.length && state.statusSection.trend[0].total && Object.entries(state.statusSection.trend[0].total).forEach(([key, value]) => {
            //     let donutRow = {}
            //     donutRow.item_count = value
            //     donutRow.item_name = key
            //     donut.push(donutRow)
            //     donut_total_count += value 
            // })
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let statusDonutData = {
                'items': donut,
                'label': 'Days',
                'total_count': donut_total_count
            }
    
            setState(prevState => ({ ...prevState, ['statusDonutData_'+row.queue_name]: statusDonutData }))
        })
    }, [state.queueDetails])

    useEffect(() => {
        if(state.refreStatusDontGraph) {
            setState(prevState => ({ ...prevState, refreStatusDontGraph: false }))
        }
    }, [state.refreStatusDontGraph])

    useEffect(() => {
        if(state.selectedQueueDetails && Object.entries(state.selectedQueueDetails).length)
        setState(prevState => ({ ...prevState, showQueueDetails: true }))
    }, [state.selectedQueueDetails])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    useEffect(() => {
        if(state.pageMode) {
            setState(prevState => ({ ...prevState, showCreateUpdateQueuePanel: true }))
        }
    }, [state.pageMode])

    
	
	const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value, deleteConfirmMessage: '' }))
	}

    const deleteQueueFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.selectedDeleteItem 
        let params = {}
        if(item && item.asset_id) {
            params.asset_id = item.asset_id
        } else {
            params.provider = item.provider
            params.account_id = item.account_id
            params.region = item.region
            params.cluster_name = item.cluster_name
            params.queue_name = item.queue_name
        }
        if(state.queue_name && state.queue_name === state.selectedDeleteItem.queue_name) {
            dispatch(deleteQueue(params))
                .then((response) => {
                    if(response) {
                        let messageType = 'danger'
                        let message = response && response.message ? response.message : "Erro in deleting"
    
                        if(response.status) {
                            messageType = 'success'
                            setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false }))
                            // toggle()
                            setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, showDeleteLoading: false }))}, 1000)
                        } else {
                            setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                        }
    
    
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    }
                })
        } else {
            if(!state.queue_name || state.queue_name === '') {
                setState(prevState => ({ ...prevState, deleteConfirmMessage: "Please enter queue name", showDeleteLoading: false }))
            } else if(state.queue_name !== item.queue_name) {
                setState(prevState => ({ ...prevState, deleteConfirmMessage: "Entered Queue name does not match selected queue name", showDeleteLoading: false }))
            }
        }

    }

    useEffect(() => {
        if(state.callDelete && state.selectedDeleteItem && Object.entries(state.selectedDeleteItem).length) {
            setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, callDelete: false }))
        }
    }, [state.callDelete, state.selectedDeleteItem, state.deleteModal])

    const handleChildClick = (event, type, dropdownType, section) => {
        
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='px-6' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            
            {state.showCreateUpdateQueuePanel ? 
                <CreateUpdateQueuePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showCreateUpdateQueuePanel: false, pageMode: '', editQueueDetails: {}, callSearch: true }))}
                    selectedQueueDetails={state.editQueueDetails}
                    pageMode={state.pageMode}
                />
            : null}
            {state.showQueueDetails ? 
                <QueueDetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showQueueDetails: false, selectedQueueDetails: {} }))}
                    selectedQueueDetails={state.selectedQueueDetails}
                    detailsFormat={state.detailsFormat ? state.detailsFormat : []}
                />
            : null}
            
            
            <div className="pt-6">
                <div className="flex flex-wrap bg-DeepPurpleTints-600 w-full rounded-2xl py-7 px-6">
                    <div className="lg:w-1/4 w-full self-center">
                        <p className="text-white text-[28px] text-semibold">Queue Details</p>
                        <p className="text-white text-base">Consolidated view of queue details</p>
                    </div>
                    <div className="lg:w-3/4 w-full flex justify-end" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
                
            <div className='pt-8'>
                <div className='flex justify-between pb-4'>
                    <p className='mb-0 text-sm self-center lg:w-1/3 w-full'>Showing {state.filteredQueueDetails && state.filteredQueueDetails.length} of total {state.queueDetails && state.queueDetails.length} queue(s)</p>
                    <div className='flex justify-end lg:w-2/3 w-full'>
                        {state.queueDetails && state.queueDetails.length ?
                            <div className='self-center w-100 ml-4'>
                            <Search
                                data={state.queueDetails ? state.queueDetails : []}
                                topClassName={'border border-NeutralGray-600 rounded-full bg-white p-2.5 pl-3 pr-4'}
                                className={'rounded-full'}
                                filteredData={(filteredClusterDetails) => {
                                    setState(prevState => ({ ...prevState, filteredClusterDetails, currentPage: 1 })
                                )}}
                            />
                            </div>
                        : null}
                        {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                            <button className={`bg-DeepPurpleTints-600 text-white mx-2 font-semibold px-5 py-2.5 rounded-full self-center text-base`} onClick={() => setState(prevState => ({ ...prevState, pageMode: 'Create' }))}>Create&nbsp;Queue</button>
                        : null}
                        {state.filteredQueueDetails && state.filteredQueueDetails.length ?
                            <div className='self-center' title="download">
                                <CSVLink 
                                    data={state.filteredQueueDetails ? state.filteredQueueDetails : []} 
                                    headers={state.queueheaders ? state.queueheaders : []}
                                    filename={'queue-lists.csv'}
                                    className={'self-center'}
                                    target='_blank'
                                >
                                    <Icon icon="charm:download" className='text-white bg-PrimaryLightOrange-600 h-9 w-9 px-2 rounded-full self-center' />
                                </CSVLink>
                            </div>
                        : null}
                    </div>
                </div>
                {state.totalRecords > state.perPage ?
                    <div className='justify-end w-full my-4'>
                        {state.totalRecords > state.perPage ?
                            <div className='flex justify-end text-black w-full'>
                                <span className='mx-3 self-center'>Page {state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)} </span>
                                <div className="flex text-sm">
                                    <button><Icon icon="mynaui:arrow-left-square" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-NeutralGray-600' : 'cursor-pointer text-DeepPurpleTints-600' }`} onClick={() => navigatePage('previous', state.currentPage)} /></button>
                                    <button><Icon icon="mynaui:arrow-right-square" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ?'pointer-events-none text-NeutralGray-600' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                </div>
                            </div>
                        : null}
                    </div>
                : null}

                {state.filteredQueueDetails && state.filteredQueueDetails.length ? 
                    state.filteredQueueDetails.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                        return(
                            <div className='flex flex-wrap bg-NeutralGray-100 border border-NeutralGray-600 rounded-2xl mb-6' key={"row_"+i}> 
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full bg-SecondaryTintsBlueTints rounded-l-2xl">
                                <div className='py-[30px] px-8 flex flex-col justify-between h-full'>
                                    <div className="flex flex-col justify-between h-full">
                                        <div className="flex">
                                            <div className="w-full">
                                                <p className="b-block mb-0 text-NeutralGray-600 text-sm">Queue</p>
                                                <p className="mb-0 text-base text-black">
                                                    { item.queue_name ? item.queue_name : ''}
                                                    {item.nodes && item.nodes.length ?
                                                    <React.Fragment>
                                                    <span className='mr-2'>CPUs: {item.nodes[0].cpus ? item.nodes[0].cpus : ''}, </span>

                                                    <span className='mr-2'>Mem:
                                                        {item.nodes[0].memory ? 
                                                            convertBytesTo(item.nodes[0].memory, 'Megabytes', 'round', 'GB')
                                                            : ''
                                                        },
                                                    </span>
                                                    </React.Fragment>
                                                : null}
                                                </p>
                                                </div>
                                                
                                        </div>
                                        <div className='flex'>
                                                <div className='w-full'>
                                                {item.nodes && item.nodes.length ?
                                                    <React.Fragment>
                                                    <span className='mr-2'>CPUs: {item.nodes[0].cpus ? item.nodes[0].cpus : ''}, </span>

                                                    <span className='mr-2'>Mem:
                                                        {item.nodes[0].memory ? 
                                                            convertBytesTo(item.nodes[0].memory, 'Megabytes', 'round', 'GB')
                                                            : ''
                                                        },
                                                    </span>
                                                    </React.Fragment>
                                                : null}
                                                {item.total_nodes ?
                                                    <span>Nodes: {item.total_nodes}</span>
                                                : null} 
                                                </div>
                                        </div>
                                        <div className="flex">
                                            <div className="w-full">
                                                <p className="b-block mb-0 text-NeutralGray-600 text-sm">Cluster</p>
                                                <p className="mb-0 text-base text-black">
                                                    { item.cluster_name ? item.cluster_name : ''}
                                                    {item.cluster_version ?
                                                        <span className={`mb-0 mr-2 f18 text-base text-black font-bold`}>
                                                            (v {item.cluster_version})
                                                        </span>
                                                    : null}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                        <div className="w-full">
                                                <p className="b-block mb-0 text-NeutralGray-600 text-sm">Resources</p>
                                                <p className="mb-0 text-base text-black">
                                                    {item.account_id ?
                                                        <span>{' '+item.account_id}</span>
                                                    : null}
                                                    {item.region ?
                                                        <span>{' '+item.region}</span>
                                                    : null}
                                                    {item.resource_type ?
                                                        <span>{' '+item.resource_type}</span>
                                                    : null}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-full my-[30px] border-r border-NeutralGray-600 pr-0">
                                    <div className="flex flex-col justify-between h-full pl-11">
                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(0, 3).map(([key, value], tIndex) => {
                                            return(
                                                <div key={'tag_' + tIndex} className={`flex`}>
                                                    <div className="w-full">
                                                        <p className="b-block mb-0 text-NeutralGray-600 text-sm">{key}</p>
                                                        {value ? 
                                                            <p className="mb-0 text-base text-black">{value}</p>   
                                                        : 
                                                            <span>&nbsp;</span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-full my-[30px] pr-0">
                                    <div className="flex flex-col justify-between h-full pl-11">
                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(3, 6).map(([key, value], tIndex) => {
                                            return(
                                                <div key={'ptag_' + tIndex} className={`flex`}>
                                                    <div className="w-full">
                                                        <p className="b-block mb-0 text-NeutralGray-600 text-sm">{key}</p>
                                                        {value ? 
                                                            <p className="mb-0 text-base text-black">{value}</p>   
                                                        : 
                                                            <span>&nbsp;</span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full pr-0">
                                    <div className='flex justify-between'>
                                        <div className='my-[20px] px-[23px] py-[10px] rounded-lg border border-DeepPurpleTints-600 bg-SecondaryTintsBlueTints mr-2 w-3/5'>
                                            <div className="flex">
                                                <div className="w-100">
                                                    <p className="b-block mb-0 text-sm pb-1">Status</p>
                                                    <p className="mb-1 text-black text-base">
                                                        {item.status === "up" ?
                                                            <span className={'bg-SecondaryGreenTints-600 border-2 border-SecondaryGreenTints-80 text-SecondaryGreen-80 px-[11px] py-[3px] rounded-full'}>{item.status} </span>
                                                        : 
                                                            <span className={'bg-PrimaryLightOrange-50 border-2 border-PrimaryLightOrange-600 text-PrimaryLightOrange-600 px-[11px] py-[3px] rounded-full'}>{item.status} </span>
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="pt-6 w-100">
                                                    <p className="b-block mb-0 text-NeutralGray-600 text-sm">Days Running</p>
                                                    <p className="mb-0 text-black text-base">
                                                        <span>{item.number_of_days ? item.number_of_days : 0}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="pt-6 w-100">
                                                    <p className="b-block mb-0 text-NeutralGray-600 text-sm">Created at</p>
                                                    <p className="mb-0 text-black text-base">{item.created_at ? momentConvertionUtcToLocalTime(item.created_at, 'DD MMM YYYY HH:mm') : <span>&nbsp;</span>}</p>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="pt-6 w-100">
                                                    <p className="b-block mb-0 text-NeutralGray-600 text-sm">Modified at</p>
                                                    <p className="mb-0 text-black text-base">{item.last_modified_at ?  momentConvertionUtcToLocalTime(item.last_modified_at, 'DD MMM YYYY HH:mm') : 'Not Modified'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-0 self-center mr-[35px]'>
                                            {/* <p className='mb-0 text-center text-info' onClick={() => setState(prevState => ({ ...prevState, editQueueDetails: item, pageMode: 'View' }))}>View</p> */}
                                            <p className='text-DeepPurpleTints-600 w-full mb-[17px] px-3 py-[6px] text-base text-center cursor-pointer border border-DeepPurpleTints-600 rounded-full' onClick={() => setState(prevState => ({ ...prevState, selectedQueueDetails: item }))}>Details</p>
                                            {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                                               <p className='text-PrimaryLightOrange-600 w-full mb-[17px] px-3 py-[6px] text-base text-center cursor-pointer border border-PrimaryLightOrange-600 rounded-full' onClick={() => setState(prevState => ({ ...prevState, editQueueDetails: item, pageMode: 'Edit' }))}>Edit</p>
                                            : null}
                                            {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                                                <p className='text-PrimaryLightOrange-600 w-full mb-[17px] px-3 py-[6px] text-base text-center cursor-pointer border border-PrimaryLightOrange-600 rounded-full' onClick={() => setState(prevState => ({ ...prevState, selectedDeleteItem: item, deleteModal: true }))}>Delete</p>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                : 
                    <div className='flex justify-center text-black m-4'>
                        There are no data on this criteria. Please try adjusting your filter.
                    </div>
                }
                <div id="popup-modal" tabIndex="-1" className={`flex ${state.deleteModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, deleteModal: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete the queue "{state.selectedDeleteItem && state.selectedDeleteItem.queue_name ? state.selectedDeleteItem.queue_name : ''}"?</h3>

                                <p>Please enter the queue name to delete</p>
                                <input
                                    type='text'
                                    placeholder={'Enter cluster name'}
                                    className={`inputTextbox bg-transparent rounded-sm px-1 border border-mediumDarkGray text-black`}
                                    // maxlength='128'
                                    value={state.queue_name ? state.queue_name : ''}
                                    onChange={e => handleInputChange('queue_name', e.target.value)}
                                />
                                <div className='flex mt-4 justify-center'>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-HalloweenOrange mr-2" 
                                    onClick={() => {
                                        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
                                        if(!state.showDeleteLoading) {
                                            deleteQueueFunction()
                                        }
                                    }}
                                >
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md bg-lightGray text-white" onClick={() => {
                                    if(!state.showDeleteLoading) {
                                        setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false, name: '' }))
                                    }
                                }}>
                                No, cancel
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    )
}
export default QueueDetails