import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getJobMetrics } from '../../../actions/Collider/JobStatusAction';

import MetricsTab from '../../common/Mertics/MetricsTab';
import LogsTab from './LogsTab';
import {Icon} from "@iconify/react";
import PageLoading from '../../common/PageLoading';
import { LoadingCircle } from '../../common/LoadingCiricle';
import DetailsTab from '../../common/DetailsTab'
import JobScript from './JobScript';
import { toggleScroll } from '../../../utils/utility';

const JobDetailsSidePanel = ({ selectedJobDetails, closeSidePanel, detailsFormat, backtoBatchJob, fromBatchJob }) => {
    const dispatch = useDispatch(); // Create a dispatch function
    const clickOut = useRef()
    const [state, setState] = useState({
        showLoading: false,
        selectedTab: "Details",
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        logsList: [],
        filteredLogsList: [],
        tabs: ['Details', 'Script', 'Observability', 'Logs']
    })

    useEffect(() => { toggleScroll(true) }, [])
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (state.selectedTab === "Observability") {
            setState(prevState => ({ ...prevState, showMetricLoading: true }));
            let params = {}
            params.start_time = selectedJobDetails && selectedJobDetails.start_time
            params.end_time = selectedJobDetails && selectedJobDetails.end_time
            params.asset_id = selectedJobDetails && selectedJobDetails.asset_id
            if(selectedJobDetails && selectedJobDetails.created_at) {
                params.created_at = selectedJobDetails.created_at
            }
            dispatch(getJobMetrics(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, metricDetails: response, showMetricLoading: false }));
                    }
                })
        }
    }, [state.selectedTab, dispatch, selectedJobDetails])

    const handleChildClick = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
    }

    return (
        <div className="overflow-y-auto">
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <PageLoading />
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-end z-20 h-full w-full right-0 top-0" onClick={(event) => handleChildClick(event, 'parent')}>
                <div className='h-full flex flex-col bg-white lg:w-3/4 w-10/12' ref={clickOut}>
                    <div className='bg-DeepPurpleTints-600 px-8 py-6'>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap w-10/12'>
                                <div className='text-black lg:w-5/12 w-full self-center'>
                                    <span className='font-bold text-2xl text-white'>Job Details</span>
                                </div>
                            </div>
                            <div className='self-center'>
                                <Icon icon="jam:close" className='text-white cursor-pointer self-center' width={24} height={24} onClick={() => closeSidePanel()} />
                            </div>
                        </div>
                    </div>
                
                    <div className={`mx-4 pt-4`}>
                        <div className='flex justify-between'>
                            <ul className="flex flex-wrap text-sm text-center">
                                {state.tabs.map(tab => {
                                    return(
                                        <li className="me-2">
                                            <span className={`inline-block px-8 py-5 rounded-t-xl border border-NeutralGray-600 ${state.selectedTab === tab ? 'font-bold text-white bg-DeepPurpleTints-600 underline' : 'text-black bg-lightGray-600 cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: tab }))}>{tab}</span>
                                        </li>

                                    )
                                })}
                            </ul>
                            {fromBatchJob ?
                                <div className='flex justify-end'>
                                    <button className={`btn bg-NeutralGray-600 px-4 py-2.5 rounded-md self-center text-center text-white cursor-pointer`} onClick={() => backtoBatchJob()}>Back</button>
                                </div>
                            : null}
                        </div>
                        <div className="overflow-y-auto h-[calc(100vh-120px)] border border-NeutralGray-500 bg-PrimaryTintsPurple-600 rounded-r-2xl rounded-b-2xl">
                            {state.selectedTab === "Details" ? 
                                <DetailsTab 
                                    detailsFormat={detailsFormat}
                                    selectedDetails={selectedJobDetails}
                                />
                            : state.selectedTab === "Script" ?
                                <JobScript
                                    selectedJobDetails={selectedJobDetails}
                                />
                            : state.selectedTab === "Observability" ?
                                <div className='p-6'>
                                    {state.showMetricLoading ?
                                        <div className='flex justify-center m-4'>
                                            <LoadingCircle />
                                        </div>
                                    : state.metricDetails ?
                                        <div className=''>
                                            <p className='text-primaryPurple-600 text-2xl text-fold pb-8'>OBSERVABILITY</p>
                                            <MetricsTab
                                                metricDetails={state.metricDetails}
                                                selectedDetails={selectedJobDetails}
                                                height={175}
                                                showStatistics={["min", "avg", "max"]}
                                                axisLabelColor={'#495057'}
                                                gradient={true}
                                                gradientColor={['#E9E3F5', '#7052BC']}
                                            />
                                        </div>
                                    : null}
                                </div>
                            : state.selectedTab === 'Licenses' ?
                                <div className="rounded">
                                    <p className={`mb-2`}>Showing the list of licenses used in job <span className="text-info">{selectedJobDetails && selectedJobDetails.job_name ? selectedJobDetails.job_name : ""}</span> </p>
                                    <div className="relative overflow-x-auto mt-2">
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead className="text-md text-black bg-white">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">Application</th>
                                                    <th scope="col" className="px-6 py-3">License Count</th>
                                                    <th scope="col" className="px-6 py-3">License Count</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {selectedJobDetails.licenses && selectedJobDetails.licenses.length ?
                                                selectedJobDetails.licenses.map((row, index)=> {
                                                    return(
                                                        <tr key={'lic_'+index} className="bg-GhostWhite border-b dark:bg-gray-800 dark:border-gray-700">
                                                            <td className="px-6 py-4 text-sm">{row.application_name ? row.application_name : ''}</td>
                                                            <td className="px-6 py-4 text-sm">{row.license_name ? row.license_name : ''}</td>
                                                            <td className="px-6 py-4 text-sm">{row.licenses_count ? row.licenses_count : ''}</td>
                                                        </tr>
                                                    )
                                                })
                                            : 
                                                <tr>
                                                    <td colspan="3" className='px-6 py-3 text-center text-lightGray'>
                                                        There are no licenses for this job.
                                                    </td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            : state.selectedTab === "Logs" ?
                                <LogsTab
                                    selectedJobDetails={selectedJobDetails}
                                />
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobDetailsSidePanel;