/*************************************************
 * Kiosk
 * @exports
 * @file JobDetails.js
 * @author Prakash // on 28/11/2023
 * @copyright © 2023 Kiosk. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { getJobTotalCost } from '../../../actions/Collider/SpendAction'
import { momentConvertionLocalToUtcTime } from '../../../utils/utility';
import StackedCircleBarChart from '../../common/charts/StackedCircleBarChart';
import {Icon} from "@iconify/react";
import { LoadingCircle } from '../../common/LoadingCiricle';

const JobDetails = (props) => {
    const rightRadialStackedBarWidthRef = useRef();
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        callGetJobTotalCostFunction: true,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 10,
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.callGetJobTotalCostFunction) {
            setState(prevState => ({ ...prevState, callGetJobTotalCostFunction: false }));
            let params = {
                // 'duration': '30d',
                'start_time': momentConvertionLocalToUtcTime(props.startDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentConvertionLocalToUtcTime(props.endDate, 'YYYY-MM-DD HH:mm:ss'),
                'aggregate_by':'job_name', 
                'radial':true
            }
            if(props.selectedDetails && props.selectedDetails.provider) {
                params.provider = props.selectedDetails.provider
            }
            
            if(props.selectedDetails && props.selectedDetails.account_id) {
                params.account_id = props.selectedDetails.account_id
            }

            if(props.selectedDetails && props.selectedDetails.region) {
                params.region = props.selectedDetails.region
            }

            if(props.selectedDetails && props.selectedDetails.cluster_name) {
                params.cluster_name = props.selectedDetails.cluster_name
            }
            
            if(props.selectedDetails && props.selectedDetails.queue_name) {
                params.queue_name = props.selectedDetails.queue_name
            }

            dispatch(getJobTotalCost(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length && response.results[0].data ? response.results[0].data : {}
                        let totalPages = 1                
                        if(Object.entries(results).length > state.perPage) {
                            totalPages = Math.ceil(Object.entries(results).length / state.perPage)
                        }
                        
                        setState(prevState => ({
                            ...prevState,
                            radialTrendResponse: results,
                            filterData: results,
                            totalPages,
                            showLoading: false
                        }));
                    }
            })
        }
    }, [dispatch, state.callGetJobTotalCostFunction, state.perPage, props])

    const count = useCallback((array, key) => {
		let data = array.reduce(function(r, a) {
			return r + a[key]
		}, 0)

		return data
	}, [])

    useEffect(() => {
        if(state.radialTrendResponse && Object.entries(state.radialTrendResponse).length) {
            let graphData = state.radialTrendResponse //radialBarData //radialTrendResponse
            let rightSideGraphColors = ['#8e3a80', '#a22ee6', '#bc47ff', '#c35cff', '#cb70ff', '#d285ff', '#da99ff', '#e15ad2', '#fb74eb', '#fb83ed', '#fc93f0', '#fca2f2', '#fdb2f4', '#fdc1f6']
            let keys = ['Cost']

            let rightSideArray = []
            let rightSideLowest = 0
            let rightSideHighest = 0
            let rightSideTotal = 0
            let rightSideTotalTableData = {}
            graphData && Object.entries(graphData).forEach(([key, value], index) => {
                let State = 'files_' + index
                let dataRow = {}
                let count = value
                dataRow.Cost = value

                dataRow.State = State
                dataRow.page = 'MultipleKeys'
                dataRow.name = key
                dataRow.total = count
                rightSideTotal = graphData.length
                if (rightSideHighest < count) {
                    rightSideHighest = count
                }
                if(count < rightSideLowest) {
                    rightSideLowest = count
                }
                if (count > 0) {
                    rightSideArray.push(dataRow)
                }

                rightSideTotalTableData[State] = dataRow
            })

            let rightSideTotalData = {}
            let data = {}
            data.name = 'Jobs'
            keys.forEach((key, j) => {
                data[key] = count(rightSideArray, key)
            })
            rightSideTotalData.data = data

            if (rightSideArray.length < 25) {
                let length = rightSideArray.length
                for (let i = length; i <= 25; i++) {
                    let dataRow = {}
                    dataRow.State = 'files_' + i
                    dataRow.name = 'empty_data'
                    let count = rightSideLowest / 5
                    dataRow.Cost = count
                    rightSideArray.push(dataRow)
                }
            }
            
            // rightSideArray = _.orderBy(rightSideArray, ['fail_count'], ['desc'])

            setState(prevState => ({ 
                ...prevState, 
                rightSideGraphColors,
                rightSideArray,
                rightSideKeys: keys,
                rightSideHighest,
                rightSideTotalData,
                rightSideTotal,
                rightSideGraphId: 'totalThreats',
                rightSideSvgGraphId: 'svgTotalThreats',
                showRightSideRadialChart: true,
                hideDistributionSection: true,
                showRadialLoading: false,
                rightSideTotalTableData,
            }))
        }
    }, [state.radialTrendResponse, count])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    const handleClickOutside = (event) => {};

    return (
        state.showLoading ?
            <div className='flex justify-center m-4'>
                <LoadingCircle />
            </div>
        : state.filterData && Object.entries(state.filterData).length ?
            <div className='p-6' onClick={handleClickOutside}>
                <p className='text-primaryPurple-600 text-[22px] font-bold pb-6'>Jobs</p>
                <div className='flex flex-wrap items-stretch'>
                    <div className='lg:w-5/12 w-full pr-4 h-full'>
                        <div className='bg-white border border-NeutralGray-600 rounded-2xl'>
                            <div className='flex flex-wrap justify-center'>
                                <div className='w-3/4' ref={rightRadialStackedBarWidthRef}>
                                    {state.showRightSideRadialChart ?
                                        <StackedCircleBarChart
                                            ID={state.rightSideGraphId}
                                            svgID={state.rightSideSvgGraphId}
                                            width={
                                                rightRadialStackedBarWidthRef.current &&
                                                rightRadialStackedBarWidthRef.current.offsetWidth
                                            }
                                            data={state.rightSideArray}
                                            keys={state.rightSideKeys}
                                            userHighestValue={true}
                                            highestValue={state.rightSideHighest}
                                            hoverData={state.rightSideTotalData}
                                            totalValue={state.rightSideTotal}
                                            selectedState={
                                                state.selectedChartControl ? state.selectedChartControl : ''
                                            }
                                            tableData={(data, State) => {
                                                if (State && State !== state.selectedChartControl) {
                                                    setState(prevState => ({ 
                                                        ...prevState,
                                                        selectedChartControl: State, 
                                                        // showRightSideRadialChart: false,
                                                        refreshStackedCircleBar: true
                                                    }))
                                                }
                                            }}
                                            legendText={'Jobs'}
                                            colors={state.rightSideGraphColors}
                                        />
                                    : null }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lg:w-7/12 w-full h-full'>
                        <div className='bg-white border border-NeutralGray-600 rounded-2xl p-3'>
                            <div className='flex justify-between mt-2'>
                                <p className='pb-5 text-black text-sm'>Showing job cost details</p>                            
                                <div className='flex w-3/5 justify-end'>
                                    {state.radialTrendResponse && Object.entries(state.radialTrendResponse).length > state.perPage ?
                                        <div className='flex justify-end text-black text-sm lg:w-1/2 w-full'>
                                            <span className='mx-3 self-center'>Page {state.currentPage} of {state.totalPages} </span>
                                            <button><Icon icon="mynaui:arrow-left-square" width="25" height="25" className={`ml-2 ${state.currentPage === 1 ? 'pointer-events-none text-NeutralGray-600' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button>
                                            <button><Icon icon="mynaui:arrow-right-square" width="25" height="25" className={`ml-2 ${state.currentPage === state.totalPages ? 'pointer-events-none text-NeutralGray-600' : 'cursor-pointer text-DeepPurpleTints-600'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                        </div>
                                    : null}
                                </div>
                            </div>
                            <div className='relative overflow-x-auto rounded-t-lg shadow border border-NeutralGray-400 pb-100'>
                                <table className="w-full text-left">
                                    <thead className='uppercase bg-NeutralGray-400 text-NeutralGray-800 font-semibold rounded-t-lg'>
                                        <tr className="flex-1 w-full">
                                            <th className='tracking-wider px-3 py-3 text-left'>Job Name</th>
                                            <th className='tracking-wider px-3 py-3 text-left'>Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-black">
                                        {state.filterData && Object.entries(state.filterData).slice(state.startRecord, state.startRecord + state.perPage).map(([key, value], index) => {
                                            return(
                                                <tr key={'tr_'+index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-cultured-600'}`}>
                                                    <td className={`p-4 text-black text-left text-sm whitespace-nowrap`}>{key}</td>
                                                    <td className={`p-4 text-black text-left text-sm whitespace-nowrap`}>{value}</td>
                                                </tr>
                                            )

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div className='flex justify-center m-4 text-black'>
                There are no job data on this {props.selectedType}.
            </div>
    );
};

export default JobDetails