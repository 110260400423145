import React, { useState } from 'react';
import TreeView from './TreeView';
import {Icon} from "@iconify/react";
import { LoadingCircle } from '../../../common/LoadingCiricle';

const TreeNode = ({ node, onNodeToggle, onNodeToggleFolder, onNodeClick }) => {
  const [loaderMountPathExpand, setLoaderMountPathExpand] = useState(false)  
  const handleNodeToggle = async (node) => {
    if (onNodeToggle) {
      setLoaderMountPathExpand(true)
      await onNodeToggle(node);
      await onNodeToggleFolder(node);
      setLoaderMountPathExpand(false)
    }
  };
  
  return (
    <li className=''>
      <div className='flex'>
        <span className='flex self-center' onClick={() => handleNodeToggle(node)}>
          {(node && node.expanded && node.children) ?
            <Icon icon="icon-park-outline:down" className="text-NeutralGray-600" width={20} height={20} />
          : (node.type === 'directory') ? 
            <Icon icon="icon-park-outline:right" className="text-NeutralGray-600" width={20} height={20} />
          : null}
        </span> 
        <span className='flex text-base self-center' onClick={() => {
          onNodeClick(node)
          handleNodeToggle(node)
        }}>
          {loaderMountPathExpand && 
            <LoadingCircle />
          }
            <Icon icon={`${node.type === 'directory' ? "material-symbols-light:folder-outline" : "mingcute:file-fill"}`} width={24} height={24} className="mr-2  self-center" /> 
          {node.name}
        </span>
      </div>
      {node && node.children && node.expanded && (
        <TreeView
          data={node.children}
          onNodeToggle={onNodeToggle}
          onNodeClick={onNodeClick}
          onNodeToggleFolder={onNodeToggleFolder}
          parent={false}
        />
      )}
    </li>
  );
};

export default TreeNode;